import React, {
  useEffect,
  useReducer,
  useMemo,
  useCallback,
  useState,
} from 'react';
import R from '@air/third-party/ramda';
import { useHistory } from 'react-router-dom';
import { toast } from '@air/third-party/toast';
import { CustomerProfileContext, CustomerProfileContextT } from 'context';
import * as ATSApi from 'domain/ATS/atsApi';
import * as CustomerApi from 'domain/CustomerProfile/customerApi';
import {
  CustomerCompanyLicenseResponse,
  DataSourceResponse,
  LoggedInCustomerInfoResponse,
  MatchMinerRequestFeatureResponse,
  RoleName,
  CustomerCompanyResponseV3,
} from '@air/api';
import useAuthenticateAction from '@air/hooks/useAuthenticateAction';
import useLogoutAction from '@air/hooks/useLogoutAction';
import useAutologinAction, {
  getUserProfileAction,
} from '@air/hooks/useAutologinAction';
import useLicense from 'hooks/useLicense';
import authReducer, {
  AuthReducerAction,
  State,
} from '@air/utils/auth/authReducer';
import * as phrases from 'constants/phrases';
import { DataSourceType } from '@air/api/models/DataSourceType';
import * as urls from 'constants/urls';
import * as commonUrls from '@air/constants/commonUrls';
import * as LicenseApi from 'domain/Trial/License';
import {
  localStore,
  ACCESS_TOKEN_ORIGIN,
  clearTokenStorage,
} from '@air/domain/WebStorage/webStorage';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CHANGE_BACKGROUND } from 'constants/gaLabels';
import { SignupSteps } from 'domain/ATS/ATSIntegration';
import { useContextSelector, useContext } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import { useUserSettings } from 'hooks/useUserSettings';
import { DEFAULT_BACKGROUND_IMAGE_ID } from '@air/constants/app';
import { attachContextToReactComponent } from '@air/utils/context';
import { ActionType } from '@air/domain/Common/ActionType';

export { ActionType } from '@air/domain/Common/ActionType';

const initialState: State<LoggedInCustomerInfoResponse> = {
  authDataReceived: false,
  authError: null,
  authenticated: false,
  user: null,
  invalidToken: false,
};

type AppReducer<T> = (
  state: State<T>,
  action: AuthReducerAction<T>
) => State<T>;

export const CustomerProfileProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<
    AppReducer<LoggedInCustomerInfoResponse>
  >(authReducer, initialState);
  const history = useHistory();

  const [licenseType, setLicenseType] = useState(null);
  const [userCredentials, setUserCredentials] = useState(null);

  const userProfileUrl = urls.CUSTOMER_API;
  const authenticate = useAuthenticateAction<LoggedInCustomerInfoResponse>(
    userProfileUrl,
    dispatch
  );
  const logout = useLogoutAction<LoggedInCustomerInfoResponse>(dispatch);
  const getUserProfile = useCallback(
    () => getUserProfileAction(userProfileUrl, dispatch),
    [userProfileUrl, dispatch]
  );
  const [ats, setAts] = useState(null);
  const [isStandaloneAtsUser, setStandaloneAtsUser] = useState(null);
  const companyId = state?.user?.companyId;
  const customerId = state?.user?.customerId;

  const [customerCompany, setCustomerCompany] = useState(null);
  const [matchMinerSettings, setMatchMinerSettings] = useState(null);
  const [matchScoutSettings, setMatchScoutSettings] = useState(null);

  const [currentBackgroundImageId, setCurrentBackgroundImageId] = useState(
    DEFAULT_BACKGROUND_IMAGE_ID
  );

  const { setBackgroundImage, storedBackgroundImageId } =
    useUserSettings(customerId);

  useEffect(() => {
    if (
      !!storedBackgroundImageId &&
      storedBackgroundImageId !== currentBackgroundImageId
    ) {
      setCurrentBackgroundImageId(storedBackgroundImageId);
    }
  }, [currentBackgroundImageId, storedBackgroundImageId]);

  const changeBackgroundImage = useCallback(
    (imageId: number) => {
      setCurrentBackgroundImageId(imageId);
      setBackgroundImage(imageId);
      trackEvent({
        category: GACategory.KanbanPage,
        label: GA_LABEL_CHANGE_BACKGROUND,
      });
    },
    [setBackgroundImage]
  );

  const canAccessUserManagement = useMemo(() => {
    if (!state.user) return false;

    return state.user.role === RoleName.CUSTOMERADMIN;
  }, [state.user]);

  const isPassiveSearchEnabled = useMemo(() => {
    return ats?.type !== DataSourceType.ORACLETALEO;
  }, [ats]);

  const { daysLeft, isTrialExpired } = useLicense({
    customerId,
    setLicenseType,
    logout,
    licenseType,
  });
  useAutologinAction(userProfileUrl, dispatch);

  const fetchCustomerCompanyInfo = useCallback(() => {
    CustomerApi.fetchCustomerCompanyInfo(companyId).fork(
      (err) => {
        toast.error(
          err?.description?.details ?? phrases.SOMETHING_WENT_WRONG_ERROR
        );
      },
      (res: CustomerCompanyResponseV3) => {
        setCustomerCompany(res);
      }
    );
  }, [companyId]);

  const updateCustomerCompanyInfo = useCallback(
    (data) => {
      CustomerApi.updateCustomerCompanyInfo(companyId, data).fork(
        (err) => {
          toast.error(
            err?.description?.details ?? phrases.SOMETHING_WENT_WRONG_ERROR
          );
        },
        (res: CustomerCompanyResponseV3) => {
          setCustomerCompany(res);
          toast.dark(phrases.COMPANY_WAS_SUCCESSFULLY_UPDATED);
        }
      );
    },
    [companyId]
  );

  const updateCustomerCompanySpecializations = useCallback(
    (specializations) => {
      return updateCustomerCompanyInfo({
        ...customerCompany,
        settings: {
          ...customerCompany.settings,
          specializations,
        },
      });
    },
    [customerCompany, updateCustomerCompanyInfo]
  );

  const viewIntroGuide = useCallback(() => {
    CustomerApi.viewIntroGuide().fork(R.noop, () => {
      dispatch({
        type: ActionType.ShowIntroGuide,
      });
    });
  }, []);

  const fetchMatchMinerSettings = useCallback(() => {
    CustomerApi.fetchMatchMinerSettings().fork(
      (err) =>
        toast.error(
          err?.description?.details ?? phrases.SOMETHING_WENT_WRONG_ERROR
        ),
      (res: MatchMinerRequestFeatureResponse) => {
        setMatchMinerSettings(res);
      }
    );
  }, []);

  const fetchMatchScoutSettings = useCallback((companyId: number) => {
    CustomerApi.fetchMatchScoutSettings(companyId).fork(
      (err) =>
        toast.error(
          err?.description?.details ?? phrases.SOMETHING_WENT_WRONG_ERROR
        ),
      (res: MatchMinerRequestFeatureResponse) => {
        setMatchScoutSettings(res);
      }
    );
  }, []);

  const requestMatchMinerForCompany = useCallback(() => {
    return CustomerApi.requestMatchMinerForCompany().fork(
      (err) =>
        toast.error(
          err?.description?.details ?? phrases.SOMETHING_WENT_WRONG_ERROR
        ),
      (res: MatchMinerRequestFeatureResponse) => {
        setMatchMinerSettings(res);
      }
    );
  }, []);

  const requestMatchScoutForCompany = useCallback(() => {
    return CustomerApi.requestMatchScoutForCompany(state.user?.companyId).fork(
      (err) =>
        toast.error(
          err?.description?.details ?? phrases.SOMETHING_WENT_WRONG_ERROR
        ),
      (res: MatchMinerRequestFeatureResponse) => {
        setMatchScoutSettings(res);
      }
    );
  }, [state.user]);

  const fetchAvailableATSList = useCallback(
    (companyId: number) => {
      ATSApi.fetchATSList(companyId).fork(
        () => toast.warn(phrases.NO_ATS),
        (res: DataSourceResponse[]) => {
          const [ats] = res;
          if (
            !ats ||
            (ats.reLoginNeeded && state.user?.role === RoleName.CUSTOMERADMIN)
          ) {
            /*
              2 scenarios are handled here:
              1. No ATS assigned yet.
              This scenario means that the user has abrupted the flow
              of ATS setup, but was able to login either via linkToken
              (link sent by BE in initial ATS setup email) or by
              using his newly setup password (but without finishing
              ATS integration process).

              In the first case we need to logout the user to
              force him to use the link from email one more time.
              In the second case we redirect the user to step 2 of
              signup flow - ATS intergration setup.
            */
            setStandaloneAtsUser(false);

            /*
              2. ATS is already assigned, but we need to update access credentials
              (redirecting to Signup page and requesting access token from ATS once again).
              See AR-10828.
            */
            setAts(ats);
            /*
              Checking location, because ATS list is also fetched during
              signup flow (but we shouldn't clear tokens in this case.
             */
            if (history.location.pathname.match(urls.SIGNUP_ROUTE)) return;

            if (localStore.getItem(ACCESS_TOKEN_ORIGIN)) {
              clearTokenStorage();
              window.location.href = commonUrls.LOGIN_ROUTE;
              return;
            }
            history.push(urls.SIGNUP_ROUTE, {
              step: SignupSteps.ATSSelectionStep,
            });
            return;
          }
          setAts(ats);
          setStandaloneAtsUser(ats.type === DataSourceType.EMPLOYAATS);
        }
      );
      fetchCustomerCompanyInfo();
      fetchMatchMinerSettings();
      fetchMatchScoutSettings(companyId);
    },
    [
      fetchCustomerCompanyInfo,
      fetchMatchMinerSettings,
      fetchMatchScoutSettings,
      history,
      state.user?.role,
    ]
  );

  useEffect(() => {
    if (companyId) {
      fetchAvailableATSList(companyId);
    }
  }, [companyId, fetchAvailableATSList, logout, history]);

  const { authDataReceived } = state;
  const uiIsReady =
    authDataReceived &&
    isStandaloneAtsUser !== null &&
    licenseType !== null &&
    ats !== null;

  const requestFullVersion = useCallback(
    async function () {
      await LicenseApi.requestFullVersion().fork(
        () => {
          toast.error(phrases.TRIAL_FULL_VERSION_REQUEST_ERROR);
          /*
            Throwing error to keep 'Trial expired' toast open in
            Trial section in case of failed request.
           */
          throw new Error(phrases.TRIAL_FULL_VERSION_REQUEST_ERROR);
        },
        (res: CustomerCompanyLicenseResponse) => {
          if (res?.type) {
            setLicenseType(res?.type);
          } else {
            logout();
          }
        }
      );
    },
    [logout]
  );

  const userProfileContextValue = useMemo(() => {
    return {
      authDataReceived: state.authDataReceived,
      authError: state.authError,
      authenticated: state.authenticated,
      invalidToken: state.invalidToken,
      user: state.user,
      canAccessUserManagement,
      ats,
      isStandaloneAtsUser,
      isPassiveSearchEnabled,
      uiIsReady,
      daysLeft,
      licenseType,
      isTrialExpired,
      customerCompany,
      matchMinerSettings,
      matchScoutSettings,
      currentBackgroundImageId,
      userCredentials,
      methods: {
        getUserProfile,
        viewIntroGuide,
        requestFullVersion,
        authenticate,
        logout,
        fetchCustomerCompanyInfo,
        updateCustomerCompanyInfo,
        updateCustomerCompanySpecializations,
        changeBackgroundImage,
        setStandaloneAtsUser,
        setAts,
        setUserCredentials,
        fetchMatchMinerSettings,
        fetchMatchScoutSettings,
        requestMatchMinerForCompany,
        requestMatchScoutForCompany,
        fetchAvailableATSList,
      },
    };
  }, [
    state.authDataReceived,
    state.authError,
    state.authenticated,
    state.invalidToken,
    state.user,
    canAccessUserManagement,
    ats,
    isStandaloneAtsUser,
    isPassiveSearchEnabled,
    uiIsReady,
    daysLeft,
    licenseType,
    isTrialExpired,
    customerCompany,
    matchMinerSettings,
    matchScoutSettings,
    currentBackgroundImageId,
    userCredentials,
    getUserProfile,
    viewIntroGuide,
    requestFullVersion,
    authenticate,
    logout,
    fetchCustomerCompanyInfo,
    updateCustomerCompanyInfo,
    updateCustomerCompanySpecializations,
    changeBackgroundImage,
    fetchMatchMinerSettings,
    fetchMatchScoutSettings,
    requestMatchMinerForCompany,
    requestMatchScoutForCompany,
    fetchAvailableATSList,
  ]);

  return (
    <CustomerProfileContext.Provider value={userProfileContextValue}>
      {children}
    </CustomerProfileContext.Provider>
  );
};

CustomerProfileProvider.displayName = 'CustomerProfileProvider';

/* @deprecated
 * This is a custom consumer that connects our context from `use-context-selector` with
 * the class components that don't have hooks. Please don't use it with
 * Functional Components since it will be deleted in the future. Use `useContext` instead
 *  */
export const LegacyCustomerProfileContextConsumer = ({
  children,
}: {
  children: any;
}) =>
  attachContextToReactComponent(children, useContext(CustomerProfileContext));

export const useCustomerProfileContext = <Selected,>(
  selector: (state: CustomerProfileContextT) => Selected
) => {
  return useContextSelector(CustomerProfileContext, selector);
};

export const useCustomerProfileMethods = () => {
  return useEqualContextSelector(
    CustomerProfileContext,
    (state: CustomerProfileContextT) => state.methods,
    R.shallowEqualObjects
  );
};
