import { ReactNode } from 'react';
import { Alert } from '@air/api';
import { SearchCriteriaAlertType } from '@air/api/models/SearchCriteriaAlertType';
import * as phrases from '@air/constants/phrases';

/**
 * @description: The function maps alert names from SearchCriteriaAlertType enum to strings
 * Texts are defined for customer portal. Applicant portal mostly uses its own local mappers.
 * todo: add a mapper for applicant-portal or think how to extend this mapper.
 * @param alert
 * @return { ReactNode } no need to specify explicitly string since it's one of the subtypes of ReactNode
 */
export const convertAlertNameToString = (alert: Alert | null): ReactNode => {
  if (!alert) return null;

  switch (alert.name) {
    case SearchCriteriaAlertType.SKILLMISSED:
    case SearchCriteriaAlertType.CERTIFICATEMISSED:
    case SearchCriteriaAlertType.EDUCATIONDEGREEMISSED:
    case SearchCriteriaAlertType.EDUCATIONMAJORMISSED:
    case SearchCriteriaAlertType.EDUCATIONINSTITUTIONMISSED:
    case SearchCriteriaAlertType.COMPANYEXPERIENCEMISSED:
    case SearchCriteriaAlertType.INDUSTRYEXPERIENCEMISSED:
    case SearchCriteriaAlertType.COMPANYSIZEEXPERIENCEMISSED:
    case SearchCriteriaAlertType.COMPANYSIZEEXPERIENCEMISSEDUS:
    case SearchCriteriaAlertType.TITLEEXPERIENCEMISSED:
    case SearchCriteriaAlertType.TITLECATEGORYEXPERIENCEMISSED:
    case SearchCriteriaAlertType.PROFESSIONALEXPERIENCEMISSED:
    case SearchCriteriaAlertType.MANAGERIALTITLESMISSED:
    case SearchCriteriaAlertType.ITEXPERIENCEMISSED:
    case SearchCriteriaAlertType.LOCATIONMISSED:
      return phrases.ALERT_MISSING;
    case SearchCriteriaAlertType.SKILLMISSEDINWORKHISTORY:
    case SearchCriteriaAlertType.PROFESSIONALEXPERIENCECLARIFICATION:
    case SearchCriteriaAlertType.MANAGERIALTITLESCLARIFICATION:
      return phrases.ALERT_LACK_OF_INFORMATION;
    case SearchCriteriaAlertType.SKILLEXPERIENCEDISCREPANCY:
      return phrases.ALERT_EXPERIENCE_DISCREPANCY;
    case SearchCriteriaAlertType.SKILLRECENTEXPERIENCEMISMATCH:
    case SearchCriteriaAlertType.COMPANYRECENTEXPERIENCEMISMATCH:
    case SearchCriteriaAlertType.INDUSTRYRECENTEXPERIENCEMISMATCH:
    case SearchCriteriaAlertType.COMPANYSIZERECENTEXPERIENCEMISMATCH:
    case SearchCriteriaAlertType.TITLERECENTEXPERIENCEMISMATCH:
    case SearchCriteriaAlertType.TITLECATEGORYRECENTEXPERIENCEMISMATCH:
    case SearchCriteriaAlertType.MANAGERIALRECENTEXPERIENCEMISMATCH:
      return phrases.ALERT_NOT_RECENT;
    case SearchCriteriaAlertType.SKILLHIGHEXPERIENCE:
    case SearchCriteriaAlertType.COMPANYHIGHEXPERIENCE:
    case SearchCriteriaAlertType.INDUSTRYHIGHEXPERIENCE:
    case SearchCriteriaAlertType.COMPANYSIZEHIGHEXPERIENCE:
    case SearchCriteriaAlertType.TITLEHIGHEXPERIENCE:
    case SearchCriteriaAlertType.TITLECATEGORYHIGHEXPERIENCE:
    case SearchCriteriaAlertType.PROFESSIONALHIGHEXPERIENCE:
    case SearchCriteriaAlertType.MANAGERIALHIGHEXPERIENCE:
    case SearchCriteriaAlertType.ITEXPERIENCEHIGH:
      return phrases.ALERT_OVER_QUALIFIED;
    case SearchCriteriaAlertType.SKILLLOWEXPERIENCE:
    case SearchCriteriaAlertType.COMPANYLOWEXPERIENCE:
    case SearchCriteriaAlertType.INDUSTRYLOWEXPERIENCE:
    case SearchCriteriaAlertType.COMPANYSIZELOWEXPERIENCE:
    case SearchCriteriaAlertType.TITLELOWEXPERIENCE:
    case SearchCriteriaAlertType.TITLECATEGORYLOWEXPERIENCE:
      return phrases.ALERT_LACK_OF_EXPERIENCE;
    case SearchCriteriaAlertType.PROFESSIONALLOWEXPERIENCE:
    case SearchCriteriaAlertType.MANAGERIALLOWEXPERIENCE:
    case SearchCriteriaAlertType.ITEXPERIENCELOW:
      return phrases.ALERT_INSUFFICIENT;
    case SearchCriteriaAlertType.SKILLNOPRACTICALEXPERIENCE:
      return phrases.ALERT_NO_PRACTICAL_EXPERIENCE;
    case SearchCriteriaAlertType.SKILLNONPRIMARYLANGUAGE:
      return phrases.ALERT_NON_PRIMARY_LANGUAGE;
    case SearchCriteriaAlertType.SKILLMISSEDDETAILS:
      return phrases.ALERT_NO_DETAILS;
    case SearchCriteriaAlertType.EDUCATIONDEGREEMISMATCH:
    case SearchCriteriaAlertType.EDUCATIONMAJORMISMATCH:
    case SearchCriteriaAlertType.EDUCATIONINSTITUTIONMISMATCH:
    case SearchCriteriaAlertType.LOCATIONMISMATCH:
      return phrases.ALERT_MISMATCH;
    case SearchCriteriaAlertType.EDUCATIONGRADUATEYEARMISSED:
    case SearchCriteriaAlertType.EDUCATIONCURRENTYEARGRADUATEDETAILSMISSED:
      return phrases.ALERT_GRADUATED;
    case SearchCriteriaAlertType.EDUCATIONGRADUATEFUTUREYEAR:
      return phrases.ALERT_EXPECTED;
    case SearchCriteriaAlertType.COMPANYEXPERIENCEEXCLUDEMISMATCH:
    case SearchCriteriaAlertType.INDUSTRYEXPERIENCEEXCLUDEMISMATCH:
    case SearchCriteriaAlertType.COMPANYSIZEEXPERIENCEEXCLUDEMISMATCH:
      return phrases.ALERT_EXCLUDED_COMPANY;
    case SearchCriteriaAlertType.MANAGERIALPEOPLELOWEXPERIENCE:
      return phrases.ALERT_TEAM_IS_TOO_SMALL;
    case SearchCriteriaAlertType.MANAGERIALPEOPLEHIGHEXPERIENCE:
      return phrases.ALERT_TEAM_IS_TOO_BIG;
    case SearchCriteriaAlertType.MANAGERIALPEOPLEEXPERIENCEMISSED:
      return phrases.ALERT_TEAM_SIZE_IS_MISSING;
    case SearchCriteriaAlertType.LOCATIONTOOFAR:
      return phrases.ALERT_TOO_FAR;
    case SearchCriteriaAlertType.LOCATIONMISSINGCITY:
      return phrases.ALERT_MISSING_CITY;
    case SearchCriteriaAlertType.LOCATIONMISSINGSTATE:
      return phrases.ALERT_MISSING_STATE;
    default:
      return null;
  }
};
