import { CardConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import {
  getCandidateRoleCollapsedCardMatchResolutionLabel,
  getRoleCriteriaCardStackTitleLabel,
  getRoleCriteriaCardTitleLabel,
  getRoleSelectValueTooltipProps,
} from '@air/domain/SearchCriteriaCards/RoleCard/roleCardHelpers';

export const roleCardConfig: CardConfig = {
  getMainCardTooltipProps: getRoleSelectValueTooltipProps,
  getStackItemTooltipProps: getRoleSelectValueTooltipProps,
  getSelectDropDownOptionTooltipProps: getRoleSelectValueTooltipProps,
  getCriteriaCardTitleLabel: getRoleCriteriaCardTitleLabel,
  getCriteriaCardStackTitleLabel: getRoleCriteriaCardStackTitleLabel,
  getLineupCardStackTitleLabel: getRoleCriteriaCardStackTitleLabel,
  getReadOnlyCardStackTitleLabel: getRoleCriteriaCardStackTitleLabel,
  getCandidateCollapsedCardMatchResolutionLabel:
    getCandidateRoleCollapsedCardMatchResolutionLabel,
};
