import React, { useState } from 'react';
import classNames from 'classnames';
import * as urls from 'constants/urls';
import { Button, Paragraph, ProgressButton, SvgIcon } from '@air/components';

import {
  isMatchServiceRequested,
  isMatchServiceSetupInProgress,
  isMatchServiceSetupFailed,
  isMatchServiceDisabled,
} from 'domain/CustomerProfile/matchServiceSettings';

import * as phrases from 'constants/phrases';

import styles from './MatchServiceRequest.css';
import {
  useCustomerProfileMethods,
  useCustomerProfileContext,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';
import { Link } from 'react-router-dom';
import { LineupTabs } from '@air/constants/tabs';
import { isMatchScoutTab } from 'domain/CandidateData/CandidateLineupData';

type MatchMinerRequestT = {
  isReadOnly?: boolean;
  isCustomerAdmin?: boolean;
  currentTab: LineupTabs;
};

// exports / component definitions
export const MatchServiceRequest: React.FC<MatchMinerRequestT> = ({
  isReadOnly,
  isCustomerAdmin,
  currentTab = LineupTabs.MatchMiner,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const matchMinerSettings = useCustomerProfileContext(
    customerProfileSelectors.matchMinerSettings
  );

  const matchScoutSettings = useCustomerProfileContext(
    customerProfileSelectors.matchScoutSettings
  );

  const { requestMatchMinerForCompany, requestMatchScoutForCompany } =
    useCustomerProfileMethods();

  const MATCH_SERVICE_DATA = {
    [LineupTabs.MatchMiner]: {
      bgFigureSrc: '/assets/images/match-miner-request-bg.png',
      logoSrc: '/assets/images/match-miner-tab-logo.png',
      description: phrases.MATCH_MINER_REQUEST_GENERAL_DESCRIPTION,
      requestCb: requestMatchMinerForCompany,
      getAccessText: phrases.MATCH_MINER_GET_ACCESS_DESC,
      requestedText: phrases.MATCH_MINER_ACCESS_REQUESTED_DESC_START,
      settings: matchMinerSettings,
    },
    [LineupTabs.MatchScout]: {
      bgFigureSrc: '/assets/images/match-scout-request-bg.png',
      logoSrc: '/assets/images/match-scout-tab-logo.png',
      description: phrases.MATCH_SCOUT_REQUEST_GENERAL_DESCRIPTION,
      requestCb: requestMatchScoutForCompany,
      getAccessText: phrases.MATCH_SCOUT_GET_ACCESS_DESC,
      requestedText: phrases.MATCH_SCOUT_ACCESS_REQUESTED_DESC_START,
      settings: matchScoutSettings,
    },
  };

  const {
    bgFigureSrc,
    logoSrc,
    description,
    requestCb,
    getAccessText,
    requestedText,
    settings,
  } =
    MATCH_SERVICE_DATA[
      currentTab as LineupTabs.MatchScout | LineupTabs.MatchMiner
    ];

  const candidatesAmount = matchMinerSettings?.indexedCandidatesCount;
  const isMatchMinerServiceDisabled = isMatchServiceDisabled(settings?.status);
  const matchMinerRequestBtnDisabled =
    !isCustomerAdmin || !isMatchMinerServiceDisabled || isReadOnly;

  const handleRequestMatchMinerForCompany = async () => {
    await setIsLoading(true);
    await requestCb();
    await setIsLoading(false);
  };

  return (
    <div className={styles.matchMinerRequest}>
      <img
        className={styles.background}
        src="/assets/images/rosette.svg"
        width="100%"
        height="100%"
      />
      <img
        className={classNames(styles.backgroundFigure, {
          [styles.matchScoutBgFigure]: isMatchScoutTab(currentTab),
        })}
        src={bgFigureSrc}
      />
      <div className={styles.content}>
        <img className={styles.logo} src={logoSrc} />
        <Paragraph className={styles.description}>{description}</Paragraph>
        {isMatchMinerServiceDisabled && (
          <div className={styles.controls}>
            <Button
              variant={Button.variants.POSITIVE_CONFIRM}
              className={styles.actionButton}
              disabled={matchMinerRequestBtnDisabled}
              onClick={handleRequestMatchMinerForCompany}
              isLoading={isLoading}
            >
              {phrases.MATCH_SERVICE_GET_ACCESS}
            </Button>
            {!isCustomerAdmin && (
              <Paragraph className={styles.controlsDescription}>
                {getAccessText}
              </Paragraph>
            )}
          </div>
        )}
        <div className={styles.controls}>
          {isMatchServiceRequested(settings?.status) && (
            <>
              <ProgressButton
                className={styles.actionButton}
                text={phrases.MATCH_SERVICE_ACCESS_REQUESTED}
              >
                <SvgIcon
                  icon="check-icon-thin"
                  className={styles.checkSuccessful}
                />
              </ProgressButton>
              <Paragraph
                className={classNames(
                  styles.controlsDescription,
                  styles.positive
                )}
              >
                {requestedText}
                <br />
                {phrases.MATCH_SERVICE_ACCESS_REQUESTED_DESC_END}
              </Paragraph>
            </>
          )}
          {isMatchServiceSetupInProgress(settings?.status) && (
            <>
              <ProgressButton
                className={styles.actionButton}
                text={phrases.MATCH_SERVICE_SETUP_IN_PROGRESS}
                isLoading
              />
              <Paragraph
                className={classNames(
                  styles.controlsDescription,
                  styles.positive
                )}
                dangerouslySetInnerHTML={{
                  __html:
                    phrases.getMatchMinerSetupProgressDesc(candidatesAmount),
                }}
              />
            </>
          )}
          {isMatchServiceSetupFailed(settings?.status) && (
            <>
              <Button
                variant={Button.variants.NEGATIVE_SECONDARY}
                className={styles.actionButton}
              >
                {phrases.MATCH_SERVICE_SETUP_FAILED}
              </Button>
              <Paragraph className={styles.controlsDescription}>
                {phrases.MATCH_SERVICE_SETUP_FAILED_DESC}
                <Link to={urls.HELP_CENTER_ROUTE} className={styles.link}>
                  {phrases.MATCH_SERVICE_SETUP_FAILED_DESC_ACTION}
                </Link>
                .
              </Paragraph>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
