import React from 'react';
import { TooltipWrapper } from '@air/components';
import styles from 'features/DraftSection/NewSearch/CardsSection.css';
import classNames from 'classnames';

export const CardsSection: React.FC<{
  sectionName: string;
  tooltipText?: string;
  className?: string;
}> = ({ sectionName, children, tooltipText, className }) => {
  return (
    <div>
      <TooltipWrapper
        placement="top-start"
        flexGrow={false}
        enabled={!!tooltipText}
        tooltip={tooltipText}
        triggerClassName={styles.sectionTooltip}
      >
        <h1 className={styles.sectionHeading}>
          #<span className={styles.sectionTitleHeading}>{sectionName}</span>
        </h1>
      </TooltipWrapper>
      <div className={classNames(styles.cardsContainer, className)}>
        {children}
      </div>
    </div>
  );
};
