import {
  CustomerCreateRequest,
  CustomerResponse,
  LoggedInCustomerInfoResponse,
  RoleName,
  InternalStatus,
  DataSourceType,
} from '@air/api';
import { Task } from '@air/utils/fp';
import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import { mapUsersToUserData, UserData } from 'domain/UserManagement/User';

export const resetPassword = (userEmail: string): Task =>
  Http.post(urls.USER_RESET_PASSWORD_API)
    .bind(Http.withJsonBody({ userEmail }))
    .toTask();

export const setNewPassword = (
  userId: number,
  linkToken: string,
  password: string
): Task =>
  Http.post(urls.makeSetUserPasswordUrl(userId))
    .bind(Http.withQueryParams({ linkToken }))
    .bind(Http.withJsonBody({ password }))
    .toTask();

export const setCompanyATS = (
  companyId: number,
  atsType: DataSourceType,
  connectionSettings: any = {}
): Task =>
  Http.post(urls.makeCompanyDataSourceUrl(companyId))
    .bind(Http.withJsonBody({ companyId, type: atsType, connectionSettings }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const updateCompanyATS = ({
  companyId,
  atsType,
  connectionSettings = {},
  atsId,
}: {
  companyId: number;
  atsType: DataSourceType;
  connectionSettings: any;
  atsId: number;
}): Task =>
  Http.put(urls.makeCompanyDatasourceUpdateUrl(companyId, atsId))
    .bind(Http.withJsonBody({ type: atsType, connectionSettings }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const getLeverAuthorizationForm = (datasourceId: number): Task =>
  Http.get(urls.LEVER_AUTHORIZATION_API)
    .bind(Http.withQueryParams({ datasourceId }))
    .toTask();

// todo: this should be refactored asap in scope of pagination feature
const USERS_LIMIT = 300;

export const getUsers = (
  currentUserInfo: LoggedInCustomerInfoResponse,
  filter: string,
  role: RoleName[],
  status: InternalStatus[]
): Task<UserData> => {
  if (!currentUserInfo) return Task.of(null);
  return Http.get(urls.USER_MANAGEMENT_API)
    .bind(
      Http.withQueryParams({
        criteria: filter,
        size: USERS_LIMIT,
        role,
        status,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson)
    .map(mapUsersToUserData(currentUserInfo));
};

export const addNewUser = (
  userData: CustomerCreateRequest
): Task<CustomerResponse> => {
  return Http.post(urls.USER_MANAGEMENT_API)
    .bind(Http.withJsonBody(userData))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateUser = (
  userData: CustomerCreateRequest
): Task<CustomerResponse> => {
  return Http.patch(urls.USER_MANAGEMENT_API)
    .bind(Http.withJsonBody(userData))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
