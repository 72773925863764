import * as ApiModel from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  normalizeSearchCriteriaExperience,
  SearchCriteriaExperienceT,
} from './BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import * as phrases from 'constants/phrases';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

export enum JobType {
  ANYJOBTYPE = 'ANY_JOB_TYPE',
  ITJOBSONLY = 'IT_JOBS_ONLY',
}

export const jobTypeOptions = {
  [JobType.ANYJOBTYPE]: {
    value: JobType.ANYJOBTYPE,
    label: phrases.EXPERIENCE_ANY_JOB_TYPE_PLACEHOLDER,
  },
  [JobType.ITJOBSONLY]: {
    value: JobType.ITJOBSONLY,
    label: phrases.EXPERIENCE_IT_JOBS_ONLY_PLACEHOLDER,
  },
};

export const jobTypeOptionsList = Object.values(jobTypeOptions);

export type JobTypeSelectedValueT =
  typeof jobTypeOptions[keyof typeof jobTypeOptions];

export interface ProfessionalCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.professional;
  experience: SearchCriteriaExperienceT | null;
  itExperience: boolean;
  label: string;
}

export const isProfessional = (
  item: BaseSearchCriteriaData
): item is ProfessionalCriteriaData => item.cardType === CardType.professional;

export const getProfessionalTitle = (itExperience: boolean): string =>
  itExperience
    ? phrases.EXPERIENCE_IT_PROFESSIONAL_TITLE_PLACEHOLDER
    : phrases.EXPERIENCE_PROFESSIONAL_TITLE_PLACEHOLDER;

export const getProfessionalCriteriaDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
}: AddCriteriaInitials): ProfessionalCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  initialCardStatus,
  cardType: CardType.professional,
  importance: criteriaImportanceOptions[importance],
  experience: null,
  itExperience: false,
  label: phrases.EXPERIENCE_PROFESSIONAL_TITLE_PLACEHOLDER,
});

export const mapExperienceToProfessionalCriteriaData = ({
  professional,
  idx,
}: Pick<
  ApiModel.Experience,
  'professional' | 'idx'
>): ProfessionalCriteriaData => ({
  idx,
  id: idx,
  key: genId(),
  cardType: CardType.professional,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  experience: professional.experience,
  itExperience: professional.itExperience,
  importance:
    criteriaImportanceOptions[
      professional.importance || ApiModel.SearchCriteriaImportanceEnum.MANDATORY
    ],
  label: getProfessionalTitle(professional.itExperience),
});

export const mapProfessionalCriteriaDataToExperience = (
  professional: ProfessionalCriteriaData
): ApiModel.Experience => ({
  idx: professional.idx,
  professional: {
    experience: {
      ...normalizeSearchCriteriaExperience(professional.experience),
    },
    itExperience: professional.itExperience,
    importance: professional.importance.value,
  },
  title: null,
  managerial: null,
});
