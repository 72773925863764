import * as Http from '@air/utils/http';
import * as urls from '@air/constants/apiEndpoints';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';

export const logout = () => Http.post(urls.LOGOUT_API).toTask();

export const authenticate = (body: any) =>
  Http.post(urls.LOGIN_API)
    .bind(Http.withJsonBody(body))
    .toAuthTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const getUserProfile = (profileUrl: string) =>
  Http.get(profileUrl)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

// regularLogin is true by default on BE, it means that
// when we log in, BE sets showIntroGuide to true if it is a first login or false if it is not
// when the user resets password we need to make BE ignore this request do that the next /current request is a first login
// in order to show intro guide
export const getApplicantProfile = (params: {
  linkToken: string;
  regularLogin?: boolean;
}) =>
  Http.post(urls.APPLICANT_TOKEN_URL)
    .bind(Http.withJsonBody(params))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
