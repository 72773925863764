// imports from vendor deps
import React from 'react';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'

// imports from styles
import styles from './SideList.css';

// component proptypes
type Props = {
  className?: string;
  children?: React.ReactNode;
};

// exports / component definitions
export const SideList: React.FC<Props> = React.memo(
  ({ children, className = '' }) => {
    return (
      <div className={classNames(styles.sideList, className)}>{children}</div>
    );
  }
);

SideList.displayName = 'SideList';
