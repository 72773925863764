// imports from vendor deps
import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import R from '@air/third-party/ramda';

// imports from types
import { DraggedItemT } from 'context';
import {
  DataSourceResponse,
  JobDescriptionFullResponse,
  JobDescriptionItem,
  JobDescriptionLocationResponse,
} from '@air/api/models';

// imports from 'components'
import { Button, FeatureView, JobTitle } from 'components';

// imports from 'features'
// imports from 'constants'
import { KANBAN_TYPES } from 'constants/kanban';
import {
  EMPTY_JOB,
  useJobsProviderMethods,
  useJobsProviderContext,
} from 'providers/JobsProvider';
import * as phrases from 'constants/phrases';

// import from images
// import {} from 'images'
// imports from helpers
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit } from 'hooks/usePubSub';
import {
  customerProfileSelectors,
  appSelectors,
  jobsProviderSelectors,
} from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  CLICK_DEBOUNCE_TIME_LONG,
  TOOLTIP_DELAY_TIME_LONG,
} from '@air/constants/app';

// imports from styles
import styles from './JobsSection.css';
import commonStyles from 'styles/commonStyles.css';
import { UIText, TooltipWrapper, Paragraph } from '@air/components';
import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';

// component proptypes
type Props = RouteComponentProps<{ id: string; dataSourceId: string }>;

// exports / component definitions
export const JobsSection: React.FC<Props> = React.memo((props) => {
  const jobsList = useJobsProviderContext(jobsProviderSelectors.jobsList);
  const jobRequisitionDetails = useJobsProviderContext(
    jobsProviderSelectors.jobRequisitionDetails
  );
  const { fetchJobDescription, clearJobDescription } = useJobsProviderMethods();
  const { match } = props;

  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );

  const jobId = match?.params?.id;
  const dataSourceId = match?.params?.dataSourceId;

  const isLeftSideBarCollapsed = useAppContext(
    appSelectors.isLeftSideBarCollapsed
  );

  useEffect(() => {
    if (dataSourceId && jobId) {
      fetchJobDescription(dataSourceId, jobId);
    }
  }, [dataSourceId, jobId, fetchJobDescription]);

  useEffect(() => {
    if (!jobId) {
      if (jobRequisitionDetails) {
        /*
          Clearing last opened JD to avoid flash of previous
          content on next opening.
         */
        clearJobDescription();
      }
      return;
    }
  }, [jobId, jobRequisitionDetails, clearJobDescription]);

  /*
    Finding currentJob in jobsList allows us to display correct job name,
    before we finish fetching rest of job information.
  */
  const currentJob = useMemo(() => {
    return (
      jobRequisitionDetails ||
      jobsList.items.find(R.propEq('externalId', jobId)) ||
      EMPTY_JOB
    );
  }, [jobRequisitionDetails, jobsList.items, jobId]);

  const onCreateDraftButtonClick = useCallback(() => {
    emit(APP_EVENTS.OPEN_JOB_PANEL, {
      item: {
        ...currentJob,
        name: currentJob.title,
        searchId: currentJob.externalId,
        jobRequisitionDetails,
      },
    });
  }, [currentJob, jobRequisitionDetails]);

  const jobDescriptionString = (
    currentJob as JobDescriptionFullResponse
  ).description?.trim();

  return (
    <div className={styles.jobsSection}>
      <FeatureView.LeftSidebar>
        <>
          {currentJob && (
            <>
              <UIText
                tiny
                bold
                className={classNames(
                  styles.leftSidebarHeader,
                  commonStyles.leftSidebarHeader,
                  {
                    [commonStyles.isCollapsed]: isLeftSideBarCollapsed,
                  }
                )}
              >
                {phrases.ATS_JOB_HEADER}
              </UIText>
              <TooltipWrapper
                delayShow={TOOLTIP_DELAY_TIME_LONG}
                tooltip={phrases.MAKE_A_DRAFT_BUTTON}
                enabled={isLeftSideBarCollapsed}
                flexGrow={false}
              >
                <Button
                  className={commonStyles.leftSidebarButton}
                  variant={Button.variants.POSITIVE_CONFIRM}
                  icon="make-draft-icon"
                  debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                  onClick={onCreateDraftButtonClick}
                >
                  {phrases.MAKE_A_DRAFT_BUTTON}
                </Button>
              </TooltipWrapper>
            </>
          )}
        </>
      </FeatureView.LeftSidebar>
      <FeatureView.Content className={styles.jobDescriptionFeature}>
        {currentJob && (
          <div className={styles.jobWrapper}>
            <JobTitle
              disabled
              className={styles.jobTitleWrapper}
              jobRequisitionDetails={currentJob}
              isBeforeDraftCreation
              isStandaloneAtsUser={isStandaloneAtsUser}
              title={currentJob.title || phrases.JOB_DESCRIPTION_DEFAULT_TITLE}
            />
            <div className={styles.jobDescriptionWrapper}>
              {currentJob === EMPTY_JOB && (
                <Paragraph>{phrases.LOADING_PLACEHOLDER}</Paragraph>
              )}
              {currentJob !== EMPTY_JOB &&
                (jobDescriptionString ? (
                  <div className={styles.jobDescriptionInnerContent}>
                    <div className={styles.jobDescription}>
                      <Paragraph>{jobDescriptionString}</Paragraph>
                    </div>
                  </div>
                ) : (
                  <Paragraph>
                    {phrases.READONLY_NO_DESCRIPTION_PLACEHOLDER}
                  </Paragraph>
                ))}
            </div>
          </div>
        )}
      </FeatureView.Content>
    </div>
  );
});
JobsSection.displayName = 'JobsSection';
JobsSection.defaultProps = {};

export type JobItemComponentT = {
  ats: DataSourceResponse;
  isDraggable: boolean;
  isActive: boolean;
  item: JobSectionItemDragObject & DraggedItemT;
  dragWrapperClassName?: string;
  jobRequisitionDetails: JobDescriptionFullResponse;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>, searchId: number) => void;
};

export type JobSectionItemDragObject = JobDescriptionItem & {
  ats: JobItemComponentT['ats'];
  type?: string;
  jobId: JobDescriptionItem['externalId'];
  locations?: JobDescriptionLocationResponse[];
  jobRequisitionDetails?: JobDescriptionFullResponse;
};

export function transformJobItem(itemProps: {
  ats: JobItemComponentT['ats'];
  jobRequisitionDetails?: JobDescriptionFullResponse;
  item: JobDescriptionItem;
}) {
  const { ats, item, jobRequisitionDetails } = itemProps;
  return {
    ...item,
    ats,
    jobId: item.externalId,
    type: KANBAN_TYPES.jobCard,
    locations: item.locations,
    jobRequisitionDetails,
  };
}
