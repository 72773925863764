import React from 'react';
// @ts-ignore commented out due to the outdated @types/react-router-dom
// updating needs significant amount of time to update all the dependent types
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import * as urls from '@air/constants/commonUrls';

type Props = {
  isAllowed: boolean;
  redirect?: string;
  path: string;
  // Exact protected route only protects from accessing a specific path.
  exact?: boolean;
  render?: RouteProps['render'];
  children?: RouteProps['children'];
};

export const ProtectedRoute: React.FC<Props> = ({
  isAllowed,
  redirect = urls.LOGIN_ROUTE,
  path,
  exact,
  render,
  children,
}) => {
  const location = useLocation();

  if (typeof isAllowed !== 'boolean') return null;

  const shouldRedirect = exact
    ? !isAllowed && location?.pathname === path
    : !isAllowed && location?.pathname.includes(path);

  return !shouldRedirect ? (
    <Route path={path} render={render} exact={exact}>
      {children}
    </Route>
  ) : (
    <Redirect from={path as string} to={redirect} />
  );
};
ProtectedRoute.displayName = 'ProtectedRoute';
