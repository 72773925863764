import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';

import { SvgIcon, FilterInput } from 'components';
import * as phrases from 'constants/phrases';
import styles from './FilterWithPlaceholder.css';
import { Paragraph } from '@air/components';

type FilterWithPlaceholder = {
  totalCount?: number;
  placeholderTitle: string;
  onChange: (query: string) => void;
  className?: string;
  isDisabled?: boolean;
  defaultValue?: string;
};

export const FilterWithPlaceholder: React.FC<FilterWithPlaceholder> =
  React.memo(
    ({
      totalCount,
      placeholderTitle,
      className,
      onChange,
      isDisabled = false,
      defaultValue = '',
    }) => {
      const methods = useForm<{ searchValue: string }>({
        defaultValues: { searchValue: defaultValue },
        shouldUnregister: true,
      });
      const { handleSubmit } = methods;

      const onSubmit = useCallback(
        ({ searchValue }: { searchValue: string }) => {
          onChange(searchValue);
        },
        [onChange]
      );
      const onSearchInputChange = useMemo(
        () => handleSubmit(onSubmit),
        [onSubmit, handleSubmit]
      );
      const [isSearchFocused, setSearchFocus] = useState(!!defaultValue);
      return (
        <div
          className={classNames(styles.filterField, className, {
            [styles.emptyFilterField]: isDisabled,
          })}
        >
          <div
            className={classNames(styles.filterPlaceholder, {
              [styles.hidden]: isSearchFocused,
              [styles.disabled]: isDisabled,
            })}
          >
            <Paragraph className={styles.filterTitle}>
              {placeholderTitle}
            </Paragraph>
            {!isDisabled && (
              <>
                <SvgIcon icon="search-icon" className={styles.filterIcon} />
                {totalCount && (
                  <span className={styles.totalCount}>{totalCount}</span>
                )}
              </>
            )}
          </div>

          {!isDisabled && (
            <FormProvider {...methods}>
              <form onSubmit={onSearchInputChange}>
                <FilterInput
                  className={classNames(styles.searchInput, {
                    [styles.hidden]: !isSearchFocused,
                  })}
                  isShrinking={false}
                  onFocus={() => setSearchFocus(true)}
                  onBlur={(value: string) => {
                    if (value) return;
                    setSearchFocus(false);
                  }}
                  onChangeInput={onSearchInputChange}
                  name="searchValue"
                  placeholder={phrases.SEARCH_FILTER_PLACEHOLDER_FILTER}
                />
              </form>
            </FormProvider>
          )}
        </div>
      );
    }
  );
