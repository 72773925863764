import React from 'react';
import * as phrases from 'constants/phrases';
import { CandidateEmailPreviewResponse } from '@air/api';
import { SelectedEmailEntityT } from 'domain/EmailConfig/EmailTemplates';
import styles from './EmailPreviews.css';

import { Header, Paragraph, UIText } from '@air/components';

export const GeneralTemplatePreview = ({
  email,
}: {
  email: SelectedEmailEntityT;
}) => {
  return (
    <div className={styles.previewWrapper}>
      <div className={styles.headerWrapper}>
        <UIText tiny bold className={styles.text}>
          {phrases.EMAIL_TEMPLATE_SUBJECT}
        </UIText>
        <Header className={styles.emailSubject} level={3} bold>
          {email.subject}
        </Header>
      </div>

      <div className={styles.sectionsWrapper}>
        {email.sections.map((section) => {
          return (
            <Paragraph
              key={`${section.idx}-${section.type}`}
              className={styles.paragraph}
            >
              {section.content || section.alternativeContent}
            </Paragraph>
          );
        })}
      </div>
    </div>
  );
};

export const RecipientEmailPreview = ({
  email,
}: {
  email: CandidateEmailPreviewResponse;
}) => {
  if (!email) {
    return null;
  }

  return (
    <div className={styles.previewWrapper}>
      <div className={styles.headerWrapper}>
        <UIText tiny bold className={styles.text}>
          {phrases.EMAIL_TEMPLATE_SUBJECT}
        </UIText>
        <Header className={styles.emailSubject} level={3} bold>
          {email.subject}
        </Header>
      </div>
      <div className={styles.sectionsWrapper}>
        <div className={styles.body}>
          {email?.body
            .split('\n')
            .filter(Boolean)
            .map((item) => (
              <Paragraph key={item} className={styles.paragraph}>
                {item}
              </Paragraph>
            ))}
        </div>
      </div>
    </div>
  );
};
