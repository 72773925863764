// imports from vendor deps
import React, { useCallback, useMemo } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
import { RangeDropdown } from '@air/components';

// imports from 'constants';
import * as phrases from '@air/constants/phrases';

// import from images
// import {} from 'images'

// imports from helpers
import {
  generateItemsForRangeDropdown,
  isLimitNotDefined,
  RangeIndex,
  MIN_RECENT_EXPERIENCE_VALUE,
  MAX_RECENT_EXPERIENCE_VALUE,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import { useRangeVisibility } from '@air/components/SelectRangeWidgets/useRangeVisibility';

// imports from styles
import styles from './RecentExperienceWidget.css';

import { RangeValue } from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import { RangeItem } from '@air/components/RangeDropdown/RangeDropdown';

// const
const MIN_RECENT_EXPERIENCE_INDEX = 0;
const MAX_RECENT_EXPERIENCE_INDEX = 10;

const yearsRange = R.range(
  MIN_RECENT_EXPERIENCE_VALUE,
  MAX_RECENT_EXPERIENCE_VALUE
);

const dropdownItems = [
  ...generateItemsForRangeDropdown(yearsRange),
  { label: '\u2731', value: MAX_RECENT_EXPERIENCE_VALUE },
] as RangeValue[];

const getIndexByValue = (value: number, items: RangeItem[]) =>
  R.findIndex(R.propEq('value', value))(items);

// component proptypes
type Props = {
  value: any;
  onChange?: (value: number) => void;
  className?: string;
  isReadOnly?: boolean;
};

type FieldProps = {
  value: number | null;
  className?: string;
  onClick?: () => void;
};

// exports / component definitions
export const RecentExperienceField: React.FC<FieldProps> = ({
  value,
  className = '',
  onClick = R.always,
}) => {
  const isAnyExperience = R.isNullOrEmpty(value);

  return (
    <div
      className={classNames(styles.recentExperienceField, className)}
      onClick={onClick}
    >
      {!isAnyExperience && (
        <span
          tabIndex={0}
          className={styles.experienceFieldValue}
          onFocus={onClick}
          onMouseDown={(event: any) => event.preventDefault()}
        >
          {value}
        </span>
      )}
      <label className={styles.experienceFieldLabel}>
        {value >= MIN_RECENT_EXPERIENCE_VALUE &&
        value !== MAX_RECENT_EXPERIENCE_VALUE
          ? `year${value === 1 ? '' : 's'}\nrecency`
          : `${phrases.RECENT_EXPERIENCE_ANY_RECENCY_LABEL}`}
      </label>
    </div>
  );
};

type DropdownProps = {
  isOpen: boolean;
  dropdownItems: RangeItem[];
  value: any;
  onChange: (values: any) => void;
};

// exports / component definitions
const RecentExperienceDropdown: React.FC<DropdownProps> = ({
  isOpen,
  onChange,
  dropdownItems,
  value,
}) => {
  const selectedItems = useMemo(() => {
    /* if value === null only last item (value === MAX_RECENT_EXPERIENCE_VALUE)
    should be selected */
    if (isLimitNotDefined(value)) {
      return R.range(
        MAX_RECENT_EXPERIENCE_VALUE,
        MAX_RECENT_EXPERIENCE_VALUE + 1
      );
    }
    return R.range(MIN_RECENT_EXPERIENCE_INDEX, value);
  }, [value]);

  const isAnyRecentExperience = (value: any) => R.isNullOrEmpty(value);

  const selectedIndex = useMemo(() => {
    return getIndexByValue(value, dropdownItems);
  }, [value, dropdownItems]);

  const getRangeItemClass = useCallback(
    (index: RangeIndex) => {
      return {
        [styles.rangeItem]: true,
        [styles.selectedRangeItem]:
          R.includes(index, selectedItems) && !R.isNil(value),
        [styles.selectedFirstRangeItem]:
          index === MIN_RECENT_EXPERIENCE_INDEX && !R.isNil(value),
        [styles.selectedLastRangeItem]:
          index === selectedIndex && !R.isNil(value),
        [styles.anyExperienceItem]: index === MAX_RECENT_EXPERIENCE_INDEX,
      };
    },
    [value, selectedIndex, selectedItems]
  );

  const getDropdownClass = useCallback(() => {
    return {
      [styles.recentExperienceDropdown]: true,
      [styles.selectedAnyExperience]: isAnyRecentExperience(value),
    };
  }, [value]);

  const onItemSelected = useCallback(
    (index: RangeIndex) => {
      onChange(index);
    },
    [onChange]
  );

  return (
    <RangeDropdown
      isOpen={isOpen}
      dropdownClass={getDropdownClass}
      rangeItems={dropdownItems}
      rangeItemClass={getRangeItemClass}
      arrowClassName={styles.dropdownArrow}
      selectedItems={selectedItems}
      onItemSelected={onItemSelected}
    />
  );
};

export const RecentExperienceReadOnlyWidget: React.FC<{
  value: number | null;
}> = ({ value }) => {
  return (
    <RecentExperienceField
      className={styles.experienceReadonlyField}
      value={value}
    />
  );
};
RecentExperienceReadOnlyWidget.displayName = 'RecentExperienceReadOnlyWidget';

// exports / component definitions
export const RecentExperienceWidget: React.FC<Props> = ({
  value,
  onChange,
  className,
  isReadOnly = false,
}) => {
  const [
    isDropdownVisible,
    outsideClickRef,
    onDropdownTriggerClicked,
    handleKeyDown,
  ] = useRangeVisibility();

  const getValueByIndex = (index: RangeIndex) => dropdownItems[index].value;

  const onChangeDropdownValues = (index: RangeIndex) => {
    const prevSelectedIndex = getIndexByValue(value, dropdownItems);

    const newSelectedValue =
      index === MAX_RECENT_EXPERIENCE_INDEX || index === prevSelectedIndex
        ? null
        : getValueByIndex(index);

    onChange(newSelectedValue);
  };

  return isReadOnly ? (
    <RecentExperienceReadOnlyWidget value={value} />
  ) : (
    <div
      className={classNames(styles.recentExperienceWidget, {
        [styles.isDropdownOpened]: isDropdownVisible,
        className,
      })}
      ref={outsideClickRef}
      onKeyDownCapture={handleKeyDown}
    >
      <RecentExperienceField value={value} onClick={onDropdownTriggerClicked} />
      <RecentExperienceDropdown
        dropdownItems={dropdownItems}
        value={value}
        onChange={onChangeDropdownValues}
        isOpen={isDropdownVisible}
      />
    </div>
  );
};
