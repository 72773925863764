export enum FlagSettingsState {
  // card with title and description
  initial = 'initial',
  // card with title and description on the outer background
  collapsed = 'collapsed',
  // card with title and change resolution buttons
  changeResolution = 'changeResolution',
}

export enum FlagSettingsActions {
  showInitial = 'SHOW_INITIAL',
  showCollapsed = 'SHOW_COLLAPSED',
  showChangeResolution = 'SHOW_CHANGE_RESOLUTION',
}

export const FlagSettingsStateConfig = {
  [FlagSettingsState.initial]: {
    [FlagSettingsActions.showCollapsed]: FlagSettingsState.collapsed,
  },
  [FlagSettingsState.collapsed]: {
    [FlagSettingsActions.showChangeResolution]:
      FlagSettingsState.changeResolution,
    [FlagSettingsActions.showInitial]: FlagSettingsState.initial,
  },
  [FlagSettingsState.changeResolution]: {
    [FlagSettingsActions.showCollapsed]: FlagSettingsState.collapsed,
    [FlagSettingsActions.showInitial]: FlagSettingsState.initial,
  },
};
