import React, { useMemo } from 'react';
import R from '@air/third-party/ramda';
import copy from 'copy-to-clipboard';

import { AddressExtended, enrichedByPIPL } from 'domain/CandidateData';
import { ContactItem } from '../ContactItem/ContactItem';
import { GeoLocation } from '@air/api/models';
import { toast } from '@air/third-party/toast';
import * as phrases from 'constants/phrases';
import { Paragraph } from '@air/components';

const getGoogleMapsHref = ({ latitude, longitude }: GeoLocation) =>
  `http://www.google.com/maps/place/${latitude},${longitude}`;

const getLocationFromAddressInfo = (address: AddressExtended) => {
  return R.props(['city', 'state'])(address as Required<AddressExtended>)
    .filter(Boolean)
    .join(', ');
};

const makeAddressString = ({
  zipCode,
  street,
  country,
  cityState,
}: Partial<AddressExtended> & {
  cityState?: string;
}) => {
  const validAddressData = [zipCode, street, cityState, country].filter(
    Boolean
  );

  return validAddressData.join(', ');
};

export const AddressItem: React.FC<{
  address: AddressExtended;
}> = ({ address }) => {
  const { country, street, zipCode, geoLocation, sources } = address;

  const isEnrichedByPIPL = useMemo(() => {
    return enrichedByPIPL(sources);
  }, [sources]);

  const result = makeAddressString({
    zipCode,
    street,
    country,
    cityState: getLocationFromAddressInfo(address),
  });

  const onCopyIconClickHandler = () => {
    copy(result);
    toast.dark(phrases.CANDIDATE_PROFILE_CONTACTS_TOAST_TEXT_COPIED);
  };

  return (
    <ContactItem
      onIconClicked={onCopyIconClickHandler}
      isEnrichedByPIPL={isEnrichedByPIPL}
    >
      <Paragraph short>
        <a
          href={geoLocation && getGoogleMapsHref(geoLocation)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {result}
        </a>
      </Paragraph>
    </ContactItem>
  );
};
