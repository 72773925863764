import React from 'react';
import classNames from 'classnames';
import { Paragraph } from '@air/components';

import styles from './TabButton.css';

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const TabButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className={classNames(styles.tabButton, props.className)}
    >
      <Paragraph short className={styles.tabText}>
        {children}
      </Paragraph>
    </button>
  );
};
