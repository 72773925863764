import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { Card, SvgIcon } from '@air/components';
import { RoleName } from '@air/api';
import { emailRegex } from '@air/utils/strings';
import { useOutsideClick } from '@air/utils/hooks';
import * as phrases from 'constants/phrases';
import { UserCollapsedCard } from './UserCard';
import { useStateMachine } from '@air/hooks';
import { getErrorDescription } from '@air/utils/errorHandling';
import { NewUserInfo } from 'domain/UserManagement/User';
import TextareaAutosize from 'react-textarea-autosize';
import { RoleSelect } from './Common';
import styles from './UserCardStyles.css';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';

enum UserFormCardState {
  view = 'view',
  viewHovered = 'view.hovered',
  edit = 'edit',
  discarded = 'discard',
}

enum UserFormCardActions {
  openView = 'OPEN_VIEW',
  openEdit = 'OPEN_EDIT',
  discard = 'DISCARD',
  hoverView = 'HOVER_VIEW',
}

const UserFormCardStateConfig = {
  [UserFormCardState.view]: {
    [UserFormCardActions.openEdit]: UserFormCardState.edit,
    [UserFormCardActions.hoverView]: UserFormCardState.viewHovered,
  },
  [UserFormCardState.edit]: {
    [UserFormCardActions.openView]: UserFormCardState.view,
    [UserFormCardActions.discard]: UserFormCardState.discarded,
  },
  [UserFormCardState.viewHovered]: {
    [UserFormCardActions.openView]: UserFormCardState.view,
    [UserFormCardActions.openEdit]: UserFormCardState.edit,
  },
};

export const UserFormCardEdit: React.FC<{
  cardLabel: string;
  className: string;
  onSubmit: () => void;
  onCancel: () => void;
}> = ({ cardLabel, className, onSubmit, onCancel }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasErrors = !R.isNullOrEmpty(errors);

  const [outsideClickRef] = useOutsideClick(onSubmit, {
    useCapture: true,
  });
  const emailTextareaRef = useRef<HTMLTextAreaElement>(null);

  const controlledInput = useMemo(() => {
    return ({ field }: { field: ControllerRenderProps }) => (
      <input
        className={styles.fieldInput}
        placeholder={phrases.INPUT_PLACEHOLDER}
        {...field}
        onChange={(e) => {
          if (e.target.value.length <= 30) {
            const { value } = e.target;
            field.onChange(value.trimLeft());
          }
        }}
      />
    );
  }, []);

  return (
    <Card.SearchCriteriaCardContainer
      className={classNames(
        styles.userExpandedCard,
        {
          [styles.isInactive]: hasErrors,
        },
        className
      )}
    >
      <Card.SearchCriteriaEditForm ref={outsideClickRef}>
        <Card.SearchCriteriaCardLabel text={cardLabel} />
        <Card.SearchCriteriaEditFormFields
          className={styles.userExpandedCardEditFormFields}
        >
          <form onSubmit={onSubmit}>
            <div className={classNames(styles.field)}>
              <label>{phrases.FIRST_NAME_LABEL}</label>
              <Controller
                name="firstName"
                rules={{
                  required: {
                    value: true,
                    message: phrases.ERROR_MISSING_VALUE,
                  },
                }}
                control={control}
                render={controlledInput}
              />
              <span className={styles.fieldError}>
                {errors?.firstName?.message}
              </span>
            </div>
            <div className={classNames(styles.field)}>
              <label>{phrases.LAST_NAME_LABEL}</label>
              <Controller
                name="lastName"
                rules={{
                  required: {
                    value: true,
                    message: phrases.ERROR_MISSING_VALUE,
                  },
                }}
                control={control}
                render={controlledInput}
              />
              <span className={styles.fieldError}>
                {errors?.lastName?.message}
              </span>
            </div>
            <div className={classNames(styles.field)}>
              <label>{phrases.EMAIL_LABEL}</label>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: phrases.ERROR_MISSING_VALUE,
                  },
                  pattern: {
                    value: emailRegex,
                    message: phrases.ERROR_INCORRECT_VALUE,
                  },
                }}
                render={({ field: { value, name, onChange, onBlur, ref } }) => (
                  <TextareaAutosize
                    className={classNames(
                      styles.fieldInput,
                      styles.emailTextarea
                    )}
                    maxRows={5}
                    minRows={1}
                    name={name}
                    inputRef={emailTextareaRef}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={() => emailTextareaRef?.current?.focus()}
                    value={value}
                    placeholder="example@mail.com"
                  />
                )}
              />
              <span className={styles.fieldError}>
                {errors?.email?.message}
              </span>
            </div>
            <div className={classNames(styles.field, styles.generalRoleField)}>
              <Controller
                control={control}
                name="role"
                render={({ field: { value, onChange } }) => (
                  <RoleSelect role={value} updateRole={onChange} />
                )}
              />
            </div>
            <div
              className={classNames(
                styles.field,
                styles.fieldAction,
                styles.cancelAction
              )}
              onClick={onCancel}
            >
              <div className={styles.fieldIcon}>
                <SvgIcon icon="cancel-icon" width="1.4em" height="1.4em" />
              </div>
              <span>{phrases.CANCEL_ACTION_TEXT}</span>
            </div>
          </form>
        </Card.SearchCriteriaEditFormFields>
        <button
          disabled={hasErrors}
          type="button"
          className={styles.userExpandedCardActionButton}
          onClick={onSubmit}
        >
          {phrases.INVITE_USER_ACTION_TEXT}
        </button>
      </Card.SearchCriteriaEditForm>
    </Card.SearchCriteriaCardContainer>
  );
};
UserFormCardEdit.displayName = 'UserFormCardEdit';

export const UserFormCard: React.FC<{
  className: string;
  onSubmit: (data: NewUserInfo) => Promise<void>;
  onDiscard: () => void;
}> = ({ className, onSubmit, onDiscard }) => {
  const [userInfo, setNewUser] = useState<NewUserInfo>({
    firstName: '',
    lastName: '',
    email: '',
    role: RoleName.RECRUITER,
  });
  const [errorCardState, setErrorCardState] = useState({
    errorStatus: null,
    hasErrors: false,
  });

  const methods = useForm<NewUserInfo>({
    defaultValues: userInfo,
    mode: 'onTouched',
    shouldUnregister: false,
  });
  const {
    getValues,
    handleSubmit,
    setError,
    reset,
    formState,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!R.isNullOrEmpty(errors)) {
      setErrorCardState({
        errorStatus: phrases.PENDING_USER_STATUS,
        hasErrors: true,
      });
    } else {
      setErrorCardState({
        errorStatus: null,
        hasErrors: false,
      });
    }
  }, [errors]);

  const [cardState, dispatch] = useStateMachine(
    UserFormCardStateConfig,
    UserFormCardState.edit
  );

  useEffect(() => {
    const NUMBER_INPUT_FIELDS = 3;
    const errorsArray = R.values(errors);
    if (
      errorsArray.length === NUMBER_INPUT_FIELDS &&
      R.all(R.propEq('type', 'required'), errorsArray)
    ) {
      onDiscard();
    }
  }, [errors, onDiscard]);

  const showExpandedView = useCallback(() => {
    dispatch(UserFormCardActions.openEdit);
  }, [dispatch]);
  const showCollapsedHoveredView = useCallback(() => {
    dispatch(UserFormCardActions.hoverView);
  }, [dispatch]);
  const showCollapsedView = useCallback(() => {
    dispatch(UserFormCardActions.openView);
  }, [dispatch]);

  const handleCancel = useCallback(() => {
    onDiscard();
    dispatch(UserFormCardActions.discard);
  }, [onDiscard, dispatch]);

  // TODO: Change after error types will be added
  const isExistingEmail = useCallback(
    (err) => getErrorDescription(err).includes('already exists'),
    []
  );

  const getErrorStatus = useCallback(
    (err) => {
      return isExistingEmail(err)
        ? phrases.EMAIL_ALREADY_EXIST
        : phrases.WARNING_USER_STATUS;
    },
    [isExistingEmail]
  );

  // TODO: change after adding error types from server
  const getServerErrors = useCallback(
    (err): { field?: keyof NewUserInfo; label?: string } => {
      if (isExistingEmail(err)) {
        return { field: 'email', label: phrases.ALREADY_EXIST };
      }

      const errorText = getErrorDescription(err);
      if (errorText.match(/email/i)) {
        return { field: 'email', label: errorText };
      }

      return {};
    },
    [isExistingEmail]
  );

  const addServerError = useCallback(
    (error) => {
      const { field, label } = getServerErrors(error);
      if (field) {
        setError(field, {
          type: 'server',
          message: label,
        });
      }
    },
    [getServerErrors, setError]
  );

  const submitHandler = useCallback(async () => {
    /* Clearing server validation before submitting */
    if (formState.isDirty) {
      await setErrorCardState({
        errorStatus: null,
        hasErrors: false,
      });
    }

    handleSubmit((data) => {
      onSubmit(data)
        .then(
          () => {
            setErrorCardState({
              errorStatus: null,
              hasErrors: false,
            });
          },
          (err) => {
            setErrorCardState({
              errorStatus: getErrorStatus(err),
              hasErrors: true,
            });
            addServerError(err);
          }
        )
        .finally(() => {
          reset(getValues(), {
            keepErrors: true,
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: true,
            keepSubmitCount: true,
          });
        });
    })();
    setNewUser(getValues());
    dispatch(UserFormCardActions.openView);
  }, [
    reset,
    getValues,
    handleSubmit,
    onSubmit,
    setNewUser,
    dispatch,
    getErrorStatus,
    addServerError,
    formState,
  ]);

  const title =
    userInfo?.firstName || userInfo?.lastName
      ? `${userInfo?.firstName} ${userInfo?.lastName}`
      : phrases.EMPTY_USER_PLACEHOLDER;

  const cardLabel = !errorCardState.hasErrors
    ? phrases.ADD_NEW_USER_LABEL
    : phrases.PENDING_LABEL;

  if (cardState.matches(UserFormCardState.edit)) {
    return (
      <FormProvider {...methods}>
        <UserFormCardEdit
          cardLabel={cardLabel}
          className={className}
          onSubmit={submitHandler}
          onCancel={handleCancel}
        />
      </FormProvider>
    );
  }

  return cardState.matches(UserFormCardState.view) ? (
    <UserCollapsedCard
      className={className}
      isInactive={errorCardState.hasErrors}
      cardLabel={cardLabel}
      title={title}
      showCardBackground={cardState.matches(UserFormCardState.viewHovered)}
      onClick={showExpandedView}
      onActionButtonClick={showExpandedView}
      onMouseOver={showCollapsedHoveredView}
      onMouseLeave={showCollapsedView}
      status={errorCardState.errorStatus}
    />
  ) : null;
};
UserFormCard.displayName = 'UserFormCard';
