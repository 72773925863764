import { createContext } from 'use-context-selector';
import { JobSectionItemDragObject } from 'features/JobsSection/JobsSection';
import {
  CompanyFullResponse,
  SearchCreateRequest,
  SearchProgressStatusEnum,
  JobDescriptionFullResponse,
  SearchKanbanResponse,
  SearchCountResponseV2,
} from '@air/api/models';

import {
  SearchListItemT,
  KanbanListT,
  KanbanFilterT,
  MatchMinerSearchT,
  CurrentSearchT,
  MatchScoutSearchT,
} from 'domain/Kanban/Kanban';
import {
  SearchCounterConsumer,
  JobDescriptionParsingConsumer,
} from 'domain/Kanban/ServerSideEventsConsumers';
import { LineupTabs } from '@air/constants/tabs';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { SearchListTypes } from 'providers/KanbanProvider';
import * as phrases from 'constants/phrases';
import { KanbanSectionNames } from 'hooks/useUserSettings';
import {
  MatchMinerSetupSettings,
  MatchScoutSetupSettings,
} from 'domain/MatchServices/MatchServices';
import { CandidateDataUpdate } from 'domain/CandidateData/CandidateDataServerEvents/CandidateDataServerEvents';

export type FetchSearchParamsT = {
  dataSourceId?: number | string;
  jobDescriptionId?: string;
  shouldPerformPassiveSearch?: boolean;
};

export type UpdateSearchStatusInListT = {
  item: KanbanCardT | SearchListItemT;
  itemsList: KanbanListT<SearchListItemT | KanbanCardT>;
  listName: SearchListTypes;
  newStatus?: SearchProgressStatusEnum;
};

export type KanbanStatsExpandedT = {
  [phrases.KANBAN_SECTION_JOBS]: boolean;
  [phrases.KANBAN_SECTION_DRAFTS]: boolean;
  [phrases.KANBAN_SECTION_SCREENING]: boolean;
  [phrases.KANBAN_SECTION_PAUSED]: boolean;
  [phrases.KANBAN_SECTION_CLOSED]: boolean;
};

export type KanbanContextT = {
  areSearchesLoading: boolean;
  isImportingCriteria: boolean;
  draftsList: KanbanListT<SearchListItemT>;
  interviewsList: KanbanListT<SearchListItemT>;
  pausedSearchesList: KanbanListT<SearchListItemT>;
  closedSearchesList: KanbanListT<SearchListItemT>;
  currentSearch?: CurrentSearchT;
  currentMatchMinerSearch?: MatchMinerSearchT;
  isCurrentMatchMinerSearchLoaded: boolean;
  currentMatchScoutSearch?: MatchScoutSearchT;
  isCurrentMatchScoutSearchLoaded: boolean;
  currentSearchFilter?: KanbanFilterT;
  companiesInfo?: CompanyFullResponse[];
  statsExpanded: KanbanStatsExpandedT;
  methods: {
    setStatsExpanded: (sectionName: KanbanSectionNames, value: boolean) => void;
    fetchAllSearches: (params: {
      withCriteria?: boolean;
      excludeId?: string[] | number[];
    }) => Promise<KanbanListT<SearchKanbanResponse>[] | void>;
    updateAllSearches: (params?: {
      withCriteria?: boolean;
      excludeId?: string[] | number[];
    }) => void;
    duplicateCriteria: (
      sourceInterviewSearchId: number,
      targetInterview: CurrentSearchT
    ) => Promise<unknown>;
    fetchSearch: ({
      dataSourceId,
      jobDescriptionId,
      shouldPerformPassiveSearch,
    }: FetchSearchParamsT) => Promise<{
      searchId: number;
      activeCount: SearchCountResponseV2;
    }>;
    updateSearchCandidatesProfileSize: (
      searchId: number,
      currentTab?: LineupTabs
    ) => Promise<SearchCountResponseV2>;
    fetchSearchesByStatus: (params: {
      status: SearchProgressStatusEnum[];
      page?: number;
      size?: number;
      withCriteria?: boolean;
      excludeId?: string[] | number[];
    }) => Promise<void>;
    fetchCompaniesInfo: (ids: number[]) => void;
    updateSearchStatusInList: (props: UpdateSearchStatusInListT) => void;
    createDraft: (
      jobInfo: JobSectionItemDragObject,
      withRedirect?: boolean
    ) => Promise<void>;
    updateDraft: (
      searchId: number | string,
      values: any,
      fullUpdate?: boolean
    ) => void;
    clearCurrentSearch: () => void;
    removeDraft: (item: SearchListItemT | KanbanCardT) => Promise<any>;
    startInterview: (
      item: SearchListItemT | CurrentSearchT
    ) => Promise<false | SearchListItemT>;
    pauseInterview: (
      item: SearchListItemT | KanbanCardT | CurrentSearchT
    ) => Promise<false | SearchListItemT>;
    republishInterview: (
      item: SearchListItemT | KanbanCardT | CurrentSearchT
    ) => Promise<false | SearchListItemT>;
    replaceInterview: (parentId: number, item: SearchListItemT) => void;
    closeInterview: (
      item: SearchListItemT | KanbanCardT
    ) => Promise<false | SearchListItemT>;
    duplicateInterview: (searchId: number | string) => Promise<SearchListItemT>;
    reopenClosedInterview: (
      item: SearchListItemT | KanbanCardT | CurrentSearchT
    ) => Promise<false | SearchListItemT>;
    updateSearchFilter: ({
      searchFilter,
      shouldFetchSearches,
      saveInSessionStore,
      withCriteria,
      excludeId,
    }: {
      searchFilter: { name?: string; isOwnedByMe?: boolean };
      shouldFetchSearches?: boolean;
      saveInSessionStore?: boolean;
      withCriteria?: boolean;
      excludeId?: string[] | number[];
    }) => void;
    updateJobDescription: (descriptionId: string, description: string) => void;
    requestParseJobDescription: (descriptionId: string) => void;
    fetchJobDescriptionForSearch: (
      dataSourceId: number | string,
      jdId: string
    ) => Promise<JobDescriptionFullResponse>;
    createNewRequisition: (
      request: SearchCreateRequest
    ) => Promise<SearchListItemT>;
    getSearchConsumerById(searchId: number): SearchCounterConsumer;
    subscribeToCandidatesProcessingSSE(
      searchId: number,
      callback: (events: CandidateDataUpdate[]) => void
    ): () => void;
    getJobDescriptionParsingConsumer(): JobDescriptionParsingConsumer;
    startMatchMinerSearch: (
      parentSearchId: number | string,
      settings: MatchMinerSetupSettings
    ) => Promise<{ error?: string }>;
    startMatchScoutSearch: (
      parentSearchId: number | string,
      settings: MatchScoutSetupSettings
    ) => Promise<{ error?: string }>;
    restartMatchServiceSearch: (
      searchId: number | string,
      settings: MatchMinerSetupSettings | MatchScoutSetupSettings,
      currentTab: LineupTabs
    ) => Promise<{ error?: string }>;
    loadMoreApplicantsForMatchService: (
      searchId: number | string,
      limit: number,
      currentTab: LineupTabs
    ) => Promise<{ error?: string }>;
    createTempDraftCard: (job: Pick<SearchListItemT, 'name' | 'ats'>) => void;
    removeTempDraftCard: () => void;
  };
};

export const KanbanContext = createContext<Partial<KanbanContextT>>({
  draftsList: { items: [], total: 0, loaded: false },
  interviewsList: { items: [], total: 0, loaded: false },
  closedSearchesList: { items: [], total: 0, loaded: false },
});

export const SelectInterviewKanbanContext = createContext<
  Partial<KanbanContextT>
>({
  draftsList: { items: [], total: 0, loaded: false },
  interviewsList: { items: [], total: 0, loaded: false },
  closedSearchesList: { items: [], total: 0, loaded: false },
});

KanbanContext.displayName = 'KanbanContext';
