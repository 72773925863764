export enum DraggedSearchCriteriaCardType {
  skill = 'skill',
  singleSkillItem = 'singleSkillItem',

  certification = 'certification',
  singleCertificationItem = 'singleCertificationItem',

  role = 'role',
  singleRoleItem = 'singleRoleItem',

  managerial = 'managerial',
  professional = 'professional',

  industry = 'industry',
  singleIndustryItem = 'singleIndustryItem',

  company = 'company',
  singleCompanyItem = 'singleCompanyItem',

  location = 'location',

  major = 'major',
  institution = 'institution',
  degree = 'degree',

  question = 'question',
}
