import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';

import { SelectOption, SvgIcon } from '@air/components';

import styles from './CheckboxOptions.css';

export type CheckboxOption = {
  id: string;
  label: string;
  className: string;
  isSelected: boolean;
};

type CheckboxOptionsProps = {
  items: CheckboxOption[];
  onChange: (items: CheckboxOption[]) => void;
};

export const CheckboxOptions: React.FC<CheckboxOptionsProps> = ({
  items,
  onChange,
}) => {
  const [updatedItems, setUpdatedItems] = useState(items);

  const onClick = useCallback((id: string) => {
    setUpdatedItems((updatedItems) => {
      const updatedItemIndex = R.findIndex(R.propEq('id', id))(updatedItems);
      const newItems = [...updatedItems];
      newItems[updatedItemIndex] = {
        ...newItems[updatedItemIndex],
        isSelected: !newItems[updatedItemIndex].isSelected,
      };
      return newItems;
    });
  }, []);

  useEffect(() => {
    onChange(updatedItems);
  }, [updatedItems, onChange]);

  return (
    <>
      {updatedItems.map((item) => {
        return (
          <SelectOption
            key={item.id}
            label={item.label}
            className={classNames([styles.filterOption], [item.className])}
            icon={
              item.isSelected ? (
                <SvgIcon
                  icon="checkbox-square-checked"
                  className={styles.icon}
                />
              ) : (
                <SvgIcon
                  icon="checkbox-square-unchecked"
                  className={styles.icon}
                />
              )
            }
            isSelected={item.isSelected}
            onClick={() => onClick(item.id)}
          />
        );
      })}
    </>
  );
};
