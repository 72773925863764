import R from '@air/third-party/ramda';
import { RangeItem } from '@air/components/RangeDropdown/RangeDropdown';
import { ExperienceValues } from '@air/utils/commonSearchHelpers';

export type RangeIndex = number | null;
export type RangeOfIndexes = [RangeIndex, RangeIndex];
export type RangeValue = { value: number; label: string };

export const isLimitNotDefined = (index: RangeIndex) => R.isNil(index);
export const isRangeNotDefined = (range: RangeOfIndexes) =>
  R.all(isLimitNotDefined, range);

export const MIN_EXPERIENCE_YEAR = 0;
export const MAX_EXPERIENCE_YEAR = 10;
export const UNSET_EXPERIENCE_MAX_YEAR = 11;

export const MIN_EQUIVALENT_EXPERIENCE_YEAR = 1;
export const MAX_EQUIVALENT_EXPERIENCE_YEAR = 10;

export const MIN_RECENT_EXPERIENCE_VALUE = 1;
export const MAX_RECENT_EXPERIENCE_VALUE = 11;

export const DISTANCE_VALUE_STEP = 10;
export const MIN_DISTANCE_VALUE = 10;
export const MAX_DISTANCE_VALUE = 100;

export const stringifyExperienceYears = (
  valueMin: number | null,
  valueMax: number | null,
  rangeDelimiter = '-'
) => {
  let yearsNumber;
  let isSingularYear = false;

  const isValueMinDefined = !R.isNil(valueMin);
  const isValueMaxDefined =
    !R.isNil(valueMax) && valueMax < UNSET_EXPERIENCE_MAX_YEAR;

  if (!isValueMinDefined) {
    yearsNumber = `0${rangeDelimiter}${valueMax}`;
  } else if (!isValueMaxDefined) {
    yearsNumber = `${valueMin}+`;
    isSingularYear = valueMin === 1;
  } else {
    yearsNumber = `${valueMin}${rangeDelimiter}${valueMax}`;
  }
  return {
    yearsNumber,
    yearsWords: `year${isSingularYear ? '' : 's'}`,
  };
};

export const isAcceptableExperienceDefined = (values: ExperienceValues) =>
  !(R.isNil(values?.acceptableMin) || R.isNil(values?.acceptableMax));

export const isIdealExperienceDefined = (values: ExperienceValues) => {
  return !(
    (R.isNil(values?.idealMin) || values.idealMin === MIN_EXPERIENCE_YEAR) &&
    (R.isNil(values?.idealMax) ||
      values?.idealMax === UNSET_EXPERIENCE_MAX_YEAR)
  );
};

export const generateSelectedIndexes = (
  acceptableRange: RangeOfIndexes,
  maxIndex: RangeIndex
) => {
  let [rangeMin, rangeMax] = acceptableRange;
  if (isRangeNotDefined(acceptableRange)) {
    rangeMin = 0;
    rangeMax = 0;
  } else if (isLimitNotDefined(rangeMax)) {
    rangeMax = maxIndex;
  } else if (isLimitNotDefined(rangeMin)) {
    rangeMin = 0;
  }
  return R.range(rangeMin, rangeMax === 0 ? rangeMax : rangeMax + 1); // + 1 because R.range has exclusive right limit
};

export const sortSelectedRange = (indexA: RangeIndex, indexB: RangeIndex) => {
  if (!isLimitNotDefined(indexA) && !isLimitNotDefined(indexB)) {
    return R.sort(R.ascend(R.identity), [indexA, indexB]);
  }
  return [indexA, indexB];
};

export const convertValuesToRangeOfIndexes = (
  allItems: RangeItem[],
  values: [number | null, number | null]
): RangeOfIndexes => {
  const rangeOfIndexes = R.map(
    (value: any) => R.findIndex(R.propEq('value', value))(allItems),
    values
  );
  return R.map(
    (value: any) => (value === -1 ? null : value),
    rangeOfIndexes
  ) as RangeOfIndexes;
};

export const generateItemsForRangeDropdown = (rangeOfValues: number[]) => {
  return R.map(
    (value: any) => ({
      label: value,
      value,
    }),
    rangeOfValues
  );
};
