import React, { useCallback, useState, useEffect } from 'react';

import { KanbanCard } from 'components';
import {
  SearchListTypes,
  useKanbanContext,
  useKanbanMethods,
  useSelectInterviewKanbanMethods,
  useSelectInterviewKanbanContext,
} from 'providers/KanbanProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import { LoadMoreButton } from 'features/Kanban/LoadMoreButton/LoadMoreButton';
import {
  getAmountOfJobsToLoad,
  KANBAN_SEARCH_GROUPS,
  addRemoveCardAction,
} from 'domain/Kanban/Kanban';
import { usePaginatedSearch } from 'domain/Kanban/usePaginatedSearch';

import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';

import commonStyles from 'features/Kanban/KanbanSection/KanbanSection.css';
import { GACategory } from '@air/domain/Common/GATypes';
import { trackEvent } from '@air/utils/ga';
import {
  GA_LABEL_PAUSE_SCREENING,
  GA_LABEL_COMPLETE_SCREENING,
} from 'constants/gaLabels';
import { emit, subscribe } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { SearchProgressStatusEnum } from '@air/api';

type Props = { isSelectMode?: boolean; selectModeTargetId?: string | number };

export const Interview: React.FC<Props> = React.memo((props) => {
  const { isSelectMode, selectModeTargetId } = props;
  const [kanbanContext, kanbanContextMethods] = isSelectMode
    ? [useSelectInterviewKanbanContext, useSelectInterviewKanbanMethods]
    : [useKanbanContext, useKanbanMethods];
  const interviewsList = kanbanContext(kanbanSelectors.interviewsList);
  const pausedSearchesList = useKanbanContext(
    kanbanSelectors.pausedSearchesList
  );
  const closedSearchesList = useKanbanContext(
    kanbanSelectors.closedSearchesList
  );
  const {
    pauseInterview,
    closeInterview,
    updateSearchStatusInList,
    removeDraft,
  } = useKanbanMethods();
  const isExternalATS = useCustomerProfileContext(
    customerProfileSelectors.isExternalATS
  );
  const { fetchSearchesByStatus } = kanbanContextMethods();
  const currentSearchFilterName = useKanbanContext(
    kanbanSelectors.currentSearchFilterName
  );
  const isFirstInterviewUpdated = useKanbanContext(
    kanbanSelectors.isFirstInterviewUpdated
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const statsExpanded = useKanbanContext(kanbanSelectors.statsExpanded);
  const isMatchMinerFeatureEnabled = useCustomerProfileContext(
    customerProfileSelectors.matchMinerEnabled
  );
  const isMatchScoutFeatureEnabled = useCustomerProfileContext(
    customerProfileSelectors.matchScoutEnabled
  );
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearchesByStatusCb = useCallback(
    async (page) => {
      setIsLoading(true);
      await fetchSearchesByStatus({
        status: KANBAN_SEARCH_GROUPS.Screening,
        page,
        ...(isSelectMode
          ? { withCriteria: true, excludeId: [selectModeTargetId as string] }
          : {}),
      });
      setIsLoading(false);
    },
    [fetchSearchesByStatus, isSelectMode, selectModeTargetId]
  );

  const loadMoreJobs = usePaginatedSearch(fetchSearchesByStatusCb, [
    currentSearchFilterName,
    isFirstInterviewUpdated,
  ]);

  const amountOfJobsLeftToLoad = getAmountOfJobsToLoad(
    interviewsList.total,
    interviewsList.items.length
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.JOB_STATUS_CHANGE, loadMoreJobs);
  }, [loadMoreJobs]);

  const pauseInterviewAction = useCallback(
    async (item: KanbanCardT) => {
      updateSearchStatusInList({
        item,
        itemsList: interviewsList,
        listName: SearchListTypes.STATUS_INTERVIEWS,
      });
      updateSearchStatusInList({
        item,
        itemsList: pausedSearchesList,
        newStatus: SearchProgressStatusEnum.ONHOLD,
        listName: SearchListTypes.STATUS_PAUSED_SEARCHES,
      });

      trackEvent({
        category: GACategory.ScreeningPage,
        label: GA_LABEL_PAUSE_SCREENING,
      });
      await pauseInterview(item);
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
    },
    [
      updateSearchStatusInList,
      interviewsList,
      pausedSearchesList,
      pauseInterview,
    ]
  );

  const closeInterviewAction = useCallback(
    async (item: KanbanCardT) => {
      updateSearchStatusInList({
        item,
        itemsList: interviewsList,
        listName: SearchListTypes.STATUS_INTERVIEWS,
      });
      updateSearchStatusInList({
        item,
        itemsList: closedSearchesList,
        newStatus: SearchProgressStatusEnum.CLOSED,
        listName: SearchListTypes.STATUS_CLOSED_SEARCHES,
      });
      trackEvent({
        category: GACategory.ScreeningPage,
        label: GA_LABEL_COMPLETE_SCREENING,
      });
      await closeInterview(item);
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
    },
    [
      closeInterview,
      closedSearchesList,
      interviewsList,
      updateSearchStatusInList,
    ]
  );

  return (
    <div className={commonStyles.sectionJobs}>
      {interviewsList.items.map((item: KanbanCardT) => {
        const actionsMenuConfig = [
          {
            onClick: () => emit(APP_EVENTS.OPEN_JOB_PANEL, { item }),
            icon: 'edit',
            title: phrases.KANBAN_ACTION_EDIT,
            disabled: isTrialExpired,
            closeMenuOnClick: true,
          },
          ...(!isExternalATS
            ? [
                {
                  onClick: () => emit(APP_EVENTS.DUPLICATE_CARD, item),
                  icon: 'duplicate',
                  title: phrases.KANBAN_ACTION_DUPLICATE,
                  disabled: isTrialExpired,
                  closeMenuOnClick: true,
                },
              ]
            : []),
          {
            onClick: () => pauseInterviewAction(item),
            icon: 'pause',
            title: phrases.KANBAN_ACTION_PAUSE,
            disabled: isTrialExpired,
            confirmTitle: phrases.PAUSE_ACTION_CONFIRMATION_MESSAGE,
            confirmLabel: phrases.KANBAN_ACTION_PAUSE,
          },
          {
            onClick: () => closeInterviewAction(item),
            icon: 'close',
            title: phrases.KANBAN_ACTION_CLOSE,
            disabled: isTrialExpired,
            confirmTitle: phrases.CLOSE_ACTION_CONFIRMATION_MESSAGE,
            confirmLabel: phrases.KANBAN_ACTION_CLOSE,
          },
        ];
        addRemoveCardAction(actionsMenuConfig, removeDraft, item);

        return (
          <KanbanCard
            className={commonStyles.sectionJobCard}
            key={item.searchId}
            item={item}
            actionsMenuConfig={actionsMenuConfig}
            isSelectMode={isSelectMode}
            isMMEnabled={isMatchMinerFeatureEnabled}
            isMSEnabled={isMatchScoutFeatureEnabled}
            areAllStatsExpanded={
              statsExpanded[phrases.KANBAN_SECTION_SCREENING]
            }
            url={urls.makeInterviewUrl(
              item.ats.id,
              item.ats.externalJobDescriptionId
            )}
          />
        );
      })}
      {!!amountOfJobsLeftToLoad && (
        <LoadMoreButton
          isLoading={isLoading}
          onClick={loadMoreJobs}
          text={phrases.getLoadMoreJobsButtonTitle(amountOfJobsLeftToLoad)}
        />
      )}
    </div>
  );
});
Interview.displayName = 'Interview';
