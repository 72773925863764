import React from 'react';

import styles from './SelectDropdown.css';
import classNames from 'classnames';
import fadeTransition from '@air/styles/transitions/fade.css';
import { CSSTransition } from 'react-transition-group';
import { useOutsideClick } from '@air/utils/hooks';

/*
  TODO: This Dropdown is very similar to ExperienceSelect.
  Maybe we could remove one of them, or create a new one
  based on those two.
*/
export const SelectDropdown: React.FC<{
  isOpen?: boolean;
  className?: string;
  arrowClassName?: string;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
}> = ({
  isOpen = false,
  className,
  arrowClassName,
  onClose,
  closeOnClickOutside = false,
  children,
}) => {
  const [outsideClickRef] = useOutsideClick(
    () => {
      if (isOpen && closeOnClickOutside && onClose) {
        onClose();
      }
    },
    { useCapture: true }
  );

  return (
    <div className={classNames(className, styles.dropDownWrapper)}>
      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames={fadeTransition}
        unmountOnExit
      >
        <div className={styles.dropDown}>
          <div
            className={classNames(styles.dropDownContentArrow, arrowClassName)}
          />
          <div className={styles.dropDownContent} ref={outsideClickRef}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
