import {
  isAcceptableExperienceDefined,
  isIdealExperienceDefined,
  stringifyExperienceYears,
  MIN_EXPERIENCE_YEAR,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';

export type CandidateExperience = {
  years?: number;
  months?: number;
  noPractical?: true | null;
} | null;

export type ExperienceValues = {
  idealMin: number;
  idealMax?: number;
  acceptableMin?: number;
  acceptableMax?: number;
};

export type EquivalentExperienceValue = number | null;

export const getSearchCriteriaExperience = (experience: any) => {
  if (isIdealExperienceDefined(experience)) {
    let { yearsNumber, yearsWords }: any = stringifyExperienceYears(
      experience?.idealMin,
      experience?.idealMax
    );
    const hasAcceptableExperience = isAcceptableExperienceDefined(experience);
    const prefix = hasAcceptableExperience ? '~' : '';
    // if ideal experience is <=1 and acceptable experience is specified,
    // system should show ~1 as total experience (AR-2518)
    if (
      experience?.idealMin === MIN_EXPERIENCE_YEAR &&
      experience?.idealMax === 1
    ) {
      yearsNumber = hasAcceptableExperience ? 1 : '0-1';
      yearsWords = 'year';
    }
    return `${prefix}${yearsNumber} ${yearsWords}`;
  }
};
