import React from 'react';
import {
  FieldArrayWithId,
  useFormContext,
  UseFieldArrayMove,
} from 'react-hook-form';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

import {
  Button,
  Paragraph,
  UIText,
  TooltipWrapper,
  SvgIcon,
} from 'air-shared/components';
import { DataOriginType, StringMergedResponse } from '@air/api';
import { ContactItem } from '../ContactItem/ContactItem';
import * as phrases from 'constants/phrases';
import { toast } from '@air/third-party/toast';
import { isEmailValid } from '@air/utils/strings';

import { FieldPath } from 'react-hook-form/dist/types/path';
import { CONTACTS_TYPE_EMAILS, EmailsFormValues } from '../ContactDetails';

import styles from './EmailsInfo.css';

const isEnrichedByPIPL = (sources: DataOriginType[]) => {
  return sources.length === 1 && sources.includes(DataOriginType.PIPL);
};

const PRIMARY_EMAIL_INDEX = 0;

type EmailInfoProps<T> = {
  isEnrichedByPIPL?: boolean;
  email: string;
  isEditMode: boolean;
  name: FieldPath<T>;
  removeField?: () => void;
  setPrimaryEmail?: () => void;
  isPrimaryEmail: boolean;
  showRemoveIcon: boolean;
  isNotValid: boolean;
};

const EmailInfo = <T,>({
  email,
  isEnrichedByPIPL,
  isEditMode,
  name,
  removeField,
  setPrimaryEmail,
  isPrimaryEmail,
  showRemoveIcon,
  isNotValid,
}: EmailInfoProps<T>) => {
  const { register } = useFormContext();

  const onCopyIconClickHandler = (email: string) => {
    copy(email);
    toast.dark(phrases.CANDIDATE_PROFILE_CONTACTS_TOAST_TEXT_COPIED);
  };

  return (
    <div className={styles.rootWrapper}>
      <ContactItem
        containerClassName={classNames(styles.wrapper)}
        contentClassName={classNames({
          [styles.inputContainer]: isEditMode,
        })}
        isEnrichedByPIPL={isEnrichedByPIPL}
        onIconClicked={() => onCopyIconClickHandler(email)}
        isEditMode={isEditMode}
      >
        {isEditMode ? (
          <>
            {!isPrimaryEmail && (
              <span
                className={styles.makePrimaryEmail}
                onClick={setPrimaryEmail}
              >
                <UIText small bold className={styles.makePrimaryEmailText}>
                  {phrases.CANDIDATE_PROFILE_CONTACTS_EMAILS_MAKE_PRIMARY}
                </UIText>
                <SvgIcon icon="arrow" />
              </span>
            )}

            <input
              defaultValue={email}
              className={classNames(styles.input, {
                [styles.incorrect]: isNotValid,
              })}
              type="text"
              placeholder={phrases.CANDIDATE_PROFILE_CONTACTS_EMAIL_PLACEHOLDER}
              {...register(name, {
                validate: (email: string) => isEmailValid(email),
              })}
            />

            {showRemoveIcon && (
              <SvgIcon
                icon="close"
                className={styles.closeIcon}
                onClick={removeField}
              />
            )}
          </>
        ) : (
          <Paragraph short>
            <a href={`mailto:${email}`}>{email}</a>
          </Paragraph>
        )}
      </ContactItem>
      {isNotValid && (
        <UIText small className={styles.errorMessage}>
          {phrases.CANDIDATE_PROFILE_CONTACTS_INVALID_EMAIL_FORMAT_ERROR}
        </UIText>
      )}
    </div>
  );
};

type Props = {
  primaryEmail: string;
  emails: StringMergedResponse[];
  isEditMode: boolean;
  fields: FieldArrayWithId<EmailsFormValues>[];
  addNewField: () => void;
  removeField: (index: number) => void;
  moveField: UseFieldArrayMove;
};

export const EmailsInfo: React.FC<Props> = ({
  emails,
  isEditMode,
  fields,
  addNewField,
  removeField,
  moveField,
}) => {
  const {
    getValues,
    formState: { isValid, errors },
  } = useFormContext<EmailsFormValues>();
  const atLeastOneSecondaryEmailHasValue = () => {
    const emailsExceptPrimaryOne = getValues()[CONTACTS_TYPE_EMAILS].slice(1);

    return emailsExceptPrimaryOne.some((item: { value: string }) =>
      Boolean(item.value)
    );
  };

  const setPrimaryEmail = (index: number) => {
    moveField(index, PRIMARY_EMAIL_INDEX);
  };

  return (
    <>
      {fields.map((field, index: number) => {
        const showPrimaryEmailLabel = index === 0 && fields.length > 1;
        const showSecondaryEmailLabel = index === 1;

        return (
          <div
            key={`email-field-wrapper-${field.id}`}
            className={classNames(styles.emailWrapper, {
              [styles.severalEmails]: fields.length > 1,
              [styles.wrapperWithPrimaryEmailLabel]: showPrimaryEmailLabel,
              [styles.wrapperWithSecondaryEmailLabel]: showSecondaryEmailLabel,
            })}
          >
            {showPrimaryEmailLabel && (
              <UIText className={styles.primaryEmail} tiny>
                {phrases.CANDIDATE_PROFILE_CONTACTS_PRIMARY_EMAIL}
              </UIText>
            )}
            {showSecondaryEmailLabel && (
              <UIText className={styles.secondaryEmails} tiny>
                {phrases.CANDIDATE_PROFILE_CONTACTS_SECONDARY_EMAIL}
              </UIText>
            )}
            <TooltipWrapper
              enabled={index === PRIMARY_EMAIL_INDEX}
              tooltip={phrases.CANDIDATE_PROFILE_CONTACTS_PRIMARY_EMAIL_TOOLTIP}
            >
              <EmailInfo<EmailsFormValues>
                key={field.id}
                name={`${CONTACTS_TYPE_EMAILS}.${index}.value`}
                email={field?.value}
                isEditMode={isEditMode}
                isEnrichedByPIPL={
                  emails[index]?.sources &&
                  isEnrichedByPIPL(emails[index]?.sources)
                }
                removeField={() => removeField(index)}
                setPrimaryEmail={() => setPrimaryEmail(index)}
                showRemoveIcon={
                  index === PRIMARY_EMAIL_INDEX
                    ? atLeastOneSecondaryEmailHasValue()
                    : true
                }
                isPrimaryEmail={index === PRIMARY_EMAIL_INDEX}
                isNotValid={
                  !!(
                    errors[CONTACTS_TYPE_EMAILS] &&
                    errors[CONTACTS_TYPE_EMAILS][index]
                  )
                }
              />
            </TooltipWrapper>
          </div>
        );
      })}

      {(!fields.length || isEditMode) && (
        <div
          className={classNames(styles.addEmailContainer, {
            [styles.noEmails]: !fields.length,
          })}
        >
          <TooltipWrapper
            enabled={fields.length && !isValid}
            tooltip={phrases.CANDIDATE_PROFILE_ADD_CONTACT_BUTTON_RESTRAIN}
            disabledChildren={!isValid}
            flexGrow={false}
          >
            <Button
              icon="add-stroke"
              variant={Button.variants.DEFAULT}
              onClick={addNewField}
              disabled={fields.length && !isValid}
              data-testid="add-email-button"
            >
              <UIText small bold className={styles.addEmailButtonText}>
                {phrases.CANDIDATE_PROFILE_CONTACTS_EMAILS_ADD_EMAIL_TEXT}
              </UIText>
            </Button>
          </TooltipWrapper>
        </div>
      )}
    </>
  );
};
