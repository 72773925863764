import * as phrases from 'constants/phrases';
import { BaseEducationCriteriaWithList, CardType } from 'domain/SearchCriteria';
import { BaseCandidateData } from 'domain/CandidateData/CandidateCriteria/BaseCandidateData';
import { GraduationData } from 'domain/CandidateData/CandidateCriteria/GraduationData';
import { AlertData, getEducationAlertData } from '@air/domain/Alerts/AlertData';
import {
  DegreeMatchResolutionEnum,
  Education,
  SearchCriteriaMatchStatusEnum,
  SearchEducationDegree,
  SearchEducationResponse,
} from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateDegreeListItem {
  id: number | string;
  label: string;
  alert: AlertData | null;
  status: SearchCriteriaMatchStatusEnum;
  equivalentYears?: number | null;
  equivalentApprox?: boolean;
  equivalent?: boolean;
  interview: InterviewData;
}

export interface CandidateDegreeData
  extends BaseCandidateData,
    BaseEducationCriteriaWithList<CandidateDegreeListItem> {
  cardType: CardType.degree;
  setInCriteria: boolean;
  graduation: GraduationData;
}

const getEquivalentExperience = (
  degree: SearchEducationDegree
): CandidateDegreeListItem => {
  return {
    id: 'equivalent',
    label: '',
    equivalent: true,
    equivalentYears: degree.equivalentExperienceYears,
    equivalentApprox: degree.equivalentExperienceApprox,
    status: degree.status,
    alert: null,
    interview: null,
  };
};

export const getDegreeItem = (
  degree: SearchEducationDegree,
  education: Education,
  interview: InterviewData
): CandidateDegreeListItem => {
  return {
    id: CardType.degree,
    label: education?.degree?.fullName ?? phrases.DEGREE_LABEL,
    status: degree.status,
    alert: getEducationAlertData(degree),
    interview,
  };
};

/**
 * If degree card was matched by equivalent experience, additional checks and data manipulation
 * are required:
 * the card will have IDEAL status (thanks to `equivalentExperience: true`), the actual candidates
 * degree visually appears as though it has an ACCEPTABLE status,
 * if no education was founded after parsing, or at least degree wasn't found, no need to push a degree
 * item into a bottom stack.
 */

export const getDegreeAcceptableList = (
  degree: SearchEducationDegree,
  education: Education,
  interview: InterviewData
) => {
  if (
    degree.equivalentExperienceYears &&
    degree.matchResolution === DegreeMatchResolutionEnum.ACCEPTABLEMATCH
  ) {
    const status =
      degree.status === SearchCriteriaMatchStatusEnum.DISQUALIFICATION
        ? SearchCriteriaMatchStatusEnum.DISQUALIFICATION
        : SearchCriteriaMatchStatusEnum.ACCEPTABLE;

    return [
      {
        ...getDegreeItem(degree, education, interview),
        status,
      },
    ];
  }
  return [];
};

export const getDegreeData = (
  education: SearchEducationResponse,
  graduation: GraduationData,
  interview: InterviewData
): CandidateDegreeData => {
  if (!education.degree || !education.degree?.setInCriteria) {
    return null;
  }
  const { degree } = education;
  const degreeItem = getDegreeItem(degree, education.education, interview);

  const idealList = degree.equivalentExperienceYears
    ? [getEquivalentExperience(degree)]
    : [degreeItem];

  const acceptableList = getDegreeAcceptableList(
    degree,
    education.education,
    interview
  );
  return {
    // @ts-ignore idx is a part of SearchEducationDegree type
    idx: degree.idx,
    id: CardType.degree,
    cardType: CardType.degree,
    refId: degree.primaryItemRefId,
    idealList: idealList,
    acceptableList: acceptableList,
    setInCriteria: degree.setInCriteria,
    status: degree.status,
    importance: criteriaImportanceOptions[degree.importance],
    graduation,
  };
};

export const degreeHasComments = (degree: CandidateDegreeData): boolean => {
  return !!degree.idealList?.[0]?.interview?.commentId;
};
