import {
  SearchCreateRequest,
  SearchProgressStatusEnum,
  SearchQuestioningRequest,
  SearchListResponseV2,
} from '@air/api';

import * as Http from '@air/utils/http';
import {
  parseErrorJson,
  parseResponseBlob,
  parseResponseJson,
} from '@air/utils/api';
import * as urls from 'constants/urls';
import {
  MatchMinerSetupSettings,
  MatchScoutSetupSettings,
} from 'domain/MatchServices/MatchServices';
import { LineupTabs } from '@air/constants/tabs';

const defaultParams = {
  page: 0,
  size: 200,
  sort: 'updated;desc',
  status: SearchProgressStatusEnum.INTERVIEW,
};

export const fetchInterviewById = (id: number) => {
  return Http.get(urls.makeSearchResultApiUrl(id))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const startInterview = (searchId: number) => {
  return Http.put(urls.makeUpdateSearchStatusApiUrl(searchId))
    .bind(Http.withJsonBody({ status: SearchProgressStatusEnum.INTERVIEW }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const pauseInterview = (searchId: number) => {
  return Http.put(urls.makeUpdateSearchStatusApiUrl(searchId))
    .bind(Http.withJsonBody({ status: SearchProgressStatusEnum.ONHOLD }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const closeInterview = (searchId: number) => {
  return Http.put(urls.makeUpdateSearchStatusApiUrl(searchId))
    .bind(Http.withJsonBody({ status: SearchProgressStatusEnum.CLOSED }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const duplicateInterview = (searchId: number) => {
  return Http.post(urls.makeDuplicateInterviewUrl(searchId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchInterviewChildSearches = (id: number, params = {}) => {
  const { status, ...rest } = defaultParams;
  const fetchParams = { ...rest, ...params };
  return Http.get(urls.makeChildInterviewSearchesApiUrl(id))
    .bind(Http.withQueryParams(fetchParams))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson)
    .map((list: SearchListResponseV2) => list.items);
};

export const createInterviewDraft = (body: SearchCreateRequest) => {
  return Http.post(urls.SEARCH_LIST_API)
    .bind(Http.withQueryParams({ modify: true }))
    .bind(Http.withJsonBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateInterviewDraft = (
  searchId: number,
  searchInfo: SearchCreateRequest,
  // we update all by default unless we do renaming which doesn't
  // affect card criteria, in this case we do partial update `fullUpdate=false`
  fullUpdate = true
) => {
  return Http.put(urls.makeSearchResultApiUrl(searchId))
    .bind(Http.withQueryParams({ fullUpdate }))
    .bind(Http.withJsonBody(searchInfo))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const removeInterviewSearch = (id: number) => {
  return Http.remove(urls.makeSearchResultApiUrl(id))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const applyInterviewDraft = (searchId: number) => {
  return Http.put(urls.makeUpdateSearchStatusApiUrl(searchId))
    .bind(Http.withJsonBody({ status: SearchProgressStatusEnum.INTERVIEW }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateQuestioning = (
  searchId: number,
  updateRequest: Partial<SearchQuestioningRequest>
) => {
  return Http.post(urls.makeStartQuestioningUrl(searchId))
    .bind(Http.withJsonBody(updateRequest))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const checkQuestioning = (searchId: number) => {
  return Http.get(urls.makeStartQuestioningUrl(searchId))
    .bind(Http.withRetry({ delay: 1000, count: 3 }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const getEngagingEmailFields = (searchId: number) => {
  return Http.get(urls.makeEngagingEmailFieldsUrl(searchId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateEngagingEmailFields = (
  searchId: number,
  fields: Partial<SearchQuestioningRequest>
) => {
  return Http.patch(urls.makeEngagingEmailFieldsUrl(searchId))
    .bind(Http.withJsonBody(fields))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const getEngagingEmailPreview = (searchId: number) => {
  return Http.get(urls.makeEngagingEmailPreviewUrl(searchId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const startMatchMinerSearch = (
  searchId: number,
  settings: MatchMinerSetupSettings
) => {
  return Http.post(urls.makeCreateMatchMinerSearchUrl(searchId))
    .bind(Http.withJsonBody(settings))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const startMatchScoutSearch = (
  searchId: number,
  settings: MatchScoutSetupSettings
) => {
  return Http.post(urls.makeCreateMatchScoutSearchUrl(searchId))
    .bind(Http.withJsonBody(settings))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const restartMatchServiceSearch = (
  searchId: number,
  settings: MatchMinerSetupSettings | MatchScoutSetupSettings,
  currentTab: LineupTabs
) => {
  const url =
    currentTab === LineupTabs.MatchMiner
      ? urls.makeRestartMatchMinerSearchUrl(searchId)
      : urls.makeRestartMatchScoutSearchUrl(searchId);

  return Http.post(url)
    .bind(Http.withJsonBody(settings))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const getMatchMinerRejectionReasons = (dataSourceId: number) => {
  return Http.get(urls.makeMatchMinerRejectionReasonsUrl())
    .bind(Http.withQueryParams({ dataSourceId }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson)
    .map((reasons: string[]) => {
      return reasons.map((value) => ({ label: value, value }));
    });
};

export const loadMoreApplicants = (
  searchId: number,
  limit: number,
  currentTab: LineupTabs
) => {
  const url =
    currentTab === LineupTabs.MatchMiner
      ? urls.makeLoadMoreMatchMinerApplicantsUrl(searchId)
      : urls.makeLoadMoreMatchScoutApplicantsUrl(searchId);

  return Http.post(url)
    .bind(Http.withQueryParams({ limit }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

type GetSearchByParentIdParamsT = {
  parentId: number;
  isMatchMinerSearch?: boolean;
  isMatchScoutSearch?: boolean;
};

export const getSearchByParentId = ({
  parentId,
  isMatchMinerSearch,
  isMatchScoutSearch,
}: GetSearchByParentIdParamsT) => {
  return Http.get(urls.SEARCH_LIST_API)
    .bind(
      Http.withQueryParams({ isMatchMinerSearch, isMatchScoutSearch, parentId })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson)
    .map((list: SearchListResponseV2) => list.items[0] || null);
};

export const downloadPositionStatusReport = (searchId: number) => {
  return Http.get(urls.makePositionStatusReportUrl(searchId))
    .toTask()
    .chain(parseResponseBlob)
    .chainError(parseResponseJson);
};

export const sendPositionStatusReport = (searchId: number, body: string[]) => {
  return Http.post(urls.makePositionStatusReportUrl(searchId))
    .bind(Http.withJsonBody(body))
    .toTask()
    .chainError(parseErrorJson);
};

export const getAmountOfParsedCandidatesForMMSearch = (
  dataSourceId: number,
  jobId: string
) => {
  return Http.get(urls.PARSED_CANDIDATES_MM_SEARCH(dataSourceId, jobId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseResponseJson);
};
