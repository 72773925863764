import { useContextSelector } from 'use-context-selector';
import React, { useState, useMemo, useCallback } from 'react';
import { AppContext, AppContextT } from 'context/app';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';
import localSettings, { setLocalSettingsValue } from '@air/localSettings';
import { INTERVIEW_LEFT_PANEL_COLLAPSED } from '@air/domain/WebStorage/webStorage';

export enum RightSidebarPanel {
  JobDescription = 'JobDescription',
  Uploads = 'Uploads',
  Report = 'Report',
  MatchServiceSettings = 'MatchServiceSettings',
  HelpCenter = 'HelpCenter',
  Wallpapers = 'Wallpapers',
  Job = 'Job',
}

export const AppProvider: React.FC = ({ children }) => {
  const [isLeftSideBarCollapsed, setIsLeftSideBarCollapsed] = useState(
    localSettings[INTERVIEW_LEFT_PANEL_COLLAPSED]
  );

  const [rightSidebarPanel, setRightSidebarPanel] = useState(null);

  const setLeftSideBarCollapsedCb = useCallback((isCollapsed: boolean) => {
    setIsLeftSideBarCollapsed(isCollapsed);
    setLocalSettingsValue(INTERVIEW_LEFT_PANEL_COLLAPSED, isCollapsed);
  }, []);

  const appContextValue = useMemo(
    () => ({
      isLeftSideBarCollapsed,
      rightSidebarPanel,
      methods: {
        setRightSidebarPanel,
        setLeftSideBarCollapsed: setLeftSideBarCollapsedCb,
      },
    }),
    [isLeftSideBarCollapsed, rightSidebarPanel, setLeftSideBarCollapsedCb]
  );

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = <Selected,>(
  selector: (state: AppContextT) => Selected
) => {
  return useContextSelector(AppContext, selector);
};

export const useAppMethods = () => {
  return useEqualContextSelector(
    AppContext,
    (state: AppContextT) => state.methods,
    R.shallowEqualObjects
  );
};
