import { MutableRefObject } from 'react';
import sanitize from 'sanitize-html';

import R from '@air/third-party/ramda';
import uniqueId from '@air/utils/uniqueId';
import {
  CandidateSearchProfileStatus,
  EmailTemplateResponse,
  JobEmailCandidate,
  JobEmailStatus,
  PlaceholderObject,
  SectionRequest,
  SectionResponse,
  SectionSchemaResponse,
  SectionType,
  UserInfoVO,
} from '@air/api';
import * as phrases from 'constants/phrases';

export const CREATED_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_TEMPLATE_CREATOR = {
  id: -1,
  firstName: phrases.DEFAULT_TEMPLATE_CREATOR,
  lastName: '',
};

export type EmailConfigSectionT = {
  dataSourceId?: string;
  jobDescriptionId?: string;
  emailId?: string;
};

export type EmailSectionT = SectionSchemaResponse & {
  id?: number;
  idx?: number;
  content: string;
  alternativeContent: string;
  displayName: string;
  type: SectionType;
  placeholders: PlaceholderObject[];
};

export type JobEmailT = {
  id?: string;
  subject: string;
  sections: EmailSectionT[];
  created?: number;
  updated?: number;
  creator?: UserInfoVO;
  status?: JobEmailStatus;
  unsavedChanges?: boolean;
  name?: string;
};

export type EmailTemplateT = Exclude<JobEmailT, 'status' | 'sections'> & {
  sections: Array<SectionResponse>;
};

export type SelectedEmailEntityT = JobEmailT | EmailTemplateT;

export const FREEFORM_SECTION: EmailSectionT = {
  displayName: phrases.EMAIL_TEMPLATE_SECTION_FREEFORM,
  type: SectionType.FREEFORMTEXT,
  placeholders: null,
  content: '',
  alternativeContent: '',
  hasAlternative: false,
};

export const createPlaceholderNode = (placeholder: PlaceholderObject) => {
  const placeholderNode = document.createElement('span');
  placeholderNode.contentEditable = 'false';
  placeholderNode.textContent = placeholder.displayName;
  placeholderNode.setAttribute('data-placeholder', placeholder.placeholder);
  return placeholderNode;
};

export const insertPlaceholderNode = (placeholderNode: HTMLSpanElement) => {
  const sel = window.getSelection();
  if (sel.getRangeAt && sel.rangeCount) {
    let range = sel.getRangeAt(0);
    range.deleteContents();
    range.insertNode(placeholderNode);
    range = range.cloneRange();
    range.setStartAfter(placeholderNode);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

export const EMAIL_SECTIONS_MAPPING = {
  [SectionType.GREETING]: phrases.EMAIL_TEMPLATE_SECTION_GREETINGS,
  [SectionType.CANDIDATESCURRENTEXPERIENCE]:
    phrases.EMAIL_TEMPLATE_SECTION_EXPERIENCE,
  [SectionType.MATCHEDSKILLS]: phrases.EMAIL_TEMPLATE_SECTION_SKILLS_MATCHED,
  [SectionType.SKILLSWITHLACKOFINFO]:
    phrases.EMAIL_TEMPLATE_SECTION_SKILLS_LACK,
  [SectionType.MISSINGSKILLS]: phrases.EMAIL_TEMPLATE_SECTION_SKILLS_MISSING,
  [SectionType.FREEFORMTEXT]: phrases.EMAIL_TEMPLATE_SECTION_FREEFORM,
};

export const EMAIL_SECTION_TITLES = Object.keys<SectionType>(
  EMAIL_SECTIONS_MAPPING
).map((key) => EMAIL_SECTIONS_MAPPING[key].toUpperCase());

export const NEW_EMAIL_DEFAULT_SUBJECT = (position: string) =>
  phrases.EMAIL_SECTION_NEWLY_CREATED_JOB_EMAIL_SUBJECT(
    normalizeEmailSubject(position)
  );

const MAX_EMAIL_SECTION_LENGTH = 3000;
export const MAX_EMAIL_SUBJECT_LENGTH = 255;
export const normalizeEmailSubject = (emailSubject: string) => {
  return emailSubject.trim().slice(0, MAX_EMAIL_SUBJECT_LENGTH);
};

export const sanitizeHTMLContent = (dirtyContent: string) => {
  if (!dirtyContent) return '';

  const sanitizedContent = sanitize(dirtyContent, {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['contenteditable', 'data-placeholder'],
    },
    selfClosing: ['br'],
    nonTextTags: [
      'img',
      'svg',
      'style',
      'script',
      'textarea',
      'option',
      'noscript',
      'strong',
      'iframe',
    ],
    disallowedTagsMode: 'discard',
  });

  return sanitizedContent.slice(0, MAX_EMAIL_SECTION_LENGTH);
};

export const normalizePlaceholders = (content: string): string => {
  const container = document.createElement('div');
  container.innerHTML = content;

  container
    .querySelectorAll('[data-placeholder]')
    .forEach((node: HTMLElement) => {
      const placeholderNode = document.createTextNode(node.dataset.placeholder);
      node.replaceWith(placeholderNode);
    });

  return container.innerText;
};

export type EmailSectionsButtonT = SectionSchemaResponse & {
  refId?: string;
  displayName: string;
};

export const prepareEmailSectionsListButtonsForUI = (
  items: SectionSchemaResponse[]
): EmailSectionsButtonT[] => {
  return items.map((item) => ({
    ...item,
    displayName: EMAIL_SECTIONS_MAPPING[item.type],
    refId: uniqueId(),
  }));
};

export const removeEmptySections = (
  sections: Array<{ content?: string; alternativeContent?: string }>
) => {
  return sections.reduce((memo, section) => {
    return section.content || section.alternativeContent
      ? [...memo, section]
      : memo;
  }, []);
};

export const transformEmailSectionButtonIntoSection = (
  emailSectionButton: EmailSectionsButtonT,
  idx: number
): EmailSectionT => {
  // When the button is clicked add a new empty section of a needed type to the email
  return {
    idx,
    content: '',
    alternativeContent: '',
    displayName: emailSectionButton.displayName,
    type: emailSectionButton.type,
    hasAlternative: emailSectionButton.hasAlternative,
    placeholders: emailSectionButton.placeholders,
  };
};

const doesSectionAlreadyExist = (
  existingSection: SectionResponse | EmailSectionT,
  sectionToUpdate: EmailSectionT
): boolean => {
  // SectionResponse comes from BE and has no refId
  // idx are unique, they are editable and also represent order
  // EmailSectionT are already transformed for FE emails but if they are added on FE they do not have ids
  // This will determine whether we need to add a new section or update an existing one
  const section = existingSection as EmailSectionT;
  return sectionToUpdate && section?.idx === sectionToUpdate.idx;
};

// This function will be handy in future, when we implement rich email editor.
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
function replaceBackendPlaceholdersWithSpans(
  content: string,
  placeholders: EmailSectionsButtonT[]
) {
  const placeholdersMap = R.pluck('placeholders', placeholders || [])
    .flat()
    .reduce((acc, item) => {
      acc[item.placeholder] = item.displayName;
      return acc;
    }, {} as { [key in string]: string });

  // find placeholders like {{LAST_COMPANY}} and replace with with displayName wrapped in <span/>
  const r = /({{)(\w+)(}})/g;
  return content.replaceAll(r, (match) => {
    const displayName: string = placeholdersMap[match];
    if (!displayName) return match;

    return `<span contenteditable="false" data-placeholder="${match}">${displayName}</span>`;
  });
}

export const prepareEmailSectionsForUI = (
  sections: SectionResponse[] | EmailSectionT[]
): EmailSectionT[] => {
  return (sections || []).map((item, index) => {
    return {
      ...item,
      idx: index,
      type: item.type,
      displayName: EMAIL_SECTIONS_MAPPING[item.type],
      placeholders: item.placeholders,
      content: item?.content,
      alternativeContent: item?.alternativeContent,
      hasAlternative: item.type !== SectionType.FREEFORMTEXT,
    };
  });
};

export const prepareEmailSectionsForBE = (
  addedSections: EmailSectionT[],
  sectionToUpdate?: EmailSectionT
): SectionRequest[] => {
  return (addedSections || [])
    .map((item, index) => {
      const isThisSectionToBeUpdated = doesSectionAlreadyExist(
        item,
        sectionToUpdate
      );

      const { content, alternativeContent } = isThisSectionToBeUpdated
        ? sectionToUpdate
        : item;

      return {
        idx: index,
        type: item.type,
        // content: normalizePlaceholders(content),
        content,
        alternativeContent,
        placeholders: (item.placeholders || []).map((placeholder) => ({
          placeholder: placeholder.placeholder,
          displayName: placeholder.displayName,
        })),
      };
    })
    .filter((section) => section.content || section.alternativeContent);
};

export const prepareTemplateForUI = (
  emailTemplate: EmailTemplateResponse
): EmailTemplateT => {
  return {
    id: emailTemplate.id,
    created: emailTemplate.created,
    updated: emailTemplate.updated,
    creator: emailTemplate.creator,
    subject: emailTemplate.subject.content,
    sections: prepareEmailSectionsForUI(emailTemplate.sections),
    unsavedChanges: emailTemplate.unsavedChanges,
    name: emailTemplate.name,
    status: null,
  };
};

type StatT = {
  pos: number;
  done: boolean;
};

/* Window.getSelection returns a Node with the position relative to it.
 * Every time we are creating new elements inside contentEditable or applying data stored in setState
 * so we can't restore position using old node objects.
 * So we are getting position relative to the contenteditable using getCursorPosition function.
 * And, after we set innerHTML content we restore the cursor position using setCursorPosition.
 * Both getCursorPosition and setCursorPosition work with nested elements
 */

// get the cursor position
export const getCursorPosition = (
  refCurrent: any,
  selection: Selection,
  stat: StatT
) => {
  // get current focused node and selection offset
  const node = selection.focusNode;
  const offset = selection.focusOffset;
  // if the position was already set, exit
  if (stat.done) return stat;

  let currentNode = null;
  if (refCurrent.childNodes.length == 0) {
    stat.pos += refCurrent.textContent.length;
  } else {
    for (let i = 0; i < refCurrent.childNodes.length && !stat.done; i++) {
      // find the node where the cursor is and save the cursor position
      currentNode = refCurrent.childNodes[i];
      if (currentNode === node) {
        stat.pos += offset;
        stat.done = true;
        return stat;
      } else getCursorPosition(currentNode, selection, stat);
    }
  }
  return stat;
};

// find the child node and relative position and set it on range
export const setRelativePositionOnChildNode = (
  currentNode: any,
  range: Range,
  stat: StatT
) => {
  if (stat.done) return range;

  if (currentNode.childNodes.length == 0) {
    if (currentNode.textContent.length >= stat.pos) {
      range.setStart(currentNode, stat.pos);
      stat.done = true;
    } else {
      stat.pos = stat.pos - currentNode.textContent.length;
    }
  } else {
    for (let i = 0; i < currentNode.childNodes.length && !stat.done; i++) {
      setRelativePositionOnChildNode(currentNode.childNodes[i], range, stat);
    }
  }
  return range;
};

// Restore cursor position
export const setCursorPosition = (
  ref: MutableRefObject<any>,
  selection: Selection,
  currentCursorPosition: StatT
) => {
  selection.removeAllRanges();
  const range = setRelativePositionOnChildNode(
    ref.current,
    document.createRange(),
    {
      pos: currentCursorPosition.pos,
      done: false,
    }
  );
  range.collapse(true);
  selection.addRange(range);
};

// Email / Template is considered valid only if it has both subject and
// at least 1 section with any content.
export function checkEmailValidity(email: SelectedEmailEntityT) {
  return (
    !!email.subject &&
    email.sections.some(({ content, alternativeContent }) => {
      return !!content || !!alternativeContent;
    })
  );
}

const recipientsNotShownForEmailConfig = [
  CandidateSearchProfileStatus.ATSFAILEDTOPARSE,
  CandidateSearchProfileStatus.NOTPROCESSED,
];

export const prepareRecipientsForUI = (items: JobEmailCandidate[]) => {
  return R.pipe(
    R.filter(
      (item: JobEmailCandidate) =>
        !recipientsNotShownForEmailConfig.includes(item.status)
    ),
    R.groupBy((item: JobEmailCandidate) => (item.emailSent ? 'sent' : 'new'))
  )(items || []);
};
