import { useCallback, useMemo } from 'react';
import {
  localStore,
  BACKGROUND_IMAGE_ID_STORAGE_KEY,
  KANBAN_SECTIONS,
} from '@air/domain/WebStorage/webStorage';
import {
  KANBAN_SECTION_DRAFTS,
  KANBAN_SECTION_PAUSED,
  KANBAN_SECTION_CLOSED,
  KANBAN_SECTION_SCREENING,
  KANBAN_SECTION_JOBS,
} from 'constants/phrases';

function userSettingsStorageService(subStorageName: string, userId: number) {
  return {
    getStore() {
      return localStore.getItem(subStorageName);
    },

    getItem(key: string) {
      const store = this.getStore();

      return store && store[userId] && store[userId][key];
    },

    setItem(key: string, value: any) {
      const store = this.getStore() || {};

      localStore.setItem(subStorageName, {
        ...store,
        [userId]: {
          ...store[userId],
          [key]: value,
        },
      });
    },

    setItems(values: { [key: string]: any }) {
      for (const [key, value] of Object.entries(values)) {
        this.setItem(key, value, userId);
      }
    },

    removeItem(key: string) {
      const { [key]: removed, ...updatedStorage } = this.getStore()[userId];
      localStore.setItem(subStorageName, {
        ...this.getStore(),
        [userId]: updatedStorage,
      });
    },
  };
}

export type KanbanSectionNames =
  | typeof KANBAN_SECTION_JOBS
  | typeof KANBAN_SECTION_DRAFTS
  | typeof KANBAN_SECTION_SCREENING
  | typeof KANBAN_SECTION_PAUSED
  | typeof KANBAN_SECTION_CLOSED;

const subStorageName = 'USER_SETTINGS';

const TRIAL_INFO_STORAGE_KEY = 'TRIAL_INFO';
type TrialSettingsT = {
  lastDaysShown: null | number;
  isShown: boolean;
};

export const useUserSettings = (customerId: number) => {
  const userSettings = useMemo(
    () => userSettingsStorageService(subStorageName, customerId),
    [customerId]
  );

  const storedBackgroundImageId = userSettings.getItem(
    BACKGROUND_IMAGE_ID_STORAGE_KEY
  );

  const setBackgroundImage = useCallback(
    (imageId: number) => {
      userSettings.setItem(BACKGROUND_IMAGE_ID_STORAGE_KEY, imageId);
    },
    [userSettings]
  );

  const saveSectionState = useCallback(
    (section: KanbanSectionNames, isOpen: boolean) => {
      const storedSections = userSettings.getItem(KANBAN_SECTIONS) || {};

      const sectionName = section.toLowerCase();
      userSettings.setItem(KANBAN_SECTIONS, {
        ...storedSections,
        [sectionName]: {
          ...storedSections[sectionName],
          isOpen,
        },
      });
    },
    [userSettings]
  );

  const getSection = useCallback(
    (sectionName: KanbanSectionNames) => {
      const storedSections = userSettings.getItem(KANBAN_SECTIONS) || {};
      return storedSections[sectionName.toLowerCase()];
    },
    [userSettings]
  );

  const trialSettings = useMemo(() => {
    const trialInfo = userSettings.getItem(TRIAL_INFO_STORAGE_KEY) || {
      lastDaysShown: null,
      isShown: false,
    };
    return trialInfo;
  }, [userSettings]);

  const setTrialSettings = useCallback(
    (settings: TrialSettingsT) => {
      userSettings.setItem(TRIAL_INFO_STORAGE_KEY, settings);
    },
    [userSettings]
  );

  return {
    storedBackgroundImageId,
    setBackgroundImage,
    saveSectionState,
    getSection,
    trialSettings,
    setTrialSettings,
  };
};
