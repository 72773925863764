import React, { useState, useCallback, CSSProperties, ReactNode } from 'react';
import Select, { components, ValueType } from 'react-select';
import classNames from 'classnames';
import { FormField, SvgIcon } from '@air/components';
import { useOutsideClick } from '@air/utils/hooks';
import { OptionTypeBase, MenuPlacement } from 'react-select/src/types';
import styles from './FormDropdown.css';
import R from '@air/third-party/ramda';

const emptyComponentFn = (): any => null;

const notEmptyObject = (object: { [key: string]: string }) =>
  object && !R.isEmpty(object);

export enum FormDropdownVariants {
  ICON = 'icon',
}

const getVariantsStyles = (variant: FormDropdownVariants) => {
  switch (variant) {
    case FormDropdownVariants.ICON:
      return {
        valueContainer: () => ({
          padding: '0',
        }),
        control: {
          border: 'none',
        },
        menuList: {
          width: '21.2rem',
          top: '5.5rem',
          left: '-10rem',
          borderRadius: '1.4rem',
          border: '1px solid var(--steel-10-color)',
        },
      };
    default:
      return {
        valueContainer: (base: any, state: any): CSSProperties => ({
          width: `var(--form-field-value-container-width, 'auto')`,
          position: 'relative',
          padding: `var(--form-field-value-container-padding, ${
            state.selectProps?.isBorderOverflown ? '0' : '0 0.4em'
          })`,
          cursor: 'pointer',
          maxWidth: '100%',
          height: `var(--form-field-value-container-height, ${
            state.selectProps?.label ? '6.8rem' : '5.6rem'
          })`,
        }),
      };
  }
};

export const formDropdownStyles = {
  valueContainer: (base: any, state: any): CSSProperties => ({
    ...getVariantsStyles(state.selectProps?.variant).valueContainer(
      base,
      state
    ),
  }),
  input: () => ({
    fontWeight: 450,
  }),
  singleValue: () => ({
    fontWeight: 450,
  }),
  control: (base: any, state: any): any => {
    const beforeCommonStyles = {
      content: "''",
      position: 'absolute',
      left: state.selectProps?.isBorderOverflown ? '-0.5rem' : 0,
      width: state.selectProps?.isBorderOverflown
        ? 'calc(100% + 1rem)'
        : '100%',
      visibility: state.selectProps?.menuIsOpen ? 'visible' : 'hidden',
      height: state.selectProps?.label ? '6.8rem' : '5.6rem',
      maxHeight: state.selectProps?.isBorderOverflown ? 'none' : '100%',
      background: 'var(--white-color)',
      borderLeft: '1px solid var(--steel-10-color)',
      borderRight: '1px solid var(--steel-10-color)',
    };
    return {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      '&:before': notEmptyObject(
        getVariantsStyles(state.selectProps.variant).control
      )
        ? getVariantsStyles(state.selectProps.variant).control
        : state.selectProps?.isMenuTopPlaced
        ? {
            ...beforeCommonStyles,
            bottom: 'var(--form-dropdown-bottom, 0.4rem)',
            borderBottom: '1px solid var(--steel-10-color)',
            borderTopRightRadius:
              'var(--form-dropdown-top-right-border-radius, 0)',
            borderTopLeftRadius: 'var(--form-dropdown-top-left-radius, 0)',
            borderBottomRightRadius:
              'var(--form-dropdown-bottom-right-border-radius, 1.4rem)',
            borderBottomLeftRadius:
              'var(--form-dropdown-bottom-left-border-radius, 1.4rem)',
          }
        : {
            ...beforeCommonStyles,
            top: state.selectProps?.isBorderOverflown ? '-0.5rem' : 0,
            borderTop: '1px solid var(--steel-10-color)',
            borderTopRightRadius:
              'var(--form-dropdown-top-right-border-radius, 1.4rem)',
            borderTopLeftRadius: 'var(--form-dropdown-top-left-radius, 1.4rem)',
            borderBottomRightRadius:
              'var(--form-dropdown-bottom-right-border-radius, 0)',
            borderBottomLeftRadius:
              'var(--form-dropdown-bottom-left-border-radius, 0)',
          },
    };
  },
  clearIndicator: (base: any, state: any) => {
    return {
      justifyContent: 'center',
      alignItems: 'center',
      display: state.hasValue ? 'flex' : 'none',
      '&:hover': {
        '> svg': {
          fill: 'var(--steel-50-color)',
          cursor: 'pointer',
        },
      },
      '> svg': {
        fill: 'var(--steel-30-color)',
      },
    };
  },
  menu: (styles: any, state: any) =>
    state.selectProps?.isMenuTopPlaced || !state.selectProps?.label
      ? {}
      : {
          ...styles,
          zIndex: 'var(--form-dropdown-z-index)',
          position: 'static',
          margin: 0,
          paddingTop: 8,
          borderRadius: 0,
          border: '1px solid var(--steel-10-color)',
          borderTop: 0,
          boxShadow: 'none',
        },
  menuList: (base: any, state: any): any => {
    const commonStyles = {
      position: 'relative',
      maxHeight: '20rem',
      overflowX: 'hidden',
      overflowY: 'auto',
      zIndex: '5',
      width: state.selectProps?.isBorderOverflown
        ? 'calc(100% + 1rem)'
        : '100%',
      left: state.selectProps?.isBorderOverflown ? '-0.5rem' : '0',
      padding: '0.4rem',
      background: 'var(--white-color)',
      borderLeft: '1px solid var(--steel-10-color)',
      borderRight: '1px solid var(--steel-10-color)',
    };
    if (state.selectProps?.isMenuTopPlaced) {
      return {
        ...commonStyles,
        borderBottom: 'none',
        borderTop: '1px solid var(--steel-10-color)',
        borderTopRightRadius: '1.4em',
        borderTopLeftRadius: '1.4em',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        marginTop: 0,
        bottom: '100%',
      };
    } else if (
      notEmptyObject(getVariantsStyles(state.selectProps?.variant).menuList)
    ) {
      return {
        ...commonStyles,
        ...getVariantsStyles(state.selectProps?.variant).menuList,
      };
    } else {
      return {
        ...commonStyles,
        borderBottom: '1px solid var(--steel-10-color)',
        borderTop: 'none',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '1.4rem',
        borderBottomLeftRadius: '1.4em',
        marginTop: '-0.4em',
        bottom: 'none',
      };
    }
  },
  option: (base: any, state: any) => ({
    fontSize: '1.6rem',
    lineHeight: '1.25em',
    color: 'var(--steel-80-color)',
    borderRadius: '1.2rem !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:first--of-type': {
      marginTop: state.selectProps?.isMenuTopPlaced ? 0 : '0.4rem',
    },
    '&:after': {
      content: "'+'",
      visibility: 'hidden',
      display: state.selectProps?.optionWithIcon ? 'flex' : 'none',
      background: 'var(--blue-color)',
      color: 'var(--white-color)',
      width: '1.6rem',
      height: '1.6rem',
      padding: '0 0 0 0.4rem',
      borderRadius: '1.6rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '1.6rem',
    },
    '&:hover:after': {
      visibility: 'visible',
    },
  }),
};

const formDropdownComponents = {
  DropdownIndicator: emptyComponentFn,
  IndicatorSeparator: emptyComponentFn,
  ValueContainer: ({ children, isDisabled, ...props }: any) => {
    if (!props.selectProps.value) return null;

    const { selectProps } = props;
    const { label } = selectProps?.value;

    return (
      <components.ValueContainer {...props} {...children}>
        {props.selectProps.valueContainer ? (
          <div onClick={selectProps?.onClick}>
            {props.selectProps.valueContainer}
          </div>
        ) : (
          <>
            <FormField
              id={selectProps?.id}
              name={selectProps.name}
              className={classNames(
                styles.valueComponent,
                selectProps.valueContainerClassName
              )}
              isEmpty={!label}
              value={label ?? ''}
              label={selectProps?.label}
              placeholder={selectProps?.placeholder}
              type="text"
              isValid={selectProps?.isValid}
              isDisabled={isDisabled}
              hasWarning={selectProps?.hasWarning}
              hasError={selectProps?.hasError}
              readOnly
              onClick={selectProps?.onClick}
              onBlur={selectProps?.onBlur}
            />
            <SvgIcon
              icon="shevron"
              className={classNames(styles.dropdownIndicator, {
                [styles.rotated]: selectProps?.menuIsOpen,
                [styles.withoutLabel]: !selectProps?.label,
                [styles.disabled]: selectProps?.isDisabled,
              })}
              onClick={selectProps?.onClick}
            />
          </>
        )}
      </components.ValueContainer>
    );
  },
  Option: (props: any) => {
    const isValueSelected = props.value === props.selectProps.value.value;

    return (
      <div
        className={classNames(styles.menuOptionWrapper, {
          [styles.disabled]: props.isDisabled,
        })}
      >
        <components.Option {...props} />
        {isValueSelected && <SvgIcon icon="check-icon-thin" />}
      </div>
    );
  },
};

type Props = {
  options: any[];
  value: { value: string | number; label: string };
  id?: string;
  type?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  onChange: (value: ValueType<OptionTypeBase>) => void;
  isDisabled?: boolean;
  isValid?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  className?: string;
  valueContainerClassName?: string;
  rules?: any;
  menuPlacement?: MenuPlacement;
  valueContainer?: ReactNode;
  /*
    This property is responsible for dropdown to look like latest UI designs,
    when the dropdown menu is located "under" the input field, and its borders
    are visible around the input (see MR AR-10835).
  */
  isBorderOverflown?: boolean;
  isClearable?: boolean;
  variant?: FormDropdownVariants;
};

export const FormDropdown: React.FC<Props> = React.forwardRef(
  (
    {
      options,
      className,
      value,
      menuPlacement = 'bottom' as MenuPlacement,
      ...props
    },
    ref
  ) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const isMenuTopPlaced = menuPlacement === 'top';

    const onChange = useCallback(
      (value: OptionTypeBase) => {
        props.onChange(value);
        setMenuOpen(false);
      },
      [props]
    );

    const onCloseMenu = useCallback(() => {
      setMenuOpen(false);
    }, []);

    const [outsideClickRef] = useOutsideClick(onCloseMenu, {
      useCapture: true,
    });

    return (
      <div
        className={classNames(styles.formDropdownWrapper, className)}
        ref={outsideClickRef}
      >
        <Select
          {...props}
          inputRef={ref}
          components={formDropdownComponents}
          styles={formDropdownStyles}
          classNamePrefix="autocompleteSelect"
          options={options}
          value={value}
          placeholder={props?.placeholder ?? null}
          menuIsOpen={isMenuOpen}
          onChange={onChange}
          hasWarning={props?.hasWarning}
          onClick={() => setMenuOpen(!isMenuOpen)}
          onBlur={() => setMenuOpen(false)}
          isMenuTopPlaced={isMenuTopPlaced}
          menuPlacement={menuPlacement}
        />
      </div>
    );
  }
);
