import { useState, useCallback, useEffect } from 'react';
import { subscribe } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';

export const useUploadPanelControl = (
  shouldShowUploadList = true,
  isUploadsListOpen: boolean,
  setUploadsListOpen: (state: boolean) => void
) => {
  const [isUploadOpenedByDragging, setUploadOpenedByDragging] = useState(false);

  const openUploadSection = useCallback(() => {
    if (shouldShowUploadList) {
      if (!isUploadsListOpen) {
        setUploadOpenedByDragging(true);
      }
      setUploadsListOpen(true);
    }
  }, [shouldShowUploadList, isUploadsListOpen, setUploadsListOpen]);

  const closeUploadSection = useCallback(() => {
    if (shouldShowUploadList && isUploadOpenedByDragging) {
      setUploadsListOpen(false);
      setUploadOpenedByDragging(false);
    }
  }, [shouldShowUploadList, isUploadOpenedByDragging, setUploadsListOpen]);

  useEffect(() => {
    const unsubscribeDragStart = subscribe(
      APP_EVENTS.APPLICANT_DRAG_START,
      openUploadSection
    );

    const unsubscribeDragEnd = subscribe(
      APP_EVENTS.APPLICANT_DRAG_END,
      closeUploadSection
    );

    return () => {
      unsubscribeDragStart();
      unsubscribeDragEnd();
    };
  }, [openUploadSection, closeUploadSection]);

  return {
    isUploadsListOpen,
    setUploadsListOpen,
  };
};
