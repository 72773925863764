/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface EnrichmentInfo
 */
export interface EnrichmentInfo {
  /**
   *
   * @type {string}
   * @memberof EnrichmentInfo
   */
  status?: EnrichmentInfoStatusEnum;
  /**
   *
   * @type {number}
   * @memberof EnrichmentInfo
   */
  startedAt?: number;
  /**
   *
   * @type {number}
   * @memberof EnrichmentInfo
   */
  finishedAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof EnrichmentInfo
   */
  outdated?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum EnrichmentInfoStatusEnum {
  INPROGRESS = 'IN_PROGRESS',
  ENRICHED = 'ENRICHED',
  NODATAENRICHED = 'NO_DATA_ENRICHED',
  FAILED = 'FAILED',
}
