import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  getCriteriaItems,
  getExperience,
} from 'domain/CandidateData';
import {
  BaseCriteriaWithList,
  CardType,
  isCompanySizeType,
  getCompanyTitle
} from 'domain/SearchCriteria';
import {
  CompanyExperienceResult,
  SearchCompanyExperience,
  SearchCompanyExperienceItem,
  SearchCriteriaImportanceEnum,
} from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateExcludeCompanyData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.company;
  exclude: true;
}

export interface CandidateIncludeCompanyData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.company;
  exclude: false;
}
export type CandidateCompanyData =
  | CandidateIncludeCompanyData
  | CandidateExcludeCompanyData;

const getCompanyItem = (
  listItem: CompanyExperienceResult
): CandidateCriteriaListItem => {
  return {
    id: listItem.name,
    label: getCompanyTitle(listItem.name, {
      usOnly: listItem.usOnly,
      type: listItem.type,
    }),
    type: listItem.type,
    experience: getExperience(listItem),
    status: listItem.status,
    alert: getAlertData(listItem.alerts),
    matchResolution: listItem.matchResolution,
    ...(isCompanySizeType(listItem.type)
      ? { minSize: listItem.minSize, maxSize: listItem.maxSize }
      : {}),
    interview: interviewDataMapper(listItem.refId, listItem.interview),
  };
};

export const mapCompanies = (
  searchCompanies: SearchCompanyExperience
): CandidateCompanyData[] => {
  const includeCompanies: CandidateIncludeCompanyData[] = (
    searchCompanies?.includeList || []
  ).map((item: SearchCompanyExperienceItem) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchCompanyExperienceItem,
      CandidateCriteriaListItem
    >(getCompanyItem, item);

    return {
      id: `include-${item.idx}`,
      refId: item.refId,
      idx: item.idx,
      cardType: CardType.company,
      list,
      exclude: false,
      importance: criteriaImportanceOptions[item.importance],
      status: primaryItem.status,
    };
  });

  const excludeCompanies: CandidateExcludeCompanyData[] = (
    searchCompanies?.excludeList || []
  ).map((item: SearchCompanyExperienceItem) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchCompanyExperienceItem,
      CandidateCriteriaListItem
    >(getCompanyItem, item);

    return {
      id: `exclude-${item.idx}`,
      refId: item.refId,
      idx: item.idx,
      cardType: CardType.company,
      list,
      exclude: true,
      importance:
        criteriaImportanceOptions[
          item.importance as SearchCriteriaImportanceEnum
        ],
      status: primaryItem.status,
    };
  });

  return [...includeCompanies, ...excludeCompanies];
};
