// imports from vendor deps
import React, { ReactNode, useCallback, useState, useRef } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import useSetState from 'react-use/lib/useSetState';
// imports from types
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData/';
// imports from helpers
import {
  GroupedPairT,
  LineupTableProps,
  LineupTHeadRow,
} from 'components/LineupTable/utils';
// imports from styles
import styles from './LineupTable.css';
import { HeaderCriteriaData } from 'domain/HeaderData/HeaderDataMapper';
import { TableBodyCellRenderer } from '../SearchResultsView/utils';
import { CandidateLineupData } from 'domain/CandidateData/CandidateLineupData';
import { FilterWithPlaceholder } from 'components';
import { trackEvent } from '@air/utils/ga';
import { UIText } from '@air/components';
import * as phrases from 'constants/phrases';
import { GA_LABEL_TAB_EXPAND_REJECTED } from 'constants/gaLabels';
import { GACategory } from '@air/domain/Common/GATypes';
import { CandidateSearchProfileStatus } from '@air/api';
import { LineupCardPreview } from 'components/LineupTable/HeaderCellRenderers';

// import from images
// import {} from 'images'

const DISPLAY_TOOLTIP_DELAY = 0;

// exports / component definitions
export const LineupTable: React.FC<LineupTableProps> = React.memo(
  ({
    columns: tableColumns,
    sortedData,
    tableSections,
    onStatusRowClick,
    firstColumnRenderer,
    cellOnClickHandler,
    totalApplicants,
    isProcessing,
    currentRequisition,
    isCandidatesListLoading,
    onFilterChange,
    filterValue,
  }) => {
    const hasAnimatedSectionTitle = useCallback(
      (status: string) =>
        status === CandidateSearchProfileStatusEnum.NOTPROCESSED ||
        (status === CandidateSearchProfileStatusEnum.UPLOAD &&
          currentRequisition?.pendingCount > 0),
      [currentRequisition]
    );

    const [focusedColumnIndex, setFocusedColumnIndex] = useState(null);

    const focusCellHandler = useCallback(
      (idx) => {
        setFocusedColumnIndex(idx);
      },
      [setFocusedColumnIndex]
    );

    const blurCellHandler = useCallback(() => {
      setFocusedColumnIndex(null);
    }, [setFocusedColumnIndex]);
    const columns = tableColumns;
    const cardsColumnCount = columns.length;
    const hasDataRows = sortedData.length > 0;
    const hasApplicants =
      isProcessing || hasDataRows || isCandidatesListLoading || totalApplicants;

    return (
      <div
        style={
          {
            /*
              AR-8203
              --cards-column-count has to be at least 1, because otherwise
              the repeat() function describing the grid-template-rows
              of .candidatesTable grid breaks the grid when it receives `0` as
              an argument.
              This case is possible (at the moment) if user checks not-intersecting
              sets of criteria and importance (see original issue).
             */
            '--cards-column-count': Math.max(cardsColumnCount, 1),
          } as React.CSSProperties
        }
        className={classNames(styles.candidatesTable, {
          [styles.emptyState]: !hasApplicants,
          [styles.headerlessEmptyTable]: !cardsColumnCount && hasApplicants,
        })}
      >
        {(hasApplicants || filterValue) && (
          <>
            <div className={styles.headerFirstCell}>
              <FilterWithPlaceholder
                isDisabled={!totalApplicants}
                placeholderTitle={phrases.SEARCH_FILTER_PLACEHOLDER_FILTER}
                onChange={onFilterChange}
                defaultValue={filterValue}
              />
            </div>
            <TableHeadRow
              className={classNames(styles.columns, {
                [styles.noColumns]: !cardsColumnCount,
              })}
              columns={columns}
              focusedColumn={focusedColumnIndex}
            />
          </>
        )}

        {Object.entries(tableSections).map(
          ([status, section], index, sections) => {
            const {
              title: sectionTitle,
              counter: sectionCounter,
              opened,
            } = section;

            /*
            Because section headings have sticky positioning, if corresponding
            section was scrolled out of visible table area, then expanding
            it from collapsed state does not reveal section content (body).
            We have to manually scroll it into view, after the end of 'max-height'
            property transition, which gradually reveals body rows.
           */
            const handleStatusRowClick = (e: any) => {
              if (!sectionCounter) return;

              const target = e.target.closest(`.${styles.sectionHeader}`);
              const siblingTableSection = target.nextElementSibling;

              if (
                status === CandidateSearchProfileStatus.RECOMMENDEDFORREJECTION
              ) {
                trackEvent({
                  category: GACategory.ScreeningPage,
                  label: GA_LABEL_TAB_EXPAND_REJECTED,
                });
              }

              if (
                !opened &&
                siblingTableSection &&
                siblingTableSection.matches(`.${styles.tableSection}`)
              ) {
                siblingTableSection.addEventListener(
                  'transitionend',
                  function handler(e: any) {
                    if (e.propertyName === 'max-height') {
                      siblingTableSection.removeEventListener(
                        'transitionend',
                        handler
                      );
                      siblingTableSection.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest',
                      });
                    }
                  }
                );
              }

              onStatusRowClick(status as CandidateSearchProfileStatusEnum);
            };

            const sectionData = sortedData.find(
              // @ts-ignore
              R.compose<
                GroupedPairT,
                CandidateSearchProfileStatusEnum,
                boolean
              >(
                R.equals(status),
                // @ts-ignore
                R.head
              )
            );

            const isTableSectionOpened =
              tableSections[status as CandidateSearchProfileStatusEnum].opened;
            const isFirstTableSection = index === 1;
            const isLastTableSection = index === sections.length - 1;
            const isNotProcessedSection =
              status === CandidateSearchProfileStatusEnum.NOTPROCESSED;
            const showLoadingTableHeader =
              isCandidatesListLoading ||
              (isProcessing && hasAnimatedSectionTitle(status));

            if (isNotProcessedSection && !sectionCounter) {
              return null;
            }

            if (!sectionData) {
              return (
                <TableBodyHeader
                  key={status}
                  index={index}
                  onClick={handleStatusRowClick}
                  title={sectionTitle}
                  bodyRowsCount={sectionCounter || 0}
                  className={classNames(mapStatusToHeaderClassname(status), {
                    [styles.firstSection]: isFirstTableSection,
                    [styles.lastSection]: isLastTableSection,
                    [styles.animatedTitle]: showLoadingTableHeader,
                  })}
                />
              );
            }

            const [, sectionItems] = sectionData;

            return (
              <React.Fragment key={status}>
                <TableBodyHeader
                  index={index}
                  onClick={handleStatusRowClick}
                  title={sectionTitle}
                  bodyRowsCount={sectionCounter}
                  className={classNames(mapStatusToHeaderClassname(status), {
                    [styles.firstSection]: isFirstTableSection,
                    [styles.lastSection]:
                      isLastTableSection &&
                      (!sectionItems.length || !isTableSectionOpened),
                    [styles.emptySectionHeader]:
                      !sectionItems.length && !hasDataRows,
                    [styles.animatedTitle]: showLoadingTableHeader,
                  })}
                />
                {sectionItems.length && (
                  <TableBody
                    className={classNames({
                      [styles.lastSection]: isLastTableSection,
                    })}
                    firstColumnRenderer={firstColumnRenderer}
                    cellOnClickHandler={cellOnClickHandler}
                    onFocusCell={focusCellHandler}
                    onBlurCell={blurCellHandler}
                    columns={columns}
                    rows={sectionItems}
                    areRowsHidden={!isTableSectionOpened}
                    totalSections={sections.length}
                  />
                )}
              </React.Fragment>
            );
          }
        )}
      </div>
    );
  }
);
LineupTable.displayName = 'LineupTable';

const TableHeadRow: React.FC<LineupTHeadRow & { className: string }> =
  React.memo(({ className, columns, focusedColumn }) => {
    const [detailedViewIndexMap, setDetailedViewIndex] = useSetState<{
      [idx: number]: any;
    }>({});

    const onExpand = useCallback(
      (index: number) => {
        setDetailedViewIndex(() => ({ [index]: true }));
      },
      [setDetailedViewIndex]
    );
    const onClose = useCallback(
      (index: number) => {
        setDetailedViewIndex(() => ({ [index]: false }));
      },
      [setDetailedViewIndex]
    );

    return (
      <div
        className={className}
        style={{ '--column-count': columns.length } as React.CSSProperties}
      >
        {columns.map((item, idx) => {
          const isFocused = focusedColumn === idx;
          return (
            <div
              key={item?.cardData.key}
              className={classNames(styles.headerCellWrapper, {
                [styles.focusedHeaderCell]:
                  isFocused || detailedViewIndexMap[idx],
              })}
            >
              <LineupCardPreview
                index={idx}
                onExpand={onExpand}
                onClose={onClose}
                cardData={item.cardData}
                focused={isFocused}
              />
            </div>
          );
        })}
      </div>
    );
  });
TableHeadRow.displayName = 'TableHeadRow';

type TableBodyHeaderProps = {
  className?: string;
  onClick: React.MouseEventHandler;
  title: string;
  bodyRowsCount: number | string; // can contain '999+' string
  index: number;
};
const TableBodyHeader: React.FC<TableBodyHeaderProps> = ({
  className,
  onClick,
  title,
  bodyRowsCount,
}) => {
  return (
    <div
      className={classNames(styles.sectionHeader, className)}
      onClick={onClick}
    >
      <div className={styles.candidatesStatus}>
        <UIText className={styles.candidatesStatusTitle}>{title}</UIText>
        <UIText className={styles.sectionHeaderCounter}>{bodyRowsCount}</UIText>
      </div>
      <div className={styles.paddingCell} />
    </div>
  );
};

type TableBodyProps = {
  rows: CandidateLineupData[];
  columns: HeaderCriteriaData[];
  firstColumnRenderer: (item: CandidateLineupData) => ReactNode;
  focusedColumn?: number;
  className?: string;
  totalSections: number;
  onFocusCell: (id: number) => void;
  onBlurCell: () => void;
  cellOnClickHandler: (
    id: string,
    cardId: string | number,
    showNote: boolean
  ) => void;
  areRowsHidden: boolean;
};
const TableBody: React.FC<TableBodyProps> = React.memo((props) => {
  const {
    rows,
    columns,
    firstColumnRenderer,
    cellOnClickHandler,
    className,
    totalSections,
    onFocusCell,
    onBlurCell,
    areRowsHidden,
  } = props;

  const scheduledFocusId = useRef<number>(null);

  const hoverHandler = useCallback(
    (idx: number) => () => {
      scheduledFocusId.current = window.setTimeout(() => {
        onFocusCell(idx);
      }, DISPLAY_TOOLTIP_DELAY);
    },
    [onFocusCell]
  );

  const mouseLeaveHandler = useCallback(() => {
    clearTimeout(scheduledFocusId.current);
    onBlurCell();
  }, [onBlurCell]);

  return (
    <div
      className={classNames(styles.tableSection, className, {
        [styles.closed]: areRowsHidden,
      })}
      style={
        {
          '--column-count': columns.length,
          '--sections-count': totalSections,
          '--items-count': rows.length,
        } as React.CSSProperties
      }
    >
      {!areRowsHidden &&
        rows.map((item, index) => {
          return (
            <TableBodyRow
              key={item.id}
              item={item}
              index={index}
              firstColumnRenderer={firstColumnRenderer}
              columns={columns}
              hoverHandler={hoverHandler}
              mouseLeaveHandler={mouseLeaveHandler}
              cellOnClickHandler={cellOnClickHandler}
            />
          );
        })}
    </div>
  );
});

type TableItemProps = {
  item: CandidateLineupData;
  columns: HeaderCriteriaData[];
  firstColumnRenderer: (item: CandidateLineupData) => ReactNode;
  index: number;
  hoverHandler: (idx: number) => () => void;
  mouseLeaveHandler: () => void;
  cellOnClickHandler: (
    id: string,
    cardId: string | number,
    showNote: boolean
  ) => void;
};

const TableBodyRow: React.FC<TableItemProps> = ({
  item,
  firstColumnRenderer,
  columns,
  index,
  hoverHandler,
  mouseLeaveHandler,
  cellOnClickHandler,
}) => {
  const onClickHandler = useCallback(
    (cardId: number | string, showNote: boolean) => () =>
      cellOnClickHandler(item.id, cardId, showNote),
    [cellOnClickHandler, item.id]
  );
  return (
    <div className={styles.row}>
      <div className={styles.firstCell}>{firstColumnRenderer(item)}</div>
      <div className={styles.paddingCell} />
      {columns.map((column, idx) => {
        const columnsCount = columns.length;
        /*
          TODO: Investigate if better popup alignment
          can be achieved by using Popper.js for fixed cards placement
          on top of the table.

          For now, while we use absolute positionining for lineup
          tooltips, we need to manually align cards in 2 first and 2 last
          columns of lineup to not be covered by names list.
        */
        const isOneOfFirstTwoColumns = idx <= 1;
        const isOneOfLastTwoColumns = idx >= columnsCount - 2;

        return (
          <div
            key={column.id}
            onMouseEnter={hoverHandler(idx)}
            onMouseLeave={mouseLeaveHandler}
            className={classNames(styles.gridValueCell, {
              [styles.leftAlignedColumnBodyCell]: isOneOfFirstTwoColumns,
              [styles.rightAlignedColumnBodyCell]:
                isOneOfLastTwoColumns && !isOneOfFirstTwoColumns,
              [styles.firstRowCell]: index === 0,
            })}
          >
            {item && (
              <TableBodyCellRenderer
                item={item}
                column={column}
                onCellClickHandler={onClickHandler}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
TableBodyRow.displayName = 'TableBodyRow';

function mapStatusToHeaderClassname(status: string) {
  switch (status) {
    case CandidateSearchProfileStatusEnum.MANUALSHORTLIST:
      return styles.selected;
    case CandidateSearchProfileStatusEnum.ACTIVE:
    case CandidateSearchProfileStatusEnum.PENDING:
      return styles.matched;
    default:
      return '';
  }
}
