import R from '@air/third-party/ramda';
import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  filterExperienceByType,
  getExperience,
} from 'domain/CandidateData';
import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import { SearchExperience, SearchProfessionalExperience } from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import * as phrases from 'constants/phrases';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateProfessionalData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.professional;
  itExperience: boolean;
  label: string;
}
export const mapExperienceToCandidateProfessionalData = (
  professional: SearchProfessionalExperience & { idx: number }
): CandidateProfessionalData => {
  const professionalItem: CandidateCriteriaListItem = {
    id: professional.idx,
    label: professional.itExperience
      ? phrases.EXPERIENCE_IT_PROFESSIONAL_TITLE_PLACEHOLDER
      : phrases.EXPERIENCE_PROFESSIONAL_TITLE_PLACEHOLDER,
    alert: getAlertData(professional.alerts),
    experience: getExperience(professional),
    status: professional.status,
    interview: interviewDataMapper(professional.refId, professional.interview),
  };

  return {
    id: professional.idx,
    refId: professional.refId,
    idx: professional.idx,
    cardType: CardType.professional,
    list: [professionalItem],
    importance: criteriaImportanceOptions[professional.importance],
    status: professional.status,
    itExperience: professional.itExperience,
    label: professional.itExperience
      ? phrases.EXPERIENCE_IT_PROFESSIONAL_TITLE_PLACEHOLDER
      : phrases.EXPERIENCE_PROFESSIONAL_TITLE_PLACEHOLDER,
  };
};

export const filterProfessionalExperience =
  filterExperienceByType<'professional'>('professional');

export const mapProfessionalExperience = R.compose<
  SearchExperience[][],
  (SearchProfessionalExperience & { idx: number })[],
  (SearchProfessionalExperience & { idx: number })[],
  CandidateProfessionalData[]
>(
  R.map(mapExperienceToCandidateProfessionalData),
  R.filter<SearchProfessionalExperience & { idx: number }>(Boolean),
  filterProfessionalExperience
);
