import React, { useCallback, useState, useMemo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import styles from './EnrichButton.css';
import { Button, SvgIcon, TooltipWrapper } from '@air/components';
import { EnrichmentInfo, EnrichmentInfoStatusEnum } from '@air/api';

import * as phrases from 'customer-portal/src/constants/phrases';

import { EnrichmentStatus } from 'domain/CandidateData/CandidateProfileData';

const getButtonProps = (
  enrichmentInfo: EnrichmentInfo,
  enrichment: EnrichmentStatus,
  shouldEnrichAfterFailure: boolean,
  canBeEnrichedAgain: boolean,
  isDisabledForCompany: boolean,
  disabled: boolean
) => {
  switch (true) {
    case enrichment === EnrichmentStatus.STARTED ||
      enrichmentInfo?.status === EnrichmentInfoStatusEnum.INPROGRESS:
      return {
        class: styles.inProgress,
        label: phrases.ENRICHING_BUTTON,
      };
    case enrichmentInfo?.status === EnrichmentInfoStatusEnum.ENRICHED &&
      !canBeEnrichedAgain &&
      !enrichmentInfo?.outdated:
      return {
        class: styles.enriched,
        label: phrases.ENRICHED_BUTTON,
      };
    case enrichmentInfo?.status === EnrichmentInfoStatusEnum.ENRICHED &&
      !canBeEnrichedAgain &&
      enrichmentInfo?.outdated:
      return {
        class: styles.outdated,
        label: phrases.ENRICHED_BUTTON,
      };
    case isDisabledForCompany:
      return {
        class: styles.disabledForCompany,
        label: phrases.ENRICH_PROFILE_BUTTON,
      };
    case disabled:
      return {
        class: styles.disableEnrichment,
        label: phrases.CANT_BE_ENRICHED_BUTTON,
      };
    case (!enrichmentInfo?.status &&
      enrichment === EnrichmentStatus.NOT_STARTED) ||
      shouldEnrichAfterFailure:
      return {
        class: styles.default,
        label: phrases.ENRICH_PROFILE_BUTTON,
      };
    case (enrichment === EnrichmentStatus.FAILED ||
      enrichmentInfo?.status === EnrichmentInfoStatusEnum.FAILED) &&
      !shouldEnrichAfterFailure:
      return {
        class: styles.failed,
        label: phrases.NO_RESPONSE_TRY_LATER_BUTTON,
      };
    case enrichmentInfo?.status === EnrichmentInfoStatusEnum.NODATAENRICHED &&
      !canBeEnrichedAgain:
      return {
        class: styles.notEnriched,
        label: phrases.NO_DATA_ENRICHED_BUTTON,
      };
    case canBeEnrichedAgain:
      return {
        class: styles.default,
        label: phrases.ENRICH_AGAIN_BUTTON,
      };
    default:
      return;
  }
};

const getEnrichedButtonProps = (date: number) => {
  const currentDate = dayjs();
  const enrichedDate = dayjs(date);
  const daysPast = currentDate.diff(enrichedDate, 'day');
  if (daysPast === 0) {
    return {
      class: styles.new,
      subtitle: '',
    };
  } else if (daysPast >= 1 && daysPast < 30) {
    return {
      class: styles.recent,
      subtitle: phrases.RECENTRLY_ENRICHED_LABEL,
    };
  } else {
    return {
      class: styles.old,
      subtitle: `${enrichedDate.format('MM/DD/YYYY')}`,
    };
  }
};

type EnrichButtonProps = {
  enrichmentStatus?: EnrichmentStatus;
  enrichmentInfo?: EnrichmentInfo;
  isDisabledForCompany: boolean;
  disabled: boolean;
  showTooltip: boolean;
  tooltip: string;
  onClick: () => void;
  className?: string;
};

export const EnrichButton: React.FC<EnrichButtonProps> = ({
  enrichmentStatus,
  enrichmentInfo,
  isDisabledForCompany,
  disabled,
  showTooltip,
  tooltip,
  onClick,
  className,
}) => {
  const [shouldEnrichAfterFailure, setEnrichAfterFailure] = useState(false);
  const [canBeEnrichedAgain, setEnrichAgain] = useState(false);

  const onMouseEnter = useCallback(() => {
    if (
      enrichmentStatus === EnrichmentStatus.FAILED ||
      enrichmentInfo?.status === EnrichmentInfoStatusEnum.FAILED
    ) {
      setEnrichAfterFailure(true);
    }
  }, [enrichmentStatus, enrichmentInfo?.status]);

  const onMouseLeave = useCallback(() => {
    if (
      enrichmentStatus === EnrichmentStatus.FAILED ||
      enrichmentInfo?.status === EnrichmentInfoStatusEnum.FAILED
    ) {
      setEnrichAfterFailure(false);
    } else if (
      enrichmentInfo?.status === EnrichmentInfoStatusEnum.ENRICHED ||
      enrichmentInfo?.status === EnrichmentInfoStatusEnum.NODATAENRICHED
    ) {
      setEnrichAgain(false);
    }
  }, [enrichmentStatus, enrichmentInfo?.status]);

  const isProfileEnriched =
    enrichmentInfo?.status === EnrichmentInfoStatusEnum.ENRICHED &&
    enrichmentStatus === EnrichmentStatus.ENRICHED;

  const buttonProps = useMemo(
    () =>
      getButtonProps(
        enrichmentInfo,
        enrichmentStatus,
        shouldEnrichAfterFailure,
        canBeEnrichedAgain,
        isDisabledForCompany,
        disabled
      ),
    [
      enrichmentInfo,
      enrichmentStatus,
      shouldEnrichAfterFailure,
      canBeEnrichedAgain,
      isDisabledForCompany,
      disabled,
    ]
  );

  const buttonEnrichedProps = useMemo(
    () =>
      isProfileEnriched &&
      !canBeEnrichedAgain &&
      getEnrichedButtonProps(enrichmentInfo?.finishedAt),
    [enrichmentInfo?.finishedAt, isProfileEnriched, canBeEnrichedAgain]
  );

  const isButtonShifted =
    (isProfileEnriched && !canBeEnrichedAgain) || disabled;

  const onClicked = useCallback(() => {
    if (
      !(
        enrichmentInfo?.status === EnrichmentInfoStatusEnum.INPROGRESS ||
        isDisabledForCompany ||
        disabled
      )
    ) {
      onClick();
    }
  }, [onClick, enrichmentInfo?.status, isDisabledForCompany, disabled]);

  const onLabelHover = useCallback(() => {
    if (
      (enrichmentInfo?.status === EnrichmentInfoStatusEnum.ENRICHED ||
        enrichmentInfo?.status === EnrichmentInfoStatusEnum.NODATAENRICHED) &&
      !isDisabledForCompany &&
      !disabled
    ) {
      setEnrichAgain(true);
    }
  }, [enrichmentInfo?.status, isDisabledForCompany, disabled]);

  return (
    <div
      className={classNames(
        styles.enrichButtonWrapper,
        {
          [styles.shifted]: isButtonShifted,
        },
        className
      )}
    >
      <TooltipWrapper
        enabled={showTooltip}
        tooltip={tooltip}
        triggerClassName={styles.enrichmentTooltip}
      >
        <Button
          variant={Button.variants.POSITIVE_SECONDARY}
          className={classNames(
            styles.enrichmentButton,
            buttonProps.class,
            buttonEnrichedProps.class
          )}
          disabled={disabled}
          onClick={onClicked}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <SvgIcon icon="snowflake" />
          <span className={styles.buttonLabel} onMouseEnter={onLabelHover}>
            <span>{buttonProps.label}</span>
            <span>{buttonEnrichedProps.subtitle}</span>
          </span>
        </Button>
      </TooltipWrapper>
    </div>
  );
};
