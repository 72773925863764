// App timeouts
export const HOVER_DEBOUNCE_TIME = 100;
export const REQUEST_DEBOUNCE_TIME = 1000;
export const KANBAN_REDIRECT_TIMEOUT = 3000;
export const PREVIEW_POLLING_TIMEOUT = 10000;

export const DATE_FORMAT = 'MM/DD/YYYY';

export const REQUEST_PAGING_SIZE = 20;
export const REQUEST_PAGING_SIZE_KANBAN = 24;
export const MAX_REQUEST_PAGING_SIZE = 2000;
