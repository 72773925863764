import React, { useEffect, useCallback, useState, useReducer } from 'react';

import { ConfirmationModal, Paragraph } from '@air/components';
import { KanbanCard } from 'components';
import { LoadMoreButton } from 'features/Kanban/LoadMoreButton/LoadMoreButton';
import {
  useKanbanContext,
  useKanbanMethods,
  useSelectInterviewKanbanMethods,
  useSelectInterviewKanbanContext,
} from 'providers/KanbanProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import {
  ConfirmActionEnum,
  ConfirmModalState,
  getAmountOfJobsToLoad,
  KANBAN_SEARCH_GROUPS,
  ModalAction,
} from 'domain/Kanban/Kanban';
import { usePaginatedSearch } from 'domain/Kanban/usePaginatedSearch';

import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import { emit, subscribe } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';

import commonStyles from 'features/Kanban/KanbanSection/KanbanSection.css';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { useJobsProviderMethods } from 'providers/JobsProvider';

type Props = {
  isSelectMode?: boolean;
  selectModeTargetId?: string | number;
};

const generateDraftCardId = (searchId: number | string) => {
  return `draft-card-${searchId}`;
};

export const Drafts: React.FC<Props> = React.memo(
  ({ children, isSelectMode, selectModeTargetId }) => {
    const [kanbanContext, kanbanContextMethods] = isSelectMode
      ? [useSelectInterviewKanbanContext, useSelectInterviewKanbanMethods]
      : [useKanbanContext, useKanbanMethods];
    const draftsList = kanbanContext(kanbanSelectors.draftsList);

    const isExternalATS = useCustomerProfileContext(
      customerProfileSelectors.isExternalATS
    );
    const { removeJobDescription } = useJobsProviderMethods();
    const hasItems = draftsList?.items.length > 0;
    const { removeDraft, duplicateInterview } = useKanbanMethods();
    const { fetchSearchesByStatus } = kanbanContextMethods();
    const currentSearchFilterName = useKanbanContext(
      kanbanSelectors.currentSearchFilterName
    );
    const isFirstDraftUpdated = useKanbanContext(
      kanbanSelectors.isFirstDraftUpdated
    );
    const isTrialExpired = useCustomerProfileContext(
      customerProfileSelectors.isTrialExpired
    );
    const statsExpanded = useKanbanContext(kanbanSelectors.statsExpanded);
    const isMatchMinerFeatureEnabled = useCustomerProfileContext(
      customerProfileSelectors.matchMinerEnabled
    );
    const [isLoading, setIsLoading] = useState(false);

    const fetchSearchesByStatusCb = useCallback(
      async (page) => {
        setIsLoading(true);
        await fetchSearchesByStatus({
          status: KANBAN_SEARCH_GROUPS.Draft,
          page,
          ...(isSelectMode
            ? { withCriteria: true, excludeId: [selectModeTargetId as string] }
            : {}),
        });
        setIsLoading(false);
      },
      [fetchSearchesByStatus, isSelectMode, selectModeTargetId]
    );

    const loadMoreDrafts = usePaginatedSearch(fetchSearchesByStatusCb, [
      currentSearchFilterName,
      isFirstDraftUpdated,
    ]);

    const amountOfJobsLeftToLoad = getAmountOfJobsToLoad(
      draftsList.total,
      draftsList.items.length
    );

    const duplicateCard = useCallback(
      async (item: KanbanCardT) => {
        const result = await duplicateInterview(item.searchId);
        emit(APP_EVENTS.OPEN_JOB_PANEL, { item: result, isDuplicate: true });
        await loadMoreDrafts(false);
        emit(APP_EVENTS.CHANGE_SECTION_STATE, {
          sectionName: phrases.KANBAN_SECTION_DRAFTS,
          isOpen: true,
        });
      },
      [duplicateInterview, loadMoreDrafts]
    );

    useEffect(() => {
      const unsubCreateDraft = subscribe(APP_EVENTS.CREATE_DRAFT, () =>
        loadMoreDrafts(false)
      );
      const unsubCreateDraftFail = subscribe(
        APP_EVENTS.CREATE_DRAFT_FAILURE,
        loadMoreDrafts
      );
      const unsubDuplicateCard = subscribe(
        APP_EVENTS.DUPLICATE_CARD,
        duplicateCard
      );
      const unsubRemoveDraft = subscribe(APP_EVENTS.REMOVE_DRAFT, () =>
        loadMoreDrafts(false)
      );

      return () => {
        unsubCreateDraft();
        unsubDuplicateCard();
        unsubCreateDraftFail();
        unsubRemoveDraft();
      };
    }, [duplicateCard, removeJobDescription, loadMoreDrafts]);

    const discardDraft = async (item: KanbanCardT) => {
      await removeDraft(item);
      emit(APP_EVENTS.REMOVE_DRAFT);
    };

    const [confirmModal, dispatch] = useReducer(
      (state: ConfirmModalState, action: ModalAction) => {
        switch (action.type) {
          case ConfirmActionEnum.REMOVE_DRAFT:
            return {
              isOpened: true,
              modalBody: (
                <Paragraph short bold centered>
                  {phrases.DRAFT_DISCARD_CONFIRMATION}
                </Paragraph>
              ),
              onConfirm: () => {
                discardDraft(action.payload);
                dispatch({ type: ConfirmActionEnum.CLOSE_MODAL });
              },
            };
          case ConfirmActionEnum.CLOSE_MODAL:
            return {
              ...state,
              isOpened: false,
            };
          default:
            return state;
        }
      },
      { isOpened: false }
    );

    return (
      <>
        {hasItems ? (
          <div className={commonStyles.sectionJobs}>
            {draftsList.items.map((item: KanbanCardT) => {
              const actionsMenuConfig = [
                {
                  onClick: () => emit(APP_EVENTS.OPEN_JOB_PANEL, { item }),
                  icon: 'edit',
                  title: phrases.KANBAN_ACTION_EDIT,
                  disabled: isTrialExpired,
                  closeMenuOnClick: true,
                },
                ...(!isExternalATS
                  ? [
                      {
                        onClick: () => emit(APP_EVENTS.DUPLICATE_CARD, item),
                        icon: 'duplicate',
                        title: phrases.KANBAN_ACTION_DUPLICATE,
                        disabled: isTrialExpired,
                        closeMenuOnClick: true,
                      },
                    ]
                  : []),
                {
                  onClick: () => {
                    dispatch({
                      type: ConfirmActionEnum.REMOVE_DRAFT,
                      payload: item,
                    });
                  },
                  icon: 'discard-draft',
                  title: phrases.KANBAN_ACTION_DISCARD,
                  disabled: isTrialExpired,
                },
              ];

              const cardId = generateDraftCardId(item.searchId);

              return (
                <KanbanCard
                  id={cardId}
                  key={cardId}
                  item={item}
                  isSelectMode={isSelectMode}
                  actionsMenuConfig={actionsMenuConfig}
                  isMMEnabled={isMatchMinerFeatureEnabled}
                  areAllStatsExpanded={
                    statsExpanded[phrases.KANBAN_SECTION_DRAFTS]
                  }
                  hideCountersBreakdown
                  url={urls.makeDraftUrl(
                    item.ats.id,
                    item.ats.externalJobDescriptionId
                  )}
                />
              );
            })}
            {!!amountOfJobsLeftToLoad && (
              <LoadMoreButton
                isLoading={isLoading}
                onClick={loadMoreDrafts}
                text={phrases.getLoadMoreJobsButtonTitle(
                  amountOfJobsLeftToLoad
                )}
              />
            )}
            <ConfirmationModal
              onConfirm={confirmModal.onConfirm}
              onCancel={() => {
                dispatch({ type: ConfirmActionEnum.CLOSE_MODAL });
              }}
              confirmLabel={phrases.DISCARD}
              isOpen={confirmModal.isOpened}
            >
              {confirmModal.modalBody}
            </ConfirmationModal>
          </div>
        ) : (
          children
        )}
      </>
    );
  }
);
