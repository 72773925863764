import { useCallback, useEffect, useMemo, useRef } from 'react';
import { AtsExternalProfileInfo } from '@air/api';
import { UploadRejectionT } from 'utils/uploads';
import { FileItemInfo, UploadMessageType } from 'domain/ResumeUpload/types';
import { MessageQueue } from 'domain/ResumeUpload/MessageQueue';
import {
  ResumeUploader,
  MessageConsumer,
  ResumeUploaderPool,
} from 'domain/ResumeUpload/ResumeUploader';
import { RequisitionFile } from 'context';

export enum UploadEventType {
  ADD_FILES = 'add_files',
  UPLOAD_FILE_COMPLETED = 'upload_file_completed',
  UPLOAD_FILE_REJECTED = 'upload_file_rejected',
  APPLICANT_REMOVED = 'applicant_removed',
  APPLICANT_ABORTED = 'applicant_aborted',
  FAILED_UPLOADS_REMOVED = 'failed_uploads_removed',
  APPLICANTS_FETCHED = 'applicants_fetched',
}

export type MessagePayloadAddFiles = {
  requisitionId: string;
  files: FileItemInfo[];
};

export type MessagePayloadUploadCompleted = {
  requisitionId: string;
  item: FileItemInfo;
  atsExternalProfileInfo: AtsExternalProfileInfo;
  totalCount: number;
};

export type MessagePayloadUploadRejected = {
  requisitionId: string;
  item: FileItemInfo;
  rejectObject: UploadRejectionT;
};

export type MessagePayloadApplicantRemoved = {
  requisitionId: string;
  atsCandidateId: string;
};

export type MessagePayloadApplicantAborted = {
  requisitionId: string;
  tempId: string;
};

export type MessagePayloadFailedUploadsRemoved = {
  requisitionId: string;
};

export type MessagePayloadApplicantsFetched = {
  requisitionId: string;
  normalizedFiles: RequisitionFile[];
};

export type MessageQueuePayload =
  | MessagePayloadAddFiles
  | MessagePayloadUploadCompleted
  | MessagePayloadUploadRejected
  | MessagePayloadApplicantRemoved
  | MessagePayloadApplicantAborted
  | MessagePayloadFailedUploadsRemoved
  | MessagePayloadApplicantsFetched;

export type MessageQueueEventAddFiles = {
  type: UploadEventType.ADD_FILES;
  payload: MessagePayloadAddFiles;
};

export type MessageQueueEventUploadCompleted = {
  type: UploadEventType.UPLOAD_FILE_COMPLETED;
  payload: MessagePayloadUploadCompleted;
};

export type MessageQueueEventUploadRejected = {
  type: UploadEventType.UPLOAD_FILE_REJECTED;
  payload: MessagePayloadUploadRejected;
};

export type MessageQueueEventApplicantRemoved = {
  type: UploadEventType.APPLICANT_REMOVED;
  payload: MessagePayloadApplicantRemoved;
};

export type MessageQueueEventApplicantAborted = {
  type: UploadEventType.APPLICANT_ABORTED;
  payload: MessagePayloadApplicantAborted;
};

export type MessageQueueEventApplicantsFetched = {
  type: UploadEventType.APPLICANTS_FETCHED;
  payload: {
    requisitionId: string;
    normalizedFiles: RequisitionFile[];
  };
};

export type MessageQueueEventFailedUploadsRemoved = {
  type: UploadEventType.FAILED_UPLOADS_REMOVED;
  payload: MessagePayloadFailedUploadsRemoved;
};

export type MessageQueueEvent =
  | MessageQueueEventAddFiles
  | MessageQueueEventUploadCompleted
  | MessageQueueEventUploadRejected
  | MessageQueueEventApplicantRemoved
  | MessageQueueEventApplicantAborted
  | MessageQueueEventFailedUploadsRemoved
  | MessageQueueEventApplicantsFetched;

/**
 * Each time customer adds to some search/requisition a bunch of resumes, there is an appropriate `ResumeUploader` is returned
 * by `ResumeUploaderPool`.
 * There can be only one `ResumeUploader` per one `jobRequisitionId`.
 *
 * `UploadApplicantsProvider` communicates with resume uploaders via 2 message queues, shared between all instances of
 * resume uploaders. One queue for incoming messages, one - for outgoing messages.
 *
 * Initially there was an assumption, that even though there can be multiple resume uploaders instances,
 * `UploadApplicantsProvider` can communicate only with one of them. Because there can be only one search opened, so
 * far only one requisition loaded etc. And this communication was needed mainly to update the state of upload widget,
 * whenever files were uploaded etc.
 * That was controlled by MessageQueue instance - only one ResumeUploader instance could subscribe to MessageQueue events,
 * even though the queue was shared between all instances of ResumeUploader.
 *
 * That assumption conflicted with another requirement - when customer logs out, all uploads should be stopped. That
 * means we need to propagate some kind of "STOP_UPLOAD" event to all of ResumeUploader instances.
 * But as long as only one (current) resume uploader is subscribed to incoming events from message queue, only this uploader
 * was stopped.
 *
 * So we changes implementation of how resume uploaders subscribe to incoming messages. Now message queue propagates
 * single event to all instances of resume uploaders. And inside handlers we check if particular event belongs to particular
 * resume uploader, by simply matching jobRequisitionId taken from event payload.
 * Obviously, this can be improved in future.
 * One of the ways is to apply `MessageConsumer` approach, which is used for SSE notifications. Here we have some kind of
 * central dispatcher which propagates events to the appropriate consumer.
 *
 * Whatever approach will be used, keep in mind that there should be two-way communication between UploadApplicantsProvider
 * and resume uploaders.
 * There should always be a way to send event to a particular resume uploader and to all uploaders
 * (like aforementioned STOP_UPLOAD event).
 * Also consider, that after stopping uploads (UploadMessageType.STOP_QUEUE), we remove all event handlers
 * from the resume uploader, but we don't remove it from uploadersPool - this also can be a subject of change.
 *
 * @param getRequisitionId
 */
export const useFileQueue = (getRequisitionId: () => string) => {
  const queue = useMemo(() => new MessageQueue<any>(), []);
  const uploadersPool = useRef<ResumeUploaderPool>(new ResumeUploaderPool());
  const fileUploader = useRef<ResumeUploader>(null);

  const fileMessageQueue = useMemo(() => new MessageQueue<any>(), []);

  const subscribe = useRef(null);

  useEffect(() => {
    const jobRequisitionId = getRequisitionId();
    if (!jobRequisitionId) return;

    fileUploader.current = uploadersPool.current.getUploaderByRequisitionId(
      getRequisitionId(),
      queue
    );

    subscribe.current = (event: UploadMessageType, cb: () => void) => {
      const handler = new MessageConsumer(cb);
      fileUploader.current.getOutputChannel().addHandler(event, handler);

      return () => {
        fileUploader.current.getOutputChannel().removeHandler(event);
      };
    };
  }, [getRequisitionId, fileMessageQueue, queue]);

  const addFiles = useCallback(
    (eventData: { requisitionId: string; files: FileItemInfo[] }) => {
      queue.send({
        type: UploadMessageType.ADD_FILES,
        payload: eventData,
      });
    },
    [queue]
  );

  const removeFile = useCallback(
    (eventData: { requisitionId: string; fileId: string }) => {
      queue.send({
        type: UploadMessageType.REMOVE_FILE,
        payload: eventData,
      });
    },
    [queue]
  );

  const stopQueue = useCallback(
    (requisitionId?: string) => {
      queue.send({
        type: UploadMessageType.STOP_QUEUE,
        payload: { requisitionId: requisitionId },
      });
    },
    [queue]
  );

  return { addFiles, removeFile, stopQueue, subscribe: subscribe.current };
};
