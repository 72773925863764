/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum MatchStatusAvailableUpdateEnum {
  IDEAL = 'IDEAL',
  ACCEPTABLE = 'ACCEPTABLE',
  DISQUALIFICATION = 'DISQUALIFICATION',
}
