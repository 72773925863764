import {
  BaseCandidateData,
  CandidateCriteriaListItem,
} from 'domain/CandidateData';
import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import { SearchAdditionalQuestion } from '@air/api';
import { optionalOrImportantOptions } from 'constants/skills';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateQuestionData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.question;
  question: string;
}

const getQuestionItem = (
  item: SearchAdditionalQuestion
): CandidateCriteriaListItem => ({
  id: item.idx,
  label: item.question,
  alert: null,
  status: item.status,
  interview: interviewDataMapper(item.refId, item.interview),
});

export const mapQuestions = (
  questions: SearchAdditionalQuestion[]
): CandidateQuestionData[] => {
  return questions.map((item: SearchAdditionalQuestion) => ({
    id: item.idx,
    refId: item.refId,
    idx: item.idx,
    list: [getQuestionItem(item)],
    cardType: CardType.question,
    question: item.question,
    importance: optionalOrImportantOptions[item.importance],
    status: item.status,
  }));
};
