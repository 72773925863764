import React from 'react';

export const ICON_SVGs = {
  selected: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke="#C7CCD1" />
      <path
        d="M7.24794 9.81933C7.02699 9.57237 6.64768 9.55128 6.40072 9.77223C6.15376 9.99318 6.13268 10.3725 6.35362 10.6195L7.24794 9.81933ZM9.46745 13.2L9.02029 13.6C9.16218 13.7586 9.37745 13.8302 9.58605 13.7881C9.79464 13.7461 9.96536 13.5967 10.0347 13.3955L9.46745 13.2ZM13.5142 8.11163C13.7967 7.93855 13.8855 7.56917 13.7124 7.28659C13.5394 7.00401 13.17 6.91524 12.8874 7.08832L13.5142 8.11163ZM6.35362 10.6195L9.02029 13.6L9.91461 12.7999L7.24794 9.81933L6.35362 10.6195ZM9.46745 13.2C10.0347 13.3955 10.0347 13.3956 10.0346 13.3957C10.0346 13.3957 10.0346 13.3957 10.0346 13.3957C10.0346 13.3957 10.0346 13.3957 10.0347 13.3956C10.0347 13.3954 10.0349 13.3949 10.0352 13.3942C10.0357 13.3927 10.0365 13.3903 10.0377 13.3869C10.0401 13.3801 10.0439 13.3694 10.049 13.3551C10.0593 13.3265 10.075 13.2833 10.096 13.2273C10.138 13.1151 10.2011 12.9517 10.284 12.7506C10.4502 12.3477 10.6946 11.797 11.0074 11.2054C11.6479 9.99383 12.5186 8.72142 13.5142 8.11163L12.8874 7.08832C11.5973 7.87853 10.6013 9.40612 9.94654 10.6446C9.61167 11.278 9.35136 11.8647 9.17468 12.2931C9.0862 12.5076 9.01833 12.6833 8.97225 12.8063C8.9492 12.8678 8.93157 12.9162 8.91952 12.9498C8.91349 12.9666 8.90886 12.9797 8.90563 12.9889C8.90402 12.9935 8.90276 12.9971 8.90185 12.9997C8.9014 13.001 8.90103 13.002 8.90076 13.0028C8.90062 13.0032 8.9005 13.0036 8.90041 13.0038C8.90036 13.004 8.90031 13.0041 8.90029 13.0042C8.90024 13.0043 8.9002 13.0045 9.46745 13.2Z"
        fill="#8F99A3"
      />
    </svg>
  ),
  matched: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke="#C7CCD1" />
      <path
        d="M6 8.19998C6 7.8686 6.26863 7.59998 6.6 7.59998H13.4C13.7314 7.59998 14 7.8686 14 8.19998C14 8.53135 13.7314 8.79998 13.4 8.79998H6.6C6.26863 8.79998 6 8.53135 6 8.19998Z"
        fill="#8F99A3"
      />
      <path
        d="M6 12.2C6 11.8686 6.26863 11.6 6.6 11.6H13.4C13.7314 11.6 14 11.8686 14 12.2C14 12.5313 13.7314 12.8 13.4 12.8H6.6C6.26863 12.8 6 12.5313 6 12.2Z"
        fill="#8F99A3"
      />
    </svg>
  ),
  pending: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke="#C7CCD1" />
      <path
        d="M13.1992 14H6.79922L13.1992 6H6.79922L13.1992 14Z"
        stroke="#8F99A3"
        strokeWidth="0.9"
        strokeLinejoin="round"
      />
    </svg>
  ),
  rejected: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke="#C7CCD1" />
      <path d="M7 7L13 13" stroke="#8F99A3" />
      <path d="M7 13L13 7" stroke="#8F99A3" />
    </svg>
  ),
};
