import React, { CSSProperties, useMemo } from 'react';
import * as sharedPhrases from '@air/constants/phrases';
import Select, {
  components,
  Props as ReactSelectProps,
  ValueType,
} from 'react-select';
import R from '@air/third-party/ramda';
import styles from './MultiSelectCheckboxes.css';
import { SvgIcon, UIText, Paragraph, TooltipWrapper } from '@air/components';
import classNames from 'classnames';
import { OptionTypeBase, OptionsType } from 'react-select/src/types';
import { ControlProps } from 'react-select/src/components/Control';
import { OptionProps } from 'react-select/src/components/Option';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import { ValueContainerProps } from 'react-select/src/components/containers';

const getDropdownValueText = (
  selectedValues: ValueType<OptionsType<{ label: string }>>,
  options: ValueType<OptionsType<{ label: string }>>
) => {
  let value = sharedPhrases.MULTI_SELECT_CHECKBOXES_VALUE_NOT_SELECTED;
  if (selectedValues.length === options.length) {
    value = sharedPhrases.MULTI_SELECT_CHECKBOXES_VALUE_ALL;
  } else if (selectedValues.length > 0) {
    value = R.reduce<ValueType<{ label: string }>, string[]>(
      (result, it) => {
        return [...result, (it as { label: string }).label];
      },
      [],
      selectedValues
    ).join(', ');
  }
  return value;
};

const customStyles = {
  dropdownIndicator: (provided: CSSProperties, state: ReactSelectProps) => ({
    ...provided,
    padding: '1.6rem',
    ...(state.selectProps.menuIsOpen
      ? {}
      : {
          transform: 'rotate(180deg)',
        }),
  }),
  valueContainer: () => ({
    paddingLeft: 0,
    flexGrow: 1,
    minWidth: 0,
  }),
  indicatorsContainer: () => ({
    borderLeft: 0,
  }),
  control: () => ({
    border: 'none',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    background: 'var(--white-color)',
    border: '1px solid var(--steel-10-color)',
    borderTop: 'none',
    boxShadow: '0 0.4rem 1.2rem rgba(0, 0, 0, 0.05)',
    borderBottomLeftRadius: '1.6rem',
    borderBottomRightRadius: '1.6rem',
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
    padding: '0.4rem',
    margin: '0 -0.4rem',
    width: 'calc(100% + 0.8rem)',
  }),
  option: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: 'transparent',
    padding: 0,
    '&:active': {
      backgroundColor: 'transparent',
    },
  }),
};

const Option = ({ children, ...props }: OptionProps<OptionTypeBase>) => {
  return (
    <components.Option {...props}>
      <div className={styles.option}>
        <SvgIcon
          className={styles.optionCheckboxIcon}
          icon={props.isSelected ? 'checked-checkbox' : 'empty-checkbox'}
        />

        <div className={styles.optionLabel}>{children}</div>
      </div>
    </components.Option>
  );
};

const Control = ({ children, ...props }: ControlProps<OptionTypeBase>) => {
  return (
    <components.Control {...props}>
      <div
        className={classNames(styles.control, {
          [styles.isOpen]: props.selectProps.menuIsOpen,
          [styles.isFocused]: props.isFocused,
          [styles.disabled]: props.isDisabled,
        })}
      >
        <UIText tiny className={styles.controlLabel}>
          {props.selectProps.label}
        </UIText>
        <div className={styles.controlInner}>{children}</div>
      </div>
    </components.Control>
  );
};

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<OptionsType<{ label: string }>>) => {
  const { getValue, options } = props;
  const selectedValues = getValue();
  const value = useMemo(() => {
    return getDropdownValueText(selectedValues, options);
  }, [selectedValues, options]);

  return (
    <components.ValueContainer {...props}>
      <TooltipWrapper
        delayShow={TOOLTIP_DELAY_TIME_LONG}
        placement="bottom"
        containerClassName={classNames(
          styles.controlTooltip,
          props.selectProps.tooltipClassName
        )}
        tooltip={props.selectProps.tooltip}
        enabled={props.selectProps.tooltip && !props.selectProps.menuIsOpen}
        flexGrow={false}
      >
        <Paragraph short className={classNames(styles.valueContainer)}>
          {value}
        </Paragraph>
      </TooltipWrapper>
      {/* we keep the original children because without it dropdown works incorrectly */}
      <div className={styles.valueContainerHiddenChild}>{children}</div>
    </components.ValueContainer>
  );
};

export const DropdownIndicator = (props: IndicatorProps<OptionTypeBase>) => {
  return (
    <components.DropdownIndicator {...props}>
      <SvgIcon icon="up" width="2rem" height="2rem" />
    </components.DropdownIndicator>
  );
};

export const MultiSelectCheckboxes = (
  props: ReactSelectProps & {
    tooltip?: string;
  }
) => {
  const { tooltip } = props;

  const select = useMemo(() => {
    return (
      <Select
        isMulti
        openMenuOnClick
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isSearchable={false}
        isClearable={false}
        components={{
          Option,
          Control,
          ValueContainer,
          DropdownIndicator,
        }}
        styles={customStyles}
        {...props}
      />
    );
  }, [props]);

  return props.isDisabled ? (
    <TooltipWrapper
      delayShow={TOOLTIP_DELAY_TIME_LONG}
      placement="bottom"
      containerClassName={classNames(
        styles.dropdownTooltip,
        props.tooltipClassName
      )}
      tooltip={tooltip}
      enabled={tooltip && !props.menuIsOpen}
      flexGrow={false}
    >
      {select}
    </TooltipWrapper>
  ) : (
    select
  );
};
