import * as ApiModel from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  calculateAcceptAny,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria/BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { criteriaImportanceOptions } from 'constants/skills';
import {
  InstitutionExtendedListResponseTypeEnum,
  DictionaryIdentifiableItem,
  InstitutionDetailTypeEnum,
} from '@air/api';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

export type InstitutionCriteriaListValue = SearchCriteriaListValue<{
  value: number;
  type: InstitutionDetailTypeEnum;
  extras: {
    type: InstitutionDetailTypeEnum;
    items: DictionaryIdentifiableItem[];
  };
}>;

export interface InstitutionCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.institution;
  idealList: InstitutionCriteriaListValue[];
  acceptableList: InstitutionCriteriaListValue[];
}

export const isInstitutionListType = (
  type: InstitutionExtendedListResponseTypeEnum
) => type === InstitutionExtendedListResponseTypeEnum.InstitutionList;

export const isInstitution = (
  item: BaseSearchCriteriaData
): item is InstitutionCriteriaData => item.cardType === CardType.institution;

export const getInstitutionCriteriaDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.IsNewEdit,
}: AddCriteriaInitials): InstitutionCriteriaData => ({
  idx,
  id: CardType.institution,
  key: genId(),
  initialCardStatus,
  cardType: CardType.institution,
  idealList: null,
  acceptableList: null,
  importance: criteriaImportanceOptions[importance],
});

const convertInstitutionListToSelectValues = (
  list: ApiModel.InstitutionDetail[] | null
): InstitutionCriteriaListValue[] =>
  list
    ? list.map((item: any) => ({
        value: item.id,
        label: item.fullName,
        type: item.type,
        extras: {
          type: item.type as InstitutionDetailTypeEnum,
          items: item.items,
        },
      }))
    : null;

const convertSelectValuesToInstitutionList = (
  list: InstitutionCriteriaListValue[]
): ApiModel.InstitutionDetail[] =>
  list
    ? list.map((item: any) => ({
        id: item.value,
        fullName: item.label,
        items: item.extras.items,
        type: item.extras.type,
      }))
    : null;

export const mapEducationToInstitutionCriteria = (
  institution: ApiModel.InstitutionData
): InstitutionCriteriaData => ({
  idx: institution.idx,
  id: CardType.institution,
  key: genId(),
  cardType: CardType.institution,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  idealList: convertInstitutionListToSelectValues(institution.idealList),
  acceptableList: convertInstitutionListToSelectValues(
    institution.acceptableList
  ),
  importance: criteriaImportanceOptions[institution.importance],
});

export const mapInstitutionCriteriaToEducation = (
  institution: InstitutionCriteriaData
): ApiModel.InstitutionData => ({
  idx: institution.idx,
  setInCriteria: true,
  acceptAny: calculateAcceptAny(institution),
  idealList: convertSelectValuesToInstitutionList(institution.idealList),
  acceptableList: convertSelectValuesToInstitutionList(
    institution.acceptableList
  ),
  importance: institution.importance.value,
});
