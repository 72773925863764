import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { SearchCriteriaImportanceEnum } from '@air/api';
import {
  ProfessionalCriteriaData,
  getImportanceValue,
  CardType,
} from 'domain/SearchCriteria';
import {
  jobTypeOptionsList,
  jobTypeOptions,
  JobType,
  JobTypeSelectedValueT,
  getProfessionalTitle,
} from 'domain/SearchCriteria/ProfessionalCriteriaData';
import {
  AcceptableExperienceWidget,
  Card,
  IdealExperienceWidget,
} from '@air/components';

import {
  ExperienceSelect,
  ExperienceReadOnlyWidget,
} from 'components/Form/renderers/ExperienceSelect/ExperienceSelect';

import { useStateMachine } from '@air/hooks';
import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import { SearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';
import {
  makeDraggableComponent,
  withCustomDrag,
  DraggableCriteriaCardProps,
} from 'components/CustomDragLayer/CustomDragLayer';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import {
  isIdealExperienceDefined,
  isAcceptableExperienceDefined,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import {
  getCardCriterionLabel,
  getCardTitle,
  getMainCard,
  getSearchCriteriaCardFooter,
} from 'domain/SearchCriteria/cardHelpers';
import { IdealExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/IdealExperienceWidget';
import { AcceptableExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/AcceptableExperienceWidget';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import {
  CriteriaCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';

import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import classNames from 'classnames';
import { convertCriteriaExperienceAndRecencyToString } from 'components/Cards/cardsCommonCode';
import * as phrases from 'constants/phrases';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

export const getProfessionalFieldsForCollapsedCard = (
  cardData: ProfessionalCriteriaData
): JSX.Element[] => {
  const experienceInfoLine =
    convertCriteriaExperienceAndRecencyToString(cardData);

  return [
    <Card.Title
      resizeable
      key="title-label"
      flexGrow={false}
      title={getCardTitle({
        cardType: cardData.cardType,
        label: cardData.label,
      })}
    />,
    experienceInfoLine && (
      <Card.Footer
        key="card-footer"
        className={cardCommonStyles.experienceYears}
        text={experienceInfoLine}
      />
    ),
  ];
};

export const getWorkExperienceReadOnlyFields = (
  card: ProfessionalCriteriaData
): Array<JSX.Element | null> => {
  const experienceTitle = card.itExperience
    ? phrases.EXPERIENCE_IT_JOBS_ONLY_PLACEHOLDER
    : phrases.EXPERIENCE_ANY_JOB_TYPE_PLACEHOLDER;

  return [
    <Card.SearchCriteriaEditFormMainTitle
      className={cardCommonStyles.cardSystemValue}
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
    <IdealExperienceReadOnlyWidget
      values={card.experience}
      key="ideal-experience-field"
    />,
    isIdealExperienceDefined(card.experience) &&
    isAcceptableExperienceDefined(card.experience) ? (
      <AcceptableExperienceReadOnlyWidget
        values={card.experience}
        key="acceptable-experience-field"
      />
    ) : null,
    <ExperienceReadOnlyWidget key="experience-field">
      {experienceTitle}
    </ExperienceReadOnlyWidget>,
  ];
};

const DraggableProfessionalCriteriaCard = makeDraggableComponent<
  DraggableCriteriaCardProps<ProfessionalCriteriaData>,
  { id: number | string }
>(
  withCustomDrag(CriteriaCollapsedCard),
  DraggedSearchCriteriaCardType.professional,
  {
    beginDrag: ({
      id,
      criteria,
      title,
      importanceSectionOrder,
      onDragStart,
      cardClasses,
    }: any) => {
      onDragStart && onDragStart();
      return {
        title,
        id,
        beginDragClass: cardClasses?.beginDragClass,
        order: criteria.idx,
        importance: getImportanceValue(criteria),
        importanceSectionOrder,
        type: DraggedSearchCriteriaCardType.professional,
      };
    },
    endDrag: ({ onDragEnd }: any) => {
      onDragEnd && onDragEnd();
    },
  }
);
DraggableProfessionalCriteriaCard.displayName =
  'DraggableProfessionalCriteriaCard';

const ProfessionalCriteriaCardEdit: FC<{
  namePrefix: string;
  values: ProfessionalCriteriaData;
  hasJobTypes: boolean;
  changeCardImportance: (
    criteria: ProfessionalCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
}> = ({
  values,
  namePrefix,
  hasJobTypes,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const professionalCardConfig = cardsConfig[CardType.professional];
  const title = getProfessionalTitle(values.itExperience);
  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      changeCardImportance={changeCardImportance}
    >
      {({
        mainCriteriaNameFieldRef,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <div
            ref={mainCriteriaNameFieldRef}
            className={cardCommonStyles.titleFieldWithImportance}
          >
            <div
              className={classNames(
                cardCommonStyles.multilineContent,
                cardCommonStyles.cardSystemValue
              )}
            >
              <Card.Title
                title={title}
                tooltipProps={professionalCardConfig?.getMainCardTooltipProps?.(
                  values,
                  values
                )}
              />
            </div>
            {importanceIndicator}
          </div>
          <Field name={`${namePrefix}.experience`}>
            {({ field, form }: FieldProps) => (
              <>
                <IdealExperienceWidget
                  isReadOnly={isReadOnly}
                  values={field.value}
                  onChangeValues={(value) =>
                    form.setFieldValue(field.name, value)
                  }
                />
                {isIdealExperienceDefined(field.value) && (
                  <AcceptableExperienceWidget
                    isReadOnly={isReadOnly}
                    values={field.value}
                    onChangeValues={(value) =>
                      form.setFieldValue(field.name, value)
                    }
                  />
                )}
              </>
            )}
          </Field>
          {hasJobTypes && (
            <Field name={`${namePrefix}.itExperience`}>
              {({ field, form }: FieldProps) => {
                const value = values.itExperience
                  ? jobTypeOptions[JobType.ITJOBSONLY]
                  : jobTypeOptions[JobType.ANYJOBTYPE];

                const updatedField = {
                  ...field,
                  value,
                };
                return (
                  <ExperienceSelect
                    isDisabled={isReadOnly}
                    {...updatedField}
                    className={cardCommonStyles.detailsRequiredField}
                    options={jobTypeOptionsList}
                    onChange={(option: JobTypeSelectedValueT) => {
                      const updatedValue = option.value === JobType.ITJOBSONLY;
                      form.setFieldValue(field.name, updatedValue);
                    }}
                  />
                );
              }}
            </Field>
          )}
        </>
      )}
    </CriteriaEditCard>
  );
};

ProfessionalCriteriaCardEdit.displayName = 'ProfessionalCriteriaCardEdit';

type ProfessionalCardComponentProps = {
  cardData: ProfessionalCriteriaData;
  namePrefix: string;
  onUpdate: () => void;
  onRemove: () => void;
  importanceSectionOrder: number;
  changeCardImportance: (
    criteria: ProfessionalCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
  hasJobTypes?: boolean;
};

export const ProfessionalCriteriaCard: FC<ProfessionalCardComponentProps> = ({
  namePrefix,
  cardData,
  importanceSectionOrder,
  changeCardImportance,
  onUpdate,
  onRemove,
  isReadOnly = false,
  hasJobTypes,
}) => {
  const cardTitle = getProfessionalTitle(cardData.itExperience);
  const [cardFooter] = getSearchCriteriaCardFooter(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: false,
      updated: true,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={false}>
        {isCardInViewMode(cardState) ? (
          <DraggableProfessionalCriteriaCard
            title={cardTitle}
            footerText={cardFooter}
            importance={cardImportance}
            cardLabel={cardLabel}
            cardClasses={{
              mainCardClass: cardCommonStyles.cardSystemValue,
              beginDragClass: cardCommonStyles.cardSystemValue,
              footerClass: cardCommonStyles.experienceYears,
            }}
            criteria={cardData}
            id={cardData.key}
            onRemove={onRemove}
            importanceSectionOrder={importanceSectionOrder}
            initialCardStatus={cardData.initialCardStatus}
          />
        ) : (
          <ProfessionalCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
            hasJobTypes={hasJobTypes}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
ProfessionalCriteriaCard.displayName = 'ProfessionalCriteriaCard';
