import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import R from '@air/third-party/ramda';
import { toast } from '@air/third-party/toast';
import { RouteComponentProps } from 'react-router';

import {
  DEFAULT_ITEMS_LIST_STATE,
  DEFAULT_JOB_EMAIL_ITEM_STATE,
  DEFAULT_JOB_EMAIL_STATE,
  DEFAULT_PREVIEW_STATE,
  DEFAULT_RECIPIENTS_STATE,
  EmailConfigurationContext,
  EmailConfigurationContextT,
  EmailSectionsButtonsListT,
} from 'context';
import * as EmailConfigurationApi from 'domain/EmailConfig/emailConfigurationApi';
import { useEqualContextSelector } from '@air/utils/hooks';
import { useContextSelector } from 'use-context-selector';
import {
  CandidateSearchProfileStatus,
  EmailTemplateRequest,
  EmailTemplateResponse,
  EmailTemplateShortResponse,
  JobEmailCandidatesListResponseWithStatuses,
  JobEmailResponse,
  JobEmailShortResponse,
  SectionSchemaResponse,
  SendJobEmailsRequest,
} from '@air/api';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import {
  EmailConfigSectionT,
  EmailTemplateT,
  prepareRecipientsForUI,
  prepareEmailSectionsForUI,
  prepareEmailSectionsListButtonsForUI,
  prepareTemplateForUI,
  removeEmptySections,
  SelectedEmailEntityT,
  DEFAULT_TEMPLATE_CREATOR,
} from 'domain/EmailConfig/EmailTemplates';
import * as urls from 'constants/urls';
import { EmailsTabs, LineupTabs } from '@air/constants/tabs';
import { updateItemById } from 'domain/EmailConfig/utils';

export const EmailConfigurationProvider: React.FC<
  RouteComponentProps<EmailConfigSectionT>
> = ({ history, match, children }) => {
  const [emailSectionsList, setEmailSectionsList] =
    useState<EmailSectionsButtonsListT>(DEFAULT_ITEMS_LIST_STATE);

  const [jobEmails, setJobEmails] = useState<
    EmailConfigurationContextT['jobEmails']
  >(DEFAULT_ITEMS_LIST_STATE);
  const [currentEmail, setCurrentEmail] = useState<
    EmailConfigurationContextT['currentEmail']
  >(DEFAULT_JOB_EMAIL_STATE);
  const [recipients, setRecipients] = useState<
    EmailConfigurationContextT['recipients']
  >(DEFAULT_RECIPIENTS_STATE);
  useState<EmailSectionsButtonsListT>(DEFAULT_ITEMS_LIST_STATE);
  const [emailTemplates, setEmailTemplates] = useState<
    EmailConfigurationContextT['emailTemplates']
  >(DEFAULT_ITEMS_LIST_STATE);

  const DEFAULT_TEMPLATE = useRef<EmailTemplateShortResponse>(null);
  useEffect(() => {
    EmailConfigurationApi.fetchDefaultEmailTemplate().fork(
      R.noop,
      (res: EmailTemplateShortResponse[]) => {
        if (!res?.length) return;

        const [template] = res;
        // See AR-12186, "default" template doesn't have a creator on BE.
        // This is the easiest way to achieve necessary result...
        template.creator = DEFAULT_TEMPLATE_CREATOR;
        template.created = null;
        DEFAULT_TEMPLATE.current = template;

        setEmailTemplates((state) => ({
          ...state,
          items: (state.items ?? []).concat(template ? template : []),
        }));
      }
    );
  }, []);

  const [preview, setPreview] = useState<EmailConfigurationContextT['preview']>(
    DEFAULT_PREVIEW_STATE
  );

  const jobDescriptionId = match.params?.jobDescriptionId;
  const dataSourceId = match.params?.dataSourceId;

  const fetchEmailTemplateSectionsList = () => {
    setEmailSectionsList((state) => ({ ...state, isLoading: true }));
    return EmailConfigurationApi.fetchEmailTemplateSectionsList().fork(
      () => {
        toast.error(phrases.EMAIL_SECTIONS_LOAD_ERROR);
        setEmailSectionsList((state) => ({ ...state, isLoading: false }));
      },
      (res: SectionSchemaResponse[]) => {
        setEmailSectionsList({
          items: prepareEmailSectionsListButtonsForUI(res),
          isLoading: false,
        });
      }
    );
  };

  const saveJobEmail = useCallback(
    (params: { searchId: number; email: SelectedEmailEntityT }) => {
      const { searchId, email } = params;

      const normalizedSections = removeEmptySections(email.sections);

      const emailRequest = {
        subject: { content: email.subject },
        sections: normalizedSections,
      };

      return EmailConfigurationApi.saveJobEmail(
        searchId,
        emailRequest,
        email.id
      ).fork(
        (err) => {
          toast.error(
            err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );
          setCurrentEmail((state) => ({ ...state, isLoading: false }));
        },
        (res: JobEmailResponse) => {
          setCurrentEmail({
            item: {
              ...res,
              subject: res.subject.content,
              sections: prepareEmailSectionsForUI(res.sections),
            },
            isLoading: false,
          });
          return res;
        }
      );
    },
    []
  );

  const fetchJobEmails = useCallback((searchId: number) => {
    setJobEmails((state) => ({
      ...state,
      isLoading: true,
    }));

    return EmailConfigurationApi.fetchJobEmails(searchId).fork(
      (err) => {
        toast.error(
          err?.details?.description ??
            phrases.EMAIL_SECTION_JOB_EMAILS_NO_EMAILS
        );
        setJobEmails({ items: [], isLoading: false });
      },
      (res: JobEmailShortResponse[]) => {
        setJobEmails({
          items: res,
          isLoading: false,
        });

        return res;
      }
    );
  }, []);

  const fetchJobEmailById = useCallback(
    (searchId: number, emailId: string) => {
      setCurrentEmail(() => ({
        item: DEFAULT_JOB_EMAIL_ITEM_STATE,
        isLoading: true,
      }));
      EmailConfigurationApi.fetchJobEmailById(searchId, emailId).fork(
        () => {
          setCurrentEmail((state) => ({ ...state, isLoading: false }));
          toast.error(phrases.EMAIL_SECTION_JOB_EMAILS_NO_EMAIL_BY_ID);
          history.replace(
            urls.makeEmailConfigUrl({
              dataSourceId,
              jobDescriptionId,
              tab: LineupTabs.Passive,
              params: { emailConfigTab: EmailsTabs.JobEmails },
            })
          );
        },
        (res: JobEmailResponse) => {
          setCurrentEmail({
            item: {
              ...res,
              subject: res.subject.content,
              sections: prepareEmailSectionsForUI(res.sections),
            },
            isLoading: false,
          });
        }
      );
    },
    [dataSourceId, history, jobDescriptionId]
  );

  const createEmailTemplate = useCallback((params: EmailTemplateRequest) => {
    return EmailConfigurationApi.createEmailTemplate(params).fork(
      (err) => {
        toast.error(
          err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
      },
      () => {
        toast.dark(phrases.EMAIL_SECTION_NEW_TEMPLATE_SAVE_SUCCESS);
      }
    );
  }, []);

  const fetchEmailTemplates = useCallback(() => {
    setEmailTemplates((state) => ({
      ...state,
      isLoading: true,
    }));

    return EmailConfigurationApi.fetchEmailTemplates().fork(
      (err) => {
        const { current } = DEFAULT_TEMPLATE;
        toast.error(
          err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
        setEmailTemplates({
          items: current ? [current] : [],
          isLoading: false,
        });
      },
      (res: EmailTemplateShortResponse[]) => {
        const { current } = DEFAULT_TEMPLATE;
        setEmailTemplates({
          items: [...res].concat(current ? [current] : []),
          isLoading: false,
        });
      }
    );
  }, []);

  const getEmailPreviewForCandidate = useCallback(
    async (searchId: number, emailId: string, candidateId: string) => {
      setPreview((state) => ({ ...state, isLoading: true }));
      return EmailConfigurationApi.getEmailPreviewForCandidate(
        searchId,
        emailId,
        candidateId
      ).fork(
        (err) => {
          setPreview((state) => ({ ...state, isLoading: false }));
          toast.error(
            err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );
          return;
        },
        (result) => {
          setPreview(() => ({ email: result, isLoading: false }));
          return result;
        }
      );
    },
    []
  );

  const deleteEmailTemplateById = useCallback(
    ({ id, isSelected }: { id: string; isSelected: boolean }) => {
      setEmailTemplates((state) => ({
        ...state,
        isLoading: true,
      }));
      return EmailConfigurationApi.deleteEmailTemplateById(id).fork(
        (err) => {
          toast.error(
            err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );
          setEmailTemplates((state) => ({
            ...state,
            isLoading: false,
          }));
        },
        () => {
          setEmailTemplates((state) => ({
            ...state,
            items: state.items.filter((item) => item.id !== id),
            isLoading: false,
          }));

          if (isSelected) {
            setCurrentEmail(DEFAULT_JOB_EMAIL_STATE);
          }
        }
      );
    },
    []
  );

  const deleteJobEmailById = useCallback(
    ({
      searchId,
      id,
      isSelected,
    }: {
      searchId: number;
      id: string;
      isSelected: boolean;
    }) => {
      setJobEmails((state) => ({
        ...state,
        isLoading: true,
      }));

      return EmailConfigurationApi.deleteJobEmailById(searchId, id).fork(
        (err) => {
          toast.error(
            err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );

          setJobEmails((state) => ({
            ...state,
            isLoading: false,
          }));
        },
        () => {
          setJobEmails((state) => ({
            ...state,
            items: state.items.filter((item) => item.id !== id),
            isLoading: false,
          }));

          if (isSelected) {
            setCurrentEmail(DEFAULT_JOB_EMAIL_STATE);
          }
        }
      );
    },
    []
  );

  /*
     Filter out job emails in response to SSE event when other system user deletes an email.
  */
  const deleteJobEmailByIdLocally = useCallback((jobEmailId: string) => {
    setJobEmails((state) => ({
      ...state,
      items: state.items.filter((item) => item.id !== jobEmailId),
    }));
  }, []);

  /*
     Filter out templates in response to SSE event when other system user deletes a template.
  */
  const deleteEmailTemplateByIdLocally = useCallback(
    (emailTemplateId: string) => {
      setEmailTemplates((state) => ({
        ...state,
        items: state.items.filter((item) => item.id !== emailTemplateId),
      }));
    },
    []
  );

  const fetchEmailTemplateById = useCallback((id: string) => {
    setCurrentEmail((state) => ({ ...state, isLoading: true }));

    EmailConfigurationApi.fetchEmailTemplateById(id).fork(
      (err) => {
        toast.error(
          err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
        setCurrentEmail((state) => ({ ...state, isLoading: false }));
      },
      (res: EmailTemplateResponse) => {
        setCurrentEmail({ item: prepareTemplateForUI(res), isLoading: false });
      }
    );
  }, []);

  const duplicateEmailTemplate = useCallback((id: string) => {
    EmailConfigurationApi.duplicateEmailTemplate(id).fork(
      (err) => {
        toast.error(
          err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
      },
      (res) => {
        const { companyId, sections, subject, ...rest } = res;
        setEmailTemplates((state) => ({
          ...state,
          items: [{ ...rest }, ...state.items],
        }));

        toast.dark(phrases.EMAIL_SECTION_DUPLICATE_TEMPLATE_TOAST);
      }
    );
  }, []);

  const duplicateJobEmail = useCallback((id: string, searchId: number) => {
    EmailConfigurationApi.duplicateJobEmail(id, searchId).fork(
      (err) => {
        toast.error(
          err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
      },
      (res) => {
        const { sections, subject, ...rest } = res;
        setJobEmails((state) => ({
          ...state,
          items: [{ ...rest, name: subject.content }, ...state.items],
        }));

        toast.dark(phrases.EMAIL_SECTION_DUPLICATE_EMAIL_TOAST);
      }
    );
  }, []);

  const updateEmailTemplate = useCallback((email: SelectedEmailEntityT) => {
    const normalizedSections = removeEmptySections(email.sections);

    const emailRequest = {
      name: email.name,
      subject: { content: email.subject },
      sections: normalizedSections,
    };

    return EmailConfigurationApi.updateEmailTemplate(
      email.id,
      emailRequest
    ).fork(
      (err) => {
        toast.error(
          err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
      },
      (res: EmailTemplateResponse) => {
        const { companyId, sections, subject, ...emailTemplateBasePayload } =
          res;

        setEmailTemplates((emailTemplates) => ({
          ...emailTemplates,
          items: updateItemById(
            emailTemplates.items,
            res.id,
            emailTemplateBasePayload
          ),
        }));

        setCurrentEmail((state) => ({
          ...state,
          item: {
            ...emailTemplateBasePayload,
            status: null,
            sections,
            subject: subject.content,
          } as EmailTemplateT,
        }));

        return res;
      }
    );
  }, []);

  const discardEmailTemplateChanges = useCallback(
    (id: string) => {
      EmailConfigurationApi.discardEmailTemplateChanges(id).fork(
        (err) => {
          toast.error(
            err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );
        },
        (res: EmailTemplateResponse) => {
          const { companyId, sections, subject, ...emailTemplateBasePayload } =
            res;

          setEmailTemplates({
            ...emailTemplates,
            items: updateItemById(
              emailTemplates.items,
              res.id,
              emailTemplateBasePayload
            ),
          });
          setCurrentEmail((state) => ({
            ...state,
            item: {
              ...emailTemplateBasePayload,
              status: null,
              sections,
              subject: subject.content,
            } as EmailTemplateT,
          }));

          toast.dark(phrases.EMAIL_SECTION_TEMPLATE_CHANGES_DISCARDED_TOAST);
        }
      );
    },
    [emailTemplates]
  );

  const submitUpdateEmailTemplate = useCallback(
    (emailId: string, email: EmailTemplateRequest) => {
      EmailConfigurationApi.submitUpdateEmailTemplate(emailId, email).fork(
        (err) => {
          toast.error(
            err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );
        },
        (res) => {
          const { companyId, sections, subject, ...emailTemplateBasePayload } =
            res;

          setEmailTemplates((emailTemplates) => {
            const itemsWithoutItemToUpdate = emailTemplates.items.filter(
              (item) => item.id !== res.id
            );
            return {
              ...emailTemplates,
              items: [emailTemplateBasePayload, ...itemsWithoutItemToUpdate],
            };
          });
          setCurrentEmail((state) => ({
            ...state,
            item: {
              ...emailTemplateBasePayload,
              status: null,
              sections,
              subject: subject.content,
            } as EmailTemplateT,
          }));

          toast.dark(phrases.EMAIL_SECTION_TEMPLATE_CHANGES_SAVED_TOAST);
        }
      );
    },
    []
  );

  const fetchEmailRecipients = useCallback(
    (emailId: string, searchId: number, params?: { size: number }) => {
      return EmailConfigurationApi.fetchEmailRecipients(
        emailId,
        searchId,
        params
      ).fork(
        (rej) => {
          toast.error(
            rej.details?.description || sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );
        },
        (result: JobEmailCandidatesListResponseWithStatuses) => {
          const recipients = prepareRecipientsForUI(
            result?.profilesList?.items
          );

          setRecipients({
            new: recipients.new || [],
            sent: recipients.sent || [],
          });
          const selectedNewIdsByDefault = (recipients.new || []).reduce(
            (selected: string[], it) =>
              [
                CandidateSearchProfileStatus.MANUALSHORTLIST,
                CandidateSearchProfileStatus.ACTIVE,
                CandidateSearchProfileStatus.PENDING,
              ].includes(it.status) && it.primaryEmail
                ? [...selected, it.id]
                : selected,
            []
          );
          return { selectedNewIdsByDefault: selectedNewIdsByDefault || [] };
        }
      );
    },
    []
  );

  const sendJobEmails = useCallback(
    async (searchId: number, emailId: string, params: SendJobEmailsRequest) => {
      return EmailConfigurationApi.sendJobEmails(
        searchId,
        emailId,
        params
      ).fork(
        (err) => {
          toast.error(
            err?.details?.description ?? sharedPhrases.GENERAL_ERROR_TRY_AGAIN
          );
          throw new Error(err);
        },
        () => {
          toast.dark(
            phrases.getEmailsSentSuccessMessage(params.profileIds.length)
          );
          setRecipients((state) => {
            const result = R.groupBy(
              (item) => (params.profileIds.includes(item.id) ? 'sent' : 'new'),
              state.new || []
            );
            return {
              new: result.new,
              sent: [...result.sent, ...state.sent],
            };
          });
        }
      );
    },
    []
  );

  const emailConfigurationContextValue =
    useMemo<EmailConfigurationContextT>(() => {
      return {
        emailSectionsList,
        jobEmails,
        currentEmail,
        emailTemplates,
        preview,
        recipients,
        methods: {
          fetchEmailTemplateSectionsList,
          fetchJobEmails,
          saveJobEmail,
          fetchJobEmailById,
          createEmailTemplate,
          fetchEmailTemplates,
          fetchEmailTemplateById,
          deleteEmailTemplateById,
          deleteJobEmailById,
          fetchEmailRecipients,
          deleteJobEmailByIdLocally,
          deleteEmailTemplateByIdLocally,
          updateEmailTemplate,
          discardEmailTemplateChanges,
          submitUpdateEmailTemplate,
          duplicateEmailTemplate,
          duplicateJobEmail,
          getEmailPreviewForCandidate,
          sendJobEmails,
        },
      };
    }, [
      emailSectionsList,
      jobEmails,
      currentEmail,
      emailTemplates,
      preview,
      recipients,
      fetchJobEmails,
      saveJobEmail,
      fetchJobEmailById,
      createEmailTemplate,
      fetchEmailTemplates,
      fetchEmailTemplateById,
      deleteEmailTemplateById,
      deleteJobEmailById,
      fetchEmailRecipients,
      deleteJobEmailByIdLocally,
      deleteEmailTemplateByIdLocally,
      updateEmailTemplate,
      discardEmailTemplateChanges,
      submitUpdateEmailTemplate,
      duplicateEmailTemplate,
      duplicateJobEmail,
      getEmailPreviewForCandidate,
      sendJobEmails,
    ]);

  return (
    <EmailConfigurationContext.Provider value={emailConfigurationContextValue}>
      {children}
    </EmailConfigurationContext.Provider>
  );
};

export const useEmailConfigurationContext = <Selected,>(
  selector: (state: EmailConfigurationContextT) => Selected
) => {
  return useContextSelector(EmailConfigurationContext, selector);
};

export const useEmailConfigurationMethods = () => {
  return useEqualContextSelector(
    EmailConfigurationContext,
    (state: EmailConfigurationContextT) => state.methods,
    R.shallowEqualObjects
  );
};

EmailConfigurationProvider.displayName = 'EmailConfigurationProvider';
