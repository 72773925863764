import { CandidateSearchProfileStatus, JobEmailCandidate } from '@air/api';
import * as phrases from 'constants/phrases';

export type StatusIconConfig = {
  icon: string;
  buttonLabel: string;
  currentStatus: string;
};

// This is taken from import { CandidateSearchProfileStatusEnum } from '@air/api/models/CandidateSearchProfile';
// UPLOADING status is added
// This status exists only on front-end.
// TODO: create and use the enum which extends the swagger model

export enum CandidateSearchProfileStatusEnum {
  UPLOAD = 'UPLOAD',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  RECOMMENDEDFORREJECTION = 'RECOMMENDED_FOR_REJECTION',
  ATSFAILEDTOPARSE = 'ATS_FAILED_TO_PARSE',
  MANUALREJECTION = 'MANUAL_REJECTION',
  MANUALSHORTLIST = 'MANUAL_SHORTLIST',
  NOTPROCESSED = 'NOT_PROCESSED',
  MANUALUNREJECTION = 'MANUAL_UNREJECTION',
  MANUALUNSHORTLIST = 'MANUAL_UNSHORTLIST',
  MATCHMINERREJECTED = 'MATCH_MINER_REJECTED',
}

export const CandidateStatus: Partial<{
  [key in CandidateSearchProfileStatusEnum]: string;
}> = {
  [CandidateSearchProfileStatusEnum.UPLOAD]:
    phrases.CANDIDATE_STATUS_UPLOAD_LABEL,
  [CandidateSearchProfileStatusEnum.ACTIVE]:
    phrases.CANDIDATE_STATUS_ACTIVE_LABEL,
  [CandidateSearchProfileStatusEnum.PENDING]:
    phrases.CANDIDATE_STATUS_PENDING_LABEL,
  [CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION]:
    phrases.CANDIDATE_STATUS_RECOMMENDEDFORREJECTION_LABEL,
  [CandidateSearchProfileStatusEnum.MANUALSHORTLIST]:
    phrases.CANDIDATE_STATUS_MANUALSHORTLIST_LABEL,
  [CandidateSearchProfileStatusEnum.MANUALREJECTION]:
    phrases.CANDIDATE_STATUS_MANUALREJECTION_LABEL,
  [CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE]:
    phrases.CANDIDATE_STATUS_ATSFAILEDTOPARSE_LABEL,
  [CandidateSearchProfileStatusEnum.NOTPROCESSED]:
    phrases.CANDIDATE_STATUS_NOTPROCESSED_LABEL,
};

export const getCandidateProfileLabel = (
  status: CandidateSearchProfileStatusEnum
): string => {
  switch (status) {
    case CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE:
      return 'Other';
    default:
      return `${CandidateStatus[status]} Applicant`;
  }
};

export const allOpenedSections = {
  [CandidateSearchProfileStatusEnum.ACTIVE]: true,
  [CandidateSearchProfileStatusEnum.PENDING]: true,
  [CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION]: true,
  [CandidateSearchProfileStatusEnum.MANUALSHORTLIST]: true,
  [CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE]: true,
  [CandidateSearchProfileStatusEnum.NOTPROCESSED]: true,
};

export const statusIconMap: {
  [key in CandidateSearchProfileStatusEnum]?: StatusIconConfig;
} = {
  [CandidateSearchProfileStatusEnum.MANUALSHORTLIST]: {
    icon: 'selected',
    buttonLabel: 'Select',
    currentStatus:
      CandidateStatus[CandidateSearchProfileStatusEnum.MANUALSHORTLIST],
  },
  [CandidateSearchProfileStatusEnum.ACTIVE]: {
    icon: 'matched',
    buttonLabel: 'Reset to matched',
    currentStatus: CandidateStatus[CandidateSearchProfileStatusEnum.ACTIVE],
  },
  [CandidateSearchProfileStatusEnum.PENDING]: {
    icon: 'pending',
    buttonLabel: 'Reset to pending',
    currentStatus: CandidateStatus[CandidateSearchProfileStatusEnum.PENDING],
  },
  [CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION]: {
    icon: 'rejected',
    buttonLabel: 'Reject',
    currentStatus:
      CandidateStatus[CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION],
  },
  [CandidateSearchProfileStatusEnum.MANUALREJECTION]: {
    icon: 'manuallyrejected',
    buttonLabel: 'Reject',
    currentStatus:
      CandidateStatus[CandidateSearchProfileStatusEnum.MANUALREJECTION],
  },
  [CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE]: {
    icon: 'other',
    buttonLabel: 'Reset to other',
    currentStatus:
      CandidateStatus[CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE],
  },
};

export function getToggleStatesForStatus(
  currentStatus: CandidateSearchProfileStatusEnum,
  systemStatus: CandidateSearchProfileStatus
) {
  /*
    Each status group has it's default status with specific icon.
    When candidate status is manually changed to SHORTLIST or REJECTED,
    we still need to show initial group status as an option to
    remove such manual changes.
    This initial status is stored in 'systemStatus', and it's
    used to derive correct icon from statusIconMap.

    Also, candidates recommended for rejection by our system,
    can only be move to 'selected' status and back.
  */
  const defaultStatus = systemStatus || currentStatus;

  if (
    defaultStatus ===
      CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION &&
    currentStatus === CandidateSearchProfileStatusEnum.MANUALREJECTION
  ) {
    return [
      CandidateSearchProfileStatusEnum.MANUALSHORTLIST,
      CandidateSearchProfileStatusEnum.MANUALREJECTION,
    ];
  }

  if (
    defaultStatus === CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION
  ) {
    return [
      CandidateSearchProfileStatusEnum.MANUALSHORTLIST,
      CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION,
    ];
  }

  return [
    CandidateSearchProfileStatusEnum.MANUALSHORTLIST,
    defaultStatus,
    CandidateSearchProfileStatusEnum.MANUALREJECTION,
  ] as CandidateSearchProfileStatusEnum[];
}

export const isInManual = (status: CandidateSearchProfileStatus) => {
  return [
    CandidateSearchProfileStatus.MANUALSHORTLIST,
    CandidateSearchProfileStatus.MANUALREJECTION,
  ].includes(status);
};

export const undoStatusesMap = (
  status: CandidateSearchProfileStatus
): CandidateSearchProfileStatus => {
  switch (status) {
    case CandidateSearchProfileStatus.MANUALREJECTION:
      return CandidateSearchProfileStatus.MANUALUNREJECTION;
    case CandidateSearchProfileStatus.MANUALSHORTLIST:
      return CandidateSearchProfileStatus.MANUALUNSHORTLIST;
    default:
      return status as unknown as CandidateSearchProfileStatus;
  }
};

export const findCandidateWithFirstAvailableStatus = (
  candidates: JobEmailCandidate[]
) => {
  const sectionNames = [
    CandidateSearchProfileStatus.NOTPROCESSED,
    CandidateSearchProfileStatus.MANUALSHORTLIST,
    CandidateSearchProfileStatus.ACTIVE,
    CandidateSearchProfileStatus.PENDING,
    CandidateSearchProfileStatus.RECOMMENDEDFORREJECTION,
  ];

  const result = sectionNames
    .map((status) => {
      return candidates.find((item) => item.status === status);
    })
    .filter((item) => item);

  return result[0];
};
