import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import querystring from 'query-string';

import { DataSourceType, DataSourceResponseV3 } from '@air/api/models';
import * as commonUrls from '@air/constants/commonUrls';
import { toast } from '@air/third-party/toast';
import { Logo, UIText } from '@air/components';
import * as phrases from '@air/constants/phrases';
import { useDebounce } from '@air/utils/hooks';
import { CreatePasswordForm, FeatureView } from 'components';
import * as appConstants from 'constants/app';
import { isMobileOrTablet } from '@air/utils/mobile';
import * as userApi from 'domain/UserManagement/userApi';
import { ATSSignupSteps, SignupSteps } from 'domain/ATS/ATSIntegration';
import {
  ACCESS_TOKEN_ORIGIN,
  localStore,
} from '@air/domain/WebStorage/webStorage';
import { ATSSelectionStep } from './SignupFormSteps/ATSSelectionStep';
import {
  GreenhouseIntegrationFailure,
  GreenhouseIntegrationForm,
  GreenhouseIntegrationSuccess,
} from './SignupFormSteps/GreeenhouseIntegrationForm';
import {
  SAPIntegrationFailure,
  SAPIntegrationForm, SAPIntegrationSuccess
} from './SignupFormSteps/SAPIntegrationForm';
import {
  TaleoIntegrationForm,
  TaleoIntegrationSuccess,
  TaleoIntegrationFailure,
} from './SignupFormSteps/TaleoIntegrationForm';

import loginStyles from 'features/Login/Login.css';
import formViewStyles from 'components/FormView/FormView.css';
import { LeverIntegrationFailure } from 'features/SignupAdmin/SignupFormSteps/LeverIntegrationForm';
import * as urls from 'constants/urls';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

const ATS_INTEGRATION_ERROR = 'integrationFailure';
const PROVIDER_LEVER = 'lever';

export const SignupAdmin: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const {
    setUserCredentials,
    setAts,
    setStandaloneAtsUser,
    fetchAvailableATSList,
  } = useCustomerProfileMethods();
  const [loginReady, setLoginReady] = useState(false);

  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );
  const ats = useCustomerProfileContext(customerProfileSelectors.ats);

  useEffect(() => {
    setTimeout(() => setLoginReady(true), 500);
  }, []);

  const { linkToken, provider, errorType } = querystring.parse(location.search);

  // here we check if the step user should go to was provided over
  // the GET params after the failure authorization process
  const extrinsicStep = useMemo(() => {
    if (errorType === ATS_INTEGRATION_ERROR) {
      if (provider === PROVIDER_LEVER) {
        return SignupSteps.LeverIntegrationFailure;
      }
    }
  }, [provider, errorType]);

  if (extrinsicStep) {
    history.replace(urls.SIGNUP_ROUTE, { step: extrinsicStep });
  }

  useEffect(() => {
    /*
      If user ends up on this page without a linkToken, it means that
      either:
      a) user was redirect here because ATS setup wasn't finished
      b) user tried to enter /signup route URL manually
      c) user was redirected here after ATS authorization failure

      In case a) there will be a { step } object in location state,
      to initiate immediate switch to ATS selection step on SignupAdminForm.
      Case a) means that user already has the password set up.

      Otherwise, we redirect the user to login screen because user isn't
      authorized to setup the password (no linkToken).
    */
    if (!linkToken && !history.location.state && !extrinsicStep) {
      history.replace(commonUrls.LOGIN_ROUTE);
    }
  }, [linkToken, extrinsicStep, history]);

  const { state } = useLocation<{ step: SignupSteps }>();
  const [currentStep, setStep] = useState(
    state?.step || extrinsicStep || SignupSteps.PasswordSetupStep
  );

  const setNewPassword = useCallback(
    ({ password }: { password: string }) => {
      return userApi
        .setNewPassword(customerId, linkToken as string, password)
        .fork(
          () => {},
          () => {
            /*
              This ACCESS_TOKEN_ORIGIN flag indicates that customer information
              was received via linkToken, not because the user entered its
              credentials in Login form.

              And if the password is already setup, linkToken is expired and
              ACCESS_TOKEN_ORIGIN flag is no longer relevant.
            */
            localStore.remove(ACCESS_TOKEN_ORIGIN);
            setUserCredentials({ email: user.email, password });
            setStep(SignupSteps.ATSSelectionStep);
          }
        );
    },
    [user, customerId, linkToken, setUserCredentials]
  );

  const onSubmitPassword = useDebounce(
    setNewPassword,
    appConstants.REQUEST_DEBOUNCE_TIME,
    true
  );

  const onIntegrationSuccess = useCallback(() => {
    history.replace(commonUrls.ROOT_ROUTE);
  }, [history]);

  const [isConnectingToATS, setConnectingState] = useState(false);
  const onAtsReceived = useCallback(
    (res: DataSourceResponseV3) => {
      setAts(res);
      setStandaloneAtsUser(res.type === DataSourceType.EMPLOYAATS);
      switch (res.type) {
        case DataSourceType.ORACLETALEO:
          setStep(SignupSteps.TaleoIntegrationSuccess);
          break;
        case DataSourceType.GREENHOUSEATS:
          setStep(SignupSteps.GreenhouseIntegrationSuccess);
          break;
        case DataSourceType.LEVERATS:
          userApi
            .getLeverAuthorizationForm(res.dataSourceId)
            .fork(R.identity, (res) => {
              const redirectLocation = res.payload?.headers.get(
                'x-redirect-location'
              );
              window.location = redirectLocation;
            });
          break;
        case DataSourceType.SAPATS:
          setStep(SignupSteps.SAPIntegrationSuccess);
          break;
        default:
          onIntegrationSuccess();
      }
    },
    [onIntegrationSuccess, setAts, setStandaloneAtsUser]
  );

  useEffect(() => {
    /*
      AR-10828:
      If user already has ATS assigned, but we need to update access credentials,
      we immediately call `onAtsReceived` to redirect user to ATS token setup.
      Currently, this logic only covers Lever ATS, and we don't have such flow
      for other ATS types. It might need refactoring in future.

      We also explicitly check currentStep, because otherwise user is infinitely redirected
      to Lever auth form in case if Lever auth failed.
    */

    if (
      ats &&
      'reLoginNeeded' in ats &&
      ats?.reLoginNeeded &&
      currentStep !== SignupSteps.LeverIntegrationFailure
    ) {
      onAtsReceived(ats);
    }
  }, [ats, onAtsReceived, currentStep]);

  const onSelectATS = useCallback(
    async (atsType: DataSourceType, atsSettings: any) => {
      const nextStep = ATSSignupSteps[atsType];
      if (nextStep && !atsSettings) {
        return setStep(nextStep);
      }

      setConnectingState(true);

      let isConnectFinished = false;

      const atsTask = !ats
        ? userApi.setCompanyATS(user.companyId, atsType, atsSettings)
        : userApi.updateCompanyATS({
            companyId: user.companyId,
            atsType,
            connectionSettings: atsSettings,
            atsId: ats.dataSourceId,
          });

      return atsTask.fork(
        () => {
          toast.error(phrases.ATS_INTEGRATION_REQUEST_FAILURE);
          isConnectFinished = true;
          switch (atsType) {
            case DataSourceType.ORACLETALEO:
              setStep(SignupSteps.TaleoIntegrationFailure);
              break;
            case DataSourceType.GREENHOUSEATS:
              setStep(SignupSteps.GreenhouseIntegrationFailure);
              break;
            case DataSourceType.SAPATS:
              setStep(SignupSteps.SAPIntegrationFailure);
              break;
          }
          /*
            AR-8687: We refetch ATS list in case of failed ATS integration,
            because backend creates a data source initially, and then tries to
            integrate with customer's ATS.
            If we return to previous screen to retry integration (for example,
            if we've input invalid credits on the 1st try), we'll need to send
            PUT request instead of POST to finish ATS integration setup.
          */
          if (!ats) {
            fetchAvailableATSList(user.companyId);
          }
        },
        (res: DataSourceResponseV3) => {
          isConnectFinished = true;
          onAtsReceived(res);
        }
      );

      const ONE_SECOND = 1000;
      const intervalId = setInterval(() => {
        if (isConnectFinished) {
          clearInterval(intervalId);
          setConnectingState(false);
        }
      }, ONE_SECOND);
    },
    [user, ats, fetchAvailableATSList, onAtsReceived]
  );

  const onSelectATSDebounced = useDebounce(
    onSelectATS,
    appConstants.REQUEST_DEBOUNCE_TIME,
    true
  );

  const onCancelIntegration = useCallback(() => {
    setStep(SignupSteps.ATSSelectionStep);
  }, []);

  const onIntegrationRetry = useCallback((atsType: DataSourceType) => {
    switch (atsType) {
      case DataSourceType.GREENHOUSEATS:
        setStep(SignupSteps.GreenhouseIntegrationStep);
        break;
      case DataSourceType.ORACLETALEO:
        setStep(SignupSteps.TaleoIntegrationStep);
        break;
      case DataSourceType.SAPATS:
        setStep(SignupSteps.SAPIntegrationStep);
        break;
      default:
        setStep(SignupSteps.ATSSelectionStep);
    }
    setConnectingState(false);
  }, []);

  const nextStepComponent = useMemo(() => {
    return {
      [SignupSteps.PasswordSetupStep]: () => (
        <div>
          <UIText small bold className={formViewStyles.supHeader}>
            {phrases.getSignupStep(1)}
          </UIText>
          <CreatePasswordForm
            onSubmit={onSubmitPassword}
            formTitle={phrases.SET_PASSWORD_FORM_TITLE}
            submitButtonText={phrases.SIGNUP_NEXT_STEP_BUTTON}
          />
        </div>
      ),
      [SignupSteps.ATSSelectionStep]: () => (
        <ATSSelectionStep onSelect={onSelectATSDebounced} />
      ),
      [SignupSteps.GreenhouseIntegrationStep]: () => (
        <GreenhouseIntegrationForm
          onConnect={onSelectATSDebounced}
          onCancel={onCancelIntegration}
          isConnecting={isConnectingToATS}
        />
      ),
      [SignupSteps.GreenhouseIntegrationSuccess]: () => (
        <GreenhouseIntegrationSuccess onStart={onIntegrationSuccess} />
      ),
      [SignupSteps.GreenhouseIntegrationFailure]: () => (
        <GreenhouseIntegrationFailure
          onRetry={() => onIntegrationRetry(DataSourceType.GREENHOUSEATS)}
        />
      ),
      [SignupSteps.TaleoIntegrationStep]: () => (
        <TaleoIntegrationForm
          onConnect={onSelectATSDebounced}
          onCancel={onCancelIntegration}
          isConnecting={isConnectingToATS}
        />
      ),
      [SignupSteps.TaleoIntegrationSuccess]: () => (
        <TaleoIntegrationSuccess onStart={onIntegrationSuccess} />
      ),
      [SignupSteps.TaleoIntegrationFailure]: () => (
        <TaleoIntegrationFailure
          onRetry={() => onIntegrationRetry(DataSourceType.ORACLETALEO)}
        />
      ),
      [SignupSteps.LeverIntegrationFailure]: () => (
        <LeverIntegrationFailure
          onRetry={() => onIntegrationRetry(DataSourceType.LEVERATS)}
        />
      ),
      [SignupSteps.SAPIntegrationStep]: () => (
        <SAPIntegrationForm
          onConnect={onSelectATSDebounced}
          onCancel={onCancelIntegration}
          isConnecting={isConnectingToATS}
        />),
      [SignupSteps.SAPIntegrationSuccess]: () => (
        <SAPIntegrationSuccess onStart={onIntegrationSuccess} />
      ),
      [SignupSteps.SAPIntegrationFailure]: () => (
        <SAPIntegrationFailure
          onRetry={() => onIntegrationRetry(DataSourceType.SAPATS)}
        />
      ),
    }[currentStep]();
  }, [
    currentStep,
    onSubmitPassword,
    onSelectATSDebounced,
    onCancelIntegration,
    onIntegrationSuccess,
    onIntegrationRetry,
    isConnectingToATS,
  ]);

  return (
    <FeatureView
      className={classNames({
        [loginStyles.isMobileOrTablet]: isMobileOrTablet,
      })}
    >
      <FeatureView.Aside className={loginStyles.loginAside}>
        <Logo isWhiteLogoText />
      </FeatureView.Aside>
      <FeatureView.Content
        className={classNames(
          loginStyles.loginContent,
          formViewStyles.formViewContent,
          {
            [loginStyles.ready]: loginReady,
          }
        )}
      >
        {nextStepComponent}
      </FeatureView.Content>
    </FeatureView>
  );
};
SignupAdmin.displayName = 'SignupAdmin';
