import React, { useCallback } from 'react';
import classNames from 'classnames';

// imports from 'types';
import { CustomerProfileContextT } from 'context';

import { History } from 'history';

// imports from helpers
import { emit } from 'hooks/usePubSub';
import { useUpdateSearchesCallback } from 'hooks/useUpdateSearchesCallback';

// imports from 'components'
import {
  CanAccess,
  Header,
  Logo,
  TooltipWrapper,
  UIText,
} from '@air/components';
import { NavbarIconButton } from 'components';

// imports from constants
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import * as urls from 'constants/urls';
import { APP_EVENTS } from 'domain/Kanban/events';

// imports from styles
import styles from './NavigationBar.css';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CONTACT_US, GA_LABEL_SETTINGS } from 'constants/gaLabels';
import { useKanbanMethods } from 'providers/KanbanProvider';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors, uploadApplicantsSelectors } from 'selectors';
import { useUploadApplicantsContext } from 'providers/UploadApplicantsProvider';
import { useRouteMatch } from 'react-router-dom';

type NavigationBarProps = {
  history: History;
};
export const NavigationBar: React.FC<NavigationBarProps> = ({ history }) => {
  const wallpapersRouteMatch = useRouteMatch(urls.WALLPAPERS_ROUTE);
  const helpCenterRouteMatch = useRouteMatch(urls.HELP_CENTER_ROUTE);
  const settingsRoutesMatch = useRouteMatch(urls.SETTINGS_ROUTES);

  const { logout } = useCustomerProfileMethods();

  const { updateSearchFilter } = useKanbanMethods();

  const isUploadInProgress = useUploadApplicantsContext(
    uploadApplicantsSelectors.isUploadInProgress
  );

  const canAccessUserManagement = useCustomerProfileContext(
    customerProfileSelectors.canAccessUserManagement
  );
  const companyName = useCustomerProfileContext(
    customerProfileSelectors.customerCompanyName
  );

  const onLogoClickCb = useUpdateSearchesCallback();

  const onSettingsClick = useCallback(() => {
    if (settingsRoutesMatch === null) {
      history.push(urls.SETTINGS_ROUTE);
      trackEvent({
        category: GACategory.KanbanPage,
        label: GA_LABEL_SETTINGS,
      });
    }
  }, [settingsRoutesMatch, history]);

  const onHelpCenterOpen = useCallback(() => {
    history.push(urls.HELP_CENTER_ROUTE);
    trackEvent({
      category: GACategory.KanbanPage,
      label: GA_LABEL_CONTACT_US,
    });
  }, [history]);

  const onLogout = useCallback(() => {
    updateSearchFilter({
      searchFilter: { name: '', isOwnedByMe: false },
      shouldFetchSearches: false,
    });
    logout();
  }, [logout, updateSearchFilter]);

  const onLogoutClicked = () => {
    if (isUploadInProgress) {
      if (!window.confirm(phrases.UPLOADING_LEAVE_PAGE)) {
        return;
      }
    }

    emit(APP_EVENTS.LOGOUT);
    onLogout();
  };

  return (
    <div className={styles.navbar}>
      <header className={styles.header}>
        <div className={styles.logo}>
          <Logo
            className={styles.mainLogo}
            onClick={onLogoClickCb}
            href={urls.ROOT_ROUTE}
            onlyIconVisible
          />
        </div>
        <div className={styles.delimiter} />
        <div className={styles.organizationWrapper}>
          <UIText className={styles.company} tiny>
            {phrases.ORGANIZATION_TITLE}
          </UIText>
          <TooltipWrapper
            tooltip={companyName}
            enabled
            placement="bottom-start"
          >
            <Header level={3} className={styles.companyName}>
              {companyName}
            </Header>
          </TooltipWrapper>
        </div>
      </header>
      <div>
        <div className={styles.navbarItem}>
          <NavbarIconButton
            id="wallpapers-button"
            isActive={wallpapersRouteMatch?.isExact}
            icon="tab"
            iconText={phrases.WALLPAPERS_BUTTON_LABEL}
            onClick={() => history.push(urls.WALLPAPERS_ROUTE)}
          />
        </div>
        <CanAccess<CustomerProfileContextT> hasAccess={canAccessUserManagement}>
          <div className={styles.navbarItem}>
            <NavbarIconButton
              icon="settings"
              isActive={settingsRoutesMatch !== null}
              iconText={phrases.SETTINGS_BUTTON_LABEL}
              onClick={onSettingsClick}
            />
          </div>
        </CanAccess>
        <div className={classNames(styles.navbarItem, styles.helpCenterIcon)}>
          <NavbarIconButton
            id="help-center-button"
            icon="help-center-icon"
            isActive={helpCenterRouteMatch?.isExact}
            iconText={phrases.HELP_CENTER_BUTTON_LABEL}
            onClick={onHelpCenterOpen}
          />
        </div>
        <div className={classNames(styles.navbarItem, styles.logout)}>
          <NavbarIconButton
            icon="log-out"
            onClick={onLogoutClicked}
            iconText={sharedPhrases.LOGOUT_BUTTON_TEXT}
          />
        </div>
      </div>
    </div>
  );
};
