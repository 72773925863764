/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum ItExperienceGroupType {
  LESSTHANONEYEAR = 'LESS_THAN_ONE_YEAR',
  ONETHREEYEAR = 'ONE_THREE_YEAR',
  THREEFIVEYEAR = 'THREE_FIVE_YEAR',
  FIVEINFINITYYEAR = 'FIVE_INFINITY_YEAR',
}
