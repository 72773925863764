// imports from vendor deps
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// @ts-ignore commented out due to the outdated @types/react-router-dom
// updating needs significant amount of time to update all the dependent types
import { useLocation } from 'react-router-dom';
import { toast } from '@air/third-party/toast';
// import {} from 'ramda';
import classNames from 'classnames';

// imports from types
import { AcceptedFile, RejectedFile } from 'context/uploadApplicants';

// imports from 'components'
// imports from 'constants'
import { DRAFT_ROUTE, INTERVIEW_ROUTE } from 'constants/urls';
import * as phrases from 'constants/phrases';

// imports from helpers
import { MAX_FILES_AMOUNT } from 'utils/uploads';

// imports from styles
import styles from './Dropzone.css';
import { trackEvent } from '@air/utils/ga';
import { GA_LABEL_FLAGS_UPLOAD_CV_DND } from 'constants/gaLabels';
import { GACategory, GAAction } from 'air-shared/domain/Common/GATypes';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors, uploadApplicantsSelectors } from 'selectors';
import {
  useUploadApplicantsContext,
  useUploadApplicantsMethods,
} from 'providers/UploadApplicantsProvider';

// component proptypes
type Props = {
  isVisible?: boolean;
  onDragEnter?: (e: React.DragEvent<HTMLElement>) => void;
  onDragLeave?: (e: React.DragEvent<HTMLElement>) => void;
  onDrop?: () => void;
};

const ACCEPTED_FILES =
  '.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';
const MAX_FILE_SIZE = 10485760; // 10 mb

export const BASIC_DROPZONE_CONFIG = {
  noKeyboard: true,
  noDragEventsBubbling: true,
  noClick: true,
  accept: ACCEPTED_FILES,
  maxFiles: MAX_FILES_AMOUNT,
  maxSize: MAX_FILE_SIZE,
};

// exports / component definitions
export const Dropzone: React.FC<Props> = ({
  isVisible,
  onDragEnter,
  onDrop,
  onDragLeave,
}) => {
  const location = useLocation();
  const {
    onDropAccepted,
    onDropRejected,
    setAddTagsAbility,
    onDraftDropAccepted,
  } = useUploadApplicantsMethods();

  const canAddTags = useUploadApplicantsContext(
    uploadApplicantsSelectors.canAddTags
  );
  const isUploadToServerInProgress = useUploadApplicantsContext(
    uploadApplicantsSelectors.isUploadToServerInProgress
  );

  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );

  const isDraftView = location.pathname.includes(DRAFT_ROUTE);
  const isInterviewView = location.pathname.includes(INTERVIEW_ROUTE);

  const isAllowedDropzone = isDraftView || isInterviewView;

  const { getRootProps, getInputProps } = useDropzone({
    ...BASIC_DROPZONE_CONFIG,
    onDropRejected: (items: RejectedFile[]) => {
      onDropRejected(items);
      onDrop();
    },
    onDropAccepted: (items: AcceptedFile[]) => {
      trackEvent({
        category: GACategory.DraftPage,
        action: GAAction.DragNDrop,
        label: GA_LABEL_FLAGS_UPLOAD_CV_DND,
      });
      if (isAllowedDropzone && !isUploadToServerInProgress) {
        if (isDraftView) {
          onDraftDropAccepted(items, dataSourceId);
        } else {
          onDropAccepted(items, dataSourceId);
        }
      }
      onDrop();
    },
    onDragEnter: (event) => {
      if (event.shiftKey) {
        setAddTagsAbility(true);
      }
      onDragEnter(event);
    },
    onDragLeave: (event) => {
      if (canAddTags) {
        setAddTagsAbility(false);
      }
      onDragLeave(event);
    },
  });

  useEffect(() => {
    if (isVisible && !isAllowedDropzone) {
      toast.warn(phrases.DRAGGING_CV_INTO_NOT_ALLOWED_AREA, {
        autoClose: 2000,
        closeButton: false,
      });
    }
  }, [isVisible, isAllowedDropzone]);

  useEffect(() => {
    if (isVisible && isUploadToServerInProgress) {
      toast.warn(phrases.DISABLED_UPLOAD_EXPLANATION_TEXT, {
        autoClose: false,
        closeButton: false,
      });
    }
  }, [isVisible, isUploadToServerInProgress]);

  return isStandaloneAtsUser ? (
    <div
      className={classNames(styles.dropzoneWrapper, {
        [styles.visible]: isVisible && isStandaloneAtsUser,
        [styles.allowedDropzone]: isVisible && isAllowedDropzone,
        [styles.forbiddenDropzone]: isVisible && !isAllowedDropzone,
      })}
    >
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
      </div>
    </div>
  ) : null;
};

Dropzone.defaultProps = {};
Dropzone.displayName = 'Dropzone';
