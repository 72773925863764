import * as phrases from 'constants/phrases';
import { RedFlagItem } from '@air/api/models/RedFlagItem';
import { RedFlagAlertType } from '@air/api';
import R from '@air/third-party/ramda';

export type RedFlagSettingsData = RedFlagItem & {
  displayName: string;
  description: string;
  category: string;
};

export const getFlagTextStrings = (type: RedFlagAlertType) => {
  switch (type) {
    case RedFlagAlertType.UNIMPRESSIVESKILLS:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_TRIVIAL_SKILLS,
        description: phrases.FLAG_SETTINGS_DESC_TRIVIAL_SKILLS,
      };
    case RedFlagAlertType.BUZZWORDS:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_BUZZWORDS,
        description: phrases.FLAG_SETTINGS_DESC_BUZZWORDS,
      };
    case RedFlagAlertType.VAGUEWORDS:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_VAGUE_WORDS,
        description: phrases.FLAG_SETTINGS_DESC_VAGUE_WORDS,
      };
    case RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_MISSING_MONTHS,
        description: phrases.FLAG_SETTINGS_DESC_MISSING_MONTHS,
      };
    case RedFlagAlertType.TOOMANYSKILLS:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_SKILL_OVERFLOW,
        description: phrases.FLAG_SETTINGS_DESC_SKILL_OVERFLOW,
      };
    case RedFlagAlertType.DIPLOMAMILL:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_DIPLOMA_MILL,
        description: phrases.FLAG_SETTINGS_DESC_DIPLOMA_MILL,
      };
    case RedFlagAlertType.UNKNOWNUNIVERSITY:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_UNKNOWN_UNIVERSITY,
        description: phrases.FLAG_SETTINGS_DESC_UNKNOWN_UNIVERSITY,
      };
    case RedFlagAlertType.UNKNOWNCOMPANY:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_UNKNOWN_COMPANY,
        description: phrases.FLAG_SETTINGS_DESC_UNKNOWN_COMPANY,
      };
    case RedFlagAlertType.EDUCATIONDATADISCREPANCY:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_PIPL_EDUCATION_DISCREPANCY,
        description: phrases.FLAG_SETTINGS_DESC_PIPL_EDUCATION_DISCREPANCY,
      };
    case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
      return {
        displayName:
          phrases.FLAG_SETTINGS_TITLE_PIPL_EMPLOYMENT_RECORD_DISCREPANCY,
        description:
          phrases.FLAG_SETTINGS_DESC_PIPL_EMPLOYMENT_RECORD_DISCREPANCY,
      };
    case RedFlagAlertType.TOOMANYJOBCHANGES:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_JOB_HOPPING,
        description: phrases.FLAG_SETTINGS_DESC_JOB_HOPPING,
      };
    case RedFlagAlertType.NOCAREERPROGRESS:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_NO_CAREER_PROGRESS,
        description: phrases.FLAG_SETTINGS_DESC_NO_CAREER_PROGRESS,
      };
    case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_LONG_TERM_EMPLOYMENT,
        description: phrases.FLAG_SETTINGS_DESC_LONG_TERM_EMPLOYMENT,
      };
    case RedFlagAlertType.GAPSINEMPLOYMENTHISTORY:
      return {
        displayName: phrases.FLAG_SETTINGS_TITLE_EMPLOYMENT_GAPS,
        description: phrases.FLAG_SETTINGS_DESC_EMPLOYMENT_GAPS,
      };
    case RedFlagAlertType.DESCRIPTIONSTOOLONG:
      return {
        displayName: phrases.FLAG_SETTINGS_DESCRIPTIONS_TOO_LONG_TITLE,
        description: phrases.FLAG_SETTINGS_DESCRIPTIONS_TOO_LONG_DESC,
      };
  }
};

export const getRedFlagCategory = (redFlagType: RedFlagAlertType) => {
  switch (redFlagType) {
    case RedFlagAlertType.UNIMPRESSIVESKILLS:
    case RedFlagAlertType.TOOMANYSKILLS:
      return phrases.CATEGORY_SKILLS_LABEL;
    case RedFlagAlertType.BUZZWORDS:
    case RedFlagAlertType.VAGUEWORDS:
    case RedFlagAlertType.DESCRIPTIONSTOOLONG:
      return phrases.CATEGORY_WORDINGS_LABEL;
    case RedFlagAlertType.GAPSINEMPLOYMENTHISTORY:
    case RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD:
    case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
    case RedFlagAlertType.TOOMANYJOBCHANGES:
    case RedFlagAlertType.NOCAREERPROGRESS:
    case RedFlagAlertType.UNKNOWNCOMPANY:
    case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
      return phrases.CATEGORY_EMPLOYMENT_LABEL;
    case RedFlagAlertType.DIPLOMAMILL:
    case RedFlagAlertType.UNKNOWNUNIVERSITY:
    case RedFlagAlertType.EDUCATIONDATADISCREPANCY:
      return phrases.CATEGORY_EDUCATION_LABEL;
  }
};

export const mapRedFlagsRequestToRedFlagData = (
  redFlags: RedFlagItem[] = []
): RedFlagSettingsData[] => {
  return R.sortBy(
    R.prop('displayName'),
    redFlags.map((it: RedFlagItem) => {
      const { displayName, description } = getFlagTextStrings(it.type);
      return {
        ...it,
        category: getRedFlagCategory(it.type),
        displayName,
        description,
      };
    })
  );
};

const FLAGS_AVAILABLE_RESOLUTIONS = {
  // Each digit in the following numbers stands for specific
  // resolution availability for different flag types
  // eg. '100':
  //    (1) - WARNING - enabled
  //    (0) - EXPLANATION - disabled
  //    (0) - DISQUALIFICATION - disabled
  //
  [RedFlagAlertType.BUZZWORDS]: '100',
  [RedFlagAlertType.GAPSINEMPLOYMENTHISTORY]: '111',
  [RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD]: '111',
  [RedFlagAlertType.UNIMPRESSIVESKILLS]: '100',
  [RedFlagAlertType.VAGUEWORDS]: '100',
  [RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY]: '100',
  [RedFlagAlertType.DIPLOMAMILL]: '101',
  [RedFlagAlertType.UNKNOWNUNIVERSITY]: '110',
  [RedFlagAlertType.TOOMANYSKILLS]: '101',
  [RedFlagAlertType.TOOMANYJOBCHANGES]: '111',
  [RedFlagAlertType.NOCAREERPROGRESS]: '100',
  [RedFlagAlertType.UNKNOWNCOMPANY]: '111',
  [RedFlagAlertType.EDUCATIONDATADISCREPANCY]: '111',
  [RedFlagAlertType.EMPLOYMENTDATADISCREPANCY]: '111',
  [RedFlagAlertType.DESCRIPTIONSTOOLONG]: '101',
};
export const isWarningAvailable = (redFlagType: RedFlagAlertType) =>
  FLAGS_AVAILABLE_RESOLUTIONS[redFlagType].charAt(0) === '1';
export const isExplanationAvailable = (redFlagType: RedFlagAlertType) =>
  FLAGS_AVAILABLE_RESOLUTIONS[redFlagType].charAt(1) === '1';
export const isDisqualifyAvailable = (redFlagType: RedFlagAlertType) =>
  FLAGS_AVAILABLE_RESOLUTIONS[redFlagType].charAt(2) === '1';
