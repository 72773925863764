import R from '@air/third-party/ramda';
import * as phrases from 'constants/phrases';
import {
  BaseCriteriaData,
  BaseCriteriaWithList,
  BaseCriteriaWithQuestion,
  BaseEducationCriteriaWithList,
  BaseSearchCriteriaData,
  CardType,
  DegreeCriteriaData,
  SearchCriteriaData,
  SearchCriteriaListValue,
  SearchCriteriaWithList,
  SearchEducationCriteria,
  CompanyCriteriaDataListItem,
  SearchCriteriaListValueType,
} from 'domain/SearchCriteria';
import { isExcludedCriteriaCard } from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';
import {
  convertCriteriaExperienceAndRecencyToString,
  convertManagerialExperienceToString,
  MAX_CARDS_IN_STACK,
  convertMaxAllowedDistanceToInfoString,
} from 'components/Cards/cardsCommonCode';
import { SearchCriteriaMatchStatusEnum, CompanyExtendedType } from '@air/api';
import { mapCandidateMatchingCardStackClassNames } from 'components/SearchCards/statusesMapper';

import {
  getCompanyCardListItemTooltip,
  getCompanyTitleClass,
} from 'components/Cards/CompanyCard/CompanyCard';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';

type CriteriaType<T> =
  | EducationCriteriaType<T>
  | StackableCriteriaType<T>
  | BaseCriteriaWithQuestion;

type EducationCriteriaType<T = unknown> =
  T extends BaseEducationCriteriaWithList
    ? BaseEducationCriteriaWithList
    : BaseCriteriaData;
type StackableCriteriaType<T> = T extends BaseCriteriaWithList
  ? BaseCriteriaWithList
  : BaseCriteriaData;

export function getTopStackItemLabel(type: CardType) {
  switch (type) {
    case CardType.institution:
    case CardType.major:
    case CardType.degree:
      return phrases.IDEAL_STACK_ITEM_LABEL;
    default:
      return phrases.OR_STACK_ITEM_LABEL;
  }
}

// type guards:
const hasList = (item: BaseCriteriaData): item is BaseCriteriaWithList =>
  'list' in item;

const hasIdealList = (
  item: BaseCriteriaData
): item is BaseEducationCriteriaWithList => 'idealList' in item;

const hasQuestion = (
  item: BaseCriteriaData
): item is BaseCriteriaWithQuestion => 'question' in item;

const hasAcceptableList = (
  item: BaseCriteriaData
): item is BaseEducationCriteriaWithList => 'acceptableList' in item;

export const getTopStackListName = <T extends BaseCriteriaData>(card: T) => {
  if (hasList(card)) {
    return 'list' as Extract<keyof T, string>;
  } else if (hasIdealList(card)) {
    return 'idealList' as Extract<keyof T, string>;
  }
  return '';
};

export const getBottomStackListName = <T extends BaseCriteriaData>(card: T) => {
  if (hasAcceptableList(card)) {
    return 'acceptableList' as Extract<keyof T, string>;
  }
};

export const getValuesForTopStack = <T extends BaseCriteriaData>(card: T) => {
  let values: any = [];

  if (hasList(card)) {
    values = card.list;
  } else if (hasIdealList(card)) {
    values = card.idealList;
  }
  return (values ?? []).slice(1);
};

export const getValuesForBottomStack = <T>(card: EducationCriteriaType) => {
  let values;
  switch (card.cardType) {
    case CardType.major:
    case CardType.degree:
    case CardType.institution:
      values = (card as BaseEducationCriteriaWithList<T>).acceptableList;
      break;
    default:
      return [];
  }
  return values ?? [];
};

export const getTopStackSize = <T extends BaseCriteriaData>(card: T) =>
  getValuesForTopStack(card).length;
export const getBottomStackSize = <T>(card: EducationCriteriaType<T>) =>
  getValuesForBottomStack(card).length;

export const getStackToggleItemsClasses = (
  cardData: BaseSearchCriteriaData
): string[] => {
  const topStackSize = getValuesForTopStack(cardData).length;
  const bottomStackSize = getValuesForBottomStack(cardData).length;
  const hasEquivalentExperience =
    'equivalentExperience' in cardData &&
    !!(cardData as DegreeCriteriaData).equivalentExperience;

  const stackStatuses = [];

  // for unwanted company stack different border color is required
  // that's why .unwanted is assigned to stack items
  // we take only 2 first elements because stack toggle displays only two items
  if (isExcludedCriteriaCard(cardData)) {
    return R.times(() => 'unwanted', Math.min(topStackSize, 2));
  }

  if (hasEquivalentExperience || topStackSize >= 1) {
    stackStatuses.push(SearchCriteriaMatchStatusEnum.IDEAL);
  }

  if (bottomStackSize >= 1) {
    stackStatuses.push(SearchCriteriaMatchStatusEnum.ACCEPTABLE);
  }

  if (topStackSize >= 2) {
    stackStatuses.push(SearchCriteriaMatchStatusEnum.IDEAL);
  }

  if (bottomStackSize >= 2) {
    stackStatuses.push(SearchCriteriaMatchStatusEnum.ACCEPTABLE);
  }

  // we take only 2 first elements because stack toggle displays only two items
  return stackStatuses.slice(0, 2).map(mapCandidateMatchingCardStackClassNames);
};

export const getMainCard = <
  T extends BaseCriteriaData,
  S = SearchCriteriaListValue
>(
  card: CriteriaType<T>
): S & { type?: CompanyExtendedType } => {
  if (hasList(card)) {
    return card.list?.[0] as unknown as S;
  } else if (hasIdealList(card)) {
    return card.idealList?.[0] as unknown as S;
  } else if (hasQuestion(card)) {
    return card.question as unknown as S;
  }
};

export const getCardTitle = (card: {
  cardType: CardType;
  label?: string;
}): string => {
  switch (card.cardType) {
    case CardType.major:
      return card?.label || phrases.ANY_MAJOR_LABEL;
    case CardType.degree:
      return card?.label || phrases.ANY_DEGREE_LABEL;
    case CardType.institution:
      return card?.label || phrases.ANY_INSTITUTION_LABEL;
    case CardType.skill:
    case CardType.certification:
    case CardType.industry:
    case CardType.role:
    case CardType.company:
      return card?.label || phrases.NO_NAME_CRITERIA;
    case CardType.location:
      return card?.label || phrases.NO_LOCATION_CARD_LABEL;
    case CardType.question:
      return card?.label || phrases.QUESTION_CRITERIA_TOP_LABEL;
    case CardType.managerial:
      return phrases.EXPERIENCE_MANAGERIAL_TITLE_PLACEHOLDER;
    case CardType.professional:
      return card?.label || phrases.EXPERIENCE_PROFESSIONAL_TITLE_PLACEHOLDER;
    default:
      const _exhaustiveCheck: never = card.cardType;
      return _exhaustiveCheck;
  }
};

export const getCardTitleClassName = (
  cardType: CardType,
  item: { type?: SearchCriteriaListValueType }
) => {
  switch (cardType) {
    case CardType.company:
      return getCompanyTitleClass(item as { type: CompanyExtendedType });
    case CardType.managerial:
    case CardType.professional:
      return cardCommonStyles.cardSystemValue;
    default:
      return '';
  }
};

/**
 * TODO: this is very fragile and error-prone logic, details are in:
 * https://gitlab.railsreactor.com/recruiterai/ui-workspace/-/issues/50
 */
export const getMainCardTitle = <
  T extends SearchCriteriaData & {
    label?: string;
  } = SearchCriteriaData
>(
  card: T
): string => {
  const mainCard = getMainCard(card);

  return getCardTitle({
    cardType: card.cardType,
    label: mainCard ? mainCard.label : card?.label,
  });
};

export const getSearchCriteriaCardFooter = (
  card: SearchCriteriaData
): Array<string> => {
  switch (card.cardType) {
    case CardType.major:
    case CardType.degree:
    case CardType.institution:
    case CardType.certification:
    case CardType.question:
      return [''];
    case CardType.industry:
    case CardType.company:
    case CardType.skill:
    case CardType.role:
    case CardType.professional:
      return [convertCriteriaExperienceAndRecencyToString(card)];
    case CardType.managerial:
      return [
        convertManagerialExperienceToString(card.managerialExperience),
        convertCriteriaExperienceAndRecencyToString(card),
      ];
    case CardType.location:
      return [convertMaxAllowedDistanceToInfoString(card.maxAllowedDistance)];
    default:
      return [`not implemented`];
  }
};

export const getCardStackSize = <T extends BaseCriteriaData>(
  card: CriteriaType<T>
): number => {
  switch (card.cardType) {
    case CardType.major:
    case CardType.institution:
      return (
        getValuesForTopStack(card).length + getValuesForBottomStack(card).length
      );
    case CardType.degree:
      return (
        getValuesForTopStack(card).length +
        getValuesForBottomStack(card).length +
        Number(
          !R.isNil((card as unknown as DegreeCriteriaData).equivalentExperience)
        )
      );
    default:
      return getValuesForTopStack(card).length;
  }
};

const getMultipleCriteriaLabelText = (
  pluralText: string,
  criteriaAmount: number
) => {
  return `${criteriaAmount} ${pluralText}`;
};

export const getLabelTextWithAmount = (
  singularText: string,
  pluralText: string,
  criteriaAmount?: number
) => {
  return criteriaAmount > 1
    ? getMultipleCriteriaLabelText(pluralText, criteriaAmount)
    : singularText;
};

const getEducationCriteriaAmount = <T extends BaseCriteriaData>(
  card: CriteriaType<T>
): number => {
  const idealExperienceCount =
    (card as unknown as DegreeCriteriaData)?.idealList?.length ?? 0;
  const acceptableExperienceCount =
    (card as unknown as DegreeCriteriaData)?.acceptableList?.length ?? 0;
  const equivalentExperienceCount = (card as unknown as DegreeCriteriaData)
    ?.equivalentExperience
    ? 1
    : 0;
  return (
    idealExperienceCount + acceptableExperienceCount + equivalentExperienceCount
  );
};

export const getCardCriterionLabel = <T extends BaseCriteriaData>(
  card: CriteriaType<T>
): string => {
  const criteriaAmount =
    (card as unknown as BaseCriteriaWithList).list?.length ||
    getEducationCriteriaAmount(card);
  const generateLabel = (singularText: string, pluralText: string) => {
    return getLabelTextWithAmount(singularText, pluralText, criteriaAmount);
  };

  switch (card.cardType) {
    case CardType.major:
      return generateLabel(
        phrases.MAJOR_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.MAJOR_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.degree:
      // we subtract equivalentExperience if present because despite the fact it's in the stack
      // it shouldn't trigger the card plural label
      return getCardStackSize(card) -
        Number(
          !R.isNil((card as unknown as DegreeCriteriaData).equivalentExperience)
        ) ===
        0
        ? phrases.DEGREE_CRITERIA_TOP_LABEL_SINGULAR
        : getMultipleCriteriaLabelText(
            phrases.DEGREE_CRITERIA_TOP_LABEL_PLURAL,
            criteriaAmount
          );
    case CardType.institution:
      return generateLabel(
        phrases.INSTITUTION_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.INSTITUTION_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.skill:
      return generateLabel(
        phrases.SKILL_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.SKILL_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.certification:
      return generateLabel(
        phrases.CERTIFICATION_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.CERTIFICATION_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.industry:
      return generateLabel(
        phrases.INDUSTRY_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.INDUSTRY_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.company:
      return generateLabel(
        phrases.COMPANY_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.COMPANY_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.location:
      return generateLabel(
        phrases.LOCATION_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.LOCATION_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.role:
      return generateLabel(
        phrases.ROLE_CRITERIA_TOP_LABEL_SINGULAR,
        phrases.ROLE_CRITERIA_TOP_LABEL_PLURAL
      );
    case CardType.managerial:
      return phrases.MANAGERIAL_CRITERIA_TOP_LABEL;
    case CardType.professional:
      return phrases.PROFESSIONAL_CRITERIA_TOP_LABEL;
    case CardType.question:
      return phrases.QUESTION_CRITERIA_TOP_LABEL;
    default:
      return `not implemented`;
  }
};

export const getAddingLimits = (
  card: SearchCriteriaWithList | SearchEducationCriteria,
  maxListSize = MAX_CARDS_IN_STACK
) => {
  const mainItem = getMainCard(card);
  const stackSize = getTopStackSize(card) + getBottomStackSize(card);
  const isMaxListSizeReached = stackSize === maxListSize;
  const canAddNewStackItem = !isMaxListSizeReached && !R.isNil(mainItem);
  return { isMaxListSizeReached, canAddNewStackItem };
};

export const getCardListItemTooltip = (
  cardData: SearchCriteriaData,
  listItem: CompanyCriteriaDataListItem,
  originalTooltip?: string
) => {
  switch (cardData.cardType) {
    case CardType.company:
      return getCompanyCardListItemTooltip(listItem, originalTooltip);
    default:
      return originalTooltip;
  }
};
