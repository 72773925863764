import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button, Header, Paragraph, TooltipWrapper } from '@air/components';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';

import { MatchMinerSettingsForm } from 'features/MatchServiceSettings/MatchServiceSettingsForms/MatchMinerSettingsForm';
import { MatchScoutSettingsForm } from 'features/MatchServiceSettings/MatchServiceSettingsForms/MatchScoutSettingsForm';
import styles from 'components/SearchResultsView/SearchResultsEmpty/SearchResultsEmpty.css';
import {
  MatchMinerSetupSettings,
  MatchScoutSetupSettings,
} from 'domain/MatchServices/MatchServices';
import { LineupTabs } from '@air/constants/tabs';
import { useMatchService } from 'domain/MatchServices/useMatchServiceSettings';

type SearchResultsEmptyMatchMinerT = {
  isSearchPausedOrClosed: boolean;
  isReadOnly: boolean;
  isStandaloneAtsUser?: boolean;
  dataSourceId: string | number;
  currentTab?: LineupTabs;
};

type MatchMinerSearchInitializationViewT = {
  hasRequiredMatchServiceCriteria: boolean;
  startMatchMinerSearch: (settings: MatchMinerSetupSettings) => void;
  modifySearchCriteria: () => void;
};

type MatchScoutSearchInitializationViewT = {
  isReadOnly: boolean;
  canStartMatchScoutSearch: boolean;
  startMatchScoutSearch: (settings: MatchScoutSetupSettings) => void;
  modifySearchCriteria: () => void;
};

type SearchResultsEmptyMatchMinerSearchStartedT = {
  dataSourceId: string | number;
  parentJobDescriptionId: string;
  isReadOnly: boolean;
  isStandaloneAtsUser?: boolean;
};

const MatchMinerSearchInitializationView: React.FC<
  MatchMinerSearchInitializationViewT
> = ({
  hasRequiredMatchServiceCriteria,
  startMatchMinerSearch,
  modifySearchCriteria,
}) => {
  return (
    <>
      <Header level={3} bold className={styles.title}>
        {phrases.MATCH_SERVICE_START_SEARCH}
      </Header>
      {hasRequiredMatchServiceCriteria ? (
        <>
          <Paragraph className={styles.description}>
            {phrases.NO_APPLICANTS_MATCH_MINER}
          </Paragraph>
          <MatchMinerSettingsForm
            isSubmitEnabled={hasRequiredMatchServiceCriteria}
            onMatchMinerSearchStart={startMatchMinerSearch}
          />
        </>
      ) : (
        <>
          <Paragraph className={classNames(styles.description)}>
            {phrases.NO_APPLICANTS_MATCH_MINER_NO_SKILLS_DESCRIPTION[0]}
            <Button
              className={styles.modifyLink}
              variant={Button.variants.INLINE}
              onClick={modifySearchCriteria}
            >
              {phrases.NO_APPLICANTS_MATCH_MINER_NO_SKILLS_DESCRIPTION[1]}
            </Button>
            {phrases.NO_APPLICANTS_MATCH_MINER_NO_SKILLS_DESCRIPTION[2]}:
          </Paragraph>
          <ul className={styles.mmRequirementsList}>
            <li>
              <Paragraph
                className={classNames(styles.description)}
                dangerouslySetInnerHTML={{
                  __html:
                    phrases.NO_APPLICANTS_MATCH_MINER_NO_SKILLS_DESCRIPTION[3],
                }}
              />
            </li>
            <li>
              <Paragraph
                className={classNames(styles.description)}
                dangerouslySetInnerHTML={{
                  __html:
                    phrases.NO_APPLICANTS_MATCH_MINER_NO_SKILLS_DESCRIPTION[4],
                }}
              />
            </li>
          </ul>
          <Button small disabled className={styles.mmBottomButton}>
            {phrases.MATCH_SERVICE_START_SEARCH}
          </Button>
        </>
      )}
    </>
  );
};

const MatchScoutSearchInitializationView: React.FC<
  MatchScoutSearchInitializationViewT
> = ({
  isReadOnly,
  canStartMatchScoutSearch,
  startMatchScoutSearch,
  modifySearchCriteria,
}) => {
  return (
    <>
      {canStartMatchScoutSearch ? (
        <>
          <Header level={3} bold className={styles.title}>
            {phrases.MATCH_SERVICE_START_SEARCH}
          </Header>
          <Paragraph className={styles.description}>
            {phrases.NO_APPLICANTS_MATCH_SCOUT}
          </Paragraph>
          <MatchScoutSettingsForm
            isSubmitEnabled={canStartMatchScoutSearch}
            onMatchScoutSearchStart={startMatchScoutSearch}
          />
        </>
      ) : (
        <>
          <Header level={3} bold className={styles.title}>
            {phrases.NO_APPLICANTS_MATCH_SCOUT_NO_SKILLS_HEADER}
          </Header>
          <ul className={styles.mmRequirementsList}>
            <li>
              <Paragraph className={classNames(styles.description)}>
                {phrases.NO_APPLICANTS_NO_PRIORITY_CRITERIA}
              </Paragraph>
            </li>
            <li>
              <Paragraph className={classNames(styles.description)}>
                {phrases.NO_APPLICANTS_NO_SKILL_CRITERIA}
              </Paragraph>
            </li>
          </ul>
          <Paragraph className={styles.description}>
            {phrases.NO_APPLICANTS_MATCH_SCOUT_DESCRIPTION_NOTE[0]}
            <TooltipWrapper
              triggerClassName={styles.underlinedTooltip}
              flexGrow={false}
              tooltip={
                phrases.NO_APPLICANTS_MATCH_SCOUT_DESCRIPTION_NOTE_TOOLTIP
              }
              enabled
            >
              <span className={styles.underlined}>
                {phrases.NO_APPLICANTS_MATCH_SCOUT_DESCRIPTION_NOTE[1]}
              </span>
            </TooltipWrapper>
          </Paragraph>
          <Button
            disabled={isReadOnly}
            variant={Button.variants.POSITIVE_SECONDARY}
            className={styles.mmBottomButton}
            onClick={modifySearchCriteria}
            icon="matching-icon"
          >
            {phrases.MATCH_SCOUT_START_GO_TO_CRITERIA}
          </Button>
        </>
      )}
    </>
  );
};

const MatchServiceStartScreen = ({
  currentTab,
  isReadOnly,
}: {
  currentTab: LineupTabs;
  isReadOnly: boolean;
}) => {
  const { canStartSearch, startSearch, modifySearchCriteria } =
    useMatchService(currentTab);

  const enableStart = canStartSearch && !isReadOnly;

  if (currentTab === LineupTabs.MatchMiner) {
    return (
      <MatchMinerSearchInitializationView
        hasRequiredMatchServiceCriteria={enableStart}
        startMatchMinerSearch={startSearch}
        modifySearchCriteria={modifySearchCriteria}
      />
    );
  }
  return (
    <MatchScoutSearchInitializationView
      isReadOnly={isReadOnly}
      canStartMatchScoutSearch={enableStart}
      startMatchScoutSearch={startSearch}
      modifySearchCriteria={modifySearchCriteria}
    />
  );
};

const SearchResultsEmptyMatchMinerSearchStarted: React.FC<
  SearchResultsEmptyMatchMinerSearchStartedT
> = ({
  dataSourceId,
  parentJobDescriptionId,
  isReadOnly,
  isStandaloneAtsUser,
}) => {
  const startPhrase = isStandaloneAtsUser
    ? phrases.MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_START_STANDALONE
    : phrases.MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_START;

  const endPhrase = isStandaloneAtsUser
    ? phrases.MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_END_STANDALONE
    : phrases.MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_END;

  return (
    <div className={styles.matchServiceNoResults}>
      <Header level={2} className={styles.title}>
        {phrases.MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS}
      </Header>
      <Paragraph className={styles.description}>
        <span dangerouslySetInnerHTML={{ __html: startPhrase }} />
        {isReadOnly ? (
          phrases.MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_ACTION
        ) : (
          <Link
            to={urls.makeEditInterviewUrl(dataSourceId, parentJobDescriptionId)}
            className={styles.link}
          >
            {phrases.MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_ACTION}
          </Link>
        )}
        <span dangerouslySetInnerHTML={{ __html: endPhrase }} />
      </Paragraph>
    </div>
  );
};

export const SearchResultsEmptyMatchMiner: React.FC<
  SearchResultsEmptyMatchMinerT
> = ({
  isSearchPausedOrClosed,
  isReadOnly,
  isStandaloneAtsUser,
  dataSourceId,
  currentTab,
}) => {
  const {
    parentJobDescriptionId,
    showEmptyMatchServiceResults,
    isSearchStarted,
  } = useMatchService(currentTab);

  if (!showEmptyMatchServiceResults) return null;

  return (
    <div className={styles.noResultsWrapper}>
      {isSearchStarted ? (
        <SearchResultsEmptyMatchMinerSearchStarted
          dataSourceId={dataSourceId}
          parentJobDescriptionId={parentJobDescriptionId}
          isReadOnly={isReadOnly}
          isStandaloneAtsUser={isStandaloneAtsUser}
        />
      ) : (
        <div className={styles.matchServiceNoResults}>
          {isSearchPausedOrClosed ? (
            <>
              <Header level={2} className={styles.title}>
                {phrases.MATCH_SERVICE_CLOSED_TITLE_NO_RESULTS}
              </Header>
              <Paragraph className={styles.description}>
                {phrases.MATCH_SERVICE_CLOSED_TITLE_NO_RESULTS_DESC}
              </Paragraph>
            </>
          ) : (
            <MatchServiceStartScreen
              currentTab={currentTab}
              isReadOnly={isReadOnly}
            />
          )}
        </div>
      )}
    </div>
  );
};
