// imports from vendor deps
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import { useDebounce, useOutsideClick } from '@air/utils/hooks';
import { toast } from '@air/third-party/toast';
import { trackEvent } from '@air/utils/ga';
import { Button, Header, Paragraph } from '@air/components';

// imports from types
import {
  JobDescriptionFullResponse,
  JobDescriptionItem,
  ParsingStatus,
} from '@air/api';

import { EMPTY_JOB } from 'providers/JobsProvider';
import {
  buildJobDescriptionMarkup,
  generateExistingCriteriaIds,
  getFlatKeywords,
  isRequestKeywordsInProgress,
  isRequestKeywordsNotStarted,
  isRequestKeywordsSuccess,
  KeywordsByCriteriaType,
} from 'features/JobDescription/jobDescriptionHelpers';
import { KanbanContextT } from 'context';
import { emit } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';
import styles from './JobDescription.css';
import { SearchTabs } from '@air/constants/tabs';
import { SkillCriteriaData } from 'domain/SearchCriteria';
import {
  CLICK_DEBOUNCE_TIME_LONG,
  CLICK_DEBOUNCE_TIME_SHORT,
} from '@air/constants/app';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_SUGGEST_KEYWORDS } from 'constants/gaLabels';
import { useKanbanMethods } from 'providers/KanbanProvider';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

// exports / component definitions
type EditableDescriptionProps = {
  description: string;
  onSaveDescription: (value: string) => void;
};
const EditableDescription: React.FC<EditableDescriptionProps> = ({
  description,
  onSaveDescription,
}) => {
  const [textAreaRefSetter, textAreaRef] = useOutsideClick<HTMLTextAreaElement>(
    () => {
      onSaveDescription(textAreaRef?.current?.value);
    }
  );

  useEffect(() => {
    textAreaRef?.current?.focus();
  }, [textAreaRef]);

  return (
    <textarea
      ref={textAreaRefSetter}
      className={styles.editableDescription}
      defaultValue={description}
      onClick={(event) => event.stopPropagation()}
    />
  );
};

EditableDescription.displayName = 'EditableDescription';

// component proptypes
type Props = {
  className?: string;
  titleText?: string;
  job: JobDescriptionFullResponse | JobDescriptionItem;
  isEditable?: boolean;
  criteria?: KanbanContextT['currentSearch']['criteria'];
  highlightKeywords?: boolean;
  disabledKeywords?: boolean;
};

export const JobDescription: React.FC<Props> = ({
  className = '',
  titleText,
  job,
  isEditable = false,
  criteria,
  highlightKeywords = false,
  disabledKeywords = false,
}) => {
  const {
    updateJobDescription,
    requestParseJobDescription,
    fetchJobDescriptionForSearch,
    getJobDescriptionParsingConsumer,
  } = useKanbanMethods();

  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );

  const [isEditing, toggleEditing] = useState(false);
  const [jobDescriptionPlainText, setJobDescriptionPlainText] = useState(null);
  const [isJobDescriptionDraft, setIsJobDescriptionDraft] = useState(false);
  const [isJobKeywordsRequested, setIsJobKeywordsRequested] = useState(false);
  const [isSuggestingJobKeywordsFailed, setIsSuggestingJobKeywordsFailed] =
    useState(false);

  const loadJD = useCallback(async () => {
    if (job.externalId) {
      await fetchJobDescriptionForSearch(dataSourceId, job.externalId);
    }
  }, [fetchJobDescriptionForSearch, dataSourceId, job.externalId]);

  useEffect(() => {
    // load job description data when the panel is mounted
    // this way if the specializations were updated the job description keywords will be recalculated and updated too
    loadJD();
  }, [loadJD]);

  useEffect(() => {
    const description = 'description' in job ? job.description?.trim() : '';
    setJobDescriptionPlainText(description);
  }, [job]);

  useEffect(() => {
    if (isRequestKeywordsInProgress(job) && !isJobKeywordsRequested) {
      setIsJobKeywordsRequested(true);
    } else if (isRequestKeywordsSuccess(job) && isJobKeywordsRequested) {
      setIsJobKeywordsRequested(false);
    }
  }, [isJobKeywordsRequested, job]);

  const editDescription = useCallback(() => {
    toggleEditing(true);
  }, [toggleEditing]);

  const jobKeywords = useMemo(() => {
    return getFlatKeywords({
      skill: job.descriptionWithPlaceholders?.skills,
    } as KeywordsByCriteriaType);
  }, [job.descriptionWithPlaceholders?.skills]);

  const existingCriteriaIds = useMemo(() => {
    return generateExistingCriteriaIds(criteria);
  }, [criteria]);

  const jobKeywordsToAdd = useMemo(() => {
    const uniqKeywords = R.uniqBy(R.path(['id']), R.values(jobKeywords));
    const flattenExistingCriteriaIds = R.flatten(R.values(existingCriteriaIds));

    return R.values(
      R.reject(
        (it: any) => flattenExistingCriteriaIds.includes(it.id),
        uniqKeywords
      )
    );
  }, [jobKeywords, existingCriteriaIds]);

  const jobDescriptionMarkup = useMemo(() => {
    return buildJobDescriptionMarkup(
      job.descriptionWithPlaceholders?.description || '',
      jobKeywords,
      existingCriteriaIds,
      classNames({
        [styles.disabledKeyword]: disabledKeywords,
      })
    );
  }, [
    disabledKeywords,
    job.descriptionWithPlaceholders?.description,
    existingCriteriaIds,
    jobKeywords,
  ]);

  const jobDescription = useMemo(() => {
    const shouldShowPlainText =
      !highlightKeywords ||
      isJobDescriptionDraft ||
      !job.descriptionWithPlaceholders ||
      R.isEmpty(job.descriptionWithPlaceholders.skills);
    return shouldShowPlainText ? jobDescriptionPlainText : jobDescriptionMarkup;
  }, [
    highlightKeywords,
    isJobDescriptionDraft,
    jobDescriptionPlainText,
    jobDescriptionMarkup,
    job.descriptionWithPlaceholders,
  ]);

  const onSaveDescription = useCallback(
    (value: string) => {
      toggleEditing(false);
      if (job.externalId && jobDescriptionPlainText !== value) {
        setIsJobDescriptionDraft(true);
        setIsSuggestingJobKeywordsFailed(false);
        setJobDescriptionPlainText(value);
        updateJobDescription(job.externalId, value);
      }
    },
    [
      toggleEditing,
      job.externalId,
      updateJobDescription,
      jobDescriptionPlainText,
    ]
  );

  const addKeywordsToSearchCriteria = useCallback(
    (keywords: SkillCriteriaData[]) => {
      emit(APP_EVENTS.CHANGE_SEARCH_CRITERIA_TAB, SearchTabs.Criteria);
      // postpone creating cards as changing tabs requires some time to complete
      setTimeout(() => emit(APP_EVENTS.CREATE_CARD, keywords), 0);
    },
    []
  );

  const onDescriptionClicked = useCallback(
    (event) => {
      event.stopPropagation();
      if ('keywordData' in event.target.dataset && !disabledKeywords) {
        event.stopPropagation();
        const keywordData = JSON.parse(event.target.dataset.keywordData);
        addKeywordsToSearchCriteria([keywordData]);
      } else {
        if (isEditable) {
          editDescription();
        }
      }
    },
    [editDescription, isEditable, disabledKeywords, addKeywordsToSearchCriteria]
  );

  const onDescriptionClickedDebounced = useDebounce(
    onDescriptionClicked,
    CLICK_DEBOUNCE_TIME_SHORT,
    true
  );

  const addAllKeywords = useCallback(() => {
    addKeywordsToSearchCriteria(jobKeywordsToAdd);
  }, [jobKeywordsToAdd, addKeywordsToSearchCriteria]);

  const addAllKeywordsDebounced = useDebounce(
    addAllKeywords,
    CLICK_DEBOUNCE_TIME_LONG,
    true
  );

  useEffect(() => {
    let unsubscribe: () => void = null;
    unsubscribe = getJobDescriptionParsingConsumer().subscribe((event) => {
      const { jobDescriptionId, parsingStatus } = event.payload;
      if (jobDescriptionId !== job.externalId) return;

      switch (parsingStatus) {
        case ParsingStatus.INPROGRESS:
          setIsJobKeywordsRequested(true);
          break;
        case ParsingStatus.SUCCESS:
          setIsSuggestingJobKeywordsFailed(false);
          setIsJobKeywordsRequested(false);
          setIsJobDescriptionDraft(false);
          loadJD();
          break;
        case ParsingStatus.FAILED:
          setIsSuggestingJobKeywordsFailed(true);
          setIsJobKeywordsRequested(false);
          toast.error(sharedPhrases.ERROR_JOB_DESCRIPTION);
          break;
      }
    });

    return () => {
      unsubscribe?.();
    };
  }, [getJobDescriptionParsingConsumer, job.externalId, loadJD]);

  const suggestKeywords = useCallback(async () => {
    if (!isJobKeywordsRequested) {
      setIsJobKeywordsRequested(true);
    }
    trackEvent({
      category: GACategory.DraftPage,
      label: GA_LABEL_SUGGEST_KEYWORDS,
    });
    requestParseJobDescription(job.externalId);
  }, [isJobKeywordsRequested, requestParseJobDescription, job.externalId]);

  const keywordsActionButton = useMemo(() => {
    if (!isSuggestingJobKeywordsFailed && isJobKeywordsRequested) {
      return {
        text: phrases.KEYWORDS_SUGGESTION_REQUESTED,
        handler: R.noop,
        disable: true,
      };
    } else if (
      isSuggestingJobKeywordsFailed ||
      (!isJobDescriptionDraft &&
        isRequestKeywordsSuccess(job) &&
        R.isNullOrEmpty(jobKeywords))
    ) {
      return {
        text: phrases.NO_KEYWORDS_FOUND,
        handler: R.noop,
        disable: true,
      };
    } else if (
      isJobDescriptionDraft ||
      !jobDescription ||
      isRequestKeywordsNotStarted(job)
    ) {
      return {
        text: phrases.SUGGEST_KEYWORDS,
        handler: suggestKeywords,
        disable: !jobDescription || disabledKeywords,
      };
    } else {
      return {
        text: phrases.ADD_ALL_KEYWORDS,
        handler: addAllKeywordsDebounced,
        disable:
          R.isNullOrEmpty(jobKeywordsToAdd) || disabledKeywords || isEditing,
      };
    }
  }, [
    disabledKeywords,
    isJobKeywordsRequested,
    isSuggestingJobKeywordsFailed,
    jobKeywords,
    suggestKeywords,
    job,
    jobDescription,
    isJobDescriptionDraft,
    addAllKeywordsDebounced,
    jobKeywordsToAdd,
    isEditing,
  ]);

  const noDescriptionPlaceholder = useMemo(() => {
    return isEditable
      ? phrases.EDITABLE_NO_DESCRIPTION_PLACEHOLDER
      : phrases.READONLY_NO_DESCRIPTION_PLACEHOLDER;
  }, [isEditable]);

  return (
    <>
      <div className={classNames(styles.jobDescriptionWrapper, className)}>
        <Header level={3} bold className={styles.jobTitle}>
          {titleText || phrases.JOB_DESCRIPTION_DEFAULT_TITLE}
        </Header>
        <div
          className={classNames(styles.jobDescriptionScrollableContent, {
            [styles.isEditing]: isEditing,
          })}
        >
          <div
            className={classNames(styles.jobDescriptionText, {
              [styles.jobDescriptionBackgroundContainer]: isEditable,
              [styles.isEditing]: isEditing,
            })}
          >
            {isEditable && isEditing ? (
              <EditableDescription
                description={jobDescriptionPlainText}
                onSaveDescription={onSaveDescription}
              />
            ) : (
              <Paragraph
                onClick={onDescriptionClickedDebounced}
                className={classNames(styles.staticJobDescription, {
                  [styles.emptyJobDescription]: !jobDescription,
                })}
                dangerouslySetInnerHTML={{
                  __html:
                    job === EMPTY_JOB
                      ? phrases.LOADING_PLACEHOLDER
                      : jobDescription || noDescriptionPlaceholder,
                }}
              />
            )}
          </div>
          {highlightKeywords && (
            <div className={styles.jobKeywordsToolbar}>
              <Button
                small
                variant={Button.variants.POSITIVE_SECONDARY}
                disabled={keywordsActionButton.disable}
                className={styles.jobKeywordActionButton}
                onClick={keywordsActionButton.handler}
              >
                {!isJobDescriptionDraft &&
                  !R.isNullOrEmpty(jobKeywordsToAdd) && (
                    <span className={styles.jobActionButtonCounter}>
                      {jobKeywordsToAdd.length}
                    </span>
                  )}
                <span className={styles.jobKeywordActionButtonLabel}>
                  {keywordsActionButton.text}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

JobDescription.displayName = 'JobDescription';
