/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  Alert,
  DictionaryIdentifiableItem,
  SearchCriteriaMatchStatusEnum,
} from './';

/**
 *
 * @export
 * @interface SearchInstitutionInfo
 */
export interface SearchInstitutionInfo {
  /**
   *
   * @type {number}
   * @memberof SearchInstitutionInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SearchInstitutionInfo
   */
  refId: string;
  /**
   *
   * @type {string}
   * @memberof SearchInstitutionInfo
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof SearchInstitutionInfo
   */
  type: SearchInstitutionInfoTypeEnum;
  /**
   *
   * @type {SearchCriteriaMatchStatusEnum}
   * @memberof SearchInstitutionInfo
   */
  status: SearchCriteriaMatchStatusEnum;
  /**
   *
   * @type {number}
   * @memberof SearchInstitutionInfo
   */
  score: number | null;
  /**
   *
   * @type {Array<Alert>}
   * @memberof SearchInstitutionInfo
   */
  alerts: Array<Alert>;
  /**
   *
   * @type {Array<DictionaryIdentifiableItem>}
   * @memberof SearchInstitutionInfo
   */
  items?: Array<DictionaryIdentifiableItem>;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchInstitutionInfoTypeEnum {
  Institution = 'Institution',
  InstitutionList = 'InstitutionList',
}
