import { MutableRefObject, useEffect } from 'react';
import R from '@air/third-party/ramda';

import { CandidateDataUpdateType } from 'domain/CandidateData/CandidateDataServerEvents/CandidateDataServerEvents';
import {
  isMatchMinerTab,
  isPassiveTab,
  mapSectionsToCandidateStatuses,
} from 'domain/CandidateData/CandidateLineupData';
import { LineupTabs } from '@air/constants/tabs';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import {
  candidateProfileSelectors,
  customerProfileSelectors,
  kanbanSelectors,
} from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  useCandidateProfileContext,
  useCandidateProfileMethods,
} from 'providers/CandidateProfileProvider';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData';
import { useCandidateQuery } from 'hooks/useCandidateQuery';

export const useLineupSSESubscription = (props: {
  openedSections: MutableRefObject<{
    active: CandidateSearchProfileStatusEnum[];
    passive: CandidateSearchProfileStatusEnum[];
    matchMiner: CandidateSearchProfileStatusEnum[];
    matchScout: CandidateSearchProfileStatusEnum[];
  }>;
  currentTab: LineupTabs;
  jobDescriptionId: string;
}) => {
  const { openedSections, currentTab, jobDescriptionId } = props;
  const currentSearchPassiveTotalCount = useKanbanContext(
    kanbanSelectors.currentSearchPassiveTotalCount
  );
  const matchMinerPassiveTotalCount = useKanbanContext(
    kanbanSelectors.matchMinerPassiveTotalCount
  );
  const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
  const currentMatchMinerSearchId = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearchId
  );
  const currentMatchScoutSearchId = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearchId
  );
  const {
    subscribeToCandidatesProcessingSSE,
    updateSearchCandidatesProfileSize,
    fetchSearch,
  } = useKanbanMethods();

  const {
    loadAllCandidates,
    handleCandidateStatusUpdate,
    fetchAndUpdateCandidateInState,
    dropAllCandidates,
    removeCandidatesFromCandidatesList,
  } = useCandidateProfileMethods();
  const isPassiveSearchEnabled = useCustomerProfileContext(
    customerProfileSelectors.isPassiveSearchEnabled
  );
  const isMatchMinerView = isMatchMinerTab(currentTab);
  const isPassiveView = isPassiveTab(currentTab) && isPassiveSearchEnabled;

  const { candidateNameQuery } = useCandidateQuery(null);

  const currentCandidateProfileId = useCandidateProfileContext(
    candidateProfileSelectors.currentCandidateProfileId
  );

  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );

  const currentSearchAppliedCount = useKanbanContext(
    kanbanSelectors.currentSearchAppliedCount
  );

  useEffect(() => {
    function subscribeToSearchSSE(
      searchId: number,
      searchTab: LineupTabs,
      openedSections: CandidateSearchProfileStatusEnum[]
    ) {
      if (!searchId) return R.noop;

      return subscribeToCandidatesProcessingSSE(
        searchId,

        /**
         * Please refer to the module `CandidateDataServerEvents.ts`,
         * where `candidateDataHandler` function is defined for detailed information on event handling.
         **/
        async (events) => {
          events.forEach((update) => {
            if (searchTab === LineupTabs.Active) {
              /*
                Updates of counters (for example interview was paused, but customer added resumes)
                It seems that it is impossible to get to this state from Customer Portal,
                but maybe it can happen if new profiles are uploaded to customer's ATS.

                This event is only possible for Parent Search (Active tab), according to BE info.
              */
              if (update.type === CandidateDataUpdateType.UPDATE_COUNTERS) {
                fetchSearch({
                  dataSourceId,
                  jobDescriptionId,
                  shouldPerformPassiveSearch: isPassiveSearchEnabled,
                });
              }
            }

            if (update.type === CandidateDataUpdateType.DROP_CANDIDATES_LIST) {
              // just clear the lineup and reload candidates if any profiles were already created
              dropAllCandidates();
              loadAllCandidates({
                searchId,
                status: mapSectionsToCandidateStatuses(openedSections),
                tab: searchTab,
                sortId: currentSearchId,
              });
              updateSearchCandidatesProfileSize(searchId, searchTab);
            }

            if (
              update.type === CandidateDataUpdateType.UPDATE_CANDIDATES ||
              update.type === CandidateDataUpdateType.UPDATE_CANDIDATE_STAGE
            ) {
              loadAllCandidates({
                searchId,
                status: mapSectionsToCandidateStatuses(openedSections),
                tab: searchTab,
                sortId: currentSearchId,
              });
              // (re)processing for candidate(s) requires fetching search as well (for counters)
              fetchSearch({
                jobDescriptionId,
                shouldPerformPassiveSearch: isPassiveSearchEnabled,
              });

              /*
                Special case to handle Passive tab candidates processing,
                because it doesn't have its own search and is "tied" to Active tab
                (parent search).
              */
              if (
                currentSearchId === searchId &&
                currentTab === LineupTabs.Passive
              ) {
                handleCandidateStatusUpdate({
                  searchId,
                  ids: update.payload,
                  filter: candidateNameQuery,
                  tab: currentTab,
                });
              }

              /* candidate profile tab is opened, and since data storages for lineup and candidate profile tab are
               * separated, we need to update it as well */
              if (update.payload.includes(currentCandidateProfileId)) {
                fetchAndUpdateCandidateInState(
                  searchId,
                  currentCandidateProfileId
                );
              }
            }
            // just remove candidate from lineup
            // please, be aware, here we don't handle (yet) the case when profile of deleted candidate is already opened
            // in tab.
            if (update.type === CandidateDataUpdateType.DELETE_CANDIDATES) {
              removeCandidatesFromCandidatesList(update.payload, currentTab);
            }
          });
        }
      );
    }

    const unsubscribeParentSearchSSE = subscribeToSearchSSE(
      currentSearchId,
      LineupTabs.Active,
      openedSections.current.active
    );
    const unsubscribeMatchMinerSearchSSE = subscribeToSearchSSE(
      currentMatchMinerSearchId,
      LineupTabs.MatchMiner,
      openedSections.current.matchMiner
    );
    const unsubscribeMatchScoutSearchSSE = subscribeToSearchSSE(
      currentMatchScoutSearchId,
      LineupTabs.MatchScout,
      openedSections.current.matchScout
    );

    return () => {
      unsubscribeParentSearchSSE();
      unsubscribeMatchMinerSearchSSE();
      unsubscribeMatchScoutSearchSSE();
    };
  }, [
    isPassiveView,
    currentTab,
    isMatchMinerView,
    isPassiveSearchEnabled,
    currentCandidateProfileId,
    currentSearchId,
    dataSourceId,
    jobDescriptionId,
    subscribeToCandidatesProcessingSSE,
    fetchSearch,
    currentSearchAppliedCount,
    loadAllCandidates,
    fetchAndUpdateCandidateInState,
    handleCandidateStatusUpdate,
    candidateNameQuery,
    dropAllCandidates,
    removeCandidatesFromCandidatesList,
    currentMatchMinerSearchId,
    matchMinerPassiveTotalCount,
    currentSearchPassiveTotalCount,
    updateSearchCandidatesProfileSize,
    openedSections,
    currentMatchScoutSearchId,
  ]);
};
