import React, { useCallback } from 'react';
import {
  RouteComponentProps,
  Redirect,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';
import { TabsControl, Header } from '@air/components';
import styles from './SettingsSection.css';
import { UserManagementView } from 'features/SettingsSection/UserManagementView';
import { GeneralSettingsView } from 'features/SettingsSection/GeneralSettingsView';

// component proptypes
type Props = {
  isReadOnly?: boolean;
} & RouteComponentProps;

const tabItems = [
  {
    label: phrases.GENERAL_TAB,
    value: urls.GENERAL_SETTINGS_ROUTE,
  },
  {
    label: phrases.USER_MANAGEMENT_TAB,
    value: urls.USER_MANAGEMENT_ROUTE,
  },
];

export const SettingsSection: React.FC<Props> = ({ isReadOnly }) => {
  const location = useLocation();
  const history = useHistory();

  const onTabChange = useCallback(
    (newTabUrl) => {
      history.replace(newTabUrl);
    },
    [history]
  );

  return (
    <div className={styles.settingsView}>
      <Header bolder level={1} className={styles.settingsHeader}>
        {phrases.SETTINGS_HEADER}
      </Header>
      <div className={styles.tabsContainer}>
        <TabsControl
          items={tabItems}
          selectedTab={location.pathname}
          onChange={onTabChange}
        />
      </div>
      <Route exact path={urls.GENERAL_SETTINGS_ROUTE}>
        <GeneralSettingsView isReadOnly={isReadOnly} />
      </Route>
      <Route exact path={urls.USER_MANAGEMENT_ROUTE}>
        <UserManagementView isReadOnly={isReadOnly} />
      </Route>
      <Redirect from={urls.SETTINGS_ROUTE} to={urls.GENERAL_SETTINGS_ROUTE} />
    </div>
  );
};
SettingsSection.displayName = 'SettingsSection';
