import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { toast } from '@air/third-party/toast';
import { ENTITY_NOT_FOUND } from '@air/constants/httpCodes';
import { JobsContext, JobsContextT, JobsListT, KanbanContextT } from 'context';
import { JobDescriptionFullResponse, NotificationEvent } from '@air/api/models';
import { SSEConnectionErrorEvent } from '@air/lib/server-notifications/Connection';
import * as urls from 'constants/urls';
import * as JobsApi from 'features/JobsSection/jobsApi';
import { Task } from '@air/utils/fp';
import { REQUEST_PAGING_SIZE_KANBAN } from 'constants/app';
import * as phrases from 'constants/phrases';
import {
  ATSJobsConsumer,
  isATSNofiticationEvent,
} from 'domain/Kanban/ServerSideEventsConsumers';
import {
  aggregateTimeout,
  MessageConsumer,
  registerSSESubscriber,
} from '@air/lib/server-notifications';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';

export const EMPTY_JOB: JobDescriptionFullResponse = {
  approvers: [],
  opened: 0,
  owners: [],
  status: '',
  title: '',
  description: '',
  externalId: '',
  appliedCount: null,
  locations: [],
  appliedPassiveCount: 0,
};

type State = {
  jobsList: JobsListT;
  jobDescriptions: { [key: string]: JobDescriptionFullResponse };
  jobRequisitionDetails: JobDescriptionFullResponse;
  searchParams: {
    atsId: number;
    searchFilter: string;
    page: number;
    size: number;
  };
};

type JobsProviderPropsT = RouteChildrenProps & {
  atsId: number;
  contextValue?: KanbanContextT;
};

export class JobsProvider extends React.Component<JobsProviderPropsT, State> {
  state: State = {
    jobsList: { items: [], total: 0, loaded: false },
    jobDescriptions: {},
    jobRequisitionDetails: null,
    searchParams: null,
  };

  private sseConsumers: {
    jobs: ATSJobsConsumer;
  };
  subscriptions: (() => void)[];

  constructor(props: JobsProviderPropsT) {
    super(props);

    /*
      Any SSE Event related to external ATS triggers refetching of jobs list
      with latest search params.
      This is much easier than trying to handle each individual job update manually,
      which doesn't give much profit.
    */
    registerSSESubscriber(
      (event: NotificationEvent | SSEConnectionErrorEvent) => {
        if (isATSNofiticationEvent(event)) {
          return this.sseConsumers.jobs.onEventReceived(event);
        }
      }
    );

    this.sseConsumers = {
      jobs: new MessageConsumer(aggregateTimeout(1000)),
    };
    this.subscriptions = [];
    this.subscriptions.push(
      this.sseConsumers.jobs.subscribe(() =>
        this.fetchJobsList(this.state.searchParams)
      )
    );
  }

  componentWillUnmount() {
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }
  }

  redirectToRoot = (error: any) => {
    if (error.status && error.status === ENTITY_NOT_FOUND) {
      this.props.history.push(urls.ROOT_ROUTE);
    }
  };

  fetchJobsList = (params?: { page: number; size?: number }) => {
    const { atsId, contextValue } = this.props;
    if (!atsId || !params) return;
    const { page = 0, size } = params;
    const { jobsList, jobDescriptions } = this.state;

    if (page === 0 && jobsList.items.length > 0) {
      this.setState({
        jobDescriptions: {},
      });
    }
    const searchParams = {
      atsId,
      searchFilter: contextValue.currentSearchFilter?.name,
      page: 0,
      size: size || (page + 1) * REQUEST_PAGING_SIZE_KANBAN,
    };

    const atsFetchTask: Task = Task.of(searchParams);

    return atsFetchTask.chain(JobsApi.fetchJobList).fork(
      () => {
        toast.error(phrases.ERROR_JOB_LISTING);
        this.setState({
          jobsList,
          jobDescriptions,
        });
      },
      (jobsListResponse: JobsListT) => {
        this.setState(() => ({
          jobsList: jobsListResponse,
          searchParams,
        }));
      }
    );
  };

  fetchJobDescription = (atsId: number | string, jdId: string) => {
    const { jobDescriptions } = this.state;

    if (jobDescriptions[jdId]) {
      this.setState({
        jobRequisitionDetails: jobDescriptions[jdId],
      });
      return;
    }

    JobsApi.fetchJobDescription(atsId, jdId).fork(
      this.redirectToRoot,
      (res: JobDescriptionFullResponse) => {
        const normalizedJobDescription = {
          ...res,
          description: res?.description?.trim(),
        };
        this.setState((prevState) => ({
          jobRequisitionDetails: normalizedJobDescription,
          jobDescriptions: {
            ...prevState.jobDescriptions,
            [normalizedJobDescription.externalId]: normalizedJobDescription,
          },
        }));
      }
    );
  };

  clearJobDescription = () => {
    this.setState({
      jobRequisitionDetails: null,
    });
  };

  // removing from local state
  removeJobDescription = (jobId: string) => {
    const { jobsList } = this.state;
    this.setState({
      jobsList: {
        items: jobsList.items.filter((job) => job.externalId !== jobId),
        total: jobsList.total - 1,
        loaded: true,
      },
    });
  };

  getContext = (): JobsContextT => {
    const { jobsList, jobRequisitionDetails } = this.state;

    return {
      jobsList,
      jobRequisitionDetails,
      methods: {
        fetchJobDescription: this.fetchJobDescription,
        clearJobDescription: this.clearJobDescription,
        fetchJobsList: this.fetchJobsList,
        removeJobDescription: this.removeJobDescription,
      },
    };
  };

  render() {
    const { children } = this.props;

    return (
      <JobsContext.Provider value={this.getContext()}>
        {children}
      </JobsContext.Provider>
    );
  }
}

export const useJobsProviderContext = <Selected,>(
  selector: (state: JobsContextT) => Selected
) => {
  return useContextSelector(JobsContext, selector);
};

export const useJobsProviderMethods = () => {
  return useEqualContextSelector(
    JobsContext,
    (state: JobsContextT) => state.methods,
    R.shallowEqualObjects
  );
};
