import React, {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useMemo,
} from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';

import styles from './Button.css';
import { useDebounce } from '@air/utils/hooks';
import { SvgIcon, UIText } from '@air/components';
import { Loader, LoaderImages } from '@air/components/Loader/Loader';

export enum ButtonVariants {
  POSITIVE_MAIN = 'positiveMain',
  POSITIVE_SECONDARY = 'positiveSecondary',
  POSITIVE_CONFIRM = 'positiveConfirm',
  DEFAULT = 'default',
  NEGATIVE_CONFIRM = 'negativeConfirm',
  NEGATIVE_MAIN = 'negativeMain',
  NEGATIVE_SECONDARY = 'negativeSecondary',
  EXECUTED_ACTION = 'executedAction',
  CHIP = 'chip',
  CHIP_BLUE = 'chipBlue',
  TINY = 'tiny',
  INLINE = 'inline',
  CLOSE = 'close',
  EXPANDING = 'expanding',
}

export enum ButtonLoaderPositions {
  LEFT = 'left',
  RIGHT = 'right',
  FIXED_LEFT = 'fixed-left',
  FIXED_RIGHT = 'fixed-right',
  FIXED_CENTER = 'fixed-center',
}

type Props = {
  debounceTime?: number;
  variant?: ButtonVariants;
  loaderPosition?: ButtonLoaderPositions;
  small?: boolean;
  alignCenter?: boolean;
  icon?: string;
  iconClassName?: string;
  innerTextClassName?: string;
  counter?: number;
  isLoading?: boolean;
  wide?: boolean;
  loaderColor?: keyof typeof LoaderImages;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type ButtonT = ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLButtonElement>
> & {
  variants?: typeof ButtonVariants;
  loaderPosition?: typeof ButtonLoaderPositions;
};

export const Button: ButtonT = React.forwardRef(
  (
    {
      debounceTime,
      disabled,
      children,
      className = '',
      onClick,
      variant = ButtonVariants.POSITIVE_MAIN,
      small,
      icon,
      iconClassName,
      innerTextClassName,
      alignCenter,
      counter,
      isLoading,
      loaderPosition = ButtonLoaderPositions.FIXED_RIGHT,
      loaderColor = 'grey',
      wide = false,
      ...props
    },
    ref
  ) => {
    const debouncedOnClick = useDebounce(onClick, debounceTime, true);

    const onClickCallback = debounceTime > 0 ? debouncedOnClick : onClick;

    const innerTextStyle = useMemo(() => {
      switch (variant) {
        case ButtonVariants.POSITIVE_CONFIRM:
        case ButtonVariants.POSITIVE_MAIN:
        case ButtonVariants.NEGATIVE_CONFIRM:
        case ButtonVariants.NEGATIVE_MAIN:
          return { bold: true };
        case ButtonVariants.CHIP:
          return { small: true };
        case ButtonVariants.TINY:
          return { small: true, wide: true };
        default:
          return {};
      }
    }, [variant]);

    const withPrefixContainer = !(R.isNil(icon) && R.isNil(counter));

    return (
      <button
        ref={ref}
        type="button"
        {...props}
        disabled={disabled}
        onClick={isLoading ? R.preventDefaultNoop : onClickCallback}
        className={classNames(styles.button, className, {
          [styles.disabled]: !!disabled,
          [styles.alignCenter]: alignCenter,
          [styles.isLoading]: isLoading,
          [styles.loaderLeft]: loaderPosition === ButtonLoaderPositions.LEFT,
          [styles.loaderRight]: loaderPosition === ButtonLoaderPositions.RIGHT,
          [styles.loaderFixedLeft]:
            loaderPosition === ButtonLoaderPositions.FIXED_LEFT,
          [styles.loaderFixedRight]:
            loaderPosition === ButtonLoaderPositions.FIXED_RIGHT,
          [styles.loaderFixedCenter]:
            loaderPosition === ButtonLoaderPositions.FIXED_CENTER,
          [styles.positiveMain]: variant === ButtonVariants.POSITIVE_MAIN,
          [styles.positiveConfirm]: variant === ButtonVariants.POSITIVE_CONFIRM,
          [styles.positiveSecondary]:
            variant === ButtonVariants.POSITIVE_SECONDARY,
          [styles.default]: variant === ButtonVariants.DEFAULT,
          [styles.negativeMain]: variant === ButtonVariants.NEGATIVE_MAIN,
          [styles.negativeSecondary]:
            variant === ButtonVariants.NEGATIVE_SECONDARY,
          [styles.negativeConfirm]: variant === ButtonVariants.NEGATIVE_CONFIRM,
          [styles.executedAction]: variant === ButtonVariants.EXECUTED_ACTION,
          [styles.chip]: variant === ButtonVariants.CHIP,
          [styles.chipBlue]: variant === ButtonVariants.CHIP_BLUE,
          [styles.tiny]: variant === ButtonVariants.TINY,
          [styles.smallButton]: small,
          [styles.inline]: variant === ButtonVariants.INLINE,
          [styles.withIcon]: !!icon,
          [styles.withCounter]: !R.isNil(counter),
          [styles.closeButton]: variant === ButtonVariants.CLOSE,
          [styles.expanding]: variant === ButtonVariants.EXPANDING,
          [styles.wide]: wide,
        })}
      >
        {(variant === ButtonVariants.CHIP ||
          variant === ButtonVariants.CHIP_BLUE) && (
          <SvgIcon
            icon="plus"
            className={classNames(styles.chipButtonIcon, {
              [styles.chipButtonIconDisabled]: disabled,
            })}
          />
        )}
        {withPrefixContainer && (
          <span className={styles.prefixContainer}>
            {!!icon && (
              <SvgIcon
                icon={icon}
                className={classNames(styles.buttonIcon, iconClassName)}
              />
            )}
            {!!counter && (
              <UIText className={styles.buttonCounter}>{counter}</UIText>
            )}
          </span>
        )}
        {!!children && (
          <UIText
            small={small}
            {...innerTextStyle}
            className={classNames(innerTextClassName, {
              [styles.withPrefix]: withPrefixContainer,
            })}
          >
            {children}
          </UIText>
        )}
        {isLoading && <Loader className={styles.loader} color={loaderColor} />}
      </button>
    );
  }
);

Button.variants = ButtonVariants;
Button.loaderPosition = ButtonLoaderPositions;
