import React from 'react';
import classNames from 'classnames';
import * as phrases from 'constants/phrases';
import styles from './LoadingTextPlaceholder.css';

export const LoadingTextPlaceholder: React.FC<{
  className?: string;
  text?: string;
}> = ({ className, text = phrases.LOADING_PLACEHOLDER }) => {
  return (
    <div className={classNames(className, styles.loadingText)}>{text}</div>
  );
};
LoadingTextPlaceholder.displayName = 'LoadingTextPlaceholder';
