// imports from vendor deps
import React from 'react';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
import { Button, TooltipWrapper } from '@air/components';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'

// imports from styles
import styles from './OverlapPageControls.css';

// component proptypes
type OpenButtonT = React.FC<{
  icon?: string;
  className?: string;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
  onClick: () => void;
}>;
const OpenButton: OpenButtonT = ({
  icon = 'hamburger-icon',
  onClick,
  className,
  label,
  disabled,
  tooltip,
}) => {
  return (
    <TooltipWrapper
      enabled={!!tooltip}
      placement="top"
      trigger="hover"
      tooltip={tooltip}
      disabledChildren={disabled}
    >
      <Button
        disabled={disabled}
        variant={Button.variants.TINY}
        icon={icon}
        onClick={onClick}
        className={className}
      >
        {label ? label : null}
      </Button>
    </TooltipWrapper>
  );
};
OpenButton.displayName = 'OverlapPageControlsOpenButton';

// exports / class definitions
export const OverlapPageControls: React.FC<{
  onClose?: () => void;
  className?: string;
  buttonWrapperClassName?: string;
}> & {
  OpenButton: OpenButtonT;
} = ({ onClose = () => {}, className, buttonWrapperClassName }) => {
  return (
    <div className={classNames(styles.overlapPageControls, className)}>
      {!!onClose && (
        <div
          className={classNames(styles.closeButton, buttonWrapperClassName)}
          onClick={onClose}
        >
          <Button
            variant={Button.variants.CLOSE}
            icon="close-icon-2"
            className={className}
          />
        </div>
      )}
    </div>
  );
};

OverlapPageControls.OpenButton = OpenButton;
OverlapPageControls.displayName = 'OverlapPageControls';
