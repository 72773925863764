import React, { PropsWithChildren } from 'react';
import { RoleName } from '@air/api';

/**
 * TODO: features nice to have:
 *   - rules for accessing
 *   - "fallback" components, to show something if role is not matching
 */
export const useRole = (userRole: RoleName) => {
  return (role: RoleName): boolean => {
    return userRole === role;
  };
};

export type HasAccessCallback<T> = (context: T) => boolean;

type Props<T> = {
  hasAccess: HasAccessCallback<T> | boolean;
  userContext?: T;
};

export const CanAccess = <T,>(props: PropsWithChildren<Props<T>>) => {
  const { hasAccess, userContext, children } = props;

  if (typeof hasAccess === 'function' && hasAccess(userContext)) {
    return <>{children}</>;
  } else if (typeof hasAccess === 'boolean' && hasAccess) {
    return <>{children}</>;
  } else {
    return null;
  }
};
CanAccess.displayName = 'CanAccess';
