import React, { useState, useCallback } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

import styles from './Toggle.css';

type Props = {
  onChange: (value: boolean | React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  className?: string;
  name?: string;
};

export const Toggle = (props: Props) => {
  const {
    onChange = R.noop,
    disabled,
    className,
    name,
    defaultChecked = false,
    checked,
    ...rest
  } = props;
  const [isEnabled, setEnabled] = useState(defaultChecked);
  const isControlledInput = !R.isNil(checked);
  const inputProps = isControlledInput ? { checked } : { defaultChecked };

  const onChangeCb = useCallback(
    (data) => {
      if (isControlledInput) {
        onChange(data);
      } else {
        setEnabled((enabled) => {
          onChange(!enabled);
          return !enabled;
        });
      }
    },
    [onChange, isControlledInput]
  );

  return (
    <div
      className={classNames(styles.toggle, className, {
        [styles.togglePressed]: isControlledInput ? checked : isEnabled,
        [styles.disabled]: disabled,
      })}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        onChange={onChangeCb}
        disabled={disabled}
        {...inputProps}
        {...rest}
      />
    </div>
  );
};
