import React, { LegacyRef, Ref, useCallback } from 'react';
import classNames from 'classnames';
import {
  Button,
  MaskedFormField,
  Paragraph,
  TooltipWrapper,
} from '@air/components';
import { Controller, useForm } from 'react-hook-form';

import * as phrases from 'constants/phrases';
import { FormFieldTypes } from '@air/components/Form/utils';
import { isNumberValid } from '@air/utils/strings';
import { useOutsideClick } from '@air/utils/hooks';
import { useMatchServiceLoadMore } from 'domain/MatchServices/useMatchServiceSettings';
import styles from './LoadMoreApplicantsSection.css';
import { LineupTabs } from '@air/constants/tabs';

interface Props {
  className?: string;
  disabled?: boolean;
  currentTab: LineupTabs;
}

export const LoadMoreApplicantsButton: React.FC<Props> = ({
  className,
  disabled,
  currentTab,
}) => {
  const {
    loadMoreApplicants,
    areCandidatesLoading,
    tooltipText,
    shouldHideLoadMoreButton,
    isButtonDisabled,
    closePopup,
    requestMoreAvailableCount,
    showLoadMoreTooltip,
    onClick,
  } = useMatchServiceLoadMore({ currentTab, disabled });

  const [popupRef] = useOutsideClick(closePopup, { useCapture: true });

  if (shouldHideLoadMoreButton) return null;

  const tooltipBody = !showLoadMoreTooltip ? (
    tooltipText
  ) : (
    <RequestMoreFormPopup
      ref={popupRef}
      onRequestMore={loadMoreApplicants}
      availableCount={requestMoreAvailableCount}
      onClose={closePopup}
    />
  );

  return (
    <TooltipWrapper
      containerClassName={classNames(styles.tooltipContainer, {
        [styles.requestMoreFormShown]: showLoadMoreTooltip,
      })}
      tooltip={tooltipBody}
      enabled={!areCandidatesLoading}
      placement="bottom"
      tooltipShown={showLoadMoreTooltip || undefined}
    >
      <Button
        isLoading={areCandidatesLoading}
        loaderColor="white"
        loaderPosition={Button.loaderPosition.FIXED_CENTER}
        variant={Button.variants.CHIP_BLUE}
        onClick={onClick}
        disabled={isButtonDisabled}
        className={classNames(className)}
      >
        {phrases.REQUEST_MORE_CANDIDATES_TEXT}
      </Button>
    </TooltipWrapper>
  );
};
LoadMoreApplicantsButton.displayName = 'LoadMoreApplicantsButton';

const LIMIT_FIELD = 'limit';
const RequestMoreFormPopup: React.FC<{
  onClose: () => void;
  onRequestMore: (count: number) => void;
  availableCount: number;
  ref?: Ref<HTMLFormElement>;
}> = React.forwardRef(({ onClose, availableCount, onRequestMore }, ref) => {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<Record<string, any>>({
    shouldUnregister: true,
    mode: 'onChange',
    defaultValues: {
      [LIMIT_FIELD]: '',
    },
  });
  const limit = watch(LIMIT_FIELD);
  const hasLimitError = !!errors[LIMIT_FIELD];
  const onCountClick = () => {
    reset({
      [LIMIT_FIELD]: availableCount,
    });
  };

  const onSubmit = useCallback(
    ({ [LIMIT_FIELD]: limit }: { [LIMIT_FIELD]: number }) => {
      onRequestMore(limit);
      onClose();
    },
    [onRequestMore, onClose]
  );

  return (
    <form
      ref={ref as LegacyRef<HTMLFormElement>}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.requestMoreFormTooltip}
    >
      <header className={styles.requestMoreFormTooltipHeader}>
        <Paragraph small>
          {phrases.REQUEST_MORE_TOOLTIP_TEXT[0]}
          <br />
          <span
            onClick={onCountClick}
            className={classNames(styles.remainingCount, {
              [styles.remainingCountDisabled]: +availableCount === +limit,
            })}
          >
            +{availableCount}
          </span>
          {phrases.REQUEST_MORE_TOOLTIP_TEXT[1]}
        </Paragraph>
        <Button
          variant={Button.variants.CLOSE}
          icon="close-icon-2"
          onClick={onClose}
        />
      </header>
      <Controller
        name={LIMIT_FIELD}
        control={control}
        rules={{
          required: true,
          validate: isNumberValid(availableCount),
        }}
        render={({ field }) => (
          <MaskedFormField
            id={LIMIT_FIELD}
            mask="9999"
            type={FormFieldTypes.text}
            label={phrases.REQUEST_MORE_TOOLTIP_PLACEHOLDER}
            hasError={hasLimitError}
            className={styles.limitField}
            {...field}
          />
        )}
      />
      <Button
        type="submit"
        small
        alignCenter
        disabled={!limit || hasLimitError}
        variant={Button.variants.POSITIVE_CONFIRM}
      >
        {phrases.REQUEST_MORE_CANDIDATES_TEXT}
      </Button>
    </form>
  );
});
