import React from 'react';
import classNames from 'classnames';
import { SvgIcon, TooltipWrapper } from '@air/components';
import * as phrases from 'constants/phrases';
import { ICON_SVGs } from 'components/Cards/KanbanCard/svgs/svgs';
import { SearchCountResponseV2 } from '@air/api';
import shortNumber from 'short-number';

import styles from './Stats.css';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';

const COUNTERS_SECTIONS = ['selected', 'matched', 'pending', 'rejected'];

export const Stats: React.FC<{
  counters: SearchCountResponseV2;
  title: string;
  isActiveCountersSection?: boolean;
  hideCountersBreakdown?: boolean;
}> = (props) => {
  const { counters, title, isActiveCountersSection, hideCountersBreakdown } =
    props;

  return (
    <div
      className={classNames(styles.stats, {
        [styles.tall]: !hideCountersBreakdown,
      })}
    >
      <div className={classNames(styles.applicantCounter, styles.counter)}>
        {isActiveCountersSection && !hideCountersBreakdown && (
          <SvgIcon icon="applicant" className={styles.icon} />
        )}
        <div className={classNames(styles.text)}>
          <span className={styles.label}>{title}: </span>
          {shortNumber(counters.totalCount || 0)}
        </div>
      </div>

      {!hideCountersBreakdown && (
        <ul className={styles.countersList}>
          {COUNTERS_SECTIONS.map((name, index) => {
            const computedName = `KANBAN_CARD_TOOLTIP_MESSAGE_${name.toUpperCase()}`;
            // @ts-ignore ignoring the need to parametrise phrases import
            const tooltipMessage = phrases[computedName];

            return (
              <li
                className={classNames(styles.counter, styles[name])}
                key={index}
              >
                <TooltipWrapper
                  enabled
                  delayShow={TOOLTIP_DELAY_TIME_LONG}
                  tooltip={tooltipMessage}
                  placement="bottom"
                >
                  {isActiveCountersSection && (
                    <span className={styles.icon}>
                      {ICON_SVGs[name as keyof typeof ICON_SVGs]}
                    </span>
                  )}
                  <span className={styles.text}>
                    {shortNumber(
                      counters[`${name}Count` as keyof SearchCountResponseV2] ||
                        0
                    )}
                  </span>
                </TooltipWrapper>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
