/*
Common Text Constants
 */

import R from '@air/third-party/ramda';

export const LOADING = 'Loading...';
export const BY_COMPANY = 'by Employa';

export const MODIFY = 'Modify';
export const VIEW = 'View';
export const ADMINISTRATOR = 'Administrator';
export const CONFIRM = 'Confirm';
export const CANCEL = 'Cancel';
export const OK = 'Ok';
export const DISMISS = 'Dismiss';
export const WARNING = 'Warning';
export const DISMISSED = 'Dismissed';
export const RE_APPLY = 'Re-Apply';
export const EMAIL = 'Email';

export const BUTTON_BACK = 'Back';
export const BUTTON_NEXT = 'Next';
export const BUTTON_START = 'Start';
export const BUTTON_FINISH = 'Finish';

export const ANY_EXPERIENCE = 'Any experience';
export const EMPTY_EQUIVALENT_EXPERIENCE_TEXT = `Years of\nexperience`;

export const RECENT_EXPERIENCE_ANY_RECENCY_LABEL = 'any recency';
export const EXPERIENCE_NUMBER_OF_YEARS = R.textByCount('year', 'years');
export const EXPERIENCE_NUMBER_OF_MONTHS = R.textByCount('month', 'months');

export const MAX_DISTANCE_TOOLTIP_TEXT = 'Applies to cities and states only';
export const MAX_DISTANCE_SELECTED_VALUE_LABEL = 'miles';
export const MAX_DISTANCE_NOTHING_SELECTED_LABEL = [`Max\nDistance`, '(mi)'];

export const EXPERIENCE_LESS_THAN_MONTH = '< 1 month';

export const PERIOD_TYPE_DURATION = 'duration';
export const PERIOD_TYPE_EXPERIENCE = 'experience';
export const getApproximateExperienceTooltipText = (periodType: string) => {
  return `Calculated ${periodType} is approximate due to missing years or months in employment records`;
};

export const COMPANY_SIZE_TYPE_EMPLOYEES = 'Employees';

export const RED_FLAG_UNIMPRESSIVE_SKILLS = 'Trivial skills';
export const RED_FLAG_BUZZWORDS = 'Buzzwords';
export const RED_FLAG_VAGUE_WORDS = 'Vague words';
export const RED_FLAG_TOO_LONG_IN_COMPANY = 'Long-term employment';
export const RED_FLAG_MISSED_MONTHS = 'Missing months';
export const RED_FLAG_GAPS_IN_HISTORY = 'Employment gaps';
export const RED_FLAG_DIPLOMA_MILL = 'Diploma Mill';
export const RED_FLAG_TOO_MANY_SKILLS = 'Skill Overflow';
export const RED_FLAG_TOO_MANY_JOBS = 'Job Hopping';
export const RED_FLAG_UNKNOWN_UNIVERSITY = 'Unknown\nuniversity';
export const RED_FLAG_NO_CAREER_PROGRESS = 'No career progress';
export const RED_FLAG_UNKNOWN_COMPANY = 'Unknown company';
export const RED_FLAG_EDUCATION_DISCREPANCY = 'Education discrepancy';
export const RED_FLAG_EMPLOYMENT_DISCREPANCY = 'Employment\nDiscrepancy';
export const RED_FLAG_DESCRIPTIONS_TOO_LONG = 'Descriptions too long';

export const MISSING_DATE = 'DATE?';
export const MISSING_YEAR = 'YEAR?';
export const MISSING_MONTH = 'MMM?';
export const GRADUATED_OR_NOT = 'Graduated?';

export const WARNING_CARD_EMPLOYMENT_GAPS_DURATION = 'Duration?';

export const UNDEFINED_VALUE = 'Undefined';
export const UNDEFINED_DATES = 'Undefined dates';
export const PRESENT_DATE = 'Present';
export const COMPANY_NAME_PLACEHOLDER = 'Undefined Company';
export const JOB_TITLE_NAME_PLACEHOLDER = 'Unknown Role';

export const SAME_LEVEL_POSITION = 'Same level position';
export const SENIORITY_DOWNGRADE = 'Seniority downgrade';

/*
  @todo:
  move this block of phrases to customer-portal workspace
*/
export const LOGOUT_BUTTON_TEXT = 'Logout';
export const LOGIN_BUTTON_TEXT = 'Login';
export const CREATE_PASSWORD_BUTTON_TEXT = 'Create\nnew password';
export const LOGIN_FORM_TITLE = 'Login';
export const CREATE_PASSWORD_FAILURE_NOTIFICATION =
  'Password creation failed. Please, try again later';
export const PASSWORD_CREATED_LOGIN_TITLE = 'Password created';
export const CREATE_PASSWORD_FORM_TITLE = 'Create Password';
export const SET_PASSWORD_FORM_TITLE = 'Set Password';
export const SIGNUP_NEXT_STEP_BUTTON = 'Next step';
export const getSignupStep = (step: number) => `Step ${step}`;
export const ATS_INTEGRATION_FORM_TITLE = 'ATS Integration';
export const ATS_INTEGRATION_FORM_DESCRIPTION =
  'Let Employa connect to your applicant tracking system. Don’t have any of the listed ATSs? No problem! We have our internal tracking system.';
export const ATS_DROPDOWN_PLACEHOLDER = 'Choose your ATS';
export const ATS_DROPDOWN_EMPLOYA_LABEL = 'I don’t have ATS (use internal)';
export const ATS_INTEGRATION_REQUEST_FAILURE =
  'Unable to setup ATS integration. Please, try again later';
export const ATS_INTEGRATION_SUCCESS_BUTTON_TEXT = 'Start';
export const ATS_INTEGRATION_RETRY_BUTTON_TEXT = 'Try again';
export const getATSIntergrationFormTitle = (atsName: string) =>
  `Connect to ${atsName}`;

export const SAP_ATS_INTEGRATION_FORM_DESCRIPTION = 'Enter admin username and password of your company account';
export const SAP_USERNAME_FIELD_LABEL = 'Username';
export const SAP_PASSWORD_FIELD_LABEL = 'Password';
export const SAP_COMPANY_ID_FIELD_LABEL = 'Company ID';
export const SAP_LINK_TO_ATS_FIELD_LABEL = 'Link to ATS';
export const SAP_LINK_TO_API_FIELD_LABEL = 'Link to API';
export const SAP_ATS_INTEGRATION_FAILURE_TITLE = 'Something went wrong. Please try again and if the error persists, please contact Employa Support support@employa.com';

export const GREENHOUSE_ATS_INTEGRATION_FORM_DESCRIPTION =
  'Enter your harvest API key. Read more about it and where to find it on <a href="https://support.greenhouse.io/hc/en-us/articles/115000521723-Manage-Harvest-API-key-permissions" target="_blank" rel="noopener noreferrer">Greenhouse support</a> website.';
export const ATS_INTEGRATION_SUCCESS_TITLE = 'Almost done!';
export const getATSIntergrationSuccessDescription = (atsName: string) =>
  `Thank you for enabling the ${atsName} integration. Your organization’s setup in Employa will be finished soon. Once it’s complete, the jobs, applicants and users from ${atsName} will appear in the Employa.`;
export const ATS_INTEGRATION_FAILURE_TITLE = 'Oops...';
export const getATSIntegrationFailureDescription = (atsName: string) =>
  `Something went wrong. Please try again and if the error persists, please contact ${atsName} support.`;
export const GREENHOUSE_API_KEY_FIELD_LABEL = 'HARVEST API KEY';
export const TALEO_ATS_INTEGRATION_FORM_DESCRIPTION =
  'Enter admin username and password of your company account.';
export const TALEO_USERNAME_FIELD_LABEL = 'Username';
export const TALEO_PASSWORD_FIELD_LABEL = 'Password';
export const TALEO_COMPANY_CODE_FIELD_LABEL = 'Company code';
export const TALEO_BASE_URL_FIELD_LABEL = 'Link to ATS';
export const ATS_CONNECT_BUTTON = 'Connect';
export const ATS_CONNECTION_PLACEHOLDER = 'Connecting';

export const LOGIN_FORM_DESCRIPTION = 'Please login here in order to start';
export const getCreatePasswordFormDescription = (email = '') =>
  `You joined as <b>${email}</b>`;
export const SHOW_PASSWORD = 'Show password';
export const RESET_PASSWORD_TEXT = 'Forgot password?';
export const PASSWORD_HINT_TEXT =
  'Use 8 or more characters with a mix of uppercase and lowercase letters & numbers';
export const LABEL_EMAIL = 'email';
export const LABEL_PASSWORD = 'password';
export const LABEL_CONFIRM_PASSWORD = 'confirm password';
export const FORM_ERROR_NO_VALUE = 'Please enter a value';
export const FORM_ERROR_INVALID_EMAIL = 'Please enter a valid email';
export const PASSWORD_ERROR_WEAK_VALUE = 'Weak password';
export const PASSWORD_STRONG_VALUE = 'Strong password';
export const PASSWORD_ERROR_LONG_VALUE = 'Password too long';
export const CONFIRM_PASSWORD_HINT_TEXT = `Passwords don't match`;
export const PASSWORD_REGEX_HINT =
  '8-100 chars length with at least 1 digit, 1 uppercase and 1 lowercase letter.';

export const RESET_PASSWORD_FORM_TITLE = 'Reset password';
export const RESET_PASSWORD_EMAIL_TEXT = 'email';
export const TO_LOGIN_TEXT = 'Login';
export const RESET_PASSWORD_FORM_DESCRIPTION =
  'Please enter your email, and if it exists in database, you will receive an email with a password reset link';
export const RESET_PASSWORD_ERROR = 'Invalid email';
export const RESET_PASSWORD_EMPTY_EMAIL_ERROR = 'Please enter your email';
export const RESET_PASSWORD_BUTTON_TEXT = 'Send\nreset link';
export const GENERAL_ERROR_TRY_AGAIN =
  'Oops, something went wrong. Please try again later.';

export const RESET_PASSWORD_REQUEST_TITLE = 'Password reset requested';
export const RESET_PASSWORD_REQUEST_DESCRIPTION = (email = '') =>
  `Check <b>${email}</b> inbox for the email.<br/>It's in the main mailbox, or it could also get stuck in the spam folder. If there is no email, make sure your email is correct and request the link again`;
export const RESET_PASSWORD_REQUEST_BUTTON_TEXT = 'Request new password reset';

// notifications
export const ERROR_USER_PROFILE = 'Error fetching user profile';
export const ERROR_JOB_DESCRIPTION = 'Error fetching job description';
export const UNAUTHORIZED_ERROR_MESSAGE =
  'An account with such credentials is not found.';
export const APPLICATION_UPDATE_NOTIFICATION =
  'Application was recently updated and requires page reload.';
export const APPLICATION_RELOAD = 'Reload application';

// General buttons
export const HIDE = 'Hide';
export const OPEN = 'Open';
export const GO_HOME = 'Go home';
export const CLOSE = 'Close';

// select
export const NO_OPTIONS_DEFAULT_MESSAGE = 'No options';
export const NO_RESULTS_FOUND_MESSAGE = 'No results for your search request';

// search card alerts
export const ALERT_MISSING = 'Missing';
export const ALERT_LACK_OF_INFORMATION = 'Lack of information';
export const ALERT_EXPERIENCE_DISCREPANCY = 'Experience discrepancy';
export const ALERT_NOT_RECENT = 'Not recent';
export const ALERT_OVER_QUALIFIED = `Over-\nqualified`;
export const ALERT_LACK_OF_EXPERIENCE = `Lack of experience`;
export const ALERT_INSUFFICIENT = `Insufficient`;
export const ALERT_NO_PRACTICAL_EXPERIENCE = `No practical experience`;
export const ALERT_NON_PRIMARY_LANGUAGE = `Non-primary language`;
export const ALERT_NO_DETAILS = `No details`;
export const ALERT_MISMATCH = `Mismatch`;
export const ALERT_GRADUATED = `Graduated?`;
export const ALERT_EXPECTED = `Expected`;
export const ALERT_EXCLUDED_COMPANY = `Excluded company`;
export const ALERT_TEAM_IS_TOO_SMALL = `Team is too small`;
export const ALERT_TEAM_IS_TOO_BIG = `Team is too big`;
export const ALERT_TEAM_SIZE_IS_MISSING = `Team size is missing`;
export const ALERT_TOO_FAR = `Too far`;
export const ALERT_MISSING_CITY = `Missing city`;
export const ALERT_MISSING_STATE = `Missing state`;

export const ROLE_CATEGORY_TOOLTIP_TEXT =
  'This role group includes the following roles:';

export const INSTITUTION_LIST_TOOLTIP_TEXT =
  'This university list includes the following universities:';

export const MULTI_SELECT_CHECKBOXES_VALUE_ALL = 'All';
export const MULTI_SELECT_CHECKBOXES_VALUE_NOT_SELECTED = 'Not selected';
export const MULTI_SELECT_CHECKBOXES_MORE = 'more';

export const ERROR_JOB_SPECIALIZATIONS_FETCH =
  'Error during getting job specializations';
