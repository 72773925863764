import { Dispatch, useCallback } from 'react';
import { ApiErrorResponse, LoginRequest, RoleName } from '@air/api';
import * as Auth from '@air/utils/auth';
import { UNAUTHORIZED } from '@air/constants/httpCodes';
import R from '@air/third-party/ramda';
import querystring from 'query-string';
import {
  localStore,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  EXPIRES_AT,
} from '@air/domain/WebStorage/webStorage';
import { toast } from '@air/third-party/toast';
import { ERROR_USER_PROFILE } from '@air/constants/phrases';
import { setUserMeta } from '@air/utils/sentry';
import { ActionType } from '@air/domain/Common/ActionType';
import * as phrases from '@air/constants/phrases';
import { AuthReducerAction } from '@air/utils/auth/authReducer';
import { getErrorDescription } from '@air/utils/errorHandling';

export default function useAuthenticateAction<T>(
  profileUrl: string,
  dispatch: Dispatch<AuthReducerAction<T>>
) {
  return useCallback(
    (values: LoginRequest) => {
      dispatch({
        type: ActionType.ClearAuthError,
      });
      return Auth.authenticate(values).fork(
        (reason: ApiErrorResponse & { status: number }) => {
          if (reason.status === UNAUTHORIZED) {
            return dispatch({
              type: ActionType.LoginFailure,
              payload: phrases.UNAUTHORIZED_ERROR_MESSAGE,
            });
          }
          const errorDescription = getErrorDescription(reason);
          if (errorDescription) {
            toast.error(errorDescription, {
              closeButton: false,
            });
          }
          return dispatch({
            type: ActionType.LoginFailure,
          });
        },
        (result: any) => {
          const isApplicantOrAdmin = [
            RoleName.APPLICANT,
            RoleName.ADMINPORTAL,
          ].includes(result.role);
          if (isApplicantOrAdmin) {
            const href =
              result.role === RoleName.APPLICANT
                ? APPLICANT_PORTAL_HOST
                : ADMIN_PORTAL_HOST;
            window.location.href = `//${href}?${querystring.stringify(
              R.pick([ACCESS_TOKEN, REFRESH_TOKEN, EXPIRES_AT], result)
            )}`;
            return result;
          }

          localStore.setItems(result);
          dispatch({ type: ActionType.LoginSuccess });

          return Auth.getUserProfile(profileUrl).fork(
            (error: any) => {
              toast.error(ERROR_USER_PROFILE);
              return error;
            },
            (userProfile: any) => {
              setUserMeta(userProfile);
              dispatch({
                type: ActionType.GotUserProfile,
                payload: userProfile,
              });
              return userProfile;
            }
          );
        }
      );
    },
    [profileUrl, dispatch]
  );
}
