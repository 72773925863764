import {
  FileItemInfo,
  MessageHandler,
  RequisitionId,
  ResumeUploaderEventType,
  UploadMessage,
  UploadMessageAddFiles,
  UploadMessageRemoveFile,
  UploadMessageStopQueue,
  UploadMessageType,
} from 'domain/ResumeUpload/types';
import { MessageQueue } from 'domain/ResumeUpload/MessageQueue';
import { uploadApplicant } from 'domain/Applicants/applicantsApi';
import { createFileFormData } from 'utils/uploads';
import * as httpCodes from '@air/constants/httpCodes';

export enum ResumeUploaderStatus {
  IDLE = 'idle',
  RUNNING = 'running',
  STOPPED = 'stopped',
}

export class MessageConsumer<T extends UploadMessage>
  implements MessageHandler<T>
{
  constructor(private _processEvent: (...args: any[]) => any) {}
  handleEvent(messageData: T['payload']) {
    this._processEvent(messageData);
  }
}

export class ResumeUploader {
  private _files: FileItemInfo[];

  private readonly _addFilesHandler: MessageHandler<UploadMessageAddFiles>;
  private readonly _removeFileHandler: MessageHandler<UploadMessageRemoveFile>;
  private readonly _stopQueueHandler: MessageHandler<UploadMessageStopQueue>;

  private _inputChannel: MessageQueue<any> | null = null;
  private readonly _outputChannel: MessageQueue<any>;
  private _status: ResumeUploaderStatus = ResumeUploaderStatus.IDLE;
  private _currentRequest: { id: string; request: XMLHttpRequest };

  constructor(private _jobRequisitionId: string) {
    this._files = [];
    this._addFilesHandler = new MessageConsumer(
      this.addFilesToQueue.bind(this)
    );
    this._removeFileHandler = new MessageConsumer(
      this.removeFileFromQueue.bind(this)
    );
    this._stopQueueHandler = new MessageConsumer(this.stopQueue.bind(this));

    this._outputChannel = new MessageQueue<any>();
  }

  addMessageQueue(channel: MessageQueue<any>): void {
    this._inputChannel = channel;
    this._inputChannel.addHandler(
      UploadMessageType.ADD_FILES,
      this._addFilesHandler
    );
    this._inputChannel.addHandler(
      UploadMessageType.REMOVE_FILE,
      this._removeFileHandler
    );
    this._inputChannel.addHandler(
      UploadMessageType.STOP_QUEUE,
      this._stopQueueHandler
    );
  }

  isMessageQueueConnected(): boolean {
    return !!this._inputChannel;
  }

  removeHandlers() {
    if (this._inputChannel) {
      this._inputChannel.removeHandler(
        UploadMessageType.ADD_FILES,
        this._addFilesHandler
      );
      this._inputChannel.removeHandler(
        UploadMessageType.REMOVE_FILE,
        this._removeFileHandler
      );
      this._inputChannel.removeHandler(
        UploadMessageType.STOP_QUEUE,
        this._stopQueueHandler
      );
    }
  }

  removeFileFromQueue({
    requisitionId,
    fileId,
  }: {
    requisitionId: string;
    fileId: string;
  }): void {
    if (requisitionId !== this._jobRequisitionId) {
      return;
    }
    if (this._currentRequest?.id === fileId) {
      this._currentRequest.request.abort();
      this._currentRequest = null;
      this.run();
    } else {
      const idx = this._files.findIndex((file) => file.tempId === fileId);
      this._files.splice(idx, 1);
    }
  }

  addFilesToQueue({
    files,
    requisitionId,
  }: {
    requisitionId: string;
    files: FileItemInfo[];
  }): void {
    if (requisitionId !== this._jobRequisitionId) {
      return;
    }
    this._files.unshift(...files);
    if (this._status === ResumeUploaderStatus.IDLE) {
      this._outputChannel.send({
        type: ResumeUploaderEventType.QUEUE_STARTED,
        payload: { requisitionId: this._jobRequisitionId },
      });
      this.run();
    }
  }

  stopQueue(payload?: { requisitionId: string }): void {
    if (
      payload?.requisitionId &&
      payload.requisitionId !== this._jobRequisitionId
    ) {
      return;
    }
    this._status = ResumeUploaderStatus.STOPPED;
    this._outputChannel.send({
      type: ResumeUploaderEventType.QUEUE_STOPPED,
      payload: { requisitionId: this._jobRequisitionId },
    });
    if (this._currentRequest) {
      this._currentRequest.request.abort();
    }
    this._files = [];
    this.removeHandlers();
  }

  async run() {
    if (this._files.length > 0) {
      this._status = ResumeUploaderStatus.RUNNING;
      const item = this._files.shift();

      await uploadApplicant({
        jobRequisitionId: this._jobRequisitionId,
        atsId: item.atsId,
        tag: item.tag,
        passive: item.passive,
        body: createFileFormData(item.file),
        xhrActions: {
          size: item.file.size,
          tempId: item.tempId,
          jobRequisitionId: this._jobRequisitionId,
          setProgress: (event: ProgressEvent, options: object) => {
            this._outputChannel.send({
              type: ResumeUploaderEventType.ITEM_PROCESSING,
              payload: { event, options },
            });
          },
          saveRequest: (xhr: XMLHttpRequest) => {
            this._currentRequest = { id: item.tempId, request: xhr };
          },
        },
      }).fork(
        (rej: any) => {
          if (rej?.status === httpCodes.XHR_REQUEST_ABORTED) {
            return;
          }
          this._outputChannel.send({
            type: ResumeUploaderEventType.ITEM_ERROR,
            payload: { rej, item },
          });
          this._currentRequest = null;
          this.run();
        },
        (res) => {
          this._outputChannel.send({
            type: ResumeUploaderEventType.ITEM_PROCESSED,
            payload: { res, item },
          });
          this._currentRequest = null;
          this.run();
        }
      );
    } else {
      if (this._status === ResumeUploaderStatus.RUNNING) {
        this._outputChannel.send({
          type: ResumeUploaderEventType.QUEUE_PAUSED,
          payload: { requisitionId: this._jobRequisitionId },
        });
      }
      this._status = ResumeUploaderStatus.IDLE;
    }
  }

  getStatus() {
    return this._status;
  }

  getOutputChannel() {
    return this._outputChannel;
  }
}

export class ResumeUploaderPool {
  private _uploaders: Map<RequisitionId, ResumeUploader>;
  constructor() {
    this._uploaders = new Map();
  }

  getUploaderByRequisitionId(
    id: RequisitionId,
    queue: MessageQueue<any>
  ): ResumeUploader {
    let uploader = this._uploaders.get(id);
    if (!uploader) {
      uploader = ResumeUploaderPool._createUploaderInstance(id, queue);
      this._uploaders.set(id, uploader);
    }
    return uploader;
  }

  private static _createUploaderInstance(
    id: RequisitionId,
    queue: MessageQueue<any>
  ): ResumeUploader {
    const _instance = new ResumeUploader(id);

    _instance.addMessageQueue(queue);
    return _instance;
  }
}
