export enum ProfileCriteriaCardState {
  view = 'view',
  expanded = 'expanded',
  viewReadOnly = 'viewReadOnly',
}

export enum ProfileCriteriaCardActions {
  showView = 'SHOW_VIEW',
  showExpanded = 'SHOW_EXPANDED',
  showViewReadOnly = 'SHOW_VIEW_READ_ONLY',
}

export const ProfileCriteriaCardConfig = {
  [ProfileCriteriaCardState.view]: {
    [ProfileCriteriaCardActions.showExpanded]: {
      target: ProfileCriteriaCardState.expanded,
      condition: ({ isReadOnly }: { isReadOnly: boolean }) => !isReadOnly,
      fallback: ProfileCriteriaCardState.viewReadOnly,
    },
    [ProfileCriteriaCardActions.showViewReadOnly]:
      ProfileCriteriaCardState.viewReadOnly,
  },
  [ProfileCriteriaCardState.expanded]: {
    [ProfileCriteriaCardActions.showView]: {
      target: ProfileCriteriaCardState.view,
      condition: ({ isReadOnly }: { isReadOnly: boolean }) => !isReadOnly,
      fallback: ProfileCriteriaCardState.viewReadOnly,
    },
  },
  [ProfileCriteriaCardState.viewReadOnly]: {
    [ProfileCriteriaCardActions.showView]: ProfileCriteriaCardState.view,
  },
};
