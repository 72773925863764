import React from 'react';
import classNames from 'classnames';
import { Placement } from '@popperjs/core';

import { TabButton } from './TabButton';

import styles from './TabsControl.css';

import { TooltipWrapper } from '@air/components';

import { TabValues } from '@air/constants/tabs';

// component proptypes

export type TabItem<T> = {
  label: string;
  value: T;
  isDisabled?: boolean;
  isHidden?: boolean;
  children?: React.ReactElement;
};

export type TabsControlPropsT<T> = {
  items: TabItem<T>[];
  selectedTab: T;
  onChange: (value: T) => void;
  className?: string;
  placement?: Placement;
};

// exports / component definitions
export const TabsControl = <T extends TabValues>(
  props: TabsControlPropsT<T>
) => {
  const { className = '', items, selectedTab, onChange, placement } = props;

  const onTabButtonClick = (item: TabItem<T>) => {
    onChange(item.value);
  };

  return (
    <div className={classNames(styles.tabsControl, className)}>
      <div className={styles.tabsContainer}>
        {items.map((item: any) => (
          <TabButton
            key={item.value}
            onClick={() => onTabButtonClick(item)}
            disabled={item.isDisabled}
            className={classNames(styles.tabButton, item.className, {
              [styles.active]: selectedTab === item.value || items.length === 1,
              [styles.disabled]: item.isDisabled,
            })}
          >
            <TooltipWrapper
              placement={placement}
              tooltip={item.tooltip}
              enabled={item.tooltip}
              triggerClassName={styles.tabsControlTooltip}
            >
              {item.label}
              {item.children}
            </TooltipWrapper>
          </TabButton>
        ))}
      </div>
    </div>
  );
};

TabsControl.displayName = 'TabsControl';
