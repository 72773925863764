/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface EducationInstitution
 */
export interface EducationInstitution {
  /**
   *
   * @type {number}
   * @memberof EducationInstitution
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EducationInstitution
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof EducationInstitution
   */
  originalName: string;
  /**
   *
   * @type {string}
   * @memberof EducationInstitution
   */
  type: EducationInstitutionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EducationInstitution
   */
  accreditaion: EducationInstitutionAccreditaionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum EducationInstitutionTypeEnum {
  COLLEGE = 'COLLEGE',
  COMMUNITY = 'COMMUNITY',
  UNIVERSITY = 'UNIVERSITY',
  PROFESSIONAL = 'PROFESSIONAL',
  HIGHSCHOOL = 'HIGHSCHOOL',
  TRADE = 'TRADE',
  SECONDARY = 'SECONDARY',
  UNKNOWN = 'UNKNOWN',
}
/**
 * @export
 * @enum {string}
 */
export enum EducationInstitutionAccreditaionEnum {
  ACCREDITED = 'ACCREDITED',
  PREACCREDITED = 'PREACCREDITED',
  UNACCREDITED = 'UNACCREDITED',
  UNKNOWN = 'UNKNOWN',
}
