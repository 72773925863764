import R from '@air/third-party/ramda';
import {
  SearchCriteriaV2,
  JobDescriptionWithPlaceholder,
  SkillInfo,
  JobDescriptionSkill,
  JobDescriptionFullResponsePlaceholderProcessingStatusEnum,
  JobDescriptionFullResponse,
  JobDescriptionItem,
  SearchCriteriaImportanceEnum,
  SkillsIncludeExcludeExperience,
} from '@air/api';
import { CardType } from 'domain/SearchCriteria';
import styles from './JobDescription.css';
import classNames from 'classnames';

export type KeywordCardTypes = CardType.skill; // only skill for now

export type KeywordData = {
  cardType: KeywordCardTypes;
  fullName: string;
  language: boolean;
  months: number | null;
  id: number;
  text: string;
  priority: SearchCriteriaImportanceEnum;
};
export type JobDescriptionKeywords = { [key: string]: KeywordData };

export type ExistingCriteriaIds = {
  skill: number[];
};

export type KeywordsByCriteriaType = {
  skill: JobDescriptionWithPlaceholder['skills'];
};

export const getFlatKeywords = (
  keywordsByCriteriaType: KeywordsByCriteriaType
): JobDescriptionKeywords => {
  const criteriaTypes = Object.keys<CardType>(keywordsByCriteriaType || {});
  return criteriaTypes.reduce((acc, criteriaName: KeywordCardTypes) => {
    const criteriaItems = keywordsByCriteriaType[criteriaName];
    return {
      ...acc,
      ...(criteriaItems
        ? criteriaItems.reduce(
            (acc, singleCriteriaItem: JobDescriptionSkill) => {
              const keywordPositions = Object.keys(
                singleCriteriaItem.contexts || {}
              );
              return {
                ...acc,
                ...keywordPositions.reduce<JobDescriptionKeywords>(
                  (acc, positionId) => {
                    return {
                      ...acc,
                      ...(criteriaName
                        ? {
                            [positionId]: {
                              id: singleCriteriaItem.id,
                              fullName: singleCriteriaItem.fullName,
                              months: singleCriteriaItem.months,
                              language: singleCriteriaItem.language,
                              cardType: criteriaName,
                              text: singleCriteriaItem.contexts[positionId],
                              priority: singleCriteriaItem.priority,
                            },
                          }
                        : {}),
                    };
                  },
                  {}
                ),
              };
            },
            {}
          )
        : {}),
    };
  }, {});
};

const getMarkupForExistingCriteria = (
  keywordData: KeywordData,
  className?: string
) => {
  return `<span class="${classNames(
    styles.jobKeyword,
    styles.existing,
    className
  )}">${keywordData.text}</span>`;
};

const getMarkupForNewCriteria = (
  keywordData: KeywordData,
  className?: string
) => {
  return `<span class="${classNames(
    styles.jobKeyword,
    styles.new,
    className
  )}" data-keyword-data='${JSON.stringify(keywordData)}'>${
    keywordData.text
  }</span>`;
};

const getMarkupForHighlightedKeyword = (
  isExistingCriteria: boolean,
  keywordData: KeywordData,
  className?: string
) => {
  return isExistingCriteria
    ? getMarkupForExistingCriteria(keywordData, className)
    : getMarkupForNewCriteria(keywordData, className);
};

export const buildJobDescriptionMarkup = (
  jobDescriptionTemplate: string,
  jobDescriptionKeywords: JobDescriptionKeywords,
  existingCriteriaIds: ExistingCriteriaIds,
  className?: string
) => {
  return R.reduce(
    (markup, positionKey) => {
      const cardData = jobDescriptionKeywords[positionKey];
      const isExistingCriteria = existingCriteriaIds[
        cardData.cardType
      ].includes(cardData.id);
      return markup.replace(
        new RegExp(`\\{${positionKey}\\}`, 'g'),
        getMarkupForHighlightedKeyword(isExistingCriteria, cardData, className)
      );
    },
    jobDescriptionTemplate || '',
    Object.keys(jobDescriptionKeywords)
  );
};

const getGenericCriteriaIds = (
  skillCriteria: SkillsIncludeExcludeExperience
) => {
  // only skill for now
  const getIdsForCriteriaList = (list: Array<SkillInfo>) =>
    R.reduce(
      (acc, listItem) => {
        return listItem?.id ? [...acc, listItem.id] : acc;
      },
      [],
      list || []
    );
  return R.reduce(
    (acc, criteriaItem) => {
      return [...acc, ...getIdsForCriteriaList(criteriaItem.list)];
    },
    [],
    skillCriteria?.includeList || []
  );
};

export const generateExistingCriteriaIds = (criteria: SearchCriteriaV2) => {
  return {
    [CardType.skill]: getGenericCriteriaIds(criteria?.skills),
  };
};

export const isRequestKeywordsNotStarted = (
  job: JobDescriptionFullResponse | JobDescriptionItem
) =>
  'placeholderProcessingStatus' in job &&
  job.placeholderProcessingStatus ===
    JobDescriptionFullResponsePlaceholderProcessingStatusEnum.NOTSTARTED;

export const isRequestKeywordsFailed = (
  job: JobDescriptionFullResponse | JobDescriptionItem
) =>
  'placeholderProcessingStatus' in job &&
  job.placeholderProcessingStatus ===
    JobDescriptionFullResponsePlaceholderProcessingStatusEnum.FAILED;

export const isRequestKeywordsSuccess = (
  job: JobDescriptionFullResponse | JobDescriptionItem
) =>
  'placeholderProcessingStatus' in job &&
  job.placeholderProcessingStatus ===
    JobDescriptionFullResponsePlaceholderProcessingStatusEnum.SUCCESS;

export const isRequestKeywordsInProgress = (
  job: JobDescriptionFullResponse | JobDescriptionItem
) =>
  'placeholderProcessingStatus' in job &&
  job.placeholderProcessingStatus ===
    JobDescriptionFullResponsePlaceholderProcessingStatusEnum.INPROGRESS;
