import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RouteChildrenProps } from 'react-router';
import querystring from 'query-string';
import R from '@air/third-party/ramda';

import {
  Button,
  ConfirmationModal,
  LabeledDropdown,
  Paragraph,
  TabsControl,
  Toggle,
  TooltipWrapper,
  UIText,
} from '@air/components';
import { JobDescription, SectionPanel, UtilitiesControls } from 'features';
import { GACategory } from '@air/domain/Common/GATypes';
import { useAppContext, useAppMethods } from 'providers/AppProvider';
import * as sharedPhrases from '@air/constants/phrases';
import {
  appSelectors,
  customerProfileSelectors,
  emailConfigurationSelectors,
  kanbanSelectors,
} from 'selectors';
import {
  EmailsTabs,
  LineupTabs,
  SelectRecipientsTabs,
} from '@air/constants/tabs';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';
import {
  isEditingEmailConfiguration,
  isRecipientsSelection,
} from 'constants/urls';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import { EmailBuilder } from './EmailBuilder/EmailBuilder';
import { FeatureView, FilterWithPlaceholder, RightSidebar } from 'components';
import { EmailsTemplatesList } from 'features/EmailConfigSection/EmailsTemplatesList/EmailsTemplatesList';
import {
  useEmailConfigurationContext,
  useEmailConfigurationMethods,
} from 'providers/EmailConfigurationProvider';
import { useDebounce } from '@air/utils/hooks';
import {
  EmailConfigSectionT,
  NEW_EMAIL_DEFAULT_SUBJECT,
} from 'domain/EmailConfig/EmailTemplates';
import {
  CandidateSearchProfileStatus,
  EmailTemplateShortResponse,
  EventType,
  JobEmailShortResponse,
  JobEmailStatus,
  NotificationEvent,
} from '@air/api';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { INPUT_DEBOUNCE_TIME } from 'air-shared/constants/app';
import { isCreator } from 'domain/EmailConfig/utils';
import { toast } from '@air/third-party/toast';
import {
  aggregateTimeout,
  MessageConsumer,
  registerSSESubscriber,
} from '@air/lib/server-notifications';
import { SSEConnectionErrorEvent } from '@air/lib/server-notifications/Connection';
import { useEmailConfigParams } from 'domain/EmailConfig/useEmailConfigParams';

import styles from './EmailConfigSection.css';

const JOB_EMAIL_ID_KEY = 'jobEmailId';
const TEMPLATE_ID_KEY = 'emailTemplateId';

const EMAILS_RECEIVERS = {
  [LineupTabs.Active]: phrases.EMAILS_RECEIVERS_APPLIED_CANDIDATES,
  [LineupTabs.Passive]: phrases.EMAILS_RECEIVERS_PASSIVE_CANDIDATES,
  [LineupTabs.MatchMiner]: phrases.EMAILS_RECEIVERS_MATCH_MINER_CANDIDATES,
  [LineupTabs.MatchScout]: phrases.EMAILS_RECEIVERS_MATCH_SCOUT_CANDIDATES,
};

const EMAILS_TABS = [
  {
    label: phrases.EMAIL_SECTION_TEMPLATES_TAB_LABEL,
    value: EmailsTabs.Templates,
  },
  {
    label: phrases.EMAIL_SECTION_JOB_EMAILS_TAB_LABEL,
    value: EmailsTabs.JobEmails,
  },
];

const FilterCreatedByCurrentUser = ({
  onChange,
  checked,
}: {
  onChange: () => void;
  checked: boolean;
}) => {
  return (
    <div className={styles.currentUserFilterContainer}>
      <div className={styles.filterLabelContainer}>
        <UIText tiny bold className={styles.filterLabel}>
          {phrases.EMAIL_SECTION_FILTER_DROPDOWN_FILTER_TEXT}
        </UIText>
      </div>
      <div className={styles.toggle}>
        <label className={styles.label}>
          <Toggle onChange={onChange} checked={checked} />
          <UIText small className={styles.toggleText}>
            {phrases.EMAIL_SECTION_FILTER_DROPDOWN_CREATED_BY_ME_TEXT}
          </UIText>
        </label>
      </div>
    </div>
  );
};

export const EmailConfigSection: React.FC<
  RouteChildrenProps<EmailConfigSectionT>
> = ({ history, match }) => {
  const jobDescriptionId = match.params?.jobDescriptionId;
  const dataSourceId = match.params?.dataSourceId;
  const routeEmailId = match.params?.emailId;
  const parsedLocationSearchParams = querystring.parse(history.location.search);
  const currentTabInRoute = parsedLocationSearchParams.tab as LineupTabs;
  const currentEmailConfigTabInRoute =
    parsedLocationSearchParams.emailConfigTab as EmailsTabs;

  const [isRemoveConfirmVisible, setRemoveConfirmVisibility] = useState(false);
  const [isFilterByCurrentUser, setFilterByCurrentUser] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [
    showTextChangesNotAffectTemplate,
    setShowTextChangesNotAffectTemplate,
  ] = useState(false);

  const itemToDelete = useRef<{ id: string; isSelected: boolean }>(null);

  const sseConsumers = useRef({
    updates: new MessageConsumer(aggregateTimeout(1000)),
  });

  const isLeftSideBarCollapsed = useAppContext(
    appSelectors.isLeftSideBarCollapsed
  );

  const { totalCount, currentSearch, currentSearchId } =
    useEmailConfigParams(currentTabInRoute);

  const currentEmail = useEmailConfigurationContext(
    emailConfigurationSelectors.currentEmail
  );
  const jobEmails = useEmailConfigurationContext(
    emailConfigurationSelectors.jobEmails
  );
  const jobRequisitionDetails = useKanbanContext(
    kanbanSelectors.jobRequisitionDetails
  );
  const emailTemplates = useEmailConfigurationContext(
    emailConfigurationSelectors.emailTemplates
  );
  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );

  const isEditMode = isEditingEmailConfiguration(history.location.pathname);

  const isSelectRecipientsMode = isRecipientsSelection(
    {
      dataSourceId,
      jobDescriptionId,
      emailId: currentEmail.item.id,
      showRecipients: true,
      emailConfigTab: currentEmailConfigTabInRoute,
    },
    history.location.pathname
  );
  const showJobEmailEditingOrRecipients = isEditMode || isSelectRecipientsMode;

  const rightSidebarPanel = useAppContext(appSelectors.rightSidebarPanel);
  const { setRightSidebarPanel } = useAppMethods();

  const {
    fetchJobEmailById,
    fetchJobEmails,
    fetchEmailTemplates,
    fetchEmailTemplateById,
    deleteJobEmailById,
    deleteEmailTemplateById,
    deleteJobEmailByIdLocally,
    deleteEmailTemplateByIdLocally,
    duplicateEmailTemplate,
    duplicateJobEmail,
    fetchEmailRecipients,
    saveJobEmail,
  } = useEmailConfigurationMethods();
  const { fetchSearch } = useKanbanMethods();

  const interviewUrl = urls.makeInterviewUrl(
    dataSourceId,
    jobDescriptionId,
    currentTabInRoute
  );

  const eventsHandler = useCallback(
    (events: NotificationEvent[]) => {
      events.forEach((eventItem) => {
        if (eventItem.userId !== customerId) {
          const isJobEmailDeletedEvent =
            eventItem.eventType === EventType.JOBEMAILDELETED;
          const isEmailTemplateDeletedEvent =
            eventItem.eventType === EventType.EMAILTEMPLATEDELETED;

          const CANDIDATE_STATUS_CHANGE_EVENTS = [
            EventType.SEARCHCANDIDATEPROFILESDROPPED,
            EventType.CANDIDATEPROFILESTAGEUPDATED,
            EventType.CANDIDATEPROFILESTATUSUPDATED,
            EventType.CANDIDATEPROFILEPRIMARYEMAILUPDATED,
          ];
          const isUpdateRecipientsListEvent =
            CANDIDATE_STATUS_CHANGE_EVENTS.includes(eventItem.eventType);
          if (
            (isJobEmailDeletedEvent || isEmailTemplateDeletedEvent) &&
            showJobEmailEditingOrRecipients
          ) {
            const itemIdKey = isJobEmailDeletedEvent
              ? JOB_EMAIL_ID_KEY
              : TEMPLATE_ID_KEY;
            const deletedItem = (
              (isJobEmailDeletedEvent ? jobEmails : emailTemplates) as {
                items: (JobEmailShortResponse | EmailTemplateShortResponse)[];
              }
            ).items.find((item) => item.id === eventItem.payload[itemIdKey]);

            history.replace(
              urls.makeEmailConfigRootUrlWithParams({
                dataSourceId,
                jobDescriptionId,
                tab: currentTabInRoute,
              })
            );

            toast.dark(
              isJobEmailDeletedEvent
                ? phrases.EMAIL_SECTION_TOAST_DELETED_ITEM_JOB_EMAIL_INFO(
                    deletedItem.name
                  )
                : phrases.EMAIL_SECTION_TOAST_DELETED_EMAIL_TEMPLATE_INFO(
                    deletedItem.name
                  )
            );
          }

          if (isJobEmailDeletedEvent) {
            deleteJobEmailByIdLocally(eventItem.payload.jobEmailId);
          }

          if (isEmailTemplateDeletedEvent) {
            deleteEmailTemplateByIdLocally(eventItem.payload.emailTemplateId);
          }

          if (isUpdateRecipientsListEvent) {
            fetchEmailRecipients(currentEmail.item.id, currentSearchId, {
              size: totalCount,
            });
          }
        }

        /*
          If new candidates have been added to current search by another user,
          they should be displayed to current user after processing.
          While they're processed, we'll receive 2 SSE events:
          1 - processing has started (newStatus is NOTPROCESSED)
          2 – processing has finished (newStatus extends CandidateSearchProfileStatus).
          
          With current BE implementation, it's easier to just refetch all candidates
          for current email after this event.
        */
        if (eventItem.eventType === EventType.CANDIDATEPROFILESTATUSUPDATED) {
          if (
            eventItem.payload?.searchId === currentSearchId &&
            eventItem.payload?.newStatus !==
              CandidateSearchProfileStatus.NOTPROCESSED
          ) {
            fetchEmailRecipients(currentEmail.item.id, currentSearchId);
          }
        }
      });
    },
    [
      customerId,
      showJobEmailEditingOrRecipients,
      jobEmails,
      emailTemplates,
      history,
      dataSourceId,
      jobDescriptionId,
      currentTabInRoute,
      deleteJobEmailByIdLocally,
      deleteEmailTemplateByIdLocally,
      fetchEmailRecipients,
      currentEmail.item.id,
      currentSearchId,
      totalCount,
    ]
  );

  useEffect(() => {
    const unsubscribe = registerSSESubscriber(
      (event: NotificationEvent | SSEConnectionErrorEvent) => {
        return sseConsumers.current.updates.onEventReceived(event);
      }
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = sseConsumers.current.updates.subscribe(eventsHandler);

    return unsubscribe;
  }, [eventsHandler]);

  useEffect(() => {
    if (!currentSearch) {
      fetchSearch({
        jobDescriptionId,
      });
    }
  }, [currentSearch, currentTabInRoute, fetchSearch, jobDescriptionId]);

  useEffect(() => {
    // load list of job emails
    if (currentSearchId && R.isNil(jobEmails?.isLoading)) {
      fetchJobEmails(currentSearchId).then((res) => {
        if (!res.length) {
          history.replace(
            urls.makeEmailConfigRootUrlWithParams({
              dataSourceId,
              jobDescriptionId,
              tab: currentTabInRoute,
              params: { emailConfigTab: EmailsTabs.Templates },
            })
          );
        }
      });
    }
  }, [
    currentSearchId,
    currentTabInRoute,
    dataSourceId,
    fetchJobEmails,
    history,
    jobDescriptionId,
    jobEmails?.isLoading,
  ]);

  useEffect(() => {
    if (!emailTemplates.isLoading && !emailTemplates.items) {
      fetchEmailTemplates();
    }
  }, [emailTemplates.items, emailTemplates.isLoading, fetchEmailTemplates]);

  const goToEmail = useCallback(
    ({ emailId, isEditMode }: { emailId?: string; isEditMode?: boolean }) => {
      history.push(
        urls.makeEmailConfigUrl({
          dataSourceId,
          jobDescriptionId,
          emailId,
          tab: currentTabInRoute,
          params: {
            emailConfigTab: currentEmailConfigTabInRoute,
          },
          isEditMode,
        })
      );
    },
    [
      currentTabInRoute,
      currentEmailConfigTabInRoute,
      dataSourceId,
      history,
      jobDescriptionId,
    ]
  );

  const goBack = () => {
    // length = 1, if you open tab through clicking a link,
    // length = 2, if you open empty tab and then adding a link,
    // there is no history available, the user just opened this page's url
    if (history.length < 3) {
      history.push(interviewUrl);
    } else {
      if (history.location.pathname.includes(urls.URL_EDIT_PART)) {
        fetchJobEmails(currentSearchId);
      }
      history.goBack();
      if (rightSidebarPanel !== null) {
        setRightSidebarPanel(null);
      }
      if (showTextChangesNotAffectTemplate) {
        setShowTextChangesNotAffectTemplate(false);
      }
    }
  };

  const routeIdFromJobEmailsList = useMemo(() => {
    const routeEmailIdFromJobEmails = (jobEmails?.items || []).find(
      (item) => item.id === routeEmailId
    );

    return routeEmailIdFromJobEmails?.id;
  }, [jobEmails.items, routeEmailId]);

  const routeIdFromTemplatesList = useMemo(() => {
    const routeEmailIdFromTemplates = (emailTemplates?.items || []).find(
      (item) => item.id === routeEmailId
    );

    return routeEmailIdFromTemplates?.id;
  }, [emailTemplates.items, routeEmailId]);

  const filteredListItems = useMemo(() => {
    const emailTemplateObject =
      currentEmailConfigTabInRoute === EmailsTabs.JobEmails
        ? jobEmails
        : emailTemplates;

    let result = (
      (emailTemplateObject.items && emailTemplateObject) || { items: [] }
    ).items.filter((item: JobEmailShortResponse | EmailTemplateShortResponse) =>
      item.name.toLowerCase().includes(filterValue)
    );

    if (isFilterByCurrentUser) {
      result = result.filter(
        (item: JobEmailShortResponse | EmailTemplateShortResponse) =>
          isCreator(customerId, item.creator)
      );
    }

    return { ...emailTemplateObject, items: result };
  }, [
    customerId,
    emailTemplates,
    isFilterByCurrentUser,
    jobEmails,
    filterValue,
    currentEmailConfigTabInRoute,
  ]);

  useEffect(() => {
    /*
      If no items are matched a filter, we have to change path to root in order not to show previously selected item on the right side.
      Instead we should display banner like "create new email or choose an existing one".

      Pathname is changed while creating newEmail, so we don't need to reset pathname to the root when toggle "created by me" is on and list items don't fit filter.
    */
    if (
      !filteredListItems.items.length &&
      isFilterByCurrentUser &&
      routeEmailId
    ) {
      history.replace(
        urls.makeEmailConfigRootUrlWithParams({
          dataSourceId,
          jobDescriptionId,
          tab: currentTabInRoute,
        })
      );
    }
  }, [
    currentTabInRoute,
    dataSourceId,
    filteredListItems.items.length,
    history,
    isFilterByCurrentUser,
    jobDescriptionId,
    routeEmailId,
  ]);

  useEffect(() => {
    const payloadForReplace = {
      dataSourceId,
      jobDescriptionId,
      tab: currentTabInRoute,
      isEditMode: false,
      emailId: filteredListItems?.items[0]?.id,
      params: { emailConfigTab: currentEmailConfigTabInRoute },
    };

    // checking if id is related to current Tab.If not, change tab
    if (
      currentEmailConfigTabInRoute === EmailsTabs.Templates &&
      routeIdFromJobEmailsList
    ) {
      urls.makeEmailConfigRootUrlWithParams({
        dataSourceId,
        jobDescriptionId,
        tab: currentTabInRoute,
        params: { emailConfigTab: EmailsTabs.JobEmails },
      });
    }

    //checking if id is related to current Tab.If not, change tab
    if (
      currentEmailConfigTabInRoute === EmailsTabs.JobEmails &&
      routeIdFromTemplatesList
    ) {
      urls.makeEmailConfigRootUrlWithParams({
        dataSourceId,
        jobDescriptionId,
        tab: currentTabInRoute,
        params: { emailConfigTab: EmailsTabs.Templates },
      });
    }

    //fetching first item in the tab list
    if (
      !routeEmailId &&
      currentEmailConfigTabInRoute === EmailsTabs.JobEmails &&
      filteredListItems.items.length
    ) {
      history.replace(urls.makeEmailConfigUrl(payloadForReplace));
    }

    //fetching first item in the tab list
    if (
      !routeEmailId &&
      currentEmailConfigTabInRoute === EmailsTabs.Templates &&
      filteredListItems.items.length
    ) {
      history.replace(urls.makeEmailConfigUrl(payloadForReplace));
    }
  }, [
    goToEmail,
    jobEmails.items,
    currentEmailConfigTabInRoute,
    routeEmailId,
    routeIdFromJobEmailsList,
    emailTemplates.items,
    routeIdFromTemplatesList,
    history,
    dataSourceId,
    jobDescriptionId,
    currentTabInRoute,
    fetchEmailTemplateById,
    currentSearchId,
    fetchJobEmailById,
    filteredListItems.items,
  ]);

  useEffect(() => {
    if (
      currentEmailConfigTabInRoute === EmailsTabs.Templates &&
      routeIdFromTemplatesList
    ) {
      fetchEmailTemplateById(routeEmailId);
    }

    if (
      currentEmailConfigTabInRoute === EmailsTabs.Templates &&
      routeIdFromJobEmailsList
    ) {
      fetchJobEmailById(currentSearchId, routeEmailId);
    }

    if (
      currentSearchId &&
      currentEmailConfigTabInRoute === EmailsTabs.JobEmails &&
      routeIdFromJobEmailsList
    ) {
      // this will be executed once when the user selects job email from the list
      fetchJobEmailById(currentSearchId, routeEmailId);
    }
  }, [
    currentSearchId,
    fetchEmailTemplateById,
    fetchJobEmailById,
    routeEmailId,
    routeIdFromJobEmailsList,
    routeIdFromTemplatesList,
    currentEmailConfigTabInRoute,
  ]);

  const handleTabChange = useCallback(
    (emailConfigTab: EmailsTabs) => {
      history.push(
        urls.makeEmailConfigRootUrlWithParams({
          dataSourceId,
          jobDescriptionId,
          tab: currentTabInRoute,
          params: { emailConfigTab },
        })
      );
    },
    [currentTabInRoute, dataSourceId, history, jobDescriptionId]
  );

  const returnToEmailConfigRoot = useCallback(() => {
    history.replace(
      urls.makeEmailConfigRootUrlWithParams({
        dataSourceId,
        jobDescriptionId,
        tab: currentTabInRoute,
        params: { emailConfigTab: currentEmailConfigTabInRoute },
      })
    );
  }, [
    currentEmailConfigTabInRoute,
    currentTabInRoute,
    dataSourceId,
    history,
    jobDescriptionId,
  ]);

  const onDeleteListItem = useCallback(
    async (id: string, isSelected: boolean) => {
      itemToDelete.current = { id, isSelected };
      setRemoveConfirmVisibility(true);
    },
    []
  );

  const confirmDeletion = async () => {
    setRemoveConfirmVisibility(false);

    const callback =
      currentEmailConfigTabInRoute === EmailsTabs.JobEmails
        ? deleteJobEmailById
        : deleteEmailTemplateById;

    const callbackPayload = {
      searchId: currentSearchId,
      ...itemToDelete.current,
    };

    try {
      await callback(callbackPayload);
      if (itemToDelete.current.isSelected) {
        returnToEmailConfigRoot();
      }
      toast.dark(
        currentEmailConfigTabInRoute === EmailsTabs.JobEmails
          ? phrases.EMAIL_SECTION_TOAST_DELETED_JOB_EMAIL_SUCCESS
          : phrases.EMAIL_SECTION_TOAST_DELETED_TEMPLATE_EMAIL_SUCCESS
      );
    } catch (e) {
      toast.error(sharedPhrases.GENERAL_ERROR_TRY_AGAIN);
    }
  };

  const duplicateItem = useCallback(
    (id: string) => {
      const callback =
        currentEmailConfigTabInRoute === EmailsTabs.JobEmails
          ? duplicateJobEmail
          : duplicateEmailTemplate;

      callback(id, currentSearchId);
    },
    [
      currentSearchId,
      duplicateEmailTemplate,
      duplicateJobEmail,
      currentEmailConfigTabInRoute,
    ]
  );

  const onChangeInputHandle = useDebounce((value: string) => {
    setFilterValue(value);
    //replace path to the root one in order to fetch first element from filtered items.
    if (
      match.url !==
      urls.makeEmailConfigRootUrlWithParams({
        dataSourceId,
        jobDescriptionId,
        tab: currentTabInRoute,
        params: { emailConfigTab: EmailsTabs.JobEmails },
      })
    ) {
      history.replace(
        urls.makeEmailConfigRootUrlWithParams({
          dataSourceId,
          jobDescriptionId,
          tab: currentTabInRoute,
          params: { emailConfigTab: currentEmailConfigTabInRoute },
        })
      );
    }
  }, INPUT_DEBOUNCE_TIME);

  const onClickCreateNewEmailHandler = useCallback(() => {
    saveJobEmail({
      email: {
        subject: NEW_EMAIL_DEFAULT_SUBJECT(currentSearch?.name),
        sections: [],
      },
      searchId: currentSearchId,
    }).then((res) => {
      setFilterByCurrentUser(false);
      goToEmail({
        emailId: res.id,
        isEditMode: true,
      });
    });
  }, [currentSearch?.name, currentSearchId, goToEmail, saveJobEmail]);

  /*  We don't know how we lead to the page of editing new jobEmail.
    Showing text 'Changes will not affect the template" is only relevant for the flow of
    creating new jobEmail from template, so we need this callback to be in response of showing this text*/
  const onBottomButtonClick = useCallback(() => {
    return currentEmailConfigTabInRoute === EmailsTabs.Templates
      ? setShowTextChangesNotAffectTemplate(true)
      : R.noop;
  }, [currentEmailConfigTabInRoute]);

  // make sure it does not blink before skeleton
  const showEncouragingMessage =
    !R.isNil(jobEmails?.isLoading) &&
    !R.isNil(emailTemplates.isLoading) &&
    !jobEmails?.isLoading &&
    !emailTemplates.isLoading;

  const onItemStatusClickHandler = ({
    status,
    id,
  }: {
    id: string;
    status: JobEmailStatus;
  }) => {
    if (status === JobEmailStatus.DRAFT) {
      goToEmail({ emailId: id, isEditMode: true });
    }

    if (status === JobEmailStatus.SENT) {
      history.push(
        urls.makeEmailConfigUrl({
          dataSourceId,
          jobDescriptionId,
          emailId: id,
          tab: currentTabInRoute,
          isEditMode: false,
          params: {
            recipientsTab: SelectRecipientsTabs.New,
            emailConfigTab: currentEmailConfigTabInRoute,
          },
        })
      );
    }
  };

  return (
    <div className={styles.EmailConfigSection}>
      <div className={styles.leftPanel}>
        <div className={styles.topBlock}>
          <nav className={styles.nav}>
            <Button
              variant={Button.variants.DEFAULT}
              className={styles.navButtonHome}
              icon="home-icon"
              onClick={() => history.push(urls.ROOT_ROUTE)}
            />
            <div className={styles.delimiter} />
            <Button
              variant={Button.variants.DEFAULT}
              className={styles.navButtonBack}
              icon="back-icon"
              iconClassName={styles.backIcon}
              onClick={goBack}
            >
              <UIText small>{sharedPhrases.BUTTON_BACK}</UIText>
            </Button>
          </nav>
          <div className={styles.sendingEmailsToWrapper}>
            <UIText tiny bold className={styles.sendingEmailsToLabel}>
              {phrases.EMAIL_CONFIG_SENDING_EMAILS_TO_LABEL}:
            </UIText>
            <Paragraph big bold className={styles.sendingEmailsTo}>
              {
                EMAILS_RECEIVERS[
                  currentTabInRoute as
                    | LineupTabs.Active
                    | LineupTabs.Passive
                    | LineupTabs.MatchMiner
                    | LineupTabs.MatchScout
                ]
              }
            </Paragraph>
          </div>
          {currentSearch && (
            <div className={styles.jobBlockWrapper}>
              <UIText tiny bold className={styles.selectedJobLabel}>
                {phrases.EMAIL_CONFIG_SELECTED_JOB_LABEL}:
              </UIText>
              <div className={styles.jobBlock}>
                <TooltipWrapper
                  enabled
                  tooltip={currentSearch?.name}
                  placement="right-end"
                  modifiers={[
                    {
                      name: 'offset',
                      options: { offset: [0, 10] },
                    },
                  ]}
                >
                  <div
                    className={styles.blockWithPosition}
                    onClick={() => {
                      setRightSidebarPanel(null);
                      history.push(interviewUrl);
                    }}
                  >
                    <Paragraph short bold className={styles.jobPosition}>
                      {currentSearch?.name}
                    </Paragraph>

                    <Button
                      icon="external-link-icon"
                      variant={Button.variants.TINY}
                      className={styles.externalLink}
                      iconClassName={styles.externalLinkIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(interviewUrl, '_blank');
                      }}
                    />
                  </div>
                </TooltipWrapper>
                <div className={styles.blockWithTip}>
                  <UIText small bold className={styles.goToScreening}>
                    {phrases.EMAIL_CONFIG_GO_TO_SCREENING_LABEL}
                  </UIText>
                </div>
              </div>
            </div>
          )}
          {showTextChangesNotAffectTemplate && (
            <Paragraph small className={styles.templateChangesText}>
              {
                phrases.EMAIL_SECTION_CREATING_JOB_EMAIL_FROM_TEMPLATE_CHANGES_TEXT
              }
            </Paragraph>
          )}
        </div>
        <div className={styles.utilitiesWrapper}>
          <UtilitiesControls
            isCollapsed={isLeftSideBarCollapsed}
            gaCategory={GACategory.ScreeningPage}
          />
        </div>
      </div>
      <FeatureView.Content className={styles.centralSection}>
        {!showJobEmailEditingOrRecipients && (
          <div className={styles.navWrapper}>
            <div className={styles.tabsWrapper}>
              <div className={styles.tabs}>
                <nav>
                  <TabsControl<EmailsTabs>
                    items={EMAILS_TABS}
                    selectedTab={currentEmailConfigTabInRoute}
                    onChange={handleTabChange}
                  />
                </nav>
                <LabeledDropdown
                  icon="filter"
                  modifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ]}
                >
                  <FilterCreatedByCurrentUser
                    checked={isFilterByCurrentUser}
                    onChange={() => setFilterByCurrentUser((state) => !state)}
                  />
                </LabeledDropdown>
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <FilterWithPlaceholder
                placeholderTitle={phrases.SEARCH_FILTER_PLACEHOLDER_FILTER}
                onChange={onChangeInputHandle}
                className={styles.input}
                defaultValue={filterValue}
              />
            </div>
            <Button
              variant={Button.variants.POSITIVE_MAIN}
              className={styles.createEmailButton}
              onClick={onClickCreateNewEmailHandler}
            >
              <UIText>{phrases.JOB_EMAILS_TAB_CREATE_NEW_EMAIL_BUTTON}</UIText>
            </Button>

            <EmailsTemplatesList
              selectEmail={goToEmail}
              selectedEmailId={routeEmailId}
              onDelete={onDeleteListItem}
              emailsTemplates={filteredListItems}
              onDuplicate={duplicateItem}
              onStatusClick={onItemStatusClickHandler}
            />
          </div>
        )}
        <div className={styles.content}>
          {routeEmailId ? (
            <EmailBuilder
              isEditMode={isEditMode}
              isSelectRecipientsMode={isSelectRecipientsMode}
              goToEditMode={goToEmail}
              dataSourceId={dataSourceId}
              jobDescriptionId={jobDescriptionId}
              currentTab={currentTabInRoute}
              selectedTab={currentEmailConfigTabInRoute}
              history={history}
              onBottomButtonClick={onBottomButtonClick}
              currentSearchId={currentSearchId}
            />
          ) : (
            showEncouragingMessage && (
              <SectionPanel
                className={styles.createNewEmail}
                title={phrases.EMAIL_SECTION_CREATE_NEW_TITLE}
              >
                <Paragraph>{phrases.EMAIL_SECTION_CREATE_NEW_DESC}</Paragraph>
              </SectionPanel>
            )
          )}
        </div>
        <RightSidebar
          isOpened={rightSidebarPanel !== null}
          closePanel={() => setRightSidebarPanel(null)}
        >
          {RightSidebar.isJobDescriptionOpened(rightSidebarPanel) && (
            <JobDescription job={jobRequisitionDetails} isEditable={false} />
          )}
        </RightSidebar>
      </FeatureView.Content>
      <ConfirmationModal
        onConfirm={confirmDeletion}
        onCancel={() => setRemoveConfirmVisibility(false)}
        isOpen={isRemoveConfirmVisible}
        confirmLabel={phrases.EMAIL_SECTION_CONFIRM_LABEL}
      >
        <Paragraph short bold className={styles.confirmModalText}>
          {currentEmailConfigTabInRoute === EmailsTabs.JobEmails
            ? phrases.EMAIL_SECTION_CONFIRM_MODAL_DELETE_TEXT_EMAIL
            : phrases.EMAIL_SECTION_CONFIRM_MODAL_DELETE_TEMPLATE_EMAIL}
          ?
        </Paragraph>
      </ConfirmationModal>
    </div>
  );
};
