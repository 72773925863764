import React from 'react';
import { OverlapPageControls } from '@air/components';
import { RightSidebarPanel } from 'providers/AppProvider';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '@air/styles/transitions/fade.css';
import slideFromRight from '@air/styles/transitions/slideFromRight.css';
import classNames from 'classnames';
import styles from './RightSidebar.css';

enum RightSidebarVariants {
  RoundedPanel,
  OverlapView,
}

enum RightSidebarTransitions {
  Fade,
  SlideFromRight,
}

const transitionsMap = {
  [RightSidebarTransitions.Fade]: fadeTransition,
  [RightSidebarTransitions.SlideFromRight]: slideFromRight,
};

export const RightSidebar = (props: {
  className?: string;
  isOpened?: boolean;
  hasReportPanel?: boolean;
  hasBlockScreen?: boolean;
  overlapping?: boolean;
  children: React.ReactNode;
  closePanel: () => void;
  variant?: RightSidebarVariants;
  transition?: RightSidebarTransitions;
}) => {
  const {
    className,
    isOpened,
    children,
    overlapping = true,
    hasBlockScreen,
    closePanel,
    variant = RightSidebarVariants.RoundedPanel,
    transition = RightSidebarTransitions.Fade,
  } = props;

  return (
    <CSSTransition
      in={isOpened}
      classNames={transitionsMap[transition]}
      timeout={500}
      unmountOnExit
    >
      <div
        className={classNames(styles.rightSidebar, {
          [styles.blockScreen]: hasBlockScreen,
        })}
      >
        <div
          className={classNames(styles.innerContent, className, {
            [styles.overlapped]: overlapping,
            [styles.roundedPanel]:
              variant === RightSidebarVariants.RoundedPanel,
            [styles.overlapView]: variant === RightSidebarVariants.OverlapView,
          })}
        >
          <OverlapPageControls onClose={closePanel} />
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

RightSidebar.isJobDescriptionOpened = (currentPanel: RightSidebarPanel) =>
  currentPanel === RightSidebarPanel.JobDescription;
RightSidebar.isUploadsOpened = (currentPanel: RightSidebarPanel) =>
  currentPanel === RightSidebarPanel.Uploads;
RightSidebar.isReportOpened = (currentPanel: RightSidebarPanel) =>
  currentPanel === RightSidebarPanel.Report;
RightSidebar.isMMSettingsOpened = (currentPanel: RightSidebarPanel) =>
  currentPanel === RightSidebarPanel.MatchServiceSettings;

RightSidebar.variants = RightSidebarVariants;
RightSidebar.transitions = RightSidebarTransitions;
