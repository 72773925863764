import React from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

import {
  BaseCandidateData,
  CandidateCriteriaListItem,
} from 'domain/CandidateData/CandidateCriteria/BaseCandidateData';
import { CardType, SearchCriteriaData } from 'domain/SearchCriteria';
import { getRoleFieldsForCandidateCollapsedCard } from 'components/Cards/RoleCard/RoleCard';
import { CandidateRoleData } from 'domain/CandidateData/CandidateCriteria/CandidateRoleData';
import {
  getDegreeFieldsForCandidateCollapsedMainCard,
  getDegreeFieldsForCandidateCollapsedStackCard,
} from 'components/Cards/DegreeCard/DegreeCard';
import { CandidateDegreeData } from 'domain/CandidateData/CandidateCriteria/CandidateDegreeData';
import { getEducationFieldsForCandidateCollapsedMainCard } from 'components/Cards/educationCommonCode';
import { CandidateMajorData } from 'domain/CandidateData/CandidateCriteria/CandidateMajorData';
import { CandidateInstitutionData } from 'domain/CandidateData/CandidateCriteria/CandidateInstitutionData';
import { Card, CardSize } from '@air/components/Card/Card';
import { RedFlagItem } from '@air/api';
import { getLocationFieldsForCandidateCollapsedCard } from 'components/Cards/LocationCard/LocationCard';
import { CandidateLocationData } from 'domain/CandidateData/CandidateCriteria/CandidateLocationData';
import {
  getCardTitle,
  getCardListItemTooltip,
} from 'domain/SearchCriteria/cardHelpers';
import * as phrases from 'constants/phrases';
import { hasInactiveAlert } from 'components/Cards/cardsCommonCode';
import {
  ApproximateExperiencePeriod,
  ApproximatePeriodType,
} from 'air-shared/components/Period/Period';
import { cardsConfig } from 'air-shared/domain/SearchCriteriaCards/cardsConfig';
import { Paragraph, SvgIcon, TooltipWrapper } from '@air/components';

import styles from 'components/Cards/cardsCommonStyles.css';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';

export const getFieldsForCandidateCollapsedMainCard = (
  mainCard: CandidateCriteriaListItem,
  cardData: BaseCandidateData
) => {
  switch (cardData.cardType) {
    case CardType.skill:
    case CardType.certification:
    case CardType.industry:
    case CardType.company:
    case CardType.managerial:
    case CardType.professional:
    case CardType.question:
      return getFieldsForCandidateCollapsedCard(mainCard, cardData);
    case CardType.location:
      return getLocationFieldsForCandidateCollapsedCard(
        mainCard,
        cardData as CandidateLocationData
      );
    case CardType.role:
      return getRoleFieldsForCandidateCollapsedCard(
        mainCard,
        cardData as CandidateRoleData
      );
    case CardType.major:
    case CardType.institution:
      return getEducationFieldsForCandidateCollapsedMainCard(
        mainCard,
        cardData as CandidateMajorData | CandidateInstitutionData
      );
    case CardType.degree:
      return getDegreeFieldsForCandidateCollapsedMainCard(
        mainCard,
        cardData as CandidateDegreeData
      );
    default:
      return getFieldsForCandidateCollapsedCard(mainCard, cardData);
  }
};

function getFieldsForCandidateCollapsedCard(
  mainCard: CandidateCriteriaListItem,
  cardData: BaseCandidateData & { exclude?: boolean }
) {
  const relatedSkillsTooltip = (
    <div className={styles.relatedSkillsTooltip}>
      <Paragraph small>{phrases.RELATED_SKILLS_TOOLTIP}</Paragraph>
      <Paragraph small bold className={styles.strong}>
        {mainCard.deducedFrom?.join(', ')}
      </Paragraph>
    </div>
  );

  const cardTitle = (
    <Card.Title
      resizeable
      flexGrow={false}
      getTooltipText={getCardListItemTooltip.bind(null, cardData, mainCard)}
      title={getCardTitle({
        cardType: cardData.cardType,
        label: mainCard.label,
      })}
      key="card-title"
    />
  );

  const cardFooter = (!!mainCard.experience ||
    mainCard.deducedFrom?.length > 0) && (
    <Card.Footer
      className={styles.cardFooter}
      text={
        mainCard.experience ? (
          <ApproximateExperiencePeriod
            experience={mainCard.experience}
            periodType={ApproximatePeriodType.criteria}
          />
        ) : (
          ''
        )
      }
      key="card-footer"
    >
      {mainCard.deducedFrom?.length > 0 && (
        <TooltipWrapper
          enabled
          trigger="hover"
          placement="bottom"
          tooltip={relatedSkillsTooltip}
          triggerClassName={styles.footerInfoTooltip}
        >
          <SvgIcon icon="info-2" className={styles.footerInfoIcon} />
        </TooltipWrapper>
      )}
    </Card.Footer>
  );

  if (cardData.exclude) {
    return [
      R.isNil(mainCard.matchResolution) ? (
        <Card.Alert
          className={cardCommonStyles.unwantedLabel}
          key="title-label-alert"
        >
          {phrases.UNWANTED_LABEL}
        </Card.Alert>
      ) : (
        <Card.TitleLabel
          key="title-label-match-resolution"
          text={cardsConfig[
            cardData.cardType
          ].getCandidateCollapsedCardMatchResolutionLabel?.(mainCard)}
        />
      ),
      cardTitle,
      cardFooter,
    ];
  } else {
    return [
      cardTitle,
      !!mainCard.alert && (
        <Card.Alert
          key="card-alert"
          className={classNames(cardCommonStyles.cardTitleAlert, {
            [cardCommonStyles.inactiveAlert]:
              hasInactiveAlert<CandidateCriteriaListItem>(mainCard),
          })}
        >
          {mainCard.alert.displayName}
        </Card.Alert>
      ),
      cardFooter,
    ];
  }
}

export const getFieldsForCandidateCollapsedStackCard = (
  mainCard: CandidateCriteriaListItem,
  cardData: BaseCandidateData
) => {
  switch (cardData.cardType) {
    case CardType.degree:
      return getDegreeFieldsForCandidateCollapsedStackCard(mainCard, cardData);
    default:
      return getFieldsForCandidateCollapsedMainCard(mainCard, cardData);
  }
};

export const getCardInitialSize = (
  cardData: BaseCandidateData | SearchCriteriaData | RedFlagItem
): CardSize => {
  return 'cardType' in cardData && cardData.cardType === CardType.question
    ? CardSize.wide
    : CardSize.normal;
};
