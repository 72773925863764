import React, { ReactNode } from 'react';
import R from '@air/third-party/ramda';
import { MatchResolution } from '@air/api';
import { Paragraph, UIText } from '@air/components';
import styles from './cardsCommonStyles.css';
import { CandidateCriteriaListItem } from 'customer-portal/src/domain/CandidateData';
import * as phrases from 'customer-portal/src/constants/phrases';
import cardCommonStyles from 'customer-portal/src/components/Cards/cardsCommonStyles.css';
import { BaseSearchCriteriaData } from 'customer-portal/src/domain/SearchCriteria';

export const CardCategoryTooltip: React.FC<{
  items: { fullName: string }[];
  tooltipText: string;
}> = ({ items = [], tooltipText }) => {
  const categoriesStr = items
    .reduce((acc, it) => [...acc, it.fullName], [])
    .join(', ');
  return (
    <div>
      <Paragraph tiny>{tooltipText}</Paragraph>
      <Paragraph tiny className={styles.cardCategoryList}>
        {categoriesStr}
      </Paragraph>
    </div>
  );
};

export const CardEquivalentSkillsTooltip: React.FC<{
  selectedItem: string;
  items: string[];
}> = ({ selectedItem, items = [] }) => {
  const AMOUNT_OF_DISPLAYED_ITEMS = 15;

  return (
    <div>
      <Paragraph small bold className={styles.cardCategoryTitle}>
        {selectedItem}
      </Paragraph>
      {items.length > 0 ? (
        <div className={styles.skillsCardTooltip}>
          <UIText tiny>{phrases.SKILLS_CARD_TOOLTIP_SUBTITLE}</UIText>
          <Paragraph tiny className={styles.cardCategoryList}>
            {R.take(AMOUNT_OF_DISPLAYED_ITEMS, items).join(', ')}
          </Paragraph>
        </div>
      ) : (
        <Paragraph small>{phrases.SKILLS_CARD_TOOLTIP_NO_RESULTS}</Paragraph>
      )}
    </div>
  );
};

export const getCandidateCollapsedCardMatchResolutionLabel = (
  cardData: CandidateCriteriaListItem
) => {
  return {
    [MatchResolution.FOUND]: phrases.NOT_FOUND_LABEL,
    [MatchResolution.RECENT]: phrases.NOT_RECENT_LABEL,
  }[cardData.matchResolution];
};

export const isExcludedCriteriaCard = (
  cardData: BaseSearchCriteriaData & {
    exclude?: boolean;
  }
): boolean => {
  return 'exclude' in cardData && cardData.exclude;
};

export const getUnwantedCriteriaCardTitleLabel = (
  cardData: BaseSearchCriteriaData & {
    exclude?: boolean;
  }
): ReactNode | null => {
  return isExcludedCriteriaCard(cardData) ? (
    <div className={cardCommonStyles.unwantedLabel}>
      #{phrases.UNWANTED_LABEL}
    </div>
  ) : null;
};
