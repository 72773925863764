import React, { useMemo, useCallback, useState } from 'react';
import R from '@air/third-party/ramda';
import { Field, FieldArray, FieldProps } from 'formik';

import { SearchCriteriaImportanceEnum } from '@air/api';
import {
  Card,
  IdealExperienceWidget,
  AcceptableExperienceWidget,
  RecentExperienceWidget,
} from '@air/components';
import { SelectRenderer } from '@air/components/Select/Select';
import {
  DetailsSelect,
  DetailsReadOnlyWidget,
} from 'components/Form/renderers/DetailsSelect/DetailsSelect';
import {
  isValidNewOption,
  canMergeLists,
  loadPaginatedSkillsAsyncOptions,
  getIdsInUse,
  WithDefaultValueOnConditionalMounting,
} from 'components/SearchCriteriaCards/utils';

import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import { FormSelectEntity } from '@air/components/Select/typings';

import { skillDetailsDemandOptionsList } from 'constants/skills';

import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import {
  isIdealExperienceDefined,
  isAcceptableExperienceDefined,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import { useStateMachine } from '@air/hooks';
import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import {
  SearchCardContext,
  StateContext,
} from 'components/SearchCriteriaCards/hooks/SearchCardContext';
import {
  getOnMainCardKeyDownCallback,
  getDraggableStackItem,
  getDraggableCriteriaCard,
  CriteriaCardViewProps,
  CriteriaDragObjectT,
  SingleCriteriaItemDragObject,
  MAX_CARDS_IN_STACK,
  isDraggableMainCard,
  getOnStackCardKeyDownCallback,
  isDraggableStackItem,
  isExcludedItem,
} from 'components/Cards/cardsCommonCode';
import {
  SkillCriteriaData,
  getImportanceValue,
  SearchCriteriaListValue,
  CardType,
  skillDataMapper,
  isExcludeSkill,
  isIncludeSkill,
} from 'domain/SearchCriteria';
import { IdealExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/IdealExperienceWidget';
import { AcceptableExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/AcceptableExperienceWidget';
import { RecentExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/RecentExperienceWidget';
import {
  getMainCardTitle,
  getCardCriterionLabel,
  getAddingLimits,
  getSearchCriteriaCardFooter,
  getCardStackSize,
  getTopStackListName,
  getMainCard,
  getTopStackSize,
} from 'domain/SearchCriteria/cardHelpers';
import {
  CriteriaDraggableCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { generateCriteriaCardTopStackItems } from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import { CriteriaCollapsedCardProps } from 'components/CardWrappers/CriteriaCollapsedCard/CriteriaCollapsedCard';
import {
  generateTopStackItems,
  generateBottomStackItems,
  CriteriaEditCardStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import { ValueType } from 'react-select';
import { OptionTypeBase } from 'react-select/src/types';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import classNames from 'classnames';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import * as phrases from 'constants/phrases';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import { CriteriaImportanceExclusionSettings } from 'components/SearchCriteriaCards/components/CriteriaImportanceExclusionSettings/CriteriaImportanceExclusionSettings';

type SkillCardComponentProps = {
  namePrefix: string;
  cardData: SkillCriteriaData;
  specializations: string[];
  onUpdate: () => void;
  onRemove: (value?: string | number) => void;
  changeCardImportance: (
    criteria: SkillCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;

  onRemoveStackItem: (value: string | number, list?: string) => void;

  optionsFilter: (value: any) => boolean;
  importanceSectionOrder: number;

  handleDrop: (id: string | number, itemId: string | number) => void;
  handleSingleCriteriaDrop: (
    id: string | number,
    itemId: string | number,
    groupId: string | number
  ) => void;
  isReadOnly?: boolean;
};

/*
  match an empty string or a string without whitespaces at the beginning
  so once typing the string, user can still clear the input
 */
const whitelistedChars = `a-zA-Z0-9&/,.!?#+\\-:;`;

const skillTitleRegex = new RegExp(
  `^$|^[${whitelistedChars}][${whitelistedChars} ]{0,100}$`
);

export const getSkillReadOnlyFields = (
  card: SkillCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {isExcludeSkill(card) && (
        <div
          className={classNames(
            cardCommonStyles.unwantedLabel,
            cardCommonStyles.lineupEditCardUnwantedLabel
          )}
        >
          #{phrases.UNWANTED_LABEL}
        </div>
      )}
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
    isIncludeSkill(card) ? (
      <IdealExperienceReadOnlyWidget
        values={card.experience}
        key="ideal-experience-field"
      />
    ) : null,
    isIncludeSkill(card) &&
    isIdealExperienceDefined(card.experience) &&
    isAcceptableExperienceDefined(card.experience) ? (
      <AcceptableExperienceReadOnlyWidget
        values={card.experience}
        key="acceptable-experience-field"
      />
    ) : null,
    <RecentExperienceReadOnlyWidget
      value={card.recent}
      key="recent-experience-field"
    />,
    isIncludeSkill(card) && !R.isNullOrEmpty(card.detailsDemand) ? (
      <DetailsReadOnlyWidget
        value={card.detailsDemand.value}
        icon={card.detailsDemand.icon}
        key="details-field"
      >
        {card.detailsDemand.label}
      </DetailsReadOnlyWidget>
    ) : null,
  ];
};

const DraggableSkillStackItem = getDraggableStackItem<SkillCriteriaData>(
  DraggedSearchCriteriaCardType.singleSkillItem
);
DraggableSkillStackItem.displayName = 'DraggableSkillStackItem';

export const SkillCriteriaDraggableCollapsedCard: React.FC<
  CriteriaCardViewProps<SkillCriteriaData> & CriteriaCollapsedCardProps
> = ({
  criteria,
  stackSize,
  handleDrop,
  handleSingleCriteriaDrop,
  isReadOnly,
  ...props
}) => {
  const id = criteria.key;

  const acceptTypes = useMemo(() => {
    return [
      DraggedSearchCriteriaCardType.skill,
      DraggedSearchCriteriaCardType.singleSkillItem,
    ];
  }, []);

  const canDropCallback = useCallback(
    (
      draggedItem:
        | CriteriaDragObjectT<SkillCriteriaData>
        | SingleCriteriaItemDragObject<SkillCriteriaData>
    ) => {
      if (
        isDraggableStackItem(draggedItem) ||
        (isDraggableMainCard(draggedItem) && draggedItem.isDrawingMainCard)
      ) {
        const isExcluded = isExcludedItem(
          draggedItem,
          DraggedSearchCriteriaCardType.singleSkillItem
        );

        return (
          id !== draggedItem.groupId &&
          getTopStackSize(criteria) < MAX_CARDS_IN_STACK &&
          criteria.exclude === isExcluded
        );
      }
      return (
        id !== draggedItem.id &&
        isDraggableMainCard(draggedItem) &&
        criteria.exclude === draggedItem.criteria.exclude &&
        canMergeLists(draggedItem.criteria, criteria)
      );
    },
    [criteria, id]
  );

  const dropCallback = useCallback(
    (droppedItem) => {
      if (
        droppedItem.type === DraggedSearchCriteriaCardType.singleSkillItem ||
        droppedItem.isDrawingMainCard
      ) {
        handleSingleCriteriaDrop(id, droppedItem.id, droppedItem.groupId);
      } else {
        handleDrop(id, droppedItem.id);
      }
    },
    [handleDrop, handleSingleCriteriaDrop, id]
  );

  return (
    <CriteriaDraggableCollapsedCard
      {...props}
      criteria={criteria}
      stackSize={stackSize}
      draggableStackItem={DraggableSkillStackItem}
      acceptTypes={acceptTypes}
      canDropCallback={canDropCallback}
      dropCallback={isReadOnly ? R.noop : dropCallback}
    />
  );
};
SkillCriteriaDraggableCollapsedCard.displayName =
  'SkillCriteriaDraggableCollapsedCard';

const DraggableSkillCriteriaCard = getDraggableCriteriaCard<SkillCriteriaData>(
  SkillCriteriaDraggableCollapsedCard,
  DraggedSearchCriteriaCardType.skill
);
DraggableSkillCriteriaCard.displayName = 'DraggableSkillCriteriaCard';

export const SkillCriteriaCard: React.FC<SkillCardComponentProps> = ({
  namePrefix,
  cardData,
  specializations,
  importanceSectionOrder,
  optionsFilter,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  handleDrop,
  handleSingleCriteriaDrop,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const cardTitle = getMainCardTitle(cardData);
  const [cardFooter] = getSearchCriteriaCardFooter(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const stackSize = getCardStackSize(cardData);
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: !isNewCard,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };
  const isSkillUnwanted = isExcludeSkill(cardData);

  const titleLabel = useMemo(() => {
    return cardsConfig[cardData.cardType]?.getCriteriaCardTitleLabel?.(
      cardData
    );
  }, [cardData]);

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <StateContext.Consumer>
            {({ states }) => {
              const canDrawMainCardFromStack =
                stackSize > 0 && states.isStackOpened;
              return (
                <DraggableSkillCriteriaCard
                  isReadOnly={isReadOnly}
                  titleLabel={titleLabel}
                  title={cardTitle}
                  footerText={cardFooter}
                  stackSize={stackSize}
                  stackItems={[topStackItems]}
                  cardClasses={{
                    footerClass: cardCommonStyles.experienceYears,
                    wrapperClass: classNames({
                      [cardCommonStyles.unwantedCard]: isSkillUnwanted,
                    }),
                  }}
                  importance={cardImportance}
                  cardLabel={cardLabel}
                  criteria={cardData}
                  importanceSectionOrder={importanceSectionOrder}
                  onRemove={onRemove}
                  onRemoveStackItem={onRemoveStackItem}
                  handleDrop={handleDrop}
                  handleSingleCriteriaDrop={handleSingleCriteriaDrop}
                  canDrawMainCardFromStack={canDrawMainCardFromStack}
                  initialCardStatus={cardData.initialCardStatus}
                />
              );
            }}
          </StateContext.Consumer>
        ) : (
          <SkillCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            specializations={specializations}
            optionsFilter={optionsFilter}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
            onUpdate={onUpdate}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};

const DEFAULT_SKILL_OPTIONS: {
  items: SearchCriteriaListValue<{ language?: boolean }>[];
  total: number;
  page: number;
} = {
  items: [],
  total: 0,
  page: 0,
};

export const SkillCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: SkillCriteriaData;
  specializations: string[];
  changeCardImportance: (
    criteria: SkillCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  optionsFilter: (value: any) => boolean;
  isReadOnly?: boolean;
  onUpdate: () => void;
}> = ({
  namePrefix,
  values,
  specializations,
  optionsFilter,
  changeCardImportance,
  isReadOnly = false,
  onUpdate,
}) => {
  const [skillOptions, setSkillOptions] = useState(DEFAULT_SKILL_OPTIONS);
  const [currentSearchInput, setCurrentSearchInput] = useState('');
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const skillCardConfig = cardsConfig[CardType.skill];

  const topStackListName = getTopStackListName(values);
  const isSkillUnwanted = isExcludeSkill(values);

  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(
    values,
    MAX_CARDS_IN_STACK
  );

  const searchSkillOptions = useCallback(
    ({
      value,
      page = 0,
      prevItems = [],
    }: {
      value?: string;
      page?: number;
      prevItems?: SearchCriteriaListValue<{ language?: boolean }>[];
    }) => {
      return loadPaginatedSkillsAsyncOptions(
        {
          value,
          page,
          specializations,
          excludeId: getIdsInUse(CardType.skill)([values]),
        },
        ({ items, total }) => {
          setSkillOptions({
            items: [...prevItems, ...R.map(skillDataMapper, items)],
            total,
            page,
          });
          setIsOptionsLoading(false);
        }
      );
    },
    [values, specializations]
  );

  const loadMoreOptions = useCallback(
    () =>
      searchSkillOptions({
        value: currentSearchInput,
        page: R.isEmpty(skillOptions.items) ? 0 : skillOptions.page + 1,
        prevItems: skillOptions.items,
      }),
    [currentSearchInput, skillOptions, searchSkillOptions]
  );

  const onChangeInputValue = useCallback(
    (value) => {
      setSkillOptions(DEFAULT_SKILL_OPTIONS);
      setCurrentSearchInput(value);
      if (value) {
        setIsOptionsLoading(true);
        searchSkillOptions({ value });
      }
    },
    [searchSkillOptions]
  );

  const selectSkillValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        const onChange = props.onChange
          ? props.onChange
          : (value: any) => form.setFieldValue(field.name, value);

        return (
          <SelectRenderer
            isDisabled={isReadOnly}
            explanation={phrases.FREEFORM_SKILL_EXPLANATION}
            isLoading={isOptionsLoading}
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            getSingleValueTooltipProps={
              skillCardConfig.getStackItemTooltipProps
            }
            {...props}
            getDropDownItemTooltipProps={
              skillCardConfig.getSelectDropDownOptionTooltipProps
            }
            inputRegex={skillTitleRegex}
            isClearable
            onChange={onChange}
            options={skillOptions.items}
            infiniteScrollOptions={{
              hasMore: skillOptions.items.length < skillOptions.total,
              threshold: 150,
              loadMore: loadMoreOptions,
            }}
            onInputChange={onChangeInputValue}
            autoFocus={props.focusField || !field.value}
            filterOption={optionsFilter}
            isValidNewOption={isValidNewOption(CardType.skill)(form.values)}
            openMenuOnClick={false}
            openMenuOnFocus={false}
            multilineInput
            creatable
          />
        );
      },
    [
      isReadOnly,
      isOptionsLoading,
      skillCardConfig.getStackItemTooltipProps,
      skillCardConfig.getSelectDropDownOptionTooltipProps,
      skillOptions.items,
      skillOptions.total,
      loadMoreOptions,
      onChangeInputValue,
      optionsFilter,
    ]
  );

  const titleLabel = useMemo(() => {
    return cardsConfig[values.cardType]?.getCriteriaCardTitleLabel?.(values);
  }, [values]);

  return (
    <CriteriaEditCard
      className={classNames({
        [cardCommonStyles.unwantedCard]: isSkillUnwanted,
      })}
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      stackItemControl={selectSkillValueControl}
      collapsibleArea={
        <CriteriaImportanceExclusionSettings
          namePrefix={namePrefix}
          cardData={values}
          onImportanceChange={changeCardImportance}
          onExclude={onUpdate}
        />
      }
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const skillTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];
              return skillTitlesList
                .slice(0, 1)
                .map((val: FormSelectEntity) => {
                  return (
                    <Field
                      key={R.prop('value', val) || 'new'}
                      name={`${namePrefix}.${topStackListName}.0`}
                    >
                      {({ field, form }: FieldProps) => (
                        <div
                          ref={mainCriteriaNameFieldRef}
                          className={cardCommonStyles.titleFieldWithImportance}
                        >
                          {titleLabel}
                          {selectSkillValueControl({
                            field,
                            form,
                            isDisabled: isImportanceSectionOpened || isReadOnly,
                            onKeyDown: getOnMainCardKeyDownCallback(
                              field,
                              arrayHelpers,
                              !isMaxListSizeReached
                            ),
                            onChange: async (value: { label: string }) => {
                              await form.setFieldValue(field.name, value);
                              updateMainFieldHandler(value);
                            },
                            focusField: !isReadOnly,
                            getSingleValueTooltipProps:
                              skillCardConfig.getMainCardTooltipProps,
                          })}
                          {importanceIndicator}
                        </div>
                      )}
                    </Field>
                  );
                });
            }}
          </FieldArray>
          <Field name={`${namePrefix}.experience`}>
            {({ field, form }: FieldProps) => (
              <WithDefaultValueOnConditionalMounting
                indicator={!isSkillUnwanted}
                defaultValue={null}
                field={field}
                form={form}
              >
                <IdealExperienceWidget
                  isReadOnly={isReadOnly}
                  values={field.value}
                  onChangeValues={(value) =>
                    form.setFieldValue(field.name, value)
                  }
                />
                {isIdealExperienceDefined(field.value) && (
                  <AcceptableExperienceWidget
                    isReadOnly={isReadOnly}
                    values={field.value}
                    onChangeValues={(value) =>
                      form.setFieldValue(field.name, value)
                    }
                  />
                )}
              </WithDefaultValueOnConditionalMounting>
            )}
          </Field>
          <Field name={`${namePrefix}.recent`}>
            {({ field, form }: FieldProps) => (
              <>
                {isReadOnly ? (
                  <RecentExperienceReadOnlyWidget value={field.value} />
                ) : (
                  <RecentExperienceWidget
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  />
                )}
              </>
            )}
          </Field>
          <Field name={`${namePrefix}.detailsDemand`}>
            {({ field, form }: FieldProps) => {
              return (
                <WithDefaultValueOnConditionalMounting
                  indicator={!isSkillUnwanted}
                  defaultValue={skillDetailsDemandOptionsList[0]}
                  field={field}
                  form={form}
                >
                  <DetailsSelect
                    {...field}
                    isDisabled={isReadOnly}
                    className={cardCommonStyles.detailsRequiredField}
                    placeholder="additional details"
                    options={skillDetailsDemandOptionsList}
                    onChange={(value: ValueType<OptionTypeBase>) =>
                      form.setFieldValue(field.name, value)
                    }
                  />
                </WithDefaultValueOnConditionalMounting>
              );
            }}
          </Field>
        </>
      )}
    </CriteriaEditCard>
  );
};
