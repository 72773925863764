import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import R from '@air/third-party/ramda';
import { toast } from '@air/third-party/toast';

import {
  SearchResponseV2,
  SearchProgressStatusEnum,
  SearchUpdateStatusResponse,
  SearchCreateRequest,
  SearchExtendedResponse,
} from '@air/api';
import { CurrentSearchT, SearchListItemT } from 'domain/Kanban/Kanban';

import * as InterviewApi from 'features/InterviewSection/interviewApi';

import { SERVER_ERROR, ENTITY_NOT_FOUND } from '@air/constants/httpCodes';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';
import { KanbanContextT } from 'context';
import { createContext, useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';

export type EditInterviewContextT = {
  interviewDraftsList: SearchListItemT[];
  currentInterviewDraft: CurrentSearchT | null;
  methods: {
    updateInterviewDraft?: (searchId: number, search: CurrentSearchT) => void;
    applyInterviewDraft?: () => Promise<CurrentSearchT>;
    fetchInterviewDrafts?: (params?: { createDraftIfEmpty: boolean }) => void;
    fetchInterviewDraftById?: (searchId: number) => void;
    removeInterviewDraft?: (searchId: number) => void;
    resetDrafts?: () => void;
  };
};
export const EditInterviewContext = createContext<EditInterviewContextT>({
  interviewDraftsList: null,
  currentInterviewDraft: null,
  methods: {},
});
EditInterviewContext.displayName = 'EditInterviewContext';

type Props = RouteComponentProps & {
  contextValue: KanbanContextT;
};

type State = {
  interviewDraftsList: SearchListItemT[];
  currentInterviewDraft: CurrentSearchT;
  wasDraftRemoved: boolean;
};

export class EditInterviewProvider extends React.Component<Props, State> {
  state: State = {
    interviewDraftsList: null,
    currentInterviewDraft: null,
    /*
      AR-9337: This flag is necessary to handle possible race conditions
      appearing if user discards draft very fast immediately after creating
      interview draft. Race condition is caused by SSE notifying of updated
      counters for interview draft. This SSE triggers refetching of interviewDraftsList,
      but the response from this fetch sometimes arrives later than currentInterviewDraft
      removal. So, we need a way to distinguish, whether we should store the
      response from fetching interviewsDraftsList or not.
    */
    wasDraftRemoved: false,
  };

  fetchInterviewDrafts = ({
    createDraftIfEmpty,
  }: { createDraftIfEmpty?: boolean } = {}) => {
    const {
      contextValue: { currentSearch },
    } = this.props;
    const { searchId } = currentSearch;

    return InterviewApi.fetchInterviewChildSearches(searchId).fork(
      () => {
        toast.error(phrases.NO_DRAFT_SEARCHES);
      },
      async (interviewDraftsList: SearchResponseV2[]) => {
        /*
          A search cannot have more than a single draft, but the only way to find out
          if it has any – is to fetch available drafts for this `searchId`.
          If it doesn't have any drafts at the moment when user enters EditInterviewSection,
          we should immediately create one, because when modifying criteria – user
          actually makes edit to this draft, and after applying, the draft replaces original
          search.
        */
        if (!interviewDraftsList.length && createDraftIfEmpty) {
          return await this.createInterviewDraft(currentSearch);
        }

        /*
          As of now, every published search (interview) may have only
          single draft copy.
          Thus, we can extract this single draft directly from the
          current response and put in into Provider's state.
        */
        const [currentInterviewDraft = null] = interviewDraftsList;
        this.setState((state) =>
          state.wasDraftRemoved
            ? {
                ...state,
                wasDraftRemoved: false,
              }
            : {
                currentInterviewDraft,
                interviewDraftsList,
                wasDraftRemoved: state.wasDraftRemoved,
              }
        );
      }
    );
  };

  fetchInterviewDraftById = (searchId: number) => {
    InterviewApi.fetchInterviewById(searchId).fork((err: Response) => {
      if (err.status === ENTITY_NOT_FOUND) {
        toast.warn(phrases.INTERVIEW_DRAFT_NOT_FOUND);
      }
      return Promise.resolve(err);
    }, this.replaceLocalInterviewDraft);
  };

  replaceLocalInterviewDraft = (res: SearchExtendedResponse) => {
    this.setState((state) => ({
      interviewDraftsList: (state.interviewDraftsList || []).map((draft) =>
        draft.searchId === res.searchId ? res : draft
      ),
      currentInterviewDraft: res,
    }));

    return Promise.resolve(res);
  };

  createInterviewDraft = (currentSearch: CurrentSearchT) => {
    const { searchId } = currentSearch;

    const requestBody: any = {
      ...R.pick(
        ['ats', 'name', 'criteria', 'redFlags', 'specializations'],
        currentSearch
      ),
      parentId: searchId,
      order: null,
    };

    /*
      Since Interview's draft will have same search parameters as
      it's parent search, we may safely use it to perform optimistic
      update on UI.
    */
    const tempDraft = {
      ...currentSearch,
      status: SearchProgressStatusEnum.DRAFT,
    };
    this.setState({
      currentInterviewDraft: tempDraft,
      interviewDraftsList: [tempDraft],
      wasDraftRemoved: false,
    });

    return InterviewApi.createInterviewDraft(requestBody).fork(
      () => {
        toast.error(phrases.ERROR_DRAFT_COPY_CREATE);
        this.resetDrafts();
        this.props.history.replace(urls.ROOT_ROUTE);
      },
      (res: SearchExtendedResponse) => {
        this.setState(() => {
          return {
            interviewDraftsList: [res],
            currentInterviewDraft: res,
          };
        });
      }
    );
  };

  updateInterviewDraft = async (
    searchId: number,
    request: SearchCreateRequest,
    fullUpdate?: boolean
  ) => {
    /*
      If user is trying to update tempDraft, we don't need to send
      requests to server, since we don't know corrent searchId to update.
    */
    const {
      contextValue: { currentSearch },
    } = this.props;
    if (searchId === currentSearch.searchId) return;

    return InterviewApi.updateInterviewDraft(
      searchId,
      request,
      fullUpdate
    ).fork(
      R.compose((err: Response) => {
        if (err.status === ENTITY_NOT_FOUND) {
          toast.warn(phrases.INTERVIEW_DRAFT_NOT_FOUND);
        }
        return Promise.resolve(err);
      }),
      this.replaceLocalInterviewDraft
    );
  };

  resetDrafts = () => {
    this.setState({
      interviewDraftsList: null,
      currentInterviewDraft: null,
      wasDraftRemoved: true,
    });
  };

  removeInterviewDraft = (searchId: number) => {
    this.resetDrafts();
    return InterviewApi.removeInterviewSearch(searchId).fork(
      R.compose((err: Response) => {
        if (err.status === ENTITY_NOT_FOUND) {
          toast.warn(phrases.INTERVIEW_DRAFT_NOT_FOUND);
        }
        return Promise.reject(err);
      }),
      R.identity
    );
  };

  applyInterviewDraft = () => {
    const {
      contextValue: { currentSearch },
    } = this.props;
    const { currentInterviewDraft } = this.state;
    if (!currentInterviewDraft) return;

    return InterviewApi.applyInterviewDraft(
      currentInterviewDraft.searchId
    ).fork(
      (err: any) => {
        if (err.status === ENTITY_NOT_FOUND) {
          toast.warn(phrases.INTERVIEW_DRAFT_NOT_FOUND);
          return;
        }
        if (err.status >= SERVER_ERROR) {
          toast.error(phrases.ERROR_INTERVIEW_UPDATE);
        }
      },
      (updatedStatus: SearchUpdateStatusResponse) => {
        /*
          When interview draft is applied to replace current interview,
          BE will check if any criteria in draft differ from parent interview,
          and if not, updatedStatus will contain same searchId as parent's
          meaning that no new search was created, and we can safely reuse
          existing parent interview's data.
          (We still need to use the data from currentInterviewDraft as well,
          because drafts' search name could have been modified, for example).
        */
        const newInterviewDraft = {
          ...(updatedStatus.searchId === currentSearch.searchId
            ? currentSearch
            : {}),
          ...currentInterviewDraft,
          ...updatedStatus,
          /**
           * Child search does not contain any counters, except appliedCount,
           * so we need to update currentInterviewDraft with counters from parentSearch
           * as well. This is required only for cases when new search was not created.
           */
          ...(updatedStatus.searchId === currentSearch.searchId && {
            pendingCount: currentSearch.activeCount.pendingCount,
            rejectedCount: currentSearch.activeCount.rejectedCount,
            processedCount: currentSearch.activeCount.processedCount,
            selectedCount: currentSearch.activeCount.selectedCount,
            matchedCount: currentSearch.activeCount.matchedCount,
          }),
          /*
          When interview draft is moved to interview, its parentId
          is cleared on server,
          but we do the same manually to avoid another request.
        */
          // @ts-ignore
          parentId: null,
          updated: Date.now(),
        };
        this.setState({
          wasDraftRemoved: false,
          currentInterviewDraft: newInterviewDraft,
        });
        return newInterviewDraft;
      }
    );
  };

  getContext = (): EditInterviewContextT => {
    return {
      ...this.state,
      methods: {
        fetchInterviewDrafts: this.fetchInterviewDrafts,
        fetchInterviewDraftById: this.fetchInterviewDraftById,
        updateInterviewDraft: this.updateInterviewDraft,
        removeInterviewDraft: this.removeInterviewDraft,
        resetDrafts: this.resetDrafts,
        applyInterviewDraft: this.applyInterviewDraft,
      },
    };
  };

  render() {
    const { children } = this.props;

    return (
      <EditInterviewContext.Provider value={this.getContext()}>
        {children}
      </EditInterviewContext.Provider>
    );
  }
}

export const useEditInterviewContext = <Selected,>(
  selector: (state: EditInterviewContextT) => Selected
) => {
  return useContextSelector(EditInterviewContext, selector);
};

export const useEditInterviewMethods = () => {
  return useEqualContextSelector(
    EditInterviewContext,
    (state: EditInterviewContextT) => state.methods,
    R.shallowEqualObjects
  );
};
