import React, { useState, useEffect, useCallback } from 'react';
import { toast } from '@air/third-party/toast';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';

import {
  UIText,
  TooltipWrapper,
  Paragraph,
  SvgIcon,
  Toggle,
  FormTypeahead,
  Button,
  Spinner,
} from '@air/components';
import * as WatchersApi from 'domain/WatchList/watchListApi';
import { WatcherResponse, WatchersListResponse } from '@air/api';
import * as phrases from 'constants/phrases';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

import styles from './WatchList.css';

const prepareOptions = (items: WatcherResponse[]) => {
  return items.map((item) => {
    return {
      value: item.id,
      label: `${item.firstName} ${item.lastName}`,
    };
  });
};

const checkIfUserIsWatcher = (items: WatcherResponse[], userId: number) => {
  if (!items?.length) return false;
  return R.any((item) => item.userId === userId, items);
};

const POTENTIAL_WATCHER = 'potentialWatcher';

type WatchListProps = {
  jobRequisitionId: string;
};

export const WatchList: React.FC<WatchListProps> = ({ jobRequisitionId }) => {
  const [isSubscriptionLoading, setSubscriptionLoading] = useState(false);
  const [showWatchersTypeahead, setShowWatchersTypeahead] = useState(false);
  const [watchersList, setWatchersList] = useState(null);
  const [isCurrentUserWatching, setCurrentUserWatching] = useState(false);

  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );

  const { control, watch } = useForm<Record<string, any>>({
    shouldUnregister: true,
  });

  const fetchWatchersList = useCallback(
    (jobDescriptionId: string, dataSourceId: number, userId: number) => {
      WatchersApi.fetchWatchersList({
        jobDescriptionId,
        dataSourceId,
      }).fork(
        () => toast.error(phrases.SOMETHING_WENT_WRONG_ERROR),
        (res: WatchersListResponse) => {
          setCurrentUserWatching(checkIfUserIsWatcher(res.content, userId));
          setWatchersList(res.content);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (jobRequisitionId && dataSourceId) {
      fetchWatchersList(jobRequisitionId, dataSourceId, customerId);
    }
  }, [jobRequisitionId, dataSourceId, customerId, fetchWatchersList]);

  const subscribeWatcher = useCallback(
    async (userId: number) => {
      setSubscriptionLoading(true);
      await WatchersApi.subscribeWatcher({
        jobDescriptionId: jobRequisitionId,
        dataSourceId,
        userId,
      }).fork(
        () => toast.error(phrases.SOMETHING_WENT_WRONG_ERROR),
        () => {
          if (userId === customerId) {
            setCurrentUserWatching(true);
          }
        }
      );
      setSubscriptionLoading(false);
      fetchWatchersList(jobRequisitionId, dataSourceId, customerId);
    },
    [dataSourceId, fetchWatchersList, jobRequisitionId, customerId]
  );

  const watchPotentialWatcherField = watch(POTENTIAL_WATCHER);

  useEffect(() => {
    const potentialWatcherId = watchPotentialWatcherField?.value;
    if (
      !isSubscriptionLoading &&
      potentialWatcherId &&
      !checkIfUserIsWatcher(watchersList, potentialWatcherId)
    ) {
      subscribeWatcher(potentialWatcherId);
      setShowWatchersTypeahead(false);
    }
  }, [
    isSubscriptionLoading,
    watchPotentialWatcherField,
    setShowWatchersTypeahead,
    watchersList,
    subscribeWatcher,
  ]);

  const unsubscribeWatcher = async (userId: number) => {
    setSubscriptionLoading(true);
    await WatchersApi.unsubscribeWatcher({
      jobDescriptionId: jobRequisitionId,
      dataSourceId,
      userId,
    }).fork(
      () => toast.error(phrases.SOMETHING_WENT_WRONG_ERROR),
      () => {
        if (userId === customerId) {
          setCurrentUserWatching(false);
        }
      }
    );
    setSubscriptionLoading(false);
    fetchWatchersList(jobRequisitionId, dataSourceId, customerId);
  };

  const onChangeToggle = async () => {
    if (isCurrentUserWatching) {
      unsubscribeWatcher(customerId);
    } else {
      await subscribeWatcher(customerId);
    }
  };

  const loadPotentialWatchersOptions = async (value: string) => {
    let result: { value: string | number; label: string }[] = [];
    if (value) {
      await WatchersApi.fetchPotentialWatchersList({
        jobDescriptionId: jobRequisitionId,
        dataSourceId,
        criteria: value,
      }).fork(
        () => toast.error(phrases.SOMETHING_WENT_WRONG_ERROR),
        (res: WatchersListResponse) => {
          result = prepareOptions(res?.content);
        }
      );
    }
    return result;
  };

  return (
    <div className={styles.watchList}>
      <div className={styles.withSidePadding}>
        <UIText tiny>Applicant watching</UIText>
        <TooltipWrapper
          enabled
          tooltip={phrases.WATCHING_DESCRIPTION}
          placement="bottom"
        >
          <label
            className={classNames(styles.selfWatchControlLabel, {
              [styles.selfWatchControlEnabled]: isCurrentUserWatching,
            })}
          >
            {R.isNil(watchersList) ? (
              <Spinner className={styles.firstTimeLoader} />
            ) : (
              <Toggle
                className={styles.toggleButton}
                onChange={isSubscriptionLoading ? R.noop : onChangeToggle}
                disabled={isSubscriptionLoading}
                name="toggleSelfWatching"
                checked={isCurrentUserWatching}
              />
            )}
            <Paragraph short className={styles.toggleTitle}>
              {phrases.WATCHING_ACTION}
            </Paragraph>
          </label>
        </TooltipWrapper>
        <UIText tiny>{phrases.WATCHING_USERS_SUBTITLE}</UIText>
      </div>
      <div className={styles.watchersList}>
        {watchersList?.length > 0 ? (
          watchersList.map((watcher: WatcherResponse) => {
            return (
              <div className={styles.watcher} key={watcher.userId}>
                <Paragraph short className={styles.watcherName}>
                  {watcher.firstName} {watcher.lastName}
                </Paragraph>
                <button
                  className={styles.removeButton}
                  disabled={isSubscriptionLoading}
                  onClick={() => unsubscribeWatcher(watcher.userId)}
                >
                  <SvgIcon icon="cross-icon" />
                </button>
              </div>
            );
          })
        ) : (
          <Paragraph
            small
            className={classNames(styles.withSidePadding, styles.noResults)}
          >
            {phrases.NO_WATCHING_USERS}
          </Paragraph>
        )}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        {showWatchersTypeahead && (
          <form className={styles.addWatchersForm}>
            <Controller
              name={POTENTIAL_WATCHER}
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <FormTypeahead
                    id={POTENTIAL_WATCHER}
                    type="text"
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={phrases.ADD_WATCHERS_ACTION}
                    loadOptions={loadPotentialWatchersOptions}
                    noResultsMessage={phrases.NO_WATCHERS_FOUND_MESSAGE}
                    optionWithIcon
                    ref={ref}
                  />
                );
              }}
            />
          </form>
        )}
        {!showWatchersTypeahead && (
          <Button
            icon="add-stroke"
            className={styles.addWatchersButton}
            variant={Button.variants.DEFAULT}
            onClick={() => setShowWatchersTypeahead(true)}
          >
            {phrases.ADD_WATCHERS_ACTION}
          </Button>
        )}
      </div>
    </div>
  );
};

WatchList.displayName = 'WatchList';
