const createDownloadLink = function () {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  return {
    download({ url, filename }: { url: string; filename: string }) {
      a.download = filename;
      a.href = url;
      a.click();
      a.remove();
    },
  };
};

const getFileExtension = (type: string) => {
  switch (type) {
    case 'application/pdf':
      return '.pdf';
    case 'application/json':
      return '.json';
    case 'application/msword':
      return '.doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return '.docx';
    case 'application/rtf':
    case 'text/rtf':
      return '.rtf';
    case 'text/plain':
      return '.txt';
    default:
      return '';
  }
};
export const downloadBlob = (filename: string, fileExtension?: string) => (
  blob: Blob
) => {
  const url = window.URL.createObjectURL(blob);
  createDownloadLink().download({
    url,
    filename: `${filename}${fileExtension || getFileExtension(blob.type)}`,
  });
  window.URL.revokeObjectURL(url);
};

export const getFileNameFromHeader = (
  headers: Headers,
  defaultName: string,
  defaultExtension: string
) => {
  const defaultFileName = [defaultName, defaultExtension];
  if (!headers) return defaultFileName;

  const fileNameWithExtension = headers
    .get('content-disposition')
    ?.split('filename=')?.[1];

  return fileNameWithExtension?.split('.') || defaultFileName;
};
