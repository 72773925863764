import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import styles from './AutoSizeInput.css';

type AutoSizeInputProps = React.ComponentProps<'input'> & {
  extraWidth?: number;
  containerClassName?: string;
  widthCalculatorClassName?: string;
};

const EXTRA_WIDTH_TO_AVOID_ELLIPSIS = 10;
export const AutoSizeInput = React.forwardRef<
  HTMLInputElement,
  AutoSizeInputProps
>(
  (
    {
      className,
      containerClassName,
      widthCalculatorClassName = className,
      extraWidth = EXTRA_WIDTH_TO_AVOID_ELLIPSIS,
      ...restProps
    },
    ref
  ) => {
    const [content, setContent] = useState<AutoSizeInputProps['value']>('');
    const [width, setWidth] = useState(0);
    const spanRef = useRef<HTMLSpanElement>();

    useEffect(() => {
      setWidth(spanRef.current.offsetWidth + extraWidth);
    }, [content, extraWidth]);

    useEffect(() => {
      setContent(restProps.value);
    }, [restProps.value]);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      restProps.onChange(event);
    };

    return (
      <div className={classNames(styles.autoSizeContainer, containerClassName)}>
        <span
          className={classNames(styles.autoSizeText, widthCalculatorClassName)}
          ref={spanRef}
        >
          {content || restProps.placeholder}
        </span>
        <input
          ref={ref}
          type="text"
          className={classNames(styles.autoSizeInput, className)}
          style={{ width }}
          {...restProps}
          onChange={changeHandler}
        />
      </div>
    );
  }
);
AutoSizeInput.displayName = 'AutoSizeInput';
