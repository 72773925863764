/* !IMPORTANT!
  Don't change string values in this file unless requested from BA as
  it can result in unwanted record duplication on Google Analytics
*/

/* Kanban */
export const GA_LABEL_MY_ITEMS = 'my-items-only';
export const GA_LABEL_ALL_ITEMS = 'all-items';
export const GA_LABEL_CHANGE_BACKGROUND = 'change-background';
export const GA_LABEL_CONTACT_US = 'contact-us';
export const GA_LABEL_SETTINGS = 'settings';

/* Draft */
export const GA_LABEL_START_NEW_DRAFT = 'start-new-draft';
export const GA_LABEL_FLAGS_DRAFT_TAB_OPENING = 'flags-draft-tab-opening';
export const GA_LABEL_VIEW_BY_RESOLUTION = 'view-by-resolution';
export const GA_LABEL_VIEW_BY_CATEGORY = 'view-by-category';
export const GA_LABEL_CHANGE_IMPORTANCE_BUTTON = 'change-importance-button';
export const GA_LABEL_START_SCREENING = 'start-screening';
export const GA_LABEL_ASSESS_APPLICANTS = 'assess-applicants';
export const GA_LABEL_DISCARD_DRAFT = 'discard-draft';
export const GA_LABEL_OPEN_JOB_DESCRIPTION = 'open-job-description';
export const GA_LABEL_CLOSE_JOB_DESCRIPTION = 'hide-job-description';
export const GA_LABEL_UPLOAD_CV_BUTTON = 'upload-cv-button';
export const GA_LABEL_FLAGS_UPLOAD_CV_DND = 'upload-cv-d-n-d';
export const GA_LABEL_BACK_TO_SCREENING = 'back-to-screening';
export const GA_LABEL_APPLY_CHANGES = 'apply-changes';

/* Screening */
export const GA_LABEL_TAB_EXPAND_REJECTED = 'rejected-tab-expand';
export const GA_LABEL_START_QUESTIONING = 'start-questions-autosending';
export const GA_LABEL_STOP_QUESTIONING = 'stop-questions-autosending';
export const GA_LABEL_START_INVITATIONS_PASSIVE =
  'start-invitations-autosending-passive';
export const GA_LABEL_STOP_INVITATIONS_PASSIVE =
  'stop-invitations-autosending-passive';
export const GA_LABEL_START_INVITATIONS_MM = 'start-invitations-autosending-MM';
export const GA_LABEL_STOP_INVITATIONS_MM = 'stop-invitations-autosending-MM';
export const GA_LABEL_MODIFY_CRITERIA = 'modify-criteria';
export const GA_LABEL_PAUSE_SCREENING = 'pause-screening';
export const GA_LABEL_RESUME_SCREENING = 'resume-screening';
export const GA_LABEL_RESTART_SCREENING = 'restart-screening';
export const GA_LABEL_COMPLETE_SCREENING = 'complete-screening';
export const GA_LABEL_CANDIDATE_STATUS_UPDATE = 'candidate-status-update';
export const GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_PASSIVE =
  'candidate-moved-to-applied-passive';
export const GA_LABEL_CANDIDATE_MOVED_TO_PASSIVE_APPLIED =
  'candidate-moved-to-passive-applied';
export const GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_MM =
  'candidate-moved-to-applied-MM';
export const GA_LABEL_CANDIDATE_MOVED_TO_PASSIVE_MM =
  'candidate-moved-to-passive-MM';
export const GA_LABEL_CRITERIA_TAB = 'criteria-tab';
export const GA_LABEL_DOCUMENTS_PREVIEW = 'documents-preview';
export const GA_LABEL_CANDIDATE_CARD_CHANGE_STATUS =
  'candidate-card-change-status';
export const GA_LABEL_CANDIDATE_ANSWER_REVIEW = 'mail-candidate-answer-review';
export const GA_LABEL_HOVER_OVER_TIMELINE = 'hover-over-timeline';
export const GA_LABEL_TIMELINE_CLICK_REVIEW_ENRICHMENT =
  'timeline-click-review-enrichment';
export const GA_LABEL_EDIT_FIRST_NAME = 'edit-first_name';
export const GA_LABEL_EDIT_LAST_NAME = 'edit-last_name';
export const GA_LABEL_EDIT_EMAILS = 'edit-emails-array';
export const GA_LABEL_CLICK_SOCIAL_MEDIA = 'click-social-media';
export const GA_LABEL_SUGGEST_KEYWORDS = 'suggest-keywords';

/* Contact us */
export const GA_LABEL_USER_GUIDE_CLICK = 'user-guide-click';

/* Settings */
export const GA_LABEL_CHANGE_USER_ROLE = 'change-user-role';
