import { CommentInfoResponse, InterviewStatusEnum } from '@air/api';

export interface BaseInterviewApiResponse {
  comments: CommentInfoResponse[];
  status: InterviewStatusEnum;
}

/**
 * This is a common interface for all RedFlags/Alerts,
 * where Leave/Read Note feature should be implemented
 */
export interface InterviewData {
  /**
   * refId of the particular Alert or RedFlag
   */
  refId: string;
  /**
   * Interview status
   */
  status: InterviewStatusEnum;
  /**
   * Info related to comments. Can be a subject of change if we need to hold an author type etc.
   */
  commentId?: string;
  comments?: string;
  read?: boolean;
}

export const interviewDataMapper = <T extends BaseInterviewApiResponse>(
  id: string,
  interview: T
): InterviewData => {
  const comment = interview?.comments?.[0];
  const commentId = comment?.id;

  return {
    refId: id,
    commentId: commentId,
    comments: comment?.text,
    status: interview?.status || null,
    read: comment?.read,
  };
};
