import { JobSectionItemDragObject } from 'features/JobsSection/JobsSection';

import {
  SearchCreateRequest,
  SearchProgressStatusEnum,
  SearchUpdateRequest,
} from '@air/api/models';

import * as urls from 'constants/urls';

import * as Http from '@air/utils/http';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';

export const createDraftSearch = (jobInfo: JobSectionItemDragObject) => {
  const body = transformJobData(jobInfo);

  return Http.post(urls.SEARCH_LIST_API)
    .bind(Http.withJsonBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateDraftSearch = (
  searchId: number,
  jobInfo: SearchUpdateRequest,
  // we update all by default unless we do renaming which doesn't
  // affect card criteria, in this case we do partial update `fullUpdate=false`
  fullUpdate = true
) => {
  return Http.put(urls.makeSearchResultApiUrl(searchId))
    .bind(Http.withQueryParams({ fullUpdate }))
    .bind(Http.withJsonBody(jobInfo))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const submitDraftSearch = (searchId: number) => {
  return Http.put(`${urls.makeUpdateSearchStatusApiUrl(searchId)}`)
    .bind(Http.withJsonBody({ status: SearchProgressStatusEnum.INTERVIEW }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const returnSearchToDraft = (searchId: number) => {
  return Http.put(`${urls.makeUpdateSearchStatusApiUrl(searchId)}`)
    .bind(Http.withJsonBody({ status: SearchProgressStatusEnum.DRAFT }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const removeDraft = (id: number) => {
  return Http.remove(urls.makeSearchResultApiUrl(id))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const createNewRequisition = ({
  name,
  ats,
  specializations,
}: SearchCreateRequest) => {
  return Http.post(urls.SEARCH_LIST_API)
    .bind(Http.withJsonBody({ name, ats, specializations }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

/**
 * utils:
 */
function transformJobData(
  jobInfo: JobSectionItemDragObject
): Partial<SearchCreateRequest> {
  const { jobId, ats, title, specializations } = jobInfo;

  return {
    name: title,
    specializations,
    ats: {
      id: ats.dataSourceId,
      externalJobDescriptionId: jobId,
    },
  };
}
