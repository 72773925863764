// imports from vendor deps
import React, { useCallback, useState } from 'react';
// import {} from 'ramda';
import classNames from 'classnames';

// imports from types
import { JobDescriptionFullResponse, JobDescriptionItem } from '@air/api';
import { FieldProps } from 'formik';
import { Props as EditableTitleProps } from 'components/EditableTitle/EditableTitle';

// imports from 'components'
import { EditableTitle, JobMetadata, SvgIcon } from 'components';

// imports from 'constants'
// import {} from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'

// imports from styles
import styles from './JobTitle.css';

// component proptypes
type Props = {
  title: string;
  className?: string;
  titleClassName?: string;
  autoFocus?: boolean;
  onBlur?: (value: string) => void;
  jobRequisitionDetails?: JobDescriptionFullResponse | JobDescriptionItem;
  created?: number;
  isStandaloneAtsUser?: boolean;
  isBeforeDraftCreation?: boolean;
} & Partial<EditableTitleProps>;

// exports / component definitions
export const JobTitle: React.FC<Props> = ({
  className = '',
  titleClassName = '',
  title,
  onBlur,
  jobRequisitionDetails,
  created,
  isStandaloneAtsUser,
  isBeforeDraftCreation = false,
  ...restProps
}) => {
  const [, setTitleEditingState] = useState(false);
  const onTitleFocus = useCallback(() => {
    setTitleEditingState(true);
  }, []);
  const onTitleBlur = useCallback(
    (value: any) => {
      setTitleEditingState(false);
      onBlur(value);
    },
    [onBlur]
  );
  const [isMetadataVisible, setMetadataVisible] = useState(false);
  const hasJobRequisition =
    !!jobRequisitionDetails && 'owners' in jobRequisitionDetails;

  return (
    <div className={classNames(styles.jobTitleWithDescription, className)}>
      <div className={styles.jobTitleWrapper}>
        <EditableTitle
          value={title}
          className={classNames(titleClassName)}
          onFocus={onTitleFocus}
          onChange={onTitleBlur}
          {...restProps}
        />
        {hasJobRequisition && (
          <button
            type="button"
            className={classNames(styles.jobMetadataToggle, {
              [styles.jobMetadataTogglePressed]: isMetadataVisible,
            })}
            onClick={() => setMetadataVisible((v) => !v)}
          >
            <SvgIcon icon="shevron-down" />
          </button>
        )}
      </div>
      {hasJobRequisition && (
        <JobMetadata
          className={classNames(styles.jobMetadata, {
            [styles.jobMetadataVisible]: isMetadataVisible,
          })}
          jobRequisitionDetails={jobRequisitionDetails}
          created={created}
          isStandaloneAtsUser={isStandaloneAtsUser}
          isBeforeDraftCreation={isBeforeDraftCreation}
        />
      )}
    </div>
  );
};

export const FormikJobTitle: React.FC<
  Props &
    FieldProps & {
      handleChange: (fieldName: string, value: string) => void;
    }
> = ({ field, handleChange, ...props }) => {
  const onFormikChange = useCallback(
    (value: any) => {
      handleChange(field.name, value);
    },
    [handleChange, field.name]
  );

  return <JobTitle {...props} title={field.value} onBlur={onFormikChange} />;
};
