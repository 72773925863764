import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, OverlayPanel, UIText } from '@air/components';
import * as phrases from '@air/constants/phrases';

import styles from './PaginatedWizard.css';

type PaginatedWizardT<StepT> = {
  opened: boolean;
  onClose: () => void;
  overlayClassName?: string;
  panelClassName?: string;
  closeButtonText?: string;
  // this is an optional left column
  panelComponent?: React.FC<{ step: StepT }>;
  stepComponent: React.FC<{
    step: StepT;
    currentStepNumber: number;
  }>;
  steps: StepT[];
};

export const PaginatedWizard = <StepT extends Record<any, any>>({
  opened,
  onClose,
  overlayClassName,
  panelClassName,
  closeButtonText = phrases.BUTTON_FINISH,
  panelComponent,
  stepComponent,
  steps,
}: PaginatedWizardT<StepT>) => {
  const [currentStepNumber, setCurrentStepNumber] = useState(1);

  useEffect(() => {
    if (!opened) {
      // set step to 1 when closing the wizard but wait until disappearing animation is played
      // so that it does not blink from current step to 1
      setTimeout(() => setCurrentStepNumber(1), 300);
    }
  }, [opened]);

  const currentStep = steps[currentStepNumber - 1];
  const stepsTotal = steps.length;
  const PanelComponent = panelComponent;
  const StepComponent = stepComponent;

  return (
    <OverlayPanel
      visible={opened}
      className={classNames(
        styles.paginatedWizardOverlayPanel,
        overlayClassName
      )}
      position={OverlayPanel.positions.center}
      onClose={onClose}
    >
      <div className={styles.paginatedWizard}>
        {PanelComponent && (
          <div className={panelClassName}>
            <PanelComponent step={currentStep} />
          </div>
        )}
        <div className={styles.description}>
          <div className={styles.content}>
            <StepComponent
              step={currentStep}
              currentStepNumber={currentStepNumber}
            />
          </div>
          <div className={styles.navigation}>
            <UIText bold>
              {currentStepNumber}/{stepsTotal}
            </UIText>
            <div className={styles.controls}>
              {currentStepNumber !== 1 && (
                <Button
                  small
                  variant={Button.variants.POSITIVE_SECONDARY}
                  onClick={() => setCurrentStepNumber(currentStepNumber - 1)}
                >
                  {phrases.BUTTON_BACK}
                </Button>
              )}
              {currentStepNumber === stepsTotal ? (
                <Button
                  small
                  className={styles.nextButton}
                  variant={Button.variants.POSITIVE_CONFIRM}
                  onClick={onClose}
                >
                  {closeButtonText}
                </Button>
              ) : (
                <Button
                  small
                  className={styles.nextButton}
                  variant={Button.variants.POSITIVE_CONFIRM}
                  onClick={() => setCurrentStepNumber(currentStepNumber + 1)}
                >
                  {phrases.BUTTON_NEXT}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </OverlayPanel>
  );
};

PaginatedWizard.displayName = 'PaginatedWizard';
