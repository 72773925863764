import { SearchExtendedResponse } from '@air/api';
import { mapSearchCriteriaToHeaderData } from 'domain/HeaderData/HeaderDataMapper';
import { JobSectionItemDragObject } from 'features/JobsSection/JobsSection';
import R from '@air/third-party/ramda';

/**
 * This module contains normalization functions for three types of data structures used in customer portal sections:
 * 1. Preview/Interview Criteria section - data needed for cards in headers.
 * 2. Preview/Interview Criteria section - data needed for candidates lineup table.
 * 3. Candidate Profile section - data needed for cards in candidate profiles.
 *
 * Two main sources for data structures are: `SearchCriteriaV2` and `CandidateSearchProfile`.
 *
 * Data for headers: `SearchCriteriaV2` is used in `mapSearchResponseToViewData`.
 * Criteria are mapped to data structures of type `HeaderCriteriaData`, which is an extension of SearchCriteriaData
 * used in SearchForm.
 *
 * Data for lineup table: `CandidateSearchProfile` is mapped to lists of cards (grouped by criteria types)
 *
 * Data for candidate profile: `CandidateSearchProfile` is also mapped to lists of cards (grouped by criteria types)
 */

/**
 * Map `SearchExtendedResponse` data to view data
 * This view data is used to build lineup table structure (it defines which sections
 * are present, and how criteria are organized inside sections).
 * TODO: for further refactoring - define what exact data should we propagate to search view results
 *  and pass it explicitly
 */
export const mapSearchResponseToViewData = (data: SearchExtendedResponse) => {
  const currentCriteria = mapSearchCriteriaToHeaderData(data.criteria);
  return {
    ...data,
    currentCriteria,
  };
};

export const normalizeDraft = (jobInfo: JobSectionItemDragObject) => {
  return {
    ...jobInfo,
    title: jobInfo.title?.trim(),
    jobRequisitionDetails: R.isNullOrEmpty(jobInfo.jobRequisitionDetails)
      ? jobInfo.jobRequisitionDetails
      : {
          ...jobInfo.jobRequisitionDetails,
          title: jobInfo.jobRequisitionDetails.title?.trim(),
        },
  };
};
