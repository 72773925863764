import { useEffect } from 'react';
import { CurrentSearchT } from 'domain/Kanban/Kanban';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors, uploadApplicantsSelectors } from 'selectors';
import {
  useUploadApplicantsContext,
  useUploadApplicantsMethods,
} from 'providers/UploadApplicantsProvider';

type UseUploadsT = {
  currentSearch: CurrentSearchT;
  isUploadsListOpen: boolean;
  setUploadsListOpen: (isUploadsListOpen: boolean) => void;
  setUploadsStarted: (jobRequisitionId: string, hasStarted: boolean) => void;
};

export const useUploads = ({
  currentSearch,
  isUploadsListOpen,
  setUploadsListOpen,
  setUploadsStarted,
}: UseUploadsT) => {
  const {
    setCurrentJobRequisitionId,
    getApplicants,
    updateRequisitionCounters,
  } = useUploadApplicantsMethods();
  const requisitionsUploadState = useUploadApplicantsContext(
    uploadApplicantsSelectors.requisitionsUploadState
  );
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const jobRequisitionId = currentSearch?.ats?.externalJobDescriptionId;
  const dataSourceId = currentSearch?.ats?.id;

  useEffect(() => {
    async function fetchData() {
      if (!isStandaloneAtsUser) {
        return;
      }
      if (jobRequisitionId && dataSourceId) {
        await setCurrentJobRequisitionId(jobRequisitionId);
        getApplicants(dataSourceId, jobRequisitionId);
      } else if (!jobRequisitionId && isStandaloneAtsUser) {
        await setCurrentJobRequisitionId(null);
      }
    }
    fetchData();
  }, [
    isStandaloneAtsUser,
    jobRequisitionId,
    setCurrentJobRequisitionId,
    dataSourceId,
    getApplicants,
  ]);

  // this effect is used to initialize counters for current requisition.
  // the side effect is that counters update will be called whenever appliedCount
  // will be updated - this will cause unnecessary update when:
  // 1) user uploads resume - requisition counters updated.
  // 2) user adds criteria - we submit the form and then re-fecth the search.
  //   Now appliedCount is update, and this re-triggers requisition counters update.
  //
  useEffect(() => {
    if (
      currentSearch?.activeCount?.totalCount &&
      currentSearch?.jobRequisitionDetails &&
      isStandaloneAtsUser
    ) {
      updateRequisitionCounters(
        currentSearch.activeCount.totalCount,
        jobRequisitionId
      );
    }
  }, [
    currentSearch?.activeCount?.totalCount,
    currentSearch?.jobRequisitionDetails,
    updateRequisitionCounters,
    jobRequisitionId,
    isStandaloneAtsUser,
  ]);

  useEffect(() => {
    if (
      requisitionsUploadState[jobRequisitionId] &&
      !isUploadsListOpen &&
      isStandaloneAtsUser
    ) {
      setUploadsListOpen(true);
    }
  }, [
    jobRequisitionId,
    requisitionsUploadState,
    isUploadsListOpen,
    setUploadsListOpen,
    isStandaloneAtsUser,
  ]);

  useEffect(() => {
    if (
      jobRequisitionId &&
      requisitionsUploadState[jobRequisitionId] &&
      isStandaloneAtsUser
    ) {
      setUploadsStarted(jobRequisitionId, false);
    }
  }, [
    jobRequisitionId,
    requisitionsUploadState,
    setUploadsStarted,
    isStandaloneAtsUser,
  ]);
};
