import R from '@air/third-party/ramda';
import {
  append,
  prepend,
  compose,
  findIndex,
  insert,
  Lens,
  lensIndex,
  remove,
  set,
  view,
  without,
} from 'ramda';
import genId from '@air/utils/uniqueId';
import { SearchCriteriaImportanceEnum } from '@air/api';
import {
  SearchCriteriaData,
  isSkill,
  isCertification,
  isMajor,
  isDegree,
  isInstitution,
  isRole,
  SearchCriteriaListValue,
  isCompany,
  SearchCriteriaWithList,
  SearchEducationCriteria,
  ImportanceSelectedValueT,
  isQuestion,
} from 'domain/SearchCriteria';
import * as phrases from 'constants/phrases';
import { isIndustry } from 'domain/SearchCriteria/IndustryCriteriaData';
import { isLocation } from 'domain/SearchCriteria/LocationCriteriaData';

type PredicateT = (a: any) => (b: any) => boolean;
type GetItemFnT<T> = (
  id: number | string,
  data: T[]
) => [number, Lens<any, any>];

type GroupableT = {
  list: any[];
  idx: number;
  id: number;
  key: string;
  importance: ImportanceSelectedValueT;
};

export interface UpdateValuesFunction {
  (values: SearchCriteriaData[]): SearchCriteriaData[];
}

export function genericGetItem<T>(predicate: PredicateT): GetItemFnT<T> {
  return (predicateParam, list) => {
    const idx = findIndex(predicate(predicateParam), list);
    const lens = lensIndex(idx);

    return [idx, lens];
  };
}

export function createMergeHandlerFactory(itemGetter: any, listName = 'list') {
  return (targetId: any, sourceId: any) => (values: any) => {
    /*
      sourceId – item being dragged
      targetId – drop target (DT)
      Dragged item criteria should replace DT's criteria,
      but merge DT's list items with own.
    */
    const [, targetItemLens] = itemGetter(targetId, values);
    const [sourceItemIndex, sourceItemLens] = itemGetter(sourceId, values);

    if (sourceItemIndex < 0) {
      return;
    }

    const targetItem: SearchCriteriaData = view(targetItemLens, values);
    const sourceItem: SearchCriteriaData = view(sourceItemLens, values);

    return compose(
      remove(sourceItemIndex, 1),
      set(targetItemLens, {
        ...sourceItem,
        key: targetItem.key,
        idx: targetItem.idx,
        importance: targetItem.importance,
        // @ts-ignore
        [listName]: [...sourceItem[listName], ...targetItem[listName]],
      })
    )(values);
  };
}

export function createSingleCriteriaTransferFactory(
  itemGetter: any,
  subItemGetter: any,
  listName: 'list' = 'list'
) {
  return (targetId: any, sourceId: any, sourceGroupId: any) => (values: any) => {
    /*
      targetId – drop target
      sourceId – item being dragged
      sourceGroupId - source criteria card from which stack item is dragged
      Dragged item group's criteria should replace drop target's criteria,
      and put source card in on the first position in target's list.
    */
    const [, targetGroupLens] = itemGetter(targetId, values);
    const [, sourceGroupLens] = itemGetter(sourceGroupId, values);

    const targetGroup: GroupableT = view(targetGroupLens, values);
    const sourceGroup: GroupableT = view(sourceGroupLens, values);

    const [, subItemLens] = subItemGetter(sourceId, sourceGroup[listName]);
    const movedSubItem = view(subItemLens, sourceGroup[listName]);

    const updateTargetGroup = set(targetGroupLens, {
      ...sourceGroup,
      id: targetGroup.id,
      idx: targetGroup.idx,
      key: targetGroup.key,
      importance: targetGroup.importance,
      [listName]: prepend(movedSubItem, targetGroup[listName]),
    });

    const updateSourceGroup = set(sourceGroupLens, {
      ...sourceGroup,
      [listName]: without([movedSubItem], sourceGroup[listName]),
    });

    return compose(
      updateSourceGroup,
      // @ts-ignore
      updateTargetGroup
    )(values);
  };
}

export type GroupT = Record<'list', any> & SearchCriteriaData;

export function createSubCardMoveOutsideHandlerFactory<T extends GroupT>(
  itemGetter: any,
  subItemGetter: any
): (
  itemId: string | number,
  groupId: string | number,
  index: number
) => UpdateValuesFunction {
  return (itemId, itemGroupId, index) => (values: any) => {
    const [, groupLens] = itemGetter(itemGroupId, values);
    const group: T = view(groupLens, values);

    const [, itemLens] = subItemGetter(itemId, group.list);
    const item = view(itemLens, group.list);

    const updateSourceGroup = set(groupLens, {
      ...group,
      list: without([item], group.list) as any[],
    });

    const updateItemsList =
      index >= 0
        ? insert(index, {
            ...group,
            id: itemId,
            key: genId(),
            list: [item],
          })
        : append({
            ...group,
            id: itemId,
            key: genId(),
            list: [item],
          });

    return compose(updateItemsList, updateSourceGroup)(values);
  };
}

export function createRemoveSubCardHandlerFactory(
  itemGetter: any,
  subItemGetter: any
) {
  return (itemId: any, itemGroupId: any, listName = 'list') =>
    (values: any) => {
      const [, groupLens] = itemGetter(itemGroupId, values);
      const group: SearchCriteriaData = view(groupLens, values);

      // @ts-ignore
      const [, subItemLens] = subItemGetter(itemId, group[listName]);
      // @ts-ignore
      const subItem = view(subItemLens, group[listName]);

      return set(groupLens, {
        ...group,
        // @ts-ignore
        [listName]: without([subItem], group[listName]),
      })(values);
    };
}

export const importanceToLabel = {
  [SearchCriteriaImportanceEnum.MANDATORY]: phrases.MANDATORY_SECTION_TITLE,
  [SearchCriteriaImportanceEnum.IMPORTANT]: phrases.IMPORTANT_SECTION_TITLE,
  [SearchCriteriaImportanceEnum.OPTIONAL]: phrases.OPTIONAL_SECTION_TITLE,
};

/*
  To filter-out cards with empty titles in stack/main title,
  we need to check all lists with empty labels here,
  before we send update request.

  Note, for skill and certification cards, empty list of items means the
  card should be discarded.
*/
export function filterOutEmptyCriteriaLists(
  criteria: SearchCriteriaData[]
): SearchCriteriaData[] {
  return criteria
    .map((value: SearchCriteriaData) => {
      if (
        isSkill(value) ||
        isCertification(value) ||
        isCompany(value) ||
        isLocation(value) ||
        isIndustry(value) ||
        isRole(value)
      ) {
        return filterCriteria(value);
      } else if (isMajor(value) || isDegree(value) || isInstitution(value)) {
        return filterEducationCriteria(value);
      } else if (isQuestion(value)) {
        return filterEmptyTitles(value.question) ? value : null;
      }
      return value;
    })
    .filter(Boolean);
}

function filterEmptyTitles(value: SearchCriteriaListValue) {
  return !R.isNil(value) && value.label.toString() !== '';
}

function filterCriteria<T extends SearchCriteriaWithList>(
  criteria: T
): T | null {
  const filteredList = criteria?.list?.filter?.(filterEmptyTitles);
  if (filteredList.length > 0) {
    return { ...criteria, list: filteredList };
  }
  return null;
}

function filterEducationCriteria<T extends SearchEducationCriteria>(
  criteria: T
): T {
  const filteredList = !R.isNullOrEmpty(criteria.idealList)
    ? criteria.idealList.filter(filterEmptyTitles)
    : [];

  return {
    ...criteria,
    idealList: filteredList.length > 0 ? filteredList : null,
    acceptableList:
      filteredList.length > 0
        ? criteria.acceptableList?.filter(filterEmptyTitles)
        : null,
  };
}
