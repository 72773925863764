import React, { CSSProperties } from 'react';
import Select, { components, ValueType } from 'react-select';

import {
  CriteriaFormDropdownWrapper,
  SelectOption,
  SvgIcon,
} from '@air/components';
import { sharedSelectStyles } from '@air/components/Select/Select';
import styles from './ExperienceSelect.css';
import { FormSelectEntity } from '@air/components/Select/typings';
import classNames from 'classnames';
import { OptionTypeBase } from 'react-select/src/types';

const emptyComponentFn = (): any => null;

export const experienceSelectStyles = {
  ...sharedSelectStyles,
  menu: (): CSSProperties => ({
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: 'none',
    width: 'calc(100% + 2em)',
  }),
  valueContainer: () => ({
    width: 'auto',
    lineHeight: '1.2em',
    display: 'flex',
    cursor: 'pointer',
  }),
  input: () => ({}),
};

const experienceComponents = {
  IndicatorsContainer: emptyComponentFn,
  MultiValueRemove: emptyComponentFn,
  MenuList: (props: any) => {
    return (
      <CriteriaFormDropdownWrapper {...props}>
        {props.children}
      </CriteriaFormDropdownWrapper>
    );
  },
  Option: (props: any) => {
    const { label, value } = props.data;
    const data = {
      label,
      isSelected: props.isSelected,
      icon: props.isSelected ? 'checkbox-checked' : 'checkbox-unchecked',
    };

    const onClicked = () => {
      props.selectProps.onChange({ ...props.data, value });
      props.selectProps.onMenuClose();
    };

    return (
      <SelectOption
        {...data}
        icon={<SvgIcon width="1.5em" height="1.5em" icon={data.icon} />}
        onClick={onClicked}
      />
    );
  },
  ValueContainer: ({ children, ...props }: any) => {
    return (
      <>
        <components.ValueContainer {...props} {...children}>
          <div className={styles.valueTitle}>{children}</div>
        </components.ValueContainer>
      </>
    );
  },
};

export const ExperienceReadOnlyWidget: React.FC<{
  children: any;
  className?: string;
}> = ({ children, className = '' }) => {
  return (
    <div
      className={classNames(
        styles.valueTitle,
        styles.experienceReadOnlyField,
        className
      )}
    >
      {children}
    </div>
  );
};
ExperienceReadOnlyWidget.displayName = 'ExperienceReadOnlyWidget';

type Props = {
  className?: string;
  options: FormSelectEntity[];
  onChange: (value: ValueType<OptionTypeBase>) => void;
  props?: any;
  isDisabled?: boolean;
};

export const ExperienceSelect: React.FC<Props> = ({
  options,
  onChange,
  ...props
}) => {
  return (
    <Select
      {...props}
      className={styles.experienceField}
      components={experienceComponents}
      styles={experienceSelectStyles}
      classNamePrefix="autocompleteSelect"
      options={options}
      onChange={onChange}
    />
  );
};
