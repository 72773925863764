import {
  MessageHandler,
  UploadMessage,
  UploadMessageType,
} from 'domain/ResumeUpload/types';

export class Queue<T> {
  private _queue: T[] = [];

  size() {
    return this._queue.length;
  }

  isEmpty() {
    return this.size() === 0;
  }

  enqueue(item: T) {
    this._queue.push(item);
  }

  dequeue(): T | undefined {
    return this._queue.shift();
  }
}

export class MessageQueue<T extends UploadMessage> {
  private _handlers: {
    [type in UploadMessageType]: MessageHandler<T>[];
  } = {} as { [type in UploadMessageType]: MessageHandler<T>[] };

  constructor() {}

  send(messageData: T) {
    if (messageData && this._handlers?.[messageData.type]) {
      this._handlers[messageData.type].forEach((handler) => {
        handler.handleEvent.call(handler, messageData.payload);
      });
    }
  }

  addHandler(eventType: UploadMessageType, listener: MessageHandler<T>) {
    if (!this._handlers[eventType]) {
      this._handlers[eventType] = [];
    }
    this._handlers[eventType].push(listener);
  }

  removeHandler(eventType: UploadMessageType, listener?: MessageHandler<T>) {
    if (this._handlers[eventType]) {
      this._handlers[eventType] = this._handlers[eventType].filter(
        (handler) => {
          return handler !== listener;
        }
      );
    }
  }
}
