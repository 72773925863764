/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum LinkType {
  Skype = 'skype',
  Linkedin = 'linkedin',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Twitter = 'twitter',
  Stackoverflow = 'stackoverflow',
  Github = 'github',
  Gitlab = 'gitlab',
  Bitbucket = 'bitbucket',
  Coursera = 'coursera',
  Acclaim = 'acclaim',
  Behance = 'behance',
  Dribbble = 'dribbble',
  Kaggle = 'kaggle',
  Upwork = 'upwork',
  Other = 'other',
}
