import { useCallback, useEffect, useMemo, useState } from 'react';
import R from '@air/third-party/ramda';
import {
  MatchMinerLoadMoreStatus,
  MatchMinerProcessingCandidateStateEnum,
  MatchMinerStatus,
  MatchScoutLoadMoreStatus,
  MatchScoutProcessingCandidateStateEnum,
  MatchScoutStatus,
  SearchProgressStatusEnum,
} from '@air/api';
import {
  CardType,
  requiredMatchServiceImportanceLevels,
} from 'domain/SearchCriteria';
import {
  getLoadMoreTooltipText,
  MatchMinerSetupSettings,
  MatchScoutSetupSettings,
  MAX_CANDIDATES_LIMIT,
  MAX_MM_CANDIDATES_TO_REQUEST,
} from 'domain/MatchServices/MatchServices';
import { LineupTabs } from '@air/constants/tabs';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import { useModifyCriteriaCallback } from 'features/InterviewSection/useModifyCriteriaCallback';
import { emit } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import {
  isMatchMinerTab,
  isMatchScoutTab,
} from 'domain/CandidateData/CandidateLineupData';
import {
  isMatchMinerEnabled,
  isMatchScoutEnabled,
} from 'domain/CustomerProfile/matchServiceSettings';

export function useMatchService(tab: LineupTabs) {
  const parentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const isCurrentMatchMinerSearchLoaded = useKanbanContext(
    kanbanSelectors.isCurrentMatchMinerSearchLoaded
  );
  const isCurrentMatchScoutSearchLoaded = useKanbanContext(
    kanbanSelectors.isCurrentMatchScoutSearchLoaded
  );
  const parentJobDescriptionId = useKanbanContext(
    kanbanSelectors.externalJobDescriptionId
  );
  const { hasRequiredMatchServiceCriteria } =
    useMatchServiceAvailabilityCheck(tab);

  const matchMinerSettings = useCustomerProfileContext(
    customerProfileSelectors.matchMinerSettings
  );
  const matchScoutSettings = useCustomerProfileContext(
    customerProfileSelectors.matchScoutSettings
  );

  const isMatchMinerSearchEnabled = isMatchMinerEnabled(
    matchMinerSettings?.status
  );
  const isMatchScoutSearchEnabled = isMatchScoutEnabled(
    matchScoutSettings?.status
  );

  const isMatchMinerView = isMatchMinerTab(tab);
  const isMatchScoutView = isMatchScoutTab(tab);

  const currentMatchMinerSearch = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearch
  );
  const currentMatchScoutSearch = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearch
  );
  const { startMatchMinerSearch, startMatchScoutSearch } = useKanbanMethods();
  const isMatchMinerSearchStarted =
    currentMatchMinerSearch?.matchMinerSearch || false;
  const isMatchScoutSearchStarted =
    currentMatchScoutSearch?.matchScoutSearch || false;

  const showMatchMinerEmptySearchResults =
    isMatchMinerView &&
    // MatchMiner feature can be disabled for a client, after he already initiated some searches.
    (isMatchMinerSearchEnabled || isMatchMinerSearchStarted) &&
    isCurrentMatchMinerSearchLoaded;

  const showMatchScoutEmptySearchResults =
    isMatchScoutView &&
    // MatchScout feature can be disabled for a client, after he already initiated some searches.
    (isMatchScoutSearchEnabled || isMatchScoutSearchStarted) &&
    isCurrentMatchScoutSearchLoaded;

  const modifySearchCriteria = useModifyCriteriaCallback();

  const handleStartMatchMinerSearch = async (
    settings: MatchMinerSetupSettings
  ) => {
    emit(APP_EVENTS.SET_CANDIDATES_LIST_LOADING, LineupTabs.MatchMiner, true);
    startMatchMinerSearch(parentSearchId, settings).then(
      (res: { error?: string }) => {
        if (res?.error) {
          emit(
            APP_EVENTS.SET_CANDIDATES_LIST_LOADING,
            LineupTabs.MatchMiner,
            false
          );
        }
      }
    );
  };

  const handleStartMatchScoutSearch = async (
    settings: MatchScoutSetupSettings
  ) => {
    emit(APP_EVENTS.SET_CANDIDATES_LIST_LOADING, LineupTabs.MatchScout, true);
    startMatchScoutSearch(parentSearchId, settings).then(
      (res: { error?: string }) => {
        if (res?.error) {
          emit(
            APP_EVENTS.SET_CANDIDATES_LIST_LOADING,
            LineupTabs.MatchScout,
            false
          );
        }
      }
    );
  };

  switch (tab) {
    case LineupTabs.MatchMiner:
      return {
        dataSourceId,
        parentJobDescriptionId,
        canStartSearch: hasRequiredMatchServiceCriteria,
        showEmptyMatchServiceResults: showMatchMinerEmptySearchResults,
        startSearch: handleStartMatchMinerSearch,
        isSearchStarted: isMatchMinerSearchStarted,
        modifySearchCriteria,
        /*
          When MM search is extracting candidates from DB, it has a special field
          indicating that processing is in progress.
        */
        areMatchServiceCandidatesProcessing:
          !!currentMatchMinerSearch &&
          'matchMinerProcessingCandidateState' in currentMatchMinerSearch
            ? currentMatchMinerSearch.matchMinerProcessingCandidateState ===
              MatchMinerProcessingCandidateStateEnum.INPROGRESS
            : false,
      };
    case LineupTabs.MatchScout:
      return {
        dataSourceId,
        parentJobDescriptionId,
        canStartSearch: hasRequiredMatchServiceCriteria,
        modifySearchCriteria,
        startSearch: handleStartMatchScoutSearch,
        showEmptyMatchServiceResults: showMatchScoutEmptySearchResults,
        isSearchStarted: isMatchScoutSearchStarted,
        /*
          When MS search is extracting candidates from DB, it has a special field
          indicating that processing is in progress.
        */
        areMatchServiceCandidatesProcessing:
          !!currentMatchScoutSearch &&
          'matchScoutProcessingCandidateState' in currentMatchScoutSearch
            ? currentMatchScoutSearch.matchScoutProcessingCandidateState ===
              MatchScoutProcessingCandidateStateEnum.INPROGRESS
            : false,
      };
  }

  return {};
}

export const useMatchServiceAvailabilityCheck = (currentTab: LineupTabs) => {
  const currentCriteria = useKanbanContext(
    kanbanSelectors.currentSearchCurrentCriteria
  );
  const currentMatchMinerSearch = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearch
  );
  const currentMatchScoutSearch = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearch
  );
  const currentMatchServiceSearch = isMatchMinerTab(currentTab)
    ? currentMatchMinerSearch
    : currentMatchScoutSearch;

  const hasRequiredMatchServiceCriteria = useMemo(() => {
    if (!currentCriteria) return false;

    const criteria = Object.values(currentCriteria).flat();
    return !R.isNullOrEmpty(
      criteria.find(
        (item) =>
          (item.cardData.cardType === CardType.skill ||
            item.cardData.cardType === CardType.role) &&
          item.cardData.exclude === false &&
          requiredMatchServiceImportanceLevels.includes(
            item.cardData.importance.value
          )
      ) || null
    );
  }, [currentCriteria]);

  return {
    hasRequiredMatchServiceCriteria,
    hasMatchServiceSearchLoaded: !!currentMatchServiceSearch,
  };
};

export const useMatchServiceLoadMore = ({
  currentTab,
  disabled,
}: {
  currentTab: LineupTabs;
  disabled: boolean;
}) => {
  const matchMinerMetadata = useKanbanContext(
    kanbanSelectors.matchMinerMetadata
  );
  const matchScoutMetadata = useKanbanContext(
    kanbanSelectors.matchScoutMetadata
  );

  const parentSearchStatus = useKanbanContext(
    kanbanSelectors.currentSearchStatus
  );
  const matchMinerTotalCandidateCount = useKanbanContext(
    kanbanSelectors.matchMinerPassiveTotalCount
  );
  const matchScoutTotalCandidateCount = useKanbanContext(
    kanbanSelectors.matchScoutPassiveTotalCount
  );
  const currentMatchMinerSearchId = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearchId
  );
  const currentMatchScoutSearchId = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearchId
  );
  const { loadMoreApplicantsForMatchService } = useKanbanMethods();

  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const matchMinerSettings = useCustomerProfileContext(
    customerProfileSelectors.matchMinerSettings
  );
  const matchScoutSettings = useCustomerProfileContext(
    customerProfileSelectors.matchScoutSettings
  );
  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const { requestMatchMinerForCompany, requestMatchScoutForCompany } =
    useCustomerProfileMethods();

  const [areCandidatesLoading, setCandidatesLoading] = useState(false);

  const { availableCandidates, loadMoreStatus, possibleLoadMore } =
    isMatchMinerTab(currentTab) ? matchMinerMetadata : matchScoutMetadata;

  const candidatesCount = isMatchMinerTab(currentTab)
    ? matchMinerTotalCandidateCount
    : matchScoutTotalCandidateCount;

  const isCandidatesFetchingCompleted = isMatchMinerTab(currentTab)
    ? loadMoreStatus === MatchMinerLoadMoreStatus.COMPLETED
    : loadMoreStatus === MatchScoutLoadMoreStatus.COMPLETED;

  const shouldHideLoadMoreButton =
    isCandidatesFetchingCompleted &&
    /*
      Candidates count is undefined if we didn't fetch any candidates after
      match service start – this can happen if no candidates were selected at all.
      But in this case `availableCandidates` in metadata will be 0.
     */
    (candidatesCount === 0 || candidatesCount === undefined) &&
    availableCandidates === 0;

  const searchId = isMatchMinerTab(currentTab)
    ? currentMatchMinerSearchId
    : currentMatchScoutSearchId;

  const loadMoreApplicants = useCallback(
    (limit: number) => {
      if (areCandidatesLoading) return;

      setCandidatesLoading(true);
      loadMoreApplicantsForMatchService(searchId, limit, currentTab);
    },
    [
      loadMoreApplicantsForMatchService,
      searchId,
      areCandidatesLoading,
      currentTab,
    ]
  );

  useEffect(() => {
    if (isCandidatesFetchingCompleted) {
      /* AR-9582, process of loading candidates finishes before processing section is shown*/
      setTimeout(() => setCandidatesLoading(false), 4000);
    }
  }, [isCandidatesFetchingCompleted]);

  const [showLoadMoreTooltip, setShowLoadMoreTooltip] = useState(false);

  const closePopup = useCallback(() => {
    setShowLoadMoreTooltip(false);
  }, []);
  const { hasRequiredMatchServiceCriteria } =
    useMatchServiceAvailabilityCheck(currentTab);

  const onClick = () => {
    setShowLoadMoreTooltip(true);
  };

  const requestMoreAvailableCount = Math.max(
    MAX_CANDIDATES_LIMIT - candidatesCount,
    0
  );

  const { status, statusEnum, requestMatchService } = isMatchMinerTab(
    currentTab
  )
    ? {
        status: matchMinerSettings.status,
        statusEnum: MatchMinerStatus,
        requestMatchService: requestMatchMinerForCompany,
      }
    : {
        status: matchScoutSettings.status,
        statusEnum: MatchScoutStatus,
        requestMatchService: requestMatchScoutForCompany,
      };

  const isButtonDisabled =
    disabled ||
    (!areCandidatesLoading && !possibleLoadMore) ||
    isTrialExpired ||
    candidatesCount >= MAX_MM_CANDIDATES_TO_REQUEST ||
    // Both MM and MS statuses have same values.
    status === statusEnum.DISABLED ||
    status === statusEnum.ACCESSREQUESTED ||
    parentSearchStatus === SearchProgressStatusEnum.ONHOLD;

  const tooltipText = getLoadMoreTooltipText({
    tab: currentTab,
    isTrialExpired,
    status,
    customerRole: user.role,
    requestMatchServiceForCompany: requestMatchService,
    parentSearchStatus,
    possibleLoadMore,
    candidatesCount,
    hasRequiredMatchServiceCriteria,
  });

  switch (currentTab) {
    case LineupTabs.MatchMiner:
      return {
        loadMoreApplicants,
        areCandidatesLoading,
        tooltipText,
        shouldHideLoadMoreButton,
        isButtonDisabled,
        closePopup,
        showLoadMoreTooltip,
        onClick,
        requestMoreAvailableCount,
      };
    case LineupTabs.MatchScout:
      return {
        loadMoreApplicants,
        areCandidatesLoading,
        tooltipText,
        shouldHideLoadMoreButton,
        isButtonDisabled,
        closePopup,
        showLoadMoreTooltip,
        onClick,
        requestMoreAvailableCount,
      };
  }

  return {};
};
