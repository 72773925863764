import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  getCriteriaItems,
} from 'domain/CandidateData';
import {
  BaseCriteriaWithList,
  CardType,
  getCriteriaListId,
} from 'domain/SearchCriteria';
import { SearchCertificate, SearchCertificateList } from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateCertificationData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.certification;
}

const getCertificateItem = (
  listItem: SearchCertificate
): CandidateCriteriaListItem => {
  return {
    id: listItem.id || listItem.fullName,
    label: listItem.fullName,
    status: listItem.status,
    alert: getAlertData(listItem.alerts),
    interview: interviewDataMapper(listItem.refId, listItem.interview),
  };
};

export const mapCertificatesToCandidateCertificationData = (
  certificate: SearchCertificateList
): CandidateCertificationData => {
  const id = getCriteriaListId(certificate);

  const [list, primaryItem] = getCriteriaItems<
    SearchCertificateList,
    CandidateCriteriaListItem
  >(getCertificateItem, certificate);

  return {
    id,
    refId: certificate.refId,
    idx: certificate.idx,
    cardType: CardType.certification,
    list,
    importance: criteriaImportanceOptions[certificate.importance],
    status: primaryItem.status,
  };
};
