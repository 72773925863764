import R from '@air/third-party/ramda';
import { v4 as uuidv4 } from 'uuid';
import { ApiErrorResponse, AtsExternalProfileInfo } from '@air/api';
import {
  FileError,
  RejectedFile,
  Requisition,
  RequisitionFile,
  ExtendedRequisitionFile,
  RequisitionsT,
  currentRequisitionDefaultProps,
  AcceptedFile,
  RequisitionCounters,
} from 'context';
import * as phrases from 'constants/phrases';
import { getErrorDescription } from '@air/utils/errorHandling';
import { LineupTabs } from '@air/constants/tabs';

type ErrorFileFromBackEndT = {
  file?: File | FormDataEntryValue;
  errors?: ApiErrorResponse;
  progress?: number;
};

export type RequestT = {
  [key: string]: XMLHttpRequest;
};

export type FileT =
  | AtsExternalProfileInfo // successfully uploaded to server file
  | RequisitionFile // the constructed type that is used on UI
  | RejectedFile // rejected file from fileUploader
  | ErrorFileFromBackEndT; // rejected file with errors from back-end OR the file being uploaded with progress

export type RequisitionsProgressT = {
  [jobRequisitionId: string]: {
    [tempId: string]: number;
  };
};

export type UploaderRejectionsT = {
  [jobRequisitionId: string]: RequisitionFile[];
};

export const MAX_FILES_AMOUNT = 10000;

export const LINEUP_TABS_MAPPING = {
  [LineupTabs.Active]: phrases.LINEUP_TAB_ACTIVE_TITLE,
  [LineupTabs.Passive]: phrases.LINEUP_TAB_PASSIVE_TITLE,
  [LineupTabs.MatchMiner]: phrases.LINEUP_TAB_MATCH_MINER_TITLE,
  [LineupTabs.MatchScout]: phrases.LINEUP_TAB_MATCH_SCOUT_TITLE,
};

export const applicantsTypeOptions = [
  { value: LineupTabs.Active, label: LINEUP_TABS_MAPPING[LineupTabs.Active] },
  { value: LineupTabs.Passive, label: LINEUP_TABS_MAPPING[LineupTabs.Passive] },
];

export function getProgressPercentage(data: ProgressEvent, size: number) {
  if (size || data.total) {
    return Math.floor((data.loaded / (size || data.total)) * 100);
  }
  return 100;
}

export function normalizeApplicantsFiles(
  files: RequisitionFile[] | RejectedFile[]
): RequisitionFile[] {
  if (!files?.length) return [];

  return files.map((file) => {
    return {
      tempId: 'tempId' in file ? file.tempId : uuidv4(), // generate id on front-end before we have atsCandidateId from back-end
      atsCandidateId: ('atsCandidateId' in file && file.atsCandidateId) || null,
      resumeFileName:
        ('file' in file && file.file?.name) ||
        ('resumeFileName' in file && file.resumeFileName),
      // Progress is updated in the contextValue of UploadApplicantsProvider (from xhr data) to avoid stale closures
      // here is the default state for uploaded files or just added to the list files
      progress: 'atsCandidateId' in file && file.atsCandidateId ? 100 : 0,
      firstName: ('firstName' in file && file.firstName) || null,
      lastName: ('lastName' in file && file.lastName) || null,
      resumeMd5: ('resumeMd5' in file && file.resumeMd5) || null,
      errors: ('errors' in file && file.errors) || null,
      size:
        ('file' in file && file.file?.size) ||
        ('size' in file && file.size) ||
        0,
    };
  });
}

export const reconcileDuplicates = (
  newFiles: RequisitionFile[],
  existingFiles: RequisitionFile[]
): RequisitionFile[] => {
  if (!newFiles || !existingFiles) return newFiles || existingFiles;

  const newFilesNotUpdated: RequisitionFile[] = [...newFiles];
  const updatedExistingFiles = existingFiles.reduce((memo, existingFile) => {
    // User drops files to the screen and they first appear in th uploads list and then are sent to back-end.
    // After we have a response from back-end we need to show success/errors/progress for those files
    // that are already present in the list
    // if there is a duplicate from back-end show it in the list
    // Files in the list are `existingFiles` and `newFiles` are newly dropped to the screen
    // or returned from back-end responses/errors
    // Successfully uploaded to back-end files have `atsCandidateId`
    // `tempId` is a temporary id generated on front-end to be used until the file is uploaded

    let updatedFile = existingFile;

    for (const el of newFiles) {
      const areAtsCandidateIdsSame =
        existingFile.atsCandidateId &&
        el.atsCandidateId &&
        existingFile.atsCandidateId === el.atsCandidateId;
      const areTempIdsSame =
        existingFile.tempId && el.tempId && existingFile.tempId === el.tempId;
      const itemExists = areAtsCandidateIdsSame || areTempIdsSame; // id of uploaded file from back-end (not null) // id from front-end (not null)

      const idx = newFilesNotUpdated.indexOf(el);
      if (itemExists) {
        updatedFile = {
          ...el,
          atsCandidateId: existingFile.atsCandidateId || el.atsCandidateId,
          errors: existingFile.errors || el.errors,
        };
        if (idx >= 0) {
          delete newFilesNotUpdated[idx];
        }
      }
    }
    // if there is a file with atsCandidateId and no errors or progress
    // it means it is present in the list but we have not received it from back-end
    // we're removing it from the list
    const fileIsAlreadyProcessed =
      !!existingFile.atsCandidateId &&
      R.isNullOrEmpty(existingFile.errors) &&
      existingFile.progress < 100;
    return fileIsAlreadyProcessed ? memo : [updatedFile, ...memo];
  }, []);
  return [...R.reject(R.isNil, newFilesNotUpdated), ...updatedExistingFiles];
};

export type UploadRejectionT = {
  body: ApiErrorResponse;
  sentData?: any;
  tempId?: string;
  status: number;
};

type NormalizeRequisitionsT = {
  newFiles?: ExtendedRequisitionFile[];
  currentRequisition?: Requisition;
  uploadRejection?: UploadRejectionT;
};

export const getRequisitionCounters = (
  files?: RequisitionFile[]
): RequisitionCounters => {
  let failedCount = 0;
  let uploadedCount = 0;

  if (R.isNullOrEmpty(files)) {
    return {
      failedCount,
      uploadedCount,
      pendingCount: 0,
      totalCount: 0,
    };
  }

  for (const file of files) {
    if (file.atsCandidateId && R.isNullOrEmpty(file.errors)) {
      uploadedCount++;
    } else if (!R.isNullOrEmpty(file.errors)) {
      failedCount++;
    }
  }

  return {
    failedCount,
    uploadedCount,
    pendingCount: files.length - uploadedCount - failedCount,
    totalCount: files.length - failedCount,
  };
};

export function normalizeRequisition({
  newFiles, // new files to be added to the requisition
  currentRequisition,
  uploadRejection, // upload error from back-end (duplicates, general errors)
}: NormalizeRequisitionsT) {
  let newFilesNormalized: RequisitionFile[] = newFiles
    ? normalizeApplicantsFiles(newFiles)
    : [];

  if (!R.isNullOrEmpty(uploadRejection)) {
    newFilesNormalized = normalizeApplicantsFiles([
      {
        file: uploadRejection?.sentData.get('file'),
        tempId: uploadRejection?.tempId,
        errors: uploadRejection
          ? normalizeBackendErrors(uploadRejection?.body)
          : [],
      },
    ]);
  }

  const updatedFiles = reconcileDuplicates(
    newFilesNormalized,
    currentRequisition.files
  );

  return {
    ...currentRequisition,
    files: updatedFiles,
    ...getRequisitionCounters(updatedFiles),
    jobRequisitionId: currentRequisition.jobRequisitionId,
  };
}

export function normalizeBackendErrors(rej: ApiErrorResponse): FileError[] {
  return [
    {
      code: rej?.errorCode,
      message: getErrorDescription(rej, phrases.UPLOADER_ERROR),
    },
  ];
}

export function createFileFormData(file: AcceptedFile): FormData {
  const formdata = new FormData();
  formdata.append('file', file);
  return formdata;
}

export const rejectFilesByAtsCandidateId = (
  atsCandidateId: string,
  files: RequisitionFile[]
) =>
  R.reject<RequisitionFile, RequisitionFile[]>(
    R.propEq('atsCandidateId', atsCandidateId),
    files
  );

export const rejectFilesByTempId = (tempId: string, files: RequisitionFile[]) =>
  R.reject<RequisitionFile, RequisitionFile[]>(
    R.propEq('tempId', tempId),
    files
  );

// returns file rejections from 1 requisition
export const rejectUploadRejectionsByTempId = (
  tempId: string,
  uploaderRejections: UploaderRejectionsT,
  jobRequisitionId: string
): RequisitionFile[] => {
  if (
    R.isNullOrEmpty(uploaderRejections) ||
    !uploaderRejections[jobRequisitionId]
  ) {
    return [];
  }
  return rejectFilesByTempId(tempId, uploaderRejections[jobRequisitionId]);
};

export function updateFilesProgress(
  files: RequisitionFile[],
  requisitionsProgress: RequisitionsProgressT,
  jobRequisitionId: string
): RequisitionFile[] {
  if (
    R.isNullOrEmpty(requisitionsProgress) ||
    !requisitionsProgress[jobRequisitionId]
  ) {
    return files;
  }

  return files.map((file) => {
    return {
      ...file,
      progress:
        requisitionsProgress[jobRequisitionId][file.tempId] || file.progress,
    };
  });
}

export function getCurrentRequisition(
  requisitions: RequisitionsT,
  jobRequisitionId: string
): Requisition {
  return !jobRequisitionId ||
    R.isNullOrEmpty(requisitions) ||
    !requisitions[jobRequisitionId]
    ? currentRequisitionDefaultProps
    : requisitions[jobRequisitionId];
}

export function getCurrentItem<T>(
  allItems: { [jobRequisitionId: string]: T },
  jobRequisitionId: string,
  defaultValue?: T
): T {
  return R.isNullOrEmpty(allItems) || !allItems[jobRequisitionId]
    ? defaultValue
    : allItems[jobRequisitionId];
}

export function isAnyFileUploading(
  requisitions: RequisitionsT,
  requisitionsProgress: RequisitionsProgressT
) {
  const hasPendingFiles = Object.keys<number>(requisitions).find(
    (key: number) => requisitions[key].pendingCount >= 1
  );
  const hasProgress = Object.keys<number>(requisitionsProgress).find(
    (key: number) => {
      return Object.keys<number>(requisitionsProgress[key]).find((k) => {
        return requisitionsProgress[key][k] < 100;
      });
    }
  );
  return !!hasPendingFiles || !!hasProgress;
}

export function getCountMessage(count: number) {
  return count > 999 ? '999+' : count;
}

export const MAX_TAG_LENGTH = 512;
export function sliceString(str?: string, maxLength?: number) {
  if (str) {
    return str.slice(0, maxLength);
  }
  return '';
}

export function getFoldersNamesFromPath(file?: AcceptedFile) {
  if (file) {
    const { path, name } = file;
    return path !== name
      ? sliceString(
          path.slice(1, path.length - name.length - 1),
          MAX_TAG_LENGTH
        )
      : '';
  }
  return '';
}

export const getNewFilesForUpload = ({
  files,
  atsId,
  currentJobRequisitionId,
  userTag,
}: {
  files: AcceptedFile[];
  atsId: number;
  currentJobRequisitionId: string;
  userTag?: string;
}) =>
  files.map((file) => ({
    file,
    tempId: uuidv4(),
    atsId,
    requisitionId: currentJobRequisitionId,
    tag: userTag ?? getFoldersNamesFromPath(file),
  }));

export const rejectUploadedToServerFiles = (files: RequisitionFile[]) =>
  files.filter((file) => !file?.atsCandidateId);
