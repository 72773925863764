import React from 'react';
import R from '@air/third-party/ramda';
import {
  JobDescriptionFullResponse,
  SearchExtendedResponse,
  SearchKanbanResponse,
  SearchProgressStatusEnum,
  SearchResponseV2,
} from '@air/api';
import { mapSearchResponseToViewData } from 'features/DraftSection/DraftPreview/SearchDataMapper';
import { HeaderCriteriaSections } from 'domain/HeaderData/HeaderDataMapper';
import * as phrases from 'constants/phrases';
import { CustomerProfileContextT } from 'context';
import { REQUEST_PAGING_SIZE_KANBAN } from 'constants/app';
import { KanbanSectionNames } from 'hooks/useUserSettings';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { JobSectionItemDragObject } from 'features/JobsSection/JobsSection';
import { ActionsMenuConfigT } from 'components/Cards/KanbanCard/ActionsMenu/ActionsMenu';

export type KanbanListT<T> = {
  items: T[];
  total: number;
  loaded: boolean;
};

type AdditionalLineupSearchFields = {
  jobRequisitionDetails?: JobDescriptionFullResponse;
  currentCriteria?: HeaderCriteriaSections;
};
export type CurrentSearchT = SearchExtendedResponse &
  AdditionalLineupSearchFields;

export type SearchListItemT = SearchKanbanResponse | CurrentSearchT;

export function isCurrentSearch(item: SearchListItemT): item is CurrentSearchT {
  return !!item && 'jobRequisitionDetails' in item;
}

export type MatchMinerSearchT = SearchKanbanResponse &
  AdditionalLineupSearchFields;

export type MatchScoutSearchT = SearchKanbanResponse &
  AdditionalLineupSearchFields;

export const createTempDraft = (
  jobInfo: JobSectionItemDragObject
): SearchListItemT => {
  const { jobId, ats, title, locations, jobRequisitionDetails, appliedCount } =
    jobInfo;

  return {
    searchId: 0,
    parentId: null,
    status: SearchProgressStatusEnum.DRAFT,
    criteria: null,
    redFlags: null,
    order: null,
    created: Date.now(),
    updated: Date.now(),
    name: title,
    locations,
    jobRequisitionDetails,
    ats: {
      id: ats.dataSourceId,
      externalJobDescriptionId: jobId,
    },
    isProcessingActual: false,
    creator: null,
    ownedByCurrentUser: true,
    activeCount: {
      matchedCount: null,
      pendingCount: null,
      processedCount: null,
      rejectedCount: null,
      selectedCount: null,
      failedCount: null,
      totalCount: appliedCount,
    },
    passiveCount: {
      matchedCount: null,
      pendingCount: null,
      processedCount: null,
      rejectedCount: null,
      selectedCount: null,
      failedCount: null,
      totalCount: appliedCount,
    },
    matchMinerCount: {
      matchedCount: null,
      pendingCount: null,
      processedCount: null,
      rejectedCount: null,
      selectedCount: null,
      failedCount: null,
      totalCount: appliedCount,
    },
  };
};

export const KANBAN_SEARCH_GROUPS = {
  Draft: [SearchProgressStatusEnum.DRAFT, SearchProgressStatusEnum.INPROGRESS],
  Screening: [SearchProgressStatusEnum.INTERVIEW],
  Paused: [SearchProgressStatusEnum.ONHOLD],
  Closed: [SearchProgressStatusEnum.CLOSED],
};

export type KanbanFilterT = { name: string; isOwnedByMe: boolean };
export const isKanbanFilterApplied = (
  currentSearchFilter: KanbanFilterT
): boolean => {
  return currentSearchFilter.name !== '' || currentSearchFilter.isOwnedByMe;
};

export const getEmptyListMessage = (
  list: KanbanListT<any>,
  currentSearchFilter: KanbanFilterT,
  initialMessage: string
) => {
  if (list.loaded) {
    if (list.items.length > 0) {
      return '';
    }
    if (isKanbanFilterApplied(currentSearchFilter)) {
      return phrases.EMPTY_SIDELIST_PLACEHOLDER;
    } else {
      return initialMessage;
    }
  }
  return '';
};

export function doesKanbanHaveATSJobs(
  user: CustomerProfileContextT['user'],
  isStandaloneAtsUser: CustomerProfileContextT['isStandaloneAtsUser']
): boolean {
  return !!user && isStandaloneAtsUser === false;
}

export function getSearchTitle<
  SearchT extends SearchExtendedResponse,
  SearchListT extends SearchListItemT
>(search: SearchT, searchList: { items: SearchListT[] }, activeId: number) {
  if (search) return search.name;
  if (!searchList.items.length) return '';

  return searchList.items.find(R.propEq('searchId', activeId))?.name;
}

export const getJobRequisitionDetails = (
  currentSearch: CurrentSearchT | MatchMinerSearchT,
  jobDescriptionId?: string
) => {
  const { jobRequisitionDetails = null } =
    currentSearch?.ats?.externalJobDescriptionId === jobDescriptionId
      ? currentSearch
      : {};
  return jobRequisitionDetails;
};

export const prepareCurrentSearchInfo = (
  currentSearch: CurrentSearchT | MatchMinerSearchT,
  jobDescriptionId: string,
  res?: SearchResponseV2
) => {
  const hasSearchResults = res?.status !== SearchProgressStatusEnum.DRAFT;
  /*
    If we refetch existing draft (for example,
    during auto-refresh on preview / interview,
    there's no need to refetch its job requisition info
    as well, it will be the same.
  */
  const jobRequisitionDetails = getJobRequisitionDetails(
    currentSearch,
    jobDescriptionId
  );

  return {
    ...(hasSearchResults && !R.isNullOrEmpty(res)
      ? mapSearchResponseToViewData(res)
      : res),
    parentId: res?.searchId,
    jobRequisitionDetails,
  };
};

export const getAmountOfJobsToLoad = (total: number, loaded: number) => {
  if (total <= REQUEST_PAGING_SIZE_KANBAN) return 0;
  const jobsNotLoadedYet = total - loaded;
  return jobsNotLoadedYet >= REQUEST_PAGING_SIZE_KANBAN
    ? REQUEST_PAGING_SIZE_KANBAN
    : jobsNotLoadedYet;
};

export const TEMP_DRAFT_CARD = 'temp-draft-card';

export const isTempDraftCard = (item: SearchListItemT) =>
  item.ats.externalJobDescriptionId === TEMP_DRAFT_CARD;

export const addRemoveCardAction = (
  actionsConfig: ActionsMenuConfigT,
  removeSearch: (item: KanbanCardT) => void,
  item: unknown
) => {
  if (ENV_NAME === 'localhost') {
    actionsConfig.push({
      onClick: () => removeSearch(item as KanbanCardT),
      icon: 'discard-draft',
      title: phrases.KANBAN_ACTION_DELETE,
      closeMenuOnClick: true,
    });
  }
};

export const SECTIONS_NAMES: KanbanSectionNames[] = [
  phrases.KANBAN_SECTION_JOBS,
  phrases.KANBAN_SECTION_DRAFTS,
  phrases.KANBAN_SECTION_SCREENING,
  phrases.KANBAN_SECTION_PAUSED,
  phrases.KANBAN_SECTION_CLOSED,
];

export const DEFAULT_SECTIONS_OPEN_STATE = SECTIONS_NAMES.reduce(
  (acc, sectionName) => {
    acc[sectionName] = true;
    return acc;
  },
  {} as {
    [key in KanbanSectionNames]: boolean;
  }
);

/*
  Kanban actions confirmation Modal.
 */
export enum ConfirmActionEnum {
  CONFIRM_ACTION = 'confirm_action',
  CLOSE_MODAL = 'close_modal',
  REMOVE_DRAFT = 'remove_draft',
}

export type RemoveDraftAction = {
  type: ConfirmActionEnum.REMOVE_DRAFT;
  payload?: KanbanCardT;
};
export type CloseConfirmModalAction = {
  type: ConfirmActionEnum.CLOSE_MODAL;
};
export type ModalAction = RemoveDraftAction | CloseConfirmModalAction;

export type ConfirmModalState = {
  isOpened: boolean;
  modalBody?: React.ReactNode;
  onConfirm?: () => void;
};
