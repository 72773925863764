import React, { useCallback, useEffect } from 'react';
import R from '@air/third-party/ramda';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';
import * as H from 'history';
import querystring from 'query-string';

import { JobEmailStatus, SectionRequest } from '@air/api';
import { Button, LoadingDocumentSkeleton } from 'components';
import { Header, Paragraph, TooltipWrapper } from '@air/components';
import * as phrases from 'constants/phrases';
import {
  CREATED_DATE_FORMAT,
  EmailSectionsButtonT,
  removeEmptySections,
  transformEmailSectionButtonIntoSection,
  normalizeEmailSubject,
  checkEmailValidity,
  SelectedEmailEntityT,
} from 'domain/EmailConfig/EmailTemplates';
import {
  useEmailConfigurationContext,
  useEmailConfigurationMethods,
} from 'providers/EmailConfigurationProvider';
import { emailConfigurationSelectors, kanbanSelectors } from 'selectors';
import { TemplateSavingControls } from 'features/EmailConfigSection/TemplateSavingControls/TemplateSavingControls';
import { EmailBuilderForm } from 'features/EmailConfigSection/EmailBuilder/EmailBuilderForm/EmailBuilderForm';
import { useDebounce } from '@air/utils/hooks';
import {
  EmailsTabs,
  LineupTabs,
  SelectRecipientsTabs,
} from '@air/constants/tabs';
import { SelectRecipientsView } from 'features';
import * as urls from 'constants/urls';
import { AUTOSAVE_DEBOUNCE_TIME } from '@air/constants/app';
import { useKanbanContext } from 'providers/KanbanProvider';

import {
  GeneralTemplatePreview,
  RecipientEmailPreview,
} from './EmailsPreviews/EmailPreviews';

import styles from './EmailBuilder.css';

type EmailBuilderT = {
  isEditMode: boolean;
  isSelectRecipientsMode: boolean;
  dataSourceId: string;
  jobDescriptionId: string;
  currentTab: LineupTabs;
  goToEditMode?: ({
    emailId,
    isEditMode,
  }: {
    emailId?: string;
    isEditMode?: boolean;
  }) => void;
  selectedTab: EmailsTabs;
  currentSearchId: number;
  history: H.History;
  onBottomButtonClick: () => void;
};

export const EmailBuilder: React.FC<EmailBuilderT> = ({
  isEditMode,
  isSelectRecipientsMode,
  goToEditMode,
  dataSourceId,
  jobDescriptionId,
  currentTab,
  selectedTab,
  currentSearchId,
  history,
  onBottomButtonClick,
}) => {
  const currentEmail = useEmailConfigurationContext(
    emailConfigurationSelectors.currentEmail
  );
  const preview = useEmailConfigurationContext(
    emailConfigurationSelectors.preview
  );

  const { saveJobEmail } = useEmailConfigurationMethods();

  const email = currentEmail.item;
  const locationSearchParams = querystring.parse(history.location.search);
  const showSkeleton =
    R.isNil(currentEmail.isLoading) ||
    currentEmail.isLoading ||
    preview.isLoading;

  const isCandidateEmailPreviewMode = !!locationSearchParams.candidatePreviewId;

  const showBottomPanel =
    !isEditMode &&
    !isSelectRecipientsMode &&
    !isCandidateEmailPreviewMode &&
    !showSkeleton;

  const onClickBottomButtonHandler = useCallback(() => {
    if (selectedTab === EmailsTabs.Templates && !email.unsavedChanges) {
      saveJobEmail({
        email: {
          subject: normalizeEmailSubject(email.subject),
          sections: email.sections,
        },
        searchId: currentSearchId,
      }).then((res) => {
        onBottomButtonClick();
        goToEditMode({
          emailId: res.id,
          isEditMode: true,
        });
      });
    } else {
      goToEditMode({
        emailId: email?.id,
        isEditMode: true,
      });
    }
  }, [
    email,
    selectedTab,
    saveJobEmail,
    currentSearchId,
    onBottomButtonClick,
    goToEditMode,
  ]);

  const isSelectRecipientsPanelOpen = urls.isRecipientsPanelOpen(
    history.location.pathname
  );

  const bottomButtonTextForTemplates = email.unsavedChanges
    ? phrases.EMAIL_GO_TO_EDIT_MODE_BUTTON
    : phrases.EMAIL_SECTION_TEMPLATE_BOTTOM_SECTION_SELECT_FOR_SENDING;

  const emailsTabText =
    email.status === JobEmailStatus.SENT
      ? phrases.EMAIL_SECTION_TEMPLATE_BOTTOM_SECTION_SELECT
      : phrases.EMAIL_GO_TO_EDIT_MODE_BUTTON;
  const bottomButtonText =
    selectedTab === EmailsTabs.Templates
      ? bottomButtonTextForTemplates
      : emailsTabText;

  return (
    <>
      {!isSelectRecipientsMode && (
        <div className={styles.emailBuilder}>
          <div className={styles.emailWrapper}>
            {showSkeleton ? (
              <LoadingDocumentSkeleton className={styles.skeleton} />
            ) : (
              <>
                {!isEditMode ? (
                  <GeneralTemplatePreview email={email} />
                ) : (
                  <EditableEmailView
                    email={email}
                    selectedTab={selectedTab}
                    history={history}
                    dataSourceId={dataSourceId}
                    jobDescriptionId={jobDescriptionId}
                    currentTab={currentTab}
                    currentSearchId={currentSearchId}
                  />
                )}
                {showBottomPanel && (
                  <div className={styles.bottomPanel}>
                    <Button
                      small
                      variant={Button.variants.POSITIVE_CONFIRM}
                      onClick={onClickBottomButtonHandler}
                    >
                      {bottomButtonText}
                    </Button>
                    <Paragraph small className={styles.creator}>
                      {phrases.EMAIL_CREATED}{' '}
                      {dayjs(email?.created).format(CREATED_DATE_FORMAT)}{' '}
                      {phrases.EMAIL_CREATED_BY} {email?.creator?.firstName}{' '}
                      {email?.creator?.lastName}
                    </Paragraph>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {isSelectRecipientsMode && (
        <>
          {showSkeleton ? (
            <div className={styles.emailBuilder}>
              <div className={styles.emailWrapper}>
                <LoadingDocumentSkeleton className={styles.skeleton} />
              </div>
            </div>
          ) : preview.email ? (
            <RecipientEmailPreview email={preview.email} />
          ) : (
            <GeneralTemplatePreview email={email} />
          )}

          <div className={styles.rightPanel}>
            {isSelectRecipientsMode && isSelectRecipientsPanelOpen && (
              <SelectRecipientsView
                jobDescriptionId={jobDescriptionId}
                searchId={currentSearchId}
                emailId={email.id}
                history={history}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

const EditableEmailView = ({
  email,
  history,
  dataSourceId,
  jobDescriptionId,
  currentTab,
  selectedTab,
  currentSearchId,
}: {
  email: SelectedEmailEntityT;
  history: H.History;
  dataSourceId: string;
  jobDescriptionId: string;
  currentTab: LineupTabs;
  selectedTab: EmailsTabs;
  currentSearchId: number;
}) => {
  const {
    createEmailTemplate,
    fetchEmailTemplates,
    discardEmailTemplateChanges,
    submitUpdateEmailTemplate,
    saveJobEmail,
    fetchEmailTemplateSectionsList,
    updateEmailTemplate,
  } = useEmailConfigurationMethods();

  const emailSectionsList = useEmailConfigurationContext(
    emailConfigurationSelectors.emailSectionsList
  );
  const passiveTotalCount = useKanbanContext(
    kanbanSelectors.currentSearchPassiveTotalCount
  );

  const matchMinerTotalProcessed = useKanbanContext(
    kanbanSelectors.matchMinerTotalProcessed
  );
  const matchScoutTotalProcessed = useKanbanContext(
    kanbanSelectors.matchScoutTotalProcessed
  );
  const totalCandidatesAmount = {
    [LineupTabs.Active]: passiveTotalCount,
    [LineupTabs.Passive]: passiveTotalCount,
    [LineupTabs.MatchMiner]: matchMinerTotalProcessed,
    [LineupTabs.MatchScout]: matchScoutTotalProcessed,
  }[currentTab];

  useEffect(() => {
    // fetch list of job email sections
    if (R.isNil(emailSectionsList.isLoading)) {
      fetchEmailTemplateSectionsList();
    }
  }, [emailSectionsList.isLoading, fetchEmailTemplateSectionsList]);

  // property status is only relevant for jobEmails
  const isEditingExistingTemplate =
    selectedTab === EmailsTabs.Templates && !email.status;

  const methods = useForm<Partial<SelectedEmailEntityT>>({
    defaultValues: {
      subject: email.subject,
      sections: email.sections,
      id: email.id,
      name: email.name,
    },
  });

  const { getValues, control, watch } = methods;
  const { replace } = useFieldArray({
    control,
    name: 'sections',
  });

  /*
    Emails / templates are autosaved after 2 seconds of user inactivity.
    Each entity has it's own saving methods, but all updates come from
    a single source of form's updated, initialized in the useEffect below.
  */
  const handleEmailSaving = useDebounce(
    useCallback(
      (email: SelectedEmailEntityT) => {
        if (!email.subject) {
          // if the user removed subject do not save the data
          return;
        }

        /*
      This condition is valid if we specifically edit Email Templates (entities from Templates tab).
      Don't mix it up with editing job emails (entities from Job Emails tab).
    */
        if (isEditingExistingTemplate) {
          return updateEmailTemplate(email);
        }

        return saveJobEmail({
          searchId: currentSearchId,
          email,
        });
      },
      [
        currentSearchId,
        isEditingExistingTemplate,
        saveJobEmail,
        updateEmailTemplate,
      ]
    ),
    AUTOSAVE_DEBOUNCE_TIME
  );

  useEffect(() => {
    /*
      To enable auto-save in EmailBuilder, we use watch with callback, which will be called
      on every change, and it contains the whole form as a `value` argument.
      From here we send in to debounced `handleSave` method.
    */
    const subscription = watch((value: SelectedEmailEntityT) => {
      handleEmailSaving(value);
    });
    return () => subscription.unsubscribe();
  }, [watch, handleEmailSaving]);

  const addSection = useCallback(
    (section: EmailSectionsButtonT) => {
      const { sections } = getValues();
      const sectionsWithContent = removeEmptySections(
        sections as SelectedEmailEntityT['sections']
      );

      const result = sectionsWithContent.concat(
        transformEmailSectionButtonIntoSection(
          section,
          sectionsWithContent.length
        )
      );
      replace(result);
    },
    [getValues, replace]
  );

  const showRecipientsMode = () => {
    history.push(
      urls.makeEmailConfigUrl({
        dataSourceId,
        jobDescriptionId,
        emailId: email.id,
        tab: currentTab,
        isEditMode: false,
        params: {
          recipientsTab: SelectRecipientsTabs.New,
          emailConfigTab: selectedTab,
        },
      })
    );
  };

  const emailBuilderFormState = watch();
  const createNewTemplate = async ({
    templateName,
  }: {
    templateName: string;
  }) => {
    await createEmailTemplate({
      name: templateName,
      sections: emailBuilderFormState.sections as SectionRequest[],
      subject: { content: emailBuilderFormState.subject },
    });

    await fetchEmailTemplates();
  };

  const submitUpdateExistingTemplate = async ({
    templateName,
  }: {
    templateName: string;
  }) => {
    await submitUpdateEmailTemplate(emailBuilderFormState.id, {
      name: templateName,
      sections: emailBuilderFormState.sections as SectionRequest[],
      subject: { content: emailBuilderFormState.subject },
    });
    history.goBack();
  };

  const discardTemplateChanges = () => {
    history.goBack();
    discardEmailTemplateChanges(email.id);
  };

  const isEmailValid = checkEmailValidity(email);
  const isEmailSubjectEmpty = !email.subject;
  const isEmailContentEmpty = !email.sections.length;
  const isEmailEmpty = isEmailSubjectEmpty && isEmailContentEmpty;

  const sendEmailTooltip = isEmailEmpty
    ? phrases.SELECT_RECIPIENTS_BUTTON_TOOLTIP_EMPTY_EMAIL
    : isEmailSubjectEmpty
    ? phrases.SELECT_RECIPIENTS_BUTTON_TOOLTIP_NO_SUBJECT
    : phrases.SELECT_RECIPIENTS_BUTTON_TOOLTIP_NO_CONTENT;

  const isEditingDisabled = email.status === JobEmailStatus.SENT;

  return (
    <div className={styles.editableEmailView}>
      <FormProvider {...methods}>
        <EmailBuilderForm
          addSection={addSection}
          email={email}
          disabled={isEditingDisabled}
        />
      </FormProvider>
      <div className={styles.rightPanel}>
        <>
          <div className={styles.sectionNames}>
            <Header level={3}>{phrases.EMAIL_CONFIG_RIGHT_PANEL_TITLE}</Header>
            <div className={styles.sectionsList}>
              {emailSectionsList.items?.map((item: EmailSectionsButtonT) => (
                <Button
                  key={item.type}
                  className={styles.sectionSelectionButton}
                  onClick={() => addSection(item)}
                  variant={Button.variants.CHIP}
                  disabled={isEditingDisabled}
                >
                  <span>{item.displayName}</span>
                </Button>
              ))}
            </div>
          </div>
          <TemplateSavingControls
            isEditingExistingTemplate={isEditingExistingTemplate}
            email={email}
            onSave={async (template) => {
              /*
                 When user decides to save the template, they may still have some changes scheduled for saving,
                 because saving is debounced. In that case we trigger .flush() to instantly run scheduled content saving,
                 and then send template saving updates.
              */
              await handleEmailSaving.flush();

              const saveCallback = isEditingExistingTemplate
                ? submitUpdateExistingTemplate
                : createNewTemplate;
              saveCallback(template);
            }}
            onDiscard={discardTemplateChanges}
          />
          <TooltipWrapper
            triggerClassName={styles.selectRecipientsTooltip}
            enabled={!isEmailValid}
            placement="top"
            trigger="hover"
            tooltip={sendEmailTooltip}
            flexGrow={false}
          >
            <Button
              small
              variant={Button.variants.POSITIVE_CONFIRM}
              className={styles.selectRecipientsButton}
              onClick={showRecipientsMode}
              disabled={!isEmailValid || !totalCandidatesAmount}
              alignCenter
            >
              {phrases.EMAIL_TEMPLATE_SELECT_RECIPIENTS_BUTTON}
            </Button>
          </TooltipWrapper>
        </>
      </div>
    </div>
  );
};
