import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '23.2rem',
    padding: '1.6rem 1.9rem',
    borderRadius: '1.6rem',
  } as React.CSSProperties,
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 'var(--modal-overlay-z-index)',
  } as unknown as React.CSSProperties,
};

const appElement = document.getElementById('app');

export type ModalStyles = {
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
};

type Props = {
  isOpen: boolean;
  onCancel?: () => void;
  contentLabel?: string;
  modalStyles?: ModalStyles;
  onRequestClose?: () => void;
};

export const ModalWrapper: React.FC<Props> = ({
  modalStyles,
  children,
  ...props
}) => {
  return props.isOpen ? (
    <Modal
      appElement={appElement}
      style={{
        content: {
          ...customStyles.content,
          ...(modalStyles || {}).content,
        },
        overlay: {
          ...customStyles.overlay,
          ...(modalStyles || {}).overlay,
        },
      }}
      {...props}
      shouldCloseOnOverlayClick
    >
      {children}
    </Modal>
  ) : null;
};
