import React from 'react';
import * as InterviewApi from 'features/InterviewSection/interviewApi';
import {
  ApiErrorResponse,
  MatchMinerStatus,
  MatchScoutStatus,
  RoleName,
  SearchProgressStatusEnum,
} from '@air/api';
import { toast } from '@air/third-party/toast';
import * as sharedPhrases from '@air/constants/phrases';
import { Button, Paragraph } from '@air/components';
import styles from 'features/InterviewSection/LoadMoreApplicantsSection/LoadMoreApplicantsSection.css';
import * as phrases from 'constants/phrases';
import { LineupTabs } from '@air/constants/tabs';
import { isMatchMinerTab } from '../CandidateData/CandidateLineupData';

export type MatchMinerSetupSettings = {
  since: number;
  limit: number;
  rejectionReasons: string[];
};

export type MatchScoutSetupSettings = {
  limit: number;
};

export const getAmountOfParsedCandidatesForMMSearch = (
  atsId: number,
  jobId: string
) => {
  return InterviewApi.getAmountOfParsedCandidatesForMMSearch(atsId, jobId).fork(
    (error: ApiErrorResponse) => {
      toast.error(
        error?.details?.description || sharedPhrases.GENERAL_ERROR_TRY_AGAIN
      );
      return error;
    },
    (res: { candidateCount: number }) => res
  );
};

export const MAX_CANDIDATES_LIMIT = 2000;
export const MAX_MM_CANDIDATES_TO_REQUEST = 2000;

export const getCommonMatchServiceTooltip = ({
  tab,
  status,
  requestMatchServiceForCompany,
  customerRole,
}: {
  tab: LineupTabs;
  status: MatchMinerStatus | MatchScoutStatus;
  customerRole: RoleName;
  requestMatchServiceForCompany: () => void;
}) => {
  /* Some parts of MatchService notifications use different phrasing. */
  const { inactiveText, statusEnum, requestedText } = isMatchMinerTab(tab)
    ? {
        inactiveText: phrases.MATCH_MINER_INACTIVE,
        requestedText: phrases.MM_REQUESTED,
        statusEnum: MatchMinerStatus,
      }
    : {
        inactiveText: phrases.MATCH_SCOUT_INACTIVE,
        requestedText: phrases.MS_REQUESTED,
        statusEnum: MatchScoutStatus,
      };

  if (
    status === statusEnum.DISABLED &&
    customerRole === RoleName.CUSTOMERADMIN
  ) {
    return (
      <>
        <Paragraph small className={styles.tooltipText}>
          {inactiveText}
        </Paragraph>
        <Paragraph small>
          <Button
            variant={Button.variants.INLINE}
            onClick={requestMatchServiceForCompany}
            className={styles.tooltipButton}
          >
            {phrases.MATCH_SERVICE_INACTIVE_ADMIN_ACTION}
          </Button>
          <span>{phrases.MATCH_SERVICE_INACTIVE_ADMIN}</span>
        </Paragraph>
      </>
    );
  }
  if (status === statusEnum.DISABLED && customerRole === RoleName.RECRUITER) {
    return (
      <>
        <Paragraph small className={styles.tooltipText}>
          {inactiveText}
        </Paragraph>
        <Paragraph small className={styles.tooltipText}>
          {phrases.MATCH_SERVICE_INACTIVE_RECRUITER}
        </Paragraph>
      </>
    );
  }

  if (status === statusEnum.ACCESSREQUESTED) {
    return (
      <>
        <Paragraph small className={styles.tooltipText}>
          {requestedText}
        </Paragraph>
        <Paragraph small className={styles.tooltipText}>
          {phrases.REQUESTED_SALES}
        </Paragraph>
      </>
    );
  }
};

type TooltipTextGetterProps = {
  tab: LineupTabs;
  isTrialExpired?: boolean;
  status: MatchMinerStatus | MatchScoutStatus;
  customerRole: RoleName;
  requestMatchServiceForCompany: () => void;
  parentSearchStatus?: SearchProgressStatusEnum;
  possibleLoadMore?: boolean;
  candidatesCount?: number;
  hasRequiredMatchServiceCriteria?: boolean;
};
export function getLoadMoreTooltipText({
  tab,
  isTrialExpired,
  parentSearchStatus,
  status,
  possibleLoadMore,
  candidatesCount,
  hasRequiredMatchServiceCriteria,
  customerRole,
  requestMatchServiceForCompany,
}: TooltipTextGetterProps) {
  const { statusEnum, maxCandidatesText } = isMatchMinerTab(tab)
    ? {
        statusEnum: MatchMinerStatus,
        maxCandidatesText: phrases.MAX_MM_CANDIDATES_REACHED,
      }
    : {
        statusEnum: MatchScoutStatus,
        maxCandidatesText: phrases.MAX_MS_CANDIDATES_REACHED,
      };
  /*
    AR-9747
    The order of the rules below is important.
   */
  if (isTrialExpired) {
    return (
      <Paragraph small className={styles.tooltipText}>
        {phrases.REQUEST_MORE_TRIAL_EXPIRED}
      </Paragraph>
    );
  }

  if (
    (status === statusEnum.DISABLED &&
      customerRole === RoleName.CUSTOMERADMIN) ||
    (status === statusEnum.DISABLED && customerRole === RoleName.RECRUITER) ||
    status === statusEnum.ACCESSREQUESTED
  ) {
    return getCommonMatchServiceTooltip({
      tab,
      status,
      customerRole,
      requestMatchServiceForCompany,
    });
  }

  if (!possibleLoadMore && hasRequiredMatchServiceCriteria) {
    return (
      <>
        <Paragraph small className={styles.tooltipText}>
          {phrases.ALL_SUITABLE_CANDIDATES_LOADED_TEXT}
        </Paragraph>
        <Paragraph small className={styles.tooltipText}>
          {phrases.TRY_TO_MODIFY_CRITERIA_TEXT}
        </Paragraph>
      </>
    );
  }

  if (
    parentSearchStatus === SearchProgressStatusEnum.CLOSED ||
    parentSearchStatus === SearchProgressStatusEnum.ONHOLD
  ) {
    return (
      <Paragraph small className={styles.tooltipText}>
        {phrases.MM_CLOSED_ON_HOLD_REQUEST}
      </Paragraph>
    );
  }

  if (candidatesCount >= MAX_MM_CANDIDATES_TO_REQUEST) {
    return (
      <Paragraph small className={styles.tooltipText}>
        {maxCandidatesText}
      </Paragraph>
    );
  }

  if (!hasRequiredMatchServiceCriteria) {
    return (
      <>
        <Paragraph small className={styles.tooltipText}>
          {phrases.REQUEST_MORE_NO_CRITERIA_DISABLED}
        </Paragraph>
      </>
    );
  }

  return (
    <>
      <Paragraph small className={styles.tooltipText}>
        {phrases.NOT_ENOUGH_CANDIDATES_TEXT}
      </Paragraph>
      <Paragraph small className={styles.tooltipText}>
        {phrases.TRY_TO_REQUEST_MORE}
      </Paragraph>
    </>
  );
}
