import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import {
  parseErrorJson,
  parseResponseBlob,
  parseResponseHeaders,
  parseResponseJson,
} from '@air/utils/api';
import {
  AlertStatusEnum,
  CandidateSearchProfilePartialListV2,
  CandidateSearchProfileStatus,
  CandidateSearchProfileV2,
  NoteResponse,
  PatchCandidateSearchProfileRequest,
  SearchCriteriaMatchStatusEnum,
  CandidateProfileStateUpdateInfoRequest,
  SearchCountResponseHolder,
} from '@air/api';
import { Task } from '@air/utils/fp';
import { ENTITY_NOT_FOUND } from '@air/constants/httpCodes';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData/candidateStatusHelpers';
import { ProfileSort } from '@air/api/models/ProfileSort';

export const CANDIDATE_MAX_BATCH = 500;

const defaultRequestedStatuses = Object.values(
  CandidateSearchProfileStatus
) as unknown as CandidateSearchProfileStatusEnum[];
export const fetchLineupCandidatesList = ({
  searchId,
  profileIds,
  filter,
  status = defaultRequestedStatuses,
  size,
  passive,
  sort,
}: {
  searchId: number;
  filter?: string;
  status?: CandidateSearchProfileStatusEnum[];
  size: number;
  profileIds?: string[];
  passive?: boolean;
  sort?: ProfileSort;
}): Task<CandidateSearchProfilePartialListV2> => {
  return Http.get(urls.makeSearchPartialCandidatesListApiUrl(searchId))
    .bind(
      Http.withQueryParams({
        size,
        sort,
        status: status.length ? status : defaultRequestedStatuses,
        filterName: filter,
        id: profileIds,
        passive,
      })
    )
    .bind(Http.withRetry({ delay: 1000, count: 3 }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const changeCandidateStatus = (
  searchId: number,
  candidateId: string,
  status: CandidateSearchProfileStatus
) => {
  return Http.put(urls.makeChangeCandidateStatusUrl(searchId, candidateId))
    .bind(Http.withJsonBody({ status }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const moveCandidateToApplied = (
  searchId: number,
  candidateId: string,
  params: CandidateProfileStateUpdateInfoRequest
) => {
  return Http.patch(urls.makeMoveCandidateToAppliedUrl(searchId, candidateId))
    .bind(Http.withJsonBody(params))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

/**
 * CandidateProfile Notes API
 */
export const getCandidateNote = (profileId: string): Task<NoteResponse[]> => {
  return Http.get(urls.makeCandidateProfileNoteUrl(profileId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const createCandidateNote = (
  profileId: string,
  message: string
): Task<NoteResponse[]> => {
  return Http.post(urls.makeCandidateProfileNoteUrl(profileId))
    .bind(Http.withJsonBody({ message }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateCandidateNote = (
  profileId: string,
  message: string,
  noteId: string
): Task<NoteResponse[]> => {
  return Http.patch(urls.makeCandidateProfileNoteUrl(profileId, noteId))
    .bind(Http.withJsonBody({ message }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const deleteCandidateNote = (
  profileId: string,
  noteId: string
): Task<null> => {
  return Http.remove(urls.makeCandidateProfileNoteUrl(profileId, noteId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const overrideCardStatus = (
  searchId: number,
  profileId: string,
  cardRefId: string,
  newStatus: SearchCriteriaMatchStatusEnum
) => {
  return Http.put(
    urls.makeOverrideCardStatusApiUrl(searchId, profileId, cardRefId)
  )
    .bind(Http.withJsonBody({ status: newStatus }))
    .toTask()
    .chainError(parseErrorJson);
};

export const changeRedFlagStatus = (
  searchId: number,
  profileId: string,
  redFlagRefId: string,
  newStatus: AlertStatusEnum
) => {
  return Http.put(
    urls.makeChangeRedFlagStatusApiUrl(searchId, profileId, redFlagRefId)
  )
    .bind(Http.withJsonBody({ status: newStatus }))
    .toTask()
    .chainError(parseErrorJson);
};

export const fetchCandidateSearchProfileById = (
  searchId: number | string,
  profileId: string
): Task<CandidateSearchProfileV2> => {
  return Http.get(urls.makeGetCandidateSearchProfileApiUrl(searchId, profileId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchCandidateSearchProfileByMainId = (
  dataSourceId: number | string,
  jobDescriptionId: number | string,
  mainProfileId: string
): Task<CandidateSearchProfileV2> => {
  return Http.get(
    urls.makeGetCandidateSearchProfileMainIdApiUrl(
      dataSourceId,
      jobDescriptionId,
      mainProfileId
    )
  )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateCandidateSearchProfileById = (
  searchId: number,
  profileId: string,
  body: PatchCandidateSearchProfileRequest
) => {
  return Http.patch(
    urls.makeGetCandidateSearchProfileApiUrl(searchId, profileId)
  )
    .bind(Http.withJsonBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const enrichCandidateSearchProfileById = (
  searchId: number,
  profileId: string
) => {
  return Http.post(
    urls.makeEnrichCandidateSearchProfileApiUrl(searchId, profileId)
  )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const getCandidateResumeFileHeaders = (
  atsId: string | number,
  profileId: string,
  pdfOnly: boolean
) => {
  return Http.head(urls.makeDownloadResumeApiUrl(atsId, profileId, pdfOnly))
    .toTask()
    .chain(parseResponseHeaders)
    .chainError((error: any) => {
      if (error.payload.status === ENTITY_NOT_FOUND) {
        // file doesn't exist
        return new Task((): any => {
          return null;
        });
      }
      return parseResponseJson(error);
    });
};

export const downloadCandidateResume = (
  atsId: string | number,
  profileId: string,
  pdfOnly: boolean
) => {
  return Http.get(urls.makeDownloadResumeApiUrl(atsId, profileId, pdfOnly))
    .toTask()
    .chain(parseResponseBlob)
    .chainError(parseResponseJson);
};

export const fetchCandidatesListSize = (
  searchId: number | string
): Task<SearchCountResponseHolder> => {
  return Http.get(urls.makeSearchCandidatesListCountersUrl(searchId))
    .bind(Http.withRetry({ delay: 1000, count: 3 }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
