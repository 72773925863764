import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { Paragraph, UIText, TooltipWrapper } from '@air/components';
import {
  EmailTemplateShortResponse,
  JobEmailShortResponse,
  JobEmailStatus,
  TemplateType,
} from '@air/api';
import {
  ActionsMenu,
  ActionMenuItemConfigT,
} from 'components/Cards/KanbanCard/ActionsMenu/ActionsMenu';
import * as phrases from 'constants/phrases';

import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';
import { hasAdminRole, isCreator } from 'domain/EmailConfig/utils';

import styles from './EmailsTemplatesList.css';

const MAX_VISIBLE_LENGTH = 70;

const EmailsCard = ({
  item,
  onClick,
  isSelected,
  isLoading,
  onDelete,
  onDuplicate,
  onStatusClick,
}: {
  onClick?: ({
    emailId,
    isEditMode,
  }: {
    emailId?: string;
    isEditMode?: boolean;
  }) => void;
  isSelected: boolean;
  isLoading: boolean;
  onDelete: (id: string, isSelected: boolean) => void;
  onDuplicate: (id: string) => void;
  creator?: JobEmailShortResponse['creator'];
  unsavedChanges?: boolean;
  item: EmailTemplateShortResponse | JobEmailShortResponse;
  onStatusClick: ({
    id,
    status,
  }: {
    id: string;
    status: JobEmailStatus;
  }) => void;
}) => {
  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );
  const customerRole = useCustomerProfileContext(
    customerProfileSelectors.customerRole
  );

  const { name, created, updated, id, creator } = item;
  const status = 'status' in item ? item.status : null;
  const hasUnsavedChanges =
    'unsavedChanges' in item ? item.unsavedChanges : false;

  // Default template doesn't have a creator.
  const isDefaultTemplate =
    'type' in item && item.type === TemplateType.DEFAULT;
  const creatorName = creator ? `${creator.firstName} ${creator.lastName}` : '';

  const deleteButtonConfig =
    (isCreator(customerId, creator) || hasAdminRole(customerRole)) &&
    !isDefaultTemplate
      ? {
          onClick: () => onDelete(id, isSelected),
          icon: 'discard-draft',
          title: phrases.EMAIL_SECTION_ACTION_MENU_DELETE,
          closeMenuOnClick: true,
          disabled: isLoading,
          tooltip: phrases.EMAIL_SECTION_ACTION_DELETE_TOOLTIP,
        }
      : ({} as ActionMenuItemConfigT);

  //templates don't have 'status' property, so we use it for understanding item is jobEmail or emailTemplate
  const editButtonConfig =
    !status && !isDefaultTemplate
      ? {
          onClick: () => onClick({ emailId: id, isEditMode: true }),
          icon: 'edit',
          title: phrases.KANBAN_ACTION_EDIT,
          disabled: isLoading,
          closeMenuOnClick: true,
        }
      : ({} as ActionMenuItemConfigT);

  return (
    <div
      className={classNames(styles.emailCard, {
        [styles.isSelected]: isSelected,
        [styles.hasUnsavedChanges]: hasUnsavedChanges,
      })}
      onClick={() => onClick({ emailId: id })}
    >
      <div className={styles.content}>
        {status && (
          <UIText
            small
            className={classNames(styles.status, {
              [styles.sentStatus]: status === JobEmailStatus.SENT,
            })}
            onClick={(event) => {
              event.stopPropagation();
              onStatusClick({ id, status: status });
            }}
          >
            {status}
          </UIText>
        )}
        {hasUnsavedChanges && (
          <UIText small className={styles.changesWarning}>
            {phrases.EMAIL_SECTION_LIST_ITEM_UNSAVED_CHANGES_TEXT}
          </UIText>
        )}
        <TooltipWrapper
          enabled={name.length > MAX_VISIBLE_LENGTH}
          tooltip={name}
          placement="right-end"
        >
          <Paragraph
            short
            bold
            className={classNames(styles.subject, {
              [styles.subjectIndent]: status || hasUnsavedChanges,
            })}
          >
            {name}
          </Paragraph>
        </TooltipWrapper>
        <UIText className={styles.date} small>
          {isDefaultTemplate
            ? phrases.DEFAULT_EMAIL_TEMPLATE_NAME
            : dayjs(updated).format('MMM D, YYYY')}
        </UIText>
      </div>
      <div
        className={styles.iconBlock}
        onClick={(event) => event.stopPropagation()}
      >
        <ActionsMenu
          actionsMenuConfig={[
            editButtonConfig,
            {
              onClick: () => onDuplicate(id),
              icon: 'duplicate',
              title: phrases.KANBAN_ACTION_DUPLICATE,
              disabled: isLoading,
              closeMenuOnClick: true,
            },
            deleteButtonConfig,
          ]}
          creator={creatorName}
          created={created}
        />
      </div>
    </div>
  );
};

type JobEmailsListT = {
  selectEmail?: ({
    emailId,
    isEditMode,
  }: {
    emailId?: string;
    isEditMode?: boolean;
  }) => void;
  selectedEmailId?: string;
  onDelete: (itemId: string, isSelected: boolean) => void;
  onDuplicate: (itemId: string) => void;
  emailsTemplates: { isLoading: boolean; items: JobEmailShortResponse[] };
  onStatusClick: ({
    id,
    status,
  }: {
    id: string;
    status: JobEmailStatus;
  }) => void;
};

export const EmailsTemplatesList = ({
  selectEmail,
  selectedEmailId,
  onDelete,
  emailsTemplates,
  onDuplicate,
  onStatusClick,
}: JobEmailsListT) => {
  return (
    <div className={styles.emailsCardsWrapper}>
      {(emailsTemplates.items || []).map((item) => {
        const isSelected = selectedEmailId === item.id;
        return (
          <EmailsCard
            item={item}
            key={item.id}
            onClick={selectEmail}
            isSelected={isSelected}
            isLoading={emailsTemplates.isLoading}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            onStatusClick={onStatusClick}
          />
        );
      })}
    </div>
  );
};
