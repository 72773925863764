import React, { useCallback, useMemo, useState, useEffect } from 'react';
import R from '@air/third-party/ramda';
import { Field, FieldArray, FieldProps } from 'formik';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import classNames from 'classnames';
// import shared utils
import {
  isIdealExperienceDefined,
  isAcceptableExperienceDefined,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
// import utils
import {
  CriteriaCardViewProps,
  CriteriaDragObjectT,
  getDraggableCriteriaCard,
  getDraggableStackItem,
  getOnMainCardKeyDownCallback,
  SingleCriteriaItemDragObject,
  getCommonFieldsForCriteriaCard,
  MAX_CARDS_IN_STACK,
  isDraggableMainCard,
  isDraggableStackItem,
  getOnStackCardKeyDownCallback,
  isExcludedItem,
} from 'components/Cards/cardsCommonCode';
import {
  canMergeLists,
  WithDefaultValueOnConditionalMounting,
  isValidNewOption,
  loadIndustryAsyncOptions,
} from 'components/SearchCriteriaCards/utils';
// import shared hooks
// import hooks
import { useStateMachine } from '@air/hooks';
import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import {
  SearchCardContext,
  StateContext,
} from 'components/SearchCriteriaCards/hooks/SearchCardContext';
// import shared components
import {
  AcceptableExperienceWidget,
  Card,
  IdealExperienceWidget,
  RecentExperienceWidget,
} from '@air/components';
// import customer components
import { SelectRenderer } from '@air/components/Select/Select';
// import types
import { SearchCriteriaImportanceEnum } from '@air/api';
import { FormSelectEntity } from '@air/components/Select/typings';
import { SearchCriteriaExperienceT } from 'domain/SearchCriteria/BaseSearchCriteria';
// import styles
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
// import constants
import * as phrases from 'constants/phrases';
import { getImportanceValue, CardType } from 'domain/SearchCriteria';
import {
  getCardCriterionLabel,
  getMainCardTitle,
  getAddingLimits,
  getSearchCriteriaCardFooter,
  getCardStackSize,
  getTopStackListName,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import { IdealExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/IdealExperienceWidget';
import { AcceptableExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/AcceptableExperienceWidget';
import { RecentExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/RecentExperienceWidget';
import {
  CriteriaDraggableCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { generateCriteriaCardTopStackItems } from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import { CriteriaCollapsedCardProps } from 'components/CardWrappers/CriteriaCollapsedCard/CriteriaCollapsedCard';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import {
  CriteriaEditCardStackItems,
  generateTopStackItems,
  generateBottomStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import {
  IndustryCriteriaData,
  isExcludeIndustry,
  isIncludeIndustry,
  ExcludeIndustryCriteriaData,
} from 'domain/SearchCriteria';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import { useCacheContext, useCacheMethods } from '@air/providers/CacheProvider';
import { cacheSelectors } from 'selectors';
import { CriteriaImportanceExclusionSettings } from 'components/SearchCriteriaCards/components/CriteriaImportanceExclusionSettings/CriteriaImportanceExclusionSettings';

/*
  match an empty string or a string without whitespaces at the beginning
  so once typing the string, user can still clear the input
 */
const whitelistedchars = `a-zA-Z0-9&/,.!?#+\\-:;{}()\\[\\]\\|ú`;

const industryTitleRegex = new RegExp(
  `^$|^[${whitelistedchars}][${whitelistedchars} ]*$`
);

export const getUnwantedIndustryFieldsForCollapsedCriteriaCard = (
  cardData: ExcludeIndustryCriteriaData
) => {
  const fields = getCommonFieldsForCriteriaCard(cardData);
  return [
    <div key="unwanted-label" className={cardCommonStyles.unwantedLabel}>
      #{phrases.UNWANTED_LABEL}
    </div>,
    fields.title,
    fields.footer,
  ];
};

export const getIndustryReadOnlyFields = (
  card: IndustryCriteriaData
): Array<JSX.Element | null> => {
  const isExperienceDefined = (experience: SearchCriteriaExperienceT) =>
    isIdealExperienceDefined(experience) &&
    isAcceptableExperienceDefined(experience);

  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {isExcludeIndustry(card) && (
        <div
          className={classNames(
            cardCommonStyles.unwantedLabel,
            cardCommonStyles.lineupEditCardUnwantedLabel
          )}
        >
          #{phrases.UNWANTED_LABEL}
        </div>
      )}
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
    isIncludeIndustry(card) ? (
      <IdealExperienceReadOnlyWidget
        values={card.experience}
        key="ideal-experience-field"
      />
    ) : null,
    isIncludeIndustry(card) && isExperienceDefined(card.experience) ? (
      <AcceptableExperienceReadOnlyWidget
        values={card.experience}
        key="acceptable-experience-field"
      />
    ) : null,
    <RecentExperienceReadOnlyWidget
      value={card.recent}
      key="recent-experience-field"
    />,
  ];
};

const DraggableIndustryStackItem = getDraggableStackItem<IndustryCriteriaData>(
  DraggedSearchCriteriaCardType.singleIndustryItem
);
DraggableIndustryStackItem.displayName = 'DraggableIndustryStackItem';

export const IndustryCriteriaDraggableCollapsedCard: React.FC<
  CriteriaCardViewProps<IndustryCriteriaData> & CriteriaCollapsedCardProps
> = ({
  criteria,
  stackSize,
  handleDrop,
  handleSingleCriteriaDrop,
  ...props
}) => {
  const id = criteria.key;

  const acceptTypes = useMemo(() => {
    return [
      DraggedSearchCriteriaCardType.industry,
      DraggedSearchCriteriaCardType.singleIndustryItem,
    ];
  }, []);

  const canDropCallback = useCallback(
    (
      draggedItem:
        | CriteriaDragObjectT<IndustryCriteriaData>
        | SingleCriteriaItemDragObject<IndustryCriteriaData>
    ) => {
      if (
        isDraggableStackItem(draggedItem) ||
        (isDraggableMainCard(draggedItem) && draggedItem.isDrawingMainCard)
      ) {
        const isExcluded = isExcludedItem(
          draggedItem,
          DraggedSearchCriteriaCardType.singleIndustryItem
        );

        return (
          id !== draggedItem.groupId &&
          stackSize < MAX_CARDS_IN_STACK &&
          criteria.exclude === isExcluded
        );
      }
      return (
        id !== draggedItem.id &&
        draggedItem.type === DraggedSearchCriteriaCardType.industry &&
        criteria.exclude === draggedItem.criteria.exclude &&
        canMergeLists(draggedItem.criteria, criteria)
      );
    },
    [criteria, id, stackSize]
  );

  const dropCallback = useCallback(
    (droppedItem) => {
      if (
        droppedItem.type === DraggedSearchCriteriaCardType.singleIndustryItem ||
        droppedItem.isDrawingMainCard
      ) {
        handleSingleCriteriaDrop(id, droppedItem.id, droppedItem.groupId);
      } else {
        handleDrop(id, droppedItem.id);
      }
    },
    [handleDrop, handleSingleCriteriaDrop, id]
  );

  return (
    <CriteriaDraggableCollapsedCard
      {...props}
      criteria={criteria}
      stackSize={stackSize}
      draggableStackItem={DraggableIndustryStackItem}
      acceptTypes={acceptTypes}
      canDropCallback={canDropCallback}
      dropCallback={dropCallback}
    />
  );
};

IndustryCriteriaDraggableCollapsedCard.displayName =
  'IndustryCriteriaDraggableCollapsedCard';

const DraggableIndustryCriteriaCard =
  getDraggableCriteriaCard<IndustryCriteriaData>(
    IndustryCriteriaDraggableCollapsedCard,
    DraggedSearchCriteriaCardType.industry
  );
DraggableIndustryCriteriaCard.displayName = 'DraggableIndustryCriteriaCard';

const mapIndustryDictionaryToSelectOptions = R.map(({ id, fullName }) => ({
  value: id,
  label: fullName,
}));

const IndustryCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: IndustryCriteriaData;
  optionsFilter: (value: any) => boolean;
  onUpdate: () => void;
  changeCardImportance: (
    criteria: IndustryCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
}> = ({
  namePrefix,
  values,
  optionsFilter,
  onUpdate,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const industryCardConfig = cardsConfig[CardType.industry];

  const initialCardStatus = values.initialCardStatus;

  const industryOptions = useCacheContext(cacheSelectors.industryOptions);
  const { updateCache } = useCacheMethods();

  const [typeaheadOptions, setTypeaheadOptions] = useState(null);
  const [isIndustryOptionsLoading, setIsIndustryOptionsLoading] =
    useState(false);

  const isIndustryUnwanted = isExcludeIndustry(values);
  const topStackListName = getTopStackListName(values);

  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(values);

  const searchIndustryOptions = useCallback(
    (value?: string) => {
      loadIndustryAsyncOptions(
        {
          value,
        },
        ({ items }) => {
          setTypeaheadOptions(mapIndustryDictionaryToSelectOptions(items));
        }
      );
    },
    [setTypeaheadOptions]
  );

  const loadMoreIndustryOptions = useCallback(() => {
    if (!isIndustryOptionsLoading) {
      setIsIndustryOptionsLoading(true);

      const page = R.isEmpty(industryOptions.items)
        ? 0
        : industryOptions.page + 1;

      loadIndustryAsyncOptions(
        {
          page,
        },
        ({ items, total }) => {
          updateCache({
            industryOptions: {
              items: [
                ...industryOptions.items,
                ...mapIndustryDictionaryToSelectOptions(items),
              ],
              total,
              page,
            },
          });
          setIsIndustryOptionsLoading(false);
        }
      );
    }
  }, [updateCache, industryOptions, isIndustryOptionsLoading]);

  // initial dictionary load
  useEffect(() => {
    if (R.isEmpty(industryOptions.items) && !isIndustryOptionsLoading) {
      loadMoreIndustryOptions();
    }
  }, [industryOptions, loadMoreIndustryOptions, isIndustryOptionsLoading]);

  const onChangeInputValue = useCallback(
    (value) => {
      if (value) {
        searchIndustryOptions(value);
      } else {
        setTypeaheadOptions(null);
      }
    },
    [searchIndustryOptions, setTypeaheadOptions]
  );

  const selectIndustryValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        const onChange = props.onChange
          ? props.onChange
          : (value: any) => form.setFieldValue(field.name, value);

        return (
          <SelectRenderer
            isDisabled={isReadOnly}
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            getSingleValueTooltipProps={
              industryCardConfig.getStackItemTooltipProps
            }
            {...props}
            getDropDownItemTooltipProps={
              industryCardConfig.getSelectDropDownOptionTooltipProps
            }
            inputRegex={industryTitleRegex}
            isClearable
            filterOption={optionsFilter}
            isValidNewOption={isValidNewOption(CardType.industry)(form.values)}
            onChange={onChange}
            creatable={false}
            onInputChange={onChangeInputValue}
            options={
              typeaheadOptions ? typeaheadOptions : industryOptions.items
            }
            infiniteScrollOptions={{
              hasMore: industryOptions.items.length < industryOptions.total,
              threshold: 150,
              loadMore: loadMoreIndustryOptions,
            }}
            autoFocus
            openMenuOnFocus={initialCardStatus && !field.value}
            openMenuOnClick
            multilineInput
            closeMenuOnSelect
          />
        );
      },
    [
      industryCardConfig,
      optionsFilter,
      isReadOnly,
      initialCardStatus,
      onChangeInputValue,
      industryOptions,
      typeaheadOptions,
      loadMoreIndustryOptions,
    ]
  );

  const titleLabel = useMemo(() => {
    return cardsConfig[values.cardType].getCriteriaCardTitleLabel?.(values);
  }, [values]);

  return (
    <CriteriaEditCard
      className={classNames({
        [cardCommonStyles.unwantedCard]: isIndustryUnwanted,
      })}
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      changeCardImportance={changeCardImportance}
      stackItemControl={selectIndustryValueControl}
      collapsibleArea={
        <CriteriaImportanceExclusionSettings
          namePrefix={namePrefix}
          cardData={values}
          onImportanceChange={changeCardImportance}
          onExclude={onUpdate}
        />
      }
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const industryTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];

              return industryTitlesList
                .slice(0, 1)
                .map((val: FormSelectEntity) => {
                  return (
                    <Field
                      key={R.prop('value', val) || 'new'}
                      name={`${namePrefix}.${topStackListName}.0`}
                    >
                      {({ field, form }: FieldProps) => (
                        <div
                          ref={mainCriteriaNameFieldRef}
                          className={cardCommonStyles.titleFieldWithImportance}
                        >
                          {titleLabel}
                          {selectIndustryValueControl({
                            field,
                            form,
                            className: cardCommonStyles.multilineTitle,
                            isDisabled: isImportanceSectionOpened || isReadOnly,
                            focusField: !isReadOnly,
                            onKeyDown: getOnMainCardKeyDownCallback(
                              field,
                              arrayHelpers,
                              !isMaxListSizeReached
                            ),
                            onChange: async (value: { label: string }) => {
                              await form.setFieldValue(field.name, value);
                              updateMainFieldHandler(value);
                            },
                            getSingleValueTooltipProps:
                              industryCardConfig.getMainCardTooltipProps,
                          })}
                          {importanceIndicator}
                        </div>
                      )}
                    </Field>
                  );
                });
            }}
          </FieldArray>
          <Field name={`${namePrefix}.experience`}>
            {({ field, form }: FieldProps) => {
              return (
                <WithDefaultValueOnConditionalMounting
                  indicator={!isIndustryUnwanted}
                  defaultValue={null}
                  field={field}
                  form={form}
                >
                  <>
                    <IdealExperienceWidget
                      isReadOnly={isReadOnly}
                      values={field.value}
                      onChangeValues={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                    />
                    {isIdealExperienceDefined(field.value) && (
                      <AcceptableExperienceWidget
                        isReadOnly={isReadOnly}
                        values={field.value}
                        onChangeValues={(value) =>
                          form.setFieldValue(field.name, value)
                        }
                      />
                    )}
                  </>
                </WithDefaultValueOnConditionalMounting>
              );
            }}
          </Field>
          <Field name={`${namePrefix}.recent`}>
            {({ field, form }: FieldProps) => (
              <RecentExperienceWidget
                isReadOnly={isReadOnly}
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
                className={classNames({
                  [cardCommonStyles.unwantedCard]: isIndustryUnwanted,
                })}
              />
            )}
          </Field>
        </>
      )}
    </CriteriaEditCard>
  );
};

IndustryCriteriaCardEdit.displayName = 'IndustryCriteriaCardEdit';

type IndustryCriteriaCardProps = {
  namePrefix: string;
  cardData: IndustryCriteriaData;
  optionsFilter: (value: any) => boolean;

  onUpdate: () => void;
  onRemove: (value?: string | number) => void;
  changeCardImportance: (
    criteria: IndustryCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;

  onRemoveStackItem: (value: string | number, list?: string) => void;
  importanceSectionOrder: number;

  handleDrop: (id: string | number, itemId: string | number) => void;
  handleSingleCriteriaDrop: (
    id: string | number,
    itemId: string | number,
    groupId: string | number
  ) => void;
  isReadOnly?: boolean;
};

export const IndustryCriteriaCard: React.FC<IndustryCriteriaCardProps> = ({
  namePrefix,
  cardData,
  importanceSectionOrder,
  optionsFilter,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  handleDrop,
  handleSingleCriteriaDrop,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const cardTitle = getMainCardTitle(cardData);
  const [cardFooter] = getSearchCriteriaCardFooter(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const stackSize = getCardStackSize(cardData);
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: !isNewCard,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };
  const isIndustryUnwanted = isExcludeIndustry(cardData);

  const titleLabel = useMemo(() => {
    return cardsConfig[cardData.cardType].getCriteriaCardTitleLabel?.(cardData);
  }, [cardData]);

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <StateContext.Consumer>
            {({ states }) => {
              const canDrawMainCardFromStack =
                stackSize > 0 && states.isStackOpened;
              return (
                <DraggableIndustryCriteriaCard
                  titleLabel={titleLabel}
                  title={cardTitle}
                  footerText={cardFooter}
                  stackSize={stackSize}
                  stackItems={[topStackItems]}
                  cardClasses={{
                    wrapperClass: classNames({
                      [cardCommonStyles.unwantedCard]: isIndustryUnwanted,
                    }),
                    footerClass: cardCommonStyles.experienceYears,
                  }}
                  importance={cardImportance}
                  cardLabel={cardLabel}
                  criteria={cardData}
                  exclude={isExcludeIndustry(cardData)}
                  importanceSectionOrder={importanceSectionOrder}
                  onRemove={onRemove}
                  onRemoveStackItem={onRemoveStackItem}
                  handleDrop={handleDrop}
                  handleSingleCriteriaDrop={handleSingleCriteriaDrop}
                  canDrawMainCardFromStack={canDrawMainCardFromStack}
                  initialCardStatus={cardData.initialCardStatus}
                />
              );
            }}
          </StateContext.Consumer>
        ) : (
          <IndustryCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            optionsFilter={optionsFilter}
            onUpdate={onUpdate}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
IndustryCriteriaCard.displayName = 'IndustryCriteriaCard';
