import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useOutsideClick } from '@air/utils/hooks';
import { Button, FormField, Paragraph } from '@air/components';
import * as phrases from 'constants/phrases';
import {
  checkEmailValidity,
  SelectedEmailEntityT,
} from 'domain/EmailConfig/EmailTemplates';

import styles from './TemplateSavingControls.css';

type TemplateSavingPopup = {
  onCancel: () => void;
  onSave: ({ templateName }: { templateName: string }) => void;
  isEditingExistingTemplate: boolean;
  templateName: string;
};

const TemplateSavingPopup: React.FC<TemplateSavingPopup> = ({
  onSave,
  onCancel,
  isEditingExistingTemplate,
  templateName,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid: isFormValid, isDirty: isFormDirty },
  } = useForm({
    defaultValues: {
      templateName: isEditingExistingTemplate ? templateName : '',
    },
    mode: 'onChange',
  });

  const nameValue = watch('templateName');

  useEffect(() => {
    setValue('templateName', templateName);
  }, [templateName, setValue]);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <FormField
        autoComplete="off"
        type="text"
        {...register('templateName', {
          required: true,
        })}
        label={phrases.EMAIL_SECTION_CONTROLS_FOR_SAVING_TEMPLATE_INPUT_LABEL}
        maxLength={100}
        autoFocus
      />

      <div className={styles.popup}>
        <Button
          variant={
            isEditingExistingTemplate
              ? Button.variants.NEGATIVE_SECONDARY
              : Button.variants.POSITIVE_MAIN
          }
          className={styles.cancelButton}
          onClick={onCancel}
          small
        >
          {isEditingExistingTemplate
            ? phrases.EMAIL_SECTION_DISCARD_CHANGES_BUTTON_TEXT
            : phrases.EMAIL_SECTION_CONTROLS_FOR_SAVING_TEMPLATE_CANCEL_BUTTON_LABEL}
        </Button>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          disabled={!(isFormValid || (isFormDirty && nameValue?.length))}
          className={styles.saveButton}
          type="submit"
          small
        >
          {isEditingExistingTemplate
            ? phrases.EMAIL_SECTION_CONTROLS_FOR_SUBMIT_TEMPLATE_CHANGES_LABEL
            : phrases.EMAIL_SECTION_CONTROLS_FOR_SAVING_TEMPLATE_SAVE_BUTTON_LABEL}
        </Button>
      </div>
    </form>
  );
};

export const TemplateSavingControls: React.FC<{
  isEditingExistingTemplate: boolean;
  email: SelectedEmailEntityT;
  onSave: ({ templateName }: { templateName: string }) => void;
  onDiscard: () => void;
}> = ({ isEditingExistingTemplate, email, onSave, onDiscard }) => {
  const [showTemplateSavingTrigger, setShowTemplateSavingTrigger] =
    useState(false);
  const [showTemplateSavingControls, setShowTemplateSavingControls] =
    useState(false);

  useEffect(() => {
    /*
      We allow to save email as a template only if it has a subject (at least)
    */
    setShowTemplateSavingTrigger(checkEmailValidity(email));
  }, [email]);

  useEffect(() => {
    setShowTemplateSavingControls(isEditingExistingTemplate);
  }, [isEditingExistingTemplate]);

  const [outsideTemplatePopupClickRef] = useOutsideClick(
    () => {
      if (showTemplateSavingControls && !isEditingExistingTemplate) {
        setShowTemplateSavingControls(false);
      }
    },
    { useCapture: true }
  );

  return (
    <div
      className={classNames(styles.controls, {
        [styles.showTemplateSavingControls]: showTemplateSavingControls,
      })}
    >
      {showTemplateSavingControls ? (
        <div
          className={classNames(styles.templateSavingControlsWrapper, {
            [styles.editingTemplate]: isEditingExistingTemplate,
          })}
          ref={outsideTemplatePopupClickRef}
        >
          <TemplateSavingPopup
            onSave={(values) => {
              onSave(values);
              setShowTemplateSavingControls(false);
            }}
            onCancel={
              isEditingExistingTemplate
                ? onDiscard
                : () => setShowTemplateSavingControls(false)
            }
            isEditingExistingTemplate={isEditingExistingTemplate}
            templateName={email.name}
          />
        </div>
      ) : (
        <>
          {showTemplateSavingTrigger && (
            <Paragraph className={styles.controlsDescription}>
              <Button
                variant={Button.variants.INLINE}
                onClick={() => setShowTemplateSavingControls(true)}
              >
                {phrases.EMAIL_TEMPLATE_SAVE_BUTTON}
              </Button>
              {phrases.EMAIL_TEMPLATE_SAVE_DESCRIPTION}
            </Paragraph>
          )}
        </>
      )}
    </div>
  );
};
