import * as InterviewApi from 'features/InterviewSection/interviewApi';
import R from '@air/third-party/ramda';

export const searchHasModifiedDraft = async (searchId: number) => {
  const childList = await InterviewApi.fetchInterviewChildSearches(
    searchId
  ).fork(R.identity, R.identity);
  if (childList && !!childList.length) {
    const [interviewDraft] = childList;
    return interviewDraft.created !== interviewDraft.updated;
  }
  return false;
};
