import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  getExperience,
  filterExperienceByType,
  getCriteriaItems,
} from 'domain/CandidateData';
import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import {
  SearchTitleExperienceList,
  SearchTitleInfo,
  SearchCriteriaImportanceEnum,
  SearchTitleExperienceItem,
} from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';
import { RoleSearchCriteriaListExtras } from '@air/domain/SearchCriteriaCards/RoleCard/roleCardHelpers';

export interface CandidateExcludeRoleData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.role;
  exclude: true;
}

export interface CandidateIncludeRoleData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.role;
  exclude: false;
}

export type CandidateRoleData =
  | CandidateExcludeRoleData
  | CandidateIncludeRoleData;

const getRoleItem = (
  listItem: SearchTitleInfo
): CandidateCriteriaListItem<{ extras: RoleSearchCriteriaListExtras }> => ({
  id: listItem.id || listItem.fullName,
  label: listItem.fullName,
  experience: getExperience(listItem),
  status: listItem.status,
  alert: getAlertData(listItem.alerts),
  matchResolution: listItem.matchResolution,
  extras: {
    type: listItem.type,
    items: listItem.items,
  },
  interview: interviewDataMapper(listItem.refId, listItem.interview),
});

export const filterRoleExperience = filterExperienceByType<'title'>('title');

export const mapRoles = (
  searchTitleExperience: SearchTitleExperienceList
): CandidateRoleData[] => {
  const includeRoles: CandidateIncludeRoleData[] = (
    searchTitleExperience?.includeList || []
  ).map((item: SearchTitleExperienceItem) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchTitleExperienceItem,
      CandidateCriteriaListItem
    >(getRoleItem, item);

    return {
      id: `include-${item.idx}`,
      refId: item.refId,
      idx: item.idx,
      cardType: CardType.role,
      list,
      exclude: false,
      importance:
        criteriaImportanceOptions[
          item.importance as SearchCriteriaImportanceEnum
        ],
      status: primaryItem.status,
    };
  });

  const excludeRoles: CandidateExcludeRoleData[] = (
    searchTitleExperience?.excludeList || []
  ).map((item: SearchTitleExperienceItem) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchTitleExperienceItem,
      CandidateCriteriaListItem
    >(getRoleItem, item);

    return {
      id: `exclude-${item.idx}`,
      refId: item.refId,
      idx: item.idx,
      cardType: CardType.role,
      list,
      exclude: true,
      importance:
        criteriaImportanceOptions[
          item.importance as SearchCriteriaImportanceEnum
        ],
      status: primaryItem.status,
    };
  });

  return [...includeRoles, ...excludeRoles];
};
