export enum ActionType {
  LoginSuccess,
  LoginFailure,
  Logout,
  GotUserProfile,
  TimeOut,
  InvalidToken,
  ClearAuthError,
  ShowIntroGuide,
}
