/*
  This file contains common API calls, shared between
  different Kanban sections (Draft, Interview).
 */
import * as urls from 'constants/urls';
import * as sharedUrls from '@air/constants/apiEndpoints';
import * as Http from '@air/utils/http';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import {
  SearchExtendedResponse,
  SearchKanbanListResponse,
  SearchKanbanResponse,
} from '@air/api';
import { Task } from '@air/utils/fp';
import { KanbanListT } from 'domain/Kanban/Kanban';
import { REQUEST_PAGING_SIZE_KANBAN } from 'constants/app';
import { FetchSearchParamsT } from 'context';

const defaultSearchParams = {
  page: 0,
  size: REQUEST_PAGING_SIZE_KANBAN,
  sort: 'updated;desc',
};
export const fetchSearchesList = (params = {}) => {
  const searchParams = {
    ...defaultSearchParams,
    ...params,
  };
  return Http.get(urls.SEARCH_LIST_API)
    .bind(Http.withQueryParams(searchParams))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson)
    .map((res: SearchKanbanListResponse): KanbanListT<SearchKanbanResponse> => {
      return {
        items: res.items,
        total: res.total,
        loaded: true,
      };
    })
    .mapError(
      (): KanbanListT<SearchKanbanResponse> => ({
        items: [],
        total: 0,
        loaded: true,
      })
    );
};

export const fetchSearch = ({
  dataSourceId,
  jobDescriptionId,
}: FetchSearchParamsT): Task<SearchExtendedResponse> => {
  return Http.get(urls.makeSearchResultApiUrl2(dataSourceId, jobDescriptionId))
    .bind(Http.withRetry({ delay: 1000, count: 3 }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchCompaniesInfo = (ids: number[]) =>
  Http.get(sharedUrls.makeCompanyInfoApiUrl)
    .bind(Http.withQueryParams({ includeId: ids, size: ids.length }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
