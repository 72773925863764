import { CommentInfoResponse } from '@air/api';
import * as Http from '@air/utils/http';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import * as urls from '@air/constants/apiEndpoints';
import { Task } from '@air/utils/fp';

export type CommentParamsT = {
  refId: string;
  commentId?: string;
  mainProfileId: string;
  atsId: number | string;
  jobId: string;
};

export const createComment = (
  params: CommentParamsT,
  comment: string
): Task<CommentInfoResponse> => {
  return Http.post(urls.makeInterviewNotesApiUrlV2(params))
    .bind(Http.withJsonBody({ text: comment }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateComment = (
  params: CommentParamsT,
  comment: string
): Task<CommentInfoResponse> => {
  return Http.put(urls.makeInterviewNotesApiUrlV2(params))
    .bind(Http.withJsonBody({ text: comment }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const removeComment = (
  params: CommentParamsT
): Task<CommentInfoResponse> => {
  return (
    Http.remove(urls.makeInterviewNotesApiUrlV2(params))
      .toTask()
      // uncomment when delete returns 204
      //.chain(parseResponseJson)
      .chainError(parseErrorJson)
  );
};

export const markCommentAsRead = (
  commentId: string,
  refId: string,
  profileId: string
): Task<CommentInfoResponse> => {
  return Http.put(
    `${urls.makeInterviewNotesApiUrl(refId, profileId, commentId)}/read`
  )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
