import { useCallback } from 'react';
import { useDebounce } from '@air/utils/hooks';
import { useKanbanMethods } from 'providers/KanbanProvider';

const UPDATE_SEARCHES_DEBOUNCE_TIME = 2000;

export const useUpdateSearchesCallback = () => {
  const { updateAllSearches } = useKanbanMethods();

  const onUpdateCallback = useCallback(() => {
    updateAllSearches();
  }, [updateAllSearches]);
  return useDebounce(onUpdateCallback, UPDATE_SEARCHES_DEBOUNCE_TIME, true);
};
