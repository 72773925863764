/*
  CacheProvider is used to store backend information that doesn't
  need to be refetched during user's session.
*/
import React, { useMemo } from 'react';
import useSetState from 'react-use/lib/useSetState';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';
import { CacheContext, CacheContextT } from '@air/context';

export const CacheProvider: React.FC = React.memo(({ children }) => {
  const [cache, updateCache] = useSetState<{ [key: string]: any }>({
    industryOptions: { items: [], total: 0, page: 0 },
  });
  const cacheValue = useMemo(
    () => ({
      cache,
      methods: {
        updateCache,
      },
    }),
    [cache, updateCache]
  );

  return (
    <CacheContext.Provider value={cacheValue}>{children}</CacheContext.Provider>
  );
});
CacheProvider.displayName = 'CacheProvider';

export const useCacheContext = <Selected,>(
  selector: (state: CacheContextT) => Selected
) => {
  return useContextSelector(CacheContext, selector);
};

export const useCacheMethods = () => {
  return useEqualContextSelector(
    CacheContext,
    (state: CacheContextT) => state.methods,
    R.shallowEqualObjects
  );
};
