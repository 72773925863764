// imports from vendor deps
import React from 'react';
import classNames from 'classnames';

// imports from types
// import … from 'types/…';

// imports from 'components'
// import {} from 'components/…';

// imports from 'constants'
// import {} from 'constants/…'

// import from images
// import {} from 'images/…'

// imports from helpers
// import {} from 'utils/…'

// imports from styles
import styles from './SvgIcon.css';

// component proptypes
type Props = {
  icon: string;
  title?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
  style?: React.CSSProperties;
};

// component state

// exports / class definitions
export class SvgIcon extends React.PureComponent<
  Props & React.SVGProps<SVGUseElement>
> {
  static defaultProps: Omit<Props, 'icon'> = {
    className: '',
    onClick: null,
  };

  render() {
    const {
      onClick,
      icon,
      title = '',
      className,
      width,
      height,
      style,
      ...restProps
    } = this.props;

    return (
      <svg
        className={classNames(styles.svgIcon, className, icon)}
        style={width || height ? { width, height, ...style } : { ...style }}
        onClick={onClick}
      >
        {title && <title>{title}</title>}
        <use {...restProps} xlinkHref={`#${icon}`} />
      </svg>
    );
  }
}
