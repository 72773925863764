import React, { useCallback, useMemo, useState } from 'react';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import styles from './GenerateReportPanel.css';
import {
  Button,
  FormField,
  Header,
  Paragraph,
  SvgIcon,
  TooltipWrapper,
} from '@air/components';
import { FormFieldTypes } from '@air/components/Form/utils';
import { useFieldArray, useForm } from 'react-hook-form';
import { emailRegex } from '@air/utils/strings';
import { useDebounce } from '@air/utils/hooks';
import { CLICK_DEBOUNCE_TIME_LONG } from '@air/constants/app';
import R from '@air/third-party/ramda';
import { downloadBlob } from '@air/utils/downloadFiles';
import * as InterviewApi from 'features/InterviewSection/interviewApi';
import dayjs from 'dayjs';
import { toast } from '@air/third-party/toast';
import classNames from 'classnames';

const FORM_EMAILS_FIELD_NAME = 'emails';
const MAX_EMAILS_TO_ADD = 100;

type FieldNameType = `emails.${number}.value`;

const getFieldId = (index: number): FieldNameType =>
  `${FORM_EMAILS_FIELD_NAME}.${index}.value`;

const DEFAULT_VALUES = { [FORM_EMAILS_FIELD_NAME]: [{ value: '' }] };

export const GenerateReportPanel: React.FC<{
  searchId: number;
  searchName: string;
  onSend?: () => void;
  isDraft?: boolean;
  isEmpty: boolean;
  noSuitableData?: boolean;
}> = ({ searchId, searchName, onSend, isEmpty, noSuitableData }) => {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { isDirty, isValid, touchedFields, errors },
  } = useForm<typeof DEFAULT_VALUES>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
    shouldUnregister: true,
  });

  const [isReportGenerating, setIsReportGenerating] = useState(false);
  const [isReportSending, setIsReportSending] = useState(false);

  const {
    fields: emails,
    append,
    remove,
  } = useFieldArray({
    control,
    name: FORM_EMAILS_FIELD_NAME,
  });

  const isAddingAllowed = useMemo(
    () => emails.length < MAX_EMAILS_TO_ADD,
    [emails]
  );

  const onAddEmailButtonClicked = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const preventOnEnterCallback = useCallback((e) => {
    // we need to release default behaviour submitting on Enter
    // to be able to add the new field on pressing Enter key
    e.preventDefault();
  }, []);

  const submitForm = useCallback(
    (formValues: { emails: { value: string }[] }) => {
      if (!R.isEmpty(formValues.emails)) {
        setIsReportSending(true);
        const emails = formValues.emails.map(({ value }) => value);
        InterviewApi.sendPositionStatusReport(searchId, emails).fork(
          () => {
            toast.error(sharedPhrases.GENERAL_ERROR_TRY_AGAIN);
            setIsReportSending(false);
          },
          () => {
            toast.success(phrases.GENERATE_REPORT_SUCCESS_TEXT);
            onSend?.();
            setIsReportSending(false);
          }
        );
      }
    },
    [searchId, onSend]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && isAddingAllowed) {
        onAddEmailButtonClicked();
      }
    },
    [onAddEmailButtonClicked, isAddingAllowed]
  );

  const onClickDownload = useCallback(() => {
    setIsReportGenerating(true);
    InterviewApi.downloadPositionStatusReport(searchId).fork(
      () => {
        setIsReportGenerating(false);
      },
      (response) => {
        setIsReportGenerating(false);
        return downloadBlob(
          `${phrases.POSITION_STATUS_REPORT_TITLE} - ${searchName} - ${dayjs(
            new Date()
          ).format('MMMM DD, YYYY')}`
        )(response);
      }
    );
  }, [searchId, searchName]);

  const onClickDownloadDebounced = useDebounce(
    onClickDownload,
    CLICK_DEBOUNCE_TIME_LONG,
    true
  );

  const onBlur = useCallback(
    (fieldName: FieldNameType) => {
      const fieldValue: string = getValues(fieldName) || '';
      if (fieldValue.startsWith(' ') || fieldValue.endsWith(' ')) {
        setValue(fieldName, fieldValue.trim());
        trigger();
      }
    },
    [setValue, trigger, getValues]
  );

  const header = (
    <Header level={3} bold>
      {phrases.GENERATE_REPORT_HEADER}
    </Header>
  );

  return (
    <form
      className={classNames(styles.panelContent, {
        [styles.loaderCursor]: isReportGenerating,
      })}
      onSubmit={preventOnEnterCallback}
      noValidate
    >
      {!isEmpty && noSuitableData && (
        <>
          <div className={styles.panelHeaderWrapper}>{header}</div>
          <div className={styles.panelScrollableContent}>
            <div className={styles.emptyMessageWrapper}>
              <Header className={styles.header} level={3}>
                {phrases.GENERATE_REPORT_NO_SUITABLE_DATA_HEADER}
              </Header>
              <Paragraph>
                {phrases.GENERATE_REPORT_NO_SUITABLE_DATA_TEXT}
              </Paragraph>
            </div>
          </div>
        </>
      )}
      {!isEmpty && !noSuitableData && (
        <>
          <div className={styles.panelHeaderWrapper}>
            {header}
            <Paragraph className={styles.panelHeaderText}>
              {phrases.GENERATE_REPORT_TEXT[0]}
              <Button
                className={styles.panelHeaderTextDownloadLink}
                variant={Button.variants.INLINE}
                onClick={isReportGenerating ? R.noop : onClickDownloadDebounced}
              >
                {phrases.GENERATE_REPORT_TEXT[1]}
              </Button>
              {phrases.GENERATE_REPORT_TEXT[2]}
            </Paragraph>
          </div>
          <div className={styles.panelScrollableContent}>
            {emails.map((email, index) => {
              const hasError =
                touchedFields[FORM_EMAILS_FIELD_NAME]?.[index] &&
                !!errors[FORM_EMAILS_FIELD_NAME]?.[index];
              const errorMessage =
                errors[FORM_EMAILS_FIELD_NAME]?.[index]?.value.message;
              const label = index === 0 ? sharedPhrases.EMAIL : '';
              return (
                <div className={styles.emailFieldWrapper} key={email.id}>
                  <FormField
                    id={getFieldId(index)}
                    type={FormFieldTypes.text}
                    className={styles.emailField}
                    {...register(getFieldId(index), {
                      required: {
                        value: true,
                        message: sharedPhrases.FORM_ERROR_NO_VALUE,
                      },
                      pattern: {
                        value: emailRegex,
                        message: sharedPhrases.FORM_ERROR_INVALID_EMAIL,
                      },
                      onBlur: () => onBlur(getFieldId(index)),
                    })}
                    hasError={hasError}
                    label={hasError && errorMessage ? errorMessage : label}
                    placeholder="example@email.com"
                    onKeyDown={onKeyDown}
                  />
                  {emails.length > 1 && (
                    <button
                      className={styles.emailRemoveButton}
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <SvgIcon icon="close-icon" />
                    </button>
                  )}
                </div>
              );
            })}
            <TooltipWrapper
              triggerClassName={styles.addEmailButtonTooltip}
              enabled={!isAddingAllowed}
              placement="top"
              trigger="hover"
              tooltip={phrases.GENERATE_REPORT_DISABLED_ADD_BUTTON_TOOLTIP(
                MAX_EMAILS_TO_ADD
              )}
              disabledChildren={!isAddingAllowed}
            >
              <Button
                disabled={!isAddingAllowed}
                icon="add-stroke"
                className={styles.addEmailButton}
                variant={Button.variants.DEFAULT}
                onClick={onAddEmailButtonClicked}
              >
                {phrases.GENERATE_REPORT_ADD_EMAIL_BUTTON}
              </Button>
            </TooltipWrapper>
          </div>
          <div className={styles.panelFooter}>
            <Button
              onClick={isReportSending ? R.noop : handleSubmit(submitForm)}
              disabled={!isDirty || !isValid}
              variant={Button.variants.POSITIVE_CONFIRM}
              small
              alignCenter
              loaderPosition={Button.loaderPosition.LEFT}
              isLoading={isReportSending}
            >
              {isReportSending
                ? phrases.GENERATE_REPORT_SENDING_BUTTON
                : phrases.GENERATE_REPORT_SEND_BUTTON}
            </Button>
          </div>
        </>
      )}
      {isEmpty && (
        <>
          <div className={styles.panelHeaderWrapper}>{header}</div>
          <div className={styles.panelScrollableContent}>
            <div className={styles.emptyMessageWrapper}>
              <Header className={styles.header} level={3}>
                {phrases.GENERATE_REPORT_EMPTY_HEADER_NO_DATA}
              </Header>
              <Paragraph>
                {phrases.GENERATE_REPORT_EMPTY_TEXT_NO_DATA}
              </Paragraph>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

GenerateReportPanel.displayName = 'GenerateReportPanel';
