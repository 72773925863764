/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  CustomerBaseResponse,
  JobDescriptionLocationResponse,
  JobDescriptionWithPlaceholderV2,
} from './';

/**
 *
 * @export
 * @interface JobDescriptionResponseV2
 */
export interface JobDescriptionResponseV2 {
  /**
   *
   * @type {string}
   * @memberof JobDescriptionResponseV2
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof JobDescriptionResponseV2
   */
  externalId: string;
  /**
   *
   * @type {string}
   * @memberof JobDescriptionResponseV2
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof JobDescriptionResponseV2
   */
  appliedCount: number;
  /**
   *
   * @type {number}
   * @memberof JobDescriptionResponseV2
   */
  appliedPassiveCount: number;
  /**
   *
   * @type {string}
   * @memberof JobDescriptionResponseV2
   */
  status: string;
  /**
   *
   * @type {number}
   * @memberof JobDescriptionResponseV2
   */
  opened: number;
  /**
   *
   * @type {Array<string>}
   * @memberof JobDescriptionResponseV2
   */
  approvers: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof JobDescriptionResponseV2
   */
  owners: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JobDescriptionResponseV2
   */
  placeholderProcessingStatus?: JobDescriptionResponseV2PlaceholderProcessingStatusEnum;
  /**
   *
   * @type {JobDescriptionWithPlaceholderV2}
   * @memberof JobDescriptionResponseV2
   */
  descriptionWithPlaceholders?: JobDescriptionWithPlaceholderV2;
  /**
   *
   * @type {CustomerBaseResponse}
   * @memberof JobDescriptionResponseV2
   */
  creator?: CustomerBaseResponse;
  /**
   *
   * @type {Array<JobDescriptionLocationResponse>}
   * @memberof JobDescriptionResponseV2
   */
  locations: Array<JobDescriptionLocationResponse>;
  /**
   *
   * @type {Array<string>}
   * @memberof JobDescriptionResponseV2
   */
  specializations?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum JobDescriptionResponseV2PlaceholderProcessingStatusEnum {
  NOTSTARTED = 'NOT_STARTED',
  INPROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
