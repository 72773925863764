/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum DataSourceType {
  ORACLETALEO = 'ORACLE_TALEO',
  EMPLOYAATS = 'EMPLOYA_ATS',
  GREENHOUSEATS = 'GREENHOUSE_ATS',
  LEVERATS = 'LEVER_ATS',
  GITHUB = 'GITHUB',
  SAPATS = 'SAP_ATS',
}
