import React, { MouseEventHandler } from 'react';

import { Button } from '@air/components';

import styles from './LoadMoreButton.css';

type Props = {
  onClick: (increasePage: boolean) => void;
  isLoading: boolean;
  text: string;
};

export const LoadMoreButton: React.FC<Props> = ({
  onClick,
  isLoading,
  text,
}) => {
  return (
    <Button
      small
      className={styles.loadMoreButton}
      variant={Button.variants.POSITIVE_MAIN}
      isLoading={isLoading}
      loaderPosition={Button.loaderPosition.FIXED_CENTER}
      loaderColor="blue"
      onClick={onClick as () => MouseEventHandler<HTMLButtonElement>}
    >
      {isLoading ? '' : text}
    </Button>
  );
};

LoadMoreButton.displayName = 'LoadMoreButton';
