import R from '@air/third-party/ramda';
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  RefObject,
} from 'react';
import { isElementOverflown } from '@air/utils/dom';
import { useContextSelector, Context } from 'use-context-selector';

export function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function usePrevious<T>(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

type useOutsideClickT = <T = any>(
  handler: (e: MouseEvent) => void,
  options?: {
    useCapture?: boolean;
    stopPropagation?: boolean;
    triggerEvent?: 'click' | 'mouseup' | 'mousedown';
  }
) => [React.Ref<T>, { current: T }];
export const useOutsideClick: useOutsideClickT = (
  handler,
  options = { useCapture: false, stopPropagation: false, triggerEvent: 'click' }
) => {
  const { useCapture, stopPropagation, triggerEvent = 'click' } = options;
  const [element, setElement] = useState(null);
  const savedHandler = useRef<(...args: any[]) => any>();
  const ref = useCallback((node) => {
    setElement(node);
  }, []);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const mouseEventHandler = useCallback(
    (e: any) => {
      if (
        element &&
        !element.contains(e.target as Node) &&
        savedHandler.current
      ) {
        if (stopPropagation) e.stopPropagation();
        savedHandler.current(e);
      }
    },
    [element, stopPropagation]
  );

  useEffect(() => {
    document.removeEventListener(triggerEvent, mouseEventHandler, useCapture);

    if (element) {
      document.addEventListener(triggerEvent, mouseEventHandler, useCapture);
    }

    return () =>
      document.removeEventListener(triggerEvent, mouseEventHandler, useCapture);
  }, [element, mouseEventHandler, triggerEvent, useCapture]);

  return [ref, { current: element }];
};

export const useDebounce = <T extends (...args: any[]) => any>(
  fn: T,
  delay: number,
  immediate = false
) => {
  return useMemo(
    () => R.debounce(fn, delay, immediate),
    [fn, delay, immediate]
  );
};

/* Hook to detect if DOM element has scroll (or is overflown in general) */
export function useOverflowCheck<T>(...deps: any): [RefObject<T>, boolean] {
  const $el = useRef(null);
  const [isRefOverflown, setOverflowStatus] = useState(false);
  useEffect(() => {
    if ($el.current) {
      setOverflowStatus(isElementOverflown($el.current));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [...deps]);

  return [$el, isRefOverflown];
}

type UseAnimationStopType = () => [boolean, () => void];

/* Hook to stop animation on mouse enter */
export const useAnimationStop: UseAnimationStopType = () => {
  const [isAnimationStopped, stopAnimation] = useState(false);

  const onMouseEnter = useCallback(
    () => !isAnimationStopped && stopAnimation(true),
    [isAnimationStopped]
  );

  return [isAnimationStopped, onMouseEnter];
};

export const useTrueFalseState = (defaultValue: boolean) => {
  const [value, setValue] = useState(defaultValue);

  const setTrueValue = useCallback(() => {
    setValue(true);
  }, [setValue]);

  const setFalseValue = useCallback(() => {
    setValue(false);
  }, [setValue]);

  return [value, setValue, setTrueValue, setFalseValue] as [
    typeof value,
    typeof setValue,
    (...arg: any) => void,
    (...arg: any) => void
  ];
};

export const useEqualContextSelector = <T, R>(
  ctx: Context<T>,
  selector: (val: T) => R,
  isEql: (a: R | null, b: R) => boolean
) => {
  const patchedSelector = useMemo(() => {
    let prevValue: R | null = null;

    return (state: T) => {
      const nextValue: R = selector(state);

      if (prevValue !== null && isEql(prevValue, nextValue)) {
        return prevValue;
      }

      prevValue = nextValue;

      return nextValue;
    };
  }, [isEql, selector]);

  return useContextSelector(ctx, patchedSelector);
};
