import { CompanyExtendedListResponse, CompanySizeResponse } from '@air/api';
import * as phrases from '@air/constants/phrases';

/**
 * Converter function, which is used to convert ranges into a string
 * of format {minSize} - {maxSize} Employees, or {minSize}+ Employees.
 *
 * @param company of type T, which contains minSize, maxSize properties.
 *
 */
export const MAX_NUMBER_OF_EMPLOYEES = 2147483647;
export const getCompanySizeDescription = <
  T extends { maxSize?: number; minSize?: number } =
    | CompanyExtendedListResponse
    | CompanySizeResponse
>(
  company: T
) => {
  return `${company.minSize}${
    company.maxSize === MAX_NUMBER_OF_EMPLOYEES ? '+' : `-${company.maxSize}`
  } ${phrases.COMPANY_SIZE_TYPE_EMPLOYEES}`;
};
