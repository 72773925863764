/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum DictionaryValidationErrorType {
  FULLNAMEABSENT = 'FULL_NAME_ABSENT',
  FULLNAMEDUPLICATE = 'FULL_NAME_DUPLICATE',
  SHORTNAMEDUPLICATE = 'SHORT_NAME_DUPLICATE',
  ALIASDUPLICATE = 'ALIAS_DUPLICATE',
  MINSIZEABSENT = 'MIN_SIZE_ABSENT',
  MAXSIZEABSENT = 'MAX_SIZE_ABSENT',
  MINMAXSIZEINTERNALOVERLAP = 'MIN_MAX_SIZE_INTERNAL_OVERLAP',
  MINMAXSIZEOVERLAP = 'MIN_MAX_SIZE_OVERLAP',
}
