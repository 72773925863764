import React from 'react';
import classNames from 'classnames';

import { SvgIcon } from '@air/components';

import styles from './ContactItem.css';

type Props = {
  containerClassName?: string;
  contentClassName?: string;
  isEnrichedByPIPL: boolean;
  onIconClicked?: () => void;
  isEditMode?: boolean;
};

export const ContactItem: React.FC<Props> = ({
  containerClassName,
  contentClassName,
  isEnrichedByPIPL,
  onIconClicked,
  isEditMode,
  children,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, containerClassName)}
      data-testid="email-data-item"
    >
      <div
        className={classNames(styles.enrichedContainer, {
          [styles.showEnriched]: isEnrichedByPIPL,
          [styles.showEnrichedInEditMode]: isEditMode,
        })}
      >
        <div className={styles.enrichedIcon} />
      </div>
      <div
        className={classNames(styles.dataItem, contentClassName, {
          [styles.dataItemInEditMode]: isEditMode,
        })}
      >
        {children}
      </div>
      {!isEditMode && (
        <SvgIcon
          className={styles.icon}
          icon="matching-icon"
          onClick={onIconClicked}
        />
      )}
    </div>
  );
};
