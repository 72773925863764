/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum CandidateSearchProfileStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  NOTPROCESSED = 'NOT_PROCESSED',
  RECOMMENDEDFORREJECTION = 'RECOMMENDED_FOR_REJECTION',
  ATSFAILEDTOPARSE = 'ATS_FAILED_TO_PARSE',
  MANUALREJECTION = 'MANUAL_REJECTION',
  MANUALSHORTLIST = 'MANUAL_SHORTLIST',
  MANUALUNREJECTION = 'MANUAL_UNREJECTION',
  MANUALUNSHORTLIST = 'MANUAL_UNSHORTLIST',
}
