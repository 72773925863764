// imports from vendor deps
import React from 'react';
import classNames from 'classnames';

// imports from types

// imports from 'components'
import { AutoSizeInput, TooltipWrapper } from '@air/components';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'

// imports from styles
import styles from './EditableTitle.css';

// component proptypes
export type Props = {
  disabled: boolean;
  optional: boolean;
  value: string;
  maxLength?: number;
  className?: string;
  textComponent: string | React.ComponentType<any>;
  onChange?: (value: string) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  // default value is applied to field, when the input is cleared
  defaultValue?: string | null;
  placeholder?: string;
};

// component state
type State = {
  isEditing: boolean;
  draftTitle: string;
  titleFocusTimeout: ReturnType<typeof setTimeout>;
};

const DELAY_FOCUS_ON_INPUT = 500;

// exports / class definitions
export class EditableTitle extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    disabled: false,
    optional: false,
    textComponent: 'p',
    className: '',
    autoFocus: false,
    defaultValue: null,
  };

  inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: props.autoFocus,
      draftTitle: props.value,
      titleFocusTimeout: null,
    };
  }

  componentDidMount() {
    if (this.props.autoFocus && this.inputRef.current) {
      /* This ensures that job appears (animates) first,
      then the input is created and then it's being focused on.
      Otherwise the animations of appearing/disappearing job when clicking on Kanban card
      and going back is broken. See AR-9467.
      */
      this.setState({
        titleFocusTimeout: setTimeout(() => {
          this.inputRef.current.focus();
        }, DELAY_FOCUS_ON_INPUT),
      });
    }
  }
  componentWillUnmount() {
    if (this.state.titleFocusTimeout) {
      clearTimeout(this.state.titleFocusTimeout);
    }
  }

  // static getDerivedStateFromProps() {}
  // getSnapshotBeforeUpdate(prevProps, prevState) {}
  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isEditing } = this.state;
    if (!prevState.isEditing && isEditing && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }
  // componentWillUnmount() {}

  startEditing = (e: React.SyntheticEvent) => {
    e && e.stopPropagation();
    if (this.state.isEditing) return;

    this.setState({
      isEditing: true,
    });
  };

  finishEditing = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e && e.stopPropagation();
    const { optional, defaultValue } = this.props;
    const { draftTitle } = this.state;
    const nextState = {
      draftTitle,
      isEditing: false,
    };

    const inputValue = (e.target as HTMLInputElement).value.trim();
    if (inputValue || optional) {
      this.props.onChange(inputValue);
      nextState.draftTitle = inputValue;
    } else if (!inputValue && defaultValue) {
      this.props.onChange(defaultValue);
      nextState.draftTitle = defaultValue;
    }

    this.setState(nextState);
  };

  onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({
      draftTitle: value,
    });
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Finish editing on Enter press
    if (e.key === 'Enter') {
      this.inputRef.current.blur();
    }
  };

  render() {
    const { draftTitle, isEditing } = this.state;
    const {
      disabled,
      value,
      maxLength,
      className,
      textComponent: TextComponent,
      placeholder,
    } = this.props;

    if (disabled) {
      return (
        <TooltipWrapper enabled placement="top" trigger="hover" tooltip={value}>
          <TextComponent
            className={classNames(styles.disabledEditableTitle, className)}
          >
            <span> {value} </span>
          </TextComponent>
        </TooltipWrapper>
      );
    }

    return (
      <TooltipWrapper
        enabled
        containerClassName={classNames({
          [styles.titleTooltipHidden]: isEditing,
        })}
        triggerClassName={styles.titleTooltipTrigger}
        placement="top"
        trigger="hover"
        tooltip={value}
      >
        <AutoSizeInput
          ref={this.inputRef}
          type="text"
          className={classNames(styles.editableField, className)}
          value={draftTitle}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={this.onInput}
          onBlur={this.finishEditing}
          onFocus={this.onFocus}
          onClick={this.startEditing}
          onKeyDown={this.onKeyDown}
        />
      </TooltipWrapper>
    );
  }
}
