import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as phrases from '@air/constants/phrases';
import { Button } from 'components';

import { DataSourceType } from '@air/api/models';
import { FormViewTitle } from 'components/FormView/FormView';
import { UIText, FormDropdown } from '@air/components';
import { availableATS } from 'domain/ATS/ATSIntegration';
import formViewStyles from 'components/FormView/FormView.css';
import styles from './SignupFormSteps.css';

export const ATSSelectionStep: React.FC<{
  onSelect: (atsType: DataSourceType, atsSettings?: any) => void;
}> = ({ onSelect }) => {
  const { control, handleSubmit } = useForm<Record<string, any>>({
    defaultValues: { ats: { label: null, value: null } },
    shouldUnregister: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const formValues = useWatch({ control });
  const canGoToNextStep = !!formValues.ats.value;

  const onSelectATS = useCallback(
    async ({ ats: { value } }) => {
      setIsLoading(true);
      await onSelect(value);
      setIsLoading(false);
    },
    [onSelect]
  );

  return (
    <form onSubmit={handleSubmit(onSelectATS)}>
      <UIText small bold className={formViewStyles.supHeader}>
        {phrases.getSignupStep(2)}
      </UIText>
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_FORM_TITLE}
        description={phrases.ATS_INTEGRATION_FORM_DESCRIPTION}
      />
      <Controller
        name="ats"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormDropdown
            isBorderOverflown
            hasWarning={false}
            isDisabled={false}
            id="ats"
            options={availableATS}
            placeholder={phrases.ATS_DROPDOWN_PLACEHOLDER}
            className={styles.atsDropdown}
            {...field}
          />
        )}
      />
      <Button
        type="submit"
        className={classNames(
          styles.nextStepButton,
          formViewStyles.actionButton
        )}
        disabled={!canGoToNextStep}
        isLoading={isLoading}
        variant={Button.variants.POSITIVE_CONFIRM}
        loaderColor="white"
      >
        {phrases.SIGNUP_NEXT_STEP_BUTTON}
      </Button>
    </form>
  );
};
ATSSelectionStep.displayName = 'ATSSelectionStep';
