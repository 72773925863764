import { AcceptedFile } from 'context';

export enum UploadMessageType {
  ADD_FILES = 'add_files',
  REMOVE_FILE = 'remove_file',
  STOP_QUEUE = 'stop_queue',
}

export enum ResumeUploaderEventType {
  QUEUE_STARTED = 'started',
  QUEUE_PAUSED = 'paused',
  QUEUE_STOPPED = 'stopped',
  ITEM_PROCESSING = 'processing',
  ITEM_PROCESSED = 'processed',
  ITEM_ERROR = 'error',
}

export interface UploadMessage {
  type: UploadMessageType;
  payload: object;
}

export interface UploadMessageAddFiles extends UploadMessage {
  type: UploadMessageType.ADD_FILES;
  payload: { files: FileItemInfo[] };
}

export interface UploadMessageRemoveFile extends UploadMessage {
  type: UploadMessageType.ADD_FILES;
  payload: { id: string };
}

export interface UploadMessageStopQueue extends UploadMessage {
  type: UploadMessageType.STOP_QUEUE;
}

export interface MessageHandler<T extends UploadMessage> {
  handleEvent(messageData: T['payload']): void;
}

export type FileItemInfo = {
  file: AcceptedFile;
  tempId: string;
  atsId: number;
  requisitionId: string;
  tag?: string;
  passive?: boolean;
};

export type RequisitionId = string;
