import {
  CustomerListResponseV1,
  CustomerResponse,
  LoggedInCustomerInfoResponse,
  RoleName,
  InternalStatus,
  AtsStatus,
} from '@air/api';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';

export type NewUserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  role: RoleName;
};

export type UpdatedUserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  role: RoleName;
  status: InternalStatus;
};

export type UserInfo = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isCurrent: boolean;
  isInactive: boolean;
  role: RoleName;
  status: InternalStatus;
  atsStatus: AtsStatus;
  atsRole: null;
};

export type UserData = {
  users: UserInfo[];
  total: number;
};

export const roleToRoleLabelMap = {
  [RoleName.SYSTEMADMIN]: phrases.ROLES_SUPERADMIN,
  [RoleName.CUSTOMERADMIN]: phrases.ROLES_CUSTOMER_ADMIN,
  [RoleName.RECRUITER]: phrases.ROLES_RECRUITER,
  [RoleName.APPLICANT]: phrases.ROLES_APPLICANT,
  [RoleName.ADMINPORTAL]: phrases.ROLES_ADMIN_PORTAL,
};

export const isInactiveUser = (userInternalStatus: InternalStatus) =>
  userInternalStatus === InternalStatus.INACTIVE;

export const isCustomerAdmin = (
  role: RoleName
): role is RoleName.CUSTOMERADMIN => role === RoleName.CUSTOMERADMIN;
export const isRecruiter = (role: RoleName): role is RoleName.RECRUITER =>
  role === RoleName.RECRUITER;

export const mapUserToUserInfo = (
  user: CustomerResponse,
  isCurrent: boolean
): UserInfo => {
  const role = user.role || RoleName.RECRUITER;

  return {
    id: user.customerId,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    isCurrent,
    isInactive: isInactiveUser(user.internalStatus),
    role,
    status: user.internalStatus,
    atsStatus: user.atsStatus,
    atsRole: null,
  };
};

export const mapUsersToUserData = (
  currentUserInfo: LoggedInCustomerInfoResponse
) => (data: CustomerListResponseV1): UserData => {
  return {
    users: data.items.map((user) =>
      mapUserToUserInfo(user, user.customerId === currentUserInfo?.customerId)
    ),
    total: data.total,
  };
};

export const updateStatus = (
  id: number,
  status: InternalStatus,
  usersData: UserInfo[]
): UserInfo[] => {
  const idx = R.findIndex(R.propEq('id', id), usersData);
  if (idx >= 0) {
    return R.set(
      R.lensPath([idx, 'isInactive']),
      isInactiveUser(status),
      usersData
    );
  }
  return usersData;
};
