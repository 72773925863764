import React, { useCallback, useEffect, useState } from 'react';
import R from '@air/third-party/ramda';
import { useForm } from 'react-hook-form';

import * as phrases from '@air/constants/phrases';
import { Button, FormField } from '@air/components';
import { FormFieldTypes, FormFields } from '@air/components/Form/utils';
import { emailRegex } from '@air/utils/strings';
import { FormViewTitle } from '../FormView/FormView';
import formViewStyles from '../FormView/FormView.css';
import styles from './ResetPasswordForm.css';

export const ResetPasswordForm: React.FC<{
  onSubmit?: (email: string) => void;
  initialValues: { email: string };
}> = ({ onSubmit = () => {}, initialValues }) => {
  const [isResetFormShown, showResetForm] = useState(true);
  const [savedEmail, saveEmail] = useState(initialValues.email);
  const {
    register,
    watch,
    handleSubmit,
    formState,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    defaultValues: {
      [FormFields.email]: savedEmail,
    },
  });

  useEffect(() => {
    setValue(FormFields.email, initialValues.email, {
      shouldValidate: initialValues.email.length > 0,
    });
    saveEmail(initialValues.email);
  }, [setValue, initialValues.email]);

  const emailLabel =
    errors?.email?.message ?? phrases.RESET_PASSWORD_EMAIL_TEXT;

  const emailValue = watch(FormFields.email);

  const submitHandler = useCallback(
    ({ email }) => {
      const trimmedEmail = email?.trim();
      if (!trimmedEmail) {
        return;
      }
      onSubmit?.(trimmedEmail);
      saveEmail(trimmedEmail);
      showResetForm(false);
    },
    [onSubmit]
  );

  return (
    <form
      className={styles.resetPasswordForm}
      onSubmit={handleSubmit(submitHandler)}
      noValidate
    >
      {isResetFormShown ? (
        <>
          <FormViewTitle
            title={phrases.RESET_PASSWORD_FORM_TITLE}
            description={phrases.RESET_PASSWORD_FORM_DESCRIPTION}
          />
          <FormField
            id={FormFields.email}
            type={FormFieldTypes.email}
            {...register(FormFields.email, {
              required: {
                value: true,
                message: phrases.RESET_PASSWORD_EMPTY_EMAIL_ERROR,
              },
              pattern: {
                value: emailRegex,
                message: phrases.RESET_PASSWORD_ERROR,
              },
              maxLength: { value: 254, message: phrases.RESET_PASSWORD_ERROR },
            })}
            hasError={!R.isNullOrEmpty(errors?.[FormFields.email])}
            isEmpty={R.isNullOrEmpty(emailValue)}
            label={emailLabel}
          />
          <div className={formViewStyles.formFieldExtra}>
            <Button
              disabled={R.isNullOrEmpty(emailValue) || !formState.isValid}
              type="submit"
              variant={Button.variants.POSITIVE_CONFIRM}
              className={formViewStyles.actionButton}
            >
              {phrases.RESET_PASSWORD_BUTTON_TEXT}
            </Button>
          </div>
        </>
      ) : (
        <div>
          <FormViewTitle
            title={phrases.RESET_PASSWORD_REQUEST_TITLE}
            description={phrases.RESET_PASSWORD_REQUEST_DESCRIPTION(savedEmail)}
          />
          <div className={formViewStyles.formFieldExtra}>
            <Button
              type="button"
              variant={Button.variants.POSITIVE_CONFIRM}
              className={formViewStyles.actionButton}
              onClick={() => showResetForm(true)}
            >
              {phrases.RESET_PASSWORD_REQUEST_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};
