import { DataSourceType } from '@air/api/models';

type PartialATSList = { [key in DataSourceType]?: string };

export const atsLogos: PartialATSList = {
  [DataSourceType.GREENHOUSEATS]: '/assets/images/greenhouse_logo.svg',
  [DataSourceType.LEVERATS]: '/assets/images/lever_logo.svg',
  [DataSourceType.ORACLETALEO]: '/assets/images/lever_logo.svg',
};

export const ATSNames: PartialATSList = {
  [DataSourceType.ORACLETALEO]: 'Taleo',
  [DataSourceType.GREENHOUSEATS]: 'Greenhouse',
  [DataSourceType.LEVERATS]: 'Lever',
  [DataSourceType.SAPATS]: 'SAP SuccessFactors',
};
