import React from 'react';
import * as phrases from 'constants/phrases';
import {
  getValuesForTopStack,
  getValuesForBottomStack,
  getCardTitle,
  getTopStackListName,
  getBottomStackListName,
  getCardListItemTooltip,
} from 'domain/SearchCriteria/cardHelpers';
import { Card } from '@air/components';
import { CardType, isCompany, SearchCriteriaData } from 'domain/SearchCriteria';
import { isCompanySizeType } from 'domain/SearchCriteria/CompanyCriteriaData';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

const getStackItemTitle = (item: any, cardData: SearchCriteriaData) => {
  if (isCompany(cardData)) {
    return isCompanySizeType(item.type) && cardData.usOnly
      ? phrases.GET_US_ONLY_COMPANY_TITLE(item.name)
      : getCardTitle({
          ...item,
          label: item.name || item.label,
          cardType: cardData.cardType,
        });
  } else {
    return getCardTitle({
      ...item,
      cardType: cardData.cardType,
    });
  }
};

type CriteriaCardStackItemsData = {
  cardData: SearchCriteriaData;
  stackValues: any;
  label: string;
  listName: string;
};

const generateCriteriaCardStackItems = ({
  cardData,
  stackValues,
  label,
  listName,
}: CriteriaCardStackItemsData) => {
  return stackValues.map((item: any, index: any, { length }: any) => {
    const id = item?.value;

    const title = getStackItemTitle(item, cardData);

    const getTooltip = getCardListItemTooltip.bind(null, cardData, item);
    return {
      key: `${id}-${index}`,
      id,
      title,
      type: item?.type,
      label,
      card: cardData,
      searchOrder: length - index,
      onRemoveItem: (event: any, removeItem: any) => {
        event.stopPropagation();
        removeItem(id, listName);
      },
      children: (
        <>
          {
            <Card.TitleLabel
              text={cardsConfig[
                cardData.cardType
              ].getCriteriaCardStackTitleLabel?.(item)}
            />
          }
          <Card.Title
            flexGrow={false}
            getTooltipText={getTooltip}
            isStackTitle
            resizeable
            title={title}
            tooltipProps={cardsConfig[
              cardData.cardType
            ]?.getStackItemTooltipProps?.(item, cardData)}
          />
        </>
      ),
    };
  });
};

export const generateCriteriaCardTopStackItems = (cardData: any) => {
  const topStackValues = getValuesForTopStack(cardData);
  switch (cardData.cardType) {
    case CardType.major:
    case CardType.degree:
    case CardType.institution:
      return generateCriteriaCardStackItems({
        cardData,
        stackValues: topStackValues,
        label: phrases.IDEAL_STACK_ITEM_LABEL,
        listName: getTopStackListName(cardData),
      });
    default:
      return generateCriteriaCardStackItems({
        cardData,
        stackValues: topStackValues,
        label: phrases.OR_STACK_ITEM_LABEL,
        listName: getTopStackListName(cardData),
      });
  }
};

export const generateCriteriaCardBottomStackItems = (cardData: any) => {
  const bottomStackValues = getValuesForBottomStack(cardData);
  switch (cardData.cardType) {
    case CardType.major:
    case CardType.degree:
    case CardType.institution:
      return generateCriteriaCardStackItems({
        cardData,
        stackValues: bottomStackValues,
        label: phrases.ACCEPTABLE_STACK_ITEM_LABEL,
        listName: getBottomStackListName(cardData),
      });
    default:
      return [];
  }
};
