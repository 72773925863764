/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum EventType {
  APPLICANTSUBMITTEDINTERVIEW = 'APPLICANT_SUBMITTED_INTERVIEW',
  APPLICANTPROFILEREPROCESSED = 'APPLICANT_PROFILE_REPROCESSED',
  SEARCHAPPLIEDCOUNTUPDATED = 'SEARCH_APPLIED_COUNT_UPDATED',
  SEARCHCREATED = 'SEARCH_CREATED',
  JOBDESCRIPTIONPARSINGSTATUS = 'JOB_DESCRIPTION_PARSING_STATUS',
  JOBDESCRIPTIONUPDATED = 'JOB_DESCRIPTION_UPDATED',
  JOBDESCRIPTIONCREATED = 'JOB_DESCRIPTION_CREATED',
  JOBDESCRIPTIONDELETED = 'JOB_DESCRIPTION_DELETED',
  CANDIDATEPROFILESTATUSUPDATED = 'CANDIDATE_PROFILE_STATUS_UPDATED',
  CANDIDATEPROFILESTAGEUPDATED = 'CANDIDATE_PROFILE_STAGE_UPDATED',
  CANDIDATEPROFILEEXISTSINMATCHMINER = 'CANDIDATE_PROFILE_EXISTS_IN_MATCH_MINER',
  CANDIDATEPROFILEPRIMARYEMAILUPDATED = 'CANDIDATE_PROFILE_PRIMARY_EMAIL_UPDATED',
  SEARCHCANDIDATEPROFILESDROPPED = 'SEARCH_CANDIDATE_PROFILES_DROPPED',
  LICENSEEXPIRED = 'LICENSE_EXPIRED',
  DEPLOYEDNEWCUSTOMERPORTAL = 'DEPLOYED_NEW_CUSTOMER_PORTAL',
  BASICQUESTIONSPROCESSED = 'BASIC_QUESTIONS_PROCESSED',
  MATCHMINERLOADMORERESULT = 'MATCH_MINER_LOAD_MORE_RESULT',
  MATCHMINERLOADMORESTATUSUPDATED = 'MATCH_MINER_LOAD_MORE_STATUS_UPDATED',
  MATCHMINERSEARCHPROCESSINGSTATEUPDATED = 'MATCH_MINER_SEARCH_PROCESSING_STATE_UPDATED',
  MATCHSCOUTLOADMORERESULT = 'MATCH_SCOUT_LOAD_MORE_RESULT',
  MATCHSCOUTLOADMORESTATUSUPDATED = 'MATCH_SCOUT_LOAD_MORE_STATUS_UPDATED',
  MATCHSCOUTSEARCHPROCESSINGSTATEUPDATED = 'MATCH_SCOUT_SEARCH_PROCESSING_STATE_UPDATED',
  FLECUSTOMERCOMPANYSETTINGSUPDATEFAILED = 'FLE_CUSTOMER_COMPANY_SETTINGS_UPDATE_FAILED',
  DSSCUSTOMERCOMPANYSETTINGSUPDATEFAILED = 'DSS_CUSTOMER_COMPANY_SETTINGS_UPDATE_FAILED',
  CUSTOMERCOMPANYACCOUNTMANAGERUPDATED = 'CUSTOMER_COMPANY_ACCOUNT_MANAGER_UPDATED',
  CUSTOMERCOMPANYACCOUNTMANAGERDELETED = 'CUSTOMER_COMPANY_ACCOUNT_MANAGER_DELETED',
  SPECIALIZATIONSUPDATED = 'SPECIALIZATIONS_UPDATED',
  JOBEMAILDELETED = 'JOB_EMAIL_DELETED',
  JOBEMAILSENT = 'JOB_EMAIL_SENT',
  EMAILTEMPLATEDELETED = 'EMAIL_TEMPLATE_DELETED',
}
