import * as ApiModels from '@air/api';
import {
  AlertStatusEnum,
  CandidateSearchProfilePartialV2,
  CandidateSearchProfileStatus,
  CommentStatus,
} from '@air/api';
import { CardType } from 'domain/SearchCriteria';
import {
  BaseCandidateData,
  CandidateDegreeData,
  CandidateInstitutionData,
  CandidateMajorData,
  CandidateProfileData,
  CandidateProfileUIT,
  CandidateSearchProfileStatusEnum,
  hasUnreadNote,
  mapCertificatesToCandidateCertificationData,
  mapCompanies,
  mapEducationToCandidateData,
  mapManagerialExperience,
  mapProfessionalExperience,
  mapQuestions,
  mapRoles,
  mapSkills,
  normalizeCandidateName,
} from 'domain/CandidateData';
import {
  BaseRedFlagData,
  isDismissedFlag,
  isDisqualificationFlag,
  isDisqualificationResolution,
  isExplanationResolution,
  isGeneralRedFlag,
  RegFlagWarningType,
} from '@air/domain/RedFlags';
import { isCandidateWithUnreadComment } from './CandidateDataMapper';
import { RequisitionFile } from 'context';
import { mapIndustries } from 'domain/CandidateData/CandidateCriteria/CandidateIndustryData';
import { LineupTabs } from '@air/constants/tabs';
import { mapLocationsToCandidateLocationData } from 'domain/CandidateData/CandidateCriteria/CandidateLocationData';
import {
  CurrentSearchT,
  MatchMinerSearchT,
  MatchScoutSearchT,
} from 'domain/Kanban/Kanban';

/*
  CandidateLineupData module defines mappers used to present candidate's data
  on LineupTable view.
 */

const mapMatchedCriteriaToLineupCell = <T extends BaseCandidateData>(
  criteria: T[]
): Map<string | number, T> => {
  return criteria.reduce((acc, item) => {
    acc.set(item.id, item);
    return acc;
  }, new Map());
};

const mapMatchedEducationCriteriaToLineupCell = (
  criteria: CandidateDegreeData | CandidateMajorData | CandidateInstitutionData
): Map<
  string,
  CandidateDegreeData | CandidateMajorData | CandidateInstitutionData
> => {
  return criteria ? new Map([[criteria.id, criteria]]) : new Map();
};

type MatchingItemsMap = {
  [cardType in CardType]: Map<string | number, BaseCandidateData>;
};

export const getMatchItems = (
  profileData: Pick<
    CandidateProfileData,
    | 'skills'
    | 'certificates'
    | 'companies'
    | 'location'
    | 'industries'
    | 'roles'
    | 'managerial'
    | 'professional'
    | 'questions'
    | 'education'
  >
): MatchingItemsMap => {
  const { education } = profileData;
  return {
    [CardType.skill]: mapMatchedCriteriaToLineupCell(profileData.skills || []),
    [CardType.degree]: mapMatchedEducationCriteriaToLineupCell(
      education?.degree
    ),
    [CardType.major]: mapMatchedEducationCriteriaToLineupCell(education?.major),
    [CardType.institution]: mapMatchedEducationCriteriaToLineupCell(
      education?.institution
    ),
    [CardType.certification]: mapMatchedCriteriaToLineupCell(
      profileData.certificates || []
    ),
    [CardType.role]: mapMatchedCriteriaToLineupCell(profileData.roles || []),
    [CardType.managerial]: mapMatchedCriteriaToLineupCell(
      profileData.managerial || []
    ),
    [CardType.professional]: mapMatchedCriteriaToLineupCell(
      profileData.professional || []
    ),
    [CardType.industry]: mapMatchedCriteriaToLineupCell(
      profileData.industries || []
    ),
    [CardType.company]: mapMatchedCriteriaToLineupCell(
      profileData.companies || []
    ),
    [CardType.location]: mapMatchedCriteriaToLineupCell(
      profileData.location ? [profileData.location] : []
    ),
    [CardType.question]: mapMatchedCriteriaToLineupCell(
      profileData.questions || []
    ),
  };
};

/**
 * CandidateLineupData represents single row inside Lineup table
 */
export type CandidateLineupData = {
  id: string;
  mainProfileId: string;
  fullName: string;
  matchingItems: MatchingItemsMap;
  invitedForInterview: boolean;
  resumeProfiles: string[];
  status: CandidateSearchProfileStatusEnum;
  systemStatus: ApiModels.CandidateSearchProfileStatus;
  warningType: RegFlagWarningType;
  file?: RequisitionFile; // present only for uploads
  score: number;
  matchScore: number;
  redFlagsScore: number;
  updated: number;
  hasUnreadNotes: boolean;
  atsCreatedAt: number;
};

export type CandidatesLineupData = {
  active: CandidateLineupData[];
  passive: CandidateLineupData[];
  matchMiner: CandidateLineupData[];
};

export const isCandidateWithRedFlagIndicator = (redFlags: BaseRedFlagData[]) =>
  redFlags.some(
    (redFlag) =>
      isGeneralRedFlag(redFlag) &&
      (isDisqualificationResolution(redFlag) ||
        isDisqualificationFlag(redFlag)) &&
      !isDismissedFlag(redFlag)
  );

export const isCandidateWithEmptyRedFlagIndicator = (
  redFlags: BaseRedFlagData[]
) =>
  redFlags.some(
    (redFlag) =>
      isGeneralRedFlag(redFlag) &&
      isExplanationResolution(redFlag) &&
      !isDismissedFlag(redFlag)
  );

export const getRedFlagWarningType = (redFlags: BaseRedFlagData[]) => {
  if (isCandidateWithRedFlagIndicator(redFlags)) {
    return RegFlagWarningType.REDFLAG;
  }
  if (isCandidateWithEmptyRedFlagIndicator(redFlags)) {
    return RegFlagWarningType.EMPTYREDFLAG;
  }
};

export const getRedFlagWarningTypeByStatus = (
  eventualRedFlagStatus: AlertStatusEnum
): RegFlagWarningType => {
  if (eventualRedFlagStatus === AlertStatusEnum.DISQUALIFICATION) {
    return RegFlagWarningType.REDFLAG;
  }
  if (eventualRedFlagStatus === AlertStatusEnum.OPENED) {
    return RegFlagWarningType.EMPTYREDFLAG;
  }
};

export const hasUnreadNoteFromCommentStatus = (
  eventualCommentStatus: CommentStatus
): boolean => {
  return eventualCommentStatus === CommentStatus.UNREAD;
};

export const mapPartialCandidateProfileToLineupData = (
  candidateProfile: CandidateSearchProfilePartialV2
): CandidateLineupData => {
  const fullName = normalizeCandidateName(
    candidateProfile.firstName?.value,
    candidateProfile.lastName?.value
  );

  const {
    searchSkills,
    searchCertificates,
    searchEducation,
    searchExperience,
    searchIndustryExperience,
    searchCompanyExperience,
    searchAdditionalQuestions,
    searchTitleExperience,
    searchLocation,
    atsCreatedAt,
  } = candidateProfile;

  const currentCandidateProfile = {
    skills: mapSkills(searchSkills),
    education: mapEducationToCandidateData(searchEducation),
    certificates: (searchCertificates || []).map(
      mapCertificatesToCandidateCertificationData
    ),
    roles: mapRoles(searchTitleExperience),
    professional: mapProfessionalExperience(searchExperience || []),
    managerial: mapManagerialExperience(searchExperience || []),
    industries: mapIndustries(searchIndustryExperience),
    companies: mapCompanies(searchCompanyExperience),
    questions: mapQuestions(searchAdditionalQuestions),
    location: mapLocationsToCandidateLocationData(searchLocation),
  };

  const matchingItems =
    candidateProfile.status !== CandidateSearchProfileStatus.NOTPROCESSED
      ? getMatchItems(currentCandidateProfile)
      : null;

  return {
    id: candidateProfile.id,
    mainProfileId: candidateProfile.mainProfileId,
    fullName,
    matchingItems,
    status:
      candidateProfile.status as unknown as CandidateSearchProfileStatusEnum,
    systemStatus: candidateProfile.systemStatus,
    warningType: getRedFlagWarningTypeByStatus(
      candidateProfile.eventualRedFlagStatus
    ),
    resumeProfiles: [],
    file: null,
    score: candidateProfile.score,
    invitedForInterview: candidateProfile.invitedForInterview,
    matchScore: candidateProfile.matchScore,
    redFlagsScore: candidateProfile.redFlagsScore,
    updated: candidateProfile.updated || 0,
    hasUnreadNotes:
      isCandidateWithUnreadComment(currentCandidateProfile) ||
      hasUnreadNoteFromCommentStatus(candidateProfile.eventualCommentStatus),
    atsCreatedAt,
  };
};

export const mapCandidateProfileToTableData = (
  candidateProfile: CandidateProfileUIT
): CandidateLineupData => {
  const { candidateProfile: currentCandidateProfile } = candidateProfile;

  const matchingItems =
    candidateProfile.status !== CandidateSearchProfileStatusEnum.NOTPROCESSED
      ? getMatchItems(currentCandidateProfile)
      : null;

  return {
    id: candidateProfile.id,
    mainProfileId: candidateProfile.mainProfileId,
    fullName: currentCandidateProfile.fullName,
    matchingItems,
    resumeProfiles: candidateProfile.resumeExternalProfileIds || [],
    status: candidateProfile.status,
    systemStatus: candidateProfile.systemStatus,
    warningType: getRedFlagWarningType(currentCandidateProfile.redFlags),
    file: candidateProfile.file,
    invitedForInterview: candidateProfile.invitedForInterview,
    score: candidateProfile.score,
    matchScore: candidateProfile.matchScore,
    redFlagsScore: candidateProfile.redFlagsScore,
    updated: candidateProfile.updated,
    hasUnreadNotes:
      isCandidateWithUnreadComment(currentCandidateProfile) ||
      hasUnreadNote(currentCandidateProfile.redFlags),
    atsCreatedAt: currentCandidateProfile.atsCreatedAt,
  };
};

export const mapSectionsToCandidateStatuses = (
  openedSections: CandidateSearchProfileStatusEnum[]
): CandidateSearchProfileStatusEnum[] => {
  return openedSections.reduce(function (statuses, section) {
    if (section === CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION) {
      return [
        ...statuses,
        section,
        CandidateSearchProfileStatusEnum.MANUALREJECTION,
      ];
    }
    return [...statuses, section];
  }, []);
};

export const getLineupTabData = <ActiveData, PassiveData, MMData, MSData>({
  activeData,
  passiveData,
  matchMinerData,
  matchScoutData,
  currentTab,
}: {
  activeData: ActiveData;
  passiveData?: PassiveData;
  matchMinerData?: MMData;
  matchScoutData?: MSData;
  currentTab: LineupTabs;
}) => {
  if (isActiveTab(currentTab)) {
    return activeData;
  }
  if (isPassiveTab(currentTab)) {
    return passiveData;
  }
  if (isMatchMinerTab(currentTab)) {
    return matchMinerData;
  }
  if (isMatchScoutTab(currentTab)) {
    return matchScoutData;
  }
};

export const isActiveTab = (currentTab: LineupTabs) =>
  currentTab === LineupTabs.Active;
export const isPassiveTab = (currentTab: LineupTabs) =>
  currentTab === LineupTabs.Passive;
export const isMatchMinerTab = (currentTab: LineupTabs) =>
  currentTab === LineupTabs.MatchMiner;
export const isMatchScoutTab = (currentTab: LineupTabs) =>
  currentTab === LineupTabs.MatchScout;
export const isMatchServiceTab = (currentTab: LineupTabs) =>
  isMatchMinerTab(currentTab) || isMatchScoutTab(currentTab);

export const getTotalCandidatesCountOnLineup = ({
  currentSearch,
  currentMatchMinerSearch,
  currentMatchScoutSearch,
}: {
  currentSearch: CurrentSearchT;
  currentMatchMinerSearch: MatchMinerSearchT;
  currentMatchScoutSearch: MatchScoutSearchT;
}): number => {
  return (
    (currentSearch?.activeCount?.totalCount ?? 0) +
    (currentSearch?.passiveCount?.totalCount ?? 0) +
    (currentMatchMinerSearch?.passiveCount?.totalCount ?? 0) +
    (currentMatchScoutSearch?.passiveCount?.totalCount ?? 0)
  );
};
