import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import { DmsSkill } from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  CardType,
  normalizeSearchCriteriaExperience,
  SearchCriteriaExperienceT,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import {
  criteriaImportanceOptions,
  skillDetailsDemandOptions,
} from 'constants/skills';

export type DetailsDemandSelectedValueT =
  typeof skillDetailsDemandOptions[keyof typeof skillDetailsDemandOptions];

export interface IncludeSkillCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.skill;
  list: SearchCriteriaListValue<{ language?: boolean }>[];
  experience: SearchCriteriaExperienceT | null;
  recent: number;
  detailsDemand: DetailsDemandSelectedValueT;
  exclude: false;
}

export interface ExcludeSkillCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.skill;
  list: SearchCriteriaListValue<{ language?: boolean }>[];
  recent: number;
  exclude: true;
}

export type SkillCriteriaData =
  | IncludeSkillCriteriaData
  | ExcludeSkillCriteriaData;

export const isSkill = (
  item: BaseSearchCriteriaData
): item is SkillCriteriaData => {
  return item.cardType === CardType.skill;
};

export const isExcludeSkill = (
  item: BaseSearchCriteriaData
): item is ExcludeSkillCriteriaData => {
  return isSkill(item) && item.exclude;
};

export const isIncludeSkill = (
  item: BaseSearchCriteriaData
): item is IncludeSkillCriteriaData => {
  return isSkill(item) && !item.exclude;
};

export const getSkillCriteriaDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx = null,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
}: AddCriteriaInitials): IncludeSkillCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  initialCardStatus,
  cardType: CardType.skill,
  importance: criteriaImportanceOptions[importance],
  list: [null],
  experience: null,
  recent: null,
  detailsDemand:
    skillDetailsDemandOptions[ApiModel.DetailsDemandEnum.NOTREQUIRED],
  exclude: false,
});

export const mapExcludeListToSkillCriteria = (
  skill: ApiModel.ExcludeExperienceList
): ExcludeSkillCriteriaData => {
  return {
    idx: skill.idx,
    id: `exclude-${skill.idx}`,
    key: genId(),
    cardType: CardType.skill,
    initialCardStatus: InitialCardStatusEnum.ExistingCard,
    list: skill.list.map((subSkill: ApiModel.SkillInfo) => ({
      value: subSkill.id || subSkill.fullName,
      label: subSkill.fullName,
      language: subSkill.language,
    })),
    importance: criteriaImportanceOptions[skill.importance],
    recent: skill.recent,
    exclude: true,
  };
};

export const mapExcludeSkillCriteriaDataToSkillList = (
  skill: ExcludeSkillCriteriaData
): ApiModel.ExcludeExperienceList => {
  return {
    idx: skill.idx,
    list: skill.list.filter(Boolean).map((listItem) => ({
      ...(listItem.value !== listItem.label && {
        id: listItem.value as number,
      }),
      fullName: listItem.label,
      shortName: listItem.label,
      language: listItem.language,
    })),
    importance: skill.importance.value,
    recent: skill.recent,
  };
};

export const mapIncludeListToSkillCriteria = (
  skill: ApiModel.SkillList
): IncludeSkillCriteriaData => {
  return {
    idx: skill.idx,
    id: `include-${skill.idx}`,
    key: genId(),
    cardType: CardType.skill,
    initialCardStatus: InitialCardStatusEnum.ExistingCard,
    list: skill.list.map((subSkill: ApiModel.SkillInfo) => ({
      value: subSkill.id || subSkill.fullName,
      label: subSkill.fullName,
      language: subSkill.language,
    })),
    experience: R.pick<
      ApiModel.ExtendedExperienceInfo,
      'idealMin' | 'idealMax' | 'acceptableMin' | 'acceptableMax'
    >(
      ['idealMin', 'idealMax', 'acceptableMin', 'acceptableMax'],
      skill.experience
    ),
    importance: criteriaImportanceOptions[skill.importance],
    detailsDemand: skillDetailsDemandOptions[skill.experience.detailsDemand],
    recent: skill.experience.recent,
    exclude: false,
  };
};

export const mapIncludeSkillCriteriaDataToSkillList = (
  skill: IncludeSkillCriteriaData
): ApiModel.SkillList => {
  return {
    idx: skill.idx,
    list: skill.list.filter(Boolean).map((listItem) => ({
      ...(listItem.value !== listItem.label && {
        id: listItem.value as number,
      }),
      fullName: listItem.label,
      shortName: listItem.label,
      language: listItem.language,
    })),
    experience: {
      ...normalizeSearchCriteriaExperience(skill.experience),
      detailsDemand: skill?.detailsDemand?.value,
      recent: skill.recent,
    },
    importance: skill.importance.value,
  };
};

export const skillDataMapper = ({
  id,
  fullName,
  language,
  aliases,
  shortName,
}: DmsSkill) => {
  return {
    value: id,
    label: fullName,
    language,
    extras: {
      items: shortName ? [...(aliases || []), shortName] : aliases,
    },
  };
};
