import React, { useEffect } from 'react';
import useToggle from 'react-use/lib/useToggle';
import classNames from 'classnames';

import { Button, Header, PaginatedWizard, Paragraph } from '@air/components';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

import styles from './Onboarding.css';

type OnboardingStepT = {
  videoSrc: string;
  videoPreviewSrc: string;
  title: string;
  description: string[];
};

const onboardingSteps: OnboardingStepT[] = [
  {
    videoSrc: urls.ONBOARDING_STEP1_VIDEO_URL,
    videoPreviewSrc: urls.ONBOARDING_STEP1_VIDEO_PREVIEW_URL,
    title: phrases.ONBOARDING_STEP_1_TITLE,
    description: phrases.ONBOARDING_STEP_1_DESCRIPTION_ARRAY,
  },
  {
    videoSrc: urls.ONBOARDING_STEP2_VIDEO_URL,
    videoPreviewSrc: urls.ONBOARDING_STEP2_VIDEO_PREVIEW_URL,
    title: phrases.ONBOARDING_STEP_2_TITLE,
    description: [phrases.ONBOARDING_STEP_2_DESCRIPTION],
  },
  {
    videoSrc: urls.ONBOARDING_STEP3_VIDEO_URL,
    videoPreviewSrc: urls.ONBOARDING_STEP3_VIDEO_PREVIEW_URL,
    title: phrases.ONBOARDING_STEP_3_TITLE,
    description: [phrases.ONBOARDING_STEP_3_DESCRIPTION],
  },
];

type StepComponentT = {
  step: OnboardingStepT;
  currentStepNumber?: number;
};

const VideoPlayer: React.FC<StepComponentT> = ({ step }) => {
  return (
    <video
      loop
      autoPlay
      muted
      className={styles.video}
      key={step.videoSrc}
      poster={step.videoPreviewSrc}
    >
      <source src={step.videoSrc} type="video/mp4" />
    </video>
  );
};

const StepComponent: React.FC<StepComponentT> = ({
  step,
  currentStepNumber,
}) => {
  return (
    <div
      className={classNames(styles.step, styles[`step${currentStepNumber}`])}
    >
      <Header level={1} bold className={styles.title}>
        {step.title}
      </Header>
      {step.description.map((desc) => (
        <Paragraph
          key={desc}
          className={styles.paragraph}
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      ))}
    </div>
  );
};

type OnboardingT = {
  showOpenButton?: boolean;
  closeButtonText?: string;
};

export const Onboarding: React.FC<OnboardingT> = ({
  showOpenButton = false,
  closeButtonText,
}) => {
  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const { viewIntroGuide } = useCustomerProfileMethods();
  // Should be shown on Kanban on first load if it wasn't already shown
  // showOpenButton is present only in Help Center
  const shouldBeShownOnFirstLoad = !showOpenButton && user.showIntroGuide;
  const [showWizard, setWizardVisibility] = useToggle(
    !!shouldBeShownOnFirstLoad
  );

  useEffect(() => {
    if (user.showIntroGuide) {
      viewIntroGuide();
    }
  }, [user.showIntroGuide, viewIntroGuide]);

  return (
    <>
      {showOpenButton && (
        <Button
          className={styles.showOnboardingButton}
          variant={Button.variants.POSITIVE_CONFIRM}
          icon="start-screening-icon"
          onClick={() => setWizardVisibility(true)}
        >
          {phrases.HELP_CENTER_RESTART_ON_BOARDING_BUTTON_TEXT}
        </Button>
      )}
      <PaginatedWizard<OnboardingStepT>
        overlayClassName={styles.onboardingOverlayPanel}
        opened={showWizard}
        onClose={() => setWizardVisibility(false)}
        panelComponent={VideoPlayer}
        stepComponent={StepComponent}
        panelClassName={styles.videoWrapper}
        closeButtonText={closeButtonText}
        steps={onboardingSteps}
      />
    </>
  );
};
Onboarding.displayName = 'Onboarding';
