import React from 'react';
import classNames from 'classnames';
import styles from './HiddenList.css';
import { HiddenListItemT } from 'constants/skills';

type HiddenListPropsT = {
  items: HiddenListItemT[];
  onChange: (item: HiddenListItemT) => void;
  activeItem: HiddenListItemT;
};
export const HiddenList: React.FC<HiddenListPropsT> = ({
  items,
  activeItem,
  onChange,
}) => {
  return (
    <>
      {items.map((hiddenItem) => {
        return (
          <button
            key={hiddenItem.label}
            type="button"
            className={classNames(styles.hiddenListButton, {
              [styles.hiddenListButtonActive]:
                hiddenItem.value === activeItem.value,
            })}
            onClick={() => onChange(hiddenItem)}
          >
            #{hiddenItem.label}
          </button>
        );
      })}
    </>
  );
};
HiddenList.displayName = 'HiddenList';
