import R from '@air/third-party/ramda';

import * as Http from '@air/utils/http';
import { withQueryParams } from '@air/utils/http';
import * as urls from '@air/constants/apiEndpoints';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import { Task } from '@air/utils/fp';
import {
  EducationDegreeListResponse,
  EducationDegreeResponse,
  TitleListResponse,
  TitleResponse,
  ItemList,
} from '@air/api/models';
import {
  INPUT_DEBOUNCE_TIME,
  TYPEAHEAD_SUGGESTIONS_LIMIT,
} from '@air/constants/app';
import { getRoleItems, RoleItem } from '@air/domain/dictionaries';
import { FormSelectEntity } from '@air/components/Select/typings';
import { institutionDataMapper } from '@air/domain/SearchCriteriaCards/InstitutionCard/institutionCriteriaData';

export const loadDegreeOptions = (cb: any) =>
  Http.get(`${urls.DICTIONARY_API_DEGREE}`)
    .toTask()
    .chain(parseResponseJson)
    .map(
      R.compose<
        EducationDegreeListResponse[],
        EducationDegreeResponse[],
        EducationDegreeResponse[],
        { value: number; label: string }[]
      >(
        R.map(({ id, fullName, level }: any) => ({
          value: id,
          label: fullName,
          level,
        })),
        R.sortBy(R.prop('level')),
        R.prop('items')
      )
    )
    .fork(R.identity, cb);

const majorDictionaryParams = {
  page: 0,
  size: 200,
  sort: 'fullName;asc',
  version: 2,
};
export const loadMajorOptions = (cb: any) =>
  Http.get(urls.DICTIONARY_API_MAJOR_EXTENDED)
    .bind(Http.withQueryParams(majorDictionaryParams))
    .toTask()
    .chain(parseResponseJson)
    .map(
      R.compose(
        R.map(({ id, fullName }) => ({
          value: id,
          label: fullName,
        })),
        R.prop('items')
      )
    )
    .fork(R.identity, cb);

export const loadInstitutionPaginatedDictionaryTask =
  ({
    size,
    page,
    includeDeprecated,
  }: {
    size: number;
    page: number;
    includeDeprecated: boolean;
  }) =>
  (keyword: string) =>
    Http.get(urls.DICTIONARY_API_INSTITUTION_EXTENDED)
      .bind(Http.withQueryParams({ keyword, size, page, includeDeprecated }))
      .toTask()
      .chain(parseResponseJson)
      .chainError(parseErrorJson);

export const loadInstitutionDictionaryTask =
  (idsInUse: Array<number | string> = [], includeDeprecated: boolean) =>
  (keyword: string) => {
    return Http.get(urls.DICTIONARY_API_INSTITUTION)
      .bind(
        Http.withQueryParams({
          keyword,
          size: TYPEAHEAD_SUGGESTIONS_LIMIT,
          excludeId: idsInUse.join(','),
          includeDeprecated,
        })
      )
      .toTask()
      .chain(parseResponseJson)
      .chainError(parseErrorJson)
      .map(R.compose(R.map(institutionDataMapper), R.prop('items')));
  };

export const loadInstitutionOptions = (
  idsInUse?: Array<number | string>,
  includeDeprecated = true
) =>
  R.debounce((value: any, cb?: () => void) => {
    R.compose(
      Task.fork(R.identity, cb),
      loadInstitutionDictionaryTask(idsInUse, includeDeprecated)
    )(value);
  }, INPUT_DEBOUNCE_TIME);

export const loadPaginatedTitleDictionaryTask =
  ({
    size,
    page,
    excludeId,
    specializations,
  }: {
    size: number;
    page: number;
    excludeId: Array<number | string>;
    specializations: string[];
  }) =>
  (keyword: string) =>
    Http.get(sharedUrls.DICTIONARY_API_EXTENDED_ROLE)
      .bind(
        withQueryParams({
          keyword,
          size,
          page,
          excludeId,
          includeDeprecated: false,
          specializations,
        })
      )
      .toTask()
      .chain(parseResponseJson)
      .chainError(parseErrorJson);

export const loadTitleDictionaryTask =
  (excludeIds: Array<number | string>, withSimilarGroups = true) =>
  (keyword: string) =>
    Http.get(`${sharedUrls.DICTIONARY_API_ROLE}?keyword=${keyword}`)
      .toTask()
      .chain(parseResponseJson)
      .chainError(parseErrorJson)
      .map(
        R.compose<
          TitleListResponse[],
          TitleResponse[],
          RoleItem[],
          RoleItem[],
          RoleItem[],
          RoleItem[],
          {
            value: number | string;
            label: string;
            managerial: boolean;
          }[]
        >(
          R.map(({ id, fullName, managerial }: RoleItem) => ({
            value: id,
            label: fullName,
            managerial,
          })),
          R.take(TYPEAHEAD_SUGGESTIONS_LIMIT),
          R.reject((item: RoleItem) => R.includes(item.id, excludeIds)),
          R.uniqBy(R.path(['id'])),
          (items) => getRoleItems(items, withSimilarGroups),
          R.prop('items')
        )
      );

export const loadTitleAsyncOptions = R.debounce(
  (value: any, cb: () => void = R.noop) => {
    R.compose(
      Task.fork(R.identity, cb),
      loadTitleDictionaryTask([], false),
      encodeURIComponent
    )(value);
  },
  INPUT_DEBOUNCE_TIME
);

export const loadTitleAsyncOptionsWithExcluded = (
  idsInUse?: Array<number | string>,
  selectedRowItem?: string | number
) =>
  R.debounce((value: any, cb?: () => FormSelectEntity[]) => {
    const computedIdsInUse = selectedRowItem
      ? [...idsInUse, selectedRowItem]
      : idsInUse;
    R.compose(
      Task.fork(R.identity, cb),
      loadTitleDictionaryTask(computedIdsInUse),
      encodeURIComponent
    )(value);
  }, INPUT_DEBOUNCE_TIME);

const TITLE_OPTIONS_PAGE_SIZE = 10;
export const loadPaginatedTitleAsyncOptions = R.debounce(
  (
    {
      value,
      page = 0,
      size = TITLE_OPTIONS_PAGE_SIZE,
      excludeId = [],
      specializations,
    }: {
      value?: string;
      page?: number;
      size?: number;
      excludeId?: number[];
      specializations: string[];
    },
    callback?: (result: any) => void
  ) => {
    R.compose(
      Task.fork(R.identity, callback),
      loadPaginatedTitleDictionaryTask({
        size,
        page,
        excludeId,
        specializations,
      })
    )(value);
  },
  INPUT_DEBOUNCE_TIME
);

export const loadJobSpecializationsTask = (): Task<ItemList> =>
  Http.get(sharedUrls.DICTIONARY_API_JOB_SPECIALIZATIONS)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
