import React, { useState } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Paragraph, SvgIcon, UIText } from '@air/components';
import {
  ApproximateExperiencePeriod,
  ApproximatePeriodType,
} from '@air/components/Period/Period';

import {
  getPositionDatesPeriods,
  getWorkExperienceString,
  MONTHS_PER_YEAR,
  notAValidWorkExperience,
  WorkHistoryData,
  getDatesPeriod,
  hasNoExperience,
  normalizeExternalLink,
} from 'domain/CandidateData/WorkHistory/WorkHistoryData';
import { CompanyFullResponse, SearchWorkHistoryType } from '@air/api';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';

import styles from './WorkHistoryTableView.css';

type WorkHistoryTableViewT = {
  workHistory: WorkHistoryData[];
  companiesInfo?: CompanyFullResponse[];
  areCompaniesLoading: boolean;
};

export const WorkHistoryTableView: React.FC<WorkHistoryTableViewT> = ({
  workHistory,
  companiesInfo,
  areCompaniesLoading,
}) => {
  return (
    <div className={styles.workHistoryTableView}>
      {workHistory.map((position, index) => {
        const companyInfo = companiesInfo.find(
          (item) => position.company?.id === item.id
        );
        return (
          <div
            key={position.company.id + position.title + index}
            className={styles.workHistoryItemWrapper}
          >
            <WorkHistoryItem
              companyInfo={companyInfo}
              position={position}
              areCompaniesLoading={areCompaniesLoading}
            />
            <div className={styles.separator} />
          </div>
        );
      })}
    </div>
  );
};

type WorkHistoryItemPeriodT = {
  position: WorkHistoryData;
  positionMonthsDuration?: number;
  hasInsufficientExperienceData: boolean;
};

const WorkHistoryItemPeriod: React.FC<WorkHistoryItemPeriodT> = ({
  hasInsufficientExperienceData,
  positionMonthsDuration,
  position,
}: WorkHistoryItemPeriodT) => {
  return (
    <div className={styles.period}>
      {hasInsufficientExperienceData ? (
        <UIText small bold>
          {sharedPhrases.UNDEFINED_VALUE}
        </UIText>
      ) : (
        <ApproximateExperiencePeriod
          experience={{
            years: Math.floor(positionMonthsDuration / MONTHS_PER_YEAR),
            months: positionMonthsDuration % MONTHS_PER_YEAR,
            approx: position.approx,
          }}
          periodType={ApproximatePeriodType.workhistory}
        />
      )}
    </div>
  );
};

type WorkHistoryItemT = {
  position: WorkHistoryData;
  companyInfo?: CompanyFullResponse;
  areCompaniesLoading: boolean;
};

const WorkHistoryItem: React.FC<WorkHistoryItemT> = ({
  position,
  companyInfo,
  areCompaniesLoading,
}) => {
  const {
    city,
    state,
    country,
    employees,
    revenue,
    industry,
    companyType,
    additionalInfo,
  } = companyInfo || {};
  const [open, setOpen] = useState(false);
  const isUnknownCompany = !position.company.id;
  const { rawCompany: companyName, location: locationParsedByML } = position;
  const isCompanyInfoExpandable = companyName && !R.isNullOrEmpty(companyInfo);

  /* If parser was unable to derive end or start date, it will be null */
  const { unknown, years, months } = position;
  const hasInsufficientExperienceData =
    unknown || hasNoExperience(years, months);
  const infoIsMissing =
    !position.title ||
    !position.company.fullName ||
    hasInsufficientExperienceData;
  const companyLocation = [city, state, country].filter(Boolean).join(', ');
  const companyEmployees = phrases.COMPANY_EMPLOYEES(employees);
  const companyIndustry = `${industry} ${phrases.COMPANY_INDUSTRY_LABEL}`;
  const companyRevenue = phrases.COMPANY_REVENUE(revenue, true);
  const companyFoundationYear = additionalInfo?.foundationDate?.slice(0, 4);
  const companyWebsites = additionalInfo?.websites;

  const { positionMonthsDuration } = getPositionDatesPeriods(position);
  const isGap = position.type === SearchWorkHistoryType.GAP;

  if (isGap) {
    return (
      <div className={classNames(styles.workHistoryItem, styles.gap)}>
        <div className={styles.header}>
          <div className={styles.mainInfo}>
            <UIText small bold className={styles.gapTitle}>
              {phrases.WORK_TIMELINE_GAP}
            </UIText>
          </div>
          <WorkHistoryItemPeriod
            hasInsufficientExperienceData={hasInsufficientExperienceData}
            positionMonthsDuration={positionMonthsDuration}
            position={position}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames(styles.workHistoryItem, {
          [styles.missing]: infoIsMissing,
        })}
      >
        <div className={styles.header}>
          <div className={styles.mainInfo}>
            <UIText small bold>
              {notAValidWorkExperience(position)
                ? getWorkExperienceString(
                    position,
                    sharedPhrases.UNDEFINED_DATES
                  )
                : getDatesPeriod(position)}
            </UIText>
            <Paragraph small className={styles.title}>
              {position.title || phrases.WORK_TIMELINE_UNKNOWN_ROLE}
            </Paragraph>
            {companyName ? (
              <Paragraph
                small
                className={styles.location}
                onClick={() =>
                  isCompanyInfoExpandable ? setOpen(!open) : R.noop
                }
              >
                <strong className={styles.strong}>
                  {companyName}
                  {isUnknownCompany ? (
                    <span className={styles.regularText}>
                      ({phrases.FLAG_SETTINGS_TITLE_UNKNOWN_COMPANY})
                    </span>
                  ) : (
                    ''
                  )}
                </strong>
                {locationParsedByML && (
                  <span className={styles.locationIndent}>
                    {locationParsedByML}
                  </span>
                )}
                {isCompanyInfoExpandable && (
                  <SvgIcon
                    icon="shevron-down"
                    className={classNames(styles.arrow, {
                      [styles.collapse]: !open,
                    })}
                  />
                )}
              </Paragraph>
            ) : (
              !areCompaniesLoading && (
                <Paragraph small className={styles.location}>
                  {phrases.WORK_TIMELINE_UNKNOWN_COMPANY}
                </Paragraph>
              )
            )}
          </div>
          <WorkHistoryItemPeriod
            hasInsufficientExperienceData={hasInsufficientExperienceData}
            positionMonthsDuration={positionMonthsDuration}
            position={position}
          />
        </div>
        {open && companyInfo && (
          <div className={styles.companyInfo}>
            <UIText small bold className={styles.header}>
              {phrases.COMPANY_HEADER}
            </UIText>
            <ul className={styles.list}>
              {companyLocation && (
                <li className={styles.listItem}>
                  <Paragraph small className={styles.paragraph}>
                    {companyLocation}
                  </Paragraph>
                </li>
              )}
              {companyEmployees && (
                <li className={styles.listItem}>
                  <Paragraph small className={styles.paragraph}>
                    {companyEmployees}
                  </Paragraph>
                </li>
              )}
              {industry && (
                <li className={styles.listItem}>
                  <Paragraph small className={styles.paragraph}>
                    {companyIndustry}
                  </Paragraph>
                </li>
              )}
              {companyType && (
                <li className={styles.listItem}>
                  <Paragraph small className={styles.paragraph}>
                    {companyType} {phrases.COMPANY_LABEL}
                  </Paragraph>
                </li>
              )}
            </ul>
            <div className={styles.additional}>
              {companyRevenue && (
                <Paragraph small>
                  <strong className={styles.strong}>
                    {phrases.COMPANY_LABEL_REVENUE}:{' '}
                  </strong>{' '}
                  {companyRevenue}
                </Paragraph>
              )}
              {companyFoundationYear && (
                <Paragraph small>
                  <strong className={styles.strong}>
                    {phrases.COMPANY_LABEL_FOUNDED}:{' '}
                  </strong>{' '}
                  {companyFoundationYear}
                </Paragraph>
              )}
              {(companyWebsites || []).map((link) => (
                <Link
                  to={{ pathname: normalizeExternalLink(link) }}
                  key={link}
                  target="_blank"
                  className={styles.website}
                >
                  {link}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
