/*
 * App routes
 */
import querystring from 'query-string';
import { EmailsTabs, LineupTabs } from '@air/constants/tabs';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { SelectRecipientsTabs } from '@air/constants/tabs';

export const ROOT_ROUTE = `/`;
export const ROUTE_PLACEHOLDER = 'pending';
export const SIGNUP_ROUTE = `${ROOT_ROUTE}signup`;
export const RESET_PASSWORD_ROUTE = `${ROOT_ROUTE}reset-password`;

export const JOBS_ROUTE = `${ROOT_ROUTE}ats`;
export const makeJobUrl = (
  dataSourceId: number | string,
  id: number | string
) => `${JOBS_ROUTE}/${dataSourceId}/job/${id}`;

export const DRAFT_ROUTE = `${ROOT_ROUTE}draft`;

const getCurrentSearchIds = (
  dataSourceId?: number | string,
  jobDescriptionId?: number | string
) => {
  return dataSourceId && jobDescriptionId
    ? `${dataSourceId}/${jobDescriptionId}`
    : '';
};

export const makeDraftUrl = (
  dataSourceId?: number | string,
  jobDescriptionId?: string
) => {
  return `${DRAFT_ROUTE}/${getCurrentSearchIds(
    dataSourceId,
    jobDescriptionId
  )}`;
};

export const INTERVIEW_ROUTE = `${ROOT_ROUTE}interview`;
export const makeInterviewUrl = (
  dataSourceId?: number | string,
  jobDescriptionId?: string,
  tab?: LineupTabs
) => {
  const interviewUrl = `${INTERVIEW_ROUTE}/${getCurrentSearchIds(
    dataSourceId,
    jobDescriptionId
  )}`;
  return sharedUrls.getUrlWithParamsAndCurrentTab({ url: interviewUrl, tab });
};

type EmailConfigUrlParamsT = {
  recipientsTab?: SelectRecipientsTabs;
  emailConfigTab?: EmailsTabs;
  candidatePreviewId?: string;
};

type EmailConfigUrlT = {
  dataSourceId?: number | string;
  jobDescriptionId?: string;
  tab?: LineupTabs;
  emailId?: string;
  isEditMode?: boolean;
  showRecipients?: boolean;
  params?: EmailConfigUrlParamsT;
};

export const URL_EDIT_PART = '/edit';

export const isEditingEmailConfiguration = (pathName: string) =>
  pathName.endsWith(URL_EDIT_PART);

export const isRecipientsPanelOpen = (pathName: string) =>
  pathName.endsWith('recipients');

export const isRecipientsSelection = (
  params: {
    dataSourceId: string;
    jobDescriptionId: string;
    emailId: string;
    showRecipients: boolean;
    emailConfigTab: string;
  },
  pathname: string
) => pathname === makeEmailConfigUrl(params);

export const makeEmailConfigUrl = ({
  dataSourceId,
  jobDescriptionId,
  tab,
  emailId,
  isEditMode = false,
  showRecipients,
  params,
}: EmailConfigUrlT) => {
  const emailConfigUrl = `${INTERVIEW_ROUTE}/${getCurrentSearchIds(
    dataSourceId,
    jobDescriptionId
  )}/email-configuration${emailId ? '/email/' + emailId : ''}`;
  const url = isEditMode ? `${emailConfigUrl}${URL_EDIT_PART}` : emailConfigUrl;
  const urlWithRecipients =
    showRecipients || params?.recipientsTab ? `${url}/recipients` : url;
  const computedParams = querystring.stringify(params);
  return sharedUrls.getUrlWithParamsAndCurrentTab({
    url: urlWithRecipients,
    tab,
    params: computedParams,
  });
};

const getRootEmailConfigUrl = ({
  dataSourceId,
  jobDescriptionId,
}: {
  dataSourceId: string;
  jobDescriptionId: string;
}) =>
  `${INTERVIEW_ROUTE}/${getCurrentSearchIds(dataSourceId, jobDescriptionId)}`;

export const makeEmailConfigRootUrlWithParams = ({
  dataSourceId,
  jobDescriptionId,
  tab,
  params,
}: {
  dataSourceId: string;
  jobDescriptionId: string;
  tab: string;
  params?: EmailConfigUrlParamsT;
}) => {
  const computedParams = querystring.stringify({ ...params, tab });
  return `${getRootEmailConfigUrl({
    dataSourceId,
    jobDescriptionId,
  })}/email-configuration/?${computedParams}`;
};

export type MakeInterviewProfileUrlT = {
  dataSourceId: number | string;
  jobDescriptionId: number | string;
  userId: string;
  cardId?: number | string;
  showNote?: boolean;
  tab?: LineupTabs;
};
export const makeInterviewProfileUrl = (params: MakeInterviewProfileUrlT) => {
  const {
    dataSourceId,
    jobDescriptionId,
    userId,
    cardId,
    showNote,
    tab = LineupTabs.Active,
  } = params;
  const queryString = querystring.stringify({
    tab,
    candidateId: userId,
    cardId,
    showNote,
  });
  return `${INTERVIEW_ROUTE}/${dataSourceId}/${jobDescriptionId}?${queryString}`;
};

export const makeEditInterviewUrl = (
  dataSourceId?: number | string,
  jobDescriptionId?: string
) => `${INTERVIEW_ROUTE}/${dataSourceId}/${jobDescriptionId}/edit`;

export const CLOSED_ROUTE = `${ROOT_ROUTE}closed`;
export const makeClosedSearchUrl = (
  dataSourceId?: number | string,
  jobDescriptionId?: string,
  tab?: LineupTabs
) => {
  const closedSearchUrl = `${CLOSED_ROUTE}/${getCurrentSearchIds(
    dataSourceId,
    jobDescriptionId
  )}`;
  return sharedUrls.getUrlWithParamsAndCurrentTab({
    url: closedSearchUrl,
    tab,
  });
};

export const makeClosedSearchProfileUrl = (
  params: MakeInterviewProfileUrlT
) => {
  const { dataSourceId, jobDescriptionId, userId, tab } = params;
  const queryString = querystring.stringify({ tab, candidateId: userId });
  return `${CLOSED_ROUTE}/${dataSourceId}/${jobDescriptionId}?${queryString}`;
};

export const SETTINGS_ROUTE = `${ROOT_ROUTE}settings`;
export const GENERAL_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/general`;
export const USER_MANAGEMENT_ROUTE = `${SETTINGS_ROUTE}/user-management`;
export const SETTINGS_ROUTES = [
  SETTINGS_ROUTE,
  GENERAL_SETTINGS_ROUTE,
  USER_MANAGEMENT_ROUTE,
];

export const WALLPAPERS_ROUTE = `${ROOT_ROUTE}wallpapers`;
export const HELP_CENTER_ROUTE = `${ROOT_ROUTE}help-center`;

export const KANBAN_PANELS_ROUTES = [
  HELP_CENTER_ROUTE,
  WALLPAPERS_ROUTE,
  ...SETTINGS_ROUTES,
];

export const EMAIL_CONFIG_ROUTES = [
  makeEmailConfigUrl({
    dataSourceId: ':dataSourceId?',
    jobDescriptionId: ':jobDescriptionId?',
    emailId: ':emailId?',
    isEditMode: true,
  }),
  makeEmailConfigUrl({
    dataSourceId: ':dataSourceId?',
    jobDescriptionId: ':jobDescriptionId?',
    emailId: ':emailId?',
    showRecipients: true,
  }),
  makeEmailConfigUrl({
    dataSourceId: ':dataSourceId?',
    jobDescriptionId: ':jobDescriptionId?',
    emailId: ':emailId?',
  }),
  makeEmailConfigUrl({
    dataSourceId: ':dataSourceId?',
    jobDescriptionId: ':jobDescriptionId?',
  }),
];

/*
  Because of complex routing logic on Kanban (main kanban sections
  should be always visible, even on ROOT route), we need to track
  all Kanban-related routes (including internal ones), which are basically all routes,
  to redirect to ROOT from any other possible url. See routes.tsx file.
*/
export const KANBAN_ROUTES = [
  ROOT_ROUTE,
  ...KANBAN_PANELS_ROUTES,
  makeJobUrl(':dataSourceId?', ':id?'),
  makeDraftUrl(':dataSourceId?', ':jobDescriptionId?'),
  makeInterviewUrl(':dataSourceId?', ':jobDescriptionId?'),
  ...EMAIL_CONFIG_ROUTES,
  makeEditInterviewUrl(':dataSourceId?', ':jobDescriptionId?'),
  makeClosedSearchUrl(':dataSourceId?', ':jobDescriptionId?'),
];

/*
 * API endpoints
 */
export const BASE_API = `${API_HOST}/api/v1`;
export const BASE_API_V2 = `${API_HOST}/api/v2`;
export const BASE_API_V3 = `${API_HOST}/api/v3`;

export const APPLICANT_API = `${BASE_API}/applicant/current`;
export const CUSTOMER_API = `${BASE_API}/customer/current`;
export const SEARCH_LIST_API = `${BASE_API_V3}/search`;
export const ATS_LIST_API = `${BASE_API}/ats`;
export const makeJobsListApiUrl = (dataSourceId: number) =>
  `${BASE_API}/ats/${dataSourceId}/job-description`;
export const makeJobDescriptionApiUrl = (
  dataSourceId: number | string,
  jdId: string
) => `${BASE_API}/ats/${dataSourceId}/job-description/${jdId}`;
export const makeJobDescriptionRequestParseApiUrl = (
  dataSourceId: number | string,
  jdId: string
) => `${makeJobDescriptionApiUrl(dataSourceId, jdId)}/initiate-parsing`;
export const makeCreateMatchMinerSearchUrl = (
  parentSearchId: number | string
) => `${BASE_API_V2}/search/${parentSearchId}/match-miner`;
export const makeCreateMatchScoutSearchUrl = (
  parentSearchId: number | string
) => `${BASE_API_V2}/search/${parentSearchId}/match-scout`;
export const makeRestartMatchMinerSearchUrl = (mmSearchId: number | string) =>
  `${BASE_API_V2}/search/${mmSearchId}/match-miner-restart`;
export const makeRestartMatchScoutSearchUrl = (msSearchId: number | string) =>
  `${BASE_API_V2}/search/${msSearchId}/match-scout-restart`;
export const makeMatchMinerRejectionReasonsUrl = () =>
  `${BASE_API}/ats/rejection-reasons`;
export const makeLoadMoreMatchMinerApplicantsUrl = (
  searchId: number | string
) => `${BASE_API_V2}/search/${searchId}/match-miner-load-more`;
export const makeLoadMoreMatchScoutApplicantsUrl = (
  searchId: number | string
) => `${BASE_API_V2}/search/${searchId}/match-scout-load-more`;
export const makeSearchResultApiUrl = (searchId: number | string) =>
  `${BASE_API_V3}/search/${searchId}`;
export const makeSearchResultApiUrl2 = (
  dataSourceId: number | string,
  jobDescriptionId: number | string
) => `${BASE_API_V3}/search/${dataSourceId}/${jobDescriptionId}`;
export const makeUpdateSearchStatusApiUrl = (id: number | string) =>
  `${BASE_API_V2}/search/${id}/status`;
export const makeDuplicateInterviewUrl = (id: number) =>
  `${BASE_API_V3}/search/duplicate/${id}`;
export const makeChildInterviewSearchesApiUrl = (id: number | string) =>
  `${BASE_API_V2}/search/${id}/child`;
export const makeSearchPartialCandidatesListApiUrl = (id: number) =>
  `${BASE_API}/search/${id}/candidate-search-profile/partial`;
export const makeSearchCandidatesListApiUrl = (id: number) =>
  `${BASE_API}/search/${id}/candidate-search-profile`;

export const makeChangeCandidateStatusUrl = (
  searchId: number,
  candidateId: string
) => `${makeSearchCandidatesListApiUrl(searchId)}/${candidateId}/status`;
export const makeMoveCandidateToAppliedUrl = (
  searchId: number,
  candidateId: string
) => `${makeSearchCandidatesListApiUrl(searchId)}/${candidateId}/update-state`;
export const makeCustomerCompanyApiUrl = (companyId: number) =>
  `${BASE_API_V3}/company/${companyId}`;
export const CUSTOMER_MATCH_MINER_URL = `${BASE_API}/match-miner/request-feature`;
export const CUSTOMER_MATCH_SCOUT_URL = (companyId: number) =>
  `${BASE_API}/match-scout/${companyId}/request-feature`;
export const makeStartQuestioningUrl = (id: number | string) =>
  `${BASE_API}/search/${id}/questioning`;
export const makeEngagingEmailFieldsUrl = (searchId: number | string) =>
  `${BASE_API}/search/${searchId}/engaging-email`;
export const makeEngagingEmailPreviewUrl = (searchId: number | string) =>
  `${BASE_API}/search/${searchId}/candidate-search-profile/engaging-email`;
export const VIEW_INTRO_GUIDE_URL = `${BASE_API}/customer/intro-guide/view`;

export const makeDownloadResumeApiUrl = (
  dataSourceId: string | number,
  profileId: string | number,
  pdfOnly: boolean
) =>
  `${BASE_API}/ats/${dataSourceId}/external-profile/${profileId}/resume?pdfOnly=${pdfOnly.toString()}`;

export const makePositionStatusReportUrl = (searchId: number) =>
  `${BASE_API}/reporting/position-status/${searchId}`;

export const makeOverrideCardStatusApiUrl = (
  searchId: number,
  profileId: string,
  cardRefId: string
) =>
  `${BASE_API}/search/${searchId}/profile/${profileId}/match-result/${cardRefId}/status`;

export const makeChangeRedFlagStatusApiUrl = (
  searchId: number,
  profileId: string,
  redFlagRefId: string
) =>
  `${BASE_API}/search/${searchId}/profile/${profileId}/red-flag/${redFlagRefId}/status`;

export const makeGetCandidateSearchProfileApiUrl = (
  searchId: number | string,
  profileId: string
) => `${BASE_API}/search/${searchId}/candidate-search-profile/${profileId}`;

export const makeGetCandidateSearchProfileMainIdApiUrl = (
  dataSourceId: number | string,
  jonDescriptionId: number | string,
  mainProfileId: number | string
) =>
  `${BASE_API}/search/${dataSourceId}/${jonDescriptionId}/candidate-search-profile?mainProfileId=${mainProfileId}`;

export const makeSearchCandidatesListCountersUrl = (
  searchId: number | string
) => `${BASE_API_V3}/search/${searchId}/count`;

export const makeEnrichCandidateSearchProfileApiUrl = (
  searchId: number,
  profileId: string
) => `${makeGetCandidateSearchProfileApiUrl(searchId, profileId)}/enrich`;

export const UPLOAD_APPLICANTS_API_URL = `${BASE_API}/ats-profiles/upload-resume`;
export const FETCH_APPLICANTS_API_URL = `${BASE_API}/ats-profiles`;
export const makeDeleteApplicantApiUrl = (applicantId: number | string) =>
  `${BASE_API}/ats-profiles/delete-resume/${applicantId}`;

export const WATCHERS_LIST_URL = `${BASE_API}/watcher`;
export const WATCHERS_LIST_POTENTIAL_URL = `${WATCHERS_LIST_URL}/potential`;
export const WATCHERS_CURRENT_USER_URL = `${WATCHERS_LIST_URL}/current-user`;
export const WATCHER_SUBSCRIBE_URL = `${WATCHERS_LIST_URL}/subscribe`;
export const WATCHER_UNSUBSCRIBE_URL = `${WATCHERS_LIST_URL}/unsubscribe`;
export const WATCHER_UNSUBSCRIBE_BY_TOKEN_URL = `${WATCHERS_LIST_URL}/unsubscribe-by-token`;

export const makeCandidateProfileNoteUrl = (
  profileId: string,
  noteId?: string
) =>
  `${BASE_API}/candidate-search-profile/${profileId}/note${
    noteId ? `/${noteId}` : ''
  }`;

// oAUTH
export const createRefreshTokenApiUrl = (refreshToken: any) =>
  `${sharedUrls.LOGIN_API}/refresh?refreshToken=${refreshToken}`;

export const USER_MANAGEMENT_API = `${BASE_API}/customer`;
export const USER_RESET_PASSWORD_API = `${BASE_API}/password/reset`;
export const makeSetUserPasswordUrl = (userId: number) =>
  `${BASE_API}/password/${userId}`;

export const makeCompanyDataSourceUrl = (companyId: number) =>
  `${BASE_API_V3}/company/${companyId}/datasource`;

export const makeCompanyDatasourceUpdateUrl = (
  companyId: number,
  dataSourceId: number
) => `${makeCompanyDataSourceUrl(companyId)}/${dataSourceId}`;
export const LEVER_AUTHORIZATION_API = `${BASE_API}/ats/oauth2/lever/authorize`;

export const updateJobSpecializations = (
  jobDescriptionId: string,
  dataSourceId: number | string
) => {
  return `${BASE_API}/ats/${dataSourceId}/job-description/${jobDescriptionId}/specializations`;
};

/* Trial */
export const licenseUrl = `${BASE_API}/company/license`;
export const licenseUrlFull = `${BASE_API}/company/license/full`;

/* Contact Form */
export const CONTACT_SUPPORT_API = `${BASE_API}/customer/support`;

/* Help Center */
export const CONTACT_US_API = `${BASE_API}/help-center/contact-us`;

/*
  External links
 */
export const YOUTUBE_DRAFT_SETUP_URL = `https://www.youtube.com/watch?v=msIvJiynPqg&feature=emb_logo&ab_channel=Employa`;
export const USER_GUIDE_STANDALONE_VERSION = `https://railsreactor.atlassian.net/wiki/spaces/MMBE/pages/1307508737/Match+Master+-+Standalone+Version+User+Guide`;
export const USER_GUIDE_ATS_VERSION = `https://railsreactor.atlassian.net/wiki/spaces/MMBE/pages/1311113233/Match+Master+-+ATS-Based+Version+User+Guide`;

export const PARSED_CANDIDATES_MM_SEARCH = (
  datasourceId: number,
  jobId: string
) =>
  `${BASE_API}/audit/search/match-miner/${datasourceId}/${jobId}/candidate/count`;

export const ONBOARDING_STEP1_VIDEO_URL =
  'https://s3.amazonaws.com/assets.employa.com/onboarding_02.mp4';
export const ONBOARDING_STEP1_VIDEO_PREVIEW_URL =
  'https://s3.amazonaws.com/assets.employa.com/onboarding_02.png';
export const ONBOARDING_STEP2_VIDEO_URL =
  'https://s3.amazonaws.com/assets.employa.com/onboarding_03.mp4';
export const ONBOARDING_STEP2_VIDEO_PREVIEW_URL =
  'https://s3.amazonaws.com/assets.employa.com/onboarding_03.png';
export const ONBOARDING_STEP3_VIDEO_URL =
  'https://s3.amazonaws.com/assets.employa.com/onboarding_04.mp4';
export const ONBOARDING_STEP3_VIDEO_PREVIEW_URL =
  'https://s3.amazonaws.com/assets.employa.com/onboarding_04.png';

// email config
export const EMAIL_CONFIG_SECTIONS_SCHEMA_URL = `${BASE_API}/email-configurations/sections-schema`;
export const EMAIL_TEMPLATE_URL = `${BASE_API}/email-templates`;
export const getJobEmailsUrl = (searchId: number) =>
  `${BASE_API}/search/${searchId}/job-emails`;

export const getJobEmailByIdUrl = (searchId: number, emailId: string) =>
  `${getJobEmailsUrl(searchId)}/${emailId}`;
export const getEmailTemplateByIdUrl = (id: string) =>
  `${EMAIL_TEMPLATE_URL}/${id}`;
export const getDefaultTemplateUrl = () => `${EMAIL_TEMPLATE_URL}/default`;

export const fetchEmailRecipientsUrl = (emailId: string, searchId: number) =>
  `${getJobEmailsUrl(searchId)}/${emailId}/candidates`;

export const duplicateEmailTemplateUrl = (id: string) =>
  `${getEmailTemplateByIdUrl(id)}/duplicate`;
export const duplicateJobEmailUrl = (id: string, searchId: number) =>
  `${getJobEmailsUrl(searchId)}/${id}/duplicate`;

export const discardEmailTemplateChangesUrl = (id: string) =>
  `${getEmailTemplateByIdUrl(id)}/discard`;

export const getTemplatePreviewUrl = (id: string, candidateId: string) =>
  `${getEmailTemplateByIdUrl(id)}/candidates/${candidateId}/preview`;
export const getEmailPreviewUrl = (
  searchId: number,
  emailId: string,
  candidateId: string
) =>
  `${getJobEmailsUrl(searchId)}/${emailId}/candidates/${candidateId}/preview`;

export const sendJobEmailsUrl = (searchId: number, emailId: string) =>
  `${getJobEmailByIdUrl(searchId, emailId)}/send`;
