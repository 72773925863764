import { LocationState } from 'history';
import {
  DataSourceResponse,
  LoginRequest,
  LoggedInCustomerInfoResponse,
  LicenseType,
  MatchMinerRequestFeatureResponse,
  CustomerCompanyResponseV3,
  DataSourceResponseV3,
  MatchScoutRequestFeatureResponse,
} from '@air/api/models';
import { createContext } from 'use-context-selector';

export type CustomerProfileContextT = {
  user: LoggedInCustomerInfoResponse | null;
  authenticated: boolean;
  authError: string | null;
  authDataReceived: boolean;
  uiIsReady: boolean;
  invalidToken: boolean;
  ats: DataSourceResponse | DataSourceResponseV3;
  isStandaloneAtsUser: boolean;
  isPassiveSearchEnabled: boolean;
  canAccessUserManagement: boolean;
  setStandaloneAtsUser?: (isStandaloneAtsUser: boolean) => void;
  daysLeft: number;
  licenseType: LicenseType;
  isTrialExpired: boolean | null;
  customerCompany: CustomerCompanyResponseV3;
  matchMinerSettings: MatchMinerRequestFeatureResponse;
  matchScoutSettings: MatchScoutRequestFeatureResponse;
  currentBackgroundImageId?: number;
  userCredentials?: { email: string; password: string };
  methods: {
    authenticate: (userData: LoginRequest) => Promise<any>;
    logout: (state?: LocationState) => void;
    getUserProfile: () => void;
    viewIntroGuide: () => void;
    setAts: (ats: DataSourceResponse) => void;
    setStandaloneAtsUser: (isStandaloneAtsUser: boolean) => void;
    requestFullVersion: () => Promise<void>;
    fetchCustomerCompanyInfo: () => void;
    updateCustomerCompanyInfo: (data: CustomerCompanyResponseV3) => void;
    updateCustomerCompanySpecializations: (specializations: string[]) => void;
    changeBackgroundImage: (imageId: number) => void;
    setUserCredentials: (credentials: {
      email: string;
      password: string;
    }) => void;
    fetchMatchMinerSettings: () => void;
    requestMatchMinerForCompany: () => Promise<void>;
    requestMatchScoutForCompany: () => Promise<void>;
    fetchAvailableATSList: (companyId: number) => void;
  };
};

export const CustomerProfileContext = createContext<
  Partial<CustomerProfileContextT>
>({
  user: null,
  authenticated: false,
  authError: null,
  authDataReceived: false,
  canAccessUserManagement: false,
  uiIsReady: false,
  invalidToken: false,
  ats: null,
  isStandaloneAtsUser: null,
  isPassiveSearchEnabled: null,
  daysLeft: null,
  licenseType: null,
  isTrialExpired: null,
  customerCompany: null,
  matchMinerSettings: null,
  matchScoutSettings: null,
});

CustomerProfileContext.displayName = 'CustomerProfileContext';
