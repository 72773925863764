import React, { useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import R from '@air/third-party/ramda';
import { DataSourceType } from '@air/api';
import classNames from 'classnames';
import * as phrases from '@air/constants/phrases';
import { FormViewTitle } from 'components/FormView/FormView';
import { FormFieldTypes } from '@air/components/Form/utils';
import { Button, FormField, UIText } from '@air/components';
import { ATSNames } from 'domain/ATS/ATSMetadata';

import styles from './SignupFormSteps.css';
import formViewStyles from 'components/FormView/FormView.css';

type GreenhouseATSSettings = { apiKey: string };
type GreenhouseIntegrationProps = {
  className?: string;
  onConnect: (
    atsType: DataSourceType,
    atsSettings: GreenhouseATSSettings
  ) => Promise<void>;
  onCancel: () => void;
  isConnecting?: boolean;
};
export const GreenhouseIntegrationForm: React.FC<
  GreenhouseIntegrationProps
> = ({ className = '', onConnect, onCancel, isConnecting }) => {
  const {
    register,
    handleSubmit,
    control,
    formState,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
  });
  const formValues = useWatch({ control });

  const hasApiKeyError = errors?.apiKey;

  const onSubmit = useCallback(
    (settings: GreenhouseATSSettings) => {
      onConnect(DataSourceType.GREENHOUSEATS, R.map(R.trim, settings));
    },
    [onConnect]
  );

  return (
    <>
      <Button
        className={styles.backButton}
        variant={Button.variants.TINY}
        icon="collapse"
        onClick={onCancel}
      >
        Back
      </Button>

      <form
        className={classNames(
          styles.createPasswordForm,
          className,
          formViewStyles.loginForm
        )}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <UIText small bold className={formViewStyles.supHeader}>
          {phrases.getSignupStep(3)}
        </UIText>
        <FormViewTitle
          title={phrases.getATSIntergrationFormTitle(
            ATSNames[DataSourceType.GREENHOUSEATS]
          )}
          description={phrases.GREENHOUSE_ATS_INTEGRATION_FORM_DESCRIPTION}
        />

        <FormField
          id="apiKey"
          type={FormFieldTypes.text}
          {...register('apiKey', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={!!formValues.apiKey}
          hasError={hasApiKeyError}
          isEmpty={R.isNullOrEmpty(formValues.apiKey)}
          label={
            errors?.apiKey?.message ?? phrases.GREENHOUSE_API_KEY_FIELD_LABEL
          }
          placeholder={null}
        />

        <div className={formViewStyles.formFieldExtra}>
          <Button
            type="submit"
            variant={Button.variants.POSITIVE_CONFIRM}
            className={classNames(
              styles.nextStepButton,
              formViewStyles.actionButton
            )}
            disabled={!formState.isValid}
            isLoading={isConnecting}
            loaderPosition={Button.loaderPosition.LEFT}
          >
            {isConnecting
              ? phrases.ATS_CONNECTION_PLACEHOLDER
              : phrases.ATS_CONNECT_BUTTON}
          </Button>
        </div>
      </form>
    </>
  );
};
GreenhouseIntegrationForm.displayName = 'GreenhouseIntegrationForm';

export const GreenhouseIntegrationSuccess: React.FC<{
  onStart: () => void;
}> = ({ onStart }) => {
  return (
    <div>
      <img
        className={styles.logoImage}
        src="/assets/images/greenhouse_logo.png"
      />
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_SUCCESS_TITLE}
        description={phrases.getATSIntergrationSuccessDescription(
          ATSNames[DataSourceType.GREENHOUSEATS]
        )}
      />
      <div className={formViewStyles.formFieldExtra}>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          className={classNames(formViewStyles.actionButton)}
          onClick={onStart}
        >
          {phrases.ATS_INTEGRATION_SUCCESS_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
GreenhouseIntegrationSuccess.displayName = 'GreenhouseIntegrationSuccess';

export const GreenhouseIntegrationFailure: React.FC<{
  onRetry: () => void;
}> = ({ onRetry }) => {
  return (
    <div>
      <img
        className={styles.logoImage}
        src="/assets/images/greenhouse_logo.png"
      />
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_FAILURE_TITLE}
        description={phrases.getATSIntegrationFailureDescription(
          ATSNames[DataSourceType.GREENHOUSEATS]
        )}
      />
      <div className={formViewStyles.formFieldExtra}>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          className={classNames(formViewStyles.actionButton)}
          onClick={onRetry}
        >
          {phrases.ATS_INTEGRATION_RETRY_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
GreenhouseIntegrationFailure.displayName = 'GreenhouseIntegrationFailure';
