import React from 'react';
import { Tooltip, TooltipPropsT } from './Tooltip';
import styles from './Tooltip.css';
import classNames from 'classnames';

export type TooltipWrapperProps = TooltipPropsT & {
  enabled: boolean;
  disabledChildren?: boolean;
};

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  enabled,
  containerClassName = '',
  triggerClassName = '',
  disabledChildren,
  ...restProps
}) =>
  enabled ? (
    <Tooltip
      containerClassName={containerClassName}
      triggerClassName={classNames(triggerClassName, {
        [styles.hasDisabledChildren]: disabledChildren,
      })}
      {...restProps}
    >
      {/* This workaround is for Chrome issue when disabled input doesn't fire its `mouseleave` event.
        This issue doesn't allow our tooltip to hide itself after being shown. Div element covers such disabled
         button completely and takes all `mouseenter` and `mouseleave` event properly. Our button is not clickable below this
         element but it's not needed though because it's disabled. */}
      {disabledChildren && <div className={styles.disabledChildrenOverlay} />}
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
