import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';

import { Integrations } from '@sentry/tracing';
import { XHRResponseBodyT } from '@air/utils/api';
import { history } from '@air/utils/history';

export const ERROR_TYPES = {
  UNCAUGHT: 'uncaught',
  API_TASK: 'api.task',
};

type SentryExtraT = {
  url: string;
  status: number;
  headers: string;
  extra?: any;
};
let responses: Array<SentryExtraT> = [];
export async function logResponse(
  res: Response | XHRResponseBodyT | Error,
  url?: string
) {
  const MAX_LOG_LENGTH = 10;
  // res can be the result of XMLHttpRequest (no clone method).
  let clonedResponse, responseHeaders;
  if (res instanceof Response) {
    clonedResponse = res.clone();
    const headers = [];
    for (const header of clonedResponse.headers) {
      headers.push(header.join(': '));
      responseHeaders = headers.join('; ');
    }
  } else if (res instanceof Error) {
    clonedResponse = {
      url,
      status: null,
      extra: res,
    };
    responseHeaders = null;
  } else {
    clonedResponse = res;
    responseHeaders = res.headers;
  }

  const responseObj: SentryExtraT = {
    url: clonedResponse.url,
    status: clonedResponse.status,
    headers: responseHeaders,
    // @todo: Add actual payload if we figure out how to increase Sentry size limits.
    // data: await clonedResponse.json(),
  };
  if ('extra' in clonedResponse) {
    responseObj.extra = clonedResponse.extra;
  }
  responses = [responseObj].concat(responses.slice(0, MAX_LOG_LENGTH - 1));
}
async function stringifyResponses(responses: Array<SentryExtraT>) {
  return JSON.stringify(responses, null, 2);
}

export function init(callback: (...args: any[]) => any) {
  if (!SENTRY_URL) {
    callback();
    return;
  }
  if (ENV_NAME !== 'localhost') {
    Sentry.init({
      dsn: SENTRY_URL,
      release: RELEASE_VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation:
            SentryReact.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 1.0,
      environment: ENV_NAME,
      ignoreErrors: [
        /* https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
         */
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        /* https://stackoverflow.com/a/67540971
          https://railsreactor.atlassian.net/browse/AR-8589?focusedCommentId=55845
           */
        'TypeError: Illegal invocation',
      ],
      beforeSend: async function (event) {
        const extraArguments = (event.extra?.arguments ?? []) as Array<any>;
        // @ts-ignore
        extraArguments.push({
          latestResponses: await stringifyResponses(responses),
        });

        return {
          ...event,
          extra: { ...event.extra, arguments: extraArguments },
        };
      },
    });
  }

  callback();
}

export function setUserMeta(userData: any) {
  if (!SENTRY_URL || ENV_NAME === 'localhost') {
    return;
  }
  Sentry.setUser(userData);
}
