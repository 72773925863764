import React, {
  DOMAttributes,
  ForwardRefExoticComponent,
  HTMLAttributes,
  RefAttributes,
} from 'react';
import classNames from 'classnames';

import styles from './Paragraph.css';

type ParagraphProps = {
  className?: string;
  bold?: boolean;
  short?: boolean;
  big?: boolean;
  small?: boolean;
  tiny?: boolean;
  centered?: boolean;
} & HTMLAttributes<HTMLParagraphElement> &
  DOMAttributes<HTMLParagraphElement>;
type ParagraphT = ForwardRefExoticComponent<
  ParagraphProps & RefAttributes<HTMLSpanElement>
>;

export const Paragraph: ParagraphT = React.forwardRef(
  (
    { children, className, bold, short, big, small, tiny, centered, ...props },
    ref
  ) => {
    return (
      <span
        ref={ref}
        className={classNames(styles.paragraph, className, {
          [styles.bold]: bold,
          [styles.short]: short,
          [styles.big]: big,
          [styles.small]: small,
          [styles.tiny]: tiny,
          [styles.centered]: centered,
        })}
        {...props}
      >
        {children}
      </span>
    );
  }
);
Paragraph.displayName = 'Paragraph';
