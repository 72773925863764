// imports from vendor deps
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import querystring from 'query-string';
// imports from types
import { UploadStatus } from 'context';
import {
  CandidateSearchProfileStatus,
  SearchProgressStatusEnum,
} from '@air/api';
import {
  CandidateProfileUIT,
  CandidateSearchProfileStatusEnum,
} from 'domain/CandidateData/';
import { APP_EVENTS } from 'domain/Kanban/events';
import { RejectedFile } from 'context/uploadApplicants';
// imports from 'components'
import {
  CandidateProfile,
  LineupTable,
  MatchServiceRequest,
  RightSidebar,
  UploadsSidebarWrapper,
} from 'components';
import { SearchResultsEmpty } from './SearchResultsEmpty/SearchResultsEmpty';
import { SearchResultsNoCriteria } from './SearchResultsNoCriteria/SearchResultsNoCriteria';
import { SearchResultsEmptyMatchMiner } from './SearchResultsEmptyMatchMiner/SearchResultsEmptyMatchMiner';
import { BASIC_DROPZONE_CONFIG } from 'components/Dropzone/Dropzone';
// imports from 'constants'
import * as phrases from 'constants/phrases';
import { EMPTY_JOB } from 'providers/JobsProvider';
import {
  expandedInterviewSectionsInitial,
  GroupedPairT,
  LineupTableSections,
  sortLineupTableData,
} from 'components/LineupTable/utils';
// imports from helpers
import {
  CriteriaConfig,
  filterAndSortCriteriaColumns,
  HeaderCriteriaData,
} from 'domain/HeaderData/HeaderDataMapper';
import { LineupTabs } from '@air/constants/tabs';
import {
  GenerateReportPanel,
  JobDescription,
  MatchServiceSettings,
} from 'features';
import { CandidateQueryContext } from 'hooks/useCandidateQuery';
import { useUploadPanelControl } from 'hooks/useUploadPanelControl';
import { useUndoableCallback } from 'hooks/useUndoableCallback';
import { emit } from 'hooks/usePubSub';
import {
  CandidateLineupData,
  getLineupTabData,
  getTotalCandidatesCountOnLineup,
  isActiveTab,
  isMatchMinerTab,
  isMatchScoutTab,
  isMatchServiceTab,
  isPassiveTab,
} from 'domain/CandidateData/CandidateLineupData';
import { allOpenedSections } from 'domain/CandidateData/candidateStatusHelpers';
import { trackEvent } from '@air/utils/ga';
import {
  getCurrentRequisition,
  rejectUploadedToServerFiles,
} from 'utils/uploads';
import {
  CandidatesListBranches,
  useCandidateProfileContext,
  useCandidateProfileMethods,
} from 'providers/CandidateProfileProvider';
import * as urls from 'constants/urls';
import { getUrlWithParamsAndCurrentTab } from '@air/constants/apiEndpoints';
import {
  isMatchMinerEnabled,
  isMatchScoutEnabled,
} from 'domain/CustomerProfile/matchServiceSettings';
import { isCustomerAdmin, isRecruiter } from 'domain/UserManagement/User';
import { GACategory } from '@air/domain/Common/GATypes';
import { NameCell } from './CandidateNameCell';

import {
  GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_MM,
  GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_PASSIVE,
} from 'constants/gaLabels';
import {
  appSelectors,
  candidateProfileSelectors,
  customerProfileSelectors,
  kanbanSelectors,
  uploadApplicantsSelectors,
} from 'selectors';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  getDefaultHiddenCandidatesState,
  HiddenIdsT,
} from 'domain/CandidateData/statusChanging';
import {
  RightSidebarPanel,
  useAppContext,
  useAppMethods,
} from 'providers/AppProvider';
import {
  MatchMinerSetupSettings,
  MatchScoutSetupSettings,
} from 'domain/MatchServices/MatchServices';
import {
  useUploadApplicantsContext,
  useUploadApplicantsMethods,
} from 'providers/UploadApplicantsProvider';
import { useComputedCounters } from 'domain/SearchCounters/useComputedCounters';
import { CandidateCategory } from 'constants/candidateCategory';
import { useMatchService } from 'domain/MatchServices/useMatchServiceSettings';

// imports from styles
import styles from './SearchResultsView.css';
import fadeTransition from '@air/styles/transitions/fade.css';

type OpenedTableSections = {
  [sectionName in CandidateSearchProfileStatusEnum]?: boolean;
};

// exports / component definitions
type SearchResultsViewProps = RouteComponentProps<{ id: string }> & {
  onOpenedSectionsChange?: (
    openedSections: CandidateSearchProfileStatusEnum[],
    currentTab: LineupTabs
  ) => void;
  changeCandidateStatus?: (
    candidateId: string,
    candidateCurrentStatus: CandidateSearchProfileStatus,
    candidateNextStatus: CandidateSearchProfileStatus
  ) => Promise<void> | null;
} & {
  className?: string;
  getCandidateProfileUrl: (params: urls.MakeInterviewProfileUrlT) => string;
  onCandidateProfileCardStatusOverride?: () => void;
  isInterviewPaused?: boolean;
  isCandidatesListLoading?: boolean;
  columnsConfig: CriteriaConfig;
  currentTab?: LineupTabs;
};

export const SearchResultsView = withRouter(
  ({
    onOpenedSectionsChange,
    location,
    history,
    changeCandidateStatus,
    className = '',
    getCandidateProfileUrl,
    isInterviewPaused,
    isCandidatesListLoading,
    columnsConfig,
    currentTab,
  }: SearchResultsViewProps) => {
    const jobRequisitionDetails = useKanbanContext(
      kanbanSelectors.jobRequisitionDetails
    );
    const currentCriteria = useKanbanContext(
      kanbanSelectors.currentSearchCurrentCriteria
    );
    const parentJobDescriptionId = useKanbanContext(
      kanbanSelectors.externalJobDescriptionId
    );
    const { candidateNameQuery, onCandidateNameSearchChange } = useContext(
      CandidateQueryContext
    );
    const { removeApplicant } = useUploadApplicantsMethods();

    const uploadToServerState = useUploadApplicantsContext(
      uploadApplicantsSelectors.uploadToServerState
    );
    const requisitions = useUploadApplicantsContext(
      uploadApplicantsSelectors.requisitions
    );

    const dataSourceId = useCustomerProfileContext(
      customerProfileSelectors.dataSourceId
    );
    const isStandaloneAtsUser = useCustomerProfileContext(
      customerProfileSelectors.isStandaloneAtsUser
    );
    const isTrialExpired = useCustomerProfileContext(
      customerProfileSelectors.isTrialExpired
    );
    const matchMinerSettings = useCustomerProfileContext(
      customerProfileSelectors.matchMinerSettings
    );
    const matchScoutSettings = useCustomerProfileContext(
      customerProfileSelectors.matchScoutSettings
    );
    const user = useCustomerProfileContext(customerProfileSelectors.user);

    const currentSearch = useKanbanContext(kanbanSelectors.currentSearch);
    const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
    const currentMatchMinerSearch = useKanbanContext(
      kanbanSelectors.currentMatchMinerSearch
    );
    const isCurrentMatchMinerSearchLoaded = useKanbanContext(
      kanbanSelectors.isCurrentMatchMinerSearchLoaded
    );
    const currentMatchScoutSearch = useKanbanContext(
      kanbanSelectors.currentMatchScoutSearch
    );
    const isCurrentMatchScoutSearchLoaded = useKanbanContext(
      kanbanSelectors.isCurrentMatchScoutSearchLoaded
    );
    const currentSearchStatus = useKanbanContext(
      kanbanSelectors.currentSearchStatus
    );
    // must come from parent currentSearch for active, passive, matchMiner
    const activeCandidates = useCandidateProfileContext(
      candidateProfileSelectors.activeCandidates
    );
    const passiveCandidates = useCandidateProfileContext(
      candidateProfileSelectors.passiveCandidates
    );
    const matchMinerCandidates = useCandidateProfileContext(
      candidateProfileSelectors.matchMinerCandidates
    );
    const matchScoutCandidates = useCandidateProfileContext(
      candidateProfileSelectors.matchScoutCandidates
    );
    const selectedSortForSearches = useCandidateProfileContext(
      candidateProfileSelectors.selectedSortForSearches
    );

    const filterQuery = useCandidateProfileContext(
      candidateProfileSelectors.filterQuery
    );

    const { republishInterview, restartMatchServiceSearch } =
      useKanbanMethods();
    const republishInterviewCb = useCallback(() => {
      republishInterview(currentSearch);
    }, [currentSearch, republishInterview]);
    const [
      isCurrentCandidateProfileLoading,
      setCurrentCandidateProfileLoading,
    ] = useState(false);

    const isMatchMinerView = isMatchMinerTab(currentTab);

    const {
      computedCounters,
      computedPassiveCounters,
      computedMatchMinerCounters,
      computedMatchScoutCounters,
    } = useComputedCounters();

    const searchCounters = getLineupTabData({
      activeData: computedCounters,
      passiveData: computedPassiveCounters,
      matchMinerData: computedMatchMinerCounters,
      matchScoutData: computedMatchScoutCounters,
      currentTab,
    });

    const candidatesCounters = getLineupTabData({
      activeData: activeCandidates.statusesCount,
      passiveData: passiveCandidates.statusesCount,
      matchMinerData: matchMinerCandidates.statusesCount,
      matchScoutData: matchScoutCandidates.statusesCount,
      currentTab,
    });

    const searchCandidates = getLineupTabData({
      activeData: activeCandidates.candidates,
      passiveData: passiveCandidates.candidates,
      matchMinerData: matchMinerCandidates.candidates,
      matchScoutData: matchScoutCandidates.candidates,
      currentTab,
    });

    const searchResults = getLineupTabData({
      activeData: currentSearch,
      passiveData: currentSearch,
      matchMinerData: currentMatchMinerSearch,
      matchScoutData: currentMatchScoutSearch,
      currentTab,
    });

    const { searchId, name, status } = searchResults || {};
    const redFlagsSettings = currentSearch?.redFlags;
    const { applicants: totalApplicants, notProcessed } = searchCounters || {};

    /*
    When any candidate name is clicked, we update URL with
    'candidateId' query param and display related candidate profile.
   */
    const currentCandidateProfileId = useCandidateProfileContext(
      candidateProfileSelectors.currentCandidateProfileId
    );
    const currentCandidateProfileStatus = useCandidateProfileContext(
      candidateProfileSelectors.currentCandidateProfileStatus
    );

    const {
      fetchCandidateProfileById,
      fetchCandidateProfileByMainProfileId,
      openRequisitionAsProfile,
      moveCandidateToAppliedOrPassive,
      dropCandidatesByType,
    } = useCandidateProfileMethods();

    const rightSidebarPanel = useAppContext(appSelectors.rightSidebarPanel);

    const { setRightSidebarPanel } = useAppMethods();

    const setUploadsListOpen = useCallback(
      (openState: boolean) => {
        setRightSidebarPanel(openState ? RightSidebarPanel.Uploads : null);
      },
      [setRightSidebarPanel]
    );

    const isSearchClosed = status === SearchProgressStatusEnum.CLOSED;

    const jobRequisitionId = jobRequisitionDetails?.externalId;
    const currentRequisition = getCurrentRequisition(
      requisitions,
      jobRequisitionId
    );

    useUploadPanelControl(
      isStandaloneAtsUser &&
        !isSearchClosed &&
        !uploadToServerState[jobRequisitionId],
      RightSidebar.isUploadsOpened(rightSidebarPanel),
      setUploadsListOpen
    );

    const onRemoveApplicant = useCallback(
      (atsCandidateId: string, tempId: string, uploadStatus: UploadStatus) => {
        removeApplicant({
          atsCandidateId,
          tempId,
          uploadStatus,
          atsId: dataSourceId,
          jobRequisitionId,
        });
      },
      [jobRequisitionId, dataSourceId, removeApplicant]
    );

    const tableColumns: HeaderCriteriaData[] = useMemo<
      HeaderCriteriaData[]
    >(() => {
      return filterAndSortCriteriaColumns(
        currentCriteria,
        columnsConfig
      ) as HeaderCriteriaData[];
    }, [currentCriteria, columnsConfig]);

    const [isCandidateProfileVisible, toggleCandidateProfile] = useState(false);
    const paramsObject = querystring.parse(location.search);
    const candidateId = paramsObject.candidateId as string;
    const mainCandidateId = paramsObject.mainCandidateId as string;

    const handleCandidateProfileClose = useCallback(() => {
      const queryParamsObject = querystring.parse(history.location.search);
      const queryParams = querystring.stringify({
        tab: queryParamsObject.tab || LineupTabs.Active,
      });
      history.push(`${history.location.pathname}?${queryParams}`);
    }, [history]);

    const handleCandidateNameClick = useCallback(
      (profileId: string, cardId: number | string, showNote?: boolean) => {
        /*
          To avoid flooding history when candidate profile
          is opened, we only push it's corresponding URL to history
          once, if no profile is opened yet.
         */
        const params = {
          dataSourceId: searchResults.ats.id,
          jobDescriptionId: parentJobDescriptionId,
          userId: profileId,
          cardId,
          showNote,
          tab: currentTab,
        };
        const candidateUrl = getCandidateProfileUrl(params);
        if (candidateId) {
          history.replace(candidateUrl);
        } else {
          history.push(candidateUrl);
        }
      },
      [
        candidateId,
        parentJobDescriptionId,
        searchResults?.ats,
        history,
        getCandidateProfileUrl,
        currentTab,
      ]
    );

    useEffect(() => {
      if (!isCandidatesListLoading && mainCandidateId && !candidateId) {
        /* AR-8295
        if there is `mainCandidateId` param in url we came from the email
        example url from email `/closed?mainCandidateId=MAIN_CANDIDATE_ID&tab=active`
        find this candidate by `mainCandidateId` in candidates list
        and replace `mainCandidateId with mainCandidateId`
        `/closed?mainCandidateId=MAIN_CANDIDATE_ID&tab=active` =>
        `/closed?candidateId=CANDIDATE_ID&tab=active`
        otherwise redirect to the corresponding lineup tab

        WHY DOES IT WORK THIS WAY?
        `mainCandidateId(mainProfileId)` (short number like 12343)
        does not change when we modify the search.
        `candidateId` (long hash like 618165b02f737644ae5d0b0d)
        changes each time we modify the search.
        Different searches can have the same candidate with `mainCandidateId`
        but `candidateId` will be different.
        We have to make sure that the urls in the emails are always valid
        so url from emails will contain `mainCandidateId` and `isMainCandidateId=true`.
        But in our app we operate with `candidateId`
        (that it how it was done historically, hard to refactor on BE now)
        */

        const fetchAndRedirectToCandidate = async () => {
          // find the candidate in the present candidates list
          let candidate: CandidateProfileUIT | CandidateLineupData =
            searchCandidates.find(R.propEq('mainProfileId', mainCandidateId));
          let tab = (paramsObject.tab as LineupTabs) || LineupTabs.Active;
          const lineupUrl = getUrlWithParamsAndCurrentTab({
            url: history.location.pathname,
            tab,
          });

          // if not we request the candidate by mainProfileId
          if (!candidate && !isCurrentCandidateProfileLoading) {
            setCurrentCandidateProfileLoading(true);
            candidate = await fetchCandidateProfileByMainProfileId(
              searchResults.ats.id,
              searchResults.ats.externalJobDescriptionId,
              mainCandidateId
            );
            setCurrentCandidateProfileLoading(false);

            // only a single candidate from `search-candidate-profile` url has `passive` field
            // candidates list returned from `search-candidate-profile/partial` do not have that field
            if (candidate?.id && (candidate as CandidateProfileUIT).passive) {
              tab = LineupTabs.Passive;
            }
          }

          // if the candidate is found, redirect to the candidate profile url
          if (candidate?.id) {
            const updatedCandidateProfileParams = querystring.stringify({
              candidateId: candidate.id,
              tab,
            });
            history.replace(
              `${history.location.pathname}?${updatedCandidateProfileParams}`
            );
          } else {
            // if no results redirect to the corresponding/active lineup tab
            history.replace(lineupUrl);
          }
        };
        fetchAndRedirectToCandidate();
      }
    }, [
      setCurrentCandidateProfileLoading,
      isCurrentCandidateProfileLoading,
      isCandidatesListLoading,
      fetchCandidateProfileByMainProfileId,
      history,
      paramsObject,
      mainCandidateId,
      searchCandidates,
      candidateId,
      searchResults?.ats,
    ]);

    useEffect(() => {
      if (candidateId) {
        if (candidateId !== currentCandidateProfileId) {
          const candidate = searchCandidates.find(R.propEq('id', candidateId));
          if (!candidate?.file) {
            fetchCandidateProfileById(currentSearchId, candidateId);
          } else {
            openRequisitionAsProfile(candidate.file, {
              isSearchPaused: isInterviewPaused,
              atsId: dataSourceId,
            });
          }
        }
      }
    }, [
      currentSearchId,
      candidateId,
      searchCandidates,
      currentCandidateProfileId,
      fetchCandidateProfileById,
      openRequisitionAsProfile,
      dataSourceId,
      isInterviewPaused,
    ]);

    useEffect(() => {
      if (candidateId) {
        if (!isCandidateProfileVisible) {
          toggleCandidateProfile(true);
        }
        if (rightSidebarPanel !== null && !isCandidateProfileVisible) {
          setRightSidebarPanel(null);
        }
      } else if (!candidateId && isCandidateProfileVisible) {
        toggleCandidateProfile(false);
      }
    }, [
      candidateId,
      rightSidebarPanel,
      isCandidateProfileVisible,
      setRightSidebarPanel,
    ]);

    const { areMatchServiceCandidatesProcessing } = useMatchService(currentTab);

    const isAllCandidatesProcessed =
      !notProcessed && !areMatchServiceCandidatesProcessing;

    const isReadOnly =
      isTrialExpired || currentSearchStatus === SearchProgressStatusEnum.ONHOLD;
    const isProcessing =
      status === SearchProgressStatusEnum.INTERVIEW &&
      !isAllCandidatesProcessed;
    const isProcessingFinished =
      status === SearchProgressStatusEnum.INTERVIEW && isAllCandidatesProcessed;
    const canChangeCandidateStatus =
      !isInterviewPaused && typeof changeCandidateStatus === 'function';
    const canEditCandidateProfile =
      !isSearchClosed &&
      !isReadOnly &&
      !isMatchScoutTab(currentTab) &&
      ![
        CandidateSearchProfileStatusEnum.NOTPROCESSED,
        CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE,
      ].includes(currentCandidateProfileStatus);

    const handleChangeCandidateStatus = useCallback(
      (candidateId, currentStatus, nextStatus) => {
        if (canChangeCandidateStatus) {
          return changeCandidateStatus(candidateId, currentStatus, nextStatus);
        }
      },
      [canChangeCandidateStatus, changeCandidateStatus]
    );

    /*
      hiddenCandidates are required to handle optimistic lineup
      updates (when we postpone actual API calls which can be undone,
      but need to update UI immediately, i.e. to hide candidates
      from lineup).
      If we change search (parent jod description id changes), we clear the list.
      We can't rely on searchId change, because MM search has its own id.
    */
    const [hiddenCandidates, setHiddenCandidates] = useState<HiddenIdsT>(
      getDefaultHiddenCandidatesState()
    );

    useEffect(() => {
      // When a candidate is moved betweed tabs and its profile was opened close the profile
      if (
        currentCandidateProfileId &&
        hiddenCandidates[currentTab] &&
        hiddenCandidates[currentTab].includes(currentCandidateProfileId)
      ) {
        history.push(history.location.pathname);
      }
    }, [hiddenCandidates, currentCandidateProfileId, currentTab, history]);

    useEffect(() => {
      setHiddenCandidates(getDefaultHiddenCandidatesState());
    }, [parentJobDescriptionId]);

    const sortedData = useMemo<GroupedPairT[] | null>(() => {
      return sortLineupTableData({
        data: R.reject<any, any>(
          (candidate) =>
            hiddenCandidates[currentTab].includes(candidate.id) ||
            !candidate.fullName.toLowerCase().includes(filterQuery),
          searchCandidates
        ),
        sortType: selectedSortForSearches[searchId],
      }) as GroupedPairT[];
    }, [
      searchCandidates,
      selectedSortForSearches,
      searchId,
      hiddenCandidates,
      currentTab,
      filterQuery,
    ]);

    const defaultOpenedSections = useMemo(() => {
      return R.compose(
        R.reduce(
          (acc, sectionName) => ({
            ...acc,
            [sectionName]: true,
          }),
          {} as OpenedTableSections
        ),
        R.keys,
        R.filter(R.prop('opened'))
      )(expandedInterviewSectionsInitial);
    }, []);

    const [openedTableSections, updateOpenedTableSection] =
      useState<OpenedTableSections>(defaultOpenedSections);

    /*
      When any status section in Lineup table changes its state
      (ie. user opens or closes it), then we should refetch
      candidates for all currently opened sections.
    */
    const onOpenedSectionsChangeCallback = useCallback(
      (openedTableSections) => {
        onOpenedSectionsChange?.(
          R.compose(
            R.keys,
            R.pickBy(R.identity)
          )(openedTableSections) as CandidateSearchProfileStatusEnum[],
          currentTab
        );
      },
      [onOpenedSectionsChange, currentTab]
    );

    useEffect(() => {
      /*
        If all candidates in current search are in rejected status,
        we should open REJECTED section on Lineup table
        (because by default it is closed to avoid fetching
        rejected applicants, since number of rejections can be very big).
       */
      if (
        searchId &&
        searchCounters?.applicants > 0 &&
        searchCounters?.applicants === searchCounters?.totalRejectedAndFailed
      ) {
        updateOpenedTableSection(allOpenedSections);
        onOpenedSectionsChangeCallback(allOpenedSections);
      }
    }, [
      searchCounters?.applicants,
      searchCounters?.totalRejectedAndFailed,
      searchId,
      onOpenedSectionsChangeCallback,
    ]);

    const [currentStatusChangingId, setCurrentStatusChangingId] =
      useState(null);

    const fadeOtherCandidatesOnStatusToggle = useCallback(
      (id: string, isOpened: boolean) => {
        /*
        `null` check here is required, because currentStatusChangingId is saved
        in a closure, which is assigned directly to window object
        as 'scroll' event listener, and does not refresh correctly
        on functional component rerender.
        This can cause a scenario where 'currentStatusChangingId' stays
        null, but 'isOpened' flag is false, which means we should
        reset current changing id.
      */
        if (
          (currentStatusChangingId === null ||
            currentStatusChangingId === id) &&
          !isOpened
        ) {
          setCurrentStatusChangingId(null);
        } else if (currentStatusChangingId !== id && isOpened) {
          setCurrentStatusChangingId(id);
        }
      },
      [currentStatusChangingId]
    );

    const removeHiddenCandidateIdFromPreviousTab = useCallback(
      /*
      I.e. we have 2 tabs (applied and passive). When candidate is moved from one tab to another,
      we have to hide it in the previous one, so there are an object where candidate's id is written (due to optimistic update).
      hiddenObject - {applied: [candidateId], passive: []};
      If after that we decide to return it to the previous tab(applied) from current tab(passive).
      We have to remove id from hiddenObject[applied], cause candidate will be hidden in applied tab.
      */
      (hidden: HiddenIdsT, id: string) => {
        const result = { ...hidden };

        const keysToCheck = R.reject(R.equals(currentTab), R.keys(result));

        keysToCheck.forEach(
          (key: keyof HiddenIdsT) =>
            (result[key] = [...R.reject(R.equals(id), result[key])])
        );

        return result;
      },
      [currentTab]
    );

    const undoableMoveCandidateBetweenTabs = useUndoableCallback<
      (
        candidate: { id: string; fullName: string },
        nextCategory: CandidateCategory
      ) => void
    >(
      useMemo(() => {
        return {
          onCallbackScheduled: (candidate: {
            fullName: string;
            id: string;
          }) => {
            setHiddenCandidates((hidden) => {
              const updatedHidden = removeHiddenCandidateIdFromPreviousTab(
                hidden,
                candidate.id
              );

              return {
                ...updatedHidden,
                [currentTab]: updatedHidden[currentTab].concat(candidate.id),
              };
            });
            setCurrentStatusChangingId(null);
          },
          callback: async (
            candidate: { fullName: string; id: string },
            nextCategory
          ) => {
            const result = await moveCandidateToAppliedOrPassive(
              searchId,
              candidate.id,
              nextCategory === CandidateCategory.Passive
            );
            if (!result) {
              setHiddenCandidates((hidden) => ({
                ...hidden,
                [currentTab]: R.reject(
                  R.equals(candidate.id),
                  hidden[currentTab]
                ),
              }));
              // Throwing to avoid displaying success callback message.
              throw new Error();
            }
          },
          undoCallback: (candidate: { fullName: string; id: string }) => {
            setHiddenCandidates((hidden) => ({
              ...hidden,
              [currentTab]: R.reject(
                R.equals(candidate.id),
                hidden[currentTab]
              ),
            }));
          },
          callbackScheduledMsg: (candidate, nextCategory) => {
            return {
              [CandidateCategory.Active]:
                phrases.getMovingToAppliedNotification(candidate),
              [CandidateCategory.Passive]:
                phrases.getMovingToPassiveNotification(candidate),
            }[nextCategory];
          },
          callbackSuccessMsg: (candidate, nextCategory) => {
            return {
              [CandidateCategory.Active]:
                phrases.getMovedToAppliedNotification(candidate),
              [CandidateCategory.Passive]:
                phrases.getMovedToPassiveNotification(candidate),
            }[nextCategory];
          },
        };
      }, [
        currentTab,
        moveCandidateToAppliedOrPassive,
        removeHiddenCandidateIdFromPreviousTab,
        searchId,
      ])
    );

    const moveCandidateTo = useCallback(
      (to: CandidateCategory) =>
        (candidate: { id: string; fullName: string }) => {
          const { checkProperTab, labelsData } = {
            [CandidateCategory.Active]: {
              checkProperTab: isPassiveTab,
              labelsData: [
                GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_PASSIVE,
                GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_MM,
              ],
            },
            [CandidateCategory.Passive]: {
              checkProperTab: isActiveTab,
              labelsData: [
                GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_PASSIVE,
                GA_LABEL_CANDIDATE_MOVED_TO_APPLIED_MM,
              ],
            },
          }[to];

          undoableMoveCandidateBetweenTabs(candidate, to);

          trackEvent({
            category: isCandidateProfileVisible
              ? GACategory.ScreeningCandidateProfile
              : GACategory.ScreeningPage,
            label: checkProperTab(currentTab) ? labelsData[0] : labelsData[1],
          });
        },
      [currentTab, isCandidateProfileVisible, undoableMoveCandidateBetweenTabs]
    );

    const moveToAppliedCb = useMemo(() => {
      /*
        Move to applied is available only to Employa ATS.
        Only candidates from Passive and MM tabs can be moved to
        Applied section, and not vice versa (for now).
       */
      return !isStandaloneAtsUser || isActiveTab(currentTab)
        ? null
        : moveCandidateTo(CandidateCategory.Active);
    }, [currentTab, moveCandidateTo, isStandaloneAtsUser]);

    const moveToPassiveCb = useMemo(() => {
      return !isStandaloneAtsUser || isPassiveTab(currentTab)
        ? null
        : moveCandidateTo(CandidateCategory.Passive);
    }, [currentTab, isStandaloneAtsUser, moveCandidateTo]);

    const candidateNameRenderer = useCallback(
      (item: CandidateLineupData) => (
        <NameCell
          item={item}
          isSelected={item.id === candidateId}
          isFaded={
            !!currentStatusChangingId && item.id !== currentStatusChangingId
          }
          isReadOnly={isReadOnly}
          onClick={handleCandidateNameClick}
          onStatusPopupToggle={fadeOtherCandidatesOnStatusToggle}
          onStatusChange={
            canChangeCandidateStatus ? handleChangeCandidateStatus : null
          }
          moveCandidateToApplied={
            isMatchScoutTab(currentTab) ? null : moveToAppliedCb
          }
          moveCandidateToPassive={
            isMatchServiceTab(currentTab) ? null : moveToPassiveCb
          }
        />
      ),
      [
        candidateId,
        currentStatusChangingId,
        isReadOnly,
        handleCandidateNameClick,
        fadeOtherCandidatesOnStatusToggle,
        canChangeCandidateStatus,
        handleChangeCandidateStatus,
        moveToAppliedCb,
        currentTab,
        moveToPassiveCb,
      ]
    );

    /*
      Per AR-4951, to avoid flashing of incorrect message about
      no search results found while we wait for server filtering
      of search candidates, we track pending request manually.
    */
    const [isSearchingCandidates, setPendingCandidatesRequest] =
      useState(false);
    useEffect(() => {
      setPendingCandidatesRequest(false);
    }, [searchCandidates]);
    const onSearchInputChange = useCallback(
      (query) => {
        setPendingCandidatesRequest(true);
        onCandidateNameSearchChange(query);
        updateOpenedTableSection(allOpenedSections);
      },
      [onCandidateNameSearchChange]
    );

    const hasNotProcessedHidden = useMemo(
      () =>
        (isProcessingFinished || isSearchClosed) && isAllCandidatesProcessed,
      [isAllCandidatesProcessed, isProcessingFinished, isSearchClosed]
    );

    const lineupTableSections = useMemo(() => {
      const sectionsStatus = expandedInterviewSectionsInitial;

      const {
        [CandidateSearchProfileStatusEnum.NOTPROCESSED]: firstTab,
        ...mainTableSections
      } = sectionsStatus;
      const sections: LineupTableSections = hasNotProcessedHidden
        ? mainTableSections
        : sectionsStatus;
      return Object.keys<CandidateSearchProfileStatusEnum>(sections).reduce(
        (acc, sectionName) => {
          /* AR-8295
            sections object keys are not fully covered by CandidateSearchProfileStatus.
            we don't have UPLOADS status in CandidateSearchProfileStatus, and this
            section is added manually on UI, but it's not affected by `candidatesCounters`.
            so without knowing any other way to fix this, I've decided to ignore the error
            reminding about UPLOADS section.
            Also, "REJECTED" section should display the sum of 2 types of counters:
            RECOMMENDED_FOR_REJECTION and MANUAL_REJECTION.
          */
          // @ts-ignore
          let counter = candidatesCounters[sectionName] || 0;
          if (
            sectionName ===
            CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION
          ) {
            counter +=
              candidatesCounters[
                CandidateSearchProfileStatusEnum.MANUALREJECTION
              ];
          }
          return {
            ...acc,
            [sectionName]: {
              ...sections[sectionName],
              counter,
              opened: openedTableSections[sectionName],
            },
          };
        },
        {}
      );
    }, [candidatesCounters, openedTableSections, hasNotProcessedHidden]);

    const handleStatusRowClick = useCallback(
      (status: CandidateSearchProfileStatusEnum) => {
        updateOpenedTableSection((prevState) => {
          const updatedSections = {
            ...prevState,
            [status]: !prevState[status],
          };
          onOpenedSectionsChangeCallback(updatedSections);
          return updatedSections;
        });
      },
      [onOpenedSectionsChangeCallback]
    );

    const { onStartUpload } = useUploadApplicantsMethods();

    const isMatchMinerSearchEnabled = isMatchMinerEnabled(
      matchMinerSettings?.status
    );

    const isMatchScoutFeatureEnabled = isMatchScoutEnabled(
      matchScoutSettings?.status
    );

    const showMatchServiceRequest =
      !totalApplicants &&
      ((isMatchMinerView &&
        !isMatchMinerSearchEnabled &&
        isCurrentMatchMinerSearchLoaded &&
        !currentMatchMinerSearch) ||
        (isMatchScoutTab(currentTab) &&
          !isMatchScoutFeatureEnabled &&
          isCurrentMatchScoutSearchLoaded &&
          !currentMatchScoutSearch));

    const showEmptyCriteriaPlaceholder = !tableColumns.length;

    const filteredFiles = useMemo(
      () => rejectUploadedToServerFiles(currentRequisition.files),
      [currentRequisition.files]
    );

    const onSendReport = useCallback(() => {
      setRightSidebarPanel(null);
    }, [setRightSidebarPanel]);

    const SECTION_APPEAR_TIMEOUT = 500;

    const totalCandidates = getTotalCandidatesCountOnLineup({
      currentSearch,
      currentMatchMinerSearch,
      currentMatchScoutSearch,
    });

    const onMatchServiceSearchRestart = (
      settings: MatchMinerSetupSettings | MatchScoutSetupSettings
    ) => {
      const tab = currentTab;

      emit(APP_EVENTS.SET_CANDIDATES_LIST_LOADING, tab, true);
      const candidatesStoreBranch = isMatchMinerTab(tab)
        ? CandidatesListBranches.MatchMiner
        : CandidatesListBranches.MatchScout;

      restartMatchServiceSearch(searchId, settings, tab)
        .then(() => {
          setRightSidebarPanel(null);
          dropCandidatesByType(candidatesStoreBranch);
        })
        .catch(() => {
          emit(APP_EVENTS.SET_CANDIDATES_LIST_LOADING, tab, false);
        });
    };

    /*
      This is the only correct condition to indicate that we should display the
      panel with empty results notification.

      We're displaying empty search view (with No candidates banner or Start MM/MS form)
      when no candidates have been applied, but in other cases when the actual list of
      lineup records is empty, we still need to display lineup's header with criteria cards
      and filter field.
    */
    const showEmptySearchResults =
      !totalApplicants && !sortedData?.length && !candidateNameQuery;

    return (
      <>
        <div className={classNames(styles.searchResultsView, className)}>
          {showMatchServiceRequest && (
            <MatchServiceRequest
              isReadOnly={isTrialExpired || isRecruiter(user.role)}
              isCustomerAdmin={isCustomerAdmin(user.role)}
              currentTab={currentTab}
            />
          )}
          {showEmptySearchResults ? (
            <EmptySearchView
              dataSourceId={dataSourceId}
              isReadOnly={isReadOnly}
              currentTab={currentTab}
              isCandidatesListLoading={isCandidatesListLoading}
              isProcessing={isProcessing}
            />
          ) : (
            <LineupTable
              isCandidatesListLoading={
                isCandidatesListLoading || isSearchingCandidates
              }
              firstColumnRenderer={candidateNameRenderer}
              cellOnClickHandler={handleCandidateNameClick}
              columns={tableColumns}
              sortedData={sortedData}
              tableSections={lineupTableSections}
              onStatusRowClick={handleStatusRowClick}
              filterValue={candidateNameQuery}
              onFilterChange={onSearchInputChange}
              totalApplicants={totalApplicants}
              isProcessing={isProcessing}
              currentRequisition={currentRequisition}
              removeApplicant={removeApplicant}
              jobRequisitionId={jobRequisitionId}
            />
          )}
          {showEmptyCriteriaPlaceholder && <SearchResultsNoCriteria />}
          {currentCandidateProfileId && (
            <CSSTransition
              appear
              in={isCandidateProfileVisible}
              classNames={fadeTransition}
              timeout={SECTION_APPEAR_TIMEOUT}
              unmountOnExit
            >
              <CandidateProfile
                isReadOnly={isReadOnly}
                searchCriteria={currentCriteria}
                redFlagsSettings={redFlagsSettings}
                changeCandidateStatus={handleChangeCandidateStatus}
                moveCandidateToApplied={
                  isMatchScoutTab(currentTab) ? null : moveToAppliedCb
                }
                moveCandidateToPassive={
                  isMatchServiceTab(currentTab) ? null : moveToPassiveCb
                }
                onClose={handleCandidateProfileClose}
                canEditCandidateProfile={canEditCandidateProfile}
                currentTab={currentTab}
                searchId={searchId}
              />
            </CSSTransition>
          )}
        </div>
        <RightSidebar
          isOpened={rightSidebarPanel !== null}
          className={styles.rightSidebarWrapper}
          hasReportPanel
          closePanel={() => setRightSidebarPanel(null)}
        >
          {RightSidebar.isJobDescriptionOpened(rightSidebarPanel) && (
            <JobDescription
              job={jobRequisitionDetails || EMPTY_JOB}
              isEditable={false}
            />
          )}
          {isStandaloneAtsUser &&
            RightSidebar.isUploadsOpened(rightSidebarPanel) && (
              <UploadsSidebarWrapper
                files={filteredFiles}
                isInterviewPaused={isInterviewPaused}
                isOpen={RightSidebar.isUploadsOpened(rightSidebarPanel)}
                pendingFilesCount={currentRequisition?.pendingCount}
                totalFilesCount={currentRequisition?.pendingCount}
                onRemoveApplicant={onRemoveApplicant}
                onStartUpload={onStartUpload}
                currentTab={isMatchMinerView ? '' : currentTab}
                isUploadingToServer={uploadToServerState[jobRequisitionId]}
                onResumeInterview={republishInterviewCb}
              />
            )}
          {RightSidebar.isReportOpened(rightSidebarPanel) && (
            <GenerateReportPanel
              searchId={currentSearchId}
              searchName={name}
              onSend={onSendReport}
              isEmpty={!totalCandidates}
            />
          )}
          {RightSidebar.isMMSettingsOpened(rightSidebarPanel) && (
            <MatchServiceSettings
              tab={currentTab}
              onRestartSearch={onMatchServiceSearchRestart}
              canRestartSearch={
                currentSearchStatus === SearchProgressStatusEnum.INTERVIEW
              }
            />
          )}
        </RightSidebar>
      </>
    );
  }
);
SearchResultsView.displayName = 'SearchResultsView';

type EmptySearchViewT = {
  dataSourceId: number;
  isReadOnly: boolean;
  currentTab: LineupTabs;
  isCandidatesListLoading: boolean;
  isProcessing: boolean;
};
export const EmptySearchView = ({
  dataSourceId,
  isReadOnly,
  currentTab,
  isCandidatesListLoading,
  isProcessing,
}: EmptySearchViewT) => {
  const isPassiveSearchEnabled = useCustomerProfileContext(
    customerProfileSelectors.isPassiveSearchEnabled
  );
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  const currentSearchStatus = useKanbanContext(
    kanbanSelectors.currentSearchStatus
  );
  const isSearchPausedOrClosed =
    currentSearchStatus === SearchProgressStatusEnum.CLOSED ||
    currentSearchStatus === SearchProgressStatusEnum.ONHOLD;

  const { onDropAccepted, onDropRejected } = useUploadApplicantsMethods();

  const { getRootProps, getInputProps, open } = useDropzone({
    ...BASIC_DROPZONE_CONFIG,
    noDrag: true,
    onDropRejected: (items: RejectedFile[]) => onDropRejected(items),
    onDropAccepted: (items: File[]) => {
      onDropAccepted(items, dataSourceId);
      emit(APP_EVENTS.APPLICANT_DRAG_START);
    },
  });

  const isMatchMinerView = isMatchMinerTab(currentTab);
  const isMatchScoutView = isMatchScoutTab(currentTab);

  const isPassiveView = isPassiveTab(currentTab) && isPassiveSearchEnabled;

  const shouldDisplayUploadCVButton =
    isStandaloneAtsUser &&
    !isTrialExpired &&
    status === SearchProgressStatusEnum.INTERVIEW;

  const showEmptySearchResults = !isCandidatesListLoading && !isProcessing;

  return (
    <>
      <SearchResultsEmptyMatchMiner
        currentTab={currentTab}
        dataSourceId={dataSourceId}
        isReadOnly={isReadOnly}
        isSearchPausedOrClosed={isSearchPausedOrClosed}
        isStandaloneAtsUser={isStandaloneAtsUser}
      />
      {showEmptySearchResults && !isMatchMinerView && !isMatchScoutView && (
        <SearchResultsEmpty
          isPassiveView={isPassiveView}
          isSearchPausedOrClosed={isSearchPausedOrClosed}
          isStandaloneAtsUser={isStandaloneAtsUser}
          shouldDisplayUploadCVButton={shouldDisplayUploadCVButton}
          dropZoneRootProps={getRootProps({
            className: 'dropzone dropzone-uploader',
          })}
          dropZoneInputProps={getInputProps()}
          openFileManager={open}
        />
      )}
    </>
  );
};
EmptySearchView.displayName = 'EmptySearchView';
