// imports from vendor deps
import React, { useCallback, useMemo } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
import {
  RangeDropdown,
  RangeItem,
  SelectedItems,
} from '@air/components/RangeDropdown/RangeDropdown';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
import { ExperienceValues } from '@air/utils/commonSearchHelpers';
import {
  convertValuesToRangeOfIndexes,
  generateItemsForRangeDropdown,
  generateSelectedIndexes,
  isAcceptableExperienceDefined,
  isLimitNotDefined,
  MAX_EXPERIENCE_YEAR,
  MIN_EXPERIENCE_YEAR,
  RangeIndex,
  RangeOfIndexes,
  stringifyExperienceYears,
  UNSET_EXPERIENCE_MAX_YEAR,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import { useRangeVisibility } from '@air/components/SelectRangeWidgets/useRangeVisibility';

// imports from styles
import styles from './AcceptableExperienceWidget.css';

const yearsRange = R.range(MIN_EXPERIENCE_YEAR, MAX_EXPERIENCE_YEAR + 1);
const dropdownItems = [
  ...generateItemsForRangeDropdown(yearsRange),
  { label: '+', value: UNSET_EXPERIENCE_MAX_YEAR },
];

// exports / component definitions
const AcceptableExperienceDropdown: React.FC<{
  isOpen: boolean;
  dropdownItems: RangeItem[];
  acceptableRange: RangeOfIndexes;
  idealRange: RangeOfIndexes;
  onChange: (values: any) => void;
}> = ({ isOpen, onChange, dropdownItems, acceptableRange, idealRange }) => {
  const [acceptableMinIndex, acceptableMaxIndex] = acceptableRange;
  const [idealMinIndex, idealMaxIndex] = idealRange;

  const acceptableItems = useMemo(() => {
    return generateSelectedIndexes(acceptableRange, dropdownItems.length - 1);
  }, [dropdownItems, acceptableRange]);

  const idealItems = useMemo(() => {
    return generateSelectedIndexes(idealRange, dropdownItems.length - 1);
  }, [dropdownItems, idealRange]);

  const getRangeItemClass = useCallback(
    (
      index: RangeIndex,
      allItems: RangeItem[],
      selectedItems: SelectedItems
    ) => {
      return {
        [styles.rangeItem]: true,
        [styles.acceptableRangeItem]: R.includes(index, selectedItems),
        [styles.acceptableFirstRangeItem]: index === R.head(selectedItems),
        [styles.acceptableLastRangeItem]: index === R.last(selectedItems),
        [styles.idealRangeItem]: R.includes(index, idealItems),
        [styles.idealFirstRangeItem]: index === R.head(idealItems),
        [styles.idealLastRangeItem]: index === R.last(idealItems),
        [styles.rangeLastItem]: index === allItems.length - 1,
      };
    },
    [idealItems]
  );

  const getDropdownClass = useCallback(
    (allItems: RangeItem[]) => {
      const hasLeftIdealRangeOnly =
        // check if range has only left range and is not (10)(+)
        R.last(idealItems) === allItems.length - 1 && idealItems.length !== 2;
      return {
        [styles.acceptableExperienceDropdown]: true,
        [styles.hasLeftIdealRangeOnly]: hasLeftIdealRangeOnly,
      };
    },
    [idealItems]
  );

  const onItemSelected = useCallback(
    (newSelectedIndex) => {
      let newMinIndex = null;
      let newMaxIndex = null;

      if (isLimitNotDefined(idealMinIndex)) {
        newMaxIndex = newSelectedIndex;
      } else if (isLimitNotDefined(idealMaxIndex)) {
        newMinIndex = newSelectedIndex;
      } else if (newSelectedIndex < idealMinIndex) {
        newMinIndex = newSelectedIndex;
        newMaxIndex = isLimitNotDefined(acceptableMaxIndex)
          ? idealMaxIndex
          : acceptableMaxIndex;
      } else if (newSelectedIndex > idealMaxIndex) {
        newMaxIndex = newSelectedIndex;
        newMinIndex = isLimitNotDefined(acceptableMinIndex)
          ? idealMinIndex
          : acceptableMinIndex;
      } else if (
        newSelectedIndex === idealMinIndex &&
        idealMaxIndex !== acceptableMaxIndex
      ) {
        newMinIndex = newSelectedIndex;
        newMaxIndex = isLimitNotDefined(acceptableMaxIndex)
          ? idealMaxIndex
          : acceptableMaxIndex;
      } else if (
        newSelectedIndex === idealMaxIndex &&
        idealMinIndex !== acceptableMinIndex
      ) {
        newMinIndex = isLimitNotDefined(acceptableMinIndex)
          ? idealMinIndex
          : acceptableMinIndex;
        newMaxIndex = newSelectedIndex;
      }

      // clicking on the ideal items resets experience to [null, null] => any selected

      onChange([newMinIndex, newMaxIndex]);
    },
    [
      idealMinIndex,
      idealMaxIndex,
      onChange,
      acceptableMaxIndex,
      acceptableMinIndex,
    ]
  );

  return (
    <RangeDropdown
      isOpen={isOpen}
      dropdownClass={getDropdownClass}
      rangeItems={dropdownItems}
      rangeItemClass={getRangeItemClass}
      arrowClassName={styles.dropdownArrow}
      onItemSelected={onItemSelected}
      selectedItems={acceptableItems}
    />
  );
};

// exports / component definitions
const AcceptableExperienceField: React.FC<{
  className?: string;
  values: ExperienceValues;
  onClick?: () => void;
}> = ({ values, onClick = R.noop, className = '' }) => {
  const { yearsNumber, yearsWords } = stringifyExperienceYears(
    values?.acceptableMin,
    values?.acceptableMax
  );

  const isAnyExperience =
    values?.acceptableMin === MIN_EXPERIENCE_YEAR &&
    values?.acceptableMax === UNSET_EXPERIENCE_MAX_YEAR;

  const isExperienceUnset = !isAcceptableExperienceDefined(values);

  const anyExperienceText = `Any\nexperience`;
  const unsetExperienceText = `Acceptable\nexperience`;
  const nYearsOfExperienceText = `${yearsWords}\nof exp`;

  return (
    <div
      className={classNames(styles.experienceField, className)}
      onClick={onClick}
    >
      {isAcceptableExperienceDefined(values) && (
        <div className={styles.experienceFieldTitle}>Acceptable</div>
      )}
      <div className={styles.experienceFieldInnerContent}>
        {!isAnyExperience && !isExperienceUnset && (
          <span
            tabIndex={0}
            className={styles.experienceFieldValue}
            onFocus={onClick}
            onMouseDown={(event: any) => event.preventDefault()}
          >
            {yearsNumber}
          </span>
        )}
        <label className={styles.experienceFieldLabel}>
          {R.cond([
            [R.always(isAnyExperience), R.always(anyExperienceText)],
            [R.always(isExperienceUnset), R.always(unsetExperienceText)],
            [R.always(true), R.always(nYearsOfExperienceText)],
          ])()}
        </label>
      </div>
    </div>
  );
};

export const AcceptableExperienceReadOnlyWidget: React.FC<{
  values: ExperienceValues;
}> = ({ values }) => {
  return (
    <AcceptableExperienceField
      className={styles.experienceReadonlyField}
      values={values}
    />
  );
};
AcceptableExperienceReadOnlyWidget.displayName =
  'AcceptableExperienceReadOnlyWidget';

// exports / component definitions
export const AcceptableExperienceWidget: React.FC<{
  values: ExperienceValues;
  onChangeValues?: (values: ExperienceValues) => void;
  isReadOnly?: boolean;
}> = ({ values, onChangeValues, isReadOnly = false }) => {
  const [
    isDropdownVisible,
    outsideClickRef,
    onDropdownTriggerClicked,
    handleKeyDown,
  ] = useRangeVisibility();

  const onChangeDropdownValues = useCallback(
    ([acceptableMin, acceptableMax]) => {
      onChangeValues({
        ...values,
        acceptableMin,
        acceptableMax,
      });
    },
    [onChangeValues, values]
  );

  const [idealValues, acceptableValues] = useMemo(() => {
    return [
      [values?.idealMin ?? null, values?.idealMax ?? null] as RangeOfIndexes,
      [
        values?.acceptableMin ?? null,
        values?.acceptableMax ?? null,
      ] as RangeOfIndexes,
    ];
  }, [values]);

  const acceptableRange = useMemo(() => {
    return convertValuesToRangeOfIndexes(dropdownItems, acceptableValues);
  }, [acceptableValues]);

  const idealRange = useMemo(() => {
    return convertValuesToRangeOfIndexes(dropdownItems, idealValues);
  }, [idealValues]);

  return isReadOnly ? (
    <AcceptableExperienceReadOnlyWidget values={values} />
  ) : (
    <div
      className={classNames(styles.acceptableExperienceWidget, {
        [styles.isDropdownOpened]: isDropdownVisible,
      })}
      ref={outsideClickRef}
      onKeyDownCapture={handleKeyDown}
    >
      <AcceptableExperienceField
        values={values}
        onClick={onDropdownTriggerClicked}
      />
      <AcceptableExperienceDropdown
        dropdownItems={dropdownItems}
        acceptableRange={acceptableRange}
        idealRange={idealRange}
        onChange={onChangeDropdownValues}
        isOpen={isDropdownVisible}
      />
    </div>
  );
};
