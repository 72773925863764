import {
  makeDraggableComponent,
  withCustomDrag,
} from 'components/CustomDragLayer/CustomDragLayer';
import { DraggedRedFlagsType } from 'features/DraftSection/dndTypes';
import { FlagSettingsCard } from 'components/CardWrappers';
import { RedFlagSettingsData } from 'domain/SearchCriteria/RedFlagsData';

type DraggableCriteriaCardProps = {
  flagData: RedFlagSettingsData;
  isDraggable?: boolean;
  onDragStart: () => void;
  onDragEnd: () => void;
};

export const DraggableFlagSettingsCard = makeDraggableComponent<
  DraggableCriteriaCardProps,
  unknown
>(withCustomDrag(FlagSettingsCard), DraggedRedFlagsType.RedFlagSettings, {
  canDrag: ({ isDraggable }) => {
    return isDraggable;
  },
  beginDrag: ({ onDragStart, flagData }) => {
    onDragStart && onDragStart();
    return {
      flagData,
      type: DraggedRedFlagsType.RedFlagSettings,
    };
  },
  endDrag: ({ onDragEnd }) => {
    onDragEnd && onDragEnd();
  },
});
