import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  normalizeSearchCriteriaExperience,
  SearchCriteriaExperienceT,
} from './BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

export type ManagerialExperienceValue = number;

export interface ManagerialCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.managerial;
  managerialExperience: ManagerialExperienceValue;
  experience: SearchCriteriaExperienceT | null;
  recent: number;
}

export const isManagerial = (
  item: BaseSearchCriteriaData
): item is ManagerialCriteriaData => item.cardType === CardType.managerial;

export const getManagerialCriteriaDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
}: AddCriteriaInitials): ManagerialCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  initialCardStatus,
  cardType: CardType.managerial,
  importance: criteriaImportanceOptions[importance],
  experience: null,
  managerialExperience: 0,
  recent: null,
});

export const mapExperienceToManagerialCriteriaData = ({
  managerial,
  idx,
}: Pick<
  ApiModel.Experience,
  'managerial' | 'idx'
>): ManagerialCriteriaData => ({
  idx,
  id: idx,
  key: genId(),
  cardType: CardType.managerial,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  experience: R.pick<
    ApiModel.ExtendedExperienceInfo,
    'idealMin' | 'idealMax' | 'acceptableMin' | 'acceptableMax'
  >(
    ['idealMin', 'idealMax', 'acceptableMin', 'acceptableMax'],
    managerial.experience
  ),
  recent: managerial.experience.recent,
  managerialExperience: managerial.minPeople,
  importance:
    criteriaImportanceOptions[
      managerial.importance || ApiModel.SearchCriteriaImportanceEnum.MANDATORY
    ],
});

export const mapManagerialCriteriaDataToExperience = (
  managerial: ManagerialCriteriaData
): ApiModel.Experience => ({
  idx: managerial.idx,
  managerial: {
    minPeople: managerial.managerialExperience,
    experience: {
      ...normalizeSearchCriteriaExperience(managerial.experience),
      recent: managerial.recent,
    },
    importance: managerial.importance.value,
  },
  professional: null,
  title: null,
});
