import React, { useMemo, useCallback } from 'react';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import styles from './FlagSettingsCard.css';
import {
  RedFlagSettingsData,
  isDisqualifyAvailable,
  isExplanationAvailable,
  isWarningAvailable,
} from 'domain/SearchCriteria/RedFlagsData';
import { SvgIcon, Card } from '@air/components';
import { RedFlagResolutionEnum, RedFlagAlertType } from '@air/api';
import classNames from 'classnames';
import { CardSize } from '@air/components/Card/Card';
import {
  FlagSettingsStateConfig,
  FlagSettingsState,
  FlagSettingsActions,
} from 'components/SearchCriteriaCards/hooks/FlagSettingsStateConfig';
import { useStateMachine } from '@air/hooks';
import { useOutsideClick } from '@air/utils/hooks';
import { HOVER_DEBOUNCE_TIME } from 'constants/app';

const DATA_BY_RESOLUTION = {
  [RedFlagResolutionEnum.DISQUALIFICATION]: {
    resolutionClassName: styles.disqualify,
    flagIcon: 'flag',
    actionText: phrases.FLAG_SETTINGS_DISQUALIFY,
  },
  [RedFlagResolutionEnum.EXPLANATION]: {
    resolutionClassName: styles.askFirst,
    flagIcon: 'red-flag-empty',
    actionText: phrases.FLAG_SETTINGS_ASK_FIRST,
  },
  [RedFlagResolutionEnum.WARNING]: {
    resolutionClassName: styles.notify,
    flagIcon: 'warning-flag',
    actionText: phrases.FLAG_SETTINGS_NOTIFY,
  },
};

const getFlagSettingsElements = (
  resolution: RedFlagResolutionEnum,
  enabled: boolean
) => {
  if (!enabled) {
    return {
      resolutionClassName: styles.ignore,
      flagIcon: 'disabled-flag',
      actionText: phrases.FLAG_SETTINGS_IGNORE,
    };
  } else {
    return DATA_BY_RESOLUTION[resolution];
  }
};

export const FlagSettingsCard: React.FC<{
  className?: string;
  cardLabel?: string;
  isReadOnly?: boolean;
  isDraggingPreview?: boolean;
  isDragging?: boolean;
  initialState?: FlagSettingsState;
  flagData: RedFlagSettingsData;
  onChange?: (
    flagType: RedFlagAlertType,
    newResolutionValue: RedFlagResolutionEnum,
    newEnabledValue: boolean
  ) => void;
}> = ({
  isReadOnly,
  className,
  cardLabel,
  flagData,
  onChange,
  initialState = FlagSettingsState.initial,
  isDragging,
  isDraggingPreview = false,
}) => {
  const [cardState, dispatch] = useStateMachine(
    FlagSettingsStateConfig,
    initialState
  );

  const { displayName, description, resolution, enabled, type } = flagData;
  const { resolutionClassName, flagIcon, actionText } = getFlagSettingsElements(
    resolution,
    enabled
  );

  const isInitialState = cardState.matches(FlagSettingsState.initial);
  const isCollapsedState = cardState.matches(FlagSettingsState.collapsed);
  const isChangeResolutionState = cardState.matches(
    FlagSettingsState.changeResolution
  );

  const showInitialState = useCallback(() => {
    if (!isInitialState) {
      dispatch(FlagSettingsActions.showInitial);
    }
  }, [dispatch, isInitialState]);

  const showChangeResolutionState = useCallback(() => {
    if (!isChangeResolutionState) {
      dispatch(FlagSettingsActions.showChangeResolution);
    }
  }, [dispatch, isChangeResolutionState]);

  const showCollapsedState = useCallback(() => {
    if (!isCollapsedState) {
      dispatch(FlagSettingsActions.showCollapsed);
    }
  }, [dispatch, isCollapsedState]);

  const resetCardState = useCallback(() => {
    if (cardState.matches(initialState)) {
      return;
    }
    switch (initialState) {
      case FlagSettingsState.initial:
        return showInitialState();
      case FlagSettingsState.collapsed:
        return showCollapsedState();
      case FlagSettingsState.changeResolution:
        return showChangeResolutionState();
    }
  }, [
    initialState,
    cardState,
    showInitialState,
    showCollapsedState,
    showChangeResolutionState,
  ]);

  const [outsideClickRef] = useOutsideClick(resetCardState, {
    useCapture: true,
  });

  const onCardClicked = useCallback(() => {
    if (!isChangeResolutionState) {
      return showChangeResolutionState();
    }
    resetCardState();
  }, [isChangeResolutionState, showChangeResolutionState, resetCardState]);

  const onMouseOver: () => void = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onMouseOverDebounced.clear();
    if (!isChangeResolutionState) {
      showCollapsedState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeResolutionState, showCollapsedState]);

  const onMouseOverDebounced = R.debounce<() => void>(
    onMouseOver,
    HOVER_DEBOUNCE_TIME
  );

  const onMouseLeave = useCallback(() => {
    onMouseOverDebounced.clear();
    if (!isChangeResolutionState) {
      resetCardState();
    }
  }, [isChangeResolutionState, resetCardState, onMouseOverDebounced]);

  const onChangeResolution = useCallback(
    (newResolutionValue: RedFlagResolutionEnum, newEnabledValue: boolean) => {
      if (newResolutionValue !== resolution || newEnabledValue !== enabled) {
        onChange(type, newResolutionValue, newEnabledValue);
      }
      resetCardState();
    },
    [onChange, resetCardState, type, resolution, enabled]
  );

  const topElement = useMemo(() => {
    return (
      <div
        className={classNames(styles.topLabel, {
          [styles.isVisible]: !isInitialState,
        })}
      >
        <span>{phrases.RED_FLAG_WHEN_LABEL}</span>
      </div>
    );
  }, [isInitialState]);

  const bottomElement = useMemo(() => {
    return (
      <div
        className={classNames(styles.bottomElement, {
          [styles.isVisible]: !isInitialState,
        })}
      >
        <div className={styles.bottomLabel}>
          <span>{phrases.RED_FLAG_THEN_LABEL}</span>
        </div>
        {isChangeResolutionState ? (
          <>
            {isDisqualifyAvailable(type) && (
              <button
                onClick={() =>
                  onChangeResolution(
                    RedFlagResolutionEnum.DISQUALIFICATION,
                    true
                  )
                }
                className={classNames(styles.actionButton, styles.disqualify, {
                  [styles.active]:
                    resolution === RedFlagResolutionEnum.DISQUALIFICATION &&
                    enabled,
                })}
              >
                {phrases.FLAG_SETTINGS_DISQUALIFY}
              </button>
            )}
            {isExplanationAvailable(type) && (
              <button
                onClick={() =>
                  onChangeResolution(RedFlagResolutionEnum.EXPLANATION, true)
                }
                className={classNames(styles.actionButton, styles.askFirst, {
                  [styles.active]:
                    resolution === RedFlagResolutionEnum.EXPLANATION && enabled,
                })}
              >
                {phrases.FLAG_SETTINGS_ASK_FIRST}
              </button>
            )}
            {isWarningAvailable(type) && (
              <button
                onClick={() =>
                  onChangeResolution(RedFlagResolutionEnum.WARNING, true)
                }
                className={classNames(styles.actionButton, styles.notify, {
                  [styles.active]:
                    resolution === RedFlagResolutionEnum.WARNING && enabled,
                })}
              >
                {phrases.FLAG_SETTINGS_NOTIFY}
              </button>
            )}
            <button
              onClick={() => onChangeResolution(resolution, false)}
              className={classNames(styles.actionButton, styles.ignore, {
                [styles.active]: !enabled,
              })}
            >
              {phrases.FLAG_SETTINGS_IGNORE}
            </button>
          </>
        ) : (
          <button
            disabled={isReadOnly}
            onClick={onCardClicked}
            className={classNames(styles.actionButton, styles.active)}
          >
            {actionText}
          </button>
        )}
      </div>
    );
  }, [
    isReadOnly,
    isInitialState,
    resolution,
    onChangeResolution,
    actionText,
    onCardClicked,
    enabled,
    type,
    isChangeResolutionState,
  ]);

  return (
    <div
      ref={outsideClickRef}
      className={classNames(className, styles.cardWrapper, {
        [styles.changeResolution]: isChangeResolutionState,
        [styles.cardDraggingPreview]: isDraggingPreview,
      })}
    >
      <Card.ResizeableCardWrapper resizeable defaultSize={CardSize.wide}>
        <Card.SearchCriteriaCardContainer
          isDragging={isDragging}
          className={classNames(styles.cardContainer, resolutionClassName)}
        >
          <Card.SearchCriteriaCardViewBackground
            isVisible={!isInitialState}
            topElement={topElement}
            bottomElement={bottomElement}
          >
            {isInitialState && cardLabel && (
              <Card.SearchCriteriaCardLabel
                className={styles.cardLabel}
                text={cardLabel}
              />
            )}
            <Card.SearchCriteriaCardContent
              onMouseOver={onMouseOverDebounced}
              onMouseLeave={onMouseLeave}
              className={classNames(styles.cardContent, {
                [styles.changeResolution]: isChangeResolutionState,
                [styles.isReadOnly]: isReadOnly,
                [styles.cardDraggingPreview]: isDraggingPreview,
              })}
              onClick={isReadOnly ? R.noop : onCardClicked}
            >
              <SvgIcon icon={flagIcon} className={styles.flagIcon} />
              <Card.Title
                className={classNames(styles.cardTitle)}
                resizeable
                flexGrow={isDraggingPreview}
                title={displayName}
              />
              {!isChangeResolutionState && !isDraggingPreview && (
                <Card.Title
                  className={classNames(styles.cardDescription)}
                  resizeable
                  flexGrow
                  showTooltip={false}
                  title={description}
                />
              )}
            </Card.SearchCriteriaCardContent>
          </Card.SearchCriteriaCardViewBackground>
        </Card.SearchCriteriaCardContainer>
      </Card.ResizeableCardWrapper>
    </div>
  );
};
