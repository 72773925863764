/*
  This component represents a calendar-style timeline.

  Data is sorted chronologically, and should be output
  as blocks on a timeline, with alignment by the left side,
  where possible. And if left column is occupied, block is
  placed on the next right column.
 */
// imports from vendor deps
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { chain } from 'ramda';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { Link } from 'react-router-dom';

// imports from types
import {
  CompanyFullResponse,
  PeriodInfo,
  SearchWorkHistoryType,
} from '@air/api/models';

// imports from 'components'
import {
  SvgIcon,
  TabsControl,
  TooltipWrapper,
  Paragraph,
  UIText,
} from '@air/components';
import {
  ApproximateExperiencePeriod,
  ApproximatePeriodType,
} from '@air/components/Period/Period';
import { WorkHistoryTableView } from 'components/WorkTimeline/WorkHistoryTableView/WorkHistoryTableView';
import { SectionPanel } from 'features/Kanban/SectionPanel/SectionPanel';

// imports from 'constants'
import * as phrases from 'constants/phrases';
import { GA_LABEL_TIMELINE_CLICK_REVIEW_ENRICHMENT } from 'constants/gaLabels';
import { TimelineTabs } from '@air/constants/tabs';

// import from images
// import {} from 'images'
// imports from helpers
import { getMonthDiff } from '@air/utils/dates';
import { useClientRect } from '@air/hooks';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import {
  MIN_JOB_ROWS_SPAN,
  MONTHS_PER_YEAR,
  WorkHistoryData,
  groupPositions,
  notAValidWorkExperience,
  getWorkExperienceString,
  getPositionDatesPeriods,
  convertTotalWorkExperienceToString,
  normalizeExternalLink,
} from 'domain/CandidateData/WorkHistory/WorkHistoryData';

// imports from styles
import styles from './WorkTimeline.css';

// component proptypes
type Props = {
  workHistory: WorkHistoryData[];
  companiesInfo: CompanyFullResponse[];
  totalWorkExperience: PeriodInfo;
  areCompaniesLoading: boolean;
};

const MIN_MONTH_NOT_TO_INTERSECT_CURRENT_YEAR = 3;

export const WorkTimeline: React.FC<Props> = React.memo(
  ({
    workHistory,
    companiesInfo,
    areCompaniesLoading,
    totalWorkExperience,
  }) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const [selectedTab, setTab] = useState(TimelineTabs.Standard);

    const handleTabsChange = (selectedTab: TimelineTabs) => {
      setTab(selectedTab);
    };

    // graphic view should not show gaps, standard view has gaps
    const allPositionsGrouped = groupPositions(
      workHistory.filter(
        (position) => position.type !== SearchWorkHistoryType.GAP
      )
    );

    const allPositionsFlat = chain(
      ([, positions]) => positions,
      allPositionsGrouped
    );

    let currentColumnForBlock = 2;
    const columnBlockEndCoords = {
      [currentColumnForBlock]: 0,
    };

    const jobPositions = allPositionsGrouped.reduce(
      (allPositions, [organization, positions]) => {
        /* Build grid components for every position of each job */
        const currentJobPositions = positions.reduce(
          (
            acc: React.ReactNode[],
            position: WorkHistoryData,
            index: number
          ) => {
            const isFirstPosition = index === 0;
            const isLastPosition = index === positions.length - 1;

            const currentJobSummary: any = {
              organization,
              topLimit: null,
              bottomLimit: null,
            };

            const {
              positionEndDate,
              positionEndMonth,
              positionStartYear,
              positionEndYear,
              positionMonthsDuration,
            } = getPositionDatesPeriods(position);

            /* Adding 1, because CSS grid rows start from 1, not 0 */
            currentJobSummary.topLimit =
              1 + getMonthDiff(positionEndDate, currentDate);
            currentJobSummary.bottomLimit =
              currentJobSummary.topLimit + positionMonthsDuration;

            let hasAdjacentBottomPosition = true;
            let hasAdjacentTopPosition = true;

            if (!isLastPosition) {
              const nextPosition = positions[index + 1];

              const nextPositionEndMonth = nextPosition.endDate.month;
              const nextPositionEndYear = nextPosition.endDate.year;
              hasAdjacentBottomPosition =
                positionStartYear === nextPositionEndYear &&
                positionEndMonth === nextPositionEndMonth;
            }
            if (!isFirstPosition) {
              const prevPosition = positions[index - 1];
              const prevPositionStartMonth = prevPosition.startDate.month;
              const prevPositionStartYear = prevPosition.startDate.year;
              hasAdjacentTopPosition =
                positionEndYear === prevPositionStartYear &&
                positionEndMonth === prevPositionStartMonth;
            }

            /*
          Calculation of column where current organization positions
          should be placed
        */
            currentColumnForBlock = 2;

            while (
              currentJobSummary.topLimit <
                columnBlockEndCoords[currentColumnForBlock] &&
              // without a guard against 0 when we have null/0 in startDate/endDate (submitted basic questions with empty dates)
              // the while loop is eternal
              columnBlockEndCoords[currentColumnForBlock] !== 0 &&
              !position.unknown
            ) {
              currentColumnForBlock++;
              columnBlockEndCoords[currentColumnForBlock] =
                columnBlockEndCoords[currentColumnForBlock] || 0;
            }

            if (position.unknown) {
              const {
                startDate: { year },
              } = position;

              const yearsDiff = currentYear - year;

              currentJobSummary.topLimit =
                yearsDiff * MONTHS_PER_YEAR + currentMonth + 1;

              currentJobSummary.bottomLimit =
                currentJobSummary.topLimit + MONTHS_PER_YEAR;
            }

            if (
              currentJobSummary.bottomLimit >
              columnBlockEndCoords[currentColumnForBlock]
            ) {
              columnBlockEndCoords[currentColumnForBlock] =
                currentJobSummary.bottomLimit;
            }

            const rowSpan = Math.max(
              currentJobSummary.bottomLimit - currentJobSummary.topLimit,
              MIN_JOB_ROWS_SPAN
            );

            const styleObj = {
              '--column': position.unknown ? 2 : currentColumnForBlock,
              '--row-start': currentJobSummary.topLimit,
              '--row-span': rowSpan,
            };

            const companyInfo = R.find(R.propEq('id', position.company?.id))(
              companiesInfo
            );

            acc.push(
              <JobPositionContainer
                key={organization + position.title + index}
                organization={organization}
                position={position}
                positionMonthsDuration={positionMonthsDuration}
                // @ts-ignore
                companyInfo={companyInfo}
                styleObj={styleObj}
                hasAdjacentBottomPosition={hasAdjacentBottomPosition}
                hasAdjacentTopPosition={hasAdjacentTopPosition}
                isFirstPosition={isFirstPosition}
                isLastPosition={isLastPosition}
              />
            );

            return acc;
          },
          []
        );

        return allPositions.concat(currentJobPositions);
      },
      []
    );

    const careerStartYear = allPositionsFlat.reduce<number>(
      (year, { startDate }) => {
        return !startDate || startDate.year === 0
          ? year
          : Math.min(year, startDate.year);
      },
      currentYear
    );

    const positionsWithoutDatesCount = allPositionsFlat.reduce<number>(
      (count, { unknown }) => (unknown ? ++count : count),
      0
    );

    const arrayWithEmptyPlaces = (positionsWithoutDates: number) => {
      return Array.from({ length: positionsWithoutDates }).map((item, index) =>
        index === 0 ? '--' : ' '
      );
    };

    const timelineYearsSpan =
      currentYear - careerStartYear - positionsWithoutDatesCount;

    const timelineColumnsCount = Object.keys(columnBlockEndCoords).length;
    const candidateHasWorkHistory = !!jobPositions.length;

    if (!candidateHasWorkHistory)
      return (
        <SectionPanel
          title={phrases.WORK_TIMELINE_TOP_TITLE_NO_HISTORY}
          className={styles.noData}
        />
      );

    return (
      <>
        <div
          className={classNames(styles.timelineTabsWrapper, {
            [styles.hasMaxWidth]: selectedTab === TimelineTabs.Standard,
          })}
        >
          <TabsControl<TimelineTabs>
            items={[
              {
                label: phrases.CANDIDATE_PROFILE_TIMELINE_TABS_STANDARD,
                value: TimelineTabs.Standard,
              },
              {
                label: phrases.CANDIDATE_PROFILE_TIMELINE_TABS_GRAPHIC,
                value: TimelineTabs.Graphic,
              },
            ]}
            selectedTab={selectedTab}
            onChange={handleTabsChange}
          />
          <Paragraph small className={styles.workExperienceWrapper}>
            {phrases.WORK_TIMELINE_TOTAL_WORK_EXPERIENCE}:{' '}
            <TooltipWrapper
              tooltip={phrases.WORK_TIMELINE_TOTAL_WORK_EXPERIENCE_TOOLTIP}
              enabled={totalWorkExperience.approx}
            >
              <strong>
                {convertTotalWorkExperienceToString(totalWorkExperience)}
              </strong>
            </TooltipWrapper>
          </Paragraph>
        </div>
        {selectedTab === TimelineTabs.Standard && (
          <WorkHistoryTableView
            workHistory={workHistory}
            companiesInfo={companiesInfo}
            areCompaniesLoading={areCompaniesLoading}
          />
        )}
        {selectedTab === TimelineTabs.Graphic ? (
          <div
            style={
              { '--columns-count': timelineColumnsCount } as React.CSSProperties
            }
            className={styles.workTimeline}
          >
            <TimelineRow
              title={phrases.WORK_TIMELINE_TOP_TITLE_PRESENT}
              className={classNames(styles.presentTimelineRow, {
                [styles.leftAlignedLabel]: !candidateHasWorkHistory,
                [styles.noJobHistory]: !jobPositions.length,
              })}
              span={currentMonth}
            />
            {jobPositions.length ? (
              <>
                {Array.from({ length: timelineYearsSpan + 1 })
                  .concat(arrayWithEmptyPlaces(positionsWithoutDatesCount))
                  .map((noTitleOrInvalidIndicator: string, i) => (
                    <TimelineRow
                      key={'row' + i}
                      title={
                        noTitleOrInvalidIndicator ||
                        (currentMonth <
                          MIN_MONTH_NOT_TO_INTERSECT_CURRENT_YEAR &&
                          i === 0)
                          ? noTitleOrInvalidIndicator
                          : currentYear - i
                      }
                      startRow={currentMonth + i * MONTHS_PER_YEAR}
                      showLine={
                        i !== 0 ||
                        currentMonth >= MIN_MONTH_NOT_TO_INTERSECT_CURRENT_YEAR
                      }
                    />
                  ))}
                {jobPositions}
              </>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
);

WorkTimeline.defaultProps = {};

type TimelineRowProps = {
  title: number | string;
  startRow?: number;
  span?: number;
  className?: string;
  showLine?: boolean;
};
const TimelineRow: React.FC<TimelineRowProps> = ({
  className = '',
  title,
  startRow = 0,
  span = MONTHS_PER_YEAR,
  showLine = false,
}) => {
  const rowStyle = {
    '--grid-row': startRow + 1,
    '--row-span': span,
  };

  return (
    <div
      className={classNames(styles.timelineRow, className, {
        [styles.withMiddleLine]: span > MONTHS_PER_YEAR / 2,
        [styles.withLine]: showLine,
      })}
      style={rowStyle as React.CSSProperties}
    >
      <p className={styles.timelineRowTitle}>
        <span>{title}</span>
      </p>
    </div>
  );
};

type JobPositionContainerProps = {
  organization: string;
  position: WorkHistoryData;
  positionMonthsDuration: number;
  companyInfo: CompanyFullResponse;
  styleObj: { [key: string]: number };
  hasAdjacentBottomPosition: boolean;
  hasAdjacentTopPosition: boolean;
  isFirstPosition: boolean;
  isLastPosition: boolean;
};

const JobPositionContainer: React.FC<JobPositionContainerProps> = ({
  organization,
  position,
  positionMonthsDuration,
  companyInfo,
  styleObj,
  hasAdjacentBottomPosition,
  hasAdjacentTopPosition,
  isFirstPosition,
  isLastPosition,
}) => {
  const [isCompanyInfoVisible, setCompanyInfoVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isJobContainerOwerflown, setJobContainerOverflown] = useState(false);

  const [jobDetailsNode, jobDetailsRef] = useClientRect();
  const [companyInfoNode, companyInfoRef] = useClientRect();
  const [jobContainerNode, jobContainerRef] = useClientRect();

  useEffect(() => {
    if (
      jobDetailsNode &&
      jobContainerNode &&
      jobDetailsNode?.height > jobContainerNode?.height
    ) {
      setJobContainerOverflown(true);
    }
  }, [jobDetailsNode, jobContainerNode]);

  // if there is enought space in jobPositionContainer to show company info,
  // we don't add any additional space. otherwise we add companyInfo height.
  const addedCompanyInfoHeight = useMemo(
    () =>
      jobDetailsNode &&
      companyInfoNode &&
      jobContainerNode &&
      jobDetailsNode?.height + companyInfoNode?.height <
        jobContainerNode?.height
        ? 0
        : companyInfoNode?.height,
    [jobDetailsNode, companyInfoNode, jobContainerNode]
  );

  /* sometimes not all information can fit in a work span
  that's why the rest that doesn't fit is calculated to be added to the height
  on hover  */
  const addedJobDetailsHeight = useMemo(
    () =>
      jobDetailsNode && jobContainerNode && isJobContainerOwerflown
        ? jobDetailsNode?.height - jobContainerNode?.height
        : 0,
    [jobDetailsNode, jobContainerNode, isJobContainerOwerflown]
  );

  const updatedStyleObj = {
    ...styleObj,
    '--company-info-height': `${addedCompanyInfoHeight}px`,
    '--added-job-details-height': `${addedJobDetailsHeight}px`,
  };

  /* If parser was unable to derive end or start date, it will be null */
  const { unknown, isStartDateUnknown, isEndDateUnknown } = position;

  const positionTitle = position.title || phrases.WORK_TIMELINE_UNKNOWN_ROLE;
  const companyName = organization || phrases.WORK_TIMELINE_UNKNOWN_COMPANY;

  const positionDuration = notAValidWorkExperience(position) ? (
    getWorkExperienceString(position)
  ) : (
    <ApproximateExperiencePeriod
      experience={{
        years: Math.floor(positionMonthsDuration / MONTHS_PER_YEAR),
        months: positionMonthsDuration % MONTHS_PER_YEAR,
        approx: position.approx,
      }}
      periodType={ApproximatePeriodType.workhistory}
    />
  );

  const delayedShowCompanyInfo = useCallback(() => {
    if (companyInfo?.fullName) {
      const timeoutId = setTimeout(() => {
        trackEvent({
          category: GACategory.ScreeningPage,
          label: GA_LABEL_TIMELINE_CLICK_REVIEW_ENRICHMENT,
        });
        setCompanyInfoVisible(true);
        // this timeout lets the user scroll down the timeline without firing the events too often
      }, 1000);
      setTimeoutId(timeoutId);
    }
  }, [companyInfo]);

  const hideCompanyInfo = useCallback(() => {
    if (companyInfo?.fullName) {
      clearTimeout(timeoutId);
      setCompanyInfoVisible(false);
    }
  }, [companyInfo, timeoutId]);

  return (
    <div
      ref={jobContainerRef}
      style={updatedStyleObj as React.CSSProperties}
      className={classNames(styles.jobContainer, {
        [styles.firstPosition]: isFirstPosition,
        [styles.lastPosition]: isLastPosition,
        [styles.noAdjacentBottomPosition]: !hasAdjacentBottomPosition,
        [styles.noAdjacentTopPosition]: !hasAdjacentTopPosition,
        [styles.expandOnHover]: isJobContainerOwerflown,
        [styles.expandedCompanyInfo]: isCompanyInfoVisible,
        [styles.isInvalidWorkExperience]:
          unknown || isEndDateUnknown || isStartDateUnknown,
      })}
      onMouseEnter={delayedShowCompanyInfo}
      onMouseLeave={hideCompanyInfo}
    >
      <div ref={jobDetailsRef} className={styles.jobDetails}>
        <div className={styles.company}>{companyName}</div>
        {companyInfo?.fullName && (
          <CompanyInfo
            ref={companyInfoRef}
            companyInfo={companyInfo}
            className={classNames({
              [styles.companyInfoHidden]: !isCompanyInfoVisible,
              [styles.companyInfoVisible]: isCompanyInfoVisible,
            })}
            locationParsedByML={position?.location}
          />
        )}
        <div className={styles.positionTitle}>{positionTitle}</div>
        <div className={styles.experience}>{positionDuration}</div>
        {companyInfo?.fullName && (
          <div className={styles.hamburgerMenu}>
            {isCompanyInfoVisible ? (
              <div className={styles.oneLineMenu}></div>
            ) : (
              <SvgIcon icon="hamburger" className={styles.hamburgerIcon} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

type CompanyInfoProps = {
  companyInfo: CompanyFullResponse;
  className?: string;
  locationParsedByML?: string;
};

const CompanyInfo = React.forwardRef<HTMLDivElement, CompanyInfoProps>(
  (
    { companyInfo, className, locationParsedByML },
    ref: RefObject<HTMLDivElement>
  ) => {
    const {
      city,
      state,
      country,
      employees,
      revenue,
      industry,
      companyType,
      additionalInfo,
    } = companyInfo;

    const companyLocation = [city, state, country].filter(Boolean).join(', ');
    const companyEmployees = phrases.COMPANY_EMPLOYEES(employees);
    const companyRevenue = phrases.COMPANY_REVENUE(revenue, true);
    const companyFoundationYear = additionalInfo?.foundationDate?.slice(0, 4);
    const companyWebsites = additionalInfo?.websites;

    return (
      <div ref={ref} className={classNames(styles.companyInfo, className)}>
        {locationParsedByML && (
          <span className={styles.data}>{locationParsedByML}</span>
        )}
        <div className={styles.companyDetails}>
          <UIText tiny bold>
            {phrases.COMPANY_HEADER}
          </UIText>
          <ul className={styles.list}>
            {companyLocation && (
              <li className={styles.listItem}>
                <span>{companyLocation}</span>
              </li>
            )}
            {companyEmployees && (
              <li className={styles.listItem}>
                <span>{companyEmployees}</span>
              </li>
            )}
            {companyRevenue && (
              <li className={styles.listItem}>
                <UIText tiny>{companyRevenue}</UIText>
              </li>
            )}
            {industry && (
              <li className={styles.listItem}>
                <span className={styles.data}>{industry}</span>{' '}
                {phrases.COMPANY_INDUSTRY_LABEL}
              </li>
            )}
            {companyType && (
              <li>
                <span className={styles.data}>{companyType}</span>{' '}
                {phrases.COMPANY_LABEL}
              </li>
            )}
            {companyFoundationYear && (
              <li className={styles.listItem}>
                {phrases.COMPANY_LABEL_FOUNDED}: {companyFoundationYear}
              </li>
            )}
            {(companyWebsites || []).map((link) => (
              <li className={styles.listItem} key={link}>
                <Link
                  to={{ pathname: normalizeExternalLink(link) }}
                  key={link}
                  target="_blank"
                  className={styles.website}
                >
                  {link}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
);
