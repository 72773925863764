import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { toast } from '@air/third-party/toast';
import R from '@air/third-party/ramda';

import styles from './TrialSection.css';
import { useUserSettings } from 'hooks/useUserSettings';
import { Button } from 'components';
import * as phrases from 'constants/phrases';
import { LicenseType } from '@air/api/models';
import { Paragraph, UIText } from '@air/components';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';
import {
  getTrialDaysLeftMessage,
  getTrialDaysLeftToastMessage,
  TRIAL_REQUEST_CALENDLY_URL,
} from 'domain/Trial/Trial';

type TrialPropsT = {
  className?: string;
  requestFullVersion?: (...args: any[]) => any;
};
export const TrialSection: React.FC<TrialPropsT> = ({ className }) => {
  const { requestFullVersion } = useCustomerProfileMethods();
  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );
  const daysLeft = useCustomerProfileContext(customerProfileSelectors.daysLeft);
  const licenseType = useCustomerProfileContext(
    customerProfileSelectors.licenseType
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  const { trialSettings, setTrialSettings } = useUserSettings(customerId);
  /*
    We need to close 'unclosable' toasts when Trial section
    is unmounted.
  */
  const currentToastId = useRef(null);
  const shouldSaveToastState = useRef(true);
  useEffect(
    () => () => {
      /*
        When user logs out, toast should be dismissed to avoid
        displaying it on the Login page. But in this case its
        onClose callback would still be called, thus incorrectly
        saving "isShown" trial toast state to local settings.
       */
      shouldSaveToastState.current = false;
      toast.dismiss(currentToastId.current);
    },
    []
  );

  useEffect(() => {
    const TRIAL_DAYS_LEFT_REMINDER = 3;
    if (
      daysLeft === null ||
      isTrialExpired === null ||
      daysLeft > TRIAL_DAYS_LEFT_REMINDER
    )
      return;

    const { lastDaysShown, isShown } = trialSettings;
    const showTrialDaysLeftToast =
      lastDaysShown === null ||
      daysLeft < lastDaysShown ||
      (daysLeft === lastDaysShown && !isShown);

    const requestTrialButton = (
      <Button
        onClick={() => {
          requestFullVersion()
            .then(() => toast.dismiss(currentToastId.current))
            .catch(R.noop);
        }}
        className={styles.trialRequestButton}
        variant={Button.variants.INLINE}
      >
        <UIText bold>{phrases.TRIAL_REQUEST_FULL}</UIText>
      </Button>
    );

    if (
      showTrialDaysLeftToast &&
      isTrialExpired === false &&
      licenseType !== LicenseType.TRIALACTIVEFULLREQUESTED
    ) {
      const TrialDaysLeftBody = (
        <div className={styles.trialToastBody}>
          <Paragraph short>{getTrialDaysLeftToastMessage(daysLeft)}</Paragraph>
          {requestTrialButton}
        </div>
      );
      currentToastId.current = toast.info(TrialDaysLeftBody, {
        autoClose: false,
        onClose: () => {
          if (shouldSaveToastState.current) {
            setTrialSettings({
              isShown: true,
              lastDaysShown: daysLeft,
            });
          }
        },
        bodyClassName: styles.trialToastBodyContainer,
      });
    }

    const TrialExpiredBody = (
      <div className={styles.trialToastBody}>
        <Paragraph short>{phrases.TRIAL_EXPIRED_NOTIFICATION}</Paragraph>
        {requestTrialButton}
      </div>
    );

    const requestedTrialMessage = isTrialExpired
      ? `${phrases.TRIAL_EXPIRED_NOTIFICATION}. ${phrases.TRIAL_REQUESTED_NOTIFICATION}`
      : `${getTrialDaysLeftToastMessage(daysLeft)}. ${
          phrases.TRIAL_REQUESTED_NOTIFICATION
        }`;

    const TrialRequestedBody = (
      <div className={styles.trialToastBody}>
        <Paragraph short>{requestedTrialMessage}</Paragraph>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={TRIAL_REQUEST_CALENDLY_URL}
          className={styles.scheduleCallLink}
        >
          <UIText bold>{phrases.TRIAL_SCHEDULE_CALL}</UIText>
        </a>
      </div>
    );

    if (licenseType === LicenseType.TRIALEXPIRED) {
      currentToastId.current = toast.warning(TrialExpiredBody, {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      });
    }

    if (
      showTrialDaysLeftToast &&
      licenseType === LicenseType.TRIALACTIVEFULLREQUESTED
    ) {
      currentToastId.current = toast.success(TrialRequestedBody, {
        autoClose: false,
        closeOnClick: false,
        closeButton: true,

        onClose: () => {
          setTrialSettings({
            isShown: true,
            lastDaysShown: daysLeft,
          });
        },
      });
    }

    if (licenseType === LicenseType.TRIALEXPIREDFULLREQUESTED) {
      currentToastId.current = toast.success(TrialRequestedBody, {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      });
    }
  }, [
    daysLeft,
    trialSettings,
    setTrialSettings,
    isTrialExpired,
    requestFullVersion,
    licenseType,
  ]);

  const message = isTrialExpired
    ? phrases.TRIAL_EXPIRED
    : getTrialDaysLeftMessage(daysLeft);

  return (
    <div className={classNames(styles.trialSection, className)}>
      <UIText tiny>{phrases.TRIAL_VERSION_LABEL}:</UIText>
      <UIText tiny>{message}</UIText>
    </div>
  );
};
TrialSection.displayName = 'TrialSection';
