// imports from vendor deps
import React from 'react';
import { identity } from 'ramda';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
// import {} from 'components';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'

// imports from styles
import styles from './Logo.css';

// component proptypes
type Props = {
  className?: string;
  href?: string;
  onClick?: () => void;
  onlyIconVisible?: boolean;
  isWhiteLogoText?: boolean;
};

// exports / class definitions
export function Logo(props: Props) {
  const {
    className,
    onClick = identity,
    href,
    onlyIconVisible = false,
    isWhiteLogoText = false,
  } = props;

  let version = null;
  if (ENV_NAME === 'qa' || ENV_NAME === 'dev') {
    const timestamp = parseInt(APP_VERSION) * 1000;
    const deployedVersion = isFinite(timestamp)
      ? new Date(timestamp).toLocaleString()
      : APP_VERSION;
    version = <small className={styles.version}>{deployedVersion}</small>;
  }

  const logoWithText = isWhiteLogoText ? 'logo-mm-white' : 'logo-mm';

  const content = (
    <div className={styles.logoContent}>
      {onlyIconVisible ? (
        <span className={styles.logoOnly}>
          <img src="/assets/images/logo_x4.png" />
        </span>
      ) : (
        <div className={styles.logoWithText}>
          <img src={`/assets/images/${logoWithText}.png`} />
          {version && <span>{version}</span>}
        </div>
      )}
    </div>
  );

  return (
    <div onClick={onClick} className={classNames(styles.logo, className)}>
      {href ? <Link to={href}>{content}</Link> : content}
    </div>
  );
}
