import React, { useCallback, useMemo, useState } from 'react';
import { Field, FieldArray, FieldProps } from 'formik';
import R from '@air/third-party/ramda';
// import shared utils
// import hooks
import { useStateMachine } from '@air/hooks';
import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import {
  SearchCardContext,
  StateContext,
} from 'components/SearchCriteriaCards/hooks/SearchCardContext';
// import shared components
import { Card } from '@air/components';
// import customer components
import { SelectRenderer } from '@air/components/Select/Select';
// import types
import { FormSelectEntity } from '@air/components/Select/typings';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
// import styles
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
// import constants
import {
  CardType,
  CertificationCriteriaData,
  getImportanceValue,
  SearchCriteriaListValue,
  certificationDataMapper,
} from 'domain/SearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
// import utils
import {
  getOnMainCardKeyDownCallback,
  getDraggableStackItem,
  getDraggableCriteriaCard,
  CriteriaCardViewProps,
  CriteriaDragObjectT,
  SingleCriteriaItemDragObject,
  MAX_CARDS_IN_STACK,
  isDraggableMainCard,
  isDraggableStackItem,
  getOnStackCardKeyDownCallback,
} from 'components/Cards/cardsCommonCode';
import {
  canMergeLists,
  loadPaginatedCertificationAsyncOptions,
} from 'components/SearchCriteriaCards/utils';
import {
  getCardCriterionLabel,
  getMainCardTitle,
  getTopStackSize,
  getAddingLimits,
  getCardStackSize,
  getTopStackListName,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import {
  CriteriaDraggableCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { generateCriteriaCardTopStackItems } from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import { CriteriaCollapsedCardProps } from 'components/CardWrappers/CriteriaCollapsedCard/CriteriaCollapsedCard';
import {
  CriteriaEditCardStackItems,
  generateTopStackItems,
  generateBottomStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import * as phrases from 'constants/phrases';

/*
  match an empty string or a string without whitespaces at the beginning
  so once typing the string, user can still clear the input
 */
const whitelistedchars = `a-zA-Z0-9&/,.!?#+\\-:;{}()\\[\\]\\|ú`;

const certificationTitleRegex = new RegExp(
  `^$|^[${whitelistedchars}][${whitelistedchars} ]*$`
);

export const getCertificationReadOnlyFields = (
  card: CertificationCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
  ];
};

const DraggableCertificationStackItem =
  getDraggableStackItem<CertificationCriteriaData>(
    DraggedSearchCriteriaCardType.singleCertificationItem
  );
DraggableCertificationStackItem.displayName = 'DraggableCertificationStackItem';

export const CertificationCriteriaDraggableCollapsedCard: React.FC<
  CriteriaCardViewProps<CertificationCriteriaData> & CriteriaCollapsedCardProps
> = ({
  criteria,
  stackSize,
  handleDrop,
  handleSingleCriteriaDrop,
  ...props
}) => {
  const id = criteria.key;

  const acceptTypes = useMemo(() => {
    return [
      DraggedSearchCriteriaCardType.certification,
      DraggedSearchCriteriaCardType.singleCertificationItem,
    ];
  }, []);

  const canDropCallback = useCallback(
    (
      draggedItem:
        | CriteriaDragObjectT<CertificationCriteriaData>
        | SingleCriteriaItemDragObject<CertificationCriteriaData>
    ) => {
      if (
        isDraggableStackItem(draggedItem) ||
        (isDraggableMainCard(draggedItem) && draggedItem.isDrawingMainCard)
      ) {
        return (
          id !== draggedItem.groupId &&
          getTopStackSize(criteria) < MAX_CARDS_IN_STACK
        );
      }
      return (
        id !== draggedItem.id &&
        draggedItem.type === DraggedSearchCriteriaCardType.certification &&
        canMergeLists(draggedItem.criteria, criteria)
      );
    },
    [criteria, id]
  );

  const dropCallback = useCallback(
    (droppedItem) => {
      if (
        droppedItem.type ===
          DraggedSearchCriteriaCardType.singleCertificationItem ||
        droppedItem.isDrawingMainCard
      ) {
        handleSingleCriteriaDrop(id, droppedItem.id, droppedItem.groupId);
      } else {
        handleDrop(id, droppedItem.id);
      }
    },
    [handleDrop, handleSingleCriteriaDrop, id]
  );

  return (
    <CriteriaDraggableCollapsedCard
      {...props}
      criteria={criteria}
      stackSize={stackSize}
      draggableStackItem={DraggableCertificationStackItem}
      acceptTypes={acceptTypes}
      canDropCallback={canDropCallback}
      dropCallback={dropCallback}
    />
  );
};

CertificationCriteriaDraggableCollapsedCard.displayName =
  'CertificationCriteriaDraggableCollapsedCard';

const DraggableCertificationCriteriaCard =
  getDraggableCriteriaCard<CertificationCriteriaData>(
    CertificationCriteriaDraggableCollapsedCard,
    DraggedSearchCriteriaCardType.certification
  );
DraggableCertificationCriteriaCard.displayName =
  'DraggableCertificationCriteriaCard';

const DEFAULT_CERTIFICATION_OPTIONS: {
  items: SearchCriteriaListValue[];
  total: number;
  page: number;
} = {
  items: [],
  total: 0,
  page: 0,
};

export const CertificationCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: CertificationCriteriaData;
  specializations: string[];
  optionsFilter: (value: any) => boolean;
  isReadOnly?: boolean;
  changeCardImportance: (
    criteria: CertificationCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
}> = ({
  namePrefix,
  values,
  specializations,
  optionsFilter,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const [certificationOptions, setCertificationOptions] = useState(
    DEFAULT_CERTIFICATION_OPTIONS
  );
  const [currentSearchInput, setCurrentSearchInput] = useState('');
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const certificationCardConfig = cardsConfig[CardType.certification];
  const topStackListName = getTopStackListName(values);

  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(
    values,
    MAX_CARDS_IN_STACK
  );

  const searchCertificationOptions = useCallback(
    ({
      value,
      page = 0,
      prevItems = [],
    }: {
      value?: string;
      page?: number;
      prevItems?: SearchCriteriaListValue[];
    }) => {
      loadPaginatedCertificationAsyncOptions(
        {
          value,
          page,
          specializations,
        },
        ({ items, total }) => {
          setCertificationOptions({
            items: [...prevItems, ...R.map(certificationDataMapper, items)],
            total,
            page,
          });
          setIsOptionsLoading(false);
        }
      );
    },
    [specializations]
  );

  const loadMoreOptions = useCallback(
    () =>
      searchCertificationOptions({
        value: currentSearchInput,
        page: R.isEmpty(certificationOptions.items)
          ? 0
          : certificationOptions.page + 1,
        prevItems: certificationOptions.items,
      }),
    [currentSearchInput, certificationOptions, searchCertificationOptions]
  );

  const onChangeInputValue = useCallback(
    (value) => {
      setCertificationOptions(DEFAULT_CERTIFICATION_OPTIONS);
      setCurrentSearchInput(value);
      if (value) {
        setIsOptionsLoading(true);
        searchCertificationOptions({ value });
      }
    },
    [searchCertificationOptions]
  );

  const selectCertificateValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        const onChange = props.onChange
          ? props.onChange
          : (value: any) => form.setFieldValue(field.name, value);
        return (
          <SelectRenderer
            isLoading={isOptionsLoading}
            explanation={phrases.FREEFORM_CERTIFICATE_EXPLANATION}
            isDisabled={isReadOnly}
            getSingleValueTooltipProps={
              certificationCardConfig.getStackItemTooltipProps
            }
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            {...props}
            getDropDownItemTooltipProps={
              certificationCardConfig.getSelectDropDownOptionTooltipProps
            }
            inputRegex={certificationTitleRegex}
            isClearable
            onChange={onChange}
            options={certificationOptions.items}
            infiniteScrollOptions={{
              hasMore:
                certificationOptions.items.length < certificationOptions.total,
              threshold: 150,
              loadMore: loadMoreOptions,
            }}
            onInputChange={onChangeInputValue}
            autoFocus={props.focusField || !field.value}
            filterOption={optionsFilter}
            openMenuOnClick={false}
            openMenuOnFocus={false}
            multilineInput
            creatable
          />
        );
      },
    [
      isOptionsLoading,
      isReadOnly,
      certificationCardConfig.getStackItemTooltipProps,
      certificationCardConfig.getSelectDropDownOptionTooltipProps,
      certificationOptions,
      loadMoreOptions,
      onChangeInputValue,
      optionsFilter,
    ]
  );

  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      changeCardImportance={changeCardImportance}
      stackItemControl={selectCertificateValueControl}
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const certificationTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];
              return certificationTitlesList
                .slice(0, 1)
                .map((val: FormSelectEntity) => {
                  return (
                    <Field
                      key={R.prop('value', val) || 'new'}
                      name={`${namePrefix}.${topStackListName}.0`}
                    >
                      {({ field, form }: FieldProps) => (
                        <div
                          ref={mainCriteriaNameFieldRef}
                          className={cardCommonStyles.titleFieldWithImportance}
                        >
                          {selectCertificateValueControl({
                            field,
                            form,
                            isDisabled: isImportanceSectionOpened || isReadOnly,
                            onKeyDown: getOnMainCardKeyDownCallback(
                              field,
                              arrayHelpers,
                              getTopStackSize(values) < MAX_CARDS_IN_STACK
                            ),
                            focusField: !isReadOnly,
                            onChange: async (value: { label: string }) => {
                              await form.setFieldValue(field.name, value);
                              updateMainFieldHandler(value);
                            },
                            getSingleValueTooltipProps:
                              certificationCardConfig.getMainCardTooltipProps,
                          })}
                          {importanceIndicator}
                        </div>
                      )}
                    </Field>
                  );
                });
            }}
          </FieldArray>
        </>
      )}
    </CriteriaEditCard>
  );
};

type CertificationCriteriaCardProps = {
  namePrefix: string;
  cardData: CertificationCriteriaData;
  specializations: string[];
  onUpdate: () => void;
  onRemove: (value?: string | number) => void;
  changeCardImportance: (
    criteria: CertificationCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;

  onRemoveStackItem: (value: string | number, list?: string) => void;

  optionsFilter: (value: any) => boolean;
  importanceSectionOrder: number;
  handleDrop: (id: string | number, itemId: string | number) => void;
  handleSingleCriteriaDrop: (
    id: string | number,
    itemId: string | number,
    groupId: string | number
  ) => void;
  isReadOnly?: boolean;
};

export const CertificationCriteriaCard: React.FC<
  CertificationCriteriaCardProps
> = ({
  namePrefix,
  cardData,
  specializations,
  importanceSectionOrder,
  changeCardImportance,
  optionsFilter,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  handleDrop,
  handleSingleCriteriaDrop,
  isReadOnly = false,
}) => {
  const cardTitle = getMainCardTitle(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const stackSize = getCardStackSize(cardData);
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const isNewCertification = !getMainCard(cardData);
  const initialCardStatus = isNewCertification
    ? InitialCardStatusEnum.IsNewEdit
    : InitialCardStatusEnum.ExistingCard;

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: !isNewCertification,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCertification}>
        {isCardInViewMode(cardState) ? (
          <StateContext.Consumer>
            {({ states }: any) => {
              const canDrawMainCardFromStack =
                stackSize > 0 && states.isStackOpened;
              return (
                <DraggableCertificationCriteriaCard
                  title={cardTitle}
                  stackSize={stackSize}
                  stackItems={[topStackItems]}
                  cardClasses={{
                    footerClass: cardCommonStyles.experienceYears,
                  }}
                  importance={cardImportance}
                  cardLabel={cardLabel}
                  criteria={cardData}
                  importanceSectionOrder={importanceSectionOrder}
                  onRemove={onRemove}
                  onRemoveStackItem={onRemoveStackItem}
                  handleDrop={handleDrop}
                  handleSingleCriteriaDrop={handleSingleCriteriaDrop}
                  canDrawMainCardFromStack={canDrawMainCardFromStack}
                  initialCardStatus={cardData.initialCardStatus}
                />
              );
            }}
          </StateContext.Consumer>
        ) : (
          <CertificationCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            specializations={specializations}
            optionsFilter={optionsFilter}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
CertificationCriteriaCard.displayName = 'CertificationCriteriaCard';
