import React from 'react';

export const attachContextToReactComponent = (
  component: unknown,
  contextValue: unknown
) => {
  // check if component is React component and not a dom element
  if (React.isValidElement(component) && typeof component.type !== 'string') {
    return React.cloneElement<Record<any, any>>(component, {
      contextValue,
    });
  } else if (typeof component === 'function') {
    return component(contextValue);
  } else {
    return component;
  }
};
