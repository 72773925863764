export enum CandidateProfileMatchingCardState {
  initial = 'initial',
  disabled = 'disabled',
  visibleBackground = 'visibleBackground',
  visibleChangeStatusControls = 'visibleChangeStatusControls',
}

export enum CandidateProfileMatchingCardActions {
  showInitial = 'SHOW_INITIAL',
  showBackground = 'SHOW_BACKGROUND',
  showChangeStatusControls = 'SHOW_CHANGE_STATUS_CONTROLS',
  disableCard = 'DISABLE_CARD',
}

export const CandidateProfileMatchingCardStateConfig = {
  [CandidateProfileMatchingCardState.initial]: {
    [CandidateProfileMatchingCardActions.showBackground]:
      CandidateProfileMatchingCardState.visibleBackground,
    [CandidateProfileMatchingCardActions.showChangeStatusControls]:
      CandidateProfileMatchingCardState.visibleChangeStatusControls,
    [CandidateProfileMatchingCardActions.disableCard]:
      CandidateProfileMatchingCardState.disabled,
  },
  [CandidateProfileMatchingCardState.visibleBackground]: {
    [CandidateProfileMatchingCardActions.showInitial]:
      CandidateProfileMatchingCardState.initial,
    [CandidateProfileMatchingCardActions.showChangeStatusControls]:
      CandidateProfileMatchingCardState.visibleChangeStatusControls,
  },
  [CandidateProfileMatchingCardState.visibleChangeStatusControls]: {
    [CandidateProfileMatchingCardActions.showInitial]:
      CandidateProfileMatchingCardState.initial,
  },
  [CandidateProfileMatchingCardState.disabled]: {
    [CandidateProfileMatchingCardActions.showInitial]:
      CandidateProfileMatchingCardState.initial,
  },
};
