import React from 'react';
import R from '@air/third-party/ramda';
import * as sharedPhrases from '@air/constants/phrases';
import * as phrases from 'constants/phrases';
import {
  BaseRedFlagDataStackItem,
  EducationDiscrepancyFlagData,
  GapsRedFlagDataStackItem,
  isDisqualificationFlag,
  isDisqualificationResolution,
  isExplanationResolution,
  isGeneralRedFlag,
  isPiplWarning,
  isWarningResolution,
  LongTermRedFlagDataStackItem,
  NormalizedDiscrepancyField,
  NormalizedDiscrepancyInfo,
  RedFlagData,
  RedFlagDataStackItem,
  UnknownCompanyRedFlagData,
  EmploymentDataDiscrepancyRedFlagData,
  EducationDiscrepancyRedFlagData,
  NoCareerProgressRedFlagData,
  MissingMonthsRedFlagData,
  TooManyJobsRedFlagData,
  TooManySkillsRedFlagData,
  EmploymentHistoryGapRedFlagData,
} from '@air/domain/RedFlags';
import {
  NoCareerProgressReason,
  PeriodAlertDetails,
  PeriodInfo,
  RedFlagAlertType,
} from '@air/api';
import { Card, PeriodGapsTitle, SvgIcon } from '@air/components';
import { CardSize } from '@air/components/Card/Card';

import styles from 'components/CardWrappers/CandidateProfileWarningCard/candidateProfileWarningCardHelpers.css';

import classNames from 'classnames';
import { convertPeriodGapInfoToString } from '@air/domain/Common/PeriodConverter';
import {
  ApproximateExperiencePeriod,
  ApproximatePeriodType,
} from 'air-shared/components/Period/Period';

export const getCareerProgressReason = (
  reason: NoCareerProgressReason
): string => {
  if (reason === NoCareerProgressReason.SAMELEVELPOSITION) {
    return sharedPhrases.SAME_LEVEL_POSITION;
  } else if (reason === NoCareerProgressReason.SENIORITYDOWNGRADE) {
    return sharedPhrases.SENIORITY_DOWNGRADE;
  }
};

export const getExplanationResolutionIcon = (cardData: RedFlagData) => {
  if (isGeneralRedFlag(cardData)) {
    return (
      <SvgIcon
        className={styles.warningCardIcon}
        icon="red-flag-empty"
        width="2em"
        height="1em"
      />
    );
  }
};

export const getDisqualificationIcon = (cardData: RedFlagData) => {
  if (isGeneralRedFlag(cardData)) {
    return (
      <SvgIcon
        className={styles.warningCardIcon}
        icon="flag"
        width="2em"
        height="1em"
      />
    );
  }
};

export const getCardIcon = (cardData: RedFlagData) => {
  if (isWarningResolution(cardData) && !isDisqualificationFlag(cardData)) {
    return (
      <SvgIcon
        className={classNames(styles.warningCardIcon, styles.warningResolution)}
        icon="warning"
        width="2em"
        height="2em"
      />
    );
  }

  if (isExplanationResolution(cardData) && !isDisqualificationFlag(cardData)) {
    return getExplanationResolutionIcon(cardData);
  }

  if (
    isDisqualificationResolution(cardData) ||
    isDisqualificationFlag(cardData)
  ) {
    return getDisqualificationIcon(cardData);
  }
};

export const getCardInitialSize = (cardData: RedFlagData): CardSize => {
  switch (cardData.type) {
    case RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD:
    case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
      return CardSize.tall;
    case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
      return CardSize.wide;
    case RedFlagAlertType.EDUCATIONDATADISCREPANCY:
      return CardSize.enormous;
    default:
      return CardSize.normal;
  }
};

export const getCardStackItems = (
  cardData: RedFlagData
): RedFlagDataStackItem[] => {
  if (isPiplWarning(cardData)) {
    return [cardData.pipl];
  }

  return 'list' in cardData ? cardData.list : [];
};

const getPiplEmploymentTitle = (
  item: Partial<NormalizedDiscrepancyInfo>,
  isStackTitle = false
) => {
  const { position, company } = item;

  const separator =
    company && position ? (
      <span key={0} className={styles.warningCardPiplTitleSeparator}>
        at
      </span>
    ) : null;

  return (
    <Card.Title
      resizeable
      isStackTitle={isStackTitle}
      flexGrow={!isStackTitle}
      className={styles.warningCardPiplTitle}
      title={[position, separator, company]}
    />
  );
};

const convertToSpan = (title: string, idx: number) => {
  if (!title) return null;
  return (
    <span key={idx} className={styles.warningCardPiplEducationTitleItem}>
      {title}
    </span>
  );
};

const getResumeEducationTitle = (
  item: EducationDiscrepancyFlagData,
  isStackTitle = false
) => {
  const { degreeName, majorName, institutionName, graduationStatus, endYear } =
    item;

  const titles = [degreeName, majorName, institutionName]
    .map(convertToSpan)
    .filter(Boolean);

  return [
    !isStackTitle || titles.length > 0 ? (
      <Card.Title
        key={0}
        resizeable={!isStackTitle}
        isStackTitle={isStackTitle}
        flexGrow={!isStackTitle}
        className={styles.warningCardPiplEducationTitleItem}
        title={titles}
      />
    ) : null,
    <div className={styles.warningCardPiplEducationFooter} key={1}>
      <div className={styles.endYear}>{endYear}</div>
      <Card.Footer
        className={classNames(styles.graduationStatus)}
        text={graduationStatus}
      />
    </div>,
  ];
};

export const getCardTitle = (cardData: RedFlagData) => {
  const title = ((cardData: RedFlagData) => {
    switch (cardData.type) {
      case RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD:
      case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
      case RedFlagAlertType.DIPLOMAMILL:
      case RedFlagAlertType.UNKNOWNUNIVERSITY:
      case RedFlagAlertType.UNKNOWNCOMPANY:
        return (cardData as UnknownCompanyRedFlagData).title;
      case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
        return getPiplEmploymentTitle(
          (cardData as EmploymentDataDiscrepancyRedFlagData).resume
        );
      case RedFlagAlertType.EDUCATIONDATADISCREPANCY:
        return getResumeEducationTitle(
          (cardData as EducationDiscrepancyRedFlagData).resume
        );
      default:
        return null;
    }
  })(cardData);

  return typeof title === 'string' ? (
    <Card.Title resizeable className={styles.warningCardTitle} title={title} />
  ) : (
    title
  );
};

export const getCardFooter = (cardData: RedFlagData): React.ReactElement => {
  const footer = ((cardData: RedFlagData) => {
    switch (cardData.type) {
      case RedFlagAlertType.UNIMPRESSIVESKILLS:
      case RedFlagAlertType.BUZZWORDS:
      case RedFlagAlertType.VAGUEWORDS:
      case RedFlagAlertType.TOOMANYSKILLS:
      case RedFlagAlertType.DESCRIPTIONSTOOLONG:
        return phrases.WARNING_CARD_LABEL_NUMBER_OF_MATCHES(
          (cardData as TooManySkillsRedFlagData).cases
        );
      case RedFlagAlertType.GAPSINEMPLOYMENTHISTORY:
        return phrases.WARNING_CARD_LABEL_NUMBER_OF_CASES(
          (cardData as EmploymentHistoryGapRedFlagData).cases
        );
      case RedFlagAlertType.TOOMANYJOBCHANGES:
        return phrases.WARNING_CARD_LABEL_JOB_HOPPING(
          (cardData as TooManyJobsRedFlagData).cases,
          (cardData as TooManyJobsRedFlagData).years
        );
      case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
        return (
          <PeriodGapsTitle
            showMissingDate
            periods={
              (cardData as EmploymentDataDiscrepancyRedFlagData).resume.periods
            }
            className={classNames(styles.warningCardStackItemTitle)}
          />
        );
      case RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD:
        return (
          <PeriodGapsTitle
            showMissingMonths
            periods={(cardData as MissingMonthsRedFlagData).periods}
            className={classNames(styles.warningCardStackItemTitle)}
          />
        );
      case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
      case RedFlagAlertType.UNKNOWNCOMPANY:
        return (
          <Card.Footer
            className={styles.warningCardFooter}
            text={
              <ApproximateExperiencePeriod
                experience={(cardData as UnknownCompanyRedFlagData).experience}
                periodType={ApproximatePeriodType.warning}
              />
            }
          />
        );
      case RedFlagAlertType.NOCAREERPROGRESS:
        return getCareerProgressReason(
          (cardData as NoCareerProgressRedFlagData).reason
        );
    }
  })(cardData);

  return typeof footer === 'string' ? (
    <Card.Footer className={styles.warningCardFooter} text={footer} />
  ) : (
    footer
  );
};

const EnrichmentStackItem = ({
  label,
  item,
  discrepancyFields,
}: {
  label: string;
  discrepancyFields: NormalizedDiscrepancyField[];
  item: NormalizedDiscrepancyInfo;
}) => {
  const filteredItem = R.pick(discrepancyFields, item);

  return (
    <>
      <span className={styles.warningCardStackItemTitleLabel}>{label}</span>
      {R.isEmpty(filteredItem)
        ? null
        : getPiplEmploymentTitle(filteredItem, true)}
    </>
  );
};
const educationPiplLabelMap: {
  [key: string]: string;
} = {
  majorName: phrases.MAJOR_LABEL,
  degreeName: phrases.DEGREE_LABEL,
  institutionName: phrases.INSTITUTION_LABEL,
  endYear: phrases.GRADUATION_YEAR_LABEL,
};

const getEducationPiplLabel = (discrepancyFields: string[]): string => {
  const labels = discrepancyFields.map((field) => educationPiplLabelMap[field]);

  return discrepancyFields.length === 1
    ? phrases.PIPL_COMPOSITE_STACK_LABEL(labels[0])
    : phrases.PIPL_STACK_LABEL;
};

const getPiplEducationTitle = (
  item: EducationDiscrepancyFlagData,
  discrepancyFields: string[],
  isStackTitle = false
) => {
  const visibleFields: {
    [name in keyof Partial<EducationDiscrepancyFlagData>]: string;
  } = Object.entries(item).reduce((acc, [key, value]) => {
    if (value && discrepancyFields.includes(key)) {
      return {
        ...acc,
        [key]: value,
      };
    } else {
      return acc;
    }
  }, {});

  const titles = [
    visibleFields.degreeName,
    visibleFields.majorName,
    visibleFields.institutionName,
  ]
    .map(convertToSpan)
    .filter(Boolean);

  return [
    titles.length > 0 ? (
      <Card.Title
        key={0}
        resizeable={!isStackTitle}
        isStackTitle={isStackTitle}
        flexGrow={!isStackTitle}
        className={styles.warningCardPiplEducationTitleItem}
        title={titles}
      />
    ) : null,
    visibleFields.endYear ? (
      <div
        className={classNames(styles.warningCardPiplEducationFooter, {
          [styles.onlyYearVisible]: titles.length === 0,
        })}
        key={1}
      >
        <div className={styles.endYear}>{visibleFields.endYear}</div>
        <Card.Footer
          className={styles.graduationStatus}
          text={item.graduationStatus}
        />
      </div>
    ) : null,
  ];
};

const EducationDiscrepancyStackItem = ({
  item,
  discrepancyFields,
}: {
  item: EducationDiscrepancyFlagData;
  discrepancyFields: string[];
}) => {
  return (
    <>
      <span className={styles.warningCardStackItemTitleLabel}>
        {getEducationPiplLabel(discrepancyFields)}
      </span>
      {discrepancyFields.length > 0 &&
        getPiplEducationTitle(item, discrepancyFields, true)}
    </>
  );
};

export const getCardStackTitle = (
  cardData: RedFlagData,
  stackItem: RedFlagDataStackItem
) => {
  const title = ((cardData: RedFlagData) => {
    switch (cardData.type) {
      case RedFlagAlertType.GAPSINEMPLOYMENTHISTORY:
        return (
          <PeriodGapsTitle
            showMissingMonths
            className={styles.warningCardStackItemTitle}
            periods={stackItem as PeriodAlertDetails}
          />
        );
      case RedFlagAlertType.TOOMANYJOBCHANGES:
        return (
          (stackItem as BaseRedFlagDataStackItem).title ||
          sharedPhrases.COMPANY_NAME_PLACEHOLDER
        );
      case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
        return (
          <EnrichmentStackItem
            label={phrases.PIPL_STACK_LABEL}
            discrepancyFields={
              (cardData as EmploymentDataDiscrepancyRedFlagData)
                .normalizedDiscrepancyFields
            }
            item={stackItem as NormalizedDiscrepancyInfo}
          />
        );
      case RedFlagAlertType.EDUCATIONDATADISCREPANCY:
        return (
          <EducationDiscrepancyStackItem
            item={stackItem as EducationDiscrepancyFlagData}
            discrepancyFields={
              (cardData as EducationDiscrepancyRedFlagData).discrepancyFields
            }
          />
        );
      default:
        return (stackItem as BaseRedFlagDataStackItem).title;
    }
  })(cardData);

  return typeof title === 'string' ? (
    <Card.Title
      resizeable
      isStackTitle
      className={styles.warningCardStackItemTitle}
      title={title}
    />
  ) : (
    title
  );
};

const convertPeriodGapInfoToApproximatedPeriod = (period: PeriodInfo) => {
  if (!period.approx) {
    return convertPeriodGapInfoToString(period);
  }

  return (
    <Card.Footer
      className={styles.warningCardFooter}
      text={
        <ApproximateExperiencePeriod
          experience={period}
          periodType={ApproximatePeriodType.warning}
        />
      }
    />
  );
};

export const getCardStackFooter = (
  cardData: RedFlagData,
  stackItem: RedFlagDataStackItem = null
) => {
  const footer = ((cardData: RedFlagData) => {
    switch (cardData.type) {
      case RedFlagAlertType.GAPSINEMPLOYMENTHISTORY:
        return convertPeriodGapInfoToString(
          (stackItem as GapsRedFlagDataStackItem).period
        );
      case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
        return convertPeriodGapInfoToApproximatedPeriod(
          (stackItem as LongTermRedFlagDataStackItem).period
        );
      case RedFlagAlertType.TOOMANYJOBCHANGES:
        return convertPeriodGapInfoToApproximatedPeriod(
          (stackItem as LongTermRedFlagDataStackItem).period
        );
      case RedFlagAlertType.UNKNOWNCOMPANY:
        if ((cardData as UnknownCompanyRedFlagData).list.length > 1) {
          return convertPeriodGapInfoToApproximatedPeriod(
            (stackItem as LongTermRedFlagDataStackItem).period
          );
        }
        return null;
      case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
        return (
          cardData as EmploymentDataDiscrepancyRedFlagData
        ).normalizedDiscrepancyFields.includes(
          NormalizedDiscrepancyField.period
        ) ? (
          <PeriodGapsTitle
            showMissingDate
            highlightedFields={
              (cardData as EmploymentDataDiscrepancyRedFlagData)
                .discrepancyFields
            }
            periods={(stackItem as NormalizedDiscrepancyInfo).periods}
            className={classNames(styles.warningCardStackItemTitle)}
          />
        ) : null;
      default:
        return null;
    }
  })(cardData);

  return typeof footer === 'string' ? <Card.Footer text={footer} /> : footer;
};
