import { useEffect } from 'react';
import { useCacheContext, useCacheMethods } from '@air/providers/CacheProvider';
import { cacheSelectors } from 'selectors';
import R from '@air/third-party/ramda';
import * as InterviewApi from 'features/InterviewSection/interviewApi';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

export const useMatchMinerRejectionReasons = () => {
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const mmRejectionReasons = useCacheContext(cacheSelectors.mmRejectionReasons);
  const { updateCache } = useCacheMethods();

  useEffect(() => {
    if (!mmRejectionReasons) {
      InterviewApi.getMatchMinerRejectionReasons(dataSourceId).fork(
        R.noop,
        (reasons) => {
          updateCache({ mmRejectionReasons: reasons });
        }
      );
    }
  }, [mmRejectionReasons, updateCache, dataSourceId]);

  return { mmRejectionReasons };
};
