import React from 'react';
import classNames from 'classnames';
import { DataSourceType } from '@air/api';
import * as phrases from '@air/constants/phrases';
import { FormViewTitle } from 'components/FormView/FormView';
import { Button } from '@air/components';
import { ATSNames } from 'domain/ATS/ATSMetadata';

import styles from './SignupFormSteps.css';
import formViewStyles from 'components/FormView/FormView.css';

export const LeverIntegrationFailure: React.FC<{
  onRetry: () => void;
}> = ({ onRetry }) => {
  return (
    <div>
      <img className={styles.logoImage} src="/assets/images/lever_logo.png" />
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_FAILURE_TITLE}
        description={phrases.getATSIntegrationFailureDescription(
          ATSNames[DataSourceType.LEVERATS]
        )}
      />
      <div className={formViewStyles.formFieldExtra}>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          className={classNames(formViewStyles.actionButton)}
          onClick={onRetry}
        >
          {phrases.ATS_INTEGRATION_RETRY_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
LeverIntegrationFailure.displayName = 'LeverIntegrationFailure';
