import React, { useState, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from '@air/third-party/toast';
import * as userApi from 'domain/UserManagement/userApi';
import R from '@air/third-party/ramda';
import { useDebounce, useEqualContextSelector } from '@air/utils/hooks';
import {getErrorDescription} from '@air/utils/errorHandling';
import {
  CustomerCreateRequest,
  CustomerResponse,
  CustomerUpdateRequest,
  LoggedInCustomerInfoResponse,
  RoleName,
  ApiErrorResponse,
  InternalStatus,
} from '@air/api';
import {
  UserData,
  UserInfo,
  NewUserInfo,
  UpdatedUserInfo,
} from 'domain/UserManagement/User';
import * as phrases from 'constants/phrases';
import * as appConstants from 'constants/app';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';
import { useContextSelector, createContext } from 'use-context-selector';
import * as sharedPhrases from "@air/constants/phrases";

export type UserManagementContextT = {
  users: UserInfo[];
  total: number;
  methods: {
    fetchUsers: (
      filter?: string,
      roles?: RoleName[],
      statuses?: InternalStatus[]
    ) => void;
    resetPassword: (email: string) => void;
    createUser: (userData: NewUserInfo) => Promise<void>;
    editUser: (customerId: number, userData: UpdatedUserInfo) => Promise<void>;
  };
};

export const UserManagementContext = createContext<UserManagementContextT>({
  users: [],
  total: null,
  methods: {
    resetPassword: null,
    fetchUsers: null,
    createUser: null,
    editUser: null,
  },
});

UserManagementContext.displayName = 'UserManagementContext';

type Props = RouteComponentProps;

export const UserManagementProvider: React.FC<Props> = ({ children }) => {
  const currentUser = useCustomerProfileContext(customerProfileSelectors.user);

  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(null);

  const resetPassword = useDebounce(
    (email: string) => {
      return userApi.resetPassword(email).fork(R.noop, R.noop);
    },
    appConstants.REQUEST_DEBOUNCE_TIME,
    true
  );

  const fetchUsers = useCallback(
    (filter = '', role = [], status = []) => {
      userApi
        .getUsers(
          currentUser as LoggedInCustomerInfoResponse,
          filter,
          role,
          status
        )
        .fork(
          () => {},
          (userData: UserData | null) => {
            if (!userData) return;

            setUsers(userData.users);
            setTotal(userData.total);
          }
        );
    },
    [currentUser]
  );

  const createUser = useCallback((userData: NewUserInfo): Promise<void> => {
    const userRequest: CustomerCreateRequest = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      role: userData.role,
    };
    return userApi.addNewUser(userRequest).fork(
      (err: ApiErrorResponse) => {
        toast.warn(
          getErrorDescription(err, phrases.CREATE_USER_REQUEST_WARNING)
        );
        return Promise.reject(err);
      },
      (user: CustomerResponse) => {
        toast.success(
          phrases.CREATE_USER_REQUEST_SUCCESS(user.firstName, user.lastName)
        );
      }
    );
  }, []);

  const editUser = useCallback(
    (customerId: number, userData: UpdatedUserInfo) => {
      const userRequest: CustomerUpdateRequest = {
        customerId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        role: userData.role,
        internalStatus: userData.status,
      };
      return userApi.updateUser(userRequest).fork(
        (err: ApiErrorResponse) => {
          toast.error(err?.details?.description || sharedPhrases.GENERAL_ERROR_TRY_AGAIN);
        },
        () => {}
      );
    },
    []
  );

  const contextValue = useMemo<UserManagementContextT>(
    () => ({
      users,
      total,
      methods: {
        fetchUsers,
        resetPassword,
        createUser,
        editUser,
      },
    }),
    [users, total, fetchUsers, resetPassword, createUser, editUser]
  );

  return (
    <UserManagementContext.Provider value={contextValue}>
      {children}
    </UserManagementContext.Provider>
  );
};

export const useUserManagementContext = <Selected,>(
  selector: (state: UserManagementContextT) => Selected
) => {
  return useContextSelector(UserManagementContext, selector);
};

export const useUserManagementMethods = () => {
  return useEqualContextSelector(
    UserManagementContext,
    (state: UserManagementContextT) => state.methods,
    R.shallowEqualObjects
  );
};

UserManagementProvider.displayName = 'UserManagementProvider';
