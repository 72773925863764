import R from '@air/third-party/ramda';
import { makeDraggableComponent } from 'components/CustomDragLayer/CustomDragLayer';
import * as phrases from 'constants/phrases';
import {
  SearchCriteriaImportanceEnum,
  GraduationStatusEnum,
  SearchCriteriaAlertType,
  SearchCriteriaMatchStatusEnum,
  SearchCriteriaResponse,
} from '@air/api';
import React from 'react';
import { Card } from '@air/components';
import {
  CandidateDegreeData,
  CandidateInstitutionData,
  CandidateMajorData,
  CandidateEducationCriteriaListItem,
} from 'domain/CandidateData';
import { getCardTitle } from 'domain/SearchCriteria/cardHelpers';
import styles from 'components/Cards/educationCommonStyles.css';
import classNames from 'classnames';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import { hasInactiveAlert } from 'components/Cards/cardsCommonCode';

type EducationCriteriaCardProps = {
  title: string;
  id: string;
  searchOrder: number;
  importanceSectionOrder: number;
  criteria: SearchCriteriaResponse;
  onDragStart: () => void;
  onDragEnd: () => void;
  importance: SearchCriteriaImportanceEnum;
  cardClasses: { [key: string]: string };
};

const getEducationFieldsForMatchedCases = (
  mainCard: CandidateEducationCriteriaListItem,
  allCardData:
    | CandidateMajorData
    | CandidateDegreeData
    | CandidateInstitutionData
): Array<JSX.Element | null> => {
  const { graduation } = allCardData;
  const alertMessage = graduation.alert?.displayName;
  const inactiveAlert =
    hasInactiveAlert<CandidateEducationCriteriaListItem>(mainCard) ||
    ([
      SearchCriteriaMatchStatusEnum.IDEAL,
      SearchCriteriaMatchStatusEnum.ACCEPTABLE,
    ].includes(mainCard.status) &&
      !!graduation.alert);

  return [
    <Card.Title
      resizeable
      flexGrow={false}
      title={getCardTitle({
        cardType: allCardData.cardType,
        label: mainCard.label,
      })}
      key="card-title"
    />,
    !!graduation.label && (
      <Card.Footer
        className={styles.candidateCardGraduationYear}
        text={allCardData.graduation.label}
        key="card-year"
      />
    ),
    !!alertMessage && (
      <Card.Alert
        key="card-year-alert"
        className={classNames(styles.candidateCardGraduationYearAlert, {
          [cardCommonStyles.inactiveAlert]: inactiveAlert,
        })}
      >
        {alertMessage}
      </Card.Alert>
    ),
    !alertMessage &&
      graduation.graduationStatus == GraduationStatusEnum.GRADUATED && (
        <Card.Footer
          className={styles.candidateCardGraduationYearLabel}
          text={graduation.graduationStatus}
          key="card-graduated-status"
        />
      ),
  ];
};

const getEducationFieldsForMismatchedCases = (
  mainCard: CandidateEducationCriteriaListItem,
  allCardData:
    | CandidateMajorData
    | CandidateDegreeData
    | CandidateInstitutionData
): Array<JSX.Element | null> => {
  const { graduation } = allCardData;
  return [
    <Card.Title
      resizeable
      flexGrow={false}
      title={getCardTitle({
        cardType: allCardData.cardType,
        label: mainCard.label,
      })}
      key="card-title"
    />,
    <Card.Alert
      key="card-alert"
      className={classNames(styles.candidateCardGraduationYearLabel, {
        [cardCommonStyles.inactiveAlert]: hasInactiveAlert<
          CandidateEducationCriteriaListItem
        >(mainCard),
      })}
    >
      {mainCard.alert.displayName}
    </Card.Alert>,
    !!graduation.label && (
      <Card.Footer
        className={styles.candidateCardGraduationYear}
        text={`${allCardData.graduation.label}${
          graduation.alert?.name ===
          SearchCriteriaAlertType.EDUCATIONCURRENTYEARGRADUATEDETAILSMISSED
            ? '?'
            : ''
        }`}
        key="card-year"
      />
    ),
    R.includes(graduation.graduationStatus, [
      GraduationStatusEnum.GRADUATED,
      GraduationStatusEnum.EXPECTED,
    ]) && (
      <Card.Footer
        className={styles.candidateCardGraduationYearLabel}
        text={graduation.graduationStatus}
        key="card-graduated-status"
      />
    ),
  ];
};

export const getEducationFieldsForCandidateCollapsedMainCard = (
  mainCard: CandidateEducationCriteriaListItem,
  allCardData:
    | CandidateMajorData
    | CandidateDegreeData
    | CandidateInstitutionData
): Array<JSX.Element | null> => {
  if (R.isNil(mainCard.alert)) {
    return getEducationFieldsForMatchedCases(mainCard, allCardData);
  } else {
    return getEducationFieldsForMismatchedCases(mainCard, allCardData);
  }
};

export const makeDraggableEducationCard = (card: any, type: any) =>
  makeDraggableComponent<EducationCriteriaCardProps, { id: string }>(
    card,
    type,
    {
      beginDrag: ({
        title,
        id,
        criteria,
        searchOrder: order,
        importanceSectionOrder,
        importance,
        onDragStart = R.noop,
        cardClasses,
      }) => {
        onDragStart();
        return {
          criteria,
          importance,
          beginDragClass: cardClasses?.beginDragClass,
          importanceSectionOrder,
          title: title || phrases.NO_NAME_CRITERIA,
          id,
          order,
          type,
        };
      },
      endDrag: ({ onDragEnd = R.noop }) => {
        onDragEnd();
      },
    }
  );
