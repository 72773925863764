import React, { useMemo } from 'react';
import R from '@air/third-party/ramda';
import { Field, FieldArray, FieldProps } from 'formik';
import { useStateMachine } from '@air/hooks';

import { FormSelectEntity } from '@air/components/Select/typings';

import * as phrases from 'constants/phrases';

import { Card } from 'components';
import { SelectRenderer } from '@air/components';

import { SearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';

import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';

import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import { makeDraggableEducationCard } from 'components/Cards/educationCommonCode';
import {
  CriteriaCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { withCustomDrag } from 'components/CustomDragLayer/CustomDragLayer';
import commonCardStyles from 'components/Cards/cardsCommonStyles.css';
import {
  getOnMainCardKeyDownCallback,
  getOnStackCardKeyDownCallback,
  MAX_CARDS_IN_STACK,
} from 'components/Cards/cardsCommonCode';
import {
  CardType,
  getImportanceValue,
  MajorCriteriaData,
} from 'domain/SearchCriteria';
import {
  getMainCardTitle,
  getStackToggleItemsClasses,
  getTopStackSize,
  getBottomStackSize,
  getAddingLimits,
  getCardStackSize,
  getCardCriterionLabel,
  getTopStackListName,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import {
  generateCriteriaCardTopStackItems,
  generateCriteriaCardBottomStackItems,
} from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import {
  generateTopStackItems,
  generateBottomStackItems,
  CriteriaEditCardStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

const DraggableMajorCriteriaCard = makeDraggableEducationCard(
  withCustomDrag(CriteriaCollapsedCard),
  DraggedSearchCriteriaCardType.major
);

DraggableMajorCriteriaCard.displayName = 'DraggableMajorCriteriaCard';

export const getMajorReadOnlyFields = (
  card: MajorCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
  ];
};

const MajorCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: MajorCriteriaData;
  changeCardImportance: (
    criteria: MajorCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  optionsFilter: (value: any) => boolean;
  options: any[];
  isReadOnly?: boolean;
}> = ({
  namePrefix,
  values,
  optionsFilter,
  options,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const majorCardConfig = cardsConfig[CardType.major];
  const topStackListName = getTopStackListName(values);
  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const stackSize = getTopStackSize(values) + getBottomStackSize(values);

  const maxListSize = Math.min(MAX_CARDS_IN_STACK, options.length);

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(
    values,
    maxListSize
  );

  const selectMajorValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        return (
          <SelectRenderer
            isDisabled={isReadOnly}
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            getSingleValueTooltipProps={
              majorCardConfig.getStackItemTooltipProps
            }
            {...props}
            getDropDownItemTooltipProps={
              majorCardConfig.getSelectDropDownOptionTooltipProps
            }
            autoFocus={props.focusField || !field.value}
            hideSelectedOptions
            multilineInput
            isClearable
            filterOption={optionsFilter}
            onChange={(value: any) => form.setFieldValue(field.name, value)}
            options={options}
          />
        );
      },
    [options, optionsFilter, isReadOnly, majorCardConfig]
  );

  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      changeCardImportance={changeCardImportance}
      stackItemControl={selectMajorValueControl}
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const idealTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];
              const mainField = R.head(idealTitlesList);

              return (
                <Field
                  key={R.prop('value', mainField) || 'new'}
                  name={`${namePrefix}.${topStackListName}.0`}
                >
                  {({ field, form }: FieldProps) => (
                    <div
                      className={commonCardStyles.titleFieldWithImportance}
                      ref={mainCriteriaNameFieldRef}
                    >
                      {selectMajorValueControl({
                        field,
                        form,
                        placeholder:
                          stackSize === 0
                            ? phrases.ANY_MAJOR_LABEL
                            : phrases.SELECT_PLACEHOLDER,
                        isDisabled: isImportanceSectionOpened || isReadOnly,
                        onChange: async (value: { label: string }) => {
                          form.setFieldValue(field.name, value);
                          updateMainFieldHandler(value);
                        },
                        focusField: !isReadOnly,
                        onKeyDown: getOnMainCardKeyDownCallback(
                          field,
                          arrayHelpers,
                          canAddNewStackItem
                        ),
                        getSingleValueTooltipProps:
                          majorCardConfig.getMainCardTooltipProps,
                      })}
                      {importanceIndicator}
                    </div>
                  )}
                </Field>
              );
            }}
          </FieldArray>
        </>
      )}
    </CriteriaEditCard>
  );
};

export const MajorCriteriaCard: React.FC<any> = ({
  cardData,
  namePrefix,
  importance,
  importanceSectionOrder,
  optionsFilter,
  options,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const bottomStackItems = generateCriteriaCardBottomStackItems(cardData);
  const stackSize = getCardStackSize(cardData);
  const cardTitle = getMainCardTitle(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const searchOrder = cardData.idx;
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: true, // TODO: refactor this flag for major card
      isReadOnly,
    }
  );

  const cardMethods = { onUpdate, onRemove };
  const stackClasses = getStackToggleItemsClasses(cardData);

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <DraggableMajorCriteriaCard
            criteria={cardData}
            id={cardData.key}
            title={cardTitle}
            stackItems={[topStackItems, bottomStackItems]}
            stackClasses={stackClasses}
            stackSize={stackSize}
            cardLabel={cardLabel}
            onRemove={onRemove}
            onRemoveStackItem={onRemoveStackItem}
            importanceSectionOrder={importanceSectionOrder}
            searchOrder={searchOrder}
            importance={importance}
            initialCardStatus={cardData.initialCardStatus}
          />
        ) : (
          <MajorCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            changeCardImportance={changeCardImportance}
            options={options}
            optionsFilter={optionsFilter}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
MajorCriteriaCard.displayName = 'MajorCriteriaCard';
