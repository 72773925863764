import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  TooltipWrapper,
  Toggle,
  FormDropdown,
} from '@air/components';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import { ButtonVariants } from '@air/components/Button/Button';

import styles from './CandidateStatusesListForm.css';

import { QuestioningStatusesValues } from 'domain/CandidateData/CandidateQuestioningData';
import { FormSelectEntity } from '@air/components/Select/typings';
import { CLICK_DEBOUNCE_TIME_LONG } from '@air/constants/app';

export type CandidateStatusesListFormSettings = {
  enable: boolean;
  values: QuestioningStatusesValues;
  isReminderChecked: boolean;
  remindInDays: number;
};

const REMINDER_OPTIONS: FormSelectEntity[] = [
  { label: phrases.REMINDER_OPTION_1_DAY, value: 1 },
  { label: phrases.REMINDER_OPTION_3_DAY, value: 3 },
  { label: phrases.REMINDER_OPTION_5_DAY, value: 5 },
  { label: phrases.REMINDER_OPTION_7_DAY, value: 7 },
  { label: phrases.REMINDER_OPTION_10_DAY, value: 10 },
];

const REMINDER_DEFAULT_VALUE = R.head(REMINDER_OPTIONS);
const FORM_ID = 'autosendingForm';

export const CandidateStatusesListForm: React.FC<{
  settings: CandidateStatusesListFormSettings;
  buttonText: string;
  buttonVariant?: ButtonVariants;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  isReminderDisabled?: boolean;
  disabledSubmitTooltip?: string;
  onSubmit?: (
    formValues: CandidateStatusesListFormSettings,
    setLoadingState?: boolean
  ) => void;
}> = ({
  settings,
  buttonText,
  isLoading,
  buttonVariant = Button.variants.POSITIVE_CONFIRM,
  isSubmitDisabled,
  isReminderDisabled,
  disabledSubmitTooltip,
  onSubmit,
}) => {
  const [formValues, setFormValues] = useState(settings);

  const { values, enable, isReminderChecked, remindInDays } = formValues;

  const remindInDaysOption =
    R.find((it) => it.value === remindInDays, REMINDER_OPTIONS) ||
    REMINDER_DEFAULT_VALUE;

  useEffect(() => {
    setFormValues(settings);
  }, [settings]);

  const onFormSubmit = useCallback(() => {
    const newFormValues = {
      ...formValues,
      enable: !enable,
      values,
    };
    setFormValues(newFormValues);
    onSubmit?.(newFormValues);
  }, [enable, formValues, onSubmit, values]);

  const isNothingSelected = useMemo(() => {
    return !(values || []).some((it) => it.checked);
  }, [values]);

  const onChange = useCallback(({ checked, label }) => {
    setFormValues(({ values, ...state }) => ({
      ...state,
      values: values.map((checkbox) => {
        if (checkbox.label === label)
          return {
            ...checkbox,
            checked,
          };
        return checkbox;
      }),
    }));
  }, []);

  const isButtonDisabled = isSubmitDisabled || isNothingSelected;

  const submitTooltip = useMemo(() => {
    if (isNothingSelected) {
      return phrases.QUESTIONNAIRE_FORM_SUBMIT_DISABLED_TOOLTIP;
    } else if (isSubmitDisabled && disabledSubmitTooltip) {
      return disabledSubmitTooltip;
    } else {
      return '';
    }
  }, [isSubmitDisabled, disabledSubmitTooltip, isNothingSelected]);

  const onChangeReminderSwitcher = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues((state) => {
      const newFormValues = {
        ...state,
        isReminderChecked: event.target.checked,
      };
      onSubmit?.(newFormValues, false);
      return newFormValues;
    });
  };

  const onChangeReminderDropdown = (selectedOption: FormSelectEntity) => {
    setFormValues((state) => {
      const newFormValues = {
        ...state,
        remindInDays: selectedOption.value as number,
      };
      onSubmit?.(newFormValues, false);
      return newFormValues;
    });
  };

  return (
    <>
      <form className={styles.formList} id={FORM_ID}>
        {values.map(({ label, value, checked }) => {
          return (
            <Checkbox
              key={value}
              name={value}
              checked={!!values && checked}
              onChange={onChange}
              label={label}
              disabled={enable || isLoading}
              className={styles.formCheckbox}
            />
          );
        })}
        <div className={styles.reminderWrapper}>
          <label className={styles.reminderSwitcher}>
            <Toggle
              disabled={isReminderDisabled}
              checked={isReminderChecked}
              onChange={onChangeReminderSwitcher}
            />
            {phrases.REMINDER_LABEL}
          </label>
          <FormDropdown
            isBorderOverflown
            isDisabled={!isReminderChecked || isReminderDisabled}
            className={styles.reminderDaysDropdown}
            valueContainerClassName={styles.reminderDaysDropdownValueField}
            value={remindInDaysOption}
            options={REMINDER_OPTIONS}
            onChange={onChangeReminderDropdown}
          />
        </div>
      </form>
      <TooltipWrapper
        enabled={!!submitTooltip}
        tooltip={submitTooltip}
        disabledChildren={isButtonDisabled && !!submitTooltip}
        flexGrow={false}
      >
        <Button
          small
          form={FORM_ID}
          debounceTime={CLICK_DEBOUNCE_TIME_LONG}
          variant={buttonVariant}
          className={styles.formSubmitButton}
          disabled={isButtonDisabled}
          onClick={onFormSubmit}
        >
          {buttonText}
        </Button>
      </TooltipWrapper>
    </>
  );
};

CandidateStatusesListForm.displayName = 'CandidateStatusesListForm';
