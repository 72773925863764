import { AtsExternalProfileInfo } from '@air/api';
import { createContext } from 'use-context-selector';

export enum FILE_ERRORS_CODES {
  TOO_LARGE = 'file-too-large',
  TOO_SMALL = 'file-too-small',
  TOO_MANY_FILES = 'too-many-files',
  INVALID_TYPE = 'file-invalid-type',
  ATS_ERROR = 'ATS-1101',
}

export interface FileError {
  message: string;
  code:
    | FILE_ERRORS_CODES.TOO_LARGE
    | FILE_ERRORS_CODES.TOO_SMALL
    | FILE_ERRORS_CODES.TOO_MANY_FILES
    | FILE_ERRORS_CODES.INVALID_TYPE
    | FILE_ERRORS_CODES.ATS_ERROR
    | string;
}

export interface UploadedFile extends File {
  path?: string;
  lastModified: number;
  lastModifiedDate?: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export type AcceptedFile = UploadedFile;

export interface RejectedFile {
  file: UploadedFile;
  errors: FileError[];
  tempId?: string;
}

export interface RequisitionFile extends AtsExternalProfileInfo {
  tempId?: string;
  atsId?: number;
  requisitionId?: string;
  progress?: number;
  errors?: FileError[];
  size?: number;
  tag?: string;
}

export interface ExtendedRequisitionFile extends RequisitionFile {
  file?: UploadedFile;
}

export interface RequisitionCounters {
  totalCount: number | null;
  pendingCount?: number;
  failedCount?: number;
  uploadedCount?: number;
}

export enum UploadStatus {
  isUploading = 1,
  isPending,
  isUploaded,
  isFailed,
}

export interface Requisition extends RequisitionCounters {
  jobRequisitionId: string;
  files: RequisitionFile[] | null;
}

export type RequisitionsT = {
  [jobRequisitionId: string]: Requisition;
};
export type RequisitionsUploadStateT = {
  [jobRequisitionId: string]: boolean;
};

export type RemoveApplicantsParamsT = {
  atsCandidateId: string;
  tempId: string;
  uploadStatus: UploadStatus;
  atsId: number;
  jobRequisitionId: string;
};

export type UploadApplicantsContextT = {
  currentJobRequisitionId: string;
  requisitionsCounters: Map<string, RequisitionCounters>;
  requisitions: RequisitionsT;
  requisitionsUploadState: RequisitionsUploadStateT;
  uploadToServerState: RequisitionsUploadStateT;
  isUploadInProgress?: boolean;
  isUploadToServerInProgress?: boolean;
  canAddTags: boolean;
  methods: {
    updateRequisitionCounters?: (
      totalCount: number,
      jobRequisitionId: string
    ) => void;
    onDropRejected: (files: RejectedFile[]) => void;
    onDropAccepted: (files: AcceptedFile[], atsId: number) => void;
    onStartUpload: (isPassive: boolean) => void;
    setCurrentJobRequisitionId?: (jobRequisitionId: string) => void;
    getApplicants?: (atsId: number, jobRequisitionId: string) => void;
    removeApplicant?: ({
      atsCandidateId,
      tempId,
      uploadStatus,
      atsId,
    }: RemoveApplicantsParamsT) => void;
    setUploadsStarted?: (
      jobRequisitionId: string,
      isUploadStarted: boolean
    ) => void;
    setAddTagsAbility?: (canAddText?: boolean) => void;
    onDraftDropAccepted: (files: AcceptedFile[], atsId: number) => void;
    setUploadsToServerStarted: (id: string, hasStarted: boolean) => void;
  };
};

export const currentRequisitionDefaultProps: Requisition = {
  jobRequisitionId: null,
  totalCount: 0,
  pendingCount: 0,
  failedCount: 0,
  uploadedCount: 0,
  files: [],
};

export const UploadApplicantsContext = createContext<UploadApplicantsContextT>({
  methods: {
    onDropRejected: null,
    onDropAccepted: null,
    onStartUpload: null,
    onDraftDropAccepted: null,
    setUploadsToServerStarted: null,
  },
  currentJobRequisitionId: null,
  requisitionsCounters: new Map(),
  requisitions: {},
  requisitionsUploadState: {},
  uploadToServerState: {},
  isUploadInProgress: false,
  isUploadToServerInProgress: false,
  canAddTags: false,
});

UploadApplicantsContext.displayName = 'UploadApplicantsContext';
