import { useRef } from 'react';
import { useDrop } from 'react-dnd';

/*
  TODO: Add better typings.
*/
export const useDroppableRef = (spec: any): [React.RefObject<any>, any] => {
  const elementRef = useRef(null);
  const [collectedProps, drop] = useDrop(spec);
  drop(elementRef);

  return [elementRef, collectedProps];
};
