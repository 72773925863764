import React, { useMemo, useCallback, useState } from 'react';
import R from '@air/third-party/ramda';
import { useStateMachine } from '@air/hooks';
import * as phrases from 'constants/phrases';

import { SearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';

import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';

import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import { makeDraggableEducationCard } from 'components/Cards/educationCommonCode';
import {
  CriteriaCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { withCustomDrag } from 'components/CustomDragLayer/CustomDragLayer';
import { Field, FieldArray, FieldProps } from 'formik';
import { FormSelectEntity } from '@air/components/Select/typings';
import commonCardStyles from 'components/Cards/cardsCommonStyles.css';
import { Card, SvgIcon } from 'components';
import { SelectRenderer } from '@air/components';
import {
  getOnMainCardKeyDownCallback,
  getOnStackCardKeyDownCallback,
} from 'components/Cards/cardsCommonCode';
import {
  getImportanceValue,
  InstitutionCriteriaData,
  CardType,
  InstitutionCriteriaListValue,
} from 'domain/SearchCriteria';
import { institutionDataMapper } from '@air/domain/SearchCriteriaCards/InstitutionCard/institutionCriteriaData';
import {
  getMainCardTitle,
  getStackToggleItemsClasses,
  getTopStackSize,
  getBottomStackSize,
  getAddingLimits,
  getCardStackSize,
  getCardCriterionLabel,
  getTopStackListName,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import {
  generateCriteriaCardTopStackItems,
  generateCriteriaCardBottomStackItems,
} from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import {
  generateTopStackItems,
  generateBottomStackItems,
  CriteriaEditCardStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import {
  SearchCriteriaImportanceEnum,
  InstitutionDetailTypeEnum,
} from '@air/api';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import cardsCommonStyles from '@air/domain/SearchCriteriaCards/cardsCommonStyles.css';
import { loadPaginatedInstitutionAsyncOptions } from 'components/SearchCriteriaCards/utils';

const DraggableInstitutionCriteriaCard = makeDraggableEducationCard(
  withCustomDrag(CriteriaCollapsedCard),
  DraggedSearchCriteriaCardType.institution
);
DraggableInstitutionCriteriaCard.displayName =
  'DraggableInstitutionCriteriaCard';

export const getInstitutionReadOnlyFields = (
  card: InstitutionCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
  ];
};

const DEFAULT_INSTITUTION_OPTIONS: {
  items: InstitutionCriteriaListValue[];
  total: number;
  page: number;
} = {
  items: [],
  total: 0,
  page: 0,
};

export const InstitutionCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: InstitutionCriteriaData;
  changeCardImportance: (
    criteria: InstitutionCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  optionsFilter: (value: any) => boolean;
  isReadOnly?: boolean;
}> = ({
  namePrefix,
  values,
  changeCardImportance,
  optionsFilter,
  isReadOnly = false,
}) => {
  const [institutionOptions, setInstitutionOptions] = useState(
    DEFAULT_INSTITUTION_OPTIONS
  );
  const [currentSearchInput, setCurrentSearchInput] = useState('');
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const institutionCardConfig = cardsConfig[CardType.institution];
  const stackSize = getTopStackSize(values) + getBottomStackSize(values);

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(values);

  const topStackListName = getTopStackListName(values);
  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const getOptionClassName = useCallback((data) => {
    return data.extras.type === InstitutionDetailTypeEnum.InstitutionList
      ? cardsCommonStyles.categoryOption
      : '';
  }, []);

  const getDropDownItemOptionIcon = useCallback((data) => {
    return data.extras.type === InstitutionDetailTypeEnum.InstitutionList ? (
      <SvgIcon icon="folder-icon" className={cardsCommonStyles.categoryIcon} />
    ) : null;
  }, []);

  const searchInstitutionOptions = useCallback(
    ({
      value,
      page = 0,
      prevItems = [],
    }: {
      value?: string;
      page?: number;
      prevItems?: InstitutionCriteriaListValue[];
    }) => {
      loadPaginatedInstitutionAsyncOptions(
        {
          value,
          page,
        },
        ({ items, total }) => {
          setInstitutionOptions({
            items: [...prevItems, ...R.map(institutionDataMapper, items)],
            total,
            page,
          });
          setIsOptionsLoading(false);
        }
      );
    },
    []
  );

  const loadMoreOptions = useCallback(
    () =>
      searchInstitutionOptions({
        value: currentSearchInput,
        page: R.isEmpty(institutionOptions.items)
          ? 0
          : institutionOptions.page + 1,
        prevItems: institutionOptions.items,
      }),
    [currentSearchInput, institutionOptions, searchInstitutionOptions]
  );

  const onChangeInputValue = useCallback(
    (value) => {
      setInstitutionOptions(DEFAULT_INSTITUTION_OPTIONS);
      setCurrentSearchInput(value);
      if (value) {
        setIsOptionsLoading(true);
        searchInstitutionOptions({ value });
      }
    },
    [searchInstitutionOptions]
  );

  const selectInstitutionValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        return (
          <SelectRenderer
            isLoading={isOptionsLoading}
            isDisabled={isReadOnly}
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            getDropDownItemOptionIcon={getDropDownItemOptionIcon}
            getSingleValueTooltipProps={
              institutionCardConfig.getStackItemTooltipProps
            }
            {...props}
            getDropDownItemTooltipProps={
              institutionCardConfig.getSelectDropDownOptionTooltipProps
            }
            optionClassName={getOptionClassName}
            autoFocus={props.focusField || !field.value}
            hideSelectedOptions
            multilineInput
            isClearable
            creatable={false}
            filterOption={optionsFilter}
            options={institutionOptions.items}
            infiniteScrollOptions={{
              hasMore:
                institutionOptions.items.length < institutionOptions.total,
              threshold: 150,
              loadMore: loadMoreOptions,
            }}
            onInputChange={onChangeInputValue}
            onChange={(value: any) => form.setFieldValue(field.name, value)}
          />
        );
      },
    [
      isOptionsLoading,
      loadMoreOptions,
      isReadOnly,
      getDropDownItemOptionIcon,
      institutionCardConfig.getStackItemTooltipProps,
      institutionCardConfig.getSelectDropDownOptionTooltipProps,
      getOptionClassName,
      optionsFilter,
      institutionOptions,
      onChangeInputValue,
    ]
  );

  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      changeCardImportance={changeCardImportance}
      stackItemControl={selectInstitutionValueControl}
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const idealTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];
              const mainField = R.head(idealTitlesList);

              return (
                <Field
                  key={R.prop('value', mainField) || 'new'}
                  name={`${namePrefix}.${topStackListName}.0`}
                >
                  {({ field, form }: FieldProps) => (
                    <div
                      className={commonCardStyles.titleFieldWithImportance}
                      ref={mainCriteriaNameFieldRef}
                    >
                      {selectInstitutionValueControl({
                        field,
                        form,
                        isDisabled: isImportanceSectionOpened || isReadOnly,
                        placeholder:
                          stackSize === 0
                            ? phrases.ANY_INSTITUTION_LABEL
                            : phrases.SELECT_PLACEHOLDER,
                        onChange: async (value: { label: string }) => {
                          await form.setFieldValue(field.name, value);
                          updateMainFieldHandler(value);
                        },
                        focusField: !isReadOnly,
                        onKeyDown: getOnMainCardKeyDownCallback(
                          field,
                          arrayHelpers,
                          canAddNewStackItem
                        ),
                        getSingleValueTooltipProps:
                          institutionCardConfig.getMainCardTooltipProps,
                      })}
                      {importanceIndicator}
                    </div>
                  )}
                </Field>
              );
            }}
          </FieldArray>
        </>
      )}
    </CriteriaEditCard>
  );
};

export const InstitutionCriteriaCard: React.FC<any> = ({
  namePrefix,
  cardData,
  importance,
  importanceSectionOrder,
  changeCardImportance,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  optionsFilter,
  isReadOnly = false,
}) => {
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const bottomStackItems = generateCriteriaCardBottomStackItems(cardData);
  const stackSize = getCardStackSize(cardData);
  const cardTitle = getMainCardTitle(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const searchOrder = cardData.idx;
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: true, // TODO: refactor this flag for this card
      isReadOnly,
    }
  );

  const cardMethods = { onUpdate, onRemove };
  const stackClasses = getStackToggleItemsClasses(cardData);

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <DraggableInstitutionCriteriaCard
            criteria={cardData}
            id={cardData.key}
            title={cardTitle}
            stackItems={[topStackItems, bottomStackItems]}
            stackClasses={stackClasses}
            stackSize={stackSize}
            cardLabel={cardLabel}
            onRemove={onRemove}
            onRemoveStackItem={onRemoveStackItem}
            importanceSectionOrder={importanceSectionOrder}
            searchOrder={searchOrder}
            importance={importance}
            initialCardStatus={cardData.initialCardStatus}
          />
        ) : (
          <InstitutionCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            changeCardImportance={changeCardImportance}
            optionsFilter={optionsFilter}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
InstitutionCriteriaCard.displayName = 'InstitutionCriteriaCard';
