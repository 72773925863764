import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  CandidateEducationCriteriaListItem,
  CandidateSkillData,
  CriteriaInterviewStatus,
  findFirstUnreadComment,
  hasComments,
  hasUnreadComment,
  itemHasComments,
  getCardStatus,
  getCardItemStatus,
} from 'domain/CandidateData';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';
import { InterviewDataOptions } from 'domain/CandidateData/CandidateInterviewMapper';
import {
  getCardStackSize,
  getMainCard,
  getValuesForBottomStack,
  getValuesForTopStack,
  getCardTitleClassName,
  getCardCriterionLabel,
} from 'domain/SearchCriteria/cardHelpers';
import {
  mapCandidateMatchingCardStatusClassName,
  mapCandidateMatchingCardStackClassNames,
  mapCandidateMatchingCardBackgroundClassName,
} from 'components/SearchCards/statusesMapper';
import R from '@air/third-party/ramda';
import { Card } from '@air/components';
import classNames from 'classnames';
import styles from './CandidateProfileMatchingCard.css';
import * as phrases from 'constants/phrases';
import { CandidateInterviewNote } from 'components';
import {
  getCardInitialSize,
  getFieldsForCandidateCollapsedMainCard,
  getFieldsForCandidateCollapsedStackCard,
} from 'domain/CandidateData/candidateCardHelpers';
import { SearchCriteriaMatchStatusEnum } from '@air/api';
import { useStateMachine, useDelayedEvent } from '@air/hooks';
import {
  CandidateProfileMatchingCardActions,
  CandidateProfileMatchingCardState,
  CandidateProfileMatchingCardStateConfig,
} from 'components/CardWrappers/CandidateProfileMatchingCard/stateConfig';
import { useOutsideClick } from '@air/utils/hooks';
import cardStyles from '@air/components/Card/Card.css';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CANDIDATE_CARD_CHANGE_STATUS } from 'constants/gaLabels';
import { CARD_BACKGROUND_DELAY_DURATION } from '@air/domain/Common/Cards';

export const getCardBackgroundElements = (
  cardData: BaseCandidateData,
  isQuestioningSent: boolean
) => {
  const cardStatus = getCardStatus(cardData);

  switch (cardStatus) {
    case CriteriaInterviewStatus.ANSWERED:
      return {
        topInfoText: phrases.EVALUATE_INFO_TEXT,
        actionButtonLabel: phrases.WARNING_EVALUATE_CARD_ACTION_BUTTON_TEXT,
      };
    case CriteriaInterviewStatus.ASKED:
      return {
        topInfoText: phrases.ASKED_INFO_TEXT,
        actionButtonLabel: isQuestioningSent
          ? phrases.ACTION_TEXT_ASKED
          : phrases.ACTION_TEXT_UNCLARIFIED,
        activeButtonClass: styles.askedCardButton,
      };
    case SearchCriteriaMatchStatusEnum.IDEAL:
      return {
        topInfoText: phrases.IDEAL_STATUS_INFO_TEXT,
        actionButtonLabel: `#${phrases.ACTION_TEXT_IDEAL}`,
        activeButtonClass: styles.idealActionButton,
      };
    case SearchCriteriaMatchStatusEnum.ACCEPTABLE:
      return {
        topInfoText: phrases.ACCEPTABLE_STATUS_INFO_TEXT,
        actionButtonLabel: `#${phrases.ACTION_TEXT_ACCEPTABLE}`,
        activeButtonClass: styles.acceptableActionButton,
      };
    case SearchCriteriaMatchStatusEnum.DISQUALIFICATION:
      return {
        topInfoText: phrases.MISMATCH_STATUS_INFO_TEXT,
        actionButtonLabel: `#${phrases.ACTION_TEXT_MISMATCH}`,
        activeButtonClass: styles.mismatchActionButton,
      };
    case SearchCriteriaMatchStatusEnum.WARNING:
    case SearchCriteriaMatchStatusEnum.WARNINGMISSED:
      return {
        topInfoText: phrases.MISMATCH_STATUS_INFO_TEXT,
        actionButtonLabel: `#${phrases.ACTION_TEXT_MISMATCH}`,
        activeButtonClass: styles.mismatchActionButton,
      };
    default:
      return {
        topInfoText: '',
        actionButtonLabel: '',
        activeButtonClass: null,
      };
  }
};

export const CandidateProfileMatchingCard: React.FC<{
  cardData: BaseCandidateData;
  isOpened: boolean;
  isNoteOpened: boolean;
  onOpenCardStack: (id: string | number) => void;
  onCloseCardStack: (id: string | number) => void;
  onOpenNote: (
    interviewData: InterviewData,
    options: InterviewDataOptions
  ) => void;
  onOverrideCardStatus: (
    cardRefId: string,
    newStatus: SearchCriteriaMatchStatusEnum
  ) => void;
  isQuestioningSent: boolean;
  isDisabled?: boolean;
}> & {
  generateContainerId: (cardId: number | string) => string;
} = ({
  cardData,
  isOpened = false,
  isNoteOpened = false,
  onOpenCardStack,
  onCloseCardStack,
  onOpenNote,
  onOverrideCardStatus,
  isQuestioningSent,
  isDisabled = false,
}) => {
  const [isStackOpened, setStackVisibility] = useState(isOpened);
  const [isNoteExpanded, expandNote] = useState(false);

  const [cardState, dispatch] = useStateMachine(
    CandidateProfileMatchingCardStateConfig,
    CandidateProfileMatchingCardState.initial
  );
  useEffect(() => {
    if (isDisabled) {
      dispatch(CandidateProfileMatchingCardActions.disableCard);
    } else {
      dispatch(CandidateProfileMatchingCardActions.showInitial);
    }
  }, [isDisabled, dispatch]);

  const isInDisabledState = cardState.matches(
    CandidateProfileMatchingCardState.disabled
  );
  const showChangeStatusControls = useCallback(() => {
    if (isInDisabledState) return;

    dispatch(CandidateProfileMatchingCardActions.showChangeStatusControls);
    setStackVisibility(true);
  }, [dispatch, isInDisabledState]);

  const [outsideClickRef] = useOutsideClick(
    () => {
      if (isInDisabledState) return;

      if (isNoteExpanded) {
        expandNote(false);
      }
      dispatch(CandidateProfileMatchingCardActions.showInitial);
    },
    {
      useCapture: true,
    }
  );

  const showInitialCard = useCallback(() => {
    dispatch(CandidateProfileMatchingCardActions.showInitial);
  }, [dispatch]);

  const showExpandedCard = useCallback(() => {
    dispatch(CandidateProfileMatchingCardActions.showBackground);
  }, [dispatch]);

  const [delayedShowExpandedCard, delayedShowInitialCard] = useDelayedEvent(
    showExpandedCard,
    showInitialCard,
    CARD_BACKGROUND_DELAY_DURATION
  );

  useEffect(() => {
    isStackOpened
      ? onOpenCardStack(cardData.id)
      : onCloseCardStack(cardData.id);
  }, [onOpenCardStack, onCloseCardStack, cardData.id, isStackOpened]);

  const mainCard = getMainCard<CandidateSkillData, CandidateCriteriaListItem>(
    cardData
  );
  const topStackItems: CandidateCriteriaListItem[] =
    getValuesForTopStack<BaseCandidateData>(cardData);
  const bottomStackItems =
    getValuesForBottomStack<CandidateEducationCriteriaListItem>(cardData);
  const criteriaLabel = getCardCriterionLabel(cardData);
  const stackSize = getCardStackSize(cardData);
  const topStackClasses = topStackItems.map((it: CandidateCriteriaListItem) =>
    mapCandidateMatchingCardStackClassNames(getCardItemStatus(it, cardData))
  );
  const topStackClassesReversed = R.reverse(topStackClasses);
  const bottomStackClasses = bottomStackItems.map((it) =>
    mapCandidateMatchingCardStackClassNames(getCardItemStatus(it, cardData))
  );

  const onClickOnCollapsedStack = useCallback(() => {
    setStackVisibility(true);
  }, []);

  const [activeNoteItem, setActiveNoteItem] = useState(null);

  const hasNoteOnMainCard = isStackOpened
    ? itemHasComments(mainCard)
    : hasComments(cardData);

  const cardStatus = getCardStatus(cardData);

  const { topInfoText, actionButtonLabel, activeButtonClass } =
    getCardBackgroundElements(cardData, isQuestioningSent);

  const noteOpenHandler = useCallback<(i: InterviewData) => void>(
    (interview) => {
      setActiveNoteItem(interview);
      showChangeStatusControls();
      expandNote(true);
      onOpenNote(interview, { type: cardData.cardType });
    },
    [onOpenNote, showChangeStatusControls, cardData]
  );

  useEffect(() => {
    if (isNoteOpened) {
      noteOpenHandler(mainCard.interview);
    }
  }, [isNoteOpened, mainCard, noteOpenHandler]);

  const onClickMainNote = useCallback(() => {
    let interview;
    if (!isStackOpened) {
      const itemWithUnreadComment = findFirstUnreadComment(cardData);
      interview = itemWithUnreadComment?.interview || mainCard.interview;
    } else {
      interview = mainCard.interview;
    }
    noteOpenHandler(interview);
  }, [noteOpenHandler, cardData, mainCard, isStackOpened]);

  const onClickOnChangeStatusButton = useCallback(
    (newStatus: SearchCriteriaMatchStatusEnum) => {
      showInitialCard();
      if (newStatus !== cardData.status) {
        onOverrideCardStatus(cardData.refId, newStatus);
        trackEvent({
          category: GACategory.ScreeningCandidateProfile,
          label: GA_LABEL_CANDIDATE_CARD_CHANGE_STATUS,
        });
      }
    },
    [showInitialCard, cardData.status, cardData.refId, onOverrideCardStatus]
  );

  const isCardHovered = cardState.in([
    CandidateProfileMatchingCardState.visibleBackground,
    CandidateProfileMatchingCardState.visibleChangeStatusControls,
  ]);

  const visibleChangeStatusControls = cardState.matches(
    CandidateProfileMatchingCardState.visibleChangeStatusControls
  );

  const changeStatusControls = useMemo(() => {
    if (visibleChangeStatusControls) {
      return (
        <div className={styles.statusControlsWrapper}>
          <button
            type="button"
            className={classNames(styles.idealActionButton, {
              [styles.activeActionButton]:
                activeButtonClass === styles.idealActionButton,
            })}
            onClick={() =>
              onClickOnChangeStatusButton(SearchCriteriaMatchStatusEnum.IDEAL)
            }
          >
            <span>#{phrases.ACTION_TEXT_IDEAL}</span>
          </button>
          <button
            type="button"
            className={classNames(styles.acceptableActionButton, {
              [styles.activeActionButton]:
                activeButtonClass === styles.acceptableActionButton,
            })}
            onClick={() =>
              onClickOnChangeStatusButton(
                SearchCriteriaMatchStatusEnum.ACCEPTABLE
              )
            }
          >
            <span>#{phrases.ACTION_TEXT_ACCEPTABLE}</span>
          </button>
          <button
            type="button"
            className={classNames(styles.mismatchActionButton, {
              [styles.activeActionButton]:
                activeButtonClass === styles.mismatchActionButton,
            })}
            onClick={() =>
              onClickOnChangeStatusButton(
                SearchCriteriaMatchStatusEnum.DISQUALIFICATION
              )
            }
          >
            <span>#{phrases.ACTION_TEXT_MISMATCH}</span>
          </button>
        </div>
      );
    } else {
      return (
        <div className={cardStyles.actionWrapper}>
          <button
            type="button"
            className={classNames(cardStyles.actionButton, activeButtonClass)}
            onClick={showChangeStatusControls}
          >
            {actionButtonLabel}
          </button>
        </div>
      );
    }
  }, [
    showChangeStatusControls,
    onClickOnChangeStatusButton,
    visibleChangeStatusControls,
    activeButtonClass,
    actionButtonLabel,
  ]);

  const onNoteClick = useCallback(() => {
    expandNote(false);
  }, []);
  const onNoteIconClick = (item: CandidateCriteriaListItem) => () =>
    noteOpenHandler(item.interview);

  const onCriteriaStackClick = () => {
    if (visibleChangeStatusControls) return null;
    return setStackVisibility((value) => !value);
  };

  const {
    onBackgroundMouseLeave,
    onBackgroundActionButtonClick,
    onStackNoteClick,
    onStackNoteIconClick,
    onCardContentClick,
    onCardContentMouseOver,
    onMainCardNoteClick,
    onMainCardNoteIconClick,
    onStackToggleClick,
  } = (() => {
    const hasBackgroundMouseLeave = !(
      visibleChangeStatusControls || isInDisabledState
    );

    const hasCardContentClick = !(
      visibleChangeStatusControls || isInDisabledState
    );
    const hasCardContentMouseOver = !isInDisabledState;

    return {
      onBackgroundMouseLeave: hasBackgroundMouseLeave
        ? delayedShowInitialCard
        : null,
      onBackgroundActionButtonClick: showChangeStatusControls,
      // @ts-ignore
      onCriteriaStackClick,
      onStackNoteClick: onNoteClick,
      onStackNoteIconClick: onNoteIconClick,
      onCardContentClick: hasCardContentClick ? showChangeStatusControls : null,
      onCardContentMouseOver: hasCardContentMouseOver
        ? delayedShowExpandedCard
        : null,
      onMainCardNoteClick: onNoteClick,
      onMainCardNoteIconClick: onClickMainNote,
      onStackToggleClick: onClickOnCollapsedStack,
    };
  })();

  return (
    <Card.ResizeableCardWrapper
      resizeable
      defaultSize={getCardInitialSize(cardData)}
    >
      <Card.SearchCriteriaCardContainer
        ref={outsideClickRef}
        id={CandidateProfileMatchingCard.generateContainerId(cardData.id)}
        className={classNames(
          styles.candidateProfileMatchingCard,
          mapCandidateMatchingCardBackgroundClassName(cardStatus),
          {
            [styles.hasNote]: hasNoteOnMainCard,
            [styles.expandedChangeStatus]: visibleChangeStatusControls,
          }
        )}
      >
        <Card.SearchCriteriaCardViewBackground
          className={styles.cardViewBackground}
          isVisible={isCardHovered}
          onMouseLeave={onBackgroundMouseLeave}
          topElement={
            visibleChangeStatusControls ? (
              <div className={styles.cardBackgroundTopText}>{topInfoText}</div>
            ) : null
          }
          bottomElement={changeStatusControls}
          actionButtonLabel={actionButtonLabel}
          onActionButtonClick={onBackgroundActionButtonClick}
        >
          <Card.SearchCriteriaCardLabel
            onClick={onCriteriaStackClick}
            text={criteriaLabel}
            showExplanationTooltip={stackSize > 0}
          />
          {R.isEmpty(topStackItems) ? null : (
            <Card.SearchCriteriaStack
              isClosed={!isStackOpened}
              onClick={onCriteriaStackClick}
            >
              {R.reverse(topStackItems).map((item, index: number) => {
                return (
                  <Card.SearchCriteriaStackItem
                    key={`${item?.label}-${index}`}
                    className={classNames(
                      styles.profileCandidateCardStackItem,
                      topStackClassesReversed[index],
                      getCardTitleClassName(cardData.cardType, item),
                      {
                        [styles.hoveredStackItem]: isCardHovered,
                      }
                    )}
                  >
                    {getFieldsForCandidateCollapsedStackCard(item, cardData)}
                    {item.interview?.commentId && (
                      <CandidateInterviewNote
                        isNoteRead={item.interview.read}
                        isNoteExpanded={
                          isNoteExpanded &&
                          activeNoteItem.commentId === item.interview.commentId
                        }
                        iconClassName={styles.noteStackIcon}
                        interviewData={item.interview}
                        onNoteClick={onStackNoteClick}
                        onNoteIconClick={onStackNoteIconClick(item)}
                      />
                    )}
                  </Card.SearchCriteriaStackItem>
                );
              })}
            </Card.SearchCriteriaStack>
          )}
          <Card.SearchCriteriaCardContent
            onClick={onCardContentClick}
            onMouseOver={onCardContentMouseOver}
            className={classNames(
              mapCandidateMatchingCardStatusClassName(cardStatus),
              getCardTitleClassName(cardData.cardType, mainCard),
              {
                [styles.hasShadow]: isStackOpened,
                [styles.hasNote]: hasNoteOnMainCard,
              }
            )}
          >
            {getFieldsForCandidateCollapsedMainCard(mainCard, cardData)}
            {hasNoteOnMainCard && (
              <CandidateInterviewNote
                isNoteRead={
                  isStackOpened
                    ? mainCard.interview.read
                    : !hasUnreadComment(cardData)
                }
                isNoteExpanded={
                  isNoteExpanded &&
                  activeNoteItem.commentId === mainCard.interview.commentId
                }
                interviewData={mainCard.interview}
                onNoteClick={onMainCardNoteClick}
                onNoteIconClick={onMainCardNoteIconClick}
              />
            )}
          </Card.SearchCriteriaCardContent>
          {bottomStackItems.length > 0 && (
            <Card.SearchCriteriaStack
              isClosed={!isStackOpened || stackSize === 0}
              onClick={onCriteriaStackClick}
              position={Card.SearchCriteriaStack.position.bottom}
            >
              {bottomStackItems.map((item, index: number) => {
                return (
                  <Card.SearchCriteriaStackItem
                    key={`${item?.label}-${index}`}
                    className={classNames(
                      styles.profileCandidateCardStackItem,
                      bottomStackClasses[index]
                    )}
                  >
                    {getFieldsForCandidateCollapsedStackCard(item, cardData)}
                  </Card.SearchCriteriaStackItem>
                );
              })}
            </Card.SearchCriteriaStack>
          )}
          {stackSize > 0 && (
            <Card.SearchCriteriaStackToggle
              isHidden={isStackOpened || isCardHovered}
              onClick={onStackToggleClick}
              stackSize={stackSize}
              stackClasses={[...topStackClasses, ...bottomStackClasses]}
            />
          )}
        </Card.SearchCriteriaCardViewBackground>
      </Card.SearchCriteriaCardContainer>
    </Card.ResizeableCardWrapper>
  );
};
CandidateProfileMatchingCard.displayName = 'CandidateProfileMatchingCard';
CandidateProfileMatchingCard.generateContainerId = (cardId: number | string) =>
  `profile-candidate-card-${cardId}`;
