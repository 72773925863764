import R from '@air/third-party/ramda';
import {
  DataOriginType,
  DateInfo,
  NormalizedLocation,
  PeriodInfo,
  SearchWorkHistoryResponse,
  SearchWorkHistoryType,
  WorkHistoryMergedResponse,
} from '@air/api';
import {
  comparePositionsByDate,
  putPositionsWithoutStartEndDatesToTheEnd,
} from '@air/utils/searchDataMapper';
import { BACKEND_CURRENT_YEAR_SIGNATURE } from '@air/domain/Common/WorkHistoryData';
import dayjs from 'dayjs';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import { getMonthDiff } from '@air/utils/dates';
import {
  convertDateToString,
  getDateToLocaleString,
} from '@air/domain/Common/PeriodConverter';

export type WorkHistoryData = {
  unknown?: boolean;
  company?: { id: number; fullName: string; sources: DataOriginType[] };
  startDate: DateInfo;
  endDate: DateInfo;
  months: number;
  years: number;
  title: string;
  rawCompany: string;
  approx: boolean;
  isStartDateUnknown?: boolean;
  isEndDateUnknown?: boolean;
  location?: string;
  type: SearchWorkHistoryType;
};

export const MIN_JOB_ROWS_SPAN = 6;
// Minimum duration for work position with insufficient data – 6 months.
export const MIN_JOB_SPAN_IN_MS = MIN_JOB_ROWS_SPAN * 1000 * 60 * 60 * 24 * 30;
export const MONTHS_PER_YEAR = 12;
export const FIRST_MONTH_INDEX = 1;

// exports / component definitions
export function getDateInfoFromDate(date: Date): DateInfo {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDay();
  return { year, month, day, approx: false };
}

export function getDateFromDateInfo(dateInfo: DateInfo): Date {
  const { year, month, day } = dateInfo;
  return new Date(`${year}/${month || 1}/${day || 1}`);
}

export const getCompanyInfo = (
  workPosition: WorkHistoryMergedResponse
): { id: number; fullName: string; sources: DataOriginType[] } => {
  const { company, cleanCompany, rawCompany } = workPosition;

  if (!R.isNil(company)) {
    return {
      id: company.value?.id,
      fullName: company.value?.fullName,
      sources: company.sources,
    };
  }

  if (!R.isNil(cleanCompany)) {
    return {
      id: null,
      fullName: cleanCompany.value,
      sources: cleanCompany.sources,
    };
  }

  if (!R.isNil(rawCompany)) {
    return {
      id: null,
      fullName: rawCompany.value,
      sources: rawCompany.sources,
    };
  }

  return {
    id: null,
    fullName: null,
    sources: [],
  };
};

const getLocationString = (location?: NormalizedLocation) => {
  const { city, country, state } = location;

  const getResult = R.pipe(
    R.filter(Boolean),
    R.map(R.prop('value')),
    R.join(', ')
  );

  return getResult([city, state, country]);
};

export const normalizePositionDates = (currentDate: Date) => {
  /*
  AR-3367:
  Many work positions have incomplete (missing) data to construct work timeline
  correctly. According to BA requirements, we provide placeholder dates
  for such cases. Every position with unknown dates gets at least a 6 months range.
  In case of both start and end date are missing, such position is placed on top
  of the timeline. In case if only one of 2 dates is missing, we add/subtract 6
  months from the other known date. If date is partially known (i.e. only year,
  we set start date to 1st month of the year, and end date to 6th month).
*/
  const currentDateInfo: DateInfo = {
    ...getDateInfoFromDate(currentDate),
    // if the date is guessed/approx/created on FE from current date
    // either day should be null OR approx should be true
    // because in this case we should now show days in the standard work timeline
    // (see `prepareStartEndDate` method)
    day: null,
  };
  const currentDateTime = currentDate.getTime();
  let theEarliestStartYear = currentDate.getFullYear();

  return (position: WorkHistoryData): WorkHistoryData => {
    if (position.endDate?.year === BACKEND_CURRENT_YEAR_SIGNATURE) {
      position.endDate = currentDateInfo;
    }

    // this puts the item with unknown dates under other items on graphic timeline
    if (position.unknown) {
      position.startDate = {
        ...position.startDate,
        year: theEarliestStartYear - 1,
      };
    }

    if (
      !position.unknown &&
      position.startDate &&
      position.startDate.year &&
      (!position.endDate || !position.endDate.year)
    ) {
      /*
        If there's a start date, but no end date, we add 6 months to start date
        (checking so that it's not bigger than current date).
      */
      const potentialEndDate = new Date(
        Math.min(
          getDateFromDateInfo(position.startDate).getTime() +
            MIN_JOB_SPAN_IN_MS,
          currentDateTime
        )
      );
      position.endDate = getDateInfoFromDate(potentialEndDate);

      position.isEndDateUnknown = true;
    }

    if (
      position.endDate &&
      position.endDate.year &&
      (!position.startDate || !position.startDate.year)
    ) {
      /*
        If there's an end date, but no start date, we subtract 6 months from end date
      */
      const potentialStartDate = new Date(
        getDateFromDateInfo(position.endDate).getTime() - MIN_JOB_SPAN_IN_MS
      );
      position.startDate = getDateInfoFromDate(potentialStartDate);

      position.isStartDateUnknown = true;
    }

    //Guessed logic
    if (
      position.startDate?.year &&
      position.endDate?.year &&
      !position.endDate?.month &&
      !position.startDate?.month
    ) {
      position.startDate.month = FIRST_MONTH_INDEX;
      position.endDate.month = MONTHS_PER_YEAR;
    }

    if (!position.endDate?.month) {
      position.endDate = { ...position.endDate, month: MONTHS_PER_YEAR };
    }
    if (!position.startDate?.month) {
      position.startDate = { ...position.startDate, month: FIRST_MONTH_INDEX };
    }

    if (position.startDate?.year < theEarliestStartYear) {
      theEarliestStartYear = position.startDate.year;
    }

    return position;
  };
};
/**
 * For compatibility with WorkTimeline component we still group positions
 * @param workHistory
 */
export const groupPositions = (workHistory: WorkHistoryData[]) =>
  Array.from(
    workHistory
      .reduce<Map<string, WorkHistoryData[]>>((positionsMap, position) => {
        const positions = positionsMap.get(position.rawCompany) || [];
        positionsMap.set(position.rawCompany, positions.concat(position));
        return positionsMap;
      }, new Map())
      .entries()
  );

const allowedCompanySources = [DataOriginType.CV, DataOriginType.APPLICANT];

export const normalizeWorkHistory = (
  workHistory: SearchWorkHistoryResponse[]
): WorkHistoryData[] => {
  return workHistory
    .map((position) => {
      return {
        title: position.originalTitle?.value ?? null,
        startDate: position.startDate?.value ?? null,
        endDate: position.endDate?.value ?? null,
        years: position.years,
        months: position.months,
        approx: position.approx,
        company: getCompanyInfo(position),
        rawCompany: position.rawCompany?.value ?? null,
        unknown:
          // 0 end year means Present day, it is known
          position.endDate?.value?.year === BACKEND_CURRENT_YEAR_SIGNATURE
            ? false
            : !(
                Boolean(position.startDate?.value?.year) ||
                Boolean(position.endDate?.value?.year)
              ),
        location: position.location && getLocationString(position.location),
        type: position.type,
      };
    })
    .filter(
      (position) =>
        position.company.sources.some((source) =>
          allowedCompanySources.includes(source)
        ) || position.type === SearchWorkHistoryType.GAP
    )
    .sort(putPositionsWithoutStartEndDatesToTheEnd)
    .map(normalizePositionDates(new Date()))
    .sort(comparePositionsByDate);
};

/* if years and months === 0 we consider it as unknown experience (AR-4817) */
export const hasNoExperience = (years?: number, months?: number) =>
  years === 0 && months === 0;

export const notAValidWorkExperience = (date: WorkHistoryData) => {
  const { years, months, isStartDateUnknown, isEndDateUnknown, unknown } = date;

  return (
    hasNoExperience(years, months) ||
    isEndDateUnknown ||
    isStartDateUnknown ||
    unknown
  );
};

const prepareStartEndDate = (date: DateInfo) => {
  const shouldShowDay = !date.approx && !!date.day;
  return dayjs(`${date.year}/${date.month}/${date.day || 1}`).format(
    shouldShowDay ? 'D MMM YYYY' : 'MMM YYYY'
  );
};

export const convertTotalWorkExperienceToString = (
  totalWorkExperience: PeriodInfo
) => {
  let result: string;

  if (totalWorkExperience) {
    const { approx = false } = totalWorkExperience;
    const [yearsValue, yearsWord, monthsValue, monthsWord] =
      convertDateToString(totalWorkExperience).split(' ');

    const years = yearsValue ? [yearsValue, yearsWord].join(' ') : null;
    const months = monthsValue ? [monthsValue, monthsWord].join(' ') : null;

    result = [years, months].filter(Boolean).join(', ').toLowerCase();
    result = approx ? `~${result}` : result;
  } else {
    result = phrases.WORK_TIMELINE_UNKNOWN_EXPERIENCE;
  }

  return result;
};

const isPresentDayEndDate = (position: WorkHistoryData) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  return (
    position.endDate.year === BACKEND_CURRENT_YEAR_SIGNATURE ||
    (position.endDate.year === currentYear &&
      position.endDate.month === currentMonth)
  );
};

export const getWorkExperienceString = (
  position: WorkHistoryData,
  unknownExperiencePlaceholder = sharedPhrases.UNDEFINED_VALUE
) => {
  const {
    isStartDateUnknown,
    isEndDateUnknown,
    unknown,
    startDate,
    endDate,
    years,
    months,
  } = position;
  let result;

  if (hasNoExperience(years, months)) {
    result = sharedPhrases.UNDEFINED_VALUE.toUpperCase();
  }

  if (unknown) {
    result = unknownExperiencePlaceholder.toUpperCase();
  }

  if (isStartDateUnknown) {
    const periodEnd = isPresentDayEndDate(position)
      ? sharedPhrases.PRESENT_DATE
      : prepareStartEndDate(endDate);
    result = `${sharedPhrases.UNDEFINED_VALUE.toUpperCase()} - ${periodEnd}`;
  }

  if (isEndDateUnknown) {
    result = `${prepareStartEndDate(
      startDate
    )} - ${sharedPhrases.UNDEFINED_VALUE.toUpperCase()}`;
  }

  return result;
};

export function getPositionDatesPeriods(position: WorkHistoryData): {
  positionStartYear: number;
  positionEndYear: number;
  positionStartMonth: number;
  positionEndMonth: number;
  positionStartDate: Date;
  positionEndDate: Date;
  positionMonthsDuration: number;
} {
  const positionStartYear = position.startDate.year;
  const positionEndYear = position.endDate.year;

  const positionStartMonth = position.startDate.month;
  const positionEndMonth = position.endDate.month;

  /*
  Calculation of grid placement for positions in current company.
  */
  const positionEndDate = new Date(`${positionEndYear}/${positionEndMonth}/1`);
  const positionStartDate = new Date(
    `${positionStartYear}/${positionStartMonth}/1`
  );

  const positionMonthsDuration =
    position.months + position.years * MONTHS_PER_YEAR ||
    getMonthDiff(positionStartDate, positionEndDate);

  return {
    positionStartYear,
    positionEndYear,
    positionStartMonth,
    positionEndMonth,
    positionStartDate,
    positionEndDate,
    positionMonthsDuration,
  };
}

const getDateOrPlaceholder = (
  month: number,
  year: number,
  approx: boolean,
  placeholder = sharedPhrases.UNDEFINED_VALUE
) => {
  if (month && year && !approx)
    return `${getDateToLocaleString({ month, year } as DateInfo)} ${year}`;
  if ((approx || !month) && year) return year;
  return placeholder;
};

export function getDatesPeriod(position: WorkHistoryData) {
  if (
    !position.startDate.month &&
    !position.startDate.year &&
    !position.endDate.month &&
    position.endDate.year
  ) {
    return sharedPhrases.UNDEFINED_VALUE;
  }
  const periodStart = getDateOrPlaceholder(
    position.startDate.month,
    position.startDate.year,
    position.approx
  );

  const periodEnd = isPresentDayEndDate(position)
    ? sharedPhrases.PRESENT_DATE
    : getDateOrPlaceholder(
        position.endDate.month,
        position.endDate.year,
        position.approx
      );

  return `${periodStart} - ${periodEnd}`;
}

export const normalizeExternalLink = (link: string) => {
  return link.startsWith('http') ? link : `//${link}`;
};
