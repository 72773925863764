import React from 'react';
import styles from 'components/CandidateProfile/CandidateProfileSection.css';
import classNames from 'classnames';

export const CandidateProfileSection = React.forwardRef<
  HTMLTableSectionElement,
  React.PropsWithChildren<{
    title: string;
    onMouseEnter?: () => void;
    inline?: boolean;
  }>
>(({ children, title, onMouseEnter, inline = false }, ref) => {
  return (
    <section
      ref={ref}
      onMouseEnter={onMouseEnter}
      className={classNames(styles.profileSection, {
        [styles.inlineSection]: inline,
      })}
    >
      {title && (
        <p className={styles.sectionTitle}>
          <span>{title}</span>
        </p>
      )}

      {children}
    </section>
  );
});
