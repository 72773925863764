import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { MatchMinerSearchMetadata } from '@air/api';
import { SelectOptionT } from '@air/domain/Forms/types';
import dayjs, { OpUnitType } from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import * as phrases from 'constants/phrases';
import {
  Button,
  FormDropdown,
  Loader,
  MaskedFormField,
  TooltipWrapper,
} from '@air/components';
import styles from './MatchServiceSettingsForm.css';
import { FormFieldTypes } from '@air/components/Form/utils';
import { isNumberValid } from '@air/utils/strings';
import { useMatchMinerRejectionReasons } from 'domain/MatchServices/useMatchMinerRejectionReasons';
import {
  getCommonMatchServiceTooltip,
  MatchMinerSetupSettings,
  MAX_CANDIDATES_LIMIT,
} from 'domain/MatchServices/MatchServices';
import { useKanbanContext } from 'providers/KanbanProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import {
  DEFAULT_CANDIDATES_LIMIT,
  isMatchMinerEnabled,
} from 'domain/CustomerProfile/matchServiceSettings';
import { LineupTabs } from '@air/constants/tabs';

const LIMIT_FIELD = 'limit';
const SINCE_FIELD = 'since';
const REJECTION_REASONS_FIELD = 'rejectionReasons';

type MatchMinerSettingsFormProps = {
  isSubmitEnabled?: boolean;
  className?: string;
  submitLabel?: string;
  onMatchMinerSearchStart: (settings: MatchMinerSetupSettings) => void;
};

export const MatchMinerSettingsForm: React.FC<MatchMinerSettingsFormProps> = ({
  className,
  isSubmitEnabled = true,
  onMatchMinerSearchStart,
  submitLabel = phrases.MATCH_SERVICE_START_SEARCH,
}) => {
  const { mmRejectionReasons } = useMatchMinerRejectionReasons();
  const matchMinerMetadata = useKanbanContext(
    kanbanSelectors.matchMinerMetadata
  );
  const mmSettings = useCustomerProfileContext(
    customerProfileSelectors.matchMinerSettings
  );
  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const { requestMatchMinerForCompany } = useCustomerProfileMethods();

  const isMatchMinerRestartEnabled = isMatchMinerEnabled(mmSettings.status);

  const defaultValues = useMemo(
    () => prepareSetupSettingsForUI(mmRejectionReasons, matchMinerMetadata),
    [mmRejectionReasons, matchMinerMetadata]
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Record<string, any>>({
    defaultValues,
    shouldUnregister: true,
    mode: 'onChange',
  });
  useEffect(() => {
    /*
      We need to reinitialize form with Rejection Reasons
      when the options are fetched for the 1st time.
     */
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = useCallback(
    (values) => {
      onMatchMinerSearchStart(prepareSetupSettingsForBackend(values));
    },
    [onMatchMinerSearchStart]
  );

  const hasErrors = !R.isEmpty(errors);
  const hasLimitError = !!errors[LIMIT_FIELD];
  const limitFieldLabel =
    errors[LIMIT_FIELD]?.message || phrases.MM_SETTINGS_FORM_LIMIT_LABEL;

  const canDisplayForm = !!mmRejectionReasons;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(styles.settingsForm, className)}
    >
      {canDisplayForm ? (
        <>
          <div className={styles.formFields}>
            <Controller
              name={SINCE_FIELD}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormDropdown
                  isBorderOverflown
                  hasWarning={false}
                  isDisabled={false}
                  id={SINCE_FIELD}
                  label={phrases.MM_SETTINGS_FORM_SINCE_LABEL}
                  options={SINCE_FIELD_OPTIONS}
                  className={styles.formField}
                  {...field}
                />
              )}
            />
            <Controller
              name={REJECTION_REASONS_FIELD}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormDropdown
                  isBorderOverflown
                  hasWarning={false}
                  isDisabled={false}
                  id={REJECTION_REASONS_FIELD}
                  label={phrases.MM_SETTINGS_FORM_EXCLUDE_LABEL}
                  options={mmRejectionReasons}
                  className={styles.formField}
                  {...field}
                />
              )}
            />
            <Controller
              name={LIMIT_FIELD}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: phrases.MM_SETTINGS_FORM_LIMIT_LABEL,
                },
                validate: {
                  notZero: (value: string) =>
                    +value !== 0 || phrases.MM_SETTINGS_FORM_LIMIT_LABEL,
                  isGreaterThanZero: (value: string) =>
                    isNumberValid(MAX_CANDIDATES_LIMIT)(value) ||
                    phrases.GET_MM_SETTINGS_FORM_LIMIT_ERROR_LABEL(
                      MAX_CANDIDATES_LIMIT
                    ),
                },
              }}
              render={({ field }) => (
                <MaskedFormField
                  id={LIMIT_FIELD}
                  mask="9999"
                  type={FormFieldTypes.text}
                  label={limitFieldLabel}
                  hasError={hasLimitError}
                  className={styles.formField}
                  {...field}
                />
              )}
            />
          </div>
          <TooltipWrapper
            tooltip={getCommonMatchServiceTooltip({
              tab: LineupTabs.MatchMiner,
              status: mmSettings.status,
              customerRole: user.role,
              requestMatchServiceForCompany: requestMatchMinerForCompany,
            })}
            enabled={!isMatchMinerRestartEnabled}
            containerClassName={styles.tooltipContainer}
            triggerClassName={styles.triggerContainer}
            disabledChildren
          >
            <Button
              type="submit"
              small
              variant={Button.variants.POSITIVE_CONFIRM}
              className={styles.startSearch}
              disabled={
                !isSubmitEnabled || hasErrors || !isMatchMinerRestartEnabled
              }
              alignCenter
            >
              {submitLabel}
            </Button>
          </TooltipWrapper>
        </>
      ) : (
        <Loader className={styles.formLoader} />
      )}
    </form>
  );
};

const currentDate = dayjs();
function getDateOption(label: string, value: number, unit?: OpUnitType) {
  return {
    label,
    value: value ? currentDate.subtract(value, unit).valueOf() : value,
  };
}
const SINCE_FIELD_OPTIONS: SelectOptionT<number>[] = [
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_ALL_TIME, undefined),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_2_YEARS, 2, 'year'),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_1_YEAR, 1, 'year'),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_9_MONTHS, 9, 'month'),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_6_MONTHS, 6, 'month'),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_3_MONTHS, 3, 'month'),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_1_MONTH, 1, 'month'),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_2_WEEKS, 2, 'week'),
  getDateOption(phrases.MM_SETTINGS_FORM_SINCE_1_WEEK, 1, 'week'),
];
function getSinceOptionFromTimestap(timestamp: number | null) {
  /*
    Options in "since" dropdown reflect predefined time periods,
    relative to current time (when MM settings form is mounted).
    But BE works with timestamps, so to retrieve a correct
    value for the dropdown, we need to scan SINCE_FIELD_OPTIONS
    and find a value closest to BE timestamp.
    timestamp will always be either `null`, or a value lying
    between 2 options in this array: o1 < timestamp <= o2.
  */
  if (!timestamp) return SINCE_FIELD_OPTIONS[0];

  for (let i = 1; i < SINCE_FIELD_OPTIONS.length; i++) {
    const currentOption = SINCE_FIELD_OPTIONS[i];

    if (timestamp <= currentOption.value) {
      return currentOption;
    }
  }
}

function prepareSetupSettingsForUI(
  mmRejectionReasons: SelectOptionT<string>[],
  matchMinerMetadata: MatchMinerSearchMetadata
) {
  if (!matchMinerMetadata)
    return {
      [SINCE_FIELD]: SINCE_FIELD_OPTIONS[0],
      [LIMIT_FIELD]: DEFAULT_CANDIDATES_LIMIT,
      [REJECTION_REASONS_FIELD]: mmRejectionReasons?.[0],
    };

  const { since, rejectionReasons, offset, limit } = matchMinerMetadata;
  const currentRejectionReason = rejectionReasons?.[0];
  const formRejectionValue = currentRejectionReason
    ? { label: currentRejectionReason, value: currentRejectionReason }
    : mmRejectionReasons?.[0];

  return {
    [SINCE_FIELD]: getSinceOptionFromTimestap(since),
    /*
      offset - all candidates, who were processed previously (before last attempt)
      limit - amount of candidates requested for processing during last attempt
    */
    [LIMIT_FIELD]: limit + offset || DEFAULT_CANDIDATES_LIMIT,
    [REJECTION_REASONS_FIELD]: formRejectionValue,
  };
}

function prepareSetupSettingsForBackend(
  settings: ReturnType<typeof prepareSetupSettingsForUI>
) {
  return {
    [SINCE_FIELD]: !settings[SINCE_FIELD].value
      ? null
      : settings[SINCE_FIELD].value,
    [LIMIT_FIELD]: settings[LIMIT_FIELD],
    [REJECTION_REASONS_FIELD]: [settings[REJECTION_REASONS_FIELD].value],
  };
}
