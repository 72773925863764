import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import { SearchCriteriaImportanceEnum } from '@air/api';
import {
  BaseSearchCriteriaData,
  CardType,
  isCertification,
  isCompany,
  isDegree,
  isInstitution,
  isMajor,
  isManagerial,
  isProfessional,
  isQuestion,
  isRole,
  isSkill,
  mapCriteriaRequestToCriteriaData,
  SearchCriteriaData,
} from 'domain/SearchCriteria';
import * as phrases from 'constants/phrases';
import { isIndustry } from 'domain/SearchCriteria/IndustryCriteriaData';
import { isLocation } from 'domain/SearchCriteria/LocationCriteriaData';

export enum TableCriteriaGroups {
  Skills = 'skills',
  Education = 'education',
  Company = 'companyExperience',
  Location = 'locations',
  Role = 'experience',
  Question = 'question',
}

export type HeaderCriteriaData<
  T extends BaseSearchCriteriaData = SearchCriteriaData
> = {
  cardData: T;
  id: string | number | null;
};

export type HeaderCriteriaSections = {
  [group in TableCriteriaGroups]?: HeaderCriteriaData[];
};

const sortByProp = (fn: any, comparator: any) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  R.useWith(comparator, [fn, fn]);

const createSortByOrder = (order: any) => {
  return (itemA: any, itemB: any) => order?.[itemA] - order?.[itemB];
};

const sortByCardType = createSortByOrder({
  [CardType.skill]: 0,
  [CardType.major]: 1,
  [CardType.degree]: 1,
  [CardType.institution]: 1,
  [CardType.certification]: 2,
  [CardType.role]: 3,
  [CardType.managerial]: 4,
  [CardType.professional]: 5,
  [CardType.industry]: 6,
  [CardType.company]: 7,
  [CardType.location]: 8,
  [CardType.question]: 9,
});

const sortByImportance = createSortByOrder({
  [ApiModel.SearchCriteriaImportanceEnum.MANDATORY]: 0,
  [ApiModel.SearchCriteriaImportanceEnum.IMPORTANT]: 1,
  [ApiModel.SearchCriteriaImportanceEnum.OPTIONAL]: 2,
});

const sortByExclude = (companyA: any, companyB: any) => (companyB ? -1 : 1);

const sortCriteriaByCardType = sortByProp(
  R.path(['cardData', 'cardType']),
  sortByCardType
);
const sortCriteriaByImportance = sortByProp(
  R.path(['cardData', 'importance', 'value']),
  sortByImportance
) as any;

const sortCompanyCriteriaByType = sortByProp(
  R.pathOr(null, ['cardData', 'exclude']),
  sortByExclude
);

// TODO check if R.or will work:
export const sortCriteria = R.sort<HeaderCriteriaData>(
  (a, b) =>
    sortCriteriaByImportance(a, b) ||
    sortCriteriaByCardType(a, b) ||
    sortCompanyCriteriaByType(a, b)
);

export const TABLE_SECTIONS = [
  { label: phrases.LINEUP_TAB_SKILLS, value: TableCriteriaGroups.Skills },
  { label: phrases.LINEUP_TAB_EDUCATION, value: TableCriteriaGroups.Education },
  { label: phrases.LINEUP_TAB_EXPERIENCE, value: TableCriteriaGroups.Role },
  { label: phrases.LINEUP_TAB_COMPANY, value: TableCriteriaGroups.Company },
  { label: phrases.LINEUP_TAB_QUESTION, value: TableCriteriaGroups.Question },
];

export const groupHeaderCriteriaData = (
  headerData: HeaderCriteriaData
): TableCriteriaGroups => {
  const criterion = headerData.cardData;

  if (isSkill(criterion)) {
    return TableCriteriaGroups.Skills;
  } else if (isLocation(criterion)) {
    return TableCriteriaGroups.Location;
  } else if (
    isMajor(criterion) ||
    isDegree(criterion) ||
    isInstitution(criterion) ||
    isCertification(criterion)
  ) {
    return TableCriteriaGroups.Education;
  } else if (isCompany(criterion) || isIndustry(criterion)) {
    return TableCriteriaGroups.Company;
  } else if (
    isRole(criterion) ||
    isProfessional(criterion) ||
    isManagerial(criterion)
  ) {
    return TableCriteriaGroups.Role;
  } else if (isQuestion(criterion)) {
    return TableCriteriaGroups.Question;
  }
};

export const mapHeaderCellRendererToHeaderData = <T extends SearchCriteriaData>(
  cardData: T
): HeaderCriteriaData<T> => ({
  cardData,
  id: cardData.id,
});

export const mapSearchCriteriaToHeaderData = (
  criteria: ApiModel.SearchCriteriaV2
): HeaderCriteriaSections => {
  const allCriteria: any = R.compose<
    any,
    any,
    any,
    typeof mapCriteriaRequestToCriteriaData
  >(
    // @ts-ignore
    sortCriteria,
    R.map(mapHeaderCellRendererToHeaderData),
    mapCriteriaRequestToCriteriaData
    // @ts-ignore
  )(criteria);

  const groupedCriteria = R.compose<
    HeaderCriteriaData[][],
    HeaderCriteriaSections,
    HeaderCriteriaSections
  >(
    R.map<HeaderCriteriaSections, HeaderCriteriaSections>(sortCriteria),
    R.groupBy(groupHeaderCriteriaData)
  )(allCriteria);

  return groupedCriteria;
};

export const defaultCriteriaConfig = {
  criteria: [
    {
      value: TableCriteriaGroups.Skills,
      label: phrases.LINEUP_TAB_SKILLS,
      checked: true,
      disabled: false,
    },
    {
      value: TableCriteriaGroups.Education,
      label: phrases.LINEUP_TAB_EDUCATION,
      checked: true,
      disabled: false,
    },
    {
      value: TableCriteriaGroups.Role,
      label: phrases.LINEUP_TAB_EXPERIENCE,
      checked: true,
      disabled: false,
    },
    {
      value: TableCriteriaGroups.Company,
      label: phrases.LINEUP_TAB_COMPANY,
      checked: true,
      disabled: false,
    },
    {
      value: TableCriteriaGroups.Location,
      label: phrases.LINEUP_TAB_LOCATION,
      checked: true,
      disabled: false,
    },
    {
      value: TableCriteriaGroups.Question,
      label: phrases.LINEUP_TAB_QUESTION,
      checked: true,
      disabled: false,
    },
  ],
  importance: [
    {
      value: SearchCriteriaImportanceEnum.MANDATORY,
      label: phrases.MANDATORY_SECTION_TITLE,
      checked: true,
      disabled: false,
    },
    {
      value: SearchCriteriaImportanceEnum.IMPORTANT,
      label: phrases.IMPORTANT_SECTION_TITLE,
      checked: true,
      disabled: false,
    },
    {
      value: SearchCriteriaImportanceEnum.OPTIONAL,
      label: phrases.OPTIONAL_SECTION_TITLE,
      checked: true,
      disabled: false,
    },
  ],
};

export type CriteriaConfig = typeof defaultCriteriaConfig;

export function filterAndSortCriteriaColumns(
  criteriaColumns: HeaderCriteriaSections,
  config: CriteriaConfig
) {
  const { criteria, importance } = config;
  const selectedImportance = importance
    .filter((item) => item.checked && !item.disabled)
    .map(R.prop('value'));
  const shouldFilterByImportance = !!selectedImportance.length;

  const selectedCriteria = criteria
    .filter((item) => item.checked && !item.disabled)
    .map(R.prop('value'));
  const shouldFilterByCriteria = !!selectedCriteria.length;

  return R.compose<
    HeaderCriteriaSections[],
    HeaderCriteriaData[][],
    HeaderCriteriaData[],
    HeaderCriteriaData[],
    HeaderCriteriaData[]
  >(
    R.sort<HeaderCriteriaData>(
      (a, b) => sortCriteriaByImportance(a, b) || sortCriteriaByCardType(a, b)
    ),
    R.filter(
      (item: HeaderCriteriaData) =>
        !shouldFilterByImportance ||
        selectedImportance.includes(item.cardData.importance.value)
    ),
    (values) =>
      // @ts-ignore
      R.filter<HeaderCriteriaData>(
        Boolean,
        // @ts-ignore
        R.flatten<HeaderCriteriaData>(values)
      ),
    shouldFilterByCriteria ? R.props(selectedCriteria) : R.values
  )(criteriaColumns);
}
