import { SearchCountResponseV2 } from '@air/api';

import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData/';

/**
 * Total counters, used on Kanban cards. `Total` means they are updated only
 * if candidates number and statuses are changed. Filtering by candidate's name
 * doesn't affect them (like it should affect counters in lineup sections).
 */

export type ItemWithCountersT = {
  totalCount: number;
  processedCount?: number;
  selectedCount?: number | null;
  matchedCount?: number | null;
  pendingCount?: number | null;
  rejectedCount?: number | null;
  failedCount?: number | null;
};

export type SearchCountersT = {
  applicants?: number;
  selected?: number | null;
  matched?: number | null;
  pending?: number | null;
  failed?: number | null;
  rejected?: number | null;
  totalRejectedAndFailed?: number | null;
  notProcessed?: number | null;
};

type SearchToSearchCountersParamsT = {
  counters: SearchCountResponseV2;
};

export const mapSearchToSearchCounters = ({
  counters,
}: SearchToSearchCountersParamsT): SearchCountersT | null => {
  if (!counters) return null;

  const {
    failedCount,
    matchedCount,
    pendingCount,
    processedCount,
    rejectedCount,
    selectedCount,
    totalCount,
  } = counters;
  const totalRejectedAndFailed =
    rejectedCount === null && failedCount === null
      ? null
      : rejectedCount + failedCount;

  return {
    applicants: totalCount,
    selected: selectedCount,
    matched: matchedCount,
    pending: pendingCount,
    /*
      When processing MM candidates, totalCount can decrease during processing
      if the candidates are rejected by internal MM rules.
      So we can have total - 500, processed - 0 when processing starts,
      and total - 300, processed - 500, when it is finished.
      It seems that this behavior only happens when processing MM candidates.
     */
    notProcessed: Math.max(totalCount - processedCount, 0),
    rejected: rejectedCount,
    failed: failedCount,
    totalRejectedAndFailed,
  };
};

/**
 * For different widgets and ui components we have different rules, under
 * which we group statuses
 */
export type StatusesMap<T extends string | number> = {
  [name in T]: CandidateSearchProfileStatusEnum[];
};
