import React, { useRef, useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { compose, not, isNil } from 'ramda';
import classNames from 'classnames';
import styles from './ReorderDropTargetCell.css';

export const ReorderDropTargetCell: React.FC<{
  isExpanded?: boolean;
  accept: any[];
  canDrop: (props: any) => boolean;
  drop: (props: any) => void;
}> = ({ isExpanded = false, ...props }) => {
  const ref = useRef(null);
  const [{ isOver, canDrop, item }, drop] = useDrop({
    accept: props.accept,
    canDrop: props.canDrop,
    drop: props.drop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      item: monitor.getItem(),
    }),
  });

  drop(ref);

  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    let timeoutId: number;
    if (item && !isDragging) {
      /*
        https://github.com/react-dnd/react-dnd/issues/1085
        Bug in react-dnd leads to immediate firing of `dragend` event when
        element styling changes immediately on drag start (in Chrome).
        This bug didn't allow to drag criteria card correctly, when there's no
        enough space to fit it in the line right above the dragged card.
        Suggested fix is to postpone styling updates to the next tick
        with setTimeout.
      */
      timeoutId = window.setTimeout(() => {
        setDragging(compose(not, isNil)(item));
      }, 0);
    } else if (isDragging && !item) {
      setDragging(false);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isDragging, item]);

  return (
    <div
      ref={ref}
      className={classNames(styles.reorderDropTargetCell, {
        [styles.dropTarget]: canDrop && isDragging,
        [styles.dropTargetHovered]: canDrop && isOver,
        [styles.expandedDrop]: isExpanded && isDragging,
      })}
    >
      <div className={styles.dropTargetInner} />
    </div>
  );
};
