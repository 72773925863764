import R from '@air/third-party/ramda';
import {
  BACKGROUND_IMAGE_ID_STORAGE_KEY,
  INTERVIEW_LEFT_PANEL_COLLAPSED,
} from '@air/domain/WebStorage/webStorage';
import { DEFAULT_BACKGROUND_IMAGE_ID } from '@air/constants/app';

const defaultEncoder = R.toString;
const defaultDecoder = R.equals('true');

type LocalSettingsItem = {
  defaultValue: any;
  decoder?: (value: string) => any;
  encoder?: (value: any) => string;
};

const localSettings = {
  RESET_APP_ON_DEPLOY: {
    defaultValue: true,
  },
  DEBUG_NOTIFICATIONS: {
    defaultValue: false,
  },
  GA_DEBUG_MODE: {
    defaultValue: false,
  },
  [BACKGROUND_IMAGE_ID_STORAGE_KEY]: {
    defaultValue: DEFAULT_BACKGROUND_IMAGE_ID,
    decoder: Number.parseInt,
  },
  [INTERVIEW_LEFT_PANEL_COLLAPSED]: {
    defaultValue: false,
  },
  // PROP_FOR_EXAMPLE: {
  //   defaultValue: { hello: 'world' },
  //   encoder: JSON.stringify,
  //   decoder: JSON.parse,
  // },
};

type LocalSettings = {
  [propertyName in keyof typeof localSettings]: LocalSettingsItem;
};

export const setLocalSettingsValue = (
  key: keyof typeof localSettings,
  value: any
) => {
  window.localStorage.setItem(
    key,
    ((localSettings[key] as LocalSettingsItem).encoder ?? defaultEncoder)(value)
  );
};

export const getLocalSettingsValue = (key: string, value: any) =>
  (value?.decoder ?? defaultDecoder)(window.localStorage.getItem(key));

R.forEachObjIndexed((value: LocalSettingsItem, key: string) => {
  if (window.localStorage.getItem(key) === null) {
    window.localStorage.setItem(
      key,
      (value?.encoder ?? defaultEncoder)(value.defaultValue)
    );
  }
}, localSettings);

export default R.mapObjIndexed(
  (value: LocalSettingsItem, key: string) => getLocalSettingsValue(key, value),
  localSettings
) as { [propertyName in keyof LocalSettings]: any };
