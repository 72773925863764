// imports from vendor deps
import React from 'react';

// imports from types

// imports from 'components'
import { Button, Header, Paragraph } from '@air/components';

// imports from 'constants'
import * as phrases from 'constants/phrases';

// imports from helpers

// imports from styles
import styles from './SearchResultsEmpty.css';

// component proptypes
type SearchResultsEmptyT = {
  isPassiveView?: boolean;
  isSearchPausedOrClosed?: boolean;
  isStandaloneAtsUser: boolean;
  shouldDisplayUploadCVButton: boolean;
  dropZoneRootProps: object;
  dropZoneInputProps: object;
  openFileManager: () => void;
};

type SearchResultsPassiveEmptyT = {
  isStandaloneAtsUser?: boolean;
  isSearchPausedOrClosed?: boolean;
};

export const SearchResultsPassiveEmpty: React.FC<SearchResultsPassiveEmptyT> = ({
  isSearchPausedOrClosed,
  isStandaloneAtsUser,
}) => {
  const header = isStandaloneAtsUser
    ? phrases.NO_APPLICANTS_TITLE_PASSIVE_STANDALONE
    : phrases.NO_APPLICANTS_STATE_TITLE;

  const standaloneDescription = isSearchPausedOrClosed
    ? phrases.NO_APPLICANTS_DESC_PASSIVE_STANDALONE_PAUSED
    : phrases.NO_APPLICANTS_DESC_PASSIVE_STANDALONE_SCREENING;

  const description = isStandaloneAtsUser
    ? standaloneDescription
    : phrases.NO_APPLICANTS_DESC_PASSIVE;

  return (
    <>
      <Header level={2} className={styles.title}>
        {header}
      </Header>
      <Paragraph
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </>
  );
};

export const SearchResultsEmpty: React.FC<SearchResultsEmptyT> = ({
  isPassiveView,
  isSearchPausedOrClosed,
  isStandaloneAtsUser,
  shouldDisplayUploadCVButton,
  dropZoneRootProps,
  dropZoneInputProps,
  openFileManager,
}) => {
  return (
    <div className={styles.noResultsWrapper}>
      <div className={styles.searchResultsEmpty}>
        {(isPassiveView && isStandaloneAtsUser) ||
        (isPassiveView && isSearchPausedOrClosed) ? (
          <SearchResultsPassiveEmpty
            isStandaloneAtsUser={isStandaloneAtsUser}
            isSearchPausedOrClosed={isSearchPausedOrClosed}
          />
        ) : (
          <>
            <Header level={2} className={styles.title}>
              {phrases.NO_APPLICANTS_STATE_TITLE}
            </Header>
            <Paragraph
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: isStandaloneAtsUser
                  ? phrases.NO_APPLICANTS_EMPLOYA_ATS_PLACEHOLDER
                  : phrases.NO_APPLICANTS_EXTERNAL_ATS_PLACEHOLDER,
              }}
            />
          </>
        )}
        {shouldDisplayUploadCVButton && (
          <div {...dropZoneRootProps}>
            <input {...dropZoneInputProps} />
            <Button
              small
              variant={Button.variants.POSITIVE_SECONDARY}
              icon="upload-icon"
              className={styles.uploadButton}
              onClick={openFileManager}
            >
              {phrases.UPLOADER_BUTTON_TEXT}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

SearchResultsEmpty.defaultProps = {};
SearchResultsEmpty.displayName = 'SearchResultsEmpty';
