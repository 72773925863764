// imports from vendor deps
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import * as Sentry from '@sentry/browser';
// import {} from 'lodash-es';
// import classNames from 'classnames';
// imports from types
// import … from 'types';
// imports from 'components'
import { CustomerProfileContextT } from 'context';
import { Logo } from '@air/components';
import { Button } from 'components';

// imports from 'constants'
import * as urls from 'constants/urls';

// import from images
// import {} from 'images'
// imports from helpers
// imports from styles
import styles from './ErrorBoundary.css';
import { LegacyCustomerProfileContextConsumer } from 'providers/CustomerProfileProvider';

// component proptypes
type Props = RouteComponentProps;

// component state
type State = {
  hasError: boolean;
  eventId?: string;
};

// exports / class definitions
class ErrorBoundaryComponent extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(/* error */) {
    return { hasError: true };
  }

  componentDidCatch() {
    const { history } = this.props;
    // Listen for changes to the current location.
    const unlisten = history.listen((/* location, action */) => {
      this.setState({ hasError: false });
      unlisten();
    });

    const eventId = Sentry.lastEventId();

    this.setState({
      eventId,
    });

    // This is a system message for Sentry. No need to move it to phrases.
    Sentry.captureMessage('Unhandled error. ErrorBoundary displayed.');
  }

  reload = () => {
    location.reload();
  };

  submitReport = (user: any) => {
    Sentry.showReportDialog({
      eventId: this.state.eventId,
      user: {
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
      },
    });
  };

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <LegacyCustomerProfileContextConsumer>
          {({ user }: { user: CustomerProfileContextT['user'] }) => (
            <div className={styles.errorContent}>
              <Logo href={urls.ROOT_ROUTE} />
              <h1>🙈 🔥 Something went wrong 🔥 🙈</h1>
              <div className={styles.actionHolder}>
                <Button style={{ marginRight: 20 }} onClick={this.reload}>
                  Reload page
                </Button>
                <Button onClick={() => this.submitReport(user)}>
                  Send report
                </Button>
              </div>
            </div>
          )}
        </LegacyCustomerProfileContextConsumer>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
