import { LineupTabs } from '@air/constants/tabs';
import { useKanbanContext } from 'providers/KanbanProvider';
import { kanbanSelectors } from 'selectors';

export const useLineupSearchId = (tab: LineupTabs): number | null => {
  const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
  const currentMatchMinerSearchId = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearchId
  );
  const currentMatchScoutSearchId = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearchId
  );
  return {
    [LineupTabs.Active]: currentSearchId,
    [LineupTabs.Passive]: currentSearchId,
    [LineupTabs.MatchMiner]: currentMatchMinerSearchId,
    [LineupTabs.MatchScout]: currentMatchScoutSearchId,
  }[tab];
};
