import { LineupTabs } from '@air/constants/tabs';
import { useKanbanContext } from 'providers/KanbanProvider';
import { kanbanSelectors } from 'selectors';

export const useEmailConfigParams = (tab: LineupTabs) => {
  const matchMinerTotalProcessed = useKanbanContext(
    kanbanSelectors.matchMinerTotalProcessed
  );
  const matchScoutTotalProcessed = useKanbanContext(
    kanbanSelectors.matchScoutTotalProcessed
  );
  const currentPassiveSearch = useKanbanContext(kanbanSelectors.currentSearch);
  const currentPassiveSearchId = useKanbanContext(
    kanbanSelectors.currentSearchId
  );
  const currentMatchMinerSearch = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearch
  );
  const currentMatchMinerSearchId = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearchId
  );
  const currentMatchScoutSearch = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearch
  );
  const currentMatchScoutSearchId = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearchId
  );
  const passiveTotalCount = useKanbanContext(
    kanbanSelectors.currentSearchPassiveTotalCount
  );
  const matchMinerTotalCount = useKanbanContext(
    kanbanSelectors.matchMinerPassiveTotalCount
  );
  const matchScoutTotalCount = useKanbanContext(
    kanbanSelectors.matchScoutPassiveTotalCount
  );

  switch (tab) {
    case LineupTabs.MatchMiner:
      return {
        totalCount: matchMinerTotalCount,
        totalProcessed: matchMinerTotalProcessed,
        currentSearchId: currentMatchMinerSearchId,
        currentSearch: currentMatchMinerSearch,
      };
    case LineupTabs.MatchScout:
      return {
        totalCount: matchScoutTotalCount,
        totalProcessed: matchScoutTotalProcessed,
        currentSearchId: currentMatchScoutSearchId,
        currentSearch: currentMatchScoutSearch,
      };
    // Active tab state is impossible on this screen,
    // but it is added for TS compliance.
    case LineupTabs.Passive:
    default:
      return {
        totalCount: passiveTotalCount,
        totalProcessed: passiveTotalCount,
        currentSearchId: currentPassiveSearchId,
        currentSearch: currentPassiveSearch,
      };
  }
};
