import { LicenseType } from '@air/api/models';
import * as phrases from 'constants/phrases';

export const showTrialSection = (licenseType: LicenseType): boolean => {
  return [
    LicenseType.TRIALACTIVE,
    LicenseType.TRIALEXPIRED,
    LicenseType.TRIALEXPIREDFULLREQUESTED,
    LicenseType.TRIALACTIVEFULLREQUESTED,
  ].includes(licenseType);
};

export const getTrialDaysLeftMessage = (daysLeft: number): string => {
  switch (daysLeft) {
    case 0:
    case 1:
      return phrases.TRIAL_LAST_DAY_LEFT;
    default:
      return phrases.getNumberOfDaysLeftMessage(daysLeft);
  }
};

export const getTrialDaysLeftToastMessage = (daysLeft: number): string => {
  switch (daysLeft) {
    case 0:
    case 1:
      return phrases.TRIAL_TOAST_LAST_DAY_LEFT;
    default:
      return phrases.getNumberOfDaysLeftToastMessage(daysLeft);
  }
};

export const TRIAL_REQUEST_CALENDLY_URL = `https://calendly.com/employa-sales/15min-demo`;
