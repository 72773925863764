export enum APP_EVENTS {
  CREATE_DRAFT,
  CREATE_DRAFT_FAILURE,
  REMOVE_DRAFT,
  OPEN_TRIAL_PANEL,
  HIDE_TRIAL_PANEL,
  OPEN_KANBAN,
  CREATE_CARD,
  DUPLICATE_CARD,
  CHANGE_SEARCH_CRITERIA_TAB,
  CHANGE_BACKGROUND,
  ADD_APPLICANTS_SCREENING,
  APPLICANT_DRAG_START,
  APPLICANT_DRAG_END,
  LOGOUT,
  JOB_STATUS_CHANGE,
  QUESTIONING_START,
  CHANGE_SECTION_STATE,
  SELECT_COPY_CRITERIA_SOURCE,
  REFETCH_DRAFT,
  RESET_SEARCH_FORM,
  IMPORT_CRITERIA,
  OPEN_CONFIRMATION_MODAL,
  SET_CANDIDATES_LIST_LOADING,
  OPEN_JOB_PANEL,
}
