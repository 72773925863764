// imports from vendor deps
import React, { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import * as phrases from 'constants/phrases';

// imports from types
import { LineupTabs } from '@air/constants/tabs';
import { RequisitionFile, UploadStatus } from 'context';

// imports from 'components'
import {
  Paragraph,
  FormDropdown,
  Button,
  Header,
  UIText,
} from '@air/components';
import { UploadsList } from 'components';

// imports from 'constants'

// import from images
// import {} from 'images'

// import {} from 'utils'
import { applicantsTypeOptions, LINEUP_TABS_MAPPING } from 'utils/uploads';

// imports from styles
import styles from './UploadsSidebarWrapper.css';
import formViewStyles from 'components/FormView/FormView.css';

type UploadsSidebarWrapperProps = {
  className?: string;
  isOpen?: boolean;
  isInterviewPaused?: boolean;
  files: RequisitionFile[];
  pendingFilesCount: number;
  totalFilesCount: number;
  shouldShowExplanationText?: boolean;
  currentTab?: LineupTabs | string;
  isUploadingToServer?: boolean;
  onRemoveApplicant?: (
    atsCandidateId: string,
    fileId: string,
    uploadStatus: UploadStatus
  ) => void;
  onStartUpload?: (isPassive: boolean) => void;
  onResumeInterview?: () => void;
};

export const UploadsSidebarWrapper: React.FC<UploadsSidebarWrapperProps> = (
  props
) => {
  const {
    files,
    isOpen,
    isInterviewPaused,
    pendingFilesCount,
    totalFilesCount,
    currentTab,
    isUploadingToServer,
    className,
    shouldShowExplanationText = false,
    onRemoveApplicant,
    onResumeInterview,
  } = props;

  type DropdownValue = {
    value: LineupTabs | string;
    label: string;
  };

  const [selectedValue, setSelectedValue] = useState<DropdownValue>(() => {
    // We can't upload applicants to MatchMiner or MatchScout tabs manually, so for
    // every other tab `Active` is selected as a default upload target.
    const value =
      currentTab === LineupTabs.Passive ? currentTab : LineupTabs.Active;
    return { value, label: LINEUP_TABS_MAPPING[value] };
  });

  const onChange = useCallback((value: DropdownValue) => {
    setSelectedValue(value);
  }, []);

  const onStartUpload = useCallback(() => {
    const isPassive = selectedValue.value === LineupTabs.Passive;
    props?.onStartUpload(isPassive);
  }, [props, selectedValue.value]);

  const isUploadButtonDisabled = useMemo(
    () => R.isNullOrEmpty(selectedValue.value) || !pendingFilesCount,
    [selectedValue, pendingFilesCount]
  );

  const buttonText = useMemo(() => {
    switch (true) {
      case isUploadingToServer:
        return phrases.UPLOADING_TITLE;
      case !!selectedValue.value:
        return phrases.STARTING_PROCESSING_BUTTON_TITLE(selectedValue.label);
      case !selectedValue.value:
        return phrases.STARTING_PROCESSING_DISABLED_BUTTON_TITLE;
    }
  }, [isUploadingToServer, selectedValue]);

  const UploadControls = useMemo(() => {
    return (
      <div>
        {isUploadingToServer ? (
          <Paragraph small className={styles.uploadsExplanationText}>
            {phrases.DISABLED_UPLOAD_EXPLANATION_TEXT}
          </Paragraph>
        ) : (
          !isInterviewPaused && (
            <>
              {/*
              //@ts-ignore */}
              <FormDropdown
                options={applicantsTypeOptions}
                value={selectedValue}
                label="Place in"
                type="select"
                name="candidateType"
                menuPlacement="top"
                isBorderOverflown
                onChange={onChange}
                className={styles.formFieldContainer}
              />
            </>
          )
        )}
        {isInterviewPaused ? (
          <div className={styles.pausedSearchResumeWrapper}>
            <Paragraph short className={styles.description}>
              {phrases.RESUME_SCREENING_UPLOAD_EXPLANATION_TEXT}
            </Paragraph>
            <Button
              variant={Button.variants.POSITIVE_CONFIRM}
              className={classNames(
                styles.processingButton,
                formViewStyles.actionButton
              )}
              onClick={onResumeInterview}
            >
              {phrases.SCREENING_SECTION_RESUME_ACTION}
            </Button>
          </div>
        ) : (
          <Button
            variant={Button.variants.POSITIVE_CONFIRM}
            disabled={isUploadButtonDisabled}
            className={classNames(
              styles.processingButton,
              formViewStyles.actionButton
            )}
            onClick={onStartUpload}
            isLoading={isUploadingToServer}
          >
            <UIText small bold>
              {buttonText}
            </UIText>
          </Button>
        )}
      </div>
    );
  }, [
    selectedValue,
    onChange,
    buttonText,
    isUploadButtonDisabled,
    isUploadingToServer,
    onStartUpload,
    isInterviewPaused,
    onResumeInterview,
  ]);

  return (
    <div
      className={classNames(styles.uploadSideWrapper, {
        [styles.controlsVisible]: !shouldShowExplanationText,
        [styles.disabled]: isUploadingToServer,
      })}
    >
      <Header level={3} bold className={styles.header}>
        {phrases.UPLOADING_TITLE}
      </Header>
      <UploadsList
        files={files}
        isOpen={isOpen}
        pendingFilesCount={pendingFilesCount}
        totalFilesCount={totalFilesCount}
        isUploadingToServer={isUploadingToServer}
        className={className}
        onRemoveApplicant={onRemoveApplicant}
      />
      {shouldShowExplanationText ? (
        <Paragraph small className={styles.uploadsExplanationText}>
          {phrases.UPLOADS_EXPLANATION_TEXT}
        </Paragraph>
      ) : (
        UploadControls
      )}
    </div>
  );
};
