import React from 'react';
import classNames from 'classnames';

import { Paragraph, SvgIcon } from '@air/components';

import styles from './NavbarIconButton.css';

type Props = {
  id?: string;
  icon?: string;
  iconText?: string;
  className?: string;
  isActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const NavbarIconButton: React.FC<Props> = ({
  id,
  icon,
  iconText,
  className,
  isActive,
  onClick,
}) => {
  return (
    <button
      id={id}
      type="button"
      className={classNames(styles.navbarIconButton, className, {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    >
      {icon && <SvgIcon icon={icon} />}
      <Paragraph big bold className={styles.iconText}>
        {iconText}
      </Paragraph>
    </button>
  );
};
NavbarIconButton.displayName = 'NavbarIconButton';
