// imports from vendor deps
import React from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

// imports from types
import { ClassDictionary } from 'classnames/types';

// imports from 'components'
// import {} from 'components';

// imports from 'constants'
// import {} from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'
// imports from styles

import styles from './RangeDropdown.css';
import fadeTransition from '@air/styles/transitions/fade.css';

export type RangeItem = {
  label: string | number | JSX.Element;
  value: number;
};

export type SelectedItems = number[];

// component proptypes
type Props = {
  rangeItems: RangeItem[];
  onItemSelected: (index: number) => void;
  selectedItems: SelectedItems;
  predefinedItems?: number[];
  isOpen: boolean;
  arrowClassName?: string;
  rangeItemClass?: (
    index: number,
    allItems: RangeItem[],
    selectedItems: SelectedItems
  ) => ClassDictionary;
  dropdownClass?: (
    rangeItems: RangeItem[],
    selectedItems: SelectedItems
  ) => ClassDictionary;
};

const isPredefinedItem = (index: number, predefinedItems: number[]) =>
  R.includes(index, predefinedItems);

const popperModifiers = {
  preventOverflow: { enabled: true },
};

// exports / component definitions
export const RangeDropdown: React.FC<Props> = ({
  rangeItems,
  onItemSelected,
  selectedItems,
  predefinedItems = [],
  isOpen,
  rangeItemClass = null,
  dropdownClass = null,
  arrowClassName,
}) => {
  const onClickRangeItem = (index: number) => () => {
    if (isPredefinedItem(index, predefinedItems)) {
      return; // do not react on clicking on predefined items
    }
    onItemSelected(index);
  };

  return (
    <Manager>
      <Reference>{({ ref }) => <div ref={ref} />}</Reference>
      <Popper placement="bottom" modifiers={popperModifiers}>
        {({ ref, style: popperStyles, arrowProps }) => (
          <CSSTransition
            in={isOpen}
            timeout={500}
            classNames={fadeTransition}
            unmountOnExit
          >
            <div ref={ref} className={styles.rangePopper} style={popperStyles}>
              <div
                className={classNames(
                  styles.rangeDropdown,
                  dropdownClass(rangeItems, selectedItems)
                )}
              >
                {rangeItems.map((item, index) => (
                  <button
                    type="button"
                    onClick={onClickRangeItem(index)}
                    key={index}
                    className={classNames(
                      styles.rangeItem,
                      rangeItemClass(index, rangeItems, selectedItems)
                    )}
                  >
                    <span>{item.label}</span>
                  </button>
                ))}
                <div
                  className={classNames(styles.popperArrow, arrowClassName)}
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                />
              </div>
            </div>
          </CSSTransition>
        )}
      </Popper>
    </Manager>
  );
};
