// imports from vendor deps
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// @ts-ignore commented out due to the outdated @types/react-router-dom
// updating needs significant amount of time to update all the dependent types
import { Link, useLocation } from 'react-router-dom';
import R from '@air/third-party/ramda';
// imports from types
import {
  AlertStatusEnum,
  CandidateSearchProfileStatus,
  EnrichmentInfoStatusEnum,
  RedFlagItem,
  SearchCriteriaImportanceEnum,
  SearchCriteriaMatchStatusEnum,
  SearchProgressStatusEnum,
} from '@air/api';

import {
  HeaderCriteriaData,
  HeaderCriteriaSections,
} from 'domain/HeaderData/HeaderDataMapper';
import { atsLogos, ATSNames } from 'domain/ATS/ATSMetadata';
import { CandidateTabs, LineupTabs } from '@air/constants/tabs';

// imports from 'components'
import { Button, EnrichButton, SvgIcon, WorkTimeline } from 'components';
import { CandidateProfileWarningCard } from 'components/CardWrappers/CandidateProfileWarningCard/CandidateProfileWarningCard';
import {
  BaseCandidateData,
  CandidateProfileContactFormT,
  CandidateProfileUIT,
  CandidateSearchProfileStatusEnum,
  getCandidateProfileLabel,
  getEnrichmentTooltip,
} from 'domain/CandidateData/';
// imports from 'constants'
import * as phrases from 'constants/phrases';
// import from images
// import {} from 'images'
// imports from helpers
// imports from styles
import styles from './CandidateProfile.css';
import {
  EnrichmentStatus,
  mapCandidateDataToImportanceSections,
  mapHeaderDataToImportanceSections,
} from 'domain/CandidateData/CandidateProfileData';
import { CardsSection } from 'features/DraftSection/NewSearch/CardsSection';
import { CriteriaTabControl, CriteriaTabs } from './CandidateCriteriaTabs';
import { SearchCriteriaData } from 'domain/SearchCriteria';
import { importanceToLabel } from 'features/DraftSection/NewSearch/utils';
import { CandidateProfileDocumentsTab } from 'components/CandidateProfile/CandidateProfileTabs/CandidateProfileDocumentsTab';
import { CandidateProfileMatchingCard } from 'components/CardWrappers/CandidateProfileMatchingCard/CandidateProfileMatchingCard';
import { CandidateProfileSearchCriteriaCard } from 'components/CardWrappers/CandidateProfileSearchCriteriaCard/CandidateProfileSearchCriteriaCard';
import classNames from 'classnames';
import { useDebounce } from '@air/utils/hooks';
import {
  CLICK_DEBOUNCE_TIME_LONG,
  INPUT_DEBOUNCE_TIME,
} from '@air/constants/app';

import {
  CandidateStatusToggle,
  StatusPopupLocation,
} from 'components/CandidateProfile/CandidateStatusToggle/CandidateStatusToggle';
import { statusIconMap } from 'domain/CandidateData/candidateStatusHelpers';
import { CandidateName } from 'components/CandidateProfile/CandidateName/CandidateName';
import { CandidateProfileNote } from 'components/CandidateProfile/CandidateProfileNote/CandidateProfileNote';
import {
  useCandidateProfileContext,
  useCandidateProfileMethods,
} from 'providers/CandidateProfileProvider';
import { downloadCandidateResume } from 'domain/CandidateData/candidateApiTasks';
import {
  mapRedFlagsRequestToRedFlagData,
  RedFlagSettingsData,
} from 'domain/SearchCriteria/RedFlagsData';
import { FlagSettingsState } from 'components/SearchCriteriaCards/hooks/FlagSettingsStateConfig';
import { FlagSettingsCard } from 'components/CardWrappers';
import { WorkHistoryData } from 'domain/CandidateData/WorkHistory/WorkHistoryData';
import { Paragraph, TooltipWrapper, UIText } from '@air/components';
import { APP_EVENTS } from 'domain/Kanban/events';
import { subscribe } from 'hooks/usePubSub';
import querystring from 'query-string';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import {
  candidateProfileSelectors,
  customerProfileSelectors,
  kanbanSelectors,
} from 'selectors';
import statusToggleStyles from './CandidateStatusToggle/CandidateStatusToggle.css';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { ContactDetails } from 'components/CandidateProfile/CandidateContactDetails/ContactDetails';
import { Loader } from '@air/components/Loader/Loader';

export enum Status {
  FAILED = 'FAILED',
  ENRICHED = 'ENRICHED',
  NO_DATA_ENRICHED = 'NODATAENRICHED',
  IN_PROGRESS = 'INPROGRESS',
}

const ENRICHMENT_POLLING_TIMEOUT = 10000;

// component proptypes

type ProfileTab = {
  label: string;
  icon: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  iconWidth?: string;
  iconHeight?: string;
  isProcessing?: boolean;
};

type ProfileTabs = { [key: string]: ProfileTab };

type Props = {
  onClose: () => void;
  searchCriteria: HeaderCriteriaSections;
  redFlagsSettings: Array<RedFlagItem>;
  changeCandidateStatus: (
    candidateId: string,
    currentStatus: CandidateSearchProfileStatusEnum,
    nextStatus: CandidateSearchProfileStatusEnum
  ) => void;
  moveCandidateToApplied: (candidate: CandidateProfileUIT) => void;
  moveCandidateToPassive: (candidate: CandidateProfileUIT) => void;
  isReadOnly?: boolean;
  canEditCandidateProfile?: boolean;
  currentTab: LineupTabs;
  searchId: number;
};

// exports / component definitions
export const CandidateProfile: React.FC<Props> = ({
  onClose,
  searchCriteria,
  redFlagsSettings,
  changeCandidateStatus,
  moveCandidateToApplied,
  moveCandidateToPassive,
  isReadOnly = false,
  canEditCandidateProfile,
  currentTab,
  searchId, // must be searchId of the current opened tab search - active, passive, matchMiner
}) => {
  const location = useLocation();
  const paramsObject = querystring.parse(location.search);
  const targetCardId = paramsObject.cardId as string;
  const targetCardShowNote: boolean = paramsObject.showNote === 'true';
  const [areCompaniesLoading, setCompaniesLoading] = useState(false);

  const [isResumeDownloading, setIsResumeDownloading] = useState(false);

  const candidateProfileRef = useRef<HTMLDivElement>(null);

  const companiesInfo = useKanbanContext(kanbanSelectors.companiesInfo);
  // const dataSourceId = useCustomerProfileContext(
  //   customerProfileSelectors.dataSourceId
  // );
  const atsType = useCustomerProfileContext(customerProfileSelectors.atsType);
  const isExternalATS = useCustomerProfileContext(
    customerProfileSelectors.isExternalATS
  );
  const currentSearchStatus = useKanbanContext(
    kanbanSelectors.currentSearchStatus
  );
  const { fetchCompaniesInfo } = useKanbanMethods();

  const currentCandidateProfileNote = useCandidateProfileContext(
    candidateProfileSelectors.currentCandidateProfileNote
  );
  const currentCandidateProfile = useCandidateProfileContext(
    candidateProfileSelectors.currentCandidateProfile
  );

  const hasWorkExperience =
    currentCandidateProfile.systemStatus !==
    CandidateSearchProfileStatus.ATSFAILEDTOPARSE;

  const {
    markNoteAsRead,
    overrideMatchingCardStatus,
    changeRedFlagStatus,
    enrichCandidateProfile,
    fetchAndUpdateCandidateInState,
    updateCandidateDetails,
    updateCandidateProfileNote,
    clearCandidateProfile,
  } = useCandidateProfileMethods();

  const {
    id: profileId,
    atsProfileStatus,
    dataSourceId,
    status,
    systemStatus,
    enrichmentStatus,
    enrichmentInfo,
    firstName,
    lastName,
    invitedForInterview,
    candidateProfile: {
      email,
      enrichedProfile,
      attachments,
      workHistory,
      totalWorkExperience,
    },
    candidateProfile,
    atsUrl,
  } = currentCandidateProfile;

  const isCurrentSearchPausedOrClosed =
    currentSearchStatus === SearchProgressStatusEnum.CLOSED ||
    currentSearchStatus === SearchProgressStatusEnum.ONHOLD;

  const resumeAttachment = R.head(attachments);
  const resumeAttachmentId = resumeAttachment?.id ?? '';
  const resumeAttachmentDownloadName =
    resumeAttachment?.downloadName || phrases.DOWNLOAD_RESUME_DEFAULT_NAME;

  const customerCompanySettings = useCustomerProfileContext(
    customerProfileSelectors.customerCompanySettings
  );
  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const { fetchCustomerCompanyInfo } = useCustomerProfileMethods();

  const companyIds = useMemo(
    () =>
      R.uniq(
        workHistory
          .map((item: WorkHistoryData) => item.company?.id)
          .filter(Boolean)
      ),
    [workHistory]
  );

  // note is being fetched separately from candidate profile
  useEffect(() => {
    if (currentCandidateProfile?.id) {
      updateCandidateProfileNote(currentCandidateProfile.id);
    }
  }, [updateCandidateProfileNote, currentCandidateProfile?.id]);

  useEffect(() => {
    const getCompanies = async () => {
      const differentIds = R.symmetricDifference(
        R.pluck('id', companiesInfo),
        companyIds
      );
      if (differentIds.length === 0) return;
      setCompaniesLoading(true);
      await fetchCompaniesInfo(companyIds);
      setCompaniesLoading(false);
    };
    getCompanies();
  }, [companyIds, fetchCompaniesInfo, companiesInfo]);

  useEffect(() => {
    let timeoutId: number;
    if (targetCardId) {
      timeoutId = window.setTimeout(() => {
        const card = document.getElementById(
          CandidateProfileMatchingCard.generateContainerId(targetCardId)
        );
        candidateProfileRef.current.scrollTo({
          top: card.offsetTop,
          behavior: 'smooth',
        });
      }, 0);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetCardId]);

  // @ts-ignore
  const meta = statusIconMap[status];
  const { icon } = meta || {};

  const isNotProcessedBySystemCandidate =
    systemStatus === CandidateSearchProfileStatus.NOTPROCESSED;
  const isFailedToProcessBySystemCandidate =
    systemStatus === CandidateSearchProfileStatus.ATSFAILEDTOPARSE;
  const isParsedBySystemCandidate = !(
    isNotProcessedBySystemCandidate || isFailedToProcessBySystemCandidate
  );

  const onOverrideCardStatus = useCallback(
    (cardRefId, newStatus: SearchCriteriaMatchStatusEnum) => {
      overrideMatchingCardStatus({
        searchId,
        profileId,
        cardRefId,
        newStatus,
        tab: currentTab,
      });
    },
    [overrideMatchingCardStatus, searchId, profileId, currentTab]
  );

  const onChangeRedFlagStatus = useCallback(
    (redFlagRefId, newStatus: AlertStatusEnum) => {
      changeRedFlagStatus({
        searchId,
        profileId,
        redFlagRefId,
        newStatus,
        tab: currentTab,
      });
    },
    [changeRedFlagStatus, searchId, profileId, currentTab]
  );

  const profileTabs = useMemo<ProfileTabs>(
    () => ({
      [CandidateTabs.Resume]: {
        label: phrases.RESUME_TAB_LABEL,
        icon: 'resume-icon',
      },
      [CandidateTabs.Matching]: {
        label: phrases.MATCHING_TAB_LABEL,
        icon: 'matching-icon',
        isDisabled: isNotProcessedBySystemCandidate,
        isProcessing: isNotProcessedBySystemCandidate,
        isHidden: isFailedToProcessBySystemCandidate,
      },
      [CandidateTabs.WorkExperience]: {
        label: phrases.WORK_EXPERIENCE_TAB_LABEL,
        icon: 'work-experience-icon',
        isDisabled: isNotProcessedBySystemCandidate,
        isProcessing: isNotProcessedBySystemCandidate,
        isHidden: !hasWorkExperience,
      },
      [CandidateTabs.Contacts]: {
        label: phrases.CONTACTS_TAB_LABEL,
        icon: 'contacts-icon',
        isHidden: false,
      },
    }),
    [
      hasWorkExperience,
      isNotProcessedBySystemCandidate,
      isFailedToProcessBySystemCandidate,
    ]
  );

  const [activeTab, setActiveTab] = useState(CandidateTabs.Matching);

  useEffect(() => {
    setActiveTab(
      isParsedBySystemCandidate ? CandidateTabs.Matching : CandidateTabs.Resume
    );
  }, [isParsedBySystemCandidate]);

  useEffect(() => {
    if (candidateProfileRef?.current) {
      candidateProfileRef.current.scrollTop = 0;
    }
  }, [activeTab]);

  useEffect(() => {
    candidateProfileRef?.current?.scrollTo({ top: 0 });
  }, [profileId, candidateProfileRef]);

  // clear current candidate profile when user leaves page
  useEffect(() => {
    return clearCandidateProfile;
  }, [clearCandidateProfile]);

  useEffect(() => {
    const unsubscribeBeginQuestioning = subscribe(
      APP_EVENTS.QUESTIONING_START,
      () => fetchAndUpdateCandidateInState(searchId, profileId)
    );
    return () => {
      unsubscribeBeginQuestioning();
    };
  }, [searchId, profileId, fetchAndUpdateCandidateInState]);

  const [openedCardStacks, updateCardStack] = useState<
    Map<number | string, boolean>
  >(new Map());
  const openStackHandler = useCallback((id) => {
    updateCardStack((prevState) => {
      const nextState = new Map([...prevState]);
      nextState.set(id, true);
      return nextState;
    });
  }, []);

  const closeStackHandler = useCallback((id) => {
    updateCardStack((prevState) => {
      const nextState = new Map([...prevState]);
      nextState.delete(id);
      return nextState;
    });
  }, []);

  const openNoteHandler = useCallback(
    (interviewData) => {
      markNoteAsRead(searchId, profileId, interviewData, currentTab);
    },
    [profileId, searchId, markNoteAsRead, currentTab]
  );

  const candidateWarningCardsSection = useMemo(
    () => ({
      name: phrases.WARNING_SECTION_NAME,
      items: candidateProfile.redFlags,
      renderFn: (cardData: any) => (
        <CandidateProfileWarningCard
          isDisabled={isReadOnly}
          isOpened={openedCardStacks.has(cardData.id)}
          onOpenCardStack={openStackHandler}
          onCloseCardStack={closeStackHandler}
          cardData={cardData}
          onOpenNote={openNoteHandler}
          isQuestioningSent={invitedForInterview}
          onChangeRedFlagStatus={onChangeRedFlagStatus}
        />
      ),
    }),
    [
      invitedForInterview,
      onChangeRedFlagStatus,
      candidateProfile.redFlags,
      closeStackHandler,
      openStackHandler,
      openedCardStacks,
      openNoteHandler,
      isReadOnly,
    ]
  );

  const matchingTabSections = useMemo(() => {
    const cardsByImportance =
      mapCandidateDataToImportanceSections(candidateProfile);
    return [
      candidateWarningCardsSection,
      ...Object.entries(cardsByImportance).map(([importance, items]) => ({
        name: importanceToLabel[importance as SearchCriteriaImportanceEnum],
        items,
        renderFn: (cardData: any) => {
          const isCardOpened =
            openedCardStacks.has(cardData.id) ||
            cardData.id.toString() === targetCardId;
          const isNoteOpened = isCardOpened && targetCardShowNote;

          return (
            <CandidateProfileMatchingCard
              isDisabled={isReadOnly}
              isOpened={isCardOpened}
              isNoteOpened={isNoteOpened}
              isQuestioningSent={invitedForInterview}
              onOpenCardStack={openStackHandler}
              onCloseCardStack={closeStackHandler}
              cardData={cardData}
              onOpenNote={openNoteHandler}
              onOverrideCardStatus={onOverrideCardStatus}
            />
          );
        },
      })),
    ];
  }, [
    invitedForInterview,
    onOverrideCardStatus,
    targetCardId,
    targetCardShowNote,
    candidateProfile,
    candidateWarningCardsSection,
    closeStackHandler,
    openStackHandler,
    openedCardStacks,
    openNoteHandler,
    isReadOnly,
  ]);

  const redFlagTypes = useMemo(
    () => R.compose(R.uniq, R.map(R.prop('type')))(candidateProfile.redFlags),
    [candidateProfile.redFlags]
  );

  const filterFlagSettingsByCandidateRedFlags = useCallback(
    (item: RedFlagSettingsData) => {
      return item.enabled && redFlagTypes.includes(item.type);
    },
    [redFlagTypes]
  );

  const criteriaTabSections = useMemo(() => {
    const cardsByImportance = mapHeaderDataToImportanceSections(
      // @ts-ignore
      R.flatten<HeaderCriteriaData>(R.values(searchCriteria))
    );

    const redFlagSettingsData = R.filter(
      filterFlagSettingsByCandidateRedFlags,
      mapRedFlagsRequestToRedFlagData(redFlagsSettings)
    );

    return [
      {
        name: phrases.RED_FLAGS_SETTINGS_SECTION_NAME,
        items: redFlagSettingsData,
        cardsListClass: styles.flagSettingsCardsListClass,
        renderFn: (flagData: RedFlagSettingsData) => (
          <FlagSettingsCard
            isReadOnly
            cardLabel={flagData.category}
            initialState={FlagSettingsState.initial}
            className={styles.flagSettingsCard}
            flagData={flagData}
          />
        ),
      },
      ...Object.entries(cardsByImportance).map(([importance, items]) => ({
        name: importanceToLabel[importance as SearchCriteriaImportanceEnum],
        items,
        renderFn: (cardData: any) => (
          <CandidateProfileSearchCriteriaCard
            isOpened={openedCardStacks.has(cardData.id)}
            onOpenCardStack={openStackHandler}
            onCloseCardStack={closeStackHandler}
            cardData={cardData}
          />
        ),
      })),
    ];
  }, [
    filterFlagSettingsByCandidateRedFlags,
    closeStackHandler,
    openStackHandler,
    openedCardStacks,
    searchCriteria,
    redFlagsSettings,
  ]);

  const onClickDownload = useCallback(() => {
    if (!isResumeDownloading) {
      setIsResumeDownloading(true);
      downloadCandidateResume(
        dataSourceId,
        resumeAttachmentId,
        resumeAttachmentDownloadName
      ).finally(() => {
        setIsResumeDownloading(false);
      });
    }
  }, [
    dataSourceId,
    resumeAttachmentDownloadName,
    resumeAttachmentId,
    isResumeDownloading,
  ]);

  const onClickDownloadDebounced = useDebounce(
    onClickDownload,
    CLICK_DEBOUNCE_TIME_LONG,
    true
  );

  const tabButtons = useMemo(() => {
    const tabKeys = Object.keys<string>(
      R.pickBy((it) => !it.isHidden, profileTabs)
    );
    return tabKeys.map((tabKey) => (
      <button
        className={classNames(styles.candidateProfileTabButton, {
          [styles.active]: activeTab === tabKey,
          [styles.disabled]:
            profileTabs[tabKey].isDisabled || profileTabs[tabKey].isProcessing,
        })}
        key={tabKey}
        disabled={profileTabs[tabKey].isDisabled}
        onClick={() => setActiveTab(tabKey as CandidateTabs)}
      >
        <span className={styles.candidateProfileTabIconWrapper}>
          {profileTabs[tabKey].isProcessing ? (
            <Loader color="grey" />
          ) : (
            <SvgIcon
              className={styles.candidateProfileTabIcon}
              icon={profileTabs[tabKey].icon}
              width={profileTabs[tabKey].iconWidth}
              height={profileTabs[tabKey].iconHeight}
            />
          )}
        </span>
        <Paragraph
          short
          className={classNames({
            [styles.processingCandidate]: profileTabs[tabKey].isProcessing,
          })}
        >
          {profileTabs[tabKey].label}
        </Paragraph>
      </button>
    ));
  }, [profileTabs, setActiveTab, activeTab]);

  const [candidateDraftStatus, updateCandidateDraftStatus] =
    useState<CandidateSearchProfileStatusEnum>(status);

  useEffect(() => {
    if (candidateDraftStatus !== status) {
      updateCandidateDraftStatus(status);
    }
  }, [candidateDraftStatus, status]);

  const onChangeCandidateStatus = useCallback(
    (candidateStatus: CandidateSearchProfileStatusEnum) => {
      changeCandidateStatus(profileId, candidateDraftStatus, candidateStatus);
    },
    [changeCandidateStatus, profileId, candidateDraftStatus]
  );

  const onEnrichButtonClicked = useCallback(() => {
    enrichCandidateProfile(searchId, profileId).catch((error) => {
      if (error?.message.includes('capability is turned off')) {
        fetchCustomerCompanyInfo();
      }
    });
  }, [searchId, profileId, enrichCandidateProfile, fetchCustomerCompanyInfo]);

  const enrichId = useRef(null);
  useEffect(() => {
    clearInterval(enrichId.current);

    if (
      enrichmentStatus === EnrichmentStatus.STARTED ||
      enrichmentInfo?.status === EnrichmentInfoStatusEnum.INPROGRESS
    ) {
      enrichId.current = setInterval(async () => {
        fetchAndUpdateCandidateInState(searchId, profileId);
      }, ENRICHMENT_POLLING_TIMEOUT);
    }

    return () => clearInterval(enrichId.current);
  }, [
    currentTab,
    enrichId,
    fetchAndUpdateCandidateInState,
    searchId,
    profileId,
    enrichmentInfo,
    enrichmentStatus,
  ]);

  const isProfileEnriched = useMemo(
    () => enrichmentInfo?.status === EnrichmentInfoStatusEnum.ENRICHED,
    [enrichmentInfo?.status]
  );

  const isEnrichmentAllowedForApplicant = useMemo(
    () => firstName && lastName && !R.isEmpty(email),
    [firstName, lastName, email]
  );

  const isEnrichmentAllowedForCompany = useMemo(
    () => customerCompanySettings?.piplEnabled,
    [customerCompanySettings]
  );

  const isEnrichmentAllowedForUser = useMemo(
    () => customerCompanySettings?.enrichAllowRoles.includes(user?.role),
    [customerCompanySettings, user?.role]
  );

  const isEnrichmentAllowed = useMemo(
    () => isEnrichmentAllowedForCompany && isEnrichmentAllowedForUser,
    [isEnrichmentAllowedForCompany, isEnrichmentAllowedForUser]
  );

  const isDisabledForCompany = useMemo(
    () => !isEnrichmentAllowedForCompany && isProfileEnriched,
    [isEnrichmentAllowedForCompany, isProfileEnriched]
  );

  const isDisabledForUser = useMemo(
    () => !isEnrichmentAllowedForUser && isProfileEnriched,
    [isEnrichmentAllowedForUser, isProfileEnriched]
  );

  const isDisabledForSearch = useMemo(
    () => isCurrentSearchPausedOrClosed && isProfileEnriched,
    [isCurrentSearchPausedOrClosed, isProfileEnriched]
  );

  const isDisabledForEnrichment = useMemo(
    () =>
      isDisabledForCompany ||
      isDisabledForUser ||
      isDisabledForSearch ||
      isTrialExpired,
    [
      isDisabledForCompany,
      isDisabledForUser,
      isDisabledForSearch,
      isTrialExpired,
    ]
  );

  const shouldShowEnrichmentButton = useMemo(
    () =>
      (isEnrichmentAllowed && !isCurrentSearchPausedOrClosed) ||
      isDisabledForEnrichment,
    [
      isEnrichmentAllowed,
      isCurrentSearchPausedOrClosed,
      isDisabledForEnrichment,
    ]
  );

  const shouldShowTooltip = useMemo(
    () =>
      isDisabledForCompany ||
      isTrialExpired ||
      isDisabledForSearch ||
      !isEnrichmentAllowedForApplicant ||
      enrichmentInfo?.outdated,
    [
      isDisabledForCompany,
      isTrialExpired,
      isDisabledForSearch,
      isEnrichmentAllowedForApplicant,
      enrichmentInfo?.outdated,
    ]
  );

  const saveCandidateContactsChanges = useCallback(
    (formValues: CandidateProfileContactFormT) => {
      const { firstName, lastName, primaryEmail, emails, generalInfo } =
        currentCandidateProfile;

      updateCandidateDetails(
        searchId,
        profileId,
        {
          firstName,
          lastName,
          emails,
          generalInfo,
          ...formValues,
          primaryEmail:
            formValues.primaryEmail || formValues.emails?.[0] || primaryEmail,
        },
        currentTab
      );
    },
    [
      currentCandidateProfile,
      searchId,
      currentTab,
      profileId,
      updateCandidateDetails,
    ]
  );
  const updateCandidateProfileNoteWithCandidate = useCallback(
    (value: string) => {
      updateCandidateProfileNote(
        currentCandidateProfile.id,
        currentCandidateProfileNote?.refId,
        value
      );
    },
    [
      updateCandidateProfileNote,
      currentCandidateProfile.id,
      currentCandidateProfileNote?.refId,
    ]
  );
  const updateCandidateProfileNoteDebounced = useDebounce(
    updateCandidateProfileNoteWithCandidate,
    INPUT_DEBOUNCE_TIME
  );

  const renderCurrentStatus = useCallback(
    (status: CandidateSearchProfileStatusEnum, className: string) => {
      const { currentStatus, icon } = statusIconMap[status];
      return (
        <div
          className={classNames(
            styles.popupCurrentStatus,
            statusToggleStyles.popupCurrentStatus,
            className
          )}
          key={status}
        >
          <SvgIcon width="2em" height="2em" icon={`${icon}status`} />
          <div className={statusToggleStyles.popupCandidateInfoWrapper}>
            <div
              className={classNames(
                styles.popupCandidateStatus,
                statusToggleStyles.popupCandidateStatus
              )}
            >
              <UIText small>{currentStatus}</UIText>
            </div>
          </div>
        </div>
      );
    },
    []
  );

  return (
    <div className={styles.candidateProfileWrapper}>
      <Button
        variant={Button.variants.CLOSE}
        icon="close-icon-2"
        className={styles.closeButton}
        onClick={onClose}
      />
      <div ref={candidateProfileRef} className={styles.candidateProfile}>
        <div className={styles.candidateProfileHeader}>
          <CandidateName
            profileId={profileId}
            activeTab={activeTab}
            firstName={currentCandidateProfile.firstName}
            lastName={currentCandidateProfile.lastName}
            saveChanges={saveCandidateContactsChanges}
            isReadOnly={!isStandaloneAtsUser || !canEditCandidateProfile}
            className={styles.candidateName}
          />
          <div className={styles.candidateStatusWrapper}>
            <div className={styles.candidateStatus}>
              {!isNotProcessedBySystemCandidate ? (
                <CandidateStatusToggle
                  onChange={onChangeCandidateStatus}
                  moveToApplied={
                    moveCandidateToApplied &&
                    (() => moveCandidateToApplied(currentCandidateProfile))
                  }
                  moveToPassive={
                    moveCandidateToPassive &&
                    (() => moveCandidateToPassive(currentCandidateProfile))
                  }
                  initialStatus={candidateDraftStatus}
                  systemStatus={systemStatus}
                  popupLocation={StatusPopupLocation.CandidateProfile}
                  renderCurrentStatus={renderCurrentStatus}
                  className={styles.popupContainer}
                  disabled={isReadOnly}
                >
                  <button
                    className={styles.candidateStatusButton}
                    disabled={isReadOnly}
                  >
                    {!isReadOnly && (
                      <SvgIcon
                        className={styles.candidateStatusIcon}
                        icon={`${icon}status`}
                      />
                    )}
                    {getCandidateProfileLabel(status)}
                  </button>
                </CandidateStatusToggle>
              ) : (
                <>
                  <Loader color="blue" className={styles.processingLoader} />
                  <UIText small className={styles.disabled}>
                    {phrases.CANDIDATE_PROFILE_PROCESSING_APPLICANT_TEXT}
                  </UIText>
                </>
              )}
            </div>
            {atsUrl && (
              <Link
                to={{ pathname: atsUrl }}
                target="_blank"
                className={styles.atsLink}
              >
                <TooltipWrapper
                  enabled
                  flexGrow={false}
                  tooltip={phrases.getExternalAtsTooltip(ATSNames[atsType])}
                  containerClassName={styles.candidateAtsStatusTooltip}
                  triggerClassName={styles.candidateAtsStatusWrapper}
                  closeOnReferenceHidden={false}
                  placement="bottom"
                >
                  <div className={styles.candidateAtsStatus}>
                    <img src={atsLogos[atsType]} />
                    <UIText small className={styles.statusLabel}>
                      {phrases.OPEN_IN_ATS_TEXT}
                    </UIText>
                  </div>
                </TooltipWrapper>
              </Link>
            )}
          </div>
        </div>

        <div className={styles.innerContent}>
          <aside className={styles.sidePanel}>
            <div className={styles.sideContentWrapper}>
              <div
                data-testid="candidate-profile-tabs"
                className={classNames(styles.sideMenu, {
                  [styles.noAtsStatus]: !atsProfileStatus,
                })}
              >
                {tabButtons}
              </div>
              <div className={styles.sideContent}>
                {activeTab === CandidateTabs.Resume ? null : (
                  <div>
                    {shouldShowEnrichmentButton && (
                      <EnrichButton
                        className={styles.enrichmentButtonContainer}
                        enrichmentStatus={enrichmentStatus}
                        enrichmentInfo={enrichmentInfo}
                        onClick={onEnrichButtonClicked}
                        isDisabledForCompany={isDisabledForCompany}
                        disabled={
                          !isEnrichmentAllowedForApplicant ||
                          isTrialExpired ||
                          isCurrentSearchPausedOrClosed ||
                          isDisabledForUser
                        }
                        showTooltip={shouldShowTooltip}
                        tooltip={getEnrichmentTooltip({
                          isDisabledForCompany,
                          isSearchPausedOrClosed: isCurrentSearchPausedOrClosed,
                          isTrialExpired,
                          isDisabledForApplicant:
                            !isEnrichmentAllowedForApplicant,
                          outdated: enrichmentInfo?.outdated,
                        })}
                      />
                    )}
                  </div>
                )}
                <CandidateProfileNote
                  key={currentCandidateProfileNote?.candidateId}
                  note={currentCandidateProfileNote}
                  className={styles.candidateProfileNoteContainer}
                  onChange={updateCandidateProfileNoteDebounced}
                />
              </div>
            </div>
          </aside>

          <div
            className={classNames(styles.mainContent, {
              [styles.workExperienceTab]:
                activeTab === CandidateTabs.WorkExperience,
            })}
          >
            {activeTab === CandidateTabs.Resume && !!attachments.length && (
              <CandidateProfileDocumentsTab
                dataSourceId={dataSourceId}
                attachments={attachments}
                onDownloadClick={onClickDownloadDebounced}
                isResumeDownloading={isResumeDownloading}
              />
            )}
            {activeTab === CandidateTabs.Matching && (
              <>
                <CriteriaTabControl key={currentCandidateProfile.applicantId}>
                  {(activeTab: any) => (
                    <>
                      {activeTab === CriteriaTabs.Criteria ? (
                        <CriteriaTab<SearchCriteriaData | RedFlagSettingsData>
                          sections={criteriaTabSections}
                        />
                      ) : (
                        <CriteriaTab<BaseCandidateData>
                          sections={matchingTabSections}
                        />
                      )}
                    </>
                  )}
                </CriteriaTabControl>
              </>
            )}
            {activeTab === CandidateTabs.WorkExperience && (
              <div className={styles.workExperienceWrapper}>
                <WorkTimeline
                  workHistory={workHistory}
                  companiesInfo={companiesInfo}
                  areCompaniesLoading={areCompaniesLoading}
                  totalWorkExperience={totalWorkExperience}
                />
              </div>
            )}
            {activeTab === CandidateTabs.Contacts && (
              <ContactDetails
                isAddContactsDisabled={!canEditCandidateProfile}
                saveChanges={saveCandidateContactsChanges}
                enrichedProfile={enrichedProfile}
                isExternalATS={isExternalATS}
                isTrialExpired={isTrialExpired}
                profileId={profileId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type CriteriaTabProps<T> = {
  sections: {
    name: string;
    items: any;
    isDisabled?: boolean;
    cardsListClass?: string;
    renderFn: (cardData: T) => ReactNode;
  }[];
};
export const CriteriaTab = <T,>(
  props: PropsWithChildren<CriteriaTabProps<T>>
) => {
  const { sections } = props;

  return (
    <>
      {sections.map((section) => {
        return R.isEmpty(section.items) ? null : (
          <CardsSection key={section.name} sectionName={section.name}>
            <ul
              className={classNames(styles.cardsList, section.cardsListClass)}
            >
              {section.items.map((item: any, index: any) => {
                return <li key={index}>{section.renderFn(item)}</li>;
              })}
            </ul>
          </CardsSection>
        );
      })}
    </>
  );
};
