import React from 'react';
import R from '@air/third-party/ramda';
import * as phrases from 'constants/phrases';
import { TooltipWrapper, Paragraph } from '@air/components';

import styles from './JobOwnersList.css';

const PLURAL_AMOUNT_HELPER = 1; // total items - 1 > 0 means that we have to apply plural form to the word
const AMOUNT_OF_SHOWN_ITEMS = 2;

export const JobOwnersList: React.FC<{
  items: Array<string>;
  listPrefix?: string;
}> = ({ items, listPrefix = phrases.REQUISITION_OWNER_PREFIX }) => {
  const [owner1, owner2, ...restOwners] = items;
  const itemsCount = items.length;

  const restLabel = phrases.getRestRequisitionOwnersLabel(
    itemsCount - AMOUNT_OF_SHOWN_ITEMS
  );
  const additionalOwners = restOwners.join(', ');
  const isOwnerPrefix = listPrefix === phrases.REQUISITION_OWNER_PREFIX;

  if (isOwnerPrefix) {
    listPrefix = phrases.getRequisitionOwnersLabel(
      listPrefix,
      itemsCount - PLURAL_AMOUNT_HELPER
    );
  }

  return (
    <Paragraph small>
      {listPrefix} {owner1}
      {owner2 ? `, ${owner2} ` : ''}
      {!R.isEmpty(restOwners) && (
        <TooltipWrapper
          enabled={!!additionalOwners.length}
          placement="right"
          trigger="hover"
          triggerClassName={styles.additionalOwnersList}
          tooltip={additionalOwners}
        >
          {phrases.AND} {restLabel}
        </TooltipWrapper>
      )}
    </Paragraph>
  );
};
JobOwnersList.displayName = 'JobOwnersList';
