/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum RedFlagAlertType {
  BUZZWORDS = 'BUZZ_WORDS',
  DIPLOMAMILL = 'DIPLOMA_MILL',
  GAPSINEMPLOYMENTHISTORY = 'GAPS_IN_EMPLOYMENT_HISTORY',
  MISSEDSTARTENDMONTHINEMPLOYMENTRECORD = 'MISSED_START_END_MONTH_IN_EMPLOYMENT_RECORD',
  TOOMANYSKILLS = 'TOO_MANY_SKILLS',
  TOOMANYJOBCHANGES = 'TOO_MANY_JOB_CHANGES',
  VAGUEWORDS = 'VAGUE_WORDS',
  WORKEDTOOLONGINTHESAMECOMPANY = 'WORKED_TOO_LONG_IN_THE_SAME_COMPANY',
  UNIMPRESSIVESKILLS = 'UNIMPRESSIVE_SKILLS',
  UNKNOWNUNIVERSITY = 'UNKNOWN_UNIVERSITY',
  NOCAREERPROGRESS = 'NO_CAREER_PROGRESS',
  UNKNOWNCOMPANY = 'UNKNOWN_COMPANY',
  EMPLOYMENTDATADISCREPANCY = 'EMPLOYMENT_DATA_DISCREPANCY',
  EDUCATIONDATADISCREPANCY = 'EDUCATION_DATA_DISCREPANCY',
  DESCRIPTIONSTOOLONG = 'DESCRIPTIONS_TOO_LONG',
}
