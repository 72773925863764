import React from 'react';
import {
  CandidateSearchProfileStatusEnum,
  normalizeCandidateName,
} from 'domain/CandidateData';
import classNames from 'classnames';

import {
  Checkbox,
  UIText,
  TooltipWrapper,
  Paragraph,
  Tooltip,
  Button,
} from '@air/components';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import * as phrases from 'constants/phrases';
import { getCandidateIcon } from 'features/SelectRecipientsView/selectRecipientsViewHelpers';
import { JobEmailCandidate } from '@air/api';

import styles from 'features/SelectRecipientsView/SelectRecipientsView.css';

export const RecipientsSection: React.FC<{
  id: CandidateSearchProfileStatusEnum;
  sentMode?: boolean;
  isOpened: boolean;
  displayName: string;
  className?: string;
  recipients: JobEmailCandidate[];
  selectedRecipients: string[];
  onSectionTitleClicked: (id: string) => void;
  onSectionCheckboxClicked?: (checked: boolean) => void;
  onRecipientSelectionChanged?: (recipientId: string, checked: boolean) => void;
  openCandidate: (candidateId: string) => void;
  onRecipientNameClick: (recipientId: string) => void;
  candidatePreviewId?: string;
}> = ({
  id,
  sentMode,
  isOpened,
  displayName,
  className,
  recipients = [],
  selectedRecipients,
  onSectionTitleClicked,
  onSectionCheckboxClicked,
  onRecipientSelectionChanged,
  openCandidate,
  onRecipientNameClick,
  candidatePreviewId,
}) => {
  const isSectionDisabled =
    !recipients.length || !recipients.filter((it) => it.primaryEmail).length;

  const isSectionCheckboxChecked =
    isSectionDisabled ||
    selectedRecipients.some((id) => recipients.find((it) => it.id === id));

  return (
    <>
      <div
        className={classNames(styles.sectionHeaderWrapper, className, {
          [styles.isClosed]: !isOpened,
        })}
      >
        <div className={styles.sectionHeader}>
          {onSectionCheckboxClicked && (
            <Checkbox
              disabled={isSectionDisabled}
              checked={isSectionCheckboxChecked}
              onChange={(value) => onSectionCheckboxClicked(value.checked)}
            />
          )}
          <UIText
            className={classNames(styles.sectionTitle, {
              [styles.disabled]: isSectionDisabled,
            })}
            onClick={() => onSectionTitleClicked(id)}
          >
            {displayName}
          </UIText>
          <UIText
            className={classNames(styles.sectionHeaderCounter, {
              [styles.disabled]: isSectionDisabled,
            })}
          >
            {recipients.length}
          </UIText>
        </div>
      </div>
      <div
        className={styles.sectionContent}
        style={{ '--items-count': recipients.length } as React.CSSProperties}
      >
        {recipients.map((item) => {
          const candidateIcon = getCandidateIcon(item, sentMode);
          const candidateName = normalizeCandidateName(
            item.firstName,
            item.lastName
          );

          const isCandidatePreviewSelected = item.id === candidatePreviewId;

          return (
            <div
              className={classNames(styles.candidateName, {
                [styles.previewSelected]: isCandidatePreviewSelected,
              })}
              key={item.id}
            >
              {candidateIcon
                ? candidateIcon
                : onRecipientSelectionChanged && (
                    <Checkbox
                      checked={selectedRecipients.includes(item.id)}
                      onChange={(value) =>
                        onRecipientSelectionChanged(item.id, value.checked)
                      }
                    />
                  )}
              <TooltipWrapper
                enabled
                placement="top"
                trigger="hover"
                delayShow={TOOLTIP_DELAY_TIME_LONG}
                triggerClassName={styles.candidateNameTooltip}
                tooltip={candidateName}
                flexGrow={false}
              >
                <Paragraph
                  className={styles.candidateNameText}
                  onClick={() => onRecipientNameClick(item.id)}
                >
                  <span>{candidateName}</span>
                </Paragraph>
              </TooltipWrapper>
              <Tooltip
                trigger="hover"
                delayShow={TOOLTIP_DELAY_TIME_LONG}
                tooltip={phrases.SELECT_RECIPIENTS_OPEN_CANDIDATE_TOOLTIP_TEXT}
                flexGrow={false}
              >
                <Button
                  className={styles.candidateOpenButton}
                  variant={Button.variants.INLINE}
                  icon="open-in-new-tab"
                  onClick={() => openCandidate(item.id)}
                />
              </Tooltip>
            </div>
          );
        })}
      </div>
    </>
  );
};
