import React, { useCallback } from 'react';
import classNames from 'classnames';
import useToggle from 'react-use/lib/useToggle';
import { SelectDropdown, SelectOption, SvgIcon } from '@air/components';
import { RoleName, InternalStatus } from '@air/api';
import * as phrases from 'constants/phrases';
import { roleToRoleLabelMap } from 'domain/UserManagement/User';
import styles from './UserCardStyles.css';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CHANGE_USER_ROLE } from 'constants/gaLabels';

export const generalRoleOptions = [RoleName.CUSTOMERADMIN, RoleName.RECRUITER];

export const RoleSelect: React.FC<{
  role: RoleName;
  updateRole: (roleKey: RoleName) => void;
  isDisabled?: boolean;
}> = ({ role, updateRole, isDisabled = false }) => {
  const [isOpened, toggleDropdown] = useToggle(false);

  const handleUpdateRole = (roleKey: RoleName) => {
    updateRole(roleKey);
    trackEvent({
      category: GACategory.Settings,
      label: GA_LABEL_CHANGE_USER_ROLE,
    });
  };

  return (
    <>
      <button
        type="button"
        disabled={isDisabled}
        className={styles.generalRoleFieldButton}
        onClick={() => toggleDropdown(true)}
      >
        <div className={styles.fieldIcon}>
          <SvgIcon icon="user-icon" className={styles.userIcon} />
        </div>
        <dl className={styles.fieldLabel}>
          <dt>{phrases.USER_CARD_GENERAL_ROLE_LABEL}</dt>
          <dd>{roleToRoleLabelMap[role]}</dd>
        </dl>
      </button>
      <SelectDropdown
        isOpen={isOpened}
        closeOnClickOutside
        onClose={() => toggleDropdown(false)}
        className={styles.generalRoleDropdown}
      >
        {generalRoleOptions.map((roleKey) => {
          const isSelected = roleKey === role;
          return (
            <SelectOption
              key={roleKey}
              className={styles.generalRoleDropdownOption}
              icon={
                isSelected ? (
                  <SvgIcon
                    icon="checkbox-checked"
                    className={styles.checkedIcon}
                  />
                ) : (
                  <SvgIcon
                    icon="checkbox-unchecked"
                    className={styles.uncheckedIcon}
                  />
                )
              }
              label={roleToRoleLabelMap[roleKey]}
              isSelected={isSelected}
              onClick={() => {
                handleUpdateRole(roleKey);
                toggleDropdown(false);
              }}
            />
          );
        })}
      </SelectDropdown>
    </>
  );
};
RoleSelect.displayName = 'RoleSelect';

export const ActivateUserButton: React.FC<{
  isUserInactive: boolean;
  value: InternalStatus;
  onChange: React.ReactEventHandler;
  disabled?: boolean;
}> = ({ isUserInactive, onChange, disabled = false }) => {
  const onButtonClick = useCallback(
    (event) => {
      if (disabled) return;
      onChange(event);
    },
    [onChange, disabled]
  );
  return (
    <div
      className={classNames(styles.field, styles.fieldAction, {
        [styles.disabledField]: disabled,
      })}
      onClick={onButtonClick}
    >
      <div className={styles.fieldIcon}>
        <SvgIcon icon="logout" className={styles.logoutIcon} />
      </div>
      <span>
        {isUserInactive
          ? phrases.USER_CARD_RE_ACTIVATE_USER
          : phrases.USER_CARD_DEACTIVATE_USER}
      </span>
    </div>
  );
};
ActivateUserButton.displayName = 'ActivateUserButton';
