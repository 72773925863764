// Courtesy of https://github.com/component/debounce
// with added return value from `flush()` method
// because it seems that original library is not supported.

export function debounce<A extends (...args: any[]) => any>(
  this: unknown,
  func: A,
  wait: number,
  immediate?: boolean
): typeof debounced {
  let timeout: number,
    args: Parameters<A>,
    context: typeof this,
    timestamp: number,
    result: ReturnType<A>;

  function later() {
    const last = Date.now() - timestamp;

    if (last < wait && last >= 0) {
      timeout = window.setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        context = args = null;
      }
    }
  }

  const debounced = (
    ...params: Parameters<A>
  ): ReturnType<A> & {
    flush: typeof flush;
    clear: typeof clear;
  } => {
    context = this;
    args = params;
    timestamp = Date.now();
    const callNow = immediate && !timeout;
    if (!timeout) timeout = window.setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };

  function clear() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }
  debounced.clear = clear;

  function flush() {
    if (timeout) {
      result = func.apply(context, args);
      context = args = null;

      clearTimeout(timeout);
      timeout = null;

      return result;
    }
  }
  debounced.flush = flush;

  return debounced;
}
