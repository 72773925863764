import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { Checkbox, UIText, TooltipWrapper } from '@air/components';
import { CheckboxOptionT } from '@air/domain/Forms/types';
import styles from './CheckboxList.css';
import { Placement } from '@popperjs/core';

type CheckboxListGroup = CheckboxOptionT[];
type CheckboxListMap = { [key: string]: CheckboxListGroup };
type CheckboxListOptions = CheckboxListGroup | CheckboxListMap;
type CheckboxListProps = {
  options: CheckboxListOptions;
  isDisabled?: boolean;
  onChange: (values: CheckboxListOptions) => void;
  wrapperClassName?: string;
  placement?: Placement;
};
export const CheckboxList: React.FC<CheckboxListProps> = ({
  options,
  isDisabled,
  onChange,
  wrapperClassName,
  placement = 'bottom',
}) => {
  const onChangeCb = useCallback(
    (newValue: { checked: boolean; label: string }) => {
      const resultMapper = (option: CheckboxOptionT) => {
        if (option.label === newValue.label) return { ...option, ...newValue };
        return option;
      };

      if (Array.isArray(options)) {
        const result = options.map(resultMapper);
        onChange(result);
        return;
      }

      const result = Object.keys(options).reduce((acc, groupName) => {
        const groupOptions = options[groupName];
        acc[groupName] = groupOptions.map(resultMapper);
        return acc;
      }, {} as CheckboxListMap);
      onChange(result);
    },
    [onChange, options]
  );

  const checkboxMapper = useCallback(
    ({ checked, label, disabled, tooltip }) => (
      <TooltipWrapper
        key={label}
        enabled={disabled}
        placement={placement}
        trigger="hover"
        tooltip={tooltip}
        containerClassName={styles.tooltipContainer}
      >
        <Checkbox
          disabled={disabled || isDisabled}
          label={label}
          checked={checked && !disabled}
          onChange={onChangeCb}
          className={styles.checkboxListLine}
        />
      </TooltipWrapper>
    ),
    [onChangeCb, isDisabled, placement]
  );

  const listElements = useMemo(() => {
    if (Array.isArray(options)) {
      return options.map(checkboxMapper);
    }

    return Object.keys(options).reduce((acc, groupName) => {
      const groupLabel = (
        <label className={styles.groupLabel} key={groupName}>
          <UIText tiny bold>
            {groupName}
          </UIText>
        </label>
      );
      const groupOptions = options[groupName].map(checkboxMapper);
      return acc.concat(groupLabel, groupOptions);
    }, []);
  }, [options, checkboxMapper]);

  return (
    <div className={classNames(styles.checkboxList, wrapperClassName)}>
      <div className={styles.scrollableArea}>
        <div className={styles.content}>{listElements}</div>
      </div>
    </div>
  );
};
CheckboxList.displayName = 'CheckboxList';
