import {
  CardType,
  BaseCriteriaData,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria';
import {
  getValuesForTopStack,
  getValuesForBottomStack,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import { ReactNode } from 'react';
import {
  OnLabelClickCallback,
  OnRemoveItemCallback,
} from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';

export type CriteriaEditCardStackItem = {
  key: string;
  label: string;
  className?: string;
  labelClassName?: string;
  children?: ReactNode;
  onRemoveItem?: OnRemoveItemCallback;
  onLabelClick?: OnLabelClickCallback;
  isLabelInteractive?: boolean;
};

export type CriteriaEditCardStackItems = [
  CriteriaEditCardStackItem[],
  CriteriaEditCardStackItem[]
];

export const generateTopStackItems = (
  cardData: BaseCriteriaData
): CriteriaEditCardStackItem[] => {
  const topStackValues = getValuesForTopStack(cardData);
  switch (cardData.cardType) {
    case CardType.skill:
    case CardType.role:
    case CardType.industry:
    case CardType.company:
    case CardType.location:
    case CardType.certification:
      return topStackValues.map(
        (item: SearchCriteriaListValue, index: number) => ({
          key: `${item?.value}-${index}`,
          label: phrases.OR_STACK_ITEM_LABEL,
          isLabelInteractive: false,
        })
      );
    case CardType.degree:
    case CardType.major:
    case CardType.institution:
      return topStackValues.map(
        (item: SearchCriteriaListValue, index: number) => ({
          key: `${item?.value}-${index}`,
          label: phrases.IDEAL_STACK_ITEM_LABEL,
        })
      );
    default:
      return [];
  }
};

export const generateBottomStackItems = (
  cardData: BaseCriteriaData
): CriteriaEditCardStackItem[] => {
  const bottomStackValues = getValuesForBottomStack(cardData);
  switch (cardData.cardType) {
    case CardType.degree:
    case CardType.major:
    case CardType.institution:
      return bottomStackValues.map(
        (item: SearchCriteriaListValue, index: number) => ({
          key: `${item?.value}-${index}`,
          label: phrases.ACCEPTABLE_STACK_ITEM_LABEL,
        })
      );
    default:
      return [];
  }
};

export type CriteriaEditCardMainCardLabel = {
  disabled: boolean;
  text: string;
  onClick?: (...args: any[]) => any;
  className?: string;
};

export const getMainCardLabel = (
  cardData: BaseCriteriaData
): CriteriaEditCardMainCardLabel => {
  const topStackValues = getValuesForTopStack(cardData);
  const mainCard = getMainCard(cardData);
  switch (cardData.cardType) {
    case CardType.degree:
    case CardType.major:
    case CardType.institution:
      return {
        disabled: !topStackValues.length || R.isNullOrEmpty(mainCard?.label),
        text: phrases.IDEAL_STACK_ITEM_LABEL,
      };
    default:
      return null;
  }
};

const generateCardTopLabel =
  (
    inactiveLabelText: string,
    activeLabelText: string,
    isCardLabelEnabled: boolean
  ) =>
  (isMaxListSizeReached: boolean, isCardValid: boolean) =>
    isMaxListSizeReached
      ? inactiveLabelText
      : `${isCardLabelEnabled && isCardValid ? '+' : ''}${activeLabelText}`;

export const getCardTopLabel = (
  cardData: BaseCriteriaData,
  isCardLabelEnabled: boolean
) => {
  switch (cardData.cardType) {
    case CardType.degree:
      return generateCardTopLabel(
        phrases.DEGREE_CRITERIA_TOP_LABEL_PLURAL,
        phrases.DEGREE_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.major:
      return generateCardTopLabel(
        phrases.MAJOR_CRITERIA_TOP_LABEL_PLURAL,
        phrases.MAJOR_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.institution:
      return generateCardTopLabel(
        phrases.INSTITUTION_CRITERIA_TOP_LABEL_PLURAL,
        phrases.INSTITUTION_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.skill:
      return generateCardTopLabel(
        phrases.SKILL_CRITERIA_TOP_LABEL_PLURAL,
        phrases.SKILL_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.industry:
      return generateCardTopLabel(
        phrases.INDUSTRY_CRITERIA_TOP_LABEL_PLURAL,
        phrases.INDUSTRY_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.location:
      return generateCardTopLabel(
        phrases.LOCATION_CRITERIA_TOP_LABEL_PLURAL,
        phrases.LOCATION_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.company:
      return generateCardTopLabel(
        phrases.COMPANY_CRITERIA_TOP_LABEL_PLURAL,
        phrases.COMPANY_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.certification:
      return generateCardTopLabel(
        phrases.CERTIFICATION_CRITERIA_TOP_LABEL_PLURAL,
        phrases.CERTIFICATION_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.role:
      return generateCardTopLabel(
        phrases.ROLE_CRITERIA_TOP_LABEL_PLURAL,
        phrases.ROLE_CRITERIA_TOP_LABEL_SINGULAR,
        isCardLabelEnabled
      );
    case CardType.managerial:
      return () => phrases.MANAGERIAL_CRITERIA_TOP_LABEL;
    case CardType.professional:
      return () => phrases.PROFESSIONAL_CRITERIA_TOP_LABEL;
    case CardType.question:
      return () => phrases.QUESTION_CRITERIA_TOP_LABEL;
    default:
      return () => '';
  }
};
