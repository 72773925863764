import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as H from 'history';
import classNames from 'classnames';
import { Route, RouteComponentProps } from 'react-router';
import R from '@air/third-party/ramda';

import {
  Button,
  FeatureView,
  JobTitle,
  LoadingTextPlaceholder,
  SearchResultsView,
  WatchList,
} from 'components';

import {
  SearchProgressStatusEnum,
  SearchExtendedResponse,
  ProfileSort,
} from '@air/api/models';
import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import { SearchListItemT, getSearchTitle } from 'domain/Kanban/Kanban';

import { CandidateQuery, useCandidateQuery } from 'hooks/useCandidateQuery';
import {
  CheckboxList,
  LabeledDropdown,
  TabsControl,
  UIText,
  TooltipWrapper,
} from '@air/components';
import { TabItem } from '@air/components/TabsControl/TabsControl';
import { useCandidateProfileMethods } from 'providers/CandidateProfileProvider';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData';
import {
  useLineupCriteriaConfig,
  useLineupTabs,
} from 'components/LineupTable/hooks';
import { CriteriaConfig } from 'domain/HeaderData/HeaderDataMapper';
import { LineupTabs } from '@air/constants/tabs';
import {
  getLineupTabData,
  mapSectionsToCandidateStatuses,
  isMatchMinerTab,
  isPassiveTab,
  isActiveTab,
  isMatchServiceTab,
} from 'domain/CandidateData/CandidateLineupData';
import { UploaderButton, UtilitiesControls } from 'features';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import {
  kanbanSelectors,
  customerProfileSelectors,
  appSelectors,
} from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';

// imports from styles
import styles from 'features/ClosedSection/ClosedSection.css';
import interviewSectionStyles from 'features/InterviewSection/InterviewSection.css';
import interviewViewStyles from 'features/InterviewSection/InterviewView/InterviewView.css';
import commonStyles from 'styles/commonStyles.css';
import { useAppContext, useAppMethods } from 'providers/AppProvider';
import { GACategory } from '@air/domain/Common/GATypes';
import {
  CLICK_DEBOUNCE_TIME_LONG,
  TOOLTIP_DELAY_TIME_LONG,
} from '@air/constants/app';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit } from 'hooks/usePubSub';
import { LoadMoreApplicantsButton } from 'features/InterviewSection/LoadMoreApplicantsSection/LoadMoreApplicantsSection';
import { useComputedCounters } from 'domain/SearchCounters/useComputedCounters';
import { SortDropdown } from 'features/SortDropdown/SortDropdown';
import { useMatchServiceAvailabilityCheck } from 'domain/MatchServices/useMatchServiceSettings';
import { useLineupSearchId } from 'domain/LineupTable/useLineupSearchId';

type Props = RouteComponentProps<{
  dataSourceId?: string;
  jobDescriptionId?: string;
}>;

export const ClosedSection: React.FC<Props> = React.memo(
  ({ match, history }) => {
    const currentSearch = useKanbanContext(kanbanSelectors.currentSearch);
    const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
    const currentSearchAppliedCount = useKanbanContext(
      kanbanSelectors.currentSearchAppliedCount
    );
    const dataSourceId = useCustomerProfileContext(
      customerProfileSelectors.dataSourceId
    );
    const currentMatchMinerSearch = useKanbanContext(
      kanbanSelectors.currentMatchMinerSearch
    );
    const currentMatchMinerSearchId = useKanbanContext(
      kanbanSelectors.currentMatchMinerSearchId
    );
    const currentMatchScoutSearch = useKanbanContext(
      kanbanSelectors.currentMatchScoutSearch
    );
    const currentMatchScoutSearchId = useKanbanContext(
      kanbanSelectors.currentMatchScoutSearchId
    );
    const closedSearchesList = useKanbanContext(
      kanbanSelectors.closedSearchesList
    );

    const { reopenClosedInterview, fetchSearch, clearCurrentSearch } =
      useKanbanMethods();

    const { loadAllCandidates, updateCandidatesForStatus } =
      useCandidateProfileMethods();

    const isTrialExpired = useCustomerProfileContext(
      customerProfileSelectors.isTrialExpired
    );
    const isPassiveSearchEnabled = useCustomerProfileContext(
      customerProfileSelectors.isPassiveSearchEnabled
    );

    const isLeftSideBarCollapsed = useAppContext(
      appSelectors.isLeftSideBarCollapsed
    );

    const { setRightSidebarPanel } = useAppMethods();

    const openedSections = useRef<{
      active: CandidateSearchProfileStatusEnum[];
      passive: CandidateSearchProfileStatusEnum[];
      matchMiner: CandidateSearchProfileStatusEnum[];
      matchScout: CandidateSearchProfileStatusEnum[];
    }>({
      active: [],
      passive: [],
      matchMiner: [],
      matchScout: [],
    });

    const updateOpenedSections = useCallback((sections, currentTab) => {
      openedSections.current[currentTab as LineupTabs] = sections;
    }, []);

    const {
      computedCounters,
      computedPassiveCounters,
      computedMatchMinerCounters,
      computedMatchScoutCounters,
    } = useComputedCounters();

    const hasDataSourceId = match.params?.dataSourceId;
    const jobDescriptionId = match.params?.jobDescriptionId;
    const [isCandidatesListLoading, setCandidatesListLoading] = useState(false);

    const [currentTab, closedLineupTabs, onTabChange] = useLineupTabs({
      activeCount: computedCounters?.applicants,
      passiveCount: computedPassiveCounters?.applicants,
      matchMinerCount: computedMatchMinerCounters?.applicants,
      matchScoutCount: computedMatchScoutCounters?.applicants,
      isPassiveSearchEnabled,
      activeSearchId: currentSearchId,
      history,
    });
    const isMatchMinerView = isMatchMinerTab(currentTab);
    const isMatchScoutView = isMatchMinerTab(currentTab);
    const isPassiveView = isPassiveTab(currentTab) && isPassiveSearchEnabled;

    const {
      candidateNameQuery,
      onCandidateNameSearchChange,
      clearSearchQuery,
    } = useCandidateQuery('');

    // Clear last search query when search id changes.
    useEffect(() => {
      if (jobDescriptionId) {
        clearSearchQuery('');
      } else {
        clearCurrentSearch();
      }
    }, [jobDescriptionId, clearSearchQuery, clearCurrentSearch]);

    useEffect(() => {
      let timeoutId: number;
      async function fetchCurrentSearch() {
        if (hasDataSourceId && jobDescriptionId) {
          await setCandidatesListLoading(true);
          await fetchSearch({
            dataSourceId,
            jobDescriptionId,
            shouldPerformPassiveSearch: isPassiveSearchEnabled,
          }).finally(() => {
            timeoutId = window.setTimeout(
              () => setCandidatesListLoading(false),
              200
            );
          });
        }
      }
      fetchCurrentSearch();

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [
      hasDataSourceId,
      dataSourceId,
      jobDescriptionId,
      isPassiveSearchEnabled,
      setCandidatesListLoading,
      fetchSearch,
      loadAllCandidates,
      currentSearchId,
    ]);

    // Re-run candidates query when search query changes.
    useEffect(() => {
      if (currentSearchId) {
        loadAllCandidates({
          searchId: currentSearchId,
          status: openedSections.current.active,
          filter: candidateNameQuery,
          tab: LineupTabs.Active,
        });
        if (isPassiveView) {
          loadAllCandidates({
            searchId: currentSearchId,
            status: openedSections.current.passive,
            filter: candidateNameQuery,
            tab: LineupTabs.Passive,
          });
        }
        if (isMatchMinerView && currentMatchMinerSearchId) {
          loadAllCandidates({
            searchId: currentMatchMinerSearchId,
            status: openedSections.current.matchMiner,
            filter: candidateNameQuery,
            tab: LineupTabs.MatchMiner,
          });
        }
        if (isMatchScoutView && currentMatchScoutSearchId) {
          loadAllCandidates({
            searchId: currentMatchScoutSearchId,
            status: openedSections.current.matchScout,
            filter: candidateNameQuery,
            tab: LineupTabs.MatchScout,
          });
        }
      }
    }, [
      isMatchMinerView,
      isMatchScoutView,
      isPassiveView,
      candidateNameQuery,
      loadAllCandidates,
      currentSearchId,
      currentMatchMinerSearchId,
      currentMatchScoutSearchId,
    ]);

    const onOpenedSectionsChanged = useCallback(
      (sections: CandidateSearchProfileStatusEnum[]) => {
        const computedSearchId = getLineupTabData({
          activeData: currentSearchId,
          passiveData: currentSearchId,
          matchMinerData: currentMatchMinerSearchId,
          matchScoutData: currentMatchScoutSearchId,
          currentTab,
        });
        const computedAppliedCount = getLineupTabData({
          activeData: currentSearchAppliedCount,
          passiveData: currentSearch?.passiveCount?.totalCount,
          matchMinerData: currentMatchMinerSearch?.passiveCount?.totalCount,
          matchScoutData: currentMatchScoutSearch?.passiveCount?.totalCount,
          currentTab,
        });
        const recentlyOpenedSections = R.difference(
          sections,
          getLineupTabData({
            activeData: openedSections.current.active,
            passiveData: openedSections.current.passive,
            matchMinerData: openedSections.current.matchMiner,
            matchScoutData: openedSections.current.matchScout,
            currentTab,
          })
        );

        if (recentlyOpenedSections && computedSearchId) {
          updateCandidatesForStatus({
            searchId: computedSearchId,
            status: mapSectionsToCandidateStatuses(recentlyOpenedSections),
            size: computedAppliedCount,
            filter: candidateNameQuery,
            tab: currentTab,
          });
        }
        if (!R.isNullOrEmpty(recentlyOpenedSections)) {
          updateOpenedSections(sections, currentTab);
        }
      },
      [
        currentSearchId,
        currentMatchMinerSearchId,
        currentMatchScoutSearchId,
        currentTab,
        currentSearchAppliedCount,
        currentSearch?.passiveCount?.totalCount,
        currentMatchMinerSearch?.passiveCount?.totalCount,
        currentMatchScoutSearch?.passiveCount?.totalCount,
        updateCandidatesForStatus,
        candidateNameQuery,
        updateOpenedSections,
      ]
    );

    const reopenSearchCb = useCallback(async () => {
      if (currentSearch) {
        const isSearchReopened = await reopenClosedInterview(currentSearch);
        if (isSearchReopened !== false) {
          history.push(
            urls.makeInterviewUrl(
              dataSourceId,
              jobDescriptionId,
              LineupTabs.Active
            )
          );
        }
      }
    }, [
      currentSearch,
      dataSourceId,
      jobDescriptionId,
      reopenClosedInterview,
      history,
    ]);

    const modifyInterviewCb = useCallback(async () => {
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
      history.push(urls.makeEditInterviewUrl(dataSourceId, jobDescriptionId));
    }, [history, jobDescriptionId, dataSourceId]);

    const currentSearchTitle = useMemo(
      () =>
        getSearchTitle<SearchExtendedResponse, SearchListItemT>(
          currentSearch,
          closedSearchesList,
          currentSearchId
        ),
      [currentSearch, currentSearchId, closedSearchesList]
    );

    const isClosedSearchLoaded = !!currentSearch;
    const isRestartScreeningDisabled = isTrialExpired || !isClosedSearchLoaded;
    const isModifyCriteriaDisabled = isTrialExpired || !isClosedSearchLoaded;

    useEffect(() => {
      return () => setRightSidebarPanel(null);
    }, [setRightSidebarPanel]);

    // uncomment when https://railsreactor.atlassian.net/browse/AR-10550 is implemented
    // const matchInterviewRoute = useRouteMatch(
    //   makeClosedSearchUrl(dataSourceId, jobDescriptionId)
    // );
    // const isExactClosedRoute = !!matchInterviewRoute?.isExact;
    //
    // const [hasModifiedDraft, setHasModifiedDraft] = useState(false);
    //
    // useEffect(() => {
    //   async function updateSearchHasModifiedDraft() {
    //     setHasModifiedDraft(
    //       await searchHasModifiedDraft(currentSearch.searchId)
    //     );
    //   }
    //   if (isExactClosedRoute && currentSearch?.searchId) {
    //     updateSearchHasModifiedDraft();
    //   }
    //   return () => {
    //     setHasModifiedDraft(false);
    //   };
    // }, [isExactClosedRoute, currentSearch?.searchId]);

    /*
      @TODO: Refactor to avoid rendering anything not visible
      when section is collapsed
    */
    return (
      <div className={styles.closedSection}>
        <FeatureView.LeftSidebar>
          <>
            {currentSearch && (
              <UIText
                tiny
                bold
                className={classNames(
                  commonStyles.leftSidebarHeader,
                  styles.leftSidebarHeader,
                  {
                    [commonStyles.isCollapsed]: isLeftSideBarCollapsed,
                  }
                )}
              >
                {phrases.CLOSED_SECTION_HEADER}
              </UIText>
            )}
            <TooltipWrapper
              delayShow={TOOLTIP_DELAY_TIME_LONG}
              tooltip={phrases.RESTART_SCREENING_CLOSED_SECTION_BUTTON}
              enabled={isLeftSideBarCollapsed}
              disabledChildren={isRestartScreeningDisabled}
              flexGrow={false}
            >
              <Button
                className={commonStyles.leftSidebarButton}
                variant={Button.variants.POSITIVE_CONFIRM}
                icon="start-screening-icon"
                debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                disabled={isRestartScreeningDisabled}
                onClick={() => {
                  emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
                    text: phrases.REOPEN_SCREENING_CONFIRMATION,
                    confirmLabel: phrases.KANBAN_ACTION_REOPEN,
                    onConfirm: reopenSearchCb,
                  });
                }}
              >
                {phrases.RESTART_SCREENING_CLOSED_SECTION_BUTTON}
              </Button>
            </TooltipWrapper>
            <TooltipWrapper
              delayShow={TOOLTIP_DELAY_TIME_LONG}
              tooltip={phrases.MODIFY_CRITERIA_INTERVIEW_SECTION_BUTTON}
              enabled={isLeftSideBarCollapsed}
              // uncomment when https://railsreactor.atlassian.net/browse/AR-10550 is implemented
              // tooltip={
              //   hasModifiedDraft
              //     ? phrases.INTERVIEW_DRAFT_HAS_CHANGES_TOOLTIP_TEXT
              //     : phrases.MODIFY_CRITERIA_INTERVIEW_SECTION_BUTTON
              // }
              // enabled={hasModifiedDraft || isLeftSideBarCollapsed}
              disabledChildren={isModifyCriteriaDisabled}
              flexGrow={false}
            >
              <Button
                disabled={isModifyCriteriaDisabled}
                className={classNames(
                  commonStyles.leftSidebarButton,
                  commonStyles.defaultState,
                  styles.modifyCriteriaButton,
                  {
                    /* uncomment when https://railsreactor.atlassian.net/browse/AR-10550 is implemented */
                  }
                  // {
                  //   [commonStyles.dotIndicator]: hasModifiedDraft,
                  // }
                )}
                debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                icon="criteria-and-flags-icon"
                variant={Button.variants.POSITIVE_SECONDARY}
                onClick={modifyInterviewCb}
              >
                {phrases.MODIFY_CRITERIA_INTERVIEW_SECTION_BUTTON}
              </Button>
            </TooltipWrapper>
          </>
          <FeatureView.LeftSidebar.Space />
          <UtilitiesControls
            gaCategory={GACategory.ScreeningPage}
            isCollapsed={isLeftSideBarCollapsed}
            hasReportsButton
          />
        </FeatureView.LeftSidebar>
        <FeatureView.Content
          className={interviewSectionStyles.interviewSectionContent}
        >
          <Route
            path={urls.makeClosedSearchUrl(
              ':dataSourceId?',
              ':jobDescriptionId?'
            )}
          >
            {() =>
              currentSearch ? (
                <CandidateQuery
                  candidateNameQuery={candidateNameQuery}
                  onCandidateNameSearchChange={onCandidateNameSearchChange}
                  clearSearchQuery={clearSearchQuery}
                >
                  <ClosedInterviewView
                    history={history}
                    onOpenedSectionsChange={onOpenedSectionsChanged}
                    isCandidatesListLoading={isCandidatesListLoading}
                    currentTab={currentTab}
                    closedLineupTabs={closedLineupTabs}
                    onTabChange={onTabChange}
                  />
                </CandidateQuery>
              ) : (
                <>
                  <JobTitle
                    disabled
                    className={styles.resultsHeader}
                    title={currentSearchTitle}
                  />
                  <LoadingTextPlaceholder />
                </>
              )
            }
          </Route>
        </FeatureView.Content>
      </div>
    );
  },
  R.arePropsShallowEqual<Partial<Props>>({
    match: R.shallowEqualObjects,
  })
);
ClosedSection.displayName = 'ClosedSection';

export const ClosedInterviewView: React.FC<{
  history: H.History;
  onOpenedSectionsChange: (
    sections: CandidateSearchProfileStatusEnum[]
  ) => void;
  isCandidatesListLoading: boolean;
  currentTab: LineupTabs;
  closedLineupTabs: TabItem<LineupTabs>[];
  onTabChange: (newTab: LineupTabs) => void;
}> = ({
  history,
  onOpenedSectionsChange,
  isCandidatesListLoading,
  currentTab,
  closedLineupTabs,
  onTabChange,
}) => {
  const currentSearchStatus = useKanbanContext(
    kanbanSelectors.currentSearchStatus
  );
  const currentSearchName = useKanbanContext(kanbanSelectors.currentSearchName);
  const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
  const jobRequisitionDetails = useKanbanContext(
    kanbanSelectors.jobRequisitionDetails
  );
  const currentSearchCreated = useKanbanContext(
    kanbanSelectors.currentSearchCreated
  );
  const currentSearchCriteria = useKanbanContext(
    kanbanSelectors.currentSearchCurrentCriteria
  );
  const externalJobDescriptionId = useKanbanContext(
    kanbanSelectors.externalJobDescriptionId
  );
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const { loadAllCandidates } = useCandidateProfileMethods();
  const { hasMatchServiceSearchLoaded } =
    useMatchServiceAvailabilityCheck(currentTab);
  const computedSearchId = useLineupSearchId(currentTab);

  useEffect(() => {
    /*
      This effect redirects from 'expired' urls, when search was removed,
      or returned to draft state for updates.
    */
    if (
      currentSearchId &&
      currentSearchStatus !== SearchProgressStatusEnum.CLOSED &&
      history.action !== 'PUSH'
    ) {
      history.replace(urls.ROOT_ROUTE);
    }
  }, [currentSearchId, currentSearchStatus, history]);

  const [lineupCriteriaConfig, setLineupConfig] = useLineupCriteriaConfig(
    currentSearchCriteria,
    currentSearchId
  );

  const loadAllSorted = useCallback(
    (sort: ProfileSort) => {
      return loadAllCandidates({
        searchId: computedSearchId,
        tab: currentTab,
        sort,
        sortId: currentSearchId,
      });
    },
    [computedSearchId, currentSearchId, currentTab, loadAllCandidates]
  );

  return (
    <>
      <JobTitle
        disabled
        className={interviewSectionStyles.resultsHeaderWrapper}
        titleClassName={interviewSectionStyles.resultsHeader}
        title={currentSearchName}
        jobRequisitionDetails={jobRequisitionDetails}
        isStandaloneAtsUser={isStandaloneAtsUser}
        created={currentSearchCreated}
      />
      <div className={interviewViewStyles.lineupTabs}>
        <TabsControl<LineupTabs>
          onChange={onTabChange}
          items={closedLineupTabs}
          selectedTab={currentTab}
        />
        <div className={interviewViewStyles.lineupHeaderDropdowns}>
          {isActiveTab(currentTab) && (
            <LabeledDropdown
              icon="eye"
              iconClassName={interviewViewStyles.dropdownIconClassname}
              tooltipText={phrases.LINEUP_WATCHING_DROPDOWN_LABEL}
              className={interviewViewStyles.watchListLabeledDropdown}
              tooltipContainerClassName={
                interviewViewStyles.watchListLabeledDropdownTooltip
              }
            >
              <WatchList jobRequisitionId={externalJobDescriptionId} />
            </LabeledDropdown>
          )}
          <SortDropdown onSortChange={loadAllSorted} />
          <LabeledDropdown
            icon="filter"
            tooltipText={phrases.LINEUP_CRITERIA_DROPDOWN_LABEL}
          >
            <CheckboxList
              options={lineupCriteriaConfig}
              onChange={(values: CriteriaConfig) => setLineupConfig(values)}
            />
          </LabeledDropdown>
          {isStandaloneAtsUser && !isMatchServiceTab(currentTab) && (
            <UploaderButton
              className={interviewViewStyles.lastButton}
              disabled
            />
          )}
          {isMatchServiceTab(currentTab) && hasMatchServiceSearchLoaded && (
            <LoadMoreApplicantsButton
              disabled
              className={interviewViewStyles.lastButton}
              currentTab={currentTab}
            />
          )}
        </div>
      </div>
      <SearchResultsView
        currentTab={currentTab}
        className={styles.closedSearchResultsView}
        changeCandidateStatus={null}
        onOpenedSectionsChange={onOpenedSectionsChange}
        columnsConfig={lineupCriteriaConfig}
        getCandidateProfileUrl={urls.makeClosedSearchProfileUrl}
        isCandidatesListLoading={isCandidatesListLoading}
      />
    </>
  );
};
