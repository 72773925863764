import { CustomerProfileContextT } from 'context';
import { MatchMinerStatus, MatchScoutStatus } from '@air/api';

export const customerProfileState = (state: CustomerProfileContextT) => state;
export const customerCompanySettings = (state: CustomerProfileContextT) =>
  state.customerCompany?.settings;
export const customerCompany = (state: CustomerProfileContextT) =>
  state.customerCompany;
export const customerCompanyId = (state: CustomerProfileContextT) =>
  state.customerCompany.id;
export const customerCompanySpecializations = (
  state: CustomerProfileContextT
) => state.customerCompany?.settings?.specializations;
export const user = (state: CustomerProfileContextT) => state.user;
export const customerId = (state: CustomerProfileContextT) =>
  state.user?.customerId;
export const ats = (state: CustomerProfileContextT) => state.ats;
export const dataSourceId = (state: CustomerProfileContextT) =>
  state.ats?.dataSourceId;
export const atsType = (state: CustomerProfileContextT) => state.ats?.type;
export const licenseType = (state: CustomerProfileContextT) =>
  state.licenseType;
export const daysLeft = (state: CustomerProfileContextT) => state.daysLeft;
export const matchMinerSettings = (state: CustomerProfileContextT) =>
  state.matchMinerSettings;
export const matchScoutSettings = (state: CustomerProfileContextT) =>
  state.matchScoutSettings;
export const matchMinerEnabled = (state: CustomerProfileContextT) =>
  state.matchMinerSettings?.status === MatchMinerStatus.ENABLED;
export const matchScoutEnabled = (state: CustomerProfileContextT) =>
  state.matchScoutSettings?.status === MatchScoutStatus.ENABLED;
export const currentBackgroundImageId = (state: CustomerProfileContextT) =>
  state.currentBackgroundImageId;
export const authenticated = (state: CustomerProfileContextT) =>
  state.authenticated;
export const authDataReceived = (state: CustomerProfileContextT) =>
  state.authDataReceived;
export const uiIsReady = (state: CustomerProfileContextT) => state.uiIsReady;
export const authError = (state: CustomerProfileContextT) => state.authError;
export const canAccessUserManagement = (state: CustomerProfileContextT) =>
  state.canAccessUserManagement;
export const userCredentials = (state: CustomerProfileContextT) =>
  state.userCredentials;
export const isPassiveSearchEnabled = (state: CustomerProfileContextT) =>
  state.isPassiveSearchEnabled;
export const isTrialExpired = (state: CustomerProfileContextT) =>
  state.isTrialExpired;
export const isStandaloneAtsUser = (state: CustomerProfileContextT) =>
  state.isStandaloneAtsUser;
export const isExternalATS = (state: CustomerProfileContextT) =>
  state.isStandaloneAtsUser === false;
export const customerCompanyName = (state: CustomerProfileContextT) =>
  state.customerCompany?.name;
export const customerRole = (state: CustomerProfileContextT) =>
  state.user?.role;
