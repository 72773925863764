import React from 'react';
import * as phrases from '@air/constants/phrases';
import {
  DictionaryIdentifiableItem,
  InstitutionDetailTypeEnum,
} from '@air/api';
import { CardCategoryTooltip } from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';
import { FormSelectEntity } from '@air/components/Select/typings';

import cardsCommonStyles from '@air/domain/SearchCriteriaCards/cardsCommonStyles.css';

export type InstitutionSearchCriteriaListExtras = {
  type: InstitutionDetailTypeEnum;
  items: DictionaryIdentifiableItem[];
};

export const getInstitutionListTooltipProps = (
  listItems: { fullName: string }[]
) => {
  return {
    enabled: true,
    tooltip: (
      <CardCategoryTooltip
        items={listItems}
        tooltipText={phrases.INSTITUTION_LIST_TOOLTIP_TEXT}
      />
    ),
    containerClassName: cardsCommonStyles.cardCategoryTooltip,
    placement: 'right-start',
  };
};

export const getInstitutionSelectValueTooltipProps = (
  data: FormSelectEntity<InstitutionSearchCriteriaListExtras>
) => {
  return data && data.extras.type === InstitutionDetailTypeEnum.InstitutionList
    ? getInstitutionListTooltipProps(
        data.extras.items as Required<DictionaryIdentifiableItem>[]
      )
    : {};
};
