import React, { useState, useCallback, useEffect, useMemo } from 'react';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';
import {
  Header,
  FormField,
  Button,
  MultiSelectCheckboxes,
} from '@air/components';
import styles from './JobPanel.css';
import { ButtonVariants } from '@air/components/Button/Button';
import { useForm } from 'react-hook-form';
import { useAppMethods } from 'providers/AppProvider';
import { RightSidebar } from 'components';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit } from 'hooks/usePubSub';
import {
  SearchCreateRequest,
  JobDescriptionFullResponse,
  DataSourceResponse,
  SearchProgressStatusEnum,
} from '@air/api';
import * as urls from 'constants/urls';
import { useKanbanMethods } from 'providers/KanbanProvider';
import { useHistory } from 'react-router-dom';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { toast } from '@air/third-party/toast';
import { useJobSpecializations } from '@air/hooks/useJobSpecializations';

type Props = {
  isDuplicate?: boolean;
  item: KanbanCardT & {
    jobRequisitionDetails?: JobDescriptionFullResponse;
  };
};

const JOB_TITLE_MIN_LENGTH = 2;

const FORM_DEFAULT_VALUES = {
  name: '',
};

type FormValues = typeof FORM_DEFAULT_VALUES;

// TODO: find out better way to check if job is ATS job
const isATSJob = (item: KanbanCardT) => item.status === 'Open' || item.status === 'Approved';

const mapNewDraftData = ({
  name,
  ats,
  item,
  jobSpecializations,
}: {
  name: string;
  ats: DataSourceResponse;
  item: Props['item'];
  jobSpecializations: { value: string }[];
}) => ({
  ats,
  title: name,
  jobId: item.searchId as string,
  externalId: item.searchId as string,
  appliedCount: item.activeCount?.totalCount,
  appliedPassiveCount: item.passiveCount?.totalCount,
  locations: item.locations,
  // we pass `jobRequisitionDetails` when we make draft from JobSection page
  // to instantly redirect user to the next search page and pass this data
  // for optimistic update this page before actually create new draft on BE
  jobRequisitionDetails: item.jobRequisitionDetails,
  specializations: R.map(R.prop('value'), jobSpecializations),
});

export const JobPanel: React.FC<Props> = ({ isDuplicate, item }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setFocus,
    formState: { isValid, isDirty },
  } = useForm<FormValues>({
    defaultValues: item ? { name: item.name } : FORM_DEFAULT_VALUES,
    mode: 'onChange',
  });

  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );

  const ats = useCustomerProfileContext(customerProfileSelectors.ats);
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  const customerCompanySpecializations = useCustomerProfileContext(
    customerProfileSelectors.customerCompanySpecializations
  );

  const history = useHistory();

  const { setRightSidebarPanel } = useAppMethods();

  const { removeDraft, createDraft, updateDraft, createNewRequisition } =
    useKanbanMethods();

  const [isSaving, setIsSaving] = useState(false);
  const [jobSpecializations, setJobSpecializations] = useState<
    { label: string; value: string }[]
  >([]);

  const { specializations } = useJobSpecializations();

  const isJobSpecializationsDirty = useMemo(() => {
    return (
      item &&
      !R.stringArraysEqual(
        item?.specializations || [],
        R.map(R.prop('value'), jobSpecializations || [])
      )
    );
  }, [item, jobSpecializations]);

  const isFormDirty = useMemo(() => {
    return isDirty || isJobSpecializationsDirty;
  }, [isDirty, isJobSpecializationsDirty]);

  // automatically sets focus on the name field when panel is opened
  useEffect(() => {
    if (!item) {
      setFocus('name');
    }
  }, [item, setFocus]);

  useEffect(() => {
    if (item && !isATSJob(item)) {
      setValue('name', item.name, { shouldValidate: true });
      setJobSpecializations(
        specializations.filter((it) =>
          (item?.specializations || []).includes(it.value)
        )
      );
    } else {
      setJobSpecializations(
        specializations.filter((it) =>
          (customerCompanySpecializations || []).includes(it.value)
        )
      );
    }
  }, [customerCompanySpecializations, item, reset, setValue, specializations]);

  const redirectAfterRequisitionSave = useCallback(
    (atsData) => {
      // atsData must be passed from the search creation request
      history.push(
        urls.makeDraftUrl(atsData.id, atsData.externalJobDescriptionId),
        { isNew: true }
      );
    },
    [history]
  );

  const discardDraft = async (item: KanbanCardT) => {
    await removeDraft(item);
    emit(APP_EVENTS.REMOVE_DRAFT);
  };

  const closePanel = useCallback(() => {
    setRightSidebarPanel(null);
  }, [setRightSidebarPanel]);

  const onModalConfirm = () => {
    if (isDuplicate) {
      discardDraft(item);
    }
    closePanel();
  };

  const createNewJob = useCallback(
    async (formData: FormValues) => {
      const data = {
        name: formData.name.trim(),
        ats: { id: dataSourceId, externalJobDescriptionId: '' },
        specializations: R.map(R.prop('value'), jobSpecializations || []),
      };

      setIsSaving(true);
      await createNewRequisition(data as SearchCreateRequest)
        .then((draft) => {
          closePanel();
          redirectAfterRequisitionSave(draft.ats);
        })
        .catch(() => {
          history.push(urls.ROOT_ROUTE);
        });
      setIsSaving(false);
    },
    [
      closePanel,
      createNewRequisition,
      dataSourceId,
      history,
      redirectAfterRequisitionSave,
      jobSpecializations,
    ]
  );

  const updateJob = useCallback(
    async (formData: FormValues) => {
      setIsSaving(true);
      await updateDraft(
        item.searchId,
        {
          ...item,
          name: formData.name.trim(),
          specializations: R.map(R.prop('value'), jobSpecializations || []),
        },
        false
      );
      setIsSaving(false);
      if (isDuplicate) {
        history.push(
          urls.makeDraftUrl(item.ats.id, item.ats.externalJobDescriptionId)
        );
      }
      closePanel();
    },
    [jobSpecializations, closePanel, history, isDuplicate, item, updateDraft]
  );

  const confirmUpdatingWithSpecializationChange = useCallback(
    (formData: FormValues) => {
      emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
        text: phrases.SAVE_WITH_SPECIALIZATION_MODAL_CONFIRM_TEXT,
        confirmLabel: phrases.JOB_PANEL_EDIT_JOB_BUTTON_TEXT,
        onConfirm: () => updateJob(formData),
        options: {
          modalStyles: {
            content: { width: 280 },
          },
        },
      });
    },
    [updateJob]
  );

  const moveToDraftFromJobSection = useCallback(
    (formData: FormValues) => {
      createDraft(
        mapNewDraftData({
          name: formData.name.trim(),
          ats,
          item,
          jobSpecializations,
        }),
        true
      )
        .then(() => {
          emit(APP_EVENTS.CREATE_DRAFT, item.externalId);
        })
        .catch(() => {
          emit(APP_EVENTS.CREATE_DRAFT_FAILURE);
        });
      closePanel();
    },
    [ats, closePanel, createDraft, item, jobSpecializations]
  );

  const moveToDraftFromKanban = useCallback(
    async (formData: FormValues) => {
      setIsSaving(true);
      await createDraft(
        mapNewDraftData({
          name: formData.name.trim(),
          ats,
          item,
          jobSpecializations,
        })
      )
        .then(() => {
          emit(APP_EVENTS.CREATE_DRAFT, item.searchId);
          toast.dark(phrases.KANBAN_MOVE_TO_DRAFTS_SUCCESS);
          closePanel();
        })
        .catch(() => {
          emit(APP_EVENTS.CREATE_DRAFT_FAILURE);
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [ats, closePanel, createDraft, item, jobSpecializations]
  );

  const onClosePanel = () => {
    if (isFormDirty || isDuplicate) {
      emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
        text: phrases.DISCARD_UNSAVED_CHANGES,
        confirmLabel: phrases.DISCARD,
        onConfirm: onModalConfirm,
      });
    } else {
      closePanel();
    }
  };

  const { buttonText, headerText, onSubmit } = useMemo(() => {
    if (!item) {
      return {
        headerText: phrases.JOB_PANEL_NEW_JOB_TITLE,
        buttonText: phrases.JOB_PANEL_NEW_JOB_BUTTON_TEXT,
        onSubmit: createNewJob,
      };
    } else {
      if (isATSJob(item)) {
        return {
          headerText: phrases.JOB_PANEL_NEW_DRAFT_TITLE,
          buttonText: phrases.JOB_PANEL_NEW_DRAFT_BUTTON_TEXT,
          onSubmit: item.jobRequisitionDetails
            ? moveToDraftFromJobSection
            : moveToDraftFromKanban,
        };
      } else {
        return {
          headerText: phrases.JOB_PANEL_EDIT_JOB_TITLE,
          buttonText: phrases.JOB_PANEL_EDIT_JOB_BUTTON_TEXT,
          onSubmit: isJobSpecializationsDirty
            ? confirmUpdatingWithSpecializationChange
            : updateJob,
        };
      }
    }
  }, [
    confirmUpdatingWithSpecializationChange,
    createNewJob,
    isJobSpecializationsDirty,
    item,
    moveToDraftFromJobSection,
    moveToDraftFromKanban,
    updateJob,
  ]);

  const onMultiSelectCheckboxesChanged = useCallback((values) => {
    setJobSpecializations(values);
  }, []);

  const isJobSpecializationDropdownDisabled =
    isTrialExpired ||
    (item &&
      !isATSJob(item) &&
      ![
        SearchProgressStatusEnum.DRAFT,
        SearchProgressStatusEnum.MODIFY,
      ].includes(item.status as SearchProgressStatusEnum));

  return (
    <RightSidebar hasBlockScreen isOpened closePanel={onClosePanel}>
      <div className={styles.panelWrapper}>
        <Header className={styles.panelHeader} level={2}>
          {headerText}
        </Header>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.panelForm}>
          <FormField
            type="text"
            {...register('name', {
              required: true,
              minLength: JOB_TITLE_MIN_LENGTH,
            })}
            label={phrases.JOB_PANEL_JOB_TITLE_LABEL}
          />
          <MultiSelectCheckboxes
            isDisabled={isJobSpecializationDropdownDisabled}
            options={specializations}
            label={phrases.JOB_PANEL_JOB_SPECIALIZATION_LABEL}
            tooltipClassName={styles.jobSpecializationSelectTooltip}
            tooltip={phrases.JOB_PANEL_EMPTY_SPECIALIZATIONS_TOOLTIP_TEXT}
            value={jobSpecializations}
            onChange={onMultiSelectCheckboxesChanged}
          />
          <Button
            alignCenter
            isLoading={isSaving}
            disabled={!isValid || isSaving}
            className={styles.panelSubmitButton}
            type="submit"
            variant={ButtonVariants.POSITIVE_CONFIRM}
          >
            {buttonText}
          </Button>
        </form>
      </div>
    </RightSidebar>
  );
};
