import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';

export const getLicense = () =>
  Http.get(urls.licenseUrl)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const requestFullVersion = () =>
  Http.put(urls.licenseUrlFull)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
