import { KanbanContextT } from 'context';

export const currentSearch = (state: KanbanContextT) => state.currentSearch;
export const atsId = (state: KanbanContextT) => state.currentSearch?.ats?.id;
export const externalJobDescriptionId = (state: KanbanContextT) =>
  state.currentSearch?.ats?.externalJobDescriptionId;
export const currentSearchId = (state: KanbanContextT) =>
  state.currentSearch?.searchId;
export const currentSearchSpecializations = (state: KanbanContextT) =>
  state.currentSearch?.specializations;
export const jobRequisitionDetails = (state: KanbanContextT) =>
  state.currentSearch?.jobRequisitionDetails;
export const currentSearchAppliedCount = (state: KanbanContextT) =>
  state.currentSearch?.activeCount?.totalCount;
export const currentSearchPassiveTotalCount = (state: KanbanContextT) =>
  state.currentSearch?.passiveCount?.totalCount;
export const currentSearchProcessedCount = (state: KanbanContextT) =>
  state.currentSearch?.activeCount?.processedCount;
export const currentSearchStatus = (state: KanbanContextT) =>
  state.currentSearch?.status;
export const currentSearchName = (state: KanbanContextT) =>
  state.currentSearch?.name;
export const currentSearchCreated = (state: KanbanContextT) =>
  state.currentSearch?.created;
export const currentSearchCurrentCriteria = (state: KanbanContextT) =>
  state.currentSearch?.currentCriteria;
export const currentSearchCriteria = (state: KanbanContextT) =>
  state.currentSearch?.criteria;
export const currentMatchMinerSearch = (state: KanbanContextT) =>
  state.currentMatchMinerSearch;
export const currentMatchMinerSearchId = (state: KanbanContextT) =>
  state.currentMatchMinerSearch?.searchId;
export const isCurrentMatchMinerSearchLoaded = (state: KanbanContextT) =>
  state.isCurrentMatchMinerSearchLoaded;
export const currentMatchScoutSearch = (state: KanbanContextT) =>
  state.currentMatchScoutSearch;
export const currentMatchScoutSearchId = (state: KanbanContextT) =>
  state.currentMatchScoutSearch?.searchId;
export const isCurrentMatchScoutSearchLoaded = (state: KanbanContextT) =>
  state.isCurrentMatchScoutSearchLoaded;
export const companiesInfo = (state: KanbanContextT) => state.companiesInfo;
export const draftsList = (state: KanbanContextT) => state.draftsList;
export const interviewsList = (state: KanbanContextT) => state.interviewsList;
export const closedSearchesList = (state: KanbanContextT) =>
  state.closedSearchesList;
export const pausedSearchesList = (state: KanbanContextT) =>
  state.pausedSearchesList;
export const isFirstDraftUpdated = (state: KanbanContextT) =>
  state.draftsList.items[0]?.updated;
export const isFirstInterviewUpdated = (state: KanbanContextT) =>
  state.interviewsList.items[0]?.updated;
export const isFirstPausedJobUpdated = (state: KanbanContextT) =>
  state.pausedSearchesList.items[0]?.updated;
export const isFirstClosedJobUpdated = (state: KanbanContextT) =>
  state.closedSearchesList.items[0]?.updated;
export const currentSearchFilter = (state: KanbanContextT) =>
  state.currentSearchFilter;
export const currentSearchFilterIsOwnedByMe = (state: KanbanContextT) =>
  state.currentSearchFilter.isOwnedByMe;
export const currentSearchFilterName = (state: KanbanContextT) =>
  state.currentSearchFilter.name;
export const matchMinerMetadata = (state: KanbanContextT) => {
  return state?.currentMatchMinerSearch?.matchMinerMetadata;
};
export const matchScoutMetadata = (state: KanbanContextT) => {
  return state?.currentMatchScoutSearch?.matchScoutMetadata;
};
export const matchMinerPassiveTotalCount = (state: KanbanContextT) => {
  return state?.currentMatchMinerSearch?.passiveCount?.totalCount;
};
export const matchScoutPassiveTotalCount = (state: KanbanContextT) => {
  return state?.currentMatchScoutSearch?.passiveCount?.totalCount;
};
export const matchMinerTotalProcessed = (state: KanbanContextT) => {
  return state?.currentMatchMinerSearch?.passiveCount?.processedCount;
};
export const matchScoutTotalProcessed = (state: KanbanContextT) => {
  return state?.currentMatchScoutSearch?.passiveCount?.processedCount;
};
export const isImportingCriteria = (state: KanbanContextT) => {
  return state.isImportingCriteria;
};
export const statsExpanded = (state: KanbanContextT) => {
  return state?.statsExpanded;
};
export const isKanbanLoaded = (state: KanbanContextT) =>
  draftsList(state).loaded &&
  interviewsList(state).loaded &&
  pausedSearchesList(state).loaded &&
  closedSearchesList(state).loaded;

export const areSearchesLoading = (state: KanbanContextT) => {
  return state.areSearchesLoading;
};
