import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { useOutsideClick, usePrevious } from '@air/utils/hooks';
import {
  Paragraph,
  SvgIcon,
  Tooltip,
  Button,
  TooltipWrapper,
} from '@air/components';

import styles from './LabeledDropdown.css';
import { TooltipTriggerProps } from 'react-popper-tooltip/dist/types';

export const LabeledDropdown: React.FC<{
  label?: string | JSX.Element;
  tooltipText?: string;
  icon?: string;
  className?: string;
  iconClassName?: string;
  tooltipContainerClassName?: string;
  labelClassName?: string;
  tooltipDelay?: number;
  dropdownPlacement?: TooltipTriggerProps['placement'];
  onClose?: () => void;
  modifiers?: TooltipTriggerProps['modifiers'];
}> = ({
  label,
  labelClassName,
  tooltipText,
  icon,
  className,
  iconClassName,
  tooltipContainerClassName,
  children,
  dropdownPlacement = 'bottom',
  tooltipDelay,
  onClose,
  modifiers,
}) => {
  const [isOpened, setOpened] = useState(false);
  const [dropdownRef, { current }] = useOutsideClick(
    (e) => {
      e.stopPropagation();
      // NOTE: this will be triggered if we click on something inside the LabeledDropdown that needs to disappear
      // in order to fix this in the component where you're clicking on something disappearing
      // add `e.stopPropagation();` to your onClick handler
      if (current && !current.contains(e.target)) {
        setOpened(false);
      }
    },
    { useCapture: false }
  );

  const prevOpenedState = usePrevious(isOpened);

  useEffect(() => {
    if (!isOpened && prevOpenedState) {
      onClose?.();
    }
  }, [isOpened, prevOpenedState, onClose]);

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.labeledDropdownContainer, className, {
        [styles.opened]: isOpened,
      })}
    >
      <Tooltip
        containerClassName={classNames(
          styles.labeledDropdown,
          tooltipContainerClassName
        )}
        tooltipShown={isOpened}
        placement={dropdownPlacement}
        tooltip={children}
        portalContainer={current}
        modifiers={modifiers}
      >
        <TooltipWrapper
          enabled={!!tooltipText}
          placement="top"
          trigger="hover"
          tooltip={tooltipText}
          delayShow={tooltipDelay}
        >
          {icon && (
            <Button
              variant={Button.variants.DEFAULT}
              className={styles.buttonStyle}
              iconClassName={iconClassName}
              icon={icon}
              onClick={() => setOpened(R.not)}
            />
          )}
          {label && (
            <Paragraph
              short
              onClick={() => setOpened(R.not)}
              className={classNames(
                styles.labeledDropdownLabel,
                labelClassName
              )}
            >
              {label}
              <SvgIcon className={styles.dropdownIcon} icon="shevron-down" />
            </Paragraph>
          )}
        </TooltipWrapper>
      </Tooltip>
    </div>
  );
};
LabeledDropdown.displayName = 'LabeledDropdown';
