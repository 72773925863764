import { CurrentStateObject, StatesConfig } from '@air/hooks/useStateMachine';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';

export enum SearchCardState {
  // parent 'view' state
  view = 'view',

  // initial 'view' state
  viewInitial = 'view.initial',

  // state when light background appears under the card
  viewBackground = 'view.background',
  // activated on 'hover', when action 'modify' is shown
  viewBackgroundHovered = 'view.background.hovered',
  // activated when card is available for drop of search criteria
  viewBackgroundDroppable = 'view.background.droppable',

  // after clicking on collapsed stack, card with opened stack
  viewOpenedStack = 'view.stack.opened',

  edit = 'edit',
  emptyFields = 'edit.fields.initial',
  updatedFields = 'edit.fields.updated',
  editImportance = 'edit.importance',
  viewReadOnly = 'view.readOnly',
  editReadOnly = 'editReadOnly',
}

export enum SearchCardActions {
  openStack = 'OPEN_STACK',
  displayHoveredBackground = 'SHOW_HOVERED_BG',
  displayDroppableBackground = 'SHOW_DROPPABLE_BG',
  openEdit = 'OPEN_EDIT',
  openImportance = 'OPEN_IMPORTANCE',
  pushToStack = 'PUSH_TO_STACK',
  updateImportance = 'UPDATE_IMPORTANCE',
  updateFields = 'UPDATE_FIELDS',
  closeEdit = 'CLOSE_EDIT',
  showInitial = 'SHOW_INITIAL',
  showViewReadOnly = 'SHOW_VIEW_READ_ONLY',
  showEditReadOnly = 'SHOW_EDIT_READ_ONLY',
}

export type SearchCardsContext = {
  hasStack: boolean;
  isReadOnly?: boolean;
  updated?: boolean;
  importanceOrigin: 'view' | 'edit';
};

const transitionBackToView = {
  [SearchCardActions.closeEdit]: SearchCardState.view,
};

const transitionOpenImportanceFromView = {
  [SearchCardActions.openImportance]: {
    target: SearchCardState.editImportance,
    action: (context: any) => ({ ...context, importanceOrigin: 'view' }),
  },
};

const transitionOpenImportanceFromEdit = {
  [SearchCardActions.openImportance]: {
    target: SearchCardState.editImportance,
    action: (context: any) => ({ ...context, importanceOrigin: 'edit' }),
  },
};

// TODO add documentation for states and transitions, add more descriptive names for states and transitions

export const SearchCardStateConfig: StatesConfig<
  SearchCardState,
  SearchCardActions,
  SearchCardsContext
> = {
  /**
   * Default Card View state, automatically transitions into viewInitial state.
   */
  [SearchCardState.view]: {
    auto: {
      target: SearchCardState.viewInitial,
      internal: true,
      condition: ({ isReadOnly }) => !isReadOnly,
      fallback: SearchCardState.viewReadOnly,
    },
  },
  /**
   * viewInitial - initial state of Card View, can transition into viewBackground.
   */
  [SearchCardState.viewInitial]: {
    // we can open stack only from initial view state, and only if it has stack
    // TODO think about creating state 'view.stack' - to mark card with stack
    [SearchCardActions.openStack]: {
      target: SearchCardState.viewOpenedStack,
      condition: ({ hasStack }) => hasStack,
    },
    [SearchCardActions.openEdit]: {
      target: SearchCardState.edit,
      condition: ({ isReadOnly }) => !isReadOnly,
      fallback: SearchCardState.editReadOnly,
    },
    // this state is triggered after hovering on card - card is transitioned to 'view.modify' state
    [SearchCardActions.displayHoveredBackground]:
      SearchCardState.viewBackgroundHovered,
    [SearchCardActions.displayDroppableBackground]:
      SearchCardState.viewBackgroundDroppable,
    ...transitionOpenImportanceFromView,
  },
  /**
   * viewBackground - state activated by hovering on a Card View (with button 'modify' and light blue border).
   */
  [SearchCardState.viewBackgroundHovered]: {
    [SearchCardActions.openEdit]: SearchCardState.edit,
    [SearchCardActions.showInitial]: SearchCardState.view,
    ...transitionOpenImportanceFromView,
  },
  [SearchCardState.viewBackgroundDroppable]: {
    [SearchCardActions.showInitial]: SearchCardState.view,
  },
  [SearchCardState.edit]: {
    auto: {
      target: SearchCardState.updatedFields,
      condition: ({ updated }) => !!updated,
      fallback: SearchCardState.emptyFields,
    },
  },
  // emptyFields state - user can't do too much, if the card wasn't created/updated, it will be simply discarded.
  [SearchCardState.emptyFields]: {
    [SearchCardActions.updateFields]: {
      target: SearchCardState.edit,
      action: (context, { value }) => ({ ...context, updated: value }),
    },
    ...transitionBackToView,
  },
  [SearchCardState.updatedFields]: {
    [SearchCardActions.updateFields]: {
      target: SearchCardState.edit,
      action: (context, { value }) => ({ ...context, updated: value }),
    },
    ...transitionOpenImportanceFromEdit,
    ...transitionBackToView,
  },
  [SearchCardState.editImportance]: {
    [SearchCardActions.updateImportance]: {
      target: SearchCardState.edit,
      condition: ({ importanceOrigin }) => importanceOrigin === 'edit',
      fallback: SearchCardState.view,
    },
    ...transitionBackToView,
  },
  [SearchCardState.viewReadOnly]: {
    [SearchCardActions.showInitial]: SearchCardState.view,
    [SearchCardActions.showEditReadOnly]: SearchCardState.editReadOnly,
    [SearchCardActions.openEdit]: SearchCardState.editReadOnly,
  },
  [SearchCardState.editReadOnly]: {
    [SearchCardActions.closeEdit]: SearchCardState.view,
  },
};

export function getCardInitialState(
  isReadOnly: boolean,
  initialCardStatus?: InitialCardStatusEnum
) {
  if (isReadOnly) return SearchCardState.viewReadOnly;
  return initialCardStatus === InitialCardStatusEnum.IsNewEdit
    ? SearchCardState.edit
    : SearchCardState.view;
}

export function isCardReadOnly(
  cardState: CurrentStateObject<SearchCardState>
): boolean {
  return cardState?.in([
    SearchCardState.viewReadOnly,
    SearchCardState.editReadOnly,
  ]);
}

export function isCardInViewMode(
  cardState: CurrentStateObject<SearchCardState>
): boolean {
  return cardState.in([SearchCardState.view, SearchCardState.viewReadOnly]);
}
