import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import * as H from 'history';
import querystring from 'query-string';

import { Button, FilterWithPlaceholder } from 'components';
import {
  FormDropdown,
  Header,
  Paragraph,
  TabsControl,
  Toggle,
  UIText,
} from '@air/components';
import {
  EmailsTabs,
  LineupTabs,
  SelectRecipientsTabs,
} from '@air/constants/tabs';
import { FormSelectEntity } from '@air/components/Select/typings';
import {
  CandidateSearchProfileStatusEnum,
  findCandidateWithFirstAvailableStatus,
} from 'domain/CandidateData';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  useEmailConfigurationContext,
  useEmailConfigurationMethods,
} from 'providers/EmailConfigurationProvider';
import {
  customerProfileSelectors,
  emailConfigurationSelectors,
} from 'selectors';
import {
  filterRecipients,
  getRecipientsSections,
} from 'features/SelectRecipientsView/selectRecipientsViewHelpers';
import { makeEmailConfigUrl } from 'constants/urls';
import { SendJobEmailsRequest } from '@air/api';

import styles from './SelectRecipientsView.css';
import { useDebounce } from '@air/utils/hooks';
import { INPUT_DEBOUNCE_TIME } from '@air/constants/app';
import { useEmailConfigParams } from 'domain/EmailConfig/useEmailConfigParams';

const REMINDER_OPTIONS: FormSelectEntity[] = [
  { label: phrases.REMINDER_OPTION_1_DAY, value: 1 },
  { label: phrases.REMINDER_OPTION_3_DAY, value: 3 },
  { label: phrases.REMINDER_OPTION_5_DAY, value: 5 },
  { label: phrases.REMINDER_OPTION_7_DAY, value: 7 },
  { label: phrases.REMINDER_OPTION_10_DAY, value: 10 },
];

export const SelectRecipientsView: React.FC<{
  jobDescriptionId: string;
  emailId: string;
  searchId: number;
  history: H.History;
}> = ({ jobDescriptionId, emailId, searchId, history }) => {
  const locationSearch = querystring.parse(history.location.search);
  const { tab: currentTabInRoute }: { tab?: LineupTabs } = locationSearch;

  const candidatePreviewId = locationSearch.candidatePreviewId as string;
  const currentRecipientsTab =
    locationSearch.recipientsTab as SelectRecipientsTabs;
  const currentEmailConfigTabInRoute =
    locationSearch.emailConfigTab as EmailsTabs;
  const [emailTabsFilter, onEmailTabsFilterChange] = useState('');
  const [openedSections, setOpenedSections] = useState<{
    [key in CandidateSearchProfileStatusEnum]?: boolean;
  }>({});
  const [isSendEmailsOverlayOpened, setSendEmailsOverlayOpened] =
    useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [isReminderEnabled, setIsReminderEnabled] = useState(false);
  const [remindInDaysValue, setRemindInDaysValue] = useState(
    REMINDER_OPTIONS[0]
  );

  const [isSendingEmails, setSendingEmails] = useState(false);

  const customerCompanySettings = useCustomerProfileContext(
    customerProfileSelectors.customerCompanySettings
  );
  const recipients = useEmailConfigurationContext(
    emailConfigurationSelectors.recipients
  );

  const { totalProcessed } = useEmailConfigParams(currentTabInRoute);

  const {
    fetchEmailRecipients,
    getEmailPreviewForCandidate,
    sendJobEmails,
    fetchJobEmailById,
  } = useEmailConfigurationMethods();

  const filteredNewRecipients = useMemo(() => {
    return filterRecipients(recipients.new, emailTabsFilter);
  }, [recipients.new, emailTabsFilter]);

  const filteredSentRecipients = useMemo(() => {
    return filterRecipients(recipients.sent, emailTabsFilter);
  }, [recipients.sent, emailTabsFilter]);

  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );

  const onSectionTitleClicked = (
    sectionName: CandidateSearchProfileStatusEnum
  ) => {
    setOpenedSections((state) => ({
      ...state,
      [sectionName]: !state[sectionName],
    }));
  };

  //checking what sections to open and what preview to show
  useEffect(() => {
    const isNewTabWithRecipients =
      currentRecipientsTab === SelectRecipientsTabs.New &&
      filteredNewRecipients?.length;
    const isSentTabWithRecipients =
      currentRecipientsTab === SelectRecipientsTabs.Sent &&
      filteredSentRecipients?.length;
    const currentRecipients = isNewTabWithRecipients
      ? filteredNewRecipients
      : filteredSentRecipients;

    if (isNewTabWithRecipients || isSentTabWithRecipients) {
      if (candidatePreviewId && !emailTabsFilter) {
        const selectedForPreviewCandidate = currentRecipients.find(
          (item) => item.id === candidatePreviewId
        );
        const sectionToOpen = selectedForPreviewCandidate?.status;
        if (selectedForPreviewCandidate && sectionToOpen) {
          setOpenedSections(() => ({ [sectionToOpen]: true }));
        }
      } else {
        const recipients = !emailTabsFilter
          ? [findCandidateWithFirstAvailableStatus(currentRecipients)]
          : currentRecipients;

        if (currentRecipients) {
          setOpenedSections(() => {
            return recipients.reduce((acc, item) => {
              acc[item.status] = true;
              return acc;
            }, {} as { [key in CandidateSearchProfileStatusEnum]?: boolean });
          });
        }

        getEmailPreviewForCandidate(searchId, emailId, recipients[0].id);
        history.replace(
          makeEmailConfigUrl({
            dataSourceId,
            jobDescriptionId,
            emailId,
            tab: currentTabInRoute as LineupTabs,
            params: {
              recipientsTab: currentRecipientsTab,
              candidatePreviewId: recipients[0].id,
              emailConfigTab: currentEmailConfigTabInRoute,
            },
          })
        );
      }
    }
  }, [
    currentRecipientsTab,
    filteredNewRecipients,
    filteredSentRecipients,
    candidatePreviewId,
    emailTabsFilter,
    getEmailPreviewForCandidate,
    searchId,
    emailId,
    history,
    dataSourceId,
    jobDescriptionId,
    currentTabInRoute,
    currentEmailConfigTabInRoute,
  ]);

  const onChangeInputHandle = useDebounce((value: string) => {
    onEmailTabsFilterChange(value);
    if (candidatePreviewId) {
      //We are remove previewId from url in order to check again (in useEffect) what sections have to be opened and what preview to show
      history.replace(
        makeEmailConfigUrl({
          dataSourceId,
          jobDescriptionId,
          emailId,
          tab: currentTabInRoute as LineupTabs,
          params: {
            recipientsTab: currentRecipientsTab,
            emailConfigTab: currentEmailConfigTabInRoute,
          },
        })
      );
    }
  }, INPUT_DEBOUNCE_TIME);

  useEffect(() => {
    // hide send emails overlay (if opened) when no recipients are selected
    if (!selectedRecipients.length && isSendEmailsOverlayOpened) {
      setSendEmailsOverlayOpened(false);
    }
  }, [isSendEmailsOverlayOpened, selectedRecipients]);

  useEffect(() => {
    const fetchRecipientsData = async (
      emailIdParam: string,
      searchIdParam: number
    ) => {
      if (emailIdParam && searchIdParam) {
        const result = await fetchEmailRecipients(emailIdParam, searchIdParam, {
          size: totalProcessed,
        });
        setSelectedRecipients(result.selectedNewIdsByDefault);
      }
    };
    fetchRecipientsData(emailId, searchId);
  }, [
    emailId,
    searchId,
    fetchEmailRecipients,
    currentTabInRoute,
    totalProcessed,
  ]);

  const tabItems = useMemo(
    () => [
      {
        label: phrases.SELECT_RECIPIENTS_TAB_NEW_TITLE,
        value: SelectRecipientsTabs.New,
        className: styles.tabName,
        children: (
          <UIText tiny bold className={styles.tabCounter}>
            {recipients.new?.length || 0}
          </UIText>
        ),
      },
      {
        label: phrases.SELECT_RECIPIENTS_TAB_SENT_TITLE,
        value: SelectRecipientsTabs.Sent,
        className: styles.tabName,
        children: (
          <UIText tiny bold className={styles.tabCounter}>
            {recipients.sent?.length}
          </UIText>
        ),
      },
    ],
    [recipients.new, recipients.sent]
  );

  const onSectionCheckboxClicked = useCallback((recipients, checked) => {
    setSelectedRecipients((state): string[] => {
      const mappedRecipients: string[] = R.compose(
        R.map(R.prop('id')),
        R.filter(R.prop('primaryEmail'))
      )(recipients);
      return checked
        ? R.union(mappedRecipients, state)
        : R.without(mappedRecipients, state);
    });
  }, []);

  const onRecipientNameClick = useCallback(
    async (candidateId: string) => {
      const result = await getEmailPreviewForCandidate(
        searchId,
        emailId,
        candidateId
      );
      if (result && candidateId !== candidatePreviewId) {
        history.push(
          makeEmailConfigUrl({
            dataSourceId,
            jobDescriptionId,
            emailId,
            tab: locationSearch.tab as LineupTabs,
            params: {
              recipientsTab:
                locationSearch.recipientsTab as SelectRecipientsTabs,
              candidatePreviewId: candidateId,
              emailConfigTab: currentEmailConfigTabInRoute,
            },
          })
        );
      }
    },
    [
      history,
      locationSearch,
      emailId,
      dataSourceId,
      jobDescriptionId,
      searchId,
      candidatePreviewId,
      getEmailPreviewForCandidate,
      currentEmailConfigTabInRoute,
    ]
  );

  const onRecipientSelectionChanged = useCallback((recipientId, checked) => {
    setSelectedRecipients((state) =>
      checked ? R.union([recipientId], state) : R.without([recipientId], state)
    );
  }, []);

  const newRecipientsSections = useMemo(() => {
    return getRecipientsSections({
      dataSourceId,
      jobDescriptionId,
      recipientsList: filteredNewRecipients,
      selectedRecipients,
      openedSections,
      onSectionTitleClicked,
      onSectionCheckboxClicked,
      onRecipientSelectionChanged,
      onRecipientNameClick,
      candidatePreviewId,
      currentTabInRoute,
    });
  }, [
    filteredNewRecipients,
    openedSections,
    selectedRecipients,
    onRecipientSelectionChanged,
    onSectionCheckboxClicked,
    dataSourceId,
    jobDescriptionId,
    onRecipientNameClick,
    candidatePreviewId,
    currentTabInRoute,
  ]);

  const sentRecipientsSections = useMemo(() => {
    return getRecipientsSections({
      dataSourceId,
      jobDescriptionId,
      recipientsList: filteredSentRecipients,
      selectedRecipients,
      openedSections,
      onSectionTitleClicked,
      onRecipientNameClick,
      sentMode: true,
      candidatePreviewId,
      currentTabInRoute,
    });
  }, [
    filteredSentRecipients,
    openedSections,
    selectedRecipients,
    dataSourceId,
    jobDescriptionId,
    onRecipientNameClick,
    candidatePreviewId,
    currentTabInRoute,
  ]);

  const onSendEmailsClicked = useCallback(async () => {
    setSendingEmails(true);
    const params: SendJobEmailsRequest = {
      profileIds: selectedRecipients,
      reminderEnabled: isReminderEnabled,
      reminderInDays: Number(remindInDaysValue.value),
    };
    try {
      await sendJobEmails(searchId, emailId, params);
      await fetchJobEmailById(searchId, emailId);
      setSendEmailsOverlayOpened(false);
      setSendingEmails(false);
      setSelectedRecipients([]);
    } catch (e) {
      setSendingEmails(false);
    }
  }, [
    selectedRecipients,
    searchId,
    emailId,
    isReminderEnabled,
    remindInDaysValue,
    sendJobEmails,
    fetchJobEmailById,
  ]);

  const isSendEmailButtonDisabled = !selectedRecipients.length;

  const onChangeTabs = useCallback(
    (newTab: SelectRecipientsTabs) => {
      history.push(
        makeEmailConfigUrl({
          dataSourceId,
          jobDescriptionId,
          emailId,
          tab: locationSearch.tab as LineupTabs,
          params: {
            recipientsTab: newTab,
            emailConfigTab: currentEmailConfigTabInRoute,
          },
        })
      );
    },
    [
      history,
      dataSourceId,
      emailId,
      jobDescriptionId,
      locationSearch.tab,
      currentEmailConfigTabInRoute,
    ]
  );

  return (
    <div className={styles.selectRecipientsWrapper}>
      <Header level={3} bold className={styles.selectRecipientsTitle}>
        {phrases.SELECT_RECIPIENTS_TITLE}
      </Header>
      <div className={styles.tabsContainer}>
        <TabsControl<SelectRecipientsTabs>
          items={tabItems}
          selectedTab={currentRecipientsTab}
          onChange={onChangeTabs}
        />
      </div>
      {currentRecipientsTab === SelectRecipientsTabs.New && (
        <div className={styles.tabNewWrapper}>
          <>
            {R.isNullOrEmpty(recipients.new) ? (
              <div className={styles.messageSection}>
                <Header level={3} bold className={styles.messageSectionTitle}>
                  {phrases.SELECT_RECIPIENTS_NO_CANDIDATES_TITLE}
                </Header>
                <Paragraph>
                  {phrases.SELECT_RECIPIENTS_NO_CANDIDATES_MESSAGE}
                </Paragraph>
              </div>
            ) : (
              <>
                <div className={styles.filterWrapper}>
                  <FilterWithPlaceholder
                    className={styles.filterField}
                    placeholderTitle={
                      phrases.SELECT_RECIPIENTS_FILTER_PLACEHOLDER
                    }
                    onChange={onChangeInputHandle}
                    defaultValue={emailTabsFilter}
                  />
                </div>
                <div className={styles.scrollableArea}>
                  {newRecipientsSections}
                </div>
              </>
            )}
          </>
        </div>
      )}
      {currentRecipientsTab === SelectRecipientsTabs.Sent && (
        <div className={styles.tabSentWrapper}>
          <>
            {R.isNullOrEmpty(recipients.sent) ? (
              <div className={styles.messageSection}>
                <Header level={3} bold className={styles.messageSectionTitle}>
                  {phrases.SELECT_RECIPIENTS_NO_EMAIL_SENT_TITLE}
                </Header>
                <Paragraph>
                  {phrases.SELECT_RECIPIENTS_NO_EMAIL_SENT_MESSAGE}
                </Paragraph>
              </div>
            ) : (
              <>
                <div className={styles.filterWrapper}>
                  <FilterWithPlaceholder
                    className={styles.filterField}
                    placeholderTitle={
                      phrases.SELECT_RECIPIENTS_FILTER_PLACEHOLDER
                    }
                    onChange={onChangeInputHandle}
                    defaultValue={emailTabsFilter}
                  />
                </div>
                <div className={styles.scrollableArea}>
                  {sentRecipientsSections}
                </div>
              </>
            )}
          </>
        </div>
      )}
      {currentRecipientsTab === SelectRecipientsTabs.New && (
        <div className={styles.panelFooter}>
          <Button
            variant={Button.variants.POSITIVE_CONFIRM}
            small
            alignCenter
            disabled={isSendEmailButtonDisabled}
            loaderPosition={Button.loaderPosition.LEFT}
            isLoading={false}
            onClick={() => setSendEmailsOverlayOpened(true)}
          >
            {phrases.SELECT_RECIPIENTS_SEND_BUTTON_TEXT}
          </Button>
        </div>
      )}
      {isSendEmailsOverlayOpened && (
        <div className={styles.sendEmailOverlay}>
          <div className={styles.candidatesSelectedInfo}>
            <Paragraph bold>{selectedRecipients.length}</Paragraph>
            <UIText small bold className={styles.candidatesSelectedInfoText}>
              {phrases.SELECT_RECIPIENTS_CANDIDATES_SELECTED_TEXT(
                selectedRecipients.length
              )}
            </UIText>
          </div>
          <div className={styles.emailsSendFromInfo}>
            <Paragraph small>
              {phrases.SELECT_RECIPIENTS_SENT_FROM_TEXT}{' '}
              <strong>{customerCompanySettings?.contactEmail}</strong>
            </Paragraph>
          </div>
          <div className={styles.separator} />
          <div className={styles.reminderWrapper}>
            <label className={styles.reminderSwitcher}>
              <Toggle
                disabled={false}
                checked={isReminderEnabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setIsReminderEnabled(event.target.checked)
                }
              />
              {phrases.REMINDER_LABEL}
            </label>
            <FormDropdown
              isBorderOverflown
              isDisabled={!isReminderEnabled}
              menuPlacement="top"
              className={styles.reminderDaysDropdown}
              valueContainerClassName={styles.reminderDaysDropdownValueField}
              value={remindInDaysValue}
              options={REMINDER_OPTIONS}
              onChange={setRemindInDaysValue}
            />
          </div>
          <Button
            small
            alignCenter
            className={styles.actionButton}
            variant={Button.variants.POSITIVE_CONFIRM}
            loaderPosition={Button.loaderPosition.LEFT}
            isLoading={isSendingEmails}
            onClick={onSendEmailsClicked}
          >
            {phrases.SELECT_RECIPIENTS_OVERLAY_SEND_BUTTON_TEXT}
          </Button>
          <Button
            small
            alignCenter
            className={styles.actionButton}
            variant={Button.variants.POSITIVE_MAIN}
            loaderPosition={Button.loaderPosition.LEFT}
            isLoading={false}
            onClick={() =>
              history.push(
                makeEmailConfigUrl({
                  dataSourceId,
                  jobDescriptionId,
                  emailId,
                  tab: locationSearch.tab as LineupTabs,
                  isEditMode: true,
                  params: {
                    emailConfigTab: currentEmailConfigTabInRoute,
                  },
                })
              )
            }
          >
            {phrases.SELECT_RECIPIENTS_OVERLAY_CANCEL_BUTTON_TEXT}
          </Button>
        </div>
      )}
    </div>
  );
};
