import { DataSourceType } from '@air/api/models';
import * as phrases from '@air/constants/phrases';

export const availableATS = [
  {
    value: DataSourceType.EMPLOYAATS,
    label: phrases.ATS_DROPDOWN_EMPLOYA_LABEL,
  },
  {
    value: DataSourceType.GREENHOUSEATS,
    label: 'Greenhouse',
  },
  {
    value: DataSourceType.ORACLETALEO,
    label: 'Taleo'
  },
  {
    value: DataSourceType.LEVERATS,
    label: 'Lever'
  },
  {
    value: DataSourceType.SAPATS,
    label: 'SAP SuccessFactors'
  },
];

export enum SignupSteps {
  PasswordSetupStep,
  ATSSelectionStep,
  GreenhouseIntegrationStep,
  GreenhouseIntegrationSuccess,
  GreenhouseIntegrationFailure,
  TaleoIntegrationStep,
  TaleoIntegrationSuccess,
  TaleoIntegrationFailure,
  LeverIntegrationFailure,
  SAPIntegrationStep,
  SAPIntegrationSuccess,
  SAPIntegrationFailure,
}

export const ATSSignupSteps = {
  [DataSourceType.GREENHOUSEATS]: SignupSteps.GreenhouseIntegrationStep,
  [DataSourceType.ORACLETALEO]: SignupSteps.TaleoIntegrationStep,
  [DataSourceType.LEVERATS]: null as SignupSteps,
  [DataSourceType.EMPLOYAATS]: null as SignupSteps,
  [DataSourceType.GITHUB]: null as SignupSteps,
  [DataSourceType.SAPATS]: SignupSteps.SAPIntegrationStep,
};
