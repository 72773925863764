import React, { CSSProperties } from 'react';
import R from '@air/third-party/ramda';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import classNames from 'classnames';
import { OptionTypeBase } from 'react-select/src/types';

import { TooltipWrapper } from '@air/components';
import style from './FormTypeahead.css';
import { formDropdownStyles } from '@air/components/Form/FormDropdown/FormDropdown';
import * as phrases from '@air/constants/phrases';
import { isMobileOrTablet } from '@air/utils/mobile';

const AMOUNT_OF_SHOWN_CHARS = 30;

const emptyComponentFn = (): any => null;

const typeaheadStyles = {
  ...formDropdownStyles,
  input: (base: any, state: any): CSSProperties => ({
    ...base,
    padding: '0',
    margin: '0',
    display: 'flex',
    justifySelf: 'center',
    position: 'absolute',
    bottom: state.selectProps?.label ? '1.2rem' : '-0.4rem',
    fontWeight: 450,
    maxWidth: 'calc(100% - 0.8rem)',
  }),
  singleValue: (): CSSProperties => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
    flex: '0 0 auto',
    paddingBottom: '0.4rem',
    fontWeight: 450,
  }),
  valueContainer: (base: any, state: any): CSSProperties => ({
    ...base,
    position: 'relative',
    padding: '0 0.4rem',
    width: '100%',
    minHeight: '4rem',
    height: state.selectProps?.label ? '6.8rem' : '4rem',
    border: 'none',
    color: 'var(--steel-80-color)',
  }),
  loadingIndicator: () => ({
    display: 'none',
  }),
  control: (base: any, state: any): any => {
    return {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      maxHeight: '100%',
      maxWidth: '100%',
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '-0.4rem',
        left: 0,
        width: '100%',
        visibility:
          R.isNullOrEmpty(state.selectProps?.options) &&
          !state.selectProps?.isLoading &&
          !state.selectProps.inputValue
            ? 'hidden'
            : 'visible',
        height: state.selectProps?.label ? '6.8rem' : '5.6rem',
        background: 'var(--white-color)',
        borderLeft: '1px solid var(--steel-10-color)',
        borderTop: '1px solid var(--steel-10-color)',
        borderRight: '1px solid var(--steel-10-color)',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
        borderTopRightRadius: '1.4rem',
        borderTopLeftRadius: '1.4rem',
      },
    };
  },
  menu: (base: any, state: any): CSSProperties => ({
    position: 'absolute',
    left: 0,
    top: state.selectProps?.label ? '6.8rem' : '5.6rem',
    background: 'var(--white-color)',
    width: '100%',
    maxWidth: '100%',
    // @ts-ignore
    zIndex: 'var(--form-typeahead-menu-z-index)',
  }),
  noOptionsMessage: (): CSSProperties => ({
    fontSize: '1.4rem',
    color: 'var(--steel-30-color)',
    textAlign: 'center',
    padding: '1rem 0',
    letterSpacing: 0,
  }),
  loadingMessage: (): CSSProperties => ({
    fontSize: '1.4rem',
    color: 'var(--steel-30-color)',
    textAlign: 'center',
    padding: '1rem 0',
    letterSpacing: 0,
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
};

const typeaheadComponents = {
  DropdownIndicator: emptyComponentFn,
  IndicatorSeparator: emptyComponentFn,
  Placeholder: ({ children, ...props }: any) => {
    const { selectProps } = props;

    return (
      <div
        className={classNames(style.placeholderWrapper, {
          [style.empty]: !props.hasValue,
          [style.focused]: selectProps?.menuIsOpen,
        })}
      >
        {selectProps?.placeholder}
      </div>
    );
  },
  ValueContainer: ({ children, ...props }: any) => {
    const { selectProps } = props;
    const shownValue = selectProps?.value?.label;
    const tooltipEnabled =
      !selectProps?.menuIsOpen &&
      props.hasValue &&
      shownValue?.length > AMOUNT_OF_SHOWN_CHARS;

    return (
      <components.ValueContainer {...props}>
        <div
          className={classNames(style.valueContainerWrapper, {
            [style.success]: selectProps?.isValid,
            [style.error]: selectProps?.hasError,
            [style.warning]: selectProps?.hasWarning,
            [style.empty]: !props.hasValue,
            [style.disabled]: selectProps?.isDisabled,
            [style.focused]: selectProps?.menuIsOpen,
            [style.withPlaceholder]: selectProps?.placeholder,
            [style.withLabel]: selectProps.label,
            [style.withoutLabel]: !selectProps.label,
          })}
        >
          <div className={style.label}>{selectProps?.label}</div>
          <TooltipWrapper
            enabled={tooltipEnabled}
            tooltip={shownValue}
            trigger={isMobileOrTablet ? 'click' : 'hover'}
          >
            {children}
          </TooltipWrapper>
        </div>
      </components.ValueContainer>
    );
  },
};

type TypeaheadProps = {
  id: string;
  type?: string;
  name: string;
  value: OptionTypeBase;
  defaultOptions?: any[];
  label?: string;
  placeholder?: string;
  creatable?: boolean;
  isDisabled?: boolean;
  isValid?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  loadOptions?: (value: string, cb: (...args: any[]) => any) => void;
  onChange?: (value: any) => void;
  className?: string;
  isClearable?: boolean;
  noResultsMessage?: string;
  optionWithIcon?: boolean;
  ref?: any;
};
export const FormTypeahead: React.FC<TypeaheadProps> = React.forwardRef(
  (
    { value, noResultsMessage = phrases.NO_RESULTS_FOUND_MESSAGE, ...props },
    ref
  ) => {
    const SelectComponent = props.creatable
      ? AsyncCreatableSelect
      : AsyncSelect;

    return (
      <div className={classNames(style.formDropdownWrapper, props.className)}>
        <SelectComponent
          {...ref}
          {...props}
          name={props.name}
          components={typeaheadComponents}
          styles={typeaheadStyles}
          classNamePrefix="typeahead"
          defaultOptions={props?.defaultOptions}
          value={value}
          placeholder={props?.placeholder ?? null}
          creatable={props?.creatable}
          createOptionPosition="first"
          label={props?.label}
          hasError={props?.hasError}
          isDisabled={props?.isDisabled}
          isValid={props?.isValid}
          hasWarning={props?.hasWarning}
          noOptionsMessage={({ inputValue }: any) =>
            inputValue ? noResultsMessage : null
          }
          loadOptions={props?.loadOptions}
          onChange={props?.onChange}
        />
      </div>
    );
  }
);
