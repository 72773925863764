import { CandidateCriteriaListItem } from 'domain/CandidateData';

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import R from '@air/third-party/ramda';
import { Field, FieldArray, FieldProps, FormikHelpers } from 'formik';

import { SearchCriteriaImportanceEnum } from '@air/api';
import { Card, MaxDistanceWidget } from '@air/components';
import { SelectRenderer } from '@air/components/Select/Select';

import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import { FormSelectEntity } from '@air/components/Select/typings';

import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import styles from './LocationCard.css';
import { useStateMachine } from '@air/hooks';
import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import { SearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';
import {
  getOnMainCardKeyDownCallback,
  MAX_CARDS_IN_STACK,
  getOnStackCardKeyDownCallback,
  getCommonFieldsForCriteriaCard,
  convertMaxAllowedDistanceToInfoString,
  hasInactiveAlert,
  convertDistanceToCriteriaLocationToInfoString,
} from 'components/Cards/cardsCommonCode';
import { CardType, getImportanceValue } from 'domain/SearchCriteria';
import {
  getMainCardTitle,
  getCardCriterionLabel,
  getAddingLimits,
  getSearchCriteriaCardFooter,
  getCardStackSize,
  getTopStackListName,
  getCardTitle,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import {
  CriteriaEditCard,
  CriteriaCollapsedCard,
} from 'components/CardWrappers';
import { generateCriteriaCardTopStackItems } from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import {
  generateTopStackItems,
  generateBottomStackItems,
  CriteriaEditCardStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import * as phrases from 'constants/phrases';
import {
  LocationCriteriaData,
  GOOGLE_LOCATION_AUTOCOMPLETE_LANGUAGE,
  GOOGLE_AUTOCOMPLETE_TYPES,
  filterCitiesStatesAndCountries,
  GOOGLE_PLACE_DETAILS_FIELDS,
  mapGooglePlaceDetailsToLocationInfo,
} from 'domain/SearchCriteria/LocationCriteriaData';
import {
  withCustomDrag,
  makeDraggableComponent,
  DraggableCriteriaCardProps,
} from 'components/CustomDragLayer/CustomDragLayer';
import { toast } from '@air/third-party/toast';
import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';
import { MaxDistanceReadOnlyWidget } from '@air/components/SelectRangeWidgets/MaxDistanceWidget';
import { INPUT_DEBOUNCE_TIME } from '@air/constants/app';
import classNames from 'classnames';
import { CandidateLocationData } from 'domain/CandidateData/CandidateCriteria/CandidateLocationData';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

type LocationCardComponentProps = {
  namePrefix: string;
  cardData: LocationCriteriaData;
  setFieldValue: FormikHelpers<LocationCriteriaData>['setFieldValue'];
  onUpdate: () => void;
  onRemove: (value?: string | number) => void;
  changeCardImportance: (
    criteria: LocationCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;

  onRemoveStackItem: (value: string | number, list?: string) => void;

  optionsFilter: (value: any) => boolean;
  importanceSectionOrder: number;

  handleDrop: (id: string | number, itemId: string | number) => void;
  handleSingleCriteriaDrop: (
    id: string | number,
    itemId: string | number,
    groupId: string | number
  ) => void;
  isReadOnly?: boolean;
};

let loadAsyncOptionsTimer: NodeJS.Timeout;

const mapGooglePredictionToDropdownOption = (
  it: google.maps.places.AutocompletePrediction
) =>
  ({
    value: it.place_id,
    label: it.description,
  } as FormSelectEntity);

const getMapElement = () => {
  const elementId = 'google-map';
  let element = document.getElementById(elementId);
  if (element) {
    return element;
  } else {
    element = document.createElement('div');
    element.id = elementId;
    element.style.display = 'none';
    document.body.appendChild(element);
    return element;
  }
};

/*
  match an empty string or a string without whitespaces at the beginning
  so once typing the string, user can still clear the input
 */
const whitelistedChars = `a-zA-Z0-9&/,.!?#+\\-:;`;

const locationTitleRegex = new RegExp(
  `^$|^[${whitelistedChars}][${whitelistedChars} ]{0,100}$`
);

export const getLocationReadOnlyFields = (
  card: LocationCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
    <MaxDistanceReadOnlyWidget
      value={card.maxAllowedDistance}
      key="max-allowed-distance-field"
    />,
  ];
};

export const getLocationFieldsForCandidateCollapsedCard = (
  mainCard: CandidateCriteriaListItem,
  allCardData: CandidateLocationData
) => {
  return [
    <Card.Title
      resizeable
      flexGrow={false}
      title={getCardTitle({
        cardType: allCardData.cardType,
        label: mainCard.label,
      })}
      key="card-title"
    />,
    !!mainCard.alert && (
      <Card.Alert
        key="card-alert"
        className={classNames(cardCommonStyles.cardTitleAlert, {
          [cardCommonStyles.inactiveAlert]:
            hasInactiveAlert<CandidateCriteriaListItem>(mainCard),
        })}
      >
        {mainCard.alert.displayName}
      </Card.Alert>
    ),
    !!mainCard.distanceToCriteriaLocation && (
      <Card.Footer
        className={styles.cardFooter}
        text={convertDistanceToCriteriaLocationToInfoString(
          mainCard.distanceToCriteriaLocation
        )}
        key="card-footer"
      />
    ),
  ];
};

export const getLocationFieldsForCollapsedCriteriaCard = (
  cardData: LocationCriteriaData
) => {
  const fields = getCommonFieldsForCriteriaCard(cardData);

  return [
    fields.title,
    !!cardData.maxAllowedDistance && (
      <Card.Footer
        key="card-location"
        className={cardCommonStyles.experienceYears}
        text={convertMaxAllowedDistanceToInfoString(
          cardData.maxAllowedDistance
        )}
      />
    ),
    fields.footer,
  ];
};

const DraggableLocationCriteriaCard = makeDraggableComponent<
  DraggableCriteriaCardProps<LocationCriteriaData>,
  { id: number | string }
>(
  withCustomDrag(CriteriaCollapsedCard),
  DraggedSearchCriteriaCardType.location,
  {
    beginDrag: ({
      id,
      title,
      criteria,
      importanceSectionOrder,
      onDragStart,
      cardClasses,
    }) => {
      onDragStart && onDragStart();
      return {
        title,
        id,
        beginDragClass: cardClasses?.beginDragClass,
        order: criteria.idx,
        importance: getImportanceValue(criteria),
        importanceSectionOrder,
        type: DraggedSearchCriteriaCardType.location,
      };
    },
    endDrag: ({ onDragEnd }) => {
      onDragEnd && onDragEnd();
    },
  }
);
DraggableLocationCriteriaCard.displayName = 'DraggableLocationCriteriaCard';

export const LocationCriteriaCard: React.FC<LocationCardComponentProps> = ({
  namePrefix,
  cardData,
  importanceSectionOrder,
  optionsFilter,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  changeCardImportance,
  setFieldValue,
  isReadOnly = false,
}) => {
  const cardTitle = getMainCardTitle(cardData);
  const [cardFooter] = getSearchCriteriaCardFooter(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const stackSize = getCardStackSize(cardData);
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: !isNewCard,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <DraggableLocationCriteriaCard
            criteria={cardData}
            id={cardData.key}
            title={cardTitle}
            stackItems={[topStackItems]}
            footerText={cardFooter}
            cardClasses={{
              footerClass: cardCommonStyles.experienceYears,
            }}
            importance={cardImportance}
            stackSize={stackSize}
            cardLabel={cardLabel}
            onRemove={onRemove}
            onRemoveStackItem={onRemoveStackItem}
            importanceSectionOrder={importanceSectionOrder}
            initialCardStatus={cardData.initialCardStatus}
          />
        ) : (
          <LocationCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            optionsFilter={optionsFilter}
            changeCardImportance={changeCardImportance}
            setFieldValue={setFieldValue}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};

export const LocationCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: LocationCriteriaData;
  changeCardImportance: (
    criteria: LocationCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  optionsFilter: (value: any) => boolean;
  isReadOnly?: boolean;
  setFieldValue: FormikHelpers<LocationCriteriaData>['setFieldValue'];
}> = ({
  namePrefix,
  values,
  optionsFilter,
  changeCardImportance,
  isReadOnly = false,
  setFieldValue,
}) => {
  const locationCardConfig = cardsConfig[CardType.location];
  const topStackListName = getTopStackListName(values);

  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(
    values,
    MAX_CARDS_IN_STACK
  );

  const [, setGoogleMap] = useState<google.maps.Map>(null);
  const [googleAutocompleteService, setGoogleAutocompleteService] =
    useState<google.maps.places.AutocompleteService>(null);
  const [googlePlacesService, setGooglePlacesService] =
    useState<google.maps.places.PlacesService>(null);
  const [googleSessionToken, setGoogleSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>(null);

  useEffect(() => {
    new GoogleMapsLoader({
      apiKey: GOOGLE_LOCATION_AUTOCOMPLETE_KEY,
      version: 'weekly',
      language: GOOGLE_LOCATION_AUTOCOMPLETE_LANGUAGE,
      libraries: ['places'],
    })
      .load()
      .then(() => {
        // we need to create map because it has to be
        // passed to PlacesService which in turn is needed
        // to get place details
        const mapElement = getMapElement();
        const mapInstance = new google.maps.Map(mapElement);
        setGoogleMap(mapInstance);
        setGoogleAutocompleteService(
          new google.maps.places.AutocompleteService()
        );
        setGooglePlacesService(
          new google.maps.places.PlacesService(mapInstance)
        );
        // google requires us to generate new token for every search session.
        // session starts when user starts typing and ends when user gets
        // place details after picking certain item from the list
        setGoogleSessionToken(
          new google.maps.places.AutocompleteSessionToken()
        );
      });
  }, []);

  const loadAsyncOptions = useCallback(
    (input = '') => {
      return new Promise<FormSelectEntity[]>((resolve) => {
        if (input) {
          clearTimeout(loadAsyncOptionsTimer);
          loadAsyncOptionsTimer = setTimeout(() => {
            googleAutocompleteService.getPlacePredictions(
              {
                input,
                sessionToken: googleSessionToken,
                types: GOOGLE_AUTOCOMPLETE_TYPES,
              },
              (result, status) => {
                if (
                  ![
                    google.maps.places.PlacesServiceStatus.OK,
                    google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
                  ].includes(status)
                ) {
                  toast.error(
                    `${phrases.LOCATION_ERROR_FETCHING_GOOGLE_PREDICTIONS}: ${status}`,
                    {
                      toastId: 'LOCATION_ERROR_FETCHING_GOOGLE_PREDICTIONS',
                    }
                  );
                }

                resolve(
                  R.pipe<
                    [google.maps.places.AutocompletePrediction[]],
                    google.maps.places.AutocompletePrediction[],
                    FormSelectEntity[]
                  >(
                    R.filter(filterCitiesStatesAndCountries),
                    R.map(mapGooglePredictionToDropdownOption)
                  )(result ?? [])
                );
              }
            );
          }, INPUT_DEBOUNCE_TIME);
        } else {
          return resolve([] as FormSelectEntity[]);
        }
      });
    },
    [googleAutocompleteService, googleSessionToken]
  );

  const onOptionSelected = useCallback(
    (onChangeCallback) => (selectedOption: FormSelectEntity) => {
      if (selectedOption) {
        googlePlacesService.getDetails(
          {
            placeId: selectedOption.value as string,
            fields: GOOGLE_PLACE_DETAILS_FIELDS,
            sessionToken: googleSessionToken,
          },
          (result, status) => {
            // reset token when user receives place details
            setGoogleSessionToken(
              new google.maps.places.AutocompleteSessionToken()
            );

            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              toast.error(
                `${phrases.LOCATION_ERROR_FETCHING_GOOGLE_PLACE_DETAILS}: ${status}`
              );
            } else {
              onChangeCallback({
                label: selectedOption.label,
                value: selectedOption.label,
                extras: mapGooglePlaceDetailsToLocationInfo(
                  result,
                  selectedOption.label
                ),
              });
            }
          }
        );
      } else {
        onChangeCallback(null);
      }
    },
    [googlePlacesService, googleSessionToken]
  );

  const getNoOptionsMessage = useCallback((inputValue) => {
    return (
      <div
        className={styles.selectNoOptions}
        dangerouslySetInnerHTML={{
          __html: phrases.OPTION_IS_NOT_FOUND(inputValue),
        }}
      />
    );
  }, []);

  const googleLogoElement = useMemo(() => {
    return (
      <div className={styles.dropdownGoogleLogo}>
        <img src="/assets/images/poweredByGoogle.png" />
      </div>
    );
  }, []);

  const selectLocationValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        const onChange = props.onChange
          ? props.onChange
          : onOptionSelected((value: FormSelectEntity) =>
              form.setFieldValue(field.name, value)
            );

        return (
          <SelectRenderer
            isDisabled={isReadOnly}
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            getSingleValueTooltipProps={
              locationCardConfig.getStackItemTooltipProps
            }
            {...props}
            getDropDownItemTooltipProps={
              locationCardConfig.getSelectDropDownOptionTooltipProps
            }
            className={styles.locationsSelect}
            dropDownAfter={googleLogoElement}
            autoFocus={props.focusField || !field.value}
            hideSelectedOptions
            multilineInput
            getNoOptionsMessage={getNoOptionsMessage}
            inputRegex={locationTitleRegex}
            isClearable
            creatable={false}
            filterOption={optionsFilter}
            asyncOptions={loadAsyncOptions}
            onChange={onChange}
          />
        );
      },
    [
      locationCardConfig,
      optionsFilter,
      getNoOptionsMessage,
      onOptionSelected,
      loadAsyncOptions,
      isReadOnly,
      googleLogoElement,
    ]
  );

  const maxAllowedDistanceFieldName = `${namePrefix}.maxAllowedDistance`;

  const hasMaxAllowedDistanceField = useMemo(() => {
    return (values.list || []).some((it) => {
      return !R.isNil(it?.extras?.city) || !R.isNil(it?.extras?.state);
    });
  }, [values]);

  useEffect(() => {
    if (!hasMaxAllowedDistanceField && !R.isNil(values.maxAllowedDistance)) {
      setFieldValue(maxAllowedDistanceFieldName, null);
    }
  }, [
    setFieldValue,
    maxAllowedDistanceFieldName,
    hasMaxAllowedDistanceField,
    values.maxAllowedDistance,
  ]);

  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      changeCardImportance={changeCardImportance}
      stackItemControl={selectLocationValueControl}
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const locationTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];
              return locationTitlesList
                .slice(0, 1)
                .map((val: FormSelectEntity) => {
                  return (
                    <Field
                      key={R.prop('value', val) || 'new'}
                      name={`${namePrefix}.${topStackListName}.0`}
                    >
                      {({ field, form }: FieldProps) => (
                        <div
                          ref={mainCriteriaNameFieldRef}
                          className={cardCommonStyles.titleFieldWithImportance}
                        >
                          {selectLocationValueControl({
                            field,
                            form,
                            isDisabled: isImportanceSectionOpened || isReadOnly,
                            onKeyDown: getOnMainCardKeyDownCallback(
                              field,
                              arrayHelpers,
                              !isMaxListSizeReached
                            ),
                            onChange: onOptionSelected(
                              async (value: { label: string }) => {
                                await form.setFieldValue(field.name, value);
                                updateMainFieldHandler(value);
                              }
                            ),
                            focusField: !isReadOnly,
                            getSingleValueTooltipProps:
                              locationCardConfig.getMainCardTooltipProps,
                          })}
                          {importanceIndicator}
                        </div>
                      )}
                    </Field>
                  );
                });
            }}
          </FieldArray>
          {hasMaxAllowedDistanceField && (
            <Field name={maxAllowedDistanceFieldName}>
              {({ field, form }: FieldProps) => (
                <MaxDistanceWidget
                  isReadOnly={isReadOnly}
                  value={field.value}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                />
              )}
            </Field>
          )}
        </>
      )}
    </CriteriaEditCard>
  );
};
