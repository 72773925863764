import * as phrases from 'constants/phrases';
import { BaseEducationCriteriaWithList, CardType } from 'domain/SearchCriteria';
import {
  BaseCandidateData,
  CandidateEducationCriteriaListItem,
} from 'domain/CandidateData/CandidateCriteria/BaseCandidateData';
import { GraduationData } from 'domain/CandidateData/CandidateCriteria/GraduationData';
import {
  Education,
  SearchEducationInstitution,
  SearchEducationResponse,
} from '@air/api';
import { getEducationAlertData } from '@air/domain/Alerts/AlertData';
import { criteriaImportanceOptions } from 'constants/skills';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateInstitutionData
  extends BaseCandidateData,
    BaseEducationCriteriaWithList<CandidateEducationCriteriaListItem> {
  cardType: CardType.institution;
  setInCriteria: boolean;
  graduation: GraduationData;
}

const getInstitutionItem = (
  institution: SearchEducationInstitution,
  education: Education | null,
  interview: InterviewData
): CandidateEducationCriteriaListItem => {
  return {
    id: CardType.institution,
    label:
      education?.institution?.fullName ??
      education?.institution?.originalName ??
      phrases.INSTITUTION_LABEL,
    status: institution.status,
    alert: getEducationAlertData(institution),
    interview,
  };
};
export const getInstitutionData = (
  education: SearchEducationResponse,
  graduation: GraduationData,
  interview: InterviewData
): CandidateInstitutionData => {
  if (!education.institution || !education.institution?.setInCriteria) {
    return null;
  }
  const { institution } = education;
  const idealList = [
    getInstitutionItem(institution, education.education, interview),
  ];

  return {
    // @ts-ignore idx is a part of SearchEducationDegree type
    idx: institution.idx,
    refId: institution.primaryItemRefId,
    id: CardType.institution,
    cardType: CardType.institution,
    idealList: idealList,
    acceptableList: null,
    setInCriteria: institution.setInCriteria,
    status: institution.status,
    importance: criteriaImportanceOptions[institution.importance],
    graduation,
  };
};

export const institutionHasComments = (
  institution: CandidateInstitutionData
): boolean => {
  return !!institution.idealList?.[0]?.interview?.commentId;
};
