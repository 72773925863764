import R from '@air/third-party/ramda';

export function wrapInQuotes(str: string) {
  return `"${str}"`;
}

const fileExtensionRegex = new RegExp(
  /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim
);

export const intBiggerThanZeroRegex = new RegExp(/^[1-9]\d*$/);
/**
 * taken from http://emailregex.com/
 * with fixes taken from https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression/201378#201378
 */
export const emailRegex = new RegExp(
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
);

export const urlRegex = new RegExp(
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/i
);

/*
  Allows passwords of 8-100 chars length with at least 1 digit, 1 uppercase and 1 lowecase letter
*/
export const passwordRegex = new RegExp(
  /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,100}$/
);

export const containsOnlyNumbersRegex = /^\d+$/;

export const isEmailValid = (email: string) => {
  if (!email) return false;
  return R.test(emailRegex, email);
};

export const isNumberValid = (maxValue: number) => (value: string) => {
  const isBiggerThanZero = intBiggerThanZeroRegex.test(value);
  return isBiggerThanZero && Number(value) <= maxValue;
};

export const transformFileName = (
  fileName: string,
  charsToLeave = 33
): string => {
  if (!fileName) return '';

  const fileExtension = fileName.match(fileExtensionRegex) || [];
  const fileExtensionLength = fileExtension[0]?.length || 0;
  // cut extension name length from the file name, for example `.gif` -> cut 4 - 1 = 3 chars
  const endStringCharsCut = fileName.length - fileExtensionLength - 1;
  const fileNameWithoutExtension = fileName.substring(0, endStringCharsCut);

  if (fileNameWithoutExtension.length > charsToLeave) {
    // {first35chars}{...}.extension
    return `${fileNameWithoutExtension.substring(
      0,
      charsToLeave
    )}...${fileExtension}`;
  }

  return fileName;
};

export const toTitleCase = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const cropAndAddEllipsis = (
  text?: string,
  lengthBeforeEllipsis?: number
) => {
  return text?.length > lengthBeforeEllipsis
    ? text.slice(0, lengthBeforeEllipsis) + '...'
    : text;
};
