import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { Paragraph, TooltipWrapper, UIText, Tooltip } from '@air/components';
import { useOverflowCheck } from '@air/utils/hooks';
import { CandidateLineupData } from 'domain/CandidateData/CandidateLineupData';
import { getWarningIcon } from 'components/SearchResultsView/utils';
import {
  CandidateSearchProfileStatusEnum,
  statusIconMap,
} from 'domain/CandidateData';
import { SvgIcon } from 'components';

import {
  CandidateStatusToggle,
  StatusPopupLocation,
} from 'components/CandidateProfile/CandidateStatusToggle/CandidateStatusToggle';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import styles from './SearchResultsView.css';
import statusToggleStyles from 'components/CandidateProfile/CandidateStatusToggle/CandidateStatusToggle.css';

type NameCellProps = {
  onStatusPopupToggle: (id: string, isOpened: boolean) => void;
  onStatusChange: (
    id: string,
    currentStatus: CandidateSearchProfileStatusEnum,
    nextStatus: CandidateSearchProfileStatusEnum
  ) => void | null;
  onClick: (id: string, cardId?: number | string) => void;
  item: CandidateLineupData;
  isSelected: boolean;
  isFaded: boolean;
  className?: string;
  isReadOnly?: boolean;
  moveCandidateToApplied?: (candidate: CandidateLineupData) => void;
  moveCandidateToPassive?: (candidate: CandidateLineupData) => void;
};

export const NameCell: React.FC<NameCellProps> = React.memo(
  ({
    item,
    isSelected,
    onClick,
    onStatusPopupToggle,
    onStatusChange,
    isFaded,
    isReadOnly,
    moveCandidateToApplied,
    moveCandidateToPassive,
  }) => {
    const {
      fullName,
      id,
      status,
      systemStatus,
      warningType,
      hasUnreadNotes: isNoteIndicator,
    } = item;

    const warningIcon = useMemo(
      () => !R.isNil(warningType) && getWarningIcon(warningType),
      [warningType]
    );

    const [cellStatus, updateCellStatus] = useState(status);
    const onStatusChangeCb = useCallback(
      (candidateStatus: CandidateSearchProfileStatusEnum) => {
        if (onStatusChange) {
          updateCellStatus(candidateStatus);
          onStatusChange(id, status, candidateStatus);
        }
      },
      [updateCellStatus, id, status, onStatusChange]
    );

    useEffect(() => {
      if (status !== cellStatus) {
        updateCellStatus(status);
      }
    }, [status, cellStatus]);

    const onStatusWidgetToggle = useCallback(
      (isOpened: boolean) => {
        onStatusPopupToggle(item.id, isOpened);
      },
      [item.id, onStatusPopupToggle]
    );
    const [fullNameRef, isFullNameOverflown] =
      useOverflowCheck<HTMLSpanElement>(fullName);

    const candidateIndicators = useMemo(() => {
      return (
        <div className={styles.candidateIndicators}>
          {isNoteIndicator && (
            <SvgIcon icon="icon-letter-big" width="2.2em" height="2.2em" />
          )}
          {warningIcon}
        </div>
      );
    }, [isNoteIndicator, warningIcon]);

    const renderCurrentStatus = useCallback(
      (status: CandidateSearchProfileStatusEnum, className: string) => {
        const { currentStatus, icon } = statusIconMap[status];
        return (
          <div
            className={classNames(
              statusToggleStyles.popupCurrentStatus,
              className
            )}
            key={status}
          >
            <SvgIcon width="2em" height="2em" icon={`${icon}status`} />
            <div className={statusToggleStyles.popupCandidateInfoWrapper}>
              <TooltipWrapper
                enabled={isFullNameOverflown}
                placement="top"
                trigger="hover"
                tooltip={fullName}
              >
                <div className={statusToggleStyles.popupCandidateNameWrapper}>
                  <Paragraph
                    big
                    className={statusToggleStyles.candidateName}
                    ref={fullNameRef}
                  >
                    {fullName}
                  </Paragraph>
                </div>
              </TooltipWrapper>
              <div className={statusToggleStyles.popupCandidateStatus}>
                <UIText tiny bold>
                  {currentStatus}
                </UIText>
              </div>
            </div>
            {candidateIndicators}
          </div>
        );
      },
      [fullName, fullNameRef, isFullNameOverflown, candidateIndicators]
    );

    const { icon, currentStatus } = statusIconMap[status] || {};

    return (
      <div
        className={classNames(styles.nameCell, {
          [styles.selectedProfile]: isSelected,
          [styles.faded]: isFaded,
          [styles.withStatusIcon]:
            cellStatus !== CandidateSearchProfileStatusEnum.NOTPROCESSED &&
            !!onStatusChange &&
            !isReadOnly,
        })}
      >
        {!!onStatusChange && !isReadOnly && (
          <CandidateStatusToggle
            onChange={onStatusChangeCb}
            initialStatus={cellStatus}
            systemStatus={systemStatus}
            popupLocation={StatusPopupLocation.LineupTable}
            renderCurrentStatus={renderCurrentStatus}
            onToggle={onStatusWidgetToggle}
            className={styles.statusTogglePopup}
            moveToApplied={
              moveCandidateToApplied && (() => moveCandidateToApplied(item))
            }
            moveToPassive={
              moveCandidateToPassive && (() => moveCandidateToPassive(item))
            }
          >
            <div className={classNames(statusToggleStyles.statusToggle)}>
              <Tooltip
                placement="top"
                delayShow={TOOLTIP_DELAY_TIME_LONG}
                trigger="hover"
                tooltip={currentStatus}
              >
                <button className={statusToggleStyles.statusButton}>
                  <SvgIcon width="2em" height="2em" icon={`${icon}status`} />
                </button>
              </Tooltip>
            </div>
          </CandidateStatusToggle>
        )}
        <TooltipWrapper
          enabled={isFullNameOverflown}
          placement="top"
          trigger="hover"
          tooltip={fullName}
        >
          <Paragraph
            big
            ref={fullNameRef}
            onClick={() => onClick(id)}
            className={classNames(styles.candidateName, {
              [styles.manuallyRejectedCandidate]:
                cellStatus === CandidateSearchProfileStatusEnum.MANUALREJECTION,
              [styles.autoRejectedCandidate]:
                cellStatus ===
                CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION,
              [styles.selectedCandidate]:
                cellStatus === CandidateSearchProfileStatusEnum.MANUALSHORTLIST,
              [styles.isFaded]:
                (warningIcon || isNoteIndicator) && isFullNameOverflown,
            })}
          >
            <span>{fullName}</span>
          </Paragraph>
        </TooltipWrapper>
        {candidateIndicators}
      </div>
    );
  }
);
NameCell.displayName = 'NameCell';
