import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import { CompanyExtendedType } from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  normalizeSearchCriteriaExperience,
  SearchCriteriaExperienceT,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria/BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

export interface ExcludeIndustryCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.industry;
  list: SearchCriteriaListValue<{ value: string | number; label: string }>[];
  exclude: true;
  recent: number;
}

export interface IncludeIndustryCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.industry;
  list: SearchCriteriaListValue<{ value: string | number; label: string }>[];
  exclude: false;
  experience: SearchCriteriaExperienceT;
  recent: number;
}

export type IndustryCriteriaData =
  | IncludeIndustryCriteriaData
  | ExcludeIndustryCriteriaData;

export const isIndustry = (
  item: BaseSearchCriteriaData
): item is IndustryCriteriaData => item.cardType === CardType.industry;

export const isExcludeIndustry = (
  item: BaseSearchCriteriaData
): item is ExcludeIndustryCriteriaData => {
  return isIndustry(item) && item.exclude;
};

export const isIncludeIndustry = (
  item: BaseSearchCriteriaData
): item is IncludeIndustryCriteriaData => {
  return isIndustry(item) && !item.exclude;
};

export const getIndustryDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.IsNewEdit,
}: AddCriteriaInitials): IncludeIndustryCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  list: [null],
  initialCardStatus,
  importance: criteriaImportanceOptions[importance],
  cardType: CardType.industry,
  exclude: false,
  experience: null,
  recent: null,
});

export const mapExcludeListToIndustryCriteria = (
  industry: ApiModel.ExcludeExperienceList
): ExcludeIndustryCriteriaData => ({
  idx: industry.idx,
  id: `exclude-${industry.idx}`,
  key: genId(),
  cardType: CardType.industry,
  exclude: true,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  importance: criteriaImportanceOptions[industry.importance],
  list: industry.list.map((c: ApiModel.DictionaryItemInfo) => ({
    label: c.name,
    value: c.id || c.name,
  })),
  recent: industry.recent,
});

export const mapExcludeIndustryCriteriaToIndustryList = (
  industry: ExcludeIndustryCriteriaData
): ApiModel.ExcludeExperienceList => ({
  idx: industry.idx,
  list: (industry.list || []).map((c) => ({
    ...(c.value !== c.label && {
      id: c.value as number,
    }),
    name: c.label,
    companyExtendedType: CompanyExtendedType.COMPANY,
  })),
  importance: industry.importance.value,
  recent: industry.recent,
});

export const mapIncludeListToIndustryCriteria = (
  industry: ApiModel.IncludeExperienceList
): IncludeIndustryCriteriaData => ({
  idx: industry.idx,
  id: `include-${industry.idx}`,
  key: genId(),
  exclude: false,
  cardType: CardType.industry,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  importance: criteriaImportanceOptions[industry.importance],
  list: industry.list.map((c: ApiModel.DictionaryItemInfo) => ({
    label: c.name,
    value: c.id || c.name,
  })),
  experience: R.pick<
    ApiModel.ExtendedExperienceInfo,
    'idealMin' | 'idealMax' | 'acceptableMin' | 'acceptableMax'
  >(
    ['idealMin', 'idealMax', 'acceptableMin', 'acceptableMax'],
    industry.experience
  ),
  recent: industry.experience.recent,
});

export const mapIncludeIndustryCriteriaToIndustryList = (
  industry: IncludeIndustryCriteriaData
): ApiModel.IncludeExperienceList => ({
  idx: industry.idx,
  list: (industry.list || []).map((c) => ({
    ...(c.value !== c.label && {
      id: c.value as number,
    }),
    name: c.label,
    companyExtendedType: CompanyExtendedType.COMPANY,
  })),
  importance: industry.importance.value,
  experience: {
    ...normalizeSearchCriteriaExperience(industry.experience),
    recent: industry.recent,
  },
});
