import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import classNames from 'classnames';
import 'react-popper-tooltip/dist/styles.css';

import styles from './Tooltip.css';
import { TooltipTriggerProps } from 'react-popper-tooltip/dist/types';

export type TooltipPropsT = Partial<
  Omit<TooltipTriggerProps, 'tooltip' | 'children'>
> & {
  tooltip: string | React.ReactNode;
  containerClassName?: string;
  triggerClassName?: string;
  flexGrow?: boolean;
  delayShow?: number;
  modifiers?: any;
  tooltipShown?: boolean;
  portalContainer?: HTMLElement;
  visibility?: boolean;
  onVisibleChange?: () => void;
};
export const Tooltip: React.FC<TooltipPropsT> = ({
  children,
  tooltip,
  containerClassName,
  triggerClassName,
  flexGrow = true,
  delayShow,
  modifiers,
  placement = 'top',
  trigger = 'hover',
  ...props
}) => (
  <TooltipTrigger
    placement={placement}
    trigger={trigger}
    delayShow={delayShow}
    modifiers={modifiers}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: classNames(
            styles.tooltipContainer,
            containerClassName,
            'tooltip-container'
          ),
        })}
      >
        <div
          {...getArrowProps({
            ref: arrowRef,
            className: classNames(styles.tooltipArrow, 'tooltip-arrow'),
            'data-placement': placement,
          })}
        />
        {tooltip}
      </div>
    )}
    {...props}
  >
    {({ getTriggerProps, triggerRef }: any) => (
      <div
        {...getTriggerProps({
          ref: triggerRef,
          className: classNames(
            styles.tooltipTrigger,
            'trigger',
            triggerClassName,
            {
              [styles.flexGrow]: flexGrow,
            }
          ),
        })}
      >
        {children}
      </div>
    )}
  </TooltipTrigger>
);
