/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum TotalExperienceInterviewOption {
  NOEXPERIENCE = 'NO_EXPERIENCE',
  LESSTHANYEAR = 'LESS_THAN_YEAR',
  ONEYEAR = 'ONE_YEAR',
  TWOYEARS = 'TWO_YEARS',
  THREEYEARS = 'THREE_YEARS',
  FOURYEARS = 'FOUR_YEARS',
  FIVEYEARS = 'FIVE_YEARS',
  SIXYEARS = 'SIX_YEARS',
  SEVENYEARS = 'SEVEN_YEARS',
  EIGHTYEARS = 'EIGHT_YEARS',
  NINEYEARS = 'NINE_YEARS',
  TENORMOREYEARS = 'TEN_OR_MORE_YEARS',
}
