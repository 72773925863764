import React, { useCallback, useMemo, useState } from 'react';
import { Field, FieldArray, FieldProps } from 'formik';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

import {
  CardType,
  getImportanceValue,
  getUnwantedRoleLabel,
  isExcludeRole,
  isIncludeRole,
  isRoleGroupCard,
  RoleCriteriaData,
  SearchCriteriaListValue,
  RoleListValue,
  titleDataMapper,
} from 'domain/SearchCriteria';

import {
  AcceptableExperienceWidget,
  Card,
  IdealExperienceWidget,
  RecentExperienceWidget,
  SvgIcon,
} from '@air/components';
import { SelectRenderer } from '@air/components/Select/Select';

import { useStateMachine } from '@air/hooks';
import {
  SearchCardContext,
  StateContext,
} from 'components/SearchCriteriaCards/hooks/SearchCardContext';
import {
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
  SearchCardStateConfig,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import {
  CriteriaCardViewProps,
  CriteriaDragObjectT,
  getDraggableCriteriaCard,
  getDraggableStackItem,
  getOnMainCardKeyDownCallback,
  getOnStackCardKeyDownCallback,
  hasInactiveAlert,
  isDraggableMainCard,
  isDraggableStackItem,
  isExcludedItem,
  MAX_CARDS_IN_STACK,
  SingleCriteriaItemDragObject,
} from 'components/Cards/cardsCommonCode';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import {
  isAcceptableExperienceDefined,
  isIdealExperienceDefined,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import {
  canMergeLists,
  getIdsInUse,
  WithDefaultValueOnConditionalMounting,
} from 'components/SearchCriteriaCards/utils';
import {
  getAddingLimits,
  getCardCriterionLabel,
  getCardStackSize,
  getCardTitle,
  getMainCard,
  getMainCardTitle,
  getSearchCriteriaCardFooter,
  getTopStackListName,
  getTopStackSize,
} from 'domain/SearchCriteria/cardHelpers';
import { IdealExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/IdealExperienceWidget';
import { AcceptableExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/AcceptableExperienceWidget';
import { RecentExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/RecentExperienceWidget';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import {
  CandidateCriteriaListItem,
  CandidateRoleData,
} from 'domain/CandidateData';
import { SearchCriteriaImportanceEnum, TitleExtendedItemType } from '@air/api';
import {
  CriteriaDraggableCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { generateCriteriaCardTopStackItems } from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import { CriteriaCollapsedCardProps } from 'components/CardWrappers/CriteriaCollapsedCard/CriteriaCollapsedCard';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import {
  CriteriaEditCardStackItems,
  generateBottomStackItems,
  generateTopStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import {
  ApproximateExperiencePeriod,
  ApproximatePeriodType,
} from '@air/components/Period/Period';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import * as phrases from 'constants/phrases';
import { loadPaginatedTitleAsyncOptions } from '@air/api-tasks/dictionariesApi';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import cardsCommonStyles from '@air/domain/SearchCriteriaCards/cardsCommonStyles.css';
import { CriteriaImportanceExclusionSettings } from 'components/SearchCriteriaCards/components/CriteriaImportanceExclusionSettings/CriteriaImportanceExclusionSettings';

/*
  match an empty string or a string without whitespaces at the beginning
  so once typing the string, user can still clear the input
 */
const whitelistedchars = `a-zA-Z0-9&/,.!?#+\\-:;()`;

const roleTitleRegex = new RegExp(
  `^$|^[${whitelistedchars}][${whitelistedchars} ]*$`
);

export const getRoleFieldsForCandidateCollapsedCard = (
  mainCard: CandidateCriteriaListItem,
  allCardData: CandidateRoleData
) => {
  const isGroup = isRoleGroupCard(mainCard);

  const cardTitle = (
    <Card.Title
      resizeable
      flexGrow={false}
      title={getCardTitle({
        cardType: allCardData.cardType,
        label: mainCard.label,
      })}
      key="card-title"
      tooltipProps={cardsConfig[
        allCardData.cardType
      ]?.getMainCardTooltipProps?.(mainCard, allCardData)}
    />
  );

  const cardFooter = !!mainCard.experience && (
    <Card.Footer
      text={
        <ApproximateExperiencePeriod
          experience={mainCard.experience}
          periodType={ApproximatePeriodType.criteria}
        />
      }
      key="card-footer"
    />
  );

  if (allCardData.exclude) {
    return [
      R.isNil(mainCard.matchResolution) ? (
        <Card.Alert
          className={cardCommonStyles.unwantedLabel}
          key="title-label-alert"
        >
          {getUnwantedRoleLabel(allCardData)}
        </Card.Alert>
      ) : (
        <Card.TitleLabel
          key="title-label-match-resolution"
          text={cardsConfig[
            allCardData.cardType
          ].getCandidateCollapsedCardMatchResolutionLabel?.(mainCard)}
        />
      ),
      cardTitle,
      cardFooter,
    ];
  } else {
    return [
      isGroup && (
        <Card.TitleLabel
          key="title-label-match-resolution"
          text={phrases.GROUP_LABEL}
        />
      ),
      cardTitle,
      !!mainCard.alert && (
        <Card.Alert
          key="card-alert"
          className={classNames(cardCommonStyles.cardTitleAlert, {
            [cardCommonStyles.inactiveAlert]:
              hasInactiveAlert<CandidateCriteriaListItem>(mainCard),
          })}
        >
          {mainCard.alert.displayName}
        </Card.Alert>
      ),
      cardFooter,
    ];
  }
};

export const getRolesReadOnlyFields = (
  card: RoleCriteriaData
): Array<JSX.Element | null> => {
  const isRoleExcluded = isExcludeRole(card);
  const unwantedLabel = getUnwantedRoleLabel(card);
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {!isRoleExcluded ? (
        <Card.TitleLabel
          text={cardsConfig[card.cardType].getReadOnlyCardStackTitleLabel?.(
            card
          )}
        />
      ) : null}
      {isRoleExcluded && (
        <div
          className={classNames(
            cardCommonStyles.unwantedLabel,
            cardCommonStyles.lineupEditCardUnwantedLabel
          )}
        >
          #{unwantedLabel}
        </div>
      )}
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
    isIncludeRole(card) ? (
      <IdealExperienceReadOnlyWidget
        values={card.experience}
        key="ideal-experience-field"
      />
    ) : null,
    isIncludeRole(card) &&
    isIdealExperienceDefined(card.experience) &&
    isAcceptableExperienceDefined(card.experience) ? (
      <AcceptableExperienceReadOnlyWidget
        values={card.experience}
        key="acceptable-experience-field"
      />
    ) : null,
    <RecentExperienceReadOnlyWidget
      value={card.recent}
      key="recent-experience-field"
    />,
  ];
};

const DraggableRoleStackItem = getDraggableStackItem<RoleCriteriaData>(
  DraggedSearchCriteriaCardType.singleRoleItem
);
DraggableRoleStackItem.displayName = 'DraggableRoleStackItem';

const RoleCriteriaDraggableCollapsedCard: React.FC<
  CriteriaCardViewProps<RoleCriteriaData> & CriteriaCollapsedCardProps
> = ({
  criteria,
  stackSize,
  handleDrop,
  handleSingleCriteriaDrop,
  initialCardStatus,
  ...props
}) => {
  const id = criteria.key;

  const acceptTypes = useMemo(() => {
    return [
      DraggedSearchCriteriaCardType.role,
      DraggedSearchCriteriaCardType.singleRoleItem,
    ];
  }, []);

  const canDropCallback = useCallback(
    (
      draggedItem:
        | CriteriaDragObjectT<RoleCriteriaData>
        | SingleCriteriaItemDragObject<RoleCriteriaData>
    ) => {
      if (
        isDraggableStackItem(draggedItem) ||
        (isDraggableMainCard(draggedItem) && draggedItem.isDrawingMainCard)
      ) {
        const isExcluded = isExcludedItem(
          draggedItem,
          DraggedSearchCriteriaCardType.singleRoleItem
        );

        return (
          id !== draggedItem.groupId &&
          getTopStackSize(criteria) < MAX_CARDS_IN_STACK &&
          criteria.exclude === isExcluded
        );
      }
      return (
        id !== draggedItem.id &&
        isDraggableMainCard(draggedItem) &&
        criteria.exclude === draggedItem.criteria.exclude &&
        canMergeLists(draggedItem.criteria, criteria)
      );
    },
    [criteria, id]
  );

  const dropCallback = useCallback(
    (droppedItem) => {
      if (
        droppedItem.type === DraggedSearchCriteriaCardType.singleRoleItem ||
        droppedItem.isDrawingMainCard
      ) {
        handleSingleCriteriaDrop(id, droppedItem.id, droppedItem.groupId);
      } else {
        handleDrop(id, droppedItem.id);
      }
    },
    [handleDrop, handleSingleCriteriaDrop, id]
  );

  return (
    <CriteriaDraggableCollapsedCard
      {...props}
      criteria={criteria}
      stackSize={stackSize}
      draggableStackItem={DraggableRoleStackItem}
      acceptTypes={acceptTypes}
      canDropCallback={canDropCallback}
      dropCallback={dropCallback}
      initialCardStatus={initialCardStatus}
    />
  );
};

RoleCriteriaDraggableCollapsedCard.displayName =
  'RoleCriteriaDraggableCollapsedCard';

const DraggableRoleCriteriaCard = getDraggableCriteriaCard<RoleCriteriaData>(
  RoleCriteriaDraggableCollapsedCard,
  DraggedSearchCriteriaCardType.role
);
DraggableRoleCriteriaCard.displayName = 'DraggableRoleCriteriaMainCard';

type RoleCardEditProps = {
  values: RoleCriteriaData;
  specializations: string[];
  namePrefix: string;
  optionFilter: (showSimilarGroups: boolean) => (value: any) => void;
  changeCardImportance: (
    criteria: RoleCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
  onUpdate: () => void;
  className?: string;
};

const DEFAULT_ROLE_OPTIONS: {
  items: RoleListValue[];
  total: number;
  page: number;
} = {
  items: [],
  total: 0,
  page: 0,
};

const RoleCriteriaCardEdit: React.FC<RoleCardEditProps> = ({
  values,
  specializations,
  namePrefix,
  optionFilter,
  changeCardImportance,
  isReadOnly = false,
  onUpdate,
}) => {
  const [roleOptions, setRoleOptions] = useState(DEFAULT_ROLE_OPTIONS);
  const [currentSearchInput, setCurrentSearchInput] = useState('');
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const roleCardConfig = cardsConfig[CardType.role];

  const topStackListName = getTopStackListName(values);
  const isRoleUnwanted = isExcludeRole(values);

  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(
    values,
    MAX_CARDS_IN_STACK
  );

  const getOptionClassName = useCallback((data) => {
    return data.extras?.type === TitleExtendedItemType.TITLECATEGORY
      ? cardsCommonStyles.categoryOption
      : '';
  }, []);

  const getDropDownItemOptionIcon = useCallback((data) => {
    return data.extras?.type === TitleExtendedItemType.TITLECATEGORY ? (
      <SvgIcon icon="folder-icon" className={cardsCommonStyles.categoryIcon} />
    ) : null;
  }, []);

  const searchRoleOptions = useCallback(
    ({
      value,
      page = 0,
      prevItems = [],
    }: {
      value?: string;
      page?: number;
      prevItems?: RoleListValue[];
    }) => {
      const excludeId = getIdsInUse(CardType.role)([values]);
      loadPaginatedTitleAsyncOptions(
        {
          value,
          page,
          excludeId,
          specializations,
        },
        ({ items, total }) => {
          setRoleOptions({
            items: [...prevItems, ...items],
            total,
            page,
          });
          setIsOptionsLoading(false);
        }
      );
    },
    [values, specializations]
  );

  const loadMoreOptions = useCallback(
    () =>
      searchRoleOptions({
        value: currentSearchInput,
        page: R.isEmpty(roleOptions.items) ? 0 : roleOptions.page + 1,
        prevItems: roleOptions.items,
      }),
    [currentSearchInput, roleOptions, searchRoleOptions]
  );

  const onChangeInputValue = useCallback(
    (value) => {
      setRoleOptions(DEFAULT_ROLE_OPTIONS);
      setCurrentSearchInput(value);
      if (value) {
        setIsOptionsLoading(true);
        searchRoleOptions({ value });
      }
    },
    [searchRoleOptions]
  );

  const selectRoleValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        const onChange = props.onChange
          ? props.onChange
          : (value: any) => form.setFieldValue(field.name, value);
        const excludedIds = getIdsInUse(CardType.role)(form.values.criteria);
        const hasMore = roleOptions.items.length < roleOptions.total;
        return (
          <SelectRenderer
            isDisabled={isReadOnly}
            explanation={phrases.FREEFORM_ROLE_EXPLANATION}
            getSingleValueTooltipProps={roleCardConfig.getStackItemTooltipProps}
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            {...props}
            optionClassName={getOptionClassName}
            getDropDownItemOptionIcon={getDropDownItemOptionIcon}
            getDropDownItemTooltipProps={
              roleCardConfig.getSelectDropDownOptionTooltipProps
            }
            inputRegex={roleTitleRegex}
            isClearable
            onChange={onChange}
            isLoading={isOptionsLoading}
            // unlike other cards we need to map items here because mapper adds new items
            // to the option array, and it results in incorrect pagination
            // @ts-ignore find correct type for items
            options={titleDataMapper(excludedIds)(roleOptions.items)}
            infiniteScrollOptions={{
              hasMore,
              threshold: 150,
              loadMore: loadMoreOptions,
            }}
            onInputChange={onChangeInputValue}
            autoFocus={props.focusField || !field.value}
            filterOption={optionFilter(!hasMore)}
            openMenuOnClick={false}
            openMenuOnFocus={false}
            multilineInput
            creatable
          />
        );
      },
    [
      isReadOnly,
      roleCardConfig.getStackItemTooltipProps,
      roleCardConfig.getSelectDropDownOptionTooltipProps,
      getOptionClassName,
      getDropDownItemOptionIcon,
      isOptionsLoading,
      roleOptions,
      loadMoreOptions,
      onChangeInputValue,
      optionFilter,
    ]
  );

  const titleLabel = useMemo(() => {
    return cardsConfig[values.cardType]?.getCriteriaCardTitleLabel?.(values);
  }, [values]);

  return (
    <CriteriaEditCard
      className={classNames({
        [cardCommonStyles.unwantedCard]: isRoleUnwanted,
      })}
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      stackItemControl={selectRoleValueControl}
      collapsibleArea={
        <CriteriaImportanceExclusionSettings
          namePrefix={namePrefix}
          cardData={values}
          onImportanceChange={changeCardImportance}
          onExclude={onUpdate}
        />
      }
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const rolesList: SearchCriteriaListValue[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];
              return rolesList.slice(0, 1).map((val) => {
                return (
                  <Field
                    key={R.prop('value', val) || 'new'}
                    name={`${namePrefix}.${topStackListName}.0`}
                  >
                    {({ field, form }: FieldProps) => (
                      <div
                        ref={mainCriteriaNameFieldRef}
                        className={cardCommonStyles.titleFieldWithImportance}
                      >
                        {titleLabel}
                        {selectRoleValueControl({
                          field,
                          form,
                          isDisabled: isImportanceSectionOpened || isReadOnly,
                          onKeyDown: getOnMainCardKeyDownCallback(
                            field,
                            arrayHelpers,
                            !isMaxListSizeReached
                          ),
                          focusField: !isReadOnly,
                          onChange: async (value: { label: string }) => {
                            await form.setFieldValue(field.name, value);
                            updateMainFieldHandler(value);
                          },
                          getSingleValueTooltipProps:
                            roleCardConfig.getMainCardTooltipProps,
                        })}
                        {importanceIndicator}
                      </div>
                    )}
                  </Field>
                );
              });
            }}
          </FieldArray>
          <Field name={`${namePrefix}.experience`}>
            {({ field, form }: FieldProps) => {
              return (
                <WithDefaultValueOnConditionalMounting
                  indicator={!isRoleUnwanted}
                  defaultValue={null}
                  field={field}
                  form={form}
                >
                  <>
                    <IdealExperienceWidget
                      isReadOnly={isReadOnly}
                      values={field.value}
                      onChangeValues={(value: any) =>
                        form.setFieldValue(field.name, value)
                      }
                    />
                    {isIdealExperienceDefined(field.value) && (
                      <AcceptableExperienceWidget
                        isReadOnly={isReadOnly}
                        values={field.value}
                        onChangeValues={(value) =>
                          form.setFieldValue(field.name, value)
                        }
                      />
                    )}
                  </>
                </WithDefaultValueOnConditionalMounting>
              );
            }}
          </Field>
          <Field name={`${namePrefix}.recent`}>
            {({ field, form }: FieldProps) => {
              return (
                <RecentExperienceWidget
                  isReadOnly={isReadOnly}
                  value={field.value}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  className={classNames({
                    [cardCommonStyles.unwantedCard]: isRoleUnwanted,
                  })}
                />
              );
            }}
          </Field>
        </>
      )}
    </CriteriaEditCard>
  );
};

type RoleCardComponentProps = {
  cardData: RoleCriteriaData;
  specializations: string[];
  namePrefix: string;
  onUpdate: () => void;
  onRemove: () => void;
  changeCardImportance: (
    criteria: RoleCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  onRemoveStackItem: (value: string | number, list?: string) => void;
  importanceSectionOrder: number;
  roleOptionFilter: (showSimilarGroups: boolean) => (value: any) => boolean;
  handleDrop: (id: string | number, itemId: string | number) => void;
  handleSingleCriteriaDrop: (
    id: string | number,
    itemId: string | number,
    groupId: string | number
  ) => void;
  isReadOnly?: boolean;
};

export const RoleCriteriaCard: React.FC<RoleCardComponentProps> = ({
  namePrefix,
  cardData,
  specializations,
  importanceSectionOrder,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  roleOptionFilter,
  handleDrop,
  handleSingleCriteriaDrop,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const cardTitle = getMainCardTitle(cardData);
  const [cardFooter] = getSearchCriteriaCardFooter(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const stackSize = getCardStackSize(cardData);
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: !isNewCard,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };
  const isRoleUnwanted = isExcludeRole(cardData);

  const titleLabel = useMemo(() => {
    return cardsConfig[cardData.cardType]?.getCriteriaCardTitleLabel?.(
      cardData
    );
  }, [cardData]);

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <StateContext.Consumer>
            {({ states }: any) => {
              const canDrawMainCardFromStack =
                stackSize > 0 && states.isStackOpened;
              return (
                <DraggableRoleCriteriaCard
                  titleLabel={titleLabel}
                  title={cardTitle}
                  footerText={cardFooter}
                  stackSize={stackSize}
                  stackItems={[topStackItems]}
                  cardClasses={{
                    footerClass: cardCommonStyles.experienceYears,
                    wrapperClass: classNames({
                      [cardCommonStyles.unwantedCard]: isRoleUnwanted,
                    }),
                  }}
                  importance={cardImportance}
                  exclude={isExcludeRole(cardData)}
                  cardLabel={cardLabel}
                  criteria={cardData}
                  importanceSectionOrder={importanceSectionOrder}
                  onRemove={onRemove}
                  onRemoveStackItem={onRemoveStackItem}
                  handleDrop={handleDrop}
                  handleSingleCriteriaDrop={handleSingleCriteriaDrop}
                  canDrawMainCardFromStack={canDrawMainCardFromStack}
                  initialCardStatus={cardData.initialCardStatus}
                />
              );
            }}
          </StateContext.Consumer>
        ) : (
          <RoleCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            optionFilter={roleOptionFilter}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
            specializations={specializations}
            onUpdate={onUpdate}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
