import React, { useMemo, useCallback, useEffect } from 'react';
import R from '@air/third-party/ramda';
import {
  CardType,
  getImportanceValue,
  isRole,
  isSkill,
  SearchCriteriaData,
  isCertification,
  isCompany,
  isIndustry,
  isInstitution,
} from 'domain/SearchCriteria';
import {
  CertificationCriteriaCard,
  CompanyCriteriaCard,
  DegreeCriteriaCard,
  InstitutionCriteriaCard,
  MajorCriteriaCard,
  ManagerialCriteriaCard,
  ProfessionalCriteriaCard,
  RoleCriteriaCard,
  SkillCriteriaCard,
  QuestionCriteriaCard,
} from 'components/SearchCriteriaCards';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { CardReorderWrapper } from 'features/DraftSection/NewSearch/SearchCriteriaForm';
import {
  createMergeHandlerFactory,
  createRemoveSubCardHandlerFactory,
  createSingleCriteriaTransferFactory,
  createSubCardMoveOutsideHandlerFactory,
  genericGetItem,
  UpdateValuesFunction,
} from 'features/DraftSection/NewSearch/utils';
import { isDraggableStackItem } from 'components/Cards/cardsCommonCode';
import { IndustryCriteriaCard } from 'components/Cards/IndustryCard/IndustryCard';
import { LocationCriteriaCard } from 'components/Cards/LocationCard/LocationCard';
import { isLocation } from 'domain/SearchCriteria/LocationCriteriaData';

const INITIAL_CARD_STATUS_UPDATE_DELAY = 1000;

// Find specific criteria card among all search criteria.
export const getCardByKey = genericGetItem<SearchCriteriaData>(R.propEq('key'));
export const getCardById = genericGetItem<SearchCriteriaData>(R.propEq('id'));
// Find specific list item in a criteria card list.
export const getCriteriaListItemByValue = genericGetItem(R.propEq('value'));

// Remove criteria list item from stack.
export const handleSingleCriteriaListItemRemove =
  createRemoveSubCardHandlerFactory(getCardByKey, getCriteriaListItemByValue);

export const createGenericCardsHandler =
  (values: SearchCriteriaData[], setFieldValue: any, onFieldUpdate: any) =>
  async (updateValuesFn: UpdateValuesFunction) => {
    await setFieldValue('criteria', updateValuesFn(values), false);
    onFieldUpdate();
  };

/*
  Create a lens for an item in a values object,
  and receive an array with item's idx and lens.
*/
export const getCriteriaCard = (
  item: any,
  values: SearchCriteriaData[]
): [number, R.Lens<any, any>] => {
  /*
   DraggedSearchCriteriaCardType can refer to either a single criteria item
   from stack, or to a full stack of criteria (multiple cards).
   In both cases dragged card's unique id is stored in it's `id` property,
   but in case of full stack group's `key` is used as unique identifier.
   Otherwise, it's `id`.
  */
  return isDraggableStackItem(item)
    ? getCardById(item.id, values)
    : getCardByKey(item.id, values);
};

/**
 * Move sub item outside its parent card, and instantiate it as a new card,
 * depending on new index.
 * @param item
 * @param newSearchOrder
 */
export const handleMoveItemOutsideStack = (
  item: any,
  newSearchOrder: any
): UpdateValuesFunction => {
  const { id, groupId } = item;
  // @ts-ignore TODO: figure out how to narrow SearchCriteriaData to GroupT.
  const handler = createSubCardMoveOutsideHandlerFactory<SearchCriteriaData>(
    getCardByKey,
    getCriteriaListItemByValue
  );

  return handler(id, groupId, newSearchOrder) as UpdateValuesFunction;
};

// Merge information when one card is dropped on another.
const mergeCardsOnDrop = createMergeHandlerFactory(getCardByKey);

/**
 * Old implementation used types of the DraggableItem, because the only way to remove subItem was
 * to drag it into recycle bin. Now user can click on a remove button near stack item.
 * That's why we need to rely on a cardType rather than draggedItemType.
 * @param type
 * @param itemId
 * @param parentId
 * @param listName
 */
export const handleRemoveItemFromStack = (
  itemId: string | number,
  parentId: string | number,
  listName?: string
) => {
  return handleSingleCriteriaListItemRemove(itemId, parentId, listName) as any;
};

const handleRemoveCriteria =
  (item: SearchCriteriaData): UpdateValuesFunction =>
  (values: any) => {
    const criteria: SearchCriteriaData = R.view(R.lensIndex(item.idx), values);
    return R.without([criteria])(values);
  };

// Drag single criteria from one stack to another card.
const handleSingleCriteriaTransfer = createSingleCriteriaTransferFactory(
  getCardByKey,
  getCriteriaListItemByValue
);

/*
  When changing card importance, updated card should be placed
  last in it's new importance section. This requires proper
  changes of not only `importance` field, but also card's `idx`
  field (which indicates card's order in the list of all search
  criteria).
  Since idx is calculated based on the order of criteria in a list
  of search form values, when changing importance, we place updated
  card's criteria on the last position in this array,
  thus it will always be last when list criteria are grouped by.

  To find the card in question, we use its unique client-side
  identifier – `key`.
*/
const handleReplaceCriteriaItem = (updatedCardCriteria: SearchCriteriaData) => {
  return (values: any) => {
    const filteredCriteria = values.filter(
      (item: any) => item.key !== updatedCardCriteria.key
    );
    const updatedCriteria = filteredCriteria.concat(updatedCardCriteria);

    return updatedCriteria;
  };
};

/*
  Filters for criteria card's title options.
*/

const createCardOptionFilter =
  (filterPredicate: (item: { cardType: CardType }) => boolean) =>
  (values: any) =>
  ({ value }: any) => {
    return R.compose(
      R.not,
      R.any(
        R.compose(
          R.any((it) => R.path(['value'], it) === value),
          R.path(['list'])
        )
      )
    )(values.filter(filterPredicate));
  };

const createSkillOptionFilter =
  (values: any) =>
  ({ value, data }: any) => {
    // if current option is 'creatable', its value is stored in option.data object
    const skillTitleId = data.__isNew__ ? data.value : value;
    return R.compose(
      R.not,
      R.any(
        R.compose(
          R.any(
            (it) =>
              R.path(['title', 'id'], it) === skillTitleId ||
              R.path(['value'], it) === value
          ),
          R.path(['list'])
        )
      )
    )(values.filter(isSkill));
  };

const createLocationOptionFilter =
  (values: any) =>
  ({ label }: any) => {
    return R.compose(
      R.not,
      R.any(
        R.compose(
          R.any((it) => R.path(['label'], it) === label),
          R.path(['list'])
        )
      )
    )(values.filter(isLocation));
  };

const createCertificationOptionFilter = createCardOptionFilter(isCertification);
const createCompanyOptionFilter = createCardOptionFilter(isCompany);
const createIndustryOptionFilter = createCardOptionFilter(isIndustry);

const createInstitutionOptionFilter =
  (values: any) =>
  ({ value }: any) => {
    return R.compose(
      R.not,
      R.any(
        R.compose(
          R.any((it) => R.path(['value'], it) === value),
          R.flatten,
          R.props(['idealList', 'acceptableList'])
        )
      )
    )(values.filter(isInstitution));
  };

const createEducationOptionFilter =
  (
    cardType: CardType.major | CardType.degree | CardType.institution,
    values: any
  ) =>
  ({ value, label, data: { keyword = '' } }: any, currentInput: any) => {
    return R.compose(
      R.not,
      R.any(
        R.compose(
          R.any((it) => {
            return (
              R.path(['value'], it) === value ||
              (!keyword &&
                !label.toLowerCase().includes(currentInput.toLowerCase())) ||
              (keyword &&
                !currentInput.toLowerCase().includes(keyword.toLowerCase()))
            );
          }),
          R.flatten,
          R.props(['idealList', 'acceptableList'])
        )
      )
    )(values.filter((item: any) => item.cardType === cardType));
  };

const createRoleTitleOptionFilter =
  (values: any) =>
  (showSimilarGroups: boolean) =>
  ({ value, data }: any) => {
    // truthy flag means the item is from similar group list
    const isSimilarGroup = data?.extras?.isSimilarGroup;
    return !showSimilarGroups && isSimilarGroup
      ? false
      : R.compose(
          R.not,
          R.any(
            R.compose(
              R.any((it) => R.path(['value'], it) === value),
              R.pathOr([], ['list'])
            )
          )
        )(values.filter(isRole));
  };
const Card: React.FC<any> = ({
  cardType,
  importanceSectionOrder,
  handleUpdate,
  removeCard,
  removeStackItem,
  data,
  specializations,
  updateCardValues,
  setFieldValue,
  isReadOnly,
  ...props
}) => {
  const searchOrder = data.idx;

  /**
   * `updated` property is used to mark card as a newly created or already saved,
   * and used to determine initial view state of the card: edit or collapsed view;
   * if card was newly created, when card is switched to a collapsed view, we need to
   * also change `updated` value.
   * this field is currently used only in `CompanyCriteriaCard` and `RoleCriteriaCard`, since there is no other
   * way to determine initial view state.
   */
  const markFieldAsCreated = useCallback(
    async (searchOrder: number) => {
      if (isReadOnly) return;
      setFieldValue(
        `criteria.${searchOrder}.initialCardStatus`,
        InitialCardStatusEnum.ExistingCard,
        false
      );
    },
    [setFieldValue, isReadOnly]
  );

  const onCardUpdate = useCallback(async () => {
    if (isReadOnly) return;
    await markFieldAsCreated(searchOrder);
    handleUpdate();
  }, [searchOrder, markFieldAsCreated, handleUpdate, isReadOnly]);

  useEffect(() => {
    if (data.initialCardStatus === InitialCardStatusEnum.IsNewView) {
      setTimeout(() => {
        markFieldAsCreated(searchOrder);
      }, INITIAL_CARD_STATUS_UPDATE_DELAY);
    }
  }, [data, markFieldAsCreated, searchOrder]);

  const onRemoveStackItem = useCallback(
    (removeVal, listName) => {
      if (isReadOnly) return;
      removeStackItem(removeVal, data.key, listName);
    },
    [removeStackItem, data.key, isReadOnly]
  );

  const changeCardImportance = useCallback(
    (
      cardCriteria: SearchCriteriaData,
      newImportance: { label: string; value: SearchCriteriaImportanceEnum }
    ) => {
      if (isReadOnly) return;
      updateCardValues(
        handleReplaceCriteriaItem({
          ...cardCriteria,
          initialCardStatus: InitialCardStatusEnum.ExistingCard,
          importance: newImportance,
        })
      );
    },
    [updateCardValues, isReadOnly]
  );

  const handleDrop = useMemo(
    () => R.compose(updateCardValues, mergeCardsOnDrop),
    [updateCardValues]
  );

  const handleSingleCriteriaDrop = useMemo(
    () => R.compose(updateCardValues, handleSingleCriteriaTransfer),
    [updateCardValues]
  );

  const commonProps = {
    importanceSectionOrder,
    changeCardImportance,
    onUpdate: onCardUpdate,
    onRemove: removeCard,
    namePrefix: `criteria.${searchOrder}`,
    isReadOnly,
  };

  const professionalCardHasJobTypes = useMemo(() => {
    return specializations.length === 1 && specializations[0] === 'IT';
  }, [specializations]);

  switch (cardType) {
    case CardType.skill:
      return (
        <SkillCriteriaCard
          handleDrop={handleDrop}
          handleSingleCriteriaDrop={handleSingleCriteriaDrop}
          onRemoveStackItem={onRemoveStackItem}
          optionsFilter={createSkillOptionFilter(props.values)}
          cardData={data}
          specializations={specializations}
          {...commonProps}
        />
      );
    case CardType.major:
      return (
        <MajorCriteriaCard
          importance={getImportanceValue(data)}
          cardData={data}
          onRemoveStackItem={onRemoveStackItem}
          optionsFilter={createEducationOptionFilter(
            CardType.major,
            props.values
          )}
          options={props.options?.majorOptions}
          {...commonProps}
        />
      );
    case CardType.degree:
      return (
        <DegreeCriteriaCard
          importance={getImportanceValue(data)}
          cardData={data}
          setFieldValue={setFieldValue}
          onRemoveStackItem={onRemoveStackItem}
          options={props.options?.degreeOptions}
          optionsFilter={createEducationOptionFilter(
            CardType.degree,
            props.values
          )}
          {...commonProps}
        />
      );
    case CardType.institution:
      return (
        <InstitutionCriteriaCard
          importance={getImportanceValue(data)}
          cardData={data}
          onRemoveStackItem={onRemoveStackItem}
          optionsFilter={createInstitutionOptionFilter(props.values)}
          {...commonProps}
        />
      );
    case CardType.certification:
      return (
        <CertificationCriteriaCard
          handleDrop={handleDrop}
          handleSingleCriteriaDrop={handleSingleCriteriaDrop}
          onRemoveStackItem={onRemoveStackItem}
          cardData={data}
          specializations={specializations}
          optionsFilter={createCertificationOptionFilter(props.values)}
          {...props}
          {...commonProps}
        />
      );
    case CardType.industry:
      return (
        <IndustryCriteriaCard
          cardData={data}
          handleDrop={handleDrop}
          handleSingleCriteriaDrop={handleSingleCriteriaDrop}
          onRemoveStackItem={onRemoveStackItem}
          optionsFilter={createIndustryOptionFilter(props.values)}
          {...props}
          {...commonProps}
        />
      );
    case CardType.company:
      return (
        <CompanyCriteriaCard
          cardData={data}
          handleDrop={handleDrop}
          handleSingleCriteriaDrop={handleSingleCriteriaDrop}
          onRemoveStackItem={onRemoveStackItem}
          optionsFilter={createCompanyOptionFilter(props.values)}
          {...props}
          {...commonProps}
        />
      );
    case CardType.location:
      return (
        <LocationCriteriaCard
          cardData={data}
          handleDrop={handleDrop}
          handleSingleCriteriaDrop={handleSingleCriteriaDrop}
          onRemoveStackItem={onRemoveStackItem}
          optionsFilter={createLocationOptionFilter(props.values)}
          setFieldValue={setFieldValue}
          {...props}
          {...commonProps}
        />
      );
    case CardType.role:
      return (
        <RoleCriteriaCard
          cardData={data}
          specializations={specializations}
          handleDrop={handleDrop}
          handleSingleCriteriaDrop={handleSingleCriteriaDrop}
          onRemoveStackItem={onRemoveStackItem}
          roleOptionFilter={createRoleTitleOptionFilter(props.values)}
          {...commonProps}
        />
      );
    case CardType.managerial:
      return <ManagerialCriteriaCard cardData={data} {...commonProps} />;
    case CardType.professional:
      return (
        <ProfessionalCriteriaCard
          cardData={data}
          hasJobTypes={professionalCardHasJobTypes}
          {...commonProps}
        />
      );
    case CardType.question:
      return <QuestionCriteriaCard cardData={data} {...commonProps} />;
    default:
      return null;
  }
};

export const SearchCriteriaFormCardWrapper: React.FC<any> = ({
  item,
  values,
  specializations,
  importanceSectionOrder,
  setFieldValue,
  handleSubmit,
  educationOptions,
  handleCardReorder,
  updateCardValues,
  isReadOnly,
}) => {
  const removeCard = useCallback(
    async () => await updateCardValues(handleRemoveCriteria(item)),
    [updateCardValues, item]
  );

  const removeStackItem = useCallback(
    async (itemId, groupId, listName) =>
      await updateCardValues(
        handleRemoveItemFromStack(itemId, groupId, listName)
      ),
    [updateCardValues]
  );

  return (
    <CardReorderWrapper
      searchOrder={item.idx}
      importance={getImportanceValue(item)}
      handleCardReorder={handleCardReorder}
      isReadOnly={isReadOnly}
    >
      <Card
        cardType={item.cardType}
        importanceSectionOrder={importanceSectionOrder}
        data={item}
        specializations={specializations}
        isReadOnly={isReadOnly}
        handleUpdate={isReadOnly ? null : handleSubmit}
        removeCard={isReadOnly ? null : removeCard}
        removeStackItem={isReadOnly ? null : removeStackItem}
        values={values}
        setFieldValue={isReadOnly ? null : setFieldValue}
        updateCardValues={isReadOnly ? null : updateCardValues}
        {...([CardType.major, CardType.degree].includes(item.cardType) && {
          options: educationOptions,
        })}
      />
    </CardReorderWrapper>
  );
};
