/*
  Some API calls may fail, in that case we redirect
  the user to app's root route.
 */
import { History } from 'history';
import {
  BAD_REQUEST,
  ENTITY_NOT_FOUND,
  FORBIDDEN,
} from '@air/constants/httpCodes';
import { toast } from '@air/third-party/toast';
import { ROOT_ROUTE } from '@air/constants/commonUrls';
import { ApiErrorResponseWithStatusT } from './http';
import { ApiErrorResponse } from '@air/api';
import * as sharedPhrases from '@air/constants/phrases';

export const redirectToRootOnError =
  (errStatus: number[]) =>
  (res: ApiErrorResponseWithStatusT, history: History) => {
    if (res.status && errStatus.includes(res.status)) {
      history.push(ROOT_ROUTE);
    }
    return res;
  };

export const redirectToRootOnNotFound = redirectToRootOnError([
  BAD_REQUEST,
  ENTITY_NOT_FOUND,
  FORBIDDEN,
]);

export const getErrorDescription = (
  error: ApiErrorResponse,
  defaultMessage = ''
) => error?.details?.description ?? defaultMessage;

export const genericErrorHandler = (error: ApiErrorResponse) => {
  toast.error(
    error?.details?.description || sharedPhrases.GENERAL_ERROR_TRY_AGAIN
  );
  return Promise.reject(error);
};
