// imports from vendor deps
import React, { useCallback, useMemo } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import * as phrases from '@air/constants/phrases';
// imports from types
import {
  RangeDropdown,
  RangeItem,
  SelectedItems,
} from '@air/components/RangeDropdown/RangeDropdown';
// imports from helpers
import { EquivalentExperienceValue } from '@air/utils/commonSearchHelpers';
import { useRangeVisibility } from '@air/components/SelectRangeWidgets/useRangeVisibility';
import {
  convertValuesToRangeOfIndexes,
  generateItemsForRangeDropdown,
  generateSelectedIndexes,
  isLimitNotDefined,
  RangeIndex,
  RangeOfIndexes,
  stringifyExperienceYears,
  UNSET_EXPERIENCE_MAX_YEAR,
  MIN_EQUIVALENT_EXPERIENCE_YEAR,
  MAX_EQUIVALENT_EXPERIENCE_YEAR,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
// imports from styles

import styles from './EquivalentExperienceWidget.css';

// imports from 'components'
// import {} from 'components';

// imports from 'constants'

// import from images
// import {} from 'images'

// component proptypes
type Props = {
  className?: string;
  value: EquivalentExperienceValue;
  onChangeValue?: (value: EquivalentExperienceValue) => void;
  isDropdownOpened?: boolean;
  isReadOnly?: boolean;
};

export const INDEX_OF_PLUS = 10;

const yearsRange = R.range(
  MIN_EQUIVALENT_EXPERIENCE_YEAR,
  MAX_EQUIVALENT_EXPERIENCE_YEAR + 1
);
const dropdownItems = [
  ...generateItemsForRangeDropdown(yearsRange),
  { label: '+', value: UNSET_EXPERIENCE_MAX_YEAR },
];

const convertExperienceValueToRangeOfIndexes = (
  allItems: RangeItem[],
  value: EquivalentExperienceValue
): RangeOfIndexes => {
  const [minIndex] = convertValuesToRangeOfIndexes(allItems, [value, null]);
  return [minIndex, null];
};

const convertRangeOfIndexesToExperienceValue = (
  allItems: RangeItem[],
  rangeOfIndexes: RangeOfIndexes
) => {
  const [minIndex] = rangeOfIndexes;
  return isLimitNotDefined(minIndex) ? null : allItems[minIndex].value;
};

const isAnySelected = (selectedItemsIndexes: any) =>
  R.head(selectedItemsIndexes) === null &&
  R.last(selectedItemsIndexes) === null;

const getDropdownClass = (
  allItems: RangeItem[],
  selectedItems: SelectedItems
) => {
  const hasLeftRangeOnly = !isAnySelected(selectedItems); // this dropdown has only one min limit
  return {
    [styles.equivalentExperienceDropdown]: true,
    [styles.isAnySelected]: isAnySelected(selectedItems),
    [styles.hasLeftRangeOnly]: hasLeftRangeOnly,
  };
};

const getRangeItemClass = (
  index: RangeIndex,
  allItems: RangeItem[],
  selectedItems: SelectedItems
) => {
  return {
    [styles.rangeItem]: true,
    [styles.selectedRangeItem]:
      R.includes(index, selectedItems) || isAnySelected(selectedItems),
    [styles.selectedFirstRangeItem]:
      index === R.head(selectedItems) ||
      (index === 0 && isAnySelected(selectedItems)),
    [styles.selectedLastRangeItem]:
      index === R.last(selectedItems) ||
      (index === allItems.length - 1 && isAnySelected(selectedItems)),
    [styles.rangeLastItem]: index === allItems.length - 1,
  };
};

const EquivalentExperienceDropdown: React.FC<{
  isOpen: boolean;
  selectedRange: RangeOfIndexes;
  onChange: (values: any) => void;
  dropdownItems: RangeItem[];
}> = ({ isOpen, onChange, selectedRange, dropdownItems }) => {
  const [selectedMinIndex] = selectedRange;

  const onItemSelected = useCallback(
    (newSelectedIndex) => {
      let newMinIndex;

      if (newSelectedIndex === INDEX_OF_PLUS) {
        return;
      } else if (newSelectedIndex === selectedMinIndex) {
        newMinIndex = null;
      } else {
        newMinIndex = newSelectedIndex;
      }
      onChange([newMinIndex, INDEX_OF_PLUS]);
    },
    [onChange, selectedMinIndex]
  );

  const selectedItems = useMemo(() => {
    return generateSelectedIndexes(selectedRange, dropdownItems.length - 1);
  }, [dropdownItems, selectedRange]);

  return (
    <RangeDropdown
      isOpen={isOpen}
      dropdownClass={getDropdownClass}
      rangeItems={dropdownItems}
      rangeItemClass={getRangeItemClass}
      arrowClassName={styles.dropdownArrow}
      onItemSelected={onItemSelected}
      selectedItems={selectedItems}
    />
  );
};

// exports / component definitions
export const EquivalentExperienceField: React.FC<{
  value: EquivalentExperienceValue;
  className?: string;
  onClick?: () => void;
}> = ({ value, onClick = null, className }) => {
  const { yearsNumber, yearsWords } = stringifyExperienceYears(value, null);

  const isEmptyExperience = R.isNil(value);
  const nYearsOfExperienceText = `${yearsWords}\nof exp`;

  return (
    <div
      className={classNames(className, styles.experienceField)}
      onClick={onClick}
    >
      <div className={styles.experienceFieldInnerContent}>
        {!isEmptyExperience && (
          <span
            tabIndex={0}
            className={styles.experienceFieldValue}
            onFocus={onClick}
            onMouseDown={(event: any) => event.preventDefault()}
          >
            {yearsNumber}
          </span>
        )}
        <label
          htmlFor="equivalent-experience-widget-field"
          className={styles.experienceFieldLabel}
        >
          {isEmptyExperience
            ? phrases.EMPTY_EQUIVALENT_EXPERIENCE_TEXT
            : nYearsOfExperienceText}
        </label>
      </div>
    </div>
  );
};

export const EquivalentExperienceReadOnlyWidget: React.FC<{
  value: EquivalentExperienceValue;
}> = ({ value }) => {
  return (
    <EquivalentExperienceField
      className={styles.experienceReadonlyField}
      value={value}
    />
  );
};
EquivalentExperienceReadOnlyWidget.displayName =
  'EquivalentExperienceReadOnlyWidget';

// exports / component definitions
export const EquivalentExperienceWidget: React.FC<Props> = ({
  className,
  value,
  onChangeValue,
  isDropdownOpened = false,
  isReadOnly = false,
}) => {
  const [
    isDropdownVisible,
    outsideClickRef,
    onDropdownTriggerClicked,
    handleKeyDown,
  ] = useRangeVisibility(isDropdownOpened);

  const onChangeDropdownValues = useCallback(
    (newSelectedValues) => {
      const newValue = convertRangeOfIndexesToExperienceValue(
        dropdownItems,
        newSelectedValues
      );

      onChangeValue(newValue);
    },
    [onChangeValue]
  );

  const selectedRange = useMemo(() => {
    return convertExperienceValueToRangeOfIndexes(dropdownItems, value);
  }, [value]);

  return isReadOnly ? (
    <EquivalentExperienceReadOnlyWidget value={value} />
  ) : (
    <div
      className={classNames(styles.experienceWidget, className, {
        [styles.isDropdownOpened]: isDropdownVisible,
      })}
      ref={outsideClickRef}
      onKeyDownCapture={handleKeyDown}
    >
      <EquivalentExperienceField
        value={value}
        onClick={onDropdownTriggerClicked}
      />
      <EquivalentExperienceDropdown
        dropdownItems={dropdownItems}
        selectedRange={selectedRange}
        onChange={onChangeDropdownValues}
        isOpen={isDropdownVisible}
      />
    </div>
  );
};
