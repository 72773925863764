import { identity, compose } from 'ramda';

class Either {
  $value: any;

  constructor(x: any) {
    this.$value = x;
  }

  // ----- Pointed (Either a)
  static of(x: any): Either | void {
    return new Right(x); // eslint-disable-line
  }
}

// TODO: Remove eslint comment when Left is used
/* eslint-disable-next-line */
class Left extends Either {
  get isLeft() {
    return true;
  }

  get isRight() {
    return false;
  }

  static of(/* x */) {
    throw new Error(
      '`of` called on class Left (value) instead of Either (type)'
    );
  }

  // ----- Functor (Either a)
  map() {
    return this;
  }

  // ----- Applicative (Either a)
  ap() {
    return this;
  }

  // ----- Monad (Either a)
  chain() {
    return this;
  }

  join() {
    return this;
  }

  // ----- Traversable (Either a)
  sequence(of: any) {
    return of(this);
  }

  traverse(of: any /* fn */) {
    return of(this);
  }
}

class Right extends Either {
  get isLeft() {
    return false;
  }

  get isRight() {
    return true;
  }

  static of(/* x */) {
    throw new Error(
      '`of` called on class Right (value) instead of Either (type)'
    );
  }

  // ----- Functor (Either a)
  map(fn: any) {
    return Either.of(fn(this.$value));
  }

  // ----- Applicative (Either a)
  ap(f: any) {
    return f.map(this.$value);
  }

  // ----- Monad (Either a)
  chain(fn: any) {
    return fn(this.$value);
  }

  join() {
    return this.$value;
  }

  // ----- Traversable (Either a)
  sequence(of: any) {
    return this.traverse(of, identity);
  }

  traverse(of: any, fn: any) {
    fn(this.$value).map(Either.of);
  }
}

type ResolveFunction<T> = (
  response?: T,
  extraParams?: {
    headers?: Headers;
  }
) => void;

export class Task<T = any> {
  fork: (rej: (...args: any[]) => any, res: ResolveFunction<T>) => any;

  constructor(fork: any) {
    this.fork = fork;
  }

  static fork(rej: any, res: any) {
    return function (task: any) {
      return task.fork(rej, res);
    };
  }

  static map(fn: any) {
    return function (task: any) {
      return task.map(fn);
    };
  }

  static rejected<T>(x: T) {
    return new Task<T>((reject: any /* _ */) => reject(x));
  }

  // ----- Pointed (Task a)
  static of<T>(x: T) {
    return new Task<T>((_: any, resolve: any) => resolve(x));
  }

  // ----- Functor (Task a)
  map(fn: any) {
    return new Task((reject: any, resolve: any) =>
      this.fork(reject, compose(resolve, fn))
    );
  }

  mapError(fn: any) {
    return new Task((reject: any, resolve: any) =>
      this.fork(compose(reject, fn), resolve)
    );
  }

  // ----- Applicative (Task a)
  ap(f: any) {
    return this.chain((fn: any) => f.map(fn));
  }

  // ----- Monad (Task a)
  chain<T>(chainFn: (...args: any) => Task<T>): Task<T> {
    const taskFromChain = (chainReject: any, chainResolve: any) =>
      this.fork(chainReject, function chainRight(x: any) {
        return chainFn(x).fork(chainReject, chainResolve);
      });
    return new Task(taskFromChain);
  }

  chainError(chainErrorFn: any) {
    const taskFromChainError = (
      chainErrorReject: any,
      chainErrorResolve: any
    ) =>
      this.fork(function chainErrorLeft(x: any) {
        return chainErrorFn(x).fork(chainErrorReject, chainErrorResolve);
      }, chainErrorResolve);
    return new Task(taskFromChainError);
  }

  join() {
    return this.chain(identity);
  }
}
