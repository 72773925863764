import R from '@air/third-party/ramda';

import { PeriodInfo, DateInfo } from '@air/api';
import * as phrases from '@air/constants/phrases';

export const MAX_EXPERIENCE_VALUE = 11;

export function convertMonthsToString({ months, days }: PeriodInfo) {
  const defaultDate = days ? phrases.EXPERIENCE_LESS_THAN_MONTH : '';
  return months ? phrases.EXPERIENCE_NUMBER_OF_MONTHS(months) : defaultDate;
}

export function convertDateToString({ years, months }: PeriodInfo) {
  let result = '';
  if (years) {
    result += `${years} YR${years === 1 ? '' : 'S'} `;
  }
  if (months) {
    result += `${months} MO${months === 1 ? '' : 'S'}`;
  }

  return result.trim();
}

export function convertApproximateDateToString({
  years,
  months,
  approx = false,
}: PeriodInfo) {
  const experienceString = convertDateToString({ years, months });
  return approx ? `~${experienceString}` : experienceString;
}

export function convertEquivalentExperienceToString({
  equivalentYears = 0,
  equivalentApprox = false,
}: {
  equivalentYears?: number;
  equivalentApprox?: boolean;
}): string {
  return equivalentApprox ? `≈${equivalentYears}` : equivalentYears.toString();
}

export function convertPeriodInfoToString({
  years,
  months,
  days,
  approx,
}: PeriodInfo) {
  const defaultDate = days ? phrases.EXPERIENCE_LESS_THAN_MONTH : '';
  return years || months
    ? convertApproximateDateToString({ years, months, approx })
    : defaultDate;
}

export const convertPeriodGapInfoToString = (period: PeriodInfo) => {
  const { years, months, days } = period;
  if (years + months + days === 0) {
    return phrases.WARNING_CARD_EMPLOYMENT_GAPS_DURATION;
  } else {
    return convertPeriodInfoToString(period);
  }
};

const FIRST_MONTH_INDEX = 1;
/**
 * @param year
 * @param month - Month is set in a range [1-12], Date gets month value in a range [0-11],
 *  thus we need to use offset value FIRST_MONTH_INDEX.
 */
export const getDateToLocaleString = ({ year, month }: DateInfo): string => {
  const date = new Date(year, month - FIRST_MONTH_INDEX);
  return date.toLocaleString('en-us', { month: 'short' });
};

export const convertDateToMonthName = (period: DateInfo): string => {
  return period?.month ? getDateToLocaleString(period) : phrases.MISSING_MONTH;
};

export const convertMonthsExperienceToYears = (months: null | number) => {
  if (R.isNullOrEmpty(months)) {
    return null;
  } else {
    return Math.floor(months / 12);
  }
};
