import { useCallback, useEffect, useState } from 'react';

/*
  To be able to correctly reset SideList's  InfiniteScroll paging,
  we track current page on our own.
  Unfortunately, if we add current list page as a dependency to
  useCallback, new function will be created on each page update,
  which in turn triggers adding of new scroll event listener
  inside InfiniteScroll component (without removing previous listeners).
  This leads to dozens of 'fetchMoreSearches' calls after
  first page update.
  So to avoid this dependency, we fetch searches inside setState
  callback.
*/
type UsePaginatedSearchT = (
  callback: (page: number) => void,
  pageResetDeps: Array<any>
) => (increasePage: boolean) => void;
export const usePaginatedSearch: UsePaginatedSearchT = (
  callback,
  pageResetDeps
) => {
  const [, setListPage] = useState(0);

  const fetchMoreInterviews = useCallback(
    (increasePage = true) => {
      setListPage((page) => {
        const nextPage = increasePage ? page + 1 : page;
        callback(nextPage);
        return nextPage;
      });
    },
    [callback]
  );

  useEffect(() => {
    setListPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, pageResetDeps);

  return fetchMoreInterviews;
};
