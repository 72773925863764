import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import R from '@air/third-party/ramda';

export const fetchATSList = (companyId: number) =>
  Http.get(urls.ATS_LIST_API)
    .bind(Http.withQueryParams({ companyId }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson)
    .map(R.prop('content'));
