import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import classNames from 'classnames';
import querystring from 'querystring';

import { Header, Logo, Paragraph } from '@air/components';
import { FeatureView } from 'components';

import { LineupTabs } from '@air/constants/tabs';
import * as WatchersApi from 'domain/WatchList/watchListApi';
import { isMobileOrTablet } from '@air/utils/mobile';

import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import * as commonUrls from '@air/constants/commonUrls';

import styles from './WatcherUnsubscription.css';

type Props = {
  isLoggedIn: boolean;
};

export const WatcherUnsubscription: React.FC<Props & RouteChildrenProps> = ({
  history,
  isLoggedIn,
}) => {
  const [ready, setReady] = useState(false);
  const params = querystring.parse(location.search.substr(1));
  const {
    dataSourceId,
    jobDescriptionId,
    jobTitle,
    t: unsubscribeToken,
  } = params;

  useEffect(() => {
    if (!dataSourceId || !jobDescriptionId || !jobTitle || !unsubscribeToken) {
      if (isLoggedIn) {
        history.push(commonUrls.ROOT_ROUTE);
      } else {
        history.replace(commonUrls.LOGIN_ROUTE);
      }
    } else {
      /* AR-7998
       * No matter the result of the unsubscribe API call (already unsubscribed,
       * error, success etc) users must see `Unsubscribed` page,
       * there should be no validation/errors shown.
       * */
      WatchersApi.unsubscribeWatcherByToken({
        dataSourceId: dataSourceId as string,
        jobDescriptionId: jobDescriptionId as string,
        unsubscribeToken: unsubscribeToken as string,
      }).fork(
        () => {},
        () => {}
      );
    }
  }, [
    dataSourceId,
    jobDescriptionId,
    jobTitle,
    unsubscribeToken,
    history,
    isLoggedIn,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setReady(true), 500);
    return () => {
      clearTimeout(timeoutId);
    };
  });

  const interviewUrl = urls.makeInterviewUrl(
    dataSourceId as string,
    jobDescriptionId as string,
    LineupTabs.Active
  );

  return (
    <FeatureView
      className={classNames({ [styles.isMobileOrTablet]: isMobileOrTablet })}
    >
      <FeatureView.Aside className={styles.watcherUnsubscriptionAside}>
        <Logo isWhiteLogoText />
      </FeatureView.Aside>
      <FeatureView.Content
        className={classNames(styles.watcherUnsubscription, {
          [styles.ready]: ready,
        })}
      >
        <div className={styles.emailLogo}>
          <img src="/assets/images/email-icon-bg.png" />
        </div>
        <Header bold className={styles.header}>
          {phrases.UNSUBSCRIBE_WATCHERS_HEADER}
        </Header>
        <Paragraph className={styles.description}>
          {phrases.UNSUBSCRIBE_WATCHERS_DESCRIPTION_START}
          {isLoggedIn ? (
            <Link to={interviewUrl} className={styles.searchLink}>
              {jobTitle}
            </Link>
          ) : (
            <strong>{jobTitle}</strong>
          )}
          {phrases.UNSUBSCRIBE_WATCHERS_DESCRIPTION_END}
        </Paragraph>
      </FeatureView.Content>
    </FeatureView>
  );
};
