import React from 'react';
import { VariationPlacement } from '@popperjs/core/lib/enums';
import { FormSelectEntity } from '@air/components/Select/typings';
import { CardEquivalentSkillsTooltip } from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';
import cardsCommonStyles from '@air/domain/SearchCriteriaCards/cardsCommonStyles.css';

export const getSkillSelectValueTooltipProps = (
  data: FormSelectEntity<{ items: string[] }>
) => {
  return data.__isNew__
    ? {}
    : {
        enabled: true,
        tooltip: (
          <CardEquivalentSkillsTooltip
            items={data.extras?.items}
            selectedItem={data.label}
          />
        ),
        containerClassName: cardsCommonStyles.cardCategoryTooltip,
        placement: 'right-start' as VariationPlacement,
      };
};
