import { UploadApplicantsContextT } from 'context';

export const requisitions = (state: UploadApplicantsContextT) =>
  state.requisitions;

export const canAddTags = (state: UploadApplicantsContextT) => state.canAddTags;

export const isUploadToServerInProgress = (state: UploadApplicantsContextT) =>
  state.isUploadToServerInProgress;

export const isUploadInProgress = (state: UploadApplicantsContextT) =>
  state.isUploadInProgress;

export const uploadToServerState = (state: UploadApplicantsContextT) =>
  state.uploadToServerState;

export const requisitionsUploadState = (state: UploadApplicantsContextT) =>
  state.requisitionsUploadState;
