/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FailedExternalProfileError } from './';

/**
 *
 * @export
 * @interface FailedExternalProfile
 */
export interface FailedExternalProfile {
  /**
   *
   * @type {string}
   * @memberof FailedExternalProfile
   */
  type: FailedExternalProfileTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FailedExternalProfile
   */
  externalProfileId: string;
  /**
   *
   * @type {FailedExternalProfileError}
   * @memberof FailedExternalProfile
   */
  error: FailedExternalProfileError;
}

/**
 * @export
 * @enum {string}
 */
export enum FailedExternalProfileTypeEnum {
  ATS = 'ATS',
}
