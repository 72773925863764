import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { history } from '@air/utils/history';

import {
  JobDescriptionLocationResponse,
  SearchCountResponseV2,
  AtsJobDescription,
} from '@air/api';
import { Button, TooltipWrapper } from '@air/components';
import { Stats } from 'components/Cards/KanbanCard/Stats/Stats';
import {
  ActionsMenu,
  ActionsMenuConfigT,
} from 'components/Cards/KanbanCard/ActionsMenu/ActionsMenu';
import * as phrases from 'constants/phrases';

import styles from './KanbanCard.css';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit } from 'hooks/usePubSub';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';

type Props<ItemT> = {
  id?: string;
  className?: string;
  item: ItemT;
  url: string;
  activeCounters?: { totalCount: number };
  passiveCounters?: { totalCount: number };
  actionsMenuConfig: ActionsMenuConfigT;
  isSelectMode?: boolean;
  areAllStatsExpanded: boolean;
  isMMEnabled?: boolean;
  isMSEnabled?: boolean;
  hideCountersBreakdown?: boolean;
};

export type KanbanCardT = {
  externalId?: string | number;
  searchId: string | number;
  name: string;
  locations?: JobDescriptionLocationResponse[];
  specializations?: string[];
  status?: string;
  created?: number;
  creator?: string;
  ats?: AtsJobDescription;
  activeCount: SearchCountResponseV2;
  passiveCount: SearchCountResponseV2;
  matchMinerCount?: SearchCountResponseV2;
  matchScoutCount?: SearchCountResponseV2;
};

export const KanbanCard: React.FC<Props<KanbanCardT>> = (props) => {
  const {
    id,
    item,
    url,
    isSelectMode,
    actionsMenuConfig,
    areAllStatsExpanded,
    isMMEnabled,
    isMSEnabled,
    hideCountersBreakdown,
  } = props;

  const [areStatsExpanded, setStatsExpanded] = useState(false);

  useEffect(() => {
    setStatsExpanded(areAllStatsExpanded);
  }, [areAllStatsExpanded]);

  const openCard = () => {
    history.push(url);
  };

  const onOpenInNewTab = (event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(url);
  };

  return (
    <div
      id={id}
      data-testid="kanban-card"
      className={classNames(styles.kanbanCard, {
        [styles.isSelectMode]: isSelectMode,
      })}
    >
      <div
        className={classNames(
          styles.generalInfo,
          styles.flexColumn,
          styles.bottomBorder
        )}
        onClick={isSelectMode ? undefined : openCard}
      >
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <TooltipWrapper
              enabled
              delayShow={TOOLTIP_DELAY_TIME_LONG}
              tooltip={item.name}
              placement="bottom-start"
            >
              <div className={styles.title}>{item.name}</div>
            </TooltipWrapper>
          </div>
          {isSelectMode ? null : (
            <div onClick={(event) => event.stopPropagation()}>
              <ActionsMenu
                creator={item.creator}
                created={item.created}
                actionsMenuConfig={actionsMenuConfig}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.statsWrapper}
        onClick={() => setStatsExpanded(!areStatsExpanded)}
      >
        {item.activeCount && (
          <Stats
            isActiveCountersSection
            counters={item.activeCount}
            title={phrases.LINEUP_TAB_ACTIVE_TITLE}
            hideCountersBreakdown={hideCountersBreakdown}
          />
        )}
        {areStatsExpanded && (
          <>
            {item.passiveCount && (
              <Stats
                counters={item.passiveCount}
                title={phrases.LINEUP_TAB_PASSIVE_TITLE}
                hideCountersBreakdown={hideCountersBreakdown}
              />
            )}
            {isMMEnabled && item.matchMinerCount && (
              <Stats
                counters={item.matchMinerCount}
                title={phrases.LINEUP_TAB_MATCH_MINER_TITLE_SHORT}
                hideCountersBreakdown={hideCountersBreakdown}
              />
            )}
            {isMSEnabled && item.matchScoutCount && (
              <Stats
                counters={item.matchScoutCount}
                title={phrases.LINEUP_TAB_MATCH_SCOUT_TITLE_SHORT}
                hideCountersBreakdown={hideCountersBreakdown}
              />
            )}
          </>
        )}
      </div>
      {isSelectMode && (
        <div className={styles.selectModeActionButtonsWrapper}>
          <div className={styles.selectModeActionButtons}>
            <Button
              alignCenter
              className={styles.selectModeButton}
              variant={Button.variants.POSITIVE_CONFIRM}
              onClick={(event) => {
                event.stopPropagation();
                emit(APP_EVENTS.SELECT_COPY_CRITERIA_SOURCE, item.searchId);
              }}
            >
              {phrases.SELECT_BUTTON_TEXT}
            </Button>
            <Button
              alignCenter
              className={styles.selectModeButton}
              onClick={onOpenInNewTab}
            >
              {phrases.OPEN_IN_NEW_TAB_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
