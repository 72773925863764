import { ReactNode } from 'react';
import { roleCardConfig } from '@air/domain/SearchCriteriaCards/RoleCard/roleCardConfig';
import { TooltipWrapperProps } from '@air/components/Tooltip/TooltipWrapper';
import { SearchCriteriaAlertType } from '@air/api';
import { skillCardConfig } from '@air/domain/SearchCriteriaCards/SkillCard/skillCardConfig';
import { certificationCardConfig } from '@air/domain/SearchCriteriaCards/CertificationCard/certificationCardConfig';
import { degreeCardConfig } from '@air/domain/SearchCriteriaCards/DegreeCard/degreeCardConfig';
import { majorCardConfig } from '@air/domain/SearchCriteriaCards/MajorCard/majorCardConfig';
import { institutionCardConfig } from '@air/domain/SearchCriteriaCards/InstitutionCard/institutionCardConfig';
import { companyCardConfig } from '@air/domain/SearchCriteriaCards/CompanyCard/companyCardConfig';
import { industryCardConfig } from '@air/domain/SearchCriteriaCards/IndustryCard/industryCardConfig';
import { professionalCardConfig } from '@air/domain/SearchCriteriaCards/ProfessionalCard/professionalCardConfig';
import { managerialCardConfig } from '@air/domain/SearchCriteriaCards/ManagerialCard/managerialCardConfig';
import { locationCardConfig } from '@air/domain/SearchCriteriaCards/LocationCard/locationCardConfig';
import {
  BaseCriteriaData,
  BaseSearchCriteriaData,
  SearchCriteriaListValue,
} from 'customer-portal/src/domain/SearchCriteria';
import { CandidateCriteriaListItem } from 'customer-portal/src/domain/CandidateData';

export enum CardType {
  skill = 'skill',
  major = 'major',
  degree = 'degree',
  institution = 'institution',
  certification = 'certification',
  industry = 'industry',
  company = 'company',
  role = 'role',
  managerial = 'managerial',
  location = 'location',
  professional = 'professional',
  question = 'question',
}

export type CardConfig = {
  // result of this callback overrides default tooltip props for the main card title
  getMainCardTooltipProps?: (
    mainCard: unknown,
    cardData?: unknown
  ) => Partial<TooltipWrapperProps>;
  // result of this callback overrides default tooltip props for the stack item title
  getStackItemTooltipProps?: (
    stackItemData: unknown,
    cardData?: unknown
  ) => Partial<TooltipWrapperProps>;
  // result of this callback overrides default tooltip props for the card select dropdown option
  getSelectDropDownOptionTooltipProps?: (
    optionData: unknown,
    cardData?: unknown
  ) => Partial<TooltipWrapperProps>;
  // used to map server alert type to human-readable string
  alertsMap?: { [alertStr: string]: SearchCriteriaAlertType[] };
  getCriteriaCardTitleLabel?: (
    cardData: BaseCriteriaData | unknown
  ) => ReactNode | null;
  getCriteriaCardStackTitleLabel?: (
    stackItemData: SearchCriteriaListValue | BaseSearchCriteriaData
  ) => string;
  getLineupCardStackTitleLabel?: (
    stackItemData: SearchCriteriaListValue | BaseSearchCriteriaData
  ) => string;
  getReadOnlyCardStackTitleLabel?: (
    stackItemData: SearchCriteriaListValue | BaseSearchCriteriaData
  ) => string;
  getCandidateCollapsedCardMatchResolutionLabel?: (
    cardData: CandidateCriteriaListItem
  ) => string;
};

export type CardsConfig = {
  [key in CardType]: CardConfig;
};

export const cardsConfig: CardsConfig = {
  [CardType.company]: companyCardConfig,
  [CardType.degree]: degreeCardConfig,
  [CardType.managerial]: managerialCardConfig,
  [CardType.certification]: certificationCardConfig,
  [CardType.role]: roleCardConfig,
  [CardType.major]: majorCardConfig,
  [CardType.industry]: industryCardConfig,
  [CardType.institution]: institutionCardConfig,
  [CardType.location]: locationCardConfig,
  [CardType.professional]: professionalCardConfig,
  [CardType.question]: {},
  [CardType.skill]: skillCardConfig,
};
