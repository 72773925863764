import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { SvgIcon } from '@air/components';
import * as phrases from 'constants/phrases';
import styles from './FilterInput.css';

const MAX_SEARCH_FILTER_LENGTH = 150;

type FilterInputPropsT = {
  name: string;
  className?: string;
  maxLength?: number;
  placeholder?: string;
  /*
    This parameter indicates if empty input should have smaller width,
    like the input for kanban searches.
   */
  isShrinking?: boolean;
  onChangeInput: () => void;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
};
export const FilterInput: React.FC<FilterInputPropsT> = ({
  name,
  onChangeInput = () => {},
  onFocus = () => {},
  onBlur = () => {},
  className,
  maxLength = MAX_SEARCH_FILTER_LENGTH,
  placeholder = phrases.SEARCH_FILTER_PLACEHOLDER,
  isShrinking = true,
}) => {
  const [selected, selectInput] = useState(false);
  const [hoverOverRejectIcon, setHoverOverRejectIcon] = useState(false);
  const ref = useRef<HTMLInputElement>();
  const { register, watch, setValue } = useFormContext();

  const currentValue = watch(name);
  const placeholderValue = selected ? placeholder : '';

  const filterInputRef = useRef(null);

  const setFocus = useCallback(() => {
    ref?.current?.focus();
  }, []);

  const [isClearingInput, triggerClearingAnimation] = useState(false);
  const removeInput = useCallback(() => {
    triggerClearingAnimation(true);
    setHoverOverRejectIcon(false);
    setTimeout(() => {
      triggerClearingAnimation(false);
      setValue(name, '', { shouldValidate: true });
      setFocus();
      onChangeInput();
    }, 400);
  }, [name, setValue, setFocus, onChangeInput]);

  const handleInputFocus = useCallback(() => {
    selectInput(true);
    onFocus();
  }, [onFocus, selectInput]);
  const handleInputBlur = useCallback(() => {
    if (currentValue?.length === 0) {
      selectInput(false);
    }
    setValue(name, currentValue ? currentValue.trim() : currentValue);
    onBlur(currentValue);
  }, [currentValue, name, selectInput, setValue, onBlur]);

  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      e.target.value = value.slice(0, maxLength);

      onChangeInput();
    },
    [maxLength, onChangeInput]
  );

  const handleKeydown = useCallback(
    (e: any) => {
      /*
      Prevent any printable characters input,
      if filter already has 150 symbols.
      We also should prevent pasting text.
    */
      const PRINTABLE_CHAR_LENGTH = 1;
      const { target, key, metaKey } = e;
      if (
        target.value.length >= maxLength &&
        key.length === PRINTABLE_CHAR_LENGTH &&
        !metaKey
      ) {
        e.preventDefault();
      }
    },
    [maxLength]
  );

  useEffect(() => {
    currentValue?.length > 0 && !selected && selectInput(true);
  }, [currentValue, selected, selectInput]);

  return (
    <div
      className={classNames(styles.filterInputWrapper, className)}
      ref={filterInputRef}
    >
      {isClearingInput && (
        <span className={styles.inputClearingAnimation}>{currentValue}</span>
      )}
      <input
        autoComplete="off"
        placeholder={placeholderValue}
        onFocus={handleInputFocus}
        onKeyDown={handleKeydown}
        {...register(name, {
          onChange: handleChange,
          onBlur: handleInputBlur,
        })}
        className={classNames(styles.filterInput, {
          [styles.focused]: selected || !isShrinking,
          [styles.hoverOverRejectIcon]: hoverOverRejectIcon,
        })}
      />
      <SvgIcon
        icon={'search-icon'}
        height="3em"
        width="3em"
        onClick={setFocus}
        className={classNames(styles.filterIcon, {
          [styles.focused]: selected && !hoverOverRejectIcon,
        })}
      />
      {currentValue?.length > 0 && (
        <div
          className={styles.rejectIconWrapper}
          onMouseEnter={() => setHoverOverRejectIcon(true)}
          onMouseLeave={() => setHoverOverRejectIcon(false)}
        >
          <SvgIcon
            icon="rejected-icon"
            onClick={removeInput}
            className={classNames(styles.rejectIcon)}
          />
        </div>
      )}
    </div>
  );
};
