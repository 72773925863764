import { MatchMinerStatus, MatchScoutStatus } from '@air/api';

type MatchServiceStatus = MatchScoutStatus | MatchMinerStatus;

const matchServiceStatuses = { ...MatchMinerStatus, ...MatchScoutStatus };

export const isMatchServiceRequested = (status: MatchServiceStatus) =>
  status === matchServiceStatuses.ACCESSREQUESTED;

export const isMatchServiceSetupInProgress = (status: MatchServiceStatus) =>
  status === matchServiceStatuses.SETUPINPROGRESS;

export const isMatchServiceSetupFailed = (status: MatchServiceStatus) =>
  status === matchServiceStatuses.ERRORSETUPINPROGRESS;

export const isMatchServiceDisabled = (status: MatchServiceStatus) =>
  status === matchServiceStatuses.DISABLED;

export const isMatchMinerEnabled = (matchMinerStatus: MatchMinerStatus) => {
  return matchMinerStatus === MatchMinerStatus.ENABLED;
};

export const isMatchScoutEnabled = (status: MatchScoutStatus) =>
  status === MatchScoutStatus.ENABLED;

export const DEFAULT_CANDIDATES_LIMIT = 150;
