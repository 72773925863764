// imports from vendor deps
import React, { PropsWithChildren } from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  DragLayer,
  DragSource,
  DragSourceSpec,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
// import classNames from 'classnames';
// imports from types
// imports from 'components'
import { CollapsedCardPreview } from 'components/CollapsedCard/CollapsedCardPreview';
import {
  DraftItem,
  InterviewItem,
  JobItem,
  ClosedSearchItem,
} from 'components/SideList/renderers';

// imports from 'constants'
import { KANBAN_TYPES } from 'constants/kanban';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';

// import from images
// import {} from 'images'
// imports from helpers
// import {} from 'utils'
// imports from styles
import styles from './CustomDragLayer.css';
import classNames from 'classnames';
import { DraggedRedFlagsType } from 'features/DraftSection/dndTypes';
import { FlagSettingsCard } from 'components/CardWrappers/FlagSettingsCard/FlagSettingsCard';
import { FlagSettingsState } from 'components/SearchCriteriaCards/hooks/FlagSettingsStateConfig';
import { SearchCriteriaImportanceEnum } from '@air/api';

const DRAGGABLE_TYPES = {
  ...KANBAN_TYPES,
  ...DraggedSearchCriteriaCardType,
  ...DraggedRedFlagsType,
};

function getItemStyles(props: any) {
  const { initialOffset, currentOffset, clientOffset } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = clientOffset;

  /*
    20 and 40 are arbitrary numbers
    that position dragged element
    closer to cursor
  */
  const transform = `translate(${x - 20}px, ${y - 40}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export type ConnectedDragSourcePropsT = {
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
  isDragging?: boolean;
  dragWrapperClassName?: string;
};

export type DraggableCriteriaCardProps<CriteriaType> = {
  id: string;
  criteria: CriteriaType;
  title: string;
  importanceSectionOrder: number;
  onDragStart: () => void;
  onDragEnd: () => void;
  importance: SearchCriteriaImportanceEnum;
  cardClasses: { [key: string]: string };
};

// component proptypes

// exports / component definitions
export const DragLayerInner: React.FC<any> = ({
  itemType,
  isDragging,
  item,
  ...props
}) => {
  if (!isDragging || !item.type) return null;
  const style = getItemStyles(props);

  const { title, locations, ownedByCurrentUser, flagData } = item;

  let dragPreview;
  switch (itemType) {
    case DRAGGABLE_TYPES.jobCard:
      dragPreview = (
        <JobItem
          isDragged={isDragging}
          title={title}
          locations={locations}
          ownedByCurrentUser={ownedByCurrentUser}
        />
      );
      break;
    case DRAGGABLE_TYPES.draftCard:
      dragPreview = (
        <DraftItem
          isDragged={isDragging}
          title={title}
          locations={locations}
          ownedByCurrentUser={ownedByCurrentUser}
        />
      );
      break;
    case DRAGGABLE_TYPES.interviewCard:
      dragPreview = (
        <InterviewItem
          isDragged={isDragging}
          title={title}
          isPaused={item.isPaused}
          locations={locations}
          ownedByCurrentUser={ownedByCurrentUser}
        />
      );
      break;
    case DRAGGABLE_TYPES.closedSearchCard:
      dragPreview = (
        <ClosedSearchItem
          isDragged={isDragging}
          title={title}
          locations={locations}
          ownedByCurrentUser={ownedByCurrentUser}
        />
      );
      break;
    case DRAGGABLE_TYPES.skill:
    case DRAGGABLE_TYPES.singleSkillItem:
    case DRAGGABLE_TYPES.certification:
    case DRAGGABLE_TYPES.singleCertificationItem:
    case DRAGGABLE_TYPES.role:
    case DRAGGABLE_TYPES.singleRoleItem:
    case DRAGGABLE_TYPES.managerial:
    case DRAGGABLE_TYPES.professional:
    case DRAGGABLE_TYPES.industry:
    case DRAGGABLE_TYPES.singleIndustryItem:
    case DRAGGABLE_TYPES.company:
    case DRAGGABLE_TYPES.location:
    case DRAGGABLE_TYPES.singleCompanyItem:
    case DRAGGABLE_TYPES.major:
    case DRAGGABLE_TYPES.institution:
    case DRAGGABLE_TYPES.degree:
    case DRAGGABLE_TYPES.question:
      dragPreview = <CollapsedCardPreview title={title} />;
      break;
    case DRAGGABLE_TYPES.RedFlagSettings:
      dragPreview = (
        <FlagSettingsCard
          isDraggingPreview
          flagData={flagData}
          initialState={FlagSettingsState.initial}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <div
      className={classNames(
        styles.customDragLayer,
        item.className,
        item.beginDragClass
      )}
    >
      <div style={style}>{dragPreview}</div>
    </div>
  );
};

// TODO: Fix any with valid return type for Draggable component
export const makeDraggableComponent = <ComponentProps, DragObject>(
  component: any,
  type: string,
  spec: DragSourceSpec<ComponentProps, DragObject>
): any =>
  DragSource(type, spec, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))(component);

export const CustomDragLayer = DragLayer((monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  clientOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}))(DragLayerInner);

type ComponentPropsT<T> = Pick<
  PropsWithChildren<T & ConnectedDragSourcePropsT>,
  | 'isDragging'
  | 'children'
  | Exclude<
      keyof T,
      'connectDragSource' | 'connectDragPreview' | 'dragWrapperClassName'
    >
>;

type WithCustomDragT = <T>(
  Component: React.FC<ComponentPropsT<T>>
) => React.FC<T & ConnectedDragSourcePropsT>;
export const withCustomDrag: WithCustomDragT = (Component) => (props) => {
  const {
    connectDragSource,
    connectDragPreview,
    dragWrapperClassName = '',
    ...restProps
  } = props;
  if (connectDragPreview) {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }
  return connectDragSource(
    <div className={dragWrapperClassName}>
      <Component {...restProps} />
    </div>
  );
};
