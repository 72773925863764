import React from 'react';
import { Header, Paragraph } from '@air/components';
import * as phrases from 'constants/phrases';
import { emit } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';
import {
  MatchMinerSetupSettings,
  MatchScoutSetupSettings,
} from 'domain/MatchServices/MatchServices';
import { MatchMinerSettingsForm } from 'features/MatchServiceSettings/MatchServiceSettingsForms/MatchMinerSettingsForm';
import styles from './MatchServiceSettings.css';
import { LineupTabs } from '@air/constants/tabs';
import { MatchScoutSettingsForm } from './MatchServiceSettingsForms/MatchScoutSettingsForm';
import { isMatchMinerTab } from 'domain/CandidateData/CandidateLineupData';

type MatchServiceSettingsProps = {
  onRestartSearch: (
    settings: MatchMinerSetupSettings | MatchScoutSetupSettings
  ) => void;
  tab: LineupTabs;
  canRestartSearch: boolean;
};
export const MatchServiceSettings: React.FC<MatchServiceSettingsProps> = ({
  onRestartSearch,
  tab,
  canRestartSearch = true,
}) => {
  const onRestartSearchSubmit = (
    settings: MatchMinerSetupSettings | MatchScoutSetupSettings
  ) => {
    emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
      text: phrases.MS_SETTINGS_PANEL_RESTART_CONFIRMATION,
      confirmLabel: phrases.MS_SETTINGS_PANEL_RESTART_SEARCH,
      onConfirm: () => onRestartSearch(settings),
      options: {
        modalStyles: {
          content: { width: 300 },
        },
      },
    });
  };

  return (
    <div className={styles.panelContent}>
      {isMatchMinerTab(tab) ? (
        <>
          <Header level={3} bold>
            {phrases.MM_SETTINGS_PANEL_HEADER}
          </Header>
          <Paragraph className={styles.mmSettingsDescription}>
            {phrases.MM_SETTINGS_PANEL_DESCRIPTION}
          </Paragraph>
          <MatchMinerSettingsForm
            className={styles.restartForm}
            submitLabel={phrases.MS_SETTINGS_PANEL_RESTART_SEARCH}
            onMatchMinerSearchStart={onRestartSearchSubmit}
            isSubmitEnabled={canRestartSearch}
          />
        </>
      ) : (
        <>
          <Header level={3} bold>
            {phrases.MS_SETTINGS_PANEL_HEADER}
          </Header>
          <Paragraph className={styles.mmSettingsDescription}>
            {phrases.MM_SETTINGS_PANEL_DESCRIPTION}
          </Paragraph>
          <MatchScoutSettingsForm
            className={styles.restartForm}
            submitLabel={phrases.MS_SETTINGS_PANEL_RESTART_SEARCH}
            onMatchScoutSearchStart={onRestartSearchSubmit}
            isSubmitEnabled={canRestartSearch}
          />
        </>
      )}
    </div>
  );
};
MatchServiceSettings.displayName = 'MatchServiceSettings';
