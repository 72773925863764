import React from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { Controller, useForm } from 'react-hook-form';
import * as phrases from 'constants/phrases';
import { Button, MaskedFormField, TooltipWrapper } from '@air/components';
import styles from './MatchServiceSettingsForm.css';
import { FormFieldTypes } from '@air/components/Form/utils';
import { isNumberValid } from '@air/utils/strings';
import {
  getCommonMatchServiceTooltip,
  MatchScoutSetupSettings,
  MAX_CANDIDATES_LIMIT,
} from 'domain/MatchServices/MatchServices';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import {
  isMatchScoutEnabled,
  DEFAULT_CANDIDATES_LIMIT,
} from 'domain/CustomerProfile/matchServiceSettings';
import { LineupTabs } from '@air/constants/tabs';
import { useKanbanContext } from 'providers/KanbanProvider';

const LIMIT_FIELD = 'limit';

type MatchScoutSettingsFormProps = {
  isSubmitEnabled?: boolean;
  className?: string;
  submitLabel?: string;
  onMatchScoutSearchStart: (settings: MatchScoutSetupSettings) => void;
};

const DEFAULT_SETTINGS = {
  limit: DEFAULT_CANDIDATES_LIMIT,
  offset: 0,
};

export const MatchScoutSettingsForm: React.FC<MatchScoutSettingsFormProps> = ({
  className,
  isSubmitEnabled = true,
  onMatchScoutSearchStart,
  submitLabel = phrases.MATCH_SERVICE_START_SEARCH,
}) => {
  const msSettings = useCustomerProfileContext(
    customerProfileSelectors.matchScoutSettings
  );
  const { limit, offset } =
    useKanbanContext(kanbanSelectors.matchScoutMetadata) || DEFAULT_SETTINGS;

  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const { requestMatchScoutForCompany } = useCustomerProfileMethods();
  const isMatchScoutRestartEnabled = isMatchScoutEnabled(msSettings.status);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, string | number>>({
    /*
      offset - all candidates, who were processed previously (before last attempt)
      limit - amount of candidates requested for processing during last attempt
    */
    defaultValues: {
      [LIMIT_FIELD]: limit + offset,
    },
    shouldUnregister: true,
    mode: 'onChange',
  });

  const hasErrors = !R.isEmpty(errors);

  const hasLimitError = !!errors[LIMIT_FIELD];
  const limitFieldLabel =
    errors[LIMIT_FIELD]?.message || phrases.MM_SETTINGS_FORM_LIMIT_LABEL;

  return (
    <form
      onSubmit={handleSubmit(onMatchScoutSearchStart)}
      className={classNames(styles.settingsForm, className)}
    >
      <>
        <div className={styles.formFields}>
          <Controller
            name={LIMIT_FIELD}
            control={control}
            rules={{
              required: {
                value: true,
                message: phrases.MM_SETTINGS_FORM_LIMIT_LABEL,
              },
              validate: {
                notZero: (value: string) =>
                  +value !== 0 || phrases.MM_SETTINGS_FORM_LIMIT_LABEL,
                isGreaterThanZero: (value: string) =>
                  isNumberValid(MAX_CANDIDATES_LIMIT)(value) ||
                  phrases.GET_MM_SETTINGS_FORM_LIMIT_ERROR_LABEL(
                    MAX_CANDIDATES_LIMIT
                  ),
              },
            }}
            render={({ field }) => (
              <MaskedFormField
                id={LIMIT_FIELD}
                mask="9999"
                type={FormFieldTypes.text}
                label={limitFieldLabel}
                hasError={hasLimitError}
                className={styles.formField}
                {...field}
              />
            )}
          />
        </div>
        <TooltipWrapper
          tooltip={getCommonMatchServiceTooltip({
            tab: LineupTabs.MatchScout,
            status: msSettings.status,
            customerRole: user.role,
            requestMatchServiceForCompany: requestMatchScoutForCompany,
          })}
          enabled={!isMatchScoutRestartEnabled}
          containerClassName={styles.tooltipContainer}
          triggerClassName={styles.triggerContainer}
          disabledChildren
        >
          <Button
            type="submit"
            small
            variant={Button.variants.POSITIVE_CONFIRM}
            className={styles.startSearch}
            disabled={
              !isSubmitEnabled || hasErrors || !isMatchScoutRestartEnabled
            }
            alignCenter
          >
            {submitLabel}
          </Button>
        </TooltipWrapper>
      </>
    </form>
  );
};
