import { ReactNode } from 'react';
import { compose, groupBy, prop, sort, toPairs, partition } from 'ramda';
import { HeaderCriteriaData } from 'domain/HeaderData/HeaderDataMapper';
import { CandidateLineupData } from 'domain/CandidateData/CandidateLineupData';
import { RemoveApplicantsParamsT, Requisition } from 'context';
import {
  CandidateSearchProfileStatusEnum,
  CandidateStatus,
} from 'domain/CandidateData/candidateStatusHelpers';
import { SearchCountersT } from 'domain/SearchCounters/SearchCounterData';
import { ProfileSort } from '@air/api';
import { sortCandidatesBySortType } from 'domain/CandidateData';

export type LineupTHeadRow = {
  columns: HeaderCriteriaData[];
  focusedColumn: number | null;
};

export type LineupTableProps = {
  columns: HeaderCriteriaData[];
  sortedData: GroupedPairT[];
  tableSections: LineupTableSections;
  onStatusRowClick: (status: CandidateSearchProfileStatusEnum) => void;
  firstColumnRenderer: (item: CandidateLineupData) => ReactNode;
  cellOnClickHandler: (
    id: string,
    cardId: number | string,
    showNote: boolean
  ) => void;
  totalApplicants: number;
  isProcessing: boolean;
  currentRequisition?: Requisition;
  isUploadsListVisible?: boolean;
  jobRequisitionId: string;
  removeApplicant?: ({
    atsCandidateId,
    tempId,
    uploadStatus,
    atsId,
  }: RemoveApplicantsParamsT) => void;
  isCandidatesListLoading?: boolean;
  onFilterChange: (query: string) => void;
  filterValue: string;
};

export type GroupedPairT = [
  CandidateSearchProfileStatusEnum,
  CandidateLineupData[]
];

export type LineupTableSections = Partial<{
  [key in CandidateSearchProfileStatusEnum]: {
    counterName?: keyof SearchCountersT;
    counter?: number;
    opened: boolean;
    title: string;
  };
}>;

export const expandedInterviewSectionsInitial: LineupTableSections = {
  [CandidateSearchProfileStatusEnum.NOTPROCESSED]: {
    opened: false,
    title: CandidateStatus[CandidateSearchProfileStatusEnum.NOTPROCESSED],
    counterName: 'notProcessed',
  },
  [CandidateSearchProfileStatusEnum.MANUALSHORTLIST]: {
    opened: true,
    title: CandidateStatus[CandidateSearchProfileStatusEnum.MANUALSHORTLIST],
    counterName: 'selected',
  },
  [CandidateSearchProfileStatusEnum.ACTIVE]: {
    opened: true,
    title: CandidateStatus[CandidateSearchProfileStatusEnum.ACTIVE],
    counterName: 'matched',
  },
  [CandidateSearchProfileStatusEnum.PENDING]: {
    opened: true,
    title: CandidateStatus[CandidateSearchProfileStatusEnum.PENDING],
    counterName: 'pending',
  },
  [CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION]: {
    opened: false,
    title:
      CandidateStatus[CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION],
    counterName: 'rejected',
  },
  [CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE]: {
    opened: false,
    title: CandidateStatus[CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE],
    counterName: 'failed',
  },
};

export const expandedInterviewSectionUploads = {
  [CandidateSearchProfileStatusEnum.UPLOAD]: {
    opened: false,
    title: CandidateStatus[CandidateSearchProfileStatusEnum.UPLOAD],
  },
};

const createSortByStatus = (order: any) => {
  const getOrder = (value = CandidateSearchProfileStatusEnum.ACTIVE) => {
    return order[value];
  };
  return ([itemA]: GroupedPairT, [itemB]: GroupedPairT) => {
    return getOrder(itemA) - getOrder(itemB);
  };
};

const sortByStatus = createSortByStatus({
  [CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE]: 6,
  [CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION]: 5,
  [CandidateSearchProfileStatusEnum.PENDING]: 4,
  [CandidateSearchProfileStatusEnum.ACTIVE]: 3,
  [CandidateSearchProfileStatusEnum.MANUALSHORTLIST]: 2,
  [CandidateSearchProfileStatusEnum.NOTPROCESSED]: 1,
  [CandidateSearchProfileStatusEnum.UPLOAD]: 0,
});

export const sortLineupTableData = ({
  data,
  sortType,
}: {
  data: CandidateLineupData;
  sortType?: ProfileSort;
}) =>
  compose<
    CandidateLineupData[],
    { [name in CandidateSearchProfileStatusEnum]: CandidateLineupData[] },
    GroupedPairT[],
    GroupedPairT[],
    GroupedPairT[]
  >(
    (data) => mergeRejectedCandidates(data, sortType),
    sort<GroupedPairT>(sortByStatus),
    // @ts-ignore
    toPairs,
    (result: CandidateLineupData[]) =>
      groupBy<CandidateLineupData, CandidateSearchProfileStatusEnum>(
        prop('status')
      )(result || [])
  )(data);

function mergeRejectedCandidates(
  sortedData: GroupedPairT[],
  sortType?: ProfileSort
): GroupedPairT[] {
  /*
    Manually rejected candidates should be merged with
    candidates rejected by system.
   */
  const [rejectedLists, otherLists] = partition(
    ([status]) =>
      [
        CandidateSearchProfileStatusEnum.MANUALREJECTION,
        CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION,
      ].includes(status),
    sortedData
  );
  const [
    [, manuallyRejectedCandidates = []] = [],
    [, systemRejectedCandidates = []] = [],
  ] = rejectedLists;

  const rejectedCandidates = sortType
    ? sort(
        (candidate1, candidate2) => {
          return sortCandidatesBySortType(candidate1, candidate2, sortType);
        },
        [...manuallyRejectedCandidates, ...systemRejectedCandidates]
      )
    : [...manuallyRejectedCandidates, ...systemRejectedCandidates];
  return rejectedCandidates.length === 0
    ? otherLists
    : [
        ...otherLists,
        [
          CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION,
          rejectedCandidates,
        ],
      ];
}
