// imports from vendor deps
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { RejectedFile } from 'context/uploadApplicants';

// imports from types
// imports from 'components'
import { Button } from '@air/components';
import { BASIC_DROPZONE_CONFIG } from 'components/Dropzone/Dropzone';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

// imports from 'constants'
import * as phrases from 'constants/phrases';

// imports from helpers
import { GA_LABEL_UPLOAD_CV_BUTTON } from 'constants/gaLabels';
import { APP_EVENTS } from 'domain/Kanban/events';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { emit } from 'hooks/usePubSub';

// imports from styles
import styles from './UploaderButton.css';
import { useUploadApplicantsMethods } from 'providers/UploadApplicantsProvider';

// component proptypes
type Props = {
  disabled?: boolean;
  onDropAccepted?: (files: File[], atsId: number) => void;
  className?: string;
};

// exports / component definitions
export const UploaderButton: React.FC<Props> = ({ disabled, className }) => {
  const { onDropRejected, onDropAccepted } = useUploadApplicantsMethods();
  const onDropAcceptedCb = useCallback(
    (files, atsId) => {
      trackEvent({
        category: GACategory.ScreeningPage,
        label: GA_LABEL_UPLOAD_CV_BUTTON,
      });
      onDropAccepted(files, atsId);
      emit(APP_EVENTS.APPLICANT_DRAG_START);
    },
    [onDropAccepted]
  );
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    ...BASIC_DROPZONE_CONFIG,
    noDrag: true,
    onDropRejected: (items: RejectedFile[]) => onDropRejected(items),
    onDropAccepted: (items: File[]) => {
      onDropAcceptedCb(items, dataSourceId);
    },
  });

  return (
    <div className={classNames(styles.uploader, className)}>
      <div
        {...getRootProps({
          className: `dropzone dropzone-uploader ${styles.dropzone}`,
        })}
      >
        <input {...getInputProps()} />
        <Button
          disabled={disabled}
          className={styles.uploaderButton}
          variant={Button.variants.CHIP_BLUE}
          onClick={open}
        >
          {phrases.UPLOADER_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};

UploaderButton.displayName = 'UploaderButton';
