import R from '@air/third-party/ramda';
import { SearchListItemT } from 'domain/Kanban/Kanban';
import { KANBAN_TYPES } from 'constants/kanban';

export function getAssessmentProgress(counters: {
  appliedCount: number;
  processedCount: number;
}) {
  /*
  AR-3529: To prevent confusion caused by incorrect backend data, when number of
  processed candidates exceeded number of applied candidates, we
  limit max displayed progress to 100%;
*/
  const MAX_PERCENTAGE = 100;
  const { appliedCount, processedCount } = counters;
  const currentProgress = Math.floor(
    ((processedCount || 0) / (appliedCount || 1)) * MAX_PERCENTAGE
  );
  const assessmentProgress = Math.min(currentProgress, MAX_PERCENTAGE);
  const isAssessmentComplete = assessmentProgress === MAX_PERCENTAGE;
  return { isAssessmentComplete, assessmentProgress };
}

function hasCriteria(criteriaName: string) {
  return (item: SearchListItemT) => {
    return R.path(['criteria', criteriaName, 'length'], item) > 0;
  };
}

// const hasSkillCriteria = hasCriteria('skills');
function hasSkillCriteria(item: SearchListItemT) {
  const skills = R.path(['criteria', 'skills'], item);
  return (
    R.path(['includeList', 'length'], skills) > 0 ||
    R.path(['excludeList', 'length'], skills) > 0
  );
}

const hasLocationCriteria = (item: SearchListItemT) => {
  return R.path(['criteria', 'locations', 'list', 'length'], item) > 0;
};

const hasCertificateCriteria = hasCriteria('certifications');
const hasExperienceCriteria = hasCriteria('experience');
const hasQuestions = hasCriteria('additionalQuestions');

function hasIndustryCriteria(item: SearchListItemT) {
  const industry = R.path(['criteria', 'industryExperience'], item);
  return (
    R.path(['includeList', 'length'], industry) > 0 ||
    R.path(['excludeList', 'length'], industry) > 0
  );
}

function hasCompanyCriteria(item: SearchListItemT) {
  const companies = R.path(['criteria', 'companyExperience'], item);
  return (
    R.path(['includeList', 'length'], companies) > 0 ||
    R.path(['excludeList', 'length'], companies) > 0
  );
}

function hasEducation(item: SearchListItemT) {
  return !R.isNil(R.path(['criteria', 'education'], item));
}

function hasTitleExperienceCriteria(item: SearchListItemT) {
  const roles = R.path(['criteria', 'titleExperience'], item);
  return (
    R.path(['includeList', 'length'], roles) > 0 ||
    R.path(['excludeList', 'length'], roles) > 0
  );
}

export function isDraftSearchValid(search: SearchListItemT) {
  return R.anyPass([
    hasSkillCriteria,
    hasCertificateCriteria,
    hasIndustryCriteria,
    hasCompanyCriteria,
    hasExperienceCriteria,
    hasEducation,
    hasTitleExperienceCriteria,
    hasLocationCriteria,
    hasQuestions,
  ])(search);
}

export const acceptableDraftDropTypes = [KANBAN_TYPES.jobCard];
