import React from 'react';
import classNames from 'classnames';
import styles from './Loader.css';

type Props = {
  className?: string;
  color?: keyof typeof LoaderImages;
};

const DEFAULT_COLOR = 'blue';
export const LoaderImages = {
  green: '/assets/images/loader-green.png',
  blue: '/assets/images/loader-blue.png',
  grey: '/assets/images/loader-grey.png',
  white: '/assets/images/loader-white.png',
};

export const Loader: React.FC<Props> = ({
  className,
  color = DEFAULT_COLOR,
}) => {
  return (
    <img
      src={LoaderImages[color]}
      alt="loader"
      className={classNames(styles.loader, className)}
    />
  );
};
