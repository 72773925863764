import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import {
  getImportanceValue,
  SearchCriteriaData,
  CardType,
  SearchCriteriaListValue,
  BaseCriteriaData,
  isExcludeRole,
  getUnwantedRoleLabel,
} from 'domain/SearchCriteria';
import { useStateMachine } from '@air/hooks';
import { Card } from '@air/components';
import {
  LineupCardActions,
  LineupCardState,
  LineupCardStateConfig,
} from 'components/SearchCriteriaCards/hooks/LineupCardStateConfig';
import { useOutsideClick } from '@air/utils/hooks';
import {
  getCardCriterionLabel,
  getSearchCriteriaCardFooter,
  getCardStackSize,
  getMainCardTitle,
  getStackToggleItemsClasses,
  getMainCard,
  getCardTitleClassName,
} from 'domain/SearchCriteria/cardHelpers';
import searchCardsStyles from 'components/SearchCards/SearchCards.css';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import * as phrases from 'constants/phrases';
import { getDegreeReadOnlyFields } from 'components/Cards/DegreeCard/DegreeCard';
import { getInstitutionReadOnlyFields } from 'components/Cards/InstitutionCard/InstitutionCard';
import { getMajorReadOnlyFields } from 'components/Cards/MajorCard/MajorCard';
import { getSkillReadOnlyFields } from 'components/Cards/SkillCard/SkillCard';
import { getCertificationReadOnlyFields } from 'components/Cards/CertificationCard/CertificationCard';
import { getCompanyReadOnlyFields } from 'components/Cards/CompanyCard/CompanyCard';
import { getRolesReadOnlyFields } from 'components/Cards/RoleCard/RoleCard';
import { getManagementReadOnlyFields } from 'components/Cards/ManagerialCard/ManagerialCard';
import { getWorkExperienceReadOnlyFields } from 'components/Cards/ProfessionalCard/ProfessionalCard';
import {
  LineupCollapsedCard,
  LineupExpandedCard,
} from 'components/CardWrappers';
import { getQuestionReadOnlyFields } from '../Cards/QuestionCard/QuestionCard';
import { getCardInitialSize } from 'domain/CandidateData/candidateCardHelpers';
import { getIndustryReadOnlyFields } from 'components/Cards/IndustryCard/IndustryCard';
import { getLocationReadOnlyFields } from 'components/Cards/LocationCard/LocationCard';
import { isExcludedCriteriaCard } from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';

const getHeaderLineupCollapsedCardClassNames = (
  card: SearchCriteriaData
): string => {
  switch (card.cardType) {
    case CardType.skill:
    case CardType.role:
    case CardType.company:
    case CardType.industry:
      return isExcludedCriteriaCard(card)
        ? searchCardsStyles.headerCollapsedExcludedCriteriaCard
        : '';
    default:
      return '';
  }
};

const getHeaderLineupExpandedCardClassNames = (
  card: SearchCriteriaData
): string => {
  switch (card.cardType) {
    case CardType.skill:
    case CardType.industry:
    case CardType.company:
    case CardType.role:
      return isExcludedCriteriaCard(card)
        ? searchCardsStyles.headerExpandedExcludedCriteriaCard
        : '';
    default:
      return '';
  }
};

const getHeaderLineupExpandedCardStackItemClassName =
  (card: SearchCriteriaData) =>
  (stackItem: SearchCriteriaListValue): string =>
    getCardTitleClassName(card.cardType, stackItem);

const getCardSecondLabel = (card: SearchCriteriaData): JSX.Element => {
  switch (card.cardType) {
    case CardType.skill:
    case CardType.company:
    case CardType.industry:
      return isExcludedCriteriaCard(card) ? (
        <div
          className={classNames(
            cardCommonStyles.unwantedLabel,
            searchCardsStyles.headerCollapsedCardUnwantedLabel
          )}
        >
          #{phrases.UNWANTED_LABEL}
        </div>
      ) : null;
    case CardType.role:
      return isExcludeRole(card) ? (
        <div
          className={classNames(
            cardCommonStyles.unwantedLabel,
            searchCardsStyles.headerCollapsedCardUnwantedLabel
          )}
        >
          #{getUnwantedRoleLabel(card)}
        </div>
      ) : null;
    default:
      return null;
  }
};

export const getCardReadOnlyFields = (card: SearchCriteriaData) => {
  switch (card.cardType) {
    case CardType.degree:
      return getDegreeReadOnlyFields(card);
    case CardType.institution:
      return getInstitutionReadOnlyFields(card);
    case CardType.major:
      return getMajorReadOnlyFields(card);
    case CardType.skill:
      return getSkillReadOnlyFields(card);
    case CardType.certification:
      return getCertificationReadOnlyFields(card);
    case CardType.industry:
      return getIndustryReadOnlyFields(card);
    case CardType.company:
      return getCompanyReadOnlyFields(card);
    case CardType.location:
      return getLocationReadOnlyFields(card);
    case CardType.role:
      return getRolesReadOnlyFields(card);
    case CardType.managerial:
      return getManagementReadOnlyFields(card);
    case CardType.professional:
      return getWorkExperienceReadOnlyFields(card);
    case CardType.question:
      return getQuestionReadOnlyFields(card);
    default:
      return [`not implemented`];
  }
};

export const LineupCardPreview = React.memo(
  ({
    cardData,
    focused,
    onExpand,
    onClose,
    index,
  }: {
    cardData: SearchCriteriaData;
    focused: boolean;
    onExpand: (index: number) => void;
    onClose: (index: number) => void;
    index: number;
  }) => {
    const [cardState, dispatch] = useStateMachine(
      LineupCardStateConfig,
      LineupCardState.initial
    );

    const expandCard = useCallback(() => {
      dispatch(LineupCardActions.showExpanded);
      onExpand(index);
    }, [dispatch, onExpand, index]);

    const collapseCard = useCallback(() => {
      dispatch(LineupCardActions.showCollapsed);
    }, [dispatch]);

    const resetCardView = useCallback(() => {
      dispatch(LineupCardActions.showInitial);
    }, [dispatch]);

    const closeCard = useCallback(() => {
      onClose(index);
      resetCardView();
    }, [resetCardView, onClose, index]);

    const isInitial = cardState.matches(LineupCardState.initial);
    const isCollapsed = cardState.matches(LineupCardState.collapsed);
    const isCardInExpandedState = cardState.matches(LineupCardState.expanded);

    useEffect(() => {
      if (isCardInExpandedState) {
        return;
      }

      if (focused) {
        collapseCard();
        onClose(index);
      } else {
        resetCardView();
      }
    }, [
      collapseCard,
      focused,
      resetCardView,
      onClose,
      index,
      isCardInExpandedState,
    ]);

    const [outsideClickRef] = useOutsideClick(closeCard, {
      useCapture: true,
    });

    const cardCriterionLabel = getCardCriterionLabel(cardData);

    const defaultProps = {
      importance: getImportanceValue(cardData),
      cardLabel: cardCriterionLabel,
      footerText: getSearchCriteriaCardFooter(cardData),
      stackSize: getCardStackSize(cardData),
    };

    const initialProps = {
      stackSize: 0,
      titleFirstLabel: cardCriterionLabel,
      importance: cardData.importance?.value,
      showImportance: !isInitial,
    };

    const stackClasses = getStackToggleItemsClasses(cardData);

    const mainCard = getMainCard<BaseCriteriaData, SearchCriteriaListValue>(
      cardData
    );

    return (
      <Card.ResizeableCardWrapper
        resizeable
        defaultSize={getCardInitialSize(cardData)}
      >
        {isCardInExpandedState ? (
          <LineupExpandedCard
            ref={outsideClickRef}
            limitedHeight
            cardData={cardData}
            topLabel={getCardCriterionLabel(cardData)}
            getStackItemClassName={getHeaderLineupExpandedCardStackItemClassName(
              cardData
            )}
            className={classNames(
              getHeaderLineupExpandedCardClassNames(cardData)
            )}
            onActionButtonClicked={closeCard}
          >
            {getCardReadOnlyFields(cardData)}
          </LineupExpandedCard>
        ) : (
          <LineupCollapsedCard
            cardData={cardData}
            className={classNames(
              getCardTitleClassName(cardData.cardType, mainCard),
              getHeaderLineupCollapsedCardClassNames(cardData),
              {
                [searchCardsStyles.headerInitialCardState]: isInitial,
                [searchCardsStyles.headerCollapsedCardState]: isCollapsed,
              }
            )}
            stackClasses={stackClasses}
            onClick={expandCard}
            onMouseEnter={collapseCard}
            onMouseLeave={resetCardView}
            title={getMainCardTitle(cardData)}
            titleSecondLabel={getCardSecondLabel(cardData)}
            {...(isInitial ? initialProps : defaultProps)}
          />
        )}
      </Card.ResizeableCardWrapper>
    );
  }
);
LineupCardPreview.displayName = 'LineupCardPreview';
