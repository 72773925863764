import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import R from '@air/third-party/ramda';
import { Field, FieldProps } from 'formik';

// import hooks
import { useStateMachine } from '@air/hooks';
import { SearchCardStateConfig } from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import { SearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';
// import utils
import { SearchCriteriaImportanceEnum } from '@air/api';
import {
  QuestionCriteriaData,
  getImportanceValue,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria';
// import shared components
import { Card } from '@air/components';
// import customer components
import {
  CriteriaCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import {
  makeDraggableComponent,
  withCustomDrag,
  DraggableCriteriaCardProps,
} from 'components/CustomDragLayer/CustomDragLayer';
// import types
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
// import styles
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
// import constants
import {
  getCardCriterionLabel,
  getMainCard,
  getMainCardTitle,
} from 'domain/SearchCriteria/cardHelpers';
import { CardSize } from '@air/components/Card/Card';
import {
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

export const getQuestionReadOnlyFields = (
  card: QuestionCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
  ];
};

const DraggableQuestionCriteriaCard = makeDraggableComponent<
  DraggableCriteriaCardProps<QuestionCriteriaData>,
  { id: number | string }
>(
  withCustomDrag(CriteriaCollapsedCard),
  DraggedSearchCriteriaCardType.question,
  {
    beginDrag: ({
      id,
      criteria,
      title,
      importanceSectionOrder,
      onDragStart,
    }: any) => {
      onDragStart && onDragStart();
      return {
        title,
        id,
        order: criteria.idx,
        importance: getImportanceValue(criteria),
        importanceSectionOrder,
        type: DraggedSearchCriteriaCardType.question,
      };
    },
    endDrag: ({ onDragEnd }: any) => {
      onDragEnd && onDragEnd();
    },
  }
);

DraggableQuestionCriteriaCard.displayName = 'DraggableQuestionCriteriaCard';

const MAX_QUESTION_LENGTH = 200;

type TextAreaRendererProps = {
  onChange: (value: SearchCriteriaListValue<{ value: string }>) => void;
  value: string;
  autoFocus: boolean;
  isReadOnly: boolean;
};

const TextAreaRenderer: React.FC<TextAreaRendererProps> = ({
  onChange,
  value,
  autoFocus,
  isReadOnly,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoFocus) {
      textAreaRef.current?.focus();
    }
  }, [autoFocus]);

  const changeHandler = useCallback((e) => {
    const { value } = e.target;
    e.target.value = value.slice(0, MAX_QUESTION_LENGTH);
  }, []);

  const blurHandler = useCallback(
    (e) => {
      const value = e.target.value.trim();
      if (value.length > 0) {
        onChange({ label: value, value: value });
      }
    },
    [onChange]
  );

  return (
    <div className={cardCommonStyles.textAreaInputWrapper}>
      <textarea
        disabled={isReadOnly}
        className={cardCommonStyles.textAreaInput}
        ref={textAreaRef}
        onChange={isReadOnly ? R.noop : changeHandler}
        onBlur={isReadOnly ? R.noop : blurHandler}
        defaultValue={value}
      />
    </div>
  );
};

const QuestionCriteriaCardEdit: FC<{
  namePrefix: string;
  values: QuestionCriteriaData;
  changeCardImportance: (
    criteria: QuestionCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
}> = ({ values, namePrefix, changeCardImportance, isReadOnly = false }) => {
  const selectQuestionValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        const onChange = props.onChange
          ? props.onChange
          : (value: any) => {
              return form.setFieldValue(field.name, value);
            };

        return (
          <TextAreaRenderer
            autoFocus={!isReadOnly}
            onChange={isReadOnly ? R.noop : onChange}
            value={field.value?.value}
            isReadOnly={isReadOnly}
          />
        );
      },
    [isReadOnly]
  );

  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      changeCardImportance={changeCardImportance}
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <Field name={`${namePrefix}.question`}>
          {({ field, form }: FieldProps) => (
            <div
              ref={mainCriteriaNameFieldRef}
              className={cardCommonStyles.titleFieldWithImportance}
            >
              {selectQuestionValueControl({
                field,
                form,
                className: cardCommonStyles.multilineTitle,
                isDisabled: isImportanceSectionOpened || isReadOnly,
                focusField: !isReadOnly,
                onChange: async (value: { label: string }) => {
                  await form.setFieldValue(field.name, value);
                  updateMainFieldHandler(value);
                },
              })}
              {importanceIndicator}
            </div>
          )}
        </Field>
      )}
    </CriteriaEditCard>
  );
};

QuestionCriteriaCardEdit.displayName = 'QuestionCriteriaCardEdit';

type QuestionCardComponentProps = {
  cardData: QuestionCriteriaData;
  namePrefix: string;
  onUpdate: () => void;
  onRemove: () => void;
  importanceSectionOrder: number;
  changeCardImportance: (
    criteria: QuestionCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
};

export const QuestionCriteriaCard: FC<QuestionCardComponentProps> = ({
  namePrefix,
  cardData,
  importanceSectionOrder,
  changeCardImportance,
  onUpdate,
  onRemove,
  isReadOnly = false,
}) => {
  const cardTitle = getMainCardTitle(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: false,
      updated: true,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper
        defaultSize={CardSize.wide}
        resizeable={false}
      >
        {isCardInViewMode(cardState) ? (
          <DraggableQuestionCriteriaCard
            title={cardTitle}
            importance={cardImportance}
            cardLabel={cardLabel}
            criteria={cardData}
            id={cardData.key}
            onRemove={onRemove}
            importanceSectionOrder={importanceSectionOrder}
            initialCardStatus={cardData.initialCardStatus}
          />
        ) : (
          <QuestionCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
QuestionCriteriaCard.displayName = 'QuestionCriteriaCard';
