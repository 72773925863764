import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import {
  SearchCriteriaImportanceEnum,
  SearchSkillListResponse,
  SearchSkillResponse,
  SearchSkillsListsResponse,
} from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  getCriteriaItems,
  getExperience,
} from 'domain/CandidateData/CandidateCriteria/BaseCandidateData';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateExcludeSkillData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.skill;
  exclude: true;
}

export interface CandidateIncludeSkillData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.skill;
  exclude: false;
}

export type CandidateSkillData =
  | CandidateIncludeSkillData
  | CandidateExcludeSkillData;

const getSkillItem = (
  listItem: SearchSkillResponse
): CandidateCriteriaListItem => {
  return {
    id: listItem.id || listItem.fullName,
    label: listItem.fullName,
    experience: getExperience<SearchSkillResponse>(listItem),
    status: listItem.status,
    alert: getAlertData(listItem.alerts),
    matchResolution: listItem.matchResolution,
    interview: interviewDataMapper(listItem.refId, listItem.interview),
    deducedFrom: listItem.deducedFrom,
  };
};

export const mapSkills = (searchSkills: SearchSkillsListsResponse) => {
  const includeSkills: CandidateIncludeSkillData[] = (
    searchSkills?.includeList || []
  ).map((skill: SearchSkillListResponse) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchSkillListResponse,
      CandidateCriteriaListItem
    >(getSkillItem, skill);

    return {
      id: `include-${skill.idx}`,
      idx: skill.idx,
      refId: skill.refId,
      cardType: CardType.skill,
      importance:
        criteriaImportanceOptions[
          skill.importance as SearchCriteriaImportanceEnum
        ],
      list,
      status: primaryItem.status,
      exclude: false,
    };
  });

  const excludeSkills: CandidateExcludeSkillData[] = (
    searchSkills?.excludeList || []
  ).map((skill: SearchSkillListResponse) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchSkillListResponse,
      CandidateCriteriaListItem
    >(getSkillItem, skill);

    return {
      id: `exclude-${skill.idx}`,
      idx: skill.idx,
      refId: skill.refId,
      cardType: CardType.skill,
      importance:
        criteriaImportanceOptions[
          skill.importance as SearchCriteriaImportanceEnum
        ],
      list,
      status: primaryItem.status,
      exclude: true,
    };
  });

  return [...includeSkills, ...excludeSkills];
};
