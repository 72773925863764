import React, { useEffect, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Button, SvgIcon, UIText } from '@air/components';
import { EmailSectionT } from 'domain/EmailConfig/EmailTemplates';
import * as phrases from 'constants/phrases';
import { useOutsideClick } from '@air/utils/hooks';
import { EmailEditor } from 'features/EmailConfigSection/EmailBuilder/EmailEditor/EmailEditor';

import styles from './EditableEmailTemplateSection.css';
import emailBuilderStyles from 'features/EmailConfigSection/EmailBuilder/EmailBuilder.css';

export type EditableEmailTemplateSectionT = {
  section: EmailSectionT;
  removeSection: () => void;
  shouldBeFocused?: boolean;
  name: string;
  readOnly?: boolean;
};

enum TABS {
  main = 'main',
  alternative = 'alternative',
}

export const EditableEmailTemplateSection: React.FC<
  EditableEmailTemplateSectionT
> = ({ section, removeSection, name, readOnly = false }) => {
  const { watch } = useFormContext();
  const emailSection = watch(name);

  const [selectedTab, selectTab] = useState(() => {
    return !section.content && section.alternativeContent
      ? TABS.alternative
      : TABS.main;
  });

  const [isFocused, setSectionFocus] = useState(false);

  useEffect(() => {
    /* If we receive a section with no main/alternative content,
       or user leaves empty main/alternative section tab while having main/alternative tab filled,
       we should switch to main/alternative tab. */
    if (!isFocused && emailSection) {
      if (!emailSection.content && emailSection.alternativeContent) {
        selectTab(TABS.alternative);
      } else if (emailSection.content && !emailSection.alternativeContent) {
        selectTab(TABS.main);
      }
    }
  }, [emailSection, isFocused]);

  const allowedPlaceholders = useMemo(() => {
    const placeholders = (section.placeholders || []).map(
      (item) => item.displayName
    );
    return [...placeholders, phrases.EMAIL_TEMPLATE_CONTENT_PLACEHOLDER];
  }, [section?.placeholders]);

  const onOutsideSectionClick = useCallback(
    (e) => {
      // @TODO: Think how to refactor this,

      /* There can be many editable template sections on a page and each one triggers its own outside click
       * For example, if there are 3 sections, and we click inside 1st one there will be 2 outside clicks
       * If we click outside any of those 3 sections there will be 3 outside clicks
       *  */
      if (
        e.target.nodeName === 'SPAN' &&
        allowedPlaceholders.includes(e.target.innerHTML)
      ) {
        // If no sections are in focus, do nothing. There are no changes to save.
        // Click inside contenteditable on placeholders is considered an outside click, ignore it.
        return;
      }
      if (isFocused) {
        /*
          Here we check where the outside click was.

          If user is currently focused in an empty EmailEditor, they can click on section buttons
          on the left to replace current empty section with another one. This case is handled in <EmailBuilder>
          component, so we just remove focus flag and change tab to default.

          Otherwise, a click outside of empty focused section should remove it from the email.
        */
        const sectionButtonClicked = !!e.target.closest(
          `.${emailBuilderStyles.sectionSelectionButton}`
        );

        const sectionHasNoContent =
          !emailSection.content && !emailSection.alternativeContent;

        if (sectionHasNoContent && (!isFocused || !sectionButtonClicked)) {
          removeSection();
        }
        setSectionFocus(false);
      }
    },
    [removeSection, emailSection, isFocused, allowedPlaceholders]
  );

  /*
      Capturing outside click here to correctly remove focus from the section,
      in the scenario where it gets replaced with a new one if user clicks on
      a section button from the list.
  */
  const [outsideSectionClickRef] = useOutsideClick(onOutsideSectionClick, {
    useCapture: true,
  });

  /*
    Email Editor can display a value from 1 of 2 available sections fields:
    `content` or `alternativeContent`, depending on which tab is selected at the moment.
   */
  const isMainTab = selectedTab === TABS.main;
  const editorValuePath = `${name}.${
    isMainTab ? 'content' : 'alternativeContent'
  }`;

  return (
    <div
      className={classNames(styles.editableEmailTemplateSection, {
        [styles.isFocused]: isFocused,
        [styles.isEditMode]: true,
      })}
      ref={outsideSectionClickRef}
    >
      <div
        className={classNames(styles.tabs, {
          [styles.hasOneTab]: !section.hasAlternative,
        })}
      >
        <div
          className={classNames(styles.tab, {
            [styles.selected]: isMainTab,
          })}
          onClick={() => selectTab(TABS.main)}
        >
          <UIText tiny bold>
            {section.displayName}
          </UIText>
        </div>
        {section.hasAlternative && (
          <div
            className={classNames(styles.tab, styles.alternative, {
              [styles.selected]: !isMainTab,
            })}
            onClick={() => selectTab(TABS.alternative)}
          >
            <UIText tiny bold>
              {phrases.EMAIL_SECTION_TAB_ALTERNATIVE}
            </UIText>
          </div>
        )}
      </div>

      <EmailEditor
        // We remount the editor on tab change to correctly update its internal
        // value from form state, otherwise it doesn't track the name change
        // and keeps previous value in other tab.
        // We also remount it if empty section is replaced with another one.
        key={`${editorValuePath}-${section.type}`}
        name={editorValuePath}
        placeholders={isMainTab ? section.placeholders : []}
        isFocused={isFocused}
        onFocus={() => setSectionFocus(true)}
        disabled={readOnly}
      />

      {!readOnly && (
        <Button
          variant={Button.variants.INLINE}
          className={styles.removeButton}
          onClick={() => {
            removeSection();
          }}
        >
          <SvgIcon icon="close-icon" className={styles.icon} />
        </Button>
      )}
    </div>
  );
};
