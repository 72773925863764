import R from '@air/third-party/ramda';
import { BaseCriteriaData } from 'domain/SearchCriteria';
import {
  SearchCertificateList,
  SearchCompanyExperienceItem,
  SearchCriteriaMatchStatusEnum,
  SearchExperience,
  SearchManagerialExperience,
  SearchProfessionalExperience,
  SearchSkillListResponse,
  SearchTitleExperience,
  MatchResolution,
  SearchIndustryExperienceItem,
  CompanyExtendedType,
  SearchLocationResponse,
} from '@air/api';
import { AlertData } from '@air/domain/Alerts/AlertData';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';

export interface BaseCandidateData extends BaseCriteriaData {
  /**
   * status is used for lineup cells, therefore should
   * be defined for every CandidateData type
   */
  status: SearchCriteriaMatchStatusEnum;
  refId: string;
}

export type CandidateExperience = {
  years?: number;
  months?: number;
  approx?: boolean;
} | null;

type CriteriaWithInterview = {
  interview: InterviewData;
};

export type CandidateCriteriaListItem<
  T extends { [key: string]: any } = unknown
> = {
  id: string | number;
  label: string;
  alert: AlertData | null;
  status: SearchCriteriaMatchStatusEnum;
  experience?: CandidateExperience;
  matchResolution?: MatchResolution;
  deducedFrom?: string[];
  distanceToCriteriaLocation?: number;
  type?: CompanyExtendedType;
  minSize?: number;
  maxSize?: number;
} & CriteriaWithInterview &
  T;

export type CandidateEducationCriteriaListItem<
  T extends { [key: string]: any } = unknown
> = {
  id: string | number;
  label: string;
  alert: AlertData | null;
  status: SearchCriteriaMatchStatusEnum;
  interview: InterviewData;
} & T;

type ApiListResponse =
  | SearchSkillListResponse
  | SearchCertificateList
  | SearchTitleExperience
  | SearchCompanyExperienceItem
  | SearchIndustryExperienceItem
  | SearchLocationResponse;

export const sortByPrimaryItem = ({
  refId,
  list,
}:
  | SearchSkillListResponse
  | SearchCertificateList
  | SearchTitleExperience
  | SearchCompanyExperienceItem
  | SearchIndustryExperienceItem
  | SearchLocationResponse) => {
  if (refId) {
    return R.flatten(R.partition(R.propEq('refId', refId), list));
  }
  return list;
};

export const getExperience = <
  T extends { years: number; months: number; approx?: boolean }
>(
  listItem: T
): CandidateExperience => {
  const { years, months, approx } = listItem;
  if (years || months) {
    return { years, months, approx };
  }
  return null;
};

type ExperienceType<S> = S extends 'title'
  ? SearchTitleExperience & { idx: number }
  : S extends 'managerial'
  ? SearchManagerialExperience & { idx: number }
  : S extends 'professional'
  ? SearchProfessionalExperience & { idx: number }
  : never;

export const filterExperienceByType =
  <T>(type: 'title' | 'managerial' | 'professional') =>
  (values: SearchExperience[]): ExperienceType<T>[] => {
    return R.reduce<SearchExperience, ExperienceType<T>[]>(
      (result, experience) => {
        if (experience[type]) {
          result.push({
            ...experience[type],
            idx: experience.idx,
          } as ExperienceType<T>);
        }
        return result;
      },
      []
    )(values);
  };

export const getCriteriaItems = <
  T extends ApiListResponse,
  S extends CandidateCriteriaListItem = CandidateCriteriaListItem
>(
  getItemFn: (a: T['list'][0]) => S,
  criterion: T
): [S[], S] => {
  const list = R.compose<any[], any, any>(
    R.map(getItemFn),
    sortByPrimaryItem
  )(criterion);
  const [primaryItem] = list;

  return [list, primaryItem];
};
