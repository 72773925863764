import { compose, assocPath, tap } from 'ramda';
import querystring from 'query-string';
import { httpTask, authTask } from '@air/utils/api';
import { localStore, ACCESS_TOKEN } from '@air/domain/WebStorage/webStorage';
import { ApiErrorResponse } from '@air/api/models';

export type QueryParamsT = { [key: string]: any } & {
  page?: number;
  size?: number;
  sort?: string | string[];
};

export type RetryOptionsT = {
  count?: number;
  delay?: number; // in milliseconds
};

export type RequestParamsT = {
  url: string;
  init: RequestInit;
  mock: any;
  mockStatus: any;
  xhrActions?: object;
  retry?: RetryOptionsT;
};

export type RequestModelT = {
  params: RequestParamsT;
};

export type ApiErrorResponseWithStatusT = ApiErrorResponse & { status: number };

const initialParams: any = {
  url: '',
  init: {
    headers: new Headers(),
  },
  mock: null,
};

class RequestModel {
  params: RequestParamsT;

  constructor(params: any) {
    this.params = params;
  }

  bind = (fn: any) => {
    return new RequestModel(fn(this.params));
  };

  run = () => {
    return fetch(this.params.url, this.params.init);
  };

  toTask = () => {
    return httpTask(this.params.url, {
      ...this.params.init,
      ...(this.params.retry ? { retry: this.params.retry } : {}),
      mock: this.params.mock,
      mockStatus: this.params.mockStatus || 200,
      xhrActions: this.params.xhrActions,
    });
  };

  toAuthTask = () => {
    return authTask(this.params.url, this.params.init);
  };
}

export const setMethod = assocPath(['init', 'method']);
export const setUrl = assocPath(['url']);
export const setMock = assocPath(['mock']);
export const setMockStatus = assocPath(['mockStatus']);

const createRequestByMethod =
  (method: string) => (url: string, xhrActions?: object) =>
    new RequestModel({ ...initialParams, xhrActions })
      .bind(setMethod(method))
      .bind(setUrl(url))
      .bind(withAuthHeaders)
      .bind(withTracingHeader);

export const head = createRequestByMethod('HEAD');
export const get = createRequestByMethod('GET');
export const post = createRequestByMethod('POST');
export const put = createRequestByMethod('PUT');
export const remove = createRequestByMethod('DELETE');
export const patch = createRequestByMethod('PATCH');

export const withJsonBody = (body: any) =>
  compose(
    assocPath(['init', 'body'], JSON.stringify(body)),
    tap((params: RequestParamsT) => {
      (params.init.headers as Headers).set('Content-Type', 'application/json');
    })
  );

export const withFormMultipartBody = (body: any) => {
  return compose(
    assocPath(['init', 'body'], body),
    tap((params: RequestParamsT) => {
      (params.init.headers as Headers).set('Accept', 'application/json');
      // removing Content-Type so that the browser can add it automatically with the needed boundary
      // this is needed to be able to send form data correctly
      (params.init.headers as Headers).delete('Content-Type');
    })
  );
};

export const withTextBody = (body: string) => {
  return compose(
    assocPath(['init', 'body'], body),
    tap((params: RequestParamsT) => {
      (params.init.headers as Headers).set('Accept', 'application/json');
      (params.init.headers as Headers).set('Content-Type', 'text/plain');
    })
  );
};

function withAuthHeaders(params: RequestParamsT) {
  const accessToken = localStore.getItem(ACCESS_TOKEN);
  if (accessToken) {
    (params.init.headers as Headers).set(
      'Authorization',
      `Bearer ${accessToken}`
    );
  } else {
    /*
      AR-5516: Since Headers object is mutable, it can contain outdated bearer
      token, so we should clear existing Authorization header for
      subsequent auth attempts, if there's no access token in local
      storage (i.e. after logout).
    */
    (params.init.headers as Headers).delete('Authorization');
  }
  return params;
}

function generateTraceId() {
  return Number(performance.now()).toString(32);
}

function withTracingHeader(params: RequestParamsT) {
  (params.init.headers as Headers).set('X-TRACE-ID', generateTraceId());
  return params;
}

export const withQueryParams =
  (queryParams: QueryParamsT) => (params: RequestParamsT) => {
    return setUrl(
      `${params.url}?${querystring.stringify(queryParams)}`,
      params
    );
  };

/**
 *
 * EXAMPLE: how to use withMockData()
 export const publishChanges = <B>(
   body: B,
   apiEndpoint: string
 ): Task<DictionaryUpsertResponse> => {
  return Http
    .put(apiEndpoint)
    .bind(Http.withMockData({ mock: YOUR_MOCK })) instead of .bind(Http.withJsonBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
*/
export const withMockData =
  (mock: any, mockStatus = 200) =>
  (params: RequestParamsT) => ({ ...params, mock, mockStatus });

export const withRetry =
  (retryOptions: RetryOptionsT) => (params: RequestParamsT) => ({
    ...params,
    retry: retryOptions,
  });
