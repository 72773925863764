import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { RouteComponentProps, withRouter, Route } from 'react-router-dom';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

import {
  Drafts,
  Interview,
  Closed,
  Jobs,
  Paused,
  TrialSection,
  HelpCenter,
  SectionPanel,
  Onboarding,
  ChangeBackgroundView,
  SettingsSection,
} from 'features';

import { KanbanContextT, CustomerProfileContextT } from 'context';
import {
  customerProfileSelectors,
  kanbanSelectors,
  jobsProviderSelectors,
} from 'selectors';
import {
  useKanbanContext,
  useSelectInterviewKanbanContext,
} from 'providers/KanbanProvider';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import {
  DEFAULT_SECTIONS_OPEN_STATE,
  doesKanbanHaveATSJobs,
  SECTIONS_NAMES,
} from 'domain/Kanban/Kanban';
import { showTrialSection } from 'domain/Trial/Trial';
import { Button, CanAccess, ProtectedRoute, useRole } from '@air/components';
import { KanbanSection } from './KanbanSection/KanbanSection';
import { KanbanFiltersToolbar, RightSidebar } from 'components';

import { GA_LABEL_START_NEW_DRAFT } from 'constants/gaLabels';
import * as sharedPhrases from '@air/constants/phrases';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';
import { isFirefox } from '@air/utils/mobile';

import { CSSTransition } from 'react-transition-group';
import styles from './Kanban.css';
import fadeTransition from '@air/styles/transitions/fade.css';
import { KanbanSectionNames, useUserSettings } from 'hooks/useUserSettings';
import { subscribe, emit } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';
import sharedStyles from '@air/styles/index.css';
import {
  useJobsProviderContext,
  useJobsProviderMethods,
} from 'providers/JobsProvider';
import { RoleName } from '@air/api';
import { UserManagementProvider } from 'providers/UserManagementProvider';

export type KanbanProps = Pick<KanbanContextT, 'currentSearch'> &
  RouteComponentProps & {
    isSelectMode?: boolean;
    selectModeTargetId?: string | number;
    isLandingMounted: boolean;
  };

const KanbanCardsSkeleton = () => {
  return (
    <div className={styles.kanbanSkeletonWrapper}>
      {R.map(
        (index) => (
          <div key={index} className={styles.kanbanSkeletonCard}>
            <div
              className={classNames(
                styles.kanbanSkeletonRunner,
                sharedStyles.loadingBox
              )}
            />
            <div
              data-testid="kanban-skeleton-card"
              className={classNames(
                styles.kanbanSkeletonRunner,
                sharedStyles.loadingBox
              )}
            />
          </div>
        ),
        R.range(0, 3)
      )}
    </div>
  );
};

const KanbanComponent: React.FC<KanbanProps> = (props) => {
  const {
    isSelectMode,
    selectModeTargetId,
    history,
    location,
    match,
    isLandingMounted,
  } = props;
  const sectionProps = {
    isSelectMode,
    selectModeTargetId,
  };

  const currentRoute = location?.pathname;
  const isKanbanPanelOpened = urls.KANBAN_PANELS_ROUTES.includes(currentRoute);
  const isCollapsed = !match?.isExact && !isKanbanPanelOpened;

  const jobsList = useJobsProviderContext(jobsProviderSelectors.jobsList);
  const { fetchJobsList } = useJobsProviderMethods();
  const kanbanContext = isSelectMode
    ? useSelectInterviewKanbanContext
    : useKanbanContext;
  const draftsList = kanbanContext(kanbanSelectors.draftsList);
  const interviewsList = kanbanContext(kanbanSelectors.interviewsList);
  const pausedSearchesList = kanbanContext(kanbanSelectors.pausedSearchesList);
  const closedSearchesList = kanbanContext(kanbanSelectors.closedSearchesList);

  const isKanbanLoaded = kanbanContext(kanbanSelectors.isKanbanLoaded);
  const areSearchesLoading = kanbanContext(kanbanSelectors.areSearchesLoading);

  const currentSearchFilterName = kanbanContext(
    kanbanSelectors.currentSearchFilterName
  );
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const ats = useCustomerProfileContext(customerProfileSelectors.ats);
  const atsType = useCustomerProfileContext(customerProfileSelectors.atsType);
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const hasAccessToJobs = doesKanbanHaveATSJobs(user, isStandaloneAtsUser);
  const currentSearchFilterIsOwnedByMe = useKanbanContext(
    kanbanSelectors.currentSearchFilterIsOwnedByMe
  );
  const canAccessUserManagement = useCustomerProfileContext(
    customerProfileSelectors.canAccessUserManagement
  );
  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );
  const licenseType = useCustomerProfileContext(
    customerProfileSelectors.licenseType
  );
  const { getSection, saveSectionState } = useUserSettings(customerId);

  const [expanded, setExpanded] = useState(false);

  const SECTIONS_COUNTERS = useMemo(
    () => ({
      [phrases.KANBAN_SECTION_JOBS]: jobsList?.total,
      [phrases.KANBAN_SECTION_DRAFTS]: draftsList?.total,
      [phrases.KANBAN_SECTION_SCREENING]: interviewsList?.total,
      [phrases.KANBAN_SECTION_PAUSED]: pausedSearchesList?.total,
      [phrases.KANBAN_SECTION_CLOSED]: closedSearchesList?.total,
    }),
    [
      jobsList?.total,
      draftsList?.total,
      interviewsList?.total,
      pausedSearchesList?.total,
      closedSearchesList?.total,
    ]
  );

  const hasNoExternalAtsJobs =
    !hasAccessToJobs || (!jobsList.items.length && jobsList.loaded);

  const userHasNoJobs =
    hasNoExternalAtsJobs &&
    !draftsList.items.length &&
    draftsList.loaded &&
    !interviewsList.items.length &&
    interviewsList.loaded &&
    !pausedSearchesList.items.length &&
    pausedSearchesList.loaded &&
    !closedSearchesList.items.length &&
    closedSearchesList.loaded;

  const [sectionsOpenState, setSectionsOpenState] = useState(
    DEFAULT_SECTIONS_OPEN_STATE
  );

  useEffect(() => {
    const updatedSectionsOpenState = SECTIONS_NAMES.reduce(
      (acc, sectionName) => {
        const storedSectionState = getSection(
          sectionName as KanbanSectionNames
        )?.isOpen;

        // draft section must be opened when there are no jobs at all - it displays info panel
        const keepOpen =
          sectionName === phrases.KANBAN_SECTION_DRAFTS ? userHasNoJobs : false;

        const isOpen =
          keepOpen ||
          (!R.isNil(storedSectionState)
            ? storedSectionState
            : !!SECTIONS_COUNTERS[sectionName]);

        acc[sectionName] = isOpen;
        return acc;
      },
      {} as {
        [key in KanbanSectionNames]: boolean;
      }
    );

    setSectionsOpenState(updatedSectionsOpenState);
  }, [userHasNoJobs, getSection, SECTIONS_COUNTERS]);

  const toggleSection = useCallback(
    (sectionName: KanbanSectionNames) => {
      setSectionsOpenState((state) => {
        !isSelectMode && saveSectionState(sectionName, !state[sectionName]);

        return { ...state, [sectionName]: !state[sectionName] };
      });
    },
    [isSelectMode, saveSectionState]
  );

  useEffect(() => {
    // show kanban after ats & user are loaded
    if (!R.isNullOrEmpty(user) && atsType) {
      setExpanded(!isCollapsed);
    }
  }, [user, atsType, isCollapsed]);

  const createNewDraftCb = useCallback(() => {
    if (!ats?.dataSourceId) {
      return;
    }
    trackEvent({
      category: GACategory.DraftPage,
      label: GA_LABEL_START_NEW_DRAFT,
    });
    emit(APP_EVENTS.OPEN_JOB_PANEL);
  }, [ats?.dataSourceId]);

  const showNoJobsPanel =
    draftsList.loaded &&
    userHasNoJobs &&
    isStandaloneAtsUser &&
    !currentSearchFilterName &&
    !currentSearchFilterIsOwnedByMe;

  const AddNewDraftButton = () => {
    return (
      <Button
        variant={Button.variants.POSITIVE_CONFIRM}
        className={classNames(styles.addNewDraftButton, {
          [styles.disabled]: isTrialExpired,
        })}
        disabled={isTrialExpired}
        onClick={createNewDraftCb}
        small
      >
        {phrases.NEW_REQUISITION_START_BUTTON_ONE_LINE}
      </Button>
    );
  };

  useEffect(() => {
    // fetch jobs list when we land on kanban or filter input is updated
    if (match?.isExact && hasAccessToJobs && !isSelectMode) {
      fetchJobsList({ page: 0 });
    }
  }, [
    match?.isExact,
    fetchJobsList,
    currentSearchFilterName,
    hasAccessToJobs,
    isSelectMode,
  ]);

  useEffect(() => {
    const unsubscribeOpenSection = subscribe(
      APP_EVENTS.CHANGE_SECTION_STATE,
      ({
        sectionName,
        isOpen,
      }: {
        sectionName: KanbanSectionNames;
        isOpen: boolean;
      }) => {
        setSectionsOpenState((state) => {
          saveSectionState(sectionName, isOpen);

          return { ...state, [sectionName]: isOpen };
        });
      }
    );
    return unsubscribeOpenSection;
  }, [saveSectionState]);

  /*
    Trial logic
   */
  const showTrial = showTrialSection(licenseType);

  const hasRole = useRole(user?.role);

  return (
    <>
      <div
        data-testid="kanban"
        className={classNames(styles.kanban, {
          [styles.isMounted]: isLandingMounted && expanded,
          [styles.noAnimation]: isKanbanPanelOpened || isSelectMode,
          [styles.scaled]: !expanded && isLandingMounted && !isSelectMode,
          [styles.firefox]: isFirefox,
          [styles.isLoading]: areSearchesLoading && isKanbanLoaded,
        })}
      >
        {!R.isNullOrEmpty(user) && match.isExact && (
          <Onboarding closeButtonText={sharedPhrases.BUTTON_START} />
        )}
        <CSSTransition
          appear
          in={!isCollapsed}
          classNames={fadeTransition}
          timeout={500}
          unmountOnExit
        >
          <KanbanFiltersToolbar
            isSelectMode={isSelectMode}
            selectModeTargetId={selectModeTargetId}
            className={styles.kanbanFiltersToolbarWrapper}
          >
            {isStandaloneAtsUser && !isSelectMode && <AddNewDraftButton />}
          </KanbanFiltersToolbar>
        </CSSTransition>

        <div className={styles.sections}>
          <CanAccess hasAccess={hasAccessToJobs && !isSelectMode}>
            <KanbanSection
              testId="kanban-jobs-section"
              title={phrases.KANBAN_SECTION_JOBS}
              counter={jobsList?.total}
              isOpen={
                !jobsList.loaded ||
                sectionsOpenState[phrases.KANBAN_SECTION_JOBS]
              }
              toggleSection={toggleSection}
              showExpandStats={!isSelectMode && jobsList.loaded}
              showCounter={jobsList.loaded}
            >
              {jobsList.loaded ? <Jobs /> : <KanbanCardsSkeleton />}
            </KanbanSection>
          </CanAccess>
          <KanbanSection
            testId="kanban-drafts-section"
            title={phrases.KANBAN_SECTION_DRAFTS}
            counter={draftsList?.total}
            isOpen={
              !draftsList.loaded ||
              sectionsOpenState[phrases.KANBAN_SECTION_DRAFTS]
            }
            toggleSection={
              userHasNoJobs || !draftsList?.total ? R.noop : toggleSection
            }
            showExpandStats={!isSelectMode && draftsList.loaded}
            showCounter={draftsList.loaded}
          >
            {draftsList.loaded ? (
              <Drafts {...sectionProps}>
                {showNoJobsPanel && (
                  <SectionPanel
                    className={styles.draftSectionPanelWrapper}
                    title={phrases.KANBAN_NO_RESULTS}
                    description={phrases.KANBAN_NO_RESULTS_DESCRIPTION}
                  >
                    <AddNewDraftButton />
                  </SectionPanel>
                )}
              </Drafts>
            ) : (
              <KanbanCardsSkeleton />
            )}
          </KanbanSection>
          <KanbanSection
            testId="kanban-interview-section"
            title={phrases.KANBAN_SECTION_SCREENING}
            counter={interviewsList?.total}
            isOpen={
              !interviewsList.loaded ||
              sectionsOpenState[phrases.KANBAN_SECTION_SCREENING]
            }
            toggleSection={toggleSection}
            showExpandStats={!isSelectMode && interviewsList.loaded}
            showCounter={interviewsList.loaded}
          >
            {interviewsList.loaded ? (
              <Interview {...sectionProps} />
            ) : (
              <KanbanCardsSkeleton />
            )}
          </KanbanSection>
          <KanbanSection
            testId="kanban-paused-section"
            title={phrases.KANBAN_SECTION_PAUSED}
            counter={pausedSearchesList?.total}
            isOpen={
              !pausedSearchesList.loaded ||
              sectionsOpenState[phrases.KANBAN_SECTION_PAUSED]
            }
            toggleSection={toggleSection}
            showExpandStats={!isSelectMode && pausedSearchesList.loaded}
            showCounter={pausedSearchesList.loaded}
          >
            {pausedSearchesList.loaded ? (
              <Paused {...sectionProps} />
            ) : (
              <KanbanCardsSkeleton />
            )}
          </KanbanSection>
          <KanbanSection
            testId="kanban-closed-section"
            title={phrases.KANBAN_SECTION_CLOSED}
            counter={closedSearchesList?.total}
            isOpen={
              !closedSearchesList.loaded ||
              sectionsOpenState[phrases.KANBAN_SECTION_CLOSED]
            }
            toggleSection={toggleSection}
            showExpandStats={!isSelectMode && closedSearchesList.loaded}
            showCounter={closedSearchesList.loaded}
          >
            {closedSearchesList.loaded ? (
              <Closed {...sectionProps} />
            ) : (
              <KanbanCardsSkeleton />
            )}
          </KanbanSection>
        </div>
        {showTrial && <TrialSection className={styles.kanbanTrialSection} />}
      </div>
      {!isCollapsed && isLandingMounted && (
        <RightSidebar
          isOpened={isKanbanPanelOpened}
          closePanel={() => history.push(urls.ROOT_ROUTE)}
          className={styles.kanbanPanel}
          variant={RightSidebar.variants.OverlapView}
          transition={RightSidebar.transitions.SlideFromRight}
        >
          <Route path={urls.HELP_CENTER_ROUTE}>
            <HelpCenter />
          </Route>
          <Route path={urls.WALLPAPERS_ROUTE}>
            <ChangeBackgroundView />
          </Route>
          <ProtectedRoute
            isAllowed={hasRole(RoleName.CUSTOMERADMIN)}
            redirect={urls.ROOT_ROUTE}
            path={urls.SETTINGS_ROUTE}
            render={(props: RouteComponentProps) => (
              <CanAccess<CustomerProfileContextT>
                hasAccess={canAccessUserManagement}
              >
                <UserManagementProvider {...props}>
                  <SettingsSection isReadOnly={isTrialExpired} {...props} />
                </UserManagementProvider>
              </CanAccess>
            )}
          />
        </RightSidebar>
      )}
    </>
  );
};

export const Kanban = withRouter(KanbanComponent);

export const emptyCounters = {
  totalCount: 0,
  selectedCount: 0,
  matchedCount: 0,
  pendingCount: 0,
  rejectedCount: 0,
};
