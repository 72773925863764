import React, { ReactNode } from 'react';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { Card } from '@air/components';

import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import {
  getCardListItemTooltip,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import { SearchCriteriaData } from 'domain/SearchCriteria';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

export const LineupCollapsedCard: React.FC<{
  cardData: SearchCriteriaData;
  importance?: SearchCriteriaImportanceEnum;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  title: string;
  footerText?: Array<string>;
  cardLabel?: string;
  titleFirstLabel?: string;
  titleSecondLabel?: ReactNode;
  className?: string;
  stackClasses: string[];
  stackSize: number;
  showImportance?: boolean;
}> = ({
  cardData,
  onClick,
  onMouseEnter,
  onMouseLeave,
  title,
  footerText = [],
  cardLabel = '',
  titleFirstLabel = '',
  titleSecondLabel,
  className = '',
  stackSize,
  stackClasses,
  importance = null,
  showImportance = true,
}) => {
  const mainCard = getMainCard(cardData);
  return (
    <Card.SearchCriteriaCardContainer
      className={className}
      importance={importance}
      onClick={onClick}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <>
        {!!cardLabel && (
          <Card.SearchCriteriaCardLabel
            onClick={onClick}
            text={cardLabel}
            showExplanationTooltip={stackSize > 0}
          />
        )}
        <Card.SearchCriteriaCardViewBackground>
          <Card.SearchCriteriaCardContent onClick={onClick}>
            {!!titleFirstLabel && <Card.TitleLabel text={titleFirstLabel} />}
            {titleSecondLabel}
            <Card.Title
              resizeable
              title={title}
              getTooltipText={getCardListItemTooltip.bind(
                null,
                cardData,
                mainCard
              )}
              tooltipProps={cardsConfig[
                cardData.cardType
              ]?.getMainCardTooltipProps?.(mainCard, cardData)}
            />
            {footerText.length > 0 &&
              footerText.map((item, key) => (
                <Card.Footer key={key} text={item} />
              ))}
            {showImportance && (
              <Card.SearchCriteriaImportanceIndicator
                className={cardCommonStyles.importanceSwitch}
                importance={importance}
              />
            )}
          </Card.SearchCriteriaCardContent>
          {stackSize > 0 && (
            <Card.SearchCriteriaStackToggle
              onClick={onClick}
              stackSize={stackSize}
              stackClasses={stackClasses}
            />
          )}
        </Card.SearchCriteriaCardViewBackground>
      </>
    </Card.SearchCriteriaCardContainer>
  );
};
