/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  type?: PhoneNumberTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  state?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PhoneNumberTypeEnum {
  Mobile = 'mobile',
  HomePhone = 'home_phone',
  HomeFax = 'home_fax',
  Unknown = 'unknown',
}
