import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  getCriteriaItems,
  getExperience,
} from 'domain/CandidateData';
import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import { criteriaImportanceOptions } from 'constants/skills';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import {
  SearchCriteriaImportanceEnum,
  IndustryExperienceResult,
  SearchIndustryExperience,
  SearchIndustryExperienceItem,
} from '@air/api';

export interface CandidateExcludeIndustryData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.industry;
  exclude: true;
}

export interface CandidateIncludeIndustryData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.industry;
  exclude: false;
}
export type CandidateIndustryData =
  | CandidateIncludeIndustryData
  | CandidateExcludeIndustryData;

const getIndustryItem = (
  listItem: IndustryExperienceResult
): CandidateCriteriaListItem => {
  return {
    id: listItem.name,
    label: listItem.name,
    experience: getExperience(listItem),
    status: listItem.status,
    alert: getAlertData(listItem.alerts),
    matchResolution: listItem.matchResolution,
    interview: interviewDataMapper(listItem.refId, listItem.interview),
  };
};

export const mapIndustries = (
  searchIndustries: SearchIndustryExperience
): CandidateIndustryData[] => {
  const includeIndustries: CandidateIncludeIndustryData[] = (
    searchIndustries?.includeList || []
  ).map((item: SearchIndustryExperienceItem) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchIndustryExperienceItem,
      CandidateCriteriaListItem
    >(getIndustryItem, item);

    return {
      id: `include-${item.idx}`,
      refId: item.refId,
      idx: item.idx,
      cardType: CardType.industry,
      list,
      exclude: false,
      importance: criteriaImportanceOptions[item.importance],
      status: primaryItem.status,
    };
  });

  const excludeIndustries: CandidateExcludeIndustryData[] = (
    searchIndustries?.excludeList || []
  ).map((item: SearchIndustryExperienceItem) => {
    const [list, primaryItem] = getCriteriaItems<
      SearchIndustryExperienceItem,
      CandidateCriteriaListItem
    >(getIndustryItem, item);

    return {
      id: `exclude-${item.idx}`,
      refId: item.refId,
      idx: item.idx,
      cardType: CardType.industry,
      list,
      exclude: true,
      importance:
        criteriaImportanceOptions[
          item.importance as SearchCriteriaImportanceEnum
        ],
      status: primaryItem.status,
    };
  });

  return [...includeIndustries, ...excludeIndustries];
};
