import React from 'react';
import {
  DictionaryIdentifiableItem,
  MatchResolution,
  TitleExtendedItemType,
} from '@air/api';
import { FormSelectEntity } from '@air/components/Select/typings';
import { Card } from '@air/components';
import * as phrases from '@air/constants/phrases';
import { CardCategoryTooltip } from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';
import cardsCommonStyles from '@air/domain/SearchCriteriaCards/cardsCommonStyles.css';
import {
  BaseSearchCriteriaData,
  getUnwantedRoleLabel,
  isExcludeRole,
  isRoleGroupCard,
  RoleCriteriaData,
  SearchCriteriaListValue,
} from 'customer-portal/src/domain/SearchCriteria';
import * as customerPortalPhrases from 'customer-portal/src/constants/phrases';
import { CandidateCriteriaListItem } from 'customer-portal/src/domain/CandidateData';
import cardCommonStyles from 'customer-portal/src/components/Cards/cardsCommonStyles.css';

export type RoleSearchCriteriaListExtras = {
  type: TitleExtendedItemType;
  items: DictionaryIdentifiableItem[];
};

export const getRoleCategoryTooltipProps = (
  categoryItems: { fullName: string }[]
) => {
  return {
    enabled: true,
    tooltip: (
      <CardCategoryTooltip
        items={categoryItems}
        tooltipText={phrases.ROLE_CATEGORY_TOOLTIP_TEXT}
      />
    ),
    containerClassName: cardsCommonStyles.cardCategoryTooltip,
    placement: 'right-start',
  };
};

export const getRoleSelectValueTooltipProps = (
  data: FormSelectEntity<RoleSearchCriteriaListExtras>
) => {
  return data?.extras &&
    data.extras.type === TitleExtendedItemType.TITLECATEGORY
    ? getRoleCategoryTooltipProps(
        data.extras.items as Required<DictionaryIdentifiableItem>[]
      )
    : {};
};

export const getRoleCriteriaCardTitleLabel = (
  cardData: RoleCriteriaData
): React.ReactNode | null => {
  const isRoleGroup = isRoleGroupCard(cardData);
  const isRoleUnwanted = isExcludeRole(cardData);

  if (isRoleGroup && !isRoleUnwanted) {
    return (
      <div className={cardCommonStyles.titleLabel}>
        <Card.TitleLabel text={customerPortalPhrases.GROUP_LABEL} />
      </div>
    );
  }

  return isRoleUnwanted ? (
    <div className={cardCommonStyles.unwantedLabel}>
      #{getUnwantedRoleLabel(cardData)}
    </div>
  ) : null;
};

export const getRoleCriteriaCardStackTitleLabel = (
  stackItemData:
    | SearchCriteriaListValue<{ extras: RoleSearchCriteriaListExtras }>
    | BaseSearchCriteriaData
): string => {
  const titleLabel = isRoleGroupCard(stackItemData)
    ? customerPortalPhrases.GROUP_LABEL
    : null;
  return titleLabel;
};

export const getCandidateRoleCollapsedCardMatchResolutionLabel = (
  cardData: CandidateCriteriaListItem
) => {
  const isGroup = isRoleGroupCard(cardData);

  return {
    [MatchResolution.FOUND]: isGroup
      ? customerPortalPhrases.NOT_FOUND_GROUP_LABEL
      : customerPortalPhrases.NOT_FOUND_LABEL,
    [MatchResolution.RECENT]: isGroup
      ? customerPortalPhrases.NOT_RECENT_GROUP_LABEL
      : customerPortalPhrases.NOT_RECENT_LABEL,
  }[cardData.matchResolution];
};
