import React, { useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import R from '@air/third-party/ramda';
import { DataSourceType } from '@air/api';
import classNames from 'classnames';
import * as phrases from '@air/constants/phrases';
import { FormViewTitle } from 'components/FormView/FormView';
import { FormFieldTypes } from '@air/components/Form/utils';
import { Button, FormField, UIText } from '@air/components';
import { ATSNames } from 'domain/ATS/ATSMetadata';

import styles from './SignupFormSteps.css';
import formViewStyles from 'components/FormView/FormView.css';

type SAPATSSettings = { apiKey: string };
type SAPIntegrationProps = {
  className?: string;
  onConnect: (
    atsType: DataSourceType,
    atsSettings: SAPATSSettings
  ) => Promise<void>;
  onCancel: () => void;
  isConnecting?: boolean;
};
export const SAPIntegrationForm: React.FC<
  SAPIntegrationProps
> = ({ className = '', onConnect, onCancel, isConnecting }) => {
  const {
    register,
    handleSubmit,
    control,
    formState,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
  });
  const formValues = useWatch({ control });

  const hasUsernameError = errors?.username;
  const hasPasswordError = errors?.password;
  const hasCompanyIdError = errors?.company;
  const hasLinkToAPIATSError = errors?.apiUrl;
  const hasLinkToATSError = errors?.adminHost;

  const onSubmit = useCallback(
    (settings: SAPATSSettings) => {
      onConnect(DataSourceType.SAPATS, R.map(R.trim, settings));
    },
    [onConnect]
  );

  return (
    <>
      <Button
        className={styles.backButton}
        variant={Button.variants.TINY}
        icon="collapse"
        onClick={onCancel}
      >
        Back
      </Button>

      <form
        className={classNames(
          styles.createPasswordForm,
          className,
          formViewStyles.loginForm
        )}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <UIText small bold className={formViewStyles.supHeader}>
          {phrases.getSignupStep(3)}
        </UIText>
        <FormViewTitle
          title={phrases.getATSIntergrationFormTitle(
            ATSNames[DataSourceType.SAPATS]
          )}
          description={phrases.SAP_ATS_INTEGRATION_FORM_DESCRIPTION}
        />

        <FormField
          id="username"
          type={FormFieldTypes.text}
          {...register('username', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={!!formValues.username}
          hasError={hasUsernameError}
          isEmpty={R.isNullOrEmpty(formValues.username)}
          label={
            errors?.username?.message ?? phrases.SAP_USERNAME_FIELD_LABEL
          }
          placeholder={null}
        />

        <FormField
          id="username"
          type={FormFieldTypes.password}
          {...register('password', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={!!formValues.password}
          hasError={hasPasswordError}
          isEmpty={R.isNullOrEmpty(formValues.password)}
          label={
            errors?.password?.message ?? phrases.SAP_PASSWORD_FIELD_LABEL
          }
          placeholder={null}
        />

        <FormField
          id="company"
          type={FormFieldTypes.text}
          {...register('company', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={!!formValues.company}
          hasError={hasCompanyIdError}
          isEmpty={R.isNullOrEmpty(formValues.company)}
          label={
            errors?.company?.message ?? phrases.SAP_COMPANY_ID_FIELD_LABEL
          }
          placeholder={null}
        />

        <FormField
          id="apiUrl"
          type={FormFieldTypes.text}
          {...register('apiUrl', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={!!formValues.apiUrl}
          hasError={hasLinkToAPIATSError}
          isEmpty={R.isNullOrEmpty(formValues.apiUrl)}
          label={
            errors?.apiUrl?.message ?? phrases.SAP_LINK_TO_API_FIELD_LABEL
          }
          placeholder={null}
        />

        <FormField
          id="adminHost"
          type={FormFieldTypes.text}
          {...register('adminHost', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={!!formValues.adminHost}
          hasError={hasLinkToATSError}
          isEmpty={R.isNullOrEmpty(formValues.adminHost)}
          label={
            errors?.adminHost?.message ?? phrases.SAP_LINK_TO_ATS_FIELD_LABEL
          }
          placeholder={null}
        />

        <div className={formViewStyles.formFieldExtra}>
          <Button
            type="submit"
            variant={Button.variants.POSITIVE_CONFIRM}
            className={classNames(
              styles.nextStepButton,
              formViewStyles.actionButton
            )}
            disabled={!formState.isValid}
            isLoading={isConnecting}
            loaderPosition={Button.loaderPosition.LEFT}
          >
            {isConnecting
              ? phrases.ATS_CONNECTION_PLACEHOLDER
              : phrases.ATS_CONNECT_BUTTON}
          </Button>
        </div>
      </form>
    </>
  );
};
SAPIntegrationForm.displayName = 'SAPIntegrationForm';

export const SAPIntegrationSuccess: React.FC<{
  onStart: () => void;
}> = ({ onStart }) => {
  return (
    <div>
      <img className={styles.logoImage} src="/assets/images/sap_logo.svg"/>
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_SUCCESS_TITLE}
        description={phrases.getATSIntergrationSuccessDescription(
          ATSNames[DataSourceType.SAPATS]
        )}
      />
      <div className={formViewStyles.formFieldExtra}>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          className={classNames(formViewStyles.actionButton)}
          onClick={onStart}
        >
          {phrases.ATS_INTEGRATION_SUCCESS_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
SAPIntegrationSuccess.displayName = 'SAPIntegrationSuccess';

export const SAPIntegrationFailure: React.FC<{
  onRetry: () => void;
}> = ({ onRetry }) => {
  return (
    <div>
      <img className={styles.logoImage} src="/assets/images/sap_logo.svg"/>
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_FAILURE_TITLE}
        description={phrases.SAP_ATS_INTEGRATION_FAILURE_TITLE}
      />
      <div className={formViewStyles.formFieldExtra}>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          className={classNames(formViewStyles.actionButton)}
          onClick={onRetry}
        >
          {phrases.ATS_INTEGRATION_RETRY_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
SAPIntegrationFailure.displayName = 'SAPIntegrationFailure';
