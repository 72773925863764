import React from 'react';
import classNames from 'classnames';

import styles from './UIText.css';

type UITextProps = {
  className?: string;
  bold?: boolean;
  small?: boolean;
  wide?: boolean;
  tiny?: boolean;
  onClick?: (e: React.MouseEvent) => void;
};
export const UIText: React.FC<UITextProps> = ({
  children,
  className,
  bold,
  small,
  wide,
  tiny,
  onClick,
}) => {
  return (
    <span
      onClick={onClick}
      className={classNames(styles.uiText, className, {
        [styles.bold]: bold,
        [styles.small]: small,
        [styles.wide]: wide,
        [styles.tiny]: tiny,
      })}
    >
      {children}
    </span>
  );
};
UIText.displayName = 'UIText';
