import React from 'react';
import {
  CandidateCriteriaListItem,
  hasUnreadComment,
  hasComments,
  getCardStatus,
  CandidateCriteriaData,
  BaseCandidateData,
} from 'domain/CandidateData';
import {
  getMainCard,
  getCardTitleClassName,
} from 'domain/SearchCriteria/cardHelpers';
import {
  mapCandidateMatchingCardStatusClassName,
  mapCandidateMatchingCardBackgroundClassName,
} from 'components/SearchCards/statusesMapper';
import { Card } from '@air/components';
import classNames from 'classnames';
import styles from './LineupCandidateCard.css';
import {
  getCardInitialSize,
  getFieldsForCandidateCollapsedMainCard,
} from 'domain/CandidateData/candidateCardHelpers';
import { CandidateInterviewNote } from 'components';
import { getCardBackgroundElements } from '../CandidateProfileMatchingCard/CandidateProfileMatchingCard';

export const LineupCandidateCard: React.FC<{
  className?: string;
  cardData: BaseCandidateData;
  invitedForInterview: boolean;
  onClickOnCollapsedStack: (
    cardId: number | string,
    showNote?: boolean
  ) => () => void;
  onClick?: (cardId: number | string, showNote?: boolean) => () => void;
}> = ({ cardData, className, invitedForInterview, onClick = null }) => {
  const mainCard = getMainCard<
    CandidateCriteriaData,
    CandidateCriteriaListItem
  >(cardData);

  const cardStatus = getCardStatus(cardData);

  const hasNoteOnMainCard = hasComments(cardData);

  const { actionButtonLabel } = getCardBackgroundElements(
    cardData,
    invitedForInterview
  );

  return (
    <Card.ResizeableCardWrapper
      resizeable
      defaultSize={getCardInitialSize(cardData)}
    >
      <Card.SearchCriteriaCardContainer
        onClick={onClick(cardData.id)}
        className={classNames(
          className,
          mapCandidateMatchingCardBackgroundClassName(cardStatus)
        )}
      >
        <Card.SearchCriteriaCardViewBackground
          isVisible
          actionButtonLabel={actionButtonLabel}
        >
          <Card.SearchCriteriaCardContent
            className={classNames(
              mapCandidateMatchingCardStatusClassName(cardStatus),
              styles.lineupCandidateCard,
              getCardTitleClassName(cardData.cardType, mainCard)
            )}
          >
            {getFieldsForCandidateCollapsedMainCard(mainCard, cardData)}
            {hasNoteOnMainCard && (
              <CandidateInterviewNote
                isNoteRead={!hasUnreadComment(cardData)}
                isNoteExpanded={false}
                interviewData={mainCard.interview}
                onNoteClick={() => {}}
                onNoteIconClick={onClick(cardData.id, true)}
              />
            )}
          </Card.SearchCriteriaCardContent>
        </Card.SearchCriteriaCardViewBackground>
      </Card.SearchCriteriaCardContainer>
    </Card.ResizeableCardWrapper>
  );
};
LineupCandidateCard.displayName = 'LineupCandidateCard';
