import '@air/wdyr.js';
import React from 'react';
import { Router } from 'react-router-dom';
import { Routes } from 'routes';

import { Toast } from '@air/components/Toast/Toast';
import { ErrorBoundary } from 'components';
import { BasicConfirmationModal } from 'features';
import { history } from '@air/utils/history';
import { CustomerProfileProvider } from 'providers';

export function App() {
  return (
    <Router history={history}>
      <CustomerProfileProvider>
        <Toast />
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </CustomerProfileProvider>
      <BasicConfirmationModal />
    </Router>
  );
}

export default App;
