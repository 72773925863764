import * as ApiModel from '@air/api';
import { Certification } from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  getCriteriaListId,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria/BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

export interface CertificationCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.certification;
  list: SearchCriteriaListValue[];
}

export const isCertification = (
  item: BaseSearchCriteriaData
): item is CertificationCriteriaData => {
  return item.cardType === CardType.certification;
};

export const getCertificationDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.IsNewEdit,
}: AddCriteriaInitials): CertificationCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  initialCardStatus,
  cardType: CardType.certification,
  importance: criteriaImportanceOptions[importance],
  list: [null],
});

export const mapCertificationListToCertificationCriteria = (
  certification: ApiModel.CertificationList
): CertificationCriteriaData => ({
  idx: certification.idx,
  id: getCriteriaListId(certification),
  key: genId(),
  cardType: CardType.certification,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  importance: criteriaImportanceOptions[certification.importance],
  list: certification.list.map((subCertification) => ({
    value: subCertification.id,
    label: subCertification.fullName,
  })),
});

export const mapCertificationCriteriaToCertificationList = (
  certification: CertificationCriteriaData
): ApiModel.CertificationList => ({
  idx: certification.idx,
  list: certification.list.filter(Boolean).map((it) => ({
    ...(it.value !== it.label && {
      id: it.value as number,
    }),
    fullName: it.label,
  })),
  importance: certification.importance.value,
});
export const certificationDataMapper = ({ id, fullName }: Certification) => ({
  value: id,
  label: fullName,
});
