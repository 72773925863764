import { EmailConfigurationContextT } from 'context';

export const emailSectionsList = (state: EmailConfigurationContextT) =>
  state.emailSectionsList;
export const jobEmails = (state: EmailConfigurationContextT) => state.jobEmails;
export const currentEmail = (state: EmailConfigurationContextT) =>
  state.currentEmail;
export const emailTemplates = (state: EmailConfigurationContextT) =>
  state.emailTemplates;
export const preview = (state: EmailConfigurationContextT) => state.preview;
export const recipients = (state: EmailConfigurationContextT) =>
  state.recipients;
