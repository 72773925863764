// imports from vendor deps
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import * as phrases from 'constants/phrases';

// imports from types

// imports from 'components'
import { Header, UIText } from '@air/components';
import { UploadedFile, SvgIcon } from 'components';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
import { getCountMessage } from 'utils/uploads';
// import {} from 'utils'

// imports from styles
import styles from './UploadsList.css';
import { RequisitionFile, UploadStatus } from 'context';
import uploadsListSlideIn from './UploadsListSlideIn.css';

// exports / component definitions

// component proptypes
type Props = {
  className?: string;
  isOpen?: boolean;
  files: RequisitionFile[];
  pendingFilesCount: number;
  totalFilesCount: number;
  isUploadingToServer?: boolean;
  onRemoveApplicant?: (
    atsCandidateId: string,
    fileId: string,
    uploadStatus: UploadStatus
  ) => void;
};

export const UploadsList: React.FC<Props> = ({
  className = '',
  isOpen = false,
  files = [],
  pendingFilesCount,
  totalFilesCount,
  isUploadingToServer,
  onRemoveApplicant,
}) => {
  const shouldShowEmptyState = useMemo(() => !files.length, [files]);

  const AttachmentsIcons = useMemo(() => {
    const attachmentsIconClasses = [styles.firstIcon, styles.secondIcon];
    return (
      <div className={styles.iconsContainer}>
        {attachmentsIconClasses.map((iconClass, index) => (
          <div
            key={index}
            className={classNames(styles.attachmentsIconWrapper, iconClass)}
          >
            <SvgIcon
              className={styles.attachmentsIcon}
              icon="attachment-solid"
            />
          </div>
        ))}
      </div>
    );
  }, []);

  return (
    <CSSTransition
      in={isOpen}
      out={(!isOpen).toString()}
      classNames={uploadsListSlideIn}
      timeout={500}
      unmountOnExit
    >
      <div
        className={classNames(styles.uploadsWrapper, className, {
          [styles.emptyState]: shouldShowEmptyState,
        })}
      >
        <div className={styles.uploads}>
          {shouldShowEmptyState ? (
            <div className={styles.emptyStateContainer}>
              {AttachmentsIcons}
              <Header level={3} className={styles.emptyStateText}>
                {phrases.UPLOADS_EMPTY_STATE_TEXT}
              </Header>
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.title}>
                  <UIText bold small>
                    {phrases.UPLOADS_FILES_TITLE}
                  </UIText>
                </div>
                <UIText className={styles.counter}>
                  {pendingFilesCount > 0
                    ? getCountMessage(pendingFilesCount)
                    : getCountMessage(totalFilesCount)}
                </UIText>
              </div>
              <div
                className={classNames(styles.files, {
                  [styles.disabled]: isUploadingToServer,
                })}
              >
                {files?.map((file) => {
                  return (
                    <UploadedFile
                      file={file}
                      key={file.tempId}
                      onRemoveApplicant={onRemoveApplicant}
                      canRemoveUploadedFiles={true}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

UploadsList.displayName = 'UploadsList';
UploadsList.defaultProps = {};
