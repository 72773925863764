/*
  The idea of this functionality was to provide an ability to reuse variables declared
  in our CSS stylesheets where it could be useful, eg. when setting up CSSTransiton or setTimeout
  based on the timing of `--fast-transition` variable (see _variables.css).
  
  The LEGACY CODE below achieved this goal, but as it turned out – it was super resource-heavy,
  and even worse – this behavior was not visible during the development.

  As it turned out, the production build experienced freezing for up to 5 seconds before the Login
  screen appeared. Due to the heavy operations of reading the information from document's stylesheets.

  The LEGACY part is left as is, because this functionality can be useful, but it needs more investigation
  to find out how to unblock the main thread while the variables map is being prepared.
  Maybe it is possible to move this logic to the build step.

  For now, a simpler approach is used. It leads to code duplication, but there's no many that many
  use cases for this now, and no time left to find a solution, since the project will be closed soon.
*/

export const cssVariables = {
  '--fast-transition': '200ms',
};

/*** LEGACY CODE ***/

// import R from '@air/third-party/ramda';

// const camelCase = (str: string) =>
//   str.replace(/[-_]([\w\d])/g, (m: any) => m[1].toUpperCase());

/*
 Check if the stylesheet is internal or hosted on the current domain.
 If it isn't, attempting to access sheet.cssRules will throw a cross origin error.
 See https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet#Notes

 NOTE: One problem this could raise is hosting stylesheets on a CDN with a
 different domain. Those would be cross origin, so you can't access them.
*/
// const isSameDomain = (styleSheet: StyleSheet) => {
//   // Internal style blocks won't have an href value
//   if (!styleSheet.href) {
//     return true;
//   }

//   return styleSheet.href.includes(window.location.origin);
// };

/*
 Determine if the given rule is a CSSStyleRule
 See: https://developer.mozilla.org/en-US/docs/Web/API/CSSRule#Type_constants
 'any' here, because TS has incorrect DOM typings for stylesheets
*/
// const isStyleRule = (rule: any) => rule.type === 1;

/**
 * Get all custom properties on a page
 * @return { key: value }, where "key" is camelCased name of custom property
 * for easier usage accross the app.
 * ex: '--accent-color' -> accentColor
 */

// export const cssVariables: { [key: string]: string } = {};
// const getCSSCustomProps = () =>
//   // styleSheets is array-like, so we convert it to an array.
//   // Filter out any stylesheets not on this domain
//   [...document.styleSheets]
//     .filter(isSameDomain)
//     .reduce(
//       (finalArr, sheet) =>
//         finalArr.concat(
//           // It's an array-like , so we convert it to an array
//           [...sheet?.cssRules]
//             .filter(isStyleRule)
//             .reduce((propValArr, rule: CSSStyleRule) => {
//               const props = Object.values(rule.style)
//                 .filter(
//                   (propName) =>
//                     R.is(String, propName) && propName.startsWith('--')
//                 )
//                 .map((propName) => [
//                   camelCase(propName.trim().slice(2)),
//                   rule.style.getPropertyValue(propName).trim(),
//                 ]);
//               return [...propValArr, ...props];
//             }, [])
//         ),
//       []
//     )
//     .reduce((acc, [prop, value]) => {
//       acc[prop] = value;
//       return acc;
//     }, cssVariables);

// const pollingId = setInterval(() => {
//   const results = getCSSCustomProps();
//   const hasParsedStyleSheets = Object.keys(results).length > 0;
//   if (hasParsedStyleSheets) {
//     clearInterval(pollingId);
//   }
// }, 200);
