import {
  aggregateTimeout,
  getLastFromBatch,
  MessageConsumer,
  pipeFilters,
} from '@air/lib/server-notifications';
import { EventType, NotificationEvent } from '@air/api';
import {
  candidateDataFilter,
  CandidateDataUpdate,
} from '../CandidateData/CandidateDataServerEvents/CandidateDataServerEvents';
import { MessageFilterFunction } from '@air/lib/server-notifications/MessageFilters';
import { SSEConnectionErrorEvent } from '@air/lib/server-notifications/Connection';

const TIMEOUT = 1000;

export type SearchCounterConsumer = MessageConsumer<
  NotificationEvent,
  NotificationEvent
>;

export type JobDescriptionParsingConsumer = MessageConsumer<
  NotificationEvent,
  NotificationEvent
>;

export type ATSJobsConsumer = MessageConsumer<
  NotificationEvent,
  NotificationEvent[]
>;

/**
 * Events filtering functions, used to define backpressure strategies.
 *
 * For draft section `drop` or `sampling` strategy is applied: when
 * handling SEARCH_APPLIED_COUNT_UPDATED - buffer events into a batch,
 * proceed with the one, drop other events.
 *
 * For interview section, `buffer` strategy is used - buffer all events into a batch,
 * process them at once. Events of different types (SEARCH_APPLIED_COUNT_UPDATED,
 * CANDIDATE_PROFILE_STATUS_UPDATED, SEARCH_CANDIDATE_PROFILES_DROPPED, APPLICANT_SUBMITTED_INTERVIEW)
 * can be triggered together, that's why they should be consumed and processed together
 *
 * For buffering, timeout of 1sec is used - this creates a batch of up to roughly 5-6 different events per search.
 * Keep in mind, if the amount of events in a batch will grow in size, reconsider
 * this strategy to prevent memory overflow.
 *
 * Filter functions can be stateful (for example aggregators which use internal state to accumulate events into a batch),
 * so they are defined here as getter functions.
 */
export const getSearchesEventFilters: () => MessageFilterFunction<
  NotificationEvent,
  NotificationEvent
> = () =>
  pipeFilters<NotificationEvent, NotificationEvent[], NotificationEvent>(
    aggregateTimeout(TIMEOUT),
    getLastFromBatch()
  );

export const getCandidatesEventFilters: () => MessageFilterFunction<
  NotificationEvent,
  CandidateDataUpdate[]
> = () => pipeFilters(aggregateTimeout(TIMEOUT), candidateDataFilter);

/*
  External ATS
 */
const EXTERNAL_ATS_EVENTS = [
  EventType.JOBDESCRIPTIONCREATED,
  EventType.JOBDESCRIPTIONUPDATED,
  EventType.JOBDESCRIPTIONDELETED,
] as const;

type ExternalAtsEvent = { eventType: typeof EXTERNAL_ATS_EVENTS[number] };

export function isATSNofiticationEvent(
  event: NotificationEvent | SSEConnectionErrorEvent
): event is ExternalAtsEvent {
  return EXTERNAL_ATS_EVENTS.includes(
    event.eventType as ExternalAtsEvent['eventType']
  );
}
