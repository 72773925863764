import React, { useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { CustomerBaseResponse } from '@air/api';
import R from '@air/third-party/ramda';
import * as phrases from 'constants/phrases';
import { DATE_FORMAT } from 'constants/app';
import { JobOwnersList } from 'components';
import { AccordionBlock, Paragraph } from '@air/components';
import styles from './RequisitionCreatorDetails.css';

export const RequisitionCreatorBox: React.FC<{
  title: string;
  date: number;
  owners?: string[];
  creator?: string;
  listPrefix?: string;
}> = ({ title, date, owners, creator, listPrefix }) => {
  return (
    <div className={styles.requisitionCreatorsBox}>
      <div className={styles.jobStatus}>
        <Paragraph small>{title}</Paragraph>
      </div>
      <div className={styles.jobSubtitle}>
        {!R.isNullOrEmpty(date) && (
          <Paragraph small>{dayjs(date).format(DATE_FORMAT)}</Paragraph>
        )}
        {creator && (
          <Paragraph small>
            {phrases.REQUISITION_CREATOR_PREFIX} {creator}
          </Paragraph>
        )}
        {!R.isNullOrEmpty(owners) && (
          <JobOwnersList listPrefix={listPrefix} items={owners} />
        )}
      </div>
    </div>
  );
};

export const RequisitionCreatorDetails: React.FC<{
  opened: number;
  owners: string[];
  isStandaloneAtsUser: boolean;
  creator?: CustomerBaseResponse;
  created?: number;
  isBeforeDraftCreation?: boolean;
  className?: string;
}> = ({
  opened,
  owners,
  creator,
  created,
  isBeforeDraftCreation,
  isStandaloneAtsUser,
  className,
}) => {
  const [isAccordionOpened, setAccordionOpened] = useState(false);

  const onAccordionClick = (opened?: boolean) => {
    setAccordionOpened(opened);
  };

  return (
    <div className={classNames(styles.requisitionAuthorWrapper, className)}>
      {isBeforeDraftCreation && !isStandaloneAtsUser && (
        <>
          {opened && (
            <RequisitionCreatorBox
              title={phrases.NEW_REQUISITION_SEARCH_OPENED_STATUS}
              date={opened}
            />
          )}
          {!R.isNullOrEmpty(owners) && <JobOwnersList items={owners} />}
        </>
      )}
      {!isBeforeDraftCreation && created && (
        <RequisitionCreatorBox
          title={phrases.NEW_REQUISITION_SEARCH_CREATED_STATUS}
          date={created}
          owners={[`${creator?.firstName} ${creator?.lastName}`]}
          listPrefix={phrases.REQUISITION_CREATOR_PREFIX}
        />
      )}
      {!isBeforeDraftCreation &&
        !isStandaloneAtsUser &&
        (!R.isNullOrEmpty(owners) || opened) && (
          <AccordionBlock
            title={phrases.ATS_INFO_TITLE}
            disabled={false}
            className={styles.jobDescriptionWrapper}
            onAccordionClick={onAccordionClick}
            isOpened={isAccordionOpened}
            showArrow
          >
            <div className={styles.jobDescription}>
              <RequisitionCreatorBox
                title={phrases.NEW_REQUISITION_SEARCH_OPENED_STATUS}
                date={opened}
                owners={owners}
                listPrefix={phrases.REQUISITION_OWNER_PREFIX}
              />
            </div>
          </AccordionBlock>
        )}
    </div>
  );
};
RequisitionCreatorDetails.displayName = 'RequisitionCreatorDetails';
