import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import * as phrases from 'constants/phrases';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import {
  AddressExtended,
  CandidateProfileContactFormT,
  EnrichedProfileExtended,
} from 'domain/CandidateData/CandidateProfileData';

import {
  Button,
  ConfirmationModal,
  Header,
  Paragraph,
  UIText,
} from '@air/components';

import { ButtonVariants } from '@air/components/Button/Button';
import { EmailsInfo } from './EmailsInfo/EmailsInfo';
import { AddressItem } from './LocationInfo/LocationInfo';
import { PhonesByType } from './PhonesInfo/PhonesInfo';
import { LinkItem } from './LinksInfo/LinksInfo';
import { StringMergedResponse } from '@air/api';
import { isEmailValid } from '@air/utils/strings';
import { useOutsideClick } from '@air/utils/hooks';
import useBeforeUnload from 'react-use/lib/useBeforeUnload';

import styles from './ContactDetails.css';

type CandidateContactDetailsProps = {
  isAddContactsDisabled: boolean;
  saveChanges?: (formValues: CandidateProfileContactFormT) => void;
  enrichedProfile: EnrichedProfileExtended;
  isExternalATS: boolean;
  isTrialExpired: boolean;
  profileId: string;
};

export const CONTACTS_TYPE_EMAILS = 'emails';
export type EmailsFormValues = { emails: { value: string }[] };

const NoContactsSection = ({
  isExternalATS,
  setContactsCreation,
  isAddContactsDisabled,
}: {
  isExternalATS: boolean;
  setContactsCreation: () => void;
  isAddContactsDisabled: boolean;
}) => {
  return (
    <div className={styles.noContactsContainer}>
      <Header level={3} className={styles.header}>
        {phrases.CANDIDATE_PROFILE_NO_CONTACTS_HEADER}
      </Header>
      <Paragraph className={styles.content}>
        {isExternalATS
          ? phrases.CANDIDATE_PROFILE_NO_CONTACTS_TEXT_EXTERNAL_ATS
          : phrases.CANDIDATE_PROFILE_NO_CONTACTS_TEXT_INTERNAL_ATS}
      </Paragraph>
      {!isExternalATS && (
        <Button
          icon="add-stroke"
          className={styles.addContactsButton}
          variant={ButtonVariants.POSITIVE_CONFIRM}
          iconClassName={styles.addContactsButtonIcon}
          onClick={setContactsCreation}
          disabled={isAddContactsDisabled}
          data-testid="add-contacts-button"
        >
          <UIText small bold>
            {phrases.CANDIDATE_PROFILE_NO_CONTACTS_BUTTON_TEXT}
          </UIText>
        </Button>
      )}
    </div>
  );
};

const checkIfAnyContactExists = (
  enrichedProfile: EnrichedProfileExtended,
  contactsCreation: boolean
) => {
  const { addresses, links, emails, primaryEmail, groupedPhones } =
    enrichedProfile;

  return (
    contactsCreation ||
    addresses.length ||
    links.length ||
    emails.length ||
    primaryEmail ||
    !R.isNullOrEmpty(groupedPhones)
  );
};

const removeEmptyValues = (values: any) => R.reject(R.isNullOrEmpty, values);

const getEmailsWithoutPrimary = (
  emails: StringMergedResponse[],
  primaryEmail?: string
) => {
  if (!primaryEmail) return emails;
  return R.reject((email: any) => email?.value === primaryEmail, emails);
};

const getPrimaryEmailData = (
  emails: StringMergedResponse[],
  primaryEmail?: string
) => {
  if (R.isEmpty(emails)) return { value: primaryEmail };
  //@ts-ignore
  const primaryEmailInEmails = R.find(R.propEq('value', primaryEmail))(emails);
  return primaryEmailInEmails || { value: primaryEmail };
};

const prependPrimaryEmail = (
  emails: StringMergedResponse[],
  primaryEmail?: string
) => {
  if (!primaryEmail) return emails;
  const emailsWithPrimary = [
    getPrimaryEmailData(emails, primaryEmail),
    ...getEmailsWithoutPrimary(emails, primaryEmail),
  ];
  return removeEmptyValues(emailsWithPrimary);
};

const prepareEmailValues = (
  emails: StringMergedResponse[],
  primaryEmail?: string
) => {
  return {
    emails: prependPrimaryEmail(emails, primaryEmail).map(
      (email: { value: string }) => ({
        value: email?.value,
      })
    ),
  };
};

export const ContactDetails: React.FC<CandidateContactDetailsProps> = ({
  enrichedProfile: { addresses, links, emails, primaryEmail, groupedPhones },
  isExternalATS,
  isTrialExpired,
  saveChanges,
  profileId,
  isAddContactsDisabled,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [contactsCreation, setContactsCreation] = useState(false);

  useBeforeUnload(
    isEditMode,
    phrases.CANDIDATE_PROFILE_CONTACTS_CONFIRM_LEAVE_PAGE
  );

  useEffect(() => {
    /*   if user starts to add contacts within applicant profile w/o contacts and then switch to another candidate profile.
   ContactsCreation mode has to be turned off.*/
    if (contactsCreation) {
      setContactsCreation(false);
    }

    /* Has to be invoked only if profileId is changed.*/
    // eslint-disable-next-line
  }, [profileId]);

  const phoneTypes = groupedPhones && R.keys(groupedPhones);

  const formIsEditable = !(isExternalATS || isTrialExpired);

  const methods = useForm({
    defaultValues: prepareEmailValues(emails, primaryEmail),
    shouldUnregister: true,
    mode: 'onChange',
  });

  const { handleSubmit, getValues, reset, control } = methods;

  const { fields, remove, append, move } = useFieldArray<EmailsFormValues>({
    control,
    name: CONTACTS_TYPE_EMAILS,
  });

  const addNewField = (categoryName: string, index: number, value = '') => {
    append({ value }, { focusName: `${categoryName}.${index}` });
  };

  const openModal = useCallback(
    (event) => {
      if (!isModalOpen && isEditMode) {
        event.stopPropagation();
      }

      if (isEditMode) {
        event.preventDefault();
        setModalOpen(true);
      }
    },
    [isEditMode, isModalOpen]
  );

  const onDiscard = () => {
    setModalOpen(false);
    onSaveDiscardClick();
  };

  const confirmSave = () => {
    setModalOpen(false);
    onSubmit(getValues());
  };

  const [outsideClickRef] = useOutsideClick(openModal, {
    useCapture: true,
  });

  const handleAddEmailButtonClick = () => {
    addNewField(CONTACTS_TYPE_EMAILS, fields.length);
    if (!isEditMode) {
      setEditMode(true);
    }
  };

  useEffect(() => {
    if (!isEditMode) {
      reset(prepareEmailValues(emails, primaryEmail));
    }
  }, [emails, reset, primaryEmail, isEditMode]);

  const onSubmit = (formData: { emails: { value: string }[] }) => {
    setEditMode((state) => !state);
    const { emails } = formData;

    const getValidFormEmails = R.pipe(
      R.map(R.prop('value')),
      removeEmptyValues,
      R.filter(isEmailValid)
    );

    const validEmails = getValidFormEmails(emails);

    const params = {
      emails: validEmails,
      primaryEmail: R.head(validEmails),
    };

    saveChanges(params);
  };

  const onFieldKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const emailsWithPrimaryOne = useMemo(
    () => [{ value: primaryEmail }, ...emails],
    [emails, primaryEmail]
  );

  const handleContactsCreation = () => {
    setContactsCreation(true);
  };

  const onSaveDiscardClick = () => {
    setContactsCreation(false);
    setEditMode(false);
  };

  return checkIfAnyContactExists(
    {
      addresses,
      links,
      emails,
      primaryEmail,
      groupedPhones,
    },
    contactsCreation
  ) ? (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={onFieldKeyDown}
          ref={fields.length ? outsideClickRef : null}
        >
          <div className={styles.rootContainer}>
            {!!fields.length && formIsEditable && (
              <>
                {!isEditMode ? (
                  <div
                    className={classNames(
                      styles.editButtonWrapper,
                      styles.editButtonsOnTop
                    )}
                  >
                    <Button
                      icon="edit"
                      className={styles.editButton}
                      iconClassName={styles.editButtonIcon}
                      variant={ButtonVariants.POSITIVE_CONFIRM}
                      onClick={() => setEditMode(true)}
                      disabled={isAddContactsDisabled}
                      data-testid="edit-button"
                    >
                      <UIText className={styles.buttonText} small bold>
                        {phrases.CANDIDATE_PROFILE_CONTACTS_EDIT_BUTTON_TEXT}
                      </UIText>
                    </Button>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      styles.discardSaveWrapper,
                      styles.editButtonsOnTop
                    )}
                    data-testid="edit-contacts-controls"
                  >
                    <Button
                      icon="discard-draft"
                      variant={ButtonVariants.NEGATIVE_SECONDARY}
                      className={styles.discardButton}
                      iconClassName={styles.discardButtonIcon}
                      onClick={onSaveDiscardClick}
                    >
                      <UIText small bold>
                        {phrases.CANDIDATE_PROFILE_CONTACTS_DISCARD_BUTTON_TEXT}
                      </UIText>
                    </Button>
                    <Button
                      icon="check-icon"
                      variant={ButtonVariants.POSITIVE_CONFIRM}
                      className={styles.saveButton}
                      iconClassName={styles.saveButtonIcon}
                      onClick={() => onSubmit(getValues())}
                    >
                      <UIText small bold>
                        {phrases.CANDIDATE_PROFILE_CONTACTS_SAVE_BUTTON_TEXT}
                      </UIText>
                    </Button>
                  </div>
                )}
              </>
            )}

            <div className={styles.sectionContainer}>
              <UIText small className={styles.sectionName}>
                {phrases.CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_EMAILS}
              </UIText>
              <div className={styles.content} data-testid="emails-section">
                <EmailsInfo
                  addNewField={handleAddEmailButtonClick}
                  removeField={remove}
                  moveField={move}
                  fields={fields}
                  emails={emailsWithPrimaryOne}
                  isEditMode={isEditMode}
                  primaryEmail={primaryEmail}
                />
              </div>
            </div>
            {!R.isNullOrEmpty(groupedPhones) && (
              <div className={styles.sectionContainer}>
                <UIText small className={styles.sectionName}>
                  {phrases.CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_PHONES}
                </UIText>
                <div className={styles.content}>
                  {phoneTypes.map((phoneType, index) => (
                    <PhonesByType
                      key={index}
                      phoneNumbers={groupedPhones[phoneType]}
                    />
                  ))}
                </div>
              </div>
            )}
            {addresses?.length > 0 && (
              <div className={styles.sectionContainer}>
                <UIText small className={styles.sectionName}>
                  {phrases.CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_LOCATION}
                </UIText>
                <div className={styles.content}>
                  {addresses.map((address: AddressExtended, index: number) => (
                    <AddressItem
                      key={address.street + index}
                      address={address}
                    />
                  ))}
                </div>
              </div>
            )}
            {links?.length > 0 && (
              <div className={styles.sectionContainer}>
                <UIText small className={styles.sectionName}>
                  {phrases.CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_LINKS}
                </UIText>
                <div className={styles.content}>
                  {links.map((link, index) => (
                    <LinkItem key={link.url + index} link={link} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
      <ConfirmationModal
        confirmLabel={phrases.CANDIDATE_PROFILE_CONTACTS_SAVE_BUTTON_TEXT}
        isOpen={isModalOpen}
        onCancel={onDiscard}
        cancelLabel={phrases.CANDIDATE_PROFILE_CONTACTS_DISCARD_BUTTON_TEXT}
        onConfirm={confirmSave}
        cancelVariant={ButtonVariants.NEGATIVE_SECONDARY}
        onRequestClose={() => setModalOpen(false)}
      >
        <Paragraph short bold className={styles.confirmModalText}>
          {phrases.CANDIDATE_PROFILE_CONTACTS_CONFIRM_MODAL_TEXT}
        </Paragraph>
      </ConfirmationModal>
    </>
  ) : (
    <NoContactsSection
      isExternalATS={isExternalATS}
      setContactsCreation={handleContactsCreation}
      isAddContactsDisabled={isAddContactsDisabled}
    />
  );
};
