// imports from vendor deps
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Placement } from 'popper.js';
import { Manager, Reference, PopperProps } from 'react-popper';
// import {} from 'ramda';
import classNames from 'classnames';
// imports from types
import { CandidateSearchProfileStatus } from '@air/api';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData/';
// imports from 'components'
import { Popup, UIText } from '@air/components';
import { SvgIcon } from 'components';
import {
  statusIconMap,
  getToggleStatesForStatus,
} from 'domain/CandidateData/candidateStatusHelpers';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CANDIDATE_STATUS_UPDATE } from 'constants/gaLabels';
import * as phrases from 'constants/phrases';

// imports from styles
import statusToggleStyles from './CandidateStatusToggle.css';

export const CandidateStatusTogglePopup: React.FC<{
  initialStatus: CandidateSearchProfileStatusEnum;
  systemStatus: CandidateSearchProfileStatus;
  onSelectNewStatus: (selectedStatus: CandidateSearchProfileStatusEnum) => void;
  onOutsideClick: () => void;
  popupModifiers: {
    modifiers: PopperProps['modifiers'];
    placement: PopperProps['placement'];
  };
  renderCurrentStatus: (
    status: CandidateSearchProfileStatusEnum,
    className: string
  ) => void;
  className?: string;
  moveToApplied?: () => void;
  moveToPassive?: () => void;
}> = ({
  initialStatus,
  systemStatus,
  onSelectNewStatus,
  onOutsideClick,
  popupModifiers,
  className,
  renderCurrentStatus,
  moveToApplied,
  moveToPassive,
}) => {
  const availableStatuses = useMemo(
    () => getToggleStatesForStatus(initialStatus, systemStatus),
    [initialStatus, systemStatus]
  );

  const renderChangeStatusButton = useCallback(
    (status: CandidateSearchProfileStatusEnum, className: string) => {
      const { icon, buttonLabel } = statusIconMap[status];
      return (
        <button
          type="button"
          className={classNames(
            statusToggleStyles.popupStatusButton,
            className,
            {
              [statusToggleStyles.rejectedStatus]: [
                CandidateSearchProfileStatusEnum.MANUALREJECTION,
                CandidateSearchProfileStatusEnum.RECOMMENDEDFORREJECTION,
              ].includes(status),
              [statusToggleStyles.withBlueBackground]: [
                CandidateSearchProfileStatusEnum.ACTIVE,
                CandidateSearchProfileStatusEnum.PENDING,
                CandidateSearchProfileStatusEnum.NOTPROCESSED,
              ].includes(status),
              [statusToggleStyles.selectedStatus]:
                CandidateSearchProfileStatusEnum.MANUALSHORTLIST === status,
            }
          )}
          key={status}
          onClick={() => onSelectNewStatus(status)}
        >
          <SvgIcon width="2em" height="2em" icon={`${icon}status`} />
          <UIText small>{buttonLabel}</UIText>
        </button>
      );
    },
    [onSelectNewStatus]
  );

  const availableStatusesEl = useMemo(() => {
    return availableStatuses.map((status, index) => {
      let className;
      /*
        Move to active is a separate action button that can exist
        in the popup before on top of other status buttons.
      */
      if (index === 0 && !moveToApplied) {
        className = statusToggleStyles.firstChild;
      } else if (!moveToPassive && index === availableStatuses.length - 1) {
        className = statusToggleStyles.lastChild;
      }
      if (status === initialStatus) {
        return renderCurrentStatus(status, className);
      } else {
        return renderChangeStatusButton(status, className);
      }
    });
  }, [
    initialStatus,
    availableStatuses,
    renderCurrentStatus,
    renderChangeStatusButton,
    moveToApplied,
    moveToPassive,
  ]);

  return (
    <Popup
      positionFixed
      useCapture
      eventsEnabled={false}
      onOutsideClick={onOutsideClick}
      {...popupModifiers}
    >
      {({ ref, style, placement }) => (
        <div
          ref={ref}
          style={style}
          data-placement={placement}
          className={classNames(
            statusToggleStyles.popupContainer,
            statusToggleStyles.statusToggleContext,
            className
          )}
        >
          {moveToApplied && (
            <button
              type="button"
              className={classNames(
                statusToggleStyles.firstChild,
                statusToggleStyles.popupStatusButton,
                statusToggleStyles.moveToAppliedAction
              )}
              onClick={moveToApplied}
            >
              <SvgIcon width="2em" height="2em" icon="applicant-icon" />
              <UIText small>
                {phrases.CANDIDATE_STATUS_TOGGLE_MOVE_TO_APPLIED}
              </UIText>
            </button>
          )}
          {availableStatusesEl}
          {moveToPassive && (
            <button
              type="button"
              className={classNames(
                statusToggleStyles.popupStatusButton,
                statusToggleStyles.moveToPassiveAction
              )}
              onClick={moveToPassive}
            >
              <SvgIcon width="2em" height="2em" icon="movetopassive" />
              <UIText small>
                {phrases.CANDIDATE_STATUS_TOGGLE_MOVE_TO_PASSIVE}
              </UIText>
            </button>
          )}
        </div>
      )}
    </Popup>
  );
};

// component proptypes
type Props = {
  initialStatus: CandidateSearchProfileStatusEnum;
  systemStatus: CandidateSearchProfileStatus;
  onChange: (status: CandidateSearchProfileStatusEnum) => void;
  onToggle?: (isOpened: boolean) => void;
  className?: string;
  popupLocation: StatusPopupLocation;
  renderCurrentStatus: (
    status: CandidateSearchProfileStatusEnum,
    className: string
  ) => void;
  moveToApplied?: () => void;
  moveToPassive?: () => void;
  disabled?: boolean;
};

// exports / component definitions
export const CandidateStatusToggle: React.FC<Props> = ({
  className,
  onToggle,
  onChange,
  initialStatus,
  systemStatus,
  children,
  popupLocation,
  renderCurrentStatus,
  moveToApplied,
  moveToPassive,
  disabled,
}) => {
  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    if (initialStatus !== status) {
      setStatus(initialStatus);
    }
  }, [status, initialStatus]);

  const [isOpened, toggleOpenedState] = useState(false);
  const selectNewStatus = useCallback(
    (selectedStatus: CandidateSearchProfileStatusEnum) => {
      toggleOpenedState(false);
      onToggle?.(false);
      if (selectedStatus !== status) {
        trackEvent(getStatusToggleGAInfo(popupLocation));
        setStatus(selectedStatus);
        onChange(selectedStatus);
      }
    },
    [onChange, onToggle, status, popupLocation]
  );

  const moveToAppliedCb = useMemo(() => {
    return !moveToApplied
      ? null
      : () => {
          toggleOpenedState(false);
          moveToApplied();
        };
  }, [moveToApplied]);

  const moveToPassiveCb = useMemo(() => {
    return !moveToPassive
      ? null
      : () => {
          toggleOpenedState(false);
          moveToPassive();
        };
  }, [moveToPassive]);

  const onOutsideClick = useCallback(() => {
    toggleOpenedState(false);
    onToggle?.(false);
  }, [onToggle]);

  /*
    If widget is opened, but user starts scrolling, we should
    immediately close the widget.
   */
  useEffect(() => {
    if (isOpened) {
      onToggle?.(true);
      window.addEventListener(
        'scroll',
        function handler() {
          window.removeEventListener('scroll', handler, true);
          toggleOpenedState(false);
          onToggle?.(false);
        },
        true
      );
    }
  }, [onToggle, isOpened]);

  const { popupModifiers } = useStatusPopupModifiers(popupLocation);

  const meta = statusIconMap[status];
  if (!meta) return null;

  const triggerPopUpToOpen = () => {
    !disabled && toggleOpenedState(true);
  };

  return (
    <div
      className={classNames(
        className,
        statusToggleStyles.statusToggleWrapper,
        statusToggleStyles.statusToggleContext
      )}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={triggerPopUpToOpen}>
              {children}
            </div>
          )}
        </Reference>
        {isOpened && (
          <CandidateStatusTogglePopup
            className={classNames(statusToggleStyles.popupContainer, className)}
            renderCurrentStatus={renderCurrentStatus}
            popupModifiers={popupModifiers}
            initialStatus={initialStatus}
            systemStatus={systemStatus}
            onSelectNewStatus={selectNewStatus}
            onOutsideClick={onOutsideClick}
            moveToApplied={moveToAppliedCb}
            moveToPassive={moveToPassiveCb}
          />
        )}
      </Manager>
    </div>
  );
};

export enum StatusPopupLocation {
  LineupTable,
  CandidateProfile,
}

const commonPopperModifiers = {
  /*
    flip is disabled to place popup on bottom, even if it's too close
    to viewport's bottom border.
  */
  flip: { enabled: false },
  hide: { enabled: false },
};

export function useStatusPopupModifiers(popupLocation: StatusPopupLocation) {
  const popupModifiers = useMemo(() => {
    return {
      modifiers: commonPopperModifiers,
      placement:
        popupLocation === StatusPopupLocation.CandidateProfile
          ? ('left' as Placement)
          : ('right' as Placement),
    };
  }, [popupLocation]);

  return {
    popupModifiers,
  };
}

function getStatusToggleGAInfo(popupLocation: StatusPopupLocation) {
  switch (popupLocation) {
    case StatusPopupLocation.CandidateProfile:
      return {
        category: GACategory.ScreeningCandidateProfile,
        label: GA_LABEL_CANDIDATE_STATUS_UPDATE,
      };
    case StatusPopupLocation.LineupTable:
      return {
        category: GACategory.ScreeningPage,
        label: GA_LABEL_CANDIDATE_STATUS_UPDATE,
      };
  }
}
