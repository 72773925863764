import {
  TitleExtendedItemType,
  TitleExtendedTypeahead,
  TitleResponse,
} from '@air/api';

import R from '@air/third-party/ramda';

export type RoleItem = {
  id: number;
  fullName: string;
  managerial: boolean;
};

export type ExtendedRoleItem = {
  id?: number;
  fullName?: string;
  type?: TitleExtendedTypeahead['type'];
  items?: TitleExtendedTypeahead['items'];
};

export const getRoleItems = (
  items: TitleResponse[],
  withSimilarGroups?: boolean
) => {
  if (!withSimilarGroups) return items;
  const currentItems = items ?? [];
  const originalRoles = currentItems.map(
    ({ id, fullName, managerial }: RoleItem) => ({
      id,
      fullName,
      managerial,
    })
  );

  const similarGroupsRoles = currentItems.flatMap((item: TitleResponse) => {
    return (item?.similarGroups ?? []).map(
      ({ id, fullName }: { id: number; fullName: string }) => ({
        id,
        fullName,
        managerial: item.managerial,
      })
    );
  });

  return originalRoles.concat(similarGroupsRoles);
};

export const getExtendedRoleItems = (
  items: TitleExtendedTypeahead[],
  withSimilarGroups = true
): ExtendedRoleItem[] => {
  const [categoryTitles, otherTitles] = R.partition<TitleExtendedTypeahead>(
    R.propEq('type', TitleExtendedItemType.TITLECATEGORY),
    items ?? []
  );

  const nonEmptyCategoryTitles = R.filter(
    (it) => it.items.length > 0,
    categoryTitles
  );

  const similarGroupsTitles = withSimilarGroups
    ? otherTitles
        .filter(
          (it) =>
            it.type === TitleExtendedItemType.TITLE && it.items?.length > 0
        )
        .flatMap((item: TitleExtendedTypeahead) => {
          return (item?.items ?? []).map(
            ({ id, fullName }: { id: number; fullName: string }) => ({
              id,
              fullName,
              extras: {
                isSimilarGroup: true,
              },
            })
          );
        })
    : [];

  return [...nonEmptyCategoryTitles, ...otherTitles, ...similarGroupsTitles];
};
