import React, { CSSProperties, useState, useCallback } from 'react';
import classNames from 'classnames';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import TextareaAutosize from 'react-textarea-autosize';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import { pathOr, omit, compose, assoc, identity } from 'ramda';

import {
  SelectComponentsProps,
  Props as SelectPropsT,
  // @ts-ignore
} from 'react-select/lib/Select';

import { AutocompleteProps, FormSelectEntity } from './typings';
// @ts-ignore
import { Option } from 'react-select/lib/filters';
import {
  CriteriaFormDropdownWrapper,
  Tooltip,
  Card,
  SvgIcon,
} from '@air/components';

import * as phrases from '@air/constants/phrases';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import './Select.css';
import selectStyles from './Select.css';

const emptyStyleFn = () => ({});

export const sharedSelectStyles = {
  option: emptyStyleFn,
  control: emptyStyleFn,
  valueContainer: () => ({
    width: 'auto',
  }),
  indicator: () => ({
    display: 'none',
  }),
  input: (): CSSProperties => ({
    display: 'inline',
    width: 'unset',
    minWidth: 'unset',
    border: 0,
    fontSize: '1.0rem',
    fontWeight: 'normal',
    fontFamily: 'IBM Plex Sans, sans-serif',
    textTransform: 'uppercase',
    color: '#000000',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  }),
  menu: (): CSSProperties => ({
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: 'none',
    width: 'calc(100% + 2em)',
  }),
  menuList: (base: any) => ({ ...base, maxHeight: 'unset' }),
  multiValue: emptyStyleFn,
  multiValueRemove: compose<object[], object, object>(
    assoc('marginLeft', 'auto'),
    omit([':hover'])
  ),
  singleValue: emptyStyleFn,
  container: (base: any) => ({
    ...base,
    pointerEvents: 'auto',
    padding: '1em 0',
  }),
  placeholder: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    transform: 'none',
    position: 'static',
  }),
  noOptionsMessage: (base: any) => ({
    ...base,
    fontSize: '1.6em',
  }),
};

const sharedCustomComponents = {
  MenuList: (props: any) => {
    return (
      <CriteriaFormDropdownWrapper {...props}>
        {props.selectProps.dropDownBefore || null}
        {props.children}
        {props.selectProps.dropDownAfter || null}
      </CriteriaFormDropdownWrapper>
    );
  },
  Option: (props: any) => {
    const showExplanation =
      props.data.__isNew__ && props.selectProps?.explanation;
    const tooltipText = showExplanation
      ? props.selectProps?.explanation
      : props.data.label;

    return (
      <Tooltip
        placement="top"
        trigger="hover"
        tooltip={tooltipText}
        delayShow={TOOLTIP_DELAY_TIME_LONG}
        {...(props.selectProps.getDropDownItemTooltipProps
          ? props.selectProps.getDropDownItemTooltipProps(props.data)
          : {})}
      >
        <div
          className={classNames(
            'autocompleteSelect__optionWrapper',
            props.selectProps.optionClassName &&
              props.selectProps.optionClassName(props.data)
          )}
        >
          <components.Option {...props}>
            <div className={selectStyles.optionLabel}>
              <span className={selectStyles.optionLabelText}>
                {props.data.label}
              </span>
              {showExplanation && (
                <span className={selectStyles.optionExplanation}>
                  <SvgIcon icon="plus" className={selectStyles.iconPlus} />
                </span>
              )}
            </div>
            {props.selectProps.getDropDownItemOptionIcon &&
              props.selectProps.getDropDownItemOptionIcon(props.data)}
            {props.data.description && (
              <div className={selectStyles.optionDescription}>
                <span>{props.data.description}</span>
              </div>
            )}
          </components.Option>
        </div>
      </Tooltip>
    );
  },
  SingleValue: (props: any) => {
    return (
      <components.SingleValue {...props}>
        <Card.Title
          className={selectStyles.multilineSingleValue}
          resizeable={props.selectProps?.multilineInput}
          flexGrow={false}
          getTooltipText={props.selectProps.getTooltipText}
          showTooltip={props.selectProps.showTooltip}
          title={props.children}
          tooltipProps={
            props.selectProps.getSingleValueTooltipProps
              ? props.selectProps.getSingleValueTooltipProps(props.data)
              : {}
          }
        />
      </components.SingleValue>
    );
  },
};

const AutosizeTextareaInput: React.FC<SelectComponentsProps> = ({
  id,
  innerRef,
  value,
  isDisabled,
  tabIndex,
  onBlur,
  onFocus,
  selectProps,
  selectProps: { inputRegex },
  onChange,
}) => {
  const onChangeCallback = useCallback(
    (event, ...args) => {
      if (inputRegex && !inputRegex.test(event.target.value)) {
        return;
      }
      onChange(event, ...args);
    },
    [inputRegex, onChange]
  );

  return (
    <div
      className={classNames(
        selectStyles.autosizeTextareaInputWrapper,
        selectProps.className
      )}
    >
      <TextareaAutosize
        className={selectStyles.autosizeTextareaInput}
        id={id}
        maxRows={5}
        inputRef={innerRef}
        onHeightChange={selectProps.onHeightChange}
        tabIndex={tabIndex}
        onBlur={onBlur}
        onChange={onChangeCallback}
        onFocus={onFocus}
        value={value}
        disabled={isDisabled}
      />
    </div>
  );
};

export const AutocompleteSelect = React.forwardRef<
  HTMLDivElement,
  AutocompleteProps
>(
  (
    {
      autoFocus = false,
      creatable = true,
      className,
      getNoOptionsMessage,
      ...props
    },
    ref
  ) => {
    const SelectComponent = creatable ? AsyncCreatableSelect : AsyncSelect;

    return (
      <SelectComponent
        // @ts-ignore
        ref={ref}
        components={{
          ...sharedCustomComponents,
          IndicatorsContainer: (): any => null,
          ...(props.multilineInput
            ? {
                Input: AutosizeTextareaInput,
              }
            : {}),
        }}
        isMulti={false}
        styles={sharedSelectStyles}
        noOptionsMessage={({ inputValue }: any) =>
          inputValue
            ? getNoOptionsMessage?.(inputValue) ||
              phrases.NO_OPTIONS_DEFAULT_MESSAGE
            : null
        }
        defaultOptions={props.defaultOptions}
        cacheOptions={false}
        formatCreateLabel={identity}
        classNamePrefix="autocompleteSelect"
        createOptionPosition="first"
        autoFocus={autoFocus}
        {...props}
        className={classNames(className, {
          [selectStyles.multilineSelect]: props.multilineInput,
        })}
      />
    );
  }
);
AutocompleteSelect.displayName = 'AutocompleteSelect';

type MultiSelectAsyncProps = {
  name?: string;
  value?: FormSelectEntity | FormSelectEntity[];
  creatable?: boolean;
  placeholder?: string;
  onChange?: (value: any) => any;
  loadOptions: (value: string, cb: (...args: any[]) => any) => Promise<any>;
  filterOption?: ((option: Option, rawInput: string) => boolean) | null;
  inputRegex?: RegExp;
};

const TagsCustomInput: React.FC<any> = ({ updateInputValue, ...props }) => {
  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('Text');
    updateInputValue(text);
  };

  // onPaste doesn't exist on type InputProps
  // but, actually, onPaste prop is spread
  // to <input /> element
  // @ts-ignore
  return <components.Input {...props} onPaste={handlePaste} />;
};

export const TagInputRenderer: React.FC<any> = ({
  value,
  onChange,
  ...props
}) => {
  const [inputValue, updateInputValue] = useState(pathOr('', ['label'], value));

  const handleInput = (input: any) => {
    if (props.inputRegex && !props.inputRegex.test(input)) {
      return '';
    }
    updateInputValue(input);
    return input;
  };

  const handleKeyDown = (event: any) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newValues = inputValue
          .trim()
          .split('\n')
          .map((val: any) => ({ label: val, value: val }));
        onChange([...(value || []), ...newValues]);
        updateInputValue('');
        event.preventDefault();
    }
  };

  // TODO: requires more investigation
  // for some reason, passing inline function as an Input component
  // to CreatableSelect 'components' prop
  // breaks Input component
  const InputComponent = React.useRef((props: any) => (
    <TagsCustomInput {...props} updateInputValue={updateInputValue} />
  ));

  return (
    <CreatableSelect
      {...props}
      components={{
        ...sharedCustomComponents,
        IndicatorsContainer: (): any => null,
        Placeholder: (): any => null,
        Input: InputComponent.current,
      }}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={handleInput}
      onKeyDown={handleKeyDown}
      value={value}
      styles={sharedSelectStyles}
      classNamePrefix="autocompleteSelect"
    />
  );
};

export const TagSelectAsync = React.forwardRef<
  HTMLDivElement,
  MultiSelectAsyncProps
>(
  (
    {
      value,
      onChange,
      loadOptions,
      filterOption,
      creatable = false,
      placeholder = '',
      inputRegex,
    },
    ref
  ) => {
    const SelectComponent = creatable ? AsyncCreatableSelect : AsyncSelect;

    const [inputValue, updateInputValue] = useState(
      pathOr('', ['label'], value)
    );

    const handleInput = (input: any) => {
      if (inputRegex && !inputRegex.test(input)) {
        return '';
      }
      updateInputValue(input);
      return input;
    };

    return (
      <SelectComponent
        // @ts-ignore
        ref={ref}
        value={value}
        components={{
          ...sharedCustomComponents,
          IndicatorsContainer: (): any => null,
        }}
        placeholder={placeholder}
        closeMenuOnSelect
        isMulti={true}
        styles={sharedSelectStyles}
        onChange={onChange}
        defaultOptions={false}
        cacheOptions={false}
        inputValue={inputValue}
        onInputChange={handleInput}
        loadOptions={loadOptions}
        classNamePrefix="autocompleteSelect"
        filterOption={filterOption}
      />
    );
  }
);

TagSelectAsync.displayName = 'TagSelectAsync';

type MultiSelectProps = {
  name?: string;
  value?: FormSelectEntity | FormSelectEntity[];
  placeholder?: string;
  onChange?: (value: FormSelectEntity) => void;
  options: FormSelectEntity[];
  autoFocus?: boolean;
  filterOption?: ((option: Option, rawInput: string) => boolean) | null;
  hideSelectedOptions?: boolean;
};

export const TagSelect: React.FC<
  MultiSelectProps & {
    onRemoveItem: (data: any) => void;
    onSelectItem: (data: any) => void;
  }
> = ({
  value,
  onChange,
  options,
  filterOption,
  onRemoveItem,
  onSelectItem,
  ...props
}) => {
  return (
    <Select
      {...props}
      value={value}
      components={{
        ...sharedCustomComponents,
        IndicatorsContainer: () => null,
        MultiValueRemove: (props) => {
          const clickHandler = (e: any) => {
            onRemoveItem && onRemoveItem(props.data);
            props.innerProps.onClick(e);
          };

          const newProps = {
            ...props,
            innerProps: { ...props.innerProps, onClick: clickHandler },
          };
          return <components.MultiValueRemove {...newProps} />;
        },
        Option: (props) => {
          const clickHandler = (e: any) => {
            onSelectItem && onSelectItem(props.data);
            props.innerProps.onClick(e);
          };

          const newProps = {
            ...props,
            innerProps: { ...props.innerProps, onClick: clickHandler },
          };
          return <components.Option {...newProps} />;
        },
      }}
      closeMenuOnSelect
      isMulti={true}
      styles={sharedSelectStyles}
      onChange={onChange}
      options={options}
      cacheOptions={false}
      classNamePrefix="autocompleteSelect"
      filterOption={filterOption}
    />
  );
};
TagSelect.displayName = 'TagSelect';

export type LoadAsyncListOptionsT<
  FormSelectEntityExtras = Record<string, any>
> = (
  idsInUse?: Array<number | string>,
  selectedRowItem?: string | number
) => (
  value: any,
  cb?: () => FormSelectEntity<FormSelectEntityExtras>[]
) => void;

type SelectRendererProps<FormSelectEntityExtras = Record<string, any>> = {
  name?: string;
  value?: FormSelectEntity<FormSelectEntityExtras>;
  options?: FormSelectEntity<FormSelectEntityExtras>[];
  label?: string;
  asyncOptions?: LoadAsyncListOptionsT<FormSelectEntityExtras>;
  onBlur?: (event: React.FocusEvent, select?: HTMLSelectElement) => void;
  onFocus?: (event: React.FocusEvent, select?: HTMLSelectElement) => void;
  onKeyDown?: (event: React.KeyboardEvent, select?: HTMLSelectElement) => void;
  onChange?: (value: any, select?: HTMLSelectElement) => void;
  onInputChange?: (value: any) => void;
  autoFocus?: boolean;
  inputRegex?: RegExp;
  multiselect?: boolean;
  multilineInput?: boolean;
} & SelectPropsT;

export const SelectRenderer = <FormSelectEntityExtras,>(
  props: SelectRendererProps<FormSelectEntityExtras>
) => {
  const selectRef = React.useRef(null);
  const handleBlur = (e: any) => {
    props.onBlur && props.onBlur(e, selectRef.current);
  };
  const handleFocus = (e: any) => {
    props.onFocus && props.onFocus(e, selectRef.current);
  };
  const handleChange = (value: any) => {
    props.onChange && props.onChange(value, selectRef.current);
  };
  const handleKeyDown = (e: any) => {
    props.onKeyDown && props.onKeyDown(e, selectRef.current);
  };

  if (props.multiselect && props.asyncOptions) {
    return (
      <TagSelectAsync
        {...props}
        ref={selectRef}
        onChange={handleChange}
        loadOptions={props.asyncOptions}
        value={props.value}
      />
    );
  }

  const SelectComponent = props.creatable ? CreatableSelect : Select;

  return typeof props.asyncOptions === 'function' ? (
    <AutocompleteSelect
      {...props}
      ref={selectRef}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      loadOptions={props.asyncOptions}
      value={props.value}
      defaultOptions={props.defaultOptions}
    />
  ) : (
    // @ts-ignore
    <SelectComponent
      createOptionPosition="first"
      formatCreateLabel={identity}
      allowCreateWhileLoading={false}
      {...props}
      ref={selectRef}
      // @ts-ignore: TODO: fix typings for AutosizeTextareaInput
      components={{
        ...sharedCustomComponents,
        IndicatorsContainer: (): null => null,
        ...(props.multilineInput
          ? {
              Input: AutosizeTextareaInput,
            }
          : {}),
      }}
      isMulti={props.multiselect}
      styles={sharedSelectStyles}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      value={props.value}
      options={props.options}
      className={classNames({
        [selectStyles.multilineSelect]: props.multilineInput,
      })}
      classNamePrefix="autocompleteSelect"
    />
  );
};

SelectRenderer.defaultProps = {
  value: null,
};
