import React from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';

import {
  SearchCriteriaData,
  getImportanceValue,
  CardType,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria';
import {
  getTopStackItemLabel,
  getValuesForTopStack,
  getValuesForBottomStack,
  getCardListItemTooltip,
} from 'domain/SearchCriteria/cardHelpers';
import { getDegreeReadOnlyEquivalentExperience } from 'components/Cards/DegreeCard/DegreeCard';
import { Card } from '@air/components';

import styles from './LineupExpandedCard.css';
import degreeStyles from 'components/Cards/DegreeCard/DegreeCard.css';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

export const LineupExpandedCard = React.forwardRef<
  HTMLDivElement,
  {
    cardData: SearchCriteriaData;
    topLabel: string;
    onActionButtonClicked: () => void;
    children: any;
    className?: string;
    getStackItemClassName?: (stackItem: any) => string;
    // Cards rendered in Lineup header should have limited height,
    // because they may not fit the viewport if the card has lot of criteria.
    limitedHeight?: boolean;
  }
>(
  (
    {
      cardData,
      topLabel,
      onActionButtonClicked = null,
      children,
      className,
      getStackItemClassName,
      limitedHeight = false,
    },
    ref
  ) => {
    const importance = getImportanceValue(cardData);

    const topStackItems = R.map(
      (item) => ({
        title: item.label,
        type: item.type,
        minSize: item.minSize,
        maxSize: item.maxSize,
        extras: item.extras,
        inclusionLabel: getTopStackItemLabel(cardData.cardType),
      }),
      getValuesForTopStack(cardData)
    );

    const bottomStackItems = [
      cardData.cardType === CardType.degree &&
        !R.isNil(cardData.equivalentExperience) && {
          title: phrases.EQUIVALENT_EXPERIENCE_STACK_LABEL,
          inclusionLabel: phrases.EQUIVALENT_EXPERIENCE_STACK_LABEL,
          className: degreeStyles.degreeEquivalentExperienceStackItem,
          labelClassName: degreeStyles.equivalentExperienceLabel,
          children: getDegreeReadOnlyEquivalentExperience(cardData),
        },
      ...R.map(
        (item) => ({
          title: item.label,
          type: item.type,
          inclusionLabel: phrases.ACCEPTABLE_STACK_ITEM_LABEL,
        }),
        getValuesForBottomStack<SearchCriteriaListValue>(cardData)
      ),
    ].filter(Boolean);

    const renderStackItem = (item: any, index: any) => (
      <Card.SearchCriteriaStackItem
        key={`${item.title}-${index}`}
        label={item.inclusionLabel}
        labelClassName={item.labelClassName}
        className={classNames(
          item.className,
          styles.expandedCardStackItem,
          getStackItemClassName && getStackItemClassName(item)
        )}
      >
        {
          <Card.TitleLabel
            text={cardsConfig[cardData.cardType].getLineupCardStackTitleLabel?.(
              item
            )}
          />
        }
        {item.children ? (
          item.children
        ) : (
          <Card.Title
            resizeable
            title={item.title}
            className={styles.expandedCardStackLabel}
            getTooltipText={getCardListItemTooltip.bind(null, cardData, item)}
            tooltipProps={cardsConfig[
              cardData.cardType
            ]?.getStackItemTooltipProps?.(item, cardData)}
          />
        )}
      </Card.SearchCriteriaStackItem>
    );

    return (
      <Card.SearchCriteriaCardContainer
        className={classNames(className, styles.expandedCardContainer)}
        importance={importance}
      >
        <>
          <Card.SearchCriteriaEditForm
            ref={ref}
            className={classNames({
              [styles.limitedHeight]: limitedHeight,
            })}
          >
            <Card.SearchCriteriaCardLabel text={topLabel} />
            <Card.SearchCriteriaStack>
              {R.reverse(topStackItems).map(renderStackItem)}
            </Card.SearchCriteriaStack>
            <Card.SearchCriteriaEditFormFields>
              {children}
            </Card.SearchCriteriaEditFormFields>
            <Card.SearchCriteriaStack
              position={Card.SearchCriteriaStack.position.bottom}
              className={styles.expandedCardBottomStack}
            >
              {bottomStackItems.map(renderStackItem)}
            </Card.SearchCriteriaStack>
            <button
              type="button"
              onClick={onActionButtonClicked}
              className={styles.expandedCardActionButton}
            >
              {sharedPhrases.OK}
            </button>
          </Card.SearchCriteriaEditForm>
        </>
      </Card.SearchCriteriaCardContainer>
    );
  }
);
