import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import { CompanyExtendedType, CompanyExtendedListResponse } from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  normalizeSearchCriteriaExperience,
  SearchCriteriaExperienceT,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria/BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import * as phrases from 'constants/phrases';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';
import { getCompanySizeDescription } from '@air/domain/Common/CompanySizeConverter';

export enum LocationType {
  WORLDWIDE = 'WORLDWIDE',
  USONLY = 'US_ONLY',
}

export const locationTypeOptions = {
  [LocationType.WORLDWIDE]: {
    value: LocationType.WORLDWIDE,
    label: phrases.COMPANY_LOCATION_WORLDWIDE_PLACEHOLDER,
  },
  [LocationType.USONLY]: {
    value: LocationType.USONLY,
    label: phrases.COMPANY_LOCATION_US_ONLY_PLACEHOLDER,
  },
};

export const locationTypeOptionsList = Object.values(locationTypeOptions);

export type LocationTypeSelectedValueT =
  typeof locationTypeOptions[keyof typeof locationTypeOptions];

export type CompanyCriteriaDataListItem = {
  value: string | number;
  label: string;
  name?: string;
  type: CompanyExtendedType;
  minSize?: number;
  maxSize?: number;
};

export interface ExcludeCompanyCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.company;
  list: SearchCriteriaListValue<CompanyCriteriaDataListItem>[];
  exclude: true;
  recent: number;
  usOnly?: boolean;
}

export interface IncludeCompanyCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.company;
  list: SearchCriteriaListValue<CompanyCriteriaDataListItem>[];
  exclude: false;
  experience: SearchCriteriaExperienceT;
  recent: number;
  usOnly?: boolean;
}

export type CompanyCriteriaData =
  | IncludeCompanyCriteriaData
  | ExcludeCompanyCriteriaData;

export const isCompany = (
  item: BaseSearchCriteriaData
): item is CompanyCriteriaData => item.cardType === CardType.company;

export const isExcludeCompany = (
  item: BaseSearchCriteriaData
): item is ExcludeCompanyCriteriaData => {
  return isCompany(item) && item.exclude;
};

export const isIncludeCompany = (
  item: BaseSearchCriteriaData
): item is IncludeCompanyCriteriaData => {
  return isCompany(item) && !item.exclude;
};

export const isCompanySizeType = (type: CompanyExtendedType) =>
  type === CompanyExtendedType.COMPANYSIZE;

export const getCompanyDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.IsNewEdit,
}: AddCriteriaInitials): IncludeCompanyCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  list: [null],
  initialCardStatus,
  importance: criteriaImportanceOptions[importance],
  cardType: CardType.company,
  exclude: false,
  experience: null,
  recent: null,
  usOnly: false,
});

export const mapExcludeListToCompanyCriteria = (
  company: ApiModel.ExcludeExperienceList
): ExcludeCompanyCriteriaData => ({
  idx: company.idx,
  id: `exclude-${company.idx}`,
  key: genId(),
  cardType: CardType.company,
  exclude: true,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  importance: criteriaImportanceOptions[company.importance],
  list: company.list.map((c: ApiModel.DictionaryItemInfo) => ({
    label: c.name,
    value: c.id || c.name,
    type: c.type as CompanyExtendedType,
    name: c.name,
    ...(isCompanySizeType(c.type as CompanyExtendedType)
      ? { minSize: c.minSize, maxSize: c.maxSize }
      : {}),
  })),
  recent: company.recent,
});

export const mapExcludeCompanyCriteriaToCompanyList = (
  company: ExcludeCompanyCriteriaData
): ApiModel.ExcludeExperienceList => ({
  idx: company.idx,
  list: (company.list || []).map((c) => ({
    ...(c.value !== c.label && {
      id: c.value as number,
    }),
    name: c.label,
    type: c.type as CompanyExtendedType,
    ...(isCompanySizeType(c.type)
      ? { minSize: c.minSize, maxSize: c.maxSize }
      : {}),
  })),
  importance: company.importance.value,
  recent: company.recent,
});

export const getCompanyTitle = (
  companyName: string,
  cardData: { type: CompanyExtendedType; usOnly: boolean }
) => {
  if (isCompanySizeType(cardData.type) && cardData.usOnly) {
    return phrases.GET_US_ONLY_COMPANY_TITLE(companyName);
  }
  return companyName;
};

export const mapIncludeListToCompanyCriteria = (
  company: ApiModel.IncludeExperienceList
): IncludeCompanyCriteriaData => {
  const usOnly = R.compose<any[], any, any>(
    R.prop('usOnly'),
    R.find(R.propEq('type', CompanyExtendedType.COMPANYSIZE))
  )(company.list) as boolean;

  return {
    idx: company.idx,
    id: `include-${company.idx}`,
    key: genId(),
    exclude: false,
    cardType: CardType.company,
    initialCardStatus: InitialCardStatusEnum.ExistingCard,
    importance: criteriaImportanceOptions[company.importance],
    list: company.list.map((c: ApiModel.DictionaryItemInfo) => ({
      label: getCompanyTitle(c.name, {
        type: c.type as CompanyExtendedType,
        usOnly,
      }),
      value: c.id || c.name,
      type: c.type as CompanyExtendedType,
      name: c.name,
      ...(isCompanySizeType(c.type as CompanyExtendedType)
        ? { minSize: c.minSize, maxSize: c.maxSize }
        : {}),
    })),
    experience: R.pick<
      ApiModel.ExtendedExperienceInfo,
      'idealMin' | 'idealMax' | 'acceptableMin' | 'acceptableMax'
    >(
      ['idealMin', 'idealMax', 'acceptableMin', 'acceptableMax'],
      company.experience
    ),
    recent: company.experience.recent,
    usOnly,
  };
};

export const mapIncludeCompanyCriteriaToCompanyList = (
  company: IncludeCompanyCriteriaData
): ApiModel.IncludeExperienceList => ({
  idx: company.idx,
  list: (company.list || []).map((c) => ({
    ...(c.value !== c.label && {
      id: c.value as number,
    }),
    name: c.name,
    type: c.type,
    usOnly: company.usOnly,
    ...(isCompanySizeType(c.type)
      ? { minSize: c.minSize, maxSize: c.maxSize }
      : {}),
  })),
  importance: company.importance.value,
  experience: {
    ...normalizeSearchCriteriaExperience(company.experience),
    recent: company.recent,
  },
});
export const companyDataMapper = (company: CompanyExtendedListResponse) => ({
  value: company.id,
  type: company.type,
  label: company.fullName,
  ...(company.type === CompanyExtendedType.COMPANY
    ? {}
    : {
        minSize: company.minSize,
        maxSize: company.maxSize,
        description: getCompanySizeDescription(company),
      }),
});
