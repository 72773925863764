import querystring from 'query-string';
import { CommentParamsT } from '@air/domain/InterviewNotes/notesApi';

export const BASE_API = `${API_HOST}/api/v1`;
export const BASE_API_V2 = `${API_HOST}/api/v2`;
export const BASE_API_V3 = `${API_HOST}/api/v3`;

export const LOGIN_API = `${BASE_API}/login`;
export const LOGOUT_API = `${BASE_API}/logout`;

export const createRefreshTokenApiUrl = (refreshToken: any) =>
  `${LOGIN_API}/refresh?refreshToken=${refreshToken}`;

export const APPLICANT_TOKEN_URL = `${LOGIN_API}/link`;

/* dictionaries */
const DICTIONARY_BASE_URL = `${BASE_API_V2}/dictionary`;
/* exported dicts */
export const CATEGORY_SKILL = 'skill';
export const CATEGORY_CERTIFICATION = 'certification';
export const CATEGORY_INSTITUTION_LIST = 'institution-list';
export const CATEGORY_INSTITUTION = 'institution';
export const CATEGORY_DEGREE = 'education-degree';
export const CATEGORY_MAJOR_GROUP = 'education-major-group';
export const CATEGORY_MAJOR = 'education-major';
export const CATEGORY_ROLE = 'title';
export const CATEGORY_ROLE_CATEGORY = 'title-category';
export const CATEGORY_EXTENDED_ROLE = 'title-extended';
export const CATEGORY_COMPANY = 'company';
export const CATEGORY_ORGANIZATION = 'organization';
export const CATEGORY_COMPANY_SIZE = 'company-size';
export const CATEGORY_INDUSTRY = 'industry';
export const makeDictionaryExportUrl = (dictionaryName: string) =>
  `${DICTIONARY_BASE_URL}/${dictionaryName}/export`;

export const DICTIONARY_API_SKILL = `${DICTIONARY_BASE_URL}/${CATEGORY_SKILL}`;
export const DICTIONARY_API_CERTIFICATION = `${DICTIONARY_BASE_URL}/${CATEGORY_CERTIFICATION}`;
export const DICTIONARY_API_COMPANY_SIZES = `${DICTIONARY_BASE_URL}/company-size`;
export const DICTIONARY_API_INDUSTRY = `${DICTIONARY_BASE_URL}/${CATEGORY_INDUSTRY}`;
export const DICTIONARY_API_MAJOR_EXTENDED = `${DICTIONARY_BASE_URL}/education-major-group`;
export const DICTIONARY_API_DEGREE = `${DICTIONARY_BASE_URL}/${CATEGORY_DEGREE}`;
export const DICTIONARY_API_MAJORS = `${DICTIONARY_BASE_URL}/${CATEGORY_MAJOR}`;
export const DICTIONARY_API_MAJOR_GROUPS = `${DICTIONARY_BASE_URL}/${CATEGORY_MAJOR_GROUP}`;
export const DICTIONARY_API_INSTITUTION = `${DICTIONARY_BASE_URL}/${CATEGORY_INSTITUTION}`;
export const DICTIONARY_API_INSTITUTION_EXTENDED = `${DICTIONARY_BASE_URL}/institution-extended`;
export const DICTIONARY_API_INSTITUTIONS_LISTS = `${DICTIONARY_BASE_URL}/${CATEGORY_INSTITUTION_LIST}`;
export const DICTIONARY_API_ROLE = `${DICTIONARY_BASE_URL}/${CATEGORY_ROLE}`;
export const DICTIONARY_API_ROLE_CATEGORY = `${DICTIONARY_BASE_URL}/${CATEGORY_ROLE_CATEGORY}`;
export const DICTIONARY_API_EXTENDED_ROLE = `${DICTIONARY_BASE_URL}/${CATEGORY_EXTENDED_ROLE}`;
export const DICTIONARY_API_COMPANY = `${DICTIONARY_BASE_URL}/${CATEGORY_COMPANY}`;
export const DICTIONARY_API_COMPANY_SIZE = `${DICTIONARY_BASE_URL}/${CATEGORY_COMPANY_SIZE}`;
export const DICTIONARY_API_COMPANY_EXTENDED = `${DICTIONARY_BASE_URL}/company-extended`;
export const DICTIONARY_API_JOB_SPECIALIZATIONS = `${DICTIONARY_BASE_URL}/specialization`;

export const API_CUSTOMER_COMPANY_V1 = `${BASE_API}/${CATEGORY_COMPANY}`;
export const API_CUSTOMER_COMPANY_V3 = `${BASE_API_V3}/${CATEGORY_COMPANY}`;

export const API_COMPANY_MANAGERS = `${BASE_API}/company/manager`;

export const getCustomerCompanyByIdEndpointV1 = (companyId: string) =>
  `${API_CUSTOMER_COMPANY_V1}/${companyId}`;

export const getCustomerCompanyByIdEndpointV3 = (companyId: string) =>
  `${API_CUSTOMER_COMPANY_V3}/${companyId}`;

export const getCustomerCompanyIndexationEndpoint = (companyId: string) =>
  `${BASE_API}/match-miner/${companyId}/drop-indexation`;
export const API_ATS_CUSTOMER_COMPANY_V1 = `${BASE_API}/ats/customer/companies`;
export const API_ATS_CUSTOMER_COMPANY_LOGO = (companyId: string) =>
  `/api/v1/company/${companyId}/logo`;

export const API_BILLING_REPORT = (
  companyId: string,
  from: number,
  to: number
) => `${BASE_API}/report/billing/${companyId}?from=${from}&to=${to}`;

export const makeCompanyInfoApiUrl = `${DICTIONARY_BASE_URL}/company/`;
/* end dictionaries */

export const makeInterviewNotesApiUrl = (
  refId: string,
  profileId: string,
  commentId?: string
) => {
  const url = `${BASE_API}/profile/${profileId}/interview/${refId}/comment`;
  return commentId ? `${url}/${commentId}` : url;
};

export const makeInterviewNotesApiUrlV2 = (params: CommentParamsT) => {
  const { mainProfileId, atsId, jobId, refId, commentId } = params;
  const url = `${BASE_API_V2}/applicant/${mainProfileId}/ats/${atsId}/job/${jobId}/interview/${refId}/comment`;
  return commentId ? `${url}/${commentId}` : url;
};

export const CUSTOMER_NOTIFICATION_URL = `${BASE_API}/notifications/customer`;
export const APPLICANT_NOTIFICATION_URL = `${BASE_API}/notifications/applicant`;
export const ADMIN_NOTIFICATION_URL = `${BASE_API}/notifications/admin-portal`;

type UrlParamsWithCurrentTabT = {
  url: string;
  params?: string;
  tab?: string;
};

export const getUrlWithParamsAndCurrentTab = ({
  url,
  params,
  tab,
}: UrlParamsWithCurrentTabT): string => {
  if (!tab && !params) return url;
  if (!tab) return `${url}?${params}`;

  const paramsObject = querystring.parse(params);
  const computedParams = querystring.stringify({
    ...paramsObject,
    tab,
  });
  return `${url}?${computedParams}`;
};

export const makeCustomerCompanyLogoUrl = (companyId: string) =>
  companyId ? `${BASE_API}/company/${companyId}/logo` : null;
