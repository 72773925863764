import { ReactNode, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import { trackPageView, GACustomDimension } from '@air/utils/ga';
import localSettings from '@air/localSettings';

type AnalyticsTrackerT = RouteComponentProps & {
  children?: ReactNode;
  userId: number;
};

export const AnalyticsTracker = withRouter(
  ({ history, userId }: AnalyticsTrackerT) => {
    useEffect(() => {
      ReactGA.initialize(GA_TRACKING_ID, {
        debug: localSettings.GA_DEBUG_MODE,
      });
    }, []);

    useEffect(() => {
      if (userId) {
        ReactGA.set({
          userId,
          [GACustomDimension.UserId]: userId,
        });
      }
    }, [userId]);

    useEffect(() => {
      // initial page load
      trackPageView(history.location.pathname + history.location.search);
      // track on every route change
      return history.listen((location) => {
        trackPageView(location.pathname + location.search);
      });
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);
    return null;
  }
);
