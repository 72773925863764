import React, { CSSProperties, useCallback } from 'react';
import Select, { components, ValueType } from 'react-select';

import {
  CriteriaFormDropdownWrapper,
  SelectOption,
  SvgIcon,
} from '@air/components';
import { DetailsDemandEnum } from '@air/api';
import styles from './DetailsSelect.css';
import { sharedSelectStyles } from '@air/components/Select/Select';
import { FormSelectEntity } from '@air/components/Select/typings';
import classNames from 'classnames';
import { OptionTypeBase } from 'react-select/src/types';

const emptyComponentFn = (): any => null;

const DetailsSelectedValueView: React.FC<{
  icon: string;
  value: DetailsDemandEnum;
  children: any;
  className?: string;
}> = ({ icon, value, children, className = '' }) => {
  const isIconVisible = value !== DetailsDemandEnum.NOTREQUIRED;
  return (
    <div className={classNames(className, styles.valueWrapper)}>
      {isIconVisible && (
        <div className={styles.valueIcon}>
          <SvgIcon width="1.5em" height="1.5em" icon={icon} />
        </div>
      )}
      <div className={styles.valueTitle}>{children}</div>
    </div>
  );
};
DetailsSelectedValueView.displayName = 'DetailsSelectedValueView';

export const detailsSelectStyles = {
  ...sharedSelectStyles,
  menu: (): CSSProperties => ({
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: 'none',
    width: 'calc(100% + 2em)',
  }),
  valueContainer: () => ({
    width: 'auto',
    lineHeight: '1.2em',
    display: 'flex',
    cursor: 'pointer',
  }),
  input: () => ({}),
};

const detailsComponents = {
  IndicatorsContainer: emptyComponentFn,
  MultiValueRemove: emptyComponentFn,
  MenuList: (props: any) => {
    return (
      <CriteriaFormDropdownWrapper {...props}>
        {props.children}
      </CriteriaFormDropdownWrapper>
    );
  },
  Option: (props: any) => {
    const { label, value, icon } = props.data;
    const data = {
      label,
      icon,
      isSelected: props.isSelected,
    };

    const onClicked = () => {
      props.selectProps.onChange({ ...props.data, value });
      props.selectProps.onMenuClose();
    };

    return (
      <SelectOption
        {...data}
        icon={<SvgIcon width="1.5em" height="1.5em" icon={data.icon} />}
        onClick={onClicked}
      />
    );
  },
  ValueContainer: ({ children, ...props }: any) => {
    /*
      TODO: Try to figure out why props.selectProps.value can be null on DEV/QA/UAT
    */
    if (!props.selectProps.value) return null;

    const { icon, value } = props.selectProps.value;
    return (
      <>
        <components.ValueContainer {...props} {...children}>
          <DetailsSelectedValueView icon={icon} value={value}>
            {children}
          </DetailsSelectedValueView>
        </components.ValueContainer>
      </>
    );
  },
};

export const DetailsReadOnlyWidget: React.FC<{
  icon: string;
  value: DetailsDemandEnum;
  children: any;
  className?: string;
}> = ({ icon, value, children, className = '' }) => {
  return (
    <DetailsSelectedValueView
      icon={icon}
      value={value}
      className={classNames(styles.detailsReadOnlyField, className)}
    >
      {children}
    </DetailsSelectedValueView>
  );
};
DetailsReadOnlyWidget.displayName = 'DetailsReadOnlyWidget';

type Props = {
  className?: string;
  placeholder: string;
  options: FormSelectEntity[];
  onChange: (value: ValueType<OptionTypeBase>) => void;
  props?: any;
  isDisabled?: any;
};

export const DetailsSelect: React.FC<Props> = ({
  options,
  onChange,
  ...props
}) => {
  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    event.preventDefault();
  }, []);
  return (
    <Select
      {...props}
      className={styles.detailsRequiredField}
      components={detailsComponents}
      styles={detailsSelectStyles}
      classNamePrefix="autocompleteSelect"
      placeholder="additional details"
      options={options}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};
