import * as Bowser from 'bowser';

export const isMobile = Bowser.getParser(window.navigator.userAgent).is(
  'mobile'
);
export const isTablet = Bowser.getParser(window.navigator.userAgent).is(
  'tablet'
);
export const isMobileOrTablet = isMobile || isTablet;

export const isLandscape = () =>
  screen?.orientation?.type === 'landscape-primary' ||
  window.innerHeight < window.innerWidth;

export const isSafari = Bowser.getParser(window.navigator.userAgent).is(
  'safari'
);

export const isFirefox = Bowser.getParser(window.navigator.userAgent).is(
  'firefox'
);
