import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import * as phrases from 'constants/phrases';
import { SvgIcon, TooltipWrapper, UIText } from '@air/components';
import { RequisitionCreatorBox } from 'components';
import { useOutsideClick } from '@air/utils/hooks';

import styles from './ActionsMenu.css';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit, subscribe } from 'hooks/usePubSub';

export type ActionMenuItemConfigT = {
  onClick: () => void;
  icon: string;
  title: string;
  disabled?: boolean;
  closeMenuOnClick?: boolean;
  confirmTitle?: string;
  confirmLabel?: string;
  tooltip?: string;
};

export type ActionsMenuConfigT = ActionMenuItemConfigT[];

type ActionsMenuT = {
  created?: number;
  creator?: string;
  className?: string;
  setMenuVisibility?: (state: boolean) => void;
  actionsMenuConfig: ActionsMenuConfigT;
};

const DisabledAction = ({ item }: { item: ActionMenuItemConfigT }) => {
  return (
    <TooltipWrapper
      key={item.title}
      enabled={item.disabled}
      tooltip={item.tooltip || phrases.KANBAN_CARD_TRIAL_ACTION_DESCRIPTION}
      disabledChildren
      placement="bottom"
    >
      <button
        disabled={item.disabled}
        className={classNames(styles.actionButton, {
          [styles.inactive]: item.disabled,
        })}
      >
        <UIText small className={styles.actionTitle}>
          <SvgIcon icon={item.icon} className={styles.icon} />
          {item.title}
        </UIText>
      </button>
    </TooltipWrapper>
  );
};

const Actions = (props: ActionsMenuT) => {
  const { created, creator, actionsMenuConfig, setMenuVisibility } = props;

  const onItemClick = (item: ActionMenuItemConfigT) => {
    if (item.closeMenuOnClick) {
      setMenuVisibility?.(false);
    }
    item.onClick();
  };

  useEffect(() => {
    // hide Actions menu when the user clicks on action and a confirmation modal is shown
    return subscribe(APP_EVENTS.OPEN_CONFIRMATION_MODAL, () =>
      setMenuVisibility?.(false)
    );
  }, [setMenuVisibility]);

  return (
    <div className={styles.actionsWrapper}>
      <div className={styles.actions}>
        {actionsMenuConfig.map((item) => {
          if (item.onClick) {
            if (item.disabled) return <DisabledAction item={item} />;
            else {
              const showConfirmationTooltip = !!item.confirmTitle;
              return (
                <TooltipWrapper
                  delayShow={TOOLTIP_DELAY_TIME_LONG}
                  tooltip={phrases.CLOSE_POSITION_INTERVIEW_SECTION_BUTTON}
                  flexGrow={false}
                  enabled={showConfirmationTooltip}
                  key={item.title}
                >
                  <button
                    onClick={() =>
                      showConfirmationTooltip
                        ? emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
                            text: item.confirmTitle,
                            confirmLabel: item.confirmLabel,
                            onConfirm: () => onItemClick(item),
                          })
                        : onItemClick(item)
                    }
                    disabled={item.disabled}
                    className={classNames(styles.actionButton, {
                      [styles.inactive]: item.disabled,
                    })}
                  >
                    <SvgIcon icon={item.icon} className={styles.icon} />
                    <UIText small className={styles.actionTitle}>
                      {item.title}
                    </UIText>
                  </button>
                </TooltipWrapper>
              );
            }
            return null;
          }
        })}
      </div>
      {creator && (
        <div className={styles.requisitionCreatorBoxWrapper}>
          <RequisitionCreatorBox
            title={phrases.NEW_REQUISITION_SEARCH_CREATED_STATUS}
            date={created}
            creator={creator}
          />
        </div>
      )}
    </div>
  );
};

export const ActionsMenu: React.FC<ActionsMenuT> = (props) => {
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [containerRef] = useOutsideClick(
    useCallback(() => setMenuVisibility(false), []),
    { useCapture: true }
  );

  return (
    <TooltipWrapper
      enabled={!isMenuVisible}
      tooltip={phrases.KANBAN_CARD_ACTIONS_DESCRIPTION}
      placement="top-end"
      containerClassName={styles.descriptionTooltipWrapper}
      triggerClassName={styles.actionsMenuTrigger}
    >
      <div
        data-testid="kanban-card-options-menu-trigger"
        className={styles.actionsButton}
        ref={containerRef}
      >
        <SvgIcon
          icon="dots"
          width="2rem"
          height="2rem"
          onClick={() => setMenuVisibility(true)}
        />
        {isMenuVisible && (
          <div className={styles.kanbanActionsTooltipWrapper}>
            <Actions {...props} setMenuVisibility={setMenuVisibility} />
          </div>
        )}
      </div>
    </TooltipWrapper>
  );
};
