import 'normalize.css/normalize.css';
import '@air/styles/index.css';
import 'styles/index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { init as initRaven } from '@air/utils/sentry';
// Direct import to avoid circular dependency.
import App from 'features/App/App';

// Insert icons sprite as inline svg.
fetch(`/${SVG_SPRITE_FILE}`)
  .then((res) => res.text())
  .then((res) => document.body.insertAdjacentHTML('beforeend', res));

initRaven(() => {
  ReactDOM.render(<App />, document.getElementById('app'));
});
