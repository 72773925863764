import React, { useMemo, useState, useEffect, useRef } from 'react';
import prettierBytes from 'prettier-bytes';
import useBeforeUnload from 'react-use/lib/useBeforeUnload';
import classNames from 'classnames';
import { useForm, useFieldArray } from 'react-hook-form';
import { useCallbackRef } from 'use-callback-ref';
import { toast } from '@air/third-party/toast';

import { Header, Paragraph, Button, FormField, SvgIcon } from '@air/components';
import { Onboarding } from 'features';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { useCacheContext, useCacheMethods } from '@air/providers/CacheProvider';
import * as helpCenterApi from 'features/HelpCenter/helpCenterApi';
import { customerProfileSelectors, cacheSelectors } from 'selectors';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import { GA_LABEL_USER_GUIDE_CLICK } from 'constants/gaLabels';

import styles from './HelpCenter.css';

const TOTAL_FILE_SIZE_LIMIT = 25000000; // bytes

type HelpFormData = {
  message: string;
  attachedFiles: [{ id: string; value: File }];
};

export const HelpCenter = () => {
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );

  const helpCenterForm = useCacheContext(cacheSelectors.helpCenterForm);
  const { updateCache } = useCacheMethods();

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues: getFormValues,
    formState: { isValid: isFormValid, isDirty: isFormDirty },
  } = useForm<HelpFormData>({
    defaultValues: helpCenterForm,
    mode: 'onChange',
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachedFiles',
  });

  const addFilesFieldRef = useCallbackRef(null, (element: HTMLInputElement) => {
    element &&
      element.addEventListener('change', () => {
        if (element.files.length) {
          Array.from(element.files).forEach((file) => {
            append({ value: file });
          });
        }
      });
  });

  useBeforeUnload(
    isFormSubmitting || isFormDirty,
    phrases.HELP_CENTER_CLOSE_CONFIRMATION_TEXT
  );

  const formStateRef = useRef<{
    isFormDirty?: boolean;
    isFormSubmitting?: boolean;
  }>({});

  // we save `isFormDirty` and `isFormSubmitting` to the static ref
  // variable to be able to use it later deciding if we
  // need to save form in cache when component unmounts
  useEffect(() => {
    formStateRef.current = {
      isFormDirty,
      isFormSubmitting,
    };
  }, [isFormDirty, isFormSubmitting]);

  // when component unmounts, and we have dirty form
  // which is not submitting at the moment - we
  // save form in cache to let user edit it later
  useEffect(() => {
    return () => {
      if (
        formStateRef.current.isFormDirty &&
        !formStateRef.current.isFormSubmitting
      ) {
        updateCache({ helpCenterForm: getFormValues() });
      }
    };
  }, [getFormValues, updateCache]);

  const onSubmit = async (data: HelpFormData) => {
    if (isFormSubmitting) {
      return;
    }
    setIsFormSubmitting(true);

    const body = new FormData();
    body.append('message', data.message);

    if (data.attachedFiles?.length) {
      for (const file in data.attachedFiles) {
        body.append('attachedFiles', data.attachedFiles[file].value);
      }
    }

    updateCache({ helpCenterForm: undefined });
    await helpCenterApi.sendMessage(body).fork(
      () => {
        toast.error(phrases.HELP_CENTER_SUBMIT_ERROR);
      },
      () => {
        setIsFormSubmitted(true);
        reset();
      }
    );
    setIsFormSubmitting(false);
  };

  const totalFileSize = useMemo(() => {
    if (fields.length) {
      return fields.reduce((total, field) => total + field.value.size, 0);
    } else {
      return 0;
    }
  }, [fields]);

  const isFileSizeValid = totalFileSize <= TOTAL_FILE_SIZE_LIMIT;

  const userGuideLink = isStandaloneAtsUser
    ? urls.USER_GUIDE_STANDALONE_VERSION
    : urls.USER_GUIDE_ATS_VERSION;

  const openUserGuide = () => {
    trackEvent({
      category: GACategory.ContactUs,
      label: GA_LABEL_USER_GUIDE_CLICK,
    });
    window.open(userGuideLink);
  };

  return (
    <div className={styles.helpCenterWrapper}>
      <Header level={1} bolder className={styles.helpCenterHeader}>
        {phrases.HELP_CENTER_HEADER}
      </Header>
      <div
        className={classNames(
          styles.helpCenterSection,
          styles.onBoardingSection
        )}
      >
        <Header level={3} bold>
          {phrases.HELP_CENTER_ON_BOARDING_TITLE}
        </Header>
        <Paragraph>{phrases.HELP_CENTER_ON_BOARDING_TEXT}</Paragraph>
        <Onboarding showOpenButton closeButtonText={sharedPhrases.CLOSE} />
      </div>
      <div
        className={classNames(styles.helpCenterSection, styles.userGideSection)}
      >
        <Header level={3} bold>
          {phrases.HELP_CENTER_USER_GUIDE_TITLE}
        </Header>
        <Paragraph>{phrases.HELP_CENTER_USER_GUIDE_TEXT}</Paragraph>
        <Button
          onClick={openUserGuide}
          className={styles.helpCenterButton}
          variant={Button.variants.POSITIVE_CONFIRM}
          icon="external-link-icon"
        >
          {phrases.HELP_CENTER_USER_GUIDE_BUTTON_TEXT}
        </Button>
      </div>
      {isFormSubmitted ? (
        <div
          className={classNames(
            styles.helpCenterSection,
            styles.successSection
          )}
        >
          <Header level={3} bold>
            {phrases.HELP_CENTER_SUCCESS_SUBMIT_TITLE}
          </Header>
          <Paragraph>{phrases.HELP_CENTER_SUCCESS_SUBMIT_TEXT}</Paragraph>
          <Button
            onClick={() => setIsFormSubmitted(false)}
            className={styles.helpCenterButton}
            variant={Button.variants.POSITIVE_CONFIRM}
            icon="send-emails-icon"
          >
            {phrases.HELP_CENTER_SUCCESS_SUBMIT_BUTTON_TEXT}
          </Button>
        </div>
      ) : (
        <form
          className={classNames(
            styles.helpCenterSection,
            styles.messageSection
          )}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Header level={3} bold>
            {phrases.HELP_CENTER_MESSAGE_TITLE}
          </Header>
          <Paragraph>{phrases.HELP_CENTER_MESSAGE_TEXT}</Paragraph>
          <FormField
            // we need to change key to re-create component after submit
            // because it starts to work incorrectly after form reset
            // and doesn't put form in valid state on change
            key={isFormSubmitted ? 'true' : 'false'}
            autosize
            {...register('message', {
              required: true,
            })}
            isDisabled={isFormSubmitting}
            className={styles.helpCenterMessageField}
            maxLength={4000}
            type="textarea"
            label={phrases.HELP_CENTER_MESSAGE_LABEL}
            placeholder={phrases.HELP_CENTER_MESSAGE_PLACEHOLDER}
          />
          {fields.map(({ value: file }, index) => (
            <div
              key={index}
              className={classNames(styles.attachedFileWrapper, {
                [styles.disabled]: isFormSubmitting,
              })}
            >
              <SvgIcon
                className={styles.attachedFileIcon}
                icon="help-center-file-icon"
              />
              <Paragraph bold className={styles.attachedFileName}>
                {file.name}
              </Paragraph>
              <Paragraph small className={styles.attachedFileSize}>
                ({prettierBytes(file.size)})
              </Paragraph>
              <Button
                disabled={isFormSubmitting}
                className={styles.attachedFiledDeleteButton}
                icon="delete"
                variant={Button.variants.INLINE}
                onClick={() => remove(index)}
              />
            </div>
          ))}
          <div className={styles.messageFooter}>
            <Button
              disabled={!isFileSizeValid || isFormSubmitting}
              className={styles.addFilesButton}
              icon="add-stroke"
              variant={Button.variants.TINY}
            >
              <label htmlFor="add-files-field">
                {phrases.HELP_CENTER_UPLOAD_FILE_BUTTON_TEXT}
              </label>
            </Button>
            <input
              disabled={!isFileSizeValid || isFormSubmitting}
              id="add-files-field"
              ref={addFilesFieldRef}
              type="file"
              multiple
            />
            <dl
              className={classNames(styles.fileSizeLimit, {
                [styles.zeroSize]: totalFileSize == 0,
                [styles.validSize]: totalFileSize > 0 && isFileSizeValid,
                [styles.invalidSize]: !isFileSizeValid,
              })}
            >
              <dt>{phrases.HELP_CENTER_FILE_SIZE_LIMIT_TEXT}</dt>
              <dd>
                {totalFileSize ? prettierBytes(totalFileSize) : 0} /{' '}
                {prettierBytes(TOTAL_FILE_SIZE_LIMIT)}
              </dd>
            </dl>
          </div>
          <Button
            isLoading={isFormSubmitting}
            loaderPosition={Button.loaderPosition.LEFT}
            loaderColor="white"
            disabled={!isFileSizeValid || (!isFormValid && !isFormSubmitting)}
            type="submit"
            className={styles.helpCenterButton}
            variant={Button.variants.POSITIVE_CONFIRM}
            icon={isFormSubmitting ? undefined : 'send-emails-icon'}
          >
            {isFormSubmitting
              ? phrases.HELP_CENTER_SENDING_BUTTON_TEXT
              : phrases.HELP_CENTER_SEND_MESSAGE_BUTTON_TEXT}
          </Button>
        </form>
      )}
    </div>
  );
};
