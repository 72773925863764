import React from 'react';
import classNames from 'classnames';

import {
  sortLineupTableData,
  expandedInterviewSectionsInitial,
} from 'components/LineupTable/utils';
import { JobEmailCandidate } from '@air/api';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData';
import * as urls from 'constants/urls';
import { Tooltip, SvgIcon } from '@air/components';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import * as phrases from 'constants/phrases';
import { RecipientsSection } from 'features/SelectRecipientsView/RecipientsSection';

import styles from 'features/SelectRecipientsView/SelectRecipientsView.css';
import { LineupTabs } from '@air/constants/tabs';

const mapRecipientsList = (
  result: JobEmailCandidate[]
): [CandidateSearchProfileStatusEnum, JobEmailCandidate[]][] => {
  // @ts-ignore TODO: find correct type for `sortLineupTableData`
  return sortLineupTableData<JobEmailCandidate>({
    data: result,
  });
};

export const getRecipientsSections = ({
  dataSourceId,
  jobDescriptionId,
  recipientsList,
  selectedRecipients,
  openedSections,
  onSectionTitleClicked,
  onSectionCheckboxClicked,
  onRecipientSelectionChanged,
  candidatePreviewId,
  onRecipientNameClick,
  sentMode = false,
  currentTabInRoute,
}: {
  dataSourceId: number;
  jobDescriptionId: string;
  recipientsList: JobEmailCandidate[];
  selectedRecipients: string[];
  openedSections: {
    [key in CandidateSearchProfileStatusEnum]?: boolean;
  };
  onSectionTitleClicked: (
    sectionName: CandidateSearchProfileStatusEnum
  ) => void;
  onSectionCheckboxClicked?: (
    recipients: JobEmailCandidate[],
    checked: boolean
  ) => void;
  onRecipientSelectionChanged?: (recipientId: string, checked: boolean) => void;
  onRecipientNameClick?: (recipientId: string) => void;
  candidatePreviewId?: string;
  sentMode?: boolean;
  currentTabInRoute: LineupTabs;
}) => {
  const sectionNames = Object.keys<CandidateSearchProfileStatusEnum>(
    expandedInterviewSectionsInitial
  ).filter(
    (item) => item !== CandidateSearchProfileStatusEnum.ATSFAILEDTOPARSE
  );

  return sectionNames.map((sectionStatus, index, array) => {
    if (sectionStatus === CandidateSearchProfileStatusEnum.NOTPROCESSED) {
      return;
    }
    const { title: sectionTitle } =
      expandedInterviewSectionsInitial[sectionStatus];
    const [, recipients] =
      mapRecipientsList(recipientsList).find(
        ([recipientsStatus]) => recipientsStatus === sectionStatus
      ) || [];

    return (
      <RecipientsSection
        sentMode={sentMode}
        key={sectionStatus}
        id={sectionStatus}
        className={classNames({
          [styles.isClosed]: !openedSections[sectionStatus],
          [styles.lastSection]: array[array.length - 1] === sectionStatus,
        })}
        displayName={sectionTitle}
        isOpened={openedSections[sectionStatus]}
        recipients={recipients}
        selectedRecipients={selectedRecipients}
        onSectionTitleClicked={() => onSectionTitleClicked(sectionStatus)}
        onSectionCheckboxClicked={
          onSectionCheckboxClicked
            ? (checked: boolean) =>
                onSectionCheckboxClicked(recipients, checked)
            : undefined
        }
        onRecipientSelectionChanged={onRecipientSelectionChanged}
        openCandidate={(candidateId: string) =>
          window.open(
            urls.makeInterviewProfileUrl({
              dataSourceId,
              jobDescriptionId,
              userId: candidateId,
              tab: currentTabInRoute,
            }),
            '_blank'
          )
        }
        onRecipientNameClick={onRecipientNameClick}
        candidatePreviewId={candidatePreviewId}
      />
    );
  });
};

export const filterRecipients = (
  recipientsList: JobEmailCandidate[],
  filter: string
) => {
  if (filter) {
    return recipientsList.filter(
      (it) =>
        it.firstName?.toLowerCase().includes(filter) ||
        it.lastName?.toLowerCase().includes(filter)
    );
  } else {
    return recipientsList;
  }
};

export const getCandidateIcon = (
  item: JobEmailCandidate,
  sentMode: boolean
) => {
  if (sentMode) {
    return (
      <Tooltip
        trigger="hover"
        delayShow={TOOLTIP_DELAY_TIME_LONG}
        tooltip={phrases.SELECT_RECIPIENTS_DELIVERED_TOOLTIP_TEXT}
        flexGrow={false}
      >
        <SvgIcon className={styles.candidateSentIcon} icon="sent-icon" />
      </Tooltip>
    );
  } else if (!item.primaryEmail) {
    return (
      <Tooltip
        trigger="hover"
        delayShow={TOOLTIP_DELAY_TIME_LONG}
        tooltip={phrases.SELECT_RECIPIENTS_NO_EMAIL_TOOLTIP_TEXT}
        flexGrow={false}
      >
        <SvgIcon
          className={styles.candidateWarningIcon}
          icon="file-upload-error"
        />
      </Tooltip>
    );
  }
};
