// imports from vendor deps
import React, { useCallback, useMemo } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
import {
  RangeDropdown,
  RangeItem,
} from '@air/components/RangeDropdown/RangeDropdown';
import { RangeValue } from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
import {
  isLimitNotDefined,
  RangeIndex,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import { useRangeVisibility } from '@air/components/SelectRangeWidgets/useRangeVisibility';

// imports from styles

import styles from './ManagerialExperienceWidget.css';

// import from constants
import * as phrases from 'customer-portal/src/constants/phrases';

// const
const MIN_MANAGERIAL_EXPERIENCE_INDEX = 0;
const MAX_MANAGERIAL_EXPERIENCE_INDEX = 6;

const MIN_MANAGERIAL_EXPERIENCE_VALUE = 0;
const MAX_MANAGERIAL_EXPERIENCE_VALUE = 6;

const dropdownItems = [
  { label: '0', value: 0 },
  { label: '3', value: 3 },
  { label: '10', value: 10 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '500', value: 500 },
  { label: '+', value: 999 },
];

// component proptypes
type Props = {
  value: any;
  onChange?: (value: number) => void;
  isReadOnly?: boolean;
};

type FieldProps = {
  value: number | null;
  range: RangeValue[];
  onClick?: () => void;
  className?: string;
  isReadOnly?: boolean;
};

// exports / component definitions
export const ManagerialExperienceField: React.FC<FieldProps> = ({
  value,
  range, // TODO: refactor this to use only value without range
  onClick = R.always,
  className,
  isReadOnly = false,
}) => {
  const displayedValue = useMemo(() => {
    const currentValue = range.find((x) => x.value === value);
    return value !== MIN_MANAGERIAL_EXPERIENCE_VALUE &&
      value !== MAX_MANAGERIAL_EXPERIENCE_VALUE &&
      currentValue
      ? `${currentValue.label}+`
      : '';
  }, [value, range]);

  const isAnyExperience = displayedValue === '';

  return (
    <div
      className={classNames(styles.experienceField, className)}
      onClick={onClick}
    >
      {!isAnyExperience && (
        <div className={styles.experienceFieldTitle}>
          {phrases.CRITERIA_CARD_PEOPLE_MANAGED_TITLE}
        </div>
      )}
      {!isAnyExperience && (
        <span
          tabIndex={0}
          className={styles.experienceFieldValue}
          onFocus={onClick}
          onMouseDown={(event: any) => event.preventDefault()}
        >
          {displayedValue}
        </span>
      )}
      {isAnyExperience && (
        <label
          className={classNames(styles.experienceFieldLabel, {
            [styles.experienceFieldLabelReadOnly]: isReadOnly,
          })}
        >
          {phrases.CRITERIA_CARD_PEOPLE_MANAGED_TITLE}
        </label>
      )}
    </div>
  );
};

export const ManagerialExperienceReadOnlyWidget: React.FC<any> = ({
  value,
}) => {
  return (
    <ManagerialExperienceField
      className={styles.experienceReadonlyField}
      range={dropdownItems}
      value={value}
    />
  );
};

type DropdownProps = {
  isOpen: boolean;
  onChange: (values: any) => void;
  dropdownItems: RangeItem[];
  value: any;
};

// exports / component definitions
const ManagerialExperienceDropdown: React.FC<DropdownProps> = ({
  isOpen,
  onChange,
  dropdownItems,
  value,
}) => {
  const selectedIndex = useMemo(() => {
    return R.findIndex(R.propEq('value', value))(dropdownItems);
  }, [value, dropdownItems]);

  const selectedItems = useMemo(() => {
    if (isLimitNotDefined(value) || !value) {
      return R.range(
        MIN_MANAGERIAL_EXPERIENCE_INDEX,
        MAX_MANAGERIAL_EXPERIENCE_INDEX + 1
      );
    }

    return R.range(selectedIndex, MAX_MANAGERIAL_EXPERIENCE_INDEX + 1);
  }, [value, selectedIndex]);

  const isAnyManagerialExperience = useCallback((value: any) => !value, []);

  const getRangeItemClass = useCallback(
    (index: RangeIndex) => {
      return {
        [styles.rangeItem]: true,
        [styles.selectedRangeItem]: R.includes(index, selectedItems),
        [styles.selectedFirstRangeItem]:
          index === R.head(selectedItems) ||
          (index === 0 && isAnyManagerialExperience(value)),
        [styles.selectedLastRangeItem]:
          index === R.last(selectedItems) ||
          (index === dropdownItems.length - 1 &&
            isAnyManagerialExperience(value)),
      };
    },
    [value, selectedItems, dropdownItems.length, isAnyManagerialExperience]
  );

  const getDropdownClass = useCallback(() => {
    return {
      [styles.managerialExperienceDropdown]: true,
      [styles.selectedAnyExperience]: isAnyManagerialExperience(value),
    };
  }, [value, isAnyManagerialExperience]);

  const onItemSelected = useCallback(
    (index: RangeIndex) => {
      if (index === MAX_MANAGERIAL_EXPERIENCE_INDEX) {
        return;
      }
      onChange(index);
    },
    [onChange]
  );

  return (
    <RangeDropdown
      isOpen={isOpen}
      dropdownClass={getDropdownClass}
      rangeItems={dropdownItems}
      rangeItemClass={getRangeItemClass}
      arrowClassName={styles.dropdownArrow}
      selectedItems={selectedItems}
      onItemSelected={onItemSelected}
    />
  );
};

// exports / component definitions
export const ManagerialExperienceWidget: React.FC<Props> = ({
  value,
  onChange,
  isReadOnly = false,
}) => {
  const [
    isDropdownVisible,
    outsideClickRef,
    onDropdownTriggerClicked,
    handleKeyDown,
  ] = useRangeVisibility();

  const getValueByIndex = (index: RangeIndex) => dropdownItems[index].value;

  const onChangeDropdownValues = (index: RangeIndex) => {
    const newSelectedValue = getValueByIndex(index);
    onChange(newSelectedValue);
  };

  return (
    <div
      className={classNames(styles.managerialExperienceWidget, {
        [styles.isDropdownOpened]: isDropdownVisible,
      })}
      ref={outsideClickRef}
      onKeyDownCapture={handleKeyDown}
    >
      <ManagerialExperienceField
        value={value}
        isReadOnly={isReadOnly}
        range={dropdownItems}
        onClick={isReadOnly ? R.noop : onDropdownTriggerClicked}
      />
      {!isReadOnly && (
        <ManagerialExperienceDropdown
          dropdownItems={dropdownItems}
          value={value}
          onChange={onChangeDropdownValues}
          isOpen={isDropdownVisible}
        />
      )}
    </div>
  );
};
