import { CandidateProfileContextT } from 'providers/CandidateProfileProvider';

export const currentCandidateProfileNote = (state: CandidateProfileContextT) =>
  state.currentCandidateProfileNotes?.[0];
export const currentCandidateProfile = (state: CandidateProfileContextT) =>
  state.currentCandidateProfile;
export const currentCandidateProfileId = (state: CandidateProfileContextT) =>
  state.currentCandidateProfile?.id;
export const currentCandidateProfileStatus = (
  state: CandidateProfileContextT
) => state.currentCandidateProfile?.status;
export const activeCandidates = (state: CandidateProfileContextT) =>
  state.active;
export const passiveCandidates = (state: CandidateProfileContextT) =>
  state.passive;
export const matchMinerCandidates = (state: CandidateProfileContextT) =>
  state.matchMiner;
export const matchScoutCandidates = (state: CandidateProfileContextT) =>
  state.matchScout;
export const selectedSortForSearches = (state: CandidateProfileContextT) =>
  state.loadAllCandidatesParams.sort;
export const filterQuery = (state: CandidateProfileContextT) =>
  state.loadAllCandidatesParams.lastFilterName;
