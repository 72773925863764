import React, { useCallback, useRef, useEffect } from 'react';
import classNames from 'classnames';

import styles from './ConfirmButton.css';
import { Button } from '@air/components';
import { ButtonVariants } from '@air/components/Button/Button';
import * as phrases from 'customer-portal/src/constants/phrases';
import { useStateMachine } from '@air/hooks';
import { useOutsideClick } from '@air/utils/hooks';
import { CLICK_DEBOUNCE_TIME_LONG } from '@air/constants/app';

type Props = {
  actionButtonVariant?: ButtonVariants;
  confirmButtonVariant?: ButtonVariants;
  performAction: React.ReactEventHandler;
  confirmButtonLabel?: string;
  actionButtonLabel: string;
  confirmMessage?: string;
  disabled?: boolean;
  showActionButtonHoverIcon?: boolean;
};

enum ConfirmButtonState {
  hidden = 'hidden',
  confirmed = 'confirmed',
  canceled = 'canceled',
  faded = 'faded',
}

enum ConfirmButtonAction {
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  FADE = 'FADE',
  FINISH = 'FINISH',
}

const ConfirmButtonConfig = {
  /* initial and final state for animations*/
  [ConfirmButtonState.hidden]: {
    [ConfirmButtonAction.CONFIRM]: ConfirmButtonState.confirmed,
  },
  /**
   * user clicked on button and confirm button is shown
   */
  [ConfirmButtonState.confirmed]: {
    [ConfirmButtonAction.CANCEL]: ConfirmButtonState.canceled,
    [ConfirmButtonAction.FADE]: ConfirmButtonState.faded,
  },
  /**
   * confirm button is transitioned into this state, when
   * user moves mouse outside of a button and slow fading animation
   * starts running
   */
  [ConfirmButtonState.faded]: {
    [ConfirmButtonAction.CONFIRM]: ConfirmButtonState.confirmed,
    [ConfirmButtonAction.CANCEL]: ConfirmButtonState.canceled,
    [ConfirmButtonAction.FINISH]: ConfirmButtonState.hidden,
  },
  /**
   * when user clicks outside and cancels confirmation state,
   * fast animations starts
   */
  [ConfirmButtonState.canceled]: {
    [ConfirmButtonAction.FINISH]: ConfirmButtonState.hidden,
  },
};

const LEAVE_CONFIRM_BUTTON_DELAY = 600;
const ANIMATION_RATE_FACTOR = 10;

const isConfirmDisappearanceAnimation = (animationName: string) =>
  /confirmButtonDisappearance/.test(animationName);

export const ConfirmButton: React.FC<Props> = ({
  actionButtonVariant,
  confirmButtonVariant,
  disabled,
  performAction,
  showActionButtonHoverIcon,
  confirmButtonLabel,
  actionButtonLabel,
  confirmMessage,
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const [current, dispatch] = useStateMachine(
    ConfirmButtonConfig,
    ConfirmButtonState.hidden,
    {}
  );

  const confirmAction = useCallback(
    () => dispatch(ConfirmButtonAction.CONFIRM),

    [dispatch]
  );
  const cancelAction = useCallback(() => dispatch(ConfirmButtonAction.CANCEL), [
    dispatch,
  ]);

  const buttonClickHandler = useCallback(
    (e) => {
      if (current.matches(ConfirmButtonState.confirmed)) {
        performAction(e);
      } else {
        confirmAction();
      }
    },
    [current, confirmAction, performAction]
  );
  const [outsideClickRef] = useOutsideClick(cancelAction, {
    useCapture: true,
  });

  useEffect(() => {
    if (current.in([ConfirmButtonState.canceled, ConfirmButtonState.faded])) {
      btnRef?.current &&
        btnRef.current
          .getAnimations()
          .filter((animation: Animation & AnimationEvent) =>
            isConfirmDisappearanceAnimation(animation?.animationName)
          )
          .forEach(function (animation: Animation & AnimationEvent) {
            if (current.matches(ConfirmButtonState.canceled)) {
              animation.updatePlaybackRate(
                animation.playbackRate * ANIMATION_RATE_FACTOR
              );
            }
            animation.finished.then(
              () => dispatch(ConfirmButtonAction.FINISH),
              () => dispatch(ConfirmButtonAction.FINISH)
            );
          });
    }
  }, [current, dispatch]);

  const leaveButtonArea = () => {
    setTimeout(
      () => dispatch(ConfirmButtonAction.FADE),
      LEAVE_CONFIRM_BUTTON_DELAY
    );
  };

  const isMessageShown = !current.matches(ConfirmButtonState.hidden);

  return (
    <div className={styles.confirmWrapper} ref={outsideClickRef}>
      <div className={styles.buttonsWrapper} onMouseLeave={leaveButtonArea}>
        <Button
          variant={actionButtonVariant}
          className={classNames({
            [styles.actionButton]: !disabled,
            [styles.actionButtonHoverIcon]: showActionButtonHoverIcon,
          })}
          disabled={disabled}
          onClick={buttonClickHandler}
        >
          {actionButtonLabel}
        </Button>

        {!disabled && (
          <Button
            debounceTime={CLICK_DEBOUNCE_TIME_LONG}
            ref={btnRef}
            variant={confirmButtonVariant}
            className={classNames(styles.confirmButton, {
              [styles.visible]: current.matches(ConfirmButtonState.confirmed),
              [styles.invisible]: current.in([
                ConfirmButtonState.canceled,
                ConfirmButtonState.faded,
              ]),
            })}
            disabled={disabled}
            onClick={performAction}
            onMouseEnter={confirmAction}
          >
            <span>{confirmButtonLabel || phrases.CONFIRM_BUTTON}</span>
          </Button>
        )}
      </div>

      {confirmMessage && (
        <p
          className={classNames(styles.confirmMessage, {
            [styles.visible]: isMessageShown,
            [styles.invisible]: !isMessageShown,
          })}
          dangerouslySetInnerHTML={{ __html: confirmMessage }}
        />
      )}
    </div>
  );
};
