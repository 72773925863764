import { toast as reactToastify, ToastOptions } from 'react-toastify';

type ToastTypeT = 'info' | 'success' | 'warning' | 'error';
function getToastDefaults(toastType: ToastTypeT, classModifier: string) {
  return function (phrase: React.ReactNode, options?: ToastOptions) {
    return reactToastify[toastType](phrase, {
      ...options,
      className: [
        `Toastify__toast--${toastType}-${classModifier}`,
        options?.className,
      ].join(' '),
    });
  };
}

export const toast = {
  ...reactToastify,
  dark: getToastDefaults('info', 'dark'),
};
