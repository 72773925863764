import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import styles from './Note.css';
import { usePrevious } from '@air/utils/hooks';

type NoteProps = {
  title: string;
  note: string;
  noteMaxLength?: number | null;
  isReadOnly?: boolean;
  onChange: (value: string) => void;
  onClick?: React.ReactEventHandler;
  className?: string;
  isBigger?: boolean;
};

export const Note = React.forwardRef<HTMLDivElement, NoteProps>(
  (
    {
      className,
      title,
      noteMaxLength = null,
      note = '',
      isReadOnly = false,
      onChange,
      onClick = null,
      isBigger = false,
    },
    ref
  ) => {
    const previousReadOnly = usePrevious(isReadOnly);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (
        !isReadOnly &&
        (previousReadOnly === undefined || previousReadOnly === true)
      ) {
        textAreaRef.current?.focus();
      }
    }, [isReadOnly, previousReadOnly]);

    return (
      <div
        ref={ref}
        className={classNames(className, styles.noteContainer, {
          [styles.bigger]: isBigger,
        })}
        onClick={onClick}
      >
        <h2>{title}</h2>
        <textarea
          disabled={isReadOnly}
          ref={textAreaRef}
          maxLength={noteMaxLength}
          className={styles.noteTextarea}
          value={note}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    );
  }
);
