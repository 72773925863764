import React, { useEffect, useState } from 'react';
import { Loader } from '@air/components';
import * as phrases from 'constants/phrases';
import { useKanbanContext } from 'providers/KanbanProvider';
import { kanbanSelectors } from 'selectors';
import { getAmountOfParsedCandidatesForMMSearch } from 'domain/MatchServices/MatchServices';

export const MatchMinerTooltip = () => {
  const atsId = useKanbanContext(kanbanSelectors.atsId);
  const externalJobDescriptionId = useKanbanContext(
    kanbanSelectors.externalJobDescriptionId
  );

  const [candidateCount, setCandidateCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getParsedCandidates = async () => {
      const response = await getAmountOfParsedCandidatesForMMSearch(
        atsId,
        externalJobDescriptionId
      );

      if ('candidateCount' in response) {
        setCandidateCount(response.candidateCount);
      }
      setIsLoading(false);
    };

    getParsedCandidates();
  }, [externalJobDescriptionId, atsId]);

  return isLoading ? (
    <Loader color="blue" />
  ) : (
    <>
      <span>{phrases.MATCH_MINER_TAB_TOOLTIP}</span>
      {!!candidateCount && (
        <>
          <br />
          <span>
            {phrases.MATCH_MINER_UPDATED_TOOLTIP[0]}
            <b>
              {candidateCount} {phrases.MATCH_MINER_UPDATED_TOOLTIP[1]}
            </b>
          </span>
        </>
      )}
    </>
  );
};
