/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum LicenseType {
  TRIALEXPIRED = 'TRIAL_EXPIRED',
  TRIALEXPIREDFULLREQUESTED = 'TRIAL_EXPIRED_FULL_REQUESTED',
  TRIALACTIVE = 'TRIAL_ACTIVE',
  TRIALACTIVEFULLREQUESTED = 'TRIAL_ACTIVE_FULL_REQUESTED',
  FULL = 'FULL',
}
