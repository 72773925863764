import R from '@air/third-party/ramda';
import { SearchExperience, SearchManagerialExperience } from '@air/api';
import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  filterExperienceByType,
  getExperience,
} from 'domain/CandidateData';
import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import { criteriaImportanceOptions } from 'constants/skills';
import * as phrases from 'constants/phrases';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateManagerialData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.managerial;
}

export const mapExperienceToCandidateManagerialData = (
  managerial: SearchManagerialExperience & { idx: number }
): CandidateManagerialData => {
  const managerialItem = {
    id: managerial.idx,
    label: phrases.EXPERIENCE_MANAGERIAL_TITLE_PLACEHOLDER,
    alert: getAlertData(managerial.alerts),
    experience: getExperience(managerial),
    status: managerial.status,
    interview: interviewDataMapper(managerial.refId, managerial.interview),
  };

  return {
    id: managerial.idx,
    refId: managerial.refId,
    idx: managerial.idx,
    cardType: CardType.managerial,
    list: [managerialItem],
    importance: criteriaImportanceOptions[managerial.importance],
    status: managerial.status,
  };
};

export const filterManagerialExperience =
  filterExperienceByType<'managerial'>('managerial');

export const mapManagerialExperience = R.compose<
  SearchExperience[][],
  (SearchManagerialExperience & { idx: number })[],
  (SearchManagerialExperience & { idx: number })[],
  CandidateManagerialData[]
>(
  R.map(mapExperienceToCandidateManagerialData),
  R.filter<SearchManagerialExperience & { idx: number }>(Boolean),
  filterManagerialExperience
);
