import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import { getAlertData } from '@air/domain/Alerts/AlertData';
import { SearchLocationInfoResponse, SearchLocationResponse } from '@air/api';
import { optionalOrImportantOptions } from 'constants/skills';
import {
  BaseCandidateData,
  CandidateCriteriaListItem,
  getCriteriaItems,
} from 'domain/CandidateData/CandidateCriteria/BaseCandidateData';
import { interviewDataMapper } from '@air/domain/InterviewNotes/InterviewData';
import R from '@air/third-party/ramda';

export interface CandidateLocationData
  extends BaseCandidateData,
    BaseCriteriaWithList<CandidateCriteriaListItem> {
  cardType: CardType.location;
  distanceToCriteriaLocation: number;
}

const getLocationItem = (
  listItem: SearchLocationInfoResponse
): CandidateCriteriaListItem => {
  return {
    id: listItem.id || listItem.fullName,
    label: listItem.fullName,
    status: listItem.status,
    alert: getAlertData(listItem.alerts),
    distanceToCriteriaLocation: listItem.distanceToCriteriaLocation,
    interview: interviewDataMapper(listItem.refId, listItem.interview),
  };
};

export const mapLocationsToCandidateLocationData = (
  location: SearchLocationResponse
): CandidateLocationData => {
  if (R.isNullOrEmpty(location)) {
    return null;
  }

  const [, primaryItem] = getCriteriaItems<
    SearchLocationResponse,
    CandidateCriteriaListItem
  >(getLocationItem, location);

  return {
    idx: location.idx,
    id: CardType.location,
    cardType: CardType.location,
    refId: location.refId,
    importance: optionalOrImportantOptions[location.importance],
    distanceToCriteriaLocation: primaryItem.distanceToCriteriaLocation,
    list: [primaryItem], // we show only one card on candidate profile that is primary
    status: primaryItem.status,
  };
};
