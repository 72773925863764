import * as R from 'ramda';
import camelCase from 'camelcase';
import { debounce } from '@air/utils/debounce';
// @ts-ignore write own types or replace with other library
import { shallowEqualArrays, shallowEqualObjects } from 'shallow-equal';
import stringArraysEqual from 'string-arrays-equal';
import { SyntheticEvent } from 'react';

const isNullOrEmpty = (value: any): boolean =>
  R.isNil(value) || R.isEmpty(value);

const noop = () => {};
const preventDefaultNoop = (e: SyntheticEvent) => {
  e.preventDefault();
};

const indexedMap =
  <T, S>(cb: (item: T, idx: number, l?: T[]) => S) =>
  (values: T[]): S[] =>
    R.addIndex<T, S>(R.map)(cb, values);

const randomInt = (minimum: number, maximum: number) => {
  if (maximum === undefined) {
    maximum = minimum;
    minimum = 0;
  }
  return Math.floor(Math.random() * (maximum - minimum + 1) + minimum);
};

const notNullMap = <T, S>(cb: (item: T) => S | null) =>
  R.compose<T[][], S[], S[]>(R.filter<S>(Boolean), R.map(cb));

const textByCount = R.curry(
  (singularText: string, pluralText: string, count: number) =>
    `${count} ${count === 1 ? singularText : pluralText}`
);

const delay = async (timeout: number) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

type UnknownObject = { [key: string]: unknown };
type ComparatorFunction = (a: unknown, b: unknown) => boolean;
const shallowEqualCollection: ComparatorFunction = (
  a: UnknownObject[],
  b: UnknownObject[]
): boolean => {
  if (a?.length !== b?.length) {
    return false;
  } else if (R.isEmpty(a) && R.isEmpty(b)) {
    return shallowEqualArrays(a, b);
  } else {
    return a.every((it, index) => shallowEqualObjects(it, b[index]));
  }
};

type Comparator = { [key: string]: ComparatorFunction };
export const arePropsShallowEqual =
  <Props extends UnknownObject>(comparator: Comparator) =>
  (prevProps: Props, nextProps: Props) => {
    const comparatorKeys = Object.keys(comparator);

    const arePropsEqual = shallowEqualObjects(
      R.omit(comparatorKeys, prevProps),
      R.omit(comparatorKeys, nextProps)
    );

    return (
      arePropsEqual &&
      (R.isEmpty(comparatorKeys)
        ? true
        : comparatorKeys.every((key) => {
            return comparator[key](prevProps[key], nextProps[key]);
          }))
    );
  };

function isFunction(fn: unknown): fn is (...args: any[]) => void {
  return typeof fn === 'function';
}

const extended = {
  ...R,
  textByCount,
  camelCase,
  randomInt,
  noop,
  preventDefaultNoop,
  isNullOrEmpty,
  isFunction,
  indexedMap,
  notNullMap,
  debounce,
  delay,
  shallowEqualArrays,
  shallowEqualObjects,
  shallowEqualCollection,
  arePropsShallowEqual,
  stringArraysEqual,
};

export default extended;
