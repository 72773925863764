import React, { ForwardRefExoticComponent, LegacyRef } from 'react';
import { RouteProps } from 'react-router-dom';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { SvgIcon } from '@air/components';

// imports from styles
import styles from './TypeaheadSearchField.css';
import * as phrases from 'customer-portal/src/constants/phrases';

export enum TypeaheadSearchFieldVariants {
  SMALL = 'small',
}

type Props = {
  isClearingInput?: boolean;
  currentSearchNameValue?: string;
  nameFilterField?: string;
  placeholderValue?: string;
  handleInputFocus?: () => void;
  handleInputBlur?: (e: any) => void;
  handleKeydown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hoverOverRejectIcon?: boolean;
  setHoverOverRejectIcon?: (value: boolean) => void;
  removeInput?: () => void;
  ref?: LegacyRef<HTMLInputElement>;
  onSubmit?: () => void;
  variant?: TypeaheadSearchFieldVariants;
} & RouteProps;

export const TypeaheadSearchField: ForwardRefExoticComponent<Props> =
  React.forwardRef(
    (
      {
        isClearingInput,
        currentSearchNameValue = '',
        nameFilterField = 'searchNameFilter',
        placeholderValue = phrases.SEARCH_FILTER_PLACEHOLDER,
        handleInputFocus = R.noop,
        handleInputBlur = R.noop,
        handleKeydown = R.noop,
        handleChange = R.noop,
        hoverOverRejectIcon,
        setHoverOverRejectIcon = R.noop,
        removeInput = R.noop,
        onSubmit = null,
        variant,
      },
      ref
    ) => {
      const { register } = useFormContext();
      return (
        <div
          className={classNames(styles.typeaheadSearchField, {
            [styles.small]: variant === TypeaheadSearchFieldVariants.SMALL,
          })}
        >
          {isClearingInput && (
            <span className={styles.inputClearingAnimation}>
              {currentSearchNameValue}
            </span>
          )}
          <input
            ref={ref}
            autoComplete="off"
            placeholder={placeholderValue}
            onFocus={handleInputFocus}
            onKeyDown={handleKeydown}
            className={classNames(styles.filterInput, {
              [styles.hoverOverRejectIcon]: hoverOverRejectIcon,
            })}
            {...register(nameFilterField, {
              onBlur: handleInputBlur,
              onChange: handleChange,
            })}
          />
          <SvgIcon
            icon="search-icon"
            onClick={onSubmit}
            className={classNames(styles.filterIcon, {
              [styles.disabled]: hoverOverRejectIcon,
            })}
          />
          {currentSearchNameValue?.length > 0 && (
            <div
              className={styles.rejectIconWrapper}
              onMouseEnter={() => setHoverOverRejectIcon(true)}
              onMouseLeave={() => setHoverOverRejectIcon(false)}
            >
              <SvgIcon
                icon="rejected-icon"
                onClick={removeInput}
                className={classNames(styles.rejectIcon)}
              />
            </div>
          )}
        </div>
      );
    }
  );

TypeaheadSearchField.displayName = 'TypeaheadSearchField';
