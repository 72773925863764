import * as ApiModel from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  SearchCriteriaListValue,
  calculateAcceptAny,
} from 'domain/SearchCriteria/BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

type MajorCriteriaListValue = SearchCriteriaListValue<{ value: number }>;

export interface MajorCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.major;
  idealList: MajorCriteriaListValue[] | null;
  acceptableList: MajorCriteriaListValue[] | null;
}

export const isMajor = (
  item: BaseSearchCriteriaData
): item is MajorCriteriaData => item.cardType === CardType.major;

export const getMajorCriteriaDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
}: AddCriteriaInitials): MajorCriteriaData => ({
  idx,
  id: CardType.major,
  key: genId(),
  initialCardStatus,
  cardType: CardType.major,
  idealList: null,
  acceptableList: null,
  importance: criteriaImportanceOptions[importance],
});

const convertMajorListToSelectValues = (
  list: ApiModel.EducationMajorGroupDetail[] | null
): MajorCriteriaListValue[] =>
  list
    ? list.map((item: any) => ({ value: item.id, label: item.fullName }))
    : null;

const convertSelectValuesToMajorList = (
  list: MajorCriteriaListValue[]
): ApiModel.EducationMajorGroupDetail[] =>
  list
    ? list.map((item: any) => ({ id: item.value, fullName: item.label }))
    : null;

export const mapEducationToMajorCriteria = (
  major: ApiModel.EducationMajorData
): MajorCriteriaData => ({
  idx: major.idx,
  id: CardType.major,
  key: genId(),
  cardType: CardType.major,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  idealList: convertMajorListToSelectValues(major.idealList),
  acceptableList: convertMajorListToSelectValues(major.acceptableList),
  importance: criteriaImportanceOptions[major.importance],
});

export const mapMajorCriteriaToEducation = (
  major: MajorCriteriaData
): ApiModel.EducationMajorData => ({
  idx: major.idx,
  setInCriteria: true,
  acceptAny: calculateAcceptAny(major),
  idealList: convertSelectValuesToMajorList(major.idealList),
  acceptableList: convertSelectValuesToMajorList(major.acceptableList),
  importance: major.importance.value,
});
