import { useCallback, useEffect, useRef } from 'react';

export function useDelayedEvent(
  delayStartCallback: any,
  delayEndCallback: any,
  delayTime = 0
) {
  const timeoutId = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const onDelayStart = useCallback(
    (event?: any) => {
      event?.persist();
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(delayStartCallback, delayTime, event);
    },
    [delayStartCallback, delayTime]
  );

  const onDelayEnd = useCallback(
    (event?: any) => {
      event?.persist();
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      delayEndCallback(event);
    },
    [delayEndCallback]
  );

  return [onDelayStart, onDelayEnd];
}
