import React, {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useCallback,
} from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { SvgIcon, UIText } from '@air/components';

import styles from './Checkbox.css';

type CheckboxProps = {
  label?: string;
  onChange?: ({ checked, label }: { checked: boolean; label: string }) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  className?: string;
  disabled?: boolean;
  name?: string;
};
type CheckboxT = ForwardRefExoticComponent<
  PropsWithoutRef<CheckboxProps> & RefAttributes<HTMLInputElement>
>;
export const Checkbox: CheckboxT = React.forwardRef(
  (
    {
      label,
      onChange = R.noop,
      checked,
      defaultChecked,
      className,
      disabled,
      ...props
    },
    ref
  ) => {
    const onChangeCb = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        onChange({ checked, label });
      },
      [onChange, label]
    );

    return (
      <label
        className={classNames(styles.checkboxLabel, className, {
          [styles.isChecked]: checked,
          [styles.disabledLabel]: disabled,
        })}
      >
        <input
          ref={ref}
          disabled={disabled}
          className={styles.checkbox}
          type="checkbox"
          name={label}
          onChange={onChangeCb}
          defaultChecked={defaultChecked}
          checked={checked}
          {...props}
        />
        <div
          className={classNames(styles.checkboxIconWrapper, {
            [styles.unchecked]: !checked,
          })}
        >
          <SvgIcon icon="checkbox-new" className={styles.checkboxIcon} />
        </div>
        <UIText small>{label}</UIText>
      </label>
    );
  }
);
Checkbox.displayName = 'Checkbox';
