import React from 'react';
import { SvgIcon } from '@air/components';
import classNames from 'classnames';

import styles from './Spinner.css';

export const Spinner: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return children ? (
    React.cloneElement(children as React.ReactElement<any>, {
      className: classNames(styles.spinner, className),
    })
  ) : (
    <SvgIcon
      className={classNames(styles.spinner, className)}
      icon="spinner"
      width="2em"
      height="2em"
    />
  );
};
Spinner.displayName = 'Spinner';
