import R from '@air/third-party/ramda';
import { Position, SearchCreateRequest, SearchCriteriaV2 } from '@air/api';

import {
  mapCriteriaDataToCriteriaRequest,
  SearchCriteriaData,
  sortByImportance,
} from 'domain/SearchCriteria/SearchCriteriaMapper';

/*
  Position dates should be sorted by chronology of 'endDate' years.
  If year === 0, it means that position is still active, and
  should be displayed first.
  If two endDates have same year, we compare 'endMonth' fields first,
  and if they're the same as well, we compare 'startYear' and  'startMonth'
  respectively.
*/
export const comparePositionsByDate = (a: Position, b: Position) => {
  const endYearA = R.path<number>(['endDate', 'year'], a);
  const endYearB = R.path<number>(['endDate', 'year'], b);
  if (endYearA === endYearB) {
    const endMonthA = +R.path<number | null>(['endDate', 'month'], a);
    const endMonthB = +R.path<number | null>(['endDate', 'month'], b);
    if (endYearA !== 0 && endYearB !== 0 && endMonthA !== endMonthB) {
      return endMonthB - endMonthA;
    }

    const startYearA = R.path<number>(['startDate', 'year'], a);
    const startYearB = R.path<number>(['startDate', 'year'], b);
    if (startYearB !== startYearA) {
      return startYearB - startYearA;
    } else {
      const startMonthA = +R.path<number>(['startDate', 'month'], a);
      const startMonthB = +R.path<number>(['startDate', 'month'], b);
      return startMonthB - startMonthA;
    }
  } else {
    if (endYearA === 0) return -1;
    if (endYearB === 0) return 1;
    return endYearB - endYearA;
  }
};

export const formSearchRequest = (values: any): SearchCreateRequest => {
  const { ats, name, criteria, redFlags } = R.pick<any, any>(
    ['ats', 'name', 'criteria', 'redFlags'],
    values
  );
  const updatedValues: SearchCriteriaData[] = criteria
    .sort(sortByImportance)
    .map((criteria: any, idx: any) => ({ ...criteria, idx: idx }));

  const criteriaRequest: SearchCriteriaV2 =
    mapCriteriaDataToCriteriaRequest(updatedValues);

  return {
    ats,
    name,
    criteria: criteriaRequest,
    redFlags,
    order: null,
    parentId: null,
  };
};

export const searchHasEmptySkills = R.compose(
  R.any(R.compose(R.isEmpty, R.prop('list'))),
  R.path(['criteria', 'skills'])
);
