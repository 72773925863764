import { Task } from '@air/utils/fp';
import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';

import { WatcherResponse, WatchersListResponse } from '@air/api';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';

type WatchersListParamsT = {
  jobDescriptionId: string;
  dataSourceId: number | string;
  userId?: number;
  criteria?: string;
  size?: number;
};

type WatchersListParamsTokenT = {
  jobDescriptionId: string;
  dataSourceId: string;
  unsubscribeToken: string;
};

const LIST_SIZE = 10000;

export const fetchWatchersList = ({
  jobDescriptionId,
  dataSourceId,
  size = LIST_SIZE,
}: WatchersListParamsT): Task<WatchersListResponse> => {
  return Http.get(urls.WATCHERS_LIST_URL)
    .bind(
      Http.withQueryParams({
        jobDescriptionId,
        dataSourceId,
        size,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchPotentialWatchersList = ({
  jobDescriptionId,
  dataSourceId,
  criteria,
  size = LIST_SIZE,
}: WatchersListParamsT): Task<WatchersListResponse> => {
  return Http.get(urls.WATCHERS_LIST_POTENTIAL_URL)
    .bind(
      Http.withQueryParams({
        jobDescriptionId,
        dataSourceId,
        criteria,
        size,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const subscribeWatcher = ({
  jobDescriptionId,
  dataSourceId,
  userId,
}: WatchersListParamsT): Task<WatcherResponse> => {
  return Http.post(urls.WATCHER_SUBSCRIBE_URL)
    .bind(
      Http.withQueryParams({
        jobDescriptionId,
        dataSourceId,
        userId,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const unsubscribeWatcher = ({
  jobDescriptionId,
  dataSourceId,
  userId,
}: WatchersListParamsT): Task<WatcherResponse> => {
  return Http.remove(urls.WATCHER_UNSUBSCRIBE_URL)
    .bind(
      Http.withQueryParams({
        jobDescriptionId,
        dataSourceId,
        userId,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const unsubscribeWatcherByToken = ({
  jobDescriptionId,
  dataSourceId,
  unsubscribeToken,
}: WatchersListParamsTokenT): Task<WatcherResponse> => {
  return Http.remove(urls.WATCHER_UNSUBSCRIBE_BY_TOKEN_URL)
    .bind(
      Http.withQueryParams({
        jobDescriptionId,
        dataSourceId,
        unsubscribeToken,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
