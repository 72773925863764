/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum RoleName {
  APPLICANT = 'ROLE_APPLICANT',
  RECRUITER = 'ROLE_RECRUITER',
  CUSTOMERADMIN = 'ROLE_CUSTOMER_ADMIN',
  SYSTEMADMIN = 'ROLE_SYSTEM_ADMIN',
  ADMINPORTAL = 'ROLE_ADMIN_PORTAL',
}
