import { CardConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import {
  getCandidateCollapsedCardMatchResolutionLabel,
  getUnwantedCriteriaCardTitleLabel,
} from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';
import { getSkillSelectValueTooltipProps } from '@air/domain/SearchCriteriaCards/SkillCard/skillCardHelpers';

export const skillCardConfig: CardConfig = {
  getCandidateCollapsedCardMatchResolutionLabel,
  getCriteriaCardTitleLabel: getUnwantedCriteriaCardTitleLabel,
  getSelectDropDownOptionTooltipProps: getSkillSelectValueTooltipProps,
};
