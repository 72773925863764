import React from 'react';
import classNames from 'classnames';

import { Header, SvgIcon, UIText } from '@air/components';
import { KanbanSectionNames } from 'hooks/useUserSettings';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import * as phrases from 'constants/phrases';

import { kanbanSelectors } from 'selectors';

import styles from './KanbanSection.css';

type PropsT = {
  title: KanbanSectionNames;
  counter: number;
  testId?: string;
  className?: string;
  isOpen: boolean;
  showExpandStats?: boolean;
  showCounter?: boolean;
  toggleSection: (v: KanbanSectionNames) => void;
};

export const KanbanSection: React.FC<PropsT> = ({
  title,
  counter,
  testId,
  className,
  children,
  isOpen,
  toggleSection,
  showExpandStats = true,
  showCounter = true,
}) => {
  const isInactiveSection = !counter;

  const { setStatsExpanded } = useKanbanMethods();
  const statsExpanded = useKanbanContext(kanbanSelectors.statsExpanded);

  const onSectionHeaderClick = () => {
    toggleSection(title);
  };

  return (
    <div
      data-testid={testId}
      className={classNames(styles.kanbanSection, className)}
    >
      <div
        className={classNames(styles.sectionHeader, {
          [styles.inactive]: isInactiveSection,
        })}
      >
        <Header level={2} onClick={onSectionHeaderClick}>
          <SvgIcon
            icon="shevron-down"
            className={classNames(styles.openButton, {
              [styles.closed]: !isOpen,
              [styles.hidden]: isInactiveSection,
            })}
          />
          {title}
          {showCounter && (
            <span
              data-testid="section-jobs-counter"
              className={styles.jobsCounter}
            >
              <UIText small bold>
                {counter}
              </UIText>
            </span>
          )}
        </Header>

        {isOpen && counter && showExpandStats ? (
          <UIText
            small
            className={styles.statsControl}
            onClick={() => setStatsExpanded(title, !statsExpanded[title])}
          >
            {statsExpanded[title]
              ? phrases.KANBAN_STATS_CONTROL_MINIMIZE
              : phrases.KANBAN_STATS_CONTROL_EXPAND}
          </UIText>
        ) : null}
      </div>
      <div
        className={classNames(styles.sectionContent, {
          [styles.hidden]: !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};
