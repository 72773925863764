import React, { useMemo } from 'react';
import styles from './ChangeBackgroundView.css';
import { Button, Header } from '@air/components';
import classNames from 'classnames';
import * as phrases from 'constants/phrases';
import { getBackgroundImageName } from 'domain/UISettings/backgroundSettings';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

const BACKGROUNDS_COUNT = 15;

type Props = {
  className?: string;
};

export const ChangeBackgroundView: React.FC<Props> = ({ className }) => {
  const currentBackgroundId = useCustomerProfileContext(
    customerProfileSelectors.currentBackgroundImageId
  );

  const { changeBackgroundImage } = useCustomerProfileMethods();

  const backgroundImages = useMemo(() => {
    const images = [];
    for (let imageId = 1; imageId <= BACKGROUNDS_COUNT; imageId++) {
      images.push(
        <Button
          key={imageId}
          type="button"
          disabled={currentBackgroundId === imageId}
          onClick={(event) => {
            event.stopPropagation();
            changeBackgroundImage(imageId);
          }}
          className={classNames(styles.backgroundItem, {
            [styles.selected]: currentBackgroundId === imageId,
          })}
          style={{
            backgroundImage: `url('/assets/images/background/${getBackgroundImageName(
              imageId,
              true
            )}')`,
          }}
        />
      );
    }
    return images;
  }, [currentBackgroundId, changeBackgroundImage]);

  return (
    <div className={classNames(styles.changeBackgroundContainer, className)}>
      <Header bolder level={1} className={styles.backgroundTitle}>
        {phrases.WALLPAPERS_TITLE}
      </Header>
      {backgroundImages}
    </div>
  );
};
