import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import { CustomerCompanyResponseV3 } from '@air/api';

export const fetchCustomerCompanyInfo = (companyId: number) =>
  Http.get(urls.makeCustomerCompanyApiUrl(companyId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const updateCustomerCompanyInfo = (
  companyId: number,
  companyData: CustomerCompanyResponseV3
) =>
  Http.put(urls.makeCustomerCompanyApiUrl(companyId))
    .bind(Http.withJsonBody(companyData))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const fetchMatchMinerSettings = () =>
  Http.get(urls.CUSTOMER_MATCH_MINER_URL)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const fetchMatchScoutSettings = (companyId: number) =>
  Http.get(urls.CUSTOMER_MATCH_SCOUT_URL(companyId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const requestMatchMinerForCompany = () =>
  Http.post(urls.CUSTOMER_MATCH_MINER_URL)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const requestMatchScoutForCompany = (companyId: number) =>
  Http.post(urls.CUSTOMER_MATCH_SCOUT_URL(companyId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);

export const viewIntroGuide = () =>
  Http.put(urls.VIEW_INTRO_GUIDE_URL).toTask();
