import R from '@air/third-party/ramda';
import * as candidateApi from 'domain/CandidateData/candidateApi';
import { downloadBlob } from '@air/utils/downloadFiles';
import {
  CandidateLineupData,
  mapPartialCandidateProfileToLineupData,
} from 'domain/CandidateData/CandidateLineupData';
import {
  CandidateSearchProfileListResponseWithStatuses,
  CandidateSearchProfileV2,
} from '@air/api';
import { Task } from '@air/utils/fp';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData/candidateStatusHelpers';
import {
  CandidateProfileUIT,
  mapCandidateSearchProfileV2ToUIProfile,
} from 'domain/CandidateData/CandidateProfileData';
import { CandidatesCountersByStatus } from 'providers/CandidateProfileProvider';
import { ProfileSort } from '@air/api/models/ProfileSort';

export const downloadCandidateResume = (
  atsId: number,
  externalProfileId: string,
  filename: string,
  pdfOnly = false
): Promise<void> =>
  candidateApi
    .downloadCandidateResume(atsId, externalProfileId, pdfOnly)
    .fork(R.identity, downloadBlob(filename));

export const getCandidateResumeBlob = (
  atsId: number,
  externalProfileId: string,
  pdfOnly = false
): Promise<Blob> =>
  candidateApi
    .downloadCandidateResume(atsId, externalProfileId, pdfOnly)
    .fork(R.identity, R.identity);

export const getCandidateResumeFileHeaders = (
  atsId: number,
  externalProfileId: string,
  pdfOnly = false
): Promise<{ [headerName: string]: string }> => {
  return candidateApi
    .getCandidateResumeFileHeaders(atsId, externalProfileId, pdfOnly)
    .fork(R.identity, R.identity);
};

export type CandidatesResponseUIT = {
  candidates: CandidateLineupData[];
  statusesCount: CandidatesCountersByStatus;
};

const mapCandidatesTask = (
  candidateProfiles: CandidateSearchProfileListResponseWithStatuses
): Task<CandidatesResponseUIT> => {
  const { profilesList, statusesCount } = candidateProfiles;
  const statusesMap = statusesCount.reduce((acc, statusData) => {
    acc[statusData.status] = statusData.count;
    return acc;
  }, {} as CandidatesCountersByStatus);

  return Task.of({
    candidates: (profilesList?.items || []).map(
      mapPartialCandidateProfileToLineupData
    ),
    statusesCount: statusesMap,
  });
};

export const requestCandidates = (params: {
  searchId: number;
  filter?: string;
  status?: CandidateSearchProfileStatusEnum[];
  size: number;
  profileIds?: string[];
  passive?: boolean;
  sort?: ProfileSort;
}): Task<CandidatesResponseUIT> => {
  return candidateApi
    .fetchLineupCandidatesList(params)
    .chain(mapCandidatesTask);
};

const updateCandidateTask = (candidate: CandidateSearchProfileV2) =>
  Task.of(mapCandidateSearchProfileV2ToUIProfile(candidate));

export const fetchCandidateTask = (
  searchId: number | string,
  profileId: string
): Task<CandidateProfileUIT> =>
  candidateApi
    .fetchCandidateSearchProfileById(searchId, profileId)
    .chain(updateCandidateTask);

export const fetchCandidateByMainIdTask = (
  dataSourceId: number | string,
  jobDescriptionId: number | string,
  mainProfileId: string
): Task<CandidateProfileUIT> =>
  candidateApi
    .fetchCandidateSearchProfileByMainId(
      dataSourceId,
      jobDescriptionId,
      mainProfileId
    )
    .chain(updateCandidateTask);
