import * as Sentry from '@sentry/browser';
import { Task } from '@air/utils/fp';
import * as apiEndpoints from '@air/constants/apiEndpoints';
import {
  localStore,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  clearTokenStorage,
} from '@air/domain/WebStorage/webStorage';
import { ERROR_TYPES, logResponse } from '@air/utils/sentry';
import { LOGOUT_REDIRECT_ROUTE } from '@air/constants/commonUrls';
import * as httpCodes from '@air/constants/httpCodes';
import R from '@air/third-party/ramda';
import { RetryOptionsT } from '@air/utils/http';

export const XHR_REQUEST_CANCELED = 'xhr-request-canceled';

export enum ResultType {
  BAD_STATUS,
  BAD_ACCESS_TOKEN,
  BAD_REFRESH_TOKEN,
  INTERNAL_ERROR,
  BAD_LOGIN,

  GOOD_STATUS,
  GOOD_REFRESH_TOKEN,
}

export class Result {
  payload: any;
  success: boolean;
  type: ResultType;

  constructor(type: any, payload: any, success: any) {
    this.payload = payload;
    this.success = success;
    this.type = type;
  }
}

export class Success extends Result {
  constructor(type: any, payload: any) {
    super(type, payload, true);
  }

  static of(type: any, payload: any) {
    return new Success(type, payload);
  }
}

export class Failure extends Result {
  constructor(type: any, payload: any) {
    super(type, payload, false);
  }

  static of(type: any, payload: any) {
    return new Failure(type, payload);
  }
}

export type OptionsT = {
  method?: string;
  headers?: any;
  mock?: any;
  mockStatus?: number;
  body?: any;
  cache?: any;
  integrity?: any;
  credentials?: any;
  window?: any;
  retry?: RetryOptionsT;
  xhrActions?: {
    setProgress?: (...args: any[]) => any;
    saveRequest?: (...args: any[]) => any;
    size?: string;
  };
};

export type ResultT = {
  ok?: boolean;
  status?: number;
  type?: ResultType;
  url?: string;
  statusText?: string;
  payload?: any;
  isXHR?: boolean | null;
  body?: any;
};

function parseStringifiedData(body: string) {
  let parsedBody = {};
  try {
    parsedBody = JSON.parse(body);
  } catch (error) {
    console.error('Could not parse XHR body', error);
    return null;
  }
  return parsedBody;
}

export type XHRResponseBodyT = {
  status: number;
  ok: boolean;
  url: string;
  statusText: string;
  isXHR: boolean;
  headers: string;
};
function composeBasicXHRBody(xhr: XMLHttpRequest) {
  return {
    status: xhr.status,
    ok: true,
    url: xhr.responseURL,
    statusText: xhr.statusText,
    isXHR: true,
    headers: xhr.getAllResponseHeaders(),
  };
}

export const doXHR = (url: string, options: OptionsT) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    options.xhrActions.saveRequest(xhr);
    xhr.open(options.method, url);

    for (const pair of options.headers.entries()) {
      xhr.setRequestHeader(pair[0], pair[1]);
    }

    xhr.upload.onprogress = function (event) {
      options.xhrActions.setProgress(event, options);
    };

    xhr.onreadystatechange = function (event: Event) {
      // request was canceled
      if (
        (event.currentTarget as XMLHttpRequest).status ===
        httpCodes.XHR_REQUEST_ABORTED
      ) {
        const xhrBody = composeBasicXHRBody(xhr);
        reject({
          body: { error: { code: XHR_REQUEST_CANCELED } },
          sentData: options.body,
          ...xhrBody,
        });
      }
    };

    xhr.onload = function () {
      const xhrBody = composeBasicXHRBody(xhr);
      if (xhr.status === httpCodes.SUCCESS) {
        resolve({
          ...xhrBody,
          body: xhr.response,
        });
      } else if (xhr.status === httpCodes.UNAUTHORIZED) {
        // here we kind of emulate the same behavior as with fetch request were
        // we resolve 40x requests but with `ok: false` flag.
        resolve({
          ...xhrBody,
          body: parseStringifiedData(xhr.response),
          sentData: options.body,
          ok: false,
        });
      } else {
        reject({
          body: parseStringifiedData(xhr.response),
          sentData: options.body,
          ...xhrBody,
        });
      }
    };

    xhr.onerror = function () {
      reject({
        ...composeBasicXHRBody(xhr),
        body: parseStringifiedData(xhr.response),
      });
    };

    xhr.send(options.body);
  });
};

export function doFetch(
  url: string,
  { mock, mockStatus, ...options }: OptionsT
): Response | Promise<ResultT> | Promise<Response> {
  if (mock) {
    return new Response(
      new Blob([JSON.stringify(mock)], {
        type: 'application/json',
      }),
      { status: mockStatus }
    );
  }
  if (options.xhrActions) {
    return doXHR(url, options);
  }
  return fetch(url, options);
}

class RefreshTokenTasksManager {
  activeRequest: any = null;

  refreshToken = () => {
    if (!this.activeRequest) {
      this.activeRequest = this.__refreshToken();
    }
    return this.activeRequest;
  };

  clearTokens = () => {
    clearTokenStorage();
  };

  abortRefreshTask = (payload?: any) => {
    this.clearTokens();
    this.activeRequest = null;
    window.location = decodeURIComponent(
      LOGOUT_REDIRECT_ROUTE
    ) as unknown as Location;
    return Failure.of(ResultType.BAD_LOGIN, payload);
  };

  __refreshToken = async () => {
    const refreshToken = localStore.getItem(REFRESH_TOKEN);
    const accessToken = localStore.getItem(ACCESS_TOKEN);

    const refreshTokenRes = await doFetch(
      apiEndpoints.createRefreshTokenApiUrl(encodeURIComponent(refreshToken)),
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );

    try {
      if (refreshTokenRes.status === httpCodes.SUCCESS) {
        // @ts-ignore
        const tokens = await refreshTokenRes.json();
        localStore.setItems(tokens);

        this.activeRequest = null;
        return Success.of(ResultType.GOOD_REFRESH_TOKEN, tokens);
      } else {
        // @ts-ignore
        const res = await refreshTokenRes.json();
        return this.abortRefreshTask(res);
      }
    } catch (err) {
      return this.abortRefreshTask(err);
    }
  };
}

const refreshTokenTasksManager = new RefreshTokenTasksManager();

/*
  Auth task is used solely for initial authorization request.
  Unlike httpTask, authTask should not send refresh token request
  in case of bad status, and allows us to reject bad response.
 */
export const authTask = (url: string, options: any) =>
  new Task(async function fromAuthTask(rej: any, res: any) {
    try {
      const result: ResultT | Response = await doFetch(url, options);
      if (result.ok) {
        return res(Success.of(ResultType.GOOD_STATUS, result));
      } else {
        // If server responded with 401 - refresh token
        const status =
          result.status === httpCodes.UNAUTHORIZED
            ? ResultType.BAD_ACCESS_TOKEN
            : ResultType.BAD_STATUS;

        return rej(Failure.of(status, result));
      }
    } catch (e) {
      return rej(Failure.of(ResultType.INTERNAL_ERROR, e));
    }
  });

export const requestTask = (url: string, options: OptionsT) => {
  let retryCount = 0;
  const makeRequest = async (
    rej: any,
    res: any
  ): Promise<ResultT | Response> => {
    try {
      const result: ResultT | Response = await doFetch(url, options);
      logResponse(result as Response);
      if (result.ok) {
        retryCount = 0;
        return res(Success.of(ResultType.GOOD_STATUS, result));
      } else {
        // If server responded with 401 - refresh token.
        if (result.status === httpCodes.UNAUTHORIZED) {
          return res(Success.of(ResultType.BAD_ACCESS_TOKEN, null));
        } else {
          if (options.retry && ++retryCount <= (options.retry.count ?? 3)) {
            await R.delay(options.retry.delay || 1000); // 1 sec
            return makeRequest(rej, res);
          }
          return rej(Failure.of(ResultType.BAD_STATUS, result || null));
        }
      }
    } catch (e) {
      if (options.retry && ++retryCount <= (options.retry.count ?? 3)) {
        await R.delay(options.retry.delay || 1000); // 1 sec
        return makeRequest(rej, res);
      }
      logResponse(e, url);
      return rej(Failure.of(ResultType.INTERNAL_ERROR, e));
    }
  };

  return new Task(async function fromRequestTask(rej: any, res: any) {
    return makeRequest(rej, res);
  });
};

export const createRefreshTokenTask = () =>
  new Task(async function fromCreateRefreshTokenTask(rej: any, res: any) {
    const result = await refreshTokenTasksManager.refreshToken();

    return result.success ? res(result) : rej(result);
  });

// String -> Object -> Task (Failure any) (Success Response)
export const httpTask = (url: string, options: OptionsT) =>
  requestTask(url, options)
    .chain((result: any) =>
      result.type === ResultType.BAD_ACCESS_TOKEN
        ? createRefreshTokenTask()
        : Task.of(result)
    )
    .chain((result: any) => {
      const hasNewAccessToken = result.type === ResultType.GOOD_REFRESH_TOKEN;
      if (!hasNewAccessToken) return Task.of(result);

      const accessToken = localStore.getItem(ACCESS_TOKEN);
      options.headers.set('Authorization', `Bearer ${accessToken}`);
      return requestTask(url, options);
    });

export const parseResponseHeaders = (x: any) =>
  new Task((parseResponseRej: any, parseResponseRes: any) => {
    const result: any = {};
    x.payload.headers.forEach((value: any, name: any) => {
      result[R.camelCase(name)] = value;
    });
    return parseResponseRes(result);
  });

// Response -> Task String JSON
export const parseResponseJson = (x: any) =>
  new Task(function parseResponseTask(
    parseResponseRej: any,
    parseResponseRes: any
  ) {
    if (x.payload.status === 204) {
      return parseResponseRes(null);
    }
    if (x.payload.isXHR) {
      return parseResponseRes(parseStringifiedData(x.payload.body));
    }
    return x.payload.json().then(parseResponseRes).catch(parseResponseRej);
  });

export const parseErrorJson = (err: any) =>
  new Task(function parseResponseErrorTask(parseResponseErrorRej: any) {
    if (err?.payload instanceof Error && ENV_NAME !== 'localhost') {
      Sentry.captureException(err, {
        tags: {
          type: ERROR_TYPES.API_TASK,
        },
      });
      const { stack, message } = err;
      return parseResponseErrorRej({
        stack,
        message,
        status: 0,
      });
    } else if (err?.payload instanceof Error && ENV_NAME === 'localhost') {
      console.error(err.message);
      console.error(err.stack);
      return;
    }

    const { status } = err.payload;
    const parseErrorErrHandler = (res: any) =>
      parseResponseErrorRej({ ...res, status });

    return err.payload instanceof Response
      ? err.payload.json().then(parseErrorErrHandler, parseErrorErrHandler)
      : parseErrorErrHandler(err.payload);
  });

// Response -> Task (Failure any) (Success BLOB)
export const parseResponseBlob = (x: any) =>
  new Task(function parseResponseBlobTask(
    parseResponseBlobRej: any,
    parseResponseBlobRes: any
  ) {
    return x.payload
      .blob()
      .then((data: Blob) =>
        parseResponseBlobRes(data, {
          headers: x.payload.headers,
        })
      )
      .catch(parseResponseBlobRej);
  });
