import React from 'react';
import { SearchListItemT } from 'domain/Kanban/Kanban';

export type DraggedItemT = SearchListItemT;

export type UndoContextT = {
  activeKanbanSection: string;
  draggedItem: DraggedItemT;
  scheduleTimeoutCallback: (
    item: DraggedItemT,
    activeKanbanSection: string,
    cb: (item: DraggedItemT) => void
  ) => void;
  undoDragItem: () => void;
  invokeDragItemCallbackImmediately: () => void;
};

export const UndoContext = React.createContext<UndoContextT>({
  activeKanbanSection: '',
  draggedItem: null,
  scheduleTimeoutCallback: null,
  undoDragItem: null,
  invokeDragItemCallbackImmediately: null,
});

UndoContext.displayName = 'UndoContext';
