import * as React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useDrop } from 'react-dnd';

import { SvgIcon } from 'components';

import styles from './RemoveButton.css';
import fadeTransition from '@air/styles/transitions/fade.css';

export const RemoveButton: React.FC<any> = ({
  onDrop,
  acceptTypes,
  className = '',
}) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: acceptTypes,
    drop: onDrop,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  return (
    <CSSTransition
      in={canDrop}
      timeout={500}
      classNames={fadeTransition}
      unmountOnExit
    >
      <div
        ref={drop}
        className={classNames(styles.removeButton, className, {
          [styles.isHovered]: isOver,
        })}
      >
        <SvgIcon icon="remove-icon" height="5rem" width="5rem" />
      </div>
    </CSSTransition>
  );
};
