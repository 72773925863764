import React, { useCallback, useEffect, useState } from 'react';

import { KanbanCard } from 'components';
import {
  SearchListTypes,
  useKanbanContext,
  useKanbanMethods,
  useSelectInterviewKanbanContext,
  useSelectInterviewKanbanMethods,
} from 'providers/KanbanProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import { LoadMoreButton } from 'features/Kanban/LoadMoreButton/LoadMoreButton';
import {
  getAmountOfJobsToLoad,
  KANBAN_SEARCH_GROUPS,
  addRemoveCardAction,
} from 'domain/Kanban/Kanban';
import { usePaginatedSearch } from 'domain/Kanban/usePaginatedSearch';

import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';

import commonStyles from 'features/Kanban/KanbanSection/KanbanSection.css';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit, subscribe } from 'hooks/usePubSub';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { SearchProgressStatusEnum } from '@air/api';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_RESTART_SCREENING } from 'constants/gaLabels';

type Props = {
  isSelectMode?: boolean;
  selectModeTargetId?: string | number;
};

export const Closed: React.FC<Props> = React.memo((props) => {
  const { isSelectMode, selectModeTargetId } = props;
  const isExternalATS = useCustomerProfileContext(
    customerProfileSelectors.isExternalATS
  );
  const [kanbanContext, kanbanContextMethods] = isSelectMode
    ? [useSelectInterviewKanbanContext, useSelectInterviewKanbanMethods]
    : [useKanbanContext, useKanbanMethods];
  const closedSearchesList = kanbanContext(kanbanSelectors.closedSearchesList);
  const interviewsList = useKanbanContext(kanbanSelectors.interviewsList);
  const currentSearchFilterName = useKanbanContext(
    kanbanSelectors.currentSearchFilterName
  );
  const isFirstClosedJobUpdated = useKanbanContext(
    kanbanSelectors.isFirstClosedJobUpdated
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const isMatchMinerFeatureEnabled = useCustomerProfileContext(
    customerProfileSelectors.matchMinerEnabled
  );
  const isMatchScoutFeatureEnabled = useCustomerProfileContext(
    customerProfileSelectors.matchScoutEnabled
  );
  const statsExpanded = useKanbanContext(kanbanSelectors.statsExpanded);
  const { reopenClosedInterview, updateSearchStatusInList, removeDraft } =
    useKanbanMethods();
  const { fetchSearchesByStatus } = kanbanContextMethods();
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearchesByStatusCb = useCallback(
    async (page) => {
      setIsLoading(true);
      await fetchSearchesByStatus({
        status: KANBAN_SEARCH_GROUPS.Closed,
        page,
        ...(isSelectMode
          ? { withCriteria: true, excludeId: [selectModeTargetId as string] }
          : {}),
      });
      setIsLoading(false);
    },
    [fetchSearchesByStatus, isSelectMode, selectModeTargetId]
  );

  const loadMoreJobs = usePaginatedSearch(fetchSearchesByStatusCb, [
    currentSearchFilterName,
    isFirstClosedJobUpdated,
  ]);

  const amountOfJobsLeftToLoad = getAmountOfJobsToLoad(
    closedSearchesList.total,
    closedSearchesList.items.length
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.JOB_STATUS_CHANGE, loadMoreJobs);
  }, [loadMoreJobs]);

  const restartInterviewAction = useCallback(
    async (item: KanbanCardT) => {
      updateSearchStatusInList({
        item,
        itemsList: closedSearchesList,
        listName: SearchListTypes.STATUS_CLOSED_SEARCHES,
      });
      updateSearchStatusInList({
        item,
        itemsList: interviewsList,
        newStatus: SearchProgressStatusEnum.INTERVIEW,
        listName: SearchListTypes.STATUS_INTERVIEWS,
      });
      trackEvent({
        category: GACategory.ScreeningPage,
        label: GA_LABEL_RESTART_SCREENING,
      });
      await reopenClosedInterview(item);
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
    },
    [
      closedSearchesList,
      interviewsList,
      reopenClosedInterview,
      updateSearchStatusInList,
    ]
  );

  return (
    <div className={commonStyles.sectionJobs}>
      {closedSearchesList.items.map((item: KanbanCardT) => {
        const actionsMenuConfig = [
          {
            onClick: () => emit(APP_EVENTS.OPEN_JOB_PANEL, { item }),
            icon: 'edit',
            title: phrases.KANBAN_ACTION_EDIT,
            disabled: isTrialExpired,
            closeMenuOnClick: true,
          },
          ...(!isExternalATS
            ? [
                {
                  onClick: () => emit(APP_EVENTS.DUPLICATE_CARD, item),
                  icon: 'duplicate',
                  title: phrases.KANBAN_ACTION_DUPLICATE,
                  disabled: isTrialExpired,
                  closeMenuOnClick: true,
                },
              ]
            : []),
          {
            onClick: () => restartInterviewAction(item),
            icon: 'restart-screening',
            title: phrases.KANBAN_ACTION_RESTART_SCREENING,
            disabled: isTrialExpired,
            confirmTitle: phrases.REOPEN_SCREENING_CONFIRMATION,
            confirmLabel: phrases.KANBAN_ACTION_REOPEN,
          },
        ];
        addRemoveCardAction(actionsMenuConfig, removeDraft, item);

        return (
          <KanbanCard
            key={item.searchId}
            item={item}
            actionsMenuConfig={actionsMenuConfig}
            isSelectMode={isSelectMode}
            isMMEnabled={isMatchMinerFeatureEnabled}
            isMSEnabled={isMatchScoutFeatureEnabled}
            areAllStatsExpanded={statsExpanded[phrases.KANBAN_SECTION_CLOSED]}
            url={urls.makeClosedSearchUrl(
              item.ats.id,
              item.ats.externalJobDescriptionId
            )}
          />
        );
      })}
      {!!amountOfJobsLeftToLoad && (
        <LoadMoreButton
          isLoading={isLoading}
          onClick={loadMoreJobs}
          text={phrases.getLoadMoreJobsButtonTitle(amountOfJobsLeftToLoad)}
        />
      )}
    </div>
  );
});
