import { SearchCriteriaMatchStatusEnum } from '@air/api';
import { CriteriaInterviewStatus } from 'domain/CandidateData';

import searchCardStyles from 'components/SearchCards/SearchCards.css';
import stackCardStyles from 'components/SearchCards/StackCards.css';
import styles from 'components/CardWrappers/CandidateProfileMatchingCard/CandidateProfileMatchingCard.css';

export const createMapStatusToClassName = (cardStyles: any) => (
  status: SearchCriteriaMatchStatusEnum | CriteriaInterviewStatus
) => {
  switch (status) {
    case SearchCriteriaMatchStatusEnum.IDEAL:
      return cardStyles.ideal;
    case SearchCriteriaMatchStatusEnum.ACCEPTABLE:
      return cardStyles.acceptable;
    case SearchCriteriaMatchStatusEnum.DISQUALIFICATION:
      return cardStyles.disqualify;
    case SearchCriteriaMatchStatusEnum.WARNING:
    case SearchCriteriaMatchStatusEnum.WARNINGMISSED:
      return cardStyles.warning;
    case CriteriaInterviewStatus.ASKED:
      return cardStyles.asked;
    case CriteriaInterviewStatus.ANSWERED:
      return cardStyles.answered;
    default:
      return '';
  }
};

export const mapCandidateMatchingCardStatusClassName = createMapStatusToClassName(
  searchCardStyles
);
export const mapCandidateMatchingCardStackClassNames = createMapStatusToClassName(
  stackCardStyles
);
export const mapCandidateMatchingCardBackgroundClassName = createMapStatusToClassName(
  styles
);
