import React, { ReactNode, useCallback, LegacyRef, useRef } from 'react';
import R from '@air/third-party/ramda';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import classNames from 'classnames';
import { useSearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';
import {
  useFormikContext,
  Field,
  FieldArray,
  FieldArrayRenderProps,
} from 'formik';
import { useOutsideClick } from '@air/utils/hooks';
import {
  SearchCardActions,
  SearchCardState,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import {
  getCardStackSize,
  getValuesForTopStack,
  getTopStackListName,
  getBottomStackListName,
  getValuesForBottomStack,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import { Card } from '@air/components';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';

import styles from 'components/Cards/educationCommonStyles.css';
import {
  getCardTopLabel,
  getMainCardLabel,
  CriteriaEditCardStackItem,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import {
  BaseCriteriaData,
  BaseSearchCriteriaData,
} from 'domain/SearchCriteria';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { scrollIntoView } from '@air/utils/dom';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { CriteriaImportanceSettings } from 'components/SearchCriteriaCards/components/CriteriaImportanceExclusionSettings/CriteriaImportanceExclusionSettings';

function getActionTitle(
  isImportanceSectionOpened: boolean,
  isReadOnly: boolean
): string {
  if (isReadOnly) return sharedPhrases.OK;
  return isImportanceSectionOpened
    ? phrases.RETURN_FROM_PRIORITY_SETTING_BUTTON
    : sharedPhrases.CONFIRM;
}

export type OnRemoveItemCallback = (
  arrayHelpers: FieldArrayRenderProps,
  listIndex: number
) => void;

export type OnLabelClickCallback = (
  arrayHelpers: FieldArrayRenderProps,
  listIndex: number
) => void;

export type OnMainCardLabelClick = () => void;

export type StackItemRenderer = (
  item: CriteriaEditCardStackItem,
  arrayHelpers: FieldArrayRenderProps,
  listIndex: number,
  defaultRenderer?: (...args: any[]) => any
) => ReactNode;

const defaultOnRemoveItem: OnRemoveItemCallback = (arrayHelpers, listIndex) => {
  arrayHelpers.remove(listIndex);
};

type UpdateMainFieldHandler = (value: { label: string }) => void;

export type CriteriaEditCardChildrenProps = {
  isImportanceSectionOpened: boolean;
  mainCriteriaNameFieldRef: LegacyRef<HTMLDivElement>;
  updateMainFieldHandler: UpdateMainFieldHandler;
  importanceIndicator: ReactNode;
};

type CriteriaEditCardT = (
  | { collapsibleArea: ReactNode }
  | {
      changeCardImportance: (
        criteria: BaseCriteriaData,
        newImportance: { label: string; value: SearchCriteriaImportanceEnum }
      ) => void;
    }
) & {
  namePrefix: string;
  cardData: BaseSearchCriteriaData;
  isMaxListSizeReached?: boolean;
  canAddNewStackItem?: boolean;
  className?: string;
  stackItemControl?: ReactNode;
  stackItemRenderers?: [StackItemRenderer | null, StackItemRenderer?];
  stackItems?: [CriteriaEditCardStackItem[], CriteriaEditCardStackItem[]];
  children: any;
};
export const CriteriaEditCard: React.FC<CriteriaEditCardT> = ({
  namePrefix,
  cardData,
  canAddNewStackItem,
  isMaxListSizeReached,
  children,
  stackItemControl,
  className = '',
  stackItemRenderers = [],
  stackItems = [],
  ...props
}) => {
  const { states, handlers, cardState, dispatch } = useSearchCardContext();
  const { isImportanceSectionOpened, isCardValid } = states;
  const { toggleImportanceHandler, saveOrDiscardHandler, clickConfirmHandler } =
    handlers;

  const isCardLabelEnabled =
    cardState.matches(SearchCardState.updatedFields) && canAddNewStackItem;

  const cardLabel = getCardTopLabel(cardData, isCardLabelEnabled)(
    isMaxListSizeReached,
    isCardValid
  );
  const stackSize = getCardStackSize(cardData);
  const mainCard = getMainCard(cardData);
  const mainCardLabel = getMainCardLabel(cardData);

  const topStackValues = getValuesForTopStack(cardData);
  const bottomStackValues = getValuesForBottomStack(cardData);

  const [topStackItems = [], bottomStackItems = []] = stackItems;

  const topStackListName = getTopStackListName(cardData);
  const bottomStackListName = getBottomStackListName(cardData);

  const [topStackItemRenderer, bottomStackItemRenderer] = stackItemRenderers;

  const { setFieldValue } = useFormikContext();
  /*
    Outside click callback should be called on capturing phase, because otherwise
    selecting title from react-select dropdown is considered click outside (after
    selecting, dropdown is removed from DOM).
  */
  const [outsideClickRef] = useOutsideClick(saveOrDiscardHandler, {
    useCapture: true,
  });

  const searchCriteriaEditFormRef = useRef<HTMLDivElement>(null);

  /**
   * updateMainFieldHandler function is assigned as an onChange handler for the main field in card,
   * which affects validation.
   * here we check if added value is empty and the list is empty, or contain only one element (the one which was cleared),
   * and dispatch `updateFields` event.
   *
   * TODO: when working on a validation, think about how to add the logic from filterOutEmptyCriteriaLists here:
   */
  const updateMainFieldHandler: UpdateMainFieldHandler = (value) => {
    const clearList =
      R.isNullOrEmpty(value?.label) && !getValuesForTopStack(cardData).length;
    dispatch(SearchCardActions.updateFields, !clearList);
  };

  const namePrefixPath = namePrefix.split('.');

  const defaultOnTopLabelClick = useCallback<OnLabelClickCallback>(
    (arrayHelpers, listIndex) => {
      arrayHelpers.remove(listIndex);
      arrayHelpers.form.setFieldValue(`${namePrefix}.${bottomStackListName}`, [
        R.path(
          namePrefixPath.concat(topStackListName, listIndex.toString()),
          arrayHelpers.form.values
        ),
        ...((R.path(
          namePrefixPath.concat(bottomStackListName),
          arrayHelpers.form.values
        ) ?? []) as any[]),
      ]);
    },
    [bottomStackListName, namePrefix, namePrefixPath, topStackListName]
  );

  const defaultOnBottomLabelClick = useCallback<OnLabelClickCallback>(
    (arrayHelpers, listIndex) => {
      arrayHelpers.remove(listIndex);
      arrayHelpers.form.setFieldValue(
        `${namePrefix}.${topStackListName}`,
        R.insert(
          1,
          R.path(
            namePrefixPath.concat(bottomStackListName, listIndex.toString()),
            arrayHelpers.form.values
          ),
          R.path(
            namePrefixPath.concat(topStackListName),
            arrayHelpers.form.values
          )
        )
      );
    },
    [bottomStackListName, namePrefix, namePrefixPath, topStackListName]
  );

  const defaultOnMainCardLabelClick = useCallback<OnMainCardLabelClick>(() => {
    const newBottomStackList = bottomStackValues.concat([mainCard]);
    setFieldValue(namePrefix, {
      ...cardData,
      [topStackListName]: topStackValues,
      [bottomStackListName]: newBottomStackList,
    });
  }, [
    bottomStackListName,
    bottomStackValues,
    cardData,
    mainCard,
    namePrefix,
    setFieldValue,
    topStackListName,
    topStackValues,
  ]);

  const onCardTopLabelClick = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => {
      /*
        Do not allow to add empty cardData to skill criteria list.
      */
      if (isMaxListSizeReached || !mainCard?.value || mainCard?.label === '')
        return;

      arrayHelpers.unshift(null);

      const elem = searchCriteriaEditFormRef?.current;
      if (elem) {
        setTimeout(() => {
          scrollIntoView(elem, false);
        }, 1000);
      }
    },
    [isMaxListSizeReached, mainCard]
  );

  const defaultTopStackItemRenderer = useCallback<StackItemRenderer>(
    (item, arrayHelpers, listIndex) => {
      return (
        <Card.SearchCriteriaStackItem
          unstyled
          key={item.key}
          className={item.className}
          label={item.label}
          labelClassName={item.labelClassName}
          onRemoveItem={
            states.isReadOnly
              ? null
              : () =>
                  (item.onRemoveItem || defaultOnRemoveItem)(
                    arrayHelpers,
                    listIndex
                  )
          }
          isLabelInteractive={!states.isReadOnly && item.isLabelInteractive}
          onLabelClick={
            states.isReadOnly
              ? null
              : () =>
                  (item.onLabelClick || defaultOnTopLabelClick)(
                    arrayHelpers,
                    listIndex
                  )
          }
        >
          <Field name={`${namePrefix}.${topStackListName}.${listIndex}`}>
            {stackItemControl}
          </Field>
        </Card.SearchCriteriaStackItem>
      );
    },
    [
      defaultOnTopLabelClick,
      namePrefix,
      stackItemControl,
      topStackListName,
      states.isReadOnly,
    ]
  );

  const defaultBottomStackItemRenderer = useCallback<StackItemRenderer>(
    (item, arrayHelpers, listIndex) => {
      return (
        <Card.SearchCriteriaStackItem
          unstyled
          key={item.key}
          className={item.className}
          label={item.label}
          labelClassName={item.labelClassName}
          isLabelInteractive={!states.isReadOnly}
          onRemoveItem={
            states.isReadOnly
              ? null
              : () =>
                  (item.onRemoveItem || defaultOnRemoveItem)(
                    arrayHelpers,
                    listIndex
                  )
          }
          onLabelClick={
            states.isReadOnly
              ? null
              : () =>
                  (item.onLabelClick || defaultOnBottomLabelClick)(
                    arrayHelpers,
                    listIndex
                  )
          }
        >
          {item.children ?? (
            <Field name={`${namePrefix}.${bottomStackListName}.${listIndex}`}>
              {stackItemControl}
            </Field>
          )}
        </Card.SearchCriteriaStackItem>
      );
    },
    [
      bottomStackListName,
      defaultOnBottomLabelClick,
      namePrefix,
      stackItemControl,
      states.isReadOnly,
    ]
  );

  const renderTopStackItem =
    topStackItemRenderer ?? defaultTopStackItemRenderer;

  const renderBottomStackItem =
    bottomStackItemRenderer ?? defaultBottomStackItemRenderer;

  return (
    <Card.SearchCriteriaCardContainer
      className={className}
      ref={outsideClickRef}
    >
      <Card.SearchCriteriaEditForm
        shouldAnimateOnMount={
          cardData.initialCardStatus === InitialCardStatusEnum.IsNewEdit
        }
        ref={searchCriteriaEditFormRef}
        className={classNames({
          [cardCommonStyles.readOnlyForm]: states.isReadOnly,
        })}
      >
        {cardState.matches(SearchCardState.editImportance) && (
          <span className={cardCommonStyles.setPriorityLabel}>
            {phrases.OPENED_IMPORTANCE_SECTION_LABEL}
          </span>
        )}
        <Card.SearchCriteriaCollapsibleArea
          isCollapsed={!isImportanceSectionOpened}
        >
          {'collapsibleArea' in props ? (
            props.collapsibleArea
          ) : (
            <CriteriaImportanceSettings
              namePrefix={namePrefix}
              cardData={cardData}
              onImportanceChange={props.changeCardImportance}
            />
          )}
        </Card.SearchCriteriaCollapsibleArea>
        <FieldArray name={`${namePrefix}.${topStackListName}`}>
          {(arrayHelpers) => {
            return (
              <>
                <Card.SearchCriteriaCardLabel
                  hidden={isImportanceSectionOpened}
                  isActive={isCardLabelEnabled}
                  onClick={() => onCardTopLabelClick(arrayHelpers)}
                  text={cardLabel}
                />
                {/*// @ts-ignore*/}
                <Card.SearchCriteriaStack isClosed={isImportanceSectionOpened}>
                  {/*According to AR-2418, new items added to stack should*/}
                  {/*appear at the bottom of stack, so we have to reverse*/}
                  {/*stack order and adjust `listIndex` accordingly.*/}
                  {R.reverse(topStackItems).map(
                    (
                      item: CriteriaEditCardStackItem,
                      index: number,
                      { length }
                    ) => {
                      return renderTopStackItem(
                        item,
                        arrayHelpers,
                        length - index,
                        defaultTopStackItemRenderer
                      );
                    }
                  )}
                </Card.SearchCriteriaStack>
              </>
            );
          }}
        </FieldArray>
        <Card.SearchCriteriaEditFormFields
          isCollapsed={isImportanceSectionOpened}
          className={classNames({
            [cardCommonStyles.importanceSectionView]: isImportanceSectionOpened,
          })}
        >
          {children({
            isImportanceSectionOpened,
            updateMainFieldHandler,
            importanceIndicator: isCardValid && (
              <Card.SearchCriteriaImportanceIndicator
                className={cardCommonStyles.importanceSwitch}
                importance={cardData.importance.value}
                onClick={states.isReadOnly ? null : toggleImportanceHandler}
              />
            ),
          })}
          {mainCardLabel && (
            <Card.StackLabel
              disabled={mainCardLabel.disabled || states.isReadOnly}
              className={classNames(
                cardCommonStyles.mainCardStackLabel,
                mainCardLabel.className
              )}
              onClick={mainCardLabel.onClick ?? defaultOnMainCardLabelClick}
            >
              {mainCardLabel.text}
            </Card.StackLabel>
          )}
        </Card.SearchCriteriaEditFormFields>
        <FieldArray name={`${namePrefix}.${bottomStackListName}`}>
          {(arrayHelpers) => {
            return (
              <Card.SearchCriteriaStack
                position={Card.SearchCriteriaStack.position.bottom}
                isClosed={isImportanceSectionOpened}
                className={styles.educationBottomStack}
              >
                {
                  bottomStackItems.map(
                    (item: CriteriaEditCardStackItem, index: number) => {
                      return renderBottomStackItem(
                        item,
                        arrayHelpers,
                        index,
                        defaultBottomStackItemRenderer
                      );
                    }
                  ) as React.ReactElement[]
                }
              </Card.SearchCriteriaStack>
            );
          }}
        </FieldArray>
        {isImportanceSectionOpened && stackSize > 0 && (
          <Card.SearchCriteriaStackToggle
            fixedHeight={false}
            className={cardCommonStyles.importanceBottomStack}
            stackSize={stackSize}
          />
        )}
        <span
          onClick={clickConfirmHandler}
          className={classNames(cardCommonStyles.editCardStatusFooter, {
            [cardCommonStyles.editCardStatusFooterReadOnly]: states.isReadOnly,
            [cardCommonStyles.editCardStatusFooterUnderOpenedStack]:
              !isImportanceSectionOpened && bottomStackItems.length > 0,
          })}
        >
          {getActionTitle(isImportanceSectionOpened, states.isReadOnly)}
        </span>
      </Card.SearchCriteriaEditForm>
    </Card.SearchCriteriaCardContainer>
  );
};
