// imports from vendor deps
import React, { useCallback, useMemo } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

// imports from types
import {
  RangeDropdown,
  RangeItem,
  SelectedItems,
} from '@air/components/RangeDropdown/RangeDropdown';

// imports from 'components'
// import {} from 'components';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
import { ExperienceValues } from '@air/utils/commonSearchHelpers';
import { useRangeVisibility } from '@air/components/SelectRangeWidgets/useRangeVisibility';
import {
  convertValuesToRangeOfIndexes,
  generateItemsForRangeDropdown,
  generateSelectedIndexes,
  isAcceptableExperienceDefined,
  isIdealExperienceDefined,
  isLimitNotDefined,
  isRangeNotDefined,
  MAX_EXPERIENCE_YEAR,
  MIN_EXPERIENCE_YEAR,
  RangeIndex,
  RangeOfIndexes,
  sortSelectedRange,
  stringifyExperienceYears,
  UNSET_EXPERIENCE_MAX_YEAR,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';

// imports from styles
import styles from './IdealExperienceWidget.css';
import { MAX_EXPERIENCE_VALUE } from '@air/domain/Common/PeriodConverter';

// component proptypes

export const INDEX_OF_PLUS = MAX_EXPERIENCE_VALUE;

// The following definitions may seem redundant but
// they can be handy when we have other items in
// the beginning of the range
export const INDEX_OF_ZERO = 0;
export const INDEX_OF_ONE = 1;

const yearsRange = R.range(MIN_EXPERIENCE_YEAR, MAX_EXPERIENCE_YEAR + 1);
const dropdownItems = [
  ...generateItemsForRangeDropdown(yearsRange),
  { label: '+', value: UNSET_EXPERIENCE_MAX_YEAR },
];

const convertExperienceValuesToRangeOfIndexes = (
  allItems: RangeItem[],
  values: [number | null, number | null]
): RangeOfIndexes => {
  const [minIndex, maxIndex] = convertValuesToRangeOfIndexes(allItems, values);
  return [
    minIndex === INDEX_OF_ZERO ? null : minIndex,
    maxIndex === INDEX_OF_PLUS ? null : maxIndex,
  ];
};

const convertRangeOfIndexesToExperienceValues = (
  allItems: RangeItem[],
  rangeOfIndexes: RangeOfIndexes
) => {
  const [minIndex, maxIndex] = rangeOfIndexes;
  const minExp = isLimitNotDefined(minIndex)
    ? MIN_EXPERIENCE_YEAR
    : allItems[minIndex].value; // backend expects absence of min range to be 0
  let maxExp;
  if (isLimitNotDefined(maxIndex) && minExp > 0) {
    maxExp = UNSET_EXPERIENCE_MAX_YEAR;
  } else if (isLimitNotDefined(maxIndex)) {
    maxExp = null; // null when user resets experience to Any
  } else {
    maxExp = allItems[maxIndex].value;
  }
  return [minExp, maxExp];
};

const isAllRangeSelected = (indexA: RangeIndex, indexB: RangeIndex) =>
  (R.isNil(indexA) || indexA === INDEX_OF_ZERO) &&
  (R.isNil(indexB) || indexB === INDEX_OF_PLUS);
const isAnySelected = (selectedItemsIndexes: any) =>
  isAllRangeSelected(
    // @ts-ignore
    R.head(selectedItemsIndexes),
    R.last(selectedItemsIndexes)
  );

const getDropdownClass = (
  allItems: RangeItem[],
  selectedItems: SelectedItems
) => {
  const hasLeftRangeOnly =
    !isAnySelected(selectedItems) &&
    R.last(selectedItems) === allItems.length - 1 &&
    selectedItems.length !== 2;
  return {
    [styles.idealExperienceDropdown]: true,
    [styles.isAnySelected]: isAnySelected(selectedItems),
    [styles.hasLeftRangeOnly]: hasLeftRangeOnly,
  };
};

const getRangeItemClass = (
  index: RangeIndex,
  allItems: RangeItem[],
  selectedItems: SelectedItems
) => {
  return {
    [styles.rangeItem]: true,
    [styles.selectedRangeItem]:
      R.includes(index, selectedItems) || isAnySelected(selectedItems),
    [styles.selectedFirstRangeItem]:
      index === R.head(selectedItems) ||
      (index === 0 && isAnySelected(selectedItems)),
    [styles.selectedLastRangeItem]:
      index === R.last(selectedItems) ||
      (index === allItems.length - 1 && isAnySelected(selectedItems)),
    [styles.rangeLastItem]: index === allItems.length - 1,
  };
};

const IdealExperienceDropdown: React.FC<{
  isOpen: boolean;
  selectedRange: RangeOfIndexes;
  onChange: (values: any) => void;
  dropdownItems: RangeItem[];
}> = ({ isOpen, onChange, selectedRange, dropdownItems }) => {
  const [selectedMinIndex, selectedMaxIndex] = selectedRange;

  const onItemSelected = useCallback(
    (newSelectedIndex) => {
      let newMinIndex = null;
      let newMaxIndex = null;

      if (newSelectedIndex === INDEX_OF_PLUS) {
        if (isRangeNotDefined(selectedRange)) {
          // first click on (+) => do nothing
        } else {
          newMinIndex = selectedMinIndex;
          newMaxIndex = INDEX_OF_PLUS;
        }
      } else if (newSelectedIndex === INDEX_OF_ZERO) {
        // clicking on 0 while having no range selected => 0-1 (AR-2396)
        newMaxIndex = isRangeNotDefined(selectedRange)
          ? INDEX_OF_ONE
          : selectedMaxIndex;
      } else if (newSelectedIndex < selectedMinIndex) {
        newMinIndex = newSelectedIndex;
        newMaxIndex = selectedMaxIndex;
      } else if (newSelectedIndex === selectedMinIndex) {
        // unselect min value => 0 - maxValue
        newMaxIndex = selectedMaxIndex;
      } else if (newSelectedIndex === selectedMaxIndex) {
        // unselect max value => minValue - (+)
        newMinIndex = selectedMinIndex;
      } else if (newSelectedIndex > selectedMaxIndex) {
        // clicking on some value while having no range selected => minValue - (+)
        if (isRangeNotDefined(selectedRange)) {
          newMinIndex = newSelectedIndex;
          newMaxIndex = INDEX_OF_PLUS;
        } else {
          newMinIndex = selectedMinIndex;
          newMaxIndex = newSelectedIndex;
        }
      } else {
        /* setting min or max value depends on the distance to the target value
        if target value is inside selectedRange */
        const middleIndex = (selectedMinIndex + selectedMaxIndex) / 2;
        if (middleIndex < newSelectedIndex) {
          newMinIndex = selectedMinIndex;
          newMaxIndex = newSelectedIndex;
        } else {
          newMinIndex = newSelectedIndex;
          newMaxIndex = selectedMaxIndex;
        }
      }

      let [sortedMinIndex, sortedMaxIndex] = sortSelectedRange(
        newMinIndex,
        newMaxIndex
      );

      // when select all range => nothing (any) is selected
      if (isAllRangeSelected(sortedMinIndex, sortedMaxIndex)) {
        sortedMinIndex = null;
        sortedMaxIndex = null;
      }

      onChange([sortedMinIndex, sortedMaxIndex]);
    },
    [selectedMinIndex, selectedMaxIndex, onChange, selectedRange]
  );

  const selectedItems = useMemo(() => {
    return generateSelectedIndexes(selectedRange, dropdownItems.length - 1);
  }, [dropdownItems, selectedRange]);

  return (
    <RangeDropdown
      isOpen={isOpen}
      dropdownClass={getDropdownClass}
      rangeItems={dropdownItems}
      rangeItemClass={getRangeItemClass}
      arrowClassName={styles.dropdownArrow}
      onItemSelected={onItemSelected}
      selectedItems={selectedItems}
    />
  );
};

// exports / component definitions
const IdealExperienceField: React.FC<{
  className?: string;
  values: ExperienceValues;
  onClick?: () => void;
}> = ({ values, onClick = R.noop, className = '' }) => {
  const { yearsNumber, yearsWords } = stringifyExperienceYears(
    values?.idealMin,
    values?.idealMax
  );

  const isAnyExperience = !isIdealExperienceDefined(values);

  const anyExperienceText = `Any\nexperience`;
  const nYearsOfExperienceText = `${yearsWords}\nof exp`;

  return (
    <div
      className={classNames(styles.experienceField, className)}
      onClick={onClick}
    >
      {isAcceptableExperienceDefined(values) && (
        <div className={styles.experienceFieldTitle}>Ideally</div>
      )}
      <div className={styles.experienceFieldInnerContent}>
        {!isAnyExperience && (
          <span
            tabIndex={0}
            className={styles.experienceFieldValue}
            onFocus={onClick}
            onMouseDown={(event: any) => event.preventDefault()}
          >
            {yearsNumber}
          </span>
        )}
        <label className={styles.experienceFieldLabel}>
          {isAnyExperience ? anyExperienceText : nYearsOfExperienceText}
        </label>
      </div>
    </div>
  );
};

export const IdealExperienceReadOnlyWidget: React.FC<{
  values: ExperienceValues;
}> = ({ values }) => {
  return (
    <IdealExperienceField
      className={styles.experienceReadonlyField}
      values={values}
    />
  );
};
IdealExperienceReadOnlyWidget.displayName = 'IdealExperienceReadOnlyWidget';

// exports / component definitions
export const IdealExperienceWidget: React.FC<{
  values: ExperienceValues;
  onChangeValues?: (values: ExperienceValues) => void;
  isReadOnly?: boolean;
}> = ({ values, onChangeValues, isReadOnly = false }) => {
  const [
    isDropdownVisible,
    outsideClickRef,
    onDropdownTriggerClicked,
    handleKeyDown,
  ] = useRangeVisibility();

  const onChangeDropdownValues = useCallback(
    (newSelectedValues) => {
      const [idealMin, idealMax] = convertRangeOfIndexesToExperienceValues(
        dropdownItems,
        newSelectedValues
      );

      // reset acceptable experience values on changing ideal ones (AR-2518:8)
      const acceptableValues =
        idealMin === values?.idealMin && idealMax === values?.idealMax
          ? {}
          : {
              acceptableMin: null,
              acceptableMax: null,
            };

      onChangeValues({
        ...values,
        idealMin,
        idealMax,
        ...acceptableValues,
      });
    },
    [onChangeValues, values]
  );

  const dropdownValues = useMemo(() => {
    return [
      values?.idealMin ?? null,
      values?.idealMax ?? null,
    ] as RangeOfIndexes;
  }, [values]);

  const selectedRange = useMemo(() => {
    return convertExperienceValuesToRangeOfIndexes(
      dropdownItems,
      dropdownValues
    );
  }, [dropdownValues]);

  return isReadOnly ? (
    <IdealExperienceReadOnlyWidget values={values} />
  ) : (
    <div
      className={classNames(styles.experienceWidget, {
        [styles.isDropdownOpened]: isDropdownVisible,
      })}
      ref={outsideClickRef}
      onKeyDownCapture={handleKeyDown}
    >
      <IdealExperienceField
        values={values}
        onClick={onDropdownTriggerClicked}
      />
      <IdealExperienceDropdown
        dropdownItems={dropdownItems}
        selectedRange={selectedRange}
        onChange={onChangeDropdownValues}
        isOpen={isDropdownVisible}
      />
    </div>
  );
};
