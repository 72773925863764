import * as ApiModel from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  calculateAcceptAny,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria/BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

type DegreeCriteriaListValue = SearchCriteriaListValue<{
  value: number;
  level: number;
}>;

export interface DegreeCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.degree;
  idealList: SearchCriteriaListValue<{ value: number; level: number }>[];
  acceptableList: SearchCriteriaListValue<{ value: number; level: number }>[];
  equivalentExperience: number;
}

export const isDegree = (
  item: BaseSearchCriteriaData
): item is DegreeCriteriaData => item.cardType === CardType.degree;

export const getDegreeCriteriaDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.IsNewEdit,
}: AddCriteriaInitials): DegreeCriteriaData => ({
  idx,
  id: CardType.degree,
  key: genId(),
  initialCardStatus,
  cardType: CardType.degree,
  idealList: null,
  acceptableList: null,
  equivalentExperience: null,
  importance: criteriaImportanceOptions[importance],
});

const convertDegreeListToSelectValues = (
  list: ApiModel.EducationDegreeInfo[]
): DegreeCriteriaListValue[] =>
  list
    ? list.map((item: any) => ({
        value: item.id,
        label: item.fullName,
        level: item.level,
      }))
    : null;

// TODO: EducationDegreeInfo contains required `list` property
const convertSelectValuesToDegreeList = (
  list: DegreeCriteriaListValue[]
): ApiModel.EducationDegreeInfo[] =>
  list
    ? list.map((item: any) => ({
        id: item.value,
        fullName: item.label,
        level: item.level,
        list: null,
      }))
    : null;

export const mapEducationToDegreeCriteria = (
  degree: ApiModel.EducationDegreeData
): DegreeCriteriaData => ({
  idx: degree.idx,
  id: CardType.degree,
  key: genId(),
  cardType: CardType.degree,
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  idealList: convertDegreeListToSelectValues(degree.idealList),
  acceptableList: convertDegreeListToSelectValues(degree.acceptableList),
  equivalentExperience: degree.equivalentExperience,
  importance: criteriaImportanceOptions[degree.importance],
});

export const mapDegreeCriteriaToEducation = (
  degree: DegreeCriteriaData
): ApiModel.EducationDegreeData => ({
  idx: degree.idx,
  setInCriteria: true,
  acceptAny: calculateAcceptAny(degree),
  idealList: convertSelectValuesToDegreeList(degree.idealList),
  acceptableList: convertSelectValuesToDegreeList(degree.acceptableList),
  equivalentExperience: degree.equivalentExperience,
  importance: degree.importance.value,
});
