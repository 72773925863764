import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import * as phrases from '@air/constants/phrases';
import styles from './StatusBadge.css';
import { UIText, Paragraph } from '@air/components';
import { useOutsideClick } from '@air/utils/hooks';

type BadgeAction = {
  label: string;
  execute: () => void;
  style?: React.CSSProperties;
  confirmationMessage?: string;
};

type StatusBadge = {
  className?: string;
  defaultLabel: string;
  actions?: BadgeAction[];
};

export const StatusBadge: React.FC<StatusBadge> = ({
  className,
  defaultLabel,
  actions,
}) => {
  const [currentAction, setCurrentAction] = useState(null);
  const [isListVisible, setListVisibility] = useState(false);

  const onActionClick = useCallback((action: BadgeAction) => {
    if (action.confirmationMessage) {
      setCurrentAction(action);
      return;
    }

    action.execute();
  }, []);

  const confirmAction = useCallback(() => {
    setCurrentAction(null);
    currentAction.execute();
  }, [currentAction]);

  const cancelAction = useCallback(() => {
    setCurrentAction(null);
  }, []);

  const [outsideClickRef] = useOutsideClick(() => {
    setListVisibility(false);
  });

  const setVisibility = useCallback((e) => {
    e.stopPropagation();
    setListVisibility((isListVisible: boolean) => !isListVisible);
  }, []);

  return !currentAction ? (
    <div
      key="status-badge"
      className={classNames(styles.statusBadge, className)}
      onClick={setVisibility}
    >
      <UIText
        small
        bold
        className={classNames(styles.statusBadgeLabel, {
          [styles.badgeWithActions]: !!actions,
          [styles.badgeWithOpenActionsList]: isListVisible,
        })}
      >
        {' '}
        {defaultLabel}{' '}
      </UIText>
      {actions && (
        <BadgeActionsList
          ref={outsideClickRef}
          className={classNames(styles.statusBadgeActionsList, {
            [styles.hidden]: !isListVisible,
          })}
          actions={actions}
          onActionClick={onActionClick}
        />
      )}
    </div>
  ) : (
    <div
      key="confirm-button"
      className={classNames(styles.statusBadge, styles.confirmBadge, className)}
    >
      <UIText
        small
        bold
        className={styles.statusBadgeLabel}
        onClick={confirmAction}
      >
        {phrases.CONFIRM}
      </UIText>
      {currentAction.confirmationMessage && (
        <Paragraph small className={styles.confirmationMessage}>
          {currentAction.confirmationMessage}
          <span
            onClick={cancelAction}
            className={styles.confirmationMessageCancel}
          >
            {phrases.CANCEL}
          </span>
        </Paragraph>
      )}
    </div>
  );
};

StatusBadge.displayName = 'StatusBadge';

type BadgeActionsListProps = {
  className?: string;
  actions: BadgeAction[];
  onActionClick: (action: BadgeAction) => void;
};
const BadgeActionsList = React.forwardRef<
  HTMLUListElement,
  BadgeActionsListProps
>(({ actions, className, onActionClick }, ref) => {
  return (
    <ul ref={ref} className={classNames(styles.badgeActionsList, className)}>
      {actions.map((action) => (
        <li
          key={action.label}
          style={action.style}
          onClick={() => onActionClick(action)}
        >
          <UIText small>{action.label}</UIText>
        </li>
      ))}
    </ul>
  );
});
BadgeActionsList.displayName = 'BadgeActionsList';
