import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  ComponentProps,
} from 'react';
import { Formik, Form, Field } from 'formik';
import R from '@air/third-party/ramda';
import { toast } from '@air/third-party/toast';
import { getErrorDescription } from '@air/utils/errorHandling';
import classNames from 'classnames';
import { FormikJobTitle, UploadsList } from 'components';
import { filterOutEmptyCriteriaLists } from 'features/DraftSection/NewSearch/utils';
import { ApiErrorResponse } from '@air/api/models/ApiErrorResponse';
import { CurrentSearchT } from 'domain/Kanban/Kanban';
import { SearchCriteriaForm } from 'features/DraftSection/NewSearch/SearchCriteriaForm';

import {
  mapCriteriaRequestToCriteriaData,
  SearchCriteriaData,
  sortCardData,
  isEmptyCriteriaRequest,
} from 'domain/SearchCriteria';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import { SearchTabs } from '@air/constants/tabs';
import {
  RequisitionFile,
  UploadStatus,
  SelectInterviewKanbanContext,
} from 'context';
import { FlagsSettings, FlagsViewMode } from './FlagsSettings';
import {
  mapRedFlagsRequestToRedFlagData,
  RedFlagSettingsData,
} from 'domain/SearchCriteria/RedFlagsData';
import {
  RedFlagAlertType,
  RedFlagResolutionEnum,
  SearchProgressStatusEnum,
} from '@air/api';
import { APP_EVENTS } from 'domain/Kanban/events';
import { subscribe, emit } from 'hooks/usePubSub';
import { trackEvent } from '@air/utils/ga';
import {
  GA_LABEL_FLAGS_DRAFT_TAB_OPENING,
  GA_LABEL_VIEW_BY_CATEGORY,
  GA_LABEL_VIEW_BY_RESOLUTION,
} from 'constants/gaLabels';
import {
  Paragraph,
  TabsControl,
  ConfirmationModal,
  LabeledDropdown,
  CheckboxList,
} from '@air/components';
import { GACategory } from 'air-shared/domain/Common/GATypes';
import styles from './NewSearch.css';
import { formSearchRequest, searchHasEmptySkills } from 'providers/utils';
import { SelectInterviewPanel } from 'features/SelectInterviewPanel/SelectInterviewPanel';
import { useKanbanContext, KanbanProvider } from 'providers/KanbanProvider';
import { kanbanSelectors, customerProfileSelectors } from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { useHistory } from 'react-router-dom';
import { CheckboxOptionT } from '@air/domain/Forms/types';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import { useJobSpecializations } from '@air/hooks/useJobSpecializations';

export type NewSearchValues = {
  name: string;
  criteria: SearchCriteriaData[];
  specializations: string[];
};

type Props = {
  item: CurrentSearchT | null;
  onSearchUpdate: any; // TODO: define type
  className?: string;
  isDraftBlocked?: boolean;
  onSearchCreate?: (name: string) => Promise<void>;
  isReadOnly?: boolean;
  showUploadsList?: boolean;
  files?: RequisitionFile[];
  pendingFilesCount?: number;
  failedFilesCount?: number;
  onRemoveApplicant?: (
    atsCandidateId: string,
    fileId: string,
    uploadStatus: UploadStatus
  ) => void;
  isUploadsListOpen?: boolean;
  totalFilesCount?: number;
};

/**
 * please be aware, that this limit is applied to our systems only (creating own requisitions/saving existing searches),
 * if and when search form will be used with other services and external ATS, keep in mind that limitations should be
 * reconsidered.
 */
const SEARCH_TITLE_MAX_LENGTH = 1024;

const flagViewModeNames = {
  [FlagsViewMode.Resolution]: phrases.FLAGS_VIEW_MODE_RESOLUTION,
  [FlagsViewMode.Category]: phrases.FLAGS_VIEW_MODE_CATEGORY,
};

const SEARCH_TABS = [
  {
    label: phrases.SEARCH_TABS_CRITERIA,
    value: SearchTabs.Criteria,
  },
  {
    label: phrases.SEARCH_TABS_FLAGS,
    value: SearchTabs.Flags,
  },
];

export const JobSpecializationDropdown: React.FC<
  Omit<ComponentProps<typeof CheckboxList>, 'options'> & {
    disabled?: boolean;
    options: { label: string; value: string }[];
    value: string[];
    controlClassName?: string;
    onClose?: () => void;
  }
> = ({ disabled, controlClassName, options, value, onClose, ...props }) => {
  const checkboxListOptions = useMemo(() => {
    return R.map(
      (it) => ({
        ...it,
        checked: value.includes(it.value as string),
      }),
      options
    );
  }, [options, value]);

  const label = useMemo(() => {
    const selectedValues = R.filter(R.prop('checked'), checkboxListOptions);
    let dropdownDisplayValue =
      sharedPhrases.MULTI_SELECT_CHECKBOXES_VALUE_NOT_SELECTED;
    if (selectedValues.length === checkboxListOptions.length) {
      dropdownDisplayValue = sharedPhrases.MULTI_SELECT_CHECKBOXES_VALUE_ALL;
    } else if (selectedValues.length === 1) {
      dropdownDisplayValue = selectedValues[0].label;
    } else if (selectedValues.length === 2) {
      dropdownDisplayValue = `${selectedValues[0].label} & ${selectedValues[1].label}`;
    } else if (selectedValues.length > 2) {
      dropdownDisplayValue = `${selectedValues[0].label} & ${
        selectedValues.length - 1
      } ${sharedPhrases.MULTI_SELECT_CHECKBOXES_MORE}`;
    }
    return (
      <dl>
        <dt>{phrases.JOB_SPECIALIZATION_DROPDOWN_LABEL}:</dt>
        <dd>{dropdownDisplayValue}</dd>
      </dl>
    );
  }, [checkboxListOptions]);

  return (
    <LabeledDropdown
      label={label}
      tooltipText={phrases.JOB_SPECIALIZATION_DROPDOWN_LABEL}
      className={controlClassName}
      labelClassName={styles.jobSpecializationControlLabel}
      tooltipContainerClassName={styles.jobSpecializationDropdown}
      dropdownPlacement="bottom-end"
      tooltipDelay={TOOLTIP_DELAY_TIME_LONG}
      onClose={onClose}
    >
      <CheckboxList
        options={checkboxListOptions}
        isDisabled={disabled}
        {...props}
      />
    </LabeledDropdown>
  );
};

export const NewSearchView: React.FC<Props> = ({
  onSearchUpdate,
  onSearchCreate = R.noop,
  item,
  className,
  isDraftBlocked = false,
  isReadOnly = false,
  files = [],
  pendingFilesCount,
  onRemoveApplicant,
  isUploadsListOpen = false,
  totalFilesCount,
}) => {
  const [currentTab, setCurrentTab] = useState(SearchTabs.Criteria);
  const [flagsViewMode, setFlagsViewMode] = useState(FlagsViewMode.Resolution);
  const [isSelectInterviewPanelOpened, setIsSelectInterviewPanelOpened] =
    useState(false);
  const [jobSpecializations, setJobSpecializations] = useState([]);

  const { specializations: specializationsOptions } = useJobSpecializations();

  const formikRef = useRef(null);

  useEffect(() => {
    setJobSpecializations(item.specializations);
  }, [item.specializations]);

  const [
    isImportCriteriaConfirmationModalOpen,
    setIsImportCriteriaConfirmationModalOpen,
  ] = useState(false);

  const jobRequisitionDetails = useKanbanContext(
    kanbanSelectors.jobRequisitionDetails
  );

  const history = useHistory();
  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  useEffect(() => {
    if (currentTab === SearchTabs.Flags) {
      trackEvent({
        category: GACategory.DraftPage,
        label: GA_LABEL_FLAGS_DRAFT_TAB_OPENING,
      });
    }
  }, [currentTab]);

  const onChangeJobSpecialization = useCallback((values: CheckboxOptionT[]) => {
    const checkedValues = R.compose<
      CheckboxOptionT[][],
      CheckboxOptionT[],
      string[]
    >(
      R.map((it) => it.value as string),
      R.filter(R.prop('checked'))
    )(values);
    setJobSpecializations(checkedValues);
  }, []);

  const changeFlagsViewMode = useCallback(() => {
    const newMode =
      flagsViewMode === FlagsViewMode.Resolution
        ? FlagsViewMode.Category
        : FlagsViewMode.Resolution;
    setFlagsViewMode(newMode);

    trackEvent({
      category: GACategory.DraftPage,
      label:
        newMode === FlagsViewMode.Resolution
          ? GA_LABEL_VIEW_BY_RESOLUTION
          : GA_LABEL_VIEW_BY_CATEGORY,
    });
  }, [flagsViewMode]);

  const initialValues: NewSearchValues = useMemo(() => {
    return {
      name: item.name,
      redFlags: mapRedFlagsRequestToRedFlagData(item.redFlags),
      specializations: item.specializations,
      criteria: sortCardData(
        item.criteria ? mapCriteriaRequestToCriteriaData(item.criteria) : []
      ),
    };
  }, [item]);

  const onChangeTabs = useCallback(
    (newTab: SearchTabs) => {
      setCurrentTab(newTab);
    },
    [setCurrentTab]
  );

  const resetSearchForm = useCallback(() => {
    formikRef.current?.resetForm();
  }, []);

  useEffect(() => {
    const unsubscribeChangeSearchCriteriaTab = subscribe(
      APP_EVENTS.CHANGE_SEARCH_CRITERIA_TAB,
      onChangeTabs
    );
    const unsubscribeResetSearchForm = subscribe(
      APP_EVENTS.RESET_SEARCH_FORM,
      resetSearchForm
    );
    return () => {
      unsubscribeChangeSearchCriteriaTab();
      unsubscribeResetSearchForm();
    };
  }, [onChangeTabs, resetSearchForm]);

  const onFormSubmit = useCallback(
    (values, { setValues }: any) => {
      if (!item?.ats?.externalJobDescriptionId) {
        return onSearchCreate(values.name);
      }
      const nonEmptyValues: NewSearchValues = {
        ...values,
        criteria: filterOutEmptyCriteriaLists(values.criteria),
        redFlags: values.redFlags,
      };
      setValues(nonEmptyValues);

      const request = formSearchRequest({ ...item, ...nonEmptyValues });
      try {
        if (searchHasEmptySkills(request)) {
          throw new Error(phrases.SEARCH_CAN_NOT_HAVE_EMPTY_SKILLS);
        }
      } catch (err) {
        toast.error(getErrorDescription(err));
        return;
      }

      // reset criteria to `null` in case they are empty
      if (isEmptyCriteriaRequest(request.criteria)) {
        request.criteria = null;
      }

      onSearchUpdate(item.searchId, {
        ...request,
        specializations: jobSpecializations,
      }).then(R.noop, (err: ApiErrorResponse) => {
        err && toast.error(getErrorDescription(err));
      });
    },
    [onSearchUpdate, onSearchCreate, item, jobSpecializations]
  );

  const onImportCriteriaClicked = useCallback(() => {
    if (!isSelectInterviewPanelOpened) {
      if (isImportCriteriaConfirmationModalOpen) {
        setIsImportCriteriaConfirmationModalOpen(false);
      } else {
        setIsImportCriteriaConfirmationModalOpen(true);
        return;
      }
      setIsSelectInterviewPanelOpened(true);
    }
  }, [isImportCriteriaConfirmationModalOpen, isSelectInterviewPanelOpened]);

  const closeSelectInterviewPanel = useCallback(() => {
    setIsSelectInterviewPanelOpened(false);
  }, []);

  useEffect(() => {
    return subscribe(APP_EVENTS.IMPORT_CRITERIA, onImportCriteriaClicked);
  }, [onImportCriteriaClicked]);

  const updateJobSpecializations = useCallback(async () => {
    onSearchUpdate(
      item.searchId,
      {
        ...item,
        specializations: jobSpecializations,
      },
      false
    ).then(
      () => {
        setTimeout(resetSearchForm, 0); // reset form after initialValues is updated
      },
      (err: ApiErrorResponse) => {
        err && toast.error(getErrorDescription(err));
      }
    );
  }, [item, onSearchUpdate, jobSpecializations, resetSearchForm]);

  const onCloseJobSpecializationsDropdown = useCallback(() => {
    if (!R.stringArraysEqual(item?.specializations, jobSpecializations)) {
      emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
        text: phrases.SAVE_WITH_SPECIALIZATION_MODAL_CONFIRM_TEXT,
        confirmLabel: phrases.SAVE_WITH_SPECIALIZATION_MODAL_CONFIRM_BUTTON,
        onConfirm: updateJobSpecializations,
        options: {
          modalStyles: {
            content: { width: 280 },
          },
          onCancel: () => {
            setJobSpecializations(item.specializations);
          },
        },
      });
    }
  }, [item?.specializations, jobSpecializations, updateJobSpecializations]);

  const shouldShowJobSpecializations = [
    SearchProgressStatusEnum.DRAFT,
    SearchProgressStatusEnum.MODIFY,
  ].includes(item.status);

  return (
    <div className={classNames(styles.searchFormWrapper, className)}>
      <Formik
        innerRef={(p) => (formikRef.current = p)}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      >
        {({ values, handleSubmit, setFieldValue, submitForm }: any) => {
          const isHintShown = !values.name;

          // TODO: replace with code that is coming with the story
          // https://railsreactor.atlassian.net/browse/AR-6278
          const onChangeFlagSettings = (
            flagType: RedFlagAlertType,
            newResolutionValue: RedFlagResolutionEnum,
            newEnabledValue: boolean
          ) => {
            setFieldValue(
              'redFlags',
              values.redFlags.reduce(
                (acc: RedFlagSettingsData[], flag: RedFlagSettingsData) => {
                  return [
                    ...acc,
                    flag.type === flagType
                      ? {
                          ...flag,
                          enabled: newEnabledValue,
                          resolution: newResolutionValue,
                        }
                      : flag,
                  ];
                },
                []
              )
            );
            submitForm();
          };

          return (
            <Form translate="yes" className={styles.searchForm}>
              <div
                className={classNames(styles.searchFormContent, {
                  [styles.blockedSearchForm]: isDraftBlocked,
                })}
              >
                <Field
                  disabled={isReadOnly}
                  name="name"
                  maxLength={SEARCH_TITLE_MAX_LENGTH}
                  className={styles.jobTitleWrapper}
                  titleClassName={styles.jobTitle}
                  handleChange={async (field: string, value: string) => {
                    await setFieldValue(field, value);
                    handleSubmit();
                  }}
                  component={FormikJobTitle}
                  placeholder={phrases.NEW_REQUISITION_JOB_TITLE_PLACEHOLDER}
                  defaultValue={phrases.NEW_REQUISITION_DEFAULT_JOB_TITLE}
                  jobRequisitionDetails={jobRequisitionDetails}
                  created={item.created}
                  isStandaloneAtsUser={isStandaloneAtsUser}
                />
                {!isReadOnly && isHintShown && (
                  <Paragraph
                    small
                    className={styles.jobTitleHint}
                    dangerouslySetInnerHTML={{
                      __html: phrases.NEW_REQUISITION_JOB_TITLE_HINT,
                    }}
                  />
                )}
                <div
                  className={classNames(styles.criteriaSection, {
                    [styles.blockedSearchForm]: isDraftBlocked,
                  })}
                >
                  <UploadsList
                    files={files}
                    isOpen={isUploadsListOpen && !R.isNullOrEmpty(files)}
                    pendingFilesCount={pendingFilesCount}
                    totalFilesCount={totalFilesCount}
                    onRemoveApplicant={onRemoveApplicant}
                  />
                  <div
                    className={classNames(styles.searchTabsWrapper, {
                      [styles.disabled]: isDraftBlocked,
                    })}
                  >
                    <TabsControl<SearchTabs>
                      onChange={onChangeTabs}
                      className={styles.searchTabs}
                      items={SEARCH_TABS}
                      selectedTab={currentTab}
                    />
                    {currentTab === SearchTabs.Flags && (
                      <button
                        type="button"
                        className={styles.flagViewModeSwitcher}
                        onClick={changeFlagsViewMode}
                      >
                        {flagViewModeNames[flagsViewMode]}
                      </button>
                    )}
                    {shouldShowJobSpecializations && (
                      <JobSpecializationDropdown
                        disabled={isTrialExpired}
                        controlClassName={styles.jobSpecializationControl}
                        options={specializationsOptions}
                        value={jobSpecializations || []}
                        onChange={onChangeJobSpecialization}
                        onClose={onCloseJobSpecializationsDropdown}
                      />
                    )}
                  </div>
                  {currentTab === SearchTabs.Flags && (
                    <FlagsSettings
                      flags={values.redFlags}
                      viewMode={flagsViewMode}
                      onChange={onChangeFlagSettings}
                    />
                  )}
                  {currentTab === SearchTabs.Criteria && (
                    <SearchCriteriaForm
                      setFieldValue={setFieldValue}
                      handleSubmit={handleSubmit}
                      values={values}
                      isFormBlocked={isDraftBlocked}
                      onImportCriteria={onImportCriteriaClicked}
                    />
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {isSelectInterviewPanelOpened && (
        <KanbanProvider
          isSelectMode
          history={history}
          contextProvider={SelectInterviewKanbanContext.Provider}
          user={user}
          dataSourceId={dataSourceId}
        >
          <SelectInterviewPanel
            targetInterview={item}
            closePanel={closeSelectInterviewPanel}
          />
        </KanbanProvider>
      )}
      <ConfirmationModal
        onConfirm={onImportCriteriaClicked}
        onCancel={() => setIsImportCriteriaConfirmationModalOpen(false)}
        confirmLabel={phrases.IMPORTING_CRITERIA_BUTTON_CONFIRMATION_TEXT}
        isOpen={isImportCriteriaConfirmationModalOpen}
      >
        <Paragraph short bold centered>
          {phrases.IMPORTING_CRITERIA_MODAL_CONFIRMATION_TEXT}
        </Paragraph>
      </ConfirmationModal>
    </div>
  );
};
