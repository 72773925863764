/* !IMPORTANT!
  Don't change string values in this file unless requested from BA as
  it can result in unwanted record duplication on Google Analytics
*/

import { trackEvent } from '@air/utils/ga';
import {
  GA_LABEL_CLOSE_JOB_DESCRIPTION,
  GA_LABEL_OPEN_JOB_DESCRIPTION,
} from 'customer-portal/src/constants/gaLabels';

export enum GACategory {
  KanbanPage = 'kanban-page',
  DraftPage = 'draft-page',
  ScreeningPage = 'screening-page',
  ScreeningCandidateProfile = 'screening-candidate-profile',
  Settings = 'settings',
  ContactUs = 'contact-us',
  ApplicantJobPosition = 'applicant-job-position',
}

export enum GAAction {
  Push = 'push',
  DragNDrop = 'drag-n-drop',
  Scroll = 'scroll',
}

export const trackJobDescriptionOpenCloseState = (
  category: GACategory,
  isOpen?: boolean
) => {
  trackEvent({
    category,
    label: isOpen
      ? GA_LABEL_OPEN_JOB_DESCRIPTION
      : GA_LABEL_CLOSE_JOB_DESCRIPTION,
  });
};
