import React, { useEffect } from 'react';
import * as H from 'history';

import { useLineupCriteriaConfig } from 'components/LineupTable/hooks';
import {
  Button,
  CheckboxList,
  LabeledDropdown,
  Paragraph,
  TabsControl,
  TooltipWrapper,
} from '@air/components';
import { JobTitle, SearchResultsView, WatchList } from 'components';
import { UploaderButton } from 'features';
import {
  CandidateSearchProfileStatus,
  SearchProgressStatusEnum,
} from '@air/api';
import * as urls from 'constants/urls';
import { CriteriaConfig } from 'domain/HeaderData/HeaderDataMapper';
import { LineupTabs } from '@air/constants/tabs';
import {
  isActiveTab,
  isMatchMinerTab,
  isMatchScoutTab,
  isMatchServiceTab,
} from 'domain/CandidateData/CandidateLineupData';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData';
import { TabItem } from '@air/components/TabsControl/TabsControl';
import { useKanbanContext } from 'providers/KanbanProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { RightSidebarPanel, useAppMethods } from 'providers/AppProvider';
import { LoadMoreApplicantsButton } from 'features/InterviewSection/LoadMoreApplicantsSection/LoadMoreApplicantsSection';
import { useMatchServiceAvailabilityCheck } from 'domain/MatchServices/useMatchServiceSettings';

import * as phrases from 'constants/phrases';

import interviewSectionStyles from './../InterviewSection.css';
import styles from './InterviewView.css';
import { ProfileSort } from '@air/api/models/ProfileSort';
import { SortDropdown } from 'features/SortDropdown/SortDropdown';

type InterviewViewProps = {
  history: H.History;
  onOpenedSectionsChange: (
    sections: CandidateSearchProfileStatusEnum[]
  ) => void;
  changeCandidateStatus: (
    candidateId: string,
    candidateCurrentStatus: CandidateSearchProfileStatus,
    candidateNextStatus: CandidateSearchProfileStatus
  ) => Promise<void> | null;
  isCandidatesListLoading: boolean;
  currentTab: LineupTabs;
  interviewLineupTabs: TabItem<LineupTabs>[];
  onTabChange: (newTab: LineupTabs) => void;
  loadAllCandidates: (sort: ProfileSort) => void;
};

export const InterviewView: React.FC<InterviewViewProps> = ({
  history,
  changeCandidateStatus,
  onOpenedSectionsChange,
  isCandidatesListLoading,
  currentTab,
  interviewLineupTabs,
  onTabChange,
  loadAllCandidates,
}) => {
  const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
  const currentMatchMinerSearch = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearch
  );
  const currentMatchScoutSearch = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearch
  );
  const currentSearchStatus = useKanbanContext(
    kanbanSelectors.currentSearchStatus
  );
  const currentSearchCurrentCriteria = useKanbanContext(
    kanbanSelectors.currentSearchCurrentCriteria
  );
  const currentSearchCreated = useKanbanContext(
    kanbanSelectors.currentSearchCreated
  );
  const externalJobDescriptionId = useKanbanContext(
    kanbanSelectors.externalJobDescriptionId
  );
  const jobRequisitionDetails = useKanbanContext(
    kanbanSelectors.jobRequisitionDetails
  );
  const currentSearchName = useKanbanContext(kanbanSelectors.currentSearchName);
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const { setRightSidebarPanel } = useAppMethods();

  const isInterviewPaused =
    currentSearchStatus === SearchProgressStatusEnum.ONHOLD;
  useEffect(() => {
    /*
      This effect redirects from 'expired' urls, when search was removed,
      or returned to draft state for updates.
    */
    if (
      currentSearchId &&
      currentSearchStatus !== SearchProgressStatusEnum.INTERVIEW &&
      !isInterviewPaused &&
      history.action !== 'PUSH'
    ) {
      history.replace(urls.ROOT_ROUTE);
    }
  }, [currentSearchId, currentSearchStatus, history, isInterviewPaused]);

  const [lineupCriteriaConfig, setLineupConfig] = useLineupCriteriaConfig(
    currentSearchCurrentCriteria,
    currentSearchId
  );
  const { hasRequiredMatchServiceCriteria, hasMatchServiceSearchLoaded } =
    useMatchServiceAvailabilityCheck(currentTab);

  const showRestartMatchServiceIcon =
    (isMatchMinerTab(currentTab) && !!currentMatchMinerSearch) ||
    (isMatchScoutTab(currentTab) && !!currentMatchScoutSearch);

  return (
    <>
      <JobTitle
        disabled
        className={interviewSectionStyles.resultsHeaderWrapper}
        titleClassName={interviewSectionStyles.resultsHeader}
        title={currentSearchName}
        jobRequisitionDetails={jobRequisitionDetails}
        isStandaloneAtsUser={isStandaloneAtsUser}
        created={currentSearchCreated}
      />
      <div className={styles.lineupTabs}>
        <TabsControl<LineupTabs>
          onChange={onTabChange}
          items={interviewLineupTabs}
          selectedTab={currentTab}
          placement="bottom"
        />
        <div className={styles.lineupHeaderDropdowns}>
          {isActiveTab(currentTab) && (
            <LabeledDropdown
              icon="eye"
              iconClassName={styles.dropdownIconClassname}
              tooltipText={phrases.LINEUP_WATCHING_DROPDOWN_LABEL}
              className={styles.watchListLabeledDropdown}
              tooltipContainerClassName={styles.watchListLabeledDropdownTooltip}
            >
              <WatchList jobRequisitionId={externalJobDescriptionId} />
            </LabeledDropdown>
          )}
          <SortDropdown onSortChange={loadAllCandidates} />
          <LabeledDropdown
            icon="filter"
            tooltipText={phrases.LINEUP_CRITERIA_DROPDOWN_LABEL}
          >
            <CheckboxList
              options={lineupCriteriaConfig}
              onChange={(values: CriteriaConfig) => setLineupConfig(values)}
            />
          </LabeledDropdown>
          {showRestartMatchServiceIcon && (
            <TooltipWrapper
              tooltip={
                <Paragraph small>
                  {isTrialExpired
                    ? phrases.REQUEST_MORE_TRIAL_EXPIRED
                    : phrases.MM_SETTINGS_PANEL_DISABLED}
                </Paragraph>
              }
              enabled={!hasRequiredMatchServiceCriteria || isTrialExpired}
              disabledChildren
            >
              <Button
                variant={Button.variants.DEFAULT}
                className={styles.mmSettings}
                disabled={
                  !hasRequiredMatchServiceCriteria ||
                  isTrialExpired ||
                  currentSearchStatus !== SearchProgressStatusEnum.INTERVIEW
                }
                icon="settings"
                onClick={() =>
                  setRightSidebarPanel(RightSidebarPanel.MatchServiceSettings)
                }
              />
            </TooltipWrapper>
          )}
          {isStandaloneAtsUser && !isMatchServiceTab(currentTab) && (
            <UploaderButton
              className={styles.lastButton}
              disabled={isTrialExpired}
            />
          )}
          {isMatchServiceTab(currentTab) && hasMatchServiceSearchLoaded && (
            <LoadMoreApplicantsButton
              className={styles.lastButton}
              currentTab={currentTab}
            />
          )}
        </div>
      </div>
      {currentSearchCurrentCriteria && (
        <SearchResultsView
          className={styles.interviewSearchResultsView}
          changeCandidateStatus={changeCandidateStatus}
          columnsConfig={lineupCriteriaConfig}
          getCandidateProfileUrl={urls.makeInterviewProfileUrl}
          onOpenedSectionsChange={onOpenedSectionsChange}
          isInterviewPaused={isInterviewPaused}
          isCandidatesListLoading={isCandidatesListLoading}
          currentTab={currentTab}
        />
      )}
    </>
  );
};
