/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum SectionType {
  GREETING = 'GREETING',
  CANDIDATESCURRENTEXPERIENCE = 'CANDIDATES_CURRENT_EXPERIENCE',
  MATCHEDSKILLS = 'MATCHED_SKILLS',
  SKILLSWITHLACKOFINFO = 'SKILLS_WITH_LACK_OF_INFO',
  MISSINGSKILLS = 'MISSING_SKILLS',
  FREEFORMTEXT = 'FREEFORM_TEXT',
}
