import * as phrases from 'constants/phrases';
import { BaseEducationCriteriaWithList, CardType } from 'domain/SearchCriteria';
import {
  BaseCandidateData,
  CandidateEducationCriteriaListItem,
} from 'domain/CandidateData/CandidateCriteria/BaseCandidateData';
import { GraduationData } from 'domain/CandidateData/CandidateCriteria/GraduationData';
import {
  Education,
  SearchEducationMajor,
  SearchEducationResponse,
} from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import { getEducationAlertData } from '@air/domain/Alerts/AlertData';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';

export interface CandidateMajorData
  extends BaseCandidateData,
    BaseEducationCriteriaWithList<CandidateEducationCriteriaListItem> {
  cardType: CardType.major;
  setInCriteria: boolean;
  graduation: GraduationData;
}

const getMajorItem = (
  major: SearchEducationMajor,
  education: Education | null,
  interview: InterviewData
): CandidateEducationCriteriaListItem => {
  return {
    id: CardType.major,
    label: education?.major?.fullName ?? phrases.MAJOR_LABEL,
    status: major.status,
    alert: getEducationAlertData(major),
    interview,
  };
};
export const getMajorData = (
  education: SearchEducationResponse,
  graduation: GraduationData,
  interview: InterviewData
): CandidateMajorData => {
  if (!education.major || !education.major?.setInCriteria) {
    return null;
  }
  const { major } = education;
  const idealList = [getMajorItem(major, education.education, interview)];

  return {
    // @ts-ignore idx is a part of SearchEducationDegree type
    idx: major.idx,
    id: CardType.major,
    cardType: CardType.major,
    refId: major.primaryItemRefId,
    idealList: idealList,
    acceptableList: null,
    setInCriteria: major.setInCriteria,
    status: major.status,
    importance: criteriaImportanceOptions[major.importance],
    graduation,
  };
};

export const majorHasComments = (major: CandidateMajorData): boolean => {
  return !!major.idealList?.[0]?.interview?.commentId;
};
