import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import classNames from 'classnames';
import R from '@air/third-party/ramda';

import * as appConstants from 'constants/app';
import * as urls from 'constants/urls';
import * as phrases from '@air/constants/phrases';
import { useDebounce } from '@air/utils/hooks';
import { isMobileOrTablet } from '@air/utils/mobile';

import { Logo } from '@air/components';
import { FeatureView, LoginForm, SvgIcon } from 'components';
import { LoginRequest } from '@air/api/models';
import { ResetPasswordForm } from 'components/ResetPasswordForm/ResetPasswordForm';
import * as userApi from 'domain/UserManagement/userApi';

import styles from './Login.css';
import formViewStyles from 'components/FormView/FormView.css';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

export const Login: React.FC<
  RouteChildrenProps<
    { from?: string },
    { from?: { pathname: string }; formTitle?: string }
  >
> = (props) => {
  const { authenticate, setUserCredentials } = useCustomerProfileMethods();

  const authenticated = useCustomerProfileContext(
    customerProfileSelectors.authenticated
  );
  const authError = useCustomerProfileContext(
    customerProfileSelectors.authError
  );
  const userCredentials = useCustomerProfileContext(
    customerProfileSelectors.userCredentials
  );

  const [loginReady, setLoginReady] = useState(false);

  const [resetPasswordFormShown, showResetPasswordForm] = useState(false);

  const [loginEmail, changeEmailHandler] = useState(null);

  useEffect(() => {
    setTimeout(() => setLoginReady(true), 500);
  }, []);

  const { location, history } = props;

  const { from = { pathname: urls.ROOT_ROUTE }, formTitle } =
    location.state || {};

  const handleResetPassword = useCallback(() => {
    showResetPasswordForm(true);
  }, []);
  const closeResetPasswordForm = useCallback(() => {
    showResetPasswordForm(false);
  }, []);

  const handleSubmit = useCallback(
    async (values: LoginRequest) => {
      if (authenticate) {
        const authResult = await authenticate(values);
        if (authResult?.customerId) {
          // do not redirect in case of login failure
          history.push(from.pathname);
        }
      }
      setUserCredentials(null);
    },
    [authenticate, history, from, setUserCredentials]
  );

  /*
    If Login screen has `userCredentials`, it means user was
    redirected to this screen after setting new password and we
    don't need to redirect him anywhere.
    Because of race condition, during logout process `authenticated` flag
    has `true` value for a moment, which causes <Redirect /> rendering
    and incorrect behavior.
  */

  if (authenticated && from && !userCredentials) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <FeatureView
        className={classNames({ [styles.isMobileOrTablet]: isMobileOrTablet })}
      >
        <FeatureView.Aside className={styles.loginAside}>
          <Logo isWhiteLogoText />
        </FeatureView.Aside>
        <FeatureView.Content
          className={classNames(
            styles.loginContent,
            formViewStyles.formViewContent,
            {
              [styles.ready]: loginReady,
            }
          )}
        >
          <LoginForm
            formTitle={formTitle}
            defaultValues={userCredentials}
            apiError={authError}
            onSubmit={handleSubmit}
            onChangeEmail={changeEmailHandler}
            onResetPassword={handleResetPassword}
          />
        </FeatureView.Content>
        {loginReady && (
          <ResetPasswordView
            onClose={closeResetPasswordForm}
            isOpened={resetPasswordFormShown}
            initialEmail={loginEmail}
          />
        )}
      </FeatureView>
    </>
  );
};

const ResetPasswordView: React.FC<{
  isOpened: boolean;
  initialEmail: string;
  onClose: () => void;
}> = ({ isOpened, initialEmail, onClose }) => {
  const resetPassword = useDebounce(
    (email: string) => {
      return userApi.resetPassword(email).fork(R.noop, R.noop);
    },
    appConstants.REQUEST_DEBOUNCE_TIME,
    true
  );

  return (
    <FeatureView.Content
      className={classNames(
        styles.resetPasswordView,
        formViewStyles.formViewContent,
        {
          [styles.ready]: isOpened,
        }
      )}
    >
      {isOpened && <div className={styles.closeResetPasswordView} />}
      <button onClick={onClose} className={styles.hideButton}>
        <SvgIcon icon="collapse" height="1em" width="1.5em" />
        <span>{phrases.TO_LOGIN_TEXT}</span>
      </button>
      <ResetPasswordForm
        onSubmit={resetPassword}
        initialValues={{ email: initialEmail }}
      />
    </FeatureView.Content>
  );
};
