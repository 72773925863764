import { createContext } from 'use-context-selector';
import localSettings from '@air/localSettings';
import { INTERVIEW_LEFT_PANEL_COLLAPSED } from '@air/domain/WebStorage/webStorage';
import { RightSidebarPanel } from 'providers/AppProvider';

export type AppContextT = {
  isLeftSideBarCollapsed: boolean;
  rightSidebarPanel: RightSidebarPanel;
  methods: {
    setRightSidebarPanel: (panel: RightSidebarPanel | null) => void;
    setLeftSideBarCollapsed?: (isCollapsed: boolean) => void;
  };
};

export const AppContext = createContext<Partial<AppContextT>>({
  isLeftSideBarCollapsed: localSettings[INTERVIEW_LEFT_PANEL_COLLAPSED],
});

AppContext.displayName = 'AppContext';
