import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import classNames from 'classnames';

// imports from components
import { Toggle, TypeaheadSearchField, UIText } from '@air/components';

// import … from 'types';

// imports from helpers
import { useDebounce } from '@air/utils/hooks';

// imports from constants
import * as phrases from 'constants/phrases';
import * as appConstants from 'constants/app';
import { INPUT_DEBOUNCE_TIME } from '@air/constants/app';

import styles from './KanbanFiltersToolbar.css';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_ALL_ITEMS, GA_LABEL_MY_ITEMS } from 'constants/gaLabels';
import {
  useKanbanContext,
  useKanbanMethods,
  useSelectInterviewKanbanMethods,
  useSelectInterviewKanbanContext,
  DEFAULT_CURRENT_SEARCH_FILTER,
} from 'providers/KanbanProvider';
import { kanbanSelectors } from 'selectors';

const MAX_SEARCH_FILTER_LENGTH = 150;

type KanbanFiltersToolbarProps = {
  className?: string;
  children?: React.ReactNode;
  isSelectMode?: boolean;
  selectModeTargetId: string | number;
};
export const KanbanFiltersToolbar: React.FC<KanbanFiltersToolbarProps> = ({
  className,
  children,
  isSelectMode,
  selectModeTargetId,
}) => {
  const [kanbanContext, kanbanContextMethods] = isSelectMode
    ? [useSelectInterviewKanbanContext, useSelectInterviewKanbanMethods]
    : [useKanbanContext, useKanbanMethods];
  const currentSearchFilterIsOwnedByMe = kanbanContext(
    kanbanSelectors.currentSearchFilterIsOwnedByMe
  );
  const currentSearchFilterName = kanbanContext(
    kanbanSelectors.currentSearchFilterName
  );
  const { updateSearchFilter } = kanbanContextMethods();

  const [isFilteredByOwnSearches, setOwnFilter] = useState(
    currentSearchFilterIsOwnedByMe
  );

  const [selected, selectInput] = useState(false);
  const [hoverOverRejectIcon, setHoverOverRejectIcon] = useState(false);
  const ref = useRef<HTMLInputElement>();
  const methods = useForm<{
    searchNameFilter: string;
  }>({
    defaultValues: {
      searchNameFilter: currentSearchFilterName,
    },
    shouldUnregister: true,
  });
  const { watch, setValue, handleSubmit } = methods;

  const nameFilterField = 'searchNameFilter';
  const currentSearchNameValue = watch(nameFilterField);

  const onUpdateCallback = useCallback(
    (currentSearchNameValue: string) => {
      updateSearchFilter({
        searchFilter: {
          name: currentSearchNameValue.trim(),
          isOwnedByMe: isFilteredByOwnSearches,
        },
        saveInSessionStore: !isSelectMode,
        withCriteria: isSelectMode,
        excludeId: [selectModeTargetId as string],
      });
    },
    [
      updateSearchFilter,
      isFilteredByOwnSearches,
      isSelectMode,
      selectModeTargetId,
    ]
  );
  const onUpdateFilter = useDebounce(onUpdateCallback, INPUT_DEBOUNCE_TIME);

  // we clear all the filters once user closes the
  // panel with kanban in select mode
  useEffect(() => {
    return () => {
      isSelectMode &&
        updateSearchFilter({
          searchFilter: DEFAULT_CURRENT_SEARCH_FILTER,
          withCriteria: isSelectMode,
        });
    };
  }, [updateSearchFilter, isSelectMode]);

  const onSubmit = useCallback(() => {
    /*
      TODO: Find out why react-hook-form performs submit with empty
      search filter on app start.
    */
    if (currentSearchNameValue === undefined) return;
    onUpdateFilter(currentSearchNameValue);
  }, [onUpdateFilter, currentSearchNameValue]);

  const submitForm = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit]
  );

  useEffect(() => {
    submitForm();
  }, [isFilteredByOwnSearches, currentSearchNameValue, submitForm]);

  const setFocus = useCallback(() => {
    ref?.current?.focus();
  }, []);

  const [isClearingInput, triggerClearingAnimation] = useState(false);
  const removeInput = useCallback(() => {
    triggerClearingAnimation(true);
    setHoverOverRejectIcon(false);
    setTimeout(() => {
      triggerClearingAnimation(false);
      setValue(nameFilterField, '', { shouldValidate: true });
      setFocus();
    }, 400);
  }, [nameFilterField, setValue, setFocus]);

  const handleInputFocus = useCallback(() => {
    selectInput(true);
    setFocus();
  }, [setFocus, selectInput]);

  const handleInputBlur = useCallback(() => {
    if (currentSearchNameValue?.length === 0) {
      selectInput(false);
    }
    setValue(nameFilterField, currentSearchNameValue);
  }, [currentSearchNameValue, nameFilterField, selectInput, setValue]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    e.target.value = value.slice(0, MAX_SEARCH_FILTER_LENGTH);
  }, []);

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      /*
      Prevent any printable characters input,
      if filter already has 150 symbols.
      We also should prevent pasting text.
    */
      const PRINTABLE_CHAR_LENGTH = 1;
      const { currentTarget, key, metaKey } = e;
      if (
        currentTarget.value.length >= MAX_SEARCH_FILTER_LENGTH &&
        key.length === PRINTABLE_CHAR_LENGTH &&
        !metaKey
      ) {
        e.preventDefault();
      }
    },
    []
  );

  useEffect(() => {
    currentSearchNameValue?.length > 0 && !selected && selectInput(true);
  }, [currentSearchNameValue, selected, selectInput]);

  const applyUserSearchFilterCallback = useCallback(() => {
    setOwnFilter((isFilteredByOwnSearches) => !isFilteredByOwnSearches);
    trackEvent({
      category: GACategory.KanbanPage,
      label: isFilteredByOwnSearches ? GA_LABEL_MY_ITEMS : GA_LABEL_ALL_ITEMS,
    });
  }, [isFilteredByOwnSearches]);

  const applyUserSearchFilter = useDebounce(
    applyUserSearchFilterCallback,
    appConstants.REQUEST_DEBOUNCE_TIME,
    true
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={submitForm}
        className={classNames(styles.kanbanFiltersToolbar, className)}
      >
        <div className={styles.filterWrapper}>
          <TypeaheadSearchField
            isClearingInput={isClearingInput}
            currentSearchNameValue={currentSearchNameValue}
            nameFilterField={nameFilterField}
            placeholderValue={phrases.SEARCH_FILTER_PLACEHOLDER}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            handleKeydown={handleKeydown}
            handleChange={handleChange}
            hoverOverRejectIcon={hoverOverRejectIcon}
            setHoverOverRejectIcon={setHoverOverRejectIcon}
            removeInput={removeInput}
            ref={ref}
          />
        </div>
        <label
          className={styles.ownSearchesToggleLabel}
          data-testid="my-own-searches-toggle"
        >
          <UIText small wide>
            {phrases.OWN_SEARCHES_FILTER}
          </UIText>
          <Toggle
            className={styles.ownSearchesToggle}
            checked={isFilteredByOwnSearches}
            onChange={applyUserSearchFilter}
          />
        </label>
        {children}
      </form>
    </FormProvider>
  );
};
