import ReactGA from 'react-ga';
import { GACategory, GAAction } from '@air/domain/Common/GATypes';

export enum GACustomDimension {
  UserId = 'dimension1',
}

type GAEventT = {
  category: GACategory;
  action?: GAAction;
  label?: string;
  value?: number;
};

export const trackPageView = (path: string) => {
  ReactGA.pageview(path);
};

export const trackModalView = (path: string) => {
  ReactGA.modalview(path);
};

export const trackEvent = ({
  category,
  action = GAAction.Push,
  label = '',
  value = 0,
}: GAEventT) => {
  ReactGA.event({
    category: category.toString(),
    action: action.toString(),
    label,
    value,
  });
};

export const trackScrollToEvent = (element: HTMLElement, event: GAEventT) => {
  let previousChangeState: IntersectionObserverEntry;

  const onIntersection = (changes: IntersectionObserverEntry[]) => {
    changes.forEach((change) => {
      if (
        change.isIntersecting &&
        previousChangeState?.isIntersecting === false
      ) {
        trackEvent(event);
      }
      previousChangeState = change;
    });
  };

  const observer = new IntersectionObserver(onIntersection);

  observer.observe(element);

  return () => {
    observer.unobserve(element);
  };
};
