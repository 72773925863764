import React from 'react';
import classNames from 'classnames';
import styles from './CollapsedCardPreview.css';
import { Card } from '@air/components';

export const CollapsedCardPreview: React.FC<any> = ({ title }) => (
  <Card.ResizeableCardWrapper resizeable>
    <Card.SearchCriteriaCardContainer
      className={classNames(styles.collapsedCardPreview)}
    >
      <Card.SearchCriteriaCardContent>
        <Card.Title resizeable title={title} />
      </Card.SearchCriteriaCardContent>
    </Card.SearchCriteriaCardContainer>
  </Card.ResizeableCardWrapper>
);
