import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

import { Button, Header } from '@air/components';
import {
  KanbanIconButton,
  LoadingDocumentSkeleton,
  LoadingTextPlaceholder,
  SvgIcon,
} from 'components';
import { defaultLazyImportFallback } from '@air/utils/codeSplitting';
import { CandidateProfileSection } from '../CandidateProfileSection';
import { CandidateProfileData } from 'domain/CandidateData/CandidateProfileData';
import {
  getCandidateResumeBlob,
  getCandidateResumeFileHeaders,
} from 'domain/CandidateData/candidateApiTasks';
import { trackEvent } from '@air/utils/ga';
import * as phrases from 'constants/phrases';
import { GA_LABEL_DOCUMENTS_PREVIEW } from 'constants/gaLabels';
import { GACategory } from 'air-shared/domain/Common/GATypes';

import styles from './CandidateProfileDocumentsTab.css';

const PDF_ONLY_PREVIEW = true;

const CandidateProfilePDFView = React.lazy(() => {
  return import(
    /* webpackChunkName: "CandidateProfilePDFView" */ '../CandidateProfilePDFView'
  ).catch(() => defaultLazyImportFallback());
});

export const CandidateProfileDocumentsTab: React.FC<{
  dataSourceId: number;
  attachments: CandidateProfileData['attachments'];
  onDownloadClick: () => void;
  isResumeDownloading: boolean;
}> = ({ dataSourceId, attachments, onDownloadClick, isResumeDownloading }) => {
  const [PDFBlobFile, setPDFBlobFile] = useState(null);
  const [isPDFBlobFileLoading, setPDFBlobFileLoading] = useState(false);
  const [resumeHeaders, setResumeHeaders] = useState(null);
  const [isResumeLoading, setIsResumeLoading] = useState(true);

  const resumeAttachment = R.head(attachments);
  const resumeAttachmentId = resumeAttachment?.id ?? '';

  useEffect(() => {
    trackEvent({
      category: GACategory.ScreeningCandidateProfile,
      label: GA_LABEL_DOCUMENTS_PREVIEW,
    });
  }, []);

  useEffect(() => {
    const getPdfAttachment = () => {
      setPDFBlobFileLoading(true);
      getCandidateResumeBlob(dataSourceId, resumeAttachmentId, PDF_ONLY_PREVIEW)
        .then(setPDFBlobFile)
        .finally(() => setPDFBlobFileLoading(false));
    };

    if (resumeAttachmentId) {
      // reset resume state
      setIsResumeLoading(true);
      setPDFBlobFile(null);
      setResumeHeaders(null);
      // get headers of the original resume file
      getCandidateResumeFileHeaders(dataSourceId, resumeAttachmentId)
        .then((payload) => {
          // if file exists
          if (!R.isNil(payload)) {
            setResumeHeaders(payload);
            // if it's a pdf file - fetch its blob
            if (payload.fileExtension === 'pdf') {
              getPdfAttachment();
            } else {
              // if not check if we have a pdf version
              return getCandidateResumeFileHeaders(
                dataSourceId,
                resumeAttachmentId,
                PDF_ONLY_PREVIEW
              ).then((payload) => {
                if (!R.isNil(payload)) {
                  getPdfAttachment();
                }
              });
            }
          }
        })
        .finally(() => {
          setIsResumeLoading(false);
        });
    }
  }, [dataSourceId, resumeAttachmentId]);

  const candidateHasResume = !!resumeAttachmentId && !!resumeHeaders;
  const candidateHasBrokenResumeFile =
    resumeAttachmentId &&
    resumeHeaders &&
    !PDFBlobFile &&
    !isResumeLoading &&
    !isPDFBlobFileLoading;

  const AttachmentsIcons = useMemo(() => {
    const attachmentsIconClasses = [styles.firstIcon, styles.secondIcon];

    return (
      <div className={styles.attachmentsIconsWrapper}>
        {attachmentsIconClasses.map((iconClass, index) => (
          <SvgIcon
            key={index}
            className={classNames(styles.icon, iconClass)}
            icon="attachment-solid"
          />
        ))}
      </div>
    );
  }, []);

  const fullScreenRef = useRef<HTMLDivElement>(null);
  const [isFullScreenMode, setFullScreen] = useState(false);
  const showFullScreen = useCallback(() => setFullScreen(true), []);
  const hideFullScreen = useCallback(
    (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      if ((event.target as Element).closest(`.${styles.preview}`)) return;

      setFullScreen(false);
    },
    []
  );

  const loadingPlaceholder = (
    <LoadingTextPlaceholder
      className={styles.candidateProfilePDFLoadingText}
      text={phrases.CANDIDATE_PROFILE_LOADING_RESUME_PREVIEW}
    />
  );

  const fullScreenPDF = ReactDOM.createPortal(
    <div
      ref={fullScreenRef}
      className={styles.fullScreenPreview}
      onClick={hideFullScreen}
    >
      <KanbanIconButton
        onClick={hideFullScreen}
        className={styles.closeFullScreenButton}
        icon="collapse"
      >
        {phrases.BACK_BUTTON}
      </KanbanIconButton>
      <CandidateProfilePDFView
        className={styles.preview}
        renderDelay={100}
        file={PDFBlobFile}
        isLoading={isPDFBlobFileLoading}
        loadingElement={loadingPlaceholder}
      />
    </div>,
    document.body
  );

  return (
    <CandidateProfileSection title="">
      {isFullScreenMode && fullScreenPDF}
      <div className={styles.attachmentsWrapper}>
        {candidateHasResume && (
          <Button
            icon={isResumeDownloading ? null : 'download-file-icon'}
            className={classNames(styles.downloadButton, {
              [styles.isLoading]: isResumeDownloading,
            })}
            variant={Button.variants.EXPANDING}
            loaderPosition={Button.loaderPosition.LEFT}
            onClick={onDownloadClick}
            isLoading={isResumeDownloading}
            loaderColor="blue"
          >
            {isResumeDownloading
              ? phrases.DOWNLOAD_RESUME_IN_PROGRESS_TEXT
              : phrases.DOWNLOAD_RESUME_INITIAL_TEXT}
          </Button>
        )}
        {candidateHasBrokenResumeFile && (
          <div className={styles.noDocumentsWrapper}>
            <div className={styles.noDocuments}>
              <SvgIcon
                icon="not-supported-image"
                className={styles.notSupportedIcon}
              />
              <Header level={3} className={styles.label}>
                {phrases.PREVIEW_FILE_UNAVAILABLE_START}
                <Button
                  variant={Button.variants.INLINE}
                  className={styles.noDocumentsAction}
                  onClick={onDownloadClick}
                  disabled={isResumeDownloading}
                >
                  {phrases.PREVIEW_FILE_UNAVAILABLE_ACTION}
                </Button>
                {phrases.PREVIEW_FILE_UNAVAILABLE_END}
              </Header>
            </div>
          </div>
        )}
        {!candidateHasResume && !isResumeLoading && (
          <div className={styles.noDocumentsWrapper}>
            <div className={styles.noDocuments}>
              {AttachmentsIcons}
              <Header level={3}>
                {phrases.CANDIDATE_PROFILE_NO_DOCUMENTS_ATTACHED}
              </Header>
            </div>
          </div>
        )}
        {(PDFBlobFile || isPDFBlobFileLoading) && (
          <div className={styles.attachmentDocumentPreview}>
            <Suspense fallback={<LoadingDocumentSkeleton />}>
              <CandidateProfilePDFView
                className={styles.inlinePdfPreview}
                onClick={showFullScreen}
                renderDelay={100}
                file={PDFBlobFile}
                isLoading={isPDFBlobFileLoading || isResumeLoading}
                loadingElement={<LoadingDocumentSkeleton />}
              />
            </Suspense>
          </div>
        )}
      </div>
    </CandidateProfileSection>
  );
};
