// imports from vendor deps
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RouteChildrenProps } from 'react-router';
import { Route, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import R from '@air/third-party/ramda';

// imports from types
import { getSearchTitle, SearchListItemT } from 'domain/Kanban/Kanban';
import {
  CandidateSearchProfileStatus,
  SearchExtendedResponse,
  SearchProgressStatusEnum,
  MatchMinerProcessingCandidateStateEnum,
} from '@air/api';
// imports from 'components'
import {
  Button,
  FeatureView,
  JobTitle,
  LoadingTextPlaceholder,
} from 'components';
import { InterviewView } from 'features/InterviewSection/InterviewView/InterviewView';
import { useLineupTabs } from 'components/LineupTable/hooks';
import { QuestionnaireFormTriggerButton } from './QuestionnaireForm/QuestionnaireForm';

// imports from 'constants'
import {
  TOOLTIP_DELAY_TIME_LONG,
  CLICK_DEBOUNCE_TIME_LONG,
} from '@air/constants/app';
import * as urls from 'constants/urls';
import { makeInterviewUrl } from 'constants/urls';
import * as phrases from 'constants/phrases';
import {
  GA_LABEL_COMPLETE_SCREENING,
  GA_LABEL_PAUSE_SCREENING,
} from 'constants/gaLabels';

// imports from helpers
import { CandidateQuery, useCandidateQuery } from 'hooks/useCandidateQuery';
import { emit, subscribe } from 'hooks/usePubSub';
import { useLineupSSESubscription } from 'hooks/useLineupSSESubscription';
import { APP_EVENTS } from 'domain/Kanban/events';
import { useCandidateProfileMethods } from 'providers/CandidateProfileProvider';
import { CandidateSearchProfileStatusEnum } from 'domain/CandidateData';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { EmailsTabs, LineupTabs } from '@air/constants/tabs';
import {
  getLineupTabData,
  isActiveTab,
  isMatchMinerTab,
  // isMatchScoutTab,
  isPassiveTab,
  mapSectionsToCandidateStatuses,
} from 'domain/CandidateData/CandidateLineupData';
import { UtilitiesControls } from 'features';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import {
  customerProfileSelectors,
  kanbanSelectors,
  appSelectors,
} from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';

// imports from styles
import styles from './InterviewSection.css';
import commonStyles from 'styles/commonStyles.css';
import { useAppContext, useAppMethods } from 'providers/AppProvider';
import { UIText, TooltipWrapper } from '@air/components';
import { searchHasModifiedDraft } from 'features/InterviewSection/interviewHelpers';
import { useUploadApplicantsMethods } from 'providers/UploadApplicantsProvider';
import { useComputedCounters } from 'domain/SearchCounters/useComputedCounters';
import { ProfileSort } from '@air/api/models/ProfileSort';
import { useModifyCriteriaCallback } from 'features/InterviewSection/useModifyCriteriaCallback';

// component proptypes
export type InterviewSectionProps = {
  dataSourceId?: string;
  jobDescriptionId?: string;
};

// exports / component definitions
export const InterviewSection: React.FC<
  RouteChildrenProps<InterviewSectionProps>
> = React.memo((props) => {
  const { history, match } = props;
  const currentSearch = useKanbanContext(kanbanSelectors.currentSearch);
  const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
  const currentSearchStatus = useKanbanContext(
    kanbanSelectors.currentSearchStatus
  );
  const currentSearchAppliedCount = useKanbanContext(
    kanbanSelectors.currentSearchAppliedCount
  );
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const currentMatchMinerSearch = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearch
  );
  const currentMatchMinerSearchId = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearchId
  );
  const currentMatchScoutSearch = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearch
  );
  const currentMatchScoutSearchId = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearchId
  );

  const interviewsList = useKanbanContext(kanbanSelectors.interviewsList);

  const {
    updateSearchCandidatesProfileSize,
    pauseInterview,
    republishInterview,
    closeInterview,
    fetchSearch,
    clearCurrentSearch,
  } = useKanbanMethods();

  const isLeftSideBarCollapsed = useAppContext(
    appSelectors.isLeftSideBarCollapsed
  );

  const {
    loadAllCandidates,
    updateCandidatesForStatus,
    changeCandidateStatus,
  } = useCandidateProfileMethods();

  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const ats = useCustomerProfileContext(customerProfileSelectors.ats);
  const isPassiveSearchEnabled = useCustomerProfileContext(
    customerProfileSelectors.isPassiveSearchEnabled
  );

  const { setRightSidebarPanel } = useAppMethods();

  const { setCurrentJobRequisitionId, getApplicants } =
    useUploadApplicantsMethods();

  const {
    computedCounters,
    computedPassiveCounters,
    computedMatchMinerCounters,
    computedMatchScoutCounters,
  } = useComputedCounters();

  const jobDescriptionId = match.params?.jobDescriptionId;
  const hasDataSourceId = match.params?.dataSourceId;

  const [isCandidatesListLoading, setCandidatesListLoading] = useState({
    [LineupTabs.Active]: false,
    [LineupTabs.Passive]: false,
    [LineupTabs.MatchMiner]: false,
    [LineupTabs.MatchScout]: false,
  });

  const isMatchMinerSearchStarted = currentMatchMinerSearch?.matchMinerSearch;
  const isMatchScoutSearchStarted = currentMatchScoutSearch?.matchScoutSearch;
  const isCandidatesProcessingInProgress =
    currentMatchMinerSearch?.matchMinerProcessingCandidateState ===
    MatchMinerProcessingCandidateStateEnum.INPROGRESS;

  const [currentTab, interviewLineupTabs, onTabChange] = useLineupTabs({
    activeCount: computedCounters?.applicants,
    passiveCount: computedPassiveCounters?.applicants,
    matchMinerCount: computedMatchMinerCounters?.applicants,
    matchScoutCount: computedMatchScoutCounters?.applicants,
    isPassiveSearchEnabled,
    activeSearchId: currentSearchId,
    history,
    isCandidatesProcessingInProgress,
  });

  const isMatchMinerView = isMatchMinerTab(currentTab);
  // const isMatchScoutView = isMatchScoutTab(currentTab);
  const isPassiveView = isPassiveTab(currentTab) && isPassiveSearchEnabled;

  const openedSections = useRef<{
    active: CandidateSearchProfileStatusEnum[];
    passive: CandidateSearchProfileStatusEnum[];
    matchMiner: CandidateSearchProfileStatusEnum[];
    matchScout: CandidateSearchProfileStatusEnum[];
  }>({
    active: [],
    passive: [],
    matchMiner: [],
    matchScout: [],
  });

  const updateOpenedSections = useCallback((sections, currentTab) => {
    openedSections.current[currentTab as LineupTabs] = sections;
  }, []);

  // Initialize SSE notifications handlers
  useLineupSSESubscription({
    openedSections,
    currentTab,
    jobDescriptionId,
  });

  const { candidateNameQuery, onCandidateNameSearchChange, clearSearchQuery } =
    useCandidateQuery('');

  const { computedSearchId, computedAppliedCount, currentTabOpenedSections } =
    useMemo(() => {
      return getLineupTabData({
        activeData: {
          computedSearchId: currentSearchId,
          computedAppliedCount: currentSearchAppliedCount,
          currentTabOpenedSections: openedSections.current.active,
        },
        passiveData: {
          computedSearchId: currentSearchId,
          computedAppliedCount: currentSearch?.passiveCount?.totalCount,
          currentTabOpenedSections: openedSections.current.passive,
        },
        matchMinerData: {
          computedSearchId: currentMatchMinerSearchId,
          computedAppliedCount:
            currentMatchMinerSearch?.passiveCount?.totalCount,
          currentTabOpenedSections: openedSections.current.matchMiner,
        },
        matchScoutData: {
          computedSearchId: currentMatchScoutSearchId,
          computedAppliedCount:
            currentMatchScoutSearch?.passiveCount?.totalCount,
          currentTabOpenedSections: openedSections.current.matchScout,
        },
        currentTab,
      });
    }, [
      currentSearchId,
      currentSearchAppliedCount,
      currentSearch?.passiveCount?.totalCount,
      currentMatchMinerSearchId,
      currentMatchMinerSearch?.passiveCount?.totalCount,
      currentMatchScoutSearchId,
      currentMatchScoutSearch?.passiveCount?.totalCount,
      currentTab,
    ]);

  const matchInterviewRoute = useRouteMatch(
    makeInterviewUrl(dataSourceId, jobDescriptionId)
  );
  const isExactInterviewRoute = !!matchInterviewRoute?.isExact;

  const [hasModifiedDraft, setHasModifiedDraft] = useState(false);

  useEffect(() => {
    async function updateSearchHasModifiedDraft() {
      setHasModifiedDraft(await searchHasModifiedDraft(currentSearchId));
    }

    if (isExactInterviewRoute && currentSearchId) {
      updateSearchHasModifiedDraft();
    }
    return () => {
      setHasModifiedDraft(false);
    };
  }, [isExactInterviewRoute, currentSearchId]);

  const loadAllSorted = useCallback(
    (sort: ProfileSort) => {
      return loadAllCandidates({
        searchId: computedSearchId,
        tab: currentTab,
        sort,
        sortId: currentSearchId,
      });
    },
    [computedSearchId, currentSearchId, currentTab, loadAllCandidates]
  );

  const searchId = {
    [LineupTabs.Active]: currentSearchId,
    [LineupTabs.Passive]: currentSearchId,
    [LineupTabs.MatchMiner]: currentMatchMinerSearchId,
    [LineupTabs.MatchScout]: currentMatchScoutSearchId,
  }[currentTab];

  const onOpenedSectionsChanged = useCallback(
    (sections: CandidateSearchProfileStatusEnum[]) => {
      const recentlyOpenedSections = R.difference(
        sections,
        currentTabOpenedSections
      );
      if (!R.isNullOrEmpty(recentlyOpenedSections) && computedSearchId) {
        updateCandidatesForStatus({
          searchId: computedSearchId,
          status: mapSectionsToCandidateStatuses(recentlyOpenedSections),
          size: computedAppliedCount,
          filter: candidateNameQuery,
          tab: currentTab,
        });
      }
      if (!R.isNullOrEmpty(recentlyOpenedSections)) {
        updateOpenedSections(sections, currentTab);
      }
    },
    [
      currentTabOpenedSections,
      computedAppliedCount,
      computedSearchId,
      candidateNameQuery,
      updateOpenedSections,
      updateCandidatesForStatus,
      currentTab,
    ]
  );

  useEffect(() => {
    if (hasDataSourceId && jobDescriptionId) {
      fetchSearch({
        dataSourceId,
        jobDescriptionId,
        shouldPerformPassiveSearch: isPassiveSearchEnabled,
      });
    }
  }, [
    isPassiveSearchEnabled,
    setCandidatesListLoading,
    fetchSearch,
    loadAllCandidates,
    hasDataSourceId,
    dataSourceId,
    jobDescriptionId,
  ]);

  const candidatesFetchParams = useMemo(
    function getFetchParams() {
      switch (currentTab) {
        case LineupTabs.Active:
          return {
            shouldFetchCandidates: !!currentSearchId,
            statusSection: 'active' as const,
            searchId: currentSearchId,
            sortId: currentSearchId,
          };
        case LineupTabs.Passive:
          return {
            shouldFetchCandidates: !!currentSearchId && isPassiveSearchEnabled,
            statusSection: 'passive' as const,
            searchId: currentSearchId,
            sortId: currentSearchId,
          };
        case LineupTabs.MatchMiner:
          return {
            shouldFetchCandidates:
              !!currentMatchMinerSearchId && isMatchMinerSearchStarted,
            statusSection: 'matchMiner' as const,
            searchId: currentMatchMinerSearchId,
            sortId: currentSearchId,
          };
        case LineupTabs.MatchScout:
          return {
            shouldFetchCandidates:
              !!currentMatchScoutSearchId && isMatchScoutSearchStarted,
            statusSection: 'matchScout' as const,
            searchId: currentMatchScoutSearchId,
            sortId: currentSearchId,
          };
      }
    },
    [
      currentTab,
      currentSearchId,
      isPassiveSearchEnabled,
      currentMatchMinerSearchId,
      isMatchMinerSearchStarted,
      currentMatchScoutSearchId,
      isMatchScoutSearchStarted,
    ]
  );

  useEffect(() => {
    async function fetchCandidatesForCurrentTab() {
      const { shouldFetchCandidates, statusSection, searchId, sortId } =
        candidatesFetchParams;

      if (shouldFetchCandidates) {
        setCandidatesListLoading((state) => ({
          ...state,
          [currentTab]: true,
        }));
        await loadAllCandidates({
          searchId,
          status: mapSectionsToCandidateStatuses(
            openedSections.current[statusSection]
          ),
          tab: currentTab,
          filter: candidateNameQuery,
          sortId,
        });
        setCandidatesListLoading((state) => ({
          ...state,
          [currentTab]: false,
        }));
      }
    }

    fetchCandidatesForCurrentTab();
  }, [
    candidateNameQuery,
    openedSections.current.active,
    openedSections.current.passive,
    openedSections.current.matchMiner,
    loadAllCandidates,
    currentTab,
    candidatesFetchParams,
    currentMatchMinerSearch?.passiveCount?.totalCount,
  ]);

  useEffect(() => {
    if (jobDescriptionId) {
      clearSearchQuery('');
    } else {
      clearCurrentSearch();
    }
  }, [jobDescriptionId, clearSearchQuery, clearCurrentSearch]);

  useEffect(() => {
    if (currentSearchId && isPassiveView) {
      updateSearchCandidatesProfileSize(currentSearchId, LineupTabs.Passive);
    }
  }, [currentSearchId, isPassiveView, updateSearchCandidatesProfileSize]);

  /**
   * Get applicants for upload widget:
   */
  useEffect(() => {
    async function fetchData() {
      if (jobDescriptionId && ats.dataSourceId && isStandaloneAtsUser) {
        await setCurrentJobRequisitionId(jobDescriptionId);
        getApplicants(ats.dataSourceId, jobDescriptionId);
      }
    }

    fetchData();
  }, [
    isStandaloneAtsUser,
    jobDescriptionId,
    setCurrentJobRequisitionId,
    ats.dataSourceId,
    getApplicants,
  ]);

  const pauseInterviewCb = useCallback(() => {
    trackEvent({
      category: GACategory.ScreeningPage,
      label: GA_LABEL_PAUSE_SCREENING,
    });
    pauseInterview(currentSearch);
  }, [currentSearch, pauseInterview]);

  const republishInterviewCb = useCallback(() => {
    republishInterview(currentSearch);
  }, [currentSearch, republishInterview]);

  useEffect(() => {
    return subscribe(
      APP_EVENTS.SET_CANDIDATES_LIST_LOADING,
      (tabName: LineupTabs, loading: boolean) => {
        setCandidatesListLoading((state) => ({
          ...state,
          [tabName]: loading,
        }));
      }
    );
  }, []);

  const closeSearchCb = useCallback(async () => {
    trackEvent({
      category: GACategory.ScreeningPage,
      label: GA_LABEL_COMPLETE_SCREENING,
    });
    const isSearchClosed = await closeInterview(currentSearch);
    emit(APP_EVENTS.JOB_STATUS_CHANGE);
    if (isSearchClosed !== false) {
      history.push(
        urls.makeClosedSearchUrl(
          dataSourceId,
          jobDescriptionId,
          LineupTabs.Active
        )
      );
    }
  }, [currentSearch, jobDescriptionId, dataSourceId, closeInterview, history]);

  const changeCandidateStatusCb = useCallback(
    async (
      candidateId: string,
      candidateCurrentStatus: CandidateSearchProfileStatus,
      candidateNextStatus: CandidateSearchProfileStatus
    ) => {
      const searchId = isMatchMinerView
        ? currentMatchMinerSearchId
        : currentSearchId;
      await changeCandidateStatus({
        searchId,
        candidateId,
        candidateCurrentStatus,
        candidateNextStatus,
      });
    },
    [
      currentMatchMinerSearchId,
      currentSearchId,
      isMatchMinerView,
      changeCandidateStatus,
    ]
  );

  const onQuestioningUpdated = useCallback(async () => {
    emit(APP_EVENTS.QUESTIONING_START);
    updateCandidatesForStatus({
      searchId: computedSearchId,
      status: currentTabOpenedSections,
      size: computedAppliedCount,
      filter: candidateNameQuery,
      tab: currentTab,
    });
  }, [
    computedAppliedCount,
    computedSearchId,
    currentTabOpenedSections,
    candidateNameQuery,
    currentTab,
    updateCandidatesForStatus,
  ]);

  const modifyInterviewCb = useModifyCriteriaCallback();

  useEffect(() => {
    return () => setRightSidebarPanel(null);
  }, [setRightSidebarPanel]);

  const isInterviewPaused =
    currentSearchStatus === SearchProgressStatusEnum.ONHOLD;

  const currentInterviewTitle = useMemo(
    () =>
      getSearchTitle<SearchExtendedResponse, SearchListItemT>(
        currentSearch,
        interviewsList,
        currentSearchId
      ),
    [currentSearch, currentSearchId, interviewsList]
  );

  const isScreeningSearchLoaded = !!currentSearch;
  const isResumeScreeningDisabled = isTrialExpired || !isScreeningSearchLoaded;
  const isQuestionnaireButtonDisabled =
    isInterviewPaused || isTrialExpired || !isScreeningSearchLoaded;
  const isSendEmailsButtonDisabled =
    !searchId ||
    isInterviewPaused ||
    isTrialExpired ||
    !isScreeningSearchLoaded;
  const isCriteriaAndFlagsDisabled = !isScreeningSearchLoaded;
  const isPauseScreeningDisabled = isTrialExpired || !isScreeningSearchLoaded;
  const isClosePositionDisabled = isTrialExpired || !isScreeningSearchLoaded;

  const goToEmailConfiguration = () => {
    history.push(
      urls.makeEmailConfigUrl({
        dataSourceId,
        jobDescriptionId,
        tab: currentTab,
        params: {
          emailConfigTab: EmailsTabs.JobEmails,
        },
      })
    );
  };

  return (
    <>
      <div className={styles.interviewSection}>
        <FeatureView.LeftSidebar>
          <>
            {currentSearch && (
              <UIText
                tiny
                bold
                className={classNames(commonStyles.leftSidebarHeader, {
                  [commonStyles.isCollapsed]: isLeftSideBarCollapsed,
                  [styles.pausedHeader]: isInterviewPaused,
                  [styles.screeningHeader]: !isInterviewPaused,
                })}
              >
                {isInterviewPaused
                  ? phrases.PAUSED_SECTION_HEADER
                  : phrases.SCREENING_SECTION_HEADER}
              </UIText>
            )}
            {isInterviewPaused && (
              <TooltipWrapper
                delayShow={TOOLTIP_DELAY_TIME_LONG}
                tooltip={phrases.RESUME_SCREENING_INTERVIEW_SECTION_BUTTON}
                enabled={isLeftSideBarCollapsed}
                disabledChildren={isResumeScreeningDisabled}
                flexGrow={false}
              >
                <Button
                  className={commonStyles.leftSidebarButton}
                  variant={Button.variants.POSITIVE_CONFIRM}
                  icon="start-screening-icon"
                  debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                  disabled={isResumeScreeningDisabled}
                  onClick={() =>
                    emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
                      text: phrases.RESUME_ACTION_CONFIRMATION_MESSAGE,
                      confirmLabel: phrases.SCREENING_SECTION_RESUME_ACTION,
                      onConfirm: republishInterviewCb,
                    })
                  }
                >
                  {phrases.RESUME_SCREENING_INTERVIEW_SECTION_BUTTON}
                </Button>
              </TooltipWrapper>
            )}
            {isInterviewPaused || !currentSearch ? null : isActiveTab(
                currentTab
              ) ? (
              <QuestionnaireFormTriggerButton
                key={searchId}
                searchId={searchId}
                disabled={isQuestionnaireButtonDisabled}
                enableButtonTooltip={isLeftSideBarCollapsed}
                onQuestioningUpdated={onQuestioningUpdated}
              />
            ) : (
              <Button
                icon="send-emails-icon"
                className={commonStyles.leftSidebarButton}
                variant={Button.variants.POSITIVE_CONFIRM}
                onClick={goToEmailConfiguration}
                disabled={isSendEmailsButtonDisabled}
              >
                {phrases.SEND_EMAILS_TEXT}
              </Button>
            )}
            <TooltipWrapper
              delayShow={TOOLTIP_DELAY_TIME_LONG}
              tooltip={
                hasModifiedDraft
                  ? phrases.INTERVIEW_DRAFT_HAS_CHANGES_TOOLTIP_TEXT
                  : phrases.MODIFY_CRITERIA_INTERVIEW_SECTION_BUTTON
              }
              enabled={hasModifiedDraft || isLeftSideBarCollapsed}
              disabledChildren={isCriteriaAndFlagsDisabled}
              flexGrow={false}
            >
              <Button
                className={classNames(
                  commonStyles.leftSidebarButton,
                  commonStyles.defaultState,
                  styles.modifyCriteriaButton,
                  {
                    [commonStyles.dotIndicator]: hasModifiedDraft,
                  }
                )}
                debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                icon="criteria-and-flags-icon"
                disabled={isCriteriaAndFlagsDisabled}
                variant={Button.variants.POSITIVE_SECONDARY}
                onClick={modifyInterviewCb}
              >
                {phrases.MODIFY_CRITERIA_INTERVIEW_SECTION_BUTTON}
              </Button>
            </TooltipWrapper>
            {!isInterviewPaused && (
              <TooltipWrapper
                delayShow={TOOLTIP_DELAY_TIME_LONG}
                tooltip={phrases.PAUSE_SCREENING_INTERVIEW_SECTION_BUTTON}
                enabled={isLeftSideBarCollapsed}
                disabledChildren={isPauseScreeningDisabled}
                flexGrow={false}
              >
                <Button
                  className={classNames(
                    commonStyles.leftSidebarButton,
                    commonStyles.defaultState,
                    styles.pauseScreeningButton
                  )}
                  debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                  icon="pause-screening-icon"
                  disabled={isPauseScreeningDisabled}
                  variant={Button.variants.POSITIVE_SECONDARY}
                  onClick={() =>
                    emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
                      text: phrases.PAUSE_ACTION_CONFIRMATION_MESSAGE,
                      confirmLabel: phrases.KANBAN_ACTION_PAUSE,
                      onConfirm: pauseInterviewCb,
                    })
                  }
                >
                  {phrases.PAUSE_SCREENING_INTERVIEW_SECTION_BUTTON}
                </Button>
              </TooltipWrapper>
            )}
            <TooltipWrapper
              delayShow={TOOLTIP_DELAY_TIME_LONG}
              tooltip={phrases.CLOSE_POSITION_INTERVIEW_SECTION_BUTTON}
              enabled={isLeftSideBarCollapsed}
              disabledChildren={isClosePositionDisabled}
              flexGrow={false}
            >
              <Button
                className={classNames(
                  commonStyles.leftSidebarButton,
                  commonStyles.defaultState,
                  commonStyles.negativeState,
                  styles.closePositionButton
                )}
                debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                icon="close-position-icon"
                disabled={isClosePositionDisabled}
                variant={Button.variants.NEGATIVE_SECONDARY}
                onClick={() => {
                  emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
                    text: phrases.CLOSE_ACTION_CONFIRMATION_MESSAGE,
                    confirmLabel: phrases.KANBAN_ACTION_CLOSE,
                    onConfirm: closeSearchCb,
                  });
                }}
              >
                {phrases.CLOSE_POSITION_INTERVIEW_SECTION_BUTTON}
              </Button>
            </TooltipWrapper>
            <FeatureView.LeftSidebar.Space />
            <UtilitiesControls
              hasReportsButton
              hasUploadsButton={isStandaloneAtsUser}
              isCollapsed={isLeftSideBarCollapsed}
              gaCategory={GACategory.ScreeningPage}
            />
          </>
        </FeatureView.LeftSidebar>

        <FeatureView.Content className={styles.interviewSectionContent}>
          <Route
            path={urls.makeInterviewUrl(':dataSourceId', ':jobDescriptionId')}
          >
            {() =>
              currentSearch ? (
                <CandidateQuery
                  candidateNameQuery={candidateNameQuery}
                  onCandidateNameSearchChange={onCandidateNameSearchChange}
                  clearSearchQuery={clearSearchQuery}
                >
                  <InterviewView
                    history={history}
                    onOpenedSectionsChange={onOpenedSectionsChanged}
                    changeCandidateStatus={changeCandidateStatusCb}
                    isCandidatesListLoading={
                      isCandidatesListLoading[currentTab]
                    }
                    currentTab={currentTab}
                    interviewLineupTabs={interviewLineupTabs}
                    onTabChange={onTabChange}
                    loadAllCandidates={loadAllSorted}
                  />
                </CandidateQuery>
              ) : (
                <>
                  <JobTitle
                    disabled
                    className={styles.resultsHeaderWrapper}
                    titleClassName={styles.resultsHeader}
                    title={currentInterviewTitle}
                  />
                  <LoadingTextPlaceholder />
                </>
              )
            }
          </Route>
        </FeatureView.Content>
      </div>
    </>
  );
});
InterviewSection.displayName = 'InterviewSection';
