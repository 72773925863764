import { OverlayPanel } from '@air/components';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import { Kanban } from 'features';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit, subscribe } from 'hooks/usePubSub';
import {
  useKanbanMethods,
  useSelectInterviewKanbanMethods,
} from 'providers/KanbanProvider';
import { toast } from '@air/third-party/toast';

import styles from './SelectInterviewPanel.css';
import { useUndoableCallback } from 'hooks/useUndoableCallback';
import { CurrentSearchT } from 'domain/Kanban/Kanban';

const SELECT_INTERVIEW_TOAST_ID = 'select-interview-toast';

type Props = {
  targetInterview: CurrentSearchT;
  closePanel: () => void;
};

export const SelectInterviewPanel: React.FC<Props> = (props) => {
  const { targetInterview, closePanel } = props;
  const previousInterviewCriteria = useRef({
    ...targetInterview,
    criteria: targetInterview.criteria,
    redFlags: targetInterview.redFlags,
  });

  const { duplicateCriteria, updateDraft } = useKanbanMethods();
  const { updateAllSearches } = useSelectInterviewKanbanMethods();

  const undoableImportCriteria = useUndoableCallback(
    useMemo(
      () => ({
        onCallbackScheduled: R.noop,
        callback: R.noop,
        undoCallback: async () => {
          await updateDraft(
            targetInterview.searchId,
            previousInterviewCriteria.current
          );
          emit(APP_EVENTS.REFETCH_DRAFT);
        },
        callbackScheduledMsg: phrases.FINISH_IMPORTING_CRITERIA_TOAST_TEXT,
      }),
      [previousInterviewCriteria, updateDraft, targetInterview.searchId]
    )
  );

  const onSelectCopyCriteriaSource = useCallback(
    async (sourceInterviewSearchId) => {
      closePanel();
      await duplicateCriteria(sourceInterviewSearchId, targetInterview);
      undoableImportCriteria();
      emit(APP_EVENTS.REFETCH_DRAFT);
    },
    [closePanel, undoableImportCriteria, duplicateCriteria, targetInterview]
  );

  // show persistent toast with explanation for the user when component's mounted
  useEffect(() => {
    toast.dark(phrases.IMPORTING_CRITERIA_SELECT_INTERVIEW_TEXT, {
      toastId: SELECT_INTERVIEW_TOAST_ID,
      closeButton: false,
      className: styles.userExplanationToast,
    });
    updateAllSearches({
      withCriteria: true,
      excludeId: [targetInterview.parentId],
    });
    return () => {
      toast.dismiss(SELECT_INTERVIEW_TOAST_ID);
    };
  }, [targetInterview.parentId, updateAllSearches]);

  useEffect(() => {
    return subscribe(
      APP_EVENTS.SELECT_COPY_CRITERIA_SOURCE,
      onSelectCopyCriteriaSource
    );
  }, [closePanel, targetInterview, onSelectCopyCriteriaSource]);

  return (
    <OverlayPanel
      showCloseButton={false}
      className={styles.selectInterviewPanel}
      position={OverlayPanel.positions.right}
      visible
      onClose={closePanel}
    >
      <Kanban
        isSelectMode
        selectModeTargetId={targetInterview.parentId}
        isLandingMounted
      />
    </OverlayPanel>
  );
};
