import { AlertDetails } from '@air/domain/RedFlags/RedFlagDetails/AlertDetails';
import {
  AlertStatusEnum,
  BuzzwordsAlertDetails,
  CompanyPeriodAlertDetails,
  DescriptionTooLongAlertDetails,
  DiplomaMillAlertDetails,
  DiscrepancyDataInfo,
  EducationDataDiscrepancyAlertDetails,
  EducationDiscrepancyDataInfo,
  EmploymentDataDiscrepancyAlertDetails,
  EmploymentRolePeriod,
  GapsInEmploymentHistoryAlertDetails,
  GraduationStatusEnum,
  MissedMonthsInEmploymentRecordAlertDetails,
  NoCareerProgressAlertDetails,
  NoCareerProgressReason,
  PeriodAlertDetails,
  PeriodInfo,
  RedFlagAlertType,
  RedFlagGroupEnum,
  RedFlagResolutionEnum,
  SkillInfo,
  TooLongAtTheSameCompanyAlertDetails,
  TooManyJobChangesAlertDetails,
  TooManySkillsAlertDetails,
  UnimpressiveSkillsAlertDetails,
  UnknownCompanyAlertDetails,
  UnknownUniversityAlertDetails,
  VagueWordsAlertDetails,
} from '@air/api';

import R from '@air/third-party/ramda';
import * as phrases from '@air/constants/phrases';
import {
  InterviewData,
  interviewDataMapper,
} from '@air/domain/InterviewNotes/InterviewData';
import { RedFlag } from '@air/domain/RedFlags/RedFlag';

export type SkillsRedFlagDataStackItem = { id: number | string; title: string };
export type BaseRedFlagDataStackItem = { title: string };
export type GapsRedFlagDataStackItem = PeriodAlertDetails;

enum EnrichmentDiscrepancyOrigin {
  PIPL = 'pipl',
  RESUME = 'resume',
}

export enum NormalizedDiscrepancyField {
  position = 'position',
  company = 'company',
  period = 'period',
}

export type LongTermRedFlagDataStackItem = {
  title: string;
  period: PeriodInfo;
};

export interface EducationDiscrepancyFlagData {
  origin: EnrichmentDiscrepancyOrigin;
  majorName: string;
  degreeName: string;
  institutionName: string;
  endYear: string;
  graduationStatus: GraduationStatusEnum | string;
}

export type NormalizedDiscrepancyInfo = {
  origin: EnrichmentDiscrepancyOrigin;
  company: string | null;
  position: string | null;
  periods: PeriodAlertDetails;
};

export type RedFlagDataStackItem =
  | BaseRedFlagDataStackItem
  | SkillsRedFlagDataStackItem
  | GapsRedFlagDataStackItem
  | LongTermRedFlagDataStackItem
  | EducationDiscrepancyFlagData
  | NormalizedDiscrepancyInfo;

export interface BaseRedFlagData {
  id: string;
  group: RedFlagGroupEnum.GENERAL;
  type: RedFlagAlertType;
  displayName: string;
  status: AlertStatusEnum;
  interview: InterviewData;
  resolution: RedFlagResolutionEnum;
}

export interface UnimpressiveSkillsRedFlagData extends BaseRedFlagData {
  list: SkillsRedFlagDataStackItem[];
  cases: number;
}

export interface BuzzwordsRedFlagData extends BaseRedFlagData {
  list: BaseRedFlagDataStackItem[];
  cases: number;
}

export interface DescriptionsTooLongRedFlagData extends BaseRedFlagData {
  cases: number;
}

export interface VagueWordsRedFlagData extends BaseRedFlagData {
  list: BaseRedFlagDataStackItem[];
  cases: number;
}

export interface DiplomaMillRedFlagData extends BaseRedFlagData {
  title: string;
}

export interface EmploymentHistoryGapRedFlagData extends BaseRedFlagData {
  list: GapsRedFlagDataStackItem[];
  cases: number;
}

export interface MissingMonthsRedFlagData extends BaseRedFlagData {
  title: string;
  periods: PeriodAlertDetails;
  list: BaseRedFlagDataStackItem[];
}

export interface LongTermEmploymentRedFlagData extends BaseRedFlagData {
  title: string;
  experience: { years?: number; months?: number; days?: number };
  list: LongTermRedFlagDataStackItem[];
}
export interface TooManySkillsRedFlagData extends BaseRedFlagData {
  cases: number;
}

export interface UnknownUniversityRedFlagData extends BaseRedFlagData {
  title: string;
}

export interface TooManyJobsRedFlagData extends BaseRedFlagData {
  list: LongTermRedFlagDataStackItem[];
  cases: number;
  years: number;
}

export interface NoCareerProgressRedFlagData extends BaseRedFlagData {
  reason: NoCareerProgressReason;
}

export interface UnknownCompanyRedFlagData extends BaseRedFlagData {
  list: LongTermRedFlagDataStackItem[];
  title: string;
  experience: {
    years?: number;
    months?: number;
    days?: number;
    approx?: boolean;
  };
}

export interface EducationDiscrepancyRedFlagData extends BaseRedFlagData {
  pipl: EducationDiscrepancyFlagData;
  resume: EducationDiscrepancyFlagData;
  discrepancyFields: string[];
}

export interface EmploymentDataDiscrepancyRedFlagData extends BaseRedFlagData {
  resume: NormalizedDiscrepancyInfo;
  pipl: NormalizedDiscrepancyInfo;
  discrepancyFields: string[];
  normalizedDiscrepancyFields: NormalizedDiscrepancyField[];
}

export type RedFlagData =
  | UnimpressiveSkillsRedFlagData
  | BuzzwordsRedFlagData
  | VagueWordsRedFlagData
  | DiplomaMillRedFlagData
  | EmploymentHistoryGapRedFlagData
  | MissingMonthsRedFlagData
  | LongTermEmploymentRedFlagData
  | TooManySkillsRedFlagData
  | TooManyJobsRedFlagData
  | UnknownUniversityRedFlagData
  | NoCareerProgressRedFlagData
  | UnknownCompanyRedFlagData
  | EducationDiscrepancyRedFlagData
  | EmploymentDataDiscrepancyRedFlagData;

type AlertDetailed<T extends AlertDetails> = RedFlag & { details: T };
type UnimpressiveSkillsAlert = AlertDetailed<UnimpressiveSkillsAlertDetails>;
type BuzzwordsAlert = AlertDetailed<BuzzwordsAlertDetails>;
type VagueWordsAlert = AlertDetailed<VagueWordsAlertDetails>;
type DiplomaMillAlert = AlertDetailed<DiplomaMillAlertDetails>;
type EmploymentHistoryGapAlert =
  AlertDetailed<GapsInEmploymentHistoryAlertDetails>;
type MissingMonthsAlert =
  AlertDetailed<MissedMonthsInEmploymentRecordAlertDetails>;
type LongTermEmploymentAlert =
  AlertDetailed<TooLongAtTheSameCompanyAlertDetails>;
type TooManySkillsAlert = AlertDetailed<TooManySkillsAlertDetails>;
type UnknownUniversityAlert = AlertDetailed<UnknownUniversityAlertDetails>;
type TooManyJobsAlert = AlertDetailed<TooManyJobChangesAlertDetails>;
type NoCareerProgressAlert = AlertDetailed<NoCareerProgressAlertDetails>;
type UnknownCompanyAlert = AlertDetailed<UnknownCompanyAlertDetails>;
type EducationDiscrepancyAlert =
  AlertDetailed<EducationDataDiscrepancyAlertDetails>;
type EmploymentDataDiscrepancyAlert =
  AlertDetailed<EmploymentDataDiscrepancyAlertDetails>;
type DescriptionsTooLongAlert = AlertDetailed<DescriptionTooLongAlertDetails>;

export interface RedFlagDataMapper<
  S extends AlertDetailed<AlertDetails>,
  T extends BaseRedFlagData
> {
  (alertData: S): T;
}

export enum RegFlagWarningType {
  REDFLAG = 'RED_FLAG',
  EMPTYREDFLAG = 'EMPTY_RED_FLAG',
}

export type EducationDiscrepancyKeysType = {
  major: string;
  degree: string;
  institution: string;
  to: string;
};

export const convertRedFlagNameToString = (
  redFlag: RedFlag | null
): string | null => {
  if (!redFlag) return null;
  switch (redFlag.type) {
    case RedFlagAlertType.BUZZWORDS:
      return phrases.RED_FLAG_BUZZWORDS;
    case RedFlagAlertType.GAPSINEMPLOYMENTHISTORY:
      return phrases.RED_FLAG_GAPS_IN_HISTORY;
    case RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD:
      return phrases.RED_FLAG_MISSED_MONTHS;
    case RedFlagAlertType.UNIMPRESSIVESKILLS:
      return phrases.RED_FLAG_UNIMPRESSIVE_SKILLS;
    case RedFlagAlertType.VAGUEWORDS:
      return phrases.RED_FLAG_VAGUE_WORDS;
    case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
      return phrases.RED_FLAG_TOO_LONG_IN_COMPANY;
    case RedFlagAlertType.DIPLOMAMILL:
      return phrases.RED_FLAG_DIPLOMA_MILL;
    case RedFlagAlertType.UNKNOWNUNIVERSITY:
      return phrases.RED_FLAG_UNKNOWN_UNIVERSITY;
    case RedFlagAlertType.TOOMANYSKILLS:
      return phrases.RED_FLAG_TOO_MANY_SKILLS;
    case RedFlagAlertType.TOOMANYJOBCHANGES:
      return phrases.RED_FLAG_TOO_MANY_JOBS;
    case RedFlagAlertType.NOCAREERPROGRESS:
      return phrases.RED_FLAG_NO_CAREER_PROGRESS;
    case RedFlagAlertType.UNKNOWNCOMPANY:
      return phrases.RED_FLAG_UNKNOWN_COMPANY;
    case RedFlagAlertType.EDUCATIONDATADISCREPANCY:
      return phrases.RED_FLAG_EDUCATION_DISCREPANCY;
    case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
      return phrases.RED_FLAG_EMPLOYMENT_DISCREPANCY;
    case RedFlagAlertType.DESCRIPTIONSTOOLONG:
      return phrases.RED_FLAG_DESCRIPTIONS_TOO_LONG;
    default:
      return null;
  }
};

export const normalizeDetailsPeriod = (
  period: PeriodAlertDetails
): PeriodAlertDetails => {
  return {
    period: period?.period ?? {
      years: null,
      months: null,
      days: null,
      approx: false,
    },
    from: period?.from ?? { year: null, month: null, day: null, approx: false },
    to: period?.to ?? { year: null, month: null, day: null, approx: false },
  };
};

export const normalizeDiscrepancyFields = (
  discrepancyFields: string[]
): NormalizedDiscrepancyField[] => {
  const normalizedFields = discrepancyFields.reduce((set, item) => {
    let normalizedField;
    if (['from', 'to'].includes(item)) {
      normalizedField = NormalizedDiscrepancyField.period;
    } else if (['company', 'cleanCompany'].includes(item)) {
      normalizedField = NormalizedDiscrepancyField.company;
    } else if (['normalizedTitle', 'originalTitle'].includes(item)) {
      normalizedField = NormalizedDiscrepancyField.position;
    }

    return normalizedField ? set.add(normalizedField) : set;
  }, new Set<NormalizedDiscrepancyField>());

  return [...normalizedFields];
};

export const normalizeDiscrepancyInfo = (
  discrepancyInfo: DiscrepancyDataInfo,
  origin: EnrichmentDiscrepancyOrigin
): NormalizedDiscrepancyInfo => ({
  origin,
  company:
    discrepancyInfo?.company?.fullName || discrepancyInfo?.cleanCompany || null,
  position:
    discrepancyInfo?.originalTitle ||
    discrepancyInfo?.normalizedTitle?.fullName ||
    null,
  periods: {
    from: discrepancyInfo.from,
    to: discrepancyInfo.to,
  },
});

export const baseRedFlagDataMapper = <T extends AlertDetails>(
  data: AlertDetailed<T>
): BaseRedFlagData => {
  return {
    id: data.refId,
    group: RedFlagGroupEnum.GENERAL,
    type: data.type,
    displayName: convertRedFlagNameToString(data),
    status: data.status,
    interview: interviewDataMapper(data.refId, data.interview),
    resolution: data.resolution,
  };
};

export const unimpressiveSkillsRedFlagDataMapper: RedFlagDataMapper<
  UnimpressiveSkillsAlert,
  UnimpressiveSkillsRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  list: R.pipe(
    R.map((skill: SkillInfo) => ({
      title: skill.fullName,
      id: skill.id,
    })),
    R.sortBy(R.prop('title'))
  )(redFlagData.details.skills),
  cases: redFlagData.details.skills.length,
});

export const buzzwordsRedFlagDataMapper: RedFlagDataMapper<
  BuzzwordsAlert,
  BuzzwordsRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  list: R.pipe(
    R.map((buzzword: string) => ({ title: buzzword })),
    R.sortBy(R.prop('title'))
  )(redFlagData.details.buzzwords || []),
  cases: redFlagData.details.buzzwords?.length ?? 0,
});

export const descriptionsTooLongDataMapper: RedFlagDataMapper<
  DescriptionsTooLongAlert,
  DescriptionsTooLongRedFlagData
> = (redFlagData) => {
  return {
    ...baseRedFlagDataMapper(redFlagData),
    cases: redFlagData.details.positions.length,
  };
};

export const vagueWordsRedFlagDataMapper: RedFlagDataMapper<
  VagueWordsAlert,
  VagueWordsRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  list: R.pipe(
    R.map((word: string) => ({ title: word })),
    R.sortBy(R.prop('title'))
  )(redFlagData.details.words || []),
  cases: redFlagData.details.words?.length ?? 0,
});

export const diplomaMillDataMapper: RedFlagDataMapper<
  DiplomaMillAlert,
  DiplomaMillRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  title: redFlagData.details.institutions?.[0]?.fullName ?? '',
});

export const employmentHistoryGapDataMapper: RedFlagDataMapper<
  EmploymentHistoryGapAlert,
  EmploymentHistoryGapRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  list: redFlagData.details?.gaps || [],
  cases: redFlagData.details?.gaps?.length ?? 0,
});

export const missingMonthsDataMapper: RedFlagDataMapper<
  MissingMonthsAlert,
  MissingMonthsRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  title:
    redFlagData.details?.period?.companyName ||
    phrases.COMPANY_NAME_PLACEHOLDER,
  periods: normalizeDetailsPeriod(redFlagData.details?.period),
  list: [
    {
      title:
        redFlagData.details?.period?.jobTitle ||
        phrases.JOB_TITLE_NAME_PLACEHOLDER,
    },
  ],
});

export const longTermEmploymentDataMapper: RedFlagDataMapper<
  LongTermEmploymentAlert,
  LongTermEmploymentRedFlagData
> = (redFlagData) => {
  const employmentInfo = redFlagData.details?.period ?? null;

  return {
    ...baseRedFlagDataMapper(redFlagData),
    title: employmentInfo?.companyName || phrases.COMPANY_NAME_PLACEHOLDER,
    experience: employmentInfo?.period ?? { years: null, months: null },
    list: (employmentInfo?.employmentRoles ?? []).map(
      ({ roleTitle, details }: EmploymentRolePeriod) => ({
        title: roleTitle || phrases.JOB_TITLE_NAME_PLACEHOLDER,
        period: details.period,
      })
    ),
  };
};

export const tooManySkillsDataMapper: RedFlagDataMapper<
  TooManySkillsAlert,
  TooManySkillsRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  cases: redFlagData.details?.candidateSkillsCount ?? 0,
});

export const unknownUniversityDataMapper: RedFlagDataMapper<
  UnknownUniversityAlert,
  UnknownUniversityRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  title: redFlagData.details.institution?.fullName ?? '',
});

export const tooManyJobsDataMapper: RedFlagDataMapper<
  TooManyJobsAlert,
  TooManyJobsRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  list: (redFlagData?.details?.employmentPeriods ?? []).map(
    ({ companyName, period }: CompanyPeriodAlertDetails) => ({
      title: companyName || null,
      period,
    })
  ),
  cases: redFlagData.details.numberOfJobChanges,
  years: redFlagData.details.lookupPeriodInYears,
});

export const noCareerProgressDataMapper: RedFlagDataMapper<
  NoCareerProgressAlert,
  NoCareerProgressRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  reason: redFlagData.details?.reason,
});

export const unknownCompanyDataMapper: RedFlagDataMapper<
  UnknownCompanyAlert,
  UnknownCompanyRedFlagData
> = (redFlagData) => ({
  ...baseRedFlagDataMapper(redFlagData),
  list: (redFlagData.details?.rolePeriods ?? []).map(
    ({ roleTitle, details }: EmploymentRolePeriod) => ({
      title: roleTitle || phrases.JOB_TITLE_NAME_PLACEHOLDER,
      period: details.period,
    })
  ),
  title: redFlagData.details?.cleanCompanyName,
  experience: redFlagData.details?.totalEmploymentPeriod ?? {
    years: null,
    months: null,
    approx: false,
  },
});

function getGraduationYear(year?: number | string) {
  return year ? String(year) : null;
}

const educationDiscrepancyKeys: EducationDiscrepancyKeysType = {
  major: 'majorName',
  degree: 'degreeName',
  institution: 'institutionName',
  to: 'endYear',
};

const prepareEducationDiscrepancyData = (
  data: EducationDiscrepancyDataInfo,
  origin: EnrichmentDiscrepancyOrigin
): EducationDiscrepancyFlagData => {
  if (R.isNullOrEmpty(data)) {
    return null;
  }
  return {
    graduationStatus: data.graduationStatus || phrases.GRADUATED_OR_NOT,
    degreeName: data.degree?.fullName,
    majorName: data.major?.fullName || data.major?.originalName,
    institutionName:
      data.institution?.fullName || data.institution?.originalName,
    endYear:
      data.to?.year === 0
        ? String(new Date().getFullYear())
        : getGraduationYear(data.to?.year),
    origin,
  };
};

const mapEducationDiscrepancyFields = (discrepancyFields: string[]) =>
  discrepancyFields.map(
    (field) =>
      educationDiscrepancyKeys[field as keyof EducationDiscrepancyKeysType]
  );

export const educationDiscrepancyDataMapper: RedFlagDataMapper<
  EducationDiscrepancyAlert,
  EducationDiscrepancyRedFlagData
> = (redFlagData) => {
  return {
    ...baseRedFlagDataMapper(redFlagData),
    discrepancyFields: mapEducationDiscrepancyFields(
      redFlagData.details?.discrepancyFields
    ),
    pipl: prepareEducationDiscrepancyData(
      redFlagData.details?.pipl,
      EnrichmentDiscrepancyOrigin.PIPL
    ),
    resume: prepareEducationDiscrepancyData(
      redFlagData.details?.resume,
      EnrichmentDiscrepancyOrigin.RESUME
    ),
  };
};

export const employmentDiscrepancyDataMapper: RedFlagDataMapper<
  EmploymentDataDiscrepancyAlert,
  EmploymentDataDiscrepancyRedFlagData
> = (redFlagData) => {
  const { resume, pipl } = redFlagData.details;
  return {
    ...baseRedFlagDataMapper(redFlagData),
    resume: normalizeDiscrepancyInfo(
      resume,
      EnrichmentDiscrepancyOrigin.RESUME
    ),
    pipl: normalizeDiscrepancyInfo(pipl, EnrichmentDiscrepancyOrigin.PIPL),
    discrepancyFields: redFlagData.details?.discrepancyFields,
    normalizedDiscrepancyFields: normalizeDiscrepancyFields(
      redFlagData.details?.discrepancyFields
    ),
  };
};

export const mapAlertToRedFlagData = (redFlagData: RedFlag): RedFlagData => {
  switch (redFlagData.type) {
    case RedFlagAlertType.UNIMPRESSIVESKILLS:
      return unimpressiveSkillsRedFlagDataMapper(
        redFlagData as UnimpressiveSkillsAlert
      );
    case RedFlagAlertType.BUZZWORDS:
      return buzzwordsRedFlagDataMapper(redFlagData as BuzzwordsAlert);
    case RedFlagAlertType.VAGUEWORDS:
      return vagueWordsRedFlagDataMapper(redFlagData as VagueWordsAlert);
    case RedFlagAlertType.DIPLOMAMILL:
      return diplomaMillDataMapper(redFlagData as DiplomaMillAlert);
    case RedFlagAlertType.GAPSINEMPLOYMENTHISTORY:
      return employmentHistoryGapDataMapper(
        redFlagData as EmploymentHistoryGapAlert
      );
    case RedFlagAlertType.MISSEDSTARTENDMONTHINEMPLOYMENTRECORD:
      return missingMonthsDataMapper(redFlagData as MissingMonthsAlert);
    case RedFlagAlertType.WORKEDTOOLONGINTHESAMECOMPANY:
      return longTermEmploymentDataMapper(
        redFlagData as LongTermEmploymentAlert
      );
    case RedFlagAlertType.TOOMANYSKILLS:
      return tooManySkillsDataMapper(redFlagData as TooManySkillsAlert);
    case RedFlagAlertType.TOOMANYJOBCHANGES:
      return tooManyJobsDataMapper(redFlagData as TooManyJobsAlert);
    case RedFlagAlertType.UNKNOWNUNIVERSITY:
      return unknownUniversityDataMapper(redFlagData as UnknownUniversityAlert);
    case RedFlagAlertType.NOCAREERPROGRESS:
      return noCareerProgressDataMapper(redFlagData as NoCareerProgressAlert);
    case RedFlagAlertType.UNKNOWNCOMPANY:
      return unknownCompanyDataMapper(redFlagData as UnknownCompanyAlert);
    case RedFlagAlertType.EDUCATIONDATADISCREPANCY:
      return educationDiscrepancyDataMapper(
        redFlagData as EducationDiscrepancyAlert
      );
    case RedFlagAlertType.EMPLOYMENTDATADISCREPANCY:
      return employmentDiscrepancyDataMapper(
        redFlagData as EmploymentDataDiscrepancyAlert
      );
    case RedFlagAlertType.DESCRIPTIONSTOOLONG:
      return descriptionsTooLongDataMapper(
        redFlagData as DescriptionsTooLongAlert
      );
    default:
      return null;
  }
};

export const isGeneralRedFlag = (
  alert: RedFlag | RedFlagData | BaseRedFlagData
) => alert.group === RedFlagGroupEnum.GENERAL;

export const isAskedRedFlag = (
  alert: RedFlag | RedFlagData | BaseRedFlagData
) => alert.status === AlertStatusEnum.OPENED;

export const isDismissedFlag = (
  alert: RedFlag | RedFlagData | BaseRedFlagData
) => alert.status === AlertStatusEnum.DISMISSED;

export const isDisqualificationFlag = (
  alert: RedFlag | RedFlagData | BaseRedFlagData
) => alert.status === AlertStatusEnum.DISQUALIFICATION;

export const isWarningResolution = (alert: BaseRedFlagData) =>
  alert.resolution === RedFlagResolutionEnum.WARNING;

export const isDisqualificationResolution = (alert: BaseRedFlagData) =>
  alert.resolution === RedFlagResolutionEnum.DISQUALIFICATION;

export const isExplanationResolution = (alert: BaseRedFlagData) =>
  alert.resolution === RedFlagResolutionEnum.EXPLANATION;

export const isPiplWarning = (
  alert: BaseRedFlagData
): alert is
  | EducationDiscrepancyRedFlagData
  | EmploymentDataDiscrepancyRedFlagData => {
  return [
    RedFlagAlertType.EMPLOYMENTDATADISCREPANCY,
    RedFlagAlertType.EDUCATIONDATADISCREPANCY,
  ].includes(alert.type);
};
