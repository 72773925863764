import {
  SearchCriteriaData,
  isManagerial,
  getImportanceValue,
  isDegree,
  isProfessional,
  CompanyCriteriaData,
  SearchCriteriaListValue,
  CompanyCriteriaDataListItem,
} from 'domain/SearchCriteria';
import React, { useState, useEffect } from 'react';
import { useStateMachine } from '@air/hooks';
import {
  ProfileCriteriaCardConfig,
  ProfileCriteriaCardState,
  ProfileCriteriaCardActions,
} from 'components/SearchCriteriaCards/hooks/ProfileCriteriaCardStateConfig';
import { useOutsideClick } from '@air/utils/hooks';
import { Card } from '@air/components';
import { LineupExpandedCard } from 'components/CardWrappers';
import classNames from 'classnames';
import searchCardsStyles from 'components/SearchCards/SearchCards.css';
import {
  getCardCriterionLabel,
  getValuesForTopStack,
  getValuesForBottomStack,
  getCardStackSize,
  getStackToggleItemsClasses,
  getMainCard,
  getCardTitleClassName,
} from 'domain/SearchCriteria/cardHelpers';
import { getCardReadOnlyFields } from 'components/LineupTable/HeaderCellRenderers';
import { getManagerialFieldsForCollapsedCriteriaCard } from 'components/Cards/ManagerialCard/ManagerialCard';
import { getProfessionalFieldsForCollapsedCard } from 'components/Cards/ProfessionalCard/ProfessionalCard';
import {
  getCommonFieldsForCriteriaCard,
  getFieldsForCollapsedCriteriaCardStackItem,
} from 'components/Cards/cardsCommonCode';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import { FormSelectEntity } from '@air/components/Select/typings';
import styles from './CandidateProfileSearchCriteriaCard.css';
import educationStyles from 'components/Cards/educationCommonStyles.css';
import { getDegreeEquivalentExperienceStackItem } from 'components/Cards/DegreeCard/DegreeCard';
import { CompanyExtendedType } from '@air/api';
import R from '@air/third-party/ramda';
import { isLocation } from 'domain/SearchCriteria/LocationCriteriaData';
import { getLocationFieldsForCollapsedCriteriaCard } from 'components/Cards/LocationCard/LocationCard';
import { isExcludedCriteriaCard } from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';
import * as phrases from 'constants/phrases';

export const getUnwantedFieldsForCollapsedCriteriaCard = (
  cardData: SearchCriteriaData
) => {
  const fields = getCommonFieldsForCriteriaCard(cardData);
  return [
    <div key="unwanted-label" className={cardCommonStyles.unwantedLabel}>
      #{phrases.UNWANTED_LABEL}
    </div>,
    fields.title,
    fields.footer,
  ];
};

const getExpandedCardStackItemClassName =
  (card: SearchCriteriaData) =>
  (stackItem: { type?: CompanyExtendedType }): string =>
    getCardTitleClassName(card.cardType, stackItem);

export const getFieldsForCollapsedCriteriaCard = (cardData: any) => {
  if (isExcludedCriteriaCard(cardData)) {
    return getUnwantedFieldsForCollapsedCriteriaCard(cardData);
  }

  if (isManagerial(cardData)) {
    return getManagerialFieldsForCollapsedCriteriaCard(cardData);
  }

  if (isProfessional(cardData)) {
    return getProfessionalFieldsForCollapsedCard(cardData);
  }

  if (isLocation(cardData)) {
    return getLocationFieldsForCollapsedCriteriaCard(cardData);
  }

  return Object.values(getCommonFieldsForCriteriaCard(cardData));
};
type ProfileSearchCriteriaCardProps = {
  cardData: SearchCriteriaData;
  isOpened: boolean;
  onOpenCardStack: (id: string | number) => void;
  onCloseCardStack: (id: string | number) => void;
};
export const CollapsedSearchCriteriaCard: React.FC<
  ProfileSearchCriteriaCardProps & { onCardClick: () => void }
> = ({
  cardData,
  isOpened = false,
  onOpenCardStack,
  onCloseCardStack,
  onCardClick,
}) => {
  const criteriaLabel = getCardCriterionLabel(cardData);
  const isUnwanted = isExcludedCriteriaCard(cardData);

  const [isStackOpened, toggleStack] = useState<boolean>(isOpened);

  useEffect(() => {
    isStackOpened
      ? onOpenCardStack(cardData.id)
      : onCloseCardStack(cardData.id);
  }, [onOpenCardStack, onCloseCardStack, cardData.id, isStackOpened]);

  const topStack = getValuesForTopStack(cardData);
  const bottomStack = getValuesForBottomStack(cardData);
  const stackSize = getCardStackSize(cardData);
  const stackClasses = getStackToggleItemsClasses(cardData);
  const mainCard = getMainCard<
    CompanyCriteriaData,
    SearchCriteriaListValue<CompanyCriteriaDataListItem>
  >(cardData);

  return (
    <Card.SearchCriteriaCardContainer
      className={classNames({
        [cardCommonStyles.unwantedCard]: isUnwanted,
      })}
      importance={getImportanceValue(cardData)}
    >
      <Card.SearchCriteriaCardViewBackground>
        <Card.SearchCriteriaCardLabel
          onClick={() => toggleStack((value) => !value)}
          text={criteriaLabel}
          showExplanationTooltip={stackSize > 0}
        />
        <Card.SearchCriteriaStack
          onClick={() => toggleStack(false)}
          isClosed={!isStackOpened}
        >
          {R.reverse(topStack).map((item: FormSelectEntity, index: number) => {
            return getFieldsForCollapsedCriteriaCardStackItem({
              cardData,
              item,
              index,
              className: getCardTitleClassName(cardData.cardType, item),
              cardType: cardData.cardType,
              stackPosition: Card.SearchCriteriaStack.position.top,
            });
          })}
        </Card.SearchCriteriaStack>
        <Card.SearchCriteriaCardContent
          onClick={onCardClick}
          className={classNames(
            styles.lineupCandidateCard,
            getCardTitleClassName(cardData.cardType, mainCard)
          )}
        >
          {getFieldsForCollapsedCriteriaCard(cardData)}
        </Card.SearchCriteriaCardContent>
        <Card.SearchCriteriaStack
          onClick={() => toggleStack(false)}
          isClosed={!isStackOpened}
          position={Card.SearchCriteriaStack.position.bottom}
          className={educationStyles.educationBottomStack}
        >
          {[
            ...(isDegree(cardData) && cardData.equivalentExperience
              ? [getDegreeEquivalentExperienceStackItem(cardData)]
              : []),
            ...bottomStack.map((item: FormSelectEntity, index: number) => {
              return getFieldsForCollapsedCriteriaCardStackItem({
                cardData,
                item,
                index,
                className: getCardTitleClassName(cardData.cardType, item),
                cardType: cardData.cardType,
                stackPosition: Card.SearchCriteriaStack.position.bottom,
              });
            }),
          ]}
        </Card.SearchCriteriaStack>
        {stackSize > 0 && (
          <Card.SearchCriteriaStackToggle
            isHidden={isStackOpened}
            onClick={() => toggleStack(true)}
            stackSize={stackSize}
            stackClasses={stackClasses}
          />
        )}
      </Card.SearchCriteriaCardViewBackground>
    </Card.SearchCriteriaCardContainer>
  );
};
export const CandidateProfileSearchCriteriaCard: React.FC<
  ProfileSearchCriteriaCardProps
> = ({ cardData, isOpened = false, onOpenCardStack, onCloseCardStack }) => {
  const [cardState, dispatch] = useStateMachine(
    ProfileCriteriaCardConfig,
    ProfileCriteriaCardState.view
  );

  const [outsideClickRef] = useOutsideClick(
    () => dispatch(ProfileCriteriaCardActions.showView),
    {
      useCapture: true,
    }
  );

  const showCollapsedView = () => dispatch(ProfileCriteriaCardActions.showView);
  const expandDetailedView = () =>
    dispatch(ProfileCriteriaCardActions.showExpanded);
  const isUnwanted = isExcludedCriteriaCard(cardData);

  return (
    <Card.ResizeableCardWrapper resizeable>
      {cardState.matches(ProfileCriteriaCardState.view) ? (
        <CollapsedSearchCriteriaCard
          cardData={cardData}
          isOpened={isOpened}
          onOpenCardStack={onOpenCardStack}
          onCloseCardStack={onCloseCardStack}
          onCardClick={expandDetailedView}
        />
      ) : (
        <LineupExpandedCard
          ref={outsideClickRef}
          cardData={cardData}
          getStackItemClassName={getExpandedCardStackItemClassName(cardData)}
          className={classNames({
            [searchCardsStyles.headerExpandedExcludedCriteriaCard]: isUnwanted,
          })}
          topLabel={getCardCriterionLabel(cardData)}
          onActionButtonClicked={showCollapsedView}
        >
          {getCardReadOnlyFields(cardData as SearchCriteriaData)}
        </LineupExpandedCard>
      )}
    </Card.ResizeableCardWrapper>
  );
};

CandidateProfileSearchCriteriaCard.displayName =
  'CandidateProfileSearchCriteriaCard';
