import React, { useCallback, useMemo } from 'react';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';

import styles from './FlagsSettings.css';
import { RedFlagAlertType, RedFlagItem, RedFlagResolutionEnum } from '@air/api';
import { SvgIcon, TooltipWrapper, UIText } from '@air/components';
import {
  isDisqualifyAvailable,
  isExplanationAvailable,
  isWarningAvailable,
  RedFlagSettingsData,
} from 'domain/SearchCriteria/RedFlagsData';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import classNames from 'classnames';
import { FlagSettingsState } from 'components/SearchCriteriaCards/hooks/FlagSettingsStateConfig';
import { DraggableFlagSettingsCard } from 'components/CardWrappers/FlagSettingsCard/DraggableFlagSettingsCard';
import { DraggedRedFlagsType } from '../dndTypes';
import { ReorderDropTargetCell } from 'features/ReorderDropTargetCell/ReorderDropTargetCell';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

const DISABLED_SECTION = 'DISABLED';

export enum FlagsViewMode {
  Resolution = 'resolution',
  Category = 'category',
}

type FlagSection = {
  name: string;
  description?: string;
  canDrop?: (flagType: RedFlagAlertType) => boolean;
  order: number;
};

const resolutionSections: { [sectionName: string]: FlagSection } = {
  [RedFlagResolutionEnum.DISQUALIFICATION]: {
    name: phrases.FLAG_SETTINGS_DISQUALIFY,
    description: phrases.FLAG_SETTINGS_DISQUALIFY_SECTION_DESC,
    canDrop: isDisqualifyAvailable,
    order: 1,
  },
  [RedFlagResolutionEnum.EXPLANATION]: {
    name: phrases.FLAG_SETTINGS_ASK_FIRST,
    description: phrases.FLAG_SETTINGS_ASK_FIRST_SECTION_DESC,
    canDrop: isExplanationAvailable,
    order: 2,
  },
  [RedFlagResolutionEnum.WARNING]: {
    name: phrases.FLAG_SETTINGS_NOTIFY,
    description: phrases.FLAG_SETTINGS_NOTIFY_SECTION_DESC,
    canDrop: isWarningAvailable,
    order: 3,
  },
  [DISABLED_SECTION]: {
    name: phrases.FLAG_SETTINGS_IGNORE,
    description: phrases.FLAG_SETTINGS_IGNORE_SECTION_DESC,
    canDrop: R.always(true),
    order: 4,
  },
};

const categorySections: { [sectionName: string]: FlagSection } = {
  [phrases.CATEGORY_EDUCATION_LABEL]: {
    name: phrases.CATEGORY_EDUCATION_LABEL,
    order: 1,
  },
  [phrases.CATEGORY_SKILLS_LABEL]: {
    name: phrases.CATEGORY_SKILLS_LABEL,
    order: 2,
  },
  [phrases.CATEGORY_EMPLOYMENT_LABEL]: {
    name: phrases.CATEGORY_EMPLOYMENT_LABEL,
    order: 3,
  },
  [phrases.CATEGORY_WORDINGS_LABEL]: {
    name: phrases.CATEGORY_WORDINGS_LABEL,
    order: 4,
  },
};

const getSectionsByResolution = (flags: Array<RedFlagItem>) => {
  const [enabledFlags = [], disabledFlags = []] = R.partition(
    R.path(['enabled']),
    flags
  );

  const flagsGroupedByResolution: {
    [sectionName: string]: RedFlagSettingsData[];
  } = R.groupBy(R.path(['resolution']), enabledFlags);

  const flagsBySection: {
    [sectionName: string]: RedFlagSettingsData[];
  } = {
    ...flagsGroupedByResolution,
    [DISABLED_SECTION]: disabledFlags,
  };

  return flagsBySection;
};

const getSectionsByCategory = (flags: Array<RedFlagItem>) => {
  const flagsGroupedByCategory: {
    [sectionName: string]: RedFlagSettingsData[];
  } = R.groupBy(R.path(['category']), flags);
  return flagsGroupedByCategory;
};

export const FlagsSettings: React.FC<{
  flags: Array<RedFlagItem>;
  viewMode: FlagsViewMode;
  onChange: (
    flagType: RedFlagAlertType,
    newResolutionValue: RedFlagResolutionEnum,
    newEnabledValue: boolean
  ) => void;
}> = ({ flags, viewMode, onChange }) => {
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  const { sections, flagsBySection, areCardsDraggable } = useMemo(() => {
    switch (viewMode) {
      case FlagsViewMode.Resolution:
        return {
          sections: resolutionSections,
          areCardsDraggable: true,
          flagsBySection: getSectionsByResolution(flags),
        };
      case FlagsViewMode.Category:
        return {
          sections: categorySections,
          areCardsDraggable: false,
          flagsBySection: getSectionsByCategory(flags),
        };
    }
  }, [flags, viewMode]);

  const sortedSectionsNames = useMemo(
    () =>
      R.sortBy(
        (sectionName: string) => sections[sectionName].order,
        Object.keys(flagsBySection)
      ),
    [flagsBySection, sections]
  );

  const canDropFlagSettingsCard = useCallback(
    (sectionName: string) =>
      ({ flagData }: { flagData: RedFlagSettingsData }) => {
        const isSameSection =
          (flagData.enabled && flagData.resolution === sectionName) ||
          (!flagData.enabled && sectionName === DISABLED_SECTION);
        if (isSameSection) {
          return false;
        }
        const canDropChecker = sections[sectionName].canDrop || R.always(false);
        return canDropChecker(flagData.type);
      },
    [sections]
  );

  const onDropFlagSettingsCard = useCallback(
    (sectionName: string) =>
      ({ flagData }: { flagData: RedFlagSettingsData }) => {
        const isEnabled = sectionName !== DISABLED_SECTION;
        const newResolutionValue = isEnabled
          ? (sectionName as RedFlagResolutionEnum)
          : flagData.resolution;
        onChange(flagData.type, newResolutionValue, isEnabled);
      },
    [onChange]
  );

  return (
    <div className={styles.flagsSettingsWrapper}>
      <div className={styles.flagsSettingsDescription}>
        <div className={styles.descriptionIcon}>
          <SvgIcon icon="big-red-flag" width="4.4em" height="5em" />
        </div>
        <div className={styles.descriptionText}>
          {phrases.FLAGS_TAB_DESCRIPTION}
        </div>
      </div>
      <div className={styles.sectionsWrapper}>
        {sortedSectionsNames.map((sectionName, index) => {
          return R.isEmpty(flagsBySection[sectionName]) ? null : (
            <div
              className={classNames(styles.section, {
                [styles.inlineSection]: viewMode === FlagsViewMode.Category,
              })}
              key={index}
            >
              <div className={styles.sectionName}>
                <TooltipWrapper
                  placement="top"
                  trigger="hover"
                  triggerClassName={styles.sectionNameTooltip}
                  tooltip={sections[sectionName].description}
                  enabled={!!sections[sectionName].description}
                  delayShow={TOOLTIP_DELAY_TIME_LONG}
                >
                  <UIText small className={styles.sectionNameText}>
                    {sections[sectionName].name}
                  </UIText>
                </TooltipWrapper>
              </div>
              <div className={styles.sectionContent}>
                {flagsBySection[sectionName].map((flagData, index) => {
                  return (
                    <DraggableFlagSettingsCard
                      isReadOnly={isTrialExpired}
                      isDraggable={areCardsDraggable}
                      dragWrapperClassName={
                        styles.draggableFlagSettingsCardWrapper
                      }
                      initialState={FlagSettingsState.collapsed}
                      className={styles.flagSettingsCard}
                      key={index}
                      flagData={flagData}
                      onChange={onChange}
                    />
                  );
                })}
                <ReorderDropTargetCell
                  isExpanded
                  accept={Object.values(DraggedRedFlagsType)}
                  canDrop={canDropFlagSettingsCard(sectionName)}
                  drop={onDropFlagSettingsCard(sectionName)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
