import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Task } from '@air/utils/fp';
import { usePrevious } from '@air/utils/hooks';
import R from '@air/third-party/ramda';
import { toast } from '@air/third-party/toast';

import fadeTransition from '@air/styles/transitions/fade.css';
import * as Http from '@air/utils/http';
import { withQueryParams } from '@air/utils/http';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { parseResponseJson, parseErrorJson } from '@air/utils/api';
import {
  compose,
  filter,
  identity,
  map,
  prop,
  propEq,
  chain,
  reduce,
} from 'ramda';
import { INPUT_DEBOUNCE_TIME } from '@air/constants/app';
import { getCompanySizeDescription } from '@air/domain/Common/CompanySizeConverter';
import { CardType, BaseCriteriaWithList } from 'domain/SearchCriteria';
import { SearchCriteriaListValue } from 'domain/SearchCriteria/BaseSearchCriteria';
import { getTopStackSize } from 'domain/SearchCriteria/cardHelpers';
import * as phrases from 'constants/phrases';
import { MAX_CARDS_IN_STACK } from 'components/Cards/cardsCommonCode';
import { NewSearchValues } from 'features/DraftSection/NewSearch/NewSearch';
import { CompanyExtendedType, CompanySizeResponse } from '@air/api';
import { loadInstitutionPaginatedDictionaryTask } from '@air/api-tasks/dictionariesApi';

export const WithDefaultValueOnConditionalMounting: React.FC<any> = ({
  indicator,
  field,
  form,
  defaultValue,
  children,
}) => {
  const previousIndicatorValue = usePrevious(indicator);
  const { setFieldValue } = form;
  useEffect(() => {
    if (indicator === true && previousIndicatorValue === false) {
      setFieldValue(field.name, defaultValue);
    } else if (indicator === false && previousIndicatorValue === true) {
      setFieldValue(field.name, null);
    }
  }, [indicator, previousIndicatorValue, field, defaultValue, setFieldValue]);

  return (
    <CSSTransition
      in={!!indicator}
      timeout={500}
      classNames={fadeTransition}
      unmountOnExit
    >
      <>{children}</>
    </CSSTransition>
  );
};

const loadCertificationPaginatedDictionaryTask =
  ({
    size,
    page,
    specializations,
  }: {
    size: number;
    page: number;
    specializations: string[];
  }) =>
  (keyword: any) =>
    Http.get(sharedUrls.DICTIONARY_API_CERTIFICATION)
      .bind(Http.withQueryParams({ keyword, size, page, specializations }))
      .toTask()
      .chain(parseResponseJson)
      .chainError(parseErrorJson);

const CERTIFICATION_OPTIONS_PAGE_SIZE = 10;
export const loadPaginatedCertificationAsyncOptions = R.debounce(
  (
    {
      value,
      page = 0,
      size = CERTIFICATION_OPTIONS_PAGE_SIZE,
      specializations,
    }: {
      value?: string;
      page?: number;
      size?: number;
      excludeId?: number[];
      specializations: string[];
    },
    callback?: (result: any) => void
  ) => {
    compose(
      Task.fork(identity, callback),
      loadCertificationPaginatedDictionaryTask({ size, page, specializations })
    )(value);
  },
  INPUT_DEBOUNCE_TIME
);

export const loadSkillPaginatedDictionaryTask =
  ({
    size,
    page,
    excludeId,
    specializations,
  }: {
    size: number;
    page: number;
    excludeId: Array<number | string>;
    specializations: string[];
  }) =>
  (keyword: string) =>
    Http.get(sharedUrls.DICTIONARY_API_SKILL)
      .bind(
        withQueryParams({ keyword, size, page, excludeId, specializations })
      )
      .toTask()
      .chain(parseResponseJson);

export const loadIndustryDictionaryTask =
  (size: number, page: number) => (keyword: string) =>
    Http.get(sharedUrls.DICTIONARY_API_INDUSTRY)
      .bind(withQueryParams({ keyword, size, page }))
      .toTask()
      .chain(parseResponseJson);

export const loadCompanyPaginatedDictionaryTask =
  ({
    size,
    page,
    excludeId,
  }: {
    size: number;
    page: number;
    excludeId: Array<number | string>;
  }) =>
  (keyword: string) =>
    Http.get(sharedUrls.DICTIONARY_API_COMPANY_EXTENDED)
      .bind(withQueryParams({ keyword, size, page, excludeId }))
      .toTask()
      .chain(parseResponseJson);

export const loadCompanySizesDictionaryTask = () =>
  Http.get(sharedUrls.DICTIONARY_API_COMPANY_SIZES)
    .toTask()
    .chain(parseResponseJson)
    .map(
      compose(
        map((company: CompanySizeResponse) => ({
          value: company.id,
          type: CompanyExtendedType.COMPANYSIZE,
          minSize: company.minSize,
          maxSize: company.maxSize,
          label: company.fullName,
          description: getCompanySizeDescription(company),
        })),
        prop('items')
      )
    );

export const getIdsInUse = (cardType: CardType) =>
  compose(
    reduce(function (acc, item) {
      const current = prop('value', item);
      if (current && !propEq('label', current, item)) {
        return [...acc, current];
      }
      return acc;
    }, []),
    chain<
      BaseCriteriaWithList<SearchCriteriaListValue>,
      SearchCriteriaListValue
    >(prop('list')),
    filter(propEq('cardType', cardType))
  );

export const getLabelsInUse = (cardType: CardType) =>
  compose(
    reduce((acc, it) => {
      const label = prop('label', it) as string;
      return label ? [...acc, label.toLowerCase()] : acc;
    }, []),
    chain<
      BaseCriteriaWithList<SearchCriteriaListValue>,
      SearchCriteriaListValue
    >(prop('list')),
    filter(propEq('cardType', cardType))
  );

export const isValidNewOption =
  (cardType: CardType) =>
  (values: NewSearchValues) =>
  (inputValue: any, selectValue: any, selectOptions: any) => {
    if (!inputValue) return false;

    const selectOptionsLabels = map(
      compose<SearchCriteriaListValue[], string, string>(
        (it) => it.toLowerCase(),
        prop('label')
      ),
      selectOptions
    );
    const labelsInUse = getLabelsInUse(cardType)(values.criteria);
    return ![...labelsInUse, ...selectOptionsLabels].includes(
      inputValue.toLowerCase()
    );
  };

const SKILL_OPTIONS_PAGE_SIZE = 10;
export const loadPaginatedSkillsAsyncOptions = R.debounce(
  (
    {
      value,
      page = 0,
      size = SKILL_OPTIONS_PAGE_SIZE,
      specializations,
      excludeId = [],
    }: {
      value?: string;
      page?: number;
      size?: number;
      excludeId?: number[];
      specializations: string[];
    },
    callback?: (result: any) => void
  ) => {
    compose(
      Task.fork(identity, callback),
      loadSkillPaginatedDictionaryTask({
        size,
        page,
        excludeId,
        specializations,
      })
    )(value);
  },
  INPUT_DEBOUNCE_TIME
);

const INDUSTRY_OPTIONS_PAGE_SIZE = 10;
export const loadIndustryAsyncOptions = R.debounce(
  (
    {
      value,
      page = 0,
      size = INDUSTRY_OPTIONS_PAGE_SIZE,
    }: { value?: string; page?: number; size?: number },
    callback?: (result: any) => void
  ) => {
    compose(
      Task.fork(identity, callback),
      loadIndustryDictionaryTask(size, page)
    )(value);
  },
  INPUT_DEBOUNCE_TIME
);

const INSTITUTION_OPTIONS_PAGE_SIZE = 10;
export const loadPaginatedInstitutionAsyncOptions = R.debounce(
  (
    {
      value,
      page = 0,
      size = INSTITUTION_OPTIONS_PAGE_SIZE,
      includeDeprecated = false,
    }: {
      value?: string;
      page?: number;
      size?: number;
      includeDeprecated?: boolean;
    },
    callback?: (result: any) => void
  ) => {
    compose(
      Task.fork(identity, callback),
      loadInstitutionPaginatedDictionaryTask({ size, page, includeDeprecated })
    )(value);
  },
  INPUT_DEBOUNCE_TIME
);

const COMPANY_OPTIONS_PAGE_SIZE = 10;
export const loadPaginatedCompanyAsyncOptions = R.debounce(
  (
    {
      value,
      page = 0,
      size = COMPANY_OPTIONS_PAGE_SIZE,
      excludeId = [],
    }: { value?: string; page?: number; size?: number; excludeId?: number[] },
    callback?: (result: any) => void
  ) => {
    compose(
      Task.fork(identity, callback),
      loadCompanyPaginatedDictionaryTask({ size, page, excludeId })
    )(value);
  },
  INPUT_DEBOUNCE_TIME
);

export const loadCompanySizesOptions = () =>
  compose(
    Task.fork((): unknown[] => {
      toast.error(phrases.COMPANY_SIZE_ERROR_FETCHING);
      return [];
    }, identity),
    loadCompanySizesDictionaryTask
  )();

export const canMergeLists = (
  source: any,
  target: any,
  maxStackListSize = MAX_CARDS_IN_STACK
) => {
  const mergedListLengthAfterDrop =
    getTopStackSize(source) + getTopStackSize(target);
  return mergedListLengthAfterDrop <= maxStackListSize;
};
