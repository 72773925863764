import React, { useRef, useCallback } from 'react';
import R from '@air/third-party/ramda';
import TextareaAutosize from 'react-textarea-autosize';
import { PlaceholderObject } from '@air/api';
import { Button } from '@air/components';

import styles from './EmailEditor.css';
import { useFormContext } from 'react-hook-form';

const MAX_CONTENT_LENGTH = 3000;

export const EmailEditor: React.FC<{
  placeholders: PlaceholderObject[];
  onFocus?: () => void;
  isFocused?: boolean;
  name: string;
  disabled?: boolean;
}> = ({ placeholders = [], onFocus = R.noop, isFocused, name, disabled }) => {
  const { register, watch } = useFormContext();
  const { ref: editorRef, ...rest } = register(name);

  const textarea = useRef(null);
  const registerTextarea = useCallback(
    (node: HTMLTextAreaElement) => {
      editorRef(node);
      textarea.current = node;
    },
    [editorRef]
  );
  const content = watch(name);

  return (
    <div className={styles.emailEditor}>
      <TextareaAutosize
        {...rest}
        disabled={disabled}
        inputRef={registerTextarea}
        autoFocus={!content && !disabled}
        onFocus={onFocus}
        maxLength={MAX_CONTENT_LENGTH}
      />

      {!R.isNullOrEmpty(placeholders) && isFocused && (
        <div className={styles.placeholdersList}>
          {placeholders.map((item) => (
            <Button
              key={item.placeholder}
              small
              disabled={content.includes(item.placeholder)}
              icon="plus"
              className={styles.sectionPlaceholder}
              variant={Button.variants.POSITIVE_CONFIRM}
              onClick={() => insertAtCaret(textarea.current, item.placeholder)}
            >
              {item.displayName}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

function insertAtCaret(textarea: HTMLTextAreaElement, text: string) {
  const scrollPos = textarea.scrollTop;
  let caretPos = textarea.selectionStart;

  const front = textarea.value.substring(0, caretPos);
  const back = textarea.value.substring(
    textarea.selectionEnd,
    textarea.value.length
  );
  const value = front + text + back;

  /*
		See https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
		for expalanation. This is the way to trigger onChange event on TextareaAutosize,
		otherwise it didn't adjust height when inserting placeholders.
	*/
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLTextAreaElement.prototype,
    'value'
  ).set;
  nativeInputValueSetter.call(textarea, value);

  caretPos = caretPos + text.length;
  textarea.selectionStart = caretPos;
  textarea.selectionEnd = caretPos;
  textarea.focus();
  textarea.scrollTop = scrollPos;

  const event = new Event('change', { bubbles: true });
  textarea.dispatchEvent(event);
}
