import { useState } from 'react';
import R from '@air/third-party/ramda';
import { useCacheContext, useCacheMethods } from '@air/providers/CacheProvider';
import { cacheSelectors } from '@air/selectors';
import { loadJobSpecializationsTask } from '@air/api-tasks/dictionariesApi';
import { toast } from '@air/third-party/toast';
import * as phrases from '@air/constants/phrases';
import { DictionaryItem } from '@air/api';

export const useJobSpecializations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [specializations, setSpecializations] = useState<
    { label: string; value: string }[]
  >(useCacheContext(cacheSelectors.jobSpecializations) || []);

  const { updateCache } = useCacheMethods();

  if (R.isNullOrEmpty(specializations) && !isLoading) {
    setIsLoading(true);
    loadJobSpecializationsTask().fork(
      () => {
        setIsLoading(false);
        toast.error(phrases.ERROR_JOB_SPECIALIZATIONS_FETCH);
        return [];
      },
      (result) => {
        const mappedResult = R.map(
          (it: DictionaryItem) => ({
            value: it.fullName,
            label: it.displayName,
          }),
          result.items
        );
        setSpecializations(mappedResult);
        updateCache({ jobSpecializations: mappedResult });
        setIsLoading(false);
      }
    );
  }

  return { isLoading, specializations };
};
