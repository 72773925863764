import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useToggle from 'react-use/lib/useToggle';
import { SearchQuestioningResponse } from '@air/api';
import { toast } from '@air/third-party/toast';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import {
  GA_LABEL_START_QUESTIONING,
  GA_LABEL_STOP_QUESTIONING,
} from 'constants/gaLabels';
import {
  Button,
  Header,
  OverlayPanel,
  Paragraph,
  TooltipWrapper,
} from '@air/components';
import * as phrases from 'constants/phrases';
import * as InterviewApi from 'features/InterviewSection/interviewApi';

import styles from './QuestionnaireForm.css';
import { CandidateStatusesListForm } from 'features';
import { CandidateStatusesListFormSettings } from 'features/CandidateStatusesListForm/CandidateStatusesListForm';
import {
  mapCandidateQuestioningDataToQuestioningRequest,
  mapCandidateQuestioningResponseToQuestioningData,
  QuestioningData,
} from 'domain/CandidateData/CandidateQuestioningData';
import R from '@air/third-party/ramda';
import commonStyles from 'styles/commonStyles.css';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';

type QuestionnaireFormProps = {
  settings: QuestioningData;
  onUpdateSettings: (
    formValues: CandidateStatusesListFormSettings
  ) => Promise<void>;
};
const QuestionnaireForm: React.FC<QuestionnaireFormProps> = ({
  settings,
  onUpdateSettings,
}) => {
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (
      formValues: CandidateStatusesListFormSettings,
      enableLoadingState = true
    ) => {
      enableLoadingState && setLoading(true);
      await onUpdateSettings(formValues);
      enableLoadingState && setLoading(false);
    },
    [onUpdateSettings]
  );

  const questioningSettings = useMemo(() => {
    return {
      enable: settings.activeEnable,
      values: settings.activeProfileStatuses,
      isReminderChecked: settings.reminderEnable,
      remindInDays: settings.reminderInDays,
    };
  }, [settings]);

  const { buttonText, buttonVariant } = questioningSettings.enable
    ? {
        buttonText: phrases.QUESTIONNAIRE_FORM_STOP_BUTTON,
        buttonVariant: Button.variants.POSITIVE_SECONDARY,
      }
    : {
        buttonText: phrases.QUESTIONNAIRE_FORM_SUBMIT_BUTTON,
        buttonVariant: Button.variants.POSITIVE_CONFIRM,
      };

  return (
    <div className={styles.wrapper}>
      <Header className={styles.questionnaireFormHeader} level={2} bold>
        {phrases.QUESTIONNAIRE_FORM_HEADER}
      </Header>
      <Paragraph className={styles.questionnaireFormDescription} small>
        {phrases.QUESTIONNAIRE_FORM_DESCRIPTION}
      </Paragraph>
      <CandidateStatusesListForm
        settings={questioningSettings}
        buttonText={buttonText}
        isLoading={isLoading}
        buttonVariant={buttonVariant}
        isSubmitDisabled={isLoading}
        isReminderDisabled={isLoading || questioningSettings.enable}
        onSubmit={onSubmit}
      />
    </div>
  );
};
QuestionnaireForm.displayName = 'QuestionnaireForm';

type QuestionnaireFormTriggerProps = {
  searchId?: number;
  disabled?: boolean;
  enableButtonTooltip?: boolean;
  onQuestioningUpdated?: () => void;
};
export const QuestionnaireFormTriggerButton: React.FC<
  QuestionnaireFormTriggerProps
> = ({ searchId, disabled, onQuestioningUpdated, enableButtonTooltip }) => {
  const [isQuestionnaireSettingsVisible, setQuestionnaireSettingsVisibility] =
    useToggle(false);

  const [questioningSettings, setQuestioningSettings] = useState(
    {} as QuestioningData
  );

  /*
    Fetch existing settings for sending questionnaires.
  */
  useEffect(() => {
    if (!searchId || disabled || questioningSettings.activeProfileStatuses)
      return;

    InterviewApi.checkQuestioning(searchId).fork(
      () => {
        toast.error(phrases.SOMETHING_WENT_WRONG_ERROR);
      },
      (questioningResponse: SearchQuestioningResponse) => {
        setQuestioningSettings(
          mapCandidateQuestioningResponseToQuestioningData(questioningResponse)
        );
      }
    );
  }, [searchId, disabled, questioningSettings.activeProfileStatuses]);

  const updateSettings = useCallback(
    (formValues: CandidateStatusesListFormSettings) => {
      const newQuestioningSettings = {
        ...questioningSettings,
        activeEnable: formValues.enable,
        activeProfileStatuses: formValues.values,
        reminderEnable: formValues.isReminderChecked,
        reminderInDays: formValues.remindInDays,
      };
      const requestData = mapCandidateQuestioningDataToQuestioningRequest(
        newQuestioningSettings
      );
      const gaLabel = formValues.enable
        ? GA_LABEL_START_QUESTIONING
        : GA_LABEL_STOP_QUESTIONING;
      trackEvent({
        category: GACategory.ScreeningPage,
        label: gaLabel,
      });

      return InterviewApi.updateQuestioning(searchId, requestData).fork(
        () => {
          toast.error(phrases.SOMETHING_WENT_WRONG_ERROR);
          setQuestioningSettings({
            ...newQuestioningSettings,
            activeEnable: !newQuestioningSettings.activeEnable,
          });
        },
        () => {
          setQuestioningSettings(newQuestioningSettings);
          onQuestioningUpdated?.();
        }
      );
    },
    [searchId, onQuestioningUpdated, questioningSettings]
  );

  const isButtonDisabled = disabled || R.isEmpty(questioningSettings);

  return (
    <>
      <TooltipWrapper
        delayShow={TOOLTIP_DELAY_TIME_LONG}
        tooltip={phrases.QUESTIONING_APPLICANTS_START_INTERVIEW_SECTION_BUTTON}
        enabled={enableButtonTooltip}
        disabledChildren={isButtonDisabled}
        flexGrow={false}
      >
        <Button
          icon="send-emails-icon"
          className={commonStyles.leftSidebarButton}
          disabled={isButtonDisabled}
          variant={Button.variants.POSITIVE_CONFIRM}
          onClick={setQuestionnaireSettingsVisibility}
        >
          {phrases.QUESTIONING_APPLICANTS_START_INTERVIEW_SECTION_BUTTON}
        </Button>
      </TooltipWrapper>

      <OverlayPanel
        className={styles.overlayPanel}
        visible={isQuestionnaireSettingsVisible}
        onClose={setQuestionnaireSettingsVisibility}
      >
        <QuestionnaireForm
          settings={questioningSettings}
          onUpdateSettings={updateSettings}
        />
      </OverlayPanel>
    </>
  );
};
QuestionnaireFormTriggerButton.displayName = 'QuestionnaireFormTriggerButton';
