import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import { LocationInfo } from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  CardType,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import {
  skillDetailsDemandOptions,
  optionalOrImportantOptions,
} from 'constants/skills';

export type DetailsDemandSelectedValueT =
  typeof skillDetailsDemandOptions[keyof typeof skillDetailsDemandOptions];

export interface LocationCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.location;
  list: SearchCriteriaListValue<{ extras: LocationInfo }>[];
  maxAllowedDistance: number | null;
}

export const GOOGLE_LOCATION_AUTOCOMPLETE_LANGUAGE = 'en';
export const GOOGLE_AUTOCOMPLETE_TYPES = ['geocode'];
export const GOOGLE_PLACE_DETAILS_FIELDS = [
  'address_components',
  'formatted_address',
  'geometry',
  'name',
  'place_id',
];

export const filterCitiesStatesAndCountries = (
  it: google.maps.places.AutocompletePrediction
): boolean => {
  return (
    it.types.includes('geocode') &&
    it.types.includes('political') &&
    (it.types.includes('country') ||
      it.types.includes('locality') ||
      it.types.includes('administrative_area_level_1'))
  );
};

export const isLocation = (
  item: BaseSearchCriteriaData
): item is LocationCriteriaData => {
  return item.cardType === CardType.location;
};

export const getLocationDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.IMPORTANT,
  idx = null,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
}: AddCriteriaInitials): LocationCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  initialCardStatus,
  cardType: CardType.location,
  importance: optionalOrImportantOptions[importance],
  list: [null],
  maxAllowedDistance: null,
});

export const mapLocationListToLocationCriteriaData = (
  locations: ApiModel.LocationList
): LocationCriteriaData => {
  return {
    idx: locations.idx,
    id: CardType.location,
    key: genId(),
    cardType: CardType.location,
    initialCardStatus: InitialCardStatusEnum.ExistingCard,
    list: locations.list.map((locationItem: ApiModel.LocationInfo) => ({
      value: locationItem.fullName,
      label: locationItem.fullName,
      extras: locationItem,
    })),
    importance: optionalOrImportantOptions[locations.importance],
    maxAllowedDistance: locations.maxAllowedDistance,
  };
};

export const mapLocationCriteriaDataToLocationList = (
  location: LocationCriteriaData
): ApiModel.LocationList => {
  return {
    idx: location.idx,
    list: location.list.filter(Boolean).map((listItem) => ({
      ...listItem.extras,
    })),
    maxAllowedDistance: location.maxAllowedDistance,
    importance: location.importance.value,
  };
};

export const mapGooglePlaceDetailsToLocationInfo = (
  placeResult: google.maps.places.PlaceResult,
  displayName: string
): LocationInfo => {
  const country = R.find(
    (it) => it.types.includes('political') && it.types.includes('country'),
    placeResult.address_components
  );
  const state = R.find(
    (it) =>
      it.types.includes('political') &&
      it.types.includes('administrative_area_level_1'),
    placeResult.address_components
  );
  const city = R.find(
    (it) => it.types.includes('political') && it.types.includes('locality'),
    placeResult.address_components
  );
  return {
    fullName: displayName,
    ...(country && {
      country: {
        longName: country.long_name,
        shortName: country.short_name,
      },
    }),
    ...(state && {
      state: {
        longName: state.long_name,
        shortName: state.short_name,
      },
    }),
    ...(city && {
      city: {
        longName: city.long_name,
        shortName: city.short_name,
      },
    }),
    geoLocation: {
      latitude: placeResult.geometry.location.lat(),
      longitude: placeResult.geometry.location.lng(),
    },
  };
};
