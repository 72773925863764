export function isElementOverflown({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}: HTMLElement): boolean {
  /*
    In some browsers, specifically when used in our Card component,
    scrollHeight differs from clientHeight by 1-2px, because of
    display: 'inline' in child element.

    This leads to incorrect result of overflow check.
    For now, I've decided to just add 1 to mitigate
    the problem.
  */

  const WHACKY_RENDER_COEFFICIENT = 1;

  return (
    scrollHeight > clientHeight + WHACKY_RENDER_COEFFICIENT ||
    scrollWidth > clientWidth
  );
}

/*
  Simple helper for key press validation.
  Usage: if (isKey.Enter(e) || isKey[' ']) { ... }
*/
export const isKey: {
  [key: string]: (e: KeyboardEvent) => boolean;
} = new Proxy(
  {},
  {
    get(obj, prop) {
      return function (event: any) {
        return event.key === prop;
      };
    },
  }
);

export const scrollIntoView = (
  elem: HTMLElement,
  options?: boolean | ScrollIntoViewOptions
) => {
  if (!elem) return;
  // @ts-ignore TS doesn't except scrollIntoViewIfNeeded in HTMLElement
  if (elem.scrollIntoViewIfNeeded) {
    // @ts-ignore
    elem.scrollIntoViewIfNeeded(options);
  } else {
    elem.scrollIntoView(options);
  }
};
