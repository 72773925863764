import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  normalizeSearchCriteriaExperience,
  SearchCriteriaExperienceT,
  SearchCriteriaListValue,
} from './BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import * as ApiModel from '@air/api';
import { TitleExtendedItemType, TitleExtendedTypeahead } from '@air/api';
import genId from '@air/utils/uniqueId';
import { criteriaImportanceOptions } from 'constants/skills';
import R from '@air/third-party/ramda';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';
import { RoleSearchCriteriaListExtras } from '@air/domain/SearchCriteriaCards/RoleCard/roleCardHelpers';
import * as phrases from 'constants/phrases';
import {
  CandidateCriteriaListItem,
  CandidateRoleData,
} from 'domain/CandidateData';
import { RoleItem, getExtendedRoleItems } from '@air/domain/dictionaries';

export type RoleListValue = SearchCriteriaListValue<{
  extras: RoleSearchCriteriaListExtras;
}>;

export interface ExcludeRoleCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.role;
  list: RoleListValue[];
  recent: number | null;
  exclude: true;
}

export interface IncludeRoleCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.role;
  list: RoleListValue[];
  experience: SearchCriteriaExperienceT | null;
  recent: number | null;
  exclude: false;
}

export type RoleCriteriaData =
  | ExcludeRoleCriteriaData
  | IncludeRoleCriteriaData;

export const isRole = (
  item: BaseSearchCriteriaData | any
): item is RoleCriteriaData => item.cardType === CardType.role;

export const isExcludeRole = (
  item: BaseSearchCriteriaData
): item is ExcludeRoleCriteriaData => {
  return isRole(item) && item.exclude;
};

export const isIncludeRole = (
  item: BaseSearchCriteriaData
): item is IncludeRoleCriteriaData => {
  return isRole(item) && !item.exclude;
};

export const isRoleGroupCard = (
  cardData:
    | BaseSearchCriteriaData
    | SearchCriteriaListValue
    | RoleListValue
    | CandidateCriteriaListItem
    | CandidateRoleData
): boolean => {
  if (!cardData) return false;

  if (isRole(cardData)) {
    return (
      cardData.list[0]?.extras?.type === TitleExtendedItemType.TITLECATEGORY
    );
  }
  return (
    'extras' in cardData &&
    cardData.extras.type === TitleExtendedItemType.TITLECATEGORY
  );
};

export const getUnwantedRoleLabel = (
  cardData: BaseSearchCriteriaData | RoleListValue | CandidateRoleData
) => {
  const isRoleGroup = isRoleGroupCard(cardData);
  return isRoleGroup ? phrases.UNWANTED_GROUP_LABEL : phrases.UNWANTED_LABEL;
};

export const getRoleCriteriaDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.MANDATORY,
  idx,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
}: AddCriteriaInitials): IncludeRoleCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  initialCardStatus,
  cardType: CardType.role,
  importance: criteriaImportanceOptions[importance],
  list: [null],
  experience: null,
  recent: null,
  exclude: false,
});

export const mapExcludeListToRoleCriteria = (
  role: ApiModel.ExcludeExperienceList
): ExcludeRoleCriteriaData => {
  return {
    idx: role.idx,
    id: `exclude-${role.idx}`,
    key: genId(),
    cardType: CardType.role,
    initialCardStatus: InitialCardStatusEnum.ExistingCard,
    exclude: true,
    list: role.list.map((title): RoleListValue => {
      return {
        value: title.id,
        label: title.fullName,
        extras: {
          type: title.type as TitleExtendedItemType,
          items: title.items,
        },
      };
    }),
    recent: role?.recent,
    importance:
      criteriaImportanceOptions[
        role.importance || ApiModel.SearchCriteriaImportanceEnum.MANDATORY
      ],
  };
};

export const mapExcludeRoleCriteriaDataToRoleList = (
  role: ExcludeRoleCriteriaData
): ApiModel.ExcludeExperienceList => {
  return {
    idx: role.idx,
    list: role.list.map((title) => ({
      ...(title.value !== title.label && {
        id: title.value as number,
        type: title.extras.type,
        items: title.extras.items,
      }),
      fullName: title.label,
    })),
    recent: role.recent,
    importance: role.importance.value,
  };
};

export const mapIncludeListToRoleCriteria = (
  role: ApiModel.IncludeExperienceList
): IncludeRoleCriteriaData => {
  return {
    idx: role.idx,
    id: `include-${role.idx}`,
    key: genId(),
    cardType: CardType.role,
    initialCardStatus: InitialCardStatusEnum.ExistingCard,
    exclude: false,
    list: role.list.map((title): RoleListValue => {
      return {
        value: title.id,
        label: title.fullName,
        extras: {
          type: title.type as TitleExtendedItemType,
          items: title.items,
        },
      };
    }),
    experience: R.pick<
      ApiModel.ExtendedExperienceInfo,
      'idealMin' | 'idealMax' | 'acceptableMin' | 'acceptableMax'
    >(
      ['idealMin', 'idealMax', 'acceptableMin', 'acceptableMax'],
      role.experience
    ),
    recent: role?.experience?.recent,
    importance:
      criteriaImportanceOptions[
        role.importance || ApiModel.SearchCriteriaImportanceEnum.MANDATORY
      ],
  };
};

export const mapIncludeRoleCriteriaDataToRoleList = (
  role: IncludeRoleCriteriaData
): ApiModel.IncludeExperienceList => {
  return {
    idx: role.idx,
    list: role.list.map((title) => ({
      ...(title.value !== title.label && {
        id: title.value as number,
        type: title.extras.type,
        items: title.extras.items,
      }),
      fullName: title.label,
    })),
    experience: {
      ...normalizeSearchCriteriaExperience(role.experience),
      recent: role.recent,
    },
    importance: role.importance.value,
  };
};
export const titleDataMapper = (excludeIds: number[]) =>
  R.compose(
    R.map(
      ({
        id,
        fullName,
        type,
        items,
        extras,
      }: SearchCriteriaListValue<
        TitleExtendedTypeahead & { extras: { isSimilarGroup: boolean } }
      >) => ({
        value: id,
        label: fullName,
        extras: {
          ...extras,
          type,
          items,
        },
      })
    ),
    R.reject((item: RoleItem) => R.includes(item.id, excludeIds)),
    R.uniqBy(R.path(['id'])),
    (items: TitleExtendedTypeahead[]) => getExtendedRoleItems(items)
  );
