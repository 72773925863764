import R from '@air/third-party/ramda';
import {
  InterviewStatusEnum,
  SearchCriteriaAlertType,
  SearchCriteriaMatchStatusEnum,
  SearchEducationResponse,
} from '@air/api';
import { CandidateEducationCriteriaListItem } from 'domain/CandidateData/CandidateCriteria/BaseCandidateData';
import { BaseCriteriaWithList, CardType } from 'domain/SearchCriteria';
import {
  InterviewData,
  interviewDataMapper,
} from '@air/domain/InterviewNotes/InterviewData';
import {
  AlertData,
  getEducationAlertData,
  getGraduationAlertData,
} from '@air/domain/Alerts/AlertData';
import { BaseRedFlagData } from '@air/domain/RedFlags';

import {
  BaseCandidateData,
  CandidateCertificationData,
  CandidateCompanyData,
  CandidateCriteriaListItem,
  CandidateDegreeData,
  CandidateInstitutionData,
  CandidateMajorData,
  CandidateManagerialData,
  CandidateProfessionalData,
  CandidateProfileData,
  CandidateRoleData,
  CandidateSkillData,
  CandidateQuestionData,
  getDegreeData,
  getGraduationData,
  getInstitutionData,
  getMajorData,
} from 'domain/CandidateData';
import { CandidateIndustryData } from 'domain/CandidateData/CandidateCriteria/CandidateIndustryData';

export type CandidateEducation = {
  degree: CandidateDegreeData | null;
  major: CandidateMajorData | null;
  institution: CandidateInstitutionData | null;
};

export type CandidateCriteriaData =
  | CandidateSkillData
  | CandidateCertificationData
  | CandidateCompanyData
  | CandidateIndustryData
  | CandidateRoleData
  | CandidateManagerialData
  | CandidateProfessionalData
  | CandidateQuestionData;

export type CandidateEducationData =
  | CandidateMajorData
  | CandidateDegreeData
  | CandidateInstitutionData;

/**
 * Matching Status OPENED represents two states ANSWERED and ASKED,
 * sometimes we need to distinguish between them.
 */
export enum CriteriaInterviewStatus {
  ASKED = 'ASKED',
  ANSWERED = 'ANSWERED',
}

const filterByAlertType =
  (alertType: SearchCriteriaAlertType | SearchCriteriaAlertType[]) =>
  (alertData: AlertData | null) => {
    if (Array.isArray(alertType)) {
      return alertType.includes(alertData?.name as SearchCriteriaAlertType);
    }
    return alertData?.name === alertType;
  };

export const getEducationInterviewData = (
  searchEducation: SearchEducationResponse
) => {
  if (!searchEducation.interview) return null;

  const interviewData = interviewDataMapper(
    searchEducation.refId,
    searchEducation.interview
  );

  const majorAlerts = searchEducation.major
    ? R.compose(
        filterByAlertType(SearchCriteriaAlertType.EDUCATIONMAJORMISSED),
        getEducationAlertData
      )(searchEducation.major)
    : null;

  const degreeAlerts = searchEducation.degree
    ? R.compose(
        filterByAlertType(SearchCriteriaAlertType.EDUCATIONDEGREEMISSED),
        getEducationAlertData
      )(searchEducation.degree)
    : null;

  const institutionAlerts = searchEducation.institution
    ? R.compose(
        filterByAlertType(SearchCriteriaAlertType.EDUCATIONINSTITUTIONMISSED),
        getEducationAlertData
      )(searchEducation.institution)
    : null;

  const graduationAlert = getGraduationAlertData(searchEducation.alerts);
  const educationAlerts = [majorAlerts, degreeAlerts, institutionAlerts].filter(
    Boolean
  );

  if (educationAlerts.length === 1 && !graduationAlert) {
    return {
      major: majorAlerts ? interviewData : null,
      degree: degreeAlerts ? interviewData : null,
      institution: institutionAlerts ? interviewData : null,
    };
  } else if (graduationAlert || educationAlerts.length > 1) {
    return {
      major: interviewData,
      degree: interviewData,
      institution: interviewData,
    };
  } else {
    return {
      major: null,
      degree: null,
      institution: null,
    };
  }
};

export const getPrimaryEducation = R.compose<
  SearchEducationResponse[][],
  SearchEducationResponse[],
  SearchEducationResponse
>(R.head, R.filter(R.propEq('primary', true)));

export const mapEducationToCandidateData = (
  searchEducation: SearchEducationResponse[] = []
): CandidateEducation => {
  if (R.isNullOrEmpty(searchEducation)) {
    return null;
  }

  const education: any = getPrimaryEducation(searchEducation);

  const interview = getEducationInterviewData(education);

  const graduation = getGraduationData(education);
  return {
    degree: getDegreeData(education, graduation, interview?.degree),
    major: getMajorData(education, graduation, interview?.major),
    institution: getInstitutionData(
      education,
      graduation,
      interview?.institution
    ),
  };
};

const checkUnreadNote = (item: CandidateCriteriaListItem): boolean =>
  !!item.interview?.commentId && !item.interview?.read;
const checkExistingNote = (item: CandidateCriteriaListItem): boolean =>
  !!item.interview?.commentId;

const educationHasComments = (criteria: CandidateEducationData): boolean => {
  const interview = criteria.idealList?.[0]?.interview;
  return (
    interview?.commentId && interview.status === InterviewStatusEnum.INREVIEW
  );
};

export const itemHasComments = <T extends { interview: InterviewData }>(
  item: T
) => {
  return (
    !!item.interview?.commentId &&
    item.interview.status === InterviewStatusEnum.INREVIEW
  );
};

const criteriaHasComments = (
  criteria: BaseCriteriaWithList<CandidateCriteriaListItem>
): boolean => {
  return criteria?.list?.some?.(itemHasComments);
};

export const hasComments = (criteria: BaseCandidateData): boolean => {
  switch (criteria.cardType) {
    case CardType.skill:
    case CardType.certification:
    case CardType.industry:
    case CardType.location:
    case CardType.company:
    case CardType.role:
    case CardType.managerial:
    case CardType.professional:
    case CardType.question:
      return criteriaHasComments(criteria as CandidateCriteriaData);
    case CardType.major:
    case CardType.degree:
    case CardType.institution:
      return educationHasComments(criteria as CandidateEducationData);
    default:
      return false;
  }
};

export const criteriaHasUnreadComment = (
  criteria: BaseCriteriaWithList<CandidateCriteriaListItem>
): boolean => {
  return criteria?.list?.some?.((item) => checkUnreadNote(item));
};

export const educationHasUnreadComment = (
  criteria: CandidateEducationData
): boolean => {
  const interview = (criteria as CandidateEducationData)?.idealList[0]
    .interview;
  return interview?.commentId && !interview?.read;
};

export const hasUnreadComment = (criteria: BaseCandidateData): boolean => {
  switch (criteria.cardType) {
    case CardType.skill:
    case CardType.certification:
    case CardType.industry:
    case CardType.location:
    case CardType.company:
    case CardType.role:
    case CardType.managerial:
    case CardType.professional:
    case CardType.question:
      return criteriaHasUnreadComment(criteria as CandidateCriteriaData);
    case CardType.major:
    case CardType.degree:
    case CardType.institution:
      return educationHasUnreadComment(criteria as CandidateEducationData);
    default:
      return false;
  }
};

const filterNotes = (predicateFn: (i: CandidateCriteriaListItem) => boolean) =>
  R.compose<
    CandidateCriteriaData[],
    CandidateCriteriaListItem[],
    CandidateCriteriaListItem[],
    CandidateCriteriaListItem
  >(R.head, R.filter<CandidateCriteriaListItem>(predicateFn), R.prop('list'));

const filterByAnyNote = filterNotes(checkExistingNote);
const filterByUnreadNote = filterNotes(checkUnreadNote);

export const findFirstUnreadComment = (criteria: BaseCandidateData): any => {
  if (!hasComments(criteria)) {
    return null;
  }
  switch (criteria.cardType) {
    case CardType.skill:
    case CardType.certification:
    case CardType.industry:
    case CardType.location:
    case CardType.company:
    case CardType.role:
    case CardType.managerial:
    case CardType.professional:
    case CardType.question:
      const unreadNote = filterByAnyNote(criteria as CandidateCriteriaData);

      if (R.isNullOrEmpty(unreadNote)) {
        return filterByUnreadNote(criteria as CandidateCriteriaData);
      }
      return unreadNote;
    case CardType.major:
    case CardType.degree:
    case CardType.institution:
      return R.compose<
        CandidateEducationData[],
        CandidateEducationCriteriaListItem[],
        CandidateEducationCriteriaListItem[],
        CandidateEducationCriteriaListItem
      >(
        R.head,
        R.filter<CandidateEducationCriteriaListItem>((item) =>
          checkUnreadNote(item)
        ),
        R.prop('idealList')
      )(criteria as CandidateEducationData);
    default:
      return null;
  }
};

export const getSubmittedInterviews = (notes: BaseRedFlagData[]) =>
  notes
    .map(R.prop('interview'))
    .filter(
      R.propEq('status', InterviewStatusEnum.INREVIEW)
    ) as InterviewData[];

export const hasUnreadNote = (notes: BaseRedFlagData[]) => {
  return getSubmittedInterviews(notes).some(
    (interview: InterviewData) => !!interview.commentId && !interview.read
  );
};

export const isCandidateWithUnreadComment = (
  candidateProfile: Pick<
    CandidateProfileData,
    | 'skills'
    | 'certificates'
    | 'companies'
    | 'industries'
    | 'roles'
    | 'managerial'
    | 'professional'
    | 'questions'
    | 'education'
    | 'location'
  >
): boolean => {
  const isCriteriaWithUnreadComment = R.compose<
    Pick<
      CandidateProfileData,
      | 'skills'
      | 'certificates'
      | 'companies'
      | 'industries'
      | 'roles'
      | 'managerial'
      | 'professional'
      | 'questions'
      | 'education'
      | 'location'
    >[],
    { [n: string]: CandidateCriteriaData[] },
    CandidateCriteriaData[][],
    CandidateCriteriaData[],
    boolean
  >(
    R.any(criteriaHasUnreadComment),
    // @ts-ignore
    (criteria: any) => R.flatten<CandidateCriteriaData>(criteria),
    R.values,
    R.pick([
      'skills',
      'certificates',
      'companies',
      'industries',
      'roles',
      'managerial',
      'professional',
      'questions',
      'location',
    ])
  )(candidateProfile) as boolean;

  const isEducationWithUnreadComment: boolean = R.compose(
    R.any(educationHasUnreadComment),
    R.values
  )(candidateProfile.education);

  return isCriteriaWithUnreadComment || isEducationWithUnreadComment;
};

export const getCardStatus = (
  cardData: BaseCandidateData
): CriteriaInterviewStatus | SearchCriteriaMatchStatusEnum => {
  if (cardData.status === SearchCriteriaMatchStatusEnum.OPENED) {
    return hasComments(cardData)
      ? CriteriaInterviewStatus.ANSWERED
      : CriteriaInterviewStatus.ASKED;
  }
  return cardData.status;
};

/**
 * According to design, the card as a whole can be 'answered', so we check
 * whether the card has any comments.
 */
export const getCardItemStatus = (
  item: { status: SearchCriteriaMatchStatusEnum },
  cardData: BaseCandidateData
): CriteriaInterviewStatus | SearchCriteriaMatchStatusEnum => {
  if (item.status === SearchCriteriaMatchStatusEnum.OPENED) {
    return hasComments(cardData)
      ? CriteriaInterviewStatus.ANSWERED
      : CriteriaInterviewStatus.ASKED;
  }
  return item.status;
};
