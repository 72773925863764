import { useEffect, Dispatch } from 'react';
import { toast } from '@air/third-party/toast';
import querystring from 'query-string';
import R from '@air/third-party/ramda';
import {
  ACCESS_TOKEN,
  EXPIRES_AT,
  localStore,
  REFRESH_TOKEN,
  LINK_TOKEN,
  ACCESS_TOKEN_ORIGIN,
  clearTokenStorage,
} from '@air/domain/WebStorage/webStorage';
import * as Auth from '@air/utils/auth';
import { LoginResponse } from '@air/api';
import { createRefreshTokenTask } from '@air/utils/api';
import { ERROR_USER_PROFILE } from '@air/constants/phrases';
import { setUserMeta } from '@air/utils/sentry';
import { ActionType } from '@air/domain/Common/ActionType';
import { AuthReducerAction } from '@air/utils/auth/authReducer';
import { RESET_PASSWORD_ROUTE } from 'customer-portal/src/constants/urls';
import { FORBIDDEN } from '@air/constants/httpCodes';

const loginQueryParams = [
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  EXPIRES_AT,
  /*
    Link token is provided in the emails to allow customers
    and applicant to log in and reset their passwords.
   */
  LINK_TOKEN,
];

export function getUserProfileAction<T>(
  profileUrl: string,
  dispatch: Dispatch<AuthReducerAction<T>>
) {
  return Auth.getUserProfile(profileUrl).fork(
    (rej) => {
      /*
        If autorization request was rejected with 403 code,
        we probably have incorrect token stored in the local storage,
        and we need to clear the storage.
      */
      if (rej.status === FORBIDDEN) {
        clearTokenStorage();
      }
      toast.error(ERROR_USER_PROFILE);
    },
    (userProfile: any) => {
      setUserMeta(userProfile);
      dispatch({
        type: ActionType.GotUserProfile,
        payload: userProfile,
      });
    }
  );
}

async function autologinAction(profileUrl: string, dispatch: any) {
  const locationSearch = querystring.parse(window.location.search);
  /* Set regularLogin to FALSE when creating a new password to make sure the users will see intro guide
    BE calculates first login logic depending on this flag
    This case should be ignored so that the next /current request is considered as the first login
    only for creating a password
    When resetting a password (not creating a new one for a new customer) the flag does not matter
  */
  const regularLogin = window.location.pathname !== RESET_PASSWORD_ROUTE;

  if (R.isEmpty(locationSearch) && !localStore.getItem(ACCESS_TOKEN)) {
    dispatch({ type: ActionType.InvalidToken });
  }
  const receivedLoginParams = R.pick<querystring.ParsedQuery, string>(
    loginQueryParams,
    locationSearch
  );
  if (receivedLoginParams.linkToken) {
    const { linkToken } = receivedLoginParams;
    const params = {
      linkToken: linkToken as string,
      regularLogin,
    };

    await Auth.getApplicantProfile(params).fork(
      () => {
        dispatch({ type: ActionType.InvalidToken });
      },
      (res: LoginResponse) => {
        receivedLoginParams.accessToken = res.accessToken;
        receivedLoginParams.refreshToken = res.refreshToken;
        receivedLoginParams.expiresAt = `${res.expiresAt}`;
        /*
          Setting up origin is required for a scenario, when
          user hasn't set up the password yet, because in this case
          we need to redirect the user to the 1st step of signup flow –
          Set Password.
        */
        localStore.setItem(ACCESS_TOKEN_ORIGIN, LINK_TOKEN);
      }
    );
  }

  if (
    receivedLoginParams.accessToken &&
    receivedLoginParams.refreshToken &&
    receivedLoginParams.expiresAt
  ) {
    localStore.setItem(ACCESS_TOKEN, receivedLoginParams.accessToken);
    localStore.setItem(REFRESH_TOKEN, receivedLoginParams.refreshToken);
    localStore.setItem(EXPIRES_AT, receivedLoginParams.expiresAt);
  }

  const accessToken = localStore.getItem(ACCESS_TOKEN);
  const refreshToken = localStore.getItem(REFRESH_TOKEN);
  const expiresAt = localStore.getItem(EXPIRES_AT);

  if (accessToken && refreshToken && expiresAt) {
    const isExpired = new Date(expiresAt) < new Date();

    if (isExpired) {
      createRefreshTokenTask().fork(clearTokenStorage, () => {
        dispatch({ type: ActionType.LoginSuccess });
        getUserProfileAction(profileUrl, dispatch);
      });
    } else {
      dispatch({ type: ActionType.LoginSuccess });
      getUserProfileAction(profileUrl, dispatch);
    }
  } else {
    dispatch({ type: ActionType.LoginFailure });
  }
}

export default function <T>(
  userProfileUrl: string,
  dispatch: Dispatch<AuthReducerAction<T>>
) {
  useEffect(() => {
    autologinAction(userProfileUrl, dispatch);
  }, [userProfileUrl, dispatch]);
}
