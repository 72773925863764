import React from 'react';
import classNames from 'classnames';

import styles from './ProgressButton.css';
import buttonStyles from './Button.css';
import { UIText } from '@air/components';
import { Loader } from '@air/components/Loader/Loader';

export const ButtonText: React.FC<any> = ({ children }) => (
  <span className={styles.buttonText}>{children}</span>
);

type Props = {
  progress?: number;
  text?: string;
  isLoading?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const ProgressButton: React.FC<Props> = ({
  children,
  className = '',
  progress = 100,
  text,
  isLoading,
  ...props
}) => (
  <button
    type="button"
    {...props}
    style={{ '--progress-width': `${progress}%` } as React.CSSProperties}
    className={classNames(
      buttonStyles.button,
      className,
      buttonStyles.progressIndicator,
      buttonStyles.loaderFixedRight,
      {
        [buttonStyles.isLoading]: isLoading,
        [buttonStyles.progressComplete]: progress === 100,
      }
    )}
  >
    {text && <UIText>{text}</UIText>}
    {children}
    {isLoading ? (
      <Loader color="green" className={buttonStyles.loader} />
    ) : null}
  </button>
);
