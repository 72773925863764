import React from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import * as commonPhrases from '@air/constants/phrases';
import * as phrases from 'customer-portal/src/constants/phrases';
import {
  convertApproximateDateToString,
  convertDateToMonthName,
  convertEquivalentExperienceToString,
  getDateToLocaleString,
} from '@air/domain/Common/PeriodConverter';
import { PeriodAlertDetails, DateInfo, PeriodInfo } from '@air/api';
import { SvgIcon, TooltipWrapper } from '@air/components';

import styles from './Period.css';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';

export const Period: React.FC<{
  showMissingMonths?: boolean;
  showInOneLine?: boolean;
  presentDayText?: string;
  period: DateInfo;
  showMissingDate?: boolean;
  shouldHighlight?: boolean;
}> = ({
  presentDayText,
  showInOneLine = false,
  showMissingMonths = false,
  period,
  showMissingDate = false,
  shouldHighlight = false,
}) => {
  return (
    <div
      className={classNames(styles.warningCardStackItemDate, {
        [styles.showInOneLine]: showInOneLine,
      })}
    >
      {showMissingDate && R.isNil(period?.year) && R.isNil(period?.month) && (
        <div>{commonPhrases.MISSING_DATE}</div>
      )}
      {R.isNil(period?.year) && period?.month && (
        <div>
          <div>{getDateToLocaleString(period).toUpperCase()}</div>
          <div>{commonPhrases.MISSING_YEAR}</div>
        </div>
      )}
      {period?.year !== 0 && (
        <div
          className={classNames({
            [styles.warningMissingMonth]: !period?.month,
            [styles.hideMissingMonth]: !showMissingMonths && !period?.month,
            [styles.highlight]: shouldHighlight,
          })}
        >
          {convertDateToMonthName(period).toUpperCase()}
        </div>
      )}
      {period?.year !== 0 && !R.isNil(period?.year) && (
        <div className={classNames({ [styles.highlight]: shouldHighlight })}>
          {period.year}
        </div>
      )}
      {period?.year === 0 && (
        <div>{presentDayText ?? phrases.PERIOD_CURRENT_YEAR}</div>
      )}
    </div>
  );
};

export const PeriodGapsTitle: React.FC<{
  showMissingMonths?: boolean;
  showInOneLine?: boolean;
  presentDayText?: string;
  periods: PeriodAlertDetails;
  className?: string;
  showMissingDate?: boolean;
  highlightedFields?: string[];
}> = ({
  showInOneLine = false,
  showMissingMonths = false,
  periods,
  presentDayText,
  className = null,
  showMissingDate = false,
  highlightedFields,
}) => {
  if (!(periods.from || periods.to || showMissingMonths)) {
    return null;
  }
  const shouldHighlightFrom = highlightedFields?.includes('from');
  const shouldHighlightTo = highlightedFields?.includes('to');

  return (
    <div
      className={classNames(styles.warningCardStackItemPeriodGap, className)}
    >
      <Period
        shouldHighlight={shouldHighlightFrom}
        presentDayText={presentDayText}
        showMissingMonths={showMissingMonths}
        showInOneLine={showInOneLine}
        showMissingDate={showMissingDate}
        period={periods?.from}
      />
      <SvgIcon
        className={styles.warningCardPeriodArrowIcon}
        icon="thin-arrow"
        height="0.7em"
        width="2em"
      />
      <Period
        shouldHighlight={shouldHighlightTo}
        presentDayText={presentDayText}
        showMissingMonths={showMissingMonths}
        showInOneLine={showInOneLine}
        showMissingDate={showMissingDate}
        period={periods?.to}
      />
    </div>
  );
};

export enum ApproximatePeriodType {
  criteria = 1,
  warning,
  workhistory,
}

export const ApproximateExperiencePeriod: React.FC<{
  experience: PeriodInfo;
  periodType: ApproximatePeriodType;
}> = ({ experience, periodType = ApproximatePeriodType.criteria }) => {
  const experienceText = convertApproximateDateToString(experience);
  if (!experience.approx) {
    return <>{experienceText}</>;
  }
  const tooltipText = commonPhrases.getApproximateExperienceTooltipText(
    periodType === ApproximatePeriodType.criteria
      ? commonPhrases.PERIOD_TYPE_EXPERIENCE
      : commonPhrases.PERIOD_TYPE_DURATION
  );

  return (
    <TooltipWrapper
      flexGrow={false}
      enabled={experience.approx}
      tooltip={tooltipText}
      delayShow={TOOLTIP_DELAY_TIME_LONG}
    >
      <span className={styles.periodInfo}>{experienceText}</span>
    </TooltipWrapper>
  );
};

export const ApproximateEquivalentExperience: React.FC<{
  experience: {
    equivalentYears?: number;
    equivalentApprox?: boolean;
  };
}> = ({ experience }) => {
  const experienceText = convertEquivalentExperienceToString(experience);
  if (!experience.equivalentApprox) {
    return <>{experienceText}</>;
  }

  const tooltipText = commonPhrases.getApproximateExperienceTooltipText(
    commonPhrases.PERIOD_TYPE_EXPERIENCE
  );

  return (
    <TooltipWrapper
      flexGrow={false}
      enabled={experience.equivalentApprox}
      tooltip={tooltipText}
      delayShow={TOOLTIP_DELAY_TIME_LONG}
    >
      <span className={styles.periodInfo}>{experienceText}</span>
    </TooltipWrapper>
  );
};
