import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import { parseErrorJson } from '@air/utils/api';

export const sendMessage = (data: FormData) => {
  return Http.post(urls.CONTACT_US_API)
    .bind(Http.withFormMultipartBody(data))
    .toTask()
    .chainError(parseErrorJson);
};
