import {
  EmailTemplateResponse,
  EmailTemplateShortResponse,
  JobEmailShortResponse,
  RoleName,
  UserInfoVO,
} from '@air/api';

export const hasAdminRole = (userStatus: RoleName) =>
  userStatus === RoleName.CUSTOMERADMIN;

export const isCreator = (customerId: number, creator: UserInfoVO | null) => {
  /*
    Default template does not have a creator.
  */
  if (!creator) return false;

  return customerId === creator.id;
};

export const updateItemById = (
  items: EmailTemplateShortResponse[] | JobEmailShortResponse[],
  id: string,
  updatePayload: EmailTemplateResponse | JobEmailShortResponse
) => {
  const result = [...items];

  return result.map((item) => {
    if (item.id === id) {
      return updatePayload;
    }
    return item;
  });
};
