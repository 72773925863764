/*
  This component is a custom wrapper for react-popper Popper,
  it adds functionality to capture clicks outside of Popper.

  It has same API as Popper, with 2 additional props,
  so don't forget to check react-popper documentation.
*/
import React from 'react';
import { identity } from 'ramda';
import { Popper, PopperProps } from 'react-popper';
import { useOutsideClick } from '@air/utils/hooks';
import ReactDOM from 'react-dom';

type PopupPropsT = {
  onOutsideClick?: (e: MouseEvent) => void;
  useCapture?: boolean;
} & PopperProps;
export const Popup: React.FC<PopupPropsT> = ({
  children,
  onOutsideClick = identity,
  useCapture,
  ...popperProps
}) => {
  const [outsideClickRef] = useOutsideClick(onOutsideClick, {
    useCapture: true,
  });

  return ReactDOM.createPortal(
    <div ref={outsideClickRef}>
      <Popper {...popperProps}>{children}</Popper>
    </div>,
    document.body
  );
};
