import React, {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import R from '@air/third-party/ramda';

import {
  AlertStatusEnum,
  ApiErrorResponse,
  CandidateSearchProfileStatus,
  NoteResponse,
  PatchCandidateSearchProfileRequest,
  ProfileSort,
  SearchCriteriaMatchStatusEnum,
} from '@air/api';
import { Task } from '@air/utils/fp';
import { getErrorDescription } from '@air/utils/errorHandling';
import {
  CandidateProfileUIT,
  CandidateSearchProfileStatusEnum,
  EnrichmentStatus,
  isInManual,
  mapCandidateSearchProfileV2ToUIProfile,
  mapRequisitionToCandidateProfile,
  sortByScore,
  sortCandidatesBySortType,
  undoStatusesMap,
} from 'domain/CandidateData';
import { toast } from '@air/third-party/toast';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';
import * as notesApi from '@air/domain/InterviewNotes/notesApi';
import * as candidateApi from 'domain/CandidateData/candidateApi';
import { RequisitionFile } from 'context';
import {
  CandidateLineupData,
  mapCandidateProfileToTableData,
} from 'domain/CandidateData/CandidateLineupData';
import { LineupTabs } from '@air/constants/tabs';

import {
  CandidatesResponseUIT,
  fetchCandidateByMainIdTask,
  fetchCandidateTask,
  requestCandidates,
} from 'domain/CandidateData/candidateApiTasks';
import { useKanbanContext } from 'providers/KanbanProvider';
import { kanbanSelectors } from 'selectors';
import { createContext, useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import { DEFAULT_MAX_FETCH_SIZE } from '@air/constants/app';
import { subscribe } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';

/**
 * CandidateProfileProvider.
 *
 * CandidateProfileContext is consumed in InterviewSection and ClosedSection,
 * Contains lists of candidates profiles and methods to update them.
 *
 * Its internal data storage is split into three parts: active, passive, matchMiner
 * which contains info for 3 tabs
 * and also currentCandidateProfile containing currently opened profile
 * Each tab has the following structure
 * 1. state[tab].candidates
 * 2. state[tab].statuses
 *
 * currentCandidateProfile stores data related to candidate profile tab (when it's opened)
 * its data type is CandidateProfileUIT
 *
 * candidates stores data used in lineup, currently its datatype is CandidateProfileUIT[],
 * will be changed into lightweight model after backend refactoring
 */

/**
 * typings:
 */
export type CandidatesCountersByStatus = {
  [key in CandidateSearchProfileStatus]: number;
};
type ExtendedCandidateNoteResponse = NoteResponse & { candidateId: string };

export type LoadAllCandidatesParamsT = {
  searchId: number;
  size?: number;
  status?: CandidateSearchProfileStatusEnum[];
  filter?: string;
  tab?: string;
  sort?: ProfileSort;
  sortId?: number;
};

let loadAllCandidatesParams: {
  sort: { [key: string]: ProfileSort };
  lastFilterName: string;
  currentSearchId: number;
} = {
  lastFilterName: '',
  sort: {},
  currentSearchId: null,
};

const resetSortType = () => {
  loadAllCandidatesParams = Object.assign(loadAllCandidatesParams, {
    sort: {},
  });
};

subscribe(APP_EVENTS.LOGOUT, resetSortType);

export type CandidateProfileContextT = {
  active: {
    candidates: CandidateLineupData[];
    statusesCount: CandidatesCountersByStatus;
  };
  passive: {
    candidates: CandidateLineupData[];
    statusesCount: CandidatesCountersByStatus;
  };
  matchMiner: {
    candidates: CandidateLineupData[];
    statusesCount: CandidatesCountersByStatus;
  };
  matchScout: {
    candidates: CandidateLineupData[];
    statusesCount: CandidatesCountersByStatus;
  };
  currentCandidateProfile: CandidateProfileUIT;
  currentCandidateProfileNotes: ExtendedCandidateNoteResponse[];
  loadAllCandidatesParams: typeof loadAllCandidatesParams;
  methods: {
    // Interview Section
    loadAllCandidates: ({
      searchId,
      status,
      size,
      filter,
      tab,
      sort,
      sortId,
    }: LoadAllCandidatesParamsT) => void;
    updateCandidatesForStatus: ({
      searchId,
      status,
      size,
      filter,
      tab,
    }: {
      searchId: number;
      status: CandidateSearchProfileStatusEnum[];
      size: number;
      filter?: string;
      tab: LineupTabs;
    }) => void;
    changeCandidateStatus: ({
      searchId,
      candidateId,
      candidateCurrentStatus,
      candidateNextStatus,
    }: {
      searchId: number;
      candidateId: string;
      candidateCurrentStatus: CandidateSearchProfileStatus;
      candidateNextStatus: CandidateSearchProfileStatus;
    }) => Promise<void>;
    moveCandidateToAppliedOrPassive: (
      searchId: number,
      candidateId: string,
      isMoveToPassive: boolean
    ) => Promise<boolean>;
    // SSE handler:
    handleCandidateStatusUpdate: (params: {
      searchId: number;
      ids: string[];
      filter: string;
      tab: LineupTabs;
    }) => Promise<void>;

    // CandidateProfileUpdates:
    markNoteAsRead: (
      searchId: number,
      profileId: string,
      interviewData: InterviewData,
      tab: LineupTabs
    ) => void;
    overrideMatchingCardStatus: ({
      searchId,
      profileId,
      cardRefId,
      newStatus,
      tab,
    }: {
      searchId: number;
      profileId: string;
      cardRefId: string;
      newStatus: SearchCriteriaMatchStatusEnum;
      tab: LineupTabs;
    }) => void;
    changeRedFlagStatus: ({
      searchId,
      profileId,
      redFlagRefId,
      newStatus,
      tab,
    }: {
      searchId: number;
      profileId: string;
      redFlagRefId: string;
      newStatus: AlertStatusEnum;
      tab: LineupTabs;
    }) => void;
    enrichCandidateProfile: (
      searchId: number,
      profileId: string
    ) => Promise<void>;
    fetchAndUpdateCandidateInState: (
      searchId: number,
      profileId: string
    ) => void;
    updateCandidateProfileNote: (
      profileId: CandidateProfileUIT['id'],
      noteRefId?: string,
      noteText?: string
    ) => void;
    updateCandidateDetails: (
      searchId: number,
      profileId: string,
      updatedCandidateDetails: PatchCandidateSearchProfileRequest,
      tab: LineupTabs
    ) => void;
    dropAllCandidates: () => void;
    dropCandidatesByType: (branch: CandidatesListBranches) => void;
    removeCandidatesFromCandidatesList: (
      profileIds: string[],
      tab: LineupTabs
    ) => void;

    fetchCandidateProfileById: (
      searchId: number | string,
      profileId: string
    ) => void;

    fetchCandidateProfileByMainProfileId: (
      dataSourceId: number | string,
      jobDescriptionId: string,
      mainProfileId: string
    ) => CandidateProfileUIT;

    openRequisitionAsProfile: (
      file: RequisitionFile,
      options: { isSearchPaused: boolean; atsId: number }
    ) => void;
    clearCandidateProfile: () => void;
  };
};

type CandidateProfileState = {
  candidates: CandidateLineupData[];
  statusesCount: CandidatesCountersByStatus;
};

type CandidateProfileStates = {
  active: CandidateProfileState;
  passive: CandidateProfileState;
  matchMiner: CandidateProfileState;
  matchScout: CandidateProfileState;
  currentCandidateProfile: CandidateProfileUIT;
  currentCandidateProfileNotes: ExtendedCandidateNoteResponse[];
};

export enum CandidatesListBranches {
  Active = 'active',
  Passive = 'passive',
  MatchMiner = 'matchMiner',
  MatchScout = 'matchScout',
}

enum ActionType {
  // load list of candidates and replace current candidates list
  FETCH_LIST = 'fetch_list',
  // load profiles and update current candidates list
  UPDATE_LIST = 'update_list',
  CLEAR_LIST = 'clear_list',
  CLEAR_CANDIDATES_BY_TYPE = 'clear_candidates_by_type',
  PATCH_CANDIDATE_LIST = 'patch_candidate_list',
  UPDATE_STATUS = 'update_status',
  REMOVE_PROFILES = 'remove_profiles',
  /* update candidate profile notes */
  UPDATE_CANDIDATE_NOTES = 'update_notes',
  /**
   * used to update current candidate profile without fetch
   */
  PATCH_CANDIDATE_PROFILE = 'patch_candidate_profile',
  /**
   * used after fetch of candidate profile, to set current candidate profile
   */
  FETCH_CANDIDATE_PROFILE = 'fetch_candidate_profile',
  /**
   * used after fetch of candidate profile, to update current candidate profile
   */
  UPDATE_CANDIDATE_PROFILE = 'update_candidate_profile',
  CLEAR_CANDIDATE_PROFILE = 'clear_candidate_profile',
}

type FetchListAction = {
  type: ActionType.FETCH_LIST;
  tab: LineupTabs;
  payload: CandidatesResponseUIT;
  filterName: string;
};

type UpdateListAction = {
  type: ActionType.UPDATE_LIST;
  tab: LineupTabs;
  payload: CandidatesResponseUIT;
};

type PatchListAction = {
  type: ActionType.PATCH_CANDIDATE_LIST;
  tab: LineupTabs;
  payload: {
    profileId: string;
    candidate: CandidateProfileUIT;
  };
};

type ClearListAction = {
  type: ActionType.CLEAR_LIST;
};

type ClearCandidatesByTypeAction = {
  type: ActionType.CLEAR_CANDIDATES_BY_TYPE;
  payload: {
    branch: CandidatesListBranches;
  };
};

type UpdateCandidateNotesAction = {
  type: ActionType.UPDATE_CANDIDATE_NOTES;
  payload: {
    currentCandidateProfileNotes: ExtendedCandidateNoteResponse[];
  };
};

type PatchCandidateProfileAction = {
  type: ActionType.PATCH_CANDIDATE_PROFILE;
  payload: {
    profileId: string;
    candidateData: Partial<CandidateProfileUIT>;
  };
};

type RemoveProfilesAction = {
  type: ActionType.REMOVE_PROFILES;
  tab: LineupTabs;
  payload: {
    profileIds: string[];
  };
};

type UpdateCandidateProfileAction = {
  type: ActionType.UPDATE_CANDIDATE_PROFILE;
  payload: {
    profileId: string;
    candidate: CandidateProfileUIT;
  };
};

type FetchCandidateProfileAction = {
  type: ActionType.FETCH_CANDIDATE_PROFILE;
  payload: {
    profileId: string;
    candidate: CandidateProfileUIT;
  };
};
type ClearCandidateProfileAction = {
  type: ActionType.CLEAR_CANDIDATE_PROFILE;
};

type CandidateProfileReducerAction =
  | FetchListAction
  | UpdateListAction
  | ClearListAction
  | ClearCandidatesByTypeAction
  | PatchListAction
  | PatchCandidateProfileAction
  | RemoveProfilesAction
  | UpdateCandidateProfileAction
  | FetchCandidateProfileAction
  | ClearCandidateProfileAction
  | UpdateCandidateNotesAction;

const shouldFetchPassiveCandidates = (tab: LineupTabs) =>
  [LineupTabs.MatchScout, LineupTabs.MatchMiner, LineupTabs.Passive].includes(
    tab
  );

/**
 * Context declaration
 */
export const CandidateProfileContext = createContext<CandidateProfileContextT>({
  active: {
    candidates: [],
    statusesCount: {} as CandidatesCountersByStatus,
  },
  passive: {
    candidates: [],
    statusesCount: {} as CandidatesCountersByStatus,
  },
  matchMiner: {
    candidates: [],
    statusesCount: {} as CandidatesCountersByStatus,
  },
  matchScout: {
    candidates: [],
    statusesCount: {} as CandidatesCountersByStatus,
  },
  loadAllCandidatesParams: loadAllCandidatesParams,
  currentCandidateProfile: null,
  currentCandidateProfileNotes: null,
  methods: {
    loadAllCandidates: null,
    updateCandidatesForStatus: null,
    changeCandidateStatus: null,
    moveCandidateToAppliedOrPassive: null,
    handleCandidateStatusUpdate: null,
    markNoteAsRead: null,
    overrideMatchingCardStatus: null,
    changeRedFlagStatus: null,
    enrichCandidateProfile: null,
    fetchCandidateProfileByMainProfileId: null,
    fetchAndUpdateCandidateInState: null,
    updateCandidateProfileNote: null,
    updateCandidateDetails: null,
    dropAllCandidates: null,
    dropCandidatesByType: null,
    removeCandidatesFromCandidatesList: null,
    fetchCandidateProfileById: null,
    openRequisitionAsProfile: null,
    clearCandidateProfile: null,
  },
});

CandidateProfileContext.displayName = 'CandidateProfileContext';

export const cleanUpCandidates = (state: CandidateProfileState) => ({
  ...state,
  candidates: [] as CandidateLineupData[],
  statusesCount: {} as CandidatesCountersByStatus,
});

// First we check if updatedCandidates are in list of current search candidates
// if so, we remove these items from updatedCandidates and replace them in existing candidates,
// otherwise we add them on top of candidates list
// Then sorting rules are applied for updated candidates
//   (which appear when user adds new applicants, or start processing)
export const updateCandidates = (
  updatedCandidates: CandidateLineupData[],
  candidates: CandidateLineupData[]
): CandidateLineupData[] => {
  const existingCandidates = candidates.map((candidate) => {
    const updatedIdx = R.findIndex(R.propEq('id', candidate.id))(
      updatedCandidates
    );

    if (updatedIdx >= 0) {
      const updated = updatedCandidates[updatedIdx];
      updatedCandidates.splice(updatedIdx, 1);
      return updated;
    }
    return candidate;
  });

  if (updatedCandidates.length > 0) {
    return sortByScore([...updatedCandidates, ...existingCandidates]);
  } else {
    return R.sort(
      (candidate1, candidate2) =>
        sortCandidatesBySortType(
          candidate1,
          candidate2,
          loadAllCandidatesParams.sort[loadAllCandidatesParams.currentSearchId]
        ),
      existingCandidates
    );
  }
};

export const removeCandidates = (
  profileIds: string[],
  candidates: CandidateLineupData[]
) => {
  return candidates.filter((candidate) => {
    return !profileIds.includes(candidate.id);
  });
};

export function CandidateProfileReducer(
  state: CandidateProfileStates,
  action: CandidateProfileReducerAction
): CandidateProfileStates {
  if (action.type === ActionType.CLEAR_LIST) {
    return {
      ...state,
      active: cleanUpCandidates(state.active),
      passive: cleanUpCandidates(state.passive),
      matchMiner: cleanUpCandidates(state.matchMiner),
      matchScout: cleanUpCandidates(state.matchScout),
    };
  }

  if (action.type === ActionType.CLEAR_CANDIDATES_BY_TYPE) {
    return {
      ...state,
      [action.payload.branch]: cleanUpCandidates(state[action.payload.branch]),
    };
  }

  if (action.type === ActionType.FETCH_LIST) {
    const { candidates } = action.payload;

    // Check (lastFilterName !== filter) to prevent race condition
    const { lastFilterName } = loadAllCandidatesParams;

    if (lastFilterName !== action.filterName) {
      return state;
    }

    return {
      ...state,
      [action.tab]: {
        ...state[action.tab],
        candidates,
        statusesCount: {
          ...state[action.tab].statusesCount,
          ...action.payload.statusesCount,
        },
      },
    };
  }

  if (action.type === ActionType.UPDATE_LIST) {
    return {
      ...state,
      [action.tab]: {
        ...state[action.tab],
        candidates: updateCandidates(
          action.payload.candidates,
          state[action.tab].candidates
        ),
        statusesCount: {
          ...state[action.tab].statusesCount,
          ...action.payload.statusesCount,
        },
      },
    };
  }

  if (action.type === ActionType.PATCH_CANDIDATE_LIST) {
    const { profileId, candidate } = action.payload;

    const idx = R.findIndex(
      R.propEq('id', profileId),
      state[action.tab].candidates
    );
    const candidateLens = R.lensIndex(idx);

    const updatedCandidates = R.set(
      candidateLens,
      mapCandidateProfileToTableData(candidate),
      state[action.tab].candidates
    );

    return {
      ...state,
      [action.tab]: {
        ...state[action.tab],
        candidates: updatedCandidates,
      },
    };
  }

  if (action.type === ActionType.FETCH_CANDIDATE_PROFILE) {
    const { candidate } = action.payload;

    return {
      ...state,
      currentCandidateProfile: candidate,
    };
  }

  if (action.type === ActionType.UPDATE_CANDIDATE_PROFILE) {
    const { profileId, candidate } = action.payload;

    if (
      state.currentCandidateProfile &&
      profileId !== state.currentCandidateProfile.id
    ) {
      return state;
    }

    return {
      ...state,
      currentCandidateProfile: candidate,
    };
  }

  if (action.type === ActionType.PATCH_CANDIDATE_PROFILE) {
    const { profileId, candidateData } = action.payload;

    if (
      state.currentCandidateProfile &&
      profileId !== state.currentCandidateProfile.id
    ) {
      return state;
    }

    return {
      ...state,
      currentCandidateProfile: {
        ...state.currentCandidateProfile,
        ...candidateData,
      },
    };
  }

  if (action.type === ActionType.CLEAR_CANDIDATE_PROFILE) {
    return {
      ...state,
      currentCandidateProfile: null,
    };
  }

  if (action.type === ActionType.REMOVE_PROFILES) {
    const updatedCandidates = removeCandidates(
      action.payload.profileIds,
      state[action.tab].candidates
    );

    return {
      ...state,
      [action.tab]: {
        ...state[action.tab],
        candidates: updatedCandidates,
      },
    };
  }

  if (action.type === ActionType.UPDATE_CANDIDATE_NOTES) {
    return {
      ...state,
      currentCandidateProfileNotes: action.payload.currentCandidateProfileNotes,
    };
  }

  return state;
}

/**
 * CandidateProfilesProvider
 */
export const CandidateProfileProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<
    Reducer<CandidateProfileStates, CandidateProfileReducerAction>
  >(CandidateProfileReducer, {
    currentCandidateProfile: null,
    currentCandidateProfileNotes: null,
    active: {
      candidates: [],
      statusesCount: {} as CandidatesCountersByStatus,
    },
    passive: {
      candidates: [],
      statusesCount: {} as CandidatesCountersByStatus,
    },
    matchMiner: {
      candidates: [],
      statusesCount: {} as CandidatesCountersByStatus,
    },
    matchScout: {
      candidates: [],
      statusesCount: {} as CandidatesCountersByStatus,
    },
  });

  const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);

  /**
   * clean up list each time currentSearch is updated
   */
  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_LIST });
  }, [currentSearchId]);

  const loadAllCandidates = useCallback(
    async ({
      searchId,
      status,
      size = DEFAULT_MAX_FETCH_SIZE,
      filter = '',
      tab = LineupTabs.Active,
      sort,
      sortId,
    }) => {
      const sortType =
        sort || loadAllCandidatesParams.sort[sortId] || ProfileSort.SCORE;

      loadAllCandidatesParams = Object.assign(
        loadAllCandidatesParams,
        { lastFilterName: filter },
        {
          sort: {
            ...loadAllCandidatesParams.sort,
            [sortId]: sortType,
          },
        }
      );

      if (size === 0) return;

      return requestCandidates({
        searchId,
        status,
        size,
        filter,
        sort: sortType,
        passive: shouldFetchPassiveCandidates(tab),
      }).fork(
        () => {},
        (response: CandidatesResponseUIT) => {
          dispatch({
            type: ActionType.FETCH_LIST,
            tab,
            payload: response,
            filterName: filter,
          });
        }
      );
    },
    []
  );

  /**
   * Handler, called when candidates with specific statuses should be
   * re-fetched
   */
  const updateCandidatesForStatus = useCallback(
    ({
      searchId,
      status,
      size,
      filter = '',
      tab,
    }): CandidateProfileContextT['methods']['updateCandidatesForStatus'] => {
      if (size === 0) return;

      return requestCandidates({
        searchId,
        status,
        size,
        filter,
        sort: loadAllCandidatesParams.sort[searchId] || ProfileSort.SCORE,
        passive: shouldFetchPassiveCandidates(tab),
      }).fork(
        () => {},
        (payload: CandidatesResponseUIT) => {
          dispatch({
            type: ActionType.UPDATE_LIST,
            tab,
            payload,
          });
        }
      );
    },
    []
  );

  // todo: change naming
  const handleCandidateStatusUpdate = useCallback(
    ({ searchId, ids, filter, tab }) => {
      if (!ids.length) return;

      return requestCandidates({
        searchId,
        profileIds: ids,
        filter,
        size: ids.length,
        passive: shouldFetchPassiveCandidates(tab),
      }).fork(
        () => {},
        (payload: CandidatesResponseUIT) => {
          dispatch({
            type: ActionType.UPDATE_LIST,
            tab,
            payload,
          });
        }
      );
    },
    []
  );

  const enrichCandidateProfile = useCallback(
    (searchId: number, profileId: string) => {
      return candidateApi
        .enrichCandidateSearchProfileById(searchId, profileId)
        .fork(
          (err: ApiErrorResponse) => {
            dispatch({
              type: ActionType.PATCH_CANDIDATE_PROFILE,
              payload: {
                profileId,
                candidateData: { enrichmentStatus: EnrichmentStatus.FAILED },
              },
            });

            const errorMsg = getErrorDescription(err);
            toast.error(errorMsg);
            throw new Error(errorMsg);
          },
          () => {
            dispatch({
              type: ActionType.PATCH_CANDIDATE_PROFILE,
              payload: {
                profileId,
                candidateData: { enrichmentStatus: EnrichmentStatus.STARTED },
              },
            });
          }
        );
    },
    []
  );

  const clearCandidateProfile = useCallback(() => {
    dispatch({
      type: ActionType.CLEAR_CANDIDATE_PROFILE,
    });
  }, [dispatch]);

  const fetchCandidateProfileById = useCallback(
    async (searchId: number | string, profileId: string) => {
      if (profileId !== state.currentCandidateProfile?.id) {
        await fetchCandidateTask(searchId, profileId).fork(
          R.identity,
          (candidate: CandidateProfileUIT) => {
            dispatch({
              type: ActionType.FETCH_CANDIDATE_PROFILE,
              payload: {
                profileId,
                candidate,
              },
            });
          }
        );
      }
    },
    [state.currentCandidateProfile]
  );

  // AR-8295
  // this method is used to fetch a candidate profile when we have
  // mainCandidateId in the url which comes only from the links from emails
  // mainCandidateId is never changed and profileId is changed every time search is modified
  const fetchCandidateProfileByMainProfileId = useCallback(
    (
      dataSourceId: number | string,
      jobDescriptionId: number | string,
      mainProfileId: string
    ) => {
      return fetchCandidateByMainIdTask(
        dataSourceId,
        jobDescriptionId,
        mainProfileId
      ).fork(R.identity, (candidate) => {
        dispatch({
          type: ActionType.FETCH_CANDIDATE_PROFILE,
          payload: {
            profileId: candidate.id,
            candidate,
          },
        });
        return candidate;
      });
    },
    []
  );

  const updateCandidateProfileById = useCallback(
    (searchId: number, profileId: string) => {
      return fetchCandidateTask(searchId, profileId).fork(
        R.identity,
        (candidate) => {
          dispatch({
            type: ActionType.UPDATE_CANDIDATE_PROFILE,
            payload: {
              profileId,
              candidate,
            },
          });
        }
      );
    },
    []
  );

  const markNoteAsRead = useCallback(
    (
      searchId: number,
      profileId: string,
      interviewData: InterviewData,
      tab: LineupTabs
    ) => {
      // if it's already read, we don't need to re-set the same value:
      if (interviewData.read) {
        return;
      }

      return notesApi
        .markCommentAsRead(
          interviewData.commentId,
          interviewData.refId,
          profileId
        )
        .chain(() => fetchCandidateTask(searchId, profileId))
        .fork(R.noop, (candidate) => {
          dispatch({
            type: ActionType.UPDATE_CANDIDATE_PROFILE,
            payload: {
              profileId,
              candidate,
            },
          });

          dispatch({
            type: ActionType.PATCH_CANDIDATE_LIST,
            tab,
            payload: {
              profileId,
              candidate,
            },
          });
        });
    },
    []
  );

  const changeCandidateStatus = useCallback(
    ({
      searchId,
      candidateId,
      candidateCurrentStatus,
      candidateNextStatus,
    }: {
      searchId: number;
      candidateId: string;
      candidateCurrentStatus: CandidateSearchProfileStatus;
      candidateNextStatus: CandidateSearchProfileStatus;
    }): Promise<void> => {
      const currentStatus = candidateCurrentStatus;
      let nextStatus = candidateNextStatus;

      if (!isInManual(candidateNextStatus)) {
        nextStatus = undoStatusesMap(candidateCurrentStatus);
      }

      if (currentStatus === nextStatus) {
        return;
      }

      return candidateApi
        .changeCandidateStatus(searchId, candidateId, nextStatus)
        .fork(
          () => {
            toast.error(sharedPhrases.GENERAL_ERROR_TRY_AGAIN);
          },
          () => {}
        );
    },
    []
  );

  const moveCandidateToAppliedOrPassive = useCallback(
    (searchId: number, candidateId: string, isMoveToPassive: boolean) => {
      return candidateApi
        .moveCandidateToApplied(searchId, candidateId, {
          passive: isMoveToPassive,
        })
        .fork(
          () => {
            toast.error(sharedPhrases.GENERAL_ERROR_TRY_AGAIN);
            return false;
          },
          () => {
            /*
            Before this request is sent, we perform optimistic update by
            adding candidate's id to hiddenCandidates array in SearchResultsView.

            We don't do anything on success, because unfortunately it may be too
            early to clean up the id from this array on success. Updated list
            can not yet finish refetching and this candidate will appear on
            the old list again.

            We can neglect potential overhead of storing some stale ids, and
            this list is cleared on every search / search tab change.
          * */
            return true;
          }
        );
    },
    []
  );

  const overrideMatchingCardStatus = useCallback(
    ({
      searchId,
      profileId,
      cardRefId,
      newStatus,
      tab,
    }): CandidateProfileContextT['methods']['overrideMatchingCardStatus'] => {
      return candidateApi
        .overrideCardStatus(searchId, profileId, cardRefId, newStatus)
        .chain(() => fetchCandidateTask(searchId, profileId))
        .fork(R.noop, (candidate) => {
          dispatch({
            type: ActionType.UPDATE_CANDIDATE_PROFILE,
            payload: {
              profileId,
              candidate,
            },
          });

          dispatch({
            type: ActionType.PATCH_CANDIDATE_LIST,
            tab,
            payload: {
              profileId: profileId,
              candidate,
            },
          });
        });
    },
    []
  );

  const changeRedFlagStatus = useCallback(
    ({
      searchId,
      profileId,
      redFlagRefId,
      newStatus,
      tab,
    }): CandidateProfileContextT['methods']['changeCandidateStatus'] => {
      return candidateApi
        .changeRedFlagStatus(searchId, profileId, redFlagRefId, newStatus)
        .chain(() => fetchCandidateTask(searchId, profileId))
        .fork(R.identity, (candidate) => {
          dispatch({
            type: ActionType.UPDATE_CANDIDATE_PROFILE,
            payload: {
              profileId,
              candidate,
            },
          });
          dispatch({
            type: ActionType.PATCH_CANDIDATE_LIST,
            tab,
            payload: {
              profileId: profileId,
              candidate,
            },
          });
        });
    },
    []
  );

  const updateCandidateProfileNote = useCallback(
    (
      candidateId: CandidateProfileUIT['id'],
      noteRefId?: string,
      noteText?: string
    ) => {
      const hasNote = !!noteRefId;

      let noteUpdateTask;
      if (noteText === undefined) {
        noteUpdateTask = candidateApi.getCandidateNote(candidateId);
      } else if (!hasNote && noteText) {
        noteUpdateTask = candidateApi.createCandidateNote(
          candidateId,
          noteText
        );
      } else if (hasNote && noteText) {
        noteUpdateTask = candidateApi.updateCandidateNote(
          candidateId,
          noteText,
          noteRefId
        );
      } else if (hasNote && !noteText) {
        noteUpdateTask = candidateApi.deleteCandidateNote(
          candidateId,
          noteRefId
        );
      }

      // !noteUpdateTask means there was no note, it was entered and removed immediately
      // so no changes need to be saved
      noteUpdateTask?.fork(R.noop, (res: NoteResponse[] | null) => {
        dispatch({
          type: ActionType.UPDATE_CANDIDATE_NOTES,
          payload: {
            currentCandidateProfileNotes: R.isNullOrEmpty(res)
              ? [{ candidateId }]
              : res.map((it) => ({ ...it, candidateId })),
          },
        });
      });
    },
    []
  );

  const updateCandidateDetails = useCallback(
    (
      searchId: number,
      profileId: string,
      updatedCandidateDetails: PatchCandidateSearchProfileRequest,
      tab: LineupTabs
    ): void => {
      // all the unchanged fields must be sent too
      // otherwise back-end treats empty/missing fields as deleted ones

      dispatch({
        type: ActionType.PATCH_CANDIDATE_PROFILE,
        payload: {
          profileId,
          candidateData: updatedCandidateDetails,
        },
      });

      candidateApi
        .updateCandidateSearchProfileById(
          searchId,
          profileId,
          updatedCandidateDetails
        )
        .chain((candidate) =>
          Task.of(mapCandidateSearchProfileV2ToUIProfile(candidate))
        )
        .fork(
          () => {
            toast.warning(phrases.CANDIDATE_PROFILE_UPDATE_ERROR);
          },
          (updatedCandidate) => {
            dispatch({
              type: ActionType.UPDATE_CANDIDATE_PROFILE,
              payload: {
                profileId: profileId,
                candidate: updatedCandidate,
              },
            });

            dispatch({
              type: ActionType.PATCH_CANDIDATE_LIST,
              tab,
              payload: {
                profileId: profileId,
                candidate: updatedCandidate,
              },
            });
          }
        );
    },
    []
  );

  const dropAllCandidates = useCallback(() => {
    dispatch({ type: ActionType.CLEAR_LIST });
  }, []);

  const dropCandidatesByType = useCallback((branch: CandidatesListBranches) => {
    dispatch({
      type: ActionType.CLEAR_CANDIDATES_BY_TYPE,
      payload: { branch },
    });
  }, []);

  const removeCandidatesFromCandidatesList = useCallback(
    (profileIds, tab: LineupTabs) => {
      dispatch({
        type: ActionType.REMOVE_PROFILES,
        tab,
        payload: { profileIds },
      });
    },
    []
  );

  const openRequisitionAsProfile = useCallback((requisition, options) => {
    const candidate = mapRequisitionToCandidateProfile(requisition, options);

    dispatch({
      type: ActionType.FETCH_CANDIDATE_PROFILE,
      payload: { profileId: candidate.id, candidate },
    });
  }, []);

  const contextValue = useMemo<CandidateProfileContextT>(
    () => ({
      active: state.active,
      passive: state.passive,
      matchMiner: state.matchMiner,
      matchScout: state.matchScout,
      currentCandidateProfileNotes: state.currentCandidateProfileNotes,
      currentCandidateProfile: state.currentCandidateProfile,
      loadAllCandidatesParams: loadAllCandidatesParams,
      methods: {
        loadAllCandidates,
        updateCandidatesForStatus,
        changeCandidateStatus,
        moveCandidateToAppliedOrPassive,
        handleCandidateStatusUpdate,
        markNoteAsRead,
        overrideMatchingCardStatus,
        changeRedFlagStatus,
        enrichCandidateProfile,
        fetchAndUpdateCandidateInState: updateCandidateProfileById,
        fetchCandidateProfileById,
        clearCandidateProfile,
        fetchCandidateProfileByMainProfileId,
        updateCandidateProfileNote,
        updateCandidateDetails,
        dropAllCandidates,
        dropCandidatesByType,
        removeCandidatesFromCandidatesList,
        openRequisitionAsProfile,
      },
    }),
    [
      state,
      loadAllCandidates,
      updateCandidatesForStatus,
      changeCandidateStatus,
      handleCandidateStatusUpdate,
      markNoteAsRead,
      overrideMatchingCardStatus,
      changeRedFlagStatus,
      enrichCandidateProfile,
      updateCandidateProfileNote,
      updateCandidateDetails,
      dropAllCandidates,
      dropCandidatesByType,
      removeCandidatesFromCandidatesList,
      clearCandidateProfile,
      fetchCandidateProfileById,
      fetchCandidateProfileByMainProfileId,
      updateCandidateProfileById,
      openRequisitionAsProfile,
      moveCandidateToAppliedOrPassive,
    ]
  );

  return (
    <CandidateProfileContext.Provider value={contextValue}>
      {children}
    </CandidateProfileContext.Provider>
  );
};

CandidateProfileProvider.displayName = 'CandidateProfileProvider';

export const useCandidateProfileContext = <Selected,>(
  selector: (state: CandidateProfileContextT) => Selected
) => {
  return useContextSelector(CandidateProfileContext, selector);
};

export const useCandidateProfileMethods = () => {
  return useEqualContextSelector(
    CandidateProfileContext,
    (state: CandidateProfileContextT) => state.methods,
    R.shallowEqualObjects
  );
};
