import React from 'react';
import * as phrases from 'constants/phrases';
import { Header, Paragraph } from '@air/components';

import styles from 'components/SearchResultsView/SearchResultsEmpty/SearchResultsEmpty.css';

export const SearchResultsNoCriteria: React.FC = () => {
  return (
    <div className={styles.noResultsWrapper}>
      <Header level={2} className={styles.title}>
        {phrases.MATCH_MINER_NO_CRITERIA_TITLE}
      </Header>
      <Paragraph className={styles.description}>
        {phrases.MATCH_MINER_NO_CRITERIA_DESCRIPTION}
      </Paragraph>
    </div>
  );
};
SearchResultsNoCriteria.displayName = 'SearchResultsNoCriteria';
