import React from 'react';
import classNames from 'classnames';

import { Header, Paragraph } from '@air/components';

import styles from './SectionPanel.css';

type Props = {
  title?: string;
  description?: string;
  className?: string;
};

export const SectionPanel: React.FC<Props> = ({
  title,
  description,
  className,
  children,
}) => {
  return (
    <div className={classNames(styles.sectionPanel, className)}>
      {title && (
        <Header level={3} className={styles.header}>
          {title}
        </Header>
      )}
      {description && (
        <Paragraph className={styles.description}>{description}</Paragraph>
      )}
      {children}
    </div>
  );
};
