import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import { useStateMachine } from '@air/hooks';
import R from '@air/third-party/ramda';
import {
  useForm,
  useFormContext,
  FormProvider,
  Controller,
} from 'react-hook-form';
import classNames from 'classnames';

import { Card, SvgIcon } from '@air/components';
import {
  UserCardStateConfig,
  UserCardState,
  UserCardActions,
} from 'components/SearchCriteriaCards/hooks/UserCardStateConfig';
import { useOutsideClick } from '@air/utils/hooks';
import {
  UserInfo,
  roleToRoleLabelMap,
  UpdatedUserInfo,
  isInactiveUser,
} from 'domain/UserManagement/User';
import { RoleName, InternalStatus } from '@air/api';
import { RoleSelect, ActivateUserButton } from './Common';
import styles from './UserCardStyles.css';

type UserCardLabelOptions = {
  isCurrent: boolean;
  role: RoleName;
  isInactive: boolean;
};

const getUserCardLabel = ({
  isCurrent,
  isInactive,
  role,
}: UserCardLabelOptions) => {
  const roleLabel = roleToRoleLabelMap[role];
  if (isCurrent) {
    return phrases.USER_CURRENT_ROLE(roleLabel);
  } else if (isInactive) {
    return `${phrases.USER_CARD_INACTIVE_LABEL} ${roleLabel}`;
  }
  return roleLabel;
};

export const UserCollapsedCard: React.FC<{
  isInactive?: boolean;
  showCardBackground?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  onActionButtonClick?: () => void;
  cardLabel: string;
  title: string;
  className?: string;
  status?: string;
}> = ({
  isInactive = false,
  showCardBackground = false,
  onClick = null,
  onMouseOver = null,
  onMouseLeave = null,
  onActionButtonClick = null,
  cardLabel,
  title,
  className = '',
  status,
}) => {
  return (
    <Card.ResizeableCardWrapper resizeable={false}>
      <Card.SearchCriteriaCardContainer
        className={classNames(
          styles.userCollapsedCard,
          {
            [styles.isInactive]: isInactive,
          },
          className
        )}
      >
        <Card.SearchCriteriaCardViewBackground
          isVisible={showCardBackground}
          actionButtonLabel={
            isInactive ? sharedPhrases.VIEW : sharedPhrases.MODIFY
          }
          onActionButtonClick={onActionButtonClick}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        >
          <Card.SearchCriteriaCardLabel text={cardLabel} />
          <Card.SearchCriteriaCardContent
            onClick={onClick}
            className={styles.userCollapsedCardContent}
          >
            <Card.Title
              flexGrow={false}
              title={title}
              className={styles.userCollapsedCardTitle}
            />
            {status && (
              <Card.Title
                flexGrow={false}
                title={status}
                className={styles.userCollapsedCardInvalidStatus}
              />
            )}
          </Card.SearchCriteriaCardContent>
        </Card.SearchCriteriaCardViewBackground>
      </Card.SearchCriteriaCardContainer>
    </Card.ResizeableCardWrapper>
  );
};
UserCollapsedCard.displayName = 'UserCollapsedCard';

enum PasswordResetState {
  untouched,
  reset,
  confirmed,
}

export const UserExpandedCard: React.FC<{
  userData: UserInfo;
  className?: string;
  onClose?: () => void;
  resetPassword?: (email: string) => void;
  onEditUser: (customerId: number) => void;
  isEditRestricted: boolean;
  isReadOnly?: boolean;
}> = ({
  userData,
  onClose = R.noop,
  onEditUser,
  resetPassword,
  className = '',
  isEditRestricted,
  isReadOnly,
}) => {
  const { id, isCurrent, email, role } = userData;

  const { getValues, control, formState, register } = useFormContext();

  const { status } = getValues();
  const [isUserInactive, setUserInactive] = useState<boolean>();

  const cardOptions: UserCardLabelOptions = {
    isCurrent,
    role,
    isInactive: isUserInactive,
  };
  const cardLabel = getUserCardLabel(cardOptions);

  useEffect(() => {
    setUserInactive(isInactiveUser(status));
  }, [status]);

  const save = useCallback(() => {
    if (formState.isDirty) {
      onEditUser(id);
    }
    onClose();
  }, [onEditUser, formState.isDirty, onClose, id]);

  const [passwordResetSwitchState, setPasswordResetSwitch] = useState(
    PasswordResetState.untouched
  );
  const onResetPassword = useCallback(async () => {
    if (passwordResetSwitchState === PasswordResetState.untouched) {
      setPasswordResetSwitch(PasswordResetState.reset);
      return;
    }

    if (passwordResetSwitchState === PasswordResetState.reset) {
      await resetPassword(email);
      setPasswordResetSwitch(PasswordResetState.confirmed);
    }
  }, [resetPassword, email, passwordResetSwitchState]);

  const getResetPasswordField = useCallback(
    (fieldState: PasswordResetState, isReadOnly?: boolean) => {
      let stateData: { label: string; icon: string; className?: string };
      switch (fieldState) {
        case PasswordResetState.untouched:
          stateData = {
            label: phrases.USER_CARD_RESET_PASSWORD,
            icon: 'reset-password-icon',
          };
          break;
        case PasswordResetState.reset:
          stateData = {
            label: phrases.USER_CARD_RESET_PASSWORD_CONFIRMATION,
            icon: 'reset-password-icon',
            className: styles.resetState,
          };
          break;
        case PasswordResetState.confirmed:
          stateData = {
            label: phrases.USER_CARD_RESET_PASSWORD_SUCCESS,
            icon: 'checkmark-green',
            className: styles.confirmedResetState,
          };
          break;
      }
      const { label, icon, className } = stateData;
      return (
        <div
          className={classNames(styles.field, styles.fieldAction, className, {
            [styles.disabledField]: isReadOnly,
          })}
          onClick={isReadOnly ? R.noop : onResetPassword}
        >
          <div className={styles.fieldIcon}>
            <SvgIcon icon={icon} className={styles.passwordIcon} />
          </div>
          <span>{label}</span>
        </div>
      );
    },
    [onResetPassword]
  );

  const [outsideClickRef] = useOutsideClick(save, {
    useCapture: true,
  });

  const isRoleDropdownDisabled = isCurrent || isUserInactive || isReadOnly;

  return (
    <Card.SearchCriteriaCardContainer
      className={classNames(
        styles.userExpandedCard,
        {
          [styles.isInactive]: isUserInactive || isReadOnly,
        },
        className
      )}
    >
      <Card.SearchCriteriaEditForm ref={outsideClickRef}>
        <Card.SearchCriteriaCardLabel
          isActive={!isUserInactive && !isReadOnly}
          text={cardLabel}
        />
        <Card.SearchCriteriaEditFormFields
          className={styles.userExpandedCardEditFormFields}
        >
          <form onSubmit={save}>
            <div className={classNames(styles.field)}>
              <label>{phrases.FIRST_NAME_LABEL}</label>
              <input
                type="text"
                className={styles.fieldInput}
                placeholder={phrases.INPUT_PLACEHOLDER}
                {...register('firstName')}
                readOnly={isEditRestricted || isReadOnly}
              />
            </div>
            <div className={classNames(styles.field)}>
              <label>{phrases.LAST_NAME_LABEL}</label>
              <input
                type="text"
                className={styles.fieldInput}
                placeholder={phrases.INPUT_PLACEHOLDER}
                {...register('lastName')}
                readOnly={isEditRestricted || isReadOnly}
              />
            </div>
            <div className={classNames(styles.field, styles.infoField)}>
              <label>{phrases.EMAIL_LABEL}</label>
              <Card.Title
                flexGrow={false}
                title={email}
                className={styles.emailField}
                tooltipClassName={styles.emailTooltip}
              />
            </div>
            <div
              className={classNames(styles.field, styles.generalRoleField, {
                [styles.disabled]: isRoleDropdownDisabled,
              })}
            >
              <Controller
                control={control}
                name="role"
                render={({ field: { value, onChange } }) => (
                  <RoleSelect
                    role={value}
                    isDisabled={isRoleDropdownDisabled}
                    updateRole={onChange}
                  />
                )}
              />
            </div>
            {/*
          <div className={classNames(styles.field, styles.atsRoleField)}>
            <div className={styles.icon}>
              <SvgIcon icon="user-icon" width="1.3em" height="1.4em" />
            </div>
            <dl>
              <dt>{phrases.USER_CARD_ATS_ROLE_LABEL}:</dt>
              <dd>{atsRole}</dd>
            </dl>
          </div>
          */}
            {!isUserInactive &&
              getResetPasswordField(passwordResetSwitchState, isReadOnly)}
            <Controller
              control={control}
              name="status"
              render={({ field: { value, onChange } }) => {
                /**
                 * the actual update of value happens after onChange,
                 * that's why this check is inverted, to reflect the future
                 * update of value
                 */
                const shouldBeInactive = !isInactiveUser(value);
                return (
                  <ActivateUserButton
                    disabled={isCurrent || isReadOnly}
                    isUserInactive={isInactiveUser(value)}
                    value={value}
                    onChange={() => {
                      const updatedStatus = isInactiveUser(value)
                        ? InternalStatus.ACTIVE
                        : InternalStatus.INACTIVE;
                      setUserInactive(shouldBeInactive);
                      onChange(updatedStatus);
                    }}
                  />
                );
              }}
            />
          </form>
        </Card.SearchCriteriaEditFormFields>
        <button
          type="button"
          className={styles.userExpandedCardActionButton}
          onClick={save}
        >
          {isUserInactive ? sharedPhrases.OK : sharedPhrases.CONFIRM}
        </button>
      </Card.SearchCriteriaEditForm>
    </Card.SearchCriteriaCardContainer>
  );
};
UserExpandedCard.displayName = 'UserExpandedCard';

export const UserCard: React.FC<{
  userData: UserInfo;
  className?: string;
  resetPassword: (email: string) => void;
  onEditUser: (customerId: number, userData: UpdatedUserInfo) => void;
  isEditRestricted?: boolean;
  isReadOnly?: boolean;
}> = ({
  userData,

  onEditUser,
  className = '',
  resetPassword,
  isEditRestricted = false,
  isReadOnly = false,
}) => {
  const defaultValues = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    role: userData.role,
    status: userData.status,
  };
  const methods = useForm<UpdatedUserInfo>({
    defaultValues,
    shouldUnregister: false,
  });
  const { getValues, setValue, handleSubmit, reset } = methods;

  useEffect(() => {
    /*
      `defaultValues` are cached, which makes it impossible to return
      changed value to initial one (react-hook-form doesn't register
      that field is dirty in such case, see AR-5040, AR-5041).
      so we have to reset default values each time when userData
      is updated.
    */
    reset(userData);
  }, [userData, reset]);

  const [cardState, dispatch] = useStateMachine(
    UserCardStateConfig,
    UserCardState.collapsed
  );

  const restoreValue = useCallback(
    (valueName: keyof UpdatedUserInfo, defaultValue: string) => {
      const formValues: UpdatedUserInfo = getValues();
      if (!formValues[valueName]) {
        setValue(valueName, defaultValue);
      }
    },
    [getValues, setValue]
  );

  const handleEditUser = useCallback(
    (customerId: number) => {
      restoreValue('firstName', userData.firstName);
      restoreValue('lastName', userData.lastName);
      handleSubmit((userData) => {
        return onEditUser(customerId, userData);
      })();
    },
    [
      handleSubmit,
      restoreValue,
      onEditUser,
      userData.firstName,
      userData.lastName,
    ]
  );

  const showCollapsedView = useCallback(() => {
    dispatch(UserCardActions.showCollapsed);
  }, [dispatch]);
  const showCollapsedHoveredView = useCallback(
    () => dispatch(UserCardActions.showCollapsedHovered),
    [dispatch]
  );

  const closeExpandedView = useCallback(() => {
    if (cardState.matches(UserCardState.expanded)) {
      showCollapsedView();
    }
  }, [showCollapsedView, cardState]);

  const showExpandedView = useCallback(
    () => dispatch(UserCardActions.showExpanded),
    [dispatch]
  );

  const cardLabel = getUserCardLabel(userData);
  const { firstName, lastName, status, email } = getValues();
  const cardTitle = useMemo(() => {
    return [firstName, lastName].filter(Boolean).join(' ') || email;
  }, [firstName, lastName, email]);

  const isUserInactive = isInactiveUser(status);

  return cardState.matches(UserCardState.expanded) ? (
    <FormProvider {...methods}>
      <UserExpandedCard
        userData={userData}
        onClose={closeExpandedView}
        resetPassword={resetPassword}
        className={className}
        onEditUser={handleEditUser}
        isEditRestricted={isEditRestricted}
        isReadOnly={isReadOnly}
      />
    </FormProvider>
  ) : (
    <UserCollapsedCard
      isInactive={isUserInactive || isReadOnly}
      cardLabel={cardLabel}
      title={cardTitle}
      showCardBackground={cardState.matches(UserCardState.collapsedHovered)}
      onClick={showExpandedView}
      onActionButtonClick={showExpandedView}
      onMouseOver={showCollapsedHoveredView}
      onMouseLeave={showCollapsedView}
      className={className}
    />
  );
};

UserCard.displayName = 'UserCard';
