import React from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { Button, RemoveButton } from 'components';

import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import { SearchCriteriaData, CardType } from 'domain/SearchCriteria';
import { draggableStackItemTypes } from 'components/Cards/cardsCommonCode';

import styles from './NewSearchToolbar.css';

/*
  Single criteria cards can not be dropped on remove button,
  they have own removal functionality via cross icon.
*/
const removableCardTypes = Object.values(DraggedSearchCriteriaCardType).filter(
  (cardType) => !draggableStackItemTypes.includes(cardType)
);

export const NewSearchToolbar: React.FC<any> = ({
  className,
  buttonClassName,
  addButtonsConfig,
  onAddButtonClick,
  onRemove,
  isReadOnly,
}: {
  className?: string;
  buttonClassName?: string;
  addButtonsConfig: {
    cardType: CardType;
    title: string;
    isDisabled: boolean;
    getDefaults: () => SearchCriteriaData;
  }[];
  onAddButtonClick: (data: SearchCriteriaData) => void;
  onRemove: (item: any) => void; // @todo fix this typing
  isReadOnly: boolean;
}) => {
  return (
    <div className={classNames(styles.searchFormActionBar, className)}>
      {addButtonsConfig.map((card, key) => (
        <Button
          className={buttonClassName}
          key={key}
          disabled={card.isDisabled || isReadOnly}
          onClick={
            isReadOnly ? R.noop : () => onAddButtonClick(card.getDefaults())
          }
          variant={Button.variants.CHIP}
        >
          <span>{card.title}</span>
        </Button>
      ))}
      {!isReadOnly && (
        <RemoveButton
          acceptTypes={removableCardTypes}
          onDrop={onRemove}
          className={styles.removeCardButton}
        />
      )}
    </div>
  );
};
NewSearchToolbar.displayName = 'NewSearchToolbar';
