import React, {
  ReactEventHandler,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';
import { useSearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';
import { Card, CardSize } from '@air/components/Card/Card';

import {
  SearchCardActions,
  SearchCardState,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';

import educationCommonStyles from 'components/Cards/educationCommonStyles.css';
import { useDroppableRef } from '@air/hooks';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import {
  CertificationCriteriaData,
  CompanyCriteriaData,
  RoleCriteriaData,
  SkillCriteriaData,
  BaseCriteriaData,
} from 'domain/SearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import {
  CriteriaDragObjectT,
  SingleCriteriaItemDragObject,
} from 'components/Cards/cardsCommonCode';
import { isCardReadOnly } from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import * as sharedPhrases from '@air/constants/phrases';
import { IndustryCriteriaData } from 'domain/SearchCriteria/IndustryCriteriaData';
import {
  getMainCard,
  getCardListItemTooltip,
} from 'domain/SearchCriteria/cardHelpers';
import { LocationCriteriaData } from 'domain/SearchCriteria/LocationCriteriaData';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

export type CriteriaCollapsedCardProps = {
  isReadOnly?: boolean;
  title: string;
  footerText?: string;
  secondFooterText?: string;
  cardLabel: string;
  titleLabel?: ReactNode;
  cardContainerRef?: React.Ref<any>;
  stackSize: number;
  stackItems: [any[], any[]];
  isDragging?: boolean;
  criteria: BaseCriteriaData;
  importance: SearchCriteriaImportanceEnum;
  onRemove: () => void;
  onRemoveStackItem: (value: string | number) => void;
  cardClasses: {
    [className: string]: string;
  };
  stackClasses?: string[];
  className?: string;
  defaultSize?: CardSize;
  renderStackItem: (item: any) => React.ReactElement; // TODO: define type;
  initialCardStatus?: InitialCardStatusEnum;
};

export const CriteriaCollapsedCard: React.FC<CriteriaCollapsedCardProps> = ({
  criteria,
  title,
  footerText = '',
  secondFooterText = '',
  cardLabel = '',
  titleLabel = '',
  cardContainerRef = null,
  stackSize = 0,
  stackItems = [],
  isDragging = false,
  importance,
  onRemove,
  onRemoveStackItem,
  cardClasses = {},
  stackClasses,
  className,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
  ...props
}) => {
  const {
    cardState,
    dispatch,
    states: { isStackOpened },
    handlers: {
      toggleStackHandler,
      openHoveredViewHandler,
      closeHoveredViewHandler,
      openEditFormHandler,
    },
  } = useSearchCardContext();

  /*
    AR-5501: If the collapsed card was hovered, put to edit mode, and then
    back to view mode faster than in 1.5s then delayed hovered handler
    is not cancelled, and card wrongfully turns to view.hovered state.
    To properly cancel delayed hovered state on collapsed card, we
    run delay-clearing callback if collapsed state component is
    unmounted (and replaced with edit state component).

    Deps array is left empty, because closeHoveredViewHandler callback
    is recreated on every delayed hover initialization, and having
    it as a dependency in useEffect leads to preliminary reset of
    hovered state.
  */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => closeHoveredViewHandler, []);

  const isReadOnly = isCardReadOnly(cardState);
  const [topStackItems = [], bottomStackItems = []] = stackItems;
  const {
    mainCardClass = '',
    wrapperClass = '',
    titleClass = '',
    footerClass = '',
    secondFooterClass = '',
  } = cardClasses;

  const defaultRenderStackItem = (item: any) => (
    <Card.SearchCriteriaStackItem
      key={item.key}
      label={item.label}
      className={item.className}
      labelClassName={item.labelClassName}
      onRemoveItem={
        isReadOnly
          ? null
          : (event: any) => item.onRemoveItem(event, onRemoveStackItem)
      }
    >
      {item.children}
    </Card.SearchCriteriaStackItem>
  );

  const renderStackItem: (item: any) => React.ReactElement =
    props.renderStackItem || defaultRenderStackItem;

  const openImportanceSectionHandler: ReactEventHandler = (event: any) => {
    event.stopPropagation();
    dispatch(SearchCardActions.openImportance);
  };

  const onStackClick = useCallback(
    () => toggleStackHandler(false),
    [toggleStackHandler]
  );

  const isCardHovered = cardState.matches(
    SearchCardState.viewBackgroundHovered
  );
  const isCardDroppable = cardState.matches(
    SearchCardState.viewBackgroundDroppable
  );

  const mainCard = criteria ? getMainCard<BaseCriteriaData>(criteria) : null;
  const getMainCardTooltip =
    criteria && mainCard
      ? getCardListItemTooltip.bind(null, criteria, mainCard)
      : R.identity;

  return (
    <Card.SearchCriteriaCardContainer
      ref={cardContainerRef}
      importance={importance}
      isDragging={isDragging && !isStackOpened}
      className={classNames(className, wrapperClass)}
    >
      <Card.SearchCriteriaCardViewBackground
        isVisible={isCardHovered}
        actionButtonLabel={sharedPhrases.MODIFY}
        onActionButtonClick={openEditFormHandler}
        onMouseLeave={closeHoveredViewHandler}
      >
        <Card.SearchCriteriaCardLabel
          onClick={() => toggleStackHandler(true)}
          text={cardLabel}
          showExplanationTooltip={stackSize > 0}
        />
        {!R.isEmpty(topStackItems) && (
          <Card.SearchCriteriaStack
            onClick={onStackClick}
            isClosed={!isStackOpened}
          >
            {R.reverse(topStackItems).map(renderStackItem)}
          </Card.SearchCriteriaStack>
        )}
        <Card.SearchCriteriaCardContent
          className={classNames(mainCardClass, {
            [cardCommonStyles.dropTarget]: isCardDroppable,
          })}
          isDragging={isDragging && isStackOpened}
          onMouseOver={openHoveredViewHandler}
          onClick={openEditFormHandler}
          onRemove={isReadOnly ? null : onRemove}
          shouldAnimateOnMount={
            initialCardStatus === InitialCardStatusEnum.IsNewView
          }
        >
          {titleLabel}
          <Card.Title
            resizeable
            title={title}
            getTooltipText={getMainCardTooltip}
            className={classNames(titleClass)}
            tooltipProps={cardsConfig[
              criteria.cardType
            ]?.getMainCardTooltipProps?.(mainCard, criteria)}
          />
          {!!footerText && (
            <Card.Footer
              className={classNames(footerClass)}
              text={footerText}
            />
          )}
          {!!secondFooterText && (
            <Card.Footer
              className={classNames(secondFooterClass)}
              text={secondFooterText}
            />
          )}
          <Card.SearchCriteriaImportanceIndicator
            className={cardCommonStyles.importanceSwitch}
            importance={importance}
            onClick={isReadOnly ? null : openImportanceSectionHandler}
          />
        </Card.SearchCriteriaCardContent>
        {!R.isEmpty(bottomStackItems) && (
          <Card.SearchCriteriaStack
            onClick={() => toggleStackHandler(false)}
            isClosed={!isStackOpened}
            position={Card.SearchCriteriaStack.position.bottom}
            className={educationCommonStyles.educationBottomStack}
          >
            {bottomStackItems.map(renderStackItem)}
          </Card.SearchCriteriaStack>
        )}
        {stackSize > 0 && (
          <Card.SearchCriteriaStackToggle
            isHidden={isStackOpened || isCardHovered}
            onClick={() => toggleStackHandler(true)}
            stackSize={stackSize}
            stackClasses={stackClasses}
          />
        )}
      </Card.SearchCriteriaCardViewBackground>
    </Card.SearchCriteriaCardContainer>
  );
};

export type DraggableCriteriaTypes =
  | SkillCriteriaData
  | CertificationCriteriaData
  | RoleCriteriaData
  | IndustryCriteriaData
  | LocationCriteriaData
  | CompanyCriteriaData;

export const CriteriaDraggableCollapsedCard: React.FC<
  {
    draggableStackItem: typeof React.Component;
    acceptTypes: DraggedSearchCriteriaCardType[];
    canDropCallback: (
      droppedItem:
        | CriteriaDragObjectT<DraggableCriteriaTypes>
        | SingleCriteriaItemDragObject<DraggableCriteriaTypes>
    ) => boolean;
    dropCallback: (
      droppedItem:
        | CriteriaDragObjectT<DraggableCriteriaTypes>
        | SingleCriteriaItemDragObject<DraggableCriteriaTypes>
    ) => void;
  } & CriteriaCollapsedCardProps
> = ({
  onRemoveStackItem,
  draggableStackItem: DraggableStackItem,
  acceptTypes,
  canDropCallback,
  dropCallback,
  criteria,
  initialCardStatus = InitialCardStatusEnum.ExistingCard,
  ...props
}) => {
  const {
    handlers: { toggleDroppableStateHandler },
    cardState,
  } = useSearchCardContext();
  /*
      DnD functionality
    */
  const [cardContentRef, { canDrop }] = useDroppableRef({
    accept: acceptTypes,
    canDrop: canDropCallback,
    collect: (monitor: any) => ({
      canDrop: monitor.canDrop(),
    }),
    drop: dropCallback,
  });

  useEffect(() => {
    toggleDroppableStateHandler(canDrop);
  }, [canDrop, toggleDroppableStateHandler]);
  const isReadOnly = isCardReadOnly(cardState);

  const renderStackItem = useCallback(
    (item: any) => {
      return (
        <DraggableStackItem
          key={item.key}
          label={item.label}
          card={item.card}
          title={item.title}
          className={item.className}
          id={item.id}
          searchOrder={item.searchOrder}
          onRemoveItem={
            isReadOnly
              ? null
              : (event: any) => item.onRemoveItem(event, onRemoveStackItem)
          }
        >
          {item.children}
        </DraggableStackItem>
      );
    },
    [onRemoveStackItem, DraggableStackItem, isReadOnly]
  );

  return (
    <CriteriaCollapsedCard
      {...props}
      initialCardStatus={initialCardStatus}
      criteria={criteria}
      onRemoveStackItem={onRemoveStackItem}
      renderStackItem={renderStackItem}
      cardContainerRef={cardContentRef}
    />
  );
};
