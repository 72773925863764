import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import R from '@air/third-party/ramda';
import { toast } from '@air/third-party/toast';
import { Paragraph, UIText } from '@air/components';
import { history } from '@air/utils/history';
import * as phrases from 'constants/phrases';

import styles from './hooksStyles.css';

const scheduledCallbacks = new Map();

/*
 All callbacks should be memoized (ex. with useCallback)
 in advance. Otherwise this hook will return new callback on each
 render.
 */
type UndoableCallbackHookArgs<T extends (...args: any[]) => void> = {
  callback: T;
  onCallbackScheduled?: CastReturnType<T>;
  undoCallback?: CastReturnType<T>;
  undoDelay?: number;
  undoSuccessVisibilityTime?: number;
  undoneConfirmationDisplayTime?: number;
  callbackScheduledMsg?: CastReturnType<T, string> | string;
  callbackSuccessMsg?: CastReturnType<T, string> | string;
  undoMsg?: CastReturnType<T, string> | string;
};

export function useUndoableCallback<T extends (...args: any[]) => void>({
  onCallbackScheduled,
  callback,
  undoCallback,
  undoDelay = 5000,
  undoSuccessVisibilityTime = 2000,
  undoneConfirmationDisplayTime = 2000,
  callbackScheduledMsg = phrases.UNDO_ACTION_SCHEDULED,
  callbackSuccessMsg,
  undoMsg = phrases.UNDO_ACTION_UNDONE,
}: UndoableCallbackHookArgs<T>) {
  return useCallback<CastReturnType<T>>(
    (...args) => {
      /*
       According to BA requirements, invocation of next undoable
       action should immediately run previously scheduled action.
       */
      if (scheduledCallbacks.size) {
        for (const [id, callback] of scheduledCallbacks.entries()) {
          callback();
          scheduledCallbacks.delete(id);
        }
      }

      const scheduledId = uuidv4();
      scheduledCallbacks.set(scheduledId, () => toast.dismiss(scheduledId));
      onCallbackScheduled?.(...args);

      const callbackToastMsg = R.isFunction(callbackScheduledMsg)
        ? callbackScheduledMsg(...args)
        : callbackScheduledMsg;

      const UndoableBody = (
        <div className={styles.undoableToastContent}>
          <Paragraph short>{callbackToastMsg}</Paragraph>
          <UIText className={styles.undoableToastUndo} bold onClick={onUndo}>
            Undo
          </UIText>
        </div>
      );

      const callbackSuccessToastMsg = R.isFunction(callbackSuccessMsg)
        ? callbackSuccessMsg(...args)
        : callbackSuccessMsg;

      const UndoableSuccessBody = (
        <div className={styles.undoableToastContent}>
          <Paragraph short>{callbackSuccessToastMsg}</Paragraph>
        </div>
      );

      const undoToastMsg = R.isFunction(undoMsg) ? undoMsg(...args) : undoMsg;
      const UndoneBody = (
        <div className={styles.undoableToastContent}>
          <Paragraph short>{undoToastMsg}</Paragraph>
        </div>
      );

      let isUndone = false;
      function onUndo() {
        isUndone = true;
        scheduledCallbacks.delete(scheduledId);
        toast.dark(UndoneBody, {
          autoClose: undoneConfirmationDisplayTime,
        });
        undoCallback?.(...args);
      }

      const unlisten = history.listen(() => {
        toast.dismiss(scheduledId);
        unlisten();
      });

      toast.dark(UndoableBody, {
        onClose: async () => {
          if (!isUndone) {
            try {
              await callback(...args);
              if (callbackSuccessMsg) {
                toast.dark(UndoableSuccessBody, {
                  autoClose: undoSuccessVisibilityTime,
                });
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
          scheduledCallbacks.delete(scheduledId);
          unlisten();
        },
        autoClose: undoDelay,
        toastId: scheduledId,
      });
    },
    [
      undoSuccessVisibilityTime,
      undoneConfirmationDisplayTime,
      onCallbackScheduled,
      callback,
      undoCallback,
      undoDelay,
      callbackScheduledMsg,
      callbackSuccessMsg,
      undoMsg,
    ]
  );
}
