import { useReducer, useEffect, useCallback } from 'react';

type Key = string;

type ActionData = { value: any };

type StateConfigObject<S extends Key, C> = {
  target: S;
  condition?: (context: C) => boolean;
  fallback?: S;
  action?: (context: C, data?: ActionData) => C;
  internal?: boolean;
};

export type StatesConfig<S extends Key, T extends Key, C> = {
  [state in S]?: { [transitions in T | 'auto']?: S | StateConfigObject<S, C> };
};

export interface CurrentStateObject<S> {
  value: S;
  matches(state: S): boolean;
  in(states: S[]): boolean;
}

export interface SendFunction<T> {
  (action: T, payload?: any): void;
}

/**
 * Type guards for State Object.
 */
// TODO find out how to use conditionals so if the type of variable is not a StateConfigObject, then it's a Key
const isStateConfigObject = <S extends Key, C>(
  state: S | StateConfigObject<S, C>
): state is StateConfigObject<S, C> => {
  return typeof state === 'object';
};

const isValidState = <S extends Key, C>(
  state: S | StateConfigObject<S, C>
): state is S => {
  return typeof state === 'string';
};

// TODO think about better interface for this function:
function runEffect(transition: any, context: any, data?: any) {
  if (isStateConfigObject(transition) && transition?.action) {
    context = transition.action(context, { value: data || null }) ?? context;
  }
  return context;
}
/**
 * Runs single action, if there is no transition possible, fall backs to current state.
 * @param transition
 * @param current
 * @param data { any } any data provided by send method along with an action type.
 */
function runTransition<S extends Key, C>(
  transition: S | StateConfigObject<S, C>,
  current: { name: S; context: C },
  data?: any
): { name: S; context: C } {
  const { context } = current;
  if (isStateConfigObject(transition)) {
    if (transition?.internal && transition.target === current.name) {
      return current;
    }
    if (transition?.condition) {
      if (!transition.condition(context)) {
        return { name: transition?.fallback ?? current.name, context };
      }
    }
    return {
      name: transition?.target,
      context: runEffect(transition, context, data),
    };
  } else if (isValidState(transition) && transition !== current.name) {
    return { name: transition, context };
  }
  return current;
}

export function createStateReducer<S extends Key, T extends Key, C>(
  statesConfig: StatesConfig<S, T, C>
) {
  return function stateReducer(
    currentState: { name: S; context: C },
    action: { type: T | 'auto'; data?: any }
  ): { name: S; context: C } {
    const transitionsMap = statesConfig?.[currentState.name];
    if (transitionsMap) {
      const transition = transitionsMap?.[action.type];
      return runTransition(transition, currentState, action?.data);
    }

    // todo: we can get here only if currentState.name doesn't exist in statesConfig,
    //  which is an obvious error, should we think about using statesConfig.default setting
    //  to transition into it, instead of keeping currentState?
    return currentState;
  };
}

export function useStateMachine<S extends Key, T extends Key, C>(
  states: StatesConfig<S, T, any>,
  initial: S,
  context: C = {} as C
): [CurrentStateObject<S>, SendFunction<T>] {
  const [currentState, dispatch] = useReducer(createStateReducer(states), {
    name: initial,
    context,
  });

  const current = {
    value: currentState.name,
    matches(state: S): boolean {
      return (
        state === currentState.name || currentState.name.startsWith(state, 0)
      );
    },
    in(states: S[]): boolean {
      return states.some(this.matches);
    },
  };

  const send = useCallback((event: T | 'auto', payload?: any): void => {
    dispatch({ type: event, data: payload });
  }, []);

  useEffect(() => {
    states?.[currentState.name]?.auto && send('auto');
  }, [currentState.name, states, send]);

  return [current, send];
}
