import React from 'react';
import * as phrases from 'constants/phrases';
import { Field, FieldProps } from 'formik';
import { HiddenList } from 'components';
import { criteriaImportanceOptionsList } from 'constants/skills';
import { GA_LABEL_CHANGE_IMPORTANCE_BUTTON } from 'constants/gaLabels';
import { SearchCardActions } from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import { BaseSearchCriteriaData } from 'domain/SearchCriteria';
import { isExcludedCriteriaCard } from '@air/domain/SearchCriteriaCards/cardsCommonHelpers';
import { trackEvent } from '@air/utils/ga';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { GACategory } from '@air/domain/Common/GATypes';
import { useSearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';

type CriteriaImportanceSettingsT = {
  namePrefix: string;
  cardData: BaseSearchCriteriaData;
  onImportanceChange: (
    criteria: BaseSearchCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
};
export const CriteriaImportanceExclusionSettings: React.FC<
  CriteriaImportanceSettingsT & {
    onExclude: () => void;
  }
> = ({ namePrefix, cardData, onImportanceChange, onExclude }) => {
  const { dispatch } = useSearchCardContext();

  const withExclusionOption = 'exclude' in cardData;
  const isCriteriaUnwanted = isExcludedCriteriaCard(cardData);

  const unwantedOption = [
    {
      label: phrases.UNWANTED_LABEL,
      value: isCriteriaUnwanted,
    },
  ];

  const activeUnwantedOption = {
    label: phrases.UNWANTED_LABEL,
    value: true,
  };

  return (
    <>
      <CriteriaImportanceSettings
        namePrefix={namePrefix}
        cardData={cardData}
        onImportanceChange={onImportanceChange}
      />
      {withExclusionOption && (
        <>
          <div className={cardCommonStyles.separatorDots}>...</div>
          <Field name={`${namePrefix}.exclude`}>
            {({ field, form }: FieldProps) => (
              <HiddenList
                items={unwantedOption}
                activeItem={activeUnwantedOption}
                onChange={async (option) => {
                  const value = !option.value;
                  await form.setFieldValue(field.name, value);
                  onExclude();
                  trackEvent({
                    category: GACategory.DraftPage,
                    label: GA_LABEL_CHANGE_IMPORTANCE_BUTTON,
                  });
                  dispatch(SearchCardActions.updateImportance);
                }}
              />
            )}
          </Field>
        </>
      )}
    </>
  );
};
CriteriaImportanceExclusionSettings.displayName =
  'CriteriaImportanceExclusionSettings';

export const CriteriaImportanceSettings: React.FC<
  CriteriaImportanceSettingsT
> = ({ namePrefix, cardData, onImportanceChange }) => {
  const { dispatch } = useSearchCardContext();

  return (
    <Field name={`${namePrefix}.importance`}>
      {({ field }: FieldProps) => (
        <HiddenList
          items={criteriaImportanceOptionsList(cardData.cardType)}
          activeItem={field.value}
          onChange={async (option) => {
            onImportanceChange(cardData, option);
            trackEvent({
              category: GACategory.DraftPage,
              label: GA_LABEL_CHANGE_IMPORTANCE_BUTTON,
            });
            dispatch(SearchCardActions.updateImportance);
          }}
        />
      )}
    </Field>
  );
};
CriteriaImportanceSettings.displayName = 'CriteriaImportanceSettings';
