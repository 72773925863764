import React, { useEffect, useReducer, useCallback } from 'react';

import { ConfirmationModal, Paragraph } from '@air/components';
import { ModalStyles } from '@air/components/ModalWrapper/ModalWrapper';
import { subscribe } from 'hooks/usePubSub';
import * as phrases from 'constants/phrases';
import { APP_EVENTS } from 'domain/Kanban/events';

enum BasicConfirmationAction {
  OPEN_MODAL,
  CLOSE_MODAL,
}

type BasicConfirmationModalAction = {
  type: BasicConfirmationAction;
  text?: string;
  confirmLabel?: string;
  onConfirm?: (...args: any[]) => void;
  options?: {
    isOpened?: boolean;
    modalBody?: React.ElementType;
    onConfirm?: (...args: any[]) => void;
    onCancel?: (...args: any[]) => void;
    modalStyles?: ModalStyles;
  };
};

type BasicConfirmationModalState = {
  isOpened: boolean;
  modalBody?: React.ReactNode;
  confirmLabel?: string;
  onConfirm?: (...args: any[]) => void;
} & BasicConfirmationModalAction['options'];

export const BasicConfirmationModal = () => {
  const [confirmModal, dispatch] = useReducer(
    (
      state: BasicConfirmationModalState,
      action: BasicConfirmationModalAction
    ) => {
      switch (action.type) {
        case BasicConfirmationAction.OPEN_MODAL:
          return {
            isOpened: true,
            modalBody: (
              <Paragraph short bold centered>
                {action.text}
              </Paragraph>
            ),
            confirmLabel: action.confirmLabel || phrases.CONFIRM,
            onConfirm: () => {
              action?.onConfirm();
              dispatch({ type: BasicConfirmationAction.CLOSE_MODAL });
            },
            ...action.options,
          };
        case BasicConfirmationAction.CLOSE_MODAL:
          return {
            ...state,
            isOpened: false,
          };
        default:
          return state;
      }
    },
    { isOpened: false }
  );

  const openConfirmationModal = useCallback(
    ({
      text,
      confirmLabel,
      onConfirm,
      options = {},
    }: {
      text: BasicConfirmationModalAction['text'];
      confirmLabel: BasicConfirmationModalAction['text'];
      onConfirm: BasicConfirmationModalAction['onConfirm'];
      options: BasicConfirmationModalAction['options'];
    }) => {
      dispatch({
        type: BasicConfirmationAction.OPEN_MODAL,
        text,
        confirmLabel,
        onConfirm,
        options,
      });
    },
    []
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.OPEN_CONFIRMATION_MODAL, openConfirmationModal);
  }, [openConfirmationModal]);

  return (
    <ConfirmationModal
      onConfirm={confirmModal.onConfirm}
      onCancel={() => {
        dispatch({ type: BasicConfirmationAction.CLOSE_MODAL });
        confirmModal.onCancel?.();
      }}
      confirmLabel={confirmModal.confirmLabel}
      isOpen={confirmModal.isOpened}
      modalStyles={confirmModal.modalStyles}
    >
      {confirmModal.modalBody}
    </ConfirmationModal>
  );
};
