export function storageFactory(storageName: string) {
  return {
    getItem(key: string) {
      // @ts-ignore
      const storedValue = window[storageName].getItem(key);
      try {
        return JSON.parse(storedValue);
      } catch {
        return storedValue;
      }
    },

    setItem(key: string, value: any) {
      // @ts-ignore
      return window[storageName].setItem(key, JSON.stringify(value));
    },

    setItems(values: { [key: string]: any }) {
      for (const [key, value] of Object.entries(values)) {
        this.setItem(key, value);
      }
    },

    remove(key: string) {
      // @ts-ignore
      window[storageName].removeItem(key);
    },
  };
}

export const localStore = storageFactory('localStorage');
export const sessionStore = storageFactory('sessionStorage');

export const ACCESS_TOKEN = 'accessToken';
export const ACCESS_TOKEN_ORIGIN = 'accessTokenOrigin';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_AT = 'expiresAt';
export const LINK_TOKEN = 'linkToken';

export const clearTokenStorage = () => {
  localStore.remove(ACCESS_TOKEN);
  localStore.remove(ACCESS_TOKEN_ORIGIN);
  localStore.remove(REFRESH_TOKEN);
  localStore.remove(EXPIRES_AT);
  localStore.remove(LINK_TOKEN);
};

export const STOPPED_DRAFTS = 'stoppedDrafts';
export const STOPPED_INTERVIEW_DRAFTS = 'stoppedInterviewDrafts';

export const SEARCH_FILTER = 'kanbanSearchFilter';
export const TRIAL_SHOWN = 'trialShown';
export const TRIAL_INFO = 'TRIAL_INFO';

export const BACKGROUND_IMAGE_ID_STORAGE_KEY = 'BACKGROUND_IMAGE_ID';
export const KANBAN_SECTIONS = 'KANBAN_SECTIONS';
export const INTERVIEW_LEFT_PANEL_COLLAPSED = 'INTERVIEW_LEFT_PANEL_COLLAPSED';
