import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import querystring from 'query-string';

import * as commonUrls from '@air/constants/commonUrls';
import { toast } from '@air/third-party/toast';
import { Logo } from '@air/components';
import * as phrases from '@air/constants/phrases';
import { useDebounce } from '@air/utils/hooks';
import { FeatureView, CreatePasswordForm } from 'components';
import * as appConstants from 'constants/app';
import { isMobileOrTablet } from '@air/utils/mobile';
import * as userApi from 'domain/UserManagement/userApi';

import styles from 'features/Login/Login.css';
import formViewStyles from 'components/FormView/FormView.css';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';

export const CreatePasswordView: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const { logout, setUserCredentials } = useCustomerProfileMethods();
  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );
  const [loginReady, setLoginReady] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoginReady(true), 500);
  }, []);

  const { linkToken } = querystring.parse(location.search);

  const setNewPassword = useCallback(
    ({ password }: { password: string }) => {
      return userApi
        .setNewPassword(customerId, linkToken as string, password)
        .fork(
          () => {
            toast.error(phrases.CREATE_PASSWORD_FAILURE_NOTIFICATION);
          },
          () => {
            /*
            Performing "manual" logout here, because we're not using
            history in logout() method, thus we can't pass location
            state, which we need (according to AR-6565) to prefill
            login form after setting new password.
           */
            logout();
            setUserCredentials({ email: user.email, password });
            history.push(commonUrls.LOGIN_ROUTE, {
              formTitle: phrases.PASSWORD_CREATED_LOGIN_TITLE,
            });
          }
        );
    },
    [user, customerId, linkToken, history, logout, setUserCredentials]
  );
  const onSubmit = useDebounce(
    setNewPassword,
    appConstants.REQUEST_DEBOUNCE_TIME,
    true
  );

  return (
    <>
      <FeatureView
        className={classNames({ [styles.isMobileOrTablet]: isMobileOrTablet })}
      >
        <FeatureView.Aside className={styles.loginAside}>
          <Logo isWhiteLogoText />
        </FeatureView.Aside>
        <FeatureView.Content
          className={classNames(
            styles.loginContent,
            formViewStyles.formViewContent,
            {
              [styles.ready]: loginReady,
            }
          )}
        >
          <CreatePasswordForm email={user?.email} onSubmit={onSubmit} />
        </FeatureView.Content>
      </FeatureView>
    </>
  );
};
CreatePasswordView.displayName = 'CreatePasswordView';
