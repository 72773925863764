import {
  JobDescriptionFullResponse,
  JobDescriptionItem,
} from '@air/api/models';
import { createContext } from 'use-context-selector';

export type JobsListT = {
  items: JobDescriptionItem[];
  total: number;
  loaded: boolean;
};
export type JobsContextT = {
  jobsList: JobsListT;
  jobRequisitionDetails?: JobDescriptionFullResponse;
  methods: {
    fetchJobDescription?: (atsId: number | string, jdId: string) => void;
    clearJobDescription?: () => void;
    removeJobDescription?: (jdId: string) => void;
    fetchJobsList?: (params: { page: number }) => void;
  };
};
export const JobsContext = createContext<JobsContextT>({
  jobsList: { items: [], total: 0, loaded: false },
  methods: {},
});
