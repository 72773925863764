import React from 'react';
import * as phrases from 'constants/phrases';
import { Button, TooltipWrapper } from '@air/components';
import styles from './UtilitiesControls.css';
import classNames from 'classnames';
import {
  useAppContext,
  useAppMethods,
  RightSidebarPanel,
} from 'providers/AppProvider';
import { appSelectors, customerProfileSelectors } from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  GACategory,
  trackJobDescriptionOpenCloseState,
} from '@air/domain/Common/GATypes';
import commonStyles from 'styles/commonStyles.css';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';

export const UtilitiesControls: React.FC<{
  isCollapsed?: boolean;
  hasUploadsButton?: boolean;
  hasReportsButton?: boolean;
  gaCategory: GACategory;
}> = ({ isCollapsed, hasUploadsButton, hasReportsButton, gaCategory }) => {
  const rightSidebarPanel = useAppContext(appSelectors.rightSidebarPanel);
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  const { setRightSidebarPanel } = useAppMethods();

  const toggleRightSidebarPanel = (panel: RightSidebarPanel) => {
    setRightSidebarPanel(panel === rightSidebarPanel ? null : panel);
  };

  const toggleJobDescriptionPanel = () => {
    if (rightSidebarPanel === RightSidebarPanel.JobDescription) {
      setRightSidebarPanel(null);
      trackJobDescriptionOpenCloseState(gaCategory, false);
    } else {
      setRightSidebarPanel(RightSidebarPanel.JobDescription);
      trackJobDescriptionOpenCloseState(gaCategory, true);
    }
  };

  const isReportsButtonDisabled = isTrialExpired;

  return (
    <>
      <span
        className={classNames(styles.headerText, {
          [styles.isCollapsed]: isCollapsed,
        })}
      >
        {phrases.UTILITIES_HEADER}
      </span>
      <TooltipWrapper
        delayShow={TOOLTIP_DELAY_TIME_LONG}
        tooltip={phrases.JOB_DESCRIPTION_BUTTON_TEXT}
        enabled={isCollapsed}
        flexGrow={false}
      >
        <Button
          icon="job-description-icon"
          type="button"
          onClick={toggleJobDescriptionPanel}
          className={classNames(
            commonStyles.leftSidebarButton,
            commonStyles.defaultState,
            {
              [styles.isCollapsed]: isCollapsed,
            }
          )}
          variant={Button.variants.POSITIVE_SECONDARY}
        >
          {phrases.JOB_DESCRIPTION_BUTTON_TEXT}
        </Button>
      </TooltipWrapper>
      {hasUploadsButton && (
        <TooltipWrapper
          delayShow={TOOLTIP_DELAY_TIME_LONG}
          tooltip={phrases.UPLOADED_FILES_BUTTON_TEXT}
          enabled={isCollapsed}
          flexGrow={false}
        >
          <Button
            icon="uploaded-files-icon"
            onClick={() => toggleRightSidebarPanel(RightSidebarPanel.Uploads)}
            className={classNames(
              commonStyles.leftSidebarButton,
              commonStyles.defaultState,
              {
                [styles.isCollapsed]: isCollapsed,
              }
            )}
            variant={Button.variants.POSITIVE_SECONDARY}
          >
            {phrases.UPLOADED_FILES_BUTTON_TEXT}
          </Button>
        </TooltipWrapper>
      )}
      {hasReportsButton && (
        <TooltipWrapper
          enabled={isReportsButtonDisabled || isCollapsed}
          tooltip={
            isReportsButtonDisabled
              ? phrases.DISABLED_REPORT_CONTROL_BUTTON_TOOLTIP
              : phrases.GENERATE_REPORT_BUTTON_TEXT
          }
          disabledChildren={isReportsButtonDisabled}
          flexGrow={false}
        >
          <Button
            disabled={isReportsButtonDisabled}
            icon="generate-report-icon"
            onClick={() => toggleRightSidebarPanel(RightSidebarPanel.Report)}
            className={classNames(
              commonStyles.leftSidebarButton,
              commonStyles.defaultState,
              {
                [styles.isCollapsed]: isCollapsed,
              }
            )}
            variant={Button.variants.POSITIVE_SECONDARY}
          >
            {phrases.GENERATE_REPORT_BUTTON_TEXT}
          </Button>
        </TooltipWrapper>
      )}
    </>
  );
};
