import React from 'react';
import {
  ModalWrapper,
  ModalStyles,
} from '@air/components/ModalWrapper/ModalWrapper';
import { Button } from '@air/components';
import * as phrases from '@air/constants/phrases';
import styles from './ConfirmationModal.css';
import { ButtonVariants } from '@air/components/Button/Button';

type Props = {
  contentLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  confirmLabel: string;
  actionDisabled?: boolean;
  cancelLabel?: string;
  cancelVariant?:
    | ButtonVariants.NEGATIVE_SECONDARY
    | ButtonVariants.POSITIVE_MAIN;
  onRequestClose?: () => void;
  modalStyles?: ModalStyles;
};

export const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  contentLabel = 'modal',
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel = phrases.CANCEL,
  isLoading,
  actionDisabled,
  cancelVariant = ButtonVariants.POSITIVE_MAIN,
  children,
  onRequestClose,
  modalStyles,
}) => {
  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      contentLabel={contentLabel}
      onRequestClose={onRequestClose || onCancel}
      modalStyles={modalStyles}
    >
      {children}
      <Button
        small
        isLoading={isLoading}
        onClick={onConfirm}
        className={styles.modalButton}
        variant={Button.variants.POSITIVE_CONFIRM}
        disabled={actionDisabled}
      >
        {confirmLabel}
      </Button>
      <Button
        small
        disabled={isLoading}
        onClick={onCancel}
        className={styles.modalButton}
        variant={cancelVariant}
      >
        {cancelLabel}
      </Button>
    </ModalWrapper>
  );
};
