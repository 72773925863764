import React, { createContext, useCallback, useState } from 'react';
import { useDebounce } from '@air/utils/hooks';
import { INPUT_DEBOUNCE_TIME } from '@air/constants/app';

type StateContextType = {
  candidateNameQuery: string;
  onCandidateNameSearchChange?: (searchQuery: string) => void;
  clearSearchQuery?: () => void;
};

export const CandidateQueryContext: React.Context<StateContextType> =
  createContext({
    candidateNameQuery: '',
  });

/*
  By default all candidates are visible, but they can be filtered by name.
*/
export const useCandidateQuery = (defaultValue = '') => {
  const [candidateNameQuery, setCandidateNameQuery] = useState(defaultValue);
  const onCandidateNameSearchChange = useDebounce(
    setCandidateNameQuery,
    INPUT_DEBOUNCE_TIME
  );

  const clearSearchQuery = useCallback((defaultValue = '') => {
    setCandidateNameQuery(defaultValue);
  }, []);

  return { candidateNameQuery, onCandidateNameSearchChange, clearSearchQuery };
};

export const CandidateQuery: React.FC<{
  candidateNameQuery: string;
  onCandidateNameSearchChange: (searchQuery: string) => void;
  clearSearchQuery: () => void;
}> = ({
  children,
  candidateNameQuery,
  onCandidateNameSearchChange,
  clearSearchQuery,
}) => {
  return (
    <CandidateQueryContext.Provider
      value={{
        candidateNameQuery,
        onCandidateNameSearchChange,
        clearSearchQuery,
      }}
    >
      {children}
    </CandidateQueryContext.Provider>
  );
};
