import {
  SearchCriteriaImportanceEnum,
  Link,
  Address,
  PhoneNumber,
  NoteResponse,
  DataOriginType,
  StringMergedResponse,
  AddressMergedResponse,
  PhoneMergedResponse,
  LinkMergeResponse,
  EnrichmentInfo,
  CandidateSearchProfileV2,
  PatchGeneralInfo,
  EnrichmentInfoStatusEnum,
  CandidateSearchProfileStatus,
  PeriodInfo,
  ProfileSort,
} from '@air/api';
import {
  CandidateSkillData,
  mapSkills,
} from 'domain/CandidateData/CandidateCriteria/CandidateSkillData';
import {
  CandidateEducation,
  mapEducationToCandidateData,
} from 'domain/CandidateData/CandidateDataMapper';
import {
  CandidateCertificationData,
  mapCertificatesToCandidateCertificationData,
} from 'domain/CandidateData/CandidateCriteria/CandidateCertificationData';
import {
  CandidateRoleData,
  mapRoles,
} from 'domain/CandidateData/CandidateCriteria/CandidateRoleData';
import {
  CandidateProfessionalData,
  mapProfessionalExperience,
} from 'domain/CandidateData/CandidateCriteria/CandidateProfessionalData';
import {
  CandidateManagerialData,
  mapManagerialExperience,
} from 'domain/CandidateData/CandidateCriteria/CandidateManagerialData';
import {
  CandidateCompanyData,
  mapCompanies,
} from 'domain/CandidateData/CandidateCriteria/CandidateCompanyData';
import {
  BaseCriteriaData,
  getImportanceValue,
  SearchCriteriaData,
} from 'domain/SearchCriteria';
import R from '@air/third-party/ramda';
import { HeaderCriteriaData } from 'domain/HeaderData/HeaderDataMapper';
import { RedFlagData, mapAlertToRedFlagData } from '@air/domain/RedFlags';
import * as phrases from 'constants/phrases';
import {
  CandidateQuestionData,
  mapQuestions,
} from 'domain/CandidateData/CandidateCriteria/CandidateQuestionData';
import { RequisitionFile } from 'context/uploadApplicants';
import {
  mapIndustries,
  CandidateIndustryData,
} from './CandidateCriteria/CandidateIndustryData';

import {
  CandidateSearchProfileStatusEnum,
  CandidateStatus,
} from './candidateStatusHelpers';
import {
  normalizeWorkHistory,
  WorkHistoryData,
} from './WorkHistory/WorkHistoryData';
import { CandidateLineupData } from 'domain/CandidateData/CandidateLineupData';
import {
  CandidateLocationData,
  mapLocationsToCandidateLocationData,
} from 'domain/CandidateData/CandidateCriteria/CandidateLocationData';

export enum EnrichmentStatus {
  NOT_STARTED = 'NOTSTARTED',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  ENRICHED = 'ENRICHED',
}

export type CandidateProfileContactFormT = {
  emails?: string[];
  primaryEmail?: string;
  firstName?: string;
  lastName?: string;
  links?: Link[];
  phones?: PhoneNumber[];
};

/*
  CandidateProfileData module describes models used to present candidate's data
  on CandidateProfile view.
 */

type CandidateAttachment = { id: string; title: string; downloadName: string };

type SourcesType = {
  sources: DataOriginType[];
};

export type LinkExtended = Link & SourcesType;
export type AddressExtended = Address & SourcesType;
export type PhoneNumberExtended = Required<PhoneNumber> & SourcesType;
export type GroupedPhones = { [index: string]: PhoneNumberExtended[] };

export type EnrichedProfileExtended = {
  emails: StringMergedResponse[];
  links: LinkExtended[];
  addresses: AddressExtended[];
  primaryEmail?: string;
  groupedPhones?: GroupedPhones;
};

/**
 * CandidateProfileData datatype.
 * Describes data required by CandidateProfile view.
 */
export type CandidateProfileData = {
  fullName: string;
  email: string;
  status: string;
  attachments: CandidateAttachment[];
  redFlags: RedFlagData[];
  skills: CandidateSkillData[];
  education: CandidateEducation;
  certificates: CandidateCertificationData[];
  roles: CandidateRoleData[];
  professional: CandidateProfessionalData[];
  managerial: CandidateManagerialData[];
  industries: CandidateIndustryData[];
  companies: CandidateCompanyData[];
  questions: CandidateQuestionData[];
  location: CandidateLocationData;
  workHistory: WorkHistoryData[];
  enrichedProfile: EnrichedProfileExtended;
  note: NoteResponse;
  totalWorkExperience: PeriodInfo;
  atsCreatedAt: number;
};

export interface CandidateUploadsProfileData extends CandidateProfileData {
  atsApplicantId: number;
}

const convertMergedResponse = (
  mergedResponse: { sources?: DataOriginType[]; value?: any }[]
) => {
  return mergedResponse.map(({ value }) => value);
};

export const groupByImportance = <T extends BaseCriteriaData>(items: T[]) =>
  R.compose(
    R.groupBy((item: T) => {
      switch (getImportanceValue(item)) {
        case SearchCriteriaImportanceEnum.MANDATORY:
          return SearchCriteriaImportanceEnum.MANDATORY;
        case SearchCriteriaImportanceEnum.IMPORTANT:
          return SearchCriteriaImportanceEnum.IMPORTANT;
        case SearchCriteriaImportanceEnum.OPTIONAL:
          return SearchCriteriaImportanceEnum.OPTIONAL;
      }
    }),
    R.sortBy(R.prop('idx'))
  )(items);

export const mapCandidateDataToImportanceSections = (
  profile: CandidateProfileData
): any => {
  const candidateData = [
    ...profile.skills,
    ...profile.certificates,
    profile.education?.degree,
    profile.education?.major,
    profile.education?.institution,
    profile.location,
    ...profile.industries,
    ...profile.companies,
    ...profile.roles,
    ...profile.managerial,
    ...profile.professional,
    ...profile.questions,
  ].filter(Boolean);

  return groupByImportance(candidateData);
};

export const mapHeaderDataToImportanceSections = (
  criteria: HeaderCriteriaData[]
): { [importance in SearchCriteriaImportanceEnum]: SearchCriteriaData[] } => {
  const candidateData = R.map(R.prop('cardData'), criteria);
  return groupByImportance<SearchCriteriaData>(candidateData);
};

type MergedResponse =
  | PhoneMergedResponse
  | AddressMergedResponse
  | LinkMergeResponse;

type FlattenEnrichInfoType =
  | LinkExtended
  | PhoneNumberExtended
  | AddressExtended;

export const flattenEnrichInfo = (
  items: MergedResponse[]
): FlattenEnrichInfoType[] => {
  return items.map((item: MergedResponse) => {
    return {
      sources: item.sources,
      ...item.value,
    };
  });
};

export const filterLinks = (links: LinkExtended[]): LinkExtended[] =>
  // @ts-ignore
  R.reject(R.propEq('type', 'other'))(links);

export function sortByScore(
  candidates: CandidateLineupData[]
): CandidateLineupData[] {
  return R.sort(
    (a, b) =>
      R.descend(R.prop('matchScore'), a, b) ||
      R.descend(R.prop('redFlagsScore'), a, b) ||
      R.descend(R.prop('atsCreatedAt'), a, b),
    candidates
  );
}

export const sortCandidatesBySortType = <
  DataT extends {
    fullName: string;
    matchScore: number;
    redFlagsScore: number;
    atsCreatedAt: number;
  }
>(
  candidate1: DataT,
  candidate2: DataT,
  sortType: ProfileSort
) => {
  switch (sortType) {
    case ProfileSort.SCORE:
      return (
        R.descend(R.prop('matchScore'), candidate1, candidate2) ||
        R.descend(R.prop('redFlagsScore'), candidate1, candidate2) ||
        R.descend(R.prop('atsCreatedAt'), candidate1, candidate2)
      );
    case ProfileSort.ALPHABETICAL:
      return candidate1.fullName.localeCompare(candidate2.fullName);
    case ProfileSort.CREATEDAT:
      return candidate2.atsCreatedAt - candidate1.atsCreatedAt;
  }
};

export const normalizeLinks = (links: LinkMergeResponse[]) => {
  const flattenLinks = flattenEnrichInfo(links);
  const filteredLinks = filterLinks(flattenLinks);
  return filteredLinks.map((link) =>
    link?.url?.startsWith('http') || link?.type === 'skype'
      ? link
      : {
          ...link,
          url: `//${link.url}`,
        }
  );
};

const groupPhones = (phones: PhoneMergedResponse[]) => {
  const flattenPhones = flattenEnrichInfo(phones);
  return R.groupBy(R.prop('type'), flattenPhones as PhoneNumberExtended[]);
};

const getUpdatedEnrichmentStatus = (
  enrichmentStatus: EnrichmentStatus,
  enrichmentInfoStatus: EnrichmentInfoStatusEnum
) => {
  switch (true) {
    case enrichmentInfoStatus === EnrichmentInfoStatusEnum.ENRICHED:
      return EnrichmentStatus.ENRICHED;
    case !!enrichmentStatus:
      return enrichmentStatus;
    default:
      return EnrichmentStatus.NOT_STARTED;
  }
};

export type CandidateSearchProfileExtended = {
  enrichmentStatus?: EnrichmentStatus;

  id: string;
  applicantId: number;
  dataSourceId: number;
  mainProfileId: string;
  primaryEmail: string;
  emails: string[] | null;
  atsProfileStatus: string;
  status: CandidateSearchProfileStatusEnum;
  systemStatus: CandidateSearchProfileStatus;
  score: number;
  matchScore: number;
  redFlagsScore: number;
  created: number;
  updated: number;
  invitedForInterview: boolean;
  passive: boolean;
  firstName: string;
  lastName: string;
  fullName: string;
  resumeExternalProfileIds: string[];
  enrichmentInfo?: EnrichmentInfo;
  generalInfo?: PatchGeneralInfo;
  notes?: NoteResponse[] | null;
  atsUrl?: string;
};

export type CandidateProfileUIT = CandidateSearchProfileExtended & {
  candidateProfile: CandidateProfileData;
  file?: RequisitionFile;
};

export const normalizeCandidateName = (
  firstName: string,
  lastName: string
): string => {
  return (
    [firstName || '', lastName || ''].filter(Boolean).join(' ') ||
    phrases.CANDIDATE_PROFILE_EMPTY_NAME
  );
};

export const mapCandidateSearchProfileV2ToUIProfile = (
  candidate: CandidateSearchProfileV2 & {
    enrichmentStatus?: EnrichmentStatus;
  }
): CandidateProfileUIT => {
  const {
    firstName,
    lastName,
    searchSkills,
    status,
    resumeExternalProfileIds,
    searchEducation,
    searchCertificates,
    searchIndustryExperience,
    searchCompanyExperience,
    searchExperience,
    searchTitleExperience,
    searchAdditionalQuestions,
    searchLocation,
    workHistory,
    primaryEmail,
    emails,
    redFlags,
    enrichmentStatus,
    notes,
    atsUrl,
    totalWorkExperience,
    atsCreatedAt,
  } = candidate;
  const fullName = normalizeCandidateName(firstName?.value, lastName?.value);

  const enrichmentStatusUpdated = getUpdatedEnrichmentStatus(
    enrichmentStatus,
    candidate.enrichmentInfo?.status
  );

  return {
    id: candidate.id,
    atsProfileStatus: candidate.atsProfileStatus,
    dataSourceId: candidate.dataSourceId,
    status: candidate.status as unknown as CandidateSearchProfileStatusEnum,
    systemStatus: candidate.systemStatus,
    enrichmentInfo: candidate.enrichmentInfo,
    firstName: candidate.firstName?.value,
    lastName: candidate.lastName?.value,
    fullName,
    primaryEmail: candidate.primaryEmail,
    mainProfileId: candidate.mainProfileId,
    invitedForInterview: candidate.invitedForInterview,
    emails: convertMergedResponse(candidate.emails || []),
    generalInfo: {
      addresses: convertMergedResponse(candidate.addresses || []),
      links: convertMergedResponse(candidate.links || []),
      phones: convertMergedResponse(candidate.phones || []),
    },
    applicantId: candidate.applicantId,
    resumeExternalProfileIds: candidate.resumeExternalProfileIds,
    notes: candidate.notes,
    enrichmentStatus: enrichmentStatusUpdated,
    score: candidate.score,
    matchScore: candidate.matchScore,
    redFlagsScore: candidate.redFlagsScore,
    created: candidate.created,
    updated: candidate.updated,
    passive: candidate.passive,
    candidateProfile: {
      fullName,
      email: primaryEmail || emails?.[0]?.value || '',
      status: CandidateStatus[status],
      attachments: (resumeExternalProfileIds || []).map((id: any) => ({
        id,
        title: phrases.CANDIDATE_PROFILE_RESUME_TITLE(fullName),
        downloadName: phrases
          .CANDIDATE_PROFILE_RESUME_TITLE(fullName)
          .replace(/ /g, '_'),
      })),
      redFlags: R.pipe(
        R.notNullMap(mapAlertToRedFlagData),
        R.sort<RedFlagData>(
          (
            a: { group: string; type: string },
            b: { group: string; type: string }
            // @ts-ignore
          ) => R.ascend(R.prop('group'))(a, b) || R.ascend(R.prop('type'))(a, b)
        )
      )(redFlags || []),
      skills: mapSkills(searchSkills),
      education: mapEducationToCandidateData(searchEducation),
      certificates: (searchCertificates || []).map(
        mapCertificatesToCandidateCertificationData
      ),
      location: mapLocationsToCandidateLocationData(searchLocation),
      roles: mapRoles(searchTitleExperience),
      professional: mapProfessionalExperience(searchExperience || []),
      managerial: mapManagerialExperience(searchExperience || []),
      industries: mapIndustries(searchIndustryExperience),
      companies: mapCompanies(searchCompanyExperience),
      questions: mapQuestions(searchAdditionalQuestions),
      workHistory: normalizeWorkHistory(workHistory || []),
      enrichedProfile: {
        primaryEmail: candidate?.primaryEmail?.toLowerCase(),
        links: normalizeLinks(candidate.links || []),
        groupedPhones: groupPhones(candidate.phones || []),
        emails: candidate.emails || [],
        addresses: flattenEnrichInfo(candidate.addresses || []),
      },
      note: notes?.[0],
      totalWorkExperience,
      atsCreatedAt,
    },
    atsUrl: atsUrl,
  };
};

export const getEnrichmentTooltip = ({
  isDisabledForCompany,
  isSearchPausedOrClosed,
  isTrialExpired,
  isDisabledForApplicant,
  outdated,
}: {
  isDisabledForCompany: boolean;
  isSearchPausedOrClosed: boolean;
  isTrialExpired: boolean;
  isDisabledForApplicant: boolean;
  outdated: boolean;
}) => {
  switch (true) {
    case isDisabledForCompany:
      return phrases.ENRICHMENT_TURN_OFF_TOOLTIP_LABEL;
    case isSearchPausedOrClosed:
      return phrases.ENRICHMENT_DISABLED_FOR_PAUSED_CLOSED_SEARCH;
    case isTrialExpired:
      return phrases.ENRICHMENT_DISABLED_FOR_EXPIRED_TRIAL;
    case isDisabledForApplicant:
      return phrases.ENRICHMENT_DISABLED_FOR_APPLICANT;
    case outdated:
      return phrases.ENRICHMENT_OUTDATED_TOOLTIP_LABEL;
    default:
      return phrases.CANT_BE_ENRICHED_BUTTON;
  }
};

export const enrichedByPIPL = (sources: DataOriginType[]) => {
  return sources.length === 1 && sources.includes(DataOriginType.PIPL);
};

export const mapRequisitionToCandidateProfile = (
  file: RequisitionFile,
  options: { isSearchPaused: boolean; atsId: number }
): CandidateProfileUIT => {
  const { atsId, isSearchPaused } = options;

  const candidateStatus =
    isSearchPaused && file.atsCandidateId
      ? CandidateSearchProfileStatusEnum.NOTPROCESSED
      : CandidateSearchProfileStatusEnum.UPLOAD;

  return {
    applicantId: null,
    mainProfileId: file.atsCandidateId,
    id: file.tempId,
    status: candidateStatus,
    candidateProfile: {
      fullName: file.resumeFileName,
      email: '',
      status: candidateStatus,
      attachments: [
        {
          id: `${atsId}-${file.atsCandidateId}`,
          title: file.resumeFileName,
          downloadName: file.resumeFileName,
        },
      ],
      redFlags: [],
      skills: [],
      education: null,
      certificates: [],
      roles: [],
      professional: [],
      managerial: [],
      industries: [],
      companies: [],
      questions: [],
      workHistory: [],
      enrichedProfile: {
        emails: [],
        links: [],
        addresses: [],
      },
      note: null,
    } as CandidateProfileData,
    emails: [],
    firstName: file.resumeFileName,
    lastName: null,
    primaryEmail: null,
    resumeExternalProfileIds: [],
    score: null,
    systemStatus: candidateStatus as unknown as CandidateSearchProfileStatus,
    file,
  } as CandidateProfileUIT;
};
