import { Dispatch, useCallback } from 'react';
import * as Auth from '@air/utils/auth';
import { clearTokenStorage } from '@air/domain/WebStorage/webStorage';
import { LOGOUT_REDIRECT_ROUTE } from '@air/constants/commonUrls';
import { ActionType } from '@air/domain/Common/ActionType';
import { AuthReducerAction } from '@air/utils/auth/authReducer';

function logout<T>(dispatch: Dispatch<AuthReducerAction<T>>) {
  return () => {
    clearTokenStorage();
    dispatch({
      type: ActionType.Logout,
    });

    if (!window.location.href.match(CUSTOMER_PORTAL_HOST)) {
      window.location = decodeURIComponent(
        LOGOUT_REDIRECT_ROUTE
      ) as unknown as Location;
    }
  };
}

export default function useLogoutAction<T>(
  dispatch: Dispatch<AuthReducerAction<T>>
) {
  return useCallback(() => {
    const onLogout = logout(dispatch);
    Auth.logout().fork(onLogout, onLogout);
  }, [dispatch]);
}
