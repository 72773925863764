import { createContext } from 'use-context-selector';

export type CacheContextT = {
  cache?: { [key: string]: any };
  methods: {
    updateCache?: (value: any) => void;
  };
};
export const CacheContext = createContext<Partial<CacheContextT>>({});

CacheContext.displayName = 'CacheContext';
