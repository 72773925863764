import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { useOutsideClick } from '@air/utils/hooks';
import { OverlapPageControls } from '@air/components';

import fadeTransition from '@air/styles/transitions/fade.css';
import styles from './OverlayPanel.css';

enum OverlayPosition {
  left,
  right,
  center,
}

type OverlayPanelProps = React.FC<{
  className?: string;
  position?: OverlayPosition;
  onClose?: () => void;
  visible?: boolean;
  showCloseButton?: boolean;
}> & { positions: typeof OverlayPosition };

export const OverlayPanel: OverlayPanelProps = ({
  className,
  position = OverlayPosition.left,
  onClose = R.noop,
  children,
  visible,
  showCloseButton = true,
}) => {
  const [isExiting, setExiting] = useState(false);
  const [panelRef] = useOutsideClick(onClose, { useCapture: true });

  return ReactDOM.createPortal(
    <CSSTransition
      in={visible}
      classNames={fadeTransition}
      timeout={500}
      unmountOnExit
      onExit={() => setExiting(true)}
      onExited={() => setExiting(false)}
    >
      <div className={styles.overlayBackground}>
        <div
          ref={panelRef}
          className={classNames(styles.overlayPanel, className, {
            [styles.overlayPanelRight]: position === OverlayPosition.right,
            [styles.overlayPanelCenter]: position === OverlayPosition.center,
            [styles.isExiting]: isExiting,
          })}
        >
          {showCloseButton && <OverlapPageControls onClose={onClose} />}
          {children}
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
};
OverlayPanel.displayName = 'OverlayPanel';
OverlayPanel.positions = OverlayPosition;
