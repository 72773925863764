import React, { useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import R from '@air/third-party/ramda';
import { DataSourceType } from '@air/api';
import classNames from 'classnames';
import * as phrases from '@air/constants/phrases';
import * as cpPhrases from 'customer-portal/src/constants/phrases';
import { FormViewTitle } from 'components/FormView/FormView';
import { FormFieldTypes } from '@air/components/Form/utils';
import { Button, FormField, UIText } from '@air/components';
import { ATSNames } from 'domain/ATS/ATSMetadata';

import styles from './SignupFormSteps.css';
import formViewStyles from 'components/FormView/FormView.css';

type TaleoATSSettings = {
  username: string;
  password: string;
  baseUrl: string;
  companyCode: string;
};
type TaleoIntegrationProps = {
  className?: string;
  onConnect: (
    atsType: DataSourceType,
    atsSettings: TaleoATSSettings
  ) => Promise<void>;
  onCancel: () => void;
  isConnecting?: boolean;
};
export const TaleoIntegrationForm: React.FC<TaleoIntegrationProps> = ({
  className = '',
  onConnect,
  onCancel,
  isConnecting,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
  });
  const formValues = useWatch({ control });

  const onSubmit = useCallback(
    async (settings: TaleoATSSettings) => {
      onConnect(DataSourceType.ORACLETALEO, R.map(R.trim, settings));
    },
    [onConnect]
  );

  return (
    <>
      <Button
        className={styles.backButton}
        variant={Button.variants.TINY}
        icon="collapse"
        onClick={onCancel}
      >
        {cpPhrases.BACK_BUTTON}
      </Button>

      <form
        className={classNames(
          styles.createPasswordForm,
          className,
          formViewStyles.loginForm
        )}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <UIText small bold className={formViewStyles.supHeader}>
          {phrases.getSignupStep(3)}
        </UIText>
        <FormViewTitle
          title={phrases.getATSIntergrationFormTitle(
            ATSNames[DataSourceType.ORACLETALEO]
          )}
          description={phrases.TALEO_ATS_INTEGRATION_FORM_DESCRIPTION}
        />

        <FormField
          id="username"
          type={FormFieldTypes.text}
          {...register('username', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={formValues.username && !errors?.username}
          hasError={!!errors?.username}
          isEmpty={R.isNullOrEmpty(formValues.username)}
          label={
            errors?.username?.message ?? phrases.TALEO_USERNAME_FIELD_LABEL
          }
          placeholder={null}
        />
        <FormField
          id="password"
          type={FormFieldTypes.password}
          {...register('password', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={formValues.password && !errors?.password}
          hasError={!!errors?.password}
          isEmpty={R.isNullOrEmpty(formValues.password)}
          label={
            errors?.password?.message ?? phrases.TALEO_PASSWORD_FIELD_LABEL
          }
          placeholder={null}
        />
        <FormField
          id="companyCode"
          type={FormFieldTypes.text}
          {...register('companyCode', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={formValues.companyCode && !errors?.companyCode}
          hasError={!!errors?.companyCode}
          isEmpty={R.isNullOrEmpty(formValues.companyCode)}
          label={
            errors?.companyCode?.message ??
            phrases.TALEO_COMPANY_CODE_FIELD_LABEL
          }
          placeholder={null}
        />
        <FormField
          id="baseUrl"
          type={FormFieldTypes.text}
          {...register('baseUrl', {
            required: {
              value: true,
              message: phrases.FORM_ERROR_NO_VALUE,
            },
          })}
          isValid={formValues.baseUrl && !errors?.baseUrl}
          hasError={!!errors?.baseUrl}
          isEmpty={R.isNullOrEmpty(formValues.baseUrl)}
          label={errors?.baseUrl?.message ?? phrases.TALEO_BASE_URL_FIELD_LABEL}
          placeholder={null}
        />

        <div className={formViewStyles.formFieldExtra}>
          <Button
            type="submit"
            variant={Button.variants.POSITIVE_CONFIRM}
            className={classNames(
              styles.nextStepButton,
              formViewStyles.actionButton
            )}
            disabled={!formState.isValid}
            isLoading={isConnecting}
          >
            {isConnecting
              ? phrases.ATS_CONNECTION_PLACEHOLDER
              : phrases.ATS_CONNECT_BUTTON}
          </Button>
        </div>
      </form>
    </>
  );
};
TaleoIntegrationForm.displayName = 'TaleoIntegrationForm';

export const TaleoIntegrationSuccess: React.FC<{
  onStart: () => void;
}> = ({ onStart }) => {
  return (
    <div>
      <img className={styles.logoImage} src="/assets/images/taleo_logo.png" />
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_SUCCESS_TITLE}
        description={phrases.getATSIntergrationSuccessDescription(
          ATSNames[DataSourceType.ORACLETALEO]
        )}
      />
      <div className={formViewStyles.formFieldExtra}>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          className={classNames(formViewStyles.actionButton)}
          onClick={onStart}
        >
          {phrases.ATS_INTEGRATION_SUCCESS_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
TaleoIntegrationSuccess.displayName = 'TaleoIntegrationSuccess';

export const TaleoIntegrationFailure: React.FC<{
  onRetry: () => void;
}> = ({ onRetry }) => {
  return (
    <div>
      <img className={styles.logoImage} src="/assets/images/taleo_logo.png" />
      <FormViewTitle
        title={phrases.ATS_INTEGRATION_FAILURE_TITLE}
        description={phrases.getATSIntegrationFailureDescription(
          ATSNames[DataSourceType.ORACLETALEO]
        )}
      />
      <div className={formViewStyles.formFieldExtra}>
        <Button
          variant={Button.variants.POSITIVE_CONFIRM}
          className={classNames(formViewStyles.actionButton)}
          onClick={onRetry}
        >
          {phrases.ATS_INTEGRATION_RETRY_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
TaleoIntegrationFailure.displayName = 'TaleoIntegrationFailure';
