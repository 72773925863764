import React, { useMemo } from 'react';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

import { enrichedByPIPL, LinkExtended } from 'domain/CandidateData';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { getIcon } from 'domain/CandidateData/CandidateContactDetails';
import { GA_LABEL_CLICK_SOCIAL_MEDIA } from 'constants/gaLabels';
import { ContactItem } from '../ContactItem/ContactItem';
import { toast } from '@air/third-party/toast';
import * as phrases from 'constants/phrases';

import styles from './LinksInfo.css';
import { Paragraph } from '@air/components';

export const LinkItem = ({ link }: { link: LinkExtended }) => {
  const isEnrichedByPIPL = useMemo(() => {
    return enrichedByPIPL(link.sources);
  }, [link.sources]);

  const onCopyIconClickHandler = () => {
    copy(link.url);
    toast.dark(phrases.CANDIDATE_PROFILE_CONTACTS_TOAST_TEXT_COPIED);
  };

  return (
    <ContactItem
      containerClassName={classNames(styles.wrapper)}
      onIconClicked={onCopyIconClickHandler}
      isEnrichedByPIPL={isEnrichedByPIPL}
    >
      <span className={styles.icon}>{getIcon(link.type)}</span>
      <Paragraph short>
        <a
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          onClick={() =>
            trackEvent({
              category: GACategory.ScreeningCandidateProfile,
              label: GA_LABEL_CLICK_SOCIAL_MEDIA,
            })
          }
        >
          <span className={styles.dataItem}>{link.type}</span>
        </a>
      </Paragraph>
    </ContactItem>
  );
};
