import React, { useCallback, useEffect, useState } from 'react';

import { KanbanCard } from 'components';
import { useKanbanContext } from 'providers/KanbanProvider';
import {
  customerProfileSelectors,
  kanbanSelectors,
  jobsProviderSelectors,
} from 'selectors';
import { getAmountOfJobsToLoad } from 'domain/Kanban/Kanban';
import { LoadMoreButton } from 'features/Kanban/LoadMoreButton/LoadMoreButton';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit, subscribe } from 'hooks/usePubSub';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';

import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';

import { usePaginatedSearch } from 'domain/Kanban/usePaginatedSearch';
import { emptyCounters } from 'features/Kanban/Kanban';
import {
  useJobsProviderContext,
  useJobsProviderMethods,
} from 'providers/JobsProvider';

export const Jobs: React.FC = React.memo(() => {
  const jobsList = useJobsProviderContext(jobsProviderSelectors.jobsList);
  const { fetchJobsList, removeJobDescription } = useJobsProviderMethods();

  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const currentSearchFilterName = useKanbanContext(
    kanbanSelectors.currentSearchFilterName
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const statsExpanded = useKanbanContext(kanbanSelectors.statsExpanded);
  const [isLoading, setIsLoading] = useState(false);

  const fetchJobsListCb = useCallback(
    async (page) => {
      setIsLoading(true);
      await fetchJobsList({ page });
      setIsLoading(false);
    },
    [fetchJobsList]
  );

  const loadMoreJobs = usePaginatedSearch(fetchJobsListCb, [
    currentSearchFilterName,
    fetchJobsList,
  ]);

  const computedItems = jobsList.items.map((item): KanbanCardT => {
    return {
      activeCount: {
        ...emptyCounters,
        failedCount: 0,
        totalCount: item.appliedCount,
      },
      passiveCount: {
        ...emptyCounters,
        failedCount: 0,
        totalCount: item.appliedPassiveCount,
      },
      locations: item.locations,
      searchId: item.externalId,
      status: item.status,
      name: item.title,
      ats: { id: dataSourceId, externalJobDescriptionId: item.externalId },
    };
  });

  const amountOfJobsLeftToLoad = getAmountOfJobsToLoad(
    jobsList.total,
    jobsList.items.length
  );

  useEffect(() => {
    const unsubCreateDraft = subscribe(
      APP_EVENTS.CREATE_DRAFT,
      removeJobDescription
    );
    const unsubCreateDraftFail = subscribe(
      APP_EVENTS.CREATE_DRAFT_FAILURE,
      loadMoreJobs
    );
    const unsubRemoveDraft = subscribe(APP_EVENTS.REMOVE_DRAFT, () =>
      loadMoreJobs(false)
    );

    return () => {
      unsubCreateDraft();
      unsubCreateDraftFail();
      unsubRemoveDraft();
    };
  }, [removeJobDescription, loadMoreJobs]);

  return (
    <div>
      {computedItems.map((item) => {
        const actionsMenuConfig = [
          {
            onClick: () => emit(APP_EVENTS.OPEN_JOB_PANEL, { item }),
            icon: 'move-to-drafts',
            title: phrases.KANBAN_ACTION_MOVE_TO_DRAFTS,
            disabled: isTrialExpired,
            closeMenuOnClick: true,
          },
        ];

        return (
          <KanbanCard
            key={item.searchId}
            item={item}
            actionsMenuConfig={actionsMenuConfig}
            areAllStatsExpanded={statsExpanded[phrases.KANBAN_SECTION_JOBS]}
            isMMEnabled={false}
            hideCountersBreakdown
            url={urls.makeJobUrl(
              item.ats.id,
              item.ats.externalJobDescriptionId
            )}
          />
        );
      })}
      {!!amountOfJobsLeftToLoad && (
        <LoadMoreButton
          isLoading={isLoading}
          onClick={loadMoreJobs}
          text={phrases.getLoadMoreJobsButtonTitle(amountOfJobsLeftToLoad)}
        />
      )}
    </div>
  );
});
