import React, {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { toast } from '@air/third-party/toast';
import R from '@air/third-party/ramda';
import { RouteChildrenProps } from 'react-router';
import {
  AcceptedFile,
  RejectedFile,
  RemoveApplicantsParamsT,
  RequisitionCounters,
  RequisitionFile,
  RequisitionsT,
  RequisitionsUploadStateT,
  UploadApplicantsContext,
  UploadApplicantsContextT,
  UploadStatus,
} from 'context/uploadApplicants';
import * as phrases from 'constants/phrases';
import {
  AtsExternalProfileListResponse,
  AtsExternalProfileWithTotalCount,
} from '@air/api/models';
import {
  deleteApplicant,
  fetchApplicants,
} from 'domain/Applicants/applicantsApi';

import {
  getCurrentItem,
  getCurrentRequisition,
  getProgressPercentage,
  getRequisitionCounters,
  isAnyFileUploading,
  MAX_FILES_AMOUNT,
  MAX_TAG_LENGTH,
  normalizeApplicantsFiles,
  normalizeRequisition,
  reconcileDuplicates,
  rejectFilesByAtsCandidateId,
  rejectFilesByTempId,
  rejectUploadRejectionsByTempId,
  RequisitionsProgressT,
  sliceString,
  updateFilesProgress,
  UploaderRejectionsT,
  getNewFilesForUpload,
} from 'utils/uploads';
import useBeforeUnload from 'react-use/lib/useBeforeUnload';
import {
  MessagePayloadAddFiles,
  MessagePayloadApplicantAborted,
  MessagePayloadApplicantRemoved,
  MessagePayloadApplicantsFetched,
  MessagePayloadFailedUploadsRemoved,
  MessagePayloadUploadCompleted,
  MessagePayloadUploadRejected,
  MessageQueueEvent,
  UploadEventType,
  useFileQueue,
} from 'hooks/useUploadMessageQueue';
import {
  FileItemInfo,
  ResumeUploaderEventType,
} from 'domain/ResumeUpload/types';
import { emit, subscribe } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';
import { isCurrentSearch, SearchListItemT } from 'domain/Kanban/Kanban';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';

type Props = RouteChildrenProps;

const addFilesToRequisition =
  (payload: MessagePayloadAddFiles) =>
  (requisitions: RequisitionsT): RequisitionsT => {
    const requisitionId = payload.requisitionId;
    const newFiles = payload.files;

    const currentRequisition = getCurrentRequisition(
      requisitions,
      requisitionId
    );
    const normalizedRequisition = normalizeRequisition({
      currentRequisition,
      newFiles,
    });
    return {
      ...requisitions,
      [requisitionId]: normalizedRequisition,
    };
  };

const updateSuccessfullyUploadedFiles =
  (payload: MessagePayloadUploadCompleted) =>
  (requisitions: RequisitionsT): RequisitionsT => {
    const { requisitionId, atsExternalProfileInfo, item } = payload;

    const currentRequisition = getCurrentRequisition(
      requisitions,
      requisitionId
    );
    const normalizedRequisition = normalizeRequisition({
      currentRequisition: currentRequisition,
      newFiles: [{ ...atsExternalProfileInfo, tempId: item.tempId }],
    });

    const files = reconcileDuplicates(
      [{ ...atsExternalProfileInfo, tempId: item.tempId }],
      normalizedRequisition?.files
    );
    return {
      ...requisitions,
      [requisitionId]: {
        ...normalizedRequisition,
        files,
        ...getRequisitionCounters(files),
        jobRequisitionId: requisitionId,
      },
    };
  };

const updateRejectedFiles =
  (payload: MessagePayloadUploadRejected) =>
  (requisitions: RequisitionsT): RequisitionsT => {
    const { requisitionId, rejectObject, item } = payload;

    const currentRequisition = getCurrentRequisition(
      requisitions,
      requisitionId
    );

    const normalizedRequisition = normalizeRequisition({
      currentRequisition: currentRequisition,
      uploadRejection: { ...rejectObject, tempId: item.tempId },
    });

    return {
      ...requisitions,
      [requisitionId]: normalizedRequisition,
    };
  };

const updateFetchedApplicants =
  ({ requisitionId, normalizedFiles }: MessagePayloadApplicantsFetched) =>
  (currentState: RequisitionsT): RequisitionsT => {
    const currentRequisition = getCurrentRequisition(
      currentState,
      requisitionId
    );
    const files = reconcileDuplicates(
      normalizedFiles,
      currentRequisition?.files?.filter(
        (item: RequisitionFile) => !item.atsCandidateId
      )
    );
    return {
      ...currentState,
      [requisitionId]: {
        files,
        ...getRequisitionCounters(files),
        jobRequisitionId: requisitionId,
      },
    };
  };

const removeApplicantFromRequisition =
  (payload: MessagePayloadApplicantRemoved) =>
  (requisitions: RequisitionsT): RequisitionsT => {
    const { requisitionId, atsCandidateId } = payload;

    const currentItem = getCurrentRequisition(requisitions, requisitionId);
    const files: RequisitionFile[] = rejectFilesByAtsCandidateId(
      atsCandidateId,
      currentItem?.files
    );
    return {
      ...requisitions,
      [requisitionId]: {
        ...currentItem,
        files,
        ...getRequisitionCounters(files),
      },
    };
  };
const removeFailedUploadsFromRequisition =
  ({ requisitionId }: MessagePayloadFailedUploadsRemoved) =>
  (currentState: RequisitionsT) => {
    const currentRequisition = getCurrentRequisition(
      currentState,
      requisitionId
    );
    const filesWithoutErrors = currentRequisition.files.reduce((memo, file) => {
      return R.isNullOrEmpty(file.errors) ? [file, ...memo] : memo;
    }, []);
    return {
      ...currentState,
      [requisitionId]: {
        files: filesWithoutErrors,
        ...getRequisitionCounters(filesWithoutErrors),
        jobRequisitionId: requisitionId,
      },
    };
  };

const removeFileByTempIdFromRequisitions =
  (payload: MessagePayloadApplicantAborted) =>
  (currentState: RequisitionsT): RequisitionsT => {
    const { requisitionId, tempId } = payload;
    const currentRequisition = getCurrentRequisition(
      currentState,
      requisitionId
    );
    const updatedFiles = rejectFilesByTempId(tempId, currentRequisition.files);
    return {
      ...currentState,
      [requisitionId]: {
        ...currentRequisition,
        files: updatedFiles,
        ...getRequisitionCounters(updatedFiles),
      },
    };
  };

const updateRequisitionCounters =
  (totalCount: number, jobRequisitionId: string) =>
  (
    requisitionsCounters: Map<string, RequisitionCounters>
  ): Map<string, RequisitionCounters> => {
    return new Map(requisitionsCounters).set(jobRequisitionId, {
      totalCount,
    });
  };

function requisitionReducer(
  state: RequisitionsT,
  event: MessageQueueEvent
): RequisitionsT {
  switch (event.type) {
    case UploadEventType.ADD_FILES: {
      return addFilesToRequisition(event.payload)(state);
    }
    case UploadEventType.UPLOAD_FILE_COMPLETED: {
      return updateSuccessfullyUploadedFiles(event.payload)(state);
    }
    case UploadEventType.UPLOAD_FILE_REJECTED: {
      return updateRejectedFiles(event.payload)(state);
    }
    case UploadEventType.APPLICANT_REMOVED: {
      return removeApplicantFromRequisition(event.payload)(state);
    }
    case UploadEventType.APPLICANT_ABORTED: {
      return removeFileByTempIdFromRequisitions(event.payload)(state);
    }
    case UploadEventType.FAILED_UPLOADS_REMOVED: {
      return removeFailedUploadsFromRequisition(event.payload)(state);
    }
    case UploadEventType.APPLICANTS_FETCHED: {
      return updateFetchedApplicants(event.payload)(state);
    }
    default:
      return state;
  }
}

export const UploadApplicantsProvider: React.FC<Props> = ({ children }) => {
  // this updates each time you go to a new job
  const [currentJobRequisitionId, setCurrentJobRequisitionId] =
    useState<string>(null);
  // counters only from back-end for applicants amount
  const [requisitionsCounters, setRequisitionsCounters] = useState<
    Map<string, RequisitionCounters>
  >(new Map());

  const [requisitions, dispatch] = useReducer<
    Reducer<RequisitionsT, MessageQueueEvent>
  >(requisitionReducer, {});

  const [requisitionsProgress, setRequisitionsProgress] =
    useState<RequisitionsProgressT>({});
  // rejected files on front-end from file uploader that are not uploaded to back-end
  // they disappear once the user reloads the page
  const [uploaderRejections, setUploaderRejections] =
    useState<UploaderRejectionsT>({});
  // requisitionsUploadState is set to true when files are dropped so that the uploads panel is open
  // it is set to false right after it's true in the views
  const [requisitionsUploadState, setRequisitionsUploadState] =
    useState<RequisitionsUploadStateT>({});
  const [isFetching, setFetching] = useState(false);

  const isUploadInProgress = useMemo(
    () => isAnyFileUploading(requisitions, requisitionsProgress),
    [requisitions, requisitionsProgress]
  );

  useBeforeUnload(isUploadInProgress, phrases.UPLOADING_LEAVE_PAGE);

  const getCurrentRequisitionId = useMemo(
    () => () => currentJobRequisitionId,
    [currentJobRequisitionId]
  );
  const {
    addFiles,
    removeFile,
    subscribe: fileQueueSubscribe,
    stopQueue,
  } = useFileQueue(getCurrentRequisitionId);

  const getApplicants = useCallback(
    async (atsId: number, jobRequisitionId: string) => {
      if (atsId && jobRequisitionId && !isFetching) {
        // if fetch request is pending do not fetch again
        setFetching(true);
        await fetchApplicants({
          jobDescriptionId: jobRequisitionId,
          atsId,
        }).fork(
          () => {},
          async (res: AtsExternalProfileListResponse) => {
            if (res?.content) {
              dispatch({
                type: UploadEventType.APPLICANTS_FETCHED,
                payload: {
                  requisitionId: jobRequisitionId,
                  normalizedFiles: normalizeApplicantsFiles(res.content),
                },
              });
            }
          }
        );
        setFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setUploadsStarted = useCallback((id: string, hasStarted: boolean) => {
    setRequisitionsUploadState((currentState: RequisitionsUploadStateT) => ({
      ...currentState,
      [id]: hasStarted,
    }));
  }, []);

  const [canAddTags, setAddTagsAbility] = useState(false);

  // files added by user and rejected by file uploader on front-end (not right size or format)
  const onDropRejected = useCallback(
    async (fileRejections: RejectedFile[]) => {
      setAddTagsAbility(false);
      if (fileRejections.length > MAX_FILES_AMOUNT) {
        toast.warning(phrases.getMaxFilesAmountError(MAX_FILES_AMOUNT), {
          delay: 500,
        });
        return;
      }
      if (!R.isNullOrEmpty(fileRejections)) {
        setUploadsStarted(currentJobRequisitionId, true);
        setUploaderRejections((currentState: UploaderRejectionsT) => {
          return {
            ...currentState,
            [currentJobRequisitionId]: [
              ...normalizeApplicantsFiles(fileRejections),
              ...getCurrentItem<RequisitionFile[]>(
                currentState,
                currentJobRequisitionId,
                []
              ),
            ],
          };
        });
      }
    },
    [currentJobRequisitionId, setUploadsStarted]
  );

  // upload progress of files being uploaded to back-end (no success or error yet)
  const setProgress = useCallback(async (event: ProgressEvent, options) => {
    // using jobRequisitionId saved in xhrActions here because the users can drop files to upload
    // and go to another requisition where new currentJobRequisition is set
    // but jobRequisitionId will have the correct value for the old requisition where the files were dropped
    const jobRequisitionId = options.xhrActions.jobRequisitionId;
    const progress = getProgressPercentage(event, options.xhrActions.size);
    // @ts-ignore
    setRequisitionsProgress((currentState: RequisitionsProgressT) => {
      const currentItem = getCurrentItem<ValueOf<RequisitionsProgressT>>(
        currentState,
        jobRequisitionId,
        {}
      );
      return {
        ...currentState,
        [jobRequisitionId]: {
          ...R.reject((item) => item === 100, currentItem),
          [options.xhrActions.tempId]: progress,
        },
      };
    });
  }, []);

  useEffect(() => {
    if (!fileQueueSubscribe) {
      return;
    }
    const unsubscribeProcessing = fileQueueSubscribe(
      ResumeUploaderEventType.ITEM_PROCESSING,
      ({ event, options }: { event: ProgressEvent; options: object }) => {
        setProgress(event, options);
      }
    );

    const unsubscribeProcessed = fileQueueSubscribe(
      ResumeUploaderEventType.ITEM_PROCESSED,
      (payload: {
        res: AtsExternalProfileWithTotalCount;
        item: FileItemInfo;
      }) => {
        dispatch({
          type: UploadEventType.UPLOAD_FILE_COMPLETED,
          payload: {
            requisitionId: payload.item.requisitionId,
            item: payload.item,
            atsExternalProfileInfo: payload.res.atsExternalProfileInfo,
            totalCount: payload.res.totalCount,
          },
        });
      }
    );

    const unsubscribeError = fileQueueSubscribe(
      ResumeUploaderEventType.ITEM_ERROR,
      ({ rej, item }: { rej: any; item: FileItemInfo }) => {
        if (!R.isNullOrEmpty(rej?.sentData)) {
          dispatch({
            type: UploadEventType.UPLOAD_FILE_REJECTED,
            payload: {
              requisitionId: item.requisitionId,
              item,
              rejectObject: rej,
            },
          });
        }
      }
    );

    const unsubscribeQueueStarted = fileQueueSubscribe(
      ResumeUploaderEventType.QUEUE_PAUSED,
      ({ requisitionId }: { requisitionId: number }) => {
        emit(APP_EVENTS.ADD_APPLICANTS_SCREENING, { requisitionId });
      }
    );

    return () => {
      unsubscribeProcessing();
      unsubscribeProcessed();
      unsubscribeError();
      unsubscribeQueueStarted();
    };
  }, [fileQueueSubscribe, setProgress, dispatch]);

  // stop queue on logout
  useEffect(() => {
    const unsubscribeOnLogout = subscribe(APP_EVENTS.LOGOUT, stopQueue);
    return () => {
      unsubscribeOnLogout();
    };
  }, [stopQueue]);

  // stop queue on remove draft
  useEffect(() => {
    const unsubscribeOnRemoveDraft = subscribe(
      APP_EVENTS.REMOVE_DRAFT,
      (item: SearchListItemT) => {
        if (isCurrentSearch(item)) {
          return stopQueue(item.jobRequisitionDetails.externalId);
        }
        stopQueue();
      }
    );
    return () => {
      unsubscribeOnRemoveDraft();
    };
  }, [stopQueue]);

  /* According to https://railsreactor.atlassian.net/browse/AR-7939
    for Screening and Edit Views all dropped files are not uploaded to the server immediately.
    Only after selecting category (Active or Passive) and after clicking on "Upload"
    button starts uploading to server. That's why all dropped files have to be saved
    in state for future upload to server.
  */
  const [filesForUpload, setFilesForUpload] = useState<FileItemInfo[]>([]);

  const getUserProvidedTag = useCallback(() => {
    if (canAddTags) {
      setAddTagsAbility(false);
      const promptValue = window.prompt('#TAG');
      if (R.isNil(promptValue)) return;
      return sliceString(promptValue, MAX_TAG_LENGTH);
    }
  }, [canAddTags]);

  // files added by user and accepted by file uploader on front-end on Screening
  const onDropAccepted = useCallback(
    (files: AcceptedFile[], atsId: number) => {
      if (!R.isNullOrEmpty(files) && currentJobRequisitionId) {
        setUploadsStarted(currentJobRequisitionId, true);
        const userTag = getUserProvidedTag();
        const newFiles: FileItemInfo[] = getNewFilesForUpload({
          files,
          atsId,
          currentJobRequisitionId,
          userTag,
        });
        setFilesForUpload((currentFiles) => currentFiles.concat(newFiles));
        dispatch({
          type: UploadEventType.ADD_FILES,
          payload: {
            requisitionId: currentJobRequisitionId,
            files: newFiles,
          },
        });
      }
    },
    [dispatch, setUploadsStarted, getUserProvidedTag, currentJobRequisitionId]
  );

  // for Screening, Edit Interview view. set upload to server started
  const [uploadToServerState, setUploadToServerState] =
    useState<RequisitionsUploadStateT>({});
  const setUploadsToServerStarted = useCallback(
    (id: string, hasStarted: boolean) => {
      setUploadToServerState((currentState: RequisitionsUploadStateT) => ({
        ...currentState,
        [id]: hasStarted,
      }));
    },
    []
  );

  const isUploadToServerInProgress = useMemo(() => {
    const currentRequisition = requisitions[currentJobRequisitionId];
    const isUploadStarted = uploadToServerState[currentJobRequisitionId];
    const isAllFilesUploaded =
      currentRequisition?.files.length ===
      currentRequisition?.uploadedCount + currentRequisition?.failedCount;
    return isUploadStarted && !isAllFilesUploaded;
  }, [uploadToServerState, currentJobRequisitionId, requisitions]);

  const onStartUpload = useCallback(
    (passive: boolean) => {
      const updatedFiles = filesForUpload.map((file) => ({
        ...file,
        passive: passive,
      }));
      addFiles({ requisitionId: currentJobRequisitionId, files: updatedFiles });
      setUploadsToServerStarted(currentJobRequisitionId, true);
    },
    [
      filesForUpload,
      currentJobRequisitionId,
      addFiles,
      setUploadsToServerStarted,
    ]
  );

  // clear filesForUpload after upload to server is finished
  useEffect(() => {
    if (!uploadToServerState[currentJobRequisitionId]) {
      setFilesForUpload([]);
    }
  }, [uploadToServerState, currentJobRequisitionId]);

  // files added by user and accepted by file uploader on front-end on DraftView
  const onDraftDropAccepted = useCallback(
    async (files: AcceptedFile[], atsId: number) => {
      if (!R.isNullOrEmpty(files) && currentJobRequisitionId) {
        const userTag = getUserProvidedTag();
        setUploadsStarted(currentJobRequisitionId, true);
        const newFiles: FileItemInfo[] = getNewFilesForUpload({
          files,
          atsId,
          currentJobRequisitionId,
          userTag,
        });

        dispatch({
          type: UploadEventType.ADD_FILES,
          payload: {
            requisitionId: currentJobRequisitionId,
            files: newFiles,
          },
        });

        addFiles({ requisitionId: currentJobRequisitionId, files: newFiles });
      }
    },
    [
      getUserProvidedTag,
      dispatch,
      addFiles,
      setUploadsStarted,
      currentJobRequisitionId,
    ]
  );

  const removeApplicant = useCallback(
    async ({
      atsCandidateId,
      tempId,
      uploadStatus,
      atsId,
      jobRequisitionId,
    }: RemoveApplicantsParamsT) => {
      // file was uploaded to back-end and has atsCandidateId
      if (atsCandidateId && uploadStatus === UploadStatus.isUploaded) {
        await deleteApplicant(atsCandidateId, jobRequisitionId, atsId).fork(
          () => {
            toast.error(phrases.REMOVE_CV_ERROR);
          },
          () => {
            dispatch({
              type: UploadEventType.APPLICANT_REMOVED,
              payload: {
                requisitionId: jobRequisitionId,
                atsCandidateId,
              },
            });
          }
        );
      } else if (
        // candidate was not uploaded to back-end
        !atsCandidateId ||
        (atsCandidateId && uploadStatus === UploadStatus.isFailed)
      ) {
        if (
          // file is uploading ot back-end - abort the request
          uploadStatus === UploadStatus.isUploading ||
          uploadStatus === UploadStatus.isPending
        ) {
          dispatch({
            type: UploadEventType.APPLICANT_ABORTED,
            payload: {
              requisitionId: jobRequisitionId,
              tempId,
            },
          });
          removeFile({ requisitionId: jobRequisitionId, fileId: tempId });
          setFilesForUpload((currentFiles) =>
            R.reduce(
              (result, file) => {
                return [...result, file.tempId !== tempId && file].filter(
                  Boolean
                );
              },
              [],
              currentFiles
            )
          );
        } else if (uploadStatus === UploadStatus.isFailed) {
          // file is rejected on front-end - remove from rejected uploads list
          setUploaderRejections((currentState: UploaderRejectionsT) => {
            return {
              ...currentState,
              [jobRequisitionId]: rejectUploadRejectionsByTempId(
                tempId,
                uploaderRejections,
                jobRequisitionId
              ),
            };
          });
          dispatch({
            type: UploadEventType.APPLICANT_ABORTED,
            payload: {
              requisitionId: jobRequisitionId,
              tempId,
            },
          });
        }
      }
    },
    [dispatch, removeFile, setFilesForUpload, uploaderRejections]
  );

  const removeFailedUploads = useCallback(() => {
    if (currentJobRequisitionId) {
      setUploaderRejections({});
      dispatch({
        type: UploadEventType.FAILED_UPLOADS_REMOVED,
        payload: { requisitionId: currentJobRequisitionId },
      });
    }
  }, [currentJobRequisitionId]);

  useEffect(() => {
    if (currentJobRequisitionId) {
      removeFailedUploads();
    }
  }, [removeFailedUploads, currentJobRequisitionId]);

  // remove failed uploads from list on job status change
  useEffect(() => {
    const onJobStatusChange = subscribe(
      APP_EVENTS.JOB_STATUS_CHANGE,
      removeFailedUploads
    );
    return () => {
      onJobStatusChange();
    };
  }, [removeFailedUploads]);

  useEffect(() => {
    if (
      uploadToServerState[currentJobRequisitionId] &&
      !isUploadToServerInProgress
    ) {
      setUploadsToServerStarted(currentJobRequisitionId, false);
    }
  }, [
    uploadToServerState,
    currentJobRequisitionId,
    isUploadToServerInProgress,
    setUploadsToServerStarted,
  ]);

  const updateCountersHandler = useCallback(
    (totalCount: number, jobRequisitionId: string) =>
      setRequisitionsCounters(
        updateRequisitionCounters(totalCount, jobRequisitionId)
      ),
    []
  );

  const contextValue = useMemo((): UploadApplicantsContextT => {
    const currentRequisitionUploaderRejections = getCurrentItem<
      RequisitionFile[]
    >(uploaderRejections, currentJobRequisitionId, []);
    const currentRequisition = getCurrentRequisition(
      requisitions,
      currentJobRequisitionId
    );
    const currentRequisitionFiles = [
      ...currentRequisitionUploaderRejections,
      ...updateFilesProgress(
        currentRequisition.files,
        requisitionsProgress,
        currentJobRequisitionId
      ),
    ];

    const computedRequisitions = {
      ...requisitions,
      [currentJobRequisitionId]: {
        ...currentRequisition,
        files: currentRequisitionFiles,
        ...getRequisitionCounters(currentRequisitionFiles),
      },
    };

    return {
      requisitionsCounters,
      currentJobRequisitionId,
      requisitions: computedRequisitions,
      requisitionsUploadState,
      uploadToServerState,
      isUploadToServerInProgress,
      isUploadInProgress,
      canAddTags,
      methods: {
        onDropRejected,
        onDropAccepted,
        removeApplicant,
        onStartUpload,
        onDraftDropAccepted,
        setUploadsToServerStarted,
        getApplicants,
        setCurrentJobRequisitionId,
        updateRequisitionCounters: updateCountersHandler,
        setUploadsStarted,
        setAddTagsAbility,
      },
    };
  }, [
    requisitionsProgress,
    requisitionsCounters,
    onDropRejected,
    onDropAccepted,
    removeApplicant,
    getApplicants,
    setCurrentJobRequisitionId,
    currentJobRequisitionId,
    requisitions,
    uploaderRejections,
    updateCountersHandler,
    uploadToServerState,
    isUploadToServerInProgress,
    requisitionsUploadState,
    setUploadsStarted,
    isUploadInProgress,
    setAddTagsAbility,
    canAddTags,
    onStartUpload,
    onDraftDropAccepted,
    setUploadsToServerStarted,
  ]);

  return (
    <UploadApplicantsContext.Provider value={contextValue}>
      {children}
    </UploadApplicantsContext.Provider>
  );
};

export const useUploadApplicantsContext = <Selected,>(
  selector: (state: UploadApplicantsContextT) => Selected
) => {
  return useContextSelector(UploadApplicantsContext, selector);
};

export const useUploadApplicantsMethods = () => {
  return useEqualContextSelector(
    UploadApplicantsContext,
    (state: UploadApplicantsContextT) => state.methods,
    R.shallowEqualObjects
  );
};

UploadApplicantsProvider.displayName = 'UploadApplicantsProvider';
