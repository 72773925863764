import React, { useCallback, useMemo, useState, useEffect } from 'react';
import R from '@air/third-party/ramda';
import { Field, FieldArray, FieldProps } from 'formik';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import classNames from 'classnames';
// import shared utils
import {
  isIdealExperienceDefined,
  isAcceptableExperienceDefined,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
// import utils
import {
  CriteriaCardViewProps,
  CriteriaDragObjectT,
  getDraggableCriteriaCard,
  getDraggableStackItem,
  getOnMainCardKeyDownCallback,
  SingleCriteriaItemDragObject,
  MAX_CARDS_IN_STACK,
  isDraggableMainCard,
  isDraggableStackItem,
  getOnStackCardKeyDownCallback,
  isExcludedItem,
} from 'components/Cards/cardsCommonCode';
import {
  canMergeLists,
  WithDefaultValueOnConditionalMounting,
  isValidNewOption,
  loadCompanySizesOptions,
  getIdsInUse,
  loadPaginatedCompanyAsyncOptions,
} from 'components/SearchCriteriaCards/utils';
// import shared hooks
// import hooks
import { useStateMachine } from '@air/hooks';
import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import {
  SearchCardContext,
  StateContext,
} from 'components/SearchCriteriaCards/hooks/SearchCardContext';
// import shared components
import {
  AcceptableExperienceWidget,
  Card,
  IdealExperienceWidget,
  RecentExperienceWidget,
} from '@air/components';
// import customer components
import { SelectRenderer } from '@air/components/Select/Select';
import {
  ExperienceSelect,
  ExperienceReadOnlyWidget,
} from 'components/Form/renderers/ExperienceSelect/ExperienceSelect';
// import types
import { SearchCriteriaImportanceEnum, CompanyExtendedType } from '@air/api';
import { FormSelectEntity } from '@air/components/Select/typings';
import {
  SearchCriteriaExperienceT,
  SearchCriteriaListValue,
} from 'domain/SearchCriteria/BaseSearchCriteria';
// import styles
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
// import constants
import * as phrases from 'constants/phrases';
import {
  CompanyCriteriaData,
  getImportanceValue,
  isExcludeCompany,
  isIncludeCompany,
  CompanyCriteriaDataListItem,
  getCompanyTitle,
  CardType,
  companyDataMapper,
} from 'domain/SearchCriteria';
import {
  locationTypeOptionsList,
  locationTypeOptions,
  LocationType,
  LocationTypeSelectedValueT,
  isCompanySizeType,
} from 'domain/SearchCriteria/CompanyCriteriaData';
import {
  getCardCriterionLabel,
  getMainCardTitle,
  getAddingLimits,
  getSearchCriteriaCardFooter,
  getCardStackSize,
  getTopStackListName,
  getMainCard,
  getCardListItemTooltip,
} from 'domain/SearchCriteria/cardHelpers';
import { IdealExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/IdealExperienceWidget';
import { AcceptableExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/AcceptableExperienceWidget';
import { RecentExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/RecentExperienceWidget';
import {
  CriteriaDraggableCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { generateCriteriaCardTopStackItems } from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import { CriteriaCollapsedCardProps } from 'components/CardWrappers/CriteriaCollapsedCard/CriteriaCollapsedCard';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import {
  CriteriaEditCardStackItems,
  generateTopStackItems,
  generateBottomStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';

import styles from './CompanyCard.css';
import { getCompanySizeDescription } from '@air/domain/Common/CompanySizeConverter';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';
import { useCacheContext, useCacheMethods } from '@air/providers/CacheProvider';
import { cacheSelectors } from 'selectors';
import { CriteriaImportanceExclusionSettings } from 'components/SearchCriteriaCards/components/CriteriaImportanceExclusionSettings/CriteriaImportanceExclusionSettings';

/*
  match an empty string or a string without whitespaces at the beginning
  so once typing the string, user can still clear the input
 */
const whitelistedchars = `a-zA-Z0-9&/,.!?#+\\-:;{}()\\[\\]\\|ú`;

const companyTitleRegex = new RegExp(
  `^$|^[${whitelistedchars}][${whitelistedchars} ]*$`
);

export const getCompanyCardListItemTooltip = (
  listItem: CompanyCriteriaDataListItem,
  originalTooltip?: string
) => {
  const companySizeTooltipText = isCompanySizeType(listItem?.type)
    ? getCompanySizeDescription(listItem)
    : '';

  return [originalTooltip, companySizeTooltipText].filter(Boolean).join(`\n`);
};

export const shouldShowCompanyLocationField = (
  companyData: CompanyCriteriaData
) => {
  const { exclude, list } = companyData;
  return !exclude && list.some((item) => isCompanySizeType(item?.type));
};

export const getCompanyTitleClass = (
  item: null | { type: CompanyExtendedType }
) => (item && isCompanySizeType(item.type) ? styles.companySizeItem : '');

export const getCompanyReadOnlyFields = (
  card: CompanyCriteriaData
): Array<JSX.Element | null> => {
  const isExperienceDefined = (experience: SearchCriteriaExperienceT) =>
    isIdealExperienceDefined(experience) &&
    isAcceptableExperienceDefined(experience);

  const mainCard = getMainCard<
    CompanyCriteriaData,
    SearchCriteriaListValue<CompanyCriteriaDataListItem>
  >(card);

  const companyLocationTitle = card.usOnly
    ? phrases.COMPANY_LOCATION_US_ONLY_PLACEHOLDER
    : phrases.COMPANY_LOCATION_WORLDWIDE_PLACEHOLDER;

  return [
    <Card.SearchCriteriaEditFormMainTitle
      className={getCompanyTitleClass(mainCard)}
      importance={getImportanceValue(card)}
      key="main-field"
      getTooltipText={getCardListItemTooltip.bind(null, card, mainCard)}
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {isExcludeCompany(card) && (
        <div
          className={classNames(
            cardCommonStyles.unwantedLabel,
            cardCommonStyles.lineupEditCardUnwantedLabel
          )}
        >
          #{phrases.UNWANTED_LABEL}
        </div>
      )}
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
    isIncludeCompany(card) ? (
      <IdealExperienceReadOnlyWidget
        values={card.experience}
        key="ideal-experience-field"
      />
    ) : null,
    isIncludeCompany(card) && isExperienceDefined(card.experience) ? (
      <AcceptableExperienceReadOnlyWidget
        values={card.experience}
        key="acceptable-experience-field"
      />
    ) : null,
    <RecentExperienceReadOnlyWidget
      value={card.recent}
      key="recent-experience-field"
    />,
    isIncludeCompany(card) && shouldShowCompanyLocationField(card) ? (
      <ExperienceReadOnlyWidget
        className={cardCommonStyles.companyLocationWidget}
        key="company-location-field"
      >
        {companyLocationTitle}
      </ExperienceReadOnlyWidget>
    ) : null,
  ];
};

const DraggableCompanyStackItem = getDraggableStackItem<CompanyCriteriaData>(
  DraggedSearchCriteriaCardType.singleCompanyItem
);
DraggableCompanyStackItem.displayName = 'DraggableCompanyStackItem';

export const CompanyCriteriaDraggableCollapsedCard: React.FC<
  CriteriaCardViewProps<CompanyCriteriaData> & CriteriaCollapsedCardProps
> = ({
  criteria,
  stackSize,
  handleDrop,
  handleSingleCriteriaDrop,
  ...props
}) => {
  const id = criteria.key;

  const acceptTypes = useMemo(() => {
    return [
      DraggedSearchCriteriaCardType.company,
      DraggedSearchCriteriaCardType.singleCompanyItem,
    ];
  }, []);

  const canDropCallback = useCallback(
    (
      draggedItem:
        | CriteriaDragObjectT<CompanyCriteriaData>
        | SingleCriteriaItemDragObject<CompanyCriteriaData>
    ) => {
      if (
        isDraggableStackItem(draggedItem) ||
        (isDraggableMainCard(draggedItem) && draggedItem.isDrawingMainCard)
      ) {
        const isExcluded = isExcludedItem(
          draggedItem,
          DraggedSearchCriteriaCardType.singleCompanyItem
        );

        return (
          id !== draggedItem.groupId &&
          stackSize < MAX_CARDS_IN_STACK &&
          criteria.exclude === isExcluded
        );
      }
      return (
        id !== draggedItem.id &&
        draggedItem.type === DraggedSearchCriteriaCardType.company &&
        criteria.exclude === draggedItem.criteria.exclude &&
        canMergeLists(draggedItem.criteria, criteria)
      );
    },
    [criteria, id, stackSize]
  );

  const dropCallback = useCallback(
    (droppedItem) => {
      if (
        droppedItem.type === DraggedSearchCriteriaCardType.singleCompanyItem ||
        droppedItem.isDrawingMainCard
      ) {
        handleSingleCriteriaDrop(id, droppedItem.id, droppedItem.groupId);
      } else {
        handleDrop(id, droppedItem.id);
      }
    },
    [handleDrop, handleSingleCriteriaDrop, id]
  );

  return (
    <CriteriaDraggableCollapsedCard
      {...props}
      criteria={criteria}
      stackSize={stackSize}
      draggableStackItem={DraggableCompanyStackItem}
      acceptTypes={acceptTypes}
      canDropCallback={canDropCallback}
      dropCallback={dropCallback}
    />
  );
};

CompanyCriteriaDraggableCollapsedCard.displayName =
  'CompanyCriteriaDraggableCollapsedCard';

const DraggableCompanyCriteriaCard =
  getDraggableCriteriaCard<CompanyCriteriaData>(
    CompanyCriteriaDraggableCollapsedCard,
    DraggedSearchCriteriaCardType.company
  );
DraggableCompanyCriteriaCard.displayName = 'DraggableCompanyCriteriaCard';

const DEFAULT_COMPANY_OPTIONS: {
  items: SearchCriteriaListValue<CompanyCriteriaDataListItem>[];
  total: number;
  page: number;
} = {
  items: [],
  total: 0,
  page: 0,
};

const CompanyCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: CompanyCriteriaData;
  optionsFilter: (value: any) => boolean;
  onUpdate: () => void;
  changeCardImportance: (
    criteria: CompanyCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
}> = ({
  namePrefix,
  values,
  optionsFilter,
  onUpdate,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const companySizesOptions = useCacheContext(
    cacheSelectors.companySizesOptions
  );
  const { updateCache } = useCacheMethods();

  const [companyOptions, setCompanyOptions] = useState(DEFAULT_COMPANY_OPTIONS);
  const [currentSearchInput, setCurrentSearchInput] = useState('');
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const companyCardConfig = cardsConfig[CardType.company];

  const isCompanyUnwanted = isExcludeCompany(values);
  const topStackListName = getTopStackListName(values);

  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    generateBottomStackItems(values),
  ];

  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(values);

  const dropDownBeforeElement = useMemo(() => {
    return (
      <div className={styles.companySizeDropDownHint}>
        {phrases.COMPANY_TYPE_DROPDOWN_HINT}
      </div>
    );
  }, []);

  const getCompanySizeOptions = useCallback(async () => {
    if (companySizesOptions) {
      return Promise.resolve(companySizesOptions);
    } else {
      return loadCompanySizesOptions().then(
        (
          options: {
            id: number;
            value: number;
            type: CompanyExtendedType;
            minSize: number;
            maxSize: number;
          }[]
        ) => {
          updateCache({
            companySizesOptions: options,
          });
          return Promise.resolve(options);
        }
      );
    }
  }, [companySizesOptions, updateCache]);

  useEffect(() => {
    getCompanySizeOptions();
  }, [getCompanySizeOptions]);

  const getOptionClassName = useCallback(
    ({ optionType }: { optionType: CompanyExtendedType }) => {
      if (isCompanySizeType(optionType)) {
        return styles.selectOptionCompanySize;
      }
      return '';
    },
    []
  );

  const getCompanyNameFromLabel = useCallback(
    (selectedOption, cardData) => {
      if (!selectedOption) return null;

      const { label, value, type } = selectedOption;
      if (isCompanySizeType(type) && cardData.usOnly) {
        // @ts-ignore
        return R.find(R.propEq('value', value), companySizesOptions)?.label;
      }
      return label;
    },
    [companySizesOptions]
  );

  const getCompanyOptions = useCallback(() => {
    if (currentSearchInput) {
      return companyOptions.items;
    } else {
      return companySizesOptions;
    }
  }, [companyOptions.items, companySizesOptions, currentSearchInput]);

  const searchSkillOptions = useCallback(
    ({
      value,
      page = 0,
      prevItems = [],
    }: {
      value?: string;
      page?: number;
      prevItems?: SearchCriteriaListValue<CompanyCriteriaDataListItem>[];
    }) => {
      return loadPaginatedCompanyAsyncOptions(
        {
          value,
          page,
          excludeId: getIdsInUse(CardType.company)([values]),
        },
        ({ items, total }) => {
          setCompanyOptions({
            items: [...prevItems, ...R.map(companyDataMapper, items)],
            total,
            page,
          });
          setIsOptionsLoading(false);
        }
      );
    },
    [values]
  );

  const loadMoreOptions = useCallback(
    () =>
      searchSkillOptions({
        value: currentSearchInput,
        page: R.isEmpty(companyOptions.items) ? 0 : companyOptions.page + 1,
        prevItems: companyOptions.items,
      }),
    [currentSearchInput, companyOptions, searchSkillOptions]
  );

  const onChangeInputValue = useCallback(
    (value) => {
      setCompanyOptions(DEFAULT_COMPANY_OPTIONS);
      setCurrentSearchInput(value);
      if (value) {
        setIsOptionsLoading(true);
        searchSkillOptions({ value });
      }
    },
    [searchSkillOptions]
  );

  const selectCompanyValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        const onChange = props.onChange
          ? props.onChange
          : (value: any) => {
              const name = getCompanyNameFromLabel(value, values);
              form.setFieldValue(field.name, { ...value, name });
            };

        const isCompanySizeItem =
          field.value && isCompanySizeType(field.value.type);

        const shouldAddUsOnlyLabel = (values: CompanyCriteriaData) =>
          isCompanySizeItem &&
          shouldShowCompanyLocationField(values) &&
          values.usOnly;

        const updatedField = {
          ...field,
          value: {
            ...field.value,
            label: shouldAddUsOnlyLabel(values)
              ? phrases.GET_US_ONLY_COMPANY_TITLE(field?.value?.name)
              : field?.value?.name,
          },
        };

        return (
          <SelectRenderer
            isDisabled={isReadOnly}
            {...updatedField}
            onKeyDown={getOnStackCardKeyDownCallback}
            getSingleValueTooltipProps={
              companyCardConfig.getStackItemTooltipProps
            }
            {...props}
            getDropDownItemTooltipProps={
              companyCardConfig.getSelectDropDownOptionTooltipProps
            }
            className={classNames(styles.companySelect, {
              [styles.companySizeItem]: isCompanySizeItem,
            })}
            getTooltipText={getCardListItemTooltip.bind(
              null,
              values,
              field.value as CompanyCriteriaDataListItem
            )}
            optionClassName={getOptionClassName}
            inputRegex={companyTitleRegex}
            isClearable
            filterOption={optionsFilter}
            isValidNewOption={isValidNewOption(CardType.company)(form.values)}
            onChange={onChange}
            isLoading={isOptionsLoading}
            options={getCompanyOptions()}
            infiniteScrollOptions={{
              hasMore: companyOptions.items.length < companyOptions.total,
              threshold: 150,
              loadMore: loadMoreOptions,
            }}
            onInputChange={onChangeInputValue}
            defaultOptions={true}
            autoFocus={true}
            openMenuOnFocus={!field.value}
            openMenuOnClick={true}
            dropDownBefore={currentSearchInput ? null : dropDownBeforeElement}
            multilineInput
            creatable
          />
        );
      },
    [
      values,
      isReadOnly,
      companyCardConfig.getStackItemTooltipProps,
      companyCardConfig.getSelectDropDownOptionTooltipProps,
      getOptionClassName,
      optionsFilter,
      isOptionsLoading,
      getCompanyOptions,
      companyOptions.items.length,
      companyOptions.total,
      loadMoreOptions,
      onChangeInputValue,
      currentSearchInput,
      dropDownBeforeElement,
      getCompanyNameFromLabel,
    ]
  );

  const titleLabel = useMemo(() => {
    return cardsConfig[values.cardType].getCriteriaCardTitleLabel?.(values);
  }, [values]);

  return (
    <CriteriaEditCard
      className={classNames({
        [cardCommonStyles.unwantedCard]: isCompanyUnwanted,
      })}
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      stackItemControl={selectCompanyValueControl}
      collapsibleArea={
        <CriteriaImportanceExclusionSettings
          namePrefix={namePrefix}
          cardData={values}
          onImportanceChange={changeCardImportance}
          onExclude={onUpdate}
        />
      }
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const companyTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];

              return companyTitlesList
                .slice(0, 1)
                .map((val: FormSelectEntity) => {
                  return (
                    <Field
                      key={R.prop('value', val) || 'new'}
                      name={`${namePrefix}.${topStackListName}.0`}
                    >
                      {({ field, form }: FieldProps) => (
                        <div
                          ref={mainCriteriaNameFieldRef}
                          className={cardCommonStyles.titleFieldWithImportance}
                        >
                          {titleLabel}
                          {selectCompanyValueControl({
                            field,
                            form,
                            className: cardCommonStyles.multilineTitle,
                            isDisabled: isImportanceSectionOpened || isReadOnly,
                            focusField: !isReadOnly,
                            onKeyDown: getOnMainCardKeyDownCallback(
                              field,
                              arrayHelpers,
                              !isMaxListSizeReached
                            ),
                            onChange: async (selectedOption?: {
                              label: string;
                            }) => {
                              const name = getCompanyNameFromLabel(
                                selectedOption,
                                values
                              );
                              const value = selectedOption
                                ? { ...selectedOption, name }
                                : selectedOption;
                              await form.setFieldValue(field.name, value);
                              updateMainFieldHandler(value);
                            },
                            getSingleValueTooltipProps:
                              companyCardConfig.getMainCardTooltipProps,
                          })}
                          {importanceIndicator}
                        </div>
                      )}
                    </Field>
                  );
                });
            }}
          </FieldArray>
          <Field name={`${namePrefix}.experience`}>
            {({ field, form }: FieldProps) => {
              return (
                <WithDefaultValueOnConditionalMounting
                  indicator={!isCompanyUnwanted}
                  defaultValue={null}
                  field={field}
                  form={form}
                >
                  <>
                    <IdealExperienceWidget
                      isReadOnly={isReadOnly}
                      values={field.value}
                      onChangeValues={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                    />
                    {isIdealExperienceDefined(field.value) && (
                      <AcceptableExperienceWidget
                        isReadOnly={isReadOnly}
                        values={field.value}
                        onChangeValues={(value) =>
                          form.setFieldValue(field.name, value)
                        }
                      />
                    )}
                  </>
                </WithDefaultValueOnConditionalMounting>
              );
            }}
          </Field>
          <Field name={`${namePrefix}.recent`}>
            {({ field, form }: FieldProps) => (
              <RecentExperienceWidget
                isReadOnly={isReadOnly}
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
                className={classNames({
                  [cardCommonStyles.unwantedCard]: isCompanyUnwanted,
                })}
              />
            )}
          </Field>
          <Field name={`${namePrefix}.usOnly`}>
            {({ field, form }: FieldProps) => {
              const value = values.usOnly
                ? locationTypeOptions[LocationType.USONLY]
                : locationTypeOptions[LocationType.WORLDWIDE];

              const updatedField = {
                ...field,
                value,
              };
              return (
                <WithDefaultValueOnConditionalMounting
                  indicator={shouldShowCompanyLocationField(values)}
                  defaultValue={null}
                  field={field}
                  form={form}
                >
                  <ExperienceSelect
                    isDisabled={isReadOnly}
                    {...updatedField}
                    className={cardCommonStyles.detailsRequiredField}
                    options={locationTypeOptionsList}
                    onChange={(option: LocationTypeSelectedValueT) => {
                      form.setFieldValue(
                        field.name,
                        option.value === LocationType.USONLY
                      );
                    }}
                  />
                </WithDefaultValueOnConditionalMounting>
              );
            }}
          </Field>
        </>
      )}
    </CriteriaEditCard>
  );
};

CompanyCriteriaCardEdit.displayName = 'CompanyCriteriaCardEdit';

type CompanyCriteriaCardProps = {
  namePrefix: string;
  cardData: CompanyCriteriaData;
  optionsFilter: (value: any) => boolean;

  onUpdate: () => void;
  onRemove: (value?: string | number) => void;
  changeCardImportance: (
    criteria: CompanyCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;

  onRemoveStackItem: (value: string | number, list?: string) => void;
  importanceSectionOrder: number;

  handleDrop: (id: string | number, itemId: string | number) => void;
  handleSingleCriteriaDrop: (
    id: string | number,
    itemId: string | number,
    groupId: string | number
  ) => void;
  isReadOnly?: boolean;
};

export const CompanyCriteriaCard: React.FC<CompanyCriteriaCardProps> = ({
  namePrefix,
  cardData,
  importanceSectionOrder,
  optionsFilter,
  onUpdate,
  onRemove,
  onRemoveStackItem,
  handleDrop,
  handleSingleCriteriaDrop,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const mainCard = getMainCard<
    CompanyCriteriaData,
    SearchCriteriaListValue<CompanyCriteriaDataListItem>
  >(cardData);

  const cardTitle = getCompanyTitle(mainCard?.name, {
    usOnly: cardData.usOnly,
    type: mainCard?.type,
  });

  const [cardFooter] = getSearchCriteriaCardFooter(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const stackSize = getCardStackSize(cardData);
  const topStackItems = [
    ...R.map(
      (item) => ({
        ...item,
        className: getCompanyTitleClass(item),
      }),
      generateCriteriaCardTopStackItems(cardData)
    ),
  ];
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: !isNewCard,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };
  const isCompanyUnwanted = isExcludeCompany(cardData);

  const titleLabel = useMemo(() => {
    return cardsConfig[cardData.cardType].getCriteriaCardTitleLabel?.(cardData);
  }, [cardData]);

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <StateContext.Consumer>
            {({ states }) => {
              const canDrawMainCardFromStack =
                stackSize > 0 && states.isStackOpened;
              return (
                <DraggableCompanyCriteriaCard
                  titleLabel={titleLabel}
                  title={cardTitle}
                  footerText={cardFooter}
                  stackSize={stackSize}
                  stackItems={[topStackItems]}
                  cardClasses={{
                    mainCardClass: getCompanyTitleClass(mainCard),
                    beginDragClass: getCompanyTitleClass(mainCard),
                    wrapperClass: classNames({
                      [cardCommonStyles.unwantedCard]: isCompanyUnwanted,
                    }),
                    footerClass: cardCommonStyles.experienceYears,
                  }}
                  importance={cardImportance}
                  exclude={isExcludeCompany(cardData)}
                  cardLabel={cardLabel}
                  criteria={cardData}
                  importanceSectionOrder={importanceSectionOrder}
                  onRemove={onRemove}
                  onRemoveStackItem={onRemoveStackItem}
                  handleDrop={handleDrop}
                  handleSingleCriteriaDrop={handleSingleCriteriaDrop}
                  canDrawMainCardFromStack={canDrawMainCardFromStack}
                  initialCardStatus={cardData.initialCardStatus}
                />
              );
            }}
          </StateContext.Consumer>
        ) : (
          <CompanyCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            optionsFilter={optionsFilter}
            onUpdate={onUpdate}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
CompanyCriteriaCard.displayName = 'CompanyCriteriaCard';
