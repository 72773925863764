import { CacheContextT } from '@air/context';

export const cache = (state: CacheContextT) => state.cache;
export const mmRejectionReasons = (state: CacheContextT) =>
  state.cache.mmRejectionReasons;
export const degreeOptions = (state: CacheContextT) =>
  state.cache.degreeOptions;
export const majorOptions = (state: CacheContextT) => state.cache.majorOptions;
export const companySizesOptions = (state: CacheContextT) =>
  state.cache.companySizesOptions;
export const industryOptions = (state: CacheContextT) =>
  state.cache.industryOptions;
export const helpCenterForm = (state: CacheContextT) =>
  state.cache.helpCenterForm;
