import React, { useCallback, useMemo, useState } from 'react';
import R from '@air/third-party/ramda';
import { useStateMachine } from '@air/hooks';
import * as phrases from 'constants/phrases';
import classNames from 'classnames';

import { SearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';

import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';

import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import {
  makeDraggableEducationCard,
  getEducationFieldsForCandidateCollapsedMainCard,
} from 'components/Cards/educationCommonCode';
import {
  CriteriaCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { withCustomDrag } from 'components/CustomDragLayer/CustomDragLayer';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from 'formik';
import { FormSelectEntity } from '@air/components/Select/typings';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import { Card } from 'components';
import { EquivalentExperienceWidget, SelectRenderer } from '@air/components';
import {
  EquivalentExperienceField,
  EquivalentExperienceReadOnlyWidget,
} from '@air/components/SelectRangeWidgets/EquivalentExperienceWidget';
import {
  getOnMainCardKeyDownCallback,
  getOnStackCardKeyDownCallback,
  MAX_CARDS_IN_STACK,
} from 'components/Cards/cardsCommonCode';
import {
  CardType,
  DegreeCriteriaData,
  getImportanceValue,
} from 'domain/SearchCriteria';
import {
  getMainCardTitle,
  getCardTitle,
  getStackToggleItemsClasses,
  getTopStackSize,
  getBottomStackSize,
  getAddingLimits,
  getCardStackSize,
  getCardCriterionLabel,
  getTopStackListName,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import {
  CandidateCriteriaListItem,
  CandidateDegreeListItem,
  CandidateDegreeData,
} from 'domain/CandidateData';
import {
  generateCriteriaCardTopStackItems,
  generateCriteriaCardBottomStackItems,
} from 'components/CardWrappers/CriteriaCollapsedCard/сriteriaCollapsedCardHelpers';
import styles from './DegreeCard.css';
import degreeStyles from 'components/Cards/DegreeCard/DegreeCard.css';
import {
  generateTopStackItems,
  generateBottomStackItems,
  CriteriaEditCardStackItem,
  CriteriaEditCardStackItems,
} from 'components/CardWrappers/CriteriaEditCard/criteriaEditCardHelpers';
import {
  StackItemRenderer,
  CriteriaEditCardChildrenProps,
} from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { ApproximateEquivalentExperience } from '@air/components/Period/Period';
import { isCardInitialStatusNew } from '@air/domain/Common/Cards';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

const DraggableDegreeCriteriaCard = makeDraggableEducationCard(
  withCustomDrag(CriteriaCollapsedCard),
  DraggedSearchCriteriaCardType.degree
);
DraggableDegreeCriteriaCard.displayName = 'DraggableDegreeCriteriaCard';

export const getDegreeFieldsForCandidateCollapsedMainCard = (
  mainCard: CandidateDegreeListItem,
  allCardData: CandidateDegreeData
): Array<JSX.Element | null> => {
  return mainCard.equivalent
    ? [
        <Card.TitleLabel
          key="title-label"
          text={<ApproximateEquivalentExperience experience={mainCard} />}
          className={styles.candidateCardEquivalentExperienceYearsLabel}
        />,
        <Card.Title
          resizeable
          flexGrow={false}
          className={styles.candidateCardEquivalentExperienceYearsTitle}
          title={`${phrases.EQUIVALENT_EXPERIENCE_YEAR_TEXT}${
            mainCard.equivalentYears === 1 ? '' : 's'
          }`}
          key="card-title"
        />,
        <Card.Footer
          text={phrases.EQUIVALENT_EXPERIENCE_CARD_FOOTER_TEXT}
          key="card-footer"
        />,
      ]
    : getEducationFieldsForCandidateCollapsedMainCard(mainCard, allCardData);
};

export const getDegreeFieldsForCandidateCollapsedStackCard = (
  stackCard: CandidateCriteriaListItem,
  allCardData: any
): JSX.Element => {
  return (
    <Card.Title
      flexGrow={false}
      title={getCardTitle({
        cardType: allCardData.cardType,
        label: stackCard.label,
      })}
    />
  );
};

export const getDegreeReadOnlyFields = (
  card: DegreeCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
  ];
};

export const getDegreeReadOnlyEquivalentExperience = (
  card: DegreeCriteriaData
): JSX.Element => {
  return (
    <EquivalentExperienceReadOnlyWidget value={card.equivalentExperience} />
  );
};

export const getDegreeEquivalentExperienceStackItem = (
  card: DegreeCriteriaData
): JSX.Element => {
  if (card.equivalentExperience) {
    return (
      <Card.SearchCriteriaStackItem
        key="equivalent-experience"
        className={styles.degreeEquivalentExperienceStackItem}
        label={phrases.EQUIVALENT_EXPERIENCE_STACK_LABEL}
        labelClassName={styles.equivalentExperienceLabel}
      >
        <EquivalentExperienceField
          className={styles.equivalentExperienceStackItem}
          value={card.equivalentExperience}
        />
      </Card.SearchCriteriaStackItem>
    );
  }
  return null;
};

const DegreeCriteriaCardEdit: React.FC<{
  namePrefix: string;
  values: DegreeCriteriaData;
  changeCardImportance: (
    criteria: DegreeCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  optionsFilter: (value: any) => boolean;
  options: any[];
  removeEquivalentExperience: () => void;
  isReadOnly?: boolean;
}> = ({
  namePrefix,
  options,
  optionsFilter,
  values,
  changeCardImportance,
  removeEquivalentExperience,
  isReadOnly = false,
}) => {
  const degreeCardConfig = cardsConfig[CardType.degree];
  const [
    isEquivalentExperienceControlVisible,
    setEquivalentExperienceControlVisibility,
  ] = useState(!R.isNil(values.equivalentExperience));

  const isEquivalentExperienceDropdownOpened =
    !R.isNil(values.equivalentExperience) !==
    isEquivalentExperienceControlVisible;

  const onRemoveEquivalentExperience = useCallback(() => {
    removeEquivalentExperience();
    setEquivalentExperienceControlVisibility(false);
  }, [removeEquivalentExperience]);

  const topStackListName = getTopStackListName(values);
  const stackItems: CriteriaEditCardStackItems = [
    generateTopStackItems(values),
    [
      isEquivalentExperienceControlVisible
        ? {
            key: 'equivalent-experience',
            className: degreeStyles.degreeEquivalentExperienceStackItem,
            label: phrases.EQUIVALENT_EXPERIENCE_STACK_LABEL,
            labelClassName: degreeStyles.equivalentExperienceLabel,
            onLabelClick: R.noop,
            onRemoveItem: onRemoveEquivalentExperience,
            children: (
              <Field name={`${namePrefix}.equivalentExperience`}>
                {({ field, form }: FieldProps) => (
                  <EquivalentExperienceWidget
                    isReadOnly={isReadOnly}
                    className={styles.equivalentExperienceWidget}
                    value={field.value}
                    onChangeValue={(value) =>
                      form.setFieldValue(field.name, value)
                    }
                    isDropdownOpened={isEquivalentExperienceDropdownOpened}
                  />
                )}
              </Field>
            ),
          }
        : null,
      ...generateBottomStackItems(values),
    ].filter(Boolean),
  ];

  const onSelectOptionChanged = useCallback((field, form) => {
    return (selectedOption: any, selectRef: any) => {
      if (
        selectedOption?.value === phrases.EQUIVALENT_EXPERIENCE_DROPDOWN_LABEL
      ) {
        setEquivalentExperienceControlVisibility(true);
        selectRef.select.blur();
      } else {
        return form.setFieldValue(field.name, selectedOption);
      }
    };
  }, []);

  const selectOptions = useMemo(() => {
    return [
      ...options,
      ...(isEquivalentExperienceControlVisible
        ? []
        : [
            {
              label: phrases.EQUIVALENT_EXPERIENCE_DROPDOWN_LABEL,
              value: phrases.EQUIVALENT_EXPERIENCE_DROPDOWN_LABEL,
            },
          ]),
    ];
  }, [isEquivalentExperienceControlVisible, options]);

  const stackSize = getTopStackSize(values) + getBottomStackSize(values);

  const maxListSize =
    MAX_CARDS_IN_STACK - Number(isEquivalentExperienceControlVisible);
  const { isMaxListSizeReached, canAddNewStackItem } = getAddingLimits(
    values,
    maxListSize
  );

  const selectDegreeValueControl = useMemo(
    () =>
      ({ field, form, ...props }: any) => {
        return (
          <SelectRenderer
            isDisabled={isReadOnly}
            {...field}
            onKeyDown={getOnStackCardKeyDownCallback}
            getSingleValueTooltipProps={
              degreeCardConfig.getStackItemTooltipProps
            }
            {...props}
            getDropDownItemTooltipProps={
              degreeCardConfig.getSelectDropDownOptionTooltipProps
            }
            autoFocus={props.focusField || !field.value}
            hideSelectedOptions
            multilineInput
            options={selectOptions}
            isClearable
            filterOption={optionsFilter}
            className={classNames(
              'Select__degreeWidget',
              field.className,
              props.className
            )}
            onChange={onSelectOptionChanged(field, form)}
          />
        );
      },
    [
      degreeCardConfig,
      optionsFilter,
      onSelectOptionChanged,
      selectOptions,
      isReadOnly,
    ]
  );

  const stackItemRenderers: [null, StackItemRenderer] = useMemo(() => {
    return [
      null,
      (
        item: CriteriaEditCardStackItem,
        arrayHelpers: FieldArrayRenderProps,
        listIndex: number,
        defaultRenderer: StackItemRenderer
      ) => {
        // we need to adjust `listIndex` because we put custom equivalent experience item
        // into generated bottom stack items list in this component
        return defaultRenderer(
          item,
          arrayHelpers,
          listIndex - Number(isEquivalentExperienceControlVisible)
        );
      },
    ];
  }, [isEquivalentExperienceControlVisible]);

  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      stackItems={stackItems}
      canAddNewStackItem={canAddNewStackItem}
      isMaxListSizeReached={isMaxListSizeReached}
      changeCardImportance={changeCardImportance}
      stackItemControl={selectDegreeValueControl}
      stackItemRenderers={stackItemRenderers}
    >
      {({
        isImportanceSectionOpened,
        mainCriteriaNameFieldRef,
        updateMainFieldHandler,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <FieldArray name={`${namePrefix}.${topStackListName}`}>
            {(arrayHelpers) => {
              const idealTitlesList: FormSelectEntity[] =
                R.path(
                  arrayHelpers.name.split('.'),
                  arrayHelpers.form.values
                ) || [];
              const mainField = R.head(idealTitlesList);

              return (
                <Field
                  key={R.prop('value', mainField) || 'new'}
                  name={`${namePrefix}.${topStackListName}.0`}
                >
                  {({ field, form }: FieldProps) => (
                    <div
                      className={cardCommonStyles.titleFieldWithImportance}
                      ref={mainCriteriaNameFieldRef}
                    >
                      {selectDegreeValueControl({
                        field,
                        form,
                        placeholder:
                          // we check if card has no equivalentExperience because it adds
                          // one more stack item to the card and thus effects the total amount of stack items
                          stackSize === 0 && !values.equivalentExperience
                            ? phrases.ANY_DEGREE_LABEL
                            : phrases.SELECT_PLACEHOLDER,
                        isDisabled: isImportanceSectionOpened || isReadOnly,
                        onChange: async (value: { label: string }) => {
                          await form.setFieldValue(field.name, value);
                          updateMainFieldHandler(value);
                        },
                        focusField: !isReadOnly,
                        onKeyDown: getOnMainCardKeyDownCallback(
                          field,
                          arrayHelpers,
                          canAddNewStackItem
                        ),
                        getSingleValueTooltipProps:
                          degreeCardConfig.getMainCardTooltipProps,
                      })}
                      {importanceIndicator}
                    </div>
                  )}
                </Field>
              );
            }}
          </FieldArray>
        </>
      )}
    </CriteriaEditCard>
  );
};

export const DegreeCriteriaCard: React.FC<any> = ({
  namePrefix,
  cardData,
  importance,
  importanceSectionOrder,
  onUpdate,
  onRemove,
  options,
  setFieldValue,
  optionsFilter,
  onRemoveStackItem,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const topStackItems = generateCriteriaCardTopStackItems(cardData);
  const bottomStackItems = [
    ...(!R.isNil(cardData.equivalentExperience)
      ? [
          {
            key: 'equivalent-experience',
            className: styles.degreeEquivalentExperienceStackItem,
            label: phrases.EQUIVALENT_EXPERIENCE_STACK_LABEL,
            labelClassName: styles.equivalentExperienceLabel,
            onRemoveItem: (event: any, removeItem: any) => {
              event.stopPropagation();
              removeItem(phrases.EQUIVALENT_EXPERIENCE_DROPDOWN_LABEL);
            },
            children: (
              <EquivalentExperienceField
                className={styles.equivalentExperienceStackItem}
                value={cardData.equivalentExperience}
              />
            ),
          },
        ]
      : []),
    ...generateCriteriaCardBottomStackItems(cardData),
  ];
  const stackSize = getCardStackSize(cardData);
  const cardTitle = getMainCardTitle(cardData);
  const cardLabel = getCardCriterionLabel(cardData);
  const searchOrder = cardData.idx;
  const isNewCard = isCardInitialStatusNew(cardData.initialCardStatus);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: stackSize > 0,
      updated: true, // TODO: refactor this flag for this card
      isReadOnly,
    }
  );

  const removeEquivalentExperience = useCallback(() => {
    setFieldValue(`${namePrefix}.equivalentExperience`, null);
  }, [setFieldValue, namePrefix]);

  const onRemoveStackItemCb = useCallback(
    (itemValue, ...args) => {
      if (itemValue === phrases.EQUIVALENT_EXPERIENCE_DROPDOWN_LABEL) {
        removeEquivalentExperience();
      } else {
        onRemoveStackItem(itemValue, ...args);
      }
    },
    [onRemoveStackItem, removeEquivalentExperience]
  );

  const cardMethods = { onUpdate, onRemove };
  const stackClasses = getStackToggleItemsClasses(cardData);

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={!isNewCard}>
        {isCardInViewMode(cardState) ? (
          <DraggableDegreeCriteriaCard
            criteria={cardData}
            id={cardData.key}
            title={cardTitle}
            stackItems={[topStackItems, bottomStackItems]}
            cardClasses={{
              footerClass: cardCommonStyles.experienceYears,
            }}
            stackClasses={stackClasses}
            stackSize={stackSize}
            cardLabel={cardLabel}
            onRemove={onRemove}
            onRemoveStackItem={onRemoveStackItemCb}
            importanceSectionOrder={importanceSectionOrder}
            searchOrder={searchOrder}
            importance={importance}
            initialCardStatus={cardData.initialCardStatus}
          />
        ) : (
          <DegreeCriteriaCardEdit
            namePrefix={namePrefix}
            options={options}
            optionsFilter={optionsFilter}
            values={cardData}
            changeCardImportance={changeCardImportance}
            removeEquivalentExperience={removeEquivalentExperience}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
DegreeCriteriaCard.displayName = 'DegreeCriteriaCard';
