import React from 'react';
import * as phrases from '@air/constants/phrases';
import { toast } from '@air/third-party/toast';
import { Button } from '@air/components';

function reloadPage() {
  window.location.href = location.href;
}

const FallbackReloadButton: React.FC = () => {
  return <Button onClick={reloadPage}>{phrases.APPLICATION_RELOAD}</Button>;
};

export const defaultLazyImportFallback = (
  fallback: React.ReactNode = FallbackReloadButton
) => {
  toast.warning(phrases.APPLICATION_UPDATE_NOTIFICATION, {
    autoClose: false,
    closeButton: false,
  });
  return { default: fallback } as { default: React.NamedExoticComponent<any> };
};
