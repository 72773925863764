import * as ApiModel from '@air/api';
import genId from '@air/utils/uniqueId';
import {
  AddCriteriaInitials,
  BaseSearchCriteriaData,
  SearchCriteriaListValue,
} from './BaseSearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { criteriaImportanceOptions } from 'constants/skills';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

export interface QuestionCriteriaData extends BaseSearchCriteriaData {
  cardType: CardType.question;
  question: SearchCriteriaListValue<{ value: string }>;
}

export const isQuestion = (
  item: BaseSearchCriteriaData
): item is QuestionCriteriaData => item.cardType === CardType.question;

export const getQuestionDefaults = ({
  importance = ApiModel.SearchCriteriaImportanceEnum.IMPORTANT,
  idx,
  initialCardStatus = InitialCardStatusEnum.IsNewEdit,
}: AddCriteriaInitials): QuestionCriteriaData => ({
  idx,
  id: null,
  key: genId(),
  initialCardStatus,
  question: null,
  importance: criteriaImportanceOptions[importance],
  cardType: CardType.question,
});

export const mapQuestionToQuestionCriteriaData = (
  question: ApiModel.AdditionalQuestion
): QuestionCriteriaData => ({
  idx: question.idx,
  id: question.idx,
  key: genId(),
  initialCardStatus: InitialCardStatusEnum.ExistingCard,
  question: { label: question.question, value: question.question },
  importance: criteriaImportanceOptions[question.importance],
  cardType: CardType.question,
});

export const mapQuestionCriteriaDataToQuestion = (
  question: QuestionCriteriaData
): ApiModel.AdditionalQuestion => ({
  idx: question.idx,
  question: question.question.value,
  importance: question.importance.value,
});
