import { createContext } from 'use-context-selector';
import {
  EmailSectionsButtonT,
  JobEmailT,
  EmailTemplateT,
  SelectedEmailEntityT,
} from 'domain/EmailConfig/EmailTemplates';
import {
  EmailTemplateRequest,
  EmailTemplateShortResponse,
  JobEmailShortResponse,
  JobEmailResponse,
  JobEmailCandidate,
  CandidateEmailPreviewResponse,
  SendJobEmailsRequest,
} from '@air/api';

export const DEFAULT_JOB_EMAIL_ITEM_STATE: JobEmailT | EmailTemplateT = {
  subject: '',
  sections: [],
};

export const DEFAULT_JOB_EMAIL_STATE: EmailConfigurationContextT['currentEmail'] =
  {
    item: DEFAULT_JOB_EMAIL_ITEM_STATE,
    isLoading: null,
  };

export const DEFAULT_ITEMS_LIST_STATE = {
  items: null as [],
  isLoading: null as boolean,
};

export const DEFAULT_PREVIEW_STATE: EmailConfigurationContextT['preview'] = {
  email: null,
  isLoading: null,
};

export type EmailSectionsButtonsListT = {
  items: EmailSectionsButtonT[];
  isLoading: boolean;
};

export type SaveJobEmailParamsT = {
  searchId: number;
  email: SelectedEmailEntityT;
};

export const DEFAULT_RECIPIENTS_STATE: EmailConfigurationContextT['recipients'] =
  {
    new: [],
    sent: [],
  };

export type EmailConfigurationContextT = {
  emailSectionsList: EmailSectionsButtonsListT;
  jobEmails: {
    items: JobEmailShortResponse[];
    isLoading: boolean;
  };
  currentEmail: {
    item: JobEmailT | EmailTemplateT;
    isLoading: boolean;
  };
  emailTemplates: {
    items: EmailTemplateShortResponse[];
    isLoading: boolean;
  };
  preview: {
    email: CandidateEmailPreviewResponse;
    isLoading: boolean;
  };
  recipients: {
    new: JobEmailCandidate[];
    sent: JobEmailCandidate[];
  };
  methods: {
    fetchEmailTemplateSectionsList: () => void;
    fetchJobEmails: (searchId: number) => Promise<JobEmailShortResponse[]>;
    saveJobEmail: (params: SaveJobEmailParamsT) => Promise<JobEmailResponse>;
    fetchJobEmailById: (searchId: number, emailId: string) => void;
    createEmailTemplate: (params: EmailTemplateRequest) => Promise<void>;
    fetchEmailTemplates: () => Promise<void>;
    fetchEmailTemplateById: (id: string) => void;
    deleteEmailTemplateById: ({
      id,
      isSelected,
    }: {
      id: string;
      isSelected: boolean;
    }) => Promise<void>;
    deleteJobEmailById: ({
      searchId,
      id,
      isSelected,
    }: {
      searchId: number;
      id: string;
      isSelected: boolean;
    }) => Promise<void>;
    fetchEmailRecipients: (
      emailId: string,
      searchId: number,
      params?: { size: number }
    ) => Promise<{
      selectedNewIdsByDefault: string[];
    }>;
    deleteJobEmailByIdLocally: (id: string) => void;
    deleteEmailTemplateByIdLocally: (id: string) => void;
    updateEmailTemplate: (email: SelectedEmailEntityT) => void;
    discardEmailTemplateChanges: (id: string) => void;
    submitUpdateEmailTemplate: (
      id: string,
      template: EmailTemplateRequest
    ) => void;
    duplicateEmailTemplate: (id: string) => void;
    duplicateJobEmail: (id: string, searchId: number) => void;
    getEmailPreviewForCandidate: (
      searchId: number,
      emailId: string,
      candidateId: string
    ) => Promise<CandidateEmailPreviewResponse>;
    sendJobEmails: (
      searchId: number,
      emailId: string,
      params: SendJobEmailsRequest
    ) => void;
  };
};

export const EmailConfigurationContext =
  createContext<EmailConfigurationContextT>({
    emailSectionsList: DEFAULT_ITEMS_LIST_STATE,
    jobEmails: DEFAULT_ITEMS_LIST_STATE,
    currentEmail: DEFAULT_JOB_EMAIL_STATE,
    emailTemplates: DEFAULT_ITEMS_LIST_STATE,
    preview: DEFAULT_PREVIEW_STATE,
    recipients: DEFAULT_RECIPIENTS_STATE,
    methods: {
      fetchEmailTemplateSectionsList: null,
      fetchJobEmails: null,
      saveJobEmail: null,
      fetchJobEmailById: null,
      createEmailTemplate: null,
      fetchEmailTemplates: null,
      fetchEmailTemplateById: null,
      deleteEmailTemplateById: null,
      deleteJobEmailById: null,
      fetchEmailRecipients: null,
      deleteJobEmailByIdLocally: null,
      deleteEmailTemplateByIdLocally: null,
      duplicateEmailTemplate: null,
      duplicateJobEmail: null,
      updateEmailTemplate: null,
      discardEmailTemplateChanges: null,
      submitUpdateEmailTemplate: null,
      getEmailPreviewForCandidate: null,
      sendJobEmails: null,
    },
  });
