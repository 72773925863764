export enum UserCardState {
  // card with title
  collapsed = 'collapsed',
  // card with title background and action button
  collapsedHovered = 'collapsed.hovered',
  // expanded card with editable fields
  expanded = 'expanded',
}

export enum UserCardActions {
  showCollapsed = 'SHOW_COLLAPSED',
  showCollapsedHovered = 'SHOW_COLLAPSED_HOVERED',
  showExpanded = 'SHOW_EXPANDED',
}

export const UserCardStateConfig = {
  [UserCardState.collapsed]: {
    [UserCardActions.showCollapsedHovered]: UserCardState.collapsedHovered,
  },
  [UserCardState.collapsedHovered]: {
    [UserCardActions.showCollapsed]: UserCardState.collapsed,
    [UserCardActions.showExpanded]: UserCardState.expanded,
  },
  [UserCardState.expanded]: {
    [UserCardActions.showCollapsed]: UserCardState.collapsed,
  },
};
