import React, { useState, useCallback, useEffect } from 'react';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import styles from './GeneralSettingsView.css';
import { Header, Paragraph, MultiSelectCheckboxes } from '@air/components';
import { useJobSpecializations } from '@air/hooks/useJobSpecializations';
import { customerProfileSelectors } from 'selectors';
import {
  useCustomerProfileContext,
  useCustomerProfileMethods,
} from 'providers/CustomerProfileProvider';

export const GeneralSettingsView: React.FC<{ isReadOnly?: boolean }> = ({
  isReadOnly,
}) => {
  const [jobSpecializations, setJobSpecializations] = useState<
    { label: string; value: string }[]
  >([]);

  const { specializations } = useJobSpecializations();

  const { updateCustomerCompanySpecializations } = useCustomerProfileMethods();

  const customerCompanySpecializations = useCustomerProfileContext(
    customerProfileSelectors.customerCompanySpecializations
  );

  useEffect(() => {
    setJobSpecializations(
      specializations.filter((it) =>
        customerCompanySpecializations.includes(it.value)
      )
    );
  }, [customerCompanySpecializations, specializations]);

  const onJobSpecializationMenuClose = useCallback(() => {
    const normalizedSpecializations = R.map(
      R.prop('value'),
      jobSpecializations
    );
    if (
      !R.stringArraysEqual(
        customerCompanySpecializations,
        normalizedSpecializations
      )
    ) {
      updateCustomerCompanySpecializations(normalizedSpecializations);
    }
  }, [
    customerCompanySpecializations,
    jobSpecializations,
    updateCustomerCompanySpecializations,
  ]);

  const onMultiSelectCheckboxesChanged = useCallback((values) => {
    setJobSpecializations(values);
  }, []);

  return (
    <div className={styles.generalTabWrapper}>
      <div className={styles.tabSection}>
        <Header level={3} bold className={styles.tabSectionHeader}>
          {phrases.GENERAL_DEFAULT_SPECIALIZATION_TITLE}
        </Header>
        <Paragraph>
          {phrases.GENERAL_DEFAULT_SPECIALIZATION_DESCRIPTION}
        </Paragraph>
        <MultiSelectCheckboxes
          isDisabled={isReadOnly}
          className={styles.jobSpecializationsDropdown}
          options={specializations}
          label={phrases.GENERAL_JOB_SPECIALIZATION_LABEL}
          value={jobSpecializations}
          onChange={onMultiSelectCheckboxesChanged}
          onMenuClose={onJobSpecializationMenuClose}
        />
      </div>
    </div>
  );
};
