import React, { useState } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { useForm, useWatch } from 'react-hook-form';
import * as phrases from '@air/constants/phrases';
import { Button } from 'components';
import { FormFields, FormFieldTypes } from '@air/components/Form/utils';

import { LoginRequest } from '@air/api/models';
import { passwordRegex } from '@air/utils/strings';
import { FormViewTitle } from 'components/FormView/FormView';
import { FormField, Toggle, Paragraph } from '@air/components';
import formViewStyles from 'components/FormView/FormView.css';
import styles from './CreatePasswordForm.css';

type CreatePasswordFormProps = {
  onSubmit: (values: LoginRequest) => Promise<void>;
  formTitle?: string;
  submitButtonText?: string;
  email?: string;
  className?: string;
};

export const CreatePasswordForm: React.FC<CreatePasswordFormProps> = ({
  onSubmit,
  formTitle = phrases.CREATE_PASSWORD_FORM_TITLE,
  submitButtonText = phrases.CREATE_PASSWORD_BUTTON_TEXT,
  className = '',
  email,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
  });
  const formValues = useWatch({ control });
  const [showPassword, setShowPassword] = useState(false);

  const hasPasswordError = errors?.password;
  const hasPasswordConfirmationError =
    !!formValues.confirmPassword && errors?.confirmPassword;

  const hasCorrectPassword = !!formValues.password && !hasPasswordError;
  const hasCorrectPasswordConfirmation =
    !!formValues.confirmPassword &&
    formValues.password === formValues.confirmPassword;

  const successPasswordLabel = formValues.password
    ? phrases.PASSWORD_STRONG_VALUE
    : phrases.LABEL_PASSWORD;

  const passwordLabel = errors?.password?.message ?? successPasswordLabel;

  const confirmPasswordLabel =
    errors?.confirmPassword?.message ?? phrases.LABEL_CONFIRM_PASSWORD;

  const canSubmitForm =
    !!formValues.password &&
    !hasPasswordError &&
    formValues.password === formValues.confirmPassword;

  return (
    <form
      className={classNames(
        styles.createPasswordForm,
        className,
        formViewStyles.loginForm
      )}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <FormViewTitle
        title={formTitle}
        description={
          email ? phrases.getCreatePasswordFormDescription(email) : null
        }
      />

      <FormField
        id={FormFields.password}
        type={showPassword ? FormFieldTypes.text : FormFieldTypes.password}
        {...register(FormFields.password, {
          required: {
            value: true,
            message: phrases.FORM_ERROR_NO_VALUE,
          },
          minLength: {
            value: 8,
            message: phrases.PASSWORD_ERROR_WEAK_VALUE,
          },
          maxLength: {
            value: 100,
            message: phrases.PASSWORD_ERROR_LONG_VALUE,
          },
          pattern: {
            value: passwordRegex,
            message: phrases.PASSWORD_ERROR_WEAK_VALUE,
          },
        })}
        isValid={hasCorrectPassword}
        hasError={hasPasswordError}
        isEmpty={R.isNullOrEmpty(formValues.password)}
        label={passwordLabel}
        placeholder={null}
      />

      <FormField
        id={FormFields.confirmPassword}
        type={showPassword ? FormFieldTypes.text : FormFieldTypes.password}
        {...register(FormFields.confirmPassword, {
          required: {
            value: true,
            message: phrases.FORM_ERROR_NO_VALUE,
          },
          validate: {
            matched: (value: unknown) =>
              value === formValues.password
                ? true
                : phrases.CONFIRM_PASSWORD_HINT_TEXT,
          },
        })}
        isValid={hasCorrectPasswordConfirmation}
        hasError={hasPasswordConfirmationError}
        isEmpty={R.isNullOrEmpty(formValues.confirmPassword)}
        label={confirmPasswordLabel}
        placeholder={null}
      />

      <div
        className={classNames(
          formViewStyles.formFieldExtra,
          formViewStyles.passwordControls
        )}
      >
        <label
          className={formViewStyles.label}
          htmlFor={FormFields.showPassword}
        >
          <Toggle
            name={FormFields.showPassword}
            className={formViewStyles.passwordToggle}
            onChange={() => setShowPassword((state) => !state)}
          />
          <Paragraph small>{phrases.SHOW_PASSWORD}</Paragraph>
        </label>
      </div>
      <div className={formViewStyles.formFieldExtra}>
        <p className={formViewStyles.hintText}>{phrases.PASSWORD_HINT_TEXT}</p>
      </div>
      <div className={formViewStyles.formFieldExtra}>
        <Button
          type="submit"
          variant={Button.variants.POSITIVE_CONFIRM}
          className={formViewStyles.actionButton}
          disabled={!canSubmitForm}
        >
          {submitButtonText}
        </Button>
      </div>
    </form>
  );
};
CreatePasswordForm.displayName = 'CreatePasswordForm';
