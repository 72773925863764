import { useCallback } from 'react';
import { APP_EVENTS } from 'domain/Kanban/events';
import * as urls from 'constants/urls';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_MODIFY_CRITERIA } from 'constants/gaLabels';
import { emit } from 'hooks/usePubSub';
import { InterviewSectionProps } from 'features/InterviewSection/InterviewSection';

/*
  Logic for initiating criteria modification process on
  search in Screening status, extracted for ease of reusal.
*/
export const useModifyCriteriaCallback = () => {
  const match = useRouteMatch<InterviewSectionProps>();
  const history = useHistory();
  const { jobDescriptionId, dataSourceId } = match?.params ?? {
    jobDescriptionId: null,
    dataSourceId: null,
  };

  return useCallback(async () => {
    if (jobDescriptionId && dataSourceId) {
      trackEvent({
        category: GACategory.ScreeningPage,
        label: GA_LABEL_MODIFY_CRITERIA,
      });
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
      history.push(urls.makeEditInterviewUrl(dataSourceId, jobDescriptionId));
    }
  }, [history, jobDescriptionId, dataSourceId]);
};
