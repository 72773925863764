import React, { useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { compose, defaultTo, filter, join, pathOr, props } from 'ramda';

import { JobDescriptionLocationResponse } from '@air/api';
import { useOverflowCheck } from '@air/utils/hooks';
import { TooltipWrapper } from '@air/components';
import { MY_INDICATOR } from 'constants/phrases';

import sideListStyles from './SideList.css';
import styles from './renderers.css';

type Props = {
  keyProp?: number | string;
};

export const FADE_TIMEOUT = 200;
export const FadingItem: React.FC<Props> = (props) => {
  return (
    <CSSTransition
      in
      appear
      key={props.keyProp}
      timeout={FADE_TIMEOUT}
      classNames="fade"
      unmountOnExit
    >
      {props.children}
    </CSSTransition>
  );
};

type BaseProps = {
  className?: string;
  isDragged?: boolean;
  isActive?: boolean;
  ownedByCurrentUser?: boolean;
};
export const BaseItem: React.FC<BaseProps> = ({
  className,
  children,
  isDragged,
  isActive,
}) => (
  <li
    className={classNames(styles.baseItem, className, {
      [styles.isDragged]: isDragged,
      [sideListStyles.active]: isActive === true,
      [sideListStyles.inactive]: isActive === false,
    })}
  >
    {children}
  </li>
);

type JobItemProps = {
  title: string;
  locations: JobDescriptionLocationResponse[];
} & BaseProps;
export const JobItem: React.FC<JobItemProps> = ({
  isDragged,
  isActive,
  title,
  locations,
}) => {
  return (
    <BaseItem
      isActive={isActive}
      isDragged={isDragged}
      className={styles.jobItem}
    >
      <SideListCardTitle title={title} />
      <SideListCardLocations locations={locations} />
    </BaseItem>
  );
};

type DraftItemProps = {
  title: string;
  locations: JobDescriptionLocationResponse[];
} & BaseProps;
export const DraftItem: React.FC<DraftItemProps> = ({
  title,
  locations,
  isActive,
  isDragged,
  ownedByCurrentUser,
}) => {
  return (
    <BaseItem
      isActive={isActive}
      className={classNames(styles.draftItem, {
        [styles.isDragged]: isDragged,
      })}
    >
      <SideListCardTitle title={title} />
      <SideListCardLocations locations={locations} />
      <IsMineIndicator isMine={ownedByCurrentUser} />
    </BaseItem>
  );
};

type InterviewItemProps = {
  title: string;
  locations: JobDescriptionLocationResponse[];
  isPaused: boolean;
  notClickable?: boolean;
} & BaseProps;
export const InterviewItem: React.FC<InterviewItemProps> = ({
  title,
  locations,
  isActive,
  isDragged = false,
  isPaused,
  ownedByCurrentUser,
  notClickable,
}) => (
  <BaseItem
    isActive={isActive}
    className={classNames(styles.interviewItem, {
      [styles.isDragged]: isDragged,
      [styles.isPaused]: isPaused,
      [styles.notClickable]: notClickable,
    })}
  >
    {isPaused && <span className={styles.interviewPausedLabel}>Paused</span>}
    <SideListCardTitle title={title} />
    <SideListCardLocations locations={locations} />
    <IsMineIndicator isMine={ownedByCurrentUser} />
  </BaseItem>
);

type InterviewDraftItemProps = {
  title: string;
  locations: JobDescriptionLocationResponse[];
} & BaseProps;
export const InterviewDraftItem: React.FC<InterviewDraftItemProps> = ({
  title,
  locations,
  isActive,
}) => {
  return (
    <BaseItem
      isActive={isActive}
      className={classNames(styles.interviewDraftItem)}
    >
      <SideListCardTitle title={title} />
      <SideListCardLocations locations={locations} />
    </BaseItem>
  );
};

type ClosedSearchItemProps = {
  title: string;
  locations: JobDescriptionLocationResponse[];
  notClickable?: boolean;
} & BaseProps;
export const ClosedSearchItem: React.FC<ClosedSearchItemProps> = ({
  title,
  locations,
  isActive,
  isDragged = false, // decided to keep this prop, we'll make it draggable anyway.
  ownedByCurrentUser,
  notClickable,
}) => (
  <BaseItem
    isActive={isActive}
    className={classNames(styles.closedInterviewItem, {
      [styles.isDragged]: isDragged,
      [styles.notClickable]: notClickable,
    })}
  >
    <SideListCardTitle title={title} />
    <SideListCardLocations locations={locations} />
    <IsMineIndicator isMine={ownedByCurrentUser} />
  </BaseItem>
);

export function getJobLocation(item: {
  location: JobDescriptionLocationResponse;
}) {
  return compose(
    defaultTo(''),
    join(', '),
    filter(Boolean),
    props<string, string>(['name', 'city', 'state', 'country']),
    pathOr<unknown>({}, ['location'])
  )(item);
}

export function getJobLocations(locations: JobDescriptionLocationResponse[]) {
  if (!locations) return [];

  return locations.map(
    compose<any[], string[], string[], string, string>(
      defaultTo(''),
      join(', '),
      filter<string>(Boolean),
      props(['name', 'city', 'state', 'country'])
    )
  );
}

const SideListCardTitle: React.FC<{ title: string }> = ({ title }) => {
  const [ref, isOverflown] = useOverflowCheck<HTMLParagraphElement>(title);

  return (
    <TooltipWrapper enabled={isOverflown} tooltip={title}>
      <p
        ref={ref}
        className={classNames(styles.name, {
          [styles.isOverflown]: isOverflown,
        })}
      >
        <span>{title}</span>
      </p>
    </TooltipWrapper>
  );
};

const SideListCardLocations: React.FC<{
  locations: JobDescriptionLocationResponse[];
}> = ({ locations }) => {
  const [firstLocation, ...restLocations] = useMemo(
    () => getJobLocations(locations),
    [locations]
  );

  if (!locations) return null;

  const restLocationsCount = restLocations.length;

  return (
    <>
      <span className={styles.location}>{firstLocation}</span>
      {restLocationsCount > 0 && (
        <TooltipWrapper enabled tooltip={restLocations.join('\n')}>
          <span className={styles.location}>and {restLocationsCount} more</span>
        </TooltipWrapper>
      )}
    </>
  );
};

const IsMineIndicator: React.FC<{ isMine: boolean }> = ({ isMine }) => {
  return isMine ? (
    <span className={styles.isMineIndicator}>{MY_INDICATOR}</span>
  ) : null;
};
