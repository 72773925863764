import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import {
  AcceptableExperienceWidget,
  Card,
  IdealExperienceWidget,
  RecentExperienceWidget,
  ManagerialExperienceWidget,
} from '@air/components';

import { useStateMachine } from '@air/hooks';
import { SearchCriteriaImportanceEnum } from '@air/api';
import {
  SearchCardStateConfig,
  getCardInitialState,
  isCardInViewMode,
  isCardReadOnly,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import { SearchCardContext } from 'components/SearchCriteriaCards/hooks/SearchCardContext';
import {
  ManagerialCriteriaData,
  getImportanceValue,
  CardType,
} from 'domain/SearchCriteria';
import {
  makeDraggableComponent,
  withCustomDrag,
  DraggableCriteriaCardProps,
} from 'components/CustomDragLayer/CustomDragLayer';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import {
  convertManagerialExperienceToString,
  getCommonFieldsForCriteriaCard,
} from 'components/Cards/cardsCommonCode';
import * as phrases from 'constants/phrases';
import {
  isIdealExperienceDefined,
  isAcceptableExperienceDefined,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import {
  getCardCriterionLabel,
  getMainCardTitle,
  getSearchCriteriaCardFooter,
  getMainCard,
} from 'domain/SearchCriteria/cardHelpers';
import { IdealExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/IdealExperienceWidget';
import { AcceptableExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/AcceptableExperienceWidget';
import { RecentExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/RecentExperienceWidget';
import { ManagerialExperienceReadOnlyWidget } from '@air/components/SelectRangeWidgets/ManagerialExperienceWidget';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import {
  CriteriaCollapsedCard,
  CriteriaEditCard,
} from 'components/CardWrappers';
import { CriteriaEditCardChildrenProps } from 'components/CardWrappers/CriteriaEditCard/CriteriaEditCard';
import classNames from 'classnames';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

export const getManagementReadOnlyFields = (
  card: ManagerialCriteriaData
): Array<JSX.Element | null> => {
  return [
    <Card.SearchCriteriaEditFormMainTitle
      className={cardCommonStyles.cardSystemValue}
      importance={getImportanceValue(card)}
      key="main-field"
      tooltipProps={cardsConfig[card.cardType]?.getMainCardTooltipProps?.(
        getMainCard(card),
        card
      )}
    >
      {getMainCardTitle(card)}
    </Card.SearchCriteriaEditFormMainTitle>,
    <IdealExperienceReadOnlyWidget
      values={card.experience}
      key="ideal-experience-field"
    />,
    isIdealExperienceDefined(card.experience) &&
    isAcceptableExperienceDefined(card.experience) ? (
      <AcceptableExperienceReadOnlyWidget
        values={card.experience}
        key="acceptable-experience-field"
      />
    ) : null,
    <RecentExperienceReadOnlyWidget
      value={card.recent}
      key="recent-experience-field"
    />,
    card.managerialExperience ? (
      <ManagerialExperienceReadOnlyWidget
        value={card.managerialExperience}
        key="managerial-experience-field"
      />
    ) : null,
  ];
};

export const getManagerialFieldsForCollapsedCriteriaCard = (
  cardData: ManagerialCriteriaData
) => {
  const fields = getCommonFieldsForCriteriaCard(cardData);

  return [
    fields.title,
    !!cardData.managerialExperience && (
      <Card.Footer
        key="card-managerial"
        className={cardCommonStyles.experienceYears}
        text={convertManagerialExperienceToString(
          cardData.managerialExperience
        )}
      />
    ),
    fields.footer,
  ];
};

const DraggableManagerialCriteriaCard = makeDraggableComponent<
  DraggableCriteriaCardProps<ManagerialCriteriaData>,
  { id: number | string }
>(
  withCustomDrag(CriteriaCollapsedCard),
  DraggedSearchCriteriaCardType.managerial,
  {
    beginDrag: ({
      id,
      criteria,
      importanceSectionOrder,
      onDragStart,
      cardClasses,
    }) => {
      onDragStart && onDragStart();
      return {
        title: phrases.EXPERIENCE_MANAGERIAL_TITLE_PLACEHOLDER,
        id,
        beginDragClass: cardClasses?.beginDragClass,
        order: criteria.idx,
        importance: getImportanceValue(criteria),
        importanceSectionOrder,
        type: DraggedSearchCriteriaCardType.managerial,
      };
    },
    endDrag: ({ onDragEnd }) => {
      onDragEnd && onDragEnd();
    },
  }
);
DraggableManagerialCriteriaCard.displayName = 'DraggableManagerialCriteriaCard';

const ManagerialCriteriaCardEdit: FC<{
  namePrefix: string;
  values: ManagerialCriteriaData;
  changeCardImportance: (
    criteria: ManagerialCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: boolean;
}> = ({ values, namePrefix, changeCardImportance, isReadOnly = false }) => {
  const managerialCardConfig = cardsConfig[CardType.managerial];
  return (
    <CriteriaEditCard
      namePrefix={namePrefix}
      cardData={values}
      changeCardImportance={changeCardImportance}
    >
      {({
        mainCriteriaNameFieldRef,
        importanceIndicator,
      }: CriteriaEditCardChildrenProps) => (
        <>
          <div
            ref={mainCriteriaNameFieldRef}
            className={cardCommonStyles.titleFieldWithImportance}
          >
            <div
              className={classNames(
                cardCommonStyles.multilineContent,
                cardCommonStyles.cardSystemValue
              )}
            >
              <Card.Title
                title={phrases.EXPERIENCE_MANAGERIAL_TITLE_PLACEHOLDER}
                tooltipProps={managerialCardConfig?.getMainCardTooltipProps?.(
                  values,
                  values
                )}
              />
            </div>
            {importanceIndicator}
          </div>
          <Field name={`${namePrefix}.experience`}>
            {({ field, form }: FieldProps) => (
              <>
                <IdealExperienceWidget
                  isReadOnly={isReadOnly}
                  values={field.value}
                  onChangeValues={(value) =>
                    form.setFieldValue(field.name, value)
                  }
                />
                {isIdealExperienceDefined(field.value) && (
                  <AcceptableExperienceWidget
                    isReadOnly={isReadOnly}
                    values={field.value}
                    onChangeValues={(value) =>
                      form.setFieldValue(field.name, value)
                    }
                  />
                )}
              </>
            )}
          </Field>
          <Field name={`${namePrefix}.recent`}>
            {({ field, form }: FieldProps) => {
              return (
                <RecentExperienceWidget
                  isReadOnly={isReadOnly}
                  value={field.value}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                />
              );
            }}
          </Field>
          <Field name={`${namePrefix}.managerialExperience`}>
            {({ field, form }: FieldProps) => (
              <ManagerialExperienceWidget
                isReadOnly={isReadOnly}
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
              />
            )}
          </Field>
        </>
      )}
    </CriteriaEditCard>
  );
};

ManagerialCriteriaCardEdit.displayName = 'ManagerialCriteriaCardEdit';

type ManagerialCardComponentProps = {
  cardData: ManagerialCriteriaData;
  namePrefix: string;
  onUpdate: () => void;
  onRemove: () => void;
  importanceSectionOrder: number;
  changeCardImportance: (
    criteria: ManagerialCriteriaData,
    newImportance: { label: string; value: SearchCriteriaImportanceEnum }
  ) => void;
  isReadOnly?: false;
};

export const ManagerialCriteriaCard: FC<ManagerialCardComponentProps> = ({
  namePrefix,
  cardData,
  importanceSectionOrder,
  onUpdate,
  onRemove,
  changeCardImportance,
  isReadOnly = false,
}) => {
  const cardTitle = getMainCardTitle(cardData);
  const [cardFooter, cardSecondFooter] = getSearchCriteriaCardFooter(cardData);
  const cardImportance = getImportanceValue(cardData);
  const cardLabel = getCardCriterionLabel(cardData);

  const [cardState, dispatch] = useStateMachine(
    SearchCardStateConfig,
    getCardInitialState(isReadOnly, cardData.initialCardStatus),
    {
      hasStack: false,
      updated: true,
      isReadOnly,
    }
  );
  const cardMethods = { onUpdate, onRemove };

  return (
    <SearchCardContext
      cardState={cardState}
      dispatch={dispatch}
      {...cardMethods}
    >
      <Card.ResizeableCardWrapper resizeable={false}>
        {isCardInViewMode(cardState) ? (
          <DraggableManagerialCriteriaCard
            title={cardTitle}
            footerText={cardFooter}
            secondFooterText={cardSecondFooter}
            importance={cardImportance}
            cardLabel={cardLabel}
            cardClasses={{
              mainCardClass: cardCommonStyles.cardSystemValue,
              beginDragClass: cardCommonStyles.cardSystemValue,
              footerClass: cardCommonStyles.experienceYears,
              secondFooterClass: cardCommonStyles.experienceYears,
            }}
            criteria={cardData}
            id={cardData.key}
            onRemove={onRemove}
            importanceSectionOrder={importanceSectionOrder}
            initialCardStatus={cardData.initialCardStatus}
          />
        ) : (
          <ManagerialCriteriaCardEdit
            namePrefix={namePrefix}
            values={cardData}
            changeCardImportance={changeCardImportance}
            isReadOnly={isCardReadOnly(cardState)}
          />
        )}
      </Card.ResizeableCardWrapper>
    </SearchCardContext>
  );
};
ManagerialCriteriaCard.displayName = 'ManagerialCriteriaCard';
