import { useEffect } from 'react';
import { toast } from '@air/third-party/toast';
import R from '@air/third-party/ramda';
import {
  getConnection,
  SSEConnectionErrorEvent,
} from '@air/lib/server-notifications/Connection';
import localSettings from '@air/localSettings';
import { ACCESS_TOKEN, localStore } from '@air/domain/WebStorage/webStorage';
import { createRefreshTokenTask } from '@air/utils/api';
import { UNAUTHORIZED } from '@air/constants/httpCodes';
import { EventType, NotificationEvent } from '@air/api/models';
import * as phrases from 'customer-portal/src/constants/phrases';

export type EventHandler = (
  event: NotificationEvent | SSEConnectionErrorEvent
) => void;
export type SSEConnectionType = NotificationEvent | SSEConnectionErrorEvent;

let eventSourceConnection: ReturnType<typeof getConnection>;
let subscribers: EventHandler[] = [];
let SSE_URL: string;

export function initSSEConnection(notificationUrl: string) {
  const accessToken = localStore.getItem(ACCESS_TOKEN);
  SSE_URL = notificationUrl;
  const initParams = {
    url: `${notificationUrl}?allNotifications=true`,
    headers: {
      'Content-Type': 'text/event-stream',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const debugMode = localSettings.DEBUG_NOTIFICATIONS;

  if (!eventSourceConnection) {
    eventSourceConnection = getConnection(initParams, debugMode);
    eventSourceConnection.subscribe(globalSSEDispatcher);
  }
}

export function shutdownSSEConnection() {
  eventSourceConnection?.unsubscribe();
  eventSourceConnection = null;
}

function globalSSEDispatcher(
  event: NotificationEvent | SSEConnectionErrorEvent
) {
  /*
    Notify subscribers.
  */
  for (const handler of subscribers) {
    handler(event);
  }

  /*
    Handle system-level events.
  */
  if (
    event.eventType === 'error' &&
    event?.payload?.errorCode === UNAUTHORIZED
  ) {
    shutdownSSEConnection();
    createRefreshTokenTask().fork(
      () => {},
      () => initSSEConnection(SSE_URL)
    );
    return;
  }

  if (event.eventType === EventType.LICENSEEXPIRED) {
    location.reload();
  }

  if (
    event.eventType === EventType.DEPLOYEDNEWCUSTOMERPORTAL &&
    localSettings.RESET_APP_ON_DEPLOY
  ) {
    let intervalId: ReturnType<typeof setTimeout>;
    let counter = 30;
    const toastId = toast.warning(phrases.getReloadNotification(counter), {
      onClose() {
        clearInterval(intervalId);
      },
      autoClose: counter * 1000,
    });
    intervalId = setInterval(() => {
      counter--;
      if (counter <= 0) {
        location.reload();
      }
      toast.update(toastId, {
        render: phrases.getReloadNotification(Math.max(counter, 0)),
      });
    }, 1000);
  }
}

export function registerSSESubscriber(subscriber: EventHandler): () => void {
  subscribers.push(subscriber);

  return function () {
    subscribers = R.reject(R.whereEq(subscriber), subscribers);
  };
}

export function useSSESubscription(
  notificationUrl: string,
  shouldConnect: boolean
) {
  useEffect(() => {
    if (shouldConnect) {
      initSSEConnection(notificationUrl);
    }
    return shutdownSSEConnection;
  }, [notificationUrl, shouldConnect]);
}
