/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum RecentExperienceInterviewOption {
  USEITNOW = 'USE_IT_NOW',
  ONEYEARAGO = 'ONE_YEAR_AGO',
  TWOYEARSAGO = 'TWO_YEARS_AGO',
  THREEYEARSAGO = 'THREE_YEARS_AGO',
  FOURYEARSAGO = 'FOUR_YEARS_AGO',
  FIVEYEARSAGO = 'FIVE_YEARS_AGO',
  SIXYEARSAGO = 'SIX_YEARS_AGO',
  SEVENYEARSAGO = 'SEVEN_YEARS_AGO',
  EIGHTYEARSAGO = 'EIGHT_YEARS_AGO',
  NINEYEARSAGO = 'NINE_YEARS_AGO',
  TENORMOREYEARSAGO = 'TEN_OR_MORE_YEARS_AGO',
}
