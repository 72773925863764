import { useEffect, useState } from 'react';
import * as LicenseApi from 'domain/Trial/License';
import { CustomerCompanyLicenseResponse, LicenseType } from '@air/api';
import { toast } from '@air/third-party/toast';
import * as phrases from 'constants/phrases';
import { getDaysDiff } from '@air/utils/dates';

type PropsT = {
  setLicenseType: (...args: any[]) => any;
  setDaysLeft: (...args: any[]) => any;
  setTrialExpired: (...args: any[]) => any;
  logout: (...args: any[]) => any;
};

async function getLicense({
  setLicenseType,
  setDaysLeft,
  setTrialExpired,
  logout,
}: PropsT) {
  await LicenseApi.getLicense().fork(
    () => {
      toast.error(phrases.TRIAL_NO_INFO);
      logout();
    },
    (res: CustomerCompanyLicenseResponse) => {
      const days = getDaysDiff(res.expired, Date.now());
      setLicenseType(res.type);
      setDaysLeft(days <= 0 ? 0 : days);
      // There can be situations when we have expired date but active trial (because the job that updates licenses is run once per hour)
      // Treat this condition as an expired trial
      setTrialExpired(
        res.type === LicenseType.TRIALEXPIRED ||
          (days <= 0 &&
            [
              LicenseType.TRIALACTIVE,
              LicenseType.TRIALEXPIREDFULLREQUESTED,
              LicenseType.TRIALACTIVEFULLREQUESTED,
            ].includes(res.type))
      );
    }
  );
}

type UseLicensePropsT = {
  customerId: number;
  setLicenseType: (...args: any[]) => any;
  logout: (...args: any[]) => any;
  licenseType: LicenseType;
};

const useLicense = ({
  customerId,
  setLicenseType,
  logout,
  licenseType,
}: UseLicensePropsT) => {
  const [daysLeft, setDaysLeft] = useState(null);
  const [isTrialExpired, setTrialExpired] = useState(null);

  useEffect(() => {
    if (customerId && !licenseType) {
      getLicense({ setLicenseType, setDaysLeft, setTrialExpired, logout });
    }
    if (!customerId) {
      setLicenseType(null);
      setDaysLeft(null);
      setTrialExpired(null);
    }
  }, [customerId, setLicenseType, setDaysLeft, logout, licenseType]);

  return { daysLeft, isTrialExpired };
};

export default useLicense;
