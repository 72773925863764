import React, { useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';

import {
  SearchCriteriaImportanceEnum,
  SearchCriteriaMatchStatusEnum,
} from '@air/api';

import * as phrases from 'constants/phrases';

import {
  BaseCandidateData,
  hasUnreadComment,
  hasComments,
} from 'domain/CandidateData';
import { SvgIcon } from '@air/components';
import { RegFlagWarningType } from '@air/domain/RedFlags';
import { LineupCandidateCard } from 'components/CardWrappers/LineupCandidateCard/LineupCandidateCard';
import { CandidateLineupData } from 'domain/CandidateData/CandidateLineupData';
import { HeaderCriteriaData } from 'domain/HeaderData/HeaderDataMapper';
import styles from 'components/SearchResultsView/utils.css';
import searchResultsViewStyles from 'components/SearchResultsView/SearchResultsView.css';

const importanceToLabel = {
  [SearchCriteriaImportanceEnum.MANDATORY]: phrases.MANDATORY_CRITERIA_LABEL,
  [SearchCriteriaImportanceEnum.IMPORTANT]: phrases.IMPORTANT_CRITERIA_LABEL,
  [SearchCriteriaImportanceEnum.OPTIONAL]: phrases.OPTIONAL_CRITERIA_LABEL,
};

type ImportanceControlsProps = {
  importance: SearchCriteriaImportanceEnum;
  importanceSectionsStatus: { [type in SearchCriteriaImportanceEnum]: boolean };
};
export const ImportanceVisibilityControls: React.FC<
  ImportanceControlsProps
> = ({ importance, importanceSectionsStatus }) => {
  /*
    If column represents a stack of cards, underlying cards
    have no importance set on them, and they should not have
    any controls when stack is expanded.
   */
  if (!importance) return null;

  /*
    First column in each section has a header/toggle control.
    When a section is disabled, its toggle should be rendered
    in closest active section column from the left.
   */
  const availableTypes = Object.keys<SearchCriteriaImportanceEnum>(
    importanceSectionsStatus
  );
  const currentSectionIndex = availableTypes.indexOf(importance);

  return (
    <div className={styles.importanceTitleWrapper}>
      {availableTypes.map((type, index) => {
        const isCurrentSection = index === currentSectionIndex;
        if (isCurrentSection) {
          return (
            <span key={type} className={styles.importanceSectionTitle}>
              {importanceToLabel[type]}
            </span>
          );
        }
        return null;
      })}
    </div>
  );
};

const mapStatusToClassnames = (cellStatus: SearchCriteriaMatchStatusEnum) => ({
  [searchResultsViewStyles.ideal]:
    cellStatus === SearchCriteriaMatchStatusEnum.IDEAL,
  [searchResultsViewStyles.acceptable]:
    cellStatus === SearchCriteriaMatchStatusEnum.ACCEPTABLE,
  [searchResultsViewStyles.disqualify]:
    cellStatus === SearchCriteriaMatchStatusEnum.DISQUALIFICATION,
  [searchResultsViewStyles.opened]:
    cellStatus === SearchCriteriaMatchStatusEnum.OPENED,
  [searchResultsViewStyles.warning]:
    cellStatus === SearchCriteriaMatchStatusEnum.WARNING ||
    cellStatus === SearchCriteriaMatchStatusEnum.WARNINGMISSED,
});

type CellValueProps<T extends BaseCandidateData = BaseCandidateData> = {
  cellStatus: SearchCriteriaMatchStatusEnum;
  item: T;
  type: any;
  cellIcon: any;
  invitedForInterview: boolean;
  onCellClickHandler: (
    cardId: number | string,
    showNote: boolean
  ) => () => void;
};

const CellValue: React.FC<CellValueProps> = React.memo(
  ({ cellStatus, item, invitedForInterview, cellIcon, onCellClickHandler }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const onCellMouseOver = useCallback(() => {
      setIsOpen(true);
    }, []);

    const onCellMouseOut = useCallback((event: any) => {
      if (!ref.current.contains(event.relatedTarget)) {
        setIsOpen(false);
      }
    }, []);

    return (
      <div
        ref={ref}
        className={styles.cellValue}
        onMouseOver={onCellMouseOver}
        onMouseOut={onCellMouseOut}
      >
        <span
          className={classNames(
            searchResultsViewStyles.searchResult,
            mapStatusToClassnames(cellStatus)
          )}
        />
        {cellIcon && (
          <SvgIcon
            className={searchResultsViewStyles.cellIcon}
            icon={cellIcon}
          />
        )}
        {isOpen && (
          <div className={styles.searchResultPopupWrapper}>
            <LineupCandidateCard
              cardData={item}
              invitedForInterview={invitedForInterview}
              onClick={onCellClickHandler}
              onClickOnCollapsedStack={onCellClickHandler}
            />
          </div>
        )}
      </div>
    );
  }
);

const getCellIcon = (data: BaseCandidateData): string | null => {
  if (data && hasComments(data)) {
    return hasUnreadComment(data) ? 'icon-letter-big' : 'icon-note-read';
  } else {
    return data?.status === SearchCriteriaMatchStatusEnum.OPENED
      ? 'question-mark'
      : null;
  }
};

export const TableBodyCellRenderer: React.FC<{
  item: CandidateLineupData;
  column: HeaderCriteriaData;
  onCellClickHandler: (
    cardId: number | string,
    showNote: boolean
  ) => () => void;
}> = ({ item, column, onCellClickHandler }) => {
  const { id: columnId } = column;
  const columnType = column?.cardData?.cardType;
  const data = item.matchingItems?.[columnType]?.get(columnId);
  const cellIcon = getCellIcon(data);
  return data ? (
    <CellValue
      type={columnType}
      item={data}
      invitedForInterview={item.invitedForInterview}
      cellStatus={data.status}
      cellIcon={cellIcon}
      onCellClickHandler={onCellClickHandler}
    />
  ) : null;
};

type WarningIconProps = {
  [key: string]: { icon: string; width: string; height: string };
};

const warningIconProps: WarningIconProps = {
  [RegFlagWarningType.REDFLAG]: { icon: 'flag', width: '2em', height: '2em' },
  [RegFlagWarningType.EMPTYREDFLAG]: {
    icon: 'red-flag-empty',
    width: '2em',
    height: '2em',
  },
};

export const getWarningIcon = (warningType: RegFlagWarningType) => {
  if (!R.isNil(warningType)) {
    const prop = warningIconProps[warningType];
    return <SvgIcon icon={prop.icon} width={prop.width} height={prop.height} />;
  }
  return null;
};
