export enum LineupCardState {
  // not visible card
  hidden = 'hidden',
  // parent type for all visible states
  visible = 'visible',
  // card without borders with title only
  initial = 'visible.initial',
  // card with title, footer and stack indicator
  collapsed = 'visible.collapsed',
  // expanded card with readonly fields
  expanded = 'visible.expanded',
}

export enum LineupCardActions {
  showInitial = 'SHOW_INITIAL',
  showCollapsed = 'SHOW_COLLAPSED',
  showExpanded = 'SHOW_EXPANDED',
}

export const LineupCardStateConfig = {
  [LineupCardState.visible]: {
    auto: {
      target: LineupCardState.initial,
    },
  },
  [LineupCardState.initial]: {
    [LineupCardActions.showCollapsed]: LineupCardState.collapsed,
    [LineupCardActions.showExpanded]: LineupCardState.expanded,
  },
  [LineupCardState.collapsed]: {
    [LineupCardActions.showInitial]: LineupCardState.initial,
    [LineupCardActions.showExpanded]: LineupCardState.expanded,
  },
  [LineupCardState.expanded]: {
    [LineupCardActions.showInitial]: LineupCardState.initial,
    [LineupCardActions.showCollapsed]: LineupCardState.collapsed,
  },
};
