export enum FormFields {
  email = 'email',
  password = 'password',
  confirmPassword = 'confirmPassword',
  showPassword = 'showPassword',
}

export enum FormFieldTypes {
  text = 'text',
  email = 'email',
  password = 'password',
  checkbox = 'checkbox',
}

export enum FormErrorTypes {
  required = 'required',
  minLength = 'minLength',
  maxLength = 'maxLength',
  pattern = 'pattern',
  validate = 'validate',
}
