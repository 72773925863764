import React from 'react';
import classNames from 'classnames';

import styles from './SelectOption.css';

type SelectOptionProps = {
  label: string;
  icon?: React.ReactNode;
  isSelected: boolean;
  className?: string;
  onClick: () => void;
};

export const SelectOption: React.FC<SelectOptionProps> = ({
  label,
  icon = null,
  isSelected,
  onClick,
  className,
}) => {
  return (
    <div
      className={classNames(className, styles.optionFields, {
        [styles.activeField]: isSelected,
      })}
      onClick={onClick}
    >
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      <span>{label}</span>
    </div>
  );
};
