import React, { useState, useCallback, useMemo, useEffect } from 'react';

import styles from './UserManagementView.css';
import {
  useUserManagementContext,
  useUserManagementMethods,
} from 'providers/UserManagementProvider';
import { userManagementSelectors, customerProfileSelectors } from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import * as phrases from 'constants/phrases';
import { CanAccess, Button, SvgIcon, CheckboxOptions } from '@air/components';
import { CustomerProfileContextT } from 'context';
import { UserFormCard } from 'components/CardWrappers/UserCard/UserFormCard';
import classNames from 'classnames';
import { FilterWithPlaceholder } from 'components';
import { UserCard } from 'components/CardWrappers';
import { CheckboxOption } from '@air/components/CheckboxOptions/CheckboxOptions';
import { RoleName, InternalStatus } from '@air/api';

enum FilterIdsEnum {
  ROLE_FILTER_RECRUITERS = 'ROLE_FILTER_RECRUITERS',
  ROLE_FILTER_ADMINS = 'ROLE_FILTER_ADMINS',
  STATUS_ACTIVE_FILTER_USERS = 'STATUS_ACTIVE_FILTER_USERS',
  STATUS_INACTIVE_FILTER_USERS = 'STATUS_INACTIVE_FILTER_USERS',
}

type RoleStatusFiltersProps = {
  fetchUsersByCustomFilters: (
    roles: RoleName[],
    status: InternalStatus[]
  ) => void;
};

const roleFilterInitial: CheckboxOption[] = [
  {
    id: FilterIdsEnum.ROLE_FILTER_RECRUITERS,
    label: phrases.ROLE_FILTER_RECRUITERS,
    className: '',
    isSelected: true,
  },
  {
    id: FilterIdsEnum.ROLE_FILTER_ADMINS,
    label: phrases.ROLE_FILTER_ADMINS,
    className: '',
    isSelected: true,
  },
];

const RoleStatusFilters: React.FC<RoleStatusFiltersProps> = ({
  fetchUsersByCustomFilters,
}) => {
  const statusFilterInitial: CheckboxOption[] = [
    {
      id: FilterIdsEnum.STATUS_ACTIVE_FILTER_USERS,
      label: phrases.STATUS_FILTER_ACTIVE_USERS,
      className: '',
      isSelected: true,
    },
    {
      id: FilterIdsEnum.STATUS_INACTIVE_FILTER_USERS,
      label: phrases.STATUS_FILTER_INACTIVE_USERS,
      className: styles.statusFilter,
      isSelected: false,
    },
  ];

  const [roleFilterItems, setRoleFilterItems] = useState(roleFilterInitial);

  const [statusFilterItems, setStatusFilterItems] =
    useState(statusFilterInitial);

  const userFilters = useMemo(() => {
    return [
      {
        label: phrases.ROLE_FILTER_LABEL,
        options: roleFilterItems,
        onClick: setRoleFilterItems,
      },
      {
        label: phrases.STATUS_FILTER_LABEL,
        options: statusFilterItems,
        onClick: setStatusFilterItems,
      },
    ];
  }, [
    roleFilterItems,
    setRoleFilterItems,
    statusFilterItems,
    setStatusFilterItems,
  ]);

  const getSelectedRoles = useCallback(
    (isRecruiterFilterSelected, isAdminFilterSelected) => {
      const roles = [];
      if (isRecruiterFilterSelected) {
        roles.push(RoleName.RECRUITER);
      }
      if (isAdminFilterSelected) {
        roles.push(RoleName.CUSTOMERADMIN);
      }
      return roles;
    },
    []
  );

  const getSelectedStatuses = useCallback(
    (isActiveStatusSelected, isInactiveStatusSelected) => {
      const statuses = [];
      if (isActiveStatusSelected) {
        statuses.push(InternalStatus.ACTIVE);
      }
      if (isInactiveStatusSelected) {
        statuses.push(InternalStatus.INACTIVE);
      }
      return statuses;
    },
    []
  );

  useEffect(() => {
    const filters = roleFilterItems
      .concat(statusFilterItems)
      .reduce((acc, item) => {
        const key = item.id;
        acc[key] = item.isSelected;
        return acc;
      }, {} as { [key: string]: boolean });

    const isRecruiterFilterSelected =
      filters[FilterIdsEnum.ROLE_FILTER_RECRUITERS];
    const isAdminFilterSelected = filters[FilterIdsEnum.ROLE_FILTER_ADMINS];
    const isActiveStatusSelected =
      filters[FilterIdsEnum.STATUS_ACTIVE_FILTER_USERS];
    const isInactiveStatusSelected =
      filters[FilterIdsEnum.STATUS_INACTIVE_FILTER_USERS];

    const roles = getSelectedRoles(
      isRecruiterFilterSelected,
      isAdminFilterSelected
    );

    const status = getSelectedStatuses(
      isActiveStatusSelected,
      isInactiveStatusSelected
    );

    fetchUsersByCustomFilters(roles, status);
  }, [
    getSelectedRoles,
    getSelectedStatuses,
    fetchUsersByCustomFilters,
    roleFilterItems,
    statusFilterItems,
  ]);

  return (
    <div className={styles.roleStatusFilters}>
      {userFilters.map((filter) => (
        <div key={filter.label} className={styles.filterSection}>
          <div className={styles.filterLabel}>{filter.label}</div>
          <CheckboxOptions items={filter.options} onChange={filter.onClick} />
        </div>
      ))}
    </div>
  );
};

export const UserManagementView: React.FC<{ isReadOnly?: boolean }> = ({
  isReadOnly,
}) => {
  const users = useUserManagementContext(userManagementSelectors.users);
  const total = useUserManagementContext(userManagementSelectors.total);

  const { fetchUsers, resetPassword, createUser, editUser } =
    useUserManagementMethods();
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );

  const [searchValue, setSearchValue] = useState('');

  const onNameFilterChange = useCallback(
    (query: string) => {
      setSearchValue(query);
      fetchUsers(query);
    },
    [fetchUsers]
  );

  const filterPlaceholderTitle = total
    ? phrases.USER_MANAGEMENT_ALL_USERS_PLACEHOLDER
    : phrases.USER_MANAGEMENT_NO_USERS_PLACEHOLDER;

  const [isNewUserCardVisible, setNewUserCardVisible] =
    useState<boolean>(false);

  const addNewUserClickHandler = useCallback(() => {
    if (!isNewUserCardVisible) {
      setNewUserCardVisible(true);
    }
  }, [isNewUserCardVisible, setNewUserCardVisible]);
  const discardNewUserHandler = useCallback(() => {
    setNewUserCardVisible(false);
  }, [setNewUserCardVisible]);

  const submitNewUserHandler = useCallback(
    (userData) =>
      createUser(userData).then(() => {
        fetchUsers(searchValue);
        setNewUserCardVisible(false);
      }),
    [searchValue, fetchUsers, createUser]
  );

  const [rolesFilter, setRolesFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const submitEditUserHandler = useCallback(
    (customerId, userData) =>
      editUser(customerId, userData).then(() => {
        fetchUsers(searchValue, rolesFilter, statusFilter);
      }),
    [searchValue, fetchUsers, editUser, rolesFilter, statusFilter]
  );

  /**
   * since `total` represents the amount of users with applied filters,
   * we need to take `searchValue` into consideration.
   * `isFilterApplied` reflects the fact that searchValue was set/unset.
   * `hasNoFilteredUsers` - empty list of users after filter was applied
   * `hasNoUsers` - account doesn't contain users at all (theoretically it's possible,
   * if we will support superadmin role in future to get access to different accounts).
   */
  const isFilterApplied = useMemo(() => searchValue?.length > 0, [searchValue]);
  const hasNoFilteredUsers = useMemo(
    () => isFilterApplied && total === 0,
    [isFilterApplied, total]
  );

  const hasNoUsers = useMemo(
    () => !isFilterApplied && total === 0,
    [isFilterApplied, total]
  );

  const fetchUsersByCustomFilters = useCallback(
    (roles, statuses) => {
      fetchUsers(searchValue, roles, statuses);
      setRolesFilter(roles);
      setStatusFilter(statuses);
    },
    [fetchUsers, searchValue]
  );

  return (
    <div className={styles.userManagementContent}>
      <div className={styles.userManagementControlPanel}>
        <CanAccess<CustomerProfileContextT> hasAccess={isStandaloneAtsUser}>
          {isNewUserCardVisible ? (
            <UserFormCard
              onDiscard={discardNewUserHandler}
              onSubmit={submitNewUserHandler}
              className={styles.userManagementUsersListItem}
            />
          ) : (
            <>
              <Button
                variant={Button.variants.POSITIVE_MAIN}
                className={styles.addNewUserButton}
                disabled={isReadOnly}
                onClick={addNewUserClickHandler}
              >
                <SvgIcon
                  className={classNames(styles.addNewUserButtonIcon, {
                    [styles.disabled]: isReadOnly,
                  })}
                  icon="add-user-icon"
                />
                <span className={styles.addNewUserButtonLabel}>
                  {phrases.ADD_NEW_USER_BUTTON}
                </span>
              </Button>
            </>
          )}
        </CanAccess>
        {isNewUserCardVisible ? null : (
          <>
            <FilterWithPlaceholder
              isDisabled={hasNoUsers}
              totalCount={total}
              placeholderTitle={filterPlaceholderTitle}
              onChange={onNameFilterChange}
            />
            <RoleStatusFilters
              fetchUsersByCustomFilters={fetchUsersByCustomFilters}
            />
          </>
        )}
      </div>

      <div className={styles.userManagementUsersList}>
        {users.length > 0 &&
          users.map((item) => (
            <UserCard
              userData={item}
              key={item.id}
              resetPassword={resetPassword}
              className={styles.userManagementUsersListItem}
              onEditUser={submitEditUserHandler}
              isEditRestricted={!isStandaloneAtsUser}
              isReadOnly={isReadOnly}
            />
          ))}
        {hasNoFilteredUsers && (
          <div className={styles.noUsersFoundMessage}>
            {phrases.USER_MANAGEMENT_NO_USERS_FOUND(searchValue.toUpperCase())}
          </div>
        )}
      </div>
    </div>
  );
};
