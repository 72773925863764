import React, {
  CSSProperties,
  DOMAttributes,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { SearchCriteriaImportanceEnum } from '@air/api';
import { useCardSizeCalculator } from '@air/hooks';
import * as phrases from 'customer-portal/src/constants/phrases';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SvgIcon, Tooltip, TooltipWrapper } from '@air/components';
import { cssVariables } from '@air/constants/cssVariables';
import styles from './Card.css';
import stackItemTransition from '@air/styles/transitions/stackItem.css';
import criteriaZoomOut from '@air/styles/transitions/criteriaZoomOut.css';
import { scrollIntoView } from '@air/utils/dom';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import { isMobileOrTablet } from '@air/utils/mobile';
import { TooltipWrapperProps } from '@air/components/Tooltip/TooltipWrapper';

type CommonProps = {
  className?: string;
} & DOMAttributes<HTMLDivElement>;

export type CardSizeContextT = {
  cardSize?: CardSize;
  onCardSizeChange?: (size: CardSize, isResizingFinished: boolean) => void;
  setResizingFinished?: (isResizingFinished: boolean) => void;
  isResizingFinished?: boolean;
};
export const CardSizeContext = React.createContext<CardSizeContextT>({});
CardSizeContext.displayName = 'CardSizeContext';

export enum CardSize {
  normal,
  tall,
  wide,
  big,
  tallest,
  enormous,
  extraWide,
}

export const ResizeableCardWrapper: React.FC<{
  defaultSize?: CardSize;
  resizeable?: boolean;
}> = ({ defaultSize = CardSize.normal, resizeable = false, children }) => {
  const [cardSize, setCardSize] = useState(defaultSize);
  const [isResizingFinished, setResizingFinished] = useState(!resizeable);
  useEffect(() => {
    const isResizingFinished = !resizeable;
    if (isResizingFinished) {
      setResizingFinished(isResizingFinished);
    }
  }, [resizeable]);

  const onCardSizeChange = useCallback(
    (size, isResizingFinished) => {
      setResizingFinished(isResizingFinished);
      if (cardSize !== null && size <= cardSize) {
        return;
      }
      setCardSize(size);
    },
    [cardSize]
  );
  const sizeContext = useMemo(() => {
    return {
      cardSize,
      onCardSizeChange,
      setResizingFinished,
      isResizingFinished,
    };
  }, [cardSize, onCardSizeChange, setResizingFinished, isResizingFinished]);

  return (
    <CardSizeContext.Provider value={sizeContext}>
      {children}
    </CardSizeContext.Provider>
  );
};
/*
  Topmost wrapper for search criteria card states.
  Card dimensions, as well as global card styling should be managed from here.
*/
export const SearchCriteriaCardContainer = React.forwardRef<
  HTMLDivElement,
  CommonProps & {
    id?: string;
    isDragging?: boolean;
    importance?: SearchCriteriaImportanceEnum;
  }
>(
  (
    {
      id = null,
      children,
      className,
      isDragging = false,
      importance = null,
      ...props
    },
    ref: RefObject<HTMLDivElement>
  ) => {
    const { cardSize = CardSize.normal, isResizingFinished = true } =
      useContext(CardSizeContext);
    return (
      <div
        id={id}
        {...props}
        ref={ref}
        className={classNames(
          styles.searchCriteriaCardContainer,
          {
            [styles.isDragging]: isDragging,
            [styles.mainImportance]:
              importance === SearchCriteriaImportanceEnum.MANDATORY,
            [styles.requiredImportance]:
              importance === SearchCriteriaImportanceEnum.IMPORTANT,
            [styles.optionalImportance]:
              importance === SearchCriteriaImportanceEnum.OPTIONAL,
            [styles.noSize]: !isResizingFinished,
            [styles.normalSize]: cardSize === CardSize.normal,
            [styles.tallSize]: cardSize === CardSize.tall,
            [styles.tallestSize]: cardSize === CardSize.tallest,
            [styles.wideSize]: cardSize === CardSize.wide,
            [styles.bigSize]: cardSize === CardSize.big,
            [styles.enormousSize]: cardSize === CardSize.enormous,
            [styles.extraWideSize]: cardSize === CardSize.extraWide,
            [styles.isMobileOrTablet]: isMobileOrTablet,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);
SearchCriteriaCardContainer.displayName = 'SearchCriteriaCardContainer';

type ViewBackgroundProps = CommonProps & {
  topElement?: ReactNode;
  bottomElement?: ReactNode;
  isVisible?: boolean;
  actionButtonLabel?: string;
  onActionButtonClick?: React.ReactEventHandler;
};

export const SearchCriteriaCardViewBackground: React.FC<
  ViewBackgroundProps
> = ({
  isVisible = false,
  children,
  className,
  onActionButtonClick = R.noop,
  actionButtonLabel,
  topElement = null,
  bottomElement = null,
  onMouseOver = null,
  onMouseLeave = null,
}) => {
  return (
    <div
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={classNames(
        styles.searchCriteriaCardViewBackground,
        { [styles.visibleBackground]: isVisible },
        className
      )}
    >
      {topElement}
      {children}
      {R.cond([
        [R.always(!!bottomElement), R.always(bottomElement)],
        [
          R.always(!!actionButtonLabel),
          R.always(
            <div className={styles.actionWrapper}>
              <button
                type="button"
                className={styles.actionButton}
                onClick={onActionButtonClick}
              >
                {actionButtonLabel}
              </button>
            </div>
          ),
        ],
      ])()}
    </div>
  );
};
SearchCriteriaCardViewBackground.displayName =
  'SearchCriteriaCardViewBackground';

type ContentProps = CommonProps & {
  isDragging?: boolean;
  onRemove?: () => void;
  onMouseOver?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  shouldAnimateOnMount?: boolean;
};

const SearchCriteriaCardContent: React.FC<ContentProps> = ({
  children,
  className,
  onMouseOver = null,
  onMouseLeave = null,
  onClick = null,
  onRemove = null,
  isDragging = false,
  shouldAnimateOnMount = false,
}) => {
  const itemRef = useRef(null);
  // Autoscroll to the manually added card
  useEffect(() => {
    if (itemRef?.current && shouldAnimateOnMount) {
      scrollIntoView(itemRef.current, { behavior: 'smooth' });
    }
  }, [itemRef, shouldAnimateOnMount]);

  return (
    <CSSTransition
      in={shouldAnimateOnMount}
      classNames={criteriaZoomOut}
      timeout={0}
      appear
    >
      <div
        ref={itemRef}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        className={classNames(styles.searchCriteriaCardContent, className, {
          [styles.isDragging]: isDragging,
          [styles.isMobileOrTablet]: isMobileOrTablet,
        })}
      >
        {onRemove && (
          <button
            type="button"
            className={styles.removeIconButton}
            onClick={onRemove}
          >
            <SvgIcon icon="close-icon" width="0.7em" height="0.7em" />
          </button>
        )}
        {children}
      </div>
    </CSSTransition>
  );
};

SearchCriteriaCardContent.displayName = 'SearchCriteriaCardContent';
/*
  Outer wrapper for search criteria EDIT state.
  It should hold visual styling for edit form, in particular solid colored
  background. It is also in charge of form's positioning,
  which will be affected by cards stack component, when it's introduced.
*/
export const SearchCriteriaEditForm = React.forwardRef<
  HTMLDivElement,
  CommonProps & {
    children: React.ReactNode;
    style?: React.CSSProperties;
    shouldAnimateOnMount?: boolean;
  }
>(
  (
    { children, className, style = null, shouldAnimateOnMount = false },
    ref: RefObject<HTMLDivElement>
  ) => {
    /*
    This effect adds autoscroll to display whole EditForm, when collapsed
    card is brought to EDIT state.
  */
    useEffect(() => {
      scrollIntoView(ref.current, { behavior: 'smooth', block: 'nearest' });
    }, [ref]);

    return (
      <CSSTransition
        in={shouldAnimateOnMount}
        classNames={criteriaZoomOut}
        timeout={0}
        appear
      >
        <div
          style={style}
          ref={ref}
          className={classNames(styles.searchCriteriaEditForm, className)}
        >
          {children}
        </div>
      </CSSTransition>
    );
  }
);
SearchCriteriaEditForm.displayName = 'SearchCriteriaEditForm';

/*
  Small label sitting on top of SearchCriteriaEditForm, like: +SKILL.
 */
export const SearchCriteriaCardLabel: React.FC<
  CommonProps & {
    hidden?: boolean;
    isActive?: boolean;
    showExplanationTooltip?: boolean;
    onClick?: () => void;
    text: string;
  }
> = ({
  className,
  isActive,
  showExplanationTooltip,
  onClick = null,
  text,
  hidden = false,
}) => {
  const isInteractive = !!onClick;

  return (
    <div
      className={classNames(styles.searchCriteriaCardLabel, className, {
        [styles.hiddenLabel]: hidden,
      })}
    >
      <TooltipWrapper
        enabled={!hidden && showExplanationTooltip}
        tooltip={phrases.SEARCH_CRITERIA_LABEL_EXPLANATION}
      >
        <span
          className={classNames(styles.searchCriteriaCardLabelText, {
            [styles.isActive]: isActive,
            [styles.isInteractive]: isInteractive && isActive,
          })}
          onClick={onClick}
        >
          {text}
        </span>
      </TooltipWrapper>
    </div>
  );
};
SearchCriteriaCardLabel.displayName = 'SearchCriteriaCardLabel';

/*
  Special component to display additional elements of search criteria card,
  which are not displayed by default, but should expand and become visible based on some condition.
  Such as in new design of 'importance selection' area on SearchCriteriaCard' edit form.
*/
export const SearchCriteriaCollapsibleArea: React.FC<
  CommonProps & { isCollapsed: boolean }
> = ({ children, className, isCollapsed = true }) => {
  return (
    <div
      className={classNames(styles.searchCriteriaCollapsibleArea, className, {
        [styles.isCollapsed]: isCollapsed,
      })}
    >
      {children}
    </div>
  );
};
SearchCriteriaCollapsibleArea.displayName = 'SearchCriteriaCollapsibleArea';

/*
  Wrapper for Search criteria form fields, which organizes them in
  the middle of the form, and provides background, round corners
  and some additional styling.
  `style` property is used to allow dynamic update of max-heigh
*/
export const SearchCriteriaEditFormFields: React.FC<
  CommonProps & {
    style?: React.CSSProperties;
    isCollapsed?: boolean;
  }
> = ({ children, className, style = null, isCollapsed = false }) => {
  const [collapsedInternal, setCollapsed] = useState(isCollapsed);

  useEffect(() => {
    let timeoutId: number;
    if (!isCollapsed) {
      timeoutId = window.setTimeout(() => {
        setCollapsed(isCollapsed);
      }, parseInt(cssVariables['--fast-transition']));
    } else {
      setCollapsed(isCollapsed);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isCollapsed]);

  return (
    <div
      style={style}
      className={classNames(styles.searchCriteriaEditFormFields, className, {
        [styles.secondaryFormFieldsVisible]: !collapsedInternal,
      })}
    >
      {children}
    </div>
  );
};
SearchCriteriaEditFormFields.displayName = 'SearchCriteriaEditFormFields';

/*
  Component to display importance icon on card,
  with optional callback on click.
*/
const importanceIcons = {
  [SearchCriteriaImportanceEnum.MANDATORY]: 'importance-mandatory',
  [SearchCriteriaImportanceEnum.IMPORTANT]: 'importance-important',
  [SearchCriteriaImportanceEnum.OPTIONAL]: 'importance-optional',
};
const importanceIconTitles = {
  [SearchCriteriaImportanceEnum.MANDATORY]:
    phrases.MANDATORY_CRITERIA_ICON_TITLE,
  [SearchCriteriaImportanceEnum.IMPORTANT]:
    phrases.IMPORTANT_CRITERIA_ICON_TITLE,
  [SearchCriteriaImportanceEnum.OPTIONAL]: phrases.OPTIONAL_CRITERIA_ICON_TITLE,
};
export const SearchCriteriaImportanceIndicator: React.FC<
  CommonProps & {
    onClick?: React.ReactEventHandler;
    importance: SearchCriteriaImportanceEnum;
  }
> = ({ className, onClick = null, importance }) => {
  const isInteractive = !!onClick;

  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.searchCriteriaImportanceIndicator,
        className,
        {
          [styles.isInteractive]: isInteractive,
        }
      )}
    >
      <Tooltip
        placement="top"
        trigger="hover"
        tooltip={importanceIconTitles[importance]}
      >
        <SvgIcon
          width="1.2em"
          height="1em"
          icon={importanceIcons[importance]}
        />
      </Tooltip>
    </div>
  );
};
SearchCriteriaImportanceIndicator.displayName =
  'SearchCriteriaImportanceIndicator';

export enum StackPosition {
  top = 'top',
  bottom = 'bottom',
}
const SearchCriteriaStack: React.FC<
  CommonProps & {
    className?: string;
    isClosed?: boolean;
    maxHeight?: number;
    onClick?: (e: MouseEvent) => void;
    position?: StackPosition;
    children: React.ReactElement[];
    scrollOverflowElement?: React.ReactElement;
  }
> & { position: typeof StackPosition } = ({
  scrollOverflowElement = null,
  maxHeight,
  className,
  children,
  isClosed = false,
  onClick = null,
  position = StackPosition.top,
}) => {
  /*
    At the moment internal closed state tracking is required to close stack instantly,
    when opening priority (importance) hidden section, but opening
    it should occur only when importance transaction is finished.

    We need to find a solution for better parametrization of this
    behavior.
  */
  const [closedInternal, setClosed] = useState(isClosed);
  useEffect(() => {
    let timeoutId: number;
    if (!isClosed) {
      timeoutId = window.setTimeout(() => {
        setClosed(isClosed);
      }, parseInt(cssVariables['--fast-transition']));
    } else {
      setClosed(isClosed);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isClosed]);

  /*
    Proper animation of top stack expand/collapse requires calculation
    of current stack height to set it as `max-height`.
    When any StackItem appears, its height is added to
    total height via a callback, and when it is removed,
    we subtract its height.

    At the same time stack's height can be increased if user
    opens dropdown menu from any stack item, to update values.
    To allow this additional increase without overflowing
    stack container, we add extra height, because it doesn't
    affect animation significantly.
  */
  const [stackHeight, setStackHeight] = useState(0);
  const MAX_ADDITIONAL_HEIGHT_FROM_MENU_DROPDOWN = 200;

  const isStackOpened = !closedInternal;

  return (
    <div
      style={
        {
          '--stack-max-height': `${
            maxHeight
              ? maxHeight
              : stackHeight + MAX_ADDITIONAL_HEIGHT_FROM_MENU_DROPDOWN
          }px`,
        } as CSSProperties
      }
      onClick={onClick}
      className={classNames(styles.searchCriteriaStack, className, {
        [styles.topStack]: position === 'top',
        [styles.bottomStack]: position === 'bottom',
        [styles.openedStack]: isStackOpened,
        [styles.scrollable]: !!maxHeight,
      })}
    >
      {children.length > 0 && (
        <div className={styles.scrollableContent}>
          <TransitionGroup component={null}>
            {React.Children.map(children, (child, index) => (
              <CSSTransition
                key={child.key}
                timeout={parseInt(cssVariables['--fast-transition'])}
                appear
                classNames={stackItemTransition}
              >
                {React.cloneElement(child, {
                  style:
                    position === 'bottom'
                      ? {
                          zIndex: children.length - index,
                        }
                      : null,
                  updateStackHeight: setStackHeight,
                })}
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      )}

      {!!maxHeight && isStackOpened && maxHeight < stackHeight && (
        <div
          style={{ zIndex: children.length + 1 }}
          className={styles.scrollOverflowElement}
        >
          {scrollOverflowElement}
        </div>
      )}
    </div>
  );
};
SearchCriteriaStack.displayName = 'SearchCriteriaStack';
SearchCriteriaStack.position = StackPosition;

const SearchCriteriaStackToggle: React.FC<
  CommonProps & {
    className?: string;
    onClick?: () => void;
    isHidden?: boolean;
    stackSize?: number;
    stackClasses?: string[];
    fixedHeight?: boolean;
  }
> = ({
  onClick,
  isHidden = false,
  stackSize = 0,
  className,
  stackClasses = [],
  fixedHeight = true,
}) => {
  const MAX_STACK_TOGGLE_CARDS = 2;
  const [hiddenInternal, setIsHiddenInternal] = useState(isHidden);

  useEffect(() => {
    let timeoutId: number;
    if (isHidden) {
      setIsHiddenInternal(isHidden);
    } else if (hiddenInternal) {
      timeoutId = window.setTimeout(() => {
        setIsHiddenInternal(isHidden);
      }, parseInt(cssVariables['--fast-transition']));
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [hiddenInternal, isHidden]);

  return (
    <div
      onClick={(event: any) => {
        onClick();
        event.stopPropagation();
      }}
      style={
        fixedHeight
          ? null
          : ({
              '--bottom-stack-item-multiplier': Math.min(
                stackSize,
                MAX_STACK_TOGGLE_CARDS
              ),
            } as React.CSSProperties)
      }
      className={classNames(className, styles.searchCriteriaStackToggle, {
        [styles.hidden]: hiddenInternal,
      })}
    >
      {Array.from({ length: Math.min(stackSize, MAX_STACK_TOGGLE_CARDS) }).map(
        (_, i) => (
          <div
            key={i}
            className={classNames(styles.stackToggleItem, stackClasses[i])}
          />
        )
      )}
    </div>
  );
};
SearchCriteriaStackToggle.displayName = 'SearchCriteriaStackToggle';

const SearchCriteriaEditFormMainTitle: React.FC<{
  children: any;
  importance: SearchCriteriaImportanceEnum;
  className?: string;
  showTooltip?: boolean;
  getTooltipText?: (currentTooltipText: string) => string;
  tooltipProps?: Partial<TooltipWrapperProps>;
}> = ({
  children,
  importance,
  className = '',
  showTooltip,
  getTooltipText,
  tooltipProps,
}) => (
  <div
    className={classNames(
      styles.searchCriteriaEditFormMainTitleContainer,
      className
    )}
  >
    <Title
      resizeable
      title={children}
      className={styles.searchCriteriaEditFormMainTitleText}
      showTooltip={showTooltip}
      getTooltipText={getTooltipText}
      tooltipProps={tooltipProps}
    />
    <Card.SearchCriteriaImportanceIndicator
      className={styles.importanceSwitch}
      importance={importance}
    />
  </div>
);
SearchCriteriaEditFormMainTitle.displayName = 'SearchCriteriaEditFormMainTitle';

export const StackLabel: React.FC<any> = ({
  children,
  onClick,
  disabled,
  className,
}) => {
  return (
    <button
      type="button"
      onClick={disabled ? R.noop : onClick}
      disabled={disabled}
      className={classNames(styles.stackItemLabel, className, {
        [styles.stackItemLabelInteractive]: !disabled,
      })}
    >
      {children}
    </button>
  );
};

StackLabel.displayName = 'StackLabel';

type SearchCriteriaStackItemProps = CommonProps & {
  unstyled?: boolean;
  label?: string;
  style?: React.CSSProperties;
  labelClassName?: string;
  isLabelInteractive?: boolean;
  isDragging?: boolean;
  onLabelClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onRemoveItem?: (
    event: React.MouseEvent<HTMLButtonElement>,
    current: HTMLDivElement | null
  ) => void;
  updateStackHeight?: (callback: (height: number) => number) => void;
};
const SearchCriteriaStackItem: React.FC<SearchCriteriaStackItemProps> = ({
  children,
  className,
  style = {},
  labelClassName,
  isDragging = false,
  onRemoveItem,
  unstyled = false,
  label,
  onLabelClick = null,
  updateStackHeight = R.noop,
  isLabelInteractive,
}) => {
  const [stackItemHeight, setStackItemHeight] = useState(0);
  const stackItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const nodeHeight = stackItemRef?.current?.clientHeight ?? 0;
    setStackItemHeight(nodeHeight);
    updateStackHeight((stackHeight) => stackHeight + nodeHeight);
  }, [stackItemRef, updateStackHeight]);

  return (
    <div
      ref={stackItemRef}
      style={
        {
          ...style,
          '--stack-item-height': `${stackItemHeight}px`,
        } as CSSProperties
      }
      className={classNames(styles.stackItem, className, {
        [styles.defaultStackItem]: !unstyled,
        [styles.hasLabel]: !!label,
        [styles.isDragging]: isDragging,
      })}
    >
      {label && (
        <StackLabel
          onClick={onLabelClick}
          disabled={!onLabelClick || isLabelInteractive === false}
          className={labelClassName}
        >
          {label}
        </StackLabel>
      )}
      {onRemoveItem && (
        <button
          type="button"
          className={styles.removeIconButton}
          onClick={(event: any) => {
            onRemoveItem(event, stackItemRef.current);
            updateStackHeight((stackHeight) =>
              Math.max(stackHeight - stackItemHeight, 0)
            );
          }}
        >
          <SvgIcon icon="close-icon" width="0.7em" height="0.7em" />
        </button>
      )}
      {children}
    </div>
  );
};
SearchCriteriaStackItem.displayName = 'SearchCriteriaStackItem';

/*
  This is a deprecated container component.
  For new version of search criteria cards, it was separated into 2 pieces.
  Use SearchCriteriaCardContainer as topmost container component for search criteria card.
  Use SearchCriteriaEditForm as a wrapper for card's EDIT state.
*/
export const SearchCriteriaEditContainer = React.forwardRef<
  HTMLDivElement,
  CommonProps & { children: React.ReactNode }
>(({ children, className }, ref: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const elem = ref && ref.current;
    if (elem && typeof elem.scrollIntoView === 'function') {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [ref]);

  return (
    <div className={styles.searchCriteriaEditContainer}>
      <div
        ref={ref}
        className={classNames(
          styles.searchCriteriaEditFormDeprecated,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
});
SearchCriteriaEditContainer.displayName = 'SearchCriteriaEditContainer';

export const DELAY_CARD_TYPE_MS = 500;
const checkCursorPosition = (rect: any, cursorPosition: any, cb: any) => {
  if (
    cursorPosition.x > rect.right ||
    cursorPosition.y > rect.bottom ||
    cursorPosition.x < rect.left ||
    cursorPosition.y < rect.top
  ) {
    cb();
  }
};
export const DelayedCardHint: React.FC<any> = ({ children, text = '' }) => {
  const [shown, updateState] = useState(false);
  const timeoutId = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const showHint = () => {
    timeoutId.current = setTimeout(() => updateState(true), DELAY_CARD_TYPE_MS);
  };

  const hideHint = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    updateState(false);
  };

  return (
    <div
      className={classNames(styles.cardTypeWrapper)}
      onMouseEnter={showHint}
      onMouseLeave={hideHint}
      /*
       * when clicking on card, check position of cursor,
       * if card was collapsed, cursor might be on top of another element,
       * but mouse leave doesn't fired.
       * @todo: think about better solution.
       **/
      onClick={(event: any) => {
        const el = event.currentTarget;
        const cursorPosition = { x: event.clientX, y: event.clientY };
        setTimeout(() => {
          checkCursorPosition(
            el.getBoundingClientRect(),
            cursorPosition,
            hideHint
          );
        }, 0);
      }}
      /*
       * when dragging card, onMouseLeave isn't triggered, and card type stays on card,
       * so we need to hide it onDragLeave as well
       **/
      onDragLeave={hideHint}
    >
      {shown && <div className={styles.cardTypeContent}>{text}</div>}
      {children}
    </div>
  );
};

type CardTitlePropsT = {
  title: string | ReactNode | ReactNode[];
  className?: string;
  tooltipClassName?: string;
  tooltipDelayShow?: number;
  expanded?: boolean;
  flexGrow?: boolean;
  resizeable?: boolean;
  isStackTitle?: boolean;
  showTooltip?: boolean;
  getTooltipText?: (currentTooltipText: string) => string;
  tooltipProps?: Partial<TooltipWrapperProps>;
};
export const Title: React.FC<CardTitlePropsT> = ({
  title,
  className = '',
  tooltipClassName = '',
  tooltipDelayShow = TOOLTIP_DELAY_TIME_LONG,
  expanded = false,
  flexGrow = true,
  resizeable = false,
  isStackTitle = false,
  showTooltip,
  getTooltipText,
  tooltipProps = {},
}) => {
  const cardSizeContext = useContext(CardSizeContext);

  const {
    titleRef,
    textRef,
    isOverflownHorizontally,
    isOverflownVertically,
    isResizingFinished,
  } = useCardSizeCalculator(cardSizeContext, resizeable, isStackTitle);

  const shouldShowOverflownTooltip =
    ((resizeable && isResizingFinished) || !resizeable) &&
    (isOverflownHorizontally || isOverflownVertically);

  const overflownTooltipText = shouldShowOverflownTooltip ? title : '';

  const tooltipText = getTooltipText
    ? getTooltipText(overflownTooltipText?.toString())
    : overflownTooltipText;

  return (
    <TooltipWrapper
      flexGrow={flexGrow}
      containerClassName={classNames(tooltipClassName)}
      enabled={tooltipText && showTooltip !== false}
      tooltip={tooltipText}
      delayShow={tooltipDelayShow}
      {...tooltipProps}
    >
      <p
        ref={titleRef}
        className={classNames(styles.title, className, {
          [styles.expandedTitle]: expanded,
          [styles.horizontallyOverflown]: isOverflownHorizontally,
          [styles.verticallyOverflown]: isOverflownVertically,
          [styles.flexGrow]: flexGrow,
        })}
      >
        <span ref={textRef}>{title}</span>
      </p>
    </TooltipWrapper>
  );
};

export const TitleLabel: React.FC<{
  text?: string | React.ReactNode;
  className?: string;
}> = ({ text, className = '' }) =>
  text ? (
    <div className={classNames(styles.titleLabel, className)}>{text}</div>
  ) : null;
TitleLabel.displayName = 'TitleLabel';

export const Footer: React.FC<{
  text?: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}> = ({ text, className, children }) => {
  const [isOverflown, setOverflown] = useState(null);
  const footerRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    let observer: IntersectionObserver;
    if (footerRef.current && textRef.current && isOverflown === null) {
      /*
        IntersectionObserver allows us to  figure out if InfoText
        fits inside Footer space, and in this case .intersectionRation
        will be around 0.99–1, meaning that 100% of Infotext span
        fits into Footer. If part of InfoText overflows Footer, this
        ratio will be smaller (depening on overall size of text).
      */
      observer = new IntersectionObserver(
        function ([entry]) {
          if (entry.isIntersecting) {
            setOverflown(!(entry.intersectionRatio > 0.95));
          }
          observer.disconnect();
        },
        { root: footerRef.current }
      );
      observer.observe(textRef.current);
    }
    return () => {
      if (observer) {
        return observer.disconnect;
      }
    };
  }, [isOverflown]);

  return (
    <div
      ref={footerRef}
      className={classNames(styles.footer, styles.withWhiteShadow, className, {
        [styles.noHighlight]: !isOverflown,
      })}
    >
      {text && <CardInfoText ref={textRef}>{text}</CardInfoText>}
      {children}
    </div>
  );
};

export const CardInfoText = React.forwardRef<
  HTMLSpanElement,
  {
    children: React.ReactNode;
    className?: string;
  }
>(({ className, children }, ref) => {
  return (
    <span ref={ref} className={classNames(styles.infoText, className)}>
      {children}
    </span>
  );
});

/*
 * Applicant Portal elements
 * */

export const CardAlert: React.FC<{
  className?: string;
}> = ({ children, className = '' }) => (
  <span className={classNames(styles.cardAlert, className)}>{children}</span>
);

export const Card = {
  Title,
  TitleLabel,
  Footer,
  StackLabel,
  ResizeableCardWrapper,
  SearchCriteriaCardContainer,
  SearchCriteriaCardViewBackground,
  SearchCriteriaCardContent,
  SearchCriteriaCollapsibleArea,
  SearchCriteriaEditForm,
  SearchCriteriaCardLabel,
  SearchCriteriaEditFormFields,
  SearchCriteriaEditFormMainTitle,
  SearchCriteriaEditContainer,
  SearchCriteriaImportanceIndicator,
  SearchCriteriaStack,
  SearchCriteriaStackToggle,
  SearchCriteriaStackItem,
  DelayedCardHint,
  InfoText: CardInfoText,
  Alert: CardAlert,
};
