import React, { createContext, useContext, useState, useCallback } from 'react';
import {
  SearchCardActions,
  SearchCardState,
} from 'components/SearchCriteriaCards/hooks/SearchCardStateConfig';
import { CurrentStateObject, SendFunction } from '@air/hooks/useStateMachine';
import styles from '@air/components/Card/Card.css';
import { useDelayedEvent } from '@air/hooks';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CHANGE_IMPORTANCE_BUTTON } from 'constants/gaLabels';
import { CARD_BACKGROUND_DELAY_DURATION } from '@air/domain/Common/Cards';

type StateContextType = {
  states: {
    // edit mode states:
    isCardValid: boolean;
    isImportanceSectionOpened: boolean;
    // view mode states:
    isStackOpened: boolean;
    isReadOnly: boolean;
  };
  handlers: {
    // edit mode handlers:
    toggleImportanceHandler: () => void;
    saveOrDiscardHandler: () => void;
    clickConfirmHandler: () => void;
    // view mode handlers:
    toggleStackHandler: (shouldBeOpened: boolean) => void;
    openHoveredViewHandler: (event: React.MouseEvent) => void;
    closeHoveredViewHandler: (event?: React.MouseEvent) => void;
    openInitialViewHandler: () => void;
    openEditFormHandler: () => void;
    toggleDroppableStateHandler: (canDrop: boolean) => void;
  };
  cardState: CurrentStateObject<SearchCardState>;
  dispatch: SendFunction<SearchCardActions>;
};

export const StateContext: React.Context<StateContextType> = createContext({
  states: null,
  handlers: null,
  cardState: null,
  dispatch: null,
});

export function useSearchCardContext() {
  return useContext(StateContext);
}

type SearchCardContextProps = {
  cardState: CurrentStateObject<SearchCardState>;
  dispatch: SendFunction<SearchCardActions>;
  onUpdate(): void;
  onRemove(): void;
};

export const SearchCardContext: React.FC<SearchCardContextProps> = ({
  children,
  cardState,
  dispatch,
  onUpdate,
  onRemove,
}) => {
  // Since validation is not implemented yet, we simply assume that 'edit.fields.updated' state refers to valid card,
  // and 'edit.fields.initial' - to invalid card
  const isCardValid = !cardState.matches(SearchCardState.emptyFields);
  const isReadOnly = cardState.in([
    SearchCardState.viewReadOnly,
    SearchCardState.editReadOnly,
  ]);

  const isImportanceSectionOpened = cardState.matches(
    SearchCardState.editImportance
  );

  const [isStackOpened, toggleStackHandler] = useState<boolean>(false);

  /**
   * @name toggleImportanceHandler
   */
  const toggleImportanceHandler = useCallback(() => {
    if (isImportanceSectionOpened) {
      trackEvent({
        category: GACategory.DraftPage,
        label: GA_LABEL_CHANGE_IMPORTANCE_BUTTON,
      });
      dispatch(SearchCardActions.updateImportance);
    } else {
      dispatch(SearchCardActions.openImportance);
    }
  }, [dispatch, isImportanceSectionOpened]);

  /**
   * @name saveOrDiscardHandler
   * @description saving card depends on whether the card is valid or not,
   * if it's not valid, discard it, otherwise - save.
   */
  const saveOrDiscardHandler = useCallback(async () => {
    if (isCardValid) {
      // save card and close edit mode:
      await onUpdate();
      dispatch(SearchCardActions.closeEdit);
    } else {
      // remove card
      onRemove();
    }
  }, [dispatch, isCardValid, onRemove, onUpdate]);

  const clickConfirmHandler = useCallback(async () => {
    if (isImportanceSectionOpened) {
      onUpdate();
      trackEvent({
        category: GACategory.DraftPage,
        label: GA_LABEL_CHANGE_IMPORTANCE_BUTTON,
      });
      dispatch(SearchCardActions.updateImportance);
    } else {
      await saveOrDiscardHandler();
    }
  }, [dispatch, isImportanceSectionOpened, onUpdate, saveOrDiscardHandler]);

  const openHoveredViewHandler = useCallback(
    (event: any) => {
      /*
      This callback is attached as `onMouseOver` event handler
      on SearchCriteriaCardViewBackground component, which is
      a parent element for the rest of card's View state visible
      elements.
      By its nature, this event is triggered multiple times when
      mouse is moved over child elements in View state.

      We can't use onMouseEnter event here, because by design this
      callback should not be triggered when cursor is hovered over
      card's stack toggle element.
      Since stack toggle is a child element of SearchCriteriaCardViewBackground
      as well, hover on stack toggle will trigger onMouseEnter of
      ViewBackground only once, and it will be impossible to know
      when cursor is moved from stack toggle to card's center, for example.
      So if we prevent dispatch on first mouseEnter, `modifyView`
      will be never dispatched.

      On the other hand, mouseOver will be triggered multiple times,
      but we need to add additional checks to prevent multiple
      updates of cardState, if were already in `viewBackground` state.
    */
      if (
        !event.target.closest(`.${styles.searchCriteriaStackToggle}`) &&
        !cardState.matches(SearchCardState.viewBackgroundHovered)
      ) {
        dispatch(SearchCardActions.displayHoveredBackground);
      }
    },
    [dispatch, cardState]
  );
  const closeHoveredViewHandler = useCallback(
    (event?: any) => {
      /*
      Special case to handle mouse moving from main content to its sibling
      elements inside SearchCriteriaCardContainer (like stack, or stack toggle).

      I've decided to put it here for now, since it's needed in every card
      on search form, but maybe it will be extracted in future.

      .currentTarget check is required, because sometimes when moving cursor
      between 2 cards, event handler doesn't register leaving first card,
      so .closest() check fails.

      We also make some extra checks to ensure that relatedTarget contains a reference
      to a correct Element, otherwise an exception is thrown.
      For example it can contain a reference to Window if browser window looses focus,
      so here is an explicit check for nodeType (it is defined for all Elements, but not for other possible
      event targets). The same reason for event.relatedTarget.closest - not all possible event targets have this
      method.
    */
      if (
        event &&
        event?.relatedTarget?.nodeType &&
        event.currentTarget.contains(event.relatedTarget) &&
        event.relatedTarget.closest &&
        event.relatedTarget.closest(`.${styles.searchCriteriaCardContainer}`)
      ) {
        return;
      }
      dispatch(SearchCardActions.showInitial);
    },
    [dispatch]
  );

  const [delayedOpenHoveredView, delayedClosedHoveredview] = useDelayedEvent(
    openHoveredViewHandler,
    closeHoveredViewHandler,
    CARD_BACKGROUND_DELAY_DURATION
  );

  const openInitialViewHandler = () => {
    dispatch(SearchCardActions.showInitial);
  };
  const openEditFormHandler = () => dispatch(SearchCardActions.openEdit);
  const toggleDroppableStateHandler = (canDrop: boolean) => {
    if (canDrop) {
      dispatch(SearchCardActions.displayDroppableBackground);
    } else if (cardState.matches(SearchCardState.viewBackgroundDroppable)) {
      dispatch(SearchCardActions.showInitial);
    }
  };

  // TODO: Think about providing Discard/Update methods to context as well.
  const states = {
    isCardValid,
    isImportanceSectionOpened,
    isStackOpened,
    isReadOnly,
  };

  const handlers = {
    toggleImportanceHandler,
    saveOrDiscardHandler,
    clickConfirmHandler,
    toggleStackHandler,
    openHoveredViewHandler: delayedOpenHoveredView,
    closeHoveredViewHandler: delayedClosedHoveredview,
    openInitialViewHandler,
    openEditFormHandler,
    toggleDroppableStateHandler,
  };

  return (
    <StateContext.Provider value={{ states, handlers, cardState, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};
