import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import {
  parseErrorJson,
  parseResponseBlob,
  parseResponseJson,
} from '@air/utils/api';
import {
  EmailTemplateRequest,
  EmailTemplateShortResponse,
  JobEmailsRequest,
  SendJobEmailsRequest,
} from '@air/api';
import { DEFAULT_MAX_FETCH_SIZE } from '@air/constants/app';
import { Task } from 'air-shared/utils/fp';

const defaultParams = {
  size: DEFAULT_MAX_FETCH_SIZE,
  sort: 'updated,desc',
};

export const fetchEmailTemplateSectionsList = () => {
  return Http.get(urls.EMAIL_CONFIG_SECTIONS_SCHEMA_URL)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const saveJobEmail = (
  searchId: number,
  params: JobEmailsRequest,
  emailId?: string
) => {
  const url = emailId
    ? urls.getJobEmailByIdUrl(searchId, emailId)
    : urls.getJobEmailsUrl(searchId);

  return Http.post(url)
    .bind(Http.withJsonBody(params))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchJobEmails = (
  searchId: number,
  params?: { size?: number; sort?: string }
) => {
  const { sort, size } = defaultParams;
  const fetchParams = { sort, size, ...params };

  return Http.get(urls.getJobEmailsUrl(searchId))
    .bind(Http.withQueryParams(fetchParams))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchJobEmailById = (searchId: number, emailId: string) => {
  return Http.get(urls.getJobEmailByIdUrl(searchId, emailId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const createEmailTemplate = (params: EmailTemplateRequest) => {
  return Http.post(urls.EMAIL_TEMPLATE_URL)
    .bind(Http.withJsonBody(params))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchEmailTemplates = (params?: {
  size?: number;
  sort?: string;
}) => {
  const { sort, size } = defaultParams;
  const fetchParams = { sort, size, ...params };

  return Http.get(urls.EMAIL_TEMPLATE_URL)
    .bind(Http.withQueryParams(fetchParams))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchDefaultEmailTemplate = (): Task<
  EmailTemplateShortResponse[]
> => {
  return Http.get(urls.getDefaultTemplateUrl())
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchEmailTemplateById = (id: string) => {
  return Http.get(urls.getEmailTemplateByIdUrl(id))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const deleteEmailTemplateById = (id: string) => {
  return Http.remove(urls.getEmailTemplateByIdUrl(id))
    .toTask()
    .chain(parseResponseBlob)
    .chainError(parseErrorJson);
};

export const deleteJobEmailById = (searchId: number, emailId: string) => {
  return Http.remove(urls.getJobEmailByIdUrl(searchId, emailId))
    .toTask()
    .chain(parseResponseBlob)
    .chainError(parseErrorJson);
};

export const fetchEmailRecipients = (
  emailId: string,
  searchId: number,
  params: { size: number } = { size: defaultParams.size }
) => {
  return Http.get(urls.fetchEmailRecipientsUrl(emailId, searchId))
    .bind(Http.withQueryParams(params))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const duplicateEmailTemplate = (id: string) => {
  return Http.post(urls.duplicateEmailTemplateUrl(id))
    .bind(Http.withJsonBody({ id }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const duplicateJobEmail = (jobEmailId: string, searchId: number) => {
  return Http.post(urls.duplicateJobEmailUrl(jobEmailId, searchId))
    .bind(Http.withJsonBody({ jobEmailId, searchId }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateEmailTemplate = (
  id: string,
  template: EmailTemplateRequest
) => {
  return Http.put(urls.getEmailTemplateByIdUrl(id))
    .bind(Http.withJsonBody(template))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const discardEmailTemplateChanges = (id: string) => {
  return Http.put(urls.discardEmailTemplateChangesUrl(id))
    .bind(Http.withJsonBody({ id }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const submitUpdateEmailTemplate = (
  id: string,
  params: EmailTemplateRequest
) => {
  return Http.post(urls.getEmailTemplateByIdUrl(id))
    .bind(Http.withJsonBody(params))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const getEmailPreviewForCandidate = (
  searchId: number,
  emailId: string,
  candidateId: string
) => {
  return Http.get(urls.getEmailPreviewUrl(searchId, emailId, candidateId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const sendJobEmails = (
  searchId: number,
  emailId: string,
  params: SendJobEmailsRequest
) => {
  return Http.post(urls.sendJobEmailsUrl(searchId, emailId))
    .bind(Http.withJsonBody(params))
    .toTask()
    .chainError(parseErrorJson);
};
