import React, { useMemo } from 'react';
import R from '@air/third-party/ramda';

import { Paragraph, UIText } from '@air/components';
import { enrichedByPIPL, PhoneNumberExtended } from 'domain/CandidateData';
import { ContactItem } from '../ContactItem/ContactItem';

import styles from './PhonesInfo.css';
import copy from 'copy-to-clipboard';
import { toast } from '@air/third-party/toast';
import * as phrases from 'constants/phrases';

const getLocationByPhoneNumber = (phoneNumber: PhoneNumberExtended) => {
  return R.props(['state', 'country'])(
    phoneNumber as Required<PhoneNumberExtended>
  )
    .filter(Boolean)
    .join(', ');
};

const PhoneItem = ({ phoneNumber }: { phoneNumber: PhoneNumberExtended }) => {
  const isEnrichedByPIPL = useMemo(() => {
    return enrichedByPIPL(phoneNumber.sources);
  }, [phoneNumber.sources]);

  const onCopyIconClickHandler = () => {
    copy(phoneNumber.phone);
    toast.dark(phrases.CANDIDATE_PROFILE_CONTACTS_TOAST_TEXT_COPIED);
  };

  return (
    <>
      <ContactItem
        containerClassName={styles.wrapper}
        onIconClicked={onCopyIconClickHandler}
        isEnrichedByPIPL={isEnrichedByPIPL}
      >
        <div>
          <Paragraph short>
            <a href={`tel:${phoneNumber.phone}`}>{phoneNumber.phone}</a>
          </Paragraph>
          <UIText tiny className={styles.phoneNumberOrigin}>
            {getLocationByPhoneNumber(phoneNumber)}
          </UIText>
        </div>
      </ContactItem>
    </>
  );
};

export const PhonesByType = ({
  phoneNumbers,
}: {
  phoneNumbers: PhoneNumberExtended[];
}) => {
  return (
    <>
      {phoneNumbers.map((phoneNumber: any, index: number) => (
        <PhoneItem key={phoneNumber.phone + index} phoneNumber={phoneNumber} />
      ))}
    </>
  );
};
