// imports from vendor deps
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import { History, Location } from 'history';
// imports from types
import { SearchProgressStatusEnum } from '@air/api';
import { KanbanContextT, UploadStatus } from 'context';
// imports from 'components'
import {
  Button,
  FeatureView,
  RightSidebar,
  SearchBlockScreen,
  UploadsSidebarWrapper,
} from 'components';
// imports from 'constants'
import { SearchListItemT } from 'domain/Kanban/Kanban';
import { EMPTY_JOB } from 'providers/JobsProvider';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';
import {
  JobDescription,
  NewSearchView,
  UtilitiesControls,
  GenerateReportPanel,
} from 'features';
// imports from styles
import styles from './DraftSection.css';
import commonStyles from 'styles/commonStyles.css';
// import from images
// import {} from 'images'
// imports from helpers
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit, subscribe } from 'hooks/usePubSub';
import { isDraftSearchValid } from 'features/DraftSection/utils';
import { useUploads } from 'hooks/useUploads';
import { useUploadPanelControl } from 'hooks/useUploadPanelControl';
import { getCurrentRequisition } from 'utils/uploads';
import {
  CLICK_DEBOUNCE_TIME_LONG,
  TOOLTIP_DELAY_TIME_SHORT,
  TOOLTIP_DELAY_TIME_LONG,
} from '@air/constants/app';
import { GACategory } from 'air-shared/domain/Common/GATypes';
import {
  GA_LABEL_DISCARD_DRAFT,
  GA_LABEL_START_SCREENING,
} from 'constants/gaLabels';
import { trackEvent } from 'air-shared/utils/ga';
import { LineupTabs } from '@air/constants/tabs';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import {
  kanbanSelectors,
  customerProfileSelectors,
  appSelectors,
  uploadApplicantsSelectors,
} from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  useAppContext,
  useAppMethods,
  RightSidebarPanel,
} from 'providers/AppProvider';
import { UIText, TooltipWrapper } from '@air/components';
import classNames from 'classnames';
import {
  useUploadApplicantsContext,
  useUploadApplicantsMethods,
} from 'providers/UploadApplicantsProvider';
import { usePrevious } from '@air/utils/hooks';

// component proptypes
type Props = RouteChildrenProps<Record<string, never>, { isNew?: boolean }>;

// exports / component definitions
export const DraftSection: React.FC<Props> = React.memo((props) => {
  const { history } = props;
  const currentSearch = useKanbanContext(kanbanSelectors.currentSearch);
  const currentSearchCriteria = useKanbanContext(
    kanbanSelectors.currentSearchCriteria
  );
  const jobRequisitionDetails = useKanbanContext(
    kanbanSelectors.jobRequisitionDetails
  );
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const externalJobDescriptionId = useKanbanContext(
    kanbanSelectors.externalJobDescriptionId
  );
  const { fetchSearch, updateDraft, removeDraft, startInterview } =
    useKanbanMethods();

  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const ats = useCustomerProfileContext(customerProfileSelectors.ats);
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const isImportingCriteria = useKanbanContext(
    kanbanSelectors.isImportingCriteria
  );
  const isLeftSideBarCollapsed = useAppContext(
    appSelectors.isLeftSideBarCollapsed
  );

  const { removeApplicant, setUploadsStarted } = useUploadApplicantsMethods();

  const requisitions = useUploadApplicantsContext(
    uploadApplicantsSelectors.requisitions
  );

  const [isInterviewStarting, setIsInterviewStarting] = useState(false);

  const currentRequisition = getCurrentRequisition(
    requisitions,
    externalJobDescriptionId
  );

  const rightSidebarPanel = useAppContext(appSelectors.rightSidebarPanel);

  const { setRightSidebarPanel } = useAppMethods();

  useEffect(() => {
    return () => setRightSidebarPanel(null);
  }, [setRightSidebarPanel]);

  const setUploadsListOpen = useCallback(
    (openState: boolean) => {
      setRightSidebarPanel(openState ? RightSidebarPanel.Uploads : null);
    },
    [setRightSidebarPanel]
  );

  useUploadPanelControl(
    isStandaloneAtsUser,
    RightSidebar.isUploadsOpened(rightSidebarPanel),
    setUploadsListOpen
  );

  useEffect(() => {
    if (RightSidebar.isUploadsOpened(rightSidebarPanel)) {
      setUploadsStarted(externalJobDescriptionId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightSidebarPanel]);

  const submitDraftSearch = useCallback(async () => {
    setIsInterviewStarting(true);
    trackEvent({
      category: GACategory.DraftPage,
      label: GA_LABEL_START_SCREENING,
    });
    const isInterviewStarted = await startInterview(currentSearch);
    if (isInterviewStarted !== false) {
      history.push(
        urls.makeInterviewUrl(
          dataSourceId,
          externalJobDescriptionId,
          LineupTabs.Active
        )
      );
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
    }
    setIsInterviewStarting(false);
  }, [
    startInterview,
    currentSearch,
    dataSourceId,
    externalJobDescriptionId,
    history,
  ]);

  useUploads({
    currentSearch,
    isUploadsListOpen: RightSidebar.isUploadsOpened(rightSidebarPanel),
    setUploadsListOpen,
    setUploadsStarted,
  });

  /*
    Automatic refresh of Preview results, until all applied
    candidates are processed.

    Refetching should occur only when preview is opened,
    as well as if not all applicants are processed yet.
  */

  const discardSearch = useCallback(
    async (item: SearchListItemT) => {
      await removeDraft(item);
      emit(APP_EVENTS.REMOVE_DRAFT, item);
      history.push(urls.ROOT_ROUTE);
    },
    [removeDraft, history]
  );

  const onRemoveApplicant = useCallback(
    async (
      atsCandidateId: string,
      tempId: string,
      uploadStatus: UploadStatus
    ) => {
      return removeApplicant({
        atsCandidateId,
        tempId,
        uploadStatus,
        atsId: ats.dataSourceId,
        jobRequisitionId: externalJobDescriptionId,
      });
    },
    [externalJobDescriptionId, ats, removeApplicant]
  );

  const onDiscardClicked = useCallback(() => {
    trackEvent({
      category: GACategory.DraftPage,
      label: GA_LABEL_DISCARD_DRAFT,
    });
    emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
      text: phrases.DRAFT_DISCARD_CONFIRMATION,
      confirmLabel: phrases.DISCARD,
      onConfirm: () => discardSearch(currentSearch),
    });
  }, [currentSearch, discardSearch]);

  const canDiscardSearch = !(!currentSearch || isTrialExpired);

  const hasDisabledActions = !currentSearch;
  const isDraftValid = isDraftSearchValid(currentSearch);
  const startSearchDisabled =
    !isDraftValid || hasDisabledActions || isInterviewStarting;

  const isStartScreeningDisabled =
    startSearchDisabled || isTrialExpired || isImportingCriteria;

  const isDiscardDraftDisabled = isTrialExpired || isImportingCriteria;

  const isImportCriteriaDisabled = isTrialExpired && isImportingCriteria;

  return (
    <div className={styles.draftSection}>
      <FeatureView.LeftSidebar>
        <>
          <UIText
            tiny
            bold
            className={classNames(
              styles.leftSidebarHeader,
              commonStyles.leftSidebarHeader,
              {
                [commonStyles.isCollapsed]: isLeftSideBarCollapsed,
              }
            )}
          >
            {phrases.DRAFT_SECTION_HEADER}
          </UIText>
          <TooltipWrapper
            delayShow={
              isStartScreeningDisabled
                ? TOOLTIP_DELAY_TIME_SHORT
                : TOOLTIP_DELAY_TIME_LONG
            }
            tooltip={
              isStartScreeningDisabled
                ? phrases.DISABLED_START_SCREENING_BUTTON_TOOLTIP
                : phrases.START_SCREENING_DRAFT_SECTION_BUTTON
            }
            enabled={isStartScreeningDisabled || isLeftSideBarCollapsed}
            disabledChildren={isStartScreeningDisabled}
            flexGrow={false}
          >
            <Button
              className={commonStyles.leftSidebarButton}
              variant={Button.variants.POSITIVE_CONFIRM}
              icon="start-screening-icon"
              debounceTime={CLICK_DEBOUNCE_TIME_LONG}
              disabled={isStartScreeningDisabled}
              onClick={submitDraftSearch}
            >
              {phrases.START_SCREENING_DRAFT_SECTION_BUTTON}
            </Button>
          </TooltipWrapper>

          <TooltipWrapper
            delayShow={TOOLTIP_DELAY_TIME_LONG}
            tooltip={phrases.IMPORT_CRITERIA_DRAFT_SECTION_BUTTON}
            enabled={isStartScreeningDisabled || isLeftSideBarCollapsed}
            disabledChildren={isImportCriteriaDisabled}
            flexGrow={false}
          >
            <Button
              className={classNames(
                commonStyles.leftSidebarButton,
                commonStyles.defaultState,
                styles.importCriteriaButton
              )}
              variant={Button.variants.POSITIVE_SECONDARY}
              icon="import-criteria-icon"
              debounceTime={CLICK_DEBOUNCE_TIME_LONG}
              disabled={isImportCriteriaDisabled}
              onClick={() => emit(APP_EVENTS.IMPORT_CRITERIA)}
            >
              {phrases.IMPORT_CRITERIA_DRAFT_SECTION_BUTTON}
            </Button>
          </TooltipWrapper>

          <TooltipWrapper
            delayShow={TOOLTIP_DELAY_TIME_LONG}
            tooltip={phrases.DRAFT_SECTION_DISCARD_ACTION}
            enabled={isLeftSideBarCollapsed}
            disabledChildren={isDiscardDraftDisabled}
            flexGrow={false}
          >
            <Button
              className={classNames(
                commonStyles.leftSidebarButton,
                commonStyles.defaultState,
                commonStyles.negativeState,
                styles.discardDraftButton
              )}
              variant={Button.variants.NEGATIVE_SECONDARY}
              icon="discard-draft-icon"
              debounceTime={CLICK_DEBOUNCE_TIME_LONG}
              disabled={isDiscardDraftDisabled}
              onClick={onDiscardClicked}
            >
              {phrases.DRAFT_SECTION_DISCARD_ACTION}
            </Button>
          </TooltipWrapper>
          <FeatureView.LeftSidebar.Space />
          <UtilitiesControls
            gaCategory={GACategory.DraftPage}
            isCollapsed={isLeftSideBarCollapsed}
            hasUploadsButton={isStandaloneAtsUser}
          />
        </>
      </FeatureView.LeftSidebar>
      <FeatureView.Content className={styles.previewSectionContent}>
        <Switch>
          <Route
            path={urls.makeDraftUrl(':dataSourceId', ':jobDescriptionId')}
            render={(routerProps) => (
              <>
                <DraftView
                  history={routerProps.history}
                  location={routerProps.location}
                  dataSourceId={routerProps.match?.params?.dataSourceId}
                  jobDescriptionId={routerProps.match?.params?.jobDescriptionId}
                  fetchSearch={fetchSearch}
                  updateDraft={updateDraft}
                  currentSearch={currentSearch}
                  isTrialExpired={isTrialExpired}
                  onDiscardDraft={canDiscardSearch ? onDiscardClicked : null}
                />
                <RightSidebar
                  isOpened={rightSidebarPanel !== null}
                  closePanel={() => setRightSidebarPanel(null)}
                  overlapping={
                    !RightSidebar.isJobDescriptionOpened(rightSidebarPanel)
                  }
                >
                  {RightSidebar.isJobDescriptionOpened(rightSidebarPanel) && (
                    <JobDescription
                      highlightKeywords
                      disabledKeywords={isTrialExpired}
                      job={jobRequisitionDetails || EMPTY_JOB}
                      criteria={currentSearchCriteria}
                      isEditable={isStandaloneAtsUser && !isTrialExpired}
                    />
                  )}
                  {isStandaloneAtsUser &&
                    RightSidebar.isUploadsOpened(rightSidebarPanel) && (
                      <UploadsSidebarWrapper
                        files={currentRequisition.files}
                        isOpen={RightSidebar.isUploadsOpened(rightSidebarPanel)}
                        pendingFilesCount={currentRequisition.pendingCount}
                        totalFilesCount={currentRequisition.totalCount}
                        shouldShowExplanationText
                        onRemoveApplicant={onRemoveApplicant}
                      />
                    )}
                  {RightSidebar.isReportOpened(rightSidebarPanel) && (
                    <GenerateReportPanel
                      isEmpty={!currentSearch?.activeCount?.totalCount}
                      noSuitableData={
                        currentSearch.status === SearchProgressStatusEnum.DRAFT
                      }
                      searchId={currentSearch.searchId}
                      searchName={currentSearch.name}
                    />
                  )}
                </RightSidebar>
              </>
            )}
          />
        </Switch>
      </FeatureView.Content>
    </div>
  );
});
DraftSection.displayName = 'DraftSection';

DraftSection.defaultProps = {};

type DraftViewProps = {
  history: History<{ isNew?: boolean }>;
  location: Location<{ isNew?: boolean }>;
  draftId?: string;
  dataSourceId?: number | string;
  jobDescriptionId?: string;
  className?: string;
  isDraftBlocked?: boolean;
  isStandaloneAtsUser?: boolean;
  isTrialExpired?: boolean;
  onDiscardDraft: () => void;
} & Pick<KanbanContextT['methods'], 'fetchSearch' | 'updateDraft'> &
  Pick<KanbanContextT, 'currentSearch'>;
const DraftView: React.FC<DraftViewProps> = (props) => {
  const {
    history,
    dataSourceId,
    jobDescriptionId,
    fetchSearch,
    updateDraft,
    currentSearch,
    isDraftBlocked = false,
    isTrialExpired,
  } = props;

  const prevSearch = usePrevious(currentSearch);

  const isImportingCriteria = useKanbanContext(
    kanbanSelectors.isImportingCriteria
  );

  const rightSidebarPanel = useAppContext(appSelectors.rightSidebarPanel);

  const { setRightSidebarPanel } = useAppMethods();

  const isNewDraft = history.location.state?.isNew;

  /*   as per requirements we have to open job description panel for the new draft
  currentSearch could be changed, so we need to use prevSearch to open JD panel once when currentSearch start to exist */
  useEffect(() => {
    if (
      !prevSearch &&
      currentSearch &&
      isNewDraft &&
      !RightSidebar.isJobDescriptionOpened(rightSidebarPanel)
    ) {
      setRightSidebarPanel(RightSidebarPanel.JobDescription);
    }
    // eslint-disable-next-line
  }, [currentSearch]);

  useEffect(() => {
    if (
      !jobDescriptionId ||
      jobDescriptionId === urls.ROUTE_PLACEHOLDER ||
      currentSearch?.ats.externalJobDescriptionId === jobDescriptionId
    ) {
      return;
    }
    fetchSearch({ dataSourceId, jobDescriptionId });
  }, [fetchSearch, currentSearch, dataSourceId, jobDescriptionId]);

  useEffect(() => {
    return subscribe(APP_EVENTS.REFETCH_DRAFT, async () => {
      await fetchSearch({ dataSourceId, jobDescriptionId });
      // reset form to values that we've just fetched with `fetchSearch`
      emit(APP_EVENTS.RESET_SEARCH_FORM);
    });
  }, [dataSourceId, fetchSearch, jobDescriptionId]);

  useEffect(() => {
    /*
      This effect redirects from 'expired' urls, when search was removed,
      or already published and should be opened from interview section.
    */
    if (
      currentSearch &&
      currentSearch.status !== SearchProgressStatusEnum.DRAFT &&
      currentSearch.status !== SearchProgressStatusEnum.INPROGRESS &&
      /*
        PUSH action check is required, because due to optimistic update,
        condition above may exist shortly during transition from
        DRAFT / INPROGRESS to INTERVIEW, and this causes redirect to Kanban.

        At the same time, during first load of page with expired URL,
        history.action is POP.
        It is equal to PUSH, when we redirect user to InterviewSection
        manually.
      */
      history.action !== 'PUSH'
    ) {
      history.replace(urls.ROOT_ROUTE);
    }
  }, [currentSearch, history]);

  return (
    <div className={styles.draftSectionWrapper}>
      {currentSearch && (
        <NewSearchView
          key={currentSearch.created}
          item={currentSearch}
          onSearchUpdate={updateDraft}
          className={props.className}
          isDraftBlocked={isDraftBlocked}
          isReadOnly={isTrialExpired}
        />
      )}
      {isDraftBlocked && (
        <SearchBlockScreen
          header={phrases.SEARCH_FORM_BLOCKER_HEADER}
          blockMessage={phrases.SEARCH_FORM_BLOCKER_TEXT}
        />
      )}
      {isImportingCriteria && (
        <SearchBlockScreen
          header={phrases.IMPORT_CRITERIA_BLOCK_SCREEN_HEADER}
          blockMessage={phrases.IMPORT_CRITERIA_BLOCK_SCREEN_TEXT}
        />
      )}
    </div>
  );
};
