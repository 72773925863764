import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import { withQueryParams } from '@air/utils/http';
import {
  JobDescriptionListResponse,
  JobDescriptionItem,
} from '@air/api/models';
import { KanbanListT } from 'domain/Kanban/Kanban';

export const fetchJobList = ({
  atsId,
  searchFilter,
  page,
  size,
}: {
  atsId: number;
  searchFilter: string;
  page: number;
  size: number;
}) => {
  return Http.get(urls.makeJobsListApiUrl(atsId))
    .bind(
      withQueryParams({
        page,
        size,
        ...(!!searchFilter && { title: searchFilter }),
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson)
    .map(
      (res: JobDescriptionListResponse): KanbanListT<JobDescriptionItem> => {
        return {
          items: res.content,
          total: res.totalElements,
          loaded: true,
        };
      }
    );
};

export const fetchJobDescription = (atsId: number | string, jdId: string) => {
  return Http.get(urls.makeJobDescriptionApiUrl(atsId, jdId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateJobDescription = (
  atsId: number | string,
  descriptionId: string,
  description: string
) => {
  return Http.patch(urls.makeJobDescriptionApiUrl(atsId, descriptionId))
    .bind(
      Http.withJsonBody({
        description,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const requestParseJobDescription = (
  atsId: number | string,
  descriptionId: string
) => {
  return Http.put(
    urls.makeJobDescriptionRequestParseApiUrl(atsId, descriptionId)
  )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
