/*
 * This is the form field that is used with react-hook-form
 * There are also old form fields used with Formin
 * Plz see customer-portal/src/components/Form
 * We are getting rig of formik gradually
 */

import React, { Ref } from 'react';
import InputMask, { MaskOptions } from 'react-input-mask';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';

import style from './FormField.css';

type FormFieldCommonProps = {
  label?: string;
  isValid?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  isEmpty?: boolean;
  isDisabled?: boolean;
  className?: string;
  isLoading?: boolean;
  autocomplete?: string;
  autosize?: boolean;
};

type HTMLInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'disabled' | 'type'
>;
type FormFieldInputProps = FormFieldCommonProps & {
  type: 'text' | 'email' | 'password' | 'number' | 'date';
  ref?: Ref<HTMLInputElement>;
} & HTMLInputProps;

type HTMLTextAreaProps = Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  'disabled' | 'type'
>;
type FormFieldTextAreaProps = FormFieldCommonProps & {
  type: 'textarea';
  ref?: Ref<HTMLTextAreaElement>;
} & HTMLTextAreaProps;

type FormFieldProps = FormFieldTextAreaProps | FormFieldInputProps;
export const FormField = React.forwardRef(
  (
    {
      id,
      type,
      label,
      isValid = false,
      hasError,
      hasWarning,
      isEmpty,
      isDisabled,
      className,
      isLoading,
      autosize,
      ...inputProps
    }: FormFieldProps,
    ref: Ref<HTMLTextAreaElement> | Ref<HTMLInputElement>
  ) => {
    const isTextarea = type === 'textarea';
    return (
      <div
        className={classNames(style.formField, className, {
          [style.success]: isValid,
          [style.error]: hasError,
          [style.warning]: hasWarning,
          [style.empty]: isEmpty,
          [style.disabled]: isDisabled,
          [style.withoutLabel]: !label,
          [style.isLoading]: isLoading,
        })}
      >
        {isTextarea ? (
          autosize ? (
            <TextareaAutosize
              id={id}
              className={classNames(style.textarea, style.autosize)}
              // @ts-ignore
              inputRef={ref as Ref<HTMLTextAreaProps>}
              disabled={isDisabled}
              {...(inputProps as HTMLTextAreaProps)}
            />
          ) : (
            <textarea
              id={id}
              className={style.textarea}
              ref={ref as Ref<HTMLTextAreaElement>}
              disabled={isDisabled}
              {...(inputProps as HTMLTextAreaProps)}
            />
          )
        ) : (
          <input
            id={id}
            className={style.input}
            type={type}
            ref={ref as Ref<HTMLInputElement>}
            disabled={isDisabled}
            {...(inputProps as HTMLInputProps)}
          />
        )}
        {label && (
          <label className={style.label} htmlFor={id}>
            {label}
          </label>
        )}
        {/* This overlay emulates padding for textarea while scrolling long text */}
        {isTextarea && <div className={style.textareaOverlay} />}
      </div>
    );
  }
);

FormField.displayName = 'FormField';

type MaskedFormFieldProps = {
  value: HTMLInputProps['value'];
} & Partial<MaskOptions> &
  FormFieldInputProps;
export const MaskedFormField: React.FC<MaskedFormFieldProps> = React.forwardRef(
  ({ value, onChange, mask, maskChar = null, onBlur, ...restProps }, ref) => {
    return (
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maskChar={maskChar}
      >
        {(inputProps: Partial<FormFieldInputProps>) => (
          <FormField ref={ref} {...inputProps} {...restProps} />
        )}
      </InputMask>
    );
  }
);
