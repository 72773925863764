import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import R from '@air/third-party/ramda';

import {
  EmailSectionsButtonT,
  FREEFORM_SECTION,
  JobEmailT,
  MAX_EMAIL_SUBJECT_LENGTH,
  NEW_EMAIL_DEFAULT_SUBJECT,
  SelectedEmailEntityT,
} from 'domain/EmailConfig/EmailTemplates';
import { useOutsideClick } from '@air/utils/hooks';

import { useKanbanContext } from 'providers/KanbanProvider';
import { kanbanSelectors } from 'selectors';
import { FormField, Paragraph, UIText } from '@air/components';
import * as phrases from 'constants/phrases';
import { EditableEmailTemplateSection } from 'features/EmailConfigSection/EmailBuilder/EditableEmailTemplateSection/EditableEmailTemplateSection';

import styles from './EmailBuilderForm.css';

const FIELD_EMAIL_SUBJECT = 'subject';

type EmailBuilderFormT = {
  addSection: (section: EmailSectionsButtonT) => void;
  email: SelectedEmailEntityT;
  disabled: boolean;
};

export const EmailBuilderForm: React.FC<EmailBuilderFormT> = ({
  addSection,
  email,
  disabled,
}) => {
  // @TODO: Extract higher
  const currentSearchName = useKanbanContext(kanbanSelectors.currentSearchName);

  const { control, setValue, setFocus, register, getValues, watch } =
    useFormContext<JobEmailT>();

  // @TODO: Think about optimizing the rendering of this form to avoid
  // rerendering of all text fields while user is typing.
  const { sections } = watch();

  const { remove } = useFieldArray({
    control,
    name: 'sections',
  });

  // If user leaves empty subject and blurs the fields,
  // put back old value.
  const onOutsideSubjectClick = () => {
    if (!getValues().subject) {
      setValue(FIELD_EMAIL_SUBJECT, email?.subject);
    }
  };
  const [outsideSubjectClickRef] = useOutsideClick(onOutsideSubjectClick);

  const isNewEmailBeingCreated =
    email?.subject === NEW_EMAIL_DEFAULT_SUBJECT(currentSearchName);

  useEffect(() => {
    // focus on empty email subject if we are creating a new email
    if (isNewEmailBeingCreated) {
      setFocus(FIELD_EMAIL_SUBJECT);
    }
  }, [isNewEmailBeingCreated, setFocus]);

  return (
    <div className={styles.emailBuilderForm}>
      <form className={styles.formFields} ref={outsideSubjectClickRef}>
        <label htmlFor={FIELD_EMAIL_SUBJECT}>
          <UIText tiny bold className={styles.text}>
            {phrases.EMAIL_TEMPLATE_SUBJECT}
          </UIText>
        </label>
        <FormField
          type="text"
          className={styles.formSubject}
          placeholder={phrases.EMAIL_SUBJECT_PLACEHOLDER}
          maxLength={MAX_EMAIL_SUBJECT_LENGTH}
          {...register(FIELD_EMAIL_SUBJECT)}
          isDisabled={disabled}
        />
        <div className={styles.sectionsWrapper}>
          {R.isNullOrEmpty(sections) ? (
            <Paragraph
              small
              className={styles.text}
              onClick={() => addSection(FREEFORM_SECTION)}
              data-placeholder={phrases.EMAIL_TEMPLATE_SECTION_FREEFORM}
            >
              {phrases.EMAIL_TEMPLATE_CONTENT_PLACEHOLDER}
            </Paragraph>
          ) : (
            sections.map((section, idx) => {
              return (
                <EditableEmailTemplateSection
                  key={`${section.idx}-${idx}`}
                  name={`sections.${idx}`}
                  section={section}
                  readOnly={disabled}
                  removeSection={() => {
                    remove(idx);
                  }}
                />
              );
            })
          )}
        </div>
      </form>
    </div>
  );
};
