// These statuses are used to be able to animate and scroll to the newly added card (manually by clicking on criteria or keywords)
export enum InitialCardStatusEnum {
  ExistingCard = 'ExistingCard',
  IsNewEdit = 'IsNewEdit', // newly created card after adding a criteria manually
  IsNewView = 'IsNewView', // newly created card after adding a keyword to the criteria
}

export const isCardInitialStatusNew = (
  initialCardStatus: InitialCardStatusEnum
) => {
  return initialCardStatus === InitialCardStatusEnum.IsNewEdit;
};

export const CARD_BACKGROUND_DELAY_DURATION = 750;
