import { SearchCriteriaImportanceEnum, DetailsDemandEnum } from '@air/api';
import { CardType } from 'domain/SearchCriteria';

export type HiddenListItemT = { label: string; value: any };

type CriteriaOptions = {
  [status in SearchCriteriaImportanceEnum]: {
    label: string;
    value: SearchCriteriaImportanceEnum;
  };
};

export const criteriaImportanceOptions: CriteriaOptions = {
  [SearchCriteriaImportanceEnum.MANDATORY]: {
    label: 'Mandatory',
    value: SearchCriteriaImportanceEnum.MANDATORY,
  },
  [SearchCriteriaImportanceEnum.IMPORTANT]: {
    label: 'Important',
    value: SearchCriteriaImportanceEnum.IMPORTANT,
  },
  [SearchCriteriaImportanceEnum.OPTIONAL]: {
    label: 'Optional',
    value: SearchCriteriaImportanceEnum.OPTIONAL,
  },
};

export const optionalOrImportantOptions: Partial<CriteriaOptions> = {
  [SearchCriteriaImportanceEnum.IMPORTANT]: {
    label: 'Important',
    value: SearchCriteriaImportanceEnum.IMPORTANT,
  },
  [SearchCriteriaImportanceEnum.OPTIONAL]: {
    label: 'Optional',
    value: SearchCriteriaImportanceEnum.OPTIONAL,
  },
};

export const criteriaImportanceOptionsList = (
  cardType: CardType
): HiddenListItemT[] => {
  switch (cardType) {
    case CardType.question:
    case CardType.location:
      return Object.values(optionalOrImportantOptions);
    default:
      return Object.values(criteriaImportanceOptions);
  }
};

export const skillDetailsDemandOptions = {
  [DetailsDemandEnum.NOTREQUIRED]: {
    label: 'No details\nrequired',
    value: DetailsDemandEnum.NOTREQUIRED,
    icon: 'rejectedstatus',
  },
  [DetailsDemandEnum.REQUIRED]: {
    label: 'Details\nrequired',
    value: DetailsDemandEnum.REQUIRED,
    icon: 'selectedstatus',
  },
};

export const skillDetailsDemandOptionsList = Object.values(
  skillDetailsDemandOptions
);
