import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Toggle, Paragraph } from '@air/components';
import R from '@air/third-party/ramda';
import { useForm, useWatch } from 'react-hook-form';
import * as phrases from '@air/constants/phrases';
import { Button } from 'components';
import {
  FormFields,
  FormFieldTypes,
  FormErrorTypes,
} from '@air/components/Form/utils';

import { LoginRequest } from '@air/api/models';
import { emailRegex } from '@air/utils/strings';
import { FormField } from '@air/components';
import { FormViewTitle } from 'components/FormView/FormView';
import styles from './LoginForm.css';
import formViewStyles from '../FormView/FormView.css';

type LoginFormProps = {
  className?: string;
  onSubmit: (values: LoginRequest) => Promise<void>;
  onResetPassword: () => void;
  onChangeEmail: (email: string) => void;
  apiError?: string;
  defaultValues?: { email: string; password: string };
  formTitle?: string;
};

const getErrorLabel = (
  fieldName: string,
  errorType?: string,
  userDoesNotExist?: boolean
) => {
  if (userDoesNotExist) {
    return fieldName === FormFields.email
      ? phrases.LABEL_EMAIL
      : phrases.LABEL_PASSWORD;
  } else if (errorType === FormErrorTypes.required) {
    return phrases.FORM_ERROR_NO_VALUE;
  } else if (
    errorType === FormErrorTypes.pattern &&
    fieldName === FormFields.email
  ) {
    return phrases.FORM_ERROR_INVALID_EMAIL;
  }
  return '';
};

const getLabel = (
  fieldName: string,
  errorType?: string,
  userDoesNotExist?: boolean,
  hasError?: boolean
) => {
  if (hasError) {
    return getErrorLabel(fieldName, errorType, userDoesNotExist);
  } else {
    return fieldName === FormFields.email
      ? phrases.LABEL_EMAIL
      : phrases.LABEL_PASSWORD;
  }
};

type FormDataT = {
  email?: string;
  password?: string;
};

const checkIfUserCannotBeFound = (
  apiError: string,
  formValues: FormDataT,
  submittedFormData?: FormDataT
) => {
  if (!apiError || !submittedFormData) return false;
  // if there is an api error and we have entered the same email and password as were submitted before
  // the form is not valid -> the users need to enter different data
  const isSameEmail = submittedFormData.email === formValues.email;
  const isSamePassword = submittedFormData.password === formValues.password;
  return isSameEmail && isSamePassword;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  onResetPassword,
  onChangeEmail,
  className = '',
  apiError,
  defaultValues,
  formTitle = phrases.LOGIN_FORM_TITLE,
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
  });
  const [submittedFormData, setSubmittedFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const formWatcher = useWatch({ control });

  useEffect(() => {
    return () => {
      setSubmittedFormData({});
    };
  }, []);

  const userDoesNotExist = () =>
    checkIfUserCannotBeFound(apiError, formWatcher, submittedFormData);
  const hasEmailError = !!(errors?.email || userDoesNotExist());
  const hasPasswordError = !!(errors?.password || userDoesNotExist());
  const emailLabel = getLabel(
    FormFields.email,
    errors?.email?.type,
    userDoesNotExist(),
    hasEmailError
  );
  const passwordLabel = getLabel(
    FormFields.password,
    errors?.password?.type,
    userDoesNotExist(),
    hasPasswordError
  );

  const performSubmit = (values: LoginRequest) => {
    setSubmittedFormData(values);
    onSubmit(values);
  };

  const enteredEmail = watch(FormFields.email);

  useEffect(() => {
    onChangeEmail(enteredEmail);
  }, [onChangeEmail, enteredEmail]);

  return (
    <form
      className={classNames(className, styles.loginForm)}
      onSubmit={handleSubmit(performSubmit)}
      noValidate
    >
      <FormViewTitle
        title={formTitle}
        description={phrases.LOGIN_FORM_DESCRIPTION}
      />

      <FormField
        id={FormFields.email}
        type={FormFieldTypes.email}
        {...register(FormFields.email, {
          required: true,
          pattern: emailRegex,
        })}
        hasError={hasEmailError}
        isEmpty={R.isNullOrEmpty(getValues().email)}
        label={emailLabel}
        defaultValue={defaultValues?.email}
      />

      <FormField
        id={FormFieldTypes.password}
        type={showPassword ? FormFieldTypes.text : FormFieldTypes.password}
        {...register(FormFields.password, { required: true })}
        hasError={hasPasswordError}
        isEmpty={R.isNullOrEmpty(getValues().password)}
        label={passwordLabel}
        defaultValue={defaultValues?.password}
      />

      <div
        className={classNames(
          formViewStyles.formFieldExtra,
          formViewStyles.passwordControls
        )}
      >
        <label
          className={formViewStyles.label}
          htmlFor={FormFields.showPassword}
        >
          <Toggle
            name={FormFields.showPassword}
            className={formViewStyles.passwordToggle}
            onChange={() => setShowPassword((state) => !state)}
          />
          <Paragraph small>{phrases.SHOW_PASSWORD}</Paragraph>
        </label>
        <button
          type="button"
          onClick={onResetPassword}
          className={styles.resetPassword}
        >
          <Paragraph small>{phrases.RESET_PASSWORD_TEXT}</Paragraph>
        </button>
      </div>
      <div className={formViewStyles.formFieldExtra}>
        <Button
          type="submit"
          variant={Button.variants.POSITIVE_CONFIRM}
          className={formViewStyles.actionButton}
        >
          {phrases.LOGIN_BUTTON_TEXT}
        </Button>

        {apiError && R.isNullOrEmpty(errors) && (
          <div className={formViewStyles.errorLabel}>{apiError}</div>
        )}
      </div>
    </form>
  );
};
