import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import {
  BaseCriteriaData,
  BaseSearchCriteriaData,
  getImportanceValue,
} from 'domain/SearchCriteria/BaseSearchCriteria';
import {
  isExcludeSkill,
  isIncludeSkill,
  mapExcludeSkillCriteriaDataToSkillList,
  mapIncludeSkillCriteriaDataToSkillList,
  mapExcludeListToSkillCriteria,
  mapIncludeListToSkillCriteria,
  SkillCriteriaData,
} from 'domain/SearchCriteria/SkillCriteriaData';
import {
  CertificationCriteriaData,
  isCertification,
  mapCertificationCriteriaToCertificationList,
  mapCertificationListToCertificationCriteria,
} from 'domain/SearchCriteria/CertificationCriteriaData';
import {
  isMajor,
  MajorCriteriaData,
  mapEducationToMajorCriteria,
  mapMajorCriteriaToEducation,
} from 'domain/SearchCriteria/MajorCriteriaData';
import {
  DegreeCriteriaData,
  isDegree,
  mapDegreeCriteriaToEducation,
  mapEducationToDegreeCriteria,
} from './DegreeCriteriaData';
import {
  InstitutionCriteriaData,
  isInstitution,
  mapEducationToInstitutionCriteria,
  mapInstitutionCriteriaToEducation,
} from 'domain/SearchCriteria/InstitutionCriteriaData';
import {
  isExcludeRole,
  isIncludeRole,
  mapExcludeRoleCriteriaDataToRoleList,
  mapIncludeRoleCriteriaDataToRoleList,
  RoleCriteriaData,
  mapExcludeListToRoleCriteria,
  mapIncludeListToRoleCriteria,
} from 'domain/SearchCriteria/RoleCriteriaData';
import {
  isManagerial,
  ManagerialCriteriaData,
  mapExperienceToManagerialCriteriaData,
  mapManagerialCriteriaDataToExperience,
} from 'domain/SearchCriteria/ManagerialCriteriaData';
import {
  isProfessional,
  mapExperienceToProfessionalCriteriaData,
  mapProfessionalCriteriaDataToExperience,
  ProfessionalCriteriaData,
} from 'domain/SearchCriteria/ProfessionalCriteriaData';
import {
  CompanyCriteriaData,
  isExcludeCompany,
  isIncludeCompany,
  mapExcludeCompanyCriteriaToCompanyList,
  mapExcludeListToCompanyCriteria,
  mapIncludeCompanyCriteriaToCompanyList,
  mapIncludeListToCompanyCriteria,
} from 'domain/SearchCriteria/CompanyCriteriaData';
import {
  isQuestion,
  mapQuestionCriteriaDataToQuestion,
  mapQuestionToQuestionCriteriaData,
  QuestionCriteriaData,
} from 'domain/SearchCriteria/QuestionCriteriaData';
import {
  IndustryCriteriaData,
  isExcludeIndustry,
  isIncludeIndustry,
  mapExcludeIndustryCriteriaToIndustryList,
  mapIncludeIndustryCriteriaToIndustryList,
  mapExcludeListToIndustryCriteria,
  mapIncludeListToIndustryCriteria,
} from 'domain/SearchCriteria/IndustryCriteriaData';
import {
  LocationCriteriaData,
  isLocation,
  mapLocationCriteriaDataToLocationList,
  mapLocationListToLocationCriteriaData,
} from 'domain/SearchCriteria/LocationCriteriaData';

export type SearchCriteriaData =
  | SkillCriteriaData
  | CertificationCriteriaData
  | MajorCriteriaData
  | DegreeCriteriaData
  | InstitutionCriteriaData
  | IndustryCriteriaData
  | CompanyCriteriaData
  | LocationCriteriaData
  | RoleCriteriaData
  | ManagerialCriteriaData
  | ProfessionalCriteriaData
  | QuestionCriteriaData;

export type SearchCriteriaWithList =
  | SkillCriteriaData
  | CertificationCriteriaData
  | RoleCriteriaData
  | IndustryCriteriaData
  | CompanyCriteriaData
  | LocationCriteriaData;

export type SearchEducationCriteria =
  | MajorCriteriaData
  | DegreeCriteriaData
  | InstitutionCriteriaData;

export const filterByImportance =
  (type: ApiModel.SearchCriteriaImportanceEnum) => (item: BaseCriteriaData) => {
    return getImportanceValue(item) === type;
  };

const createSortByImportance = (order: any) => {
  const getOrder = (value = ApiModel.SearchCriteriaImportanceEnum.OPTIONAL) => {
    return order[value];
  };
  return (itemA: BaseCriteriaData, itemB: BaseCriteriaData) => {
    return (
      getOrder(getImportanceValue(itemA)) - getOrder(getImportanceValue(itemB))
    );
  };
};

export const sortByImportance = createSortByImportance({
  [ApiModel.SearchCriteriaImportanceEnum.OPTIONAL]: 2,
  [ApiModel.SearchCriteriaImportanceEnum.IMPORTANT]: 1,
  [ApiModel.SearchCriteriaImportanceEnum.MANDATORY]: 0,
});

export const sortCardData = (
  values: SearchCriteriaData[]
): SearchCriteriaData[] =>
  R.compose(
    R.indexedMap<SearchCriteriaData, SearchCriteriaData>((item, idx) => {
      return { ...item, idx };
    }),
    R.sort(sortByImportance),
    R.sortBy(R.prop('idx'))
  )(values);

export const mapCriteriaRequestToCriteriaData = (
  criteria?: ApiModel.SearchCriteriaV2
): SearchCriteriaData[] => {
  const excludeSkills = (criteria?.skills?.excludeList || []).map(
    mapExcludeListToSkillCriteria
  );
  const includeSkills = (criteria?.skills?.includeList || []).map(
    mapIncludeListToSkillCriteria
  );
  const certifications = (criteria?.certifications || []).map(
    mapCertificationListToCertificationCriteria
  );
  const major = criteria?.education?.major
    ? [mapEducationToMajorCriteria(criteria?.education.major)]
    : [];

  const degree = criteria?.education?.degree
    ? [mapEducationToDegreeCriteria(criteria?.education.degree)]
    : [];

  const institution = criteria?.education?.institution
    ? [mapEducationToInstitutionCriteria(criteria?.education.institution)]
    : [];

  const locations = criteria?.locations
    ? [mapLocationListToLocationCriteriaData(criteria?.locations)]
    : [];

  const excludeIndustries = (
    criteria?.industryExperience?.excludeList || []
  ).map(mapExcludeListToIndustryCriteria);
  const includeIndustries = (
    criteria?.industryExperience?.includeList || []
  ).map(mapIncludeListToIndustryCriteria);

  const excludeCompanies = (criteria?.companyExperience?.excludeList || []).map(
    mapExcludeListToCompanyCriteria
  );
  const includeCompanies = (criteria?.companyExperience?.includeList || []).map(
    mapIncludeListToCompanyCriteria
  );

  const excludeRoles = (criteria?.titleExperience?.excludeList || []).map(
    mapExcludeListToRoleCriteria
  );
  const includeRoles = (criteria?.titleExperience?.includeList || []).map(
    mapIncludeListToRoleCriteria
  );

  const experience = (criteria?.experience || []).map((experienceItem) => {
    if (experienceItem.managerial) {
      return mapExperienceToManagerialCriteriaData(experienceItem);
    } else if (experienceItem.professional) {
      return mapExperienceToProfessionalCriteriaData(experienceItem);
    }
  });

  const questions = (criteria?.additionalQuestions || []).map(
    mapQuestionToQuestionCriteriaData
  );

  return sortCardData([
    ...excludeSkills,
    ...includeSkills,
    ...certifications,
    ...major,
    ...degree,
    ...institution,
    ...excludeIndustries,
    ...includeIndustries,
    ...includeCompanies,
    ...excludeCompanies,
    ...locations,
    ...experience,
    ...excludeRoles,
    ...includeRoles,
    ...questions,
  ]);
};

export const isEmptyCriteriaRequest = (
  formRequest: ApiModel.SearchCriteriaV2
) => {
  return (
    R.isEmpty(formRequest.skills.includeList) &&
    R.isEmpty(formRequest.skills.excludeList) &&
    R.isEmpty(formRequest.certifications) &&
    R.isNil(formRequest.education) &&
    R.isNil(formRequest.industryExperience.includeList) &&
    R.isNil(formRequest.industryExperience.excludeList) &&
    R.isNil(formRequest.companyExperience.includeList) &&
    R.isNil(formRequest.companyExperience.excludeList) &&
    R.isEmpty(formRequest.experience) &&
    R.isNil(formRequest.locations) &&
    R.isNil(formRequest.titleExperience.includeList) &&
    R.isNil(formRequest.titleExperience.excludeList) &&
    R.isEmpty(formRequest.additionalQuestions)
  );
};

export const mapCriteriaDataToCriteriaRequest = (
  criteria: SearchCriteriaData[]
): ApiModel.SearchCriteriaV2 => {
  const formRequest: ApiModel.SearchCriteriaV2 = {
    skills: { excludeList: null, includeList: null },
    certifications: [],
    education: null,
    industryExperience: { excludeList: null, includeList: null },
    companyExperience: { excludeList: null, includeList: null },
    experience: [],
    locations: null,
    titleExperience: { excludeList: null, includeList: null },
    additionalQuestions: [],
  };

  criteria.forEach((criteriaItem: BaseSearchCriteriaData) => {
    if (isIncludeSkill(criteriaItem)) {
      formRequest.skills.includeList = [
        ...(formRequest.skills.includeList || []),
        mapIncludeSkillCriteriaDataToSkillList(criteriaItem),
      ];
    } else if (isExcludeSkill(criteriaItem)) {
      formRequest.skills.excludeList = [
        ...(formRequest.skills.excludeList || []),
        mapExcludeSkillCriteriaDataToSkillList(criteriaItem),
      ];
    } else if (isCertification(criteriaItem)) {
      if (criteriaItem.list.filter(Boolean).length > 0) {
        formRequest.certifications.push(
          mapCertificationCriteriaToCertificationList(criteriaItem)
        );
      }
    } else if (isMajor(criteriaItem)) {
      const major = mapMajorCriteriaToEducation(criteriaItem);
      formRequest.education = { ...formRequest.education, major };
    } else if (isDegree(criteriaItem)) {
      const degree = mapDegreeCriteriaToEducation(criteriaItem);
      formRequest.education = { ...formRequest.education, degree };
    } else if (isInstitution(criteriaItem)) {
      const institution = mapInstitutionCriteriaToEducation(criteriaItem);
      formRequest.education = { ...formRequest.education, institution };
    } else if (isExcludeIndustry(criteriaItem)) {
      formRequest.industryExperience.excludeList = [
        ...(formRequest.industryExperience.excludeList || []),
        mapExcludeIndustryCriteriaToIndustryList(criteriaItem),
      ];
    } else if (isIncludeIndustry(criteriaItem)) {
      formRequest.industryExperience.includeList = [
        ...(formRequest.industryExperience.includeList || []),
        mapIncludeIndustryCriteriaToIndustryList(criteriaItem),
      ];
    } else if (isExcludeCompany(criteriaItem)) {
      formRequest.companyExperience.excludeList = [
        ...(formRequest.companyExperience.excludeList || []),
        mapExcludeCompanyCriteriaToCompanyList(criteriaItem),
      ];
    } else if (isIncludeCompany(criteriaItem)) {
      formRequest.companyExperience.includeList = [
        ...(formRequest.companyExperience.includeList || []),
        mapIncludeCompanyCriteriaToCompanyList(criteriaItem),
      ];
    } else if (isLocation(criteriaItem)) {
      formRequest.locations =
        mapLocationCriteriaDataToLocationList(criteriaItem);
    } else if (isExcludeRole(criteriaItem)) {
      formRequest.titleExperience.excludeList = [
        ...(formRequest.titleExperience.excludeList || []),
        mapExcludeRoleCriteriaDataToRoleList(criteriaItem),
      ];
    } else if (isIncludeRole(criteriaItem)) {
      formRequest.titleExperience.includeList = [
        ...(formRequest.titleExperience.includeList || []),
        mapIncludeRoleCriteriaDataToRoleList(criteriaItem),
      ];
    } else if (isManagerial(criteriaItem)) {
      formRequest.experience.push(
        mapManagerialCriteriaDataToExperience(criteriaItem)
      );
    } else if (isProfessional(criteriaItem)) {
      formRequest.experience.push(
        mapProfessionalCriteriaDataToExperience(criteriaItem)
      );
    } else if (isQuestion(criteriaItem)) {
      if (criteriaItem.question?.value) {
        formRequest.additionalQuestions.push(
          mapQuestionCriteriaDataToQuestion(criteriaItem)
        );
      }
    }
  });

  return formRequest;
};
