import React from 'react';

import styles from './SearchBlockScreen.css';
import { Header, Paragraph, Spinner } from '@air/components';

export const SearchBlockScreen: React.FC<{
  header?: string;
  blockMessage?: string;
}> = ({ header, blockMessage }) => {
  return (
    <div className={styles.searchBlockScreen}>
      <div className={styles.blockMessage}>
        {header && (
          <Header level={3} className={styles.blockHeader}>
            {header}
          </Header>
        )}
        {blockMessage && <Paragraph>{blockMessage}</Paragraph>}
        <Spinner className={styles.spinner} />
      </div>
    </div>
  );
};
