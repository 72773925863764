import React from 'react';
import classNames from 'classnames';

import styles from './Header.css';

type HeaderProps = {
  level?: number;
  className?: string;
  bold?: boolean;
  bolder?: boolean;
  onClick?: () => void;
};
export const Header: React.FC<HeaderProps> = ({
  level = 1,
  children,
  className,
  bold,
  bolder,
  onClick,
}) => {
  const Tag = getHeaderTagName(level);

  return (
    <Tag
      className={classNames(styles.header, className, {
        [styles.bold]: bold,
        [styles.bolder]: bolder,
      })}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};
Header.displayName = 'Header';

function getHeaderTagName(level: number) {
  switch (level) {
    case 1:
      return 'h1';
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
      return 'h4';
    case 5:
      return 'h5';
    case 6:
      return 'h6';
  }
}
