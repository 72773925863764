import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  addIndex,
  compose,
  find,
  identity,
  Lens,
  lensPath,
  map,
  propEq,
  set,
} from 'ramda';
import { FieldArray, FormikHandlers, FormikHelpers } from 'formik';
import { NewSearchToolbar } from './NewSearchToolbar';
import { CardsSection } from './CardsSection';
import {
  loadDegreeOptions,
  loadMajorOptions,
} from '@air/api-tasks/dictionariesApi';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import { DraggableCriteriaTypes } from 'components/CardWrappers/CriteriaCollapsedCard/CriteriaCollapsedCard';
import { criteriaImportanceOptions } from 'constants/skills';
import * as urls from 'constants/urls';

import { importanceToLabel, UpdateValuesFunction } from './utils';

import {
  EducationDegree,
  EducationMajor,
  SearchCriteriaImportanceEnum,
} from '@air/api';
import {
  CardType,
  filterByImportance,
  getCertificationDefaults,
  getCompanyDefaults,
  getDegreeCriteriaDefaults,
  getInstitutionCriteriaDefaults,
  getMajorCriteriaDefaults,
  getManagerialCriteriaDefaults,
  getProfessionalCriteriaDefaults,
  getQuestionDefaults,
  getRoleCriteriaDefaults,
  getSkillCriteriaDefaults,
  ImportanceSelectedValueT,
  SearchCriteriaData,
  SkillCriteriaData,
} from 'domain/SearchCriteria';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import * as phrases from 'constants/phrases';
import R from '@air/third-party/ramda';
import {
  createGenericCardsHandler,
  getCriteriaCard,
  handleMoveItemOutsideStack,
  handleRemoveItemFromStack,
  SearchCriteriaFormCardWrapper,
} from 'features/DraftSection/NewSearch/SearchCriteriaFormCardWrapper';
import styles from './SearchCriteriaForm.css';
import { NewSearchValues } from 'features/DraftSection/NewSearch/NewSearch';
import { SvgIcon, Header, Paragraph, UIText, Button } from '@air/components';
import {
  CriteriaDragObjectT,
  DraggableCardT,
  isDraggableMainCard,
  isDraggableStackItem,
  SingleCriteriaItemDragObject,
} from 'components/Cards/cardsCommonCode';
import { APP_EVENTS } from 'domain/Kanban/events';
import { subscribe } from 'hooks/usePubSub';
import { KeywordData } from 'features/JobDescription/jobDescriptionHelpers';
import {
  convertMonthsExperienceToYears,
  MAX_EXPERIENCE_VALUE,
} from '@air/domain/Common/PeriodConverter';
import { getIndustryDefaults } from 'domain/SearchCriteria/IndustryCriteriaData';
import { ReorderDropTargetCell } from 'features/ReorderDropTargetCell/ReorderDropTargetCell';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  customerProfileSelectors,
  kanbanSelectors,
  cacheSelectors,
} from 'selectors';
import { getLocationDefaults } from 'domain/SearchCriteria/LocationCriteriaData';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useCacheContext, useCacheMethods } from '@air/providers/CacheProvider';

const changeCriteriaImportanceOnDrop =
  (item: any, importance: SearchCriteriaImportanceEnum): UpdateValuesFunction =>
  (values: any) => {
    if (typeof importance === 'undefined') {
      return values;
    }

    const [, itemLens] = getCriteriaCard(item, values);
    const importanceLens = lensPath(['importance']);
    const importanceValue: ImportanceSelectedValueT =
      criteriaImportanceOptions[importance];

    return set<ImportanceSelectedValueT, any>(
      compose(itemLens, importanceLens) as Lens<any, any>,
      importanceValue
    )(values);
  };

const canDropSearchCriteriaCard =
  (
    isDraggable: boolean,
    importance: SearchCriteriaImportanceEnum,
    searchOrder: number,
    cellIndex: number | null = null
  ) =>
  (
    props:
      | CriteriaDragObjectT<DraggableCriteriaTypes>
      | SingleCriteriaItemDragObject<DraggableCriteriaTypes>
  ) => {
    const { order } = props;

    if (!isDraggable) return false;

    if (
      isDraggableMainCard(props) &&
      importance === props.importance &&
      !props.isDrawingMainCard
    ) {
      /*
    When a card is dragged, it's adjacent reorder cell and the next
    reorder cell after it should be hidden (we can't move the card before
    or after itself).
    We also hide the last expanded ReorderCell in current importance section,
    if last card in section is dragged.
   */
      if (
        order === searchOrder ||
        order + 1 === searchOrder ||
        props.importanceSectionOrder + 1 === cellIndex
      )
        return false;
    }

    if (importance === SearchCriteriaImportanceEnum.MANDATORY) {
      /* According to https://railsreactor.atlassian.net/browse/AR-4368 and https://railsreactor.atlassian.net/browse/AR-8395
      user can drop Question and Locations cards to the Important and Optional sections only
     */
      if (
        [
          DraggedSearchCriteriaCardType.question,
          DraggedSearchCriteriaCardType.location,
        ].includes(props.type)
      ) {
        return false;
      }
    }

    return true;
  };

const indexedMap = addIndex<SearchCriteriaData, SearchCriteriaData>(map);

type PrefetchedOptionsT = {
  degreeOptions: EducationDegree[];
  majorOptions: EducationMajor[];
};

type SearchCriteriaFormPropsT = {
  setFieldValue: FormikHelpers<NewSearchValues>['setFieldValue'];
  handleSubmit: FormikHandlers['handleSubmit'];
  values: NewSearchValues;
  isFormBlocked?: boolean;
  onImportCriteria: () => void;
};

/*
  Using Formik for SearchCriteriaForm leads to lots of re-rendering
  of form contents after every form action. This is related to
  how Formik handles its updates internally and re-renders its
  children.
  To avoid unnecessary re-renderings SearchCriteriaForm could be
  wrapped in React.memo, but since Formik uses mutable approach
  to internal values, React.memo can not detect important updates
  to values, and theoretically it may lead to hardly-detectable bugs.
  So for now, this component is not wrapped in React.memo.

  TODO: Replace Formik with other form solution (i.e. react-hook-form),
  which uses non-mutable operations with form values,
  then wrap SearchCriteriaForm in React.memo to decrease number of
  re-renderings (potentially).
*/
export const SearchCriteriaForm: React.FC<SearchCriteriaFormPropsT> = ({
  setFieldValue,
  handleSubmit,
  values: { criteria, specializations },
  isFormBlocked = false,
  onImportCriteria,
}) => {
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const isImportingCriteria = useKanbanContext(
    kanbanSelectors.isImportingCriteria
  );

  const [lastImportance, updateLastImportance] = useState(
    SearchCriteriaImportanceEnum.MANDATORY
  );

  // update indexes:
  const values = indexedMap((item, index) => {
    return { ...item, idx: index };
  }, criteria);

  const [shouldShowEmptyState, setShouldShowEmptyState] = useState(
    values.length === 0
  );

  useEffect(() => {
    if (values.length > 0) {
      setShouldShowEmptyState(false);
    }
  }, [values]);

  const searchToolbarButtonsConfig = useMemo(() => {
    const canAddMajor = !find(propEq('cardType', CardType.major), values);
    const canAddDegree = !find(propEq('cardType', CardType.degree), values);
    const canAddInstitution = !find(
      propEq('cardType', CardType.institution),
      values
    );
    const canAddManagerial = !find(
      propEq('cardType', CardType.managerial),
      values
    );
    const canAddProfessional = !find(
      propEq('cardType', CardType.professional),
      values
    );
    const canAddLocation = !find(propEq('cardType', CardType.location), values);

    const defaultCriteria = {
      importance: lastImportance,
      idx: values.length,
    };

    /*
      According to https://railsreactor.atlassian.net/browse/AR-4368
      ...or previous criteria was added to Mandatory section, new Additional
      Question card should be added with important priority
      (should appear in Important section)
    */
    const questionCriteriaImportance =
      lastImportance === SearchCriteriaImportanceEnum.MANDATORY
        ? SearchCriteriaImportanceEnum.IMPORTANT
        : lastImportance;

    /*
      According to https://railsreactor.atlassian.net/browse/AR-8395
      location card can only be Important or Optional
    */
    const locationsCriteriaImportance =
      lastImportance === SearchCriteriaImportanceEnum.MANDATORY
        ? SearchCriteriaImportanceEnum.IMPORTANT
        : lastImportance;

    return [
      {
        cardType: CardType.skill,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_SKILL,
        isDisabled: isTrialExpired,
        getDefaults: () =>
          getSkillCriteriaDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.major,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_MAJOR,
        isDisabled: !canAddMajor || isTrialExpired,
        getDefaults: () =>
          getMajorCriteriaDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.degree,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_DEGREE,
        isDisabled: !canAddDegree || isTrialExpired,
        getDefaults: () =>
          getDegreeCriteriaDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.institution,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_INSTITUTION,
        isDisabled: !canAddInstitution || isTrialExpired,
        getDefaults: () =>
          getInstitutionCriteriaDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.certification,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_CERTIFICATION,
        isDisabled: isTrialExpired,
        getDefaults: () =>
          getCertificationDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.role,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_ROLE,
        isDisabled: isTrialExpired,
        getDefaults: () =>
          getRoleCriteriaDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.managerial,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_MANAGERIAL,
        isDisabled: !canAddManagerial || isTrialExpired,
        getDefaults: () =>
          getManagerialCriteriaDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.professional,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_PROFESSIONAL,
        isDisabled: !canAddProfessional || isTrialExpired,
        getDefaults: () =>
          getProfessionalCriteriaDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.industry,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_INDUSTRY,
        isDisabled: isTrialExpired,
        getDefaults: () =>
          getIndustryDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.company,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_COMPANY,
        isDisabled: isTrialExpired,
        getDefaults: () =>
          getCompanyDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
      {
        cardType: CardType.location,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_LOCATION,
        isDisabled: isTrialExpired || !canAddLocation,
        getDefaults: () =>
          getLocationDefaults({
            ...defaultCriteria,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
            importance: locationsCriteriaImportance,
          }),
      },
      {
        cardType: CardType.question,
        title: phrases.SEARCH_FORM_TOOLBAR_ADD_QUESTION,
        isDisabled: isTrialExpired,
        getDefaults: () =>
          getQuestionDefaults({
            idx: values.length,
            importance: questionCriteriaImportance,
            initialCardStatus: InitialCardStatusEnum.IsNewEdit,
          }),
      },
    ];
  }, [values, lastImportance, isTrialExpired]);

  const createCardsFromEvent = useCallback(
    (eventData) => {
      if (eventData && !isTrialExpired) {
        const cardsData = R.reduce(
          (acc: SkillCriteriaData[], singleCardData: KeywordData) => {
            const card = R.find(
              (it) => it.cardType === singleCardData.cardType,
              searchToolbarButtonsConfig
            );
            return card
              ? [
                  ...acc,
                  {
                    ...card.getDefaults(),
                    list: [
                      {
                        value: singleCardData.id,
                        label: singleCardData.fullName,
                        language: singleCardData.language,
                      },
                    ],
                    experience: R.isNullOrEmpty(singleCardData.months)
                      ? null
                      : {
                          idealMax: MAX_EXPERIENCE_VALUE,
                          idealMin: convertMonthsExperienceToYears(
                            singleCardData.months
                          ),
                        },
                    initialCardStatus: InitialCardStatusEnum.IsNewView,
                    importance:
                      criteriaImportanceOptions[singleCardData.priority],
                  } as SkillCriteriaData,
                ]
              : acc;
          },
          [],
          eventData
        );
        setFieldValue('criteria', [...values, ...cardsData]);
        handleSubmit();
      }
    },
    [
      isTrialExpired,
      values,
      handleSubmit,
      setFieldValue,
      searchToolbarButtonsConfig,
    ]
  );

  useEffect(() => {
    const unsubscribeCreateCard = subscribe(
      APP_EVENTS.CREATE_CARD,
      createCardsFromEvent
    );
    return () => {
      unsubscribeCreateCard();
    };
  }, [createCardsFromEvent]);

  const updateCardValues = useMemo(
    () => createGenericCardsHandler(values, setFieldValue, handleSubmit),
    [values, setFieldValue, handleSubmit]
  );

  const fieldArrayCardReorder =
    (arrayHelpers: any) =>
    (
      { order: currentSearchOrder, isDrawingMainCard, ...item }: any,
      newSearchOrder: number,
      newImportance: any
    ) => {
      const updatedItem = { order: currentSearchOrder, ...item };

      if (isDraggableStackItem(item) || isDrawingMainCard) {
        updateCardValues(
          compose(
            changeCriteriaImportanceOnDrop(updatedItem, newImportance),
            handleMoveItemOutsideStack(item, newSearchOrder)
          )
        );
      } else {
        createGenericCardsHandler(values, setFieldValue, () => {
          arrayHelpers.move(
            currentSearchOrder,
            currentSearchOrder >= newSearchOrder
              ? newSearchOrder
              : newSearchOrder - 1
          );
          updateLastImportance(newImportance);
          handleSubmit();
        })(changeCriteriaImportanceOnDrop(updatedItem, newImportance));
      }
    };

  const degreeOptions = useCacheContext(cacheSelectors.degreeOptions);
  const majorOptions = useCacheContext(cacheSelectors.majorOptions);
  const { updateCache } = useCacheMethods();

  useEffect(() => {
    async function fetchEducationOptions() {
      if (!degreeOptions && !majorOptions) {
        const [degreeOptionsResult, majorOptionsResult] =
          await Promise.allSettled([
            loadDegreeOptions(identity),
            loadMajorOptions(identity),
          ]);
        const degreeOptionsValue =
          degreeOptionsResult.status === 'fulfilled'
            ? degreeOptionsResult.value
            : [];
        const majorOptionsValue =
          majorOptionsResult.status === 'fulfilled'
            ? majorOptionsResult.value
            : [];

        updateCache({
          degreeOptions: degreeOptionsValue,
          majorOptions: majorOptionsValue,
        });
      }
    }
    fetchEducationOptions();
  }, [degreeOptions, majorOptions, updateCache]);
  const prefetchedEducationOptions: PrefetchedOptionsT = useMemo(
    () => ({
      degreeOptions: degreeOptions || [],
      majorOptions: majorOptions || [],
    }),
    [degreeOptions, majorOptions]
  );

  return (
    <FieldArray name="criteria">
      {(arrayHelpers) => {
        const onCardReorder = fieldArrayCardReorder(arrayHelpers);

        const onAddNewCard = (newCardData: any) => {
          arrayHelpers.push(newCardData);
        };

        const renderCard = (item: any, index: any) => {
          return (
            <SearchCriteriaFormCardWrapper
              key={item.key}
              item={item}
              importanceSectionOrder={index}
              educationOptions={prefetchedEducationOptions}
              setFieldValue={setFieldValue}
              values={values}
              specializations={specializations}
              handleCardReorder={onCardReorder}
              handleSubmit={handleSubmit}
              updateCardValues={updateCardValues}
              isReadOnly={isTrialExpired}
            />
          );
        };

        const renderCardsSection = (
          cards: JSX.Element[],
          importance: SearchCriteriaImportanceEnum,
          tooltipText: string
        ) => {
          return (
            <CardsSection
              className={styles.criteriaFormCardsSection}
              sectionName={importanceToLabel[importance]}
              tooltipText={tooltipText}
            >
              {cards}
              <ReorderDropTargetCell
                isExpanded
                accept={Object.values(DraggedSearchCriteriaCardType)}
                canDrop={canDropSearchCriteriaCard(
                  !isTrialExpired,
                  importance,
                  values.length,
                  cards.length
                )}
                drop={(data: any) => {
                  onCardReorder(data, values.length, importance);
                }}
              />
            </CardsSection>
          );
        };

        const mandatoryCards = values
          .filter(filterByImportance(SearchCriteriaImportanceEnum.MANDATORY))
          .map(renderCard);

        const requiredCards = values
          .filter(filterByImportance(SearchCriteriaImportanceEnum.IMPORTANT))
          .map(renderCard);

        const optionalCards = values
          .filter(filterByImportance(SearchCriteriaImportanceEnum.OPTIONAL))
          .map(renderCard);

        return (
          <>
            <div
              className={classNames(styles.searchToolbarWrapper, {
                [styles.disabled]: isFormBlocked,
              })}
            >
              <NewSearchToolbar
                buttonClassName={styles.searchToolbarButton}
                isReadOnly={isTrialExpired}
                addButtonsConfig={searchToolbarButtonsConfig}
                onAddButtonClick={onAddNewCard}
                onRemove={(item: DraggableCardT) => {
                  if (isDraggableStackItem(item)) {
                    updateCardValues(
                      handleRemoveItemFromStack(item.id, item.groupId)
                    );
                  } else {
                    const removedItemIndex = item.order;
                    if (removedItemIndex > -1) {
                      arrayHelpers.remove(removedItemIndex);
                      handleSubmit();
                    }
                  }
                }}
              />
            </div>
            <div className={styles.scrollableArea}>
              {shouldShowEmptyState && (
                <div className={styles.emptyState}>
                  <div className={styles.emptyStateText}>
                    <Header level={3} className={styles.emptyStateHeader}>
                      {phrases.NO_CRITERIA_HEADER}
                    </Header>
                    <Paragraph className={styles.emptyStateParagraph}>
                      {phrases.NO_CRITERIA_TEXT}
                    </Paragraph>
                    <div className={styles.emptyStateButtonsWrapper}>
                      <a
                        className={styles.emptyStateButton}
                        href={urls.YOUTUBE_DRAFT_SETUP_URL}
                        title={phrases.YOUTUBE_DRAFT_SETUP_TITLE}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SvgIcon icon="play" />
                        <UIText
                          small
                          bold
                          className={styles.emptyStateButtonText}
                        >
                          {phrases.NO_CRITERIA_BUTTON_TEXT}
                        </UIText>
                      </a>
                      {!isTrialExpired && (
                        <Button
                          className={classNames(
                            styles.emptyStateButton,
                            styles.importCriteriaButton
                          )}
                          variant={Button.variants.INLINE}
                          onClick={onImportCriteria}
                          disabled={isImportingCriteria}
                          icon="import-criteria-icon-blue"
                          iconClassName={styles.emptyStateButtonIcon}
                        >
                          <UIText
                            small
                            bold
                            className={styles.emptyStateButtonText}
                          >
                            {phrases.IMPORT_CRITERIA_BUTTON_TEXT}
                          </UIText>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!shouldShowEmptyState && (
                <>
                  {renderCardsSection(
                    mandatoryCards,
                    SearchCriteriaImportanceEnum.MANDATORY,
                    phrases.MANDATORY_SECTION_TITLE_TOOLTIP
                  )}
                  {renderCardsSection(
                    requiredCards,
                    SearchCriteriaImportanceEnum.IMPORTANT,
                    phrases.IMPORTANT_SECTION_TITLE_TOOLTIP
                  )}
                  {renderCardsSection(
                    optionalCards,
                    SearchCriteriaImportanceEnum.OPTIONAL,
                    phrases.OPTIONAL_SECTION_TITLE_TOOLTIP
                  )}
                </>
              )}
            </div>
          </>
        );
      }}
    </FieldArray>
  );
};
SearchCriteriaForm.displayName = 'SearchCriteriaForm';

export const CardReorderWrapper: React.FC<any> = ({
  searchOrder,
  children = null,
  handleCardReorder,
  importance,
  isReadOnly,
}) => {
  return (
    <div className={styles.cardOrderWrapper}>
      <ReorderDropTargetCell
        accept={Object.values(DraggedSearchCriteriaCardType)}
        canDrop={canDropSearchCriteriaCard(
          !isReadOnly,
          importance,
          searchOrder
        )}
        drop={(data: any) => {
          handleCardReorder(data, searchOrder, importance);
        }}
      />
      {children}
    </div>
  );
};
