// imports from vendor deps
import React from 'react';
import classNames from 'classnames';
import * as urls from 'constants/urls';
import * as phrases from '@air/constants/phrases';
// imports from types
// import … from 'types';
// imports from 'components'
import { KanbanHeader, Button, SvgIcon } from 'components';

// imports from 'constants'
// import from images
// import {} from 'images'
// imports from helpers
import { isMobileOrTablet } from '@air/utils/mobile';

// imports from styles
import styles from './FeatureView.css';
import commonStyles from 'styles/commonStyles.css';
import landingStyles from 'features/Landing/Landing.css';
import { useAppContext, useAppMethods } from 'providers/AppProvider';
import { appSelectors } from 'selectors';
import { useHistory } from 'react-router-dom';

type AsideProps = {
  header?: string;
  headerHref?: string;
  kanbanSectionType?: string;
  className?: string;
  scrollable?: boolean;
  headerClassName?: string;
  shouldShowHeader?: boolean;
};
const Aside: React.FC<AsideProps> = ({
  children,
  header,
  scrollable = false,
  headerClassName = '',
  headerHref,
  shouldShowHeader,
  className,
}) => {
  const content = scrollable ? (
    <div className={styles.scrollableAsideContent}>{children}</div>
  ) : (
    children
  );

  return (
    <aside className={classNames(styles.featureAside, className)}>
      {shouldShowHeader && header && (
        <KanbanHeader
          href={headerHref}
          className={classNames(styles.asideHeader, headerClassName)}
        >
          {header}
        </KanbanHeader>
      )}
      {content}
    </aside>
  );
};

export const LeftSidebarSpace = () => {
  return <div className={styles.leftSidebarSpace} />;
};

const LeftSidebar: React.FC & {
  Space: typeof LeftSidebarSpace;
} = ({ children }) => {
  const history = useHistory();
  const isInterviewLeftSideBarCollapsed = useAppContext(
    appSelectors.isLeftSideBarCollapsed
  );
  const { setLeftSideBarCollapsed } = useAppMethods();

  return (
    <div
      className={classNames(styles.leftSidebar, {
        [styles.isCollapsed]: isInterviewLeftSideBarCollapsed,
      })}
    >
      <Button
        className={classNames(
          commonStyles.leftSidebarButton,
          commonStyles.defaultState,
          styles.homeButton
        )}
        icon="home-icon"
        variant={Button.variants.POSITIVE_SECONDARY}
        onClick={() => history.push(urls.ROOT_ROUTE)}
      >
        {phrases.GO_HOME}
      </Button>
      <button
        className={styles.collapseButton}
        onClick={() =>
          setLeftSideBarCollapsed(!isInterviewLeftSideBarCollapsed)
        }
      >
        <SvgIcon icon="shevron-left" className={styles.collapseButtonIcon} />
      </button>
      {children}
    </div>
  );
};

LeftSidebar.Space = LeftSidebarSpace;

type ContentProps = {
  className?: string;
};
const Content: React.FC<ContentProps> = (props) => {
  return (
    <main className={classNames(styles.featureContent, props.className)}>
      {props.children}
    </main>
  );
};

type Props = {
  className?: string;
  isCollapsed?: boolean;
};

type FeatureViewT = React.FC<Props> & {
  Aside: typeof Aside;
  Content: typeof Content;
  LeftSidebar: typeof LeftSidebar;
  styles: { [key: string]: string };
};

export const FeatureView: FeatureViewT = (props) => {
  const { isCollapsed, children } = props;
  const visibleChildren =
    Array.isArray(children) && isCollapsed ? children[0] : children;

  return (
    <div
      className={classNames(
        landingStyles.rightAlignedKanbanSection,
        styles.featureView,
        props.className,
        {
          [styles.isExpanded]: !isCollapsed,
          [styles.isMobileOrTablet]: isMobileOrTablet,
        }
      )}
    >
      {visibleChildren}
    </div>
  );
};

FeatureView.Aside = Aside;
FeatureView.Content = Content;
FeatureView.LeftSidebar = LeftSidebar;
FeatureView.styles = styles;
