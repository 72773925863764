import {
  CandidateSearchProfileStatus,
  SearchQuestioningRequest,
  SearchQuestioningResponse,
} from '@air/api';
import { CandidateStatus } from 'domain/CandidateData/candidateStatusHelpers';

export const questioningStatusesDefaultValues = [
  {
    label: CandidateStatus[CandidateSearchProfileStatus.MANUALSHORTLIST],
    value: CandidateSearchProfileStatus.MANUALSHORTLIST,
    checked: true,
  },
  {
    label: CandidateStatus[CandidateSearchProfileStatus.ACTIVE],
    value: CandidateSearchProfileStatus.ACTIVE,
    checked: true,
  },
  {
    label: CandidateStatus[CandidateSearchProfileStatus.PENDING],
    value: CandidateSearchProfileStatus.PENDING,
    checked: true,
  },
  {
    label:
      CandidateStatus[CandidateSearchProfileStatus.RECOMMENDEDFORREJECTION],
    value: CandidateSearchProfileStatus.RECOMMENDEDFORREJECTION,
    checked: false,
  },
];

export const mapCandidateQuestioningResponseToQuestioningData = (
  questioningResponse: SearchQuestioningResponse
) => {
  const [activeProfileStatuses, passiveProfileStatuses] = (
    ['activeProfileStatuses', 'passiveProfileStatuses'] as const
  ).map((statusesName) => {
    return questioningStatusesDefaultValues.map((checkbox) => {
      return {
        ...checkbox,
        ...(questioningResponse[statusesName]
          ? {
              checked: questioningResponse[statusesName].includes(
                checkbox.value
              ),
            }
          : {}),
      };
    });
  });
  return {
    activeEnable: questioningResponse.enable,
    passiveEnable: questioningResponse.passiveEnable,
    activeProfileStatuses,
    passiveProfileStatuses,
    passiveReminderEnable: questioningResponse.passiveReminderEnable,
    passiveReminderInDays: questioningResponse.passiveReminderInDays,
    reminderEnable: questioningResponse.reminderEnable,
    reminderInDays: questioningResponse.reminderInDays,
  };
};

export type QuestioningStatusesValues = typeof questioningStatusesDefaultValues;

export type QuestioningData = {
  activeEnable: boolean;
  passiveEnable: boolean;
  activeProfileStatuses: QuestioningStatusesValues;
  passiveProfileStatuses: QuestioningStatusesValues;
  passiveReminderEnable: boolean;
  passiveReminderInDays: number;
  reminderEnable: boolean;
  reminderInDays: number;
};

export const mapCandidateQuestioningDataToQuestioningRequest = ({
  activeEnable,
  passiveEnable,
  passiveReminderEnable,
  passiveReminderInDays,
  reminderEnable,
  reminderInDays,
  ...statuses
}: QuestioningData): SearchQuestioningRequest => {
  return {
    enable: activeEnable,
    passiveEnable,
    passiveReminderEnable,
    passiveReminderInDays,
    reminderEnable,
    reminderInDays,
    ...(['activeProfileStatuses', 'passiveProfileStatuses'] as const).reduce(
      (result, sectionName) => {
        return {
          ...result,
          [sectionName]: statuses[sectionName].reduce((statuses, group) => {
            if (!group.checked) {
              return statuses;
            }

            const { value } = group;
            const result =
              value === CandidateSearchProfileStatus.RECOMMENDEDFORREJECTION
                ? [value, CandidateSearchProfileStatus.MANUALREJECTION]
                : value;
            return statuses.concat(result);
          }, []),
        };
      },
      {} as {
        activeProfileStatuses: SearchQuestioningRequest['activeProfileStatuses'];
        passiveProfileStatuses: SearchQuestioningRequest['passiveProfileStatuses'];
      }
    ),
  };
};
