export const CLICK_DEBOUNCE_TIME_SHORT = 500;
export const CLICK_DEBOUNCE_TIME_LONG = 1000;

/* used as a default delay for TooltipWrapper component */
export const TOOLTIP_DELAY_TIME_LONG = 1000;
export const TOOLTIP_DELAY_TIME_SHORT = 500;

export const DEFAULT_BACKGROUND_IMAGE_ID = 1;
export const INPUT_DEBOUNCE_TIME = 500;
export const AUTOSAVE_DEBOUNCE_TIME = 2000;
export const TYPEAHEAD_SUGGESTIONS_LIMIT = 5;

const MAX_INT = 2147483647;
export const DEFAULT_MAX_FETCH_SIZE = MAX_INT;
