import React from 'react';
import { Header, Paragraph } from '@air/components';
import styles from './FormView.css';

export const FormViewTitle = ({ title = '', description = '' }) => {
  return (
    <div className={styles.formViewTitle}>
      <Header level={1} bold className={styles.formTitleHeader}>
        {title}
      </Header>
      {!!description && (
        <Paragraph
          className={styles.formTitleDescription}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </div>
  );
};
