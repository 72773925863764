export function getMonthDiff(dateFrom: Date, dateTo: Date): number {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
}

export function getDaysDiff(fromTimestamp: number, toTimestamp: number) {
  return Math.ceil((fromTimestamp - toTimestamp) / (1000 * 3600 * 24));
}
