import R from '@air/third-party/ramda';
import * as ApiModel from '@air/api';
import {
  CompanyExtendedType,
  InstitutionDetailTypeEnum,
  InstitutionExtendedListResponseTypeEnum,
  SearchCriteriaImportanceEnum,
  TitleExtendedItemType,
  TitleInfo,
} from '@air/api';
import { criteriaImportanceOptions } from 'constants/skills';
import { InitialCardStatusEnum } from '@air/domain/Common/Cards';
import { CardType } from '@air/domain/SearchCriteriaCards/cardsConfig';

export const requiredMatchServiceImportanceLevels = [
  SearchCriteriaImportanceEnum.MANDATORY,
  SearchCriteriaImportanceEnum.IMPORTANT,
];

export type ImportanceSelectedValueT =
  typeof criteriaImportanceOptions[keyof typeof criteriaImportanceOptions];

/**
 * Base type for search criteria used in search form.
 */
export interface BaseCriteriaData {
  cardType: CardType;
  /**
   * id is computed depending on a card type.
   * Note: The id is built only on fetching data from server and not updated in runtime,
   * when a card is altered in the search form.
   */
  id: string | number | null;
  /**
   * is an index, that is used for ordering cards in search form sections.
   */
  idx: number;
  importance: ImportanceSelectedValueT;
}

export interface BaseSearchCriteriaData extends BaseCriteriaData {
  /**
   * is unique string that is generated in runtime and is not persistent.
   */
  key: string;
  /**
   * is used to mark whether card is newly created or was already saved.
   */
  initialCardStatus: InitialCardStatusEnum;
}

export type SearchCriteriaListValueType =
  | TitleExtendedItemType
  | CompanyExtendedType
  | InstitutionDetailTypeEnum
  | InstitutionExtendedListResponseTypeEnum;

export type SearchCriteriaListValue<T = unknown, E = Record<any, any>> = {
  value: string | number;
  label: string;
  type?: SearchCriteriaListValueType;
  extras?: E;
} & T;

export interface BaseCriteriaWithList<T = SearchCriteriaListValue>
  extends BaseCriteriaData {
  list: T[];
}

export interface BaseCriteriaWithQuestion<T = SearchCriteriaListValue>
  extends BaseCriteriaData {
  question: T;
}

export interface BaseEducationCriteriaWithList<T = SearchCriteriaListValue>
  extends BaseCriteriaData {
  idealList: T[];
  acceptableList: T[];
}

export interface SearchCriteriaExperienceT {
  idealMin: number | null;
  idealMax?: number;
  acceptableMin?: number;
  acceptableMax?: number;
}

export const normalizeSearchCriteriaExperience = (
  experience: SearchCriteriaExperienceT
) => {
  return {
    idealMin: experience?.idealMin ?? 0,
    idealMax: experience?.idealMax ?? null,
    acceptableMin: experience?.acceptableMin ?? null,
    acceptableMax: experience?.acceptableMax ?? null,
  };
};

export type AddCriteriaInitials = {
  idx: number;
  importance: SearchCriteriaImportanceEnum;
  initialCardStatus?: InitialCardStatusEnum;
};

export const calculateAcceptAny = R.compose(
  R.all(R.isNil),
  R.props(['idealList', 'acceptableList'])
);

export const getImportanceValue = (
  item: BaseCriteriaData
): ApiModel.SearchCriteriaImportanceEnum => {
  return item?.importance?.value || null;
};

interface ApiCriteria {
  list: TitleInfo[];
}

export const getCriteriaListItemId = <T extends TitleInfo>(item: T) =>
  item.id || item.fullName;
export const getCriteriaListId = <T extends ApiCriteria>(criteria: T) =>
  getCriteriaListItemId<T['list'][0]>(criteria?.list?.[0]);
