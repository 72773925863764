import React, { useCallback } from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import { InterviewStatusEnum } from '@air/api';
import { useOutsideClick } from '@air/utils/hooks';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';
import { Note, SvgIcon } from '@air/components';
import styles from './CandidateInterviewNote.css';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CANDIDATE_ANSWER_REVIEW } from 'constants/gaLabels';

export const cardHasNote = (item: {
  interview: InterviewData | null;
}): boolean =>
  !!item.interview?.commentId &&
  item.interview?.status === InterviewStatusEnum.INREVIEW;

type CandidateInterviewNoteProps = {
  isNoteRead: boolean;
  isNoteExpanded: boolean;
  interviewData: InterviewData;
  onNoteClick: () => void;
  onNoteIconClick: () => void;
  iconClassName?: string;
};

export const CandidateInterviewNote: React.FC<CandidateInterviewNoteProps> = ({
  isNoteRead,
  isNoteExpanded,
  interviewData,
  onNoteClick,
  onNoteIconClick,
  iconClassName,
}) => {
  const icon = isNoteRead ? 'icon-note-read' : 'icon-letter-big';
  const [outsideClickRef] = useOutsideClick(onNoteClick, {
    useCapture: true,
  });

  const iconClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onNoteIconClick();
      trackEvent({
        category: GACategory.ScreeningCandidateProfile,
        label: GA_LABEL_CANDIDATE_ANSWER_REVIEW,
      });
    },
    [onNoteIconClick]
  );

  return !isNoteExpanded ? (
    <span
      className={classNames(styles.candidateInterviewNoteIcon, iconClassName)}
    >
      <SvgIcon icon={icon} width="2.2em" height="2.2em" onClick={iconClick} />
    </span>
  ) : (
    <Note
      ref={outsideClickRef}
      className={styles.candidateInterviewNote}
      noteMaxLength={200}
      title={null}
      onChange={R.noop}
      onClick={onNoteClick}
      isReadOnly={true}
      note={interviewData.comments}
    />
  );
};
