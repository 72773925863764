export enum SearchTabs {
  Criteria = 'criteria',
  Flags = 'flags',
}

export enum LineupTabs {
  Active = 'active',
  Passive = 'passive',
  MatchMiner = 'matchMiner',
  MatchScout = 'matchScout',
}

export enum CandidateTabs {
  Resume = 'resume',
  Matching = 'matching',
  WorkExperience = 'work-experience',
  Contacts = 'contacts',
}

export enum CompanyTabs {
  Companies = 'companies',
  CompanyGroups = 'companyGroups',
  CompanySizes = 'companySizes',
}

export enum RolesTabs {
  Roles = 'roles',
  RoleCategories = 'roleCategories',
}

export enum MajorsTabs {
  Majors = 'majors',
  MajorGroups = 'majorGroups',
}

export enum InstitutionsTabs {
  Institutions = 'universities',
  InstitutionsLists = 'universityLists',
}

export enum TimelineTabs {
  Standard = 'standard',
  Graphic = 'graphic',
}

export enum EmailsTabs {
  Templates = 'templates',
  JobEmails = 'jobEmails',
}

export enum SettingsTab {
  UserManagement = 'userManagement',
}

export enum SelectRecipientsTabs {
  New = 'new',
  Sent = 'sent',
}

export type TabValues =
  | CandidateTabs
  | SearchTabs
  | LineupTabs
  | MajorsTabs
  | InstitutionsTabs
  | CompanyTabs
  | RolesTabs
  | TimelineTabs
  | SettingsTab
  | EmailsTabs
  | SelectRecipientsTabs;
