import R from '@air/third-party/ramda';
import { Card } from '@air/components';
import { StackPosition } from '@air/components/Card/Card';
import { isKey } from '@air/utils/dom';
import {
  SearchCriteriaImportanceEnum,
  SearchCriteriaMatchStatusEnum,
} from '@air/api';
import {
  BaseSearchCriteriaData,
  SearchCriteriaData,
  SearchCriteriaExperienceT,
  CardType,
  BaseCriteriaData,
  isCompany,
  getCompanyTitle,
  RoleCriteriaData,
  CompanyCriteriaData,
  IndustryCriteriaData,
  isRoleGroupCard,
  SkillCriteriaData,
} from 'domain/SearchCriteria';
import { getSearchCriteriaExperience } from '@air/utils/commonSearchHelpers';

import * as phrases from 'constants/phrases';
import {
  makeDraggableComponent,
  withCustomDrag,
} from 'components/CustomDragLayer/CustomDragLayer';
import { DraggedSearchCriteriaCardType } from 'components/SearchCriteriaCards/dndTypes';
import {
  getCardTitle,
  getMainCard,
  getMainCardTitle,
  getTopStackItemLabel,
  getCardListItemTooltip,
} from 'domain/SearchCriteria/cardHelpers';
import cardCommonStyles from 'components/Cards/cardsCommonStyles.css';
import React from 'react';
import { DraggableCriteriaTypes } from 'components/CardWrappers/CriteriaCollapsedCard/CriteriaCollapsedCard';
import { CandidateCriteriaListItem } from 'domain/CandidateData';
import { FormSelectEntity } from '@air/components/Select/typings';
import { cardsConfig } from '@air/domain/SearchCriteriaCards/cardsConfig';

export const MAX_CARDS_IN_STACK = 9;

type CardWithExperience = {
  experience?: SearchCriteriaExperienceT;
  recent?: number;
};

export function convertCriteriaExperienceAndRecencyToString<
  T extends BaseSearchCriteriaData
>(cardData: T & CardWithExperience): string {
  const { experience = null, recent = null } = cardData;
  const result = [];
  if (experience) {
    const experiencePreview = getSearchCriteriaExperience(experience) || '';
    result.push(experiencePreview);
  }

  if (recent) {
    const recentExperience = `${phrases.RECENT_EXPERIENCE_RECENTLY_LABEL}`;
    result.push(recentExperience);
  }

  return result.filter(Boolean).join(',\n');
}

export const convertManagerialExperienceToString = (
  managerialExperience: number
) =>
  managerialExperience > 0
    ? `${managerialExperience}+ ${phrases.CRITERIA_CARD_PEOPLE_LABEL}`
    : '';

export const getOnStackCardKeyDownCallback = (event: any, ref: any) => {
  if (isKey.Enter(event) && !ref?.state?.inputValue) {
    event.preventDefault();
  }
};

export function convertMaxAllowedDistanceToInfoString(
  maxAllowedDistance: number
): string {
  return R.isNil(maxAllowedDistance)
    ? ''
    : `+${maxAllowedDistance} ${phrases.MAX_DISTANCE_INFO_LINE_TEXT}`;
}

export function convertDistanceToCriteriaLocationToInfoString(
  distanceToCriteriaLocation: number
): string {
  return R.isNil(distanceToCriteriaLocation)
    ? ''
    : `${distanceToCriteriaLocation} ${phrases.DISTANCE_TO_CRITERIA_LOCATION_INFO_LINE_TEXT}`;
}

export const getOnMainCardKeyDownCallback =
  (field: any, arrayHelpers: any, canAddNewStackItem: any) =>
  (event: any, ref: any) => {
    /*
  Since none of our selects currently works from keyboard at all,
    I'm disabling handling of up/down arrows for now, since
  it leads to incorrect behavior or react-select.

    In particular, in SkillCriteria title field it sets
  Select internals into "menu open/closed" state,
    but it doesn't work with non-cached async options
  and leads to UI inconsistency.
  */
    if (isKey.ArrowUp(event) || isKey.ArrowDown(event)) {
      event.preventDefault();
    }
    if (isKey.Enter(event) && field.value?.label && !ref?.state?.inputValue) {
      /*
          If we don't prevent default behavior,
          card form is getting submitted with new empty value.
          We need to investigate it to make sure this
          can't be handled in some other way.
        */
      event.preventDefault();
      if (canAddNewStackItem) {
        arrayHelpers.unshift(null);
      }
    }
  };

export type CriteriaCardViewProps<T extends SearchCriteriaData> = {
  stackSize: number;
  criteria: T;
  importance: SearchCriteriaImportanceEnum;
  importanceSectionOrder: number;
  onRemove: () => void;
  onRemoveStackItem: (value: number | string) => void;
  handleDrop: (id: string | number, itemId: string | number) => void;
  handleSingleCriteriaDrop: (
    id: string | number,
    itemId: string | number,
    groupId: string | number
  ) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  isDragging: boolean;
  canDrawMainCardFromStack?: boolean;
  exclude?: boolean;
  className?: string;
  cardClasses: {
    [className: string]: string;
  };
};
export const draggableMainCardTypes = [
  DraggedSearchCriteriaCardType.skill,
  DraggedSearchCriteriaCardType.certification,
  DraggedSearchCriteriaCardType.role,
  DraggedSearchCriteriaCardType.industry,
  DraggedSearchCriteriaCardType.company,
  DraggedSearchCriteriaCardType.location,
];
export type DraggableMainCardTypeT = typeof draggableMainCardTypes[number];
export function isDraggableMainCard(
  draggedObject: DraggableCardT
): draggedObject is CriteriaDragObjectT<DraggableCriteriaTypes> {
  return draggableMainCardTypes.includes(draggedObject.type);
}

export const draggableStackItemTypes = [
  DraggedSearchCriteriaCardType.singleSkillItem,
  DraggedSearchCriteriaCardType.singleCertificationItem,
  DraggedSearchCriteriaCardType.singleRoleItem,
  DraggedSearchCriteriaCardType.singleCompanyItem,
  DraggedSearchCriteriaCardType.singleIndustryItem,
];
export type DraggableStackItemTypeT = typeof draggableStackItemTypes[number];
export function isDraggableStackItem(
  draggedObject: DraggableCardT
): draggedObject is SingleCriteriaItemDragObject<DraggableCriteriaTypes> {
  return draggableStackItemTypes.includes(draggedObject.type);
}
export type DraggableCardT =
  | CriteriaDragObjectT<DraggableCriteriaTypes>
  | SingleCriteriaItemDragObject<DraggableCriteriaTypes>;

export type CriteriaDragObjectT<T> = {
  criteria: T;
  importance: SearchCriteriaImportanceEnum;
  importanceSectionOrder: number;
  title: string;
  id: string;
  order: number;
  type: DraggableMainCardTypeT;
  groupId?: string;
  exclude?: boolean;
  isDrawingMainCard: boolean;
};
export const getDraggableCriteriaCard = <T extends SearchCriteriaData>(
  component: React.FC<CriteriaCardViewProps<T>>,
  type: DraggedSearchCriteriaCardType
) =>
  makeDraggableComponent<CriteriaCardViewProps<T>, CriteriaDragObjectT<T>>(
    withCustomDrag<CriteriaCardViewProps<T>>(component),
    type,
    {
      // @ts-ignore TODO: add type guards for draggable criteria cards
      beginDrag: ({
        criteria,
        exclude,
        importanceSectionOrder,
        importance,
        onDragStart,
        canDrawMainCardFromStack,
        cardClasses,
      }) => {
        onDragStart && onDragStart();

        // @ts-ignore TODO: check why list in not type of T
        const mainCard = getMainCard<T>(criteria);
        // @ts-ignore
        const mainCardId = mainCard?.value;
        return {
          criteria,
          importance,
          importanceSectionOrder,
          exclude,
          beginDragClass: cardClasses?.beginDragClass,
          title: isCompany(criteria)
            ? // @ts-ignore
              getCompanyTitle(mainCard?.name, {
                type: mainCard.type,
                usOnly: criteria.usOnly,
              })
            : getMainCardTitle(criteria),
          id: canDrawMainCardFromStack ? mainCardId : criteria.key,
          groupId: criteria.key,
          order: criteria.idx,
          type,
          isDrawingMainCard: canDrawMainCardFromStack,
        };
      },
      endDrag: ({ onDragEnd }) => {
        onDragEnd && onDragEnd();
      },
    }
  );

type DraggableSingleCriteriaItemProps<T> = {
  card: T;
  id: number;
  title: string;
  searchOrder: number;
  onDragStart?: () => void;
  onDragEnd?: () => void;
};
export type SingleCriteriaItemDragObject<T> = {
  item: T;
  groupId: string;
  title: string;
  id: number;
  order: number;
  type: DraggableStackItemTypeT;
  exclude?: boolean;
};

export function getDraggableStackItem<T extends SearchCriteriaData>(
  type: DraggedSearchCriteriaCardType
) {
  return makeDraggableComponent<
    DraggableSingleCriteriaItemProps<T>,
    SingleCriteriaItemDragObject<T>
  >(withCustomDrag(Card.SearchCriteriaStackItem), type, {
    // @ts-ignore TODO: add type guards for draggable stack items
    beginDrag: ({ card, title, id, searchOrder, className }) => {
      return {
        item: card,
        groupId: card.key,
        title,
        id,
        className,
        order: searchOrder,
        type,
      };
    },
    endDrag: ({ onDragEnd }) => {
      onDragEnd && onDragEnd();
    },
  });
}

type CommonFieldSet = {
  [fieldName in 'title' | 'footer']: JSX.Element | JSX.Element[];
};

export const getCommonFieldsForCriteriaCard = (
  cardData: SearchCriteriaData
): CommonFieldSet => {
  const mainCard = getMainCard(cardData);
  const experienceInfoLine =
    convertCriteriaExperienceAndRecencyToString(cardData);

  const isGroup = isRoleGroupCard(mainCard);

  return {
    title: [
      isGroup && (
        <Card.TitleLabel
          key="title-label-match-resolution"
          text={phrases.GROUP_LABEL}
        />
      ),
      <Card.Title
        resizeable
        key="title-label"
        flexGrow={false}
        getTooltipText={getCardListItemTooltip.bind(null, cardData, mainCard)}
        title={getCardTitle({
          cardType: cardData.cardType,
          label: mainCard?.label,
        })}
        tooltipProps={cardsConfig[cardData.cardType]?.getMainCardTooltipProps?.(
          mainCard,
          cardData
        )}
      />,
    ],
    footer: experienceInfoLine && (
      <Card.Footer
        key="card-footer"
        className={cardCommonStyles.experienceYears}
        text={experienceInfoLine}
      />
    ),
  };
};

function getCollapsedStackItemLabel(
  cardType: CardType,
  stackPosition: StackPosition
) {
  switch (true) {
    case stackPosition === StackPosition.bottom:
      return phrases.ACCEPTABLE_STACK_ITEM_LABEL;
    case stackPosition === StackPosition.top:
      return getTopStackItemLabel(cardType);
    default:
      return phrases.OR_STACK_ITEM_LABEL;
  }
}
type FieldsForCollapsedCriteriaCardStackItemT = {
  cardData: BaseCriteriaData;
  item: FormSelectEntity;
  index: number;
  cardType: CardType;
  className?: string;
  stackPosition: StackPosition;
};

export const getFieldsForCollapsedCriteriaCardStackItem: React.FC<
  FieldsForCollapsedCriteriaCardStackItemT
> = ({ cardData, item, index, cardType, className = '', stackPosition }) => {
  const isGroup = isRoleGroupCard(item);
  return (
    <Card.SearchCriteriaStackItem
      key={`${item?.value}-${index}`}
      className={className}
      label={getCollapsedStackItemLabel(cardType, stackPosition)}
    >
      {isGroup && (
        <Card.TitleLabel
          key="title-label-match-resolution"
          text={phrases.GROUP_LABEL}
        />
      )}
      <Card.Title
        key="title-label"
        flexGrow={false}
        getTooltipText={getCardListItemTooltip.bind(null, cardData, item)}
        title={getCardTitle({
          cardType,
          label: item?.label,
        })}
        tooltipProps={cardsConfig[
          cardData.cardType
        ]?.getStackItemTooltipProps?.(item, cardData)}
      />
    </Card.SearchCriteriaStackItem>
  );
};

export const hasInactiveAlert = <T extends CandidateCriteriaListItem>(
  cardItemData: T
): boolean => {
  return (
    [
      SearchCriteriaMatchStatusEnum.IDEAL,
      SearchCriteriaMatchStatusEnum.ACCEPTABLE,
    ].includes(cardItemData.status) && !!cardItemData.alert
  );
};

export type ExcludedCriteriaData =
  | SkillCriteriaData
  | RoleCriteriaData
  | CompanyCriteriaData
  | IndustryCriteriaData;

export const isExcludedItem = (
  draggedItem:
    | CriteriaDragObjectT<ExcludedCriteriaData>
    | SingleCriteriaItemDragObject<ExcludedCriteriaData>,
  draggedItemType: DraggedSearchCriteriaCardType
) =>
  isDraggableStackItem(draggedItem) && draggedItem.type === draggedItemType
    ? draggedItem.item.exclude
    : draggedItem.exclude;
