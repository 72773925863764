// imports from vendor deps
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { CSSTransition } from 'react-transition-group';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CustomDragLayer, NavigationBar } from 'components';
// imports from styles
import styles from './Landing.css';
// import {} from 'ramda';
import classNames from 'classnames';

// imports from types
import { KanbanContextT } from 'context';
import * as urls from 'constants/urls';
import fadeTransition from '@air/styles/transitions/fade.css';
import { useKanbanContext, useKanbanMethods } from 'providers/KanbanProvider';
import {
  kanbanSelectors,
  customerProfileSelectors,
  appSelectors,
} from 'selectors';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { getBackgroundImageName } from 'domain/UISettings/backgroundSettings';
import {
  RightSidebarPanel,
  useAppContext,
  useAppMethods,
} from 'providers/AppProvider';
import { JobPanel } from 'features/JobPanel/JobPanel';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { subscribe } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';

// component proptypes
type Props = Pick<KanbanContextT, 'currentSearch'> &
  RouteComponentProps & {
    children: (state: {
      isLandingMounted: boolean;
      hasExpandedSection: boolean;
    }) => React.ReactNode;
  };

// component state

DndProvider.displayName = 'DndProvider';

// exports / class definitions
export const Landing: React.FC<Props> = ({
  children,
  match,
  history,
  location,
}) => {
  const currentRoute = location?.pathname;
  const isKanbanPanelOpened = urls.KANBAN_PANELS_ROUTES.includes(currentRoute);
  const hasExpandedSection = !match.isExact && !isKanbanPanelOpened;

  const currentSearch = useKanbanContext(kanbanSelectors.currentSearch);
  const { clearCurrentSearch, updateAllSearches } = useKanbanMethods();

  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  const currentBackgroundImageId = useCustomerProfileContext(
    customerProfileSelectors.currentBackgroundImageId
  );

  useEffect(() => {
    /*
      In this effect we fetch all available searches on initial app load.
      First check allows us to fetch searches, when user opens
      direct link to any section, i.e. Screening.

      At this time history.action is POP, and it is sent only once.
      @todo: Implement same requests cancelling ASAP.
    */
    if (!match.isExact && history.action === 'POP' && !currentSearch) {
      updateAllSearches();
    }
    if (match.isExact && currentSearch) {
      clearCurrentSearch();
    }
    if (match.isExact && !currentSearch) {
      /*
        Every time we return to Kanban from expanded section
        we refetch all searches to always display
        latest applicant counters.
      */
      updateAllSearches();
    }
  }, [
    match.isExact,
    history,
    currentSearch,
    clearCurrentSearch,
    updateAllSearches,
  ]);

  const [isMounted, updateMount] = React.useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateMount(true);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const landingRef = useRef(null);

  const rightSidebarPanel = useAppContext(appSelectors.rightSidebarPanel);
  const { setRightSidebarPanel } = useAppMethods();

  const [jobPanelData, setJobPanelData] = useState<{
    item: KanbanCardT;
    isDuplicate?: boolean;
  }>({ item: null });

  useEffect(() => {
    return subscribe(
      APP_EVENTS.OPEN_JOB_PANEL,
      (payload: { item: KanbanCardT; isDuplicate?: boolean }) => {
        setJobPanelData({
          ...payload,
        });
        setRightSidebarPanel(RightSidebarPanel.Job);
      }
    );
  }, [setRightSidebarPanel]);

  return (
    <>
      <img
        src={`/assets/images/background/${getBackgroundImageName(
          currentBackgroundImageId
        )}`}
        className={classNames(styles.backgroundImage, {
          [styles.backgroundImageLoaded]: isMounted,
          [styles.backgroundImageShiftedLeft]: hasExpandedSection,
        })}
      />
      <DndProvider backend={HTML5Backend} context={window}>
        <div
          ref={landingRef}
          className={classNames(styles.landing, {
            [styles.isExpanded]: hasExpandedSection,
            [styles.isInitial]: !isMounted,
          })}
        >
          {!isTrialExpired && <CustomDragLayer />}
          <CSSTransition
            appear
            in={!hasExpandedSection}
            classNames={fadeTransition}
            timeout={500}
            unmountOnExit
          >
            <NavigationBar history={history} />
          </CSSTransition>
          {children({
            isLandingMounted: isMounted,
            hasExpandedSection,
          })}
          {rightSidebarPanel === RightSidebarPanel.Job && (
            <JobPanel {...jobPanelData} />
          )}
        </div>
      </DndProvider>
    </>
  );
};

Landing.defaultProps = {};
