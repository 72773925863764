import { useEffect, useRef, useState } from 'react';
import { CardSize, CardSizeContextT } from '@air/components/Card/Card';

const cache: { [key: string]: any } = {};

export const useCardSizeCalculator = (
  cardSizeContext: CardSizeContextT,
  resizeable: boolean,
  isStackTitle: boolean
) => {
  const {
    cardSize,
    onCardSizeChange,
    setResizingFinished,
    isResizingFinished,
  } = cardSizeContext;
  const [isOverflownHorizontally, setOverflownX] = useState(false);
  const [isOverflownVertically, setOverflownY] = useState(false);
  const [initialTitleHeight, setInitialHeight] = useState(0);

  const titleRef = useRef(null);
  const textRef = useRef(null);

  // Save initial height
  useEffect(() => {
    if (titleRef.current) {
      setInitialHeight(titleRef.current.clientHeight);
    }
  }, [titleRef]);

  // Init IntersectionObserver
  useEffect(() => {
    if (!initialTitleHeight) return;

    const titleText = textRef.current?.textContent;
    const cacheKey = `${titleText}-${initialTitleHeight}`;

    if (cache[cacheKey]) {
      const { optimalSize, hasHorizontalOverflow, hasVerticalOverflow } = cache[
        cacheKey
      ];

      if (resizeable && optimalSize !== cardSize) {
        onCardSizeChange(optimalSize, true);
      }
      if (resizeable && optimalSize === cardSize) {
        setResizingFinished(true);
      }
      setOverflownX(hasHorizontalOverflow);
      setOverflownY(hasVerticalOverflow);
      return;
    }

    /*
        IntersectionObserver allows us to  figure out if title
        fits inside available space.
      */
    const observer = new IntersectionObserver(
      function intersectionCallback([intersectionEntry]) {
        const titleText = intersectionEntry.target.textContent;
        /*
          For unknown reasons in rare cases intersection callback is triggered with
          all meaningful values equal to 0, i.e. it's impossible to calculate correct
          sizes and overflow status. In such case we should abort the calculation.
        */
        if (
          !intersectionEntry.boundingClientRect?.width &&
          !intersectionEntry.rootBounds?.width
        ) {
          setResizingFinished(true);
          return;
        }

        /*
            We use initialTitleHeight as part of cache key,
            because, apart from Card.Title,
            cards can have other elements which take space
            available for the title, thus affecting the end size.

            I.e. card with no infoline has ~75px for the title,
            but card with 2 lines infoline will have around 40px.
            And the latter will have more probability for resizing.

            So we cache not only by title's text, but also by
            initial height that title takes when it's rendered
            the 1st time.
          */
        const cacheKey = `${titleText}-${initialTitleHeight}`;

        const {
          optimalSize,
          hasHorizontalOverflow,
          hasVerticalOverflow,
          isCalculationFinished,
        } = !isStackTitle
          ? getTitleCardSize(cardSize, intersectionEntry)
          : getStackTitleCardSize(cardSize, intersectionEntry);

        if (isCalculationFinished) {
          cache[cacheKey] = {
            optimalSize,
            hasHorizontalOverflow,
            hasVerticalOverflow,
          };
        }
        if (resizeable || isCalculationFinished) {
          onCardSizeChange(optimalSize, isCalculationFinished);
        }
        setOverflownX(hasHorizontalOverflow);
        setOverflownY(hasVerticalOverflow);
        observer.disconnect();
      },
      { root: titleRef.current }
    );
    if (resizeable) {
      observer.observe(textRef.current);
    }
  }, [
    cardSize,
    onCardSizeChange,
    initialTitleHeight,
    resizeable,
    setResizingFinished,
    isStackTitle,
  ]);

  return {
    titleRef,
    textRef,
    isOverflownHorizontally,
    isOverflownVertically,
    isResizingFinished,
  };
};

/*
  Card.Title, located on the main card, can affect card's sizing
  over both X and Y axis.
*/
function getTitleCardSize(
  currentCardSize: CardSize,
  intersectionEntry: IntersectionObserverEntry
) {
  let isCalculationFinished = false;
  let optimalSize = currentCardSize;
  const { hasVerticalOverflow, hasHorizontalOverflow } = getTitleOverflowByAxis(
    intersectionEntry
  );

  if (
    currentCardSize < CardSize.tall &&
    hasVerticalOverflow &&
    !hasHorizontalOverflow
  ) {
    optimalSize = CardSize.tall;
  } else if (
    currentCardSize < CardSize.wide &&
    (hasVerticalOverflow || hasHorizontalOverflow)
  ) {
    optimalSize = CardSize.wide;
  } else if (currentCardSize < CardSize.big && hasVerticalOverflow) {
    optimalSize = CardSize.big;
    /*
      Finishing calculation, because we're not using other
      card sizes when trying to fit the content, and 'big' is
      the last step.
    */
    isCalculationFinished = true;
  } else {
    isCalculationFinished = true;
  }

  return {
    optimalSize,
    hasHorizontalOverflow,
    hasVerticalOverflow,
    isCalculationFinished,
  };
}

/*
  Card.Title, located on the stacked card, can affect card's sizing
  only over X axis, since stack items height doesn't increase in
  cards of bigger size. So we should skip sizes, which are considered
  bigger than `currentCardSize`, but lead to decrease of main card's
  height, since it can lead to rendering bugs and main title overflowing
  it's container.
*/
function getStackTitleCardSize(
  currentCardSize: CardSize,
  intersectionEntry: IntersectionObserverEntry
) {
  let isCalculationFinished = false;
  let optimalSize = currentCardSize;
  const { hasVerticalOverflow, hasHorizontalOverflow } = getTitleOverflowByAxis(
    intersectionEntry
  );

  if (currentCardSize < CardSize.tall && hasHorizontalOverflow) {
    optimalSize = CardSize.wide;
  } else if (currentCardSize < CardSize.big && hasHorizontalOverflow) {
    optimalSize = CardSize.big;
  } else {
    isCalculationFinished = true;
  }

  return {
    optimalSize,
    hasHorizontalOverflow,
    hasVerticalOverflow,
    isCalculationFinished,
  };
}

function getTitleOverflowByAxis(intersectionEntry: IntersectionObserverEntry) {
  /*
    Acceptable overflow is max size of title overflowing its container (in pixels),
    after which we try to use bigger card size to fit the title correctly.
  */
  const ACCEPTABLE_OVERFLOW = 2;

  const {
    width: containerWidth,
    height: containerHeight,
  } = intersectionEntry.rootBounds;
  /*
    To get correct dimensions of the title <span> inside its container <p>,
    we use its offsetWidth and offsetHeight properties instead of width and
    height provided by `intersectionEntry.boundingClientRect`, because
    after long observation it was determined that the latter randomly returns
    incorrect dimensions.
    From time to time the values of width and height in boundingClientRect remained
    unchanged after card's resizing, which caused incorrect detection of
    overflow, although no overflow existed under bigger card's size.
    offsetWidth and offsetHeight are the properties of title <span> element
    itself and always have correct values, even though they add a bit of
    computational overhead.
  */

  const {
    width: titleWidth,
    height: titleHeight,
  } = intersectionEntry.boundingClientRect;

  const hasHorizontalOverflow =
    titleWidth - containerWidth > ACCEPTABLE_OVERFLOW;
  const hasVerticalOverflow =
    titleHeight - containerHeight > ACCEPTABLE_OVERFLOW;

  return {
    hasVerticalOverflow,
    hasHorizontalOverflow,
  };
}
