import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import R from '@air/third-party/ramda';
import querystring from 'query-string';
import * as H from 'history';

import { Loader, UIText } from '@air/components';
import { TabItem } from '@air/components/TabsControl/TabsControl';
import {
  CriteriaConfig,
  defaultCriteriaConfig,
  HeaderCriteriaData,
  HeaderCriteriaSections,
} from 'domain/HeaderData/HeaderDataMapper';
import { LineupTabs } from '@air/constants/tabs';
import { LINEUP_TABS_MAPPING } from 'utils/uploads';
import { MatchMinerTooltip } from 'features/MatchServiceSettings/MatchMinerTooltip';
import classNames from 'classnames';
import * as phrases from 'constants/phrases';

import styles from 'features/InterviewSection/InterviewSection.css';

/*
  Configuration for visible columns (criteria) on top
  of Lineup table.
*/
export const useLineupCriteriaConfig = (
  currentCriteria: HeaderCriteriaSections = {},
  searchId: number
): [CriteriaConfig, (config: CriteriaConfig) => void] => {
  const [lineupCriteriaConfig, setLineupConfig] = useState(
    defaultCriteriaConfig
  );
  const refId = useRef(null);

  useEffect(() => {
    const { criteria: defaultCriteria, importance: defaultImportance } =
      defaultCriteriaConfig;

    if (refId.current === searchId || R.isEmpty(currentCriteria)) return;
    refId.current = searchId;

    const existingCriteria = Object.keys(currentCriteria);
    const existingImportance = R.compose(
      R.uniq,
      R.map((item: any) => item.cardData.importance.value),
      // @ts-ignore
      (values: HeaderCriteriaData[][]) => R.flatten<HeaderCriteriaData>(values),
      R.values
    )(currentCriteria);
    const criteria = defaultCriteria.map((item) => {
      const isDisabled = !existingCriteria.includes(item.value);
      return {
        ...item,
        disabled: isDisabled,
        ...(isDisabled
          ? { tooltip: phrases.LINEUP_DISABLED_CRITERIA_TOOLTIP }
          : {}),
      };
    });
    const importance = defaultImportance.map((item) => {
      const isDisabled = !existingImportance.includes(item.value);
      return {
        ...item,
        disabled: isDisabled,
        ...(isDisabled
          ? { tooltip: phrases.LINEUP_DISABLED_IMPORTANCE_TOOLTIP }
          : {}),
      };
    });

    setLineupConfig({
      criteria,
      importance,
    });
  }, [currentCriteria, searchId]);

  return [lineupCriteriaConfig, setLineupConfig];
};

/*
  Visible tabs above the lineup table section.
*/
export const useLineupTabs = ({
  activeCount,
  passiveCount,
  matchMinerCount,
  matchScoutCount,
  isPassiveSearchEnabled,
  activeSearchId,
  history,
  isCandidatesProcessingInProgress,
}: {
  activeCount: number;
  passiveCount?: number;
  matchMinerCount?: number;
  matchScoutCount?: number;
  isPassiveSearchEnabled?: boolean;
  activeSearchId?: number;
  history: H.History;
  isCandidatesProcessingInProgress?: boolean;
}): [LineupTabs, TabItem<LineupTabs>[], (tab: LineupTabs) => void] => {
  const [currentTab, setCurrentTab] = useState(LineupTabs.Active);

  const onChangeTabs = useCallback(
    (newTab: LineupTabs) => {
      setCurrentTab(newTab);
      history.push(`${history.location.pathname}?tab=${newTab}`);
    },
    [history]
  );

  const parsedLocationSearchParams = querystring.parse(history.location.search);
  const currentTabInRoute = parsedLocationSearchParams.tab;

  useEffect(() => {
    if (activeSearchId) {
      setCurrentTab((currentTabInRoute as LineupTabs) || LineupTabs.Active);
    }
  }, [activeSearchId, currentTabInRoute]);

  const lineupTabs = useMemo(
    () => [
      {
        id: LINEUP_TABS_MAPPING[LineupTabs.Active],
        label: LINEUP_TABS_MAPPING[LineupTabs.Active],
        className: styles.tabName,
        value: LineupTabs.Active,
        tooltip: phrases.ACTIVE_TAB_TOOLTIP,
        children: activeCount ? (
          <UIText tiny bold className={styles.tabCounter}>
            {activeCount}
          </UIText>
        ) : null,
      },
      isPassiveSearchEnabled
        ? {
            id: LINEUP_TABS_MAPPING[LineupTabs.Passive],
            label: LINEUP_TABS_MAPPING[LineupTabs.Passive],
            className: styles.tabName,
            value: LineupTabs.Passive,
            tooltip: phrases.PASSIVE_TAB_TOOLTIP,
            children: passiveCount ? (
              <UIText tiny bold className={styles.tabCounter}>
                {passiveCount}
              </UIText>
            ) : null,
          }
        : null,
      {
        id: LINEUP_TABS_MAPPING[LineupTabs.MatchMiner],
        label: LINEUP_TABS_MAPPING[LineupTabs.MatchMiner],
        className: classNames(styles.tabName, styles.matchMinerTabItem),
        value: LineupTabs.MatchMiner,
        tooltip: matchMinerCount ? (
          <MatchMinerTooltip />
        ) : (
          phrases.MATCH_MINER_TAB_TOOLTIP
        ),
        children: matchMinerCount ? (
          <UIText tiny bold className={styles.tabCounter}>
            {isCandidatesProcessingInProgress ? <Loader /> : matchMinerCount}
          </UIText>
        ) : null,
      },
      {
        id: LINEUP_TABS_MAPPING[LineupTabs.MatchScout],
        label: LINEUP_TABS_MAPPING[LineupTabs.MatchScout],
        className: classNames(styles.tabName, styles.matchMinerTabItem),
        value: LineupTabs.MatchScout,
        tooltip: phrases.MATCH_MINER_TAB_TOOLTIP,
        children: matchScoutCount ? (
          <UIText tiny bold className={styles.tabCounter}>
            {matchScoutCount}
          </UIText>
        ) : null,
      },
    ],
    [
      activeCount,
      isPassiveSearchEnabled,
      passiveCount,
      matchMinerCount,
      isCandidatesProcessingInProgress,
      matchScoutCount,
    ]
  );

  return [currentTab, R.reject<any, any>(R.isNil, lineupTabs), onChangeTabs];
};
