import React from 'react';
import classNames from 'classnames';

import { SvgIcon } from '@air/components';
import { ButtonLoaderPositions } from '@air/components/Button/Button';

import styles from './KanbanIconButton.css';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  alignment?: ButtonLoaderPositions.LEFT | ButtonLoaderPositions.RIGHT;
  className?: string;
  icon?: string;
};

export const KanbanIconButton: React.FC<Props> = ({
  className = null,
  icon = null,
  alignment = ButtonLoaderPositions.LEFT,
  onClick,
  children,
}) => {
  const isButtonRightAligned = alignment === ButtonLoaderPositions.RIGHT;
  return (
    <button
      type="button"
      className={classNames(styles.kanbanIconButton, className, {
        [styles.rightAlignedButton]: isButtonRightAligned,
      })}
      onClick={onClick}
    >
      {icon && <SvgIcon className={styles.kanbanIconButtonSvg} icon={icon} />}
      <span>{children}</span>
    </button>
  );
};
KanbanIconButton.displayName = 'KanbanIconButton';
