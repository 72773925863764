import { useKanbanContext } from 'providers/KanbanProvider';
import { kanbanSelectors } from 'selectors';
import {
  SearchCountersT,
  mapSearchToSearchCounters,
} from 'domain/SearchCounters/SearchCounterData';
import { useMemo } from 'react';

export const useComputedCounters = () => {
  const currentSearch = useKanbanContext(kanbanSelectors.currentSearch);
  const currentMatchMinerSearch = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearch
  );
  const currentMatchScoutSearch = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearch
  );

  const computedCounters: SearchCountersT = useMemo(
    () => mapSearchToSearchCounters({ counters: currentSearch?.activeCount }),
    [currentSearch?.activeCount]
  );

  const computedPassiveCounters: SearchCountersT = useMemo(() => {
    return mapSearchToSearchCounters({
      counters: currentSearch?.passiveCount,
    });
  }, [currentSearch?.passiveCount]);

  const computedMatchMinerCounters: SearchCountersT = useMemo(() => {
    return mapSearchToSearchCounters({
      counters: currentMatchMinerSearch?.passiveCount,
    });
  }, [currentMatchMinerSearch?.passiveCount]);

  const computedMatchScoutCounters: SearchCountersT = useMemo(() => {
    return mapSearchToSearchCounters({
      counters: currentMatchScoutSearch?.passiveCount,
    });
  }, [currentMatchScoutSearch?.passiveCount]);

  return {
    computedCounters,
    computedPassiveCounters,
    computedMatchMinerCounters,
    computedMatchScoutCounters,
  };
};
