import React, { useMemo, useCallback, useState } from 'react';
import { Manager, Reference } from 'react-popper';
import classNames from 'classnames';
import R from '@air/third-party/ramda';

import styles from './UploadedFile.css';
import {
  SvgIcon,
  Popup,
  TooltipWrapper,
  Paragraph,
  UIText,
  Loader,
} from '@air/components';
import {
  FileError,
  FILE_ERRORS_CODES,
  UploadStatus,
  RequisitionFile,
} from 'context/uploadApplicants';
import * as phrases from 'constants/phrases';

import { useOverflowCheck } from '@air/utils/hooks';
import { transformFileName } from '@air/utils/strings';

const FINISHED_UPLOAD_PERCENTAGE = 100;

const getIcon = (errors: FileError[]) => {
  if (R.isNullOrEmpty(errors)) {
    return 'file-upload';
  } else {
    return 'file-upload-error';
  }
};

type UploadingInfoPopupProps = {
  file: RequisitionFile;
  uploadStatus: UploadStatus;
  isRemoveButtonVisible: boolean;
  onActionButtonClicked: () => void;
  charsAmountToDisplay?: number;
  closePopup?: () => void;
  isRemoving?: boolean;
};

const UploadingInfoPopup: React.FC<UploadingInfoPopupProps> = ({
  file,
  uploadStatus,
  children,
  isRemoveButtonVisible,
  onActionButtonClicked,
  charsAmountToDisplay,
  closePopup,
  isRemoving,
}) => {
  const { resumeFileName, tempId, progress, errors } = file;
  const [fileNameTooltipRef, isFileNameTooltipOverflown] =
    useOverflowCheck<HTMLParagraphElement>(resumeFileName);

  const UploadStatusMessagesMapping: { [key: number]: string } = {
    [UploadStatus.isFailed]: getErrorMessage(errors),
    [UploadStatus.isPending]: phrases.FILE_QUEUED_UPLOAD_INFO,
    [UploadStatus.isUploading]: phrases.getPercentageUploadedInfo(progress),
    [UploadStatus.isUploaded]: phrases.SUCCESSFULLY_UPLOADED_INFO,
  };

  return (
    <>
      <div className={styles.uploadedFileTooltip}>
        {children}
        <div className={styles.fileInfo}>
          <TooltipWrapper
            key={`tooltip-${tempId}`}
            enabled={isFileNameTooltipOverflown}
            tooltip={resumeFileName}
          >
            <Paragraph big ref={fileNameTooltipRef} className={styles.fileName}>
              {isFileNameTooltipOverflown
                ? transformFileName(resumeFileName, charsAmountToDisplay)
                : resumeFileName}
            </Paragraph>
          </TooltipWrapper>
          <UIText tiny className={styles.fileInfoMessage} onClick={closePopup}>
            {UploadStatusMessagesMapping[uploadStatus]}
          </UIText>
        </div>
      </div>
      {isRemoveButtonVisible && (
        <div
          className={classNames(styles.actionButton, {
            [styles.isLoading]: isRemoving,
          })}
          onClick={isRemoving ? R.noop : onActionButtonClicked}
        >
          <div className={styles.actionIconContainer}>
            {isRemoving ? (
              <Loader color="grey" className={styles.loader} />
            ) : (
              <SvgIcon icon="close-icon" />
            )}
          </div>
          <UIText small className={styles.actionName}>
            {isRemoving ? phrases.REMOVING_ACTION : phrases.REMOVE_ACTION}
          </UIText>
        </div>
      )}
    </>
  );
};

type UploadingIconProps = {
  progress: number;
  closePopup?: () => void;
};

const UploadingIcon: React.FC<UploadingIconProps> = ({
  progress,
  closePopup,
}) => {
  return (
    <div className={styles.uploadingIcon}>
      <SvgIcon icon="file-upload-empty" onClick={closePopup} />
      <div
        className={styles.uploadingProgress}
        style={{ '--upload-progress': `${progress}%` } as React.CSSProperties}
      />
    </div>
  );
};

const popperModifiers = {
  /*
    flip is disabled to place popup on bottom, even if it's too close
    to viewport's bottom border.
  */
  flip: { enabled: false },
  preventOverflow: { enabled: false },
  hide: { enabled: false },
};

type Props = {
  file: RequisitionFile;
  canRemoveUploadedFiles?: boolean;
  charsToDisplayInParent?: number;
  isLineupView?: boolean;
  onRemoveApplicant: (
    atsCandidateId: string,
    fileId: string,
    uploadStatus: UploadStatus
  ) => void;
};

const CHARS_TO_DISPLAY_FOR_DRAFT = 19;

export const UploadedFile: React.FC<Props> = ({
  file,
  canRemoveUploadedFiles = false,
  onRemoveApplicant,
  charsToDisplayInParent = CHARS_TO_DISPLAY_FOR_DRAFT,
  isLineupView,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const { tempId, atsCandidateId, resumeFileName, progress, errors } = file;
  const [fileNameRef, isFileNameOverflown] =
    useOverflowCheck<HTMLParagraphElement>(resumeFileName);

  const getUploadStatus = useCallback(() => {
    if (!R.isNullOrEmpty(errors)) {
      return UploadStatus.isFailed;
    } else if (!atsCandidateId && !progress) {
      return UploadStatus.isPending;
    } else if (
      atsCandidateId ||
      (progress && progress >= FINISHED_UPLOAD_PERCENTAGE)
    ) {
      return UploadStatus.isUploaded;
    } else if (progress && progress < FINISHED_UPLOAD_PERCENTAGE) {
      return UploadStatus.isUploading;
    }
  }, [errors, atsCandidateId, progress]);

  const uploadStatus = useMemo(() => getUploadStatus(), [getUploadStatus]);

  const isRemoveButtonVisible =
    onRemoveApplicant &&
    (canRemoveUploadedFiles ||
      [UploadStatus.isPending, UploadStatus.isFailed].includes(uploadStatus));

  const POPUP_HEIGHT = isRemoveButtonVisible ? 10 : 6;
  /* PARENT_HEIGHT should be the same as --parent-height
   variable in UploadedFile.css */
  const PARENT_HEIGHT = 4;

  const icon = useMemo(() => getIcon(errors), [errors]);

  const [isUploadPopupOpened, toggleUploadPopupOpenedState] = useState(false);

  const closePopup = useCallback(() => {
    toggleUploadPopupOpenedState(false);
  }, []);

  const UploadedIcon = useMemo(() => {
    return (
      <>
        {uploadStatus === UploadStatus.isUploading ? (
          <UploadingIcon progress={progress} closePopup={closePopup} />
        ) : (
          <SvgIcon
            className={classNames(styles.fileIcon, {
              [styles.pending]: uploadStatus === UploadStatus.isPending,
              [styles.uploaded]: uploadStatus === UploadStatus.isUploaded,
              [styles.error]: uploadStatus === UploadStatus.isFailed,
            })}
            icon={icon}
            onClick={closePopup}
          />
        )}
      </>
    );
  }, [uploadStatus, icon, progress, closePopup]);

  const onActionButtonClicked = useCallback(async () => {
    setIsRemoving(true);
    await onRemoveApplicant(atsCandidateId, tempId, uploadStatus);
    setIsRemoving(false);
  }, [atsCandidateId, onRemoveApplicant, tempId, uploadStatus]);

  const openFileInfo = useCallback(() => {
    toggleUploadPopupOpenedState(true);
    /*
    If popup is opened, but user starts scrolling, we should
    immediately close the popup.
   */
    window.addEventListener(
      'scroll',
      function handler() {
        window.removeEventListener('scroll', handler, true);
        toggleUploadPopupOpenedState(false);
      },
      true
    );
    /*
      If popup is opened, but user starts resizing window, we should
      immediately close the popup.
    */
    window.addEventListener(
      'resize',
      function handler() {
        window.removeEventListener('resize', handler, true);
        toggleUploadPopupOpenedState(false);
      },
      true
    );
  }, []);

  return (
    <>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              ref={ref}
              className={classNames(styles.uploadedFile, {
                [styles.uploadedFileLineup]: isLineupView,
              })}
            >
              <div onClick={openFileInfo}>{UploadedIcon}</div>
              <TooltipWrapper
                enabled={isFileNameOverflown}
                tooltip={resumeFileName}
              >
                <Paragraph big ref={fileNameRef} className={styles.fileName}>
                  <span
                    className={classNames(styles.fileNameText, {
                      [styles.failedUpload]:
                        uploadStatus === UploadStatus.isFailed,
                    })}
                  >
                    {isFileNameOverflown
                      ? transformFileName(
                          resumeFileName,
                          charsToDisplayInParent
                        )
                      : resumeFileName}
                  </span>
                </Paragraph>
              </TooltipWrapper>
            </div>
          )}
        </Reference>
        {isUploadPopupOpened && (
          <Popup
            eventsEnabled={false}
            onOutsideClick={closePopup}
            placement="bottom"
            modifiers={{
              ...popperModifiers,
              offset: getPopupOffset(
                POPUP_HEIGHT,
                PARENT_HEIGHT,
                isRemoveButtonVisible
              ),
            }}
          >
            {({ ref, style, placement }) => {
              return (
                <div
                  ref={ref}
                  style={{ ...style, height: `${POPUP_HEIGHT}em` }}
                  data-placement={placement}
                  className={classNames(styles.uploadedFileInfoContainer, {
                    [styles.lineup]: isLineupView,
                  })}
                >
                  <UploadingInfoPopup
                    file={file}
                    uploadStatus={uploadStatus}
                    isRemoveButtonVisible={isRemoveButtonVisible}
                    onActionButtonClicked={onActionButtonClicked}
                    isRemoving={isRemoving}
                    charsAmountToDisplay={charsToDisplayInParent - 1}
                    closePopup={closePopup}
                  >
                    {UploadedIcon}
                  </UploadingInfoPopup>
                </div>
              );
            }}
          </Popup>
        )}
      </Manager>
    </>
  );
};

function getPopupOffset(
  popupHeight: number,
  parentHeight: number,
  isRemoveButtonVisible: boolean
): { offset: string } {
  /*
    Depending on screen width, fontSize can change, so for safety we recalculate
    it every time.
  */
  const fontSize = parseInt(
    getComputedStyle(document.documentElement).fontSize,
    10
  );

  /* to align popup vertically */
  const distance = (fontSize * (popupHeight + parentHeight)) / 2;
  const offset = `0, -${distance - (isRemoveButtonVisible ? 15 : 0) - 5}px`;

  return { offset };
}

function getErrorMessage(errors: FileError[]) {
  if (R.isNullOrEmpty(errors)) return '';
  const error = errors[0]; // 1 file can have multiple errors, show only the first one
  const errorMessage = error?.message;

  switch (error.code) {
    case FILE_ERRORS_CODES.INVALID_TYPE:
      return phrases.INCORRECT_FILE_TYPE_ERROR;
    case FILE_ERRORS_CODES.TOO_LARGE:
      return phrases.TOO_BIG_FILE_ERROR;
    // TODO: change after implementing error codes from BE side
    case FILE_ERRORS_CODES.ATS_ERROR: // ARS-1101 - hardcoded errors from back-end
      if (errorMessage.includes('already exists')) {
        return phrases.DUPLICATED_FILE_ERROR;
      } else if (
        errorMessage.includes('type is incorrect') ||
        errorMessage.includes('file extension')
      ) {
        return phrases.INCORRECT_FILE_TYPE_ERROR;
      }
      break;
    default:
      return phrases.SOMETHING_WENT_WRONG_ERROR;
  }
}
