import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { JobDescriptionFullResponse } from '@air/api';
import R from '@air/third-party/ramda';
import { Paragraph } from '@air/components';
import * as phrases from 'constants/phrases';
import { DATE_FORMAT } from 'constants/app';
import { JobOwnersList } from 'components';
import styles from './JobMetadata.css';

export const MetadataField: React.FC<{
  title: string;
  date: number;
  owners?: string[];
  creator?: string;
  listPrefix?: string;
}> = ({ title, date, owners, creator, listPrefix }) => {
  const hasDate = !R.isNullOrEmpty(date);
  const hasOwners = !R.isNullOrEmpty(owners);
  const hasCreator = !!creator;

  const shouldJoinDateAndAuthor =
    (hasOwners || hasCreator) &&
    title === phrases.NEW_REQUISITION_SEARCH_CREATED_STATUS;

  return (
    <div className={styles.metadataField}>
      {hasDate && (
        <div
          className={classNames(styles.dateField, {
            [styles.dateFieldWithPrefix]: shouldJoinDateAndAuthor,
          })}
        >
          <Paragraph small>{title} </Paragraph>
          <Paragraph small>{dayjs(date).format(DATE_FORMAT)}</Paragraph>
        </div>
      )}
      {hasOwners && <JobOwnersList listPrefix={listPrefix} items={owners} />}
      {hasCreator && (
        <Paragraph small>
          {phrases.REQUISITION_CREATOR_PREFIX} {creator}
        </Paragraph>
      )}
    </div>
  );
};

export const JobMetadata: React.FC<{
  jobRequisitionDetails: JobDescriptionFullResponse;
  isStandaloneAtsUser: boolean;
  created?: number;
  isBeforeDraftCreation?: boolean;
  className?: string;
}> = ({
  created,
  isBeforeDraftCreation,
  isStandaloneAtsUser,
  className,
  jobRequisitionDetails,
}) => {
  if (!jobRequisitionDetails) return null;

  const { creator, opened = 0, owners = [] } = jobRequisitionDetails || {};
  const creatorName = [`${creator?.firstName} ${creator?.lastName}`];

  return (
    <div className={classNames(styles.jobMetadata, className)}>
      {isBeforeDraftCreation && !isStandaloneAtsUser && (
        <>
          {!!opened && (
            <MetadataField
              title={phrases.NEW_REQUISITION_SEARCH_OPENED_STATUS}
              date={opened}
            />
          )}
          {!R.isNullOrEmpty(owners) && <JobOwnersList items={owners} />}
        </>
      )}
      {!isBeforeDraftCreation &&
        !isStandaloneAtsUser &&
        (!R.isNullOrEmpty(owners) || !!opened) && (
          <MetadataField
            title={phrases.NEW_REQUISITION_SEARCH_OPENED_STATUS}
            date={opened}
            owners={owners}
            listPrefix={phrases.REQUISITION_OWNER_PREFIX}
          />
        )}
      {!isBeforeDraftCreation && !!created && (
        <MetadataField
          title={phrases.NEW_REQUISITION_SEARCH_CREATED_STATUS}
          date={created}
          owners={creatorName}
          listPrefix={phrases.REQUISITION_CREATOR_PREFIX}
        />
      )}
    </div>
  );
};
JobMetadata.displayName = 'JobMetadata';
