import React from 'react';
import R from '@air/third-party/ramda';

import {
  Alert,
  AlertStatusEnum,
  SearchCriteriaAlertType,
  SearchEducationDegree,
  SearchEducationDegreeInfo,
  SearchEducationInstitution,
  SearchEducationMajor,
  SearchEducationMajorInfo,
  SearchInstitutionInfo,
} from '@air/api';
import { convertAlertNameToString } from '@air/domain/Alerts/AlertMapper';

/**
 * Module `AlertData` contains data type that describes an object,
 * used by ui components to represent any issues associated with criteria cards,
 * and operations (methods) defined on tha data types.
 * These methods can be - constructors to create values from DTOs,
 * normalize functions etc.
 *
 * todo: add red-flags support here as well
 */
export type AlertData = {
  name: SearchCriteriaAlertType | string;
  displayName: React.ReactNode;
  status: AlertStatusEnum;
};

export const getAlertByPrimaryProperty = (values: any) => {
  if (R.isNullOrEmpty(values)) {
    return null;
  }
  return R.compose<Alert[][], Alert[], Alert>(
    R.head,
    R.filter(R.propEq('primary', true))
  )(values);
};

export const filterOpenedAlerts = <T extends { status: AlertStatusEnum }>(
  values: T[]
): T[] | null =>
  R.filter(({ status }: T) =>
    [AlertStatusEnum.OPENED, AlertStatusEnum.WARNINGOPENED].includes(status)
  )(values);

/**
 * @description constructs a value of type AlertData
 * @param displayName
 * @param name
 * @param status
 */
export const createAlertData = (
  displayName: React.ReactNode,
  name: SearchCriteriaAlertType | string = null,
  status: AlertStatusEnum = null
): AlertData => ({
  displayName,
  name,
  status,
});

/**
 * @description: Assuming that no further ordering and filtering required,
 * the function extracts the first alert and construct AlertData datatype from it
 **/

export function getAlertData(alerts: Alert[]): AlertData | null {
  if (alerts?.length > 0) {
    const alert = getAlertByPrimaryProperty(alerts);
    return createAlertData(
      convertAlertNameToString(alert),
      alert.name,
      alert.status
    );
  }
  return null;
}

export const getAlertsByPrimaryItem =
  (primaryItemRefId: string) => (values: Record<'alerts', Alert[]>[]) => {
    if (R.isNullOrEmpty(values)) {
      return null;
    }

    return R.compose<
      Record<'alerts', Alert[]>[][],
      Record<'alerts', Alert[]>[],
      Record<'alerts', Alert[]>,
      Alert[],
      Alert
    >(
      getAlertByPrimaryProperty,
      R.prop('alerts'),
      R.head,
      // @ts-ignore
      R.filter(R.propEq('refId', primaryItemRefId))
    )(values);
  };

// TODO @evgeny.kozhura
//  in AlertData consider using generic types that implements certain interface
//  instead of these type aliases:
export type SearchEducationTypes =
  | SearchEducationMajor
  | SearchEducationDegree
  | SearchEducationInstitution;
export type SearchEducationInfoTypes =
  | SearchEducationMajorInfo
  | SearchEducationDegreeInfo
  | SearchInstitutionInfo;

export const getEducationPrimaryAlert = (
  educationCriterion: SearchEducationTypes
): any => {
  return R.compose<
    SearchEducationTypes[],
    SearchEducationInfoTypes[][],
    SearchEducationInfoTypes[],
    SearchEducationInfoTypes[],
    any
  >(
    getAlertsByPrimaryItem(educationCriterion.primaryItemRefId),
    R.filter<SearchEducationInfoTypes>(Boolean),
    // @ts-ignore
    (i) => R.flatten<SearchEducationInfoTypes>(i),
    R.props<'idealList' | 'acceptableList', SearchEducationInfoTypes[]>([
      'idealList',
      'acceptableList',
    ])
  )(educationCriterion);
};

export const getGraduationAlertData = R.compose<Alert[][], Alert[], AlertData>(
  getAlertData,
  R.filter((alert: Alert) =>
    [
      SearchCriteriaAlertType.EDUCATIONGRADUATEYEARMISSED,
      SearchCriteriaAlertType.EDUCATIONCURRENTYEARGRADUATEDETAILSMISSED,
    ].includes(alert.name as SearchCriteriaAlertType)
  )
);

export const getEducationAlertData = (
  educationCriterion: SearchEducationTypes
): AlertData => {
  const alert = getEducationPrimaryAlert(educationCriterion);
  return alert
    ? createAlertData(convertAlertNameToString(alert), alert.name, alert.status)
    : null;
};
