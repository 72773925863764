import R from '@air/third-party/ramda';
import { EventType, NotificationEvent } from '@air/api';
import { MessageFilterFunction } from '@air/lib/server-notifications/MessageFilters';

type ProfileId = string;

export enum CandidateDataUpdateType {
  DROP_CANDIDATES_LIST = 1,
  UPDATE_COUNTERS,
  UPDATE_CANDIDATES,
  DELETE_CANDIDATES,
  UPDATE_CANDIDATE_STAGE,
}

export type CandidateDataUpdate = {
  type: CandidateDataUpdateType;
  payload?: ProfileId[];
};

const selectEvent = (event: EventType) =>
  R.propEq<EventType>('eventType' as EventType, event);

/**
 * Main event filtering function for InterviewSection.
 * Transforms a batch of different types of events based on different scenarios into a sequence of updates
 * which is then processed on InterviewSection.
 *
 * 1. Processing:
 *   The sequence of events includes one event of type SEARCH_APPLIED_COUNT_UPDATED and several events
 *   of type CANDIDATE_PROFILE_STATUS_UPDATED. Since CANDIDATE_PROFILE_STATUS_UPDATED can update counters
 *   on search, consumer should update currentSearch each time it updates a group of candidate profiles.
 *   That's why we can safely drop SEARCH_APPLIED_COUNT_UPDATED, as search counters will be updated anyway.
 *
 * 2. Reprocessing after modify:
 *   The same sequence of events, but before CANDIDATE_PROFILE_STATUS_UPDATED are received, backend triggers
 *   SEARCH_CANDIDATE_PROFILES_DROPPED. That means, profiles are removed on backend and we also need to clear
 *   lineup before any candidate profile updates. The same rule for SEARCH_APPLIED_COUNT_UPDATED is applied - we
 *   can safely ignore it.
 *
 * 3. Interview on pause:
 *   Even though interview is on pause, some actions can be performed. For example, for some ATS customer
 *   can upload resumes. Even though processing is on hold, applicant counter is updated and backend triggers
 *   SEARCH_APPLIED_COUNT_UPDATED. In this case we process it. That's the case when we don't want to ignore
 *   SEARCH_APPLIED_COUNT_UPDATED and process it.
 *
 * 4. Candidates deleted from 3rd-party ATS:
 *   When candidate is deleted, backend sends CANDIDATE_PROFILE_STATUS_UPDATED, where in its payload
 *   `originalStatus` and `newStatus` are null. That's a special case and it should be processed separately
 *   from other CANDIDATE_PROFILE_STATUS_UPDATED events (where `newStatus` is never null).
 *
 * Resulting sequence of updates will contain one (are all) of CandidateDataUpdateType events, which then passed
 * further to event processing pipe (for now it's _runProcessing method of MessageConsumer, which just pass this batch
 * to the subscriber in `InterviewSection`).
 */
export const candidateDataHandler = (
  events: NotificationEvent[]
): CandidateDataUpdate[] => {
  const updates: CandidateDataUpdate[] = [];

  // one to one transform: we received SEARCH_CANDIDATE_PROFILES_DROPPED event and push DROP_CANDIDATES_LIST event
  if (
    events.some(
      selectEvent(EventType.SEARCHCANDIDATEPROFILESDROPPED) as () => boolean
    )
  ) {
    updates.push({
      type: CandidateDataUpdateType.DROP_CANDIDATES_LIST,
    });
  }

  // grouping CANDIDATE_PROFILE_STATUS_UPDATED events into two batches - one for deletion from lineup,
  // and one for candidates updates in lineup
  const candidatesUpdates = events
    .filter(
      selectEvent(EventType.CANDIDATEPROFILESTATUSUPDATED) as () => boolean
    )
    .reduce(
      (acc, current) => {
        if (
          current.payload.originalStatus === null &&
          current.payload.newStatus === null
        ) {
          return {
            ...acc,
            delete: acc.delete.concat(current.payload.profileId),
          };
        } else {
          return {
            ...acc,
            update: acc.update.concat(current.payload.profileId),
          };
        }
      },
      { delete: [], update: [] }
    );

  // for each group of profileIds we generate one event:
  // one for deletion DELETE_CANDIDATES, and one for updates UPDATE_CANDIDATES
  if (candidatesUpdates.delete.length > 0) {
    updates.push({
      type: CandidateDataUpdateType.DELETE_CANDIDATES,
      payload: candidatesUpdates.delete,
    });
  }

  if (candidatesUpdates.update.length > 0) {
    updates.push({
      type: CandidateDataUpdateType.UPDATE_CANDIDATES,
      payload: candidatesUpdates.update,
    });

    // this is kind of optimization, if we received SEARCH_APPLIED_COUNT_UPDATED in one batch
    // with some CANDIDATE_PROFILE_STATUS_UPDATED events, we would ignore SEARCH_APPLIED_COUNT_UPDATED,
    // because search will be fetched in this case anyway (refer InterviewSection.tsx to review this logic)
  } else if (
    events.some(
      selectEvent(EventType.SEARCHAPPLIEDCOUNTUPDATED) as () => boolean
    )
  ) {
    updates.push({
      type: CandidateDataUpdateType.UPDATE_COUNTERS,
    });
  }
  /*
    If SEARCHAPPLIEDCOUNTUPDATED and CANDIDATEPROFILESTAGEUPDATED events
    are received simultaneously (as in the case that led to AR-10627)
    we missed to schedule refetching of candidate profiles.
    Because of this only tab counters were updated, but the list of
    candidates stayed the same.
   */
  if (
    events.some(
      selectEvent(EventType.CANDIDATEPROFILESTAGEUPDATED) as () => boolean
    )
  ) {
    updates.push({
      type: CandidateDataUpdateType.UPDATE_CANDIDATE_STAGE,
      payload: events.reduce((acc, event) => {
        if (event.eventType === EventType.CANDIDATEPROFILESTAGEUPDATED) {
          acc.push(R.path(['payload', 'profileId'], event));
          return acc;
        }
        return acc;
      }, []),
    });
  }

  return updates;
};

export const candidateDataFilter: MessageFilterFunction<
  NotificationEvent[],
  CandidateDataUpdate[]
> = (onComplete) => (events) => onComplete(candidateDataHandler(events));
