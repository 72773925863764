import { Task } from '@air/utils/fp';
import * as Http from '@air/utils/http';
import * as urls from 'constants/urls';
import { parseErrorJson, parseResponseJson } from '@air/utils/api';
import {
  AtsExternalProfileListResponse,
  AtsExternalProfileWithTotalCount,
} from '@air/api/models';

type FetchApplicantsParamsT = {
  jobDescriptionId: number | string;
  atsId: number | string;
  notProcessedProfiles?: boolean;
  size?: number;
};

export const fetchApplicants = ({
  jobDescriptionId,
  atsId,
  notProcessedProfiles = true,
  size = 1000, // BE will return only 20 items by default so we're sending quite a big hardcoded size before there is a pagination
}: FetchApplicantsParamsT): Task<AtsExternalProfileListResponse> => {
  return Http.get(`${urls.FETCH_APPLICANTS_API_URL}`)
    .bind(
      Http.withQueryParams({
        jobDescriptionId,
        atsId,
        notProcessedProfiles,
        size,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

type UploadApplicantT = {
  jobRequisitionId: string;
  atsId: number;
  body: FormData;
  xhrActions: object;
  tag?: string;
  passive?: boolean;
};

export const uploadApplicant = ({
  jobRequisitionId,
  atsId,
  tag,
  passive,
  body,
  xhrActions,
}: UploadApplicantT): Task<AtsExternalProfileWithTotalCount> => {
  const queryParams = tag
    ? { jobRequisitionId, atsId, passive, tag }
    : { jobRequisitionId, atsId, passive };

  return Http.post(`${urls.UPLOAD_APPLICANTS_API_URL}`, xhrActions)
    .bind(Http.withQueryParams(queryParams))
    .bind(Http.withFormMultipartBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const deleteApplicant = (
  applicantId: number | string,
  jobRequisitionId: string,
  atsId: number
): Task<AtsExternalProfileWithTotalCount> => {
  return Http.remove(`${urls.makeDeleteApplicantApiUrl(applicantId)}`)
    .bind(Http.withQueryParams({ jobRequisitionId, atsId }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
