import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Button, FormDropdown, Tooltip } from '@air/components';
import { ProfileSort } from '@air/api';
import { FormDropdownVariants } from '@air/components/Form';
import * as phrases from 'constants/phrases';
import { useCandidateProfileContext } from 'providers/CandidateProfileProvider';
import { candidateProfileSelectors, kanbanSelectors } from 'selectors';
import { useKanbanContext } from 'providers/KanbanProvider';

import styles from './SortDropdown.css';

type Props = {
  onSortChange: (sort: ProfileSort) => void;
};

const FORM_DROPDOWN_OPTIONS = [
  {
    label: phrases.LINEUP_TABS_SORT_BEST_MATCHING_LABEL,
    value: ProfileSort.SCORE,
  },
  {
    label: phrases.LINEUP_TABS_SORT_RECENTLY_ADDED_LABEL,
    value: ProfileSort.CREATEDAT,
  },
  {
    label: phrases.LINEUP_TABS_SORT_ALPHABETICAL_ORDER_LABEL,
    value: ProfileSort.ALPHABETICAL,
  },
];

export const SortDropdown: React.FC<Props> = ({ onSortChange }) => {
  const selectedSortForSearches = useCandidateProfileContext(
    candidateProfileSelectors.selectedSortForSearches
  );
  const sortId = useKanbanContext(kanbanSelectors.currentSearchId);

  // We use the id of parent search (currentSearch) to store selected sort type
  // for all related searches (MatchMiner, MatchScout), because when restarting MM or MS
  // their ids change and sort will be reset in that case.
  const selectedSort = selectedSortForSearches[sortId] || ProfileSort.SCORE;

  const onSubmit = (data: { value: ProfileSort }) => onSortChange(data.value);

  const { control } = useForm({
    defaultValues: {
      sortType: {
        label: phrases.LINEUP_TABS_SORT_BEST_MATCHING_LABEL,
        value: selectedSort,
      },
    },
  });

  const selectedValue = useMemo(() => {
    return FORM_DROPDOWN_OPTIONS.find((item) => item.value === selectedSort);
  }, [selectedSort]);

  return (
    <form>
      <Controller
        name="sortType"
        control={control}
        render={({ field }) => {
          const { onChange: onChangeLib } = field;

          return (
            <FormDropdown
              {...field}
              options={FORM_DROPDOWN_OPTIONS}
              value={selectedValue}
              onChange={(value: { label: string; value: ProfileSort }) => {
                onChangeLib(value);
                onSubmit(value);
              }}
              variant={FormDropdownVariants.ICON}
              valueContainer={
                <Tooltip
                  placement="top"
                  trigger="hover"
                  tooltip={phrases.LINEUP_TABS_SORT_TOOLTIP}
                  triggerClassName={styles.sortIconWrapper}
                >
                  <Button
                    variant={Button.variants.DEFAULT}
                    className={styles.sortIcon}
                    icon="sort"
                  />
                </Tooltip>
              }
            />
          );
        }}
      />
    </form>
  );
};
