/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicantJobStatus {
  OPENREQUISITION = 'OPEN_REQUISITION',
  OPENREQUISITIONSUBMITTED = 'OPEN_REQUISITION_SUBMITTED',
  ONHOLDREQUISITION = 'ON_HOLD_REQUISITION',
  ONHOLDREQUISITIONSUBMITTED = 'ON_HOLD_REQUISITION_SUBMITTED',
  CLOSEDREQUISITION = 'CLOSED_REQUISITION',
  CLOSEDREQUISITIONSUBMITTED = 'CLOSED_REQUISITION_SUBMITTED',
}
