import React, { useCallback, useEffect, useState } from 'react';

import { KanbanCard } from 'components';
import {
  SearchListTypes,
  useKanbanContext,
  useKanbanMethods,
  useSelectInterviewKanbanContext,
  useSelectInterviewKanbanMethods,
} from 'providers/KanbanProvider';
import { customerProfileSelectors, kanbanSelectors } from 'selectors';
import { LoadMoreButton } from 'features/Kanban/LoadMoreButton/LoadMoreButton';
import {
  getAmountOfJobsToLoad,
  KANBAN_SEARCH_GROUPS,
  addRemoveCardAction,
} from 'domain/Kanban/Kanban';
import { usePaginatedSearch } from 'domain/Kanban/usePaginatedSearch';

import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';

import commonStyles from 'features/Kanban/KanbanSection/KanbanSection.css';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit, subscribe } from 'hooks/usePubSub';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import {
  GA_LABEL_COMPLETE_SCREENING,
  GA_LABEL_RESUME_SCREENING,
} from 'constants/gaLabels';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { KanbanCardT } from 'components/Cards/KanbanCard/KanbanCard';
import { SearchProgressStatusEnum } from '@air/api';

type Props = {
  isSelectMode?: boolean;
  selectModeTargetId?: string | number;
};

export const Paused: React.FC<Props> = React.memo((props) => {
  const { isSelectMode, selectModeTargetId } = props;
  const [kanbanContext, kanbanContextMethods] = isSelectMode
    ? [useSelectInterviewKanbanContext, useSelectInterviewKanbanMethods]
    : [useKanbanContext, useKanbanMethods];
  const pausedSearchesList = useKanbanContext(
    kanbanSelectors.pausedSearchesList
  );
  const interviewsList = kanbanContext(kanbanSelectors.interviewsList);
  const closedSearchesList = useKanbanContext(
    kanbanSelectors.closedSearchesList
  );
  const currentSearchFilterName = useKanbanContext(
    kanbanSelectors.currentSearchFilterName
  );
  const isFirstPausedJobUpdated = useKanbanContext(
    kanbanSelectors.isFirstPausedJobUpdated
  );
  const {
    closeInterview,
    republishInterview,
    updateSearchStatusInList,
    removeDraft,
  } = useKanbanMethods();
  const isExternalATS = useCustomerProfileContext(
    customerProfileSelectors.isExternalATS
  );
  const { fetchSearchesByStatus } = kanbanContextMethods();
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const statsExpanded = useKanbanContext(kanbanSelectors.statsExpanded);
  const isMatchMinerFeatureEnabled = useCustomerProfileContext(
    customerProfileSelectors.matchMinerEnabled
  );
  const isMatchScoutFeatureEnabled = useCustomerProfileContext(
    customerProfileSelectors.matchScoutEnabled
  );
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearchesByStatusCb = useCallback(
    async (page) => {
      setIsLoading(true);
      await fetchSearchesByStatus({
        status: KANBAN_SEARCH_GROUPS.Paused,
        page,
        ...(isSelectMode
          ? { withCriteria: true, excludeId: [selectModeTargetId as string] }
          : {}),
      });
      setIsLoading(false);
    },
    [fetchSearchesByStatus, isSelectMode, selectModeTargetId]
  );

  const loadMoreJobs = usePaginatedSearch(fetchSearchesByStatusCb, [
    currentSearchFilterName,
    isFirstPausedJobUpdated,
  ]);

  const amountOfJobsLeftToLoad = getAmountOfJobsToLoad(
    pausedSearchesList.total,
    pausedSearchesList.items.length
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.JOB_STATUS_CHANGE, loadMoreJobs);
  }, [loadMoreJobs]);

  const resumeInterviewAction = useCallback(
    async (item: KanbanCardT) => {
      updateSearchStatusInList({
        item,
        itemsList: pausedSearchesList,
        listName: SearchListTypes.STATUS_PAUSED_SEARCHES,
      });
      updateSearchStatusInList({
        item,
        itemsList: interviewsList,
        newStatus: SearchProgressStatusEnum.INTERVIEW,
        listName: SearchListTypes.STATUS_INTERVIEWS,
      });
      trackEvent({
        category: GACategory.ScreeningPage,
        label: GA_LABEL_RESUME_SCREENING,
      });
      await republishInterview(item);
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
    },
    [
      interviewsList,
      pausedSearchesList,
      republishInterview,
      updateSearchStatusInList,
    ]
  );

  const closeInterviewAction = useCallback(
    async (item: KanbanCardT) => {
      updateSearchStatusInList({
        item,
        itemsList: pausedSearchesList,
        listName: SearchListTypes.STATUS_PAUSED_SEARCHES,
      });
      updateSearchStatusInList({
        item,
        itemsList: closedSearchesList,
        newStatus: SearchProgressStatusEnum.CLOSED,
        listName: SearchListTypes.STATUS_CLOSED_SEARCHES,
      });
      trackEvent({
        category: GACategory.ScreeningPage,
        label: GA_LABEL_COMPLETE_SCREENING,
      });
      await closeInterview(item);
      emit(APP_EVENTS.JOB_STATUS_CHANGE);
    },
    [
      closeInterview,
      closedSearchesList,
      pausedSearchesList,
      updateSearchStatusInList,
    ]
  );

  return (
    <div className={commonStyles.sectionJobs}>
      {pausedSearchesList.items.map((item: KanbanCardT) => {
        const actionsMenuConfig = [
          {
            onClick: () => emit(APP_EVENTS.OPEN_JOB_PANEL, { item }),
            icon: 'edit',
            title: phrases.KANBAN_ACTION_EDIT,
            disabled: isTrialExpired,
            closeMenuOnClick: true,
          },
          ...(!isExternalATS
            ? [
                {
                  onClick: () => emit(APP_EVENTS.DUPLICATE_CARD, item),
                  icon: 'duplicate',
                  title: phrases.KANBAN_ACTION_DUPLICATE,
                  disabled: isTrialExpired,
                  closeMenuOnClick: true,
                },
              ]
            : []),
          {
            onClick: () => resumeInterviewAction(item),
            icon: 'restart-screening',
            title: phrases.KANBAN_ACTION_RESUME_SCREENING,
            disabled: isTrialExpired,
            confirmTitle: phrases.RESUME_ACTION_CONFIRMATION_MESSAGE,
            confirmLabel: phrases.SCREENING_SECTION_RESUME_ACTION,
          },
          {
            onClick: () => closeInterviewAction(item),
            icon: 'close',
            title: phrases.KANBAN_ACTION_CLOSE,
            disabled: isTrialExpired,
            confirmTitle: phrases.CLOSE_ACTION_CONFIRMATION_MESSAGE,
            confirmLabel: phrases.KANBAN_ACTION_CLOSE,
          },
        ];
        addRemoveCardAction(actionsMenuConfig, removeDraft, item);

        return (
          <KanbanCard
            key={item.searchId}
            item={item}
            actionsMenuConfig={actionsMenuConfig}
            isSelectMode={isSelectMode}
            isMMEnabled={isMatchMinerFeatureEnabled}
            isMSEnabled={isMatchScoutFeatureEnabled}
            areAllStatsExpanded={statsExpanded[phrases.KANBAN_SECTION_PAUSED]}
            url={urls.makeInterviewUrl(
              item.ats.id,
              item.ats.externalJobDescriptionId
            )}
          />
        );
      })}
      {!!amountOfJobsLeftToLoad && (
        <LoadMoreButton
          isLoading={isLoading}
          onClick={loadMoreJobs}
          text={phrases.getLoadMoreJobsButtonTitle(amountOfJobsLeftToLoad)}
        />
      )}
    </div>
  );
});
