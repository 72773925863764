import React, { useCallback } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { toast } from '@air/third-party/toast';
import {
  ClosedSection,
  CreatePasswordView,
  DraftSection,
  EmailConfigSection,
  InterviewSection,
  JobsSection,
  Kanban,
  Landing,
  Login,
  SignupAdmin,
  WatcherUnsubscription,
} from 'features';
import {
  CacheProvider,
  JobsProvider,
  KanbanProvider,
  UploadApplicantsProvider,
  AppProvider,
  EditInterviewProvider,
} from 'providers';
import { AnalyticsTracker, CanAccess } from '@air/components';
import { Dropzone, FeatureView, ProtectedRoute } from 'components';
import styles from 'features/Landing/Landing.css';
import { doesKanbanHaveATSJobs } from 'domain/Kanban/Kanban';
import * as urls from 'constants/urls';
import { EMAIL_CONFIG_ROUTES, makeInterviewUrl } from 'constants/urls';
import * as commonUrls from '@air/constants/commonUrls';
import { useSSESubscription } from '@air/lib/server-notifications';
import { emit } from 'hooks/usePubSub';
import { APP_EVENTS } from 'domain/Kanban/events';
import { CandidateProfileProvider } from 'providers/CandidateProfileProvider';
import { LegacyKanbanContextConsumer } from 'providers/KanbanProvider';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import { customerProfileSelectors } from 'selectors';
import { KanbanContext, KanbanContextT } from 'context';
import { getBackgroundImageName } from 'domain/UISettings/backgroundSettings';
import { EditInterviewSection } from 'features/InterviewSection/EditInterview/EditInterviewSection';
import { CUSTOMER_NOTIFICATION_URL } from '@air/constants/apiEndpoints';
import { EmailConfigurationProvider } from 'providers/EmailConfigurationProvider';
import { EmailConfigSectionT } from 'domain/EmailConfig/EmailTemplates';

export const Routes = () => {
  const [dropZoneVisible, setDropZoneVisible] = React.useState(false);

  const user = useCustomerProfileContext(customerProfileSelectors.user);
  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );
  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const currentBackgroundImageId = useCustomerProfileContext(
    customerProfileSelectors.currentBackgroundImageId
  );
  const authenticated = useCustomerProfileContext(
    customerProfileSelectors.authenticated
  );
  const authDataReceived = useCustomerProfileContext(
    customerProfileSelectors.authDataReceived
  );
  const uiIsReady = useCustomerProfileContext(
    customerProfileSelectors.uiIsReady
  );

  const customerId = useCustomerProfileContext(
    customerProfileSelectors.customerId
  );

  const onDragEnter = useCallback(
    (event) => {
      if (!isTrialExpired && event?.dataTransfer?.types?.includes('Files')) {
        emit(APP_EVENTS.APPLICANT_DRAG_START);
        setDropZoneVisible(true);
      }
    },
    [isTrialExpired]
  );

  const onDrop = useCallback(() => {
    setDropZoneVisible(false);
  }, []);

  const onDragExit = useCallback((e) => {
    /*
      DragExit event is fired multiple time when item is dragged over
      different elements on the screen, but if it is dragged outside of the
      screen then e.relatedTarget will be null.
      And this is the only case of drag exit that we're interested in.
     */
    if (e && !e.relatedTarget) {
      toast.dismiss();
      emit(APP_EVENTS.APPLICANT_DRAG_END);
      setDropZoneVisible(false);
    }
  }, []);

  useSSESubscription(CUSTOMER_NOTIFICATION_URL, uiIsReady);

  return (
    <AppProvider>
      <CacheProvider>
        <div className={styles.customerPortalWrapper} onDragEnter={onDragEnter}>
          <img
            src={`/assets/images/background/${getBackgroundImageName(
              currentBackgroundImageId
            )}`}
            /*
                Inline styles here to avoid background image jumping
                on first page load.
              */
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
              minWidth: '144em',
              height: '100vh',
              objectFit: 'cover',
              objectPosition: 'left center',
              transform: 'scale(1.1, 1.1)',
              background: 'var(--background-blue)',
            }}
          />
          {
            <Switch>
              <Route
                exact
                path={commonUrls.LOGIN_ROUTE}
                render={(props) => {
                  return <Login {...props} />;
                }}
              />
              <Route
                exact
                path={commonUrls.WATCHER_UNSUBSCRIPTION_ROUTE}
                render={(props) => {
                  return (
                    <WatcherUnsubscription
                      {...props}
                      isLoggedIn={authDataReceived && authenticated}
                    />
                  );
                }}
              />
              <Route
                exact
                path={urls.SIGNUP_ROUTE}
                render={(props) => {
                  return <SignupAdmin {...props} />;
                }}
              />
              <Route
                exact
                path={urls.RESET_PASSWORD_ROUTE}
                render={(props) => {
                  return <CreatePasswordView {...props} />;
                }}
              />
              {authDataReceived && !authenticated && (
                <Route
                  render={({ location }) => {
                    return (
                      <Redirect
                        to={{
                          pathname: commonUrls.LOGIN_ROUTE,
                          state: { from: location },
                        }}
                      />
                    );
                  }}
                />
              )}
              {uiIsReady && (
                <ProtectedRoute
                  path={urls.ROOT_ROUTE}
                  isAllowed={authenticated}
                  render={(props) => {
                    return (
                      <KanbanProvider
                        contextProvider={KanbanContext.Provider}
                        user={user}
                        {...props}
                        dataSourceId={dataSourceId}
                      >
                        <LegacyKanbanContextConsumer>
                          <JobsProvider {...props} atsId={dataSourceId}>
                            <UploadApplicantsProvider {...props}>
                              {isStandaloneAtsUser && !isTrialExpired && (
                                <Dropzone
                                  isVisible={dropZoneVisible}
                                  onDragEnter={onDragEnter}
                                  onDrop={onDrop}
                                  onDragLeave={onDragExit}
                                />
                              )}
                              <Landing {...props}>
                                {(landingProps: {
                                  isLandingMounted: boolean;
                                  hasExpandedSection: boolean;
                                }) => (
                                  <>
                                    <Kanban
                                      isLandingMounted={
                                        landingProps.isLandingMounted
                                      }
                                      {...props}
                                    />
                                    <Switch>
                                      <Route
                                        exact
                                        /*
                                              This allows us to redirect to Kanban (ROOT) from all
                                              non-Kanban related routes. Don't forget
                                              to add new routes to KANBAN_ROUTES, if necessary.
                                            */
                                        path={urls.KANBAN_ROUTES}
                                      >
                                        <FeatureView isCollapsed={false}>
                                          <CanAccess
                                            hasAccess={doesKanbanHaveATSJobs(
                                              user,
                                              isStandaloneAtsUser
                                            )}
                                          >
                                            <Route
                                              exact
                                              path={urls.makeJobUrl(
                                                ':dataSourceId?',
                                                ':id?'
                                              )}
                                              component={(
                                                props: RouteComponentProps<{
                                                  id: string;
                                                  dataSourceId: string;
                                                }>
                                              ) => <JobsSection {...props} />}
                                            />
                                          </CanAccess>
                                          <Route
                                            exact
                                            path={urls.makeDraftUrl(
                                              ':dataSourceId?',
                                              ':jobDescriptionId?'
                                            )}
                                            render={(
                                              props: RouteComponentProps
                                            ) => <DraftSection {...props} />}
                                          />
                                          <Route
                                            exact
                                            path={urls.makeEditInterviewUrl(
                                              ':dataSourceId',
                                              ':jobDescriptionId'
                                            )}
                                            render={(
                                              props: RouteComponentProps<{
                                                jobDescriptionId?: string;
                                              }>
                                            ) => (
                                              <LegacyKanbanContextConsumer>
                                                <EditInterviewProvider
                                                  {...(props as RouteComponentProps<{
                                                    jobDescriptionId?: string;
                                                  }> & {
                                                    contextValue: KanbanContextT;
                                                  })}
                                                >
                                                  <EditInterviewSection
                                                    {...props}
                                                  />
                                                </EditInterviewProvider>
                                              </LegacyKanbanContextConsumer>
                                            )}
                                          />
                                          <CandidateProfileProvider>
                                            <Route
                                              exact
                                              path={makeInterviewUrl(
                                                ':dataSourceId?',
                                                ':jobDescriptionId?'
                                              )}
                                              render={(
                                                props: RouteComponentProps<{
                                                  dataSourceId?: string;
                                                  jobDescriptionId?: string;
                                                }>
                                              ) => (
                                                <InterviewSection {...props} />
                                              )}
                                            />
                                            <Route
                                              path={EMAIL_CONFIG_ROUTES}
                                              render={(
                                                props: RouteComponentProps<EmailConfigSectionT>
                                              ) => (
                                                <EmailConfigurationProvider
                                                  {...(props as RouteComponentProps<EmailConfigSectionT>)}
                                                >
                                                  <EmailConfigSection
                                                    {...props}
                                                  />
                                                </EmailConfigurationProvider>
                                              )}
                                            />
                                          </CandidateProfileProvider>
                                          <Route
                                            exact
                                            path={urls.makeClosedSearchUrl(
                                              ':dataSourceId?',
                                              ':jobDescriptionId?'
                                            )}
                                            render={(
                                              props: RouteComponentProps<{
                                                dataSourceId?: string;
                                                jobDescriptionId?: string;
                                              }>
                                            ) => (
                                              <CandidateProfileProvider>
                                                <ClosedSection {...props} />
                                              </CandidateProfileProvider>
                                            )}
                                          />
                                        </FeatureView>
                                      </Route>
                                      <Redirect to={urls.ROOT_ROUTE} />
                                    </Switch>
                                  </>
                                )}
                              </Landing>
                            </UploadApplicantsProvider>
                          </JobsProvider>
                        </LegacyKanbanContextConsumer>
                      </KanbanProvider>
                    );
                  }}
                />
              )}
            </Switch>
          }
        </div>
      </CacheProvider>
      <AnalyticsTracker userId={customerId} />
    </AppProvider>
  );
};
