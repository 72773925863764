// imports from vendor deps
import React from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
// import {} from 'components';

// imports from 'constants'
// import {} from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'

// imports from styles
import styles from './CriteriaFormDropdownWrapper.css';
import InfiniteScroll from 'react-infinite-scroller';

// component proptypes
type Props = {
  className?: string;
  onClick?: () => void;
  selectProps: {
    children?: any;
    infiniteScrollOptions?: React.ComponentProps<typeof InfiniteScroll>;
  };
};

// exports / component definitions
export const CriteriaFormDropdownWrapper = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(({ children, className = '', selectProps }, ref) => {
  const {
    infiniteScrollOptions,
    infiniteScrollOptions: {
      pageStart = 0,
      loadMore = R.noop,
      hasMore = false,
      loader = null,
      useWindow = false,
      initialLoad = false,
      threshold = 250,
    } = {},
  } = selectProps;

  return (
    <div
      ref={ref}
      className={classNames(styles.criteriaFormDropdownWrapper, className)}
    >
      <InfiniteScroll
        pageStart={pageStart}
        loadMore={loadMore}
        useWindow={useWindow}
        initialLoad={initialLoad}
        threshold={threshold}
        hasMore={!R.isEmpty(infiniteScrollOptions) && hasMore}
        loader={loader}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
});

CriteriaFormDropdownWrapper.defaultProps = {};
