/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum SearchCriteriaAlertType {
  CERTIFICATEMISSED = 'CERTIFICATE_MISSED',
  PROFESSIONALEXPERIENCECLARIFICATION = 'PROFESSIONAL_EXPERIENCE_CLARIFICATION',
  PROFESSIONALEXPERIENCEMISSED = 'PROFESSIONAL_EXPERIENCE_MISSED',
  PROFESSIONALLOWEXPERIENCE = 'PROFESSIONAL_LOW_EXPERIENCE',
  PROFESSIONALHIGHEXPERIENCE = 'PROFESSIONAL_HIGH_EXPERIENCE',
  ITEXPERIENCEMISSED = 'IT_EXPERIENCE_MISSED',
  ITEXPERIENCELOW = 'IT_EXPERIENCE_LOW',
  ITEXPERIENCEHIGH = 'IT_EXPERIENCE_HIGH',
  SKILLMISSED = 'SKILL_MISSED',
  SKILLMISSEDINWORKHISTORY = 'SKILL_MISSED_IN_WORK_HISTORY',
  SKILLEXPERIENCEDISCREPANCY = 'SKILL_EXPERIENCE_DISCREPANCY',
  SKILLRECENTEXPERIENCEMISMATCH = 'SKILL_RECENT_EXPERIENCE_MISMATCH',
  SKILLLOWEXPERIENCE = 'SKILL_LOW_EXPERIENCE',
  SKILLHIGHEXPERIENCE = 'SKILL_HIGH_EXPERIENCE',
  SKILLNOPRACTICALEXPERIENCE = 'SKILL_NO_PRACTICAL_EXPERIENCE',
  SKILLMISSEDDETAILS = 'SKILL_MISSED_DETAILS',
  SKILLNONPRIMARYLANGUAGE = 'SKILL_NON_PRIMARY_LANGUAGE',
  EDUCATIONDEGREEMISSED = 'EDUCATION_DEGREE_MISSED',
  EDUCATIONDEGREEMISMATCH = 'EDUCATION_DEGREE_MISMATCH',
  EDUCATIONGRADUATEYEARMISSED = 'EDUCATION_GRADUATE_YEAR_MISSED',
  EDUCATIONCURRENTYEARGRADUATEDETAILSMISSED = 'EDUCATION_CURRENT_YEAR_GRADUATE_DETAILS_MISSED',
  EDUCATIONGRADUATEFUTUREYEAR = 'EDUCATION_GRADUATE_FUTURE_YEAR',
  EDUCATIONMAJORMISSED = 'EDUCATION_MAJOR_MISSED',
  EDUCATIONMAJORMISMATCH = 'EDUCATION_MAJOR_MISMATCH',
  EDUCATIONINSTITUTIONMISSED = 'EDUCATION_INSTITUTION_MISSED',
  EDUCATIONINSTITUTIONMISMATCH = 'EDUCATION_INSTITUTION_MISMATCH',
  EMPLOYMENTDATESMISSED = 'EMPLOYMENT_DATES_MISSED',
  EMPLOYMENTTITLEMISSED = 'EMPLOYMENT_TITLE_MISSED',
  COMPANYNAMEMISSED = 'COMPANY_NAME_MISSED',
  COMPANYEXPERIENCEEXCLUDEMISMATCH = 'COMPANY_EXPERIENCE_EXCLUDE_MISMATCH',
  COMPANYEXPERIENCEMISSED = 'COMPANY_EXPERIENCE_MISSED',
  COMPANYRECENTEXPERIENCEMISMATCH = 'COMPANY_RECENT_EXPERIENCE_MISMATCH',
  COMPANYHIGHEXPERIENCE = 'COMPANY_HIGH_EXPERIENCE',
  COMPANYLOWEXPERIENCE = 'COMPANY_LOW_EXPERIENCE',
  INDUSTRYEXPERIENCEEXCLUDEMISMATCH = 'INDUSTRY_EXPERIENCE_EXCLUDE_MISMATCH',
  INDUSTRYEXPERIENCEMISSED = 'INDUSTRY_EXPERIENCE_MISSED',
  INDUSTRYRECENTEXPERIENCEMISMATCH = 'INDUSTRY_RECENT_EXPERIENCE_MISMATCH',
  INDUSTRYHIGHEXPERIENCE = 'INDUSTRY_HIGH_EXPERIENCE',
  INDUSTRYLOWEXPERIENCE = 'INDUSTRY_LOW_EXPERIENCE',
  COMPANYSIZEEXPERIENCEEXCLUDEMISMATCH = 'COMPANY_SIZE_EXPERIENCE_EXCLUDE_MISMATCH',
  COMPANYSIZEEXPERIENCEMISSED = 'COMPANY_SIZE_EXPERIENCE_MISSED',
  COMPANYSIZEEXPERIENCEMISSEDUS = 'COMPANY_SIZE_EXPERIENCE_MISSED_US',
  COMPANYSIZERECENTEXPERIENCEMISMATCH = 'COMPANY_SIZE_RECENT_EXPERIENCE_MISMATCH',
  COMPANYSIZEHIGHEXPERIENCE = 'COMPANY_SIZE_HIGH_EXPERIENCE',
  COMPANYSIZELOWEXPERIENCE = 'COMPANY_SIZE_LOW_EXPERIENCE',
  TITLEEXPERIENCEMISSED = 'TITLE_EXPERIENCE_MISSED',
  TITLELOWEXPERIENCE = 'TITLE_LOW_EXPERIENCE',
  TITLEHIGHEXPERIENCE = 'TITLE_HIGH_EXPERIENCE',
  TITLERECENTEXPERIENCEMISMATCH = 'TITLE_RECENT_EXPERIENCE_MISMATCH',
  TITLECATEGORYEXPERIENCEEXCLUDEMISMATCH = 'TITLE_CATEGORY_EXPERIENCE_EXCLUDE_MISMATCH',
  TITLECATEGORYEXPERIENCEMISSED = 'TITLE_CATEGORY_EXPERIENCE_MISSED',
  TITLECATEGORYLOWEXPERIENCE = 'TITLE_CATEGORY_LOW_EXPERIENCE',
  TITLECATEGORYHIGHEXPERIENCE = 'TITLE_CATEGORY_HIGH_EXPERIENCE',
  TITLECATEGORYRECENTEXPERIENCEMISMATCH = 'TITLE_CATEGORY_RECENT_EXPERIENCE_MISMATCH',
  MANAGERIALTITLESCLARIFICATION = 'MANAGERIAL_TITLES_CLARIFICATION',
  MANAGERIALTITLESMISSED = 'MANAGERIAL_TITLES_MISSED',
  MANAGERIALPEOPLEEXPERIENCEMISSED = 'MANAGERIAL_PEOPLE_EXPERIENCE_MISSED',
  MANAGERIALLOWEXPERIENCE = 'MANAGERIAL_LOW_EXPERIENCE',
  MANAGERIALHIGHEXPERIENCE = 'MANAGERIAL_HIGH_EXPERIENCE',
  MANAGERIALRECENTEXPERIENCEMISMATCH = 'MANAGERIAL_RECENT_EXPERIENCE_MISMATCH',
  MANAGERIALPEOPLELOWEXPERIENCE = 'MANAGERIAL_PEOPLE_LOW_EXPERIENCE',
  MANAGERIALPEOPLEHIGHEXPERIENCE = 'MANAGERIAL_PEOPLE_HIGH_EXPERIENCE',
  LOCATIONMISSED = 'LOCATION_MISSED',
  LOCATIONMISSINGCITY = 'LOCATION_MISSING_CITY',
  LOCATIONMISSINGSTATE = 'LOCATION_MISSING_STATE',
  LOCATIONTOOFAR = 'LOCATION_TOO_FAR',
  LOCATIONMISMATCH = 'LOCATION_MISMATCH',
  SKILLEXCLUDEMISMATCH = 'SKILL_EXCLUDE_MISMATCH',
  TITLEEXPERIENCEEXCLUDEMISMATCH = 'TITLE_EXPERIENCE_EXCLUDE_MISMATCH',
  INDUSTRYNAMEMISSED = 'INDUSTRY_NAME_MISSED',
}
