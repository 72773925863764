import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import classNames from 'classnames';
import { Card } from '@air/components';
import styles from './CandidateProfileWarningCard.css';
import cardStyles from '@air/components/Card/Card.css';
import { RedFlagData, isPiplWarning } from '@air/domain/RedFlags';
import { useStateMachine, useDelayedEvent } from '@air/hooks';
import {
  ProfileCriteriaCardActions,
  ProfileCriteriaCardConfig,
  ProfileCriteriaCardState,
} from 'components/SearchCriteriaCards/hooks/ProfileCriteriaCardStateConfig';
import {
  getCardFooter,
  getCardIcon,
  getCardInitialSize,
  getCardStackFooter,
  getCardStackItems,
  getCardStackTitle,
  getCardTitle,
} from 'components/CardWrappers/CandidateProfileWarningCard/candidateProfileWarningCardHelpers';
import { InterviewData } from '@air/domain/InterviewNotes/InterviewData';
import { CandidateInterviewNote } from 'components';
import { cardHasNote } from 'components/CandidateInterviewNote/CandidateInterviewNote';
import { InterviewDataOptions } from 'domain/CandidateData/CandidateInterviewMapper';
import { AlertStatusEnum, RedFlagResolutionEnum } from '@air/api';
import { useOutsideClick } from '@air/utils/hooks';
import { CriteriaInterviewStatus } from 'domain/CandidateData';
import { getRedFlagCategory } from 'domain/SearchCriteria/RedFlagsData';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { GA_LABEL_CANDIDATE_CARD_CHANGE_STATUS } from 'constants/gaLabels';
import { CARD_BACKGROUND_DELAY_DURATION } from '@air/domain/Common/Cards';

export const getRedFlagCardStatus = (
  cardData: RedFlagData
): CriteriaInterviewStatus | AlertStatusEnum => {
  if (cardData.status === AlertStatusEnum.OPENED) {
    return cardHasNote(cardData)
      ? CriteriaInterviewStatus.ANSWERED
      : CriteriaInterviewStatus.ASKED;
  }
  return cardData.status;
};

export const getActionButtonText = (
  cardStatus: AlertStatusEnum | CriteriaInterviewStatus,
  isQuestioningSent: boolean
) => {
  switch (cardStatus) {
    case AlertStatusEnum.DISMISSED:
      return sharedPhrases.RE_APPLY;
    case AlertStatusEnum.WARNING:
      return sharedPhrases.WARNING;
    case AlertStatusEnum.DISQUALIFICATION:
      return sharedPhrases.DISMISS;
    case CriteriaInterviewStatus.ASKED:
      return isQuestioningSent
        ? phrases.WARNING_ASKED_CARD_ACTION_BUTTON_TEXT
        : phrases.WARNING_UNCLARIFIED_CARD_ACTION_BUTTON_TEXT;
    case CriteriaInterviewStatus.ANSWERED:
      return phrases.WARNING_EVALUATE_CARD_ACTION_BUTTON_TEXT;
    default:
      return '';
  }
};

const getCardAttributes = (
  cardData: RedFlagData,
  isQuestioningSent: boolean
) => {
  const cardStatus = getRedFlagCardStatus(cardData);
  const cardResolution = cardData.resolution;

  if (cardStatus === AlertStatusEnum.DISMISSED) {
    return {
      topInfoText: null as string | null,
      actionButtonText: sharedPhrases.RE_APPLY,
      cardClassName: styles.dismissedCard,
      hasBackground: true,
    };
  } else if (cardStatus === AlertStatusEnum.DISQUALIFICATION) {
    return {
      topInfoText: null,
      actionButtonText: getActionButtonText(cardStatus, isQuestioningSent),
      cardClassName: styles.disqualificationResolutionCard,
      hasBackground: true,
    };
  } else if (cardStatus === CriteriaInterviewStatus.ANSWERED) {
    return {
      topInfoText: null,
      actionButtonText: getActionButtonText(cardStatus, isQuestioningSent),
      cardClassName: styles.answeredResolutionCard,
      hasBackground: true,
    };
  } else {
    switch (cardResolution) {
      case RedFlagResolutionEnum.WARNING:
        return {
          topInfoText: null,
          actionButtonText: getActionButtonText(cardStatus, isQuestioningSent),
          cardClassName: styles.warningResolutionCard,
          hasBackground: true,
        };
      case RedFlagResolutionEnum.EXPLANATION:
        return {
          topInfoText: null,
          actionButtonText: getActionButtonText(cardStatus, isQuestioningSent),
          cardClassName: styles.explanationResolutionCard,
          hasBackground: true,
        };
      case RedFlagResolutionEnum.DISQUALIFICATION:
        return {
          topInfoText: null,
          actionButtonText: getActionButtonText(cardStatus, isQuestioningSent),
          cardClassName: styles.disqualificationResolutionCard,
          hasBackground: true,
        };
      default:
        return {
          topInfoText: null,
          actionButtonText: '',
          cardClassName: '',
          hasBackground: false,
        };
    }
  }
};

const EnrichmentAnimationWrapper: React.FC<{ cardData: RedFlagData }> = ({
  cardData,
  children,
}) => {
  const [isAnimationRemoved, removeAnimantion] = useState(false);

  const onMouseEnter = useCallback(
    () => !isAnimationRemoved && removeAnimantion(true),
    [isAnimationRemoved]
  );

  return (
    <div
      className={classNames(styles.animationWrapper, {
        [styles.rippleEffect]: isPiplWarning(cardData) && !isAnimationRemoved,
      })}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </div>
  );
};

export const CandidateProfileWarningCard: React.FC<{
  cardData: RedFlagData;
  isOpened: boolean;
  onOpenCardStack: (id: string | number) => void;
  onCloseCardStack: (id: string | number) => void;
  onOpenNote: (
    interviewData: InterviewData,
    options: InterviewDataOptions
  ) => void;
  onChangeRedFlagStatus: (
    cardRefId: string,
    newStatus: AlertStatusEnum
  ) => void;
  isDisabled?: boolean;
  isQuestioningSent: boolean;
  className?: string;
}> = ({
  cardData,
  isOpened = false,
  onOpenCardStack,
  onCloseCardStack,
  onOpenNote,
  onChangeRedFlagStatus,
  isDisabled = false,
  isQuestioningSent,
  className,
}) => {
  const [isStackOpened, setStackVisibility] = useState(isOpened);
  const [isNoteShown, showNote] = useState(false);
  const [isEvaluateOpened, openEvaluate] = useState(false);
  const [isWarningActionsOpened, openWarningActions] = useState(false);
  const [activeNoteItem, setActiveNoteItem] = useState(null);

  const [cardState, dispatch] = useStateMachine(
    ProfileCriteriaCardConfig,
    ProfileCriteriaCardState.view,
    {
      isReadOnly: isDisabled,
    }
  );
  useEffect(() => {
    if (isDisabled) {
      dispatch(ProfileCriteriaCardActions.showViewReadOnly);
    } else {
      dispatch(ProfileCriteriaCardActions.showView);
    }
  }, [isDisabled, dispatch]);

  const isInReadOnlyState = cardState.matches(
    ProfileCriteriaCardState.viewReadOnly
  );

  const showCollapsedView = useCallback(
    () =>
      isInReadOnlyState
        ? dispatch(ProfileCriteriaCardActions.showViewReadOnly)
        : dispatch(ProfileCriteriaCardActions.showView),
    [dispatch, isInReadOnlyState]
  );
  const expandDetailedView = useCallback(
    () => dispatch(ProfileCriteriaCardActions.showExpanded),
    [dispatch]
  );

  const [delayedExpandDetailedView, delayedShowCollapsedView] = useDelayedEvent(
    expandDetailedView,
    showCollapsedView,
    CARD_BACKGROUND_DELAY_DURATION
  );

  useEffect(() => {
    isStackOpened
      ? onOpenCardStack(cardData.id)
      : onCloseCardStack(cardData.id);
  }, [onOpenCardStack, onCloseCardStack, cardData.id, isStackOpened]);

  const stackItems = getCardStackItems(cardData);
  const stackSize = stackItems.length;

  const onClickOnCollapsedStack = useCallback(() => {
    setStackVisibility(true);
  }, []);

  const warningTitle = getCardTitle(cardData);
  const isCardHovered = cardState.matches(ProfileCriteriaCardState.expanded);
  const isCardHoveredAndAsked =
    isCardHovered || cardData.status === AlertStatusEnum.OPENED;

  const redFlagHasNote = cardHasNote(cardData);

  const { topInfoText, hasBackground, cardClassName, actionButtonText } =
    getCardAttributes(cardData, isQuestioningSent);

  const onClickOnBackgroundActionButton = useCallback(() => {
    if (isInReadOnlyState) return;

    trackEvent({
      category: GACategory.ScreeningCandidateProfile,
      label: GA_LABEL_CANDIDATE_CARD_CHANGE_STATUS,
    });

    if (cardData.status === AlertStatusEnum.WARNING) {
      expandDetailedView();
      setStackVisibility(true);
      openWarningActions(true);
    } else if (cardData.status === AlertStatusEnum.DISQUALIFICATION) {
      onChangeRedFlagStatus(cardData.id, AlertStatusEnum.DISMISSED);
    } else if (cardData.status === AlertStatusEnum.DISMISSED) {
      onChangeRedFlagStatus(cardData.id, AlertStatusEnum.DISQUALIFICATION);
    } else if (
      cardData.status === AlertStatusEnum.OPENED &&
      !isEvaluateOpened
    ) {
      // calling expandDetailedView here to set the expand view immediately
      // otherwise it will be set in 1500 ms and the card will have the wrong bg before that happens
      expandDetailedView();
      openEvaluate(true);
      setStackVisibility(true);
    } else if (cardData.status === AlertStatusEnum.OPENED && isEvaluateOpened) {
      openEvaluate(false);
    }
  }, [
    onChangeRedFlagStatus,
    isEvaluateOpened,
    cardData.status,
    cardData.id,
    expandDetailedView,
    isInReadOnlyState,
  ]);

  const onClickOnChangeStatusButton = useCallback(
    (newStatus: AlertStatusEnum) => {
      openEvaluate(false);
      openWarningActions(false);
      if (newStatus !== cardData.status) {
        onChangeRedFlagStatus(cardData.id, newStatus);
      }
    },
    [onChangeRedFlagStatus, openEvaluate, cardData.status, cardData.id]
  );

  const onClickWarningActionButton = useCallback(
    (status) => {
      onClickOnChangeStatusButton(status);
      setStackVisibility(false);
      showCollapsedView();
    },
    [onClickOnChangeStatusButton, setStackVisibility, showCollapsedView]
  );

  const changeStatusControls = useMemo(() => {
    if (isEvaluateOpened) {
      return (
        <div className={styles.statusControlsWrapper}>
          <button
            type="button"
            className={styles.warningActionButton}
            onClick={() =>
              onClickOnChangeStatusButton(AlertStatusEnum.DISQUALIFICATION)
            }
          >
            {phrases.WARNING_CONFIRM_TEXT}
          </button>
          <button
            type="button"
            className={styles.warningActionButton}
            onClick={() =>
              onClickOnChangeStatusButton(AlertStatusEnum.DISMISSED)
            }
          >
            {phrases.WARNING_DISMISS_TEXT}
          </button>
        </div>
      );
    } else if (isWarningActionsOpened) {
      return (
        <div className={styles.statusControlsWrapper}>
          <button
            type="button"
            className={styles.warningActionButton}
            onClick={() =>
              onClickWarningActionButton(AlertStatusEnum.DISQUALIFICATION)
            }
          >
            {phrases.WARNING_DISQUALIFY_TEXT}
          </button>
          <button
            type="button"
            className={styles.warningActionButton}
            onClick={() =>
              onClickWarningActionButton(AlertStatusEnum.DISMISSED)
            }
          >
            {sharedPhrases.DISMISS}
          </button>
        </div>
      );
    } else {
      return (
        <div className={cardStyles.actionWrapper}>
          <button
            type="button"
            className={classNames(styles.warningActionButton, {
              [styles.askedActionButton]: !redFlagHasNote,
            })}
            onClick={onClickOnBackgroundActionButton}
          >
            {actionButtonText}
          </button>
        </div>
      );
    }
  }, [
    redFlagHasNote,
    isEvaluateOpened,
    onClickWarningActionButton,
    isWarningActionsOpened,
    actionButtonText,
    onClickOnBackgroundActionButton,
    onClickOnChangeStatusButton,
  ]);

  const onNoteClick = useCallback(() => {
    showNote(false);
  }, []);
  const onNoteIconClick = useCallback(() => {
    !isStackOpened && setStackVisibility(true);
    setActiveNoteItem(cardData.interview);
    showNote(true);
    if (!isInReadOnlyState) {
      onOpenNote(cardData.interview, { type: 'RedFlag' });
    }
  }, [isStackOpened, onOpenNote, cardData.interview, isInReadOnlyState]);

  const onOutsideClick = useCallback(() => {
    openEvaluate(false);
    openWarningActions(false);
    setStackVisibility(false);
    showCollapsedView();
  }, [showCollapsedView]);

  const onCriteriaStackClick = () => {
    if (isEvaluateOpened || isWarningActionsOpened) return;
    return setStackVisibility((value) => !value);
  };

  const {
    onBackgroundMouseLeave = undefined,
    onBackgroundMouseOver = undefined,
    onBackgroundActionButtonClick = undefined,
    onCardContentClick = undefined,
    onCardContentMouseOver = undefined,
  } = (() => {
    const hasBackgroundMouseLeave =
      !isEvaluateOpened && !isWarningActionsOpened;
    const hasBackgroundMouseOver = hasBackground && isStackOpened;
    const hasContentClick = !isEvaluateOpened && !isWarningActionsOpened;
    const hasMouseOver = hasBackground;

    if (isInReadOnlyState) {
      // @ts-ignore
      return { onCriteriaStackClick };
    }

    return {
      onBackgroundMouseLeave: hasBackgroundMouseLeave
        ? delayedShowCollapsedView
        : null,
      onBackgroundMouseOver: hasBackgroundMouseOver
        ? delayedExpandDetailedView
        : null,
      onBackgroundActionButtonClick: onClickOnBackgroundActionButton,
      onCardContentClick: hasContentClick
        ? onClickOnBackgroundActionButton
        : null,
      onCardContentMouseOver: hasMouseOver ? delayedExpandDetailedView : null,
      // @ts-ignore
      onCriteriaStackClick,
    };
  })();

  const [outsideClickRef] = useOutsideClick(onOutsideClick, {
    useCapture: true,
  });

  return (
    <Card.ResizeableCardWrapper
      resizeable={!!warningTitle}
      defaultSize={getCardInitialSize(cardData)}
    >
      <Card.SearchCriteriaCardContainer
        ref={outsideClickRef}
        className={classNames(
          styles.warningCardContainer,
          className,
          cardClassName,
          {
            [styles.expandedCard]: cardState.matches(
              ProfileCriteriaCardState.expanded
            ),
          }
        )}
      >
        <Card.SearchCriteriaCardViewBackground
          className={styles.cardViewBackground}
          isVisible={isCardHovered}
          actionButtonLabel={actionButtonText}
          // onMouseLeave={!isEvaluateOpened ? delayedShowCollapsedView : null}
          onMouseLeave={onBackgroundMouseLeave}
          // onMouseOver={hasBackground && isStackOpened ? delayedExpandDetailedView : null}
          onMouseOver={onBackgroundMouseOver}
          topElement={
            isEvaluateOpened ? (
              <div className={styles.cardBackgroundTopText}>{topInfoText}</div>
            ) : null
          }
          bottomElement={changeStatusControls}
          onActionButtonClick={onBackgroundActionButtonClick}
        >
          <Card.SearchCriteriaCardLabel
            onClick={onCriteriaStackClick}
            className={styles.warningCardLabel}
            text={getRedFlagCategory(cardData.type)}
          />
          <EnrichmentAnimationWrapper cardData={cardData}>
            <Card.SearchCriteriaCardContent
              onClick={onCardContentClick}
              onMouseOver={onCardContentMouseOver}
              className={classNames(styles.warningCardContent, cardClassName, {
                [styles.hasNote]: redFlagHasNote,
                [styles.isReadOnly]: isInReadOnlyState,
                [styles.warningShadowBackground]:
                  isEvaluateOpened || isCardHoveredAndAsked,
              })}
            >
              {getCardIcon(cardData)}
              <Card.Alert className={styles.warningCardAlert}>
                {cardData.displayName}
              </Card.Alert>
              {warningTitle}
              {getCardFooter(cardData)}
              {redFlagHasNote && (
                <CandidateInterviewNote
                  isNoteRead={cardData.interview.read}
                  isNoteExpanded={
                    isNoteShown &&
                    activeNoteItem.commentId === cardData.interview.commentId
                  }
                  interviewData={cardData.interview}
                  onNoteClick={onNoteClick}
                  onNoteIconClick={onNoteIconClick}
                />
              )}
            </Card.SearchCriteriaCardContent>

            <Card.SearchCriteriaStack
              isClosed={!isStackOpened || stackSize === 0}
              onClick={onCriteriaStackClick}
              className={classNames({
                [styles.warningShadowBackground]:
                  isEvaluateOpened || isCardHovered,
              })}
              maxHeight={280}
              scrollOverflowElement={
                <div className={styles.warningCardStackOverflowElement} />
              }
              position={Card.SearchCriteriaStack.position.bottom}
            >
              {stackItems.map((stackItem, index: number) => {
                return (
                  <Card.SearchCriteriaStackItem
                    key={index}
                    className={styles.warningCardStackItem}
                    onClick={onCriteriaStackClick}
                  >
                    {getCardStackTitle(cardData, stackItem)}
                    {getCardStackFooter(cardData, stackItem)}
                  </Card.SearchCriteriaStackItem>
                );
              })}
            </Card.SearchCriteriaStack>
            {stackSize > 0 && (
              <Card.SearchCriteriaStackToggle
                isHidden={isStackOpened || isCardHovered}
                onClick={onClickOnCollapsedStack}
                stackSize={stackSize}
              />
            )}
          </EnrichmentAnimationWrapper>
        </Card.SearchCriteriaCardViewBackground>
      </Card.SearchCriteriaCardContainer>
    </Card.ResizeableCardWrapper>
  );
};
CandidateProfileWarningCard.displayName = 'CandidateProfileWarningCard';
