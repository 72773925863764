// imports from vendor deps
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// import {} from 'ramda';
import classNames from 'classnames';
import { toast } from '@air/third-party/toast';
import R from '@air/third-party/ramda';

// imports from types
// import … from 'types';
// imports from context
import { SearchListItemT } from 'domain/Kanban/Kanban';
import {
  useEditInterviewMethods,
  useEditInterviewContext,
} from 'providers/EditInterviewProvider';
import { UploadStatus } from 'context';

// imports from 'components'
import {
  JobDescription,
  NewSearchView,
  UtilitiesControls,
  GenerateReportPanel,
} from 'features';
import { UIText, TooltipWrapper } from '@air/components';
import {
  Button,
  FeatureView,
  RightSidebar,
  SearchBlockScreen,
  UploadsSidebarWrapper,
} from 'components';
import { isDraftSearchValid } from 'features/DraftSection/utils';

// imports from 'constants'
import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';

// import from images
// import {} from 'images'
// imports from helpers
// import {} from 'utils'
// imports from styles
import styles from './EditInterviewSection.css';
import commonStyles from 'styles/commonStyles.css';
import { usePrevious } from '@air/utils/hooks';
import { useUploads } from 'hooks/useUploads';
import { useUploadPanelControl } from 'hooks/useUploadPanelControl';
import { EMPTY_JOB } from 'providers/JobsProvider';
import {
  getCurrentRequisition,
  rejectUploadedToServerFiles,
} from 'utils/uploads';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit, subscribe } from 'hooks/usePubSub';
import { trackEvent } from '@air/utils/ga';
import {
  GA_LABEL_DISCARD_DRAFT,
  GA_LABEL_BACK_TO_SCREENING,
  GA_LABEL_APPLY_CHANGES,
} from 'constants/gaLabels';
import { GACategory } from 'air-shared/domain/Common/GATypes';
import { LineupTabs } from '@air/constants/tabs';
import { useKanbanMethods, useKanbanContext } from 'providers/KanbanProvider';
import { useCustomerProfileContext } from 'providers/CustomerProfileProvider';
import {
  customerProfileSelectors,
  kanbanSelectors,
  appSelectors,
  editInterviewSelectors,
  uploadApplicantsSelectors,
} from 'selectors';
import {
  CLICK_DEBOUNCE_TIME_LONG,
  TOOLTIP_DELAY_TIME_LONG,
} from '@air/constants/app';
import {
  useAppContext,
  useAppMethods,
  RightSidebarPanel,
} from 'providers/AppProvider';
import { SearchProgressStatusEnum } from '@air/api';
import { ButtonVariants } from '@air/components/Button/Button';
import { getTotalCandidatesCountOnLineup } from 'domain/CandidateData/CandidateLineupData';
import {
  useUploadApplicantsContext,
  useUploadApplicantsMethods,
} from 'providers/UploadApplicantsProvider';

// component proptypes

// exports / component definitions
type Props = RouteComponentProps<{
  jobDescriptionId?: string;
}>;

export const EditInterviewSection: React.FC<Props> = ({ match, history }) => {
  const interviewDraftsList = useEditInterviewContext(
    editInterviewSelectors.interviewDraftsList
  );

  const currentInterviewDraft = useEditInterviewContext(
    editInterviewSelectors.currentInterviewDraft
  );

  const {
    fetchInterviewDrafts,
    updateInterviewDraft,
    removeInterviewDraft,
    resetDrafts,
    applyInterviewDraft,
  } = useEditInterviewMethods();

  const jobDescriptionId = match.params?.jobDescriptionId;

  const dataSourceId = useCustomerProfileContext(
    customerProfileSelectors.dataSourceId
  );

  const isStandaloneAtsUser = useCustomerProfileContext(
    customerProfileSelectors.isStandaloneAtsUser
  );
  const isTrialExpired = useCustomerProfileContext(
    customerProfileSelectors.isTrialExpired
  );

  const isImportingCriteria = useKanbanContext(
    kanbanSelectors.isImportingCriteria
  );

  const isLeftSideBarCollapsed = useAppContext(
    appSelectors.isLeftSideBarCollapsed
  );

  const currentSearchStatus = useKanbanContext(
    kanbanSelectors.currentSearchStatus
  );

  const currentSearchId = useKanbanContext(kanbanSelectors.currentSearchId);
  const currentSearch = useKanbanContext(kanbanSelectors.currentSearch);

  const currentMatchMinerSearch = useKanbanContext(
    kanbanSelectors.currentMatchMinerSearch
  );
  const currentMatchScoutSearch = useKanbanContext(
    kanbanSelectors.currentMatchScoutSearch
  );
  const { getSearchConsumerById, fetchSearch, replaceInterview } =
    useKanbanMethods();

  const rightSidebarPanel = useAppContext(appSelectors.rightSidebarPanel);

  const { setRightSidebarPanel } = useAppMethods();

  useEffect(() => {
    if (!match?.isExact) {
      setRightSidebarPanel(null);
    }
  }, [setRightSidebarPanel, match?.isExact]);

  const { setUploadsStarted, removeApplicant, onStartUpload } =
    useUploadApplicantsMethods();

  const requisitions = useUploadApplicantsContext(
    uploadApplicantsSelectors.requisitions
  );
  const uploadToServerState = useUploadApplicantsContext(
    uploadApplicantsSelectors.uploadToServerState
  );

  const jobRequisitionId = currentSearch?.ats?.externalJobDescriptionId;

  const currentRequisition = getCurrentRequisition(
    requisitions,
    jobRequisitionId
  );

  const setUploadsListOpen = useCallback(
    (openState: boolean) => {
      setRightSidebarPanel(openState ? RightSidebarPanel.Uploads : null);
    },
    [setRightSidebarPanel]
  );

  useUploadPanelControl(
    isStandaloneAtsUser,
    RightSidebar.isUploadsOpened(rightSidebarPanel),
    setUploadsListOpen
  );

  useEffect(() => {
    if (RightSidebar.isUploadsOpened(rightSidebarPanel)) {
      setUploadsStarted(jobRequisitionId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightSidebarPanel]);

  const onRemoveApplicant = useCallback(
    (atsCandidateId: string, tempId: string, uploadStatus: UploadStatus) => {
      removeApplicant({
        atsCandidateId,
        tempId,
        uploadStatus,
        atsId: dataSourceId,
        jobRequisitionId,
      });
    },
    [jobRequisitionId, dataSourceId, removeApplicant]
  );
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const previousCurrentSearch = usePrevious<SearchListItemT>(currentSearch);

  useEffect(() => {
    if (
      !currentSearch &&
      dataSourceId &&
      jobDescriptionId &&
      !isApplyingChanges
    ) {
      fetchSearch({
        dataSourceId,
        jobDescriptionId,
      });
    }
  }, [
    isApplyingChanges,
    currentSearch,
    dataSourceId,
    fetchSearch,
    jobDescriptionId,
  ]);

  useEffect(() => {
    // Fetch all available search drafts on opening of "Criteria and Flags screen".
    // If no drafts have been found, create one.
    const previousParentId = previousCurrentSearch?.searchId;
    if (!isApplyingChanges && !previousParentId && currentSearch?.searchId) {
      fetchInterviewDrafts({ createDraftIfEmpty: true });
    }
  }, [
    fetchInterviewDrafts,
    currentSearch,
    previousCurrentSearch,
    resetDrafts,
    isApplyingChanges,
  ]);

  useEffect(() => {
    let unsubscribe: () => void = null;
    if (currentInterviewDraft?.searchId) {
      unsubscribe = getSearchConsumerById(
        currentInterviewDraft.searchId
      ).subscribe(() => fetchInterviewDrafts());
    }
    return () => {
      unsubscribe?.();
    };
  }, [
    fetchInterviewDrafts,
    currentInterviewDraft?.searchId,
    getSearchConsumerById,
  ]);

  useEffect(() => {
    const unsubscribeRefetchInteriewDrafts = subscribe(
      APP_EVENTS.REFETCH_DRAFT,
      async () => {
        await fetchInterviewDrafts();
        // TODO: find better way reset form after draft's been updated from the outside
        setTimeout(() => {
          // reset form to values that we've just fetched with `fetchSearch`
          emit(APP_EVENTS.RESET_SEARCH_FORM);
        }, 0);
      }
    );
    return unsubscribeRefetchInteriewDrafts;
  }, [fetchInterviewDrafts]);

  useEffect(() => {
    return () => setRightSidebarPanel(null);
  }, [setRightSidebarPanel]);

  const applyInterviewDraftCb = useCallback(async () => {
    trackEvent({
      category: GACategory.DraftPage,
      label: GA_LABEL_APPLY_CHANGES,
    });
    setIsApplyingChanges(true);
    const newInterviewDraft = await applyInterviewDraft();
    /*
      Artificial delay between applying updated criteria to the screening search
      and returning to Interview screen, because our *awesome* backend
      is unable to implement microservices properly.
      Without this delay user sees an empty lineup (with a message that no applicants are available)
      for a brief moment of time, because BE has reset all the counters. ¯\_(ツ)_/¯ 
      
      See https://railsreactor.atlassian.net/browse/AR-11547.
      */
    await R.delay(2000);
    resetDrafts();
    replaceInterview(currentSearchId, newInterviewDraft);
    history.replace(
      urls.makeInterviewUrl(
        newInterviewDraft.ats.id,
        newInterviewDraft.ats.externalJobDescriptionId,
        LineupTabs.Active
      )
    );
    emit(APP_EVENTS.JOB_STATUS_CHANGE);
  }, [
    applyInterviewDraft,
    resetDrafts,
    replaceInterview,
    currentSearchId,
    history,
  ]);

  const isInterviewPaused =
    currentSearchStatus === SearchProgressStatusEnum.ONHOLD;
  const isInterviewClosed =
    currentSearchStatus === SearchProgressStatusEnum.CLOSED;

  const discardInterviewDraftCb = useCallback(
    async (item: SearchListItemT) => {
      trackEvent({
        category: GACategory.DraftPage,
        label: GA_LABEL_DISCARD_DRAFT,
      });
      try {
        await removeInterviewDraft(item.searchId);
        history.replace(
          urls[isInterviewClosed ? 'makeClosedSearchUrl' : 'makeInterviewUrl'](
            item.ats?.id,
            item.ats.externalJobDescriptionId,
            LineupTabs.Active
          )
        );
      } catch (e) {
        toast.error(sharedPhrases.GENERAL_ERROR_TRY_AGAIN);
        history.replace(urls.ROOT_ROUTE);
      }
    },
    [history, isInterviewClosed, removeInterviewDraft]
  );

  useUploads({
    currentSearch,
    isUploadsListOpen: RightSidebar.isUploadsOpened(rightSidebarPanel),
    setUploadsListOpen,
    setUploadsStarted,
  });

  const isDraftFormBlocked = useMemo(() => {
    if (!currentInterviewDraft) return false;

    /*
      In case if we're still on optimistic update phase, and we wait
      for actual interview draft to be created on the BE side (while
      we display the data derived from parent search manually),
      we do not allow to make any criteria changes. (AR-8742)
     */
    return currentInterviewDraft.searchId === currentInterviewDraft.parentId;
  }, [currentInterviewDraft]);

  const filteredFiles = useMemo(
    () => rejectUploadedToServerFiles(currentRequisition.files),
    [currentRequisition.files]
  );

  const onBackToScreeningButtonClicked = () => {
    trackEvent({
      category: GACategory.DraftPage,
      label: GA_LABEL_BACK_TO_SCREENING,
    });
    history.push(
      urls[isInterviewClosed ? 'makeClosedSearchUrl' : 'makeInterviewUrl'](
        interviewDraft.ats.id,
        interviewDraft.ats.externalJobDescriptionId,
        LineupTabs.Active
      )
    );
  };

  const onDiscardDraftClicked = () => {
    emit(APP_EVENTS.OPEN_CONFIRMATION_MODAL, {
      text: phrases.CHANGES_REMOVAL_CONFIRMATION,
      confirmLabel: phrases.CONFIRM,
      onConfirm: () => discardInterviewDraftCb(interviewDraft),
    });
  };

  const totalCandidates = getTotalCandidatesCountOnLineup({
    currentSearch,
    currentMatchMinerSearch,
    currentMatchScoutSearch,
  });

  const onSendReport = useCallback(() => {
    setRightSidebarPanel(null);
  }, [setRightSidebarPanel]);

  const [interviewDraft] = interviewDraftsList || [];

  const hasInterviewDraftBeenCreated =
    interviewDraft && interviewDraft.searchId !== currentSearch.searchId;
  const hasInterviewDraftBeenUpdated = interviewDraft
    ? interviewDraft.created !== interviewDraft.updated
    : null;
  const isDraftValid = interviewDraft && isDraftSearchValid(interviewDraft);

  const isApplyChangesDisabled =
    !interviewDraft ||
    isImportingCriteria ||
    isApplyingChanges ||
    !isDraftValid ||
    !hasInterviewDraftBeenCreated ||
    !hasInterviewDraftBeenUpdated ||
    isTrialExpired;

  const isDiscardDisabled =
    isImportingCriteria || !hasInterviewDraftBeenCreated || isTrialExpired;

  const isBackToScreeningDisabled = isImportingCriteria;

  const displayDraftButton =
    hasInterviewDraftBeenUpdated &&
    hasInterviewDraftBeenCreated &&
    !R.isNil(hasInterviewDraftBeenUpdated);

  const isImportCriteriaDisabled =
    isDraftFormBlocked ||
    !currentSearch ||
    isTrialExpired ||
    isImportingCriteria;

  return (
    <div className={styles.editInterviewSection}>
      <FeatureView>
        <FeatureView.LeftSidebar>
          <>
            {interviewDraft && (
              <>
                <UIText
                  tiny
                  bold
                  className={classNames(commonStyles.leftSidebarHeader, {
                    [commonStyles.isCollapsed]: isLeftSideBarCollapsed,
                    [styles.pausedHeader]: isInterviewPaused,
                    [styles.screeningHeader]: !isInterviewPaused,
                  })}
                >
                  {isInterviewPaused
                    ? phrases.PAUSED_SECTION_HEADER
                    : phrases.SCREENING_SECTION_HEADER}
                </UIText>
                <TooltipWrapper
                  delayShow={TOOLTIP_DELAY_TIME_LONG}
                  tooltip={phrases.APPLY_CHANGES_EDIT_INTERVIEW_SECTION_BUTTON}
                  enabled={isLeftSideBarCollapsed}
                  disabledChildren={isApplyChangesDisabled}
                  flexGrow={false}
                >
                  <Button
                    className={commonStyles.leftSidebarButton}
                    variant={Button.variants.POSITIVE_CONFIRM}
                    icon="apply-changes-icon"
                    debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                    disabled={isApplyChangesDisabled}
                    onClick={applyInterviewDraftCb}
                  >
                    {phrases.APPLY_CHANGES_EDIT_INTERVIEW_SECTION_BUTTON}
                  </Button>
                </TooltipWrapper>

                <TooltipWrapper
                  delayShow={TOOLTIP_DELAY_TIME_LONG}
                  tooltip={phrases.IMPORT_CRITERIA_DRAFT_SECTION_BUTTON}
                  enabled={isLeftSideBarCollapsed}
                  disabledChildren={isImportCriteriaDisabled}
                  flexGrow={false}
                >
                  <Button
                    className={classNames(
                      commonStyles.leftSidebarButton,
                      commonStyles.defaultState,
                      styles.importCriteriaButton
                    )}
                    variant={Button.variants.POSITIVE_SECONDARY}
                    icon="import-criteria-icon"
                    debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                    disabled={isImportCriteriaDisabled}
                    onClick={() => emit(APP_EVENTS.IMPORT_CRITERIA)}
                  >
                    {phrases.IMPORT_CRITERIA_DRAFT_SECTION_BUTTON}
                  </Button>
                </TooltipWrapper>
                {displayDraftButton && (
                  <TooltipWrapper
                    delayShow={TOOLTIP_DELAY_TIME_LONG}
                    tooltip={phrases.DISCARD_CHANGES_SEARCH_FORM_BUTTON}
                    enabled={isLeftSideBarCollapsed}
                    disabledChildren={isDiscardDisabled}
                    flexGrow={false}
                  >
                    <Button
                      disabled={isDiscardDisabled}
                      className={classNames(
                        commonStyles.leftSidebarButton,
                        commonStyles.defaultState,
                        commonStyles.negativeState,
                        styles.discardChangesButton
                      )}
                      debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                      icon="discard-draft-icon"
                      variant={ButtonVariants.NEGATIVE_SECONDARY}
                      onClick={onDiscardDraftClicked}
                    >
                      {phrases.DISCARD_CHANGES_SEARCH_FORM_BUTTON}
                    </Button>
                  </TooltipWrapper>
                )}
                <TooltipWrapper
                  delayShow={TOOLTIP_DELAY_TIME_LONG}
                  tooltip={
                    phrases.BACK_TO_SCREENING_EDIT_INTERVIEW_SECTION_BUTTON
                  }
                  enabled={isLeftSideBarCollapsed}
                  disabledChildren={isBackToScreeningDisabled}
                  flexGrow={false}
                >
                  <Button
                    disabled={isBackToScreeningDisabled}
                    className={classNames(
                      commonStyles.leftSidebarButton,
                      commonStyles.defaultState,
                      styles.backButton
                    )}
                    debounceTime={CLICK_DEBOUNCE_TIME_LONG}
                    icon="back-icon"
                    variant={ButtonVariants.POSITIVE_SECONDARY}
                    onClick={onBackToScreeningButtonClicked}
                  >
                    {phrases.BACK_TO_SCREENING_EDIT_INTERVIEW_SECTION_BUTTON}
                  </Button>
                </TooltipWrapper>
              </>
            )}
            <FeatureView.LeftSidebar.Space />
            <UtilitiesControls
              isCollapsed={isLeftSideBarCollapsed}
              gaCategory={GACategory.ScreeningPage}
            />
          </>
        </FeatureView.LeftSidebar>
        <FeatureView.Content className={styles.editInterviewSearchForm}>
          {currentInterviewDraft && (
            <>
              <div className={styles.searchViewWrapper}>
                <NewSearchView
                  key={
                    /*
                    'name' is used as a key here, because at the moment
                    we can only have a single draft per interview,
                    thus it's safe to assume that name is unique key.
                    Also we want to prevent remount of SearchForm when
                    optimistic update is finished, so we can't use searchId here
                    (tempDraft searchId will differ from actual draft's searchId).
                  */
                    currentInterviewDraft.name
                  }
                  item={currentInterviewDraft}
                  onSearchUpdate={updateInterviewDraft}
                  isDraftBlocked={isDraftFormBlocked}
                  isReadOnly={isTrialExpired}
                />
              </div>
              <RightSidebar
                isOpened={rightSidebarPanel !== null}
                closePanel={() => setRightSidebarPanel(null)}
                overlapping={
                  !RightSidebar.isJobDescriptionOpened(rightSidebarPanel)
                }
                className={classNames({
                  [styles.editInterviewJobRequisitionDetailsWrapper]:
                    RightSidebar.isJobDescriptionOpened(rightSidebarPanel),
                })}
              >
                {RightSidebar.isJobDescriptionOpened(rightSidebarPanel) && (
                  <JobDescription
                    highlightKeywords={!isDraftFormBlocked}
                    disabledKeywords={isTrialExpired}
                    className={styles.editInterviewJobRequisitionDetails}
                    job={currentSearch?.jobRequisitionDetails || EMPTY_JOB}
                    criteria={currentInterviewDraft.criteria}
                    isEditable={isStandaloneAtsUser && !isTrialExpired}
                  />
                )}
                {isStandaloneAtsUser &&
                  RightSidebar.isUploadsOpened(rightSidebarPanel) && (
                    <UploadsSidebarWrapper
                      files={filteredFiles}
                      isOpen={RightSidebar.isUploadsOpened(rightSidebarPanel)}
                      pendingFilesCount={currentRequisition.pendingCount}
                      totalFilesCount={currentRequisition.pendingCount}
                      isUploadingToServer={
                        uploadToServerState[jobRequisitionId]
                      }
                      onStartUpload={onStartUpload}
                      onRemoveApplicant={onRemoveApplicant}
                    />
                  )}
                {RightSidebar.isReportOpened(rightSidebarPanel) && (
                  <GenerateReportPanel
                    searchId={currentSearch.searchId}
                    searchName={currentSearch.name}
                    onSend={onSendReport}
                    isEmpty={!totalCandidates}
                  />
                )}
              </RightSidebar>
            </>
          )}
          {(isDraftFormBlocked || !currentSearch) && (
            <SearchBlockScreen
              header={phrases.SEARCH_FORM_BLOCKER_JUST_SECOND}
            />
          )}
          {isImportingCriteria && (
            <SearchBlockScreen
              header={phrases.IMPORT_CRITERIA_BLOCK_SCREEN_HEADER}
              blockMessage={phrases.IMPORT_CRITERIA_BLOCK_SCREEN_TEXT}
            />
          )}
        </FeatureView.Content>
      </FeatureView>
    </div>
  );
};

EditInterviewSection.defaultProps = {};
