/* eslint @typescript-eslint/array-type: 0 */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DictionaryIdentifiableItem } from './';

/**
 *
 * @export
 * @interface InstitutionDetail
 */
export interface InstitutionDetail {
  /**
   *
   * @type {number}
   * @memberof InstitutionDetail
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof InstitutionDetail
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof InstitutionDetail
   */
  type: InstitutionDetailTypeEnum;
  /**
   *
   * @type {Array<DictionaryIdentifiableItem>}
   * @memberof InstitutionDetail
   */
  items?: Array<DictionaryIdentifiableItem>;
}

/**
 * @export
 * @enum {string}
 */
export enum InstitutionDetailTypeEnum {
  Institution = 'Institution',
  InstitutionList = 'InstitutionList',
}
