import {
  Education,
  GraduationStatusEnum,
  SearchCriteriaMatchStatusEnum,
  SearchEducationResponse,
} from '@air/api';
import { AlertData, getAlertData } from '@air/domain/Alerts/AlertData';

export interface GraduationData {
  id: string;
  label: string;
  alert: AlertData;
  status: SearchCriteriaMatchStatusEnum;
  graduationStatus: GraduationStatusEnum;
}

export const getGraduationYear = (education: Education) => {
  const { endDate, graduationStatus } = education || {
    endDate: null,
    graduationStatus: null,
  };

  const graduationYear =
    endDate?.year === 0
      ? new Date().getFullYear().toString()
      : endDate?.year?.toString?.();

  return {
    graduationStatus,
    graduationYear,
  };
};

export const getGraduationData = (
  education: SearchEducationResponse
): GraduationData => {
  const { graduationYear, graduationStatus } = getGraduationYear(
    education.education
  );

  return {
    id: 'graduation',
    label: graduationYear,
    status: education.status,
    alert: getAlertData(education.alerts),
    graduationStatus,
  };
};
