import React, { useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { NoteResponse } from '@air/api';
import * as phrases from 'constants/phrases';
import styles from './CandidateProfileNote.css';

const MAX_PROFILE_NOTE_LENGTH = 1000;
export const CandidateProfileNote: React.FC<{
  className?: string;
  note?: NoteResponse;
  onChange: (value: string) => void;
}> = ({ className, note, onChange }) => {
  const onNoteChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className={classNames(styles.candidateProfileNote, className)}>
      <TextareaAutosize
        placeholder={phrases.CANDIDATE_PROFILE_NOTE_PLACEHOLDER}
        className={styles.candidateProfileNoteInput}
        defaultValue={note?.message}
        maxLength={MAX_PROFILE_NOTE_LENGTH}
        onChange={onNoteChange}
      />
    </div>
  );
};
CandidateProfileNote.displayName = 'CandidateProfileNote';
