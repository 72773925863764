import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import * as phrases from 'constants/phrases';

import styles from './CandidateCriteriaTabs.css';
import { trackEvent } from '@air/utils/ga';
import { GA_LABEL_CRITERIA_TAB } from 'constants/gaLabels';
import { GACategory } from 'air-shared/domain/Common/GATypes';

export enum CriteriaTabs {
  Matching,
  Criteria,
}

export const CriteriaTabControl: React.FC<any> = ({ children }) => {
  const [activeTab, selectCriteriaTab] = useState<CriteriaTabs>(
    CriteriaTabs.Matching
  );

  const onCriteriaTabClicked = useCallback(() => {
    trackEvent({
      category: GACategory.ScreeningCandidateProfile,
      label: GA_LABEL_CRITERIA_TAB,
    });
    selectCriteriaTab(CriteriaTabs.Criteria);
  }, []);

  return (
    <>
      <div className={styles.criteriaTabControl}>
        <button
          className={classNames(styles.criteriaTabControlItem, {
            [styles.active]: activeTab === CriteriaTabs.Matching,
          })}
          type="button"
          onClick={() => selectCriteriaTab(CriteriaTabs.Matching)}
        >
          {phrases.CANDIDATE_PROFILE_CRITERIA_TAB_MATCHING}
        </button>
        /
        <button
          className={classNames(styles.criteriaTabControlItem, {
            [styles.active]: activeTab === CriteriaTabs.Criteria,
          })}
          type="button"
          onClick={onCriteriaTabClicked}
        >
          {phrases.CANDIDATE_PROFILE_CRITERIA_TAB_CRITERIA}
        </button>
      </div>
      {children(activeTab)}
    </>
  );
};
