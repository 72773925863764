import { useState, useCallback, useEffect } from 'react';

import { useOutsideClick } from '@air/utils/hooks';

type useExperienceRangeVisibility = (
  isDropdownOpened?: boolean
) => [
  boolean,
  React.Ref<any>,
  () => void,
  (event: React.KeyboardEvent) => void
];
export const useRangeVisibility: useExperienceRangeVisibility = (
  isDropdownOpened = false
) => {
  const [isDropdownVisible, setDropdownVisibility] = useState(isDropdownOpened);

  useEffect(() => {
    let timeoutId: number;
    if (isDropdownVisible) {
      /* 
      scroll event listener needs to be added only after dropdown appearance,
      cause dropdown increases card height and triggers 'scroll' event.
      bugfix https://railsreactor.atlassian.net/browse/AR-6306
      */
      timeoutId = window.setTimeout(
        () =>
          window.addEventListener(
            'scroll',
            function handler() {
              window.removeEventListener('scroll', handler, true);
              setDropdownVisibility(false);
            },
            true
          ),
        100
      );
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [isDropdownVisible]);

  const [outsideClickRef] = useOutsideClick(
    () => {
      if (isDropdownVisible) {
        setDropdownVisibility(false);
      }
    },
    {
      useCapture: true,
    }
  );

  const onDropdownTriggerClicked = useCallback(() => {
    setDropdownVisibility((isDropdownVisible) => !isDropdownVisible);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setDropdownVisibility((isDropdownVisible) => !isDropdownVisible);
    }
  };

  return [
    isDropdownVisible,
    outsideClickRef,
    onDropdownTriggerClicked,
    handleKeyDown,
  ];
};
