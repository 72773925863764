// imports from vendor deps
import React from 'react';
// import {} from 'ramda';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
// import {} from 'components';

// imports from 'constants'

// import from images
// import {} from 'images'

// imports from helpers
// import {} from 'utils'

// imports from styles
import styles from './KanbanHeader.css';

// component proptypes
type Props = {
  className?: string;
  href?: string;
};

// exports / component definitions
export const KanbanHeader: React.FC<Props> = (props) => {
  const { children, className, href } = props;

  return href ? (
    <Link to={href} className={classNames(styles.kanbanHeader, className)}>
      <h2>{children}</h2>
    </Link>
  ) : (
    <h2 className={classNames(styles.kanbanHeader, className)}>{children}</h2>
  );
};

KanbanHeader.defaultProps = {};
