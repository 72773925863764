import React from 'react';
import { RouteComponentProps } from 'react-router';
import R from '@air/third-party/ramda';
import { toast } from '@air/third-party/toast';

import { ENTITY_NOT_FOUND } from '@air/constants/httpCodes';
import {
  KANBAN_REDIRECT_TIMEOUT,
  REQUEST_PAGING_SIZE_KANBAN,
} from 'constants/app';
import { SEARCH_FILTER, sessionStore } from '@air/domain/WebStorage/webStorage';
import {
  getErrorDescription,
  redirectToRootOnNotFound,
  genericErrorHandler,
} from '@air/utils/errorHandling';
import {
  CustomerProfileContextT,
  FetchSearchParamsT,
  KanbanContext,
  KanbanContextT,
  KanbanStatsExpandedT,
  UpdateSearchStatusInListT,
  SelectInterviewKanbanContext,
} from 'context';
import {
  SearchListItemT,
  CurrentSearchT,
  getJobRequisitionDetails,
  isTempDraftCard,
  KANBAN_SEARCH_GROUPS,
  KanbanFilterT,
  KanbanListT,
  MatchMinerSearchT,
  prepareCurrentSearchInfo,
  MatchScoutSearchT,
} from 'domain/Kanban/Kanban';

import {
  ApiErrorResponse,
  CompanyFullResponse,
  EventType,
  JobDescriptionFullResponse,
  MatchMinerSearchMetadata,
  MatchScoutSearchMetadata,
  NotificationEvent,
  SearchAppliedCountUpdatedPayload,
  SearchCountResponseHolder,
  SearchCountResponseV2,
  SearchCreateRequest,
  SearchExtendedResponse,
  SearchKanbanResponse,
  SearchProgressStatusEnum,
  SearchResponseV2,
  SearchUpdateRequest,
} from '@air/api/models';
import { ApiErrorResponseWithStatusT } from '@air/utils/http';
import { JobSectionItemDragObject } from 'features/JobsSection/JobsSection';
import * as JobsApi from 'features/JobsSection/jobsApi';
import * as SearchApi from 'features/Landing/searchApi';
import * as DraftApi from 'features/DraftSection/draftApi';
import * as InterviewApi from 'features/InterviewSection/interviewApi';
import * as CandidateApi from 'domain/CandidateData/candidateApi';
import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import { ERROR_JOB_DESCRIPTION } from '@air/constants/phrases';

import {
  mapSearchResponseToViewData,
  normalizeDraft,
} from 'features/DraftSection/DraftPreview/SearchDataMapper';
import { SSEConnectionErrorEvent } from '@air/lib/server-notifications/Connection';
import {
  getCandidatesEventFilters,
  getSearchesEventFilters,
  JobDescriptionParsingConsumer,
  SearchCounterConsumer,
} from 'domain/Kanban/ServerSideEventsConsumers';
import {
  aggregateTimeout,
  MessageConsumer,
  MessageConsumerPool,
  registerSSESubscriber,
} from '@air/lib/server-notifications';
import { LineupTabs } from '@air/constants/tabs';
import { useContext, useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from 'air-shared/utils/hooks';
import { KanbanSectionNames } from 'hooks/useUserSettings';
import {
  MatchMinerSetupSettings,
  MatchScoutSetupSettings,
} from 'domain/MatchServices/MatchServices';
import { APP_EVENTS } from 'domain/Kanban/events';
import { emit } from 'hooks/usePubSub';
import { attachContextToReactComponent } from '@air/utils/context';
import { CandidateDataUpdate } from 'domain/CandidateData/CandidateDataServerEvents/CandidateDataServerEvents';
import { isMatchMinerTab } from 'domain/CandidateData/CandidateLineupData';

// MODIFY status will only be available in the searches
// that were created by clicking on Modify Criteria button
// later it will be removed (DISCARD) or become IN_PROGRESS/INTERVIEW
// MODIFY status is not used on front-end at all, in case of modify `modify: true` param is sent to back-end

export enum SearchListTypes {
  STATUS_DRAFTS = 'draftsList',
  STATUS_MODIFY = 'modify',
  STATUS_INTERVIEWS = 'interviewsList',
  STATUS_PAUSED_SEARCHES = 'pausedSearchesList',
  STATUS_CLOSED_SEARCHES = 'closedSearchesList',
}

type stateMapT = {
  [key in SearchProgressStatusEnum]: SearchListTypes;
};
const stateMap: stateMapT = {
  [SearchProgressStatusEnum.DRAFT]: SearchListTypes.STATUS_DRAFTS,
  [SearchProgressStatusEnum.MODIFY]: SearchListTypes.STATUS_MODIFY,
  [SearchProgressStatusEnum.INPROGRESS]: SearchListTypes.STATUS_DRAFTS,
  [SearchProgressStatusEnum.INTERVIEW]: SearchListTypes.STATUS_INTERVIEWS,
  [SearchProgressStatusEnum.ONHOLD]: SearchListTypes.STATUS_PAUSED_SEARCHES,
  [SearchProgressStatusEnum.CLOSED]: SearchListTypes.STATUS_CLOSED_SEARCHES,
};

const mapStatusToStateBranch = (
  status: SearchProgressStatusEnum
): SearchListTypes => {
  return stateMap[status];
};

export type KanbanProviderProps = {
  isSelectMode?: boolean;
  contextProvider: typeof KanbanContext.Provider;
  user: CustomerProfileContextT['user'];
  dataSourceId: number | string;
};
type State = {
  areSearchesLoading: boolean;
  isImportingCriteria: boolean;
  draftsList: KanbanListT<SearchListItemT>;
  modify: KanbanListT<SearchListItemT>;
  interviewsList: KanbanListT<SearchListItemT>;
  pausedSearchesList: KanbanListT<SearchListItemT>;
  closedSearchesList: KanbanListT<SearchListItemT>;
  currentSearch: KanbanContextT['currentSearch'];
  currentMatchMinerSearch: KanbanContextT['currentMatchMinerSearch'];
  isCurrentMatchMinerSearchLoaded: boolean;
  currentMatchScoutSearch: KanbanContextT['currentMatchScoutSearch'];
  isCurrentMatchScoutSearchLoaded: boolean;
  currentSearchFilter?: KanbanFilterT;
  companiesInfo: KanbanContextT['companiesInfo'];
  statsExpanded: KanbanStatsExpandedT;
};

// TODO: this actually holds KanbanCardT except for modify
const emptyListState: KanbanListT<SearchListItemT> = {
  items: [],
  total: 0,
  loaded: false,
};

export const DEFAULT_CURRENT_SEARCH_FILTER = {
  name: '',
  isOwnedByMe: false,
};

export class KanbanProvider extends React.Component<
  KanbanProviderProps & Pick<RouteComponentProps, 'history'>,
  State
> {
  state: State = {
    areSearchesLoading: false,
    draftsList: emptyListState,
    modify: emptyListState,
    interviewsList: emptyListState,
    pausedSearchesList: emptyListState,
    closedSearchesList: emptyListState,
    isImportingCriteria: false,
    currentSearch: null,
    currentMatchMinerSearch: null,
    isCurrentMatchMinerSearchLoaded: false,
    currentMatchScoutSearch: null,
    isCurrentMatchScoutSearchLoaded: false,
    currentSearchFilter: DEFAULT_CURRENT_SEARCH_FILTER,
    companiesInfo: [],
    statsExpanded: {
      [phrases.KANBAN_SECTION_JOBS]: false,
      [phrases.KANBAN_SECTION_DRAFTS]: false,
      [phrases.KANBAN_SECTION_SCREENING]: false,
      [phrases.KANBAN_SECTION_PAUSED]: false,
      [phrases.KANBAN_SECTION_CLOSED]: false,
    },
  };

  /**
   * SSE handlers:
   */
  private sseConsumers: {
    searches: MessageConsumerPool;
    candidates: MessageConsumerPool;
    jobDescription: JobDescriptionParsingConsumer;
    updates: MessageConsumer<
      { payload: SearchAppliedCountUpdatedPayload },
      { payload: SearchAppliedCountUpdatedPayload }[]
    >;
  } = null;
  subscriptions: (() => void)[];

  constructor(props: KanbanProviderProps & RouteComponentProps) {
    super(props);
    const { isSelectMode } = props;

    if (!isSelectMode) {
      const savedSearchFilter = sessionStore.getItem(SEARCH_FILTER);
      if (savedSearchFilter) {
        this.state.currentSearchFilter = savedSearchFilter;
      }

      this.sseConsumers = {
        searches: new MessageConsumerPool(getSearchesEventFilters),
        candidates: new MessageConsumerPool(getCandidatesEventFilters),
        jobDescription: new MessageConsumer(),
        updates: new MessageConsumer(aggregateTimeout(1000)),
      };

      this.subscriptions = [];
      this.subscriptions.push(
        this.sseConsumers.updates.subscribe(this.updateSearchesAppliedCount)
      );

      registerSSESubscriber(this.dispatchHandler.bind(this));
    }
  }

  componentWillUnmount() {
    const { isSelectMode } = this.props;
    if (!isSelectMode) {
      for (const unsubscribe of this.subscriptions) {
        unsubscribe();
      }
    }
  }

  getSearchConsumerById = (searchId: number): SearchCounterConsumer => {
    return this.sseConsumers.searches.getConsumerById(searchId);
  };

  subscribeToCandidatesProcessingSSE = (
    searchId: number,
    callback: (events: CandidateDataUpdate[]) => void
  ): (() => void) => {
    return this.sseConsumers.candidates
      .getConsumerById(searchId)
      .subscribe(callback);
  };

  getJobDescriptionParsingConsumer = (): JobDescriptionParsingConsumer => {
    return this.sseConsumers.jobDescription;
  };

  dispatchHandler = (event: NotificationEvent | SSEConnectionErrorEvent) => {
    if (event.eventType === EventType.JOBDESCRIPTIONPARSINGSTATUS) {
      return this.sseConsumers.jobDescription.onEventReceived(event);
    }
    if (event.eventType === EventType.SEARCHAPPLIEDCOUNTUPDATED) {
      this.sseConsumers.searches
        .getConsumerById(event.payload.searchId)
        .onEventReceived(event);

      this.sseConsumers.candidates
        .getConsumerById(event.payload.searchId)
        .onEventReceived(event);

      this.sseConsumers.updates.onEventReceived(
        event as { payload: SearchAppliedCountUpdatedPayload }
      );

      return;
    }

    if (
      event.eventType === EventType.CANDIDATEPROFILESTATUSUPDATED ||
      event.eventType === EventType.CANDIDATEPROFILESTAGEUPDATED
    ) {
      return this.sseConsumers.candidates
        .getConsumerById(event.payload.searchId)
        .onEventReceived(event);
    }

    if (event.eventType === EventType.SEARCHCANDIDATEPROFILESDROPPED) {
      return this.sseConsumers.candidates
        .getConsumerById(event.payload.searchId)
        .onEventReceived(event);
    }

    if (
      (event.eventType === EventType.MATCHMINERLOADMORERESULT &&
        event.payload.searchId ===
          this.state.currentMatchMinerSearch?.searchId) ||
      (event.eventType === EventType.MATCHMINERLOADMORESTATUSUPDATED &&
        event.payload.mmSearchId ===
          this.state.currentMatchMinerSearch?.searchId)
    ) {
      this.setState((state) => {
        return R.over(
          R.lensProp('currentMatchMinerSearch'),
          (currentMatchMinerSearch) =>
            currentMatchMinerSearch
              ? {
                  ...currentMatchMinerSearch,
                  matchMinerMetadata: {
                    ...currentMatchMinerSearch.matchMinerMetadata,
                    ...event.payload,
                    ...(!R.isNil(event.payload.enabledRequestMore) && {
                      possibleLoadMore: event.payload.enabledRequestMore,
                    }),
                  },
                }
              : currentMatchMinerSearch,
          state
        );
      });
      /*
        TODO: Consider refactoring MM-handling logic from InterviewSection
        and this event in a single place.
        For now, we need to finish displaying loader on lineup if MM search
        didn't return any candidates upon selected criteria.
       */
      if (event.payload.availableCandidates === 0) {
        emit(
          APP_EVENTS.SET_CANDIDATES_LIST_LOADING,
          LineupTabs.MatchMiner,
          false
        );
      }
    }

    if (
      event.eventType === EventType.MATCHMINERSEARCHPROCESSINGSTATEUPDATED &&
      event.payload.searchId === this.state.currentMatchMinerSearch?.searchId
    ) {
      this.setState((state) => {
        return R.over(
          R.lensProp('currentMatchMinerSearch'),
          (currentMatchMinerSearch) =>
            currentMatchMinerSearch
              ? {
                  ...currentMatchMinerSearch,
                  matchMinerProcessingCandidateState:
                    event.payload.matchMinerProcessingCandidateState,
                }
              : currentMatchMinerSearch,
          state
        );
      });
    }
  };

  setStatsExpanded = (sectionName: KanbanSectionNames, value: boolean) => {
    this.setState((state) => ({
      ...state,
      statsExpanded: {
        ...state.statsExpanded,
        [sectionName]: value,
      },
    }));
  };

  /* Drafts feature */
  updateDraftsList = (draftsList: KanbanListT<SearchListItemT>) => {
    this.setState(() => ({
      draftsList,
    }));
  };

  fetchSearchesByStatus = (params: {
    status: SearchProgressStatusEnum[];
    page?: number;
    size?: number;
    withCriteria?: boolean;
    excludeId?: string[];
  }) => {
    const { status, page = 0, size, withCriteria, excludeId } = params;
    const searchListStateBranch = mapStatusToStateBranch(status[0]);
    const { currentSearchFilter, [searchListStateBranch]: currentListState } =
      this.state;

    if (page === 0 && currentListState.items.length > 0) {
      this.setState((state) => ({
        ...state,
        [searchListStateBranch]: {
          items: [],
          total: 0,
          loaded: state[searchListStateBranch].loaded,
        },
      }));
    }

    /*
      TODO: Rewrite size incrementing logic with normal
      paging, when BE implements pointers for correct
      lazy-loading of updated searches.
    */
    return SearchApi.fetchSearchesList({
      status,
      page: 0,
      size: size || (page + 1) * REQUEST_PAGING_SIZE_KANBAN,
      withCriteria,
      excludeId,
      ...currentSearchFilter,
    }).fork(
      () => {},
      (res: KanbanListT<SearchKanbanResponse>) => {
        this.setState((state) => ({
          ...state,
          [searchListStateBranch]: {
            items: res.items,
            /*
              TODO: Don't forget to use this as well, when paging
              is fixed.

              page === 0
                ? res.items
                : [...state[searchListStateBranch].items, ...res.items],
            */
            total: res.total,
            loaded: res.loaded,
          },
        }));
      }
    );
  };

  fetchAllSearches: KanbanContextT['methods']['fetchAllSearches'] = ({
    withCriteria = false,
    excludeId,
  }) => {
    const statuses = [
      KANBAN_SEARCH_GROUPS.Draft,
      KANBAN_SEARCH_GROUPS.Screening,
      KANBAN_SEARCH_GROUPS.Paused,
      KANBAN_SEARCH_GROUPS.Closed,
    ];
    const { currentSearchFilter } = this.state;

    return Promise.all(
      statuses.map((status) =>
        SearchApi.fetchSearchesList({
          status,
          ...currentSearchFilter,
          withCriteria,
          excludeId,
        }).fork(R.identity, R.identity)
      )
    ).catch(() => {
      toast.error(phrases.SEARCHES_FETCHING_ERROR);
    });
  };

  updateAllSearches: KanbanContextT['methods']['updateAllSearches'] = (
    params = {}
  ) => {
    const { withCriteria = false, excludeId } = params;
    this.setState((state) => ({
      areSearchesLoading: true,
      draftsList: {
        ...state.draftsList,
      },
      interviewsList: {
        ...state.interviewsList,
      },
      pausedSearchesList: {
        ...state.pausedSearchesList,
      },
      closedSearchesList: {
        ...state.closedSearchesList,
      },
    }));

    this.fetchAllSearches({ withCriteria, excludeId }).then((res) => {
      if (res) {
        const [
          draftsList,
          interviewsList,
          pausedSearchesList,
          closedSearchesList,
        ] = res;
        this.setState(() => ({
          areSearchesLoading: false,
          draftsList,
          interviewsList,
          pausedSearchesList,
          closedSearchesList,
        }));
      }
    });
  };

  fetchDraftsList = () => {
    const { currentSearchFilter } = this.state;
    SearchApi.fetchSearchesList({
      status: KANBAN_SEARCH_GROUPS.Draft,
      ...currentSearchFilter,
    }).fork(R.identity, this.updateDraftsList);
  };

  // fetch job description and put result into currentSearch
  fetchJobDescriptionForSearch = (
    dataSourceId: number | string,
    jdId: string
  ) => {
    const { currentSearch } = this.state;

    return JobsApi.fetchJobDescription(dataSourceId, jdId).fork(
      genericErrorHandler,
      (res: JobDescriptionFullResponse) => {
        const normalizedJobDescription = {
          ...res,
          description: res?.description?.trim(),
        };
        this.setState(() => ({
          currentSearch: {
            ...currentSearch,
            jobRequisitionDetails: normalizedJobDescription,
          },
        }));
        return Promise.resolve(res);
      }
    );
  };

  updateSearchCandidatesProfileSize = async (
    searchId: number,
    currentTab?: LineupTabs
  ) => {
    if (!searchId) return;

    /*
      This method returns appliedCount for Passive, MatchMiner and MatchScout tabs.
      Counters for different lineup tabs are derived from the following places:
      - Passive tab counters are stored in `passiveCount` field of "parent" search
      - MatchMiner tab counters – `passiveCount` field of associated "MatchMiner" search
      - MatchScout tab counters – `passiveCount` field of associated "MatchScout" search

      For Active tab counters we do NOT use this method. That counters are taken
      from currentSearch.matchedCount.
    */
    return await CandidateApi.fetchCandidatesListSize(searchId).fork(
      () => {
        return 0;
      },
      (response: SearchCountResponseHolder) => {
        let updatedData: {
          counters?: SearchCountResponseV2;
          updatedState?: {
            currentSearch?: KanbanContextT['currentSearch'];
            currentMatchMinerSearch?: KanbanContextT['currentMatchMinerSearch'];
            currentMatchScoutSearch?: KanbanContextT['currentMatchScoutSearch'];
          };
        } = {} as const;

        switch (currentTab) {
          case LineupTabs.Passive:
            updatedData = {
              counters: response.passiveCount,
              updatedState: {
                currentSearch: {
                  ...this.state.currentSearch,
                  ...response,
                },
              },
            };
            break;
          case LineupTabs.MatchMiner:
            updatedData = {
              counters: response.passiveCount,
              updatedState: {
                currentMatchMinerSearch: this.state.currentMatchMinerSearch && {
                  ...this.state.currentMatchMinerSearch,
                  ...response,
                },
              },
            };
            break;
          case LineupTabs.MatchScout:
            updatedData = {
              counters: response.passiveCount,
              updatedState: {
                currentMatchScoutSearch: this.state.currentMatchScoutSearch && {
                  ...this.state.currentMatchScoutSearch,
                  ...response,
                },
              },
            };
            break;
        }
        const { counters, updatedState } = updatedData;
        this.setState((state) => ({
          ...state,
          ...updatedState,
        }));
        return counters;
      }
    );
  };

  fetchMatchServiceSearchesByParentId = async (parentId: number) => {
    /*
      Every started search can potentially have associated MatchMiner and/or MatchScout searches.
      They are requested from a separate endpoint by search's id, when user
      opens candidates lineup page.
    */
    const [matchMinerSearch, matchScoutSearch] = await Promise.allSettled<
      [MatchMinerSearchT, MatchScoutSearchT]
    >([
      InterviewApi.getSearchByParentId({
        parentId,
        isMatchMinerSearch: true,
      }).fork(genericErrorHandler, R.identity),
      InterviewApi.getSearchByParentId({
        parentId,
        isMatchScoutSearch: true,
      }).fork(genericErrorHandler, R.identity),
    ]);

    this.setState((state) => {
      const mmStateSlice = {
        currentMatchMinerSearch:
          matchMinerSearch.status === 'fulfilled' && matchMinerSearch.value
            ? {
                ...state.currentMatchMinerSearch,
                ...matchMinerSearch.value,
              }
            : null,
      };

      const msStateSlice = {
        currentMatchScoutSearch:
          matchScoutSearch.status === 'fulfilled' && matchScoutSearch.value
            ? {
                ...state.currentMatchScoutSearch,
                ...matchScoutSearch.value,
              }
            : null,
      };

      return {
        ...mmStateSlice,
        ...msStateSlice,
        isCurrentMatchMinerSearchLoaded: true,
        isCurrentMatchScoutSearchLoaded: true,
      };
    });

    if (matchMinerSearch.status === 'fulfilled') {
      this.updateSearchCandidatesProfileSize(
        matchMinerSearch.value?.searchId,
        LineupTabs.MatchMiner
      );
    }
    if (matchScoutSearch.status === 'fulfilled') {
      this.updateSearchCandidatesProfileSize(
        matchScoutSearch.value?.searchId,
        LineupTabs.MatchScout
      );
    }
  };

  fetchSearch = async ({
    jobDescriptionId,
    shouldPerformPassiveSearch,
  }: FetchSearchParamsT) => {
    const searchParams = {
      dataSourceId: this.props.dataSourceId,
      jobDescriptionId,
    };

    const result = await SearchApi.fetchSearch(searchParams).fork(
      R.compose(
        (err: ApiErrorResponseWithStatusT) => {
          if (err.status == ENTITY_NOT_FOUND) {
            this.updateAllSearches();
          }
          toast.warn(phrases.ITEM_NOT_FOUND);
        },
        (rej: ApiErrorResponseWithStatusT) => {
          setTimeout(() => {
            redirectToRootOnNotFound(rej, this.props.history);
          }, KANBAN_REDIRECT_TIMEOUT);
          return rej;
        }
      ),
      (res: SearchResponseV2) => {
        /*
          When we refetch any search, it may have updated counters for applicants,
          so we extract this information and update the list manually, to avoid
          extra server requests.
        */
        const searchListStateBranch = mapStatusToStateBranch(res?.status);
        const { currentSearch } = this.state;
        const { [searchListStateBranch]: list } = this.state;
        if (list) {
          const jobRequisitionDetails = getJobRequisitionDetails(
            currentSearch,
            jobDescriptionId
          );

          // @todo: Fix typings for state branches later.
          // @ts-ignore
          this.setState(() => ({
            currentSearch: prepareCurrentSearchInfo(
              currentSearch,
              jobDescriptionId,
              res
            ),
            [searchListStateBranch]: list,
          }));

          /*
            However, if we don't have job requisition details for
            fetched draft yet, we request it conditionally.
            At the same time, this information is secondary,
            so there's no need to postpone state updates
            until details are received.
          */
          if (!jobRequisitionDetails) {
            const {
              ats: {
                id: dataSourceId = '',
                externalJobDescriptionId = '',
              } = {},
            } = res;
            this.fetchJobDescriptionForSearch(
              dataSourceId,
              externalJobDescriptionId
            ).catch(() => {
              toast.error(ERROR_JOB_DESCRIPTION);
            });
          }

          return {
            searchId: res.searchId,
            activeCount: { totalCount: res.appliedCount },
          };
        }
      }
    );
    const searchId = this.state.currentSearch?.searchId;
    if (searchId) {
      // we get currentMatchMinerSearch using parent search id
      // after we currentMatchMinerSearch.searchId
      // we call updateSearchCandidatesProfileSize(currentMatchMinerSearch.searchId) inside fetchMatchMinerSearchByParentId
      // to get appliedCount for currentMatchMinerSearch
      // all other counters are taken from loadCandidateProfile
      // DO NOT use counters currentMatchMinerSearch, they are wrong (from BE)!
      this.fetchMatchServiceSearchesByParentId(searchId);
      if (shouldPerformPassiveSearch) {
        // we use parent search id (activeId from route) to update counters
        // for passive search tab.
        await this.updateSearchCandidatesProfileSize(
          searchId,
          LineupTabs.Passive
        );
      }
    }

    return result;
  };

  /**
   * Standalone ATS methods for creation and updates of requisitions:
   */
  createNewRequisition = (request: SearchCreateRequest) => {
    return DraftApi.createNewRequisition(request).fork(
      genericErrorHandler,
      async (draft: SearchExtendedResponse) => {
        const { draftsList } = this.state;
        this.setState({
          draftsList: {
            items: [
              draft,
              ...draftsList.items.filter((item) => !isTempDraftCard(item)),
            ],
            total: draftsList.total + 1,
            loaded: draftsList.loaded,
          },
          currentSearch: draft,
        });
        return draft;
      }
    );
  };

  createDraft = (jobInfo: JobSectionItemDragObject, withRedirect?: boolean) => {
    const { history, user, dataSourceId } = this.props;

    if (withRedirect) {
      history.push(urls.makeDraftUrl(dataSourceId, urls.ROUTE_PLACEHOLDER), {
        isNew: true,
      });
    }

    const normalizedJobInfo = normalizeDraft(jobInfo);

    return DraftApi.createDraftSearch(normalizedJobInfo).fork(
      genericErrorHandler,
      (res: SearchExtendedResponse) => {
        if (withRedirect) {
          const { jobRequisitionDetails } = normalizedJobInfo;
          this.setState({
            currentSearch: {
              ...res,
              jobRequisitionDetails: {
                ...jobRequisitionDetails,
                creator: R.pick(
                  ['customerId', 'email', 'firstName', 'lastName'],
                  user
                ),
              },
            },
          });
          history.replace(
            urls.makeDraftUrl(res.ats.id, res.ats.externalJobDescriptionId),
            { isNew: true }
          );
        }
      }
    );
  };

  createTempDraft = (tempDraftCard: Pick<SearchListItemT, 'name' | 'ats'>) => {
    const { draftsList } = this.state;

    this.setState({
      draftsList: {
        ...draftsList,
        items: [
          tempDraftCard as SearchListItemT,
          ...draftsList.items.filter((item) => !isTempDraftCard(item)),
        ],
        total: draftsList.total + 1,
      },
    });
  };

  removeTempDraft = () => {
    const { draftsList } = this.state;

    const updatedDraftList = draftsList.items.filter(
      (item) => !isTempDraftCard(item)
    );

    this.setState({
      draftsList: {
        ...draftsList,
        items: updatedDraftList,
        total: updatedDraftList.length,
      },
    });
  };

  updateJobDescription = (descriptionId: string, description: string) => {
    const { currentSearch } = this.state;
    return JobsApi.updateJobDescription(
      currentSearch.ats.id,
      descriptionId,
      description
    ).fork(R.identity, (res: JobDescriptionFullResponse) => {
      this.setState(({ currentSearch }) => ({
        currentSearch: {
          ...currentSearch,
          jobRequisitionDetails: res,
        },
      }));
    });
  };

  requestParseJobDescription = (descriptionId: string) => {
    const { currentSearch } = this.state;
    return JobsApi.requestParseJobDescription(
      currentSearch.ats.id,
      descriptionId
    ).fork(genericErrorHandler, (res: JobDescriptionFullResponse) => {
      this.setState(({ currentSearch, currentMatchMinerSearch }) => ({
        currentMatchMinerSearch,
        currentSearch: {
          ...currentSearch,
          jobRequisitionDetails: res,
        },
      }));
      return Promise.resolve(res);
    });
  };

  updateDraft = async (
    searchId: number,
    values: SearchUpdateRequest,
    fullUpdate?: boolean
  ) =>
    DraftApi.updateDraftSearch(searchId, values, fullUpdate).fork(
      R.compose(
        (err: ApiErrorResponseWithStatusT) => {
          if (err.status == ENTITY_NOT_FOUND) {
            this.updateAllSearches();
          }
          return Promise.reject(err);
        },
        (rej: ApiErrorResponseWithStatusT) =>
          redirectToRootOnNotFound(rej, this.props.history)
      ),
      (updatedDraft: SearchExtendedResponse) => {
        this.updateCurrentSearch(updatedDraft);

        return Promise.resolve(updatedDraft);
      }
    );

  updateSearch = (updatedItem: SearchExtendedResponse) => {
    const updatedSearchId = updatedItem.ats.externalJobDescriptionId;
    const currentListName = mapStatusToStateBranch(updatedItem.status);
    const listToUpdate = this.state[currentListName];

    this.setState((state) => ({
      ...state,
      [currentListName]: {
        ...listToUpdate,
        items: listToUpdate.items.map((item) => {
          if (item.ats.externalJobDescriptionId === updatedSearchId) {
            return { ...updatedItem };
          } else return item;
        }),
      },
    }));
  };

  updateCurrentSearch = (updatedDraft: SearchExtendedResponse) => {
    const { currentSearch, draftsList } = this.state;

    if (!currentSearch || currentSearch.searchId !== updatedDraft.searchId) {
      return this.updateSearch(updatedDraft);
    }

    const { jobRequisitionDetails } = currentSearch;
    const hasCandidates =
      updatedDraft.status !== SearchProgressStatusEnum.DRAFT;
    const updatedCurrentSearch = {
      ...(hasCandidates
        ? mapSearchResponseToViewData(updatedDraft)
        : updatedDraft),
      jobRequisitionDetails,
    };

    this.setState({
      currentSearch: updatedCurrentSearch,
      draftsList: {
        items: R.map<any[], any[]>(
          R.ifElse(
            R.propEq('searchId', updatedDraft.searchId),
            R.always(updatedDraft),
            R.identity
          ),
          draftsList.items
        ),
        total: draftsList.total,
        loaded: draftsList.loaded,
      },
    });
  };

  clearCurrentSearch = () => {
    this.setState({
      currentSearch: null,
      currentMatchMinerSearch: null,
      currentMatchScoutSearch: null,
    });
  };

  removeDraft = (draftItem: SearchListItemT) => {
    const { searchId } = draftItem;

    return DraftApi.removeDraft(searchId).fork(
      R.compose(
        (err: ApiErrorResponseWithStatusT) => {
          if (err.status == ENTITY_NOT_FOUND) {
            this.fetchDraftsList();
          }
          return Promise.reject(err);
        },
        (rej: ApiErrorResponseWithStatusT) =>
          redirectToRootOnNotFound(rej, this.props.history)
      ),
      () => {
        toast.dark(phrases.DRAFT_DISCARDED_TOAST_TEXT);
      }
    );
  };

  fetchCompaniesInfo = async (ids: number[]) => {
    if (ids.length) {
      const resultCompaniesInfo = await SearchApi.fetchCompaniesInfo(ids).fork(
        (reason: any) => {
          return { ...reason };
        },
        (res: { items: CompanyFullResponse[] }) => {
          return res;
        }
      );

      if ('items' in resultCompaniesInfo) {
        this.setState({ companiesInfo: resultCompaniesInfo.items });
      }
    }
  };

  startInterview = async (item: SearchListItemT) => {
    const { draftsList, interviewsList } = this.state;

    /*
      AR-11134: This delay is required because in a scenario
      described in related bug sending of status change request
      (startInterview) happense simultaneously with last update
      of search draft. Because of this, candidates are processed
      with a previous version of search. By delaying this request,
      we allow server to update the search in the database, before
      we change its status.
     */
    await R.delay(500);

    return InterviewApi.startInterview(item.searchId).fork(
      /*
        If an error happens during transition of Draft to
        Interview status, we first redirect user to App root route.
        Then we roll back to previous state for both lists.
      */
      R.compose<
        ApiErrorResponseWithStatusT[],
        ApiErrorResponseWithStatusT,
        Promise<boolean>
      >(
        (err: ApiErrorResponse) => {
          toast.error(getErrorDescription(err, phrases.ERROR_DRAFT_PUBLISHING));
          this.setState({
            draftsList,
            interviewsList,
          });
          return Promise.resolve(false);
        },
        (rej: ApiErrorResponseWithStatusT) =>
          redirectToRootOnNotFound(rej, this.props.history)
      ),
      (res: { status: SearchProgressStatusEnum }) => {
        this.setState((prevState) => {
          const { currentSearch, interviewsList } = prevState;
          const shouldUpdateCurrentSearch =
            currentSearch && item.searchId === currentSearch.searchId;
          return {
            interviewsList: {
              items: interviewsList.items.map((interview) => {
                return interview.searchId === item.searchId
                  ? {
                      ...interview,
                      ...res,
                    }
                  : interview;
              }),
              total: interviewsList.total,
              loaded: interviewsList.loaded,
            },
            ...(shouldUpdateCurrentSearch
              ? {
                  currentSearch: {
                    ...mapSearchResponseToViewData(currentSearch),
                    ...res,
                  },
                }
              : { currentSearch }),
          };
        });
      }
    );
  };

  pauseInterview = (item: SearchListItemT) => {
    const { interviewsList, currentSearch } = this.state;
    const shouldUpdateCurrentInterview =
      currentSearch && item.searchId === currentSearch.searchId;

    const updatedInterview = shouldUpdateCurrentInterview
      ? (item as CurrentSearchT)
      : currentSearch;

    this.setState(({ currentMatchMinerSearch }) => ({
      currentSearch: {
        ...updatedInterview,
        status: SearchProgressStatusEnum.ONHOLD,
      },
      currentMatchMinerSearch,
    }));

    return InterviewApi.pauseInterview(item.searchId).fork(
      /*
      If an error happens during pausing of selected interview,
       we just roll back to previous state.
       */
      (rej: ApiErrorResponseWithStatusT) => {
        toast.error(phrases.ERROR_INTERVIEW_PAUSE);
        this.setState(({ currentMatchMinerSearch }) => ({
          interviewsList,
          currentSearch,
          currentMatchMinerSearch,
        }));
        return redirectToRootOnNotFound(rej, this.props.history);
      },
      () => {
        toast.dark(phrases.KANBAN_MOVE_SCREENING_PAUSED);
      }
    );
  };

  // update search status locally (for optimistic update)
  updateSearchStatusInList = (props: UpdateSearchStatusInListT) => {
    const { item, itemsList, newStatus, listName } = props;
    const updatedList = newStatus
      ? [{ ...item, status: newStatus }, ...itemsList.items]
      : itemsList.items.filter((el) => el.searchId !== item.searchId);

    this.setState((state) => ({
      ...state,
      [listName]: {
        items: updatedList as any,
        total: newStatus ? itemsList.total + 1 : itemsList.total - 1,
        loaded: itemsList.loaded,
      },
    }));
  };

  republishInterview = (item: SearchListItemT) => {
    const { interviewsList, currentSearch } = this.state;
    const shouldUpdateCurrentInterview =
      currentSearch && item.searchId === currentSearch.searchId;

    const updatedInterview = shouldUpdateCurrentInterview
      ? (item as CurrentSearchT)
      : currentSearch;

    this.setState(({ currentMatchMinerSearch }) => ({
      currentSearch: {
        ...updatedInterview,
        status: SearchProgressStatusEnum.INTERVIEW,
      },
      currentMatchMinerSearch,
    }));

    return InterviewApi.startInterview(item.searchId).fork(
      /*
      If an error happens during pausing of selected interview,
       we just roll back to previous state.
       */

      R.compose<
        ApiErrorResponseWithStatusT[],
        ApiErrorResponseWithStatusT,
        Promise<boolean>
      >(
        () => {
          toast.error(phrases.ERROR_INTERVIEW_REPUBLISH);
          this.setState({
            interviewsList,
            currentSearch,
          });
          return Promise.resolve(false);
        },
        (rej: ApiErrorResponseWithStatusT) =>
          redirectToRootOnNotFound(rej, this.props.history)
      ),
      () => {
        toast.dark(phrases.KANBAN_MOVE_SCREENING_RESUMED);
      }
    );
  };

  replaceInterview = (parentId: number, newSearch: CurrentSearchT) => {
    /*
      This method is intended to replace running screening search
      with its draft (with or without criteria modifications).
      When interview draft is applied to replace current interview,
      BE will check if any criteria in draft differ from parent interview,
      and if not newSearch will contain same searchId as its parent,
      meaning that no new search was created, and we can safely reuse
      existing match miner search data and (if necessary) replace currentSearch
      with applied draft.
    */
    const {
      interviewsList,
      currentSearch,
      currentMatchMinerSearch,
      currentMatchScoutSearch,
    } = this.state;

    let updatedStore = {
      interviewsList: {
        items: interviewsList.items.map((interview) =>
          /*
            @TODO: Remove this logic after moving to Search V3
            for Modify Interview.
          * */
          interview.searchId === parentId
            ? {
                ...interview,
                created: newSearch.created,
                updated: newSearch.updated,
                name: newSearch.name,
                creator: newSearch.creator,
                ownedByCurrentUser: newSearch.ownedByCurrentUser,
                searchId: newSearch.searchId,
              }
            : interview
        ),
        total: interviewsList.total,
        loaded: interviewsList.loaded,
      },
      currentSearch,
      currentMatchMinerSearch: null as MatchMinerSearchT,
      currentMatchScoutSearch: null as MatchScoutSearchT,
    };

    if (currentSearch.searchId === parentId) {
      updatedStore = {
        ...updatedStore,
        currentSearch: mapSearchResponseToViewData(newSearch),
        ...(newSearch.searchId === parentId && { currentMatchMinerSearch }),
        ...(newSearch.searchId === parentId && { currentMatchScoutSearch }),
      };
    }

    this.setState(updatedStore);
    this.updateAllSearches();
  };

  closeInterview = (
    item: SearchListItemT
  ): Promise<false | SearchListItemT> => {
    const { searchId } = item;
    const {
      interviewsList,
      currentSearch,
      closedSearchesList,
      currentMatchMinerSearch,
    } = this.state;

    return InterviewApi.closeInterview(searchId).fork(
      (rej: ApiErrorResponseWithStatusT) => {
        toast.error(phrases.ERROR_INTERVIEW_CLOSE);
        this.setState({
          interviewsList,
          currentSearch,
          currentMatchMinerSearch,
          closedSearchesList,
        });
        return redirectToRootOnNotFound(rej, this.props.history);
      },
      (res: SearchResponseV2) => {
        toast.dark(phrases.KANBAN_MOVE_SCREENING_CLOSED);
        const shouldUpdateCurrentSearch =
          currentSearch && searchId === currentSearch.searchId;

        this.setState((prevState) => ({
          closedSearchesList: {
            items: prevState.closedSearchesList.items.map((closedSearch) => {
              return closedSearch.searchId === item.searchId
                ? {
                    ...closedSearch,
                    ...res,
                  }
                : closedSearch;
            }),
            total: prevState.closedSearchesList.total,
            loaded: prevState.closedSearchesList.loaded,
          },
          ...(shouldUpdateCurrentSearch
            ? {
                currentSearch: {
                  ...currentSearch,
                  ...res,
                },
              }
            : { currentSearch }),
        }));
      }
    );
  };

  reopenClosedInterview = (
    item: SearchListItemT
  ): Promise<false | SearchListItemT> => {
    const { interviewsList, closedSearchesList } = this.state;

    return InterviewApi.startInterview(item.searchId).fork(
      /*
        If an error happens during transition of ClosedSearch to
        Interview status, we first redirect user to App root route.
        Then we  roll back to previous state for both lists.
      */
      R.compose<
        ApiErrorResponseWithStatusT[],
        ApiErrorResponseWithStatusT,
        Promise<boolean>
      >(
        () => {
          toast.error(phrases.ERROR_SEARCH_REOPEN);
          this.setState({
            closedSearchesList,
            interviewsList,
          });
          return Promise.resolve(false);
        },
        (rej: ApiErrorResponseWithStatusT) =>
          redirectToRootOnNotFound(rej, this.props.history)
      ),
      (res: { status: SearchProgressStatusEnum }) => {
        toast.dark(phrases.KANBAN_MOVE_SCREENING_RESTARTED);
        this.setState((prevState) => {
          const { currentSearch, interviewsList } = prevState;
          const shouldUpdateCurrentSearch =
            currentSearch && item.searchId === currentSearch.searchId;
          return {
            interviewsList: {
              items: interviewsList.items.map((interview) => {
                return interview.searchId === item.searchId
                  ? {
                      ...interview,
                      ...res,
                    }
                  : interview;
              }),
              total: interviewsList.total,
              loaded: interviewsList.loaded,
            },
            ...(shouldUpdateCurrentSearch
              ? {
                  currentSearch: {
                    ...mapSearchResponseToViewData(currentSearch),
                    ...res,
                  },
                }
              : { currentSearch }),
          };
        });
      }
    );
  };

  duplicateInterview = (searchId: number) => {
    return InterviewApi.duplicateInterview(searchId).fork(
      genericErrorHandler,
      R.identity
    );
  };

  duplicateCriteria: KanbanContextT['methods']['duplicateCriteria'] = (
    sourceInterviewSearchId,
    targetInterview
  ) => {
    const setIsImportingCriteria = (isImporting: boolean) => {
      this.setState(() => ({
        isImportingCriteria: isImporting,
      }));
    };
    setIsImportingCriteria(true);
    return InterviewApi.fetchInterviewById(sourceInterviewSearchId).fork(
      (error: ApiErrorResponse) => {
        setIsImportingCriteria(false);
        return genericErrorHandler(error);
      },
      async (result: SearchExtendedResponse) => {
        return InterviewApi.updateInterviewDraft(targetInterview.searchId, {
          ...targetInterview,
          criteria: result.criteria,
          redFlags: result.redFlags,
          specializations: result.specializations,
        }).fork(
          (error: ApiErrorResponse) => {
            setIsImportingCriteria(false);
            return genericErrorHandler(error);
          },
          () => {
            setIsImportingCriteria(false);
            return Promise.resolve();
          }
        );
      }
    );
  };

  updateSearchFilter: KanbanContextT['methods']['updateSearchFilter'] = ({
    searchFilter,
    shouldFetchSearches = true,
    saveInSessionStore = true,
    withCriteria,
    excludeId,
  }) => {
    const { currentSearchFilter } = this.state;
    const updatedSearchFilter = {
      ...currentSearchFilter,
      ...searchFilter,
    };

    if (R.equals(updatedSearchFilter, currentSearchFilter)) {
      return;
    }

    saveInSessionStore &&
      sessionStore.setItem(SEARCH_FILTER, updatedSearchFilter);
    this.setState(
      {
        currentSearchFilter: updatedSearchFilter,
      },
      () => {
        if (shouldFetchSearches) {
          this.updateAllSearches({ withCriteria, excludeId });
        }
      }
    );
  };

  updateSearchesAppliedCount = (
    events: { payload: SearchAppliedCountUpdatedPayload }[]
  ) => {
    const countersMap = events
      .map(R.prop<string, SearchAppliedCountUpdatedPayload>('payload'))
      .reduce<{ [key: number]: number }>((acc, event) => {
        const { searchId } = event;
        acc[searchId] = event.appliedCount;
        return acc;
      }, {});
    const { draftsList, interviewsList, closedSearchesList } = this.state;
    const [updatedDrafts, updatedInterviews, updatedClosedSearches] = [
      draftsList.items,
      interviewsList.items,
      closedSearchesList.items,
    ].map((list) =>
      list.map((item) => {
        const updatedAppliedCount = countersMap[item.searchId];
        return updatedAppliedCount
          ? {
              ...item,
              activeCount: {
                totalCount: updatedAppliedCount,
                selectedCount: 0,
                matchedCount: 0,
                pendingCount: 0,
                rejectedCount: 0,
                failedCount: 0,
              },
            }
          : item;
      })
    );

    this.setState({
      draftsList: { ...draftsList, items: updatedDrafts },
      interviewsList: {
        ...interviewsList,
        items: updatedInterviews,
      },
      closedSearchesList: {
        ...closedSearchesList,
        items: updatedClosedSearches,
      },
    });
  };

  startMatchMinerSearch = (
    parentSearchId: number,
    settings: MatchMinerSetupSettings
  ) => {
    return InterviewApi.startMatchMinerSearch(parentSearchId, settings).fork(
      genericErrorHandler,
      (res: SearchKanbanResponse) => {
        this.setState((state) => ({
          ...state,
          currentMatchMinerSearch: res,
        }));
      }
    );
  };

  startMatchScoutSearch = (
    parentSearchId: number,
    settings: MatchScoutSetupSettings
  ) => {
    return InterviewApi.startMatchScoutSearch(parentSearchId, settings).fork(
      genericErrorHandler,
      (res: SearchKanbanResponse) => {
        this.setState((state) => ({
          ...state,
          currentMatchScoutSearch: res,
        }));
      }
    );
  };

  restartMatchServiceSearch = (
    searchId: number,
    settings: MatchMinerSetupSettings | MatchScoutSetupSettings,
    currentTab: LineupTabs
  ) => {
    return InterviewApi.restartMatchServiceSearch(
      searchId,
      settings,
      currentTab
    ).fork(genericErrorHandler, (res: SearchKanbanResponse) => {
      this.setState((state) => {
        const nextState = isMatchMinerTab(currentTab)
          ? {
              currentMatchMinerSearch: res,
              currentMatchScoutSearch: state.currentMatchScoutSearch,
            }
          : {
              currentMatchScoutSearch: res,
              currentMatchMinerSearch: state.currentMatchMinerSearch,
            };
        return nextState;
      });
    });
  };

  loadMoreApplicants = (
    searchId: number,
    limit: number,
    currentTab: LineupTabs
  ) => {
    return InterviewApi.loadMoreApplicants(searchId, limit, currentTab).fork(
      genericErrorHandler,
      (res: MatchMinerSearchMetadata | MatchScoutSearchMetadata) => {
        let nextState;

        switch (currentTab) {
          case LineupTabs.MatchMiner:
            nextState = {
              currentMatchMinerSearch: {
                ...this.state.currentMatchMinerSearch,
                matchMinerMetadata: res as MatchMinerSearchMetadata,
              },
            };
            break;
          case LineupTabs.MatchScout:
            nextState = {
              currentMatchScoutSearch: {
                ...this.state.currentMatchScoutSearch,
                matchScoutMetadata: res as MatchScoutSearchMetadata,
              },
            };
            break;
          default:
            nextState = this.state;
        }
        /*
          For some reason TS doesn't accept this partial state as next value.
        */
        this.setState(nextState as unknown as State);
      }
    );
  };

  getContext = (): KanbanContextT => {
    const {
      areSearchesLoading,
      isImportingCriteria,
      draftsList,
      interviewsList,
      currentSearch,
      currentMatchMinerSearch,
      currentMatchScoutSearch,
      pausedSearchesList,
      closedSearchesList,
      currentSearchFilter,
      companiesInfo,
      statsExpanded,
      isCurrentMatchMinerSearchLoaded,
      isCurrentMatchScoutSearchLoaded,
    } = this.state;

    return {
      areSearchesLoading,
      isImportingCriteria,
      draftsList,
      interviewsList,
      pausedSearchesList,
      closedSearchesList,
      currentSearchFilter,
      currentSearch,
      currentMatchMinerSearch,
      currentMatchScoutSearch,
      isCurrentMatchMinerSearchLoaded,
      companiesInfo,
      statsExpanded,
      isCurrentMatchScoutSearchLoaded,
      methods: {
        setStatsExpanded: this.setStatsExpanded,
        clearCurrentSearch: this.clearCurrentSearch,
        createNewRequisition: this.createNewRequisition,
        updateSearchStatusInList: this.updateSearchStatusInList,
        createDraft: this.createDraft,
        updateDraft: this.updateDraft,
        removeDraft: this.removeDraft,
        fetchAllSearches: this.fetchAllSearches,
        updateAllSearches: this.updateAllSearches,
        fetchSearchesByStatus: this.fetchSearchesByStatus,
        fetchSearch: this.fetchSearch,
        updateSearchCandidatesProfileSize:
          this.updateSearchCandidatesProfileSize,
        fetchCompaniesInfo: this.fetchCompaniesInfo,
        startInterview: this.startInterview,
        pauseInterview: this.pauseInterview,
        republishInterview: this.republishInterview,
        replaceInterview: this.replaceInterview,
        closeInterview: this.closeInterview,
        duplicateInterview: this.duplicateInterview,
        duplicateCriteria: this.duplicateCriteria,
        reopenClosedInterview: this.reopenClosedInterview,
        updateSearchFilter: this.updateSearchFilter,
        updateJobDescription: this.updateJobDescription,
        requestParseJobDescription: this.requestParseJobDescription,
        fetchJobDescriptionForSearch: this.fetchJobDescriptionForSearch,
        startMatchMinerSearch: this.startMatchMinerSearch,
        startMatchScoutSearch: this.startMatchScoutSearch,
        restartMatchServiceSearch: this.restartMatchServiceSearch,
        loadMoreApplicantsForMatchService: this.loadMoreApplicants,
        createTempDraftCard: this.createTempDraft,
        removeTempDraftCard: this.removeTempDraft,

        // SSE Handlers:
        getSearchConsumerById: this.getSearchConsumerById,
        subscribeToCandidatesProcessingSSE:
          this.subscribeToCandidatesProcessingSSE,
        getJobDescriptionParsingConsumer: this.getJobDescriptionParsingConsumer,
      },
    };
  };

  render() {
    const { children, contextProvider: ContextProvider } = this.props;

    return (
      <ContextProvider value={this.getContext()}>{children}</ContextProvider>
    );
  }
}

/* @deprecated
 * This is a custom consumer that connects our context from `use-context-selector` with
 * the class components that don't have hooks. Please don't use it with
 * Functional Components since it will be deleted in the future. Use `useContext` instead
 *  */
export const LegacyKanbanContextConsumer = ({
  children,
}: {
  children: unknown;
}) => attachContextToReactComponent(children, useContext(KanbanContext));

export const useKanbanContext = <Selected,>(
  selector: (state: KanbanContextT) => Selected
) => {
  return useContextSelector(KanbanContext, selector);
};

export const useKanbanMethods = () => {
  return useEqualContextSelector(
    KanbanContext,
    (state: KanbanContextT) => state.methods,
    R.shallowEqualObjects
  );
};

export const useSelectInterviewKanbanContext = <Selected,>(
  selector: (state: KanbanContextT) => Selected
) => {
  return useContextSelector(SelectInterviewKanbanContext, selector);
};

export const useSelectInterviewKanbanMethods = () => {
  return useEqualContextSelector(
    SelectInterviewKanbanContext,
    (state: KanbanContextT) => state.methods,
    R.shallowEqualObjects
  );
};
