import React, { useEffect, useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from '@air/third-party/toast';
import classNames from 'classnames';
import { AutoSizeInput, TooltipWrapper } from '@air/components';
import { useOutsideClick } from '@air/utils/hooks';
import * as phrases from 'constants/phrases';
import { CandidateProfileContactFormT } from 'domain/CandidateData';
import { trackEvent } from '@air/utils/ga';
import { GACategory } from '@air/domain/Common/GATypes';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';
import {
  GA_LABEL_EDIT_FIRST_NAME,
  GA_LABEL_EDIT_LAST_NAME,
} from 'constants/gaLabels';
import styles from 'components/CandidateProfile/CandidateName/CandidateName.css';

const CONTACTS_TYPE_FIRST_NAME = 'firstName';
const CONTACTS_TYPE_LAST_NAME = 'lastName';

type CandidateFirstLastNameButtonProps = {
  setEditMode: () => void;
  isReadOnly: boolean;
  content: string;
  tooltip: string;
  className?: string;
};

const AMOUNT_OF_CHARS_TO_SAVE = 35;

const getTrimmedName = (name: string) => {
  return name ? name.slice(0, AMOUNT_OF_CHARS_TO_SAVE) : '';
};

const CandidateFirstLastNameButton: React.FC<
  CandidateFirstLastNameButtonProps
> = ({ setEditMode, isReadOnly, tooltip, content, className }) => {
  return (
    <button
      type="button"
      className={classNames(styles.unstyledButton, className)}
      onClick={setEditMode}
      disabled={isReadOnly}
    >
      <TooltipWrapper
        enabled
        delayShow={TOOLTIP_DELAY_TIME_LONG}
        placement="top"
        trigger="hover"
        tooltip={tooltip}
        // this property prevents tooltip from hiding immediately after showing on panel with `position: fixed`
        closeOnReferenceHidden={false}
        containerClassName={styles.tooltipMaxSize}
      >
        <div
          className={classNames(styles.nameWrapper, {
            [styles.isEditable]: !isReadOnly,
          })}
        >
          <span className={styles.name}>{content}</span>
        </div>
      </TooltipWrapper>
    </button>
  );
};

type CandidateNameT = {
  firstName: string;
  lastName: string;
  isReadOnly?: boolean; // has access rights to edit
  saveChanges: (formValues: CandidateProfileContactFormT) => void;
  profileId: string;
  activeTab: string;
  className?: string;
};

export const CandidateName: React.FC<CandidateNameT> = ({
  firstName,
  lastName,
  isReadOnly,
  saveChanges,
  profileId,
  activeTab,
  className,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  // If candidate's name was not parsed, we still need to initialize the profile
  // with default values, for customer to be able to change those.
  if (!firstName && !lastName) {
    firstName = phrases.CANDIDATE_PROFILE_EMPTY_FIRST_NAME;
    lastName = phrases.CANDIDATE_PROFILE_EMPTY_LAST_NAME;
  }

  const { register, handleSubmit, formState, reset, getValues, control } =
    useForm({
      defaultValues: { firstName, lastName },
      shouldUnregister: true,
    });
  const formWatcher = useWatch({ control });

  useEffect(() => {
    if (!isEditMode) {
      reset({ firstName, lastName });
    }
  }, [firstName, lastName, reset, isEditMode]);

  useEffect(() => {
    if (profileId || activeTab) {
      setEditMode(false);
    }
  }, [profileId, activeTab]);

  const trackFormChanges = useCallback(
    (values: CandidateProfileContactFormT) => {
      // if both first name and last name were changed 2 events must be sent
      if (values.firstName !== firstName) {
        trackEvent({
          category: GACategory.ScreeningCandidateProfile,
          label: GA_LABEL_EDIT_FIRST_NAME,
        });
      }
      if (values.lastName !== lastName) {
        trackEvent({
          category: GACategory.ScreeningCandidateProfile,
          label: GA_LABEL_EDIT_LAST_NAME,
        });
      }
    },
    [firstName, lastName]
  );

  const onSubmit = useCallback(
    async (formData: CandidateProfileContactFormT) => {
      const values = getValues();

      // TODO: dirty hack to check if the form is dirty
      // for some reason when the form is changed for the first time isDirty is false
      // comparing the fields manually to fix this
      if (
        formState.isDirty ||
        values.firstName !== firstName ||
        values.lastName !== lastName
      ) {
        try {
          const params = {
            firstName: getTrimmedName(formData.firstName) || firstName,
            lastName: getTrimmedName(formData.lastName) || lastName,
          };
          trackFormChanges(values);
          await saveChanges(params);
        } catch (error) {
          toast.error(phrases.SOMETHING_WENT_WRONG_ERROR);
        }
      }
      setEditMode(false);
    },
    [firstName, lastName, formState, saveChanges, getValues, trackFormChanges]
  );

  const onOutsideClick = useCallback(async () => {
    await handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  const [outsideClickRef] = useOutsideClick(onOutsideClick, {
    useCapture: true,
  });

  return (
    <div
      className={classNames(className, styles.candidateName, {
        [styles.isEditable]: !isReadOnly,
      })}
    >
      {isEditMode && !isReadOnly ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={outsideClickRef}
          className={styles.candidateNameForm}
        >
          <TooltipWrapper
            enabled
            placement="top"
            trigger="hover"
            tooltip={phrases.CANDIDATE_PROFILE_HEADER_FIRST_NAME_TOOLTIP}
            triggerClassName={styles.tooltip}
            // this property prevents tooltip from hiding immediately after showing on panel with `position: fixed`
            closeOnReferenceHidden={false}
          >
            <AutoSizeInput
              placeholder={
                phrases.CANDIDATE_PROFILE_CONTACTS_FIRST_NAME_PLACEHOLDER
              }
              autoComplete="off"
              className={styles.editableField}
              containerClassName={styles.inputContainer}
              {...register(CONTACTS_TYPE_FIRST_NAME)}
              value={formWatcher.firstName}
              onKeyDown={onKeyDown}
            />
          </TooltipWrapper>

          <TooltipWrapper
            enabled
            placement="top"
            trigger="hover"
            tooltip={phrases.CANDIDATE_PROFILE_HEADER_LAST_NAME_TOOLTIP}
            triggerClassName={classNames(styles.tooltip, styles.lastNameIndent)}
            // this property prevents tooltip from hiding immediately after showing on panel with `position: fixed`
            closeOnReferenceHidden={false}
          >
            <AutoSizeInput
              placeholder={
                phrases.CANDIDATE_PROFILE_CONTACTS_LAST_NAME_PLACEHOLDER
              }
              autoComplete="off"
              className={styles.editableField}
              containerClassName={styles.inputContainer}
              {...register(CONTACTS_TYPE_LAST_NAME)}
              value={formWatcher.lastName}
              onKeyDown={onKeyDown}
            />
          </TooltipWrapper>
        </form>
      ) : (
        <h2
          className={styles.candidateNameHeader}
          data-testid="candidate-name-surname"
        >
          <CandidateFirstLastNameButton
            setEditMode={() => setEditMode(true)}
            isReadOnly={isReadOnly}
            tooltip={`${phrases.CANDIDATE_PROFILE_CONTACTS_FIRST_NAME_PLACEHOLDER}: ${firstName}`}
            content={firstName}
          />
          <CandidateFirstLastNameButton
            setEditMode={() => setEditMode(true)}
            isReadOnly={isReadOnly}
            tooltip={`${phrases.CANDIDATE_PROFILE_CONTACTS_LAST_NAME_PLACEHOLDER}: ${lastName}`}
            content={lastName}
            className={styles.lastNameIndent}
          />
        </h2>
      )}
    </div>
  );
};
CandidateName.displayName = 'CandidateName';
