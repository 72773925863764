import React from 'react';
import classNames from 'classnames';
import R from '@air/third-party/ramda';

import styles from './LoadingDocumentSkeleton.css';

const PARAGRAPHS = R.range(1, 10);

export const LoadingDocumentSkeleton: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={classNames(styles.loadingDocumentSkeleton, className)}>
      <div className={classNames(styles.line, styles.fakeHeaderFirstLine)} />
      <div className={classNames(styles.line, styles.fakeHeaderSecondLine)} />
      {PARAGRAPHS.map((el, idx) => (
        <div
          key={idx}
          className={classNames(styles.line, styles.fakeParagraph)}
        />
      ))}
    </div>
  );
};
LoadingDocumentSkeleton.displayName = 'LoadingDocumentSkeleton';
