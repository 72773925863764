// imports from vendor deps
import React, { useCallback, useMemo } from 'react';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

// imports from types
// import … from 'types';

// imports from 'components'
import { RangeDropdown, TooltipWrapper } from '@air/components';

// imports from 'constants';
import * as phrases from '@air/constants/phrases';

// import from images
// import {} from 'images'

// imports from helpers
import {
  generateItemsForRangeDropdown,
  RangeIndex,
  MIN_DISTANCE_VALUE,
  MAX_DISTANCE_VALUE,
  DISTANCE_VALUE_STEP,
} from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import { useRangeVisibility } from '@air/components/SelectRangeWidgets/useRangeVisibility';

// imports from styles
import styles from './MaxDistanceWidget.css';

import { RangeValue } from '@air/components/SelectRangeWidgets/rangeWidgetHelpers';
import { RangeItem } from '@air/components/RangeDropdown/RangeDropdown';
import { TOOLTIP_DELAY_TIME_LONG } from '@air/constants/app';

// const
const MIN_DISTANCE_INDEX = 0;

const getDistanceRange = (min: number, max: number) => {
  const range = [];
  for (let i = min; i <= max; i = i + DISTANCE_VALUE_STEP) {
    range.push(i);
  }
  return range;
};

const distanceRange = getDistanceRange(MIN_DISTANCE_VALUE, MAX_DISTANCE_VALUE);

const dropdownItems = [
  ...generateItemsForRangeDropdown(distanceRange),
] as RangeValue[];

const getIndexByValue = (value: number, items: RangeItem[]) =>
  R.findIndex(R.propEq('value', value))(items);

// component proptypes
type Props = {
  value: number;
  onChange?: (value: number) => void;
  className?: string;
  isReadOnly?: boolean;
};

type FieldProps = {
  value: number | null;
  className?: string;
  onClick?: () => void;
};

// exports / component definitions
export const MaxDistanceField: React.FC<FieldProps> = ({
  value,
  className = '',
  onClick = R.always,
}) => {
  const isNothingSelected = R.isNullOrEmpty(value);

  return (
    <TooltipWrapper
      delayShow={TOOLTIP_DELAY_TIME_LONG}
      enabled
      placement="top"
      trigger="hover"
      containerClassName={styles.maxDistanceTooltip}
      tooltip={phrases.MAX_DISTANCE_TOOLTIP_TEXT}
    >
      <div
        className={classNames(styles.maxDistanceField, className)}
        onClick={onClick}
      >
        {!isNothingSelected && (
          <span
            tabIndex={0}
            className={styles.maxDistanceFieldValue}
            onFocus={onClick}
            onMouseDown={(event: React.MouseEvent) => event.preventDefault()}
          >
            +{value}
          </span>
        )}

        {value >= MIN_DISTANCE_VALUE ? (
          <label className={styles.maxDistanceFieldLabel}>
            {phrases.MAX_DISTANCE_SELECTED_VALUE_LABEL}
          </label>
        ) : (
          <label className={styles.maxDistanceNothingSelectedLabel}>
            {phrases.MAX_DISTANCE_NOTHING_SELECTED_LABEL[0]}{' '}
            <span>{phrases.MAX_DISTANCE_NOTHING_SELECTED_LABEL[1]}</span>
          </label>
        )}
      </div>
    </TooltipWrapper>
  );
};

type DropdownProps = {
  isOpen: boolean;
  dropdownItems: RangeItem[];
  value: number;
  onChange: (value: number) => void;
};

// exports / component definitions
const MaxDistanceDropdown: React.FC<DropdownProps> = ({
  isOpen,
  onChange,
  dropdownItems,
  value,
}) => {
  const selectedIndex = useMemo(() => {
    return getIndexByValue(value, dropdownItems);
  }, [value, dropdownItems]);

  const selectedItemsIndexes = useMemo(() => {
    if (R.isNil(value)) {
      return [];
    }
    return R.range(MIN_DISTANCE_INDEX, selectedIndex + 1);
  }, [value, selectedIndex]);

  const isNothingSelected = (value: number | null) => R.isNullOrEmpty(value);

  const getRangeItemClass = useCallback(
    (index: RangeIndex) => {
      return {
        [styles.rangeItem]: true,
        [styles.selectedRangeItem]:
          R.includes(index, selectedItemsIndexes) && !R.isNil(value),
        [styles.selectedFirstRangeItem]:
          index === MIN_DISTANCE_INDEX && !R.isNil(value),
        [styles.selectedLastRangeItem]:
          index === selectedIndex && !R.isNil(value),
      };
    },
    [value, selectedIndex, selectedItemsIndexes]
  );

  const getDropdownClass = useCallback(() => {
    return {
      [styles.maxDistanceDropdown]: true,
      [styles.nothingSelected]: isNothingSelected(value),
    };
  }, [value]);

  const onItemSelected = useCallback(
    (index: RangeIndex) => {
      onChange(index);
    },
    [onChange]
  );

  return (
    <RangeDropdown
      isOpen={isOpen}
      dropdownClass={getDropdownClass}
      rangeItems={dropdownItems}
      rangeItemClass={getRangeItemClass}
      arrowClassName={styles.dropdownArrow}
      selectedItems={selectedItemsIndexes}
      onItemSelected={onItemSelected}
    />
  );
};

export const MaxDistanceReadOnlyWidget: React.FC<{
  value: number | null;
}> = ({ value }) => {
  return (
    <div className={styles.maxDistanceWidget}>
      <MaxDistanceField
        className={styles.maxDistanceReadonlyField}
        value={value}
      />
    </div>
  );
};
MaxDistanceReadOnlyWidget.displayName = 'MaxDistanceReadOnlyWidget';

// exports / component definitions
export const MaxDistanceWidget: React.FC<Props> = ({
  value,
  onChange,
  className,
  isReadOnly = false,
}) => {
  const [
    isDropdownVisible,
    outsideClickRef,
    onDropdownTriggerClicked,
    handleKeyDown,
  ] = useRangeVisibility();

  const getValueByIndex = (index: RangeIndex) => dropdownItems[index].value;

  const onChangeDropdownValues = (index: RangeIndex) => {
    const prevSelectedIndex = getIndexByValue(value, dropdownItems);

    const newSelectedValue =
      index === prevSelectedIndex ? null : getValueByIndex(index);

    onChange(newSelectedValue);
  };

  return isReadOnly ? (
    <MaxDistanceReadOnlyWidget value={value} />
  ) : (
    <div
      className={classNames(styles.maxDistanceWidget, {
        [styles.isDropdownOpened]: isDropdownVisible,
        className,
      })}
      ref={outsideClickRef}
      onKeyDownCapture={handleKeyDown}
    >
      <MaxDistanceField value={value} onClick={onDropdownTriggerClicked} />
      <MaxDistanceDropdown
        dropdownItems={dropdownItems}
        value={value}
        onChange={onChangeDropdownValues}
        isOpen={isDropdownVisible}
      />
    </div>
  );
};
