import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import { SvgIcon } from '@air/components';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Toast.css';

const TOAST_VISIBILITY_DURATION = 3000;
const TOAST_QUANTITY_LIMIT = 3; // per AR-5430

const CloseButton = ({ closeToast }: { closeToast: () => void }) => {
  return (
    <button type="button" className={styles.closeButton} onClick={closeToast}>
      <SvgIcon className={styles.closeIcon} icon="close-icon" />
    </button>
  );
};

export const Toast = () => {
  return (
    <ToastContainer
      limit={TOAST_QUANTITY_LIMIT}
      hideProgressBar
      position="bottom-center"
      draggable={false}
      transition={Zoom}
      autoClose={TOAST_VISIBILITY_DURATION}
      closeButton={CloseButton}
    />
  );
};
Toast.displayName = 'Toast';
