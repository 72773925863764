import EventSource from 'eventsource';
import { NotificationEvent } from '@air/api';

export type InitParams = { url: string; headers: any };
export type SSEConnectionErrorEvent = {
  eventType: 'error';
  payload: {
    errorCode?: number;
    message?: string;
  };
};

export interface ConnectionInterface<T, S> {
  onError(error: S): void;
  onClose(data: any): void;
  onMessage?: (data: T) => void;
  subscribe(cb: (data: T) => void): void;
  unsubscribe(): void;
}

class EventSourceConnection implements ConnectionInterface<any, any> {
  private eventSource: EventSource;
  onMessage: (data: any) => void;
  onError: (data: any) => void;

  constructor(initParams: InitParams, private _debug: boolean = false) {
    this.eventSource = new EventSource(initParams.url, {
      headers: initParams.headers,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClose(data: any) {}

  subscribe(cb: (data: any) => void) {
    this.onMessage = (eventData) => {
      let parsedData: NotificationEvent | SSEConnectionErrorEvent = null;
      try {
        parsedData = JSON.parse(eventData.data);
      } catch (e) {
        parsedData = {
          eventType: 'error',
          payload: {
            errorCode: null,
            message: 'Parse error',
          },
        };
      }
      if (this._debug) {
        // eslint-disable-next-line no-console
        console.table(parsedData);
      }
      cb(parsedData);
    };

    this.onError = (error: any) => {
      const errorData: SSEConnectionErrorEvent = {
        eventType: 'error',
        payload: {
          errorCode: error?.status,
          message: error?.message,
        },
      };
      if (this._debug) {
        // eslint-disable-next-line no-console
        console.table(errorData);
      }
      cb(errorData);
    };

    this.eventSource.addEventListener('message', this.onMessage);
    this.eventSource.addEventListener('error', this.onError);
    this.eventSource.addEventListener('close', this.onClose);

    return this.unsubscribe;
  }

  unsubscribe() {
    this.eventSource.close();
    this.eventSource.removeEventListener('message', this.onMessage);
    this.eventSource.removeEventListener('error', this.onError);
    this.eventSource.removeEventListener('close', this.onClose);
  }
}

export const getConnection = function (
  initParams: InitParams,
  debug = false
): ConnectionInterface<any, any> {
  return new EventSourceConnection(initParams, debug);
};
