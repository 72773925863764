import { ActionType } from '@air/domain/Common/ActionType';

export type AuthReducerAction<T> = {
  type: ActionType;
  payload?: T | string;
};

export type State<T> = {
  authDataReceived: boolean;
  authenticated: boolean;
  authError: null | string;
  user: T;
  timeOut?: boolean;
  invalidToken: boolean;
};

export default function authReducer<T>(
  state: State<T>,
  action: AuthReducerAction<T>
): State<T> {
  switch (action.type) {
    case ActionType.TimeOut:
      return {
        ...state,
        timeOut: true,
      };
    case ActionType.LoginSuccess:
      return {
        ...state,
        authDataReceived: true,
        authenticated: true,
        authError: null,
      };
    case ActionType.LoginFailure:
      return {
        ...state,
        authDataReceived: true,
        authError: action.payload as string,
      };
    case ActionType.Logout:
      return {
        authDataReceived: true,
        authError: null,
        user: null,
        invalidToken: false,
        authenticated: false,
      };
    case ActionType.GotUserProfile:
      return {
        ...state,
        user: action.payload as T,
      };
    case ActionType.InvalidToken:
      return {
        ...state,
        invalidToken: true,
        authDataReceived: true,
      };
    case ActionType.ClearAuthError:
      return {
        ...state,
        authError: null,
      };
    case ActionType.ShowIntroGuide:
      return {
        ...state,
        user: {
          ...state.user,
          showIntroGuide: false,
        },
      };
  }
}
