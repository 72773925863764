import {
  ExtendedInstitutionTypeahead,
  InstitutionDetailTypeEnum,
  DictionaryIdentifiableItem,
} from '@air/api';

export const institutionDataMapper = ({
  id,
  fullName,
  type,
  items,
}: ExtendedInstitutionTypeahead) =>
  ({
    value: id,
    label: fullName,
    type,
    extras: {
      type,
      items,
    },
  } as unknown as {
    // this hack is needed because in backend models type doesn't have correct type InstitutionDetailTypeEnum but same values and it's hard for BE to fix it
    value: number;
    type: InstitutionDetailTypeEnum;
    label: string;
    extras: {
      type: InstitutionDetailTypeEnum;
      items: DictionaryIdentifiableItem[];
    };
  });
